import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { InactivityMonitorService } from '@app/shared/services/inactivity-monitor.service';
//import { NotificationService } from '@core/services/notification.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'ctfo-idle-timer-popup',
  templateUrl: './idle-timer-popup.component.html',
  styleUrls: ['./idle-timer-popup.component.scss'],
})
export class IdleTimerPopupComponent implements OnInit {
  @Input() modal: NgbModalRef;
  private subscription: Subscription;
  timer = 30;

  public secondsToDday;

  constructor(private inactivityMonitor: InactivityMonitorService, private router: Router) {}

  private getTimeDifference(time) {
    this.secondsToDday = time;
  }

  onCancel(): void {
    this.modal.close('cancel');
  }

  onOk(): void {
    this.inactivityMonitor?.resetTimeLapsed();
    this.modal.close('ok');
  }

  ngOnInit() {
    this.subscription = interval(1000).subscribe(x => {
      this.getTimeDifference(this.timer);
      this.timer--;
      if (this.timer <= 0) {
        this.modal.close('cancel');
        this.subscription?.unsubscribe();
        this.router.navigate(['/modeling-predictions/view-scenarios']);
      }
    });
  }

  ngOnDestroy() {
    this.inactivityMonitor.resetTimeLapsed();
    this.subscription?.unsubscribe();
   // this.modal?.dismiss();
  }
}
