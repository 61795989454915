import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation, AfterViewInit, OnChanges } from '@angular/core';
import { BroadcastService } from '@app/shared/services/broadcast.service';
import { EnrollmentModelSimulationService } from '@app/shared/services/enrollment-model-simulation.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable, timer } from 'rxjs';
import { statusMetaData, message } from './download-popup.model';
import { take, map } from 'rxjs/operators';

@Component({
  selector: 'app-download-popup',
  templateUrl: './download-popup.component.html',
  styleUrls: ['./download-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadPopupComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() modal: NgbModalRef;
  @Input() parentScreen: string;
  @Input() downlaodStatus: string;
  @Input() showTimer: string;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  status = '';
  statusIndicators = statusMetaData;
  messages = message;
  headerMessage = message.HEADER_MESSAGE;
  returnMessage = message.RETURN_MESSAGE;

  broadcasterSubscription: Subscription;
  counter$: Observable<number>;
  count = 10;

  constructor(
    private broadcaster: BroadcastService,
    private enrollmentModelSimulationService: EnrollmentModelSimulationService,
  ) { }

  ngOnInit(): void {
    this.registerBroadcasters();
    this.status = this.downlaodStatus;
  }

  ngAfterViewInit(): void {
    /*  this.counter$ = timer(0, 1000).pipe(
       take(this.count),
       map(() => --this.count),
     ); */
  }

  ngOnChanges(): void {
    this.status = this.downlaodStatus;
  }
  registerBroadcasters(): void {/* 
    this.broadcasterSubscription =
      this.broadcaster.on('enrollmentModelStatusEvent')
        .subscribe((response: string) => {
          if (response && response.length) {
            this.status = response.toLowerCase();
          }
          if (this.status === 'redirect') {
            this.modalDismissed('success');
          }
          if (response && response.toLowerCase() === 'completed') {
            this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
            // setTimeout(() => {
            //   this.modalDismissed('success');
            // }, 500);
          }
          if (response && (response.toLowerCase() === 'failed' || response.includes('error'))) {
            // setTimeout(() => {
            //   this.modalDismissed('error');
            // }, 500);
            this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
          }
        }); */
  }

  modalDismissed(action: string): void {
    // this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
    if (this.status === 'failed' && this.parentScreen === 'modelling-predictions') {
      action = 'error';
    }
    if (this.status === 'completed') {
      action = 'success';
    }
    this.modalClosed.emit(action);
    this.modal.close();
  }

  ngOnDestroy(): void {
    // this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
    // this.broadcasterSubscription.unsubscribe();
  }

}
