import { Component, OnInit, HostListener, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ScenarioService } from '@app/featured-modules/modeling-predictions/modules/modeling-predictions-shared/scenarios.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { AuthService } from '@sharedServices/auth.service';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Output() navigationItemClicked: EventEmitter<any> = new EventEmitter<any>();
  userDetails: object;
  show: boolean;
  notificationConfig;
  showNotificationIcon: boolean = false;
  userGroup;
  refreshForecastNotifIcon: boolean;
  isLocked = false;

  isAdminActive: boolean = false;
  isHomeActive: boolean = false;

  constructor(public router: Router, private authService: AuthService, public scenarioService: ScenarioService, private localStorage: SessionStorageService, private notificationService: NotificationService) {
    this.show = false;
  }

  ngOnInit(): void {
    this.userDetails = this.authService.getUserDetails();
    this.scenarioService.fetchUserActiveValue.subscribe(value => {
      this.showNotificationIcon = value;
    });
    const scenarioDetail = this.localStorage.get('scenario-details');
    const inputRequest = this.localStorage.get('inputRequest');
    this.refreshForecastNotifIcon = scenarioDetail?.forecast_type === 'refresh_forecast' ? true : false;
    if (scenarioDetail) {
      this.notificationService.latestLockStatus.subscribe(val => {
        this.isLocked = val;
      })
    }
    else {
      const lockValue = ((inputRequest?.input_request?.scenario_lock_status === 'False') || (inputRequest?.input_request?.scenario_lock_status == false));
      this.isLocked = lockValue ? false : true;
    }
    this.activeDiv();

    this.notificationService.closePanel.subscribe(()=>{
      this.cancelFilter()
    })
  }

  onProfileClickMe(): void {
    this.show = !this.show;
  }

  removeValue(): any {
    const scenarioDetails = this.localStorage.get('scenario-details');
    if (scenarioDetails) {
      this.notificationService.insertUserActivityDetails(false).then(res => {
        if (res) {
          this.logoutUser();
        }
      });
    } else {
      this.logoutUser();
    }
  }

  logoutUser() {
    this.authService.onLogout();
    this.router.navigate(['/']);
  }

  activeDiv(item?): void {
    let url = window.location.href
    if (url.includes('admin/manage-users')) {
      this.isAdminActive = true
      this.isHomeActive = false
    }
    else{
      this.isAdminActive = false
      this.isHomeActive = true
    }
  }

  navigate(url): void {
    this.navigationItemClicked.emit(url);
  }

  checkVisibility(): boolean {
    return true;
  }

  openNotification(): any {
    this.notificationConfig = {};
    const obj = { state: 'open', group: 'group' };
    Object.assign(this.notificationConfig, obj);
    this.notificationConfig = JSON.parse(JSON.stringify(this.notificationConfig));
  }

  cancelFilter(e?): void {
    this.notificationConfig = {};
    const obj = { state: 'closed' };
    this.notificationConfig.state = 'closed';
    Object.assign(this.notificationConfig, obj);
  }

  @HostListener('document:click', ['$event'])
  clickout(event): void {
    if (event.target.tagName === 'IMG') {
      // returns true
    } else {
      this.show = false;
    }
  }

}
