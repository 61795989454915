<div class="modal-header border-bottom-0 pb-0">
  <h5 class="mb-4 font-18">{{ modalTitle }}</h5>
</div>
<div class="modal-body pt-0 pb-2">
  <div>
    <textarea class="textbox-input" #textareaInput maxlength="200" [(ngModel)]="modalData[modalInputColumnName]">{{ modalData[modalInputColumnName] }}</textarea>
  </div>
</div>
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
  <button type="button" class="button-primary mr-3" *ngIf="showOkButton" [disabled]="textareaInput.value.length == 0" (click)="onProceed('OK', textareaInput)">{{okButtonText}}</button>
  <div class="cursor-pointer" *ngIf="showCancelButton" (click)="onProceed('Cancel')"><img src="assets/images/shared/cancel.svg" class="mr-2" width="16" height="16">{{cancelButtonText}}</div>
</div>
