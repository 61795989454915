export const setupCancelConfig = {
  heading: 'Cancel Scenario Creation',
  message: 'Your changes will be lost. Are you sure you want to cancel?',
  callback: 'setupCancelClicked',
};

export const breadcumAssessmentConfig = {
  heading: 'Navigation Confirmation',
  message: 'Are you sure want to navigate away from this screen?',
  callback: 'breadcumAssessmentClicked',
};

export const breadcumSetupConfig = {
  heading: 'Navigation Confirmation',
  message: 'Are you sure want to navigate away from this screen?',
  callback: 'breadcumSetupClicked',
};
export const breadcumResultSiteConfig = {
  heading: 'Navigation Confirmation',
  message: 'Are you sure want to navigate away from this screen?',
  callback: 'breadcumResultSiteClicked',
};

export const weightingCancelConfig = {
  heading: 'Weighting',
  message: 'Weighting should be exact 1',
  callback: 'weightingCancelClicked',
};

export const resultSiteRemoveConfig = {
  heading: 'Remove Site',
  message: 'Are you sure you want to remove this site?',
  callback: 'resultSiteRemoveClicked',
};

export const addsiteAlertConfig = {
  heading: 'Add a Site',
  message: '',
  callback: 'addSiteAlertClicked',
};

export const filterCloseAlertConfig = {
  heading: 'Filters Confirmation',
  message: '',
  callback: 'filterCloseAlertClicked',
};

export const maxCountrySelectAlert = {
  heading: 'Country Selection Limit',
  message: 'Please select upto 5 countries for displaying. Please change your selections accordingly.',
  callback: 'maxCountryLimit',
};

export const filterSaveAlertConfig = {
  heading: 'Filters Confirmation',
  message: '',
  callback: 'filterSaveAlertClicked',
};

export const filterResetAlertConfig = {
  heading: 'Reset Confirmation',
  message: '',
  callback: 'filterResetAlertClicked',
};

export const resultSiteCancelConfig = {
  heading: 'Cancel Scenario Creation',
  message: 'There are unsaved changes on this page. Are you sure you want to cancel?',
  callback: 'resultSiteCancelClicked',
};

export const resultSiteMapCancelConfig = {
  heading: 'Cancel Scenario',
  message: 'Are you sure you want to navigate to Scenario Based Assessments?',
  callback: 'resultSiteMapCancelClicked',
};

export const resultCountryCancelConfig = {
  heading: 'Cancel Scenario Creation',
  message: 'There are unsaved changes on this page. Are you sure you want to cancel?',
  callback: 'resultCountryCancelClicked',
};
export const resultPiCancelConfig = {
  heading: 'Cancel Scenario Creation',
  message: 'There are unsaved changes on this page. Are you sure you want to cancel?',
  callback: 'resultPiCancelClicked',
};

export const filterConstraintsCancelConfig = {
  heading: 'Filters & Constraints',
  message: 'Your changes will be lost. Are you sure you want to cancel?',
  callback: 'filterContraintsCancelClicked',
};

export const breadcrumbScenarioConfig = {
  heading: 'Navigation Confirmation',
  message: 'Are you sure want to navigate away from this screen?',
  callback: 'breadcrumbScenarioClicked',
};

export const regionConfigPrevous = {
  heading: 'Region Configuration',
  message: 'Your changes will be lost. Are you sure you want to continue?',
  callback: 'fnRegionConfig',
};

export const regionConfigCancel = {
  heading: 'Region Configuration',
  message: 'Your changes will be lost. Are you sure you want to continue?',
  callback: 'fnRegionConfig',
};

export const countryConfigPrevous = {
  heading: 'Country Configuration',
  message: 'Your changes will be lost. Are you sure you want to continue?',
  callback: 'fnCountryConfig',
};

export const countryConfigCancel = {
  heading: 'Country Configuration',
  message: 'Your changes will be lost. Are you sure you want to continue?',
  callback: 'fnCountryConfig',
};

export const siteConfigPrevous = {
  heading: 'Site Configuration',
  message: 'Your changes will be lost. Are you sure you want to continue?',
  callback: 'fnSiteConfig',
};

export const siteConfigCancel = {
  heading: 'Site Configuration',
  message: 'Your changes will be lost. Are you sure you want to continue?',
  callback: 'fnSiteConfig',
};

export const minMaxSubjectValidation = {
  heading: 'Warning',
  message: 'Min. & Max. Subject % value must be between 0 to 1.',
  callback: 'fnMinMaxSubjectValidation',
};

export const minSiteCountValidation = {
  heading: 'Warning',
  message: 'Please reduce the highlighted min site count values',
  callback: 'fnminSiteCountValidation',
};

export const enrollRateAdjFactorValidation = {
  heading: 'Warning',
  message: 'Enroll. Rate Adj. Factor value must be between 0 to 1.',
  callback: 'fnEnrollRateAdjFactorValidation',
};

export const expectedPercentageSubjectRangeValidation = {
  heading: 'Warning',
  message: 'Expected % Subject Range value must be between 0 to 1.',
  callback: 'fnExpectedPercentageSubjectRange',
};

export const minMaxSiteCountValidation = {
  heading: 'Warning',
  message: '',
  callback: 'fnMinMaxSiteCountValidation',
};

export const saveConfigurationFailed = {
  heading: 'Warning',
  message: 'Something went wrong – please try again after sometime',
  callback: 'saveConfigFailed',
};

export const scenarioDeleteCofiguration = {
  heading: 'Delete Scenario',
  message: 'Do you want to archive the scenario or permanently delete the scenario?',
  callback: 'scenarioDeleteConfig',
};

export const scenarioArchiveCofiguration = {
  heading: 'Archive Scenario',
  message: 'Please use the Archive icon to archive a scenario.',
  callback: 'scenarioArchiveConfig',
};

export const studyDeleteCofiguration = {
  heading: 'Delete Study',
  message: 'Do you want to archive the study or permanently delete the study ?',
  callback: 'studyDeleteConfig',
};

export const studyArchiveCofiguration = {
  heading: 'Archive Study',
  message: 'Please use the Archive icon to archive a study.',
  callback: 'studyArchiveConfig',
};

export const finalDeleteStudyConfiguration = {
  heading: 'Delete Study',
  message: 'Are you sure you want to delete this study?',
  callback: 'finalDeleteStudyConfig',
};

export const finalDeleteScenarioConfiguration = {
  heading: 'Delete Scenario',
  message: 'Are you sure you want to delete this scenario?',
  callback: 'finalDeleteScenarioConfig',
};

export const deleteScenarioConfirmConfiguration = {
  heading: 'Delete Scenario',
  message: 'The selected scenario has been successfully deleted',
  callback: 'deleteScenarioConfirmConfig',
};

export const deleteStudyConfirmConfiguration = {
  heading: 'Delete Study',
  message: 'The selected study has been successfully deleted.',
  callback: 'deleteStudyConfirmConfig',
};
export const exportSuccessConfig = {
  heading: 'Export',
  message: 'Your export is now complete',
  callback: 'expotSuccessConfig',
};
export const finalArchiveStudyConfiguration = {
  heading: 'Archive Study',
  message: 'Are you sure you want to archive this study?',
  callback: 'finalArchiveStudyConfig',
};

export const archiveStudyConfirmConfiguration = {
  heading: 'Archive Study',
  message: 'The selected study has been successfully archived.',
  callback: 'archiveStudyConfirmConfig',
};

export const finalArchiveScenarioConfiguration = {
  heading: 'Archive Scenario',
  message: 'Are you sure you want to archive this scenario?',
  callback: 'finalArchiveScenarioConfig',
};

export const archiveScenarioConfirmConfiguration = {
  heading: 'Archive Scenario',
  message: 'The selected scenario has been successfully archived.',
  callback: 'archiveScenarioConfirmConfig',
};

export const permanentDeleteScenarioConfiguration = {
  heading: 'Delete Scenario',
  message: 'Are you sure you want to permanently delete the scenario ?',
  callback: 'permanentDeleteScenarioConfig',
};
export const permanentDeleteScenarioConfirmConfiguration = {
  heading: 'Delete Scenario',
  message: 'The selected scenario has been successfully deleted.',
  callback: 'permanentDeleteScenarioConfirmConfig',
};

export const permanentDeleteStudyConfiguration = {
  heading: 'Delete Study',
  message: 'Are you sure you want to permanently delete the study ?',
  callback: 'permanentDeleteStudyConfig',
};

export const permanentDeletePatialStudyConfiguration = {
  heading: 'Delete Study',
  message:
    'This study has both archived and active scenarios. If you delete the study, all associated scenarios will get deleted. Are you sure you want to delete the study ?',
  callback: 'permanentDeletePatialStudyConfig',
};
export const permanentDeleteStudyConfirmConfiguration = {
  heading: 'Delete Study',
  message: 'The selected study has been successfully deleted.',
  callback: 'permanentDeleteStudyConfirmConfig',
};

export const restoreScenarioConfiguration = {
  heading: 'Unarchive Scenario',
  message: 'Are you sure you want to retrieve the scenario ?',
  callback: 'restoreScenarioConfig',
};

export const restoreScenarioConfirmConfiguration = {
  heading: 'Unarchive Scenario',
  message: 'The selected scenario has been successfully retrieved',
  callback: 'restoreScenarioConfirmConfig',
};

export const restoreStudyConfiguration = {
  heading: 'Unarchive Study',
  message: 'Are you sure you want to retrieve the study ?',
  callback: 'restoreStudyConfig',
};

export const restoreStudyConfirmConfiguration = {
  heading: 'Unarchive Study',
  message: 'The selected study has been successfully retrieved',
  callback: 'restoreStudyConfirmConfig',
};

export const errorMessageConfiguration = {
  heading: 'Error Occurred',
  message: 'Something went wrong – please try again after sometime',
  callback: 'errorMessageConfig',
};

export const filterCriteriaAddConfig = {
  heading: 'Age Validation Error',
  message: 'Please validate the Age !',
  callback: '',
};

export const compareScenarioAlertConfig = {
  heading: 'Compare Scenario Selection Limit',
  message: 'Please select upto 3 scenarios for displaying. Please change you selections accordingly.',
  callback: 'maxCompareLimit',
};

export const browserBackConfiguration = {
  heading: 'Navigation Confirmation',
  message: 'Are you sure want to navigate away from this screen?',
  callback: 'browserBackConfig',
};

export const preventCloseAlertConfig = {
  heading: 'Fill Mandatory Fields',
  message: 'Please fill out mandatory form fields.',
  callback: 'preventCloseAlertClicked',
};

export const customizeColSave = {
  heading: 'Apply Weights',
  message:
    'Kindly click "Apply Weights" after assigning customized weights on the screen. This will ensure that the rankings are accurate.',
  callback: 'fnCustomizeSave',
};

export const surveyReminderSuccessConfig = {
  heading: 'Send Survey Reminder',
  message: 'Reminder will be sent to all the countries that have not completed the survey.',
  callback: 'surveyReminderSuccessConfig',
};

export const saveOutreachSuccessConfig = {
  heading: 'Saved Successfully',
  message: 'Country outreach details saved successfully',
  callback: 'surveyReminderSuccessConfig',
};

export const sendSurveyReminderSuccessConfig = {
  heading: 'Survey reminder sent successfully',
  message: 'Country survey reminder sent successfully',
  callback: 'countrySurveyReminderSuccess',
};

export const surveyCreateSuccessConfig = {
  heading: 'Survey Creation Successful',
  message: 'Survey has been created successfully.',
  callback: '',
};

export const sendSurveySuccessConfig = {
  heading: 'Survey sent Successfully',
  message: 'Survey has been sent successfully.',
  callback: '',
};
export const failureAlert = {
  heading: 'Error occurred.',
  message: 'Something went wrong - Please try again later.',
  callback: '',
};

export const saveSiteOureachSuccessConfig = {
  heading: 'Site outreach details saved.',
  message: 'Site outreach details has been saved successfully.',
  callback: '',
};

export const saveInvestigatorAlertConfig = {
  heading: 'Investigator details saved.',
  message: 'Investigator details saved successfully',
  callback: '',
};

export const emailNotAvailableAlertConfig = {
  heading: 'Investigator email not present.',
  message: 'Email id is not added for the selected site investigator. Please add the email address.',
  callback: '',
};

export const countryOutreachAlertConfig = {
  heading: 'Save',
  message: 'Please save your changes before navigating.',
  callback: 'countryOutreachAlertConfig',
};

export const lostProgressAlertConfig = {
  heading: 'Save',
  message: 'You will lose all the progress made. Are you sure you want to continue?',
  callback: 'lostProgressAlertConfig',
};
