import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let role = route.data.role;
    return this.auth.isLoggedIn().pipe(
      map(isAuthenticated => {
        if (!isAuthenticated) {
          const url = window.location.origin;
          window.location.href = `${environment.authLoginUrl}${url}`;
        }

        const { active_status, app_user_group } = this.auth.getUserDetails();
        if (role === 'admin') {
          if (active_status?.toLowerCase() !== 'active' || app_user_group !== 'superuser') {
            this.router.navigate(['no-access']);
          }
        } else {
          if (active_status?.toLowerCase() !== 'active') {
            this.router.navigate(['no-access']);
          }
        }
        return isAuthenticated;
      })
    );
  }
}
