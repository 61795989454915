import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// tslint:disable-next-line: max-line-length
import { CustomChartComponent } from '@app/featured-modules/modeling-predictions/modules/study-data/study-data/historical-summary/custom-chart/custom-chart.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from '@thisissoon/angular-infinite-scroll';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { HighchartsChartModule } from 'highcharts-angular';
import { environment } from 'src/environments/environment';
import { AlertsComponent } from './components/alerts/alerts.component';
import { ExpandChartComponent } from './components/expand-chart/expand-chart.component';
import { GridComponent } from './components/grid/grid.component';
import { TextboxPopupComponent } from './components/textbox-popup/textbox-popup.component';
import { SortingService } from './components/grid/sorting-service';
import { HeaderComponent } from './components/header/header.component';
import { LoadingPopupComponent } from './components/loading-popup/loading-popup.component';
import { MessagePopupComponent } from './components/message-popup/message-popup.component';
import { MultiHeaderTableComponent } from './components/multi-header-table/multi-header-table.component';
import { AuthGuard } from './services/auth.gaurd';
import { MomentDateFormatter } from './services/dateformat';
import { DragDropDirective } from './services/drag-drop.directive';
import { InMemoryService } from './services/in-memory.service';
import { DatePipe } from './services/pipes/date.pipe';
import { SearchPipe } from './services/pipes/search.pipe';
import { DropdownModule } from './components/dropdown/dropdown.module';
import { CopyInputsPopupComponent } from './components/copy-inputs-popup/copy-inputs-popup.component';
import { Debounce } from './services/directives/debounce.directive';
import { DownloadPopupComponent } from './components/download-popup/download-popup.component';
import { NotificationPanelComponent } from '@app/featured-modules/modeling-predictions/notification-panel/notification-panel.component';
import { GridModule } from '@app/shared/dependency/grid';
import { TableModule } from '@app/shared/dependency/table';
import { HasRoleDirective } from './dependency/directives/has-role.directive';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditUserCountryModelComponent } from '@app/featured-modules/modeling-predictions/share-scenario/edit-user-country-model/edit-user-country-model.component';
import { PipesModule } from './dependency/pipes';
import { CustomiseChartComponent } from './components/customise-chart/customise-chart.component';
import { IdleTimerPopupComponent } from '@app/featured-modules/modeling-predictions/idle-timer-popup/idle-timer-popup.component';
import { DateRangePopupComponent } from './components/date-range-popup/date-range-popup.component';
import { CustomizeActivationFlyoutComponent } from './components/customize-activation-flyout/customize-activation-flyout.component';

@NgModule({
  declarations: [
    HeaderComponent,
    DragDropDirective,
    MessagePopupComponent,
    AlertsComponent,
    GridComponent,
    TextboxPopupComponent,
    DatePipe,
    SearchPipe,
    CustomChartComponent,
    ExpandChartComponent,
    MultiHeaderTableComponent,
    LoadingPopupComponent,
    DownloadPopupComponent,
    CopyInputsPopupComponent,
    Debounce,
    NotificationPanelComponent,
    EditUserCountryModelComponent,
    HasRoleDirective,
    CustomiseChartComponent,
    IdleTimerPopupComponent,
    DateRangePopupComponent,
    CustomizeActivationFlyoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    environment.env === 'local'
      ? HttpClientInMemoryWebApiModule.forRoot(InMemoryService, { dataEncapsulation: false })
      : [],
    FormsModule,
    InfiniteScrollModule,
    HighchartsChartModule,
    GridModule,
    TableModule,
    PipesModule,
    NgSelectModule,
  ],
  providers: [AuthGuard, SortingService, MomentDateFormatter],
  exports: [
    HeaderComponent,
    DragDropDirective,
    MessagePopupComponent,
    GridComponent,
    TextboxPopupComponent,
    AlertsComponent,
    CustomChartComponent,
    ExpandChartComponent,
    MultiHeaderTableComponent,
    SearchPipe,
    LoadingPopupComponent,
    DownloadPopupComponent,
    CopyInputsPopupComponent,
    Debounce,
    NotificationPanelComponent,
    EditUserCountryModelComponent,
    GridModule,
    TableModule,
    HasRoleDirective,
    NgSelectModule,
    PipesModule,
    CustomiseChartComponent,
    IdleTimerPopupComponent,
    DateRangePopupComponent,
    CustomizeActivationFlyoutComponent,
  ],
})
export class SharedModule {}
