import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';


@Injectable()
export class SessionIdInterceptor implements HttpInterceptor {
    constructor(private sessionStorage: SessionStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if account is logged in and request is to the api url

        const user = this.sessionStorage.get('user-details');
        if (user && user.session_id) {
            request = request.clone({
                setHeaders: { 'session-id': user.session_id }
            });
        }

        return next.handle(request);
    }
}