<div class="loading-circle h-100 w-100" *ngIf="showLoader">
    <img src="assets/images/shared/loading-circle.svg" width="50" height="50" alt="loading...">
</div>
<div>
    <div class="modal-header border-bottom-0 pb-0">
        <h5 class="modal-title">Add Countries</h5>
    </div>
    <div style="padding-left: 10px; max-height: 321px; overflow: hidden">
        <!-- <div class="mr-3" style="margin-top: 10px; margin-left: 7px">
            <div>
                <input type="text" style="float: left;" class="custom-checkbox-button search-input" id="inputSearch" #inputSearch placeholder="Search by Country" [(ngModel)]="searchText" (keyup)="searchCountry()" />
            </div>
            <strong class="read-access-label" for="id">Read Access</strong>
            <div class="custom-checkbox-wrapper">
                <input class="custom-checkbox country-checkbox" [(ngModel)]="isReadAccess" (click)="onReadAccess($event)" [disabled]="this.countryRowData.create_type !== 'insert'" type="checkbox" style="height: 0px;" />
            </div>
        </div> -->
        <ctfo-grid [gridData]="countryAccessGridData" [gridProp]="countryAccessGridConfig" (rowclick)="tableRowClicked($event)">
        </ctfo-grid>
        <div class="no-msg" *ngIf="errorMsg">
            <span>{{ errorMsg }}</span>
        </div>
    </div>

    <div class="modal-footer custom-footer">
        <button class="button-primary mr-2" (click)="onCountryAdd()" [disabled]="!isAddDisabled()">Add</button>
        <div class="cancel-button d-flex" (click)="onCancel()">
            <img src="assets/images/shared/cancel.svg" alt="cancel" class="cursor-pointer" width="15px">
            <p class="mb-0 ml-2 cursor-pointer">Cancel</p>
        </div>
    </div>
</div>