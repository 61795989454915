<div class="table-responsive mt-3" [ngClass]="{'tableClassName': showScrollBar}">
  <table class="table">
    <thead>
      <tr class="table-header-tr">
        <th *ngFor="let column of chartOptions.columns" (click)="onSort(column)" class="table-header"
          [ngStyle]="{'width': column.width + '%'}">
          {{column.name}}
          <span [ngStyle]="{'visibility': column.isSortable === true ? 'unset' : 'hidden'}">
            <i *ngIf="(sortOrder === 'desc' && column.sorted)" class="fa fa-caret-down"></i>
            <i *ngIf="(sortOrder === 'asc' && column.sorted)" class="fa fa-caret-up"></i>
          </span>
        </th>
      </tr>
    </thead>
    <tbody>
      <div class="no-data-text table-text" *ngIf="!chartOptions.data.length">No Data to Display</div>
      <tr *ngFor="let row of chartOptions.data; let i = index;">
        <td [ngStyle]="{'width': chartOptions.columns[0].width + '%'}" class="table-text">
          {{row.country || row.tier}}
        </td>

        <td [ngStyle]="{'width': chartOptions.columns[1].width + '%'}" class="table-text">
          <div class="d-flex align-items-center">
            <div class="bar-count table-text col p-0">{{row.no_of_sites}}</div>
            <div class="col-11">
              <div class="progress progress-bar {{chartOptions.columns[1].className}}" role="progressbar"
                [attr.aria-valuenow]="row.no_of_sites" aria-valuemin="0"
                [ngStyle]="{'width': row.no_of_sites_width + '%'}"
                [attr.aria-valuemax]="chartOptions.maxValues.no_of_sites">
              </div>
            </div>
          </div>
        </td>

        <td [ngStyle]="{'width': chartOptions.columns[2].width + '%'}" class="table-text pl-4">
          <div class="d-flex align-items-center">
            <div class="bar-count table-text col p-0 text-right">
              {{row.median_randomization_rate | number : '1.2-2'}}
            </div>
            <div class="col-11">
              <div class="progress progress-bar {{chartOptions.columns[2].className}}" role="progressbar"
                [attr.aria-valuenow]="row.median_randomization_rate" aria-valuemin="0"
                [ngStyle]="{'width': row.median_randomization_rate_width + '%'}"
                [attr.aria-valuemax]="chartOptions.maxValues.median_randomization_rate">
              </div>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
