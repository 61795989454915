<div class="loading-circle h-100 w-100" *ngIf="showLoader">
  <img src="assets/images/shared/loading-circle.svg" width="50" height="50" alt="loading...">
</div>
<div class="tablecustomiser" [@openCloseSidebar]="getState(this.gridColumnCustomizerToggle)">
  <!-- <div class="dummycontainer" (click)="close()"></div> -->
  <div class="maincontainer">
    <div class="header">
      <header>{{ custHeading }}</header>
    </div>
    <ul class="nav nav-tabs tabspace" role="tablist">
      <li class="tab-item">
        <a class="nav-link inactive" data-toggle="tab" [class.active]="true"> Columns</a>
      </li>
    </ul>

    <div class="boxBorder">
      <div>
        <div class="desc">
          <div class="col_head">
            <span class="titlehead">Select columns for your default view.</span>
          </div>
        </div>

        <div class="col-data col_topposition">
          <ng-container *ngFor="let table_column of columns; let i = index">
            <ng-container *ngIf="table_column.columntitle && table_column.columntitle != 'Benchmark'">
              <div class="column">
                <div class="checkbox">
                  <input
                    type="checkbox"
                    (change)="changeCustomizeColumn($event, table_column['columndatakey'], i)"
                    [checked]="table_column['isdisplayed']"
                  />
                  <span class="column_name">{{ table_column.columntitle }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="sidebar-footer">
      <div class="btn-darker" (click)="saveClicked()">Save</div>
      <div class="cancel-btn" (click)="close()"><img src="assets/images/icon_cancel.svg" /><span>Cancel</span></div>
    </div>

    <!-- <div class="ctfo-footer custom-footer">
      <button class="btn-custom-primary mr-2" (click)="saveClicked()">Save</button>
      <button class="btn-outline-none" (click)="close()">
        <img class="mr-2" src="assets/images/icon_cancel.svg" />
        <span>Cancel</span>
      </button>
    </div> -->
  </div>
</div>
<!-- <div class="sidebar-footer">
      <div class="btn-darker" (click)="saveClicked()">Save</div>
      <div class="cancel-btn" (click)="close()"><img src="assets/images/icon_cancel.svg" /><span>Cancel</span></div>
    </div> -->
