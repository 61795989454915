import { Injectable } from '@angular/core';
import { AuthService } from '@app/shared/services/auth.service';
import { BroadcastService } from '@app/shared/services/broadcast.service';
import { HttpHandlerService } from '@app/shared/services/http-handler.service';
import { appSetting } from '@constants/endpoints';
import { API_SUCCESS } from '@constants/messages';
import { interval, Subscription } from 'rxjs';
import {
  EnrollmentModelRunInputRequest,
  EnrollmentModelStatusInputRequest,
} from '../models/enrollment-model-simulation.model';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentModelSimulationService {
  private intervalSubscription: Subscription;
  private intervalPeriod = 20000;
  // private count = 0;

  constructor(
    private httpHandler: HttpHandlerService,
    private broadcaster: BroadcastService,
    private authService: AuthService,
    private localStorage: SessionStorageService
  ) {}

  runEnrollmentModel(payload: EnrollmentModelRunInputRequest, url: string, statusUrl: string): void {
    this.broadcaster.broadcast('enrollmentModelStatusEvent', 'preparing');
    const statusPayload = this.preparePayloadForEnrollmentModelStatus(payload);
    // This method is only used for sending data. The response need not be listened to.
    this.httpHandler.postHttpResults(url, payload).subscribe();
    // Start timer for checking status

    // statusUrl = appSetting.RUN_ENROLLMENT_MODEL_STATUS;
    // if (url === appSetting.RUN_ENROLLMENT_MODEL_PREF) {
    //   statusUrl = appSetting.RUN_ENROLLMENT_MODEL_STATUS_PREF;
    // }

    this.checkEnrollmentModelStatus(statusPayload, statusUrl);
  }

  checkEnrollmentModelStatus(payload: EnrollmentModelStatusInputRequest, statusUrl: string): void {
    this.intervalSubscription = interval(this.intervalPeriod).subscribe(() => {
      this.checkEnrollmentStatusAPI(payload, statusUrl);
    });
  }

  private checkEnrollmentStatusAPI(payload: EnrollmentModelStatusInputRequest, statusUrl: string): void {
    this.httpHandler.postHttpResults(statusUrl, payload).subscribe((response: any) => {
      if (response.status === API_SUCCESS) {
        if (response.result && response.result.length > 0) {
          this.localStorage.set('modelRunResult', response.result);
          // if (this.count === 0) {
          //   this.broadcaster.broadcast('enrollmentModelStatusEvent', 'started');
          // }
          // if (this.count === 1) {
          //   this.broadcaster.broadcast('enrollmentModelStatusEvent', 'in-progress');
          // }
          // if (this.count === 2) {
          //   this.broadcaster.broadcast('enrollmentModelStatusEvent', 'error-enrollment');
          //   this.count = 0;
          //   this.broadcaster.broadcast('enrollmentModelStatusEvent', 'completed');
          // }
          // this.count++;
          this.broadcaster.broadcast('enrollmentModelStatusEvent', response.result[0].model_run_status);
        } else {
          this.broadcaster.broadcast('enrollmentModelStatusEvent');
        }
      } else {
        this.broadcaster.broadcast('enrollmentModelStatusEvent', 'error-enrollment');
      }
    });
  }

  stopEnrollmentStatusCheck(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  private preparePayloadForEnrollmentModelStatus(
    data: EnrollmentModelRunInputRequest
  ): EnrollmentModelStatusInputRequest {
    const payload: EnrollmentModelStatusInputRequest = {
      input_request: {
        user_id: data.input_request.user_id,
        theme_id: data.input_request.theme_id,
        study_id: data.input_request.study_id,
        scenario_id: data.input_request.scenario_id,
        new_scenario_version: data.input_request.scenario_version,
        forecast_type: data.input_request.forecast_type,
        study_feasibility: data.input_request.study_feasibility,
        study_created_by: data.input_request.user_id,
        scenario_created_by: data.input_request.scenario_created_by,
        action: '',
      },
    };
    return payload;
  }
}
