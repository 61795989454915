export enum appSetting {
  USER_DETAILS = 'login-details',
  SCENARIOSEARCH = 'scenarioSearch',
  SCENARIO_LIST = 'scenario-list',
  GETFILTER = 'get-filter',
  GETFILTERSETUP = 'cascade-filter',
  DISPLAYSCENARIO = 'display-scenario',
  SEARCHLIST = 'set-up-scenario-search-1',
  SAVESCENARIO = 'create-scenario',
  SAVEFILTER = 'create-filter',
  SITERANK = 'site-rank',
  NARROWRESULT = 'narrow-result',
  COUNTRYRANK = 'country-rank',
  PIRANK = 'pi-rank',
  OPTIMIZE_GET_CONSTRAINTS = 'optimize-constraints',
  OPTIMIZE_DISPLAY_CONSTRAINTS = 'display-constraints',
  OPTIMIZE_SAVE_CONSTRAINTS = 'save-constraints',
  SITEDETAIL = 'site-detail',
  COUNTRYDETAIL = 'country-details',
  PIDETAIL = 'investigator-detail',
  ADDDELETESITEOPTI = 'add-delete-site-post-optimization',
  GETFILTERSITE = 'get-filter-site',
  DELETESCENARIO = 'scenario-housekeeping',
  GETCONSTRAINTFILTER = 'get-constraint-filter',
  GETCONSTRAINTSITEFILTER = 'get-constraint-site-filter',
  PATIENTDETAIL = 'patient-drug-detail',
  ADDSITE = 'add-site',
  EXPORT = 'export',
  COMMENTSERVICE = 'comment-service',
  SUMMARYDETAIL = 'summary-detail',
  DRUGMAPDETAIL = 'get-drugstatus',
  DRUGDETAIL = 'drug-detail',
  PATIENTCHART = 'patientgraph',
  DROPDOWNFILTER = 'dropdown-filter',
  LANDSCAPEFILTER = 'landscape-filter',
  COMPARESCENARIO = 'compare-scenarios',
  LANDSCAPEEXPORT = 'landscape-export',
  PATIENTCOUNTRYMAP = 'landscapecount',
  DRUGCOUNTRYMAP = 'display-drug-map',
  PATIENTDDNLIST = 'patient-dropdown',
  GLOBALCOUNTRYLANDSCAPEDETAIL = 'display-country-landscape',
  SITEINVDETAIL = 'sites-detail',
  COUNTRYPROFILEDDN = 'country-dropdown',
  COUNTRYMAP = 'countrycount',
  SITEMAP = 'sitemap',
  SITETIER = 'sitetier',
  LANDSCAPESITESPONSOR = 'sponsor-experience',
  LANDSCAPESITETRIAL = 'trials',
  LANDSCAPESITETRIALTREND = 'trial-trend',
  LANDSCAPESITEPROFILE = 'site-profile',
  LANDSCAPESITEINV = 'affiliated-investigators',
  SITEMETRICS = 'sitemetric',
  INVDRUGEXPERIENCE = 'drug-experience',
  INVSPONSOREXPERIENCE = 'investigator-sponsor-experience',
  INVTRIALEXPERIENCE = 'trial-experience',
  INVAFFSITE = 'affiliated-sites',
  INVTRIALTREND = 'investigator-trial-trend',
  INVPROFILE = 'investigator-profile',
  INVSCORE = 'investigator-scores',
  INVEXPANALYSIS = 'experience-analysis',
  INVPATIENTACCESS = 'patient-access',
  DRUGGRID = 'drug-status',
  DRUGDDN = 'drug-dropdown',
  SUMMARYOPTIMIZEDLIST = 'get-site-comparison-details',
  COUNTRYOUTREACH = 'get-country-outreach-details',
  SITEOUTREACH = 'get-site-outreach-details',
  GET_INVESTIGATOR_DETAILS = 'get-investigator-details',
  SURVEYDETAIL = 'get-survey-details',
  SEARCHDETAILS = 'search-details',
  SURVEYCREATION = 'survey-creation',
  SENDSURVEY = 'send-survey',
  SENDSURVEYREMINDER = 'send-survey-reminder',
  EXPORTSURVEY = 'export-survey',
  COUNTRY_DETAILS = 'get-country-details',
  SAVE_COUNTRY_DETAILS = 'save-country-details',
  SAVECOUNTRYOUTREACH = 'save-country-outreach-details',
  SAVESITEOUTREACHDETAIL = 'save-site-outreach-details',
  SAVE_INVESTIGATOR_DETAILS = 'save-investigator-details',
  COUTRYSURVELFLYOUTDETAILS = 'get-survey-prioritization-details-flyout',
  SITEINVESTIGATORDETAILS = 'get-site-prioritization-investigator-details',
  SAVESITECURROPTIMISATIONFLAG = 'save-site-curr-optimisation-flag',
  UPDATECOUNTRYSURVEYFLAG = 'update-country-survey-flag-details',
  SAVEFINALSITELIST = 'save-final-list',
  GETFINALVIEW = 'get-final-prioritization-view',
  CLINICALDETAIL = 'display-trial-detail',
  COUNTRYLANDSCAPEDETAIL = 'countrylandscape-detail',
  CLINICALTIMELINE = 'display-trial-timeline',
  CLINICALTRIALCHART = 'clinicaltrialchart',
  DISPLAYCLINICALTRIALCHART = 'display-trial-chart',
  CLINICALMAP = 'trialmap',
  DISPLAYSCENARIOSTATUS = 'display-scenario-status',
  UPDATESCENARIOSTATUS = 'update-scenario-status',
  POST_TRIAL_DETAILS = 'post-trial-details',
  PROJECT_ENROLLMENT_DETAILS = 'get-project-enrollment-details',
  VIEW_SITE_RAMP_AND_ENROLLMENT_FACTOR = 'view-site-ramp-and-enrollment-factor',
  SAVE_SITE_RAMP_AND_ENROLLMENT_FACTOR = 'save-site-ramp-and-enrollment-factor',
  GET_VIEW_DROPDOWN = 'get-view-dropdown',
  CALCULATE_ENROLLMENT_RESULTS = 'calculate-enrollment-results',
  SAVE_ENROLLMENT_SCENARIO = 'save-enrollment-scenario',
  SITE_ACTIVATED_GRAPH = 'display-sites-activated',
  PATIENT_ENROLLMENT_GRAPH = 'display-patient-enrollment',
  NEW_CUSTOM_SCENARIO = 'generate-custom-enrollment-scenario',
  GET_ENROLLMENT_CHARTS = 'export-graph-data',
  EXPORT_PROJECT_ENROLLMENT = 'export-project-enrollment',
  CHECK_EP_RESULTS_EXISTS = 'check-ep-results-exists',
  GET_GENERATE_STATUS = 'check-async-status',
  CHECK_ENROLLMENT_DOWNLOAD_STATUS = 'check-download-status',
  REFRESH_SURVEY_DATA = 'refresh-survey-data',
  REFRESH_SURVEY_DATA_POLL = 'check-data-refresh-completed',
  SITE_TIER_PERFORMANCE = 'sitetier/performance',
  SITE_TIER_EXPERIENCE = 'sitetier/experience',
  UPDATE_METRICS_DOWNLOAD_PREVIOUS = 'download-file',
  UPDATE_METRICS_UPLOAD_FILE = 'upload-file',
  SCENARIOMAPVIEW = 'scenario-map-view',
  VIEW_PROTOCOL_UPLOAD = 'upload-protocol-document',
  VIEW_PROTOCOL_DOWNLOAD = 'download-protocol-document',
  VIEW_PROTOCOL_DELETE = 'delete-protocol-document',
  UPDATECOLUMN = 'last-module-activated',
  TRIALUNIVERSEGENERATE = 'generate-trialuniverse',
  TRIALUNIVERSEDETAIL = 'get-trialuniverse-details',
  TRIALUNIVERSELOCALFILTERS = 'get-trialuniverse-local-filters',
  TRIALUNIVERSESAVE = 'save-trialuniverse',
  TRIALUNIVERSEEXPORT = 'export-trialuniverse',
  BENCHMARK_GENERATE = 'generate-benchmark',
  BENCHMARK_DETAIL = 'get-benchmark-trial-details',
  BENCHMARK_SUMMARY = 'get-benchmark-details',
  BENCHMARK_SAVE = 'save-benchmark',
  BENCHMARK_SUMMARY_SAVED_FILTER = 'benchmark-summary-saved-filter',
  BENCHMARK_SUMMARY_FILTER = 'benchmark-summary-filter',
  BENCHMARK_REFRESH = 'refresh-benchmark',
  BENCHMARK_EXPORT = 'export-benchmark',
  ADD_TRIAL_FILTERS = 'add-trial-filters',
  ADD_TRIAL_DISPLAY = 'add-trial-display',
  SAVE_ADD_TRIAL = 'save-add-trial',
  COMMENT_DISPLAY = 'view-comments',
  COMMENT_SAVE = 'save-comments',
  COMMENT_CHANGE_STATUS = 'update-comments',
  // SEARCH_FILTER = 'search-filter',
  SEARCH_FILTER = 'get-filter',
  FLYOUT_GENERATE = 'flyout-generate',
  INV_FLYOUT_GENERATE = 'invflyout-generate',
  FLYOUT_COUNT = 'flyout-count',
  GENERATE_HIGHEST_INV = 'highest-inv',
  APPLYWEIGHTS = 'apply-weights',
  VALIDATE_OPTIMIZE_FLAG = 'validate-optimize-flag',
  OPTIMIZE_VALIDATE = 'validate-constraints',
  GET_LIST_OF_USER_DETAILS = 'ctemp-get-user-list-details',
  UPDATE_USER_DETAILS = 'ctemp-update-user-details',//used
  DELETE_USER_DETAILS = 'ctemp-delete-user',
  GET_ALL_SCENARIO_DETAILS = 'ctemp-get-all-scenario-details',
  GET_COUNTRY_FOR_SCENARIO = 'ctemp-get-mda-country-list',//used
  GET_USER_SCENARIO_ACCESS_DETAILS = 'ctemp-get-user-scenario-access-details',// Used
  GET_USER_SCENARIO_ACCESS_FILTERS = 'ctemp-get-admin-scenario-filters',//used
  REMOVE_USER_SCENARIO_ACCESS = 'delete-user-scenario',
  EXPORT_ADMIN_CONSOLE_DATA = 'ctemp-export-admin-console-data',//Used
  DISPLAY_SCENARIO_ACCESS_DETAILS = 'ctemp-display-scenario-access-details',// Used
  REMOVE_SCENARIO_USER_ACCESS = 'remove-scenario-user-access',
  SHARE_SCENARIO_DROPDOWN = 'ctemp-share-scenario-dropdown',// Used
  UPDATE_SCENARIO_ACCESS_DETAILS = 'ctemp-update-scenario-access-details',
  GET_NOTIFICATIONS = 'ctemp-get-notifications',
  COUNTRY_ACCESS_LIST = 'get-country-dropdown',
  UPDATE_POKE_RELEASE = 'ctemp-update-poke-details', // Used
  INSERT_USER_ACTIVITY = 'ctemp-insert-scenario-log-activity', // Used
  GET_USERS_DROPDOWN = 'ctemp-get-idm-user-list',// Used
  get_study_dropdown = 'ctemp-get-study-dropdown',//Used
  get_scenario_dropdown = 'ctemp-get-scenario-dropdown',//Used
  ctemp_copy_user_information_temp = 'ctemp-copy-user-information', // Used
  ctemp_get_active_gfl_list = 'ctemp-get-active-gfl-list', // used
  ctemp_assign_study_secondary_owner = 'ctemp-assign-study-secondary-owner'//Used
}
