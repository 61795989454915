<!-- <ctfo-overlay [state]="sidebarOpen == 'open' ? true : false"></ctfo-overlay> -->
<div [class.hideOverlay]="!isShow" class="custom-overlay" id="custom-overlay">
  <div [@openCloseSidebar]="sidebarOpen" class="narrow-slide landscape-filter">
    <div class="narrow-content padding">
      <h5 class="displayinline">
        Notifications
        <!-- <img class="refresh-icon" [title]="'Refresh'" [src]="refresh" (click)="refreshNotification()" /> -->
        <i class="fa fa-refresh icon-color" [ngClass]="{ 'fa-spin': refreshstatus }" aria-hidden="true"
          (click)="refreshNotification()"></i>
        <!-- <i [ngClass]="{ 'fa-spin': refreshstatus }" class="fas fa-sync refresh-icon" (click)="refreshNotification()"></i> -->
        <input *ngIf="viewTypeData == 'notification' || viewTypeData == 'activeuser'" type="text"
          class="search-input-notification" #inputSearch placeholder="Search Geographies"
          (keypress)="allowAlphabetOnly($event)" [(ngModel)]="searchText"
          (ngModelChange)="searchStream.next(searchText)" />
      </h5>
      <!-- <div class="search-filter"></div> -->
      <div class="clear"></div>

      <div class="maincontainer">
        <ul class="nav nav-tabs" role="tablist">
          <li class="landscape">
            <a class="nav-link inactive nav-style" data-toggle="tab" title="Pending Requests" [class.active]="this.notificationview"
              (click)="setViewType('notification')">Pending Requests</a>
          </li>
          <li class="landscape">
            <a class="nav-link inactive nav-style" data-toggle="tab" title="Raise New Requests" [class.active]="this.activeuserview"
              (click)="setViewType('activeuser')">Raise New Requests</a>
          </li>
          <li class="landscape">
            <a class="nav-link inactive-archive nav-style" data-toggle="tab" title="Archive" [class.active]="this.archiveview"
              (click)="setViewType('archive')">Archive</a>
          </li>
        </ul>

        <div class="boxBorder">
          <!-- column start -->
          <div *ngIf="viewTypeData == 'notification'">
            <div class="loader-container notification-inner-panel" *ngIf="notificationLoading">
              <!-- <ctfo-loading-spinner></ctfo-loading-spinner> -->
            </div>

            <div *ngIf="!notificationLoading" class="no-gutters page-view-scenarios">
              <div *ngIf="!noDataFlag" class="col-md-12 table-body">
                <ng-template *ngTemplateOutlet="NotificationTable"> </ng-template>
              </div>

              <div *ngIf="noDataFlag">
                <div class="notification-inner-panel">{{ pendingRequest }}</div>
              </div>

            </div>
          </div>

          <div *ngIf="viewTypeData == 'activeuser'">
            <div class="no-gutters page-view-scenarios">
              <div>
                <div class="new-request loader-container" *ngIf="userLoading">
                  <!--   <ctfo-loading-spinner></ctfo-loading-spinner> -->
                </div>
                <div *ngIf="!userLoading" class="col-md-12 table-body hieght-div">
                  <div *ngIf="!noDataFlagUser">
                    <ng-template *ngTemplateOutlet="NotificationTable"> </ng-template>
                  </div>

                  <div *ngIf="noDataFlagUser">
                    <div class="new-request">{{ activeUserError }}</div>
                  </div>
                </div>
                <hr />
                <div class="col-md-12 table-body hieght-div">
                  <h5>Available Demographics</h5>

                  <div class="table-container">
                    <ctfo-grid [gridData]="acquireDetailsData" [gridProp]="acquireDetailsProp"
                      (onscrollend)="onTableScrollEnd()" (rowclick)="tableRowClicked($event)"></ctfo-grid>
                    <div class="button-footer">
                      <button *ngIf="viewTypeData == 'activeuser' && acquireData.length > 0"
                        class="button-secondary mr-3 table-footer"
                        (click)="confirmChanges('acquire', panel, confirmationDialog)" [disabled]="!showAquire">
                        Acquire
                      </button>

                      <button *ngIf="((viewTypeData == 'activeuser') && (acquireData.length > 0)) && hideAcquireAllBtn"
                        class="button-primary mr-3 table-footer"
                        (click)="confirmChanges('acquireall',panel, confirmationDialog)" [disabled]="!showAquireAll">
                        Acquire All
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="viewTypeData == 'archive'">
            <div class="no-gutters page-view-scenarios archive-table">
              <div class="col-md-12 table-body">
                <ctfo-grid [gridData]="archiveDetailsData" [gridProp]="archiveDetailsProp"
                  (onscrollend)="onTableScrollEnd()"></ctfo-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="ctemp-footer custom-footer set-footer">
      <button class="footer-button button-secondary mr-3" (click)="closePanel()">Close</button>
      <button class="footer-button button-secondary mr-3" [disabled]="!showRequestAll"
        *ngIf="viewTypeData == 'activeuser' && auth.getUserDetails().app_user_group == 'gfl' && hideAcquireAllBtn"
        (click)="confirmChanges('requestalluser',panel,confirmationDialog)">
        Request All
      </button>
    </div>
  </div>
</div>
<ng-template #NotificationTable>
  <div class="accordion-container">
    <div class="custom-table">
      <div class="complete-table"></div>
    </div>
    <div class="accordion-body" [ngClass]="{
        'height-notification': viewTypeData == 'notification',
        'height-active-user': viewTypeData == 'activeuser'
      }" snInfiniteScroll [offset]="10" (scrollEnd)="accordionScrollEnd()">
      <ngb-accordion #a="ngbAccordion" [closeOthers]="true" activeIds="">
        <ngb-panel *ngFor="let panel of accordionData; let i = index" id="panel-{{ i + 1 }}">
          <ng-template ngbPanelHeader let-opened="opened">
            <div class="d-flex align-items-center justify-content-between panel-header">
              <i class="fa fa-caret-right" *ngIf="!opened" (click)="toggleAccordion(a, i + 1)"></i>
              <i class="fa fa-caret-down" *ngIf="opened" (click)="toggleAccordion(a, i + 1)"></i>
              <div class="btn btn-link text-left outer-colItem">
                <div *ngIf="viewTypeData == 'notification'" class="colItem theme" (click)="toggleAccordion(a, i + 1)"
                  title="{{ panel.poked_by | titlecase }}">
                  <div class="txt-ellps">
                    {{ panel.user_name }} <span class="user-group-class">({{ panel.user_group }}) </span>
                  </div>
                </div>

                <div *ngIf="viewTypeData == 'activeuser'" class="colItem theme" (click)="toggleAccordion(a, i + 1)"
                  title="{{ panel.user_access_id | titlecase }}">
                  <div class="txt-ellps">
                    {{ panel.user_name }} <span class="user-group-class">({{ panel.user_group }})</span>
                  </div>
                </div>
                <div class="colItem actions">
                  <img *ngIf="viewTypeData == 'notification'" class="image-style" [title]="'Accept All'" [src]="grant"
                    (click)="confirmChanges('acceptall', panel,confirmationDialog)" />
                  <img *ngIf="viewTypeData == 'notification'" class="image-style" [title]="'Reject All'" [src]="reject"
                    (click)="confirmChanges('rejectall', panel,confirmationDialog)" />
                  <img *ngIf="viewTypeData == 'activeuser'" class="image-style" [title]="'Request All'" [src]="request"
                    (click)="confirmChanges('requestall', panel,confirmationDialog)" />
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>

            <ng-container *ngIf="panel.accordionType === 'table'">
              <div class="table-container">
                <ctfo-table [tableColumns]="panel.table['tableColumns']"
                  [tableEditableColumns]="panel.table['tableEditableColumns']"
                  [tableScrollableColumns]="panel.table['tableScrollableColumns']" [tableData]="panel.table.tableData"
                  [tableIdentifier]="'accor-table-' + i" [sortOn]="panel.table.sortOn" [sortType]="panel.table.sortType"
                  [isLoading]="panel.table.isLoading" [isHeaderVisible]="panel.table.isHeaderVisible"
                  [tableStyle]="panel.table.tableStyle" [checkTextDisable]="true" (onsort)="panelTableSort(i, $event)"
                  (dataclick)="panelTableBtnClick($event, panel)" (ontablescrollend)="panelTableScrollEnd($event)"
                  [noScrollWidth]="'calc(100% - 24px)'">
                </ctfo-table>
              </div>
            </ng-container>
            <ng-container *ngIf="panel.accordionType === 'table'">
              <!-- <button *ngIf="viewTypeData == 'notification'" class="button-secondary mr-3 table-footer"
                [disabled]="!showbutton" (click)="responseActions('accepted', panel)">
                Accept
              </button> -->
              <button *ngIf="viewTypeData == 'notification'" class="button-secondary mr-3 table-footer"
                [disabled]="!showbutton" (click)="confirmChanges('accepted', panel , confirmationDialog)">
                Accept
              </button>
              <button *ngIf="viewTypeData == 'notification'" [disabled]="!showbutton"
                class="button-secondary table-footer" (click)="confirmChanges('rejected', panel, confirmationDialog)">
                Reject
              </button>
              <button *ngIf="viewTypeData == 'activeuser'" [disabled]="!showbutton"
                class="button-secondary mr-3 table-footer" (click)="confirmChanges('poked', panel, confirmationDialog)">
                Request
              </button>
            </ng-container>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</ng-template>

<ng-template let-modal #confirmationDialog>
  <app-message-popup [modal]="modal" [modalMessage]="confirmationModal.modalMessage"
    [modalTitle]="confirmationModal.modalTitle" [cancelButtonText]="confirmationModal.cancelButtonText"
    [okButtonText]="confirmationModal.okButtonText" [showCancelButton]="true" [showOkButton]="true"
    (modalClosed)="confirmationModalClosed($event)"></app-message-popup>
</ng-template>
