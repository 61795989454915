import { Injectable } from '@angular/core';
import { HttpHandlerService } from '@app/shared/services/http-handler.service';
import { CohortListPayload } from '@app/shared/models/scenario-common.model';
import { appSetting } from '@constants/endpoints';
import { Observable, forkJoin, Subject } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root',
})
export class ViewEnrollmentService {
  // DROP SITES
  dropSitesEnabled = new Subject<boolean>(); // true : enable, false : save
  addReasonTextboxOpen = new Subject<{ action: boolean; data: any }>(); // action = true : open popup, action = false : save popup data
  dropSitesSave = new Subject<{ validationPassed?: boolean; succeedeed?: boolean; data?: any }>(); // succeeded = true : save API called successfully/nothing to save
  dropSitesSaveType = new Subject<{ createType: string; tag: string }>(); // createnew: Create New Study , createnewclonescn: Create New Study with Clone Scenario
  checkUnsavedChangesOnDropSites = new Subject<{ status: boolean; destination?: string }>(); // status = true: check if unsaved changes, status = false: no unsaved changes
  checkDropSitesSaveConditions = new Subject<any>();
  detailViewTabSwitched = new Subject<string>();

  // UPDATE TRIAL
  updateTrialEnabled = new Subject<boolean>();
  updateTrialSave = new Subject<{ save: boolean; studySaveType?: string; tag?: string }>(); // true = save, false = failed
  checkUpdateTrialSaveConditions = new Subject<{ type: boolean; data?: any }>(); // true = check, false = checked already
  updateTrialNavigation = new Subject<{ type: boolean; destination?: string }>(); // true = save, false = only navigate
  saveTrialCancelled = new Subject<boolean>();

  constructor(private httpHandler: HttpHandlerService, private localStorage: SessionStorageService) {}
  getCohortList(scenarioDetail, studyDetail): Observable<any> {
    const payload = this.createCohortListPayload(scenarioDetail, studyDetail);
    return this.httpHandler.postHttpResults(appSetting.COHORT_LIST, payload);
  }

  getCohortListJoin(scenarioDetailList, studyDetail): Observable<any> {
    if (scenarioDetailList?.length === 2) {
      return forkJoin(
        this.getCohortList(scenarioDetailList[0], studyDetail),
        this.getCohortList(scenarioDetailList[1], studyDetail)
      );
    } else {
      return forkJoin(
        this.getCohortList(scenarioDetailList[0], studyDetail),
        this.getCohortList(scenarioDetailList[1], studyDetail),
        this.getCohortList(scenarioDetailList[2], studyDetail)
      );
    }
  }

  private createCohortListPayload(scenarioDetail, studyDetail): any {
    const payload = CohortListPayload;
    const userDetails = this.localStorage.get('user-details');
    payload.input_request.scenario_created_by = scenarioDetail.scenario_created_by;
    payload.input_request.scenario_id = scenarioDetail.scenario_id;
    payload.input_request.scenario_version = scenarioDetail.old_scenario_version
      ? scenarioDetail.old_scenario_version
      : scenarioDetail.new_scenario_version;
    payload.input_request.study_created_by = studyDetail.study_created_by;
    payload.input_request.study_id = studyDetail.study_id;
    payload.input_request.theme_id = studyDetail.theme_id;
    payload.input_request.user_id = userDetails.user_id;
    payload.input_request.is_cohort = studyDetail.is_cohort;
    payload.input_request.category = studyDetail.study_feasibility;
    // payload.input_request.geography_list_flag = true;
    payload.input_request.country_list_flag = true;
    return payload;
  }
}
