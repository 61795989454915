import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { appSetting } from './../../constants';
import { localNames } from './../../constants/storage';
import { AppStateService } from './../../app-state.service';
import { LoaderService } from './../../loader.service';
import { openCloseAccordionAnimation, openCloseSidebarAnimation } from './../../animations';
import { Utils } from './../../utils';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'ctfo-grid-column-customizer',
  templateUrl: './grid-column-customizer.component.html',
  styleUrls: ['./grid-column-customizer.component.scss'],
  animations: [openCloseAccordionAnimation, openCloseSidebarAnimation],
})
export class GridColumnCustomizerComponent implements OnInit, OnChanges {
  public original_array: any;
  public tables;
  public gridColumnCustomizerToggle = true;

  tempArr: any = [];
  updated_scrollWidth: any = 0;
  final_scrollWidth: any;
  scrolltable: any;
  defaultHeaderColumn = [];
  @Input() tableIdentifier: string;
  @Output() emitCustomizeColumn: EventEmitter<any> = new EventEmitter();
  @Output() emitLeftRightScroll: EventEmitter<any> = new EventEmitter();
  @Output() emitError: EventEmitter<any> = new EventEmitter();

  errorMessageConfiguration: any;
  custHeading = 'Customize Columns';
  columnUpdated = false;
  columns = [];
  showLoader = false;

  @Input() set tableColumns(resps: any) {
    if (resps && resps.length) {
      this.columns = resps;
    }
  }

  constructor(
    private appState: AppStateService,
    private router: Router,
    public auth: AuthService,
    private http: HttpClient,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.close();
    AppStateService.appstate.subscribe(val => {
      if (val['gridColumnCustomizerToggle'] === true || val['gridColumnCustomizerToggle'] === false) {
        this.gridColumnCustomizerToggle = val['gridColumnCustomizerToggle'];
      }
    });

    this.defaultHeaderColumn = this.columns.map((item, index) => ({ order: index, ...item }));
    const oldHeaderColumns = this.defaultHeaderColumn.map(({ isdisplayed, ...rest }) => rest);
    this.displayCustomColumns(oldHeaderColumns);
  }

  ngOnChanges(): void {
    if (this.tableIdentifier) {
      AppStateService.appstate.subscribe(val => {
        if (val['tables']) {
          this.tables = val['tables'];
          if (val['tables'][this.tableIdentifier]) {
          }
        }
      });
    }
  }

  close(): void {
    this.appState.setGlobalState('gridColumnCustomizerToggle', false);
  }

  changeCustomizeColumn(event, columndatakey, index): void {
    this.scrolltable = $('.scroll-table-header:visible')[0];
    this.final_scrollWidth = this.scrolltable.scrollWidth;
    this.tempArr = Utils.assignObject(this.columns);

    if (event.target.checked) {
      this.updated_scrollWidth = this.updated_scrollWidth + 100;
      this.tempArr[index].isdisplayed = true;
    } else {
      this.updated_scrollWidth = this.updated_scrollWidth - 100;
      this.tempArr[index].isdisplayed = false;
    }

    this.final_scrollWidth = this.final_scrollWidth + this.updated_scrollWidth;
    this.columns = Utils.assignObject(this.tempArr);
    this.columnUpdated = true;
  }

  displayCustomColumns(headercolumns): any {
    const screen = this.getPayloadValue();
    this.showLoader = true;
    const postObj: any = {
      theme_id: this.getThemeScenarioSignature().theme_id,
      scenario_id: this.getThemeScenarioSignature().scenario_id,
      user_id: this.auth.getUserDetails().user_id,
      active_screen: this.getPayloadValue(),
      action: 'view',
      column_mapping_json: { column_array: [] },
    };

    this.http.post(appSetting.UPDATECOLUMN, postObj).subscribe(resp => {
      if (Utils.isSuccess(resp) && Utils.isArrayExists(resp['result'].column_array)) {
        const respcol = resp['result'].column_array;
        const head = [...headercolumns];

        const hc = respcol.reduce((acc, el) => {
          const index = head.findIndex(e => e.columndatakey === el.columnname);
          let o;
          if (index > -1) {
            const {
              columndatakey,
              columnwidth,
              style,
              columntype,
              columntitle,
              validationItems,
              actionicons,
              dropdownitems,
            } = head[index];
            const { isdisplayed } = el;
            o = {
              columndatakey,
              columnwidth,
              style,
              columntype,
              columntitle,
              isdisplayed,
              validationItems,
              actionicons,
              dropdownitems,
            };
          } else {
            o = { ...head[index] };
          }
          acc.push(o);
          return acc;
        }, []);

        this.emitCustomizeColumn.emit([...hc]);

        this.columns = Utils.assignObject(headercolumns);
        this.showLoader = false;
        this.close();
      } else {
        this.showLoader = false;
        this.emitCustomizeColumn.emit(this.defaultHeaderColumn);
        this.columns = Utils.assignObject(this.defaultHeaderColumn);
        this.close();
      }
    }),
      (error: any): any => {
        this.showLoader = false;
        this.columns = Utils.assignObject(this.defaultHeaderColumn);
        this.emitCustomizeColumn.emit(this.defaultHeaderColumn);
        this.close();
      };
  }

  saveCustomizeColumn(): void {
    this.showLoader = true;
    const newArray = this.columns.map(item => ({ ...item }));
    const updatedColumns = newArray.map(({ columndatakey, isdisplayed }, index) => ({
      order: index,
      columnname: columndatakey,
      isdisplayed,
    }));
    const oldHeaderColumns = this.columns.map(({ isdisplayed, ...rest }) => rest);

    this.appState.setGlobalState('editColumnToggle', false);
    const postObj: any = {
      theme_id: this.getThemeScenarioSignature().theme_id,
      scenario_id: this.getThemeScenarioSignature().scenario_id,
      user_id: this.auth.getUserDetails().user_id,
      active_screen: this.getPayloadValue(),
      action: 'save',
      column_mapping_json: { column_array: updatedColumns },
    };
    this.http.post(appSetting.UPDATECOLUMN, postObj).subscribe(resp => {
      if (Utils.isSuccess(resp)) {
        this.showLoader = false;
        this.appState.setGlobalState('editColumnToggle', false);
        this.displayCustomColumns(oldHeaderColumns);
      } else {
        this.showLoader = false;
        this.appState.setGlobalState('editColumnToggle', false);
        this.emitCustomizeColumn.emit(this.defaultHeaderColumn);
        this.columns = Utils.assignObject(this.defaultHeaderColumn);
      }
    }),
      (error: any): any => {
        this.showLoader = false;
        this.appState.setGlobalState('editColumnToggle', false);
        this.columns = Utils.assignObject(this.defaultHeaderColumn);
        this.emitCustomizeColumn.emit(this.defaultHeaderColumn);
      };
  }

  getPayloadValue(): any {
    const parts = this.router.url.split('/');
    const lastRoute = parts[parts.length - 1];
    const lastRouteParent = parts[parts.length - 2];

    switch (lastRoute) {
      case payLoadScreen.BENCHMARK_SUMMARY: {
        return 'benchmark_metrics';
      }
    }
  }

  saveClicked(): any {
    this.saveCustomizeColumn();
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    const ESCAPE_KEYCODE = 27;
    if (event.keyCode === ESCAPE_KEYCODE) {
      this.close();
    }
  }

  getThemeScenarioSignature(): any {
    let theme_id = '';
    let scenario_id = '';
    const data = Utils.getSessionStorage(localNames.SCENARIOINFO);
    if (Utils.isValueExists(data) && Utils.isValueExists(data.theme_id)) {
      theme_id = data.theme_id;
    }

    if (Utils.isValueExists(data) && Utils.isValueExists(data.scenario_id)) {
      scenario_id = data.scenario_id;
    }

    return {
      scenario_id: scenario_id,
      theme_id: theme_id,
    };
  }

  getState(editToggle) {
    if (editToggle) {
      return 'open';
    } else {
      return 'closed';
    }
  }
}

enum payLoadScreen {
  BENCHMARK_SUMMARY = 'benchmarks',
}
