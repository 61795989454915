export interface InvestigatorDetails {
  ctfo_inv_email: string;
  ctfo_inv_email_secondary: string;
  ctfo_inv_id: string;
  ctfo_inv_name: string;
  ctfo_inv_phone: string;
  ctfo_site_id: string;
  send_survey_inv?: boolean;
  survey_sent_date?: string;
  salutation?: string;
}

export interface GetInvestigatorDetailsAPIResponse {
  status: string;
  result: {
    theme_id: string;
    scenario_id: string;
    scenario_name: string;
    investigator_details: InvestigatorDetails[];
  };
}

export interface SaveInvestigatorDetailsAPIPayload {
  user_id: string;
  scenario_id: string;
  scenario_version: string;
  theme_id: string;
  survey_id: string;
  investigator_details: {
    country_name: string;
    ctfo_site_id: string;
    previous_ctfo_inv_id: string;
    new_ctfo_inv_id: string;
    ctfo_inv_name: string;
    ctfo_inv_email: string;
    ctfo_inv_email_secondary: string;
    ctfo_inv_phone: string;
    is_manual: boolean;
  };
}

export enum DropdownOption {
  ALLYES = 'Select All - Include',
  ALLNO = 'Select All - Exlude',
  ALLMAYBE = 'Select All - Maybe',
  HYPHEN = '-',
}

export enum SurveyStatus {
  GO_LIVE = 'go_live',
  INITIATED = 'initiated',
}

export enum SelectAll {
  TRUE = 'true',
  FALSE = 'false',
  MAYBE = 'Maybe',
}
