// this object maps the key-word used for study feasibility throughout the application
export const STUDY_FEASIBILITY = {
  PRE: 'pre',
  POST: 'post',
};

const EXIT_SCENARIO =
  'All progress will be lost if you exit. ' + 'Are you sure you would like to Exit Scenario creation?';
const LOSE_UNSAVED_CHANGES = 'You may lose the unsaved changes. Are you sure you want to continue?';
const LOSE_UNSAVED_FORECAST = 'You may lose the enrollment forecast results. Are you sure you want to continue?';

export const API_SUCCESS = 'SUCCEEDED';
export const API_FAILED = 'FAILED';
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
export const INCORRECT_DATA = 'Incorrect data found. Please try again later.';
export const NO_SCENARIO_DATA = 'The scenario does not have any country(s) saved in it.';

export const FILE_COULD_NOT_BE_DOWNLOADED = 'File could not be downloaded .';

export const LOGIN_PAGE = {
  invalid_credentials: 'Incorrect Username or Password.',
  fields_empty: 'Username and Password cannot be blank.',
  username_empty: 'Username is required.',
  password_empty: 'Password is required.',
};

export const UPLOAD_FILE = {
  replace: 'The previously uploaded file will get replaced. Are you sure you want to continue ?',
  file_type: 'Please upload a .csv file.',
  file_type_xlsx: 'Please upload a .xlsx file.',
  number_of_files: 'Please select a single file to upload.',
  title: 'Upload File',
  could_not_upload_file: 'Unable to upload the selected file.',
  could_not_save_file: 'Could not save uploaded file.',
  delete_title: 'Delete File',
  delete_confirmation_message: 'Are you sure you want to delete the file?',
  validation_complete: 'Your file validation has been completed. Please view the validation status.',
  file_validation_failed: 'Your file could not be uploaded due to errors. Please upload again.',
  invalid_excel: 'error in excel validation process..',
  invalid_excel_message: 'Please upload a valid file.',
  upload_again: 'Please upload a file again.',
  unsaved_changes: 'Uploaded file will be lost. Are you sure you want to continue?',
  tooltips: {
    missingDataCheck: 'Ensures all required data is filled and there are no missing entries.',
    dataFormatCheck: 'Ensures all data is in the desired format – Numbers, Dates, Text, etc.',
    dataConsistencyCheck: 'Ensures all data entries are consistent with the business rules.',
    referenceId: 'Reference Id',
  },
};

export const SITE_LEVEL_STUDY_DATA_LISTING_POPUP = {
  no_data_available: 'No data available',
  select_studies: 'Please select one study on which you want to create modeling and predictions.',
  cancel_upload_confirmation:
    'Study Data will be lost if you Cancel Upload. Are you sure you would like to cancel upload?',
  something_went_wrong_model_run: 'Something went wrong while creating the model. Please try again later.',
};

export const CONNECT_TO_CTFO = {
  no_data_available: 'No data available. Please create scenarios in CTFO to begin creating Modeling and Predictions.',
  no_search_results: 'No search results are found.',
  study_id_key_missing: 'study_id_details_list is empty or study_id_details_list keys is missing',
  study_id_key_missing_message:
    'All sites have zero randomization rate or historical no site activation dates available.' +
    'Thus, data cannot be loaded to CTEMP.',
};

export const HISTORICAL_SUMMARY = {
  mapViewTitle: 'Site Count and Enrollment Rate by Country',
  mapViews: {
    no_of_sites: 'Site Count',
    median_randomization_rate: 'Median Randomization / Enrollment Rate',
  },
  mapLegendHeaders: {
    no_of_sites: 'Number of Sites',
    median_randomization_rate: 'Enrollment Rate',
  },
  toolTips: {
    view: 'Select the data to view on the map.',
    view_data: 'View historical or actual enrolling data.',
  },
};

export const ADJUST_RANDOMIZATION_RATE = {
  saveButtonText: 'Adjust Randomization/Enrollment Rate',
  modalMessage: {
    save:
      'Altering the randomization/enrollment rate will delete all the scenarios for this study. ' +
      'Are you sure you want to continue?',
    cancel: LOSE_UNSAVED_CHANGES,
  },
  replaceWith: 'Replaces the median randomization/enrollment rates with the provided value.',
  adjustFactor: 'Adjusts the median randomization/enrollment rates by the positive/negative % provided.',
  replace_with_error: 'Randomization/Enrollment Rate should be between 0.01 to 30.',
  adjust_factor_error: 'Adjustment factor percentage should be between -90 to 200.',
};

export const MODELLING_AND_PREDICTIONS = {
  widgets: [
    {
      key: 'all',
      title: 'Total Studies',
      icon: 'total.svg',
    },
    {
      key: 'pre',
      title: 'Pre-Feasibility',
      icon: 'pre-fs.svg',
    },
    {
      key: 'post',
      title: 'Post-Feasibility',
      icon: 'post-fs.svg',
    },
    {
      key: 'tracked_monitored',
      title: 'Tracked & Monitored',
      icon: 'track-monitor.svg',
    },
  ],
  unable_to_fetch_data: 'We are unable to fetch the data. Please try again.',
  compare_scenarios_title: 'Scenarios for comparison',
  compare_scenarios_body:
    'You have already selected more than 3 scenarios. Please deselect one to continue with scenario comparison.',
  delete_scenario_title: 'Delete Scenario',
  delete_scenario_body: 'Are you sure you want to permanently delete the ',
  delete_title: 'Delete Study',
  delete_confirmation_message: 'Are you sure you want to permanently delete the study & the scenarios in it?',
  unable_to_delete_study: 'We are unable to delete the study. Please try again.',
};

export const INPUT_MODEL_PARAMETERS = {
  modeling_levels: ['Global', 'Region', 'Country'],
  modeling_types: ['Competitive Target', 'Demographic Specific Target'],
  // distribution_types
  distribution_types: ['Gamma', 'Half-Normal', 'Triangular'],
  // country list
  country_list: ['india', 'australia', 'USA', 'saudi'],
  special_chars_in_scenario_name:
    'Error in the Scenario Name. Please use alphanumeric characters such as A–Z, a–z, 0–9, _ only.',
  duplicate_scenario_name: 'Error in the Scenario Name. Please use a unique scenario name.',
  incorrect_scenario_name_length: 'The Scenario Name cannot be more than 150 characters.',
  value_out_of_bound: 'Please enter a value between 0 and 40 only.',
  confidence_interval_value_out_of_bound: 'Please enter a value in increment of 5 between 5 - 95 only.',
  target_completion_prob: 'Please enter a value more than 0 and less than 100.',

  unique_scenario_name: 'Error in Scenario Name. Please use a unique scenario name.',
  toolTips: {
    threshold_empty_sites: 'Estimated % of Sites which are expected to be empty.',
    confidence_interval:
      'Enter the probability of Enrollment predictions falling within the range of predicted results.',
    historical_trial_median_screening_failure:
      'Median of Screening Failure Rate that has been reported across historical trials.',
    historical_trial_median_patient_drop_out:
      'Median of Patient Drop Out Rate that has been reported across historical trials.',
    scenario_name:
      'Enter a unique name for Enrollment modeling scenario.' +
      'The field can only contain alpha-numeric characters – a-z, A-Z, 0-9 and underscores (_).',
    scenario_name_refresh:
      'Enter a unique name for Enrollment scenario.' +
      'The field can only contain alpha-numeric characters – a-z, A-Z, 0-9 and underscores (_).',
    modeling_level: 'Select the level for Enrollment modeling and prediction.',
    modeling_type: 'Select the Enrollment modeling target prediction type. Select ‘Competitive Target’ to let model continue to enroll in a demographic even after demographic specified target is met and ‘Demographic Specific’ to let the model stop enrolling when demographic specified target is met',
    param_est_tech: 'Select the technique which shall be used to estimate the Enrollment prediction Parameters ',
    expected_site_activation_failure:
      'Percentage of total planned sites that are expected to not get activated ' + 'by end of Enrollment for study.',
    expected_empty_sites:
      'Percentage of activated sites that are expected to have zero Enrollment rate by end of ' +
      'Enrollment duration.',
    enrollment_rate_reduction_screening_failure:
      'Percentage of total patients expected to fail the Screening ' +
      'Procedures during the Enrollment duration. This is an additional reduction on the Randomization / Enrollment rate ' +
      'from what has been reported through historical data.',
    enrollment_rate_reduction_patient_drop_out:
      'Percentage of enrolled patients expected to drop out during Enrollment ' +
      'duration. This is an additional reduction on the Randomization/Enrollment rate from what has been reported ' +
      'through historical data.',
    target_patients_for_country: 'Enter the target # of patients to enroll for each country in the study.',
    total_countries: 'Total number of countries selected for Enrollment modeling.',
    global_target_patients: 'Global target # of patients to enroll for the study.',
    total_historical_sites: 'Total number of sites in the selected countries to be used for Enrollment modeling.',
    expected_enrollment: 'Enter the chances of expected Enrollment to be completed. Shall be entered in % values',
    actual_enrolled_patient_count:
      'Total number of patients currently enrolled in an ongoing study by the sites ' + 'which are already activated.',
    cohort_status:
      'Select the status of the cohort. Below status should be based on the patient enrolled for the Cohort.',
    cohortTypeInfo: `Single-entry indicates that indication(s) are the same across cohorts. Multi-entry indicates that indication(s) can be different across cohorts`,
  },
};

export const COUNTRY_SELECTION_PATIENT_COUNT = {
  cohort_status: ['in-progress', 'completed'],
  selections_country_error:
    'Countries with zero standard deviation in the Randomization / Enrollment Rates across sites or ' +
    'less than 2 sites cannot be selected. Please deselect country and/or adjust the randomization rates for: ',
  selections_region_error:
    'Regions with zero standard deviation in the Randomization / Enrollment Rates across sites or ' +
    'less than 2 sites cannot be selected. Please deselect region and/or adjust the randomization rates for: ',
  selections_global_error:
    'The Study has zero standard deviation in the Randomization / Enrollment Rates across sites or ' +
    'less than 2 sites. Please adjust randomization rates or add more sites.',
  actual_patient_count_error:
    'Countries with Actual Enrolled Patient Count greater than 0 cannot be deselected. ' + 'Please select countries: ',
  target_no_patients: 'Please enter Target # of Patients between 1 - 3000 only.',
  country_mean_expected:
    'Expected Randomization/ Enrollment Rate Standard Deviation Across Sites & Expected Mean Randomization Enrollment Rate should be between 0.01 - 30. Please deselect countries or change “Expected“ values.',
  region_mean_expected:
    'Expected Randomization/ Enrollment Rate Standard Deviation Across Sites & Expected Mean Randomization Enrollment Rate should be between 0.01 - 30. Please deselect regions or change "Expected" values.',
  global_mean_expected:
    'Expected Randomization/ Enrollment Rate Standard Deviation Across Sites & Expected Mean Randomization Enrollment Rate should be between 0.01 - 30. Please change “Expected“ values globally.',
  country_deviation_expected:
    'Expected Randomization/ Enrollment Rate Standard Deviation Across Sites & Expected Mean Randomization Enrollment Rate should be between 0.01 - 30. Please deselect countries or change “Expected“ values.',
  region_deviation_expected:
    'Expected Randomization/ Enrollment Rate Standard Deviation Across Sites & Expected Mean Randomization Enrollment Rate should be between 0.01 - 30. Please deselect regions or change "Expected" values.',
  global_deviation_expected:
    'Expected Randomization/ Enrollment Rate Standard Deviation Across Sites & Expected Mean Randomization Enrollment Rate should be between 0.01 - 30. Please change “Expected“ values globally.',
  toolTips: {
    target_no_patients: 'Enter the Target # of Patients to enroll for the study.',
    actual_enrolled_patient: 'The number of Patients enrolled.',
    historical_no_sites: 'Number of Sites falling under respective Demographics.',
    mean_randomization: 'Median value of the Randomization Enrollment Rate for respective Demographics.',
    standard_deviation:
      'The amount of variation or dispersion of a set of values of Randomization / Enrollment Rate across sites.',
    target_number_of_patients_for_country: 'Enter the target # of patients to enroll for each country in the study.',
    total_countries: 'Total number of countries selected for Enrollment modeling.',
    global_target_number_of_patients: 'Global target # of patients to enroll for the study.',
    total_historical_number_of_sites:
      'Total number of sites in the selected countries to be used for Enrollment modeling.',
    actual_enrolled_patient_count:
      'Total number of patients currently enrolled in an ongoing study by the sites ' + 'which are already activated.',
    target_number_of_patients_for_ongoing: `User input for Target # of Patients should be greater
    than Actual Enrolled Patient Count. Please enter valid inputs`,
    target_number_of_patients_for_ongoing_country: `User input for Target # of Patients for Country should be greater
    than Actual Enrolled Patient Count. Please enter valid inputs`,
    target_number_of_patients_for_ongoing_region: `User input for Target # of Patients for Region should be greater
    than Actual Enrolled Patient Count. Please enter valid inputs`,

    edit_distribution: {
      type_of_ditribution: 'Select the Type of distribution to be used for Enrollment Prediction results generation.',
      country_region_dd: 'Select the particular Demographic to view the corresponding distribution.',
      country_region_chart: 'Density vs Site Randomization Enrollment Rate (PSM) plot of the distribution.',
      global_chart:
        'Density vs Site Randomization Enrollment Rate (PSM) plot of the distribution (Aggregated at Global level)',
      distribution: 'Type of Distribution',
      chi_sqr:
        'Chi Square value measures the extent of fit of the distribution to actual observed data. This value is inversely related to goodness of fit.',
      p_value: 'Level of statistical significance. Lesser the value more is the statistical significance.',
    },
  },
};

export const TRACKING_AND_MONITOR = {
  popup: {
    title: 'Monitoring & Tracking',
    message: 'All unsaved results will be lost. Are you sure you want to continue?',
  },
  no_of_months: 'Please enter months(s) between 1-100 only.',
};

export const FORECAST_VALIDATION_POPUP = {
  popup: {
    title: 'Additional Demographics Detected',
    message:
      'Refresh forecasting failed with available data, please update the target patients for additional geographies & geographies with target # patient less than Actual Enrollment',
  },
};

export const SITE_ACTIVATION_STRATEGY = {
  enter_inputs_to_run_simulation:
    'Enter inputs above and run simulation to see the simulated ' + 'site activation chart.',
  complete_site_act_by_error: 'Complete Site Activation By duration should be less than enrollment duration.',
  complete_site_act_by_error_ongoing:
    'Complete Site Activation By duration should be' + ' greater than currently elapsed site activation duration.',
  enter_a_valid_number: 'Please enter a valid number.',
  scale_shape_error: 'fieldValue should be greater than 0',
  incorrect_max_enrollment_duration:
    'Max Enrollment Duration should be greater than or equal to Site Activation Duration + 12 months.',
  toolTips: {
    // input fields
    study_start_date:
      'Specify the start date of the study – this is the date the ' +
      'approval for the study was obtained, i.e., date before first site activation and Enrollment start.',
    expected_enrollment_completion_date:
      'Date by which we expect the study to complete Enrollment' + ' with respect to Study Start Date.',
    prediction_start_date:
      'If Ongoing study, specify the date from which remaining patient' +
      ' Enrollment forecast or site activations will start',
    duration: 'Months between Study Start Date and Expected Enrollment Completion Date.',
    complete_site_activation_by:
      'Specify the number of months from Study Start Date in' + ' which we intend to activate all sites.',
    scale_parameter: 'Specify value for Scale Parameter to adjust the pace for Simulated Site Activation Curve.',
    shape_parameter: 'Specify value for Shape Parameter for to adjust the shape Simulated Site Activation Curve.',
    max_enrollment_duration:
      'Specify the maximum duration to forecast Enrollment for study. Minimum value: Duration + 12 months',
    select_countries: 'Filter countries for view using the dropdown.',
    // graphs
    simulated_site_activation: 'Country wise distribution of globally simulated site activation trend.',
    historical_site_activation: 'Historical mean site activation trend across countries.',
    time_quintiles: 'Legend for each time quintile between study start date and last site activation date.',
    simulated_activation_curve: 'Globally simulated site activation trend based on user inputs.',
    historical_activation_curve: 'Mean site activation trend across historical trials.',
  },
};

export const SIMULATION_INPUT_PARAMS = {
  validation: {
    iterations: 'Please enter a value between 100 - 1000 only.',
    target_completion_prob: 'Please enter a value more than 0 and less than 100.',
    confidence_interval: 'Please enter a value in increment of 5 between 5 - 95 only.',
    total_no_patient: 'Target # of Patient & Total Patient to be Enrolled entered should be equal for',
    min_max_range: 'Min PSM Mean & Max PSM Mean should fall under the range mentioned in the cohort configurator of.',
  },
};

export const COMMON_TOOLTIP = {
  select_countries: 'Filter countries for view using the dropdown.',
};

export const LOCK_SCENARIO = {
  lock_scenario_tooltip: 'Locking the scenario would revoke edit access of the users until unlocked.',
};

export const VIEW_ENROLLMENT_PREDICTIONS = {
  chart_variables: {
    month: {
      max: 24,
      visible_interval: 4,
    },
    year: {
      max: 2,
      visible_interval: 4,
    },
    half_year_ms: 15552000000,
  },
  toolTips: {
    [STUDY_FEASIBILITY.POST]: {
      geographyLevel:
        'Select the Geography Level to view its Enrollment forecasts and target completion probabilities. ' +
        'Values in this dropdown depend on the Modeling Level selected by the user in Input Model Parameters.',
      region: 'Select the Region to view its Enrollment forecast and target completion probability',
      country: 'Select the Country to view its Enrollment forecast and target completion probability.',
      timePeriod: 'Toggle X axis values between Month or Year time grain.',
      dateActive: 'Replace X axis with date values with respect to study start date.',
      enrollmentTimeChart: 'Predicted patient Enrollment with mean, upper and lower confidence across time.',
      probabilityTimeChart: 'Provides the probability of completing the defined patient Enrollment target across time.',
      viewSite: 'Select the Site to view its Enrollment forecast.',
      viewCountry: 'Select the Country to view its Enrollment forecast.',
      viewRegion: 'Select the Region to view its Enrollment forecast.',
    },
    [STUDY_FEASIBILITY.PRE]: {
      geographyLevel:
        'Select the Geography Level to view its Enrollment forecasts and target completion probabilities. ' +
        'Values in this dropdown depend on the Modeling Level selected by the user in Modeling Inputs.',
      region: 'Select the Region to view its Enrollment forecast and target completion probability',
      country: 'Select the Country to view its Enrollment forecast and target completion probability.',
      timePeriod: 'Toggle X axis values between Month or Year time grain.',
      dateActive: 'Replace X axis with date values with respect to study start date.',
      enrollmentTimeChart: 'Predicted Patient Enrollment with mean, upper and lower confidence across time.',
      probabilityTimeChart: 'Provides the probability of completing the defined patient Enrollment target across time.',
      distributionTime:
        'Histogram plot of Target completion time. ' +
        'Y axis shows frequency vs X axis which has completion time (in Months).',
      siteRandomization:
        'Line chart of Site Randomization Enrollment rate. Y axis shows Site frequency vs X axis which ' +
        'has Enrollment rate. Shows both simulated distributions for each iteration & averaged out distribution curve.',
    },
  },
};

export const EDIT_FUNCTIONALITY_POPUP_VALUES = {
  title: 'Update Changes',
  message: 'Are you sure you want to update the changes?',
  ok_button_text: 'Ok',
  cancel_button_text: 'Cancel',
};

const PREV_MODAL_CONFIG = {
  title: 'Exit Scenario',
  message: LOSE_UNSAVED_CHANGES,
  ok_button_text: 'Ok',
  cancel_button_text: 'Cancel',
};

const PREV_VEP_MODAL_CONFIG = {
  title: 'Exit Scenario',
  message: LOSE_UNSAVED_FORECAST,
  ok_button_text: 'Ok',
  cancel_button_text: 'Cancel',
};

const CANCEL_MODAL_CONFIG = {
  title: 'Exit Scenario',
  message: EXIT_SCENARIO,
  ok_button_text: 'Exit Scenario',
  cancel_button_text: 'Close & Continue Editing Scenario',
};

export const EXIT_SCENARIO_POPUP_VALUES = {
  'modeling-inputs': {
    new: { ...CANCEL_MODAL_CONFIG },
    edit: { ...PREV_MODAL_CONFIG },
    view: { ...PREV_MODAL_CONFIG },
  },
  simulation_input_params: {
    new: { ...PREV_MODAL_CONFIG },
    edit: { ...PREV_MODAL_CONFIG },
    view: { ...PREV_MODAL_CONFIG },
  },
  simulation_activation: {
    new: { ...PREV_MODAL_CONFIG },
    edit: { ...PREV_MODAL_CONFIG },
    view: { ...PREV_MODAL_CONFIG },
  },
  enrollment_prediction: {
    new: { ...PREV_VEP_MODAL_CONFIG },
    edit: { ...PREV_VEP_MODAL_CONFIG },
    view: { ...PREV_VEP_MODAL_CONFIG },
  },

  // Remove this as it is similar to modeling-inputs
  imp: {
    title: 'Exit Scenario',
    new: {
      message: EXIT_SCENARIO,
      ok_button_text: 'Exit Scenario',
      cancel_button_text: 'Close & Continue Editing Scenario',
    },
    edit: {
      message: LOSE_UNSAVED_CHANGES,
      ok_button_text: 'Ok',
      cancel_button_text: 'Cancel',
    },
    view: {
      message: LOSE_UNSAVED_CHANGES,
      ok_button_text: 'Ok',
      cancel_button_text: 'Cancel',
    },
  },
  cspc: {
    title: 'Exit Scenario',
    message: LOSE_UNSAVED_CHANGES,
    ok_button_text: 'Ok',
    cancel_button_text: 'Cancel',
  },
  vep: {
    title: 'Exit Scenario',
    message: LOSE_UNSAVED_FORECAST,
    ok_button_text: 'Ok',
    cancel_button_text: 'Cancel',
  },
};

export const EXPAND_CHART_POPUP_TITLE = {
  historical: 'Historical Site Activation',
  simulated: 'Simulated Site Activation',
};

export const DOWNLOAD_SCENARIO = {
  modalTitle: 'Download Successful',
  modalMessage: 'Your download is complete.',
};

export const DROP_SITES_VALIDATION_FAIL = {
  modalTitle: 'Add Reason',
  modalMessage: 'Please add a reason for dropping out of the selected site.',
  showOkButton: true,
  showCancelButton: false,
  okButtonText: 'OK',
  cancelButtonText: '',
};

export const ADD_STUDY_POPUP = {
  studyType: 'Select the Study Type based on the data availability to create the Enrollment plans.',
};

export const PRE_FEASIBILITY_STUDY = {
  okButton: 'Exit Study',
  modalTitle: 'Exit study',
  special_chars_in_study_name: 'Study Name should not have any special characters - $,%,#,@,!',
  special_chars_in_protocol_id: 'Reference Protocol / NCT ID should not have any special characters - $,%,#,@,!',
  duplicate_study_name: 'Error in the study Name. Please use a unique study name.',
  incorrect_study_name_length: 'The study Name cannot be more than 50 characters.',
  modalMessage: 'All progress will be lost if you exit. Are you sure you would like to Exit Study creation?',
  study_type: 'Select the Study Type based on the data availability to create the enrollment plans.',
  no_of_cohorts: 'Please enter cohorts between 2-20.',

  tooltip: {
    reference:
      'Enter NCT ID.The unique identification code given to each clinical study upon registration at ' +
      'ClinicalTrials.gov. The format is "NCT" followed by an 8-digit number',
    therapeuticArea: 'Enter the Therapeutic Area for the Trial',
    indication: 'Enter the Indication/Disease for the Trial',
    phase: 'Enter the Phase for the Trial',
    studyName:
      'Enter the study name. The field can only contain alpha-numeric characters – a-z, A-Z, 0-9 and underscores (_).',
    description: 'Description',
    study_start_date:
      'Specify the start date of the study – this is the date the ' +
      'approval for the study was obtained, i.e., date before first site activation and Enrollment start.',
    no_of_cohorts: 'Please enter cohorts between 2-20.',
    study_code: '',
  },
};

export const POST_FEASIBILITY_STUDY = {
  no_of_cohorts: 'Please enter cohorts between 2-20.',
};

export const SIMULATION_INPUT_PARAMETER = {
  okButton: 'Ok',
  cancelButtonText: 'Cancel',
  modalTitle: 'Exit study',
  modalMessage: 'Are you sure you want to permanently delete selected row from the scenario?',
  cohortModalMessage: 'Are you sure you want to permanently delete the selected row from the scenario?',
  selectionMessage:
    'Please note that performed operation will be valid for current cohort. Click OK to apply the modification',
  target_no_patients_val: 'Please enter Target # of Patients between 1 - 3000 only.',
  target_no_sites_val:
    'Countries with sites less than 2 and more than 3000 cannot be selected. Please modify your input.',
  max_psm_error: 'Max PSM  should be greater than "0" &  "Min PSM ".',
  min_psm_error: 'Min PSM  should be greater than "0" & less than "Max PSM ".',
  mode_psm_val: 'Mode for PSM values should be in between "Min PSM " & "Max PSM " for: ',
  var_psm_error: 'Variance for PSM values should be greater than "0".',
  target_number: `Input for Target # of Patients & Target # of Sites should be greater
    than or equal to Actual Enrolled Patients Count & Sites Count. Please enter valid inputs`,
  blankCountry: 'Demographic(s) with site count as 0 cannot be selected for the enrollment plan: ',
  target_number_of_patients: `User input for Target # of patients should be greater
    than Actual Enrolled Patients Count. Please enter valid inputs`,
  target_number_of_sites: `User input for Target # of Sites should be greater
    than Actual Enrolled Sites Count. Please enter valid inputs`,
  actual_country: 'with Actual Enrolled Patient Count greater than 0 cannot be deselected.',
  tooltip: {
    iteration:
      'The number of repetitions of a process in order to generate the simulation. ' +
      'The sequence will approach some end point or end value. Each repetition of the process is a single iteration.',
    confidence_interval:
      'Enter the confidence interval (%). Confidence interval is an estimated range of values' +
      ' which is likely to include the Predicted Patient Enrollment over time, the estimated range being calculated from the simulation.',
    expected_enrollment: 'Enter the chances of expected Enrollment to be completed. Shall be entered in % values',
    target_no_patients: 'Enter the target # of patients to enroll for a trial based on modelling level.',
    target_no_sites: 'Enter the target # of sites for the trial based on modeling level',
    target_no_trials: 'It indicates the total number of benchmark trials for which the Performance data is available (x) out of the total number of benchmark trials (y) at given geography (x/y). Benchmark trials with performance data are used to calculate the min, max and mode/variance. Please note: Total trial count is distinct at global level.',
 // target_no_trials: 'It indicates the number of trials used at given geography to calculate the min, max and mode/variance for PSM. Please note: Total trial count is distinct at global level.',
    min_psm: 'Enter the Minimum value of Patient per site per month',
    max_psm: 'Enter the Maximum value of Patient per site per month',
    var_psm: 'Enter the variability from the mean value of patient per site per month',
    mode_psm: 'Value of Patient per site per month which has highest frequency of repetition',
  },
};

export const CUSTOMIZE_ACTIVATION_CURVE_ERROR_MESSAGES = {
  badOrder:
    'Please enter "% Site Activation Duration" & "% Site Activated" value greater than previous value & less than next value' +
    ' in the column.',
  duplicateTrialDuration: 'Entered "% Site Activation Duration" already exists.',
  sitesActivatedOutOfRange: 'Please enter "% Site Activated" value between 0 - 100 only.',
  trialDurationOutOfRange: 'Please enter "% Site Activation Duration" value between 1 - 99 only.',
};

// export const ENROLLMENT_MODEL_RUNNING = 'enrollment_model_running';
// export const ENROLLMENT_PREDICTION = 'enrollment_prediction';

export const UNSAVED_CHANGES_POPUP = {
  modalTitle: 'Save Changes',
  modalMessage: 'You may lose the unsaved changes. Are you sure you want to continue?',
  cancelButtonText: 'Cancel',
  okButtonText: 'OK',
  showOkButton: true,
  showCancelButton: true,
};

export const MANDATORY_SELECTION_POPUP = {
  modalTitle: 'Selection Required',
  modalMessage: 'Please select atleast one ',
  cancelButtonText: '',
  okButtonText: 'OK',
  showOkButton: true,
  showCancelButton: false,
};
