import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
})
export class MessagePopupComponent implements OnInit, OnDestroy {

  @Input() modal: NgbModalRef;
  @Input() modalRef: any;
  @Input() modalTitle: string;
  @Input() modalMessage: string;
  @Input() showOkButton: string;
  @Input() okButtonText: string;
  @Input() showCancelButton: string;
  @Input() cancelButtonText: string;

  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();

  actionButtonsClicked: boolean;
  constructor() {
  }

  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    if (!this.actionButtonsClicked) {
      this.modalClosed.emit('close');
    }
  }

  modalDismissed(action: string): void {
    this.actionButtonsClicked = true;
    this.modalClosed.emit(action);
    this.modal.close();
  }

}
