import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppStateService {
  static appstate = new BehaviorSubject(null);

  constructor() {
    let gbstate = localStorage.getItem('globalState');
    if (gbstate === null || gbstate === undefined) {
      gbstate = JSON.parse('{}');
    } else {
      if (typeof gbstate === 'string') {
        try {
          gbstate = JSON.parse(gbstate);
        } catch (e) {
          gbstate = JSON.parse('{}');
        }
      }
    }

    AppStateService.appstate.next(gbstate);
  }

  setGlobalState(key, value): any {
    let temp = AppStateService.appstate.getValue();

    if (temp === null || temp === undefined) {
      temp = {};
    } else {
      if (typeof temp === 'string') {
        try {
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      } else {
        try {
          temp = JSON.stringify(temp);
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      }
    }

    try {
      temp[key] = value;
    } catch (e) {
      temp = {};
      temp[key] = value;
    }

    localStorage.setItem('globalState', JSON.stringify(temp));
    AppStateService.appstate.next(temp);
  }

  removeGlobalState(key): any {
    let temp = AppStateService.appstate.getValue();
    if (temp === null || temp === undefined) {
      temp = {};
    } else {
      if (typeof temp === 'string') {
        try {
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      } else {
        try {
          temp = JSON.stringify(temp);
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      }
    }
    delete temp[key];

    AppStateService.appstate.next(temp);
    localStorage.setItem('globalState', JSON.stringify(temp));
  }

  clearGlobalState(): any {
    localStorage.clear();
  }
}
