export const ROLE_PERMISSIONS = [
  {
    "resource": "mu_manage_users_&_scenario_access",
    "gfl": "no access",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_search_study_by_id_or_name",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_compare_scenarios",//used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_sort_by",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_add_study", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "no access"
  },
  {
    "resource": "mp_study_acc_view_details",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_lockUnlock",//used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_coowner",//used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_delete",//used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_cohort_results_consolidation",
    "gfl": "",
    "mda": "",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_track_and_monitor",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_view_details",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_delete",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_edit",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_clone",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_download",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_add_new_scenario",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_mi_scenario_name",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_mi_modelling_level",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_mi_distribution",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_mi_cohort_type_dropdown",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_cohort_name",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_description",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_total_patients_to_be_enrolled",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_dependencies",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_delay(days)",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_min_psm",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_max_psm",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_indication",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_expected_site_activation_failure",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_expected_empty_sites",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_enrollment_rate_reduction_due_to_patient__drop_out",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_enrollment_rate_reduction_due_to_screening_failure",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_checkbox_to_select_region/country",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "slp_cc_copy_inputs_from_other_cohort",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_iterations",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_confidence_interval",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_expected_enrollment_completion_probabilty",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_types_of_distribution_dropdown",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_copy_inputs_from_other_cohort",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_target_no_of_patients",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_target_no_of_sites",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_min_psm_mean",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_max_psm_mean",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_variance_for_psm",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_delete",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sip_ca_add_a_country",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_study_start_date",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_expected_enrollment_completion_date",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_duration",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_complete_site_activation_by",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "max_enrollment_duration",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_simulate_to_historical_data",//used
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_customize_activation_curve",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_graph_country_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_save_and_run_enrollment_model",//used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_next_button",//used
    "gfl": "no access",
    "mda": "edit",
    "superuser": "no access",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_trial_duration",//used
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_sites_activated",//used
    "gfl": "no access",
    "mda": "edit",
    "superuser": "no access",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_modelling_level_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_cohort_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_date_active_checkbox",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_time_period_radio_button",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_lower_modelling_level",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_country_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_edit_parametres",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_refresh_forecasting",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_save_and_close", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_save_and_create", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vd_adjust_random_btn", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vd_create_scenerio_btn", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "trackMonitor_connect_new_data_btn", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "trackMonitor_edit_params_btn", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "trackMonitor_save_btn", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "trackMonitor_refresh_forecast_btn", //used
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  }
];

export const POST_ROLE_PERMISSIONS = [
  {
    "resource": "mu_manage_users_&_scenario_access",
    "gfl": "no access",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_search_study_by_id_or_name",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_compare_scenarios",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_sort_by",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_add_study",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_view_details",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_delete",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_study_acc_cohort_results_consolidation",
    "gfl": "",
    "mda": "",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_track_and_monitor",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_view_details",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_delete",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_edit",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_clone",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_download",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "mp_scenario_acc_add_new_scenario",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_mi_scenario_name",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_mi_modelling_level",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_mi_distribution",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_mi_parameter_estimation_technique_radio_button",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_mi_confidence_interval",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_mi_cohort_type_dropdown",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_cohort_name",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_description",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_total_patients_to_be_enrolled",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_dependencies",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_delay(days)",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_min_psm",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_max_psm",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_indication",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_expected_site_activation_failure",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_expected_empty_sites",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_enrollment_rate_reduction_due_to_patient__drop_out",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_enrollment_rate_reduction_due_to_screening_failure",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_checkbox_to_select_region/country",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "imp_cc_copy_inputs_from_other_cohort",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "gspc_ca_types_of_distribution_dropdown",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "gspc_ca_copy_inputs_from_other_cohort",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "gspc_ca_checkbox_to_select_region/country",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "gspc_ca_edit_distribution",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "gspc_ca_target_no_of_patients",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_study_start_date",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_expected_enrollment_completion_date",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_duration",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_complete_site_activation_by",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_max_enrollment_duration",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_simulate_to_historical_data",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_customize_activation_curve",
    "gfl": "edit",
    "mda": "view",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "sas_graph_select__countries_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_modelling_level_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_cohort_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_date_active_checkbox",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_time_period_radio_button",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_view_lower_modelling_level",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_country_dropdown",
    "gfl": "edit",
    "mda": "edit",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_edit_parameters",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  },
  {
    "resource": "vep_refresh_forecasting",
    "gfl": "edit",
    "mda": "no access",
    "superuser": "edit",
    "restrict_type": "disable"
  }
];