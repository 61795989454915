<div class="modal-header border-bottom-0 pb-0">
  <h5 class="modal-title">Preparing Download Folder ..</h5>
</div>
<div class="modal-body">
  <p *ngIf="status !== 'completed' && status !== 'failed'" id="subHeading" class="mb-3">Time for a coffee
    break!!!<br>{{headerMessage}}
    <ng-template #name [ngIf]="showTimer">
      {{returnMessage}}
    </ng-template>
  </p>
  <p *ngIf="status === 'completed'" id="subHeading" class="mb-3">{{messages.MODEL_RUN_SUCCESS}}</p>
  <p *ngIf="status === 'failed'" id="subHeading" class="mb-3">{{messages.MODEL_RUN_FAILED}}</p>
  <div class="d-flex flex-column pt-2">
    <div class="row no-gutter">
      <ng-container *ngFor="let item of statusIndicators; let i = index">
        <div class="col-12 d-flex">
          <div class="mr-4 d-flex align-items-center">
            <ng-container *ngIf="item.status.loading.includes(status)">
              <ng-template [ngTemplateOutlet]="loader"></ng-template>
            </ng-container>
            <ng-container *ngIf="item.status.done.includes(status)">
              <ng-template [ngTemplateOutlet]="done"></ng-template>
            </ng-container>
            <ng-container *ngIf="item.status.error.includes(status)">
              <ng-template [ngTemplateOutlet]="error"></ng-template>
            </ng-container>
            <ng-container
              *ngIf="!item.status.loading.includes(status) && !item.status.done.includes(status) && !item.status.error.includes(status)">
              <ng-template [ngTemplateOutlet]="none"></ng-template>
            </ng-container>
          </div>
          <p class="m-0">{{item.title}}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <br>
  <p id="subHeading" class="mb-3" *ngIf="showTimer">You will be navigated to home screen in <b>{{counter$ | async}}</b>
    seconds.</p>
</div>
<!-- 
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
  <ng-container
    *ngIf="parentScreen === 'simulation_activation' || parentScreen ==='site-activation' || parentScreen ==='track-monitor-screen'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('redirect')">
      Return to Home screen
    </button>
  </ng-container>
  <ng-container *ngIf="status === 'completed'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('close')">
      View Results
    </button>
  </ng-container>
  <ng-container *ngIf="parentScreen === 'modelling-predictions' && status !== 'completed'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('close')">Close</button>
  </ng-container>
</div>
 -->
<ng-template #loader>
  <!-- <div class="loader">
    <i class="fa fa-spinner fa-spin"></i>
  </div> -->
  <div class="loading-circle h-100 w-100">
    <img src="assets/images/shared/loading-circle.svg" width="50" height="50" alt="loading...">
  </div>
</ng-template>

<ng-template #done>
  <img src="assets/images/process-status/completed.svg" height="24" width="24" alt="completed">
</ng-template>

<ng-template #error>
  <img src="assets/images/process-status/error.svg" height="24" width="24" alt="completed">
</ng-template>

<ng-template #none>
  <img src="assets/images/process-status/none.svg" height="24" width="24" alt="completed">
</ng-template>
