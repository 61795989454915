<div id="overlay"></div>
<ctfo-dialog (ctfoDialogCloseEmitter)="afterClose.emit()">
  <ctfo-dialog-title>{{ title }}</ctfo-dialog-title>
  <ctfo-dialog-content>
    <div [innerHtml]="message"></div>
  </ctfo-dialog-content>
  <ctfo-dialog-actions>
    <!-- <button class="btn-custom-primary" ctfoDialogClose (click)="afterClose.emit()">Close</button> -->
    <div class="cancel-button d-flex ng-star-inserted" ctfoDialogClose (click)="afterClose.emit()">
      <img src="assets/images/shared/cancel.svg" alt="cancel" class="cursor-pointer" width="15px">
      <p class="mb-0 ml-2 cursor-pointer">Close</p>
    </div>
  </ctfo-dialog-actions>
</ctfo-dialog>
