export const CHART_CONFIG = {
  historical: {
    lineColor: '#36756A',
    renderTo: 'historical-chart-container',
  },
  simulated: {
    lineColor: '#39ABED',
    renderTo: 'simulated-chart-container',
  },
};

// -------------------------------------------------- INTERFACES --------------------------------------------------
export interface SiteActivationInputs {
  duration: any;
  complete_site_activation_by: number;
  max_trial_enrollment_duration: number;
  startDate: DateValues;
  endDate: DateValues;
  preStartDate: DateValues;
}

export interface DateValues {
  input: string;
  model: {
    year: number;
    month: number;
    day: number;
  };
  dateLimit?: {
    year: number;
    month: number;
    day: number;
  };
  minDateValue?: {
    year: number;
    month: number;
    day: number;
  };
}

export interface ModelRunInputRequest {
  input_request: {
    user_group: string;
    user_id: string;
    theme_id: string;
    study_id: string;
    study_type: string; // New by default
    scenario_id: string;
    scenario_version: string;
    study_name: string;
    scenario_name: string;
    historical_data_id: string;
    site_activation_method: string; // historical or custom
    custom_inputs?: Array<CustomActivationInputs>;
    forecast_type: string;
    study_created_by: string;
    scenario_created_by: string;
    is_cohort: string;
    cohort_type: string;
  };
}

export interface ChartFetchInputRequest {
  input_request: {
    user_id: string;
    theme_id: string;
    study_id: string;
    scenario_id: string;
    scenario_version: string;
    site_activation_method: string; // historical or custom
    custom_inputs?: Array<CustomActivationInputs>;
    study_created_by: string;
    scenario_created_by: string;
    is_cohort: string;
    cohort_type: string;
  };
}

export interface CustomActivationInputs {
  perc_trial_duration: any;
  perc_site_activated: string;
}

export interface DateInputModel {
  input: string;
  model: {
    year: number;
    month: number;
    day: number;
  };
}

export const TOOLTIP = {
  startDate: 'Date by which the sites start getting activated',
  endDate: 'Date by which all the sites are activated',
};
