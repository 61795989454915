export interface PostTrialDetailsAPIPayload {
  theme_id: string;
  scenario_id: string;
  country: string;
  user_id: string;
  post_trial_access_flag: string;
}

export interface PostTrialDetailsAPIResponse {
  result: {
    post_trial_details: string;
    country: string;
  };
  status: string;
}

export enum ReasonForExclusionOption {
  COUNTRYDECLINED = 'Country Declined',
  COUNTRYNOTRESPONDED = 'Country did not respond',
  USEREXCLUDED = 'User Excluded',
}

export enum ReasonForSiteExclusionOption {
  SITE_DECLINED = 'Site Declined',
  SITE_NOTRESPONDED = 'Site did not respond',
  USEREXCLUDED = 'User Excluded',
}
