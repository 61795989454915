import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'whitespace' })
export class WhitespacePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.toString().replace(/,/g, ', ');
    } else {
      return '';
    }
  }
}
