export const CHEVRONS = {
  pre: {
    info: [
      {
        key: 'study_level_params',
        name: 'Study Level Parameters',
      },
      {
        name: 'Simulation Input Parameters',
        key: 'simulation_input_params',
      },
      {
        name: 'Site Activation Strategy',
        key: 'simulation_activation',
      },
      {
        name: 'View Enrollment Predictions',
        key: 'enrollment_prediction',
      },
    ],
    list: ['study_level_params', 'simulation_input_params', 'simulation_activation', 'enrollment_prediction'],
  },
  // Make similar for post-feasibility to share among common components
};

export interface HistoricalMetrics {
  historical_trial_patient_drop_out_rate: string;
  historical_trial_median_screening_failure_rate: string;
}

export interface UserDetails {
  role: string;
  user_id: string;
  username: string;
  app_user_group: string;
}

export interface StudyDetails {
  app_user_group?: string;
  created_by: string;
  historical_data_available: string;
  historical_data_id: string;
  indication: string;
  is_baseline_tracked: string;
  phase: string;
  study_feasibility: string;
  study_id: string;
  study_name: string;
  study_type?: string;
  theme_id: string;
  therapeutic_area: string;
  updated_timestamp: string;
  is_cohort: string;
  num_of_cohorts: string;
  study_start_date: string;
  study_created_by: string;
  cohort_type: string;
  study_secondary_owner?: string;
  list_type?: string;
  study_code?: string;
}
