import { Injectable } from '@angular/core';
import { Observable, pipe, of } from 'rxjs';
import { SessionStorageService, LocalStorageService } from 'angular-web-storage';
import { catchError, concatMap, map } from 'rxjs/operators';
import { appSetting } from 'src/constants/endpoints';
import { environment } from 'src/environments/environment';
import { HttpHandlerService } from './http-handler.service';
import { appSetting as adminAppSettings } from '@app/shared/dependency/constants/endpoints';
import * as messages from '@constants/messages';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  public isValidJson = str => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };

  constructor(
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private httpHandler: HttpHandlerService,
    private notification: NotificationService,
    private route: Router
  ) { }

  isLoggedIn(): Observable<boolean> {
    const new_session_request = JSON.parse(this.sessionStorage.get('new_session_request')) || false;
    const payload = {
      user_id: '',
      password: '',
      new_session_request,
    };
    return this.httpHandler.postHttpResults(appSetting.LOGIN, payload)
      .pipe(concatMap((response: any) => {
        let data = response.idm_response.session_id;
        if (this.isValidJson(data.replace(/'/g, '"'))) {
          data = JSON.parse(data?.replace(/'/g, '"'));
        }
        if (data?.FAILED === 'Session Already Active') {
          this.route.navigate(['session']);
          return false;
        } else if (response && response.idm_response && response.idm_response.auth_status === 'success') {
          const user = this.sessionStorage.get('user-details');
          const userDetails = {
            user_id: response.idm_response.user_id || '',
            user_email: response.idm_response.user_email || '',
            username: response.idm_response.username || '',
            role: response.idm_response.get_users_application_roles_api || '',
            active_status: response.idm_response.active_status || '',
            app_user_group: response.idm_response.app_user_group || '',
            client_role: response.idm_response.client_role || '',
            session_id: response.idm_response.session_id,
            client_name: response.idm_response.client_name || '',
          };
          this.sessionStorage.set('user-details', userDetails);
          this.sessionStorage.set('new_session_request', false);
          if (response.idm_response.active_status === 'inactive') {
            return of(true);
          }

          return this.httpHandler
            .postHttpResults(adminAppSettings.ctemp_copy_user_information_temp, this.createPayloadCopyApi(userDetails))
            .pipe(
              map((resp: any) => (resp.status === messages.API_SUCCESS)),
              catchError(() => {
                return of(false);
              })
            )
        } else {
          return of(false);
        }

      })
      );
  }


  createPayloadCopyApi(temp): any {
    return {
      input_request: {
        user_id: temp.user_id,
        app_user_group: temp.app_user_group
      }
    }
  }


  copyInformationCall(userDetails): any {
    this.httpHandler
      .postHttpResults(adminAppSettings.ctemp_copy_user_information_temp, this.createPayloadCopyApi(userDetails)).pipe(map((resp: any) => {
        if (resp.status === messages.API_SUCCESS) {
          //
        }
      }))
  }


  handleError(message: any) {
    console.info(message)
  }

  onLogout(): any {
    localStorage.setItem('isLoggedOut', 'yes');
    // this.localStorage.clear();
    // this.sessionStorage.clear();
    window.location.href = environment.authLogOutUrl;
  }

  getUserDetails(): any {
    return this.sessionStorage.get('user-details') || {};
  }

  authenticateUser(value): any {
    return this.httpHandler.postHttpResults(appSetting.LOGIN, value);
  }
}
