import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DecimalNumberFormatPipe } from './decimal-number-format.pipe';
import { NullHyphenPipe } from './null-hyphen.pipe';
import { RemoveDuplicatePipe } from './remove-duplicate.pipe';
import { ShortNumberSuffixPipe } from './short-number-suffix.pipe';
import { TitleCaseExceptPipe } from './title-case-except.pipe';
import { WhitespacePipe } from './whitespace.pipe';
import { SanitizedHtmlPipe } from './dom-sanitizer.pipe';

@NgModule({
  declarations: [
    DecimalNumberFormatPipe,
    RemoveDuplicatePipe,
    ShortNumberSuffixPipe,
    WhitespacePipe,
    NullHyphenPipe,
    TitleCaseExceptPipe,
    SanitizedHtmlPipe
  ],
  imports: [CommonModule],
  exports: [
    DecimalNumberFormatPipe,
    RemoveDuplicatePipe,
    ShortNumberSuffixPipe,
    WhitespacePipe,
    NullHyphenPipe,
    TitleCaseExceptPipe,
    SanitizedHtmlPipe
  ],
})
export class PipesModule {}
