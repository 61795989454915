import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { SITE_ACTIVATION_STRATEGY } from 'src/constants/messages';

// high charts dependencies
declare let require: any;
const Boost = require('highcharts/modules/boost');
const noData = require('highcharts/modules/no-data-to-display');
const More = require('highcharts/highcharts-more');
const exportMap = require('highcharts/modules/exporting');
const highstock = require('highcharts/modules/stock');
const fullScreen = require('highcharts/modules/full-screen');

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
exportMap(Highcharts);
highstock(Highcharts);
fullScreen(Highcharts);

@Component({
  selector: 'app-expand-chart',
  templateUrl: './expand-chart.component.html',
  styleUrls: ['./expand-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExpandChartComponent implements OnInit, OnChanges {

  @Input() modal: NgbModalRef;
  @Input() modalRef: any;
  @Input() modalTitle: string;
  @Input() modalTitleSmall: string;
  @Input() parentScreen: string;
  @Input() chartOptions;
  @Input() legends: Array<any>;
  @Input() legendsHeader: string;
  highcharts: any;
  messageConstants = SITE_ACTIVATION_STRATEGY;

  chartCallback = (chart): void => {
    setTimeout(() => {
      chart.redraw();
      chart.reflow();
    });
  }

  constructor() {
    this.highcharts = Highcharts;
  }

  ngOnInit(): void {
    //
  }

  ngOnChanges(): void {
    if (this.modalTitle.includes('Site Activation')) {
      (this as any).chartOptions.yAxis.title.text = null;
    } else {
      (this as any).chartOptions.chart.spacingLeft = 15;
    }
    (this as any).chartOptions.tooltip.snap = 1;
    // (this as any).chartOptions.chart.height = 457;
    // (this as any).chartOptions.chart.width = 870;
    // (this as any).chartOptions.chart.width = 1380;
  }

  modalDismissed(): void {
    this.modal.close();
  }
}
