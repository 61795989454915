import { Injectable } from '@angular/core';
import { Utils } from '@app/shared/dependency/utils';
import { ROLE_PERMISSIONS } from '@app/shared/dependency/constants/permissions';
import { localNames } from '@app/shared/dependency/constants';
import { AuthService } from '@app/shared/services/auth.service';

enum ROLES {
  ADMIN = 'superuser',
  GFL = 'gfl',
  CFL = 'cfl',
}

@Injectable({
  providedIn: 'root',
})
export class HasUserRoleService {
  permissions = ROLE_PERMISSIONS;
  userType = '';
  showIcon = false;
  constructor(private auth: AuthService) {
    this.determineRole();
  }

  determineRole() {
    const scenarioRole = Utils.getSessionStorage(localNames.user_details).app_user_group;

    if (scenarioRole) {
      this.showIcon = true;
      this.userType = scenarioRole;
    } else {
      this.userType = this.auth.getUserDetails().app_user_group;
    }
  }

  checkUserAccess(resource, role?) {
    if (!role) {
      role = this.userType;
    }
    let accessType;
    this.permissions.filter(res => {
      if (res.resource === resource) {
        if (res[role] == 'edit') {
          accessType = 'block';
        } else if (res[role] == 'no access') {
          accessType = 'none';
        } else {
          accessType = 'disabled';
        }
      }
    });
    return accessType;
  }

  getUserAccessType(resource, role): string {
    let type = 'invalid';
    this.permissions.filter(res => {
      if (res.resource === resource) {
        type = res[role];
      }
    });
    return type;
  }

  updateRole(currentView) {
    if (currentView == 'global') {
      this.showIcon = false;
      this.userType = this.auth.getUserDetails().user_group;
    } else {
      this.showIcon = true;
      this.userType = Utils.getSessionStorage(localNames.SCENARIOINFO).user_group;
    }
  }
}
