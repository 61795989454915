<div class="modal-header border-bottom-0 pb-0">
    <h5 class="modal-title font-18">{{modalTitle}}</h5>
</div>
<div class="modal-body pt-0 pb-2">
    {{modalMessage}}
</div>
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed(okButtonText)" *ngIf="showOkButton">{{okButtonText}}</button>
    <div class="cursor-pointer" (click)="modalDismissed(cancelButtonText)" *ngIf="showCancelButton"><img src="assets/images/shared/cancel.svg" class="mr-2" width="16" height="16">{{cancelButtonText}}</div>
</div>
