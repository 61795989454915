<div>
  <div class="modal-header border-bottom-0 pb-0">
    <h5 class="modal-title">Session Timeout</h5>
  </div>
  <hr />
  <div class="timer" style="padding-left: 10px; max-height: 321px; overflow: hidden">
    <p class="timer-text">
      You are being timed out due to inactivity. Please click on “Resume“ to save your work.
    </p>
    <span id="seconds"> {{ secondsToDday }} secs</span>
  </div>

  <div class="modal-footer custom-footer">
    <button class="button-primary" (click)="onOk()">Resume</button>
    <button class="footer-button button-secondary mr-3" (click)="onCancel()">
      <img class="mr-2" src="assets/images/icon_cancel.svg" />
      <span>Cancel</span>
    </button>
  </div>
</div>
