
export const CohortListPayload = {
    input_request: {
        user_id: "",
        scenario_created_by: "",
        study_created_by: "",
        study_id: "",
        scenario_id: "",
        scenario_version: "",
        theme_id: "",
        is_cohort: "",
        category: "",
        geography_list_flag: false,
        country_list_flag: false
    },
};
