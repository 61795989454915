export const statusMetaData = [
  {
    title: 'Fetching Image Data',
    status: {
      loading: ['started', 'in-progress'],
      done: ['started', 'in-progress', 'completed', 'error-enrollment', 'failed'],
      error: ['error-preparing'],
    },
  },
  {
    title: 'Generating Images',
    status: {
      loading: [],
      done: ['completed'],
      error: ['error-enrollment', 'failed'],
    },
  },
  {
    title: 'Preparing Download Folder',
    status: {
      loading: [],
      done: ['completed'],
      error: ['error-enrollment', 'failed'],
    },
  },
];


export const message = {
  MODEL_RUN_SUCCESS: 'Enrollment Predictions Generated Successfully !',
  MODEL_RUN_FAILED: 'Failed to generate the predictions.Please modify the inputs',
  HEADER_MESSAGE: 'This process could take a few minutes to complete.',
  RETURN_MESSAGE: 'You may return to homepage & check back to view predictions generated.',
}
