<div class="multi-header-table">
  <table>
    <thead class="table-header-content">
      <tr>
        <ng-container *ngFor="let header of mainHeader; let i = index">
          <th [class]="header.class" [ngStyle]="{width: mainHeaderConfiguration[i].width}"
            [attr.colspan]="mainHeaderConfiguration[i].colspan" [attr.rowSpan]="mainHeaderConfiguration[i].rowspan"
            (click)="sortTableContent(header.id, i, 'mainHeader')">
            <div class="d-flex w-100 align-items-end text-right" [ngStyle]="{'justify-content': mainHeaderConfiguration[i].align, 'color': mainHeaderConfiguration[i].isSortable ? '#27a6a4':'unset',
                    'cursor': mainHeaderConfiguration[i].isSortable ? 'pointer':'default'}">
              <p class="m-0">{{header.label}}</p>
              <div class="d-flex align-items-center pb-1" style="width: 0; height: 8px;"
                [ngStyle]="{'visibility': mainHeaderConfiguration[i].sortOrder === '' ? 'hidden':'unset'}">
                <span class="fa fa-sort-asc ml-1" *ngIf="mainHeaderConfiguration[i].sortOrder==='ASC'"
                  style="max-width: 8px; height: 8px;"></span>
                <span class="fa fa-sort-desc mt-n3 ml-1" *ngIf="mainHeaderConfiguration[i].sortOrder==='DSC'"
                  style="max-width: 8px; height: 8px;"></span>
              </div>
            </div>
          </th>
        </ng-container>
      </tr>
      <tr>
        <ng-container *ngFor="let head of subHeader; let i = index;">
          <td class="" [class]="head.class" [ngStyle]="{width: subHeaderConfiguration[i].width}"
            (click)="sortTableContent(head.id, i, 'subHeader')">
            <div class="d-flex w-100 align-items-end" [ngStyle]="{'justify-content': subHeaderConfiguration[i].align, 'color': subHeaderConfiguration[i].isSortable ? '#27a6a4':'unset',
                    'cursor': subHeaderConfiguration[i].isSortable ? 'pointer':'default'}">
              <p class="m-0">{{head.label}}</p>
              <div class="d-flex align-items-center pb-1" style="width: 8px; height: 8px;"
                [ngStyle]="{'visibility': subHeaderConfiguration[i].sortOrder === '' ? 'hidden':'unset'}">
                <span class="fa fa-sort-asc ml-1" *ngIf="subHeaderConfiguration[i].sortOrder==='ASC'"
                  style="max-width: 8px; height: 8px;"></span>
                <span class="fa fa-sort-desc mt-n3 ml-1" *ngIf="subHeaderConfiguration[i].sortOrder==='DSC'"
                  style="max-width: 8px; height: 8px;"></span>
              </div>
            </div>
          </td>
        </ng-container>
      </tr>
    </thead>
    <!-- snInfiniteScroll (scrollEnd)="onScrollEnd()" [offset]="10 -->
    <tbody class="table-body-content" *ngIf="tbody && tbody.length > 0">
      <ng-container *ngIf="(tbody |filter:searchText:['geography_level']).length > 0; else noSearchData">
        <tr class="content" *ngFor="let row of tbody |filter: searchText: ['geography_level']">
          <td [ngStyle]="{width: head.width, 'text-align': head.alignment}" *ngFor="let head of tableConfiguration">
            {{row[head.id]}}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-container *ngIf="tbody && tbody.length < 1">
  <div class="no-data d-flex align-items-center justify-content-center">
    <h4>No data available.</h4>
  </div>
</ng-container>

<ng-template #noSearchData>
  <div class="no-data d-flex align-items-center justify-content-center">
    <h4>No data available for search text "{{searchText}}".</h4>
  </div>
</ng-template>
