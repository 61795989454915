export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';

export const SOMETHING_WENT_WRONG_EXPORT =
  'Something went wrong. Your file export could not be downloaded. Please ensure that you save the data and try again';

export const NO_DATA = 'No data available.';

export const ERROR_EMAIL = 'Please enter a valid email address.';
export const ERROR_FIRST_NAME = 'Please enter a valid first name.';
export const ERROR_LAST_NAME = 'Please enter a valid last name.';

export const SAVE_SUCCESSFUL = 'Saved successfully.';

export const LOSE_UNSAVED_CHANGES = 'You may lose the unsaved changes. Are you sure you want to continue?';

export const RUN_OPTIMIZATION = 'Run optimization';

export const RUN_OPTIMIZATION_MESSAGE = 'Do you want to optimize the site list?';

export const COUNTRY_INCLUDE_ENROLLMENT_TOOLTIP = `By choosing INCLUDE, the country might populate in PE tab based on your site selection. By choosing
EXCLUDE, the country will not populate under PE tab and will have an impact on country
prioritization too.`;

export const SITE_INCLUDE_ENROLLMENT_TOOLTIP = `By choosing INCLUDE, the site might populate in PE tab based on your site and country selection.
By choosing EXCLUDE, the site will not populate under PE tab and will have an impact on site prioritization too.`;

export const ALL_SITES_EXCLUDED = 'Unable to lock scenario. Please include some sites for Project Enrollment.';

export const NON_ZERO_AND_POSITIVE = 'This metric cannot be zero. Please enter a positive integer.';

export const ZERO_AND_POSITIVE = 'This metric cannot be negative. Please enter either zero or a positive integer.';

export const NON_NEGATIVE = 'This metric cannot be negative. Please enter a positive integer.';

export const ZS_MAIL_EXTENSION_WARNING =
  'This investigator has an email id outside of ZS environment. Please make sure you are sending this to the correct recipient.';
export const ZS_MAIL_EXTENSION_WARNING_COUNTRY =
  'This medical advisor has an email id outside of ZS environment. Please make sure you are sending this to the correct recipient.';
