<ctfo-dialog>
  <ctfo-dialog-title>{{ title }}</ctfo-dialog-title>
  <ctfo-dialog-content>
    <p class="m-0 font-weight-bold">{{ inputConfig?.fieldName }}</p>
    <input
      [ngClass]="{ mandatory: this.inputConfig?.validation?.required }"
      [type]="inputConfig?.fieldType"
      [(ngModel)]="inputData"
      (ngModelChange)="validateInput($event)"
      #inputEl
    />
    <small class="text-danger mt-1">{{ errorMessage }}</small>
  </ctfo-dialog-content>
  <ctfo-dialog-actions>
    <button
      *ngIf="!buttonConfig?.primary?.hidden"
      class="btn-custom-primary mr-3"
      ctfoDialogClose
      (click)="afterOK.emit(inputData)"
      [disabled]="isOKButtonDisabled()"
    >
      {{ buttonConfig?.primary?.label || 'OK' }}
    </button>
    <button
      *ngIf="!buttonConfig?.secondary?.hidden"
      class="button-secondary mr-3"
      ctfoDialogClose
      (click)="afterOK.emit('')"
    >
      {{ buttonConfig?.secondary?.label || 'Reset' }}
    </button>
    <button
      *ngIf="!buttonConfig?.close?.hidden"
      class="btn-outline-none ml-n2"
      ctfoDialogClose
      (click)="afterCancel.emit()"
    >
      <img class="mr-2" src="assets/images/icon_cancel.svg" />
      <span>{{ buttonConfig?.close?.label || 'Cancel' }}</span>
    </button>
  </ctfo-dialog-actions>
</ctfo-dialog>
