import { BaseAPIPayload, BaseAPIResponse } from './api.model';

export enum ProjectEnrollmentResultsTabs {
  ENROLLMENT_DETAILS = 'ENROLLMENT_DETAILS',
  ENROLLMENT_GRAPHS = 'ENROLLMENT_GRAPHS',
}

export interface ScenarioDetails {
  enrollment_scenario_id: string;
  enrollment_scenario_name: string;
  enrollment_scenario_identifier: string;
  scenario_enrollment_status?: boolean;
  pe_graph_generation_status?: string;
}

export interface ScenarioDetailsObject {
  [enrollment_scenario_id: string]: ScenarioDetails;
}

export interface EnrollmentDetailsGridRow {
  all_sites_initiated: string | dateObject;
  avg_time_from_site_initiation_to_FPI: number;
  cost_per_patient: number;
  cost_per_site: number;
  country_code: string;
  country_name: string;
  disable_ramp_up?: boolean;
  enrolled_rate_psm: number;
  fifty_percent_site_initiated: string | dateObject;
  first_patient_in_date: string | dateObject;
  first_site_initiated: string | dateObject;
  include_country_flag: boolean;
  last_patient_in_date: string | dateObject;
  max_available_patients: number;
  min_available_patients: number;
  no_of_patients_aggregated?: number;
  no_of_patients_committed: number;
  no_of_sites: number;
  no_of_sites_interested: number;
  patient_drop_rate?: number;
  planned_recruitment_duration: number;
  projected_LPI: string | dateObject;
  projected_recruitment_duration: number;
  ramp_up_update_flag: boolean;
  region_code: string;
  region_name: string;
  screen_failure_rate?: number;
  total_cost: number;
  updated_patients_committed: number;
  updated_recruitment_rate: number;
  updated_sites_committed: number;
}

export interface GraphsData {
  patients_enrolled: any;
  sites_activated: any;
}

export interface RunningMetricsObject {
  data: string | number;
  visible: boolean;
}

export interface RunningMetrics {
  country_name?: RunningMetricsObject;
  country_code?: RunningMetricsObject;
  region_name?: RunningMetricsObject;
  region_code?: RunningMetricsObject;
  first_site_initiated?: RunningMetricsObject;
  fifty_percent_site_initiated?: RunningMetricsObject;
  all_sites_initiated?: RunningMetricsObject;
  first_patient_in_date?: RunningMetricsObject;
  last_patient_in_date?: RunningMetricsObject;
  avg_time_from_site_initiation_to_FPI?: RunningMetricsObject;
  no_of_sites?: RunningMetricsObject;
  no_of_patients_committed?: RunningMetricsObject;
  cost_per_patient?: RunningMetricsObject;
  enrolled_rate_psm?: RunningMetricsObject;
  projected_recruitment_duration?: RunningMetricsObject;
  include_country_flag?: RunningMetricsObject;
  no_of_sites_interested?: RunningMetricsObject;
  min_available_patients?: RunningMetricsObject;
  max_available_patients?: RunningMetricsObject;
  updated_sites_committed?: RunningMetricsObject;
  updated_patients_committed?: RunningMetricsObject;
  planned_recruitment_duration?: RunningMetricsObject;
  updated_recruitment_rate?: RunningMetricsObject;
  projected_LPI?: RunningMetricsObject;
  cost_per_site?: RunningMetricsObject;
  total_cost?: RunningMetricsObject;
}

// API Response
export interface RefreshAPIResponse {
  message: {
    async_list_id: string;
    task_id: string;
  };
  status: string;
}

export interface RefreshPollAPIResponse extends BaseAPIResponse {
  result: { dataAvailable: boolean };
}
export interface ViewListAPIResponse extends BaseAPIResponse {
  result: {
    items: ScenarioDetails[];
  };
}

export interface EnrollmentModelAPIResponse {
  message: {
    async_list_id: string;
    task_id: string;
  };
  status: string;
}

export interface EnrollmentSaveAPIResponse extends BaseAPIResponse {
  message: string;
  result: {
    validation_failed: boolean;
    validation_failed_for: string[];
  };
  status: string;
}

export interface GraphAPIResponse extends BaseAPIResponse {}

export interface CustomScenarioResponse extends BaseAPIResponse {
  result: {
    enrollment_scenario_id: string;
    enrollment_scenario_name: string;
  };
}

export interface EnrollmentDetailAPIResponse extends BaseAPIResponse {
  result: { enrollment_details: EnrollmentDetailsGridRow[] };
}

export interface GetGraphDataAPIResponse extends BaseAPIResponse {
  result: GraphsData;
}

export interface ExportImagesAPIResponse extends BaseAPIResponse {
  result: { url: string };
}

export interface EnrollmentModelStatusAPIResponse {
  result: {
    message: string;
    validation_failed_for?: string[];
  };
  status: string;
}

// API Payloads
export interface EnrollmentModelAPIPayload extends BaseAPIPayload {
  data: EnrollmentDetailsGridRow[];
  enrollment_scenario_id: string;
  enrollment_scenario_identifier: string;
  enrollment_scenario_name: string;
  metric_change: Boolean;
  category: String;
}

export interface EnrollmentModelStatusAPIPayload extends BaseAPIPayload {
  enrollment_scenario_id: string;
  enrollment_scenario_identifier: string;
  enrollment_scenario_name: string;
  task_id: string;
}

export interface GetEnrollmentDetailPayload extends BaseAPIPayload {
  enrollment_scenario_id: string;
  enrollment_scenario_identifier: string;
  enrollment_scenario_name: string;
}
export interface GraphAPIPayload extends BaseAPIPayload {
  enrollment_scenario_id: string;
  enrollment_scenario_identifier: string;
}

export interface CustomScenarioAPIPayload extends BaseAPIPayload {
  enrollment_scenario_name: string;
}

export interface ExportImagesAPIPayload extends BaseAPIPayload {
  enrollment_scenario_id: string;
  enrollment_scenario_name: string;
  enrollment_scenario_identifier: string;
  image_dictionary: { patients_enrolled: string; sites_activated: string };
}

export interface dateObject {
  year: number;
  month: number;
  day: number;
}
