import { Directive, ElementRef, Input, Output, EventEmitter, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

@Directive({
    selector: '[ngModel][debounce]',
})
export class Debounce {
    @Output() public onDebounce = new EventEmitter<any>();

    @Input('debounce') public debounceTime: number = 500;

    private modelValue = null;

    constructor(public model: NgControl, el: ElementRef, renderer: Renderer2) {
    }

    ngOnInit() {
        this.modelValue = this.model.value;

        if (!this.modelValue) {
            var firstChangeSubs = this.model.valueChanges.subscribe(v => {
                this.modelValue = v;
                firstChangeSubs.unsubscribe()
            });
        }

        this.model.valueChanges
            .pipe(debounceTime(this.debounceTime))
            .pipe(distinctUntilChanged())
            .subscribe(mv => {
                if (this.modelValue != mv) {
                    this.modelValue = mv;
                    this.onDebounce.emit(mv);
                }
            });
    }
}