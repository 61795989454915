import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class SortingService {

    constructor() {
    }

    // common method for sort a list by key
    commonSortByKey(array, key, isNumber): Array<object> {
        if (array) {
            return array.sort((a, b) => {
                let x;
                let y;
                const type = typeof a[key];
                // numerical data
                try {
                    if (isNumber) {
                        x = parseFloat(a[key]);
                        y = parseFloat(b[key]);
                        if (isNaN(y)) {
                            y = 0.0;
                        }
                        if (isNaN(x)) {
                            x = 0.0;
                        }
                    } else if (type === 'string') {
                        x = a[key].toLowerCase();
                        y = b[key].toLowerCase();
                    }
                    //  else if (isDate) {
                    //     x = new Date(a[key]);
                    //     x /= 1000 + 900 + 330 * 60;
                    //     y = new Date(b[key]);
                    //     y /= 1000 + 900 + 330 * 60;
                    // }
                    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
                } catch (exception) {
                    return array;
                }
            });
        }
    }

    // common method for reverse sort a list by key
    commonReverseSortByKey(array, key, isNumber): Array<object> {
        if (array) {
            return array.sort((b, a) => {
                let x;
                let y;
                const type = typeof a[key];
                // numerical data
                if (isNumber) {
                    x = parseFloat(a[key]);
                    y = parseFloat(b[key]);
                    if (isNaN(y)) {
                        y = 0.0;
                    }
                    if (isNaN(x)) {
                        x = 0.0;
                    }
                } else if (type === 'string') {
                    x = a[key].toLowerCase();
                    y = b[key].toLowerCase();
                }
                // else if (isDate) {
                //     x = new Date(a[key]);
                //     x /= 1000 + 900 + 330 * 60;
                //     y = new Date(b[key]);
                //     y /= 1000 + 900 + 330 * 60;
                // }

                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        }
    }


}
