import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ButtonConfig, InputConfig } from '../dialog.model';

@Component({
  selector: 'ctfo-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss'],
})
export class InputDialogComponent implements AfterViewInit {
  @Input() title: string;
  @Input() buttonConfig?: ButtonConfig = {
    primary: { label: 'OK' },
    close: { label: 'Cancel' },
    secondary: { label: 'Reset' },
  };
  @Input() inputConfig: InputConfig;

  @Output() afterCancel = new EventEmitter<void>();
  @Output() afterOK = new EventEmitter<string>();

  inputData: string;
  formValid: boolean;
  errorMessage = '';

  @ViewChild('inputEl') inputEl: ElementRef;
  ngAfterViewInit() {
    this.inputEl.nativeElement.focus();
  }

  validateInput(inputData: string): void {
    this.inputData = inputData.replace(/\s+/g, ' ').trim();
    const { maxLength, minLength, required, pattern } = this.inputConfig?.validation;
    if (maxLength && this.inputData?.length > maxLength) {
      this.errorMessage = `Error: The ${this.inputConfig.fieldName} cannot be more than ${maxLength} characters.`;
      this.formValid = false;
      return;
    }
    if (minLength && this.inputData?.length < minLength) {
      this.errorMessage = `Error: The ${this.inputConfig.fieldName} cannot be less than ${minLength} characters.`;
      this.formValid = false;
      return;
    }
    if (pattern) {
      const regEx = new RegExp(pattern);
      if (!regEx?.test(this.inputData)) {
        this.errorMessage = `Error: The ${this.inputConfig.fieldName} cannot contain any special characters.`;
        this.formValid = false;
        return;
      }
    }
    if (required && !this.inputData) {
      this.errorMessage = '';
      this.formValid = false;
      return;
    }
    this.errorMessage = '';
    this.formValid = true;
  }

  isOKButtonDisabled(): boolean {
    return !this.formValid;
  }
}
