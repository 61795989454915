import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ctfo-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  @Input() title: string;
  @Input() message: string;

  @Output() afterClose = new EventEmitter<void>();
}
