const DATE_MODEL = {
  input: '',
  model: {
    year: null,
    month: null,
    day: null,
  },
};

export const PERCENTAGE_DATA_POINT = {
  perc_trial_duration: null,
  perc_site_activated: null,
};

export const DATE_DATA_POINT = {
  perc_trial_duration: Object.assign({}, DATE_MODEL),
  perc_site_activated: null,
};

export const PERCENTAGE_CUSTOM_INPUT = [
  {
    perc_trial_duration: 0,
    perc_site_activated: 0,
  },
  {
    perc_trial_duration: 25,
    perc_site_activated: null,
  },
  {
    perc_trial_duration: 50,
    perc_site_activated: null,
  },
  {
    perc_trial_duration: 75,
    perc_site_activated: null,
  },
  {
    perc_trial_duration: 100,
    perc_site_activated: 100,
  },
];

export const DATE_CUSTOM_INPUT = [
  {
    perc_trial_duration: DATE_MODEL,
    perc_site_activated: 0,
  },
  JSON.parse(JSON.stringify(DATE_DATA_POINT)),
  JSON.parse(JSON.stringify(DATE_DATA_POINT)),
  JSON.parse(JSON.stringify(DATE_DATA_POINT)),
  {
    perc_trial_duration: DATE_MODEL,
    perc_site_activated: 100,
  },
];

export const CONFIRMATION_MODAL = {
  cancelButtonText: 'Cancel',
  okButtonText: 'OK',
  modalTitle: 'Save Changes',
  modalMessage: 'You may lose the unsaved changes. Are you sure you want to continue?',
};

export const CANCEL_MODAL = {
  cancelButtonText: 'No, Cancel',
  okButtonText: 'OK',
  modalTitle: 'Unsaved Changes',
  modalMessage: 'All unsaved changes will be lost. Please "Save & Apply" the Site activation.',
};

export const CALCULATE_ACTIVATION_MODAL = {
  cancelButtonText: 'Cancel',
  okButtonText: 'OK',
  modalTitle: 'Unsaved Changes',
  modalMessage:
    'Changes will be lost if you proceed without calculating activation curve. Are you sure you want to proceed?',
};

export interface CUSTOM_ACTIVATION_DATA {
  geographyName: string;
  historical: Array<{}>;
  custom: {
    dateCustom: Array<{}>;
    percentageCustom: Array<{}>;
  };
  siteActivationMethod: boolean;
  siteStartDate: {
    input: string;
    model: {
      year: number;
      month: number;
      day: number;
    };
  };
  siteEndDate: {
    input: string;
    model: {
      year: number;
      month: number;
      day: number;
    };
  };
  toggleSelection: boolean;
}

export const CHART_CONFIG = {
  historical: {
    lineColor: '#36756A',
    renderTo: 'historical-chart-container',
  },
  simulated: {
    lineColor: '#39ABED',
    renderTo: 'simulated-chart-container',
  },
};
