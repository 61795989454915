<ctfo-dialog (ctfoDialogCloseEmitter)="afterClose.emit()">
  <ctfo-dialog-title>{{ title }}</ctfo-dialog-title>
  <ctfo-dialog-content>{{ message }}</ctfo-dialog-content>
  <ctfo-dialog-actions>
    <button class="mr-3 button-primary" ctfoDialogClose (click)="afterOK.emit()">
      {{ confirmationButtonLabel }}
    </button>
    <!-- <button class="ml-2 button-secondary" ctfoDialogClose (click)="afterCancel.emit()">
      <img class="mr-2" src="assets/images/icon_cancel.svg" />
      <span>Cancel</span>
    </button> -->
    <div class="cursor-pointer custom-cancel-btn" ctfoDialogClose (click)="afterCancel.emit()" style="color: #27a6a4;">
      <img src="assets/images/shared/cancel.svg" width="16" height="16" class="mr-2">{{cancelButtonLabel}}</div>
  </ctfo-dialog-actions>
</ctfo-dialog>
