import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { HttpHandlerService } from '@app/shared/services/http-handler.service';
import { appSetting } from '@constants/endpoints';
import { API_SUCCESS } from '@constants/messages';
import { SessionStorageService } from 'angular-web-storage';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PostfInputRequest } from '../post-feasibility/postf-scenarios/scenarios.model';
import { LockUnlockscenarioRequest, PrefInputRequest } from '../pre-feasibility/pref-scenarios/pref-scenarios.model';
import { StudyDetails, UserDetails } from './modeling-predictions-shared.model';


@Injectable({
  providedIn: 'root',
})
export class ScenarioService {

  valuesChanged = false;

  updateStudyStartDate: Subject<Object> = new Subject<Object>();

  modellingLevel: Subject<string> = new Subject<string>();
  modellingType: Subject<string> = new Subject<string>();
  estimationTech: Subject<string> = new Subject<string>();
  cohortType: Subject<string> = new Subject<string>();

  cohort_type: Subject<string> = new Subject<string>();

  setScenarioName: Subject<string> = new Subject<string>();
  fetchScenarioName = this.setScenarioName.asObservable();

  setModelingLevel: Subject<string> = new Subject<string>();
  setModelingType: Subject<string> = new Subject<string>();
  fetchModelingLevel = this.setModelingLevel.asObservable();

  cohortObject: Subject<Object> = new Subject<Object>();

  //Track & Monitor Event (True)
  deleteScenarioFile: Subject<boolean> = new Subject<boolean>();
  editParam: Subject<boolean> = new Subject<boolean>();
  num_of_cohorts: Subject<any> = new Subject<any>();
  // To check if user is inside Scenario
  isUserActive: Subject<any> = new Subject<any>();
  inputRequest = this.localStorage.get('inputRequest');
  lockStatus = this.inputRequest?.input_request.scenario_lock_status
  updatedLockStatus = new BehaviorSubject(this.lockStatus);
  fetchUserActiveValue = this.isUserActive.asObservable();
  currentScreenMap = {
    study_level_params: "study_level_parameters",
    simulation_input_params: "simulation_input_parameters",
    simulation_activation: "site_activation_strategy",
    enrollment_prediction: "view_enrollment_predictions",
    enrollment_model_running: "view_enrollment_predictions",

    //post maps
    input_model_params: 'input_model_parameters',
    country_selection: 'country_selection',
    historical_activation: "site_activation_strategy",

  }
  reversCurrentScreenMap = {
    study_level_parameters: "study_level_params",
    simulation_input_parameters: "simulation_input_params",
    site_activation_strategy: "simulation_activation",
    view_enrollment_predictions: "enrollment_prediction",
    country_selection: 'country_selection',
    input_model_parameters: 'input_model_params',
    historical_activation: 'site-activation',
  }

  constructor(
    private localStorage: SessionStorageService,
    private httpHandler: HttpHandlerService,
    private authService: AuthService,
    private router: Router) { }

  getScenarioName(): string {
    const modellingInputs = this.localStorage.get('modeling-inputs');
    if (modellingInputs) {
      return modellingInputs.scenarioName;
    }
    return '';
  }

  generateScenarioVersion(): Observable<string> {
    return this.httpHandler.readHttpResults(appSetting.VERSION_ID)
      .pipe(map(response => {
        if ((response as any).status === API_SUCCESS) {
          return (response as any).result.new_scenario_version;
        }
        return '';
      }, () => {
        return '';
      }));
  }

  generateScenarioID(): Observable<string> {
    return this.httpHandler.readHttpResults(appSetting.SCENARIO_ID)
      .pipe(map(response => {
        if ((response as any).status === API_SUCCESS) {
          return (response as any).result.scenario_id;
        }
        return '';
      }, () => {
        return '';
      }));
  }

  saveUpdateAPI(payload: PrefInputRequest | PostfInputRequest): Observable<string> {
    const endpoint = appSetting[payload.input_request.study_feasibility].SAVE_AND_UPDATE;
    return this.httpHandler
      .postHttpResults(endpoint, payload)
      .pipe(map((saveResponse) => {
        return (saveResponse as any).status;
      }, () => {
        return 'FAILED';
      }));
  }

  updateLocalStorage(inputRequest: any): void {
    // delete the old_scenario_version and update LS input request with new_scenario_version key
    delete inputRequest.input_request.old_scenario_version;
    this.localStorage.set('inputRequest', inputRequest);
    // Update ids in local storage
    const ids = {
      scenario_id: inputRequest.input_request.scenario_id,
      version_id: inputRequest.input_request.new_scenario_version,
    };
    this.localStorage.set('ids', ids);
    // in case of edit mode if scenario_status is completed -> modify scenario_status
    const scenarioDetails = this.localStorage.get('scenario-details');
    if (scenarioDetails) {
      if (scenarioDetails.scenario_status.toLowerCase() === 'completed') {
        scenarioDetails.scenario_status = 'In Progress';
      }
      scenarioDetails.last_module_activated = inputRequest.input_request.last_module_activated;
      this.localStorage.set('scenario-details', scenarioDetails);
    }
  }

  checkCurrentScreenMap(screenValue: any) {
    switch (screenValue) {
      case 'site-activation': {
        return 'site_activation_strategy';
      }
      case 'view-enrollment': {
        return 'view_enrollment_predictions';
      }
      default: {
        return this.currentScreenMap[screenValue];
      }
    }
  }

  getLatestScenarioDetail(scenarioDetails, userDetails, studyDetails): any {
    const scenarioNavigationMode = this.localStorage.get('tabInfo');
    const screenMapValue = this.checkCurrentScreenMap(scenarioNavigationMode.currentTab);
    const userDetail = this.localStorage.get('user-details');
    const payload = {
      input_request: {
        user_id: userDetails.user_id,
        study_id: studyDetails.study_id,
        study_feasibility: studyDetails.study_feasibility,
        scenario_id: scenarioDetails.scenario_id,
        user_access: scenarioDetails.user_access,
        user_group: scenarioDetails.user_group.length > 0 ?scenarioDetails.user_group : userDetails.app_user_group,
        current_screen: screenMapValue,
        last_module_activated: scenarioDetails.last_module_activated,
        is_cohort: studyDetails.is_cohort,
        theme_id: scenarioDetails.theme_id,
        study_created_by: studyDetails.study_created_by,
        study_type: studyDetails.study_type ?? 'New',
        study_secondary_owner: studyDetails.study_secondary_owner ? studyDetails.study_secondary_owner : ''
      },
    };
    return this.httpHandler.postHttpResults(appSetting.GET_LATEST_SCENARIO_DETAILS, payload).pipe(
      map((response: any) => {
        this.updatedLockStatus.next(response.result);
        this.mapLatestScenarioDetails(response.result);
        const latestDetail = response.result.latest_scenario_details;
        this.updateStudyStartDate.next(response.result);
        if (latestDetail.is_redirect) {
          scenarioNavigationMode.currentTab = this.reversCurrentScreenMap[latestDetail.redirect_screen_name];
          this.localStorage.set('tabInfo', scenarioNavigationMode);
        }
        return response;
      })
    );

  }

  mapLatestScenarioDetails(latestScenario): any {
    const scenario = this.localStorage.get('scenario-details');
    if (scenario) {
      Object.keys(scenario).forEach((key) => {
        if (latestScenario[key]) {
          scenario[key] = latestScenario[key];
        }
      });
      this.localStorage.remove('scenario-details');
      this.localStorage.set('scenario-details', scenario);
    }


    const inputRequest = this.localStorage.get('inputRequest');
    Object.keys(inputRequest.input_request).forEach((key) => {
      if (latestScenario[key]) {
        inputRequest.input_request[key] = latestScenario[key];
      }
    });
    inputRequest.input_request.target_completion_prob = latestScenario?.target_completion_prob
    this.localStorage.remove('inputRequest');
    this.localStorage.set('inputRequest', inputRequest);
  }

  lockUnlockAuthorityCheck(inputRequestValue): any {
    const userDetails: UserDetails = this.authService.getUserDetails();
    const studyDetails: StudyDetails = this.localStorage.get('study-details');
    const inputRequest = inputRequestValue;
    const scenarioLockStatus = inputRequest?.scenario_lock_status ? inputRequest?.scenario_lock_status : 'False';
    const scenarioLockBoolean = scenarioLockStatus === 'False' ? false : true;
    const scenerioDetails = inputRequest;
    let canLock: boolean;
    let canUnlock: boolean;
    //scenario owner ,study owner,study secndry owner , admin(superuser) for Locking
    if (scenerioDetails?.scenario_created_by === userDetails.user_id || studyDetails.study_secondary_owner === userDetails.user_id
      || studyDetails.study_created_by === userDetails.user_id || studyDetails.app_user_group === "superuser" || studyDetails.app_user_group === "gfl") {
      canLock = true;
    }

    //scenario liockedby , study scndary owner , study created by , admin(superuser) foer unlocking
    if (scenerioDetails?.scenario_created_by === userDetails.user_id || scenerioDetails?.lock_updated_by === userDetails.user_id || studyDetails.study_secondary_owner === userDetails.user_id
      || studyDetails.study_created_by === userDetails.user_id || studyDetails.app_user_group === "superuser" ||studyDetails.app_user_group === "gfl") {
      canUnlock = true;
    }
    return { 'scenarioLockBoolean': scenarioLockBoolean, 'canLock': canLock, 'canUnlock': canUnlock }
  }

  lockUnlockScenerio(lockStatusvalue, lockFor): any {
    const payload = this.lockUnlockPayload(lockStatusvalue, lockFor);
    return this.httpHandler.postHttpResults(appSetting.pre.STUDY_SCENERIO_LOCK, payload);
  }

  private lockUnlockPayload(lockStatusvalue, lockFor): LockUnlockscenarioRequest {
    let scenerioDetails = this.localStorage.get('scenario-details');
    const studyData = this.localStorage.get('study-details');
    const studyViewData = this.localStorage.get('study-view');
    const userDetails: UserDetails = this.authService.getUserDetails();
    const studyDetails: StudyDetails = studyData ? studyData : studyViewData;
    const inputRequest = this.localStorage.get('inputRequest');
    if (!scenerioDetails && lockFor === "scenario") {
      scenerioDetails = inputRequest.input_request;
    }
    // this.scenarioLockBoolean = !this.scenarioLockBoolean;
    return {
      input_request: {
        user_id: userDetails.user_id,
        study_id: studyDetails.study_id,
        study_feasibility: studyDetails.study_feasibility,
        scenario_id: scenerioDetails ? scenerioDetails.scenario_id : '',
        theme_id: studyDetails.theme_id,
        user_group: lockFor === "study" ? userDetails.app_user_group : scenerioDetails.user_group,
        study_created_by: studyDetails.study_created_by,
        study_secondary_owner: studyDetails.study_secondary_owner ? studyDetails.study_secondary_owner : '',
        scenario_created_by: scenerioDetails ? scenerioDetails.scenario_created_by : '',
        lock_for: lockFor,
        is_locked: lockStatusvalue
      }
    }
  }

  setLockStatusValue(scenarioLockStatus): any {
    const inputRequest = this.localStorage.get('inputRequest');
    inputRequest.input_request.scenario_lock_status = scenarioLockStatus;
    this.localStorage.set('inputRequest', inputRequest);
  }


}
