<div class="branding " style="height: 100vh; width:80px;">
    <!-- <div class="container d-flex justify-content-between px-0 h-100"> -->
        <!-- BRANDING BAR -->
        <div style="position: relative;top: 0px;" class=" mt-3">
        <div class="navbar-logo">
            <img style="margin-left: 12px;" src="assets/images/header/zs-logo.svg" width="42" />
            <!-- <header class="font-weight-normal text-white">E F</header>
            <img src="assets/images/home.svg" > -->
        </div>
        <div class=" navbar-div mt-3" [class.active]="isHomeActive" [ngClass]="{ 'router-link-active': router.url.includes('/modeling-predictions')}" routerLinkActive="active" (click)="activeDiv('modeling-predictions');navigate('/modeling-predictions')">
            <img class="ml-2 pl-3"src="assets/images/header/home.svg" >
            <span class="navbar-text" >Modeling & Predictions</span>
        </div>
        <div class="navbar-div " [class.active]="isAdminActive" *ngIf="userDetails['app_user_group'] === 'superuser'" routerLinkActive="active" [ngClass]="{ 'router-link-active': router.url.includes('/admin')}" (click)="activeDiv('admin');navigate('/admin')">
            <img class="ml-2 pl-3 mt-2" src="assets/images/header/admin-logo.svg" ><br>
            <span class="navbar-text pl-3">Admin</span>
        </div>

    </div>

    <div style="bottom: 10px;position: absolute;">
        <div class="ml-3 pl-3">
            <div class="d-flex mr-4 mb-4  align-items-center welcome" [ngbPopover]="popContent" placement="right">
                <!-- <span class="text-white mr-3"
          >Welcome
          <p class="mb-0 mt-n1 font-weight-bold text-white">
            {{ userDetails['username'] }}
          </p></span
        > -->
                <img src="assets/images/header/zs-icon-user-fill.svg" class="cursor-pointer"  (click)="onProfileClickMe()" />
                
                <!-- <ng-container *ngIf="show">
                    <div class="profile-window d-flex flex-column justify-content-between">
                        <div class="d-flex align-items-center justify-content-between pr-3">
                            <span>Welcome<br /><strong>{{ userDetails['username'] }}</strong></span>
                            <span class="fa fa-user-circle-o ml-2"></span>
                        </div>

                        <div class="d-flex align-items-center mb-2">
                            <span class="fa fa-sign-out mr-2 cursor-pointer" (click)="removeValue()"></span>
                            <span (click)="removeValue()" class="cursor-pointer">Logout</span>
                        </div>
                    </div>
                </ng-container> -->
            </div>
            <ng-template #popContent>
                
                 <ng-container *ngIf="show">
                    <div class="profile-window d-flex flex-column justify-content-between">
                        <div class="d-flex align-items-center justify-content-between pr-3">
                            <span>Welcome<br /><strong>{{ userDetails['username'] }}</strong></span>
                            <span class="fa fa-user-circle-o ml-2"></span>
                        </div>

                        <div class="d-flex align-items-center mb-2">
                            <span class="fa fa-sign-out mr-2 cursor-pointer" (click)="removeValue()"></span>
                            <span (click)="removeValue()" class="cursor-pointer">Logout</span>
                        </div>
                    </div>
                </ng-container>
            </ng-template>
            <div class="h-100 d-flex align-items-center mr-4 mb-4">
                <img src="assets/images/header/zs-icon-app-nav.svg" alt="">
            </div>
            <div class="h-100 d-flex align-items-center mb-4">
                <a href="https://zs-clinical-experience-bank.dev.zsservices.com" target="_self"> <img src="assets/images/header/zs-icon-help-fill.svg" alt="">
                </a>
                <!-- <span class="fa fa-question-circle mr-2 text-white ml-3 cursor-pointer"></span> -->
                <!-- <span class="text-white cursor-pointer">Help</span> -->
            </div>
        </div>
        </div>
    <!-- </div> -->
</div>

<!-- <div style="top:0px;postion:fixed;margin-left:80px;background-color: #ec7200e5; width: 250px;height: 40px;margin-top: 8px;text-align: center;">
    <p class="navbar-header">Enrollment Forecasting</p>
</div> -->
<!-- NAVIGATION BAR -->
<div class="navigation-bar w-100 bg-white" style="top: 0px;">
    <div class="navbar-flag" >
        <p class="navbar-header">Enrollment Forecasting</p>
    </div>
    <nav class="nav container d-flex align-items-end justify-content-between" style=" margin-top:10px; border-top: 1px solid #efeeef;padding-bottom: 20px;" >
        <div class="d-flex ml-4" *ngIf="!router.url.includes('/admin')">
            <span class="header-text">Modeling & Predictions</span>           
            
        </div>
        <div class="d-flex ml-4" *ngIf="router.url.includes('/admin')">
            <span class="header-text">Manage Users</span>           
        </div>
        <!-- <div class="d-flex ml-4" *ngIf="!router.url.includes('/modeling-predictions/view-scenarios') && !router.url.includes('/admin')">
            <a class="nav-link d-flex align-items-center font-15" [ngClass]="{ 'router-link-active': router.url.includes('/modeling-predictions')}" routerLinkActive="active" (click)="navigate('/modeling-predictions')"><span class="cursor-pointer">Modeling & Predictions</span></a>
             <a class="nav-link d-flex align-items-center font-15" *ngIf="userDetails['app_user_group'] === 'superuser'" routerLinkActive="active" [ngClass]="{ 'router-link-active': router.url.includes('/admin')}" (click)="navigate('/admin')"><span class="cursor-pointer">Admin</span></a> 
        </div> -->
        <div class="d-flex">
            <div *ngIf="showNotificationIcon && !refreshForecastNotifIcon" class="mb-2" (click)="openNotification()" [ngClass]="{ 'notif-disable': isLocked}">
                <img src="assets/images/icon_info.png">
            </div>
        </div>
        <!-- <a class="nav-link d-flex align-items-center font-15" [ngClass]="{'router-link-active': router.url.includes('/site-level-study-data')}"
          (click)="navigate('/site-level-study-data')"><span>Site Level Study Data</span></a>-->
    </nav>
</div>
<ctfo-notification-panel *ngIf="checkVisibility()" [notificationConfig]="notificationConfig" (emitCancelFilterResult)="cancelFilter($event)">
</ctfo-notification-panel>