export const CHART_FONT_FAMILY = "'Open Sans', sans-serif";
export const SPLINE_CHART_COLORS = ['#36756A', '#39ABED', '#05A6DC', '#000000', '#AFE1FF', '#4682B4'];

export const ENROLLMENT_SERIES_ONGOING = [
  {
    name: 'Actual Patient Enrollment',
    data: [],
    color: SPLINE_CHART_COLORS[0],
    stickyTracking: false,
    lineWidth: 3,
  },
  {
    name: 'Predicted Patient Enrollment',
    data: [],
    color: SPLINE_CHART_COLORS[1],
    stickyTracking: false,
    lineWidth: 3,
  },
  {
    name: '95% Lower Confidence',
    data: [],
    color: SPLINE_CHART_COLORS[2],
    stickyTracking: false,
    lineWidth: 2,
    dashStyle: 'Dash',
  },
  // {
  //   name: '85% Probablity',
  //   data: [],
  //   color: SPLINE_CHART_COLORS[5],
  //   stickyTracking: false,
  //   lineWidth: 3,
  // },
  {
    name: '95% Upper Confidence',
    data: [],
    color: SPLINE_CHART_COLORS[2],
    stickyTracking: false,
    lineWidth: 2,
    dashStyle: 'Dash',
  },
];

export const ENROLLMENT_SERIES_NEW = [
  {
    name: 'Predicted Patient Enrollment',
    data: [],
    color: SPLINE_CHART_COLORS[1],
    stickyTracking: false,
    lineWidth: 3,
  },
  {
    name: '95% Lower Confidence',
    data: [],
    color: SPLINE_CHART_COLORS[2],
    stickyTracking: false,
    lineWidth: 2,
    dashStyle: 'Dash',
  },
  // {
  //   name: '85% Probaility',
  //   data: [],
  //   color: SPLINE_CHART_COLORS[5],
  //   stickyTracking: false,
  //   lineWidth: 3,
  // },
  {
    name: '95% Upper Confidence',
    data: [],
    color: SPLINE_CHART_COLORS[2],
    stickyTracking: false,
    lineWidth: 2,
    dashStyle: 'Dash',
  },
];

export const CHART_COLORS = {
  color: '#454250',
  lineColor: '#888888',
  gridLineColor: '#E7E7E7',
  plotLineColor: '#000000',
  plotLineColorPurple: '#A880A1',
};

export const CHART_CONFIG = {
  xAxisLabelStyle: {
    fontSize: '14px',
    lineHeight: '16px',
    color: CHART_COLORS.color,
    opacity: 0.7,
  },
  yAxisLabelStyle: {
    fontSize: '13px',
    lineHeight: '15px',
    color: CHART_COLORS.color,
    opacity: 0.7,
  },
  axisTitleStyle: {
    fontSize: '15px',
    lineHeight: '18px',
    color: CHART_COLORS.color,
  },
};

export const PERC_LINE_CHART = {
  chart: {
    type: 'line',
    spacingLeft: 0,
    backgroundColor: 'transparent',
    spacingBottom: 0,
    spacingRight: 0,
    hover: {
      enabled: false,
    },
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
  },
  title: {
    text: null,
  },
  tooltip: {
    backgroundColor: 'white',
    useHTML: true,
    borderRadius: 5,
    borderWidth: 0,
    style: {
      color: CHART_COLORS.color,
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    title: {
      text: '',
      style: CHART_CONFIG.axisTitleStyle,
    },
    labels: {
      style: CHART_CONFIG.xAxisLabelStyle,
    },
    scrollbar: {
      enabled: false,
    },
    // tickInterval: 25,
    // min: 0,
    // max: 100,
    lineColor: CHART_COLORS.lineColor,
    lineWidth: 1,
    tickLength: 0,
    // endOnTick: true,
  },
  yAxis: {
    title: {
      text: 'Sites Activated',
      style: CHART_CONFIG.axisTitleStyle,
    },
    labels: {
      style: CHART_CONFIG.yAxisLabelStyle,
    },
    tickInterval: 25,
    tickLength: 0,
    min: 0,
    max: 100,
    lineColor: CHART_COLORS.lineColor,
    gridLineColor: CHART_COLORS.gridLineColor,
  },
  plotOptions: {
    line: {
      lineWidth: 2,
      states: {
        enabled: false,
        hover: {
          enabled: false,
        },
      },
      marker: {
        enabled: false,
      },
      stickyTracking: false,
    },
  },
  legend: {
    enabled: false,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  data: {},
  noData: {
    style: {
      fontSize: '15px',
      color: CHART_COLORS.color,
    },
    attr: {
      zIndex: 1,
    },
  },
};

export const ENROLLMENT_TIME_CHART = {
  chart: {
    hover: {
      enabled: false,
    },
    spacingTop: 12,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
    type: 'spline',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  plotOptions: {
    series: {
      getExtremesFromAll: true,
      marker: {
        enabled: false,
      },
      states: {
        enabled: false,
        hover: {
          enabled: false,
        },
      },
      stickyTracking: false,
    },
  },
  series: [],
  time: {
    useUTC: false,
  },
  title: {
    text: null,
  },
  tooltip: {
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 0,
    style: {
      color: '#454250',
      fontSize: '14px',
      lineHeight: '16px',
    },
    useHTML: true,
  },
  xAxis: {
    endOnTick: false,
    gridLineColor: CHART_COLORS.gridLineColor,
    labels: {
      style: CHART_CONFIG.xAxisLabelStyle,
    },
    lineColor: CHART_COLORS.lineColor,
    min: 0,
    plotLines: [
      {
        color: CHART_COLORS.plotLineColor,
        dashStyle: 'Dash',
        label: {
          align: 'center',
          rotation: 360,
          style: {
            color: CHART_COLORS.color,
            fontSize: '14px',
          },
          text: '',
          x: 0,
          y: -2,
        },
        width: 2,
        zIndex: 8,
      },
      {
        color: CHART_COLORS.plotLineColorPurple,
        dashStyle: 'Dash',
        label: {
          align: 'center',
          rotation: 360,
          style: {
            color: CHART_COLORS.color,
            fontSize: '14px',
          },
          text: '',
          x: 0,
          y: -2,
        },
        width: 2,
        zIndex: 8,
      },
    ],
    scrollbar: {
      enabled: false,
    },
    startOnTick: false,
    tickLength: 0,
    title: {
      style: CHART_CONFIG.axisTitleStyle,
      text: 'Time (Months)',
    },
    type: 'linear',
  },
  yAxis: {
    gridLineColor: CHART_COLORS.gridLineColor,
    labels: {
      format: '{value}',
      style: CHART_CONFIG.yAxisLabelStyle,
    },
    lineColor: CHART_COLORS.gridLineColor,
    tickAmount: 5,
    tickLength: 10,
    title: {
      style: CHART_CONFIG.axisTitleStyle,
      text: 'Predicted Patient Enrollment',
      x: -5,
    },
  },
};

export const PROBABILITY_TIME_CHART = {
  chart: {
    hover: {
      enabled: false,
    },
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    spacingTop: 12,
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
    type: 'line',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  noData: {
    attr: {
      zIndex: 1,
    },
    style: {
      color: CHART_COLORS.color,
      fontSize: '15px',
    },
  },
  plotOptions: {
    line: {
      color: SPLINE_CHART_COLORS[1],
      getExtremesFromAll: true,
      lineWidth: 2,
      marker: {
        enabled: false,
      },
      states: {
        enabled: false,
        hover: {
          enabled: false,
        },
      },
      stickyTracking: false,
    },
  },
  series: [
    {
      data: [],
      stickyTracking: false,
      type: 'line',
    },
  ],
  title: {
    text: null,
  },
  tooltip: {
    backgroundColor: 'white',
    borderRadius: 5,
    borderWidth: 0,
    style: {
      color: CHART_COLORS.color,
      fontSize: '14px',
      lineHeight: '16px',
    },
    useHTML: true,
  },
  xAxis: {
    gridLineColor: CHART_COLORS.gridLineColor,
    labels: {
      style: CHART_CONFIG.xAxisLabelStyle,
    },
    lineColor: CHART_COLORS.lineColor,
    lineWidth: 1,
    plotLines: [
      {
        color: CHART_COLORS.plotLineColor,
        dashStyle: 'Dash',
        label: {
          // verticalAlign: 'top',
          align: 'center',
          rotation: 360,
          style: {
            color: CHART_COLORS.color,
            fontSize: '14px',
          },
          text: '',
          x: 0,
          y: -2,
        },
        width: 2,
        zIndex: 8,
      },
    ],
    scrollbar: {
      enabled: false,
    },
    showLastLabel: true,
    startOnTick: false,
    tickLength: 0,
    title: {
      style: CHART_CONFIG.axisTitleStyle,
      text: 'Time (Months)',
    },
  },
  yAxis: {
    gridLineColor: CHART_COLORS.gridLineColor,
    labels: {
      format: '{value}' + '%',
      style: CHART_CONFIG.yAxisLabelStyle,
    },
    max: 100,
    min: 0,
    tickInterval: 25,
    title: {
      // align: 'right',
      style: CHART_CONFIG.axisTitleStyle,
      text: 'Probability',
      x: -5,
    },
  },
};

export const SITE_RANDOMIZATION_CHART_CONFIG = {
  chart: {
    hover: {
      enabled: false,
    },
    spacingLeft: 0,
    spacingRight: 0,
    spacingBottom: 0,
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
    type: 'line',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  plotOptions: {
    series: {
      enableMouseTracking: false,
      getExtremesFromAll: true,
      marker: {
        enabled: false,
      },
      states: {
        enabled: false,
        hover: {
          enabled: false,
        },
      },
      stickyTracking: false,
    },
  },
  series: [],
  title: {
    text: null,
  },
  tooltip: {
    enabled: false,
  },
  xAxis: {
    gridLineColor: CHART_COLORS.gridLineColor,
    lineColor: CHART_COLORS.lineColor,
    labels: {
      style: CHART_CONFIG.xAxisLabelStyle,
    },
    min: 0,
    scrollbar: {
      enabled: false,
    },
    tickLength: 0,
    // tickInterval: 20,
    title: {
      style: CHART_CONFIG.axisTitleStyle,
      text: 'Site Randomization Enrollment Rate (PSM)',
    },
  },
  yAxis: {
    gridLineColor: CHART_COLORS.gridLineColor,
    labels: {
      format: '{value}',
      style: CHART_CONFIG.yAxisLabelStyle,
    },
    lineColor: CHART_COLORS.lineColor,
    // tickInterval: 100,
    min: 0,
    tickAmount: 5,
    // maxTick: 5,
    title: {
      style: CHART_CONFIG.axisTitleStyle,
      text: 'Site Frequency',
      x: -5,
    },
  },
};

export const DISTRIBUTION_TARGET_CHART_CONFIG = {
  chart: {
    type: 'column',
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
    spacingLeft: 0,
    spacingRight: 0,
    spacingBottom: 0,
  },
  title: {
    text: null,
  },
  xAxis: {
    title: {
      text: 'Target Completion Time (Months)',
      style: CHART_CONFIG.axisTitleStyle,
    },
    labels: {
      style: {
        fontSize: '14px',
        opacity: 0.7,
        color: '#454250',
      },
    },
    scrollbar: {
      enabled: false,
    },
    gridLineColor: CHART_COLORS.gridLineColor,
    lineColor: CHART_COLORS.lineColor,
    categories: [],
    crosshair: true,
    tickLength: 0,
  },
  labels: {
    style: {
      fontSize: '14px',
      lineHeight: '16px',
      color: '#454250',
      opacity: 0.7,
    },
  },
  yAxis: {
    min: 0,
    allowDecimals: false,
    lineColor: CHART_COLORS.lineColor,
    gridLineColor: CHART_COLORS.gridLineColor,
    title: {
      text: 'Frequency',
      style: CHART_CONFIG.axisTitleStyle,
      x: -5,
    },
    // tickInterval: 5,
    tickAmount: 5,
    // maxTick: 5,
    labels: {
      format: '{value}',
      style: {
        fontSize: '13px',
        opacity: 0.7,
        color: '#454250',
      },
    },
  },
  legend: {
    enabled: false,
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    backgroundColor: 'white',
    useHTML: true,
    borderRadius: 5,
    borderWidth: 0,
    style: {
      color: '#454250',
      fontSize: '14px',
      lineHeight: '16px',
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  plotOptions: {
    column: {
      color: '#39ABED',
    },
  },
};

// const DATE_LINE_CHART_OPTIONS = {
//   chart: {
//     type: 'line',
//     spacingLeft: 0,
//     backgroundColor: 'transparent',
//     spacingBottom: 0,
//     spacingRight: 0,
//     hover: {
//       enabled: false,
//     },
//     style: {
//       fontFamily: CHART_FONT_FAMILY,
//     },
//   },
//   title: {
//     text: null,
//   },
//   tooltip: {
//     backgroundColor: 'white',
//     useHTML: true,
//     borderRadius: 5,
//     borderWidth: 0,
//     style: {
//       color: CHART_COLORS.color,
//       fontSize: '14px',
//       lineHeight: '16px',
//     },
//   },
//   credits: {
//     enabled: false,
//   },
//   xAxis: {
//     type: 'datetime',
//     tickInterval: undefined,
//     dateFormat: 'YYYY/mm/dd',
//     title: {
//       text: 'Date of Activation',
//       style: CHART_CONFIG.axisTitleStyle,
//     },
//     labels: {
//       style: CHART_CONFIG.xAxisLabelStyle,
//       align: 'right',
//     },
//     scrollbar: {
//       enabled: false,
//     },
//     lineColor: CHART_COLORS.lineColor,
//     lineWidth: 1,
//     tickLength: 0,
//   },
//   yAxis: {
//     title: {
//       text: 'Sites Activated',
//       style: CHART_CONFIG.axisTitleStyle,
//     },
//     labels: {
//       style: CHART_CONFIG.yAxisLabelStyle,
//     },
//     tickInterval: 25,
//     tickLength: 0,
//     min: 0,
//     max: 100,
//     lineColor: CHART_COLORS.lineColor,
//     gridLineColor: CHART_COLORS.gridLineColor,
//   },
//   plotOptions: {
//     line: {
//       lineWidth: 2,
//       states: {
//         enabled: false,
//         hover: {
//           enabled: false,
//         },
//       },
//       marker: {
//         enabled: false,
//       },
//       stickyTracking: false,
//     },
//   },
//   legend: {
//     enabled: false,
//   },
//   navigation: {
//     buttonOptions: {
//       enabled: false,
//     },
//   },
//   data: {},
//   noData: {
//     style: {
//       fontSize: '15px',
//       color: CHART_COLORS.color,
//     },
//     attr: {
//       zIndex: 1,
//     },
//   },
// };
