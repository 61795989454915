import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimalNumberFormat' })
export class DecimalNumberFormatPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let b;
    if (typeof value == 'string') {
      const arr = value.split('.');
      if (arr.length > 1) {
        b = value.toString().substring(0, value.toString().indexOf('.') + 4);
      } else {
        b = value;
      }
    } else if (value.toString().includes('.')) {
      b = value.toString().substring(0, value.toString().indexOf('.') + 4);
    } else {
      b = value.toString();
    }
    return b;
  }
}
