import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from '@thisissoon/angular-infinite-scroll';

import { TableComponent } from './table.component';



@NgModule({
  declarations: [TableComponent],
  exports: [TableComponent],
  imports: [CommonModule, FormsModule, InfiniteScrollModule],
})
export class TableModule { }
