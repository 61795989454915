import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

import { DialogService } from './dialog.service';

@Directive({
  selector: '[ctfoDialogClose]',
})
export class DialogCloseDirective {
  @Output() ctfoDialogClose = new EventEmitter<void>();

  constructor(private dialog: DialogService) {}

  @HostListener('click')
  onClick() {
    setTimeout(() => {
      this.ctfoDialogClose.emit();
      this.dialog.close();
    }, 50);
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void {
    const ESCAPE_KEYCODE = 27;
    if (event.keyCode === ESCAPE_KEYCODE) {
      this.ctfoDialogClose.emit();
      this.dialog.close();
    }
    if (event.which === 13 || event.which === 32) {
      event.preventDefault();
    }
  }
}
