import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from '../app-state.service';
import { HasUserRoleService } from '../has-user-role.service';


import { Utils } from '../utils';

@Component({
  selector: 'ctfo-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnChanges {
  // Mandatory
  @Input() tableColumns = [];
  @Input() tableScrollableColumns = [];
  @Input() tableEditableColumns = [];
  @Input() tableData = [];

  // Optional
  @Input() sortOn: string;
  @Input() sortType = 'asc';
  @Input() tableIdentifier: string;
  @Input() errorMessage: string;

  // Styles
  @Input() tableStyle = {};
  @Input() noScrollWidth: any;
  @Input() scrollWidth = 'calc(100% - 80px)';

  // Misc
  @Input() isHeaderSticky = true;
  @Input() isHeaderVisible = true;
  @Input() isCustomizable = false;
  @Input() isDebugMode = true;
  @Input() isLoading = false;
  @Input() isLazyLoadDisabled = false;
  @Input() checkTextDisable = false;

  @Output() onsort = new EventEmitter();
  @Output() ontablescrollend = new EventEmitter();
  @Output() dataclick = new EventEmitter();
  @Output() saveasdefault = new EventEmitter();

  // Internal Variables
  tables = {};
  internal_error_message;

  constructor(private appState: AppStateService, private route: Router, private accessService: HasUserRoleService) {}

  ngOnChanges(): void {
    if (this.isDebugMode === undefined) {
      this.isDebugMode = false;
    }

    if (this.isLoading === undefined) {
      this.isLoading = false;
    }

    if (this.tableIdentifier && this.isCustomizable) {
      AppStateService.appstate.subscribe(val => {
        if (val['tables']) {
          this.tables = val['tables'];

          if (val['tables'][this.tableIdentifier]) {
            if (JSON.stringify(val['tables'][this.tableIdentifier]) !== JSON.stringify(this.tableEditableColumns)) {
              this.tables[this.tableIdentifier] = this.tableEditableColumns;
              this.appState.setGlobalState('tables', this.tables);
            }
          }
        } else {
          this.tables[this.tableIdentifier] = this.tableEditableColumns;
          this.appState.setGlobalState('tables', this.tables);
        }
      });
    } else {
      this.appState.removeGlobalState('tables');
    }
    setTimeout(() => {
      this.adjustPadForScroll('');
    }, 0);
  }

  adjustPadForScroll(ele): any {
    const elements = document.getElementsByClassName('no-scroll-table');
    if (elements.length) {
      for (let i = 0; i < elements.length; i++) {
        const ele = elements[i];
        const tableContainer = $('.table-data-container', ele);
        const scrollHeight = tableContainer[0]?.scrollHeight;
        const clientHeight = tableContainer[0]?.clientHeight;
        if (scrollHeight > clientHeight) {
          const headerElement = $('.table-header', ele);
          const scrollWidth = tableContainer[0].offsetWidth - tableContainer[0].clientWidth;
          $(headerElement).css('padding-right', scrollWidth + 'px');
        }
      }
    }
  }

  onButtonClick(column, row, icon?): void {
    event.stopPropagation();
    this.dataclick.emit({ column: column, data: row, params: icon });
  }

  onButtonClickForCheckbox(column, row, ele): void {
    const c = Utils.assignObject(column);
    c.columndatakey = c.columndatakey + '_checkbox';
    event.stopPropagation();
    this.dataclick.emit({ column: c, data: row, params: ele });
  }

  onSearch(event, column, row): void {
    this.onButtonClick(column, row, event);
  }

  onDropdownScrollEnd(column, row): void {
    const temp = {
      event: 'dropdownscroll',
    };
    this.onButtonClick(column, row, temp);
  }

  onSelectedItemChange(column, row): void {
    const temp = {
      event: 'itemselected',
    };
    this.onButtonClick(column, row, temp);
  }

  sortData(columnkey, columntype?: string): void {
    if (columntype !== 'actionicon') {
      this.onsort.emit({ sortOn: columnkey, sortType: this.sortType });
    }
  }

  saveAsDefault(event): void {
    const payload = {
      tableIdentifier: this.tableIdentifier,
      columns: event,
    };
    this.saveasdefault.emit(payload);
  }

  onScrollEnd(): void {
    this.ontablescrollend.emit();
  }

  scrollSlicedTable(direction = 'left'): void {
    const scrolltable = document.getElementById('scroll-table');
    if (direction === 'left') {
      scrolltable.scrollLeft += 40;
    }

    if (direction === 'right') {
      scrolltable.scrollLeft -= 40;
    }
  }

  redirectToUrl(): void {
    this.route.navigate(['/assessment/scenario/scenario-builder/setup']);
  }
}
