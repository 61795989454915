import { GridColumnModel } from './../grid';

export const MANAGE_USER_GRID_CONFIG: GridColumnModel[] = [
  {
    columntitle: 'Last Name',
    columndatakey: 'last_name',
    columnwidth: '11%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'First Name',
    columndatakey: 'first_name',
    columnwidth: '11%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Email',
    columndatakey: 'email',
    columnwidth: '18%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'User ID',
    columndatakey: 'user_access_id',
    columnwidth: '13%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Last Login Date',
    columndatakey: 'last_login_date',
    columnwidth: '12.5%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'User Role',
    columndatakey: 'user_group',
    columnwidth: '12.5%',
    columntype: 'plaintext',
    style: { color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'User Group',
    columndatakey: 'client_role',
    columnwidth: '9%',
    columntype: 'plaintext',
    style: { color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Edit',
    columndatakey: 'edit_user',
    columnwidth: '6%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'edit',
        iconsrc: 'assets/images/accordion/pencil.svg',
        action: 'edit',
      },
    ],
  },
  {
    columntitle: 'Remove User',
    columndatakey: 'remove_user',
    columnwidth: '8%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'Delete',
        iconsrc: 'assets/images/shared/cancel.svg',
        action: 'delete',
      },
    ],
  },
].map(
  col =>
    new GridColumnModel(
      col.columntitle,
      col.columndatakey,
      col.columnwidth,
      col.columntype,
      col.style,
      col.isdisplayed,
      col.dropdownitems,
      col.actionicons
    )
);

export const MANAGE_USER_GRID_SORTABLE_COLUMNS = [
  'last_name',
  'first_name',
  'email',
  'user_access_id',
  'last_login_date',
];

export const SCENARIO_DETAILS_GRID_SORTABLE_COLUMNS = [
  'study_id',
  'scenario_name',
  'therapeutic_area',
  'country',
  'scenario_created',
  'last_updated',
];

export const MDA_USER_GRID_CONFIG = [
  {
    columntitle: 'Study Id',
    columndatakey: 'study_id',
    columnwidth: '12%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Scenario Name',
    columndatakey: 'scenario_name',
    columnwidth: '16%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Therapeutic Area',
    columndatakey: 'therapeutic_area',
    columnwidth: '13%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Country',
    columndatakey: 'country',
    columnwidth: '15%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'view Country',
        iconsrc: 'assets/images/accordion/pencil.svg',
        action: 'edit',
      },
    ],
  },
  {
    columntitle: 'Scenario Created',
    columndatakey: 'scenario_created',
    columnwidth: '11%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Last Updated',
    columndatakey: 'last_updated',
    columnwidth: '10%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  // {
  //   columntitle: 'Write Access',
  //   columndatakey: 'write_access',
  //   columnwidth: '7%',
  //   columntype: 'checkbox',
  //   style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
  //   isdisplayed: true,
  //   dropdownitems: [],
  // },
  {
    columntitle: 'Due Date',
    columndatakey: 'due_date',
    columnwidth: '10%',
    columntype: 'datepicker',
    style: { 'text-align': 'center' },
    isdisplayed: true,
    dropdownitems: [],
  },
  {
    columntitle: 'Remove Access',
    columndatakey: 'remove_access',
    columnwidth: '11%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250', 'margin-left': '10px' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'Delete',
        iconsrc: 'assets/images/shared/cancel.svg',
        action: 'delete',
      },
    ],
  },
].map(
  col =>
    new GridColumnModel(
      col.columntitle,
      col.columndatakey,
      col.columnwidth,
      col.columntype,
      col.style,
      col.isdisplayed,
      col.dropdownitems,
      col.actionicons
    )
);

export const GFL_USER_GRID_CONFIG = [
  {
    columntitle: 'Study Id',
    columndatakey: 'study_id',
    columnwidth: '12%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Scenario Name',
    columndatakey: 'scenario_name',
    columnwidth: '24%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Therapeutic Area',
    columndatakey: 'therapeutic_area',
    columnwidth: '12%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Scenario Created',
    columndatakey: 'scenario_created',
    columnwidth: '12%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Last Updated',
    columndatakey: 'last_updated',
    columnwidth: '10%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Write Access',
    columndatakey: 'write_access',
    columnwidth: '10%',
    columntype: 'checkbox',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
  },
  {
    columntitle: 'Due Date',
    columndatakey: 'due_date',
    columnwidth: '10%',
    columntype: 'datepicker',
    style: { 'text-align': 'center' },
    isdisplayed: true,
    dropdownitems: [],
  },
  {
    columntitle: 'Remove Access',
    columndatakey: 'remove_access',
    columnwidth: '10%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'Delete',
        iconsrc: 'assets/images/shared/cancel.svg',
        action: 'delete',
      },
    ],
  },
].map(
  col =>
    new GridColumnModel(
      col.columntitle,
      col.columndatakey,
      col.columnwidth,
      col.columntype,
      col.style,
      col.isdisplayed,
      col.dropdownitems,
      col.actionicons
    )
);

export const SHARE_SCENARIO_LIST = [
  {
    columntitle: 'User Name',
    columndatakey: 'user_name',
    columnwidth: '20%',
    columntype: 'plaintext',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'User Role',
    columndatakey: 'user_group_dropdown',
    columnwidth: '20%',
    columntype: 'dropdown',
    placeholder: '',
    style: {},
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Country',
    columndatakey: 'country',
    columnwidth: '20%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'view Country',
        iconsrc: 'assets/images/accordion/pencil.svg',
        action: 'edit',
      },
    ],
  },

  {
    columntitle: 'Access Rights',
    columndatakey: 'write_access',
    columnwidth: '15%',
    columntype: 'checkbox',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
  },
  {
    columntitle: 'Due Date',
    columndatakey: 'due_date',
    columnwidth: '15%',
    columntype: 'datepicker',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: '',
    columndatakey: 'remove_access',
    columnwidth: '10%',
    columntype: 'actionicon',
    style: { 'text-align': 'center', 'justify-content': 'center', color: '#454250' },
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [
      {
        title: 'Delete',
        iconsrc: 'assets/images/shared/delete.svg',
        action: 'delete',
      },
    ],
  },
].map(
  col =>
    new GridColumnModel(
      col.columntitle,
      col.columndatakey,
      col.columnwidth,
      col.columntype,
      col.style,
      col.isdisplayed,
      col.dropdownitems,
      col.actionicons
    )
);

export const COUNTRY_LIST = [
  {
    columntitle: 'Country List',
    columndatakey: 'country_name',
    columnwidth: '50%',
    columntype: 'plaintext',
    isdisplayed: true,
    dropdownitems: [],
    actionicons: [],
  },
  {
    columntitle: 'Grant Access',
    columndatakey: 'read_access',
    columnwidth: '25%',
    columntype: 'checkbox',
    style: { 'text-align': 'center', 'justify-content': 'center' },
    isdisplayed: false,
    dropdownitems: [],
  },
  {
    columntitle: 'Write Access',
    columndatakey: 'write_access',
    columnwidth: '25%',
    columntype: 'checkbox',
    style: { 'text-align': 'center', 'justify-content': 'center' },
    isdisplayed: true,
    dropdownitems: [],
  },
].map(
  col =>
    new GridColumnModel(
      col.columntitle,
      col.columndatakey,
      col.columnwidth,
      col.columntype,
      col.style,
      col.isdisplayed,
      col.dropdownitems,
      col.actionicons
    )
);
