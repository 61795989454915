import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { HasUserRoleService } from '@app/shared/dependency/has-user-role.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ViewEnrollmentService } from '@app/featured-modules/modeling-predictions/modules/modeling-predictions-shared/view-enrollment/view-enrollment.service';

@Component({
  selector: 'app-textbox-popup',
  templateUrl: './textbox-popup.component.html',
  styleUrls: ['./textbox-popup.component.scss'],
})

export class TextboxPopupComponent implements OnInit{
  @Input() modal: NgbModalRef;
  @Input() modalRef: any;
  @Input() modalTitle;
  @Input() okButtonText;
  @Input() cancelButtonText;
  @Input() showOkButton: boolean;
  @Input() showCancelButton: boolean;
  @Input() modalData;
  @Input() modalInputColumnName;


  constructor(
    private viewEnrollmentService: ViewEnrollmentService,
  ){}

  ngOnInit(): void {

  }

  onProceed(action, inputValue?){
    if(action === 'OK'){
      switch (this.modalRef) {
        case 'addReason': {
          const updatedData = {site_id: this.modalData.site_id, drop_reason: inputValue.value.length ? inputValue.value : this.modalData.drop_reason};
          this.viewEnrollmentService.addReasonTextboxOpen.next({action: false, data: updatedData});
          break;
        }
      }
    } else {

    }
    this.modal.close();
  }
}
