export const statusMetaData = [
  {
    title: 'Preparing Data',
    status: {
      loading: ['preparing', 'cloned', 'queued', 'launched'],
      done: ['started', 'in-progress', 'completed', 'error-enrollment', 'failed'],
      error: ['error-preparing', 'stopping', 'stopped'],
    },
  },
  {
    title: 'Running Enrollment Models',
    status: {
      loading: ['started', 'in-progress'],
      done: ['completed'],
      error: ['error-enrollment', 'failed', 'stopping', 'stopped'],
    },
  },
  {
    title: 'Calculating Results',
    status: {
      loading: [],
      done: ['completed'],
      error: ['error-enrollment', 'failed', 'stopping', 'stopped'],
    },
  },
];

export const message = {
  MODEL_RUN_SUCCESS: 'Enrollment Predictions Generated Successfully !',
  MODEL_RUN_FAILED: 'Failed to generate the predictions.Please modify the inputs',
  HEADER_MESSAGE: 'This process could take a few minutes to complete.',
  RETURN_MESSAGE: 'You may return to homepage & check back to view predictions generated.',
  FILE_UPLOAD_RETURN_MESSAGE: 'You may return to homepage & check the progress.',
};
