import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { CustomChartOptions } from './custom-chart.model';
import { SortingService } from '@app/shared/components/grid/sorting-service';

@Component({
  selector: 'app-custom-chart',
  templateUrl: './custom-chart.component.html',
  styleUrls: ['./custom-chart.component.scss'],
})
export class CustomChartComponent implements OnInit, OnChanges {

  @Input() chartOptions = new CustomChartOptions();
  @Input() showScrollBar = true;

  @Output() sortClickEvent = new EventEmitter();

  sortOrder = 'desc';
  currentColumn: any;

  constructor(private sortService: SortingService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartOptions && !changes.chartOptions.isFirstChange()) {
      const sortAlwaysColumns = this.chartOptions.columns.find(x => x.sortAlwaysOn);
      if (sortAlwaysColumns) {
        this.sortOrder = sortAlwaysColumns.sortOrder;
        this.sortBySameOrder(sortAlwaysColumns);
      } else {
        this.sortBySameOrder(this.currentColumn);
      }
    } else {
      const defaultSortColumn = this.chartOptions.columns.find(x => x.sorted);
      if (defaultSortColumn) {
        this.sortBySameOrder(defaultSortColumn);
      }
    }
    this.adjustPadForScroll();
  }


  onSort(column: any): void {
    this.chartOptions.columns.map(x => {
      if (x.name === column.name) {
        x.sorted = true;
        x.isSortable = true;
      } else {
        x.sorted = false;
        x.isSortable = false;
      }
    });

    if (this.sortOrder === 'asc') {
      this.sortOrder = 'desc';
      this.chartOptions.data = this.sortService.commonReverseSortByKey(this.chartOptions.data, column.key, column.isNumber);
    } else {
      this.sortOrder = 'asc';
      this.chartOptions.data = this.sortService.commonSortByKey(this.chartOptions.data, column.key, column.isNumber);
    }

    this.currentColumn = column;
  }


  sortBySameOrder(column: any): void {
    this.chartOptions.columns.map(x => {
      if (x.name === column.name) {
        x.sorted = true;
        x.isSortable = true;
      } else {
        x.sorted = false;
        x.isSortable = false;
      }
    });


    if (this.sortOrder === 'desc') {
      this.chartOptions.data = this.sortService.commonReverseSortByKey(this.chartOptions.data, column.key, column.isNumber);
    } else {
      this.chartOptions.data = this.sortService.commonSortByKey(this.chartOptions.data, column.key, column.isNumber);
    }
    this.currentColumn = column;
  }

  adjustPadForScroll(): void {
    const elements: any = document.getElementsByClassName('table');
    if (elements.length) {
      for (const ele of elements) {
        const tableBody = $('tbody', ele);
        const headerElement = $('.table-header-tr', ele);
        const scrollHeight = tableBody[0].scrollHeight;
        const clientHeight = tableBody[0].clientHeight;
        if (scrollHeight > clientHeight) {
          const scrollWidth = tableBody[0].offsetWidth - tableBody[0].clientWidth;
          $(headerElement).attr('style', 'padding-right:' + scrollWidth + 'px !important');
        }
      }
    }
  }
}

