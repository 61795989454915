<div class="modal-header border-bottom-0 py-0 d-flex align-items-center">
  <h5 class="modal-title font-18 d-flex align-items-center">Customize Activation Curve <p
      class="font-18 align-items-center" style="margin-top:14px;" *ngIf="geoLevelName"> - {{geoLevelName}}</p>
  </h5>
</div>
<div class="modal-body pt-0">
  <div class="loader loading-circle" *ngIf="savingInProgress || tabDataLoading">
    <i class="fa fa-spinner fa-spin"></i>
  </div>
  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link tab-basic cursor-pointer" (click)="openTabSwitchPopup('global')"
        [ngClass]="{'tab-active': activeTab === 'global', 'tab-inactive': activeTab !== 'global', 'disableTab': popupOpen }">
        <!-- <span> -->
        Global Level
        <!-- </span> -->
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link tab-basic cursor-pointer" (click)="openTabSwitchPopup('country')"
        [ngClass]="{'tab-active': activeTab === 'country', 'tab-inactive': activeTab !== 'country', 'disableTab': !enableCountryTab() || popupOpen }">
        <!-- <span> -->
        Country Level
        <!-- </span> -->
      </a>
    </li>
  </ul>
  <!-- <div class="clearfix"></div> -->
  <div class="result-container px-3 pt-3 pb-2 w-100">
    <!-- <div> -->
    <div class="d-flex justify-content-between h-100">
      <div class="col-5 pl-0 pr-3 h-100">
        <div class="row mx-0 h-100">
          <div class="table country-level h-100" id="main-table">
            <div class="table-header d-flex align-items-center">
              <div class="column font-weight-bold d-flex">Geography</div>
              <div class="column font-weight-bold d-flex">Activation Curve</div>
              <div class="column font-weight-bold d-flex">View</div>
            </div>
            <div class="table-body geo-level overflow-auto" [ngClass]="{'scroll-div1': geographyGridData.length > 15}"
              [style.max-height]="'calc(100% - 36px)'" *ngIf="!tabDataLoading">
              <div class="line d-flex" *ngFor="let row of geographyGridData; index as i"
                [ngClass]="activeIndex === i ? 'activeRow': '' ">

                <div class="column d-flex"><span class="txt-ellps text-left">{{row.geographyName}}</span></div>

                <div class="column d-flex">
                  <span *ngIf="row.siteActivationMethod && globalData.siteActivationMethod">Historical</span>
                  <span *ngIf="row.siteActivationMethod && !globalData.siteActivationMethod">Global Custom</span>
                  <span *ngIf="!row.siteActivationMethod">Customized</span>
                </div>

                <div class="column d-flex pl-2">
                  <span class="cursor-pointer" (click)="changeGridData(activeTab, i)"
                    [ngClass]="{'disabled': popupOpen}">
                    <img src="assets/images/view.svg" height="24px" width="24px" alt="">
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-7 scroll-div pr-0 pl-2 h-100" id="right-container">
        <div class="d-flex justify-content-between mb-1 w-100" *ngIf="errorMessage.length > 0">
          <app-alerts [messageType]="'error'" [message]="errorMessage" (alertClosed)="errorMessage=''"
            [style.width.%]="100" [showCloseButton]="true"></app-alerts>
        </div>
        <div class="d-flex justify-content-between mb-1 w-100" *ngIf="errorMessageDate.length > 0">
          <app-alerts [messageType]="'error'" [message]="errorMessageDate" (alertClosed)="errorMessageDate=''"
            [style.width.%]="100" [showCloseButton]="true"></app-alerts>
        </div>
        <div *ngIf="!tabDataLoading">
          <div class="d-flex justify-content-between mb-4 mx-0">
            <div class="item start-date">
              <label class="item-label d-flex align-items-center font-weight-bold mb-2">
                <span>
                  Site Activation Start <sup>*</sup>
                  <span>
                    <img placement="bottom" tooltipClass="tooltip-class"
                      ngbTooltip="Date by which the sites start getting activated" class="cursor-pointer ml-1 mb-1"
                      src="assets/images/shared/info-icon.svg" height="12" width="12" alt="tooltip">
                  </span>
                </span>
              </label>
              <div class="item-input">
                <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left"
                  [startDate]="currentActivationData.siteStartDate.model"
                  (dateSelect)="validateSiteDates(activeTab, siteStartDate, 'siteStartDate', activeIndex)"
                  #siteStartDate="ngbDatepicker" [(ngModel)]="currentActivationData.siteStartDate.model"
                  (keydown)="$event.preventDefault();" (click)="siteStartDate.toggle(); $event.stopPropagation();"
                  autocomplete="off" [disabled]="currentActivationData.siteActivationMethod || popupOpen">
              </div>
            </div>
            <div class="item end-date">
              <label class="item-label d-flex align-items-center font-weight-bold mb-2">
                <span>
                  Site Activation End<sup>*</sup>
                  <span>
                    <img placement="top" tooltipClass="tooltip-class"
                      ngbTooltip="Date by which all the sites are activated" class="cursor-pointer ml-1 mb-1"
                      src="assets/images/shared/info-icon.svg" height="12" width="12" alt="tooltip" placement="bottom">
                  </span>
                </span>
              </label>

              <div class="item-input">
                <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left"
                  [startDate]="currentActivationData.siteEndDate.model || currentActivationData.siteStartDate.model"
                  (dateSelect)="validateSiteDates(activeTab, siteEndDate, 'siteEndDate', activeIndex)"
                  #siteEndDate="ngbDatepicker" [(ngModel)]="currentActivationData.siteEndDate.model"
                  (keydown)="$event.preventDefault();" (click)="siteEndDate.toggle(); $event.stopPropagation();"
                  autocomplete="off" [disabled]="currentActivationData.siteActivationMethod || popupOpen">
              </div>
            </div>
            <div class="item toggle-switch">
              <label class="item-label d-flex align-items-center font-weight-bold mb-2">
                <span>
                  %/Date
                </span>
              </label>
              <div>
                <label class="toggle">
                  <input type="checkbox" [checked]="getToggleValue()"
                    (click)="legendToggle(activeTab, 0, $event, false)"
                    [disabled]="currentActivationData.siteActivationMethod || this.currentActivationData.siteStartDate.input == '' || this.currentActivationData.siteEndDate.input == '' || !accessMode || popupOpen">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between mb-1">
            <div class="d-flex" [style.max-width.%]="50">
              <span class="font-15 font-weight-bold" *ngIf="activeTab === 'global'">Global Activation Curve</span>
              <span class="font-15 font-weight-bold txt-ellps" *ngIf="activeTab === 'country'">Country Activation
                Curve ({{selectedGeographyData.geographyName}})</span>
            </div>

            <div>
              <div class="item-inside d-flex align-items-center" *ngIf="activeTab === 'global'">
                <label class="custom-radio-button mb-0 d-flex align-items-center mr-4 normal-font-weight">
                  <input name="global-activation" type="radio" [value]="true"
                    (change)="siteActivationMethodChanged('global', true, false)"
                    [(ngModel)]="currentActivationData.siteActivationMethod"
                    [disabled]="studyDetails.historical_data_available == 'N' || currentActivationData.siteStartDate.input == '' || currentActivationData.siteEndDate.input == '' || popupOpen"><span></span>Historical
                </label>
                <label class="custom-radio-button mb-0 d-flex align-items-center mr-4 normal-font-weight">
                  <input name="global-activation" type="radio" [value]="false"
                    (change)="siteActivationMethodChanged('global', false, false)"
                    [(ngModel)]="currentActivationData.siteActivationMethod"
                    [disabled]="currentActivationData.siteStartDate.input == '' || currentActivationData.siteEndDate.input == '' || popupOpen"><span></span>Customized
                </label>
              </div>
              <div class="item-inside d-flex align-items-center" *ngIf="activeTab === 'country'">
                <label class="custom-radio-button mb-0 d-flex align-items-center mr-4 normal-font-weight">
                  <input type="radio" [value]="true"
                    [disabled]="(studyDetails.historical_data_available == 'N' && globalData.siteActivationMethod)|| currentActivationData.siteStartDate.input == '' || currentActivationData.siteEndDate.input == '' || popupOpen"
                    [(ngModel)]="currentActivationData.siteActivationMethod"
                    (change)="siteActivationMethodChanged('country', true, false)"><span></span>
                  <a *ngIf="!globalData.siteActivationMethod ; else global_historical">
                    Global Custom
                  </a>
                  <ng-template #global_historical>
                    Global Historical
                  </ng-template>
                </label>
                <label class="custom-radio-button mb-0 d-flex align-items-center mr-4 normal-font-weight">
                  <input name="selectActivationCurveCountry" type="radio" [value]="false"
                    [(ngModel)]="currentActivationData.siteActivationMethod"
                    [disabled]="currentActivationData.siteStartDate.input == '' || currentActivationData.siteEndDate.input == '' || popupOpen"
                    (change)="siteActivationMethodChanged('country', false, false)"><span></span>Customized
                </label>
              </div>
            </div>
          </div>
          <div class="table country-level" id="coordinate"
            [ngClass]="{'cust-grid': !currentActivationData.siteActivationMethod }">
            <div class="table-header d-flex">
              <th class="column font-weight-bold d-flex mb-1 justify-content-end"
                *ngIf="!currentActivationData.toggleSelection">% Site Activation Duration<sup class="pt-3">*</sup>
              </th>
              <th class="column font-weight-bold d-flex mb-1 justify-content-end"
                *ngIf="currentActivationData.toggleSelection">Date of Activation<sup class="pt-3">*</sup></th>
              <th class="column font-weight-bold d-flex mb-1 justify-content-end">% Sites Activated<sup
                  class="pt-3">*</sup></th>
              <th class="column font-weight-bold d-flex mb-1 justify-content-end"
                *ngIf="!currentActivationData.siteActivationMethod">Delete</th>
            </div>
            <tbody class="table-body" id="input-grid">
              <ng-container *ngFor="let row of inputGridData; let i=index; let f=first; let l=last">
                <tr *ngIf="f || l" class="fixrow">
                  <td>
                    <span
                      *ngIf="!currentActivationData.toggleSelection && !(currentActivationData.siteActivationMethod && activeTab == 'country' && !globalData.siteActivationMethod && globalData.toggleSelection); else gridElseBlock">{{row.perc_trial_duration}}</span>
                    <ng-template #gridElseBlock>{{row.perc_trial_duration.input}}</ng-template>
                  </td>
                  <td>{{row.perc_site_activated}}</td>

                  <td *ngIf="!currentActivationData.siteActivationMethod"></td>
                </tr>
                <tr *ngIf="!f && !l">
                  <td>
                    <span *ngIf="!getToggleValue()">
                      <input type="text" class="" [(ngModel)]="row.perc_trial_duration"
                        [ngClass]="{'error-required': isError && focussedItem.row === i && focussedItem.column === 'perc_trial_duration'}"
                        onkeydown="if(event.key==='.'){event.preventDefault();}"
                        oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                        (ngModelChange)="setGridData($event, i, 'perc_trial_duration')"
                        (focus)="onGridInputFocus(i, 'perc_trial_duration')"
                        (focusout)="onGridInputFocusOut(i, 'perc_trial_duration')"
                        [disabled]="isGridInputDisabled(i, 'perc_trial_duration') || popupOpen">
                    </span>
                    <span *ngIf="getToggleValue()">
                      <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left" [startDate]=""
                        (dateSelect)="setGridData(date, i, 'perc_trial_duration')" #date="ngbDatepicker"
                        [(ngModel)]="row.perc_trial_duration.model" (keydown)="$event.preventDefault();"
                        [minDate]="inputGridData[i -1].perc_trial_duration.model"
                        [startDate]="inputGridData[i -1].perc_trial_duration.model"
                        (click)="date.toggle(); $event.stopPropagation();" autocomplete="off"
                        [disabled]="isGridInputDisabled(i, 'perc_trial_duration') || popupOpen">
                    </span>
                  </td>
                  <td>
                    <input type="text" class="" [(ngModel)]="row.perc_site_activated"
                      [ngClass]="{'error-required': isError && focussedItem.row === i && focussedItem.column === 'perc_site_activated'}"
                      onkeydown="if(event.key==='.'){event.preventDefault();}"
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                      (ngModelChange)="setGridData($event, i, 'perc_site_activated')"
                      (focus)="onGridInputFocus(i, 'perc_site_activated')"
                      (focusout)="onGridInputFocusOut(i, 'perc_site_activated')"
                      [disabled]="isGridInputDisabled(i, 'perc_site_activated') || popupOpen">
                  </td>
                  <td *ngIf="!currentActivationData.siteActivationMethod">
                    <button class="btn btn-secondary-white p-0" (click)="deleteCoordinate(i)"
                      [disabled]="inputGridData.length <= requiredCoordinates || !accessMode || popupOpen">
                      <img src="assets/images/shared/delete.svg" alt="delete">
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </div>
          <div class="d-flex justify-content-end cursor-pointer mt-2"
            [ngClass]="{'disabled': !accessMode || popupOpen }" *ngIf="!currentActivationData.siteActivationMethod">
            <img src="assets/images/shared/add.svg" alt="" class="pr-2"> <span style="color: #27a6a4;"
              (click)="addCoordinate(currentActivationData.toggleSelection)">Add Coordinate</span>
          </div>
          <div class="clearfix"></div>
          <div class="d-flex mb-2" *ngIf="!currentActivationData.siteActivationMethod">
            <button class="button-secondary"
              (click)="createChart(activeTab, inputGridData, this.activeIndex, getToggleValue(), true)"
              [disabled]="enableCalculateButton()">Calculate Activation
              Curve</button>
          </div>
        </div>
        <div class="graph-container activation-graph border p-2 mt-3 d-flex w-100" style="flex: 1">
          <!-- <div class="font-15 font-weight-bold mb-2">Simulated Activation Curve</div> -->
          <div id="chart-container" [ngStyle]="{width: '100%', display: 'block'}"></div>
        </div>
      </div>
    </div>
    <!-- </div> -->

  </div>
</div>
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
  <button type="button" class="button-primary mr-3" (click)="modalDismissed('Update')" [disabled]="disableSave()">Save &
    Apply
  </button>
  <div class="cursor-pointer" (click)="modalDismissed('Cancel')" [ngClass]="{'disabled': popupOpen}">
    <img src="assets/images/shared/cancel.svg" class="mr-1" width="16" height="16">
    Cancel
  </div>
</div>