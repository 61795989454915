import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { appSetting } from '@app/shared/dependency/constants';
import { UserDetails, ManageUsersScenarioDetails, COUNTRY_LIST } from '@app/shared/dependency/models';
import { ManageUsersService } from '@app/shared/dependency/manage-users.service';
import { AuthService } from '@app/shared/services/auth.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { GridConfigModel, GridDataModel } from '@app/shared/dependency/grid';
import { NotificationService } from '@app/shared/services/notification.service';
import { Utils } from '@app/shared/dependency/utils';
import { LoaderService } from '@app/shared/dependency/loader.service';
import { HttpHandlerService } from '@app/shared/services/http-handler.service';
import * as messages from '@constants/messages';


@Component({
  selector: 'ctfo-edit-user-country-model',
  templateUrl: './edit-user-country-model.component.html',
  styleUrls: ['./edit-user-country-model.component.scss'],
})
export class EditUserCountryModelComponent implements OnInit {
  @Input() modal: NgbModalRef;
  @Input() scenarioDetails: ManageUsersScenarioDetails;
  @Input() studyDetail: any;
  @Input() scenarioD;
  @Input() countryRowData: any;

  isLoading = false;
  searchInput = '';
  countryAccessGridData: GridDataModel = new GridDataModel();
  countryAccessGridConfig: GridConfigModel = new GridConfigModel();

  countryList: string[] = [];
  selectedCountryList: string[] = [];
  countryColumns = COUNTRY_LIST;
  countryData: any = [];
  payloadArray = [];

  userDetails: UserDetails;
  countryArray = [];
  searchText: any;
  errorMsg = '';
  @ViewChild('inputSearch', { static: true }) inputSearch: ElementRef;
  filteredArray = [...this.countryData];
  @Input() deletedUsers;
  savedCountries = [];
  countrySubscription: any;
  showLoader = true;
  isDisabled = false;
  isReadAccess = false;

  constructor(
    private manageUsers: ManageUsersService,
    private auth: AuthService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private httpClient: HttpHandlerService
  ) { }

  ngOnInit(): void {
    this.setGridConfig();
    this.getCountryList();
    this.userDetails = this.manageUsers.getUserDetailsValue();
    // this.fetchCountryList().subscribe(result => (this.countryList = result));
    this.selectedCountryList = [...this.scenarioDetails.country];
    this.countrySubscription = this.manageUsers.selected_countries?.subscribe(param => {
      if (param !== null) {
        this.savedCountries = param;
      }
      this.savedCountries?.map(el => {
        if (this.countryRowData.user_access_id === el.user_access_id) {
          this.payloadArray = Utils.assignObject(el.countries);
        }
      });
    });

    // if (this.countryRowData.create_type !== 'insert') {
    //   this.isReadAccess = true;
    // }
    // this.countryRowData.hasReadAccessChecked = this.isReadAccess;
  }

  ngOnDestroy() {
    this.countrySubscription.unsubscribe();
  }

  // onReadAccess(event): void {
  //   this.isReadAccess = event.currentTarget.checked;
  //   this.countryRowData.hasReadAccessChecked = this.isReadAccess;
  // }

  isAddButtonDisabled(): boolean {
    return (
      this.selectedCountryList?.length <= 0 ||
      Utils.isEqual(this.selectedCountryList.slice().sort(), this.scenarioDetails.country.slice().sort())
    );
  }

  isItemSelected(item): boolean {
    return this.selectedCountryList.includes(item);
  }

  getPlaceholderLabel(): string {
    return this.selectedCountryList?.join(', ');
  }

  onAdd(): void {
    this.modal.close(this.selectedCountryList.slice().sort());
  }

  onCancel(): void {
    this.isDisabled = false;
    this.modal.dismiss('cancel');
  }

  setGridConfig() {
    this.countryAccessGridConfig.setHeaderStyle({ 'line-height': '18px' });
    this.countryAccessGridConfig.setGridStyle({
      'max-height': '225px',
      'min-height': '225px',
    });
    this.countryAccessGridConfig.setLoading(false);
    this.countryAccessGridData.setColumns(this.countryColumns);
  }

  getCountryList() {
    this.showLoader = true;
    const payload = {
      input_request: {
        user_access_id: this.scenarioDetails?.user_access_id,
        study_id: this.studyDetail.study_id,
        scenario_id: this.scenarioD.scenario_id,
        theme_id: this.studyDetail.theme_id,
        study_feasibility: this.studyDetail.study_feasibility,
        study_type: this.studyDetail.study_type ? this.studyDetail.study_type : ''
      }
    };
    this.httpClient.postHttpResults(appSetting.GET_COUNTRY_FOR_SCENARIO, payload).subscribe((res: any) => {
      if (Utils.isSuccess(res)) {
        this.showLoader = false;
        this.countryData = res.result.sort((a: any, b: any) => {
          return a.read_access === b.read_access ? 0 : a.read_access ? -1 : 1;
        });
        this.showLoader = false;
        this.countryArray = Utils.assignObject(res.result);

        if (this.savedCountries?.length !== 0 && !this.deletedUsers.has(payload.input_request.user_access_id)) {
          this.countryData = this.countryData?.map(resp => {
            this.savedCountries?.map(el => {
              if (el?.user_access_id === this.countryRowData?.user_access_id) {
                el?.countries?.map(data => {
                  if (resp.country_name === data.country_name && this.deletedUsers) {
                    resp = Utils.assignObject(data);
                  }
                });
              }
            });
            return resp;
          });
        } else if (this.deletedUsers.has(payload.input_request.user_access_id)) {
          this.countryData.map(resp => {
            resp.write_access = false;
            resp.read_access = false;
          });
        }
        this.countryAccessGridData.setData(this.countryData);
        if (!res.result?.length) {
          this.showLoader = false;
          this.errorMsg = messages.NO_SCENARIO_DATA;
        }
      } else {
        this.showLoader = false;

        this.errorMsg = messages.SOMETHING_WENT_WRONG;
      }
    });
  }

  searchCountry() {
    const inputText = this.inputSearch.nativeElement.value;
    if (!this.countryData?.length) {
      this.filteredArray = [];
      return this.countryAccessGridData.setData(this.filteredArray);
    }
    if (!this.searchText) {
      this.filteredArray = [...this.countryData];
      this.errorMsg = '';
      return this.countryAccessGridData.setData(this.filteredArray);
    }
    if (inputText?.length >= 3) {
      this.filteredArray = this.countryData.filter(el => {
        const valueString = el['country_name']?.toString()?.toLowerCase();
        if (valueString.includes(this.searchText.toLowerCase())) {
          this.errorMsg = '';
          return el;
        }
      });
      if (this.filteredArray?.length == 0) {
        this.errorMsg = 'No Data available.';
      }
      this.countryAccessGridData.setData(this.filteredArray);
    }
  }

  tableRowClicked($event) {
    const checkBoxArray = [];
    this.isDisabled = true;
    if ($event?.column?.columndatakey == 'read_access' && !$event?.row?.read_access && $event?.row?.write_access) {
      $event.row.write_access = false;
    } else if (
      $event?.column?.columndatakey == 'write_access' &&
      !$event?.row?.read_access &&
      $event?.row?.write_access
    ) {
      $event.row.read_access = true;
    }
    checkBoxArray.push($event.row);
    this.countryData.map(obj => {
      checkBoxArray.find(o => {
        o.country_name === obj.country_name;
      }) || obj;
    });
    this.countryAccessGridData.setData(this.countryData);

    if (this.payloadArray?.length) {
      this.payloadArray.map((obj, i) => {
        if (obj?.country_name === $event?.row?.country_name) {
          this.payloadArray.splice(i, 1);
        }
      });
      this.payloadArray.push($event.row);
    } else {
      this.payloadArray.push($event.row);
    }
  }

  onCountryAdd() {
    let country = Utils.assignObject(this.payloadArray);
    country.map(el => {
      el['country_action'] = 'insert';
    });
    this.countryArray.forEach(resp => {
      if (resp.write_access === true) {
        country.map(el => {
          if (resp?.country_name === el?.country_name) {
            el['country_action'] = 'update';
          }
        });
      }
    });
    let obj = {
      countries: [...country],
      user_access_id: this.scenarioDetails?.user_access_id,
    };

    if (this.savedCountries?.length === 0) {
      this.savedCountries.push(obj);
    } else {
      this.savedCountries.map((res, i) => {
        if (res.user_access_id === obj.user_access_id) {
          obj.countries?.map(el => {
            res.countries.map((e, i) => {
              if (e.country_name === el.country_name) {
                res.countries.splice(i, 1);
              }
            });
            res.countries.push(el);
          });
          obj.countries = [...res.countries];
          this.savedCountries.splice(i, 1);
        }
      });
      this.savedCountries.push(obj);
    }
    this.manageUsers.selectedCountryData(this.savedCountries);
    this.isDisabled = false;
    this.modal.close(country);
  }

  isAddDisabled(): boolean {
    const checkReadAccess = this.countryAccessGridData.getData();
    return checkReadAccess?.some(res => res.write_access) && this.isDisabled;
  }

}
