import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[pink-toast-component]',
  styles: [
    `
      :host {
        background-color: #379392;
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 10px 10px 10px 10px;
        width: 300px;
        border-radius: 3px 3px 3px 3px;
        border-color: #2b7272;
        color: #ffffff;
        pointer-events: all;
        cursor: pointer;
      }
      .btn-pink {
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
      }
      .btn-pink {
        color: #ffffff;
        background-color: #2b7272;
        border-color: #2b7272;
        background: #2b7272 linear-gradient(180deg, #2b7272, darken(#2b7272, 10%)) repeat-x;
        border-color: #2b7272;
      }
      .btn-pink:hover {
        background-color: darken(#2b7272, 10%);
        background: darken(#2b7272, 10%) linear-gradient(180deg, darken($pink, 5%), darken($pink, 15%)) repeat-x;
        border-color: darken(#2b7272, 10%);
      }
      .flex {
        display: flex;
        padding: 0px 15px;
      }
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .disabled {
        pointer-events : none;
        opacity : 0.5;
      }
    `,
  ],
  template: `
    <div class="row" [style.display]="state.value === 'inactive' ? 'none' : ''">
      <div class="col-8">
        <div [class]="options.titleClass" [attr.aria-label]="title">
          {{ title }}
        </div>
        <div role="alert" aria-live="polite" [class]="options.messageClass" [attr.aria-label]="message">
          {{ message }}
        </div>
      </div>
      <div class="col-3 text-right">
        <p (click)="remove()">X</p>
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row flex">
      <a style="margin-top: 20px;margin-right: 20px;"  class="btn btn-pink btn-sm" [class.disabled]="isDisabled()" (click)="action($event, 'approve')">
        {{ buttonString }}
      </a>
      <a style="margin-top: 20px" class="btn button-secondary btn-sm center" [class.disabled]="isDisabled()" (click)="action($event, 'reject')">
        Reject
      </a>
    </div>
    <!-- <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div> -->
  `,
  animations: [
    trigger('flyInOut', [
      state(
        'inactive',
        style({
          opacity: 0,
        })
      ),
      transition(
        'inactive => active',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
              opacity: 0,
            }),
            style({
              transform: 'skewX(20deg)',
              opacity: 1,
            }),
            style({
              transform: 'skewX(-5deg)',
              opacity: 1,
            }),
            style({
              transform: 'none',
              opacity: 1,
            }),
          ])
        )
      ),
      transition(
        'active => removed',
        animate(
          '400ms ease-out',
          keyframes([
            style({
              opacity: 1,
            }),
            style({
              transform: 'translate3d(100%, 0, 0) skewX(30deg)',
              opacity: 0,
            }),
          ])
        )
      ),
    ]),
  ],
  preserveWhitespaces: false,
})
export class CustomNotificationComponent extends Toast {
  // used for demo purposes
  buttonString = 'Approve';
  actionClicked = false;

  // constructor is only necessary when not using AoT
  constructor(protected tService: ToastrService, public toastPackage: ToastPackage) {
    super(tService, toastPackage);
  }

  action(event: Event, actionType) {
    this.actionClicked = true
    event.stopPropagation();
    // this.buttonString = 'Granted';
    this.toastPackage.triggerAction(actionType);
    return false;
  }

  isDisabled(): boolean{
    return this.actionClicked
  }
}
