import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockCopyPasteDirective } from './../directives/copyPasteBlocker.directive';
import { PipesModule } from './../pipes';
import { InfiniteScrollModule } from '@thisissoon/angular-infinite-scroll';
import { HighchartsChartModule } from 'highcharts-angular';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';

import { GridComponent } from './grid.component';
import { GridColumnCustomizerComponent } from './grid-column-customizer/grid-column-customizer.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [GridComponent, GridColumnCustomizerComponent, BlockCopyPasteDirective],
  exports: [GridComponent],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    InfiniteScrollModule,
    HighchartsChartModule,
    VirtualScrollerModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgSelectModule,
  ],
})
export class GridModule {}
