export class GridColumnModel {
  columntitle: string;
  columndatakey: string;
  columnwidth: string;
  columntype =
    'plaintext' ||
    'input' ||
    'checkbox' ||
    'radio' ||
    'actionicon' ||
    'dropdown' ||
    'plaintext_nodpipe' ||
    'plaintext_html' ||
    'sparklinechart' ||
    'plaintext_withK' ||
    'custombarchart' ||
    'linktext' ||
    'plaintext_icon' ||
    'datepicker';

  style: object;
  isdisplayed = true;
  dropdownitems = [];
  actionicons: Array<any>;
  validationItems: object;

  constructor(
    columntitle: string,
    columndatakey: string,
    columnwidth: string,
    columntype?: string,
    style?: object,
    isdisplayed?: boolean,
    dropdownitems?: Array<any>,
    actionicons?: Array<any>,
    validationItems?: object
  ) {
    this.columntitle = columntitle;
    this.columndatakey = columndatakey;
    this.columnwidth = columnwidth;

    if (columntype) {
      this.columntype = columntype;
    }
    if (style !== undefined) {
      this.style = style;
    }
    if (isdisplayed !== undefined) {
      this.isdisplayed = isdisplayed;
    }
    if (dropdownitems !== undefined) {
      this.dropdownitems = dropdownitems;
    }
    if (actionicons !== undefined) {
      this.actionicons = actionicons;
    }
    if (validationItems !== undefined) {
      this.validationItems = validationItems;
    }
  }
}

export class GridDataModel {
  [x: string]: any;

  private gridcolumns = Array<GridColumnModel>();
  private gridscrollablecolumns = Array<GridColumnModel>();
  public griddata = Array<any>();
  private sorton = 'id';
  private sorttype = 'desc';
  private gridIdentifier = 'common-table';
  constructor(
    gridcolumns?: Array<GridColumnModel>,
    gridscrollablecolumns?: Array<GridColumnModel>,
    griddata?: Array<any>,
    sorton?: string,
    sorttype?: string,
    gridIdentifier?: string
  ) {
    if (gridcolumns !== undefined) {
      this.gridcolumns = gridcolumns;
    }
    if (gridscrollablecolumns !== undefined) {
      this.gridscrollablecolumns = gridscrollablecolumns;
    }
    if (griddata !== undefined) {
      this.griddata = griddata;
    }
    if (sorton !== undefined) {
      this.sorton = sorton;
    }
    if (sorttype !== undefined) {
      this.sorttype = sorttype;
    }
    if (gridIdentifier !== undefined) {
      this.gridIdentifier = gridIdentifier;
    }
  }

  setProperties(
    gridcolumns?: Array<GridColumnModel>,
    gridscrollablecolumns?: Array<GridColumnModel>,
    griddata?: Array<any>,
    sorton?: string,
    sorttype?: string,
    gridIdentifier?: string
  ): void {
    if (gridcolumns !== undefined) {
      this.gridcolumns = gridcolumns;
    }
    if (gridscrollablecolumns !== undefined) {
      this.gridscrollablecolumns = gridscrollablecolumns;
    }
    if (griddata !== undefined) {
      this.griddata = griddata;
    }
    if (sorton !== undefined) {
      this.sorton = sorton;
    }
    if (sorttype !== undefined) {
      this.sorttype = sorttype;
    }
    if (gridIdentifier !== undefined) {
      this.gridIdentifier = gridIdentifier;
    }
  }

  getColumns(): any {
    return this.gridcolumns;
  }

  setColumns(gridcolumns: Array<GridColumnModel>): void {
    this.gridcolumns = gridcolumns;
  }

  getScrollableColumns(): any {
    return this.gridscrollablecolumns;
  }

  setScrollableColumns(gridscrollablecolumns: Array<GridColumnModel>): void {
    this.gridscrollablecolumns = gridscrollablecolumns;
  }

  getData(): any {
    return this.griddata;
  }

  setData(griddata: Array<any>): void {
    this.griddata = griddata;
  }

  getSortOn(): any {
    return this.sorton;
  }

  setSortOn(sorton: string): void {
    this.sorton = sorton;
  }

  getSortType(): any {
    return this.sorttype;
  }

  setSortType(sorttype: string): void {
    this.sorttype = sorttype;
  }

  getGridIdentifier(): any {
    return this.gridIdentifier;
  }

  setGridIdentifier(gridIdentifier: string): void {
    this.gridIdentifier = gridIdentifier;
  }
}

export class GridConfigModel {
  private headervisible = true;
  private customisable = false;
  private debugmode = true;
  private destroyGrid = false;
  private loading = false;
  private lazyload = true;
  private loaderstyle: object;
  private errormessage: string;
  private noDataMessage: string;
  private gridstyle: object;
  private tablestyle: object;
  private scrollabletablestyle: object;
  private tableheaderstyle: object;
  private tabledatastyle: object;

  constructor(
    headervisible?,
    customisable?,
    debugmode?,
    destroyGrid?,
    loading?,
    lazyload?,
    loaderstyle?,
    gridstyle?,
    tablestyle?,
    scrollabletablestyle?,
    tableheaderstyle?,
    tabledatastyle?
  ) {
    this.setProperties(
      headervisible,
      customisable,
      debugmode,
      destroyGrid,
      loading,
      lazyload,
      loaderstyle,
      gridstyle,
      tablestyle,
      scrollabletablestyle,
      tableheaderstyle,
      tabledatastyle
    );
  }

  setProperties(
    headervisible?: boolean,
    customisable?: boolean,
    debugmode?: boolean,
    destroyGrid?: boolean,
    loading?: boolean,
    lazyload?: boolean,
    loaderstyle?: object,
    gridstyle?: object,
    tablestyle?: object,
    scrollabletablestyle?: object,
    tableheaderstyle?: object,
    tabledatastyle?: object
  ): void {
    if (headervisible !== undefined) {
      this.headervisible = headervisible;
    }
    if (customisable !== undefined) {
      this.customisable = customisable;
    }
    if (debugmode !== undefined) {
      this.debugmode = debugmode;
    }
    if (destroyGrid !== undefined) {
      this.destroyGrid = destroyGrid;
    }
    if (loading !== undefined) {
      this.loading = loading;
    }
    if (lazyload !== undefined) {
      this.lazyload = lazyload;
    }
    if (loaderstyle !== undefined) {
      this.loaderstyle = loaderstyle;
    }
    if (gridstyle !== undefined) {
      this.gridstyle = gridstyle;
    }
    if (tablestyle !== undefined) {
      this.tablestyle = tablestyle;
    }
    if (scrollabletablestyle !== undefined) {
      this.scrollabletablestyle = scrollabletablestyle;
    }
    if (tableheaderstyle !== undefined) {
      this.tableheaderstyle = tableheaderstyle;
    }
    if (tabledatastyle !== undefined) {
      this.tabledatastyle = tabledatastyle;
    }
  }

  // destroyGrid
  getDestroyGrid(): any {
    return this.destroyGrid;
  }

  setDestroyGrid(destroyGrid: boolean): void {
    this.destroyGrid = destroyGrid;
  }

  // Loading Toggle
  getLoading(): any {
    return this.loading;
  }

  setLoading(loading: boolean): void {
    this.loading = loading;
  }

  getHeaderVisible(): any {
    return this.headervisible;
  }

  setHeaderVisible(headervisible): void {
    this.headervisible = headervisible;
  }

  getLazyLoad(): any {
    return this.lazyload;
  }

  setLazyLoadEnable(lazyload): void {
    this.lazyload = lazyload;
  }

  getLoaderstyle(): any {
    return this.loaderstyle;
  }

  setLoaderstyle(loaderstyle): void {
    this.loaderstyle = loaderstyle;
  }

  getErrorMessage(): any {
    return this.errormessage;
  }

  setError(errormessage: string): void {
    this.errormessage = errormessage;
  }

  getNoDataMessage(): any {
    return this.noDataMessage;
  }

  setNoData(noDataMessage: string): void {
    this.noDataMessage = noDataMessage;
  }

  getGridStyle(): any {
    return this.gridstyle;
  }

  setGridStyle(gridstyle): void {
    this.gridstyle = gridstyle;
  }

  getTableStyle(): any {
    return this.tablestyle;
  }

  setTableStyle(tablestyle): void {
    this.tablestyle = tablestyle;
  }

  getScrollableTableStyle(): any {
    return this.scrollabletablestyle;
  }

  setScrollableTableStyle(scrollabletablestyle): void {
    this.scrollabletablestyle = scrollabletablestyle;
  }

  getHeaderStyle(): any {
    return this.tableheaderstyle;
  }

  setHeaderStyle(tableheaderstyle): void {
    this.tableheaderstyle = tableheaderstyle;
  }

  getDataStyle(): any {
    return this.tabledatastyle;
  }

  setDataStyle(tabledatastyle): any {
    this.tabledatastyle = tabledatastyle;
  }
}
