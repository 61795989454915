export enum UserGroup {
  'superuser' = 'Superuser',
  'gfl' = 'Global Feasibility Lead',
  'mda' = 'Medical Advisor',
}
export enum UserGroupInverted {
  'Superuser' = 'superuser',
  'Global Feasibility Lead' = 'gfl',
  'Medical Advisor' = 'mda',
}

export interface UserDetails {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userGroup: UserGroup[];
  error: string;
  client_role?: any;
  emailTrigger?: boolean;
}

export interface ManageUsersStudyDetails {
  study_feasibility
  study_id: string;
  study_type: string;
  theme_id: string;
}
export interface ManageUsersScenarioDetails {
  newly_added?: boolean | undefined;
  scenario_id: string;
  scenario_name: string;
  therapeutic_area: string;
  country: string[];
  study_code: string;
  scenario_created: string;
  last_updated: string;
  read_access: boolean;
  write_access: boolean;
  user_group: UserGroup;
  due_date: string;
  user_access_id: string;
  study_feasibility: string
}

export interface ShareUsersScenarioDetails {
  user_access_id: string;
  country: string[];
  user_group: UserGroup;
  user_name: string;
  write_access: boolean;
  due_date: string;
}

export enum ViewMode {
  'ADD' = 'add',
  'EDIT' = 'edit',
}

export enum AdminGrid {
  MANAGE_USER_GRID = 'manage-user-grid',
  MDA_USER_GRID = 'mda-user-grid',
  GFL_USER_GRID = 'gfl-user-grid',
}
