import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogService } from '@app/shared/dependency/dialog';
import { PERC_LINE_CHART } from '@app/shared/models/charts.model';
import { CUSTOMIZE_ACTIVATION_CURVE_ERROR_MESSAGES as ERROR_MESSAGES } from '@constants/messages';
import { NgbDateParserFormatter, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SessionStorageService } from 'angular-web-storage';
import * as Highcharts from 'highcharts';
import HC_Data from 'highcharts/modules/data';
import * as _ from 'lodash';
import * as model from './customise-chart.model';
import { CustomActivationInputs } from './customise-chart.model';
import * as moment from 'moment';
import { MomentDateFormatter } from '@sharedServices/dateformat';

@Component({
  selector: 'app-customise-chart',
  templateUrl: './customise-chart.component.html',
  styleUrls: ['./customise-chart.component.scss'],
  providers: [{ provide: NgbDateParserFormatter, useClass: MomentDateFormatter }],
  encapsulation: ViewEncapsulation.None,
})
export class CustomiseChartComponent implements OnInit {
  @Input() modal: NgbModalRef;
  @Input() chartOptions: Highcharts.Options;
  @Input() modelingLevel: string;
  @Input() incomingCustomInputs: Array<CustomActivationInputs> = [];
  @Input() accessMode: any;
  // @Input() geoLevelName: any;
  @Input() countryLevelData: any;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('startDate') startDate;
  @ViewChild('endDate') endDate;

  highcharts = Highcharts;
  chart;
  requiredCoordinates = 5;
  plottedCustomInputs: Array<CustomActivationInputs> = [];
  customInputs: Array<CustomActivationInputs> = [];
  percentageDataPoint: CustomActivationInputs = {
    perc_trial_duration: null,
    perc_site_activated: null,
  };

  dateDataPoint: CustomActivationInputs = {
    perc_trial_duration: this.convertToDateModel(''),
    perc_site_activated: null,
  };

  chartPlotted = false;
  isError = false;
  errorMessage = '';
  focussedItem = {
    column: null,
    row: null,
  };
  inputRequest: any;
  disable_baseline_tracked: boolean;
  inputchanged: boolean;

  geoLevelData: {
    startDate: any;
    endDate: any;
    minimumStartDate: any;
    maximumEndDate: any;
    toggleSelection: boolean;
  };

  dateDataRetention;
  percentageDataRetention;
  toolTip = model.TOOLTIP;
  popupOpen: boolean = false;
  legendPopupOpen: boolean = false;

  constructor(private localStorage: SessionStorageService, private dialog: DialogService) {
    // this.highcharts = Highcharts;
    HC_Data(Highcharts);
  }

  ngOnInit(): void {
    this.inputRequest = this.localStorage.get('inputRequest');
    const scenario = this.localStorage.get('scenario-details');
    this.disable_baseline_tracked =
      this.inputRequest.input_request.is_baseline_tracked == '1' && scenario.editMode != true
        ? true
        : false ||
          (this.inputRequest.input_request.forecast_type == 'refresh_forecast' &&
            scenario.scenario_status == 'Completed'); // true == disable

    const inputs = JSON.parse(JSON.stringify(this.incomingCustomInputs));

    const custom_k = Object.keys(inputs);
    const custom_v = Object.values(inputs);
    const custom = [];

    this.geoLevelData = {
      toggleSelection: this.countryLevelData.legend_toggle === 'date' ? true : false,
      startDate: this.convertToDateModel(this.countryLevelData.start_date || this.countryLevelData.study_start_date),
      endDate: this.convertToDateModel(this.countryLevelData.end_date || this.countryLevelData.study_end_date),
      minimumStartDate: this.convertToDateModel(this.countryLevelData.study_start_date || ''),
      maximumEndDate: this.convertToDateModel(this.countryLevelData.study_end_date || ''),
    };

    if (this.countryLevelData.legend_toggle === 'date') {
      for (let x = 0; x < custom_k.length; x++) {
        const obj = {
          perc_trial_duration: this.convertToDateModel(custom_k[x]),
          perc_site_activated: +custom_v[x],
        };
        custom.push(obj);
      }
    } else {
      for (let x = 0; x < custom_k.length; x++) {
        const obj = {
          perc_trial_duration: +custom_k[x],
          perc_site_activated: +custom_v[x],
        };
        custom.push(obj);
      }
    }

    this.customInputs = JSON.parse(JSON.stringify(custom));

    // if (this.geoLevelData.toggleSelection) { // true = date
    //   this.customInputs.map(i => {
    //       i.perc_trial_duration = this.convertToDateModel(i.perc_trial_duration);
    //   })
    // }

    if (this.customInputs.length <= 0) {
      this.customInputs = this.createDefaultInputs(this.geoLevelData.toggleSelection);

      setTimeout(() => {
        this.plotChart([]);
        this.adjustPadForScroll();
      }, 200);
    } else {
      // this.geoLevelData.toggleSelection ? this.dateDataRetention = JSON.parse(JSON.stringify(custom)) : this.percentageDataRetention = JSON.parse(JSON.stringify(custom));
      this.dataRetention(this.geoLevelData.toggleSelection, JSON.parse(JSON.stringify(this.customInputs)));
      setTimeout(() => {
        this.updateChart();
        this.adjustPadForScroll();
      }, 200);
    }
  }

  dataRetention(fieldType, data) {
    if (fieldType) {
      this.dateDataRetention = JSON.parse(JSON.stringify(data));
    } else {
      this.percentageDataRetention = JSON.parse(JSON.stringify(data));
    }
  }

  onGridInputFocus(index: number, field: string): void {
    this.focussedItem = {
      row: index,
      column: field,
    };
  }

  onGridInputFocusOut(index: number, field: string): void {
    if (this.isError) {
      this.customInputs[index][field] = null;
      this.displayError('');
      this.isError = false;
    }
    // this.sortTable('perc_trial_duration');
  }

  isGridInputDisabled(index: number, field: string): boolean {
    const compField = field === 'perc_trial_duration' ? 'perc_site_activated' : 'perc_trial_duration'; // the other field in the row

    // let field1, field2;
    const field1 = 'perc_trial_duration';
    const field2 = 'perc_site_activated';

    if (!this.geoLevelData.toggleSelection) {
      // percentage input
      return (
        this.customInputs[index - 1][field1] === null ||
        this.customInputs[index - 1][field2] === null ||
        this.customInputs[index - 1][field1] === '' ||
        this.customInputs[index - 1][field2] === ''
      );
    } else {
      // date input
      return (
        this.customInputs[index - 1][field2] === null ||
        this.customInputs[index - 1][field2] === '' ||
        this.customInputs[index - 1][field1].input === '' ||
        this.customInputs[index - 1][field1].input === null
      );
    }
    // || (this.isError && (this.focussedItem.column === field || this.focussedItem.row === index));
  }

  private plotChart(chartData: Array<[any, any]>, chartType = 'simulated'): void {
    const chartOptions: Highcharts.Options = JSON.parse(JSON.stringify(PERC_LINE_CHART));
    chartOptions.chart.height = '45%';

    chartOptions.plotOptions.line.color = '' + model.CHART_CONFIG[chartType].lineColor;

    if (this.geoLevelData.toggleSelection) {
      // all date chart options
      chartData.splice(0, 0, [null, '']);
      chartOptions.series = [];
      chartOptions.xAxis['type'] = 'datetime';
      chartOptions.data['rows'] = chartData;
      chartOptions.data['dateFormat'] = 'YYYY/mm/dd';
      chartOptions.xAxis['tickInterval'] = undefined;
      (chartOptions as any).xAxis.title.text = 'Date of Activation';

      if (chartData.length > 1) {
        // account for extra added datapoint
        const minVal = moment(chartData[1][0]).valueOf();
        const maxVal = moment(chartData[chartData.length - 1][0])
          .add(1, 'days')
          .valueOf();
        chartOptions.xAxis['min'] = minVal;
        chartOptions.xAxis['max'] = maxVal;

        (chartOptions as any).xAxis.tickPositioner = () => {
          var positions = [],
            tick = minVal,
            increment = (maxVal - minVal) / 4;

          if (maxVal !== null && minVal !== null) {
            for (tick; tick - increment <= maxVal; tick += increment) {
              positions.push(tick);
            }
          }
          return positions;
        };
      } else {
        (chartOptions as any).xAxis.tickPositioner = () => {
          return [];
        };
        chartOptions.xAxis['min'] = undefined;
        chartOptions.xAxis['max'] = undefined;
      }
      (chartOptions as any).xAxis.labels.align = 'right';
    } else {
      chartOptions.series = [
        {
          type: 'line',
          name: 'Sites Activated',
          data: chartData,
        },
      ];
      chartOptions.xAxis['tickInterval'] = 25;
      chartOptions.xAxis['min'] = 0;
      chartOptions.xAxis['max'] = 100;
      (chartOptions as any).xAxis.tickPositioner = () => {
        return [0, 25, 50, 75, 100];
      };
      (chartOptions as any).xAxis.labels.align = 'center';
      (chartOptions as any).xAxis.title.text = 'Site Activation Duration';
    }

    chartOptions.tooltip.formatter = (c): string => {
      const point = c.chart.hoverPoint;
      const xTooltip = this.geoLevelData.toggleSelection
        ? 'Date of Activation: <strong>' + Highcharts.dateFormat('%d %b %Y', point.x)
        : 'Site Activation Duration: <strong>' + point.x + '%';
      return `Sites Activated: <strong>${point.y}%</strong><br>${xTooltip}</strong><br>`;
    };
    (chartOptions as any).yAxis.labels.formatter = (e: Highcharts.AxisLabelsFormatterContextObject): string => {
      return e.isFirst ? '' + e.value : `${e.value}%`;
    };
    (chartOptions as any).xAxis.labels.formatter = (e): string => {
      const xLabel = this.geoLevelData.toggleSelection ? Highcharts.dateFormat('%d %b %Y', e.value) : e.value + '%';
      return e.isFirst ? '' : `${xLabel}`;
    };
    if (this.chart) {
      this.chart.update(chartOptions, true);
    } else {
      this.chart = new this.highcharts.Chart('chart-container', chartOptions);
    }
    this.plottedCustomInputs = _.cloneDeep(this.customInputs);
  }

  deleteCoordinate(index: number): void {
    this.customInputs.splice(index, 1);
    this.chartPlotted = _.isEqual(this.customInputs, this.plottedCustomInputs);
    setTimeout(() => {
      this.adjustPadForScroll();
    }, 50);
  }

  addCoordinate(): void {
    const dataPoint = this.geoLevelData.toggleSelection
      ? JSON.parse(JSON.stringify(this.dateDataPoint))
      : JSON.parse(JSON.stringify(this.percentageDataPoint));
    this.customInputs.splice(this.customInputs.length - 1, 0, { ...dataPoint });
    this.chartPlotted = false;
    setTimeout(() => {
      // Scroll to Bottom
      const ele = document.getElementsByClassName('table-body')[0];
      const height = ele.scrollHeight;
      $(ele).animate({ scrollTop: height }, 500);
    }, 50);
  }

  updateChart(): void {
    this.dataRetention(this.geoLevelData.toggleSelection, this.customInputs);
    const chartData: Array<[any, any]> = this.customInputs.map(el => [
      this.geoLevelData.toggleSelection
        ? moment(el.perc_trial_duration.input).format('YYYY/MM/DD')
        : Number(el.perc_trial_duration),
      Number(el.perc_site_activated),
    ]);
    this.plotChart(chartData);
    this.chartPlotted = true;
  }

  checkForUnfilledInputs(toggleSelection): boolean {
    let unfilledInputs = 0;
    this.customInputs.forEach(el => {
      const durationInp = toggleSelection ? el.perc_trial_duration.input : el.perc_trial_duration;
      if (
        el.perc_site_activated === null ||
        el.perc_site_activated === '' ||
        durationInp === null ||
        durationInp === ''
      ) {
        unfilledInputs += 1;
      }
    });
    return unfilledInputs === 0;
  }

  enableSaveButton(): boolean {
    return (
      this.chartPlotted &&
      !this.popupOpen &&
      this.checkForUnfilledInputs(this.geoLevelData.toggleSelection) &&
      Boolean(this.geoLevelData.startDate.input.length) &&
      Boolean(this.geoLevelData.endDate.input.length) &&
      !this.isError &&
      !this.disable_baseline_tracked &&
      this.accessMode
    );
  }

  displayError(errorMessage: string): void {
    this.errorMessage = errorMessage;
  }

  adjustPadForScroll(): void {
    const elements: any = document.getElementsByClassName('table');
    if (elements.length) {
      for (const ele of elements) {
        const tableContainer = $('.table-body', ele);
        const headerElement = $('.table-header', ele);
        const scrollHeight = tableContainer[0]?.scrollHeight;
        const clientHeight = tableContainer[0]?.clientHeight;
        if (scrollHeight > clientHeight) {
          const scrollWidth = tableContainer[0].offsetWidth - tableContainer[0].clientWidth;
          $(headerElement).attr('style', `width: calc(100% - ${scrollWidth}px) !important`);
        } else {
          $(headerElement).attr('style', 'width: 100%');
        }
      }
    }
  }

  modalDismissed(action: string): void {
    const saveData = this.geoLevelData.toggleSelection
      ? this.customInputs.map(e => {
          return {
            perc_trial_duration: this.convertDateToString(e.perc_trial_duration.input),
            perc_site_activated: e.perc_site_activated,
          };
        })
      : this.customInputs;
    const emitObject = {
      action,
      items: saveData,
      dates: {
        start_date: this.convertDateToString(this.geoLevelData.startDate.input),
        end_date: this.convertDateToString(this.geoLevelData.endDate.input),
      },
      legend_toggle: this.geoLevelData.toggleSelection ? 'date' : 'percentage',
    };
    if (this.inputchanged && action !== 'Update') {
      // check for dates too
      this.popupOpen = true;
      this.dialog.confirm({
        title: 'Unsaved Changes',
        message: 'You will lose all the progress made. Are you sure you want to continue?',
        afterOK: () => {
          this.popupOpen = false;
          this.modalClosed.emit(emitObject);
          this.modal.close();
        },
        afterCancel: () => {
          this.popupOpen = false;
        },
        afterClose: () => {
          this.popupOpen = false;
        },
      });
    } else {
      this.modalClosed.emit(emitObject);
      this.modal.close();
    }
  }

  legendToggle(event, bypass) {
    if (!this.chartPlotted && !bypass) {
      this.popupOpen = true;
      this.legendPopupOpen = true;
      this.dialog.confirm({
        title: 'Changes Lost',
        message:
          'If you proceed without calculating activation curve your changes will be lost. Are you sure you want to continue?',
        afterOK: () => {
          this.popupOpen = false;
          this.legendPopupOpen = false;
          this.legendToggle(event, true);
        },
        afterClose: () => {
          this.popupOpen = false;
          this.legendPopupOpen = false;
          this.geoLevelData.toggleSelection = !event.target.checked;
        },
        afterCancel: () => {
          this.popupOpen = false;
          this.legendPopupOpen = false;
          this.geoLevelData.toggleSelection = !event.target.checked;
        },
      });
    } else {
      this.geoLevelData.toggleSelection = event.target.checked;
      // const prevToggle = !this.geoLevelData.toggleSelection;

      if (this.geoLevelData.toggleSelection) {
        //date
        // this.percentageDataRetention = this.chartPlotted ? this.customInputs : this.percentageDataRetention.length > 0 ? this.percentageDataRetention : null; //retain current data
        this.chartPlotted = false;
        this.customInputs = this.dateDataRetention?.length
          ? JSON.parse(JSON.stringify(this.dateDataRetention))
          : this.createDefaultInputs(true);
        if (this.geoLevelData.startDate.input != '') {
          this.closePicker('grid', this.startDate, 'date', 0, false);
        }
        if (this.geoLevelData.endDate.input != '') {
          this.closePicker('grid', this.endDate, 'date', this.customInputs.length - 1, false);
        }

        // this.chartPlotted ? this.dataRetention(this.geoLevelData.toggleSelection, this.customInputs) :
        this.dateDataRetention && this.dateDataRetention?.length >= this.requiredCoordinates
          ? this.updateChart()
          : this.plotChart([]);
      } else {
        // this.dateDataRetention = this.chartPlotted ? this.customInputs : this.dateDataRetention.length > 0 ? this.dateDataRetention : null; //retain current data
        this.chartPlotted = false;
        this.customInputs = this.percentageDataRetention?.length
          ? JSON.parse(JSON.stringify(this.percentageDataRetention))
          : this.createDefaultInputs(false);
        this.percentageDataRetention && this.percentageDataRetention?.length >= this.requiredCoordinates
          ? this.updateChart()
          : this.plotChart([]);
      }
      setTimeout(() => {
        // Scroll to Top
        const ele = document.getElementsByClassName('table-body')[0];
        $(ele).animate({ scrollTop: 0 }, 500);
      }, 50);
    }
  }

  closePicker(level, datepicker, dateField, index, reset) {
    datepicker.close();

    if (level === 'country' && !reset) {
      this.geoLevelData[dateField].input = datepicker._inputValue;
      this.geoLevelData[dateField].model = datepicker._model;
      if (dateField == 'startDate' && this.geoLevelData.toggleSelection) {
        this.customInputs[0].perc_trial_duration.input = datepicker._inputValue;
        this.customInputs[0].perc_trial_duration.model = datepicker._model;
      } else if (dateField == 'endDate' && this.geoLevelData.toggleSelection) {
        this.customInputs.slice(-1)[0].perc_trial_duration.input = datepicker._inputValue;
        this.customInputs.slice(-1)[0].perc_trial_duration.model = datepicker._model;
      }
    } else if (level === 'country') {
      this.geoLevelData[dateField] = this.convertToDateModel('');
      if (dateField == 'startDate' && this.geoLevelData.toggleSelection) {
        this.customInputs[0].perc_trial_duration = this.convertToDateModel('');
      } else if (dateField == 'endDate' && this.geoLevelData.toggleSelection) {
        this.customInputs.slice(-1)[0].perc_trial_duration = this.convertToDateModel('');
      }
    } else {
      if (reset) {
        this.customInputs[index].perc_trial_duration = this.convertToDateModel('');
      } else {
        this.customInputs[index].perc_trial_duration.input = datepicker._inputValue;
        this.customInputs[index].perc_trial_duration.model = datepicker._model;
      }
    }
  }

  convertToDateModel(date) {
    let d1 = moment(date).format('YYYY-MM-DD');
    const d = d1.split('-');
    return {
      input: date.length ? moment(date).format('DD MMM YYYY') : '',
      model: {
        year: +d[0] || null,
        month: +d[1] || null,
        day: +d[2] || null,
      },
    };
  }

  convertDateToString(date) {
    return moment(date).format('YYYY-MM-DD').toString();
  }

  createDefaultInputs(inputType) {
    if (!inputType) {
      // percentage input
      return [
        {
          perc_trial_duration: '0',
          perc_site_activated: '0',
        },
        {
          perc_trial_duration: '25',
          perc_site_activated: null,
        },
        {
          perc_trial_duration: '50',
          perc_site_activated: null,
        },
        {
          perc_trial_duration: '75',
          perc_site_activated: null,
        },
        
        {
          perc_trial_duration: '100',
          perc_site_activated: '100',
        },
      ];
    } else {
      return [
        {
          perc_trial_duration: this.convertToDateModel(this.geoLevelData.startDate.input),
          perc_site_activated: '0',
        },
        {
          perc_trial_duration: this.convertToDateModel(''),
          perc_site_activated: null,
        },
        {
          perc_trial_duration: this.convertToDateModel(''),
          perc_site_activated: null,
        },
        {
          perc_trial_duration: this.convertToDateModel(''),
          perc_site_activated: null,
        },
        {
          perc_trial_duration: this.convertToDateModel(this.geoLevelData.endDate.input),
          perc_site_activated: '100',
        },
      ];
    }
  }

  onGridInputChange(level, inputEvent, field, index) {
    this.displayError('');
    this.inputchanged = true;
    if (this.geoLevelData.toggleSelection && level !== 'country' && field == 'perc_trial_duration') {
      // only for grid dates
      this.chartPlotted = false;
      this.inputValidation(inputEvent, field, index); // inputEvent == datepicker
    } else if (level === 'country') {
      //for country dates
      this.countryLevelDateValidation(inputEvent, field, index);
    } else {
      this.chartPlotted = false;
      this.inputValidation(inputEvent, field, index);
    }
  }

  inputValidation(inputEvent, field, index) {
    let errorMsg;

    if (this.geoLevelData.toggleSelection && field == 'perc_trial_duration') {
      const date = moment(inputEvent._inputValue);
      const siteStartDate = moment(this.geoLevelData.startDate.input);
      const siteEndDate = moment(this.geoLevelData.endDate.input);
      const prevDate = moment(this.customInputs[index - 1].perc_trial_duration.input);

      const duplicate = this.customInputs.filter(dt => date.isSame(dt.perc_trial_duration.input));

      if (date.diff(siteStartDate) < 0 || date.diff(siteEndDate) >= 0) {
        errorMsg = 'Please add a date within the site duration range.';
      } else if (duplicate.length > 0) {
        errorMsg = 'Entered "Date of Activation" already exists.';
      } else if (date.diff(siteEndDate) == 0) {
        errorMsg = 'Site activation date cannot be the same as site activation end date. Please enter valid date.';
      } else {
        errorMsg = '';
        this.closePicker('grid', inputEvent, field, index, false);
      }

      if (errorMsg.length) {
        this.displayError(errorMsg);
        this.closePicker('grid', inputEvent, field, index, true);
      }
    } else {
      const currValue = Number(inputEvent); // converted to number for comparison
      switch (field) {
        case 'perc_trial_duration': {
          const isDuplicate = this.customInputs.some(
            (el, i) => el.perc_trial_duration === inputEvent && i !== index && inputEvent !== null
          );
          if (inputEvent === null || inputEvent === '' || (currValue > 0 && currValue < 100)) {
            const prevValue = this.customInputs[index - 1].perc_trial_duration;
            const nextValue = this.customInputs[index + 1].perc_trial_duration;
            if (isDuplicate) {
              this.displayError(ERROR_MESSAGES.duplicateTrialDuration);
              this.isError = true;
            } else if (
              inputEvent !== null &&
              inputEvent !== '' &&
              ((prevValue !== null && prevValue !== '' && Number(prevValue) > currValue) ||
                (nextValue !== null && nextValue !== '' && Number(nextValue) < currValue))
            ) {
              this.displayError(ERROR_MESSAGES.badOrder);
              this.isError = true;
            } else {
              this.displayError('');
              this.isError = false;
            }
          } else {
            this.displayError(ERROR_MESSAGES.trialDurationOutOfRange);
            this.isError = true;
          }
          break;
        }
        case 'perc_site_activated': {
          if (inputEvent === null || inputEvent === '' || (currValue >= 0 && currValue <= 100)) {
            const prevValue = this.customInputs[index - 1].perc_site_activated;
            const nextValue = this.customInputs[index + 1].perc_site_activated;
            if (
              inputEvent !== null &&
              inputEvent !== '' &&
              ((prevValue !== null && prevValue !== '' && Number(prevValue) > currValue) ||
                (nextValue !== null && nextValue !== '' && Number(nextValue) < currValue))
            ) {
              this.displayError(ERROR_MESSAGES.badOrder);
              this.isError = true;
            } else {
              this.displayError('');
              this.isError = false;
            }
          } else {
            this.displayError(ERROR_MESSAGES.sitesActivatedOutOfRange);
            this.isError = true;
          }
          break;
        }
      }

      // this.chartPlotted = _.isEqual(this.customInputs, this.plottedCustomInputs);
    }
  }

  countryLevelDateValidation(inputEvent, field, index) {
    let errorMsg;
    const compFieldDate =
      field == 'startDate' ? moment(this.geoLevelData.endDate.input) : moment(this.geoLevelData.startDate.input);
    const date = moment(inputEvent._inputValue);
    const studyStartDate = moment(this.geoLevelData.minimumStartDate.input);
    const studyEndDate = moment(this.geoLevelData.maximumEndDate.input);

    if (date.diff(studyStartDate) < 0 || date.diff(studyEndDate) > 0) {
      // errorMsg = 'Site activation start date cannot be outside study duration. Please enter valid date.';
      this.displayError('Site activation start date cannot be outside study duration. Please enter valid date.');
      this.closePicker('country', inputEvent, field, index, true);
    } else if (date.diff(compFieldDate) === 0) {
      this.displayError('Site activation start and end date cannot be same. Please enter valid date.');
      this.closePicker('country', inputEvent, field, index, true);
      // errorMsg = 'Site activation start and end date cannot be same. Please enter valid date.';
      // } else if (date.diff(studyEndDate) > 0) {
      //   this.displayError(errorMsg);
      //   this.closePicker('country', inputEvent, field, index, true);
      //   errorMsg = 'Site activation date cannot be outside study duration. Please enter valid date.';
    } else if (this.checkDatesOutOfBound(inputEvent, field)) {
      this.customInputs = JSON.parse(JSON.stringify(this.createDefaultInputs(true)));
      this.plotChart([]);
      this.displayError('Your input is cleared due to change in Site Activation End date.');
      this.closePicker('country', inputEvent, field, index, false);
    } else {
      errorMsg = '';
      this.closePicker('country', inputEvent, field, index, false);
    }
  }

  checkDatesOutOfBound(inputEvent, field) {
    if (this.geoLevelData.startDate.input == '' || !this.geoLevelData.toggleSelection) {
      return false; // Do not check if not already present in grid
    }

    let startDate, endDate, errorMsg;

    if (field === 'siteStartDate') {
      startDate = moment(inputEvent._inputValue);
      endDate = moment(this.geoLevelData.endDate.input);
    } else {
      startDate = moment(this.geoLevelData.startDate.input);
      endDate = moment(inputEvent._inputValue);
    }

    const inputsToCheck = this.customInputs.slice(1, -1); // Check only for user entered inputs
    const orderError = inputsToCheck.filter(
      d =>
        (moment(d.perc_trial_duration.input).diff(startDate) <= 0 ||
          moment(d.perc_trial_duration.input).diff(endDate) >= 0) &&
        (d.perc_trial_duration.input !== null || d.perc_trial_duration.input !== '')
    );

    errorMsg = orderError.length ? ERROR_MESSAGES.badOrder : '';

    return Boolean(errorMsg.length);
  }

  getLegendValue() {
    return this.geoLevelData.toggleSelection;
  }
}
