<div class="modal-header border-bottom-0 py-0 d-flex align-items-center">
  <h5 class="modal-title font-18 d-flex align-items-center">{{modalTitle}}
    <span class="font-14 font-weight-bold ml-1">{{modalTitleSmall}}</span>
  </h5>
  <img src="assets/images/shared/modal-close.svg" class="cursor-pointer" (click)="modalDismissed()" height="20"
    width="20">
</div>
<div class="modal-body pt-0 pb-2">
  <div class="col-12 p-0">
    <div class="chart d-flex justify-content-center align-items-center">
      <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [callbackFunction]="chartCallback"
        style="width: 100%; height: 100%; display: block;">
      </highcharts-chart>
    </div>

    <!-- Legends Header -->
    <p class="mb-1 mr-2 mt-3 font-weight-bold" *ngIf="legendsHeader">
      {{legendsHeader}}
      <span><img placement="top" tooltipClass="tooltip-class" ngbTooltip="{{messageConstants.toolTips.time_quintiles}}"
          class="cursor-pointer mb-1 ml-1" src="assets/images/shared/info-icon.svg" height="12" width="12">
      </span>
    </p>

    <!-- Legends -->
    <ng-container *ngIf="parentScreen === 'view-enrollments'">
      <div id="view-enrollments-legends" class="legends d-flex flex-wrap pr-2 mt-2">
        <div *ngFor="let legend of legends" class="legend d-flex align-items-start px-2 pb-2 pt-0"
          [ngStyle]="{'max-width': legend.legend_width}">
          <span *ngIf="legend.legend_type ==='solid'" class="legend-color mt-2"
            [style.background-color]="legend.legend_color"></span>
          <div *ngIf="legend.legend_type ==='h-dash'" class="d-flex flex mr-3 mt-2">
            <span class="legend-line-h-color mr-1" [style.background-color]="legend.legend_color"></span>
            <span class="legend-line-h-color mr-1" [style.background-color]="legend.legend_color"></span>
            <span class="legend-line-h-color" [style.background-color]="legend.legend_color"></span>
          </div>
          <div *ngIf="legend.legend_type ==='v-dash'" class="d-flex flex-column mr-3 mt-2">
            <span class="legend-line-v-color" [style.background-color]="legend.legend_color"></span>
            <span class="legend-line-v-color mt-1" [style.background-color]="legend.legend_color"></span>
            <span class="legend-line-v-color mt-1" [style.background-color]="legend.legend_color"></span>
          </div>
          <p class="mb-0">{{legend.legend_value}}</p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="parentScreen === 'site-activation'">
      <div id="site-activation-legends" class="legends d-flex flex-wrap" *ngIf="legends"
        [ngClass]="{'mt-2': !legendsHeader}">
        <div class="legend d-flex align-items-center" *ngFor="let legendValue of legends">
          <div class="legend-color" [style.background-color]="legendValue.legend_color"
            *ngIf="legendValue.legend_value !=='Prediction Start Date'"></div>
          <div *ngIf="legendValue.legend_value==='Prediction Start Date'" class="d-flex flex-column mr-3">
            <span class="legend-line-color mt-1" [style.background-color]="legendValue.legend_color"></span>
            <span class="legend-line-color mt-1" [style.background-color]="legendValue.legend_color"></span>
            <span class="legend-line-color mt-1" [style.background-color]="legendValue.legend_color"></span>
          </div>
          <p class="mb-0">{{legendValue.legend_value}}</p>
        </div>
      </div>
    </ng-container>

  </div>
</div>
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
  <div class="cursor-pointer" (click)="modalDismissed()">
    <img src="assets/images/shared/cancel.svg" class="mr-2" width="16" height="16">Close
  </div>
</div>
