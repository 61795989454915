<div id="overlay"></div>
<ctfo-dialog (ctfoDialogCloseEmitter)="afterClose.emit()">
  <ctfo-dialog-title>{{ title }}</ctfo-dialog-title>
  <ctfo-dialog-content>
    <div [innerHtml]="message"></div>
  </ctfo-dialog-content>
  <ctfo-dialog-actions>
    <button class="button-primary" ctfoDialogClose (click)="afterClose.emit()">Ok</button>
  </ctfo-dialog-actions>
</ctfo-dialog>
