import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-date-range-popup',
  templateUrl: './date-range-popup.component.html',
  styleUrls: ['./date-range-popup.component.scss'],
})
export class DateRangePopupComponent implements OnInit, OnDestroy {
  @Input() modal: NgbModalRef;
  @Input() modalValues: {
    modalRef: string;
    modalTitle: string;
    modalMessage: string;
    okButtonText: string;
    cancelButtonText: string;
    startDateLabel: string;
    endDateLabel: string;
    durationLabel: string;
    duration: string;
    minimumStartDate: any;
    maximumEndDate: any;
    startDate: any;
    endDate: any;
    isDisabled: boolean;
    startDateTooltip: boolean;
    endDateTooltip: boolean;
    elapsedDuration;
  };

  errorMessage = '';
  @ViewChild('startDate') startDate;
  @ViewChild('endDate') endDate;
  durationError: boolean = false;

  @Output() actionButtonClicked = new EventEmitter<{ action: string; data: any }>();

  constructor() {}

  ngOnInit(): void {
    this.modalValues.minimumStartDate = this.convertStringToDate(this.modalValues.minimumStartDate);
    this.modalValues.maximumEndDate = this.convertStringToDate(this.modalValues.maximumEndDate);
    this.modalValues.startDate = this.convertStringToDate(this.modalValues.startDate);
    this.modalValues.endDate = this.convertStringToDate(this.modalValues.endDate);
    if (this.modalValues.startDate.input.length && this.modalValues.endDate.input.length) {
      this.modalValues.duration = this.calculateDuration(
        this.modalValues.startDate.input,
        this.modalValues.endDate.input
      );

      if (
        this.calculateDuration(this.modalValues.startDate.input, this.modalValues.endDate.input) <
        this.modalValues.elapsedDuration
      ) {
        this.durationError = true;
        this.errorMessage =
          'Complete Site Activation By duration should be greater than currently elapsed site activation duration.';
      }
    }
  }

  ngOnDestroy(): void {
    // this.modal.close();
  }

  closePicker(date: any, dateField: string, reset: boolean): void {
    date.close();
    if (!reset) {
      this.modalValues[dateField].input = date._inputValue;
      this.modalValues[dateField].model = date._model;
    } else {
      this.modalValues[dateField].input = '';
      this.modalValues[dateField].model = {
        year: null,
        month: null,
        day: null,
      };
    }
  }

  modalDismissed(type) {
    if (type === 'OK' && this.preSaveDataValidation()) {
      const data = {
        startDate: this.convertDateToString(this.modalValues.startDate.input),
        endDate: this.convertDateToString(this.modalValues.endDate.input),
        duration: this.modalValues.duration,
      };
      this.actionButtonClicked.emit({ action: type, data: data });
      this.modal.close();
    } else if (type === 'OK') {
      // this.errorMessage = 'Please enter valid dates.';
      // do nothing
    } else {
      this.actionButtonClicked.emit({ action: type, data: [] });
      this.modal.close();
    }
  }

  preSaveDataValidation() {
    this.onGridInputChange(this.startDate, 'startDate');
    this.onGridInputChange(this.endDate, 'endDate');

    return !this.errorMessage.length;
  }

  disableSave() {
    return (
      !this.modalValues.startDate.input.length ||
      !this.modalValues.endDate.input.length ||
      this.modalValues.isDisabled ||
      this.durationError
    );
  }

  onGridInputChange(datepicker, dateField) {
    this.errorMessage = '';
    this.durationError = false;
    const minStartDate = moment(this.modalValues.minimumStartDate.input);
    const maxEndDate = moment(this.modalValues.maximumEndDate.input);
    const date = moment(datepicker._inputValue);

    if (date.diff(minStartDate) < 0 || date.diff(maxEndDate) > 0) {
      this.errorMessage = 'Please add a date within the study duration range.';
    } else if (
      dateField == 'startDate' &&
      this.modalValues.endDate.input !== '' &&
      date.diff(this.modalValues.endDate.input) > 0
    ) {
      this.errorMessage = 'Please add a date before site activation end date.';
    } else if (
      dateField == 'endDate' &&
      this.modalValues.startDate.input !== '' &&
      date.diff(this.modalValues.startDate.input) < 0
    ) {
      this.errorMessage = 'Please add a date after site activation start date.';
    } else {
      this.errorMessage = '';
      this.closePicker(datepicker, dateField, false);
    }

    if (this.errorMessage?.length) {
      this.closePicker(datepicker, dateField, true);
    } else if (this.modalValues.startDate.input.length && this.modalValues.endDate.input.length) {
      if (
        this.calculateDuration(this.modalValues.startDate.input, this.modalValues.endDate.input) <
        this.modalValues.elapsedDuration
      ) {
        this.durationError = true;
        this.errorMessage =
          'Complete Site Activation By duration should be greater than currently elapsed site activation duration.';
        this.closePicker(datepicker, dateField, true);
      } else {
        this.modalValues.duration = this.calculateDuration(
          this.modalValues.startDate.input,
          this.modalValues.endDate.input
        );
      }
    }
  }

  calculateDuration(startDate, endDate) {
    const duration = moment(endDate).diff(moment(startDate), 'months', true).toFixed(1);
    return duration;
  }

  convertStringToDate(date) {
    let d1;
    const dt = moment(date).format('YYYY-MM-DD');
    d1 = dt.split('-');
    return {
      input: date.length ? moment(date).format('DD MMM YYYY') : '',
      model: {
        year: +d1[0],
        month: +d1[1],
        day: +d1[2],
      },
    };
  }

  convertDateToString(dateStr) {
    return moment(dateStr).format('YYYY-MM-DD').toString();
  }
}
