import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { consolidatedBaseUrl } from 'src/constants/baseUrls';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CurdService {
  endpoint: any;
  httpOptions: object;
  lp: boolean = environment.env === 'local' ? true : false;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    consolidatedBaseUrl.setCurrentUrl();
    this.endpoint = consolidatedBaseUrl.getCurrentUrl(null);
  }

  // CREATE
  create<T>(model: T | any, objToCreate: T | any): Observable<T | T[]> {
    if (this.lp) {
      return this.http.get<T | T[]>(`${this.endpoint}/${model}`, this.httpOptions);
    } else {
      return this.http.post<T | T[]>(`${this.endpoint}/${model}`, objToCreate);
    }
  } // READ

  read<T>(model: T | any): Observable<T | T[]> {
    return this.http.get<T | T[]>(`${this.endpoint}/${model}`, this.httpOptions);
  } // UPDATE

  update<T>(model: T | any, objToUpdate: T | any): Observable<T | T[]> {
    return this.http.patch<T | T[]>(
      `${this.endpoint}/${model}/${objToUpdate.id}`,
      objToUpdate,
      this.httpOptions,
    );
  } // DELETE

  delete<T>({ model, objToUpdate }: { model: T | any; objToUpdate: T | any }): Observable<T | T[]> {
    return this.http.patch<T | T[]>(
      `${this.endpoint}/${model}/${objToUpdate.id}`,
      objToUpdate,
      this.httpOptions,
    );
  }
}
