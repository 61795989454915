import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ctfo-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() confirmationButtonLabel = 'OK';
  @Input() cancelButtonLabel = 'Cancel';
  @Input() title: string;
  @Input() message: string;

  @Output() afterClose = new EventEmitter<void>();
  @Output() afterCancel = new EventEmitter<void>();
  @Output() afterOK = new EventEmitter<void>();
}
