import { formatDate } from '@angular/common';
import { API_SUCCESS } from './constants';
import { LocalStorageService, SessionStorageService } from 'angular-web-storage';

const _local: any = new LocalStorageService();
const _session: any = new SessionStorageService();

export class Utils {
  constructor() {}
  static isValueExists(val: any): any {
    if (val && val != undefined && val != null) {
      return val;
    }
  }

  static setLocalStorageArray(name, arr): void {
    if (arr && arr != undefined && arr != null) {
      _local.set(name, JSON.stringify(arr));
    }
  }

  static getLocalStorageArray(name): any {
    if (name && name != undefined && name != null) {
      if (_local.get(name)) {
        return JSON.parse(_local.get(name));
      }
    }
  }

  static setLocalStorage(name, value): void {
    if (value && value != undefined && value != null && name != undefined) {
      _local.set(name, value);
    }
  }

  static getLocalStorage(name): any {
    if (name && name != undefined && name != null) {
      if (Utils.isValueExists(_local.get(name))) {
        return _local.get(name);
      }
    }
    return '';
  }

  static removeLocalStorageItem(name): any {
    if (name && name != undefined && name != null) {
      if (_local.get(name)) {
        localStorage.removeItem(name);
      }
    }
  }

  static isArrayExists(arr): any {
    if (arr && typeof arr != 'undefined' && Object.keys(arr).length > 0) {
      return true;
    }
    return false;
  }

  static arrLength(arr): any {
    return Object.keys(arr).length;
  }

  static activeChevron(classname): void {
    const ele = document.getElementsByClassName(classname)[0];
    if ($(ele).parent().hasClass('active')) {
      return;
    }
    let ev;
    if (typeof Event === 'function') {
      ev = new Event('click');
    } else {
      ev = document.createEvent('Event');
      ev.initEvent('click', true, true);
    }
    ele.dispatchEvent(ev);
  }

  static assignObject(obj): any {
    return JSON.parse(JSON.stringify(obj));
  }

  static isEqual(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  /**
   * Creates a deep clone of the object.
   * @param object The object to be cloned.
   * @returns The cloned object.
   */
  static cloneDeep<T>(object: T): T {
    return JSON.parse(JSON.stringify(object));
  }

  static camelCase(str): any {
    return (str = str.replace(/-/g, ' '));
  }

  static setSessionStorage(name, value): void {
    if (value && value != undefined && value != null && name != undefined) {
      _session.set(name, value);
    }
  }

  static getSessionStorage(name): any {
    if (name && name != undefined && name != null) {
      if (Utils.isValueExists(_session.get(name))) {
        return _session.get(name);
      }
    }
    return '';
  }

  static setSessionStorageArray(name, arr): void {
    if (arr && arr != undefined && arr != null) {
      _session.set(name, JSON.stringify(arr));
    }
  }

  static getSessionStorageArray(name): any {
    if (name && name != undefined && name != null) {
      if (_session.get(name)) {
        return JSON.parse(_session.get(name));
      }
    }
  }

  static removeSessionStorageItem(name): any {
    if (name && name != undefined && name != null) {
      if (_session.get(name)) {
        sessionStorage.removeItem(name);
      }
    }
  }

  static isSuccess(resp): any {
    if (
      (resp && resp?.status == 'SUCCEEDED') ||
      resp?.status == 'success' ||
      resp?.status == 'Success' ||
      resp?.status == 'SUCCESS' ||
      resp?.status == 'Succeess' ||
      resp?.status == 'succeess'
    ) {
      return true;
    }
  }

  /**
   * @param response A JSON object received as response from the API call.
   * @returns true if API responds with status `success`; else false.
   */
  static isAPICallSuccessful(response: any): boolean {
    return response?.status?.toLowerCase() === API_SUCCESS;
  }

  static sortArray(arrList, key): any {
    return arrList.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
  }

  static setHyphen(value): any {
    if (value && value != null && value !== 'null') {
      return value;
    } else {
      return '-';
    }
  }

  static getFormattedDate(value, format, locale = 'en-US'): string {
    if (value !== null && value !== undefined && value !== '' && value !== 'null') {
      return this.setHyphen(formatDate(value, format, locale));
    } else {
      return this.setHyphen(value);
    }
  }

  static getDateString(data): string {
    if (this.isEmpty(data)) {
      return '';
    }
    const { year, month, day } = data;
    if (this.isValueExists(year) && this.isValueExists(month) && this.isValueExists(day)) {
      return `${year}-${month}-${day}`;
    }
    return '';
  }

  static addDaysToDate(date: string | number | Date, days: number): Date {
    let updatedDate = new Date(date);
    updatedDate.setDate(updatedDate.getDate() + days);
    return updatedDate;
  }

  /**
   * Returns difference between two dates.
   * @param date1 Date value as a Date, or a number (milliseconds since UTC epoch) or an ISO date-time string.
   * @param date2 Date value as a Date, or a number (milliseconds since UTC epoch) or an ISO date-time string.
   * @param differenceIn A string to mention the difference to be calculated as milliseconds, days, months or years.
   * @returns
   */
  static getDateDifference(
    date1: string | number | Date,
    date2: string | number | Date,
    differenceIn: 'ms' | 'days' | 'months' | 'years' = 'ms'
  ): number {
    date1 = new Date(date1);
    date2 = new Date(date2);
    const difference = Math.abs(date1.getTime() - date2.getTime());
    if (differenceIn === 'days') {
      const oneDay = 1000 * 60 * 60 * 24;
      return difference / oneDay;
    }
    if (differenceIn === 'months') {
      const oneMonth = 1000 * 60 * 60 * 24 * 30.4;
      return difference / oneMonth;
    }
    if (differenceIn === 'years') {
      const oneYear = 1000 * 60 * 60 * 24 * 365;
      return difference / oneYear;
    }
    return difference;
  }

  /**
   * Converts an object into an array of numbers.
   * @param obj An object with key and value as numbers.
   * @returns a list of two numbers array.
   */
  static objectToNumberArray<T>(obj: T): [number, number][] {
    return Object.entries(obj).map(e => [+e[0], +e[1]]);
  }

  /**
   * Opens the SVG image in a new browser tab.
   * @param b64 SVG image as a base 64 string.
   */
  static openSVGInNewTab(b64: string): void {
    const w = window.open('about:blank');
    const image = new Image();
    image.src = 'data:image/svg+xml;base64,' + b64;
    setTimeout(() => {
      w.document.write(image.outerHTML);
    }, 0);
  }

  /**
   * Returns a cloned object omitting the props passed.
   * @param obj An object who's clone is to be created.
   * @param props The list of properties that needs to be omitted from the object.
   */
  static omit<T>(obj: T, props: string | string[]) {
    props = props instanceof Array ? props : [props];
    return eval(`(({${props.join(',')}, ...o}) => o)(obj)`);
  }

  /**
   * Returns a cloned object with the props passed.
   * @param obj An object who's clone is to be created.
   * @param props The list of properties that needs to be included from the object.
   */
  static pick<T>(obj: T, props: string | string[]) {
    props = props instanceof Array ? props : [props];
    let p = props.join(',');
    return eval(`(({${p}}) => ({${p}}))(obj)`);
  }

  /**
   * @returns Quotient for two consecutive divisions
   */
  static doubleDivision(value1: number, value2: number, value3: number): number {
    if (
      this.isEmpty(value1) ||
      this.isEmpty(value2) ||
      this.isEmpty(value3) ||
      value1 === 0 ||
      value2 === 0 ||
      value3 === 0
    ) {
      return 0;
    }
    return value1 / value2 / value3;
  }

  /**
   * Returns true if `input` is null or undefined or empty string; else false.
   */
  static isEmpty(data): boolean {
    return !(data !== null && data !== undefined && data !== '');
  }

  /**
   * Returns true if `input` is null or undefined or empty string; else false.
   * Also returns true if the date object is empty.
   */
  static isDateEmpty(date): boolean {
    return (
      date?.day === null ||
      date?.month === null ||
      date?.year === null ||
      date === null ||
      date === undefined ||
      date === ''
    );
  }

  /**
   * Returns true if type is boolean; else false.
   */
  static isBoolean(object): boolean {
    return typeof object === 'boolean';
  }

  static isDateStruct(object): boolean {
    return (
      object &&
      typeof object === 'object' &&
      object.hasOwnProperty('day') &&
      object.hasOwnProperty('month') &&
      object.hasOwnProperty('year')
    );
  }

  static isEmailValid(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  static isAlpha(name: string): boolean {
    const re = /^[a-zA-z0-9-]+$/;
    return re.test(name);
  }

  static isNameValid(name: string): boolean {
    const re = /^[a-zA-Z0-9 ]+$/;
    return re.test(name);
  }

  static getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }

  // static getEnumKeyByEnumValue<T>(value: T, theEnum: T) {
  //   return Object.keys(theEnum)[Object.values(theEnum).indexOf(value)];
  // }
}
