import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/services/auth.gaurd';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { SessionHandlerModalComponent } from './session-handler-modal/session-handler-modal.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'modeling-predictions',
    pathMatch: 'full',
  },
  {
    path: 'site-level-study-data',
    loadChildren: () =>
      import('./featured-modules/site-level-study-data/site-level-study-data.module').then(
        m => m.SiteLevelStudyDataModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'modeling-predictions',
    loadChildren: () =>
      import('./featured-modules/modeling-predictions/modeling-predictions.module').then(
        m => m.ModelingPredictionsModule
      ),
    canActivate: [AuthGuard],
    data: { role: 'scenario' },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./featured-modules/admin-dashboard/admin-dashboard.module').then(m => m.AdminDashboardModule),
    canActivate: [AuthGuard],
    data: { role: 'admin' },
  },
  {
    path: 'session',
    component: SessionHandlerModalComponent,
  },
  // { path: '**', component: NotFoundComponent },
  { path: 'no-access', component: NoAccessComponent },
  { path: 'maintenance', component: MaintenanceComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
