import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'customDateFormat' })
export class DatePipe implements PipeTransform {
    transform(date: number): string {
        return this.convertDate(date);
    }

    convertDate(dateString): string {
        let modifiedDateValue = dateString;
        let dateTime;
        if (dateString) {
            dateTime = new Date(
                Number(dateString.substr(0, 4)),
                Number(dateString.substr(5, 2)) - 1,
                Number(dateString.substr(8, 2)),
                Number(dateString.substr(11, 2)),
                Number(dateString.substr(14, 2)),
                Number(dateString.substr(17, 2)),
            );

            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const timeOffsetInMS = dateTime.getTimezoneOffset() * 60000;
            dateTime.setTime(dateTime.getTime() - timeOffsetInMS);
            // const strTime = this.getTime(dateTime);
            modifiedDateValue = (dateTime.getDate() < 10 ? '0' : '')
                .concat(dateTime.getDate().toString()) + ' ' + months[dateTime.getMonth()]
                + ' ' + dateTime.getFullYear();
        } else {
            modifiedDateValue = '';
        }
        // + ' ' + strTime;
        return modifiedDateValue;
    }
}
