import { Directive, ElementRef, Input } from '@angular/core';
import { HasUserRoleService } from './../has-user-role.service';

@Directive({
  selector: '[ctempHasRole]',
})
export class HasRoleDirective {
  constructor(private element: ElementRef, private accessService: HasUserRoleService) { }

  @Input() resource: string;
  @Input() specificRole: string;

  // @Input()
  // set ctempHasRole(resource: string) {
  //   if (!resource) {
  //     throw new Error('Resource value is empty or missed');
  //   }
  //   this.resource = resource;
  // }

  ngOnInit() {
    let accessType;
    if (this.specificRole) {
      accessType = this.accessService.checkUserAccess(this.resource, this.specificRole);
    } else accessType = this.accessService.checkUserAccess(this.resource);
    switch (accessType) {
      case 'block':
        this.element.nativeElement.style.display = 'block';
        break;
      case 'none':
        this.element.nativeElement.style.display = 'none';
        break;
      case 'disabled':
        this.element.nativeElement.disabled = 'true';
        break;
    }
  }
}
