export enum DialogType {
  Error,
  Confirm,
  Input,
}

export interface BaseDialogParams {
  title: string;
  message?: string;
}

export interface ConfirmDialogParams extends BaseDialogParams {
  confirmationButtonLabel?: string;
  cancelButtonLabel?: string;
  afterClose?: () => void;
  afterOK: () => void;
  afterCancel?: () => void;
}

export interface ErrorDialogParams extends BaseDialogParams {
  afterClose?: () => void;
}

export interface InfoDialogParams extends BaseDialogParams {
  afterCancel?: () => void;
}

export interface SuccessDialogParams extends BaseDialogParams {
  afterClose?: () => void;
  afterCancel?: () => void;
}

export interface InputDialogParams extends BaseDialogParams {
  inputConfig: InputConfig;
  buttonConfig?: ButtonConfig;
  afterOK: (inputData: string) => void;
  afterCancel?: () => void;
}

export interface InputConfig {
  fieldName: string;
  fieldType: string;
  validation?: {
    maxLength?: number;
    minLength?: number;
    required?: boolean;
    pattern?: string;
  };
}

export interface ButtonConfig {
  primary?: {
    label?: string;
    hidden?: boolean;
  };
  secondary?: {
    label?: string;
    hidden?: boolean;
  };
  close?: {
    label?: string;
    hidden?: boolean;
  };
}
