import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  // isLoading = new BehaviorSubject<any>({ state: false, id: 'default' });
  loaderState = new Subject();
  loaderAction = this.loaderState.asObservable();

  getLoaderState(state, id?: string): void {
    this.loaderState.next({ state: state, id: id ? id : 'default' });
  }
}
