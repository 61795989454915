import { Injectable } from '@angular/core';
import {
  AdminGrid,
  GFL_USER_GRID_CONFIG,
  MANAGE_USER_GRID_CONFIG,
  ManageUsersScenarioDetails,
  MDA_USER_GRID_CONFIG,
  UpdateUserDetailsAPIPayload,
  UserDetails,
  UserGroup,
  ViewMode,
} from './models';
import { GridColumnModel } from './grid';
import { Utils } from './utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '@app/shared/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ManageUsersService {
  private userDetails = new BehaviorSubject<UserDetails>(null);
  private viewMode = new BehaviorSubject<ViewMode>(ViewMode.EDIT);
  private savedUserDetails: UserDetails;
  scenarioDetails: ManageUsersScenarioDetails[] = [];
  public scenario_Details = new BehaviorSubject(null);
  sharedParam = this.scenario_Details.asObservable();
  public selected_countries = new BehaviorSubject(null);
  country = this.selected_countries.asObservable();
  private studyDetail: any;
  constructor(private auth: AuthService) {}
  setselectedCountryData(country) {
    this.selected_countries = new BehaviorSubject(country);
  }
  selectedCountryData(data: any[]) {
    this.selected_countries.next(data);
  }
  setScenarioDetails(scenario) {
    this.scenario_Details = new BehaviorSubject(scenario);
    this.sharedParam = null;
  }

  setStudyDetail(study): void {
    this.studyDetail = study;
  }
  getStudyDetail(): any {
    return this.studyDetail;
  }
  scenarioDetailsPayload(param: any[]) {
    this.scenario_Details.next(param);
  }
  getUserDetails(): Observable<UserDetails> {
    return this.userDetails.asObservable();
  }
  closeUserDetails() {
    this.userDetails?.unsubscribe();
  }
  getUserDetailsValue(): UserDetails {
    return this.userDetails.value;
  }
  setUserDetails(userDetails: UserDetails): void {
    this.userDetails.next(userDetails);
  }
  getSavedUserDetails(): UserDetails {
    return this.savedUserDetails;
  }
  updateSavedUserDetails(): void {
    this.savedUserDetails = Utils.cloneDeep(this.getUserDetailsValue());
  }
  getViewMode(): Observable<ViewMode> {
    return this.viewMode.asObservable();
  }
  getViewModeValue(): ViewMode {
    return this.viewMode.value;
  }
  setViewMode(viewMode: ViewMode): void {
    this.viewMode.next(viewMode);
  }
  getGridConfig(screen: string | UserGroup): GridColumnModel[] {
    switch (screen) {
      case AdminGrid.MANAGE_USER_GRID: {
        return MANAGE_USER_GRID_CONFIG;
      }
      // case UserGroup.gfl: {
      //   return GFL_USER_GRID_CONFIG;
      // }
      // case UserGroup.mda: {
      //   return MDA_USER_GRID_CONFIG;
      // }
      // case 'superuser' : {
      //   return MANAGE_USER_GRID_CONFIG;
      // }
      case 'Global Feasibility Lead': {
        return GFL_USER_GRID_CONFIG;
      }
      case 'Medical Advisor': {
        return MDA_USER_GRID_CONFIG;
      }
      default: {
        return null;
      }
    }
  }
  prepareUpdateUserDetailsPayload(): UpdateUserDetailsAPIPayload {
    const send_email = this.viewMode.value === ViewMode.ADD && this.userDetails?.value?.emailTrigger;
    const is_updated =
      this.viewMode.value === ViewMode.EDIT && !Utils.isEqual(this.userDetails.value, this.savedUserDetails);
    let client_role;

    if (this.viewMode.value === 'add') {
      client_role = this.userDetails?.value.client_role[0].toString();
    } else if (this.viewMode.value === 'edit') {
      client_role = this.userDetails?.value.client_role;
    }


    return {
      input_request: {
        create_type: this.viewMode.value,
        user_id: this.auth.getUserDetails().user_id,
        user_details: {
          first_name: this.userDetails?.value.firstName,
          last_name: this.userDetails?.value.lastName,
          email: this.userDetails?.value.email,
          user_access_id: this.userDetails?.value.id,
          app_user_group: this.userDetails?.value.userGroup.map(
            role => Utils.getEnumKeyByEnumValue(UserGroup, role) as UserGroup
          ),
          //app_user_group: this.userDetails?.value.userGroup,
          send_email,
          client_role: client_role.toString(),
          is_updated,
          is_created: this.viewMode.value === 'add' ? true : false,
        },
        // scenario_details: this.scenarioDetails,
      },
    };
  }

  removeDuplicates(list, key) {
    let result = [];
    let set = new Set();
    result = list.reduce((acc, cur) => {
      if (!set.has(cur[key])) {
        set.add(cur[key]);
        acc.push(cur);
      }
      return acc;
    }, []);
    return result;
  }
}
