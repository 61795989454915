<div class="modal-container">
  <div class="modal-header pl-3 py-0">
    <h5 class="font-18 my-3">{{ modalValues.modalTitle }}</h5>
  </div>
  <div class="modal-body p-4">
    <div class="text-justify" [ngClass]="{'mb-3' : !errorMessage.length, 'mb-4' : errorMessage.length}">
      <span>{{ modalValues.modalMessage }}</span>
    </div>
    <div [ngClass]="{'error-styling' : errorMessage.length}">
      <div class="col-12 px-0">
        <app-alerts [messageType]="'error'" [message]="errorMessage" *ngIf="errorMessage.length"
          (alertClosed)="errorMessage=''" [showCloseButton]="true"></app-alerts>
      </div>
      <div class="d-flex justify-content-between date-container">
        <div class="item w-45">
          <label class="item-label font-weight-bold">
            <span>
              {{ modalValues.startDateLabel }} <sup>*</sup>
              <span>
                <img placement="top" tooltipClass="tooltip-class" ngbTooltip="{{modalValues.startDateTooltip}}"
                  class="cursor-pointer ml-1 mb-1" src="assets/images/shared/info-icon.svg" height="12" width="12"
                  alt="tooltip">
              </span>
            </span>
          </label>
          <div class="item-input">
            <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left" #startDate="ngbDatepicker"
              [startDate]="modalValues.startDate.model || modalValues.minimumStartDate.model" autocomplete="off"
              (dateSelect)="onGridInputChange(startDate, 'startDate')" (keydown)="$event.preventDefault()"
              (click)="startDate.toggle(); $event.stopPropagation();" [(ngModel)]="modalValues.startDate.model"
              [disabled]="modalValues.isDisabled">
          </div>
        </div>

        <div class="item w-45">
          <label class="item-label font-weight-bold">
            <span>
              {{ modalValues.endDateLabel }} <sup>*</sup>
              <span>
                <img placement="top" tooltipClass="tooltip-class" ngbTooltip="{{modalValues.endDateTooltip}}"
                  class="cursor-pointer ml-1 mb-1" src="assets/images/shared/info-icon.svg" height="12" width="12"
                  alt="tooltip">
              </span>
            </span>
          </label>
          <div class="item-input">
            <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left" #endDate="ngbDatepicker"
              [startDate]="modalValues.endDate.model || modalValues.maximumEndDate.model" autocomplete="off"
              (dateSelect)="onGridInputChange(endDate, 'endDate')" (keydown)="$event.preventDefault()"
              [(ngModel)]="modalValues.endDate.model" (click)="endDate.toggle(); $event.stopPropagation();"
              [disabled]="modalValues.isDisabled">
          </div>
        </div>
      </div>
      <div class="d-flex mb-3 align-items-center">
        <label class="item-label font-weight-bold mb-0 w-45">
          <span>{{ modalValues.durationLabel }} -</span>
        </label>
        <div class="w-45 d-flex align-items-center">
          <span class="duration"> {{ modalValues.duration || 0}}<span class="ml-3">Months</span></span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex py-0 d-flex justify-content-start">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('OK')" [disabled]="disableSave()">
      {{ modalValues.okButtonText }}
    </button>
    <div class="cursor-pointer" (click)="modalDismissed('Cancel')">
      <img src="assets/images/shared/cancel.svg" class="mr-1" width="16" height="16">
      Cancel
    </div>
  </div>
</div>
