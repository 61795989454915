import { animate, state, style, transition, trigger } from '@angular/animations';

export const openCloseSidebarAnimation = trigger('openCloseSidebar', [
  state('open', style({ right: '0', display: 'block' })),
  state('closed', style({ display: 'none' })),
  transition('open <=> closed', []),
]);

export const openCloseAccordionAnimation = trigger('openCloseAccordion', [
  state('open', style({})),
  state('closed', style({ height: '0px', padding: '0px', border: '0px' })),
  transition('open <=> closed', [animate('0.3s 0ms ease-out')]),
]);
