import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'titleCaseExcept' })
export class TitleCaseExceptPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // return value.replace(/\b\w/g, first => first.toLocaleUpperCase());

    let words = /\b(?!xxx)\w+/g;
    let newVal = value.replace(words, match =>
      match.replace(/^\w/, word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    );

    return newVal.charAt(0).toUpperCase() + newVal.substr(1);
  }
}
