import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HasUserRoleService } from './../has-user-role.service';
import * as Highcharts from 'highcharts';

import { Utils } from '../utils';
import { GridConfigModel, GridDataModel } from './grid';
import { AuthService } from '@app/shared/services/auth.service';

@Component({
  selector: 'ctfo-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() gridData = new GridDataModel();
  @Input() gridProp = new GridConfigModel();
  @Output() sort = new EventEmitter();
  @Output() rowclick = new EventEmitter();
  @Output() checkboxEvent = new EventEmitter();
  @Output() onscrollend = new EventEmitter();
  @Output() onscroll = new EventEmitter();
  @Output() rowedited = new EventEmitter();
  @Input() defaultCoulmn; // stores the column name upon which the whole accordion is sorted
  @Input() sortOn;
  @Input() sortOnGrid = false;
  @Input() sortType;
  @Input() adminScreen;
  @Input() editableRowIndexes = [];
  @Input() minDate;
  disable_left_marker = true;
  disable_right_marker = false;
  @Input() addK;
  addKV = false;
  sortValue: any;
  tableIdentifier = 'GT1';
  @Input() isCustomizable = false;
  @Input() viewMode = false;
  @Output() emitCustomizedColumn = new EventEmitter();

  constructor(public auth: AuthService, private accessService: HasUserRoleService) {}
  ngOnInit(): any {
    this.gridData.setSortType(this.sortType);
    this.sortValue = this.defaultCoulmn;
    if (this.sortOnGrid) {
      this.sortColumns(this.sortValue);
    }
  }

  onSort(column): void {
    this.sort.emit(column);
    if (this.sortOnGrid) {
      this.sortColumns(column.columndatakey);
    }
  }

  sortColumns(column): any {
    const col_name = column;

    if (this.gridData.getSortType() == 'asc') {
      this.gridData.getData().sort((a, b) => (a[col_name] < b[col_name] ? 1 : b[col_name] < a[col_name] ? -1 : 0));
      this.gridData.setSortType('desc');
      this.gridData.setSortOn(col_name);
    } else {
      this.gridData.getData().sort((a, b) => (a[col_name] > b[col_name] ? 1 : b[col_name] > a[col_name] ? -1 : 0));
      this.gridData.setSortType('asc');
      this.gridData.setSortOn(col_name);
    }
  }

  onTableSort(event): void {
    if (this.gridData.getColumns() === event.colname) {
      if (this.gridData.getSortType() === 'asc') {
        this.gridData.getSortType() === 'desc';
      } else {
        this.gridData.getSortType() == 'asc';
      }
    } else {
      this.gridData.getColumns() === event.colname;
      this.gridData.getSortType() === 'asc';
    }
  }

  onScrollEnd(): void {
    this.onscrollend.emit();
  }

  onScrollEvent(event): void {
    this.onscroll.emit(event);
  }

  inputUpdated(ev: Event, column: any, row: any): void {
    const obj = {
      event: ev,
      column: column,
      data: row,
    };
    this.checkboxEvent.emit(obj);
  }

  onClick(column, row, params?): void {
    event.preventDefault();
    event.stopPropagation();
    this.rowclick.emit({ column: column, row: row, params: params });
  }

  getValues(value): any {
    if (typeof value == 'object') {
      return value.no_of_countries;
    }
    return value;
  }

  getType(value): any {
    return typeof value;
  }

  getClass(str): any {
    if (Utils.isValueExists(str) && typeof str == 'string') {
      const strLower = str.replace(/\s/g, '');
      return strLower.toLowerCase();
    }
    return false;
  }

  getColumns(): void {}

  checkLeftMarker(): any {
    const scrollHeader = $('.scrollable-grid-header:visible')[0];
    const scrollContent = $('.scrollable-grid-content:visible')[0];

    if (this.gridProp.getDestroyGrid() == true) {
      if (scrollHeader && scrollHeader != undefined) {
        scrollHeader.scrollLeft = 0;
        scrollContent.scrollLeft = 0;
      }
      this.disable_left_marker = true;
    }

    return this.disable_left_marker;
  }

  checkRightMarker(): any {
    if (this.gridProp.getDestroyGrid() == true) {
      this.disable_right_marker = false;
    }

    return this.disable_right_marker;
  }

  updateLeftMarker(scrollHeader): void {
    if (scrollHeader.scrollLeft < 34) {
      this.disable_left_marker = true;
    } else {
      this.disable_left_marker = false;
    }
  }

  scrollSlicedTable(direction = 'left'): void {
    const scrollHeader = $('.scrollable-grid-header:visible')[0];
    const scrollContent = $('.scrollable-grid-content:visible')[0];

    if (scrollHeader && scrollHeader != undefined) {
      if (direction === 'left') {
        scrollHeader.scrollLeft += 40;
        scrollContent.scrollLeft += 40;
        this.updateLeftMarker(scrollHeader);
      }

      if (direction === 'right') {
        if ($(scrollHeader).is(':visible')) {
          scrollHeader.scrollLeft -= 40;
          scrollContent.scrollLeft -= 40;
        }
        this.updateLeftMarker(scrollHeader);
      }
      if (scrollHeader.scrollWidth <= scrollHeader.clientWidth + scrollHeader.scrollLeft + 1) {
        this.disable_right_marker = true;
      } else {
        this.disable_right_marker = false;
      }
    }
  }

  checkIsNan(value): any {
    return isNaN(value);
  }

  checkIsRowEditable(rowindex): any {
    if (this.editableRowIndexes.length > 0) {
      const tmp = this.editableRowIndexes.findIndex(o => o == rowindex);
      if (tmp) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onRowEdited() {
    const updatedData = this.gridData.getData();
    this.rowedited.emit(updatedData);
  }

  checkIfInput(): any {
    // if (
    //   this.auth.isLocked() ||
    //   this.auth.isOptimizeRunning() ||
    //   this.auth.isOptimizedList() ||
    //   this.auth.isSurveyCreated()
    // ) {
    //   return true;
    // } else {
    //   return false;
    // }
    return false;
  }

  saveAsDefault(event) {}
  updatedCustomizeColumn(event) {
    this.emitCustomizedColumn.emit(event);
  }
  updatedScroll(event) {}

  applyValidation(event, value, item) {
    if (event.key == 'Backspace') {
      if (item.value) {
        item.value = item.value.substring(0, item.value.length - 1);
      }
      return true;
    } else if (item.validationItems.isNegativeAllowed && event.keyCode == 189) {
      return true;
    } else if (event.keyCode == 69 || event.keyCode == 189) {
      return false;
    } else if (event.key == '.' && !item.validationItems.isDecimalAllowed) {
      return false;
    } else if (item.validationItems.isDecimalAllowed && item.validationItems.maximum == null) {
      const afterDecimal = JSON.stringify(value).split('.');
      if (afterDecimal[1]?.length >= item.validationItems.decimalPlaces) {
        return false;
      }
    } else if (item.validationItems.isDecimalAllowed && item.validationItems.maximum != null) {
      if (value == null) {
        item.value = event.key;
      } else if (!item.value.includes('.')) {
        if (Number(value + event.key + '') > item.validationItems.maximum) {
          return false;
        } else item.value = value + event.key;
      } else if (value && item.value.includes('.')) {
        const afterDecimal = item.value.split('.');
        if (afterDecimal[1].length) {
          if (afterDecimal[1].length >= item.validationItems.decimalPlaces) {
            return false;
          } else item.value = value + event.key;
        } else {
          item.value = value + '.' + event.key;
        }
      }
      // if (!value) {
      //   item.value = event.key;
      // }
      // if (event.key == '.') {
      //   item.value = value + '.';
      // } else {
      //   item.value = item.value + event.key;
      // }

      // if (event.key == '.') {
      //   item.flagCounter = 1;
      //   return true;
      // }
      // if (item.flagCounter) {
      //   const afterDecimal = JSON.stringify(value).split('.');
      //   if (afterDecimal.length > 1 && afterDecimal[1]?.length >= item.validationItems.decimalPlaces) {
      //     return false;
      //   }
      // } else if (Number(value + event.key + '') > item.validationItems.maximum) {
      //   return false;
      // }
    }
    return true;
  }

  /**
   *
   * @param datepicker
   * close Datepicker popup
   */
  closePicker(datepicker) {
    datepicker.close();
  }

  selfAdminAccess(row, title): any {
    if (this.adminScreen) {
      if (title === 'Delete' && row.user_access_id === this.auth.getUserDetails().user_id) {
        return true;
      } else {
        return false;
      }
    }
  }
}
