<ctfo-grid-column-customizer
  *ngIf="tableIdentifier && isCustomizable"
  [tableColumns]="gridData.getColumns()"
  [tableIdentifier]="tableIdentifier"
  (saveAsDefault)="saveAsDefault($event)"
  (emitCustomizeColumn)="updatedCustomizeColumn($event)"
  (emitLeftRightScroll)="updatedScroll($event)"
>
</ctfo-grid-column-customizer>
<div [id]="gridData.getGridIdentifier()" class="grid-container">
  <!-- Loader-->
  <div class="grid-misc loader-container" [ngStyle]="gridProp.getLoaderstyle()" *ngIf="gridProp.getLoading()">
    <!-- <div class="loader"></div> -->
    <div class="loading-circle h-100 w-100">
      <img src="assets/images/shared/loading-circle.svg" width="50" height="50" alt="loading..." />
    </div>
  </div>

  <div class="grid" [class.no-data]="gridData.getData().length == 0">
    <!-- Table Header Starts Here -->
    <div class="grid-header-wrapper">
      <!-- Non Scrollable Starts Here -->
      <div
        class="grid-no-scroll"
        *ngIf="gridData.getColumns() && gridData.getColumns().length"
        [ngStyle]="gridProp.getTableStyle()"
      >
        <div class="grid-row grid-header" *ngIf="gridProp.getHeaderVisible()" [ngStyle]="gridProp.getHeaderStyle()">
          <ng-container *ngFor="let column of gridData.getColumns()">
            <div
              class="grid-column  col_{{ column.columndatakey }}"
              *ngIf="
                column.isdisplayed && column.columntype != 'sparklinechart' && column.columntype != 'custombarchart'
              "
              [ngStyle]="column['style']"
              [style.width]="column.columnwidth"
              (click)="onSort(column)"
            >
              <header [title]="column.columntitle">{{ column.columntitle }}</header>
              <i
                *ngIf="gridData.getSortOn() === column.columndatakey"
                class="fa"
                [ngClass]="{
                  'fa-caret-down': gridData.getSortType() === 'asc',
                  'fa-caret-up': gridData.getSortType() === 'desc'
                }"
              >
              </i>
            </div>
            <div
              class="grid-column"
              class="{{ column.columndatakey }}"
              *ngIf="column.isdisplayed && column.columntype == 'sparklinechart'"
              [ngStyle]="column['style']"
              [style.width]="column.columnwidth"
              (click)="onSort(column)"
            >
              <header [innerHTML]="column.columntitle"></header>
            </div>
            <div
              class="grid-column"
              class="{{ column.columndatakey }}"
              *ngIf="column.isdisplayed && column.columntype == 'custombarchart'"
              [ngStyle]="column['style']"
              [style.width]="column.columnwidth"
              (click)="onSort(column)"
            >
              <header [innerHTML]="column.columntitle"></header>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- Non Scrollable Ends Here -->

      <!-- Marker Starts Here -->
      <div
        class="grid-marker left-marker"
        *ngIf="gridData.getScrollableColumns() && gridData.getScrollableColumns().length"
        [ngStyle]="gridProp.getScrollableTableStyle()"
      >
        <div class="grid-row grid-header" *ngIf="gridProp.getHeaderVisible()" [ngStyle]="gridProp.getHeaderStyle()">
          <div
            class="grid-column"
            id="grid_left_marker"
            (click)="scrollSlicedTable('right')"
            [class.disable]="checkLeftMarker()"
          >
            <i class="fas fa-caret-left"></i>
          </div>
        </div>
      </div>
      <!-- Marker Ends Here -->

      <!-- Scrollable Starts Here -->
      <div
        id="scrollable-grid-header"
        class="scrollable-grid-header grid-scroll"
        *ngIf="gridData.getScrollableColumns() && gridData.getScrollableColumns().length"
        [ngStyle]="gridProp.getScrollableTableStyle()"
      >
        <div class="grid-row grid-header" *ngIf="gridProp.getHeaderVisible()" [ngStyle]="gridProp.getHeaderStyle()">
          <ng-container *ngFor="let column of gridData.getScrollableColumns()">
            <div
              class="grid-column"
              *ngIf="column.isdisplayed"
              [ngStyle]="column['style']"
              [style.width]="column.columnwidth"
              (click)="onSort(column)"
            >
              <header [title]="column.columntitle">{{ column.columntitle }}</header>

              <i
                *ngIf="gridData.getSortOn() === column.columndatakey"
                class="fa"
                [ngClass]="{
                  'fa-caret-down': gridData.getSortType() === 'asc',
                  'fa-caret-up': gridData.getSortType() === 'desc'
                }"
              >
              </i>
            </div>
          </ng-container>
        </div>
      </div>
      <!-- Scrollable Ends Here -->

      <!-- Marker Starts Here -->
      <div
        class="grid-marker"
        *ngIf="gridData.getScrollableColumns() && gridData.getScrollableColumns().length"
        [ngStyle]="gridProp.getScrollableTableStyle()"
      >
        <div class="grid-row grid-header" *ngIf="gridProp.getHeaderVisible()" [ngStyle]="gridProp.getHeaderStyle()">
          <div
            class="grid-column"
            id="grid_right_marker"
            (click)="scrollSlicedTable('left')"
            [class.disable]="checkRightMarker()"
          >
            <i class="fas fa-caret-right"></i>
          </div>
        </div>
      </div>
      <!-- Marker Ends Here -->
    </div>
    <!-- Table Header Ends Here -->

    <!-- Table Data Starts Here -->
    <div
      class="grid-data-wrapper"
      #scrollingBlock
      [ngStyle]="gridProp.getGridStyle()"
      snInfiniteScroll
      (scrollEnd)="onScrollEnd()"
      [offset]="10"
      [disabled]="!gridProp.getLazyLoad()"
      (scroll)="onScrollEvent($event)"
    >
      <!-- Non Scrollable Starts Here -->
      <div
        class="grid-no-scroll"
        *ngIf="gridData.getColumns() && gridData.getColumns().length"
        [ngStyle]="gridProp.getTableStyle()"
      >
        <div class="grid-data" *ngIf="gridData.getData() && gridData.getData().length">
          <virtual-scroller
            #scroll
            [items]="gridData.getData()"
            [ngStyle]="gridProp.getGridStyle()"
            [parentScroll]="scrollingBlock"
          >
            <div
              class="grid-row"
              *ngFor="let row of scroll.viewPortItems; let rowindex = index"
              [ngStyle]="gridProp.getDataStyle()"
            >
              <ng-container *ngFor="let column of gridData.getColumns(); let columnindex = index">
                <div
                  class="grid-column {{ getClass(row[column.columndatakey]) }}"
                  *ngIf="column['isdisplayed']"
                  [ngStyle]="column['style']"
                  [style.width]="column.columnwidth"
                >
                  <div
                    class="text  {{ column.columndatakey }}"
                    *ngIf="column.columntype === 'plaintext' && row[column.columndatakey] != null; else display_dash"
                    (click)="onClick(column, row)"
                  >
                    <header
                      *ngIf="checkIsNan(row[column.columndatakey]); else display_number"
                      [title]="row[column.columndatakey]"
                    >
                      {{ row[column.columndatakey] }}
                    </header>
                    <ng-template #display_number>
                      <header [title]="row[column.columndatakey]">
                        {{ row[column.columndatakey] | decimalNumberFormat | number: '1.0':'en-US' }}
                      </header>
                    </ng-template>
                  </div>
                  <ng-template #display_dash>
                    <div class="placeholder-text" *ngIf="column.columntype === 'plaintext'">
                      <header>-</header>
                    </div>
                  </ng-template>

                  <div
                    class="text  {{ column.columndatakey }}"
                    *ngIf="
                      column.columntype === 'plaintext_html' && row[column.columndatakey] != null;
                      else display_dashplan
                    "
                    (click)="onClick(column, row)"
                  >
                    <div
                      *ngIf="checkIsNan(row[column.columndatakey]); else display_number"
                      [innerHTML]="row[column.columndatakey]"
                    ></div>
                    <ng-template #display_number>
                      <header [title]="row[column.columndatakey]">
                        {{ row[column.columndatakey] | decimalNumberFormat }}
                      </header>
                    </ng-template>
                  </div>
                  <ng-template #display_dashplan>
                    <div class="placeholder-text" *ngIf="column.columntype === 'plaintext_html'">
                      <header>-</header>
                    </div>
                  </ng-template>

                  <div
                    class="text {{ column.columndatakey }}"
                    *ngIf="
                      column.columntype === 'plaintext_nodpipe' && row[column.columndatakey] != null;
                      else display_dashvalue
                    "
                    (click)="onClick(column, row)"
                    [class.blue-c]="getType(row[column.columndatakey]) == 'object'"
                    [ngbTooltip]="
                      getType(row[column.columndatakey]) == 'object' && row[column.columndatakey]['no_of_countries'] > 0
                        ? countryContent
                        : ''
                    "
                    data-container="body"
                    placement="bottom-left"
                    triggers="click:blur"
                    autoClose="outside"
                    tooltipClass="c-tooltip"
                  >
                    <header
                      *ngIf="checkIsNan(row[column.columndatakey]); else display_numbervalue"
                      [title]="getValues(row[column.columndatakey]) | titlecase"
                    >
                      {{ getValues(row[column.columndatakey]) | titlecase }}
                    </header>
                    <ng-template #display_numbervalue>
                      <header [title]="row[column.columndatakey]">
                        {{ row[column.columndatakey] | number: '1.0':'en-US' }}
                      </header>
                    </ng-template>
                  </div>

                  <ng-template #display_dashvalue>
                    <div class="placeholder-text" *ngIf="column.columntype === 'plaintext_nodpipe'">
                      <header>-</header>
                    </div>
                  </ng-template>
                  <ng-template #countryContent>
                    {{ row[column.columndatakey]['countries'] }}
                  </ng-template>

                  <div
                    class="text"
                    *ngIf="
                      column.columntype === 'plaintext_withK' && row[column.columndatakey] != null;
                      else display_dashV
                    "
                    (click)="onClick(column, row)"
                  >
                    <header
                      *ngIf="checkIsNan(row[column.columndatakey]); else display_numberV"
                      [title]="row[column.columndatakey]"
                    >
                      {{ row[column.columndatakey] }}
                    </header>
                    <ng-template #display_numberV>
                      <header [title]="row[column.columndatakey] + 'K'">{{ row[column.columndatakey] }}K</header>
                    </ng-template>
                  </div>
                  <ng-template #display_dashV>
                    <div class="placeholder-text" *ngIf="column.columntype === 'plaintext_withK'">
                      <header>-</header>
                    </div>
                  </ng-template>

                  <div class="text" *ngIf="column.columntype === 'input'">
                    <input
                      type="text"
                      [(ngModel)]="row[column.columndatakey]"
                      [title]="row[column.columndatakey]"
                      (change)="onClick(column, row)"
                    />
                  </div>
                  <div
                    class="text"
                    *ngIf="
                      column.columntype === 'sparklinechart' &&
                      row[column.columndatakey] != null &&
                      row[column.columndatakey]['chartOptions']
                    "
                    (click)="onClick(column, row)"
                  >
                    <highcharts-chart [Highcharts]="Highcharts" [options]="row[column.columndatakey]['chartOptions']">
                    </highcharts-chart>
                  </div>
                  <div
                    class="text bar-text"
                    *ngIf="column.columntype === 'custombarchart' && row[column.columndatakey] != null"
                  >
                    <div
                      class="fillBar"
                      [ngbTooltip]="row[column.columndatakey][0].tooltip"
                      data-container="body"
                      placement="bottom-left"
                      autoClose="outside"
                      tooltipClass="c-tooltip custom-bar"
                      container="body"
                      [ngStyle]="{
                        'max-width': row[column.columndatakey][0].width,
                        'background-color': row[column.columndatakey][0].color
                      }"
                    ></div>
                  </div>
                  <div class="buttoncontainer" *ngIf="column.columntype === 'actionicon'">
                    <div
                      class="buttons"
                      *ngFor="let icon of column['actionicons']"
                      [ngStyle]="
                        row['scenario_id'] === null ||
                        (viewMode === true &&
                          row['user_group_dropdown'][0] === 'gfl' &&
                          icon['iconsrc'] == 'assets/images/accordion/pencil.svg') || selfAdminAccess(row,icon['title'])
                          ? { 'pointer-events': 'none', opacity: '0.5' }
                          : {}
                      "
                    >
                      <img
                        [src]="icon['iconsrc']"
                        [title]="icon['title']"
                        height="20"
                        (click)="onClick(column, row, icon)"
                      />
                    </div>
                  </div>
                  <div class="plain-text-icon text ellips-text" *ngIf="column.columntype === 'plaintext_icon'">
                    <div [title]="row[column['columndatakey']]">
                      {{ row[column['columndatakey']] }}
                    </div>
                    <img
                      src="assets/images/accordion/pencil.svg"
                      (click)="onClick(column, row)"
                      style="cursor: pointer"
                      height="24"
                      width="24"
                    />
                  </div>
                  <div class="custom-radio" *ngIf="column.columntype === 'inputRadio'">
                    <input
                      type="radio"
                      name="siteSurvey"
                      [value]="row[column.columndatakey]"
                      (change)="onClick(column, row)"
                      [checked]="row[column.columndatakey]"
                    />
                  </div>
                  <div
                    class="text  {{ column.columndatakey }}"
                    disabled="checkIfInput()"
                    *ngIf="
                      column.columntype === 'input_on_row' &&
                      row[column.columndatakey] != null &&
                      !checkIsRowEditable(rowindex)
                    "
                    (click)="onClick(column, row)"
                  >
                    <header
                      *ngIf="checkIsNan(row[column.columndatakey]); else display_number"
                      [title]="row[column.columndatakey]"
                    >
                      {{ row[column.columndatakey] }}
                    </header>
                    <ng-template #display_number>
                      <header [title]="row[column.columndatakey] | decimalNumberFormat">
                        {{ row[column.columndatakey] | decimalNumberFormat }}
                      </header>
                    </ng-template>
                  </div>
                  <div
                    class="text  {{ column.columndatakey }}"
                    *ngIf="
                      column.columntype === 'input_on_row' &&
                      !checkIsRowEditable(rowindex) &&
                      (row[column.columndatakey] == null || checkIsNan(row[column.columndatakey]))
                    "
                  >
                    <header class="fill-dash" title="-">-</header>
                  </div>
                  <div
                    class="custom-input-grid"
                    *ngIf="column.columntype === 'input_on_row' && checkIsRowEditable(rowindex)"
                  >
                    <input
                      style="width: 61px !important; height: 34px; text-align: right"
                      type="number"
                      ctfoAppBlockCopyPaste
                      (keydown)="applyValidation($event, row[column.columndatakey], column)"
                      [(ngModel)]="row[column.columndatakey]"
                      (ngModelChange)="onRowEdited()"
                    />
                  </div>
                  <div
                    [ngStyle]="
                      row['scenario_id'] === null || (viewMode === true && row['user_group_dropdown'][0] === 'mda')
                        ? { 'pointer-events': 'none', opacity: '0.5' }
                        : {}
                    "
                    class="custom-checkbox-wrapper"
                    *ngIf="column.columntype === 'checkbox'"
                  >
                    <input
                      class="custom-checkbox"
                      type="checkbox"
                      [(ngModel)]="row[column.columndatakey]"
                      (change)="onClick(column, row, $event)"
                      (click)="inputUpdated($event, column, row)"
                      [checked]="row[column.columndatakey]"
                    />
                  </div>
                  <!---------------------- date picker ---------------------->
                  <div
                    [ngStyle]="row['scenario_id'] === null ? { 'pointer-events': 'none', opacity: '0.5' } : {}"
                    *ngIf="column.columntype === 'datepicker'"
                  >
                    <input
                      class="custom-input date-input"
                      autoClose="true"
                      placeholder="yyyy-mm-dd"
                      name="date"
                      [(ngModel)]="row[column.columndatakey]"
                      ngbDatepicker
                      #cta="ngbDatepicker"
                      (click)="cta.toggle(); onClick(column, row)"
                      (dateSelect)="closePicker(cta)"
                      autocomplete="off"
                      maxlength="10"
                      container="body"
                      [readOnly]="true"
                      [minDate]="minDate"
                    />
                  </div>
                  <!---------------- Dropdown with search option--------------->
                  <div *ngIf="column.columntype === 'dropdown'">
                    <div class="custom-ng-select">
                      <ng-select
                        [items]="row[column.columndatakey]"
                        [(ngModel)]="row[column.columndatakey][0]"
                        #NgSelectComboBox
                        bindLabel="name"
                        bindValue="id"
                        [multiple]="false"
                        [searchable]="false"
                        [clearable]="false"
                        [closeOnSelect]="true"
                        placeholder="{{ row[column.placeholder] }}"
                        [disabled]="row['disableUserGroupDD']"
                        (close)="onClick(column, row, row[column.columndatakey][0])"
                      >
                        <ng-template ng-option-tmp let-item="item"> {{ item }}</ng-template>
                      </ng-select>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </virtual-scroller>
        </div>
      </div>
      <!-- Non Scrollable Ends Here -->

      <!-- Marker Starts Here -->
      <div
        class="grid-marker left-marker"
        *ngIf="gridData.getScrollableColumns() && gridData.getScrollableColumns().length"
        [ngStyle]="gridProp.getScrollableTableStyle()"
      >
        <div class="grid-data" *ngIf="gridData.getData() && gridData.getData().length">
          <div class="grid-row" *ngFor="let row of gridData.getData()" [ngStyle]="gridProp.getDataStyle()">
            <ng-container *ngFor="let column of gridData.getScrollableColumns()">
              <div class="grid-column" [ngStyle]="column['style']"></div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Marker Ends Here -->

      <!-- Scrollable Starts Here -->
      <div
        id="scrollable-grid-content"
        class="scrollable-grid-content grid-scroll"
        *ngIf="gridData.getScrollableColumns() && gridData.getScrollableColumns().length"
        [ngStyle]="gridProp.getScrollableTableStyle()"
      >
        <div class="grid-data" *ngIf="gridData.getData() && gridData.getData().length">
          <div class="grid-row" *ngFor="let row of gridData.getData()" [ngStyle]="gridProp.getDataStyle()">
            <ng-container *ngFor="let column of gridData.getScrollableColumns()">
              <div
                class="grid-column"
                *ngIf="column['isdisplayed']"
                [ngStyle]="column['style']"
                [style.width]="column.columnwidth"
              >
                <div
                  class="text"
                  *ngIf="column.columntype === 'plaintext' && row[column.columndatakey] != null; else display_dash"
                  (click)="onClick(column, row)"
                >
                  <header
                    *ngIf="checkIsNan(row[column.columndatakey]); else display_number"
                    [title]="row[column.columndatakey]"
                  >
                    {{ row[column.columndatakey] }}
                  </header>
                  <ng-template #display_number>
                    <header [title]="row[column.columndatakey]">
                      {{ row[column.columndatakey] | decimalNumberFormat }}
                    </header>
                  </ng-template>
                </div>
                <ng-template #display_dash>
                  <div class="placeholder-text" *ngIf="column.columntype === 'plaintext'">
                    <header>-</header>
                  </div>
                </ng-template>
                <div
                  class="text"
                  *ngIf="
                    column.columntype === 'plaintext_nodpipe' && row[column.columndatakey] != null;
                    else display_dashvalue
                  "
                  (click)="onClick(column, row)"
                >
                  <header
                    *ngIf="checkIsNan(row[column.columndatakey]); else display_numbervalue"
                    [title]="row[column.columndatakey]"
                  >
                    {{ row[column.columndatakey] }}
                  </header>
                  <ng-template #display_numbervalue>
                    <header [title]="row[column.columndatakey]">{{ row[column.columndatakey] }}</header>
                  </ng-template>
                </div>
                <ng-template #display_dashvalue>
                  <div class="placeholder-text" *ngIf="column.columntype === 'plaintext_nodpipe'">
                    <header>-</header>
                  </div>
                </ng-template>
                <div
                  class="text"
                  *ngIf="
                    column.columntype === 'plaintext_withK' && row[column.columndatakey] != null;
                    else display_dashV
                  "
                  (click)="onClick(column, row)"
                >
                  <header
                    *ngIf="checkIsNan(row[column.columndatakey]); else display_numberV"
                    [title]="row[column.columndatakey]"
                  >
                    {{ row[column.columndatakey] }}
                  </header>
                  <ng-template #display_numberV>
                    <header [title]="row[column.columndatakey] + 'K'">{{ row[column.columndatakey] }}K</header>
                  </ng-template>
                </div>
                <ng-template #display_dashV>
                  <div class="placeholder-text" *ngIf="column.columntype === 'plaintext_withK'">
                    <header>-</header>
                  </div>
                </ng-template>
                <div class="text" *ngIf="column.columntype === 'input'">
                  <input
                    type="text"
                    [(ngModel)]="row[column.columndatakey]"
                    [title]="row[column.columndatakey]"
                    (change)="onClick(column, row)"
                  />
                </div>
                <div class="buttoncontainer" *ngIf="column.columntype === 'actionicon'">
                  <div class="buttons" *ngFor="let icon of column['actionicons']" (click)="onClick(column, row, icon)">
                    <img [src]="icon['iconsrc']" [title]="icon['title']" height="20" />
                  </div>
                </div>
                <div class="plain-text-icon text ellips-text" *ngIf="column.columntype === 'plaintext_icon'">
                  <div [title]="row[column['columndatakey']]">
                    {{ row[column['columndatakey']] }}
                  </div>
                  <img
                    src="assets/images/accordion/pencil.svg"
                    (click)="onClick(column, row)"
                    style="cursor: pointer"
                    height="24"
                    width="24"
                  />
                </div>
                <div *ngIf="column.columntype === 'inputRadio'">
                  <input type="radio" id="male" name="gender" value="male" />
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Scrollable Ends Here -->

      <!-- Marker Starts Here -->
      <div
        class="grid-marker"
        *ngIf="gridData.getScrollableColumns() && gridData.getScrollableColumns().length"
        [ngStyle]="gridProp.getScrollableTableStyle()"
      >
        <div class="grid-data" *ngIf="gridData.getData() && gridData.getData().length">
          <div class="grid-row" *ngFor="let row of gridData.getData()" [ngStyle]="gridProp.getDataStyle()">
            <ng-container *ngFor="let column of gridData.getScrollableColumns()">
              <div class="grid-column" [ngStyle]="column['style']"></div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Marker Ends Here -->
    </div>
    <!-- Table Data Ends Here -->

    <!-- Error-->
    <div class="grid-misc" *ngIf="gridProp.getErrorMessage()">
      <header>{{ gridProp.getErrorMessage() }}</header>
    </div>

    <!-- No Data Message-->
    <div class="grid-misc" *ngIf="gridData.getData() == '' && gridProp.getNoDataMessage() && !gridProp.getLoading()">
      <header>{{ gridProp.getNoDataMessage() }}</header>
    </div>
  </div>
</div>
