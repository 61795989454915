import { ManageUsersScenarioDetails, UserGroup, ViewMode } from './manage-users.model';

export interface ManageUsersBaseAPIResponse {
  status: string;
  message: string;
  result?: any;
}

export interface UserRecord {
  first_name?: string;
  last_name?: string;
  email: string;
  user_access_id: string;
  app_user_group: UserGroup[];
  client_role: string;
  error?: string;
  last_login_date?: string;
  send_email?: boolean;
  is_updated?: boolean;
  is_created?: boolean
}

export interface UpdateUserDetailsAPIPayload {
  input_request: {
    create_type: ViewMode;
    user_id: string;
    user_details?: UserRecord;
    scenario_details?: ManageUsersScenarioDetails[];
    is_created?: boolean;
  }
}

export interface UpdateUserDetailsAPIResponse extends ManageUsersBaseAPIResponse {
  result: { is_exist: boolean };
}
export interface ScenarioAccessDetailsAPIResponse extends ManageUsersBaseAPIResponse {
  result: {
    user_details: UserRecord;
    scenario_details: ManageUsersScenarioDetails[];
    total_count: number;
    save_flag: string;
  };
}

export interface ScenarioAccessDetailsAPIPayload {
  input_request: {
    user_id: string;
    user_access_id: string;
    client_role?: string;
    filters: {
      country: string[];
      therapeutic_area: string[];
      view_scenario_search: string[];
      search_value: string;
      user_group: string;
    };
    order_by?: string;
    order_type?: string;
    limit: number;
    offset: number;
  }
}

export interface ScenarioAccessFiltersAPIPayload {
  input_request: {
    user_access_id: string;
    user_group: string;
    client_role: string;
  }
}

export interface ScenarioAccessFiltersAPIResponse extends ManageUsersBaseAPIResponse {
  result: {
    country: string[];
    therapeutic_area: string[];
  };
}

export interface ScenarioAccessRemoveAPIPayload {
  user_id: string;
  user_access_id: string;
  scenario_id: string;
  theme_id: string;
  user_group: UserGroup;
}

export interface UserListAPIResponse extends ManageUsersBaseAPIResponse {
  result: {
    totalCount: number;
    data: UserRecord[];
  };
}

export interface UserListAPIPayload {
  input_request: {
    user_id: string;
    filters: {
      last_login_from: string;
      last_login_to: string;
      user_group: UserGroup[];
      search_value: string;
      client_role: string[];
    };
    limit: number;
    offset: number;
    order_by: string;
    order_type: string;
  }
}

export interface AdminExportAPIResponse extends ManageUsersBaseAPIResponse {
  result: { url: string };
}

export interface DeleteUserAPIPayload {
  input_request: {
    user_id: string;
    user_details: UserRecord;
  }
}
