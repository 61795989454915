import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
  AfterViewInit,
} from '@angular/core';
import { BroadcastService } from '@app/shared/services/broadcast.service';
import { EnrollmentModelSimulationService } from '@app/shared/services/enrollment-model-simulation.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Observable, timer } from 'rxjs';
import { statusMetaData, message } from './loading-popup.model';
import { take, map } from 'rxjs/operators';
import { result } from 'lodash';

@Component({
  selector: 'app-loading-popup',
  templateUrl: './loading-popup.component.html',
  styleUrls: ['./loading-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingPopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() modal: NgbModalRef;
  @Input() parentScreen: string;
  @Input() showTimer: string;
  @Input() modelTitle = 'Generating Enrollment Model...';
  @Input() popupDescription = message.RETURN_MESSAGE;
  @Input() statusIndicators = JSON.parse(JSON.stringify(statusMetaData));
  @Input() errorMessage = '';
  @Input() cancelButtonAvailable: boolean = false;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  @Output() modelCancelled: EventEmitter<any> = new EventEmitter<any>();

  @Input() status = '';

  messages = message;
  headerMessage = message.HEADER_MESSAGE;

  broadcasterSubscription: Subscription;
  counter$: Observable<number>;
  count = 10;
  showCloseCounter;

  constructor(
    private broadcaster: BroadcastService,
    private enrollmentModelSimulationService: EnrollmentModelSimulationService
  ) {}

  ngOnInit(): void {
    this.registerBroadcasters();
  }

  ngAfterViewInit(): void {
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  ngOnDestroy() {
    this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
    this.broadcasterSubscription.unsubscribe();
  }

  registerBroadcasters(): void {
    this.broadcasterSubscription =
      this.parentScreen != 'check-track-monitor-validation'
        ? this.broadcaster.on('enrollmentModelStatusEvent').subscribe((response: string) => {
            if (response && response.length) {
              this.status = response.toLowerCase();
            }
            if (this.status === 'redirect') {
              this.modalDismissed('success');
            }
            if (
              response &&
              (response.toLowerCase() === 'completed' ||
                response.toLowerCase() === 'failed' ||
                response.includes('error') ||
                response.toLowerCase() === 'stopped')
            ) {
              this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
            }

            if (
              this.parentScreen === 'modelling-predictions' &&
              this.status == 'stopping' &&
              this.statusIndicators.length < 4
            ) {
              this.statusIndicators.push({
                title: 'Termination In Progress',
                status: {
                  loading: ['stopping'],
                  done: ['stopped'],
                  error: ['failed'],
                },
              });
              this.modelTitle = 'Terminating Enrollment Model...';
            } else if (
              this.parentScreen === 'modelling-predictions' &&
              this.status == 'stopped' &&
              this.statusIndicators.length < 4
            ) {
              this.statusIndicators.push({
                title: 'Termination Complete',
                status: {
                  loading: ['stopping'],
                  done: ['stopped'],
                  error: ['failed'],
                },
              });
              this.modelTitle = 'Terminating Enrollment Model...';
            }
            // if ((this.statusIndicators.length = 4 && this.status == 'stopped')) {
            //   this.statusIndicators[3].title = 'Termination Complete';
            // }
          })
        : this.broadcaster.on('checkTrackMonitorValiation').subscribe((response: string) => {
            this.status = response;
          });
  }

  modalDismissed(action: string): void {
    // this.enrollmentModelSimulationService.stopEnrollmentStatusCheck();
    if(action === 'navigate'){
      this.modalClosed.emit(action);
    }else{
      if (
        this.status === 'failed' &&
        action != 'close' &&
        (this.parentScreen === 'modelling-predictions' || this.parentScreen === 't-n-m-file-check')
      ) {
        action = 'error';
      }
      if (this.status === 'completed' && action != 'close') {
        action = 'success';
      }
      this.modalClosed.emit(action);
    }

    if (this.parentScreen === 'modelling-predictions' && action == 'cancel') {
      this.showCloseCounter = true;
      setTimeout(() => {
        this.modal.close();
      }, 10000);
    } else {
      this.modal.close();
    }
  }

  modelRunCancelled() {
    this.status = 'stopping';
    this.statusIndicators.push({
      title: 'Termination In Progress',
      status: {
        loading: ['stopping'],
        done: ['stopped'],
        error: ['failed'],
      },
    });
    this.modelTitle = 'Terminating Enrollment Model...';
    this.modalDismissed('cancel');
  }
}
