import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullHyphen',
})
export class NullHyphenPipe implements PipeTransform {
  transform(value: any, includeZero?: boolean, ...args: unknown[]): any {
    if (value === null || value === undefined || value === '') return '–';
    if (includeZero && value === 0) return '–';
    return value;
  }
}
