import { NO_DATA } from './messages';

const CHART_FONT_FAMILY = "'Roboto', sans-serif";

const CHART_COLORS = {
  color: '#454250',
  lineColor: '#888888',
  gridLineColor: '#E7E7E7',
  plotLineColor: '#000000',
};

export const ENROLL_LINE_CHART = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'line',
    height: '350px',
    hover: {
      enabled: false,
    },
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
  },
  title: {
    text: 'Patient Enrollment - Country Level',
    align: 'left',
    style: {
      color: '#454250',
      fontSize: '18px',
      fontFamily: CHART_FONT_FAMILY,
    },
  },
  tooltip: {
    xDateFormat: '%b %y',
  },
  xAxis: {
    type: 'datetime',
    categories: '',
    // startOnTick: true,
    // endOnTick: true,
    tickInterval: 30 * 24 * 3600 * 1000,
    labels: {
      format: '{value:%b %y}',
    },
  },
  yAxis: {
    min: 0,
    lineColor: '#ccd6eb',
    lineWidth: 1,
    // tickInterval: 20,
    allowDecimals: false,
    title: {
      text: 'Patients Enrolled',
      style: {
        color: '#54565B',
        fontSize: '14px',
        fontFamily: CHART_FONT_FAMILY,
      },
    },
  },
  time: {
    useUTC: false,
  },
  legend: {
    symbolHeight: 12,
    symbolWidth: 18,
    align: 'left',
    itemStyle: {
      color: '#716E79',
      fontSize: '14px',
      fontFamily: CHART_FONT_FAMILY,
      fontWeight: 'normal',
    },
  },
  plotOptions: {
    series: {
      pointInterval: 24 * 3600 * 1000 * 10,
      marker: {
        enabled: false,
      },
    },
    line: {
      lineWidth: 2,
      states: {
        enabled: false,
        hover: {
          enabled: false,
        },
      },
      marker: {
        enabled: false,
      },
      stickyTracking: false,
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  lang: {
    noData: NO_DATA,
  },
  noData: {
    style: {
      fontSize: '14px',
      color: CHART_COLORS.color,
    },
  },
};

export const ACTIVATE_STACKED_CHART = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    height: '350px',
    hover: {
      enabled: false,
    },
    style: {
      fontFamily: CHART_FONT_FAMILY,
    },
  },
  colors: ['#ED8B00', '#F6A670', '#FBDBC6', '#B089BB', '#753577', '#003366', '#CCD6E0', '#1C75BC', '#80B8C4'],
  title: {
    text: 'Sites Activated - Country Level',
    align: 'left',
    style: {
      color: '#454250',
      fontSize: '18px',
      fontFamily: CHART_FONT_FAMILY,
    },
  },
  xAxis: {
    categories: '',
  },
  yAxis: {
    min: 0,
    lineColor: '#ccd6eb',
    lineWidth: 1,
    // tickInterval: 20,
    allowDecimals: false,
    title: {
      text: 'Sites Activated',
      style: {
        color: '#54565B',
        fontSize: '14px',
        fontFamily: CHART_FONT_FAMILY,
      },
    },
  },
  legend: {
    symbolRadius: 0,
    align: 'left',
    itemStyle: {
      color: '#716E79',
      fontSize: '14px',
      fontFamily: CHART_FONT_FAMILY,
      fontWeight: 'normal',
      paddingLeft: '30',
    },
  },
  tooltip: {
    pointFormat: '<span>{series.name}</span>: <strong>{point.y}</strong><br/>',
    shared: true,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
    series: {
      borderWidth: 0,
      states: {
        inactive: {
          opacity: 1,
        },
      },
    },
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  lang: {
    noData: NO_DATA,
  },
  noData: {
    style: {
      fontSize: '14px',
      color: CHART_COLORS.color,
    },
  },
};

export const REJECTION_REASON_CHART = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
    height: '250px',
    hover: {
      enabled: false,
    },
  },
  title: {
    text: '',
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  xAxis: {
    type: 'category',
    labels: {
      align: 'center',
      autoRotation: 0,
      style: {
        color: '#454250',
        fontSize: '12px',
        fontFamily: 'Roboto, sans-serif',
        whiteSpace: 'nowrap',
        width: '20px',
      },
      enabled: true,
    },
    useHTML: true,
    title: {
      text: 'Reasons',
    },
  },
  yAxis: {
    min: 0,
    lineWidth: 1,
    title: {
      text: '',
    },
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    pointFormat: '',
  },
  plotOptions: {
    series: {
      pointWidth: 40,
    },
  },
  lang: {
    noData: 'No Data Available',
  },
  noData: {
    style: {
      fontWeight: 'bold',
      fontSize: '15px',
      color: '#303030',
    },
  },
};
