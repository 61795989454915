<div class="grid d-flex flex-column">
  <div class="table-header d-flex align-items-center">
    <div class="table-data" *ngIf="gridConfiguration.checkboxOptions.isCheckBoxEnabled">
      <label class="custom-checkbox-button mb-0 d-flex align-items-center"
        [ngStyle]="{'visibility':gridConfiguration.checkboxOptions.isSelectAllCheckboxVisible?'unset':'hidden'}">
        <input class="custom-checkbox" type="checkbox" (change)="selectedRow('all', $event)"
          [checked]="(selectedEntities.length === gridConfiguration.gridStructure.gridData.length) && gridConfiguration.gridStructure.gridData.length!=0"
          [disabled]="isSelectAllDisabled() || gridConfiguration.gridStructure.gridData.length===0">
        <span [ngStyle]="{'background-color': isSelectAllDisabled() ?'#eff3f6':'white'}"></span>
      </label>
    </div>
    <div class="table-data d-flex align-items-center" [ngStyle]="{'width': gridConfiguration.gridStructure.gridWidths[i]['width'],
            'justify-content': getTableHeaderAlignment(gridConfiguration.gridStructure.gridWidths[i]['alignment']),
            'text-align': gridConfiguration.gridStructure.gridWidths[i]['alignment']}"
      *ngFor="let columnName of gridConfiguration.gridStructure.gridColumns; let i=index">
      <p class="mb-0 font-weight-bold" (click)="clickedOn(gridConfiguration.gridStructure.keyMappings[i], columnName)"
        [ngClass]="{'cursor-pointer': gridConfiguration.sortingDetails.isSortingEnabled &&
            !gridConfiguration.sortingDetails.unSortableColumns.includes(columnName), 'link-color': gridConfiguration.sortingDetails.isSortingEnabled &&
                !gridConfiguration.sortingDetails.unSortableColumns.includes(columnName)}">{{columnName}}</p>
      <div class="d-flex align-items-center"
        *ngIf="gridConfiguration.sortingDetails.isSortingEnabled && !gridConfiguration.sortingDetails.unSortableColumns.includes(columnName)"
        [ngStyle]="{'visibility': gridConfiguration.sortingDetails.sortingOptions['sortColumn']===gridConfiguration.gridStructure.keyMappings[i] && gridConfiguration.sortingDetails.isSortingEnabled  ?'unset':'hidden'}">
        <span class="fa fa-sort-asc ml-1"
          *ngIf="gridConfiguration.sortingDetails.sortingOptions['sortOrder']==='ASC' && gridConfiguration.sortingDetails.sortingOptions['sortColumn']===gridConfiguration.gridStructure.keyMappings[i]"></span>
        <span class="fa fa-sort-desc mt-n1 ml-1"
          *ngIf="gridConfiguration.sortingDetails.sortingOptions['sortOrder']==='DESC' && gridConfiguration.sortingDetails.sortingOptions['sortColumn']===gridConfiguration.gridStructure.keyMappings[i]"></span>
      </div>
      <img src="assets/images/shared/info-icon.svg" height="12" width="12" class="ml-1 mb-1 cursor-pointer"
        placement="top" tooltipClass="tooltip-class"
        ngbTooltip="{{gridConfiguration.statusIconsOptions.displayInfoIcon['tooltip_value']}}"
        *ngIf="gridConfiguration.sortingDetails.unSortableColumns.includes(columnName)">
    </div>
  </div>
  <div class="table-body" [ngStyle]="{'max-height': gridConfiguration.gridStructure.tableBodyMaxHeight}"
    snInfiniteScroll (scrollEnd)="onScrollEnd()" [offset]="10">
    <ng-container
      *ngIf="(gridConfiguration.gridStructure.gridData|filter:gridConfiguration.rowOptions.searchText:gridConfiguration.columnsMetaData.filterOn).length>0 else noDataFound">
      <div class="table-row d-flex align-items-center"
        [ngClass]="{'highlight': gridConfiguration.rowOptions.highlightRowsonError && checkForHighlight(row)}"
        *ngFor="let row of gridConfiguration.gridStructure.gridData| filter: gridConfiguration.rowOptions.searchText: gridConfiguration.columnsMetaData.filterOn">
        <!-- checkbox -->
        <div class="table-data" *ngIf="gridConfiguration.checkboxOptions.isCheckBoxEnabled">
          <label class="custom-checkbox-button mb-0 d-flex align-items-center">
            <input class="custom-checkbox" type="checkbox" (change)="selectedRow(row, $event)" [checked]="checkRow(row)"
              [disabled]="isCheckDisabled(row)">
            <span [ngStyle]="{'background-color': isCheckDisabled(row)?'#eff3f6':'white'}"></span>
          </label>
        </div>

        <!-- textual data -->
        <!--  {{row[key]?(row[key].length>=36?row[key]:''):'-'}} -->
        <div class="table-data d-flex" *ngFor="let key of gridConfiguration.gridStructure.keyMappings; let i=index"
          [ngStyle]="{'width': gridConfiguration.gridStructure.gridWidths[i]['width'], 'text-align': gridConfiguration.gridStructure.gridWidths[i]['alignment']}">
          <p class="mb-0 txt-ellps"
            [ngStyle]="{'padding-right': gridConfiguration.sortingDetails.isSortingEnabled?'15px':'0'}"
            (click)="rowItemClicked(row, row[key], key)" *ngIf="key!==''">
            <span [ngClass]="{'clickable-row': key==gridConfiguration.rowOptions.rowItemKeyClicked}"
              *ngIf="key.includes('_date')||key.includes('timestamp') else iconContainer">
              {{row[key]?(row[key] | date:'dd MMM yyyy'):'-'}}</span>
          </p>

          <!-- input field -->
          <input *ngIf="gridConfiguration.columnsMetaData.integerColumnName && key===''" (focus)="onFocus(row)"
            [(ngModel)]="row[gridConfiguration.rowOptions.inputFieldKey]" (ngModelChange)="onChange(row)"
            (focusout)="onFocusOut()" [disabled]="!checkRow(row)" [ngClass]="{'required':checkRow(row)}" placeholder="0"
            type="number">


          <ng-template #iconContainer>
            <ng-container
              *ngIf="gridConfiguration.statusIconsOptions.showStatusIcon && key ==='scenario_status'; else textContainer">
              <div class="table-data d-flex justify-content-between pr-0 ">
                <img *ngIf="row[key] === 'In Progress'" class="cursor-pointer mx-auto"
                  title="{{gridConfiguration.statusIconsOptions.statusIcons[0]['title']}}"
                  [src]="gridConfiguration.statusIconsOptions.statusIcons[0]['path']" height="24" width="24">
                <img
                  *ngIf="row[key] === 'Completed' && (row.is_baseline_tracked == 0 || row.is_baseline_tracked == null)"
                  class="cursor-pointer mx-auto"
                  title="{{gridConfiguration.statusIconsOptions.statusIcons[1]['title']}}"
                  [src]="gridConfiguration.statusIconsOptions.statusIcons[1]['path']" height="24" width="24">
                <img *ngIf="row.is_baseline_tracked == 1" class="cursor-pointer mx-auto"
                  title="{{gridConfiguration.statusIconsOptions.statusIcons[2]['title']}}"
                  [src]="gridConfiguration.statusIconsOptions.statusIcons[2]['path']" height="24" width="24">
              </div>
            </ng-container>
          </ng-template>
          <ng-template #textContainer>
            <span *ngIf="key!==''" (click)="onSpanClick(row, key)" title="{{row[key]}}" [ngClass]="{'clickable-row': key==gridConfiguration.rowOptions.rowItemKeyClicked,
                        'button-disabled':isLinkDisabled(row, key)}">
              {{(row[key]?row[key]:'-')}}</span>
          </ng-template>

        </div>
        <ng-container *ngIf="gridConfiguration.columnsMetaData.isActionButtonsSecondaryAvailable">
          <div class="table-data d-flex justify-content-center pr-0"
            [ngStyle]="{'width': gridConfiguration.gridStructure.gridWidths[gridConfiguration.gridStructure.gridWidths.length-2]['width']}">
            <ng-container
              *ngFor="let actionButton of gridConfiguration.columnsMetaData.actionButtonsSecondary; index as i">
              <span *ngIf="showtrackMonitorLinkIcon">
                <img *ngIf="i == 0 && row.scenario_name !== trackScenarioName" class="cursor-pointer"
                  [src]="actionButton['path']" height="24" width="24"
                  [ngClass]="isActionButtonDisabled(row, actionButton['title'])" placement="top"
                  tooltipClass="tooltip-class tooltip-no-min-width" ngbTooltip="{{actionButton['message']}}"
                  container="body" [alt]="actionButton['title']" (click)="rowItemClicked(row, actionButton['title'])">
                <img *ngIf="i == 1 && row.scenario_name == trackScenarioName" class="cursor-pointer"
                  [src]="actionButton['path']" height="24" width="24"
                  [ngClass]="isActionButtonDisabled(row, actionButton['title'])" placement="top"
                  tooltipClass="tooltip-class tooltip-no-min-width" ngbTooltip="{{actionButton['message']}}"
                  container="body" [alt]="actionButton['title']" (click)="rowItemClicked(row, actionButton['title'])">
              </span>
              <span *ngIf="!showtrackMonitorLinkIcon">
                <img *ngIf="i == 0" class="cursor-pointer" [src]="actionButton['path']" height="24" width="24"
                  [ngClass]="isActionButtonDisabled(row, actionButton['title'])" placement="top"
                  tooltipClass="tooltip-class tooltip-no-min-width" ngbTooltip="{{actionButton['message']}}"
                  container="body" [alt]="actionButton['title']" (click)="rowItemClicked(row, actionButton['title'])">
              </span>

            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="gridConfiguration.columnsMetaData.isActionButtonsAvailable">
          <div class="table-data d-flex justify-content-between pr-0"
            [ngStyle]="{'width': gridConfiguration.gridStructure.gridWidths[gridConfiguration.gridStructure.gridWidths.length-1]['width']}">
            <ng-container *ngFor="let actionButton of gridConfiguration.columnsMetaData.actionButtons">
              <img class="cursor-pointer" [src]="(actionButton['title']=='Download' && row.download_status == 'disable') ?  actionButton['path1'] : actionButton['path']" height="24" width="24"
                [ngClass]="isActionButtonDisabled(row, actionButton['title'])" placement="top"
                tooltipClass="tooltip-class tooltip-no-min-width" ngbTooltip="{{actionButton['title']}}"
                container="body" [alt]="actionButton['title']" (click)="rowItemClicked(row, actionButton['title'])">
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #noDataFound>
      <div class="d-flex justify-content-center align-items-center no-data-found">
        <h4 *ngIf="gridConfiguration.gridStructure.gridData.length===0">No Study Data Available.</h4>
        <h4
          *ngIf="(gridConfiguration.gridStructure.gridData|filter:gridConfiguration.rowOptions.searchText:gridConfiguration.columnsMetaData.filterOn).length==0 && gridConfiguration.rowOptions.searchText.length>0">
          No data available for
          search text
          "{{gridConfiguration.rowOptions.searchText}}".</h4>
      </div>
    </ng-template>
  </div>
</div>
