import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
  HttpSentEvent,
} from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private router: Router) { }

  /**
   * Method for customize request.
   * @param request http request
   */
  addCommonHttpOptions(request): HttpRequest<any> {
    try {
      const req = request.clone({});
      const env = environment;
      const methodName = request.url.split('CTEMP/')[1];
      if (env && env.forceServeStaticDataFor?.includes(methodName)) {
        req.url = `/assets/data/${methodName}.json`;
        req.urlWithParams = `/assets/data/${methodName}.json`;
        req.method = 'GET';
      }
      req.headers.withCredentials = environment.production;
      req.withCredentials = environment.production;

      return req;
    } catch (error) {
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const customRequest = this.addCommonHttpOptions(req);
    return next.handle(customRequest).pipe(
      map(res => res),
      catchError(err => {
        if (err.status === 401) {
          const url = window.location.origin;
          window.location.href = environment.authLoginUrl + url;
          return EMPTY;
        } else if (err.status === 503) {
          this.router.navigate(['/maintenance']);
         }
        else {
          return throwError(err);
        }
      })
    );
  }
}
