import { ApplicationRef, Injectable } from '@angular/core';
import { Subscription, Observable, fromEvent, merge, interval, Subject } from 'rxjs';
import { switchMap, take, tap, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InactivityMonitorService {
  inactivityTimerEvent: Array<any>[] = [
    [document, 'click'],
    [document, 'wheel'],
    [document, 'scroll'],
    [document, 'mousemove'],
    [document, 'keyup'],
    [window, 'resize'],
    [window, 'scroll'],
    [window, 'mousemove'],
  ];

  inactivityTime = 870; // 14m 30s = 870s

  timeLapsedSinceInactivity = 0;

  subscription: Subscription;
  observeable$: Observable<any>;
  mergedObservable$: Observable<any>;

  minute: number = this.padZero(0);
  seconds: number = this.padZero(0);

  inactivityModalStream = new Subject();

  constructor(private cd: ApplicationRef) {}

  createObservableList(): void {
    let observers = [];
    this.inactivityTimerEvent?.forEach(e => {
      observers.push(fromEvent(e[0], e[1]));
    });
    this.mergedObservable$ = merge(...observers);
  }

  createObserable(): void {
    this.createObservableList();
    // this.ngZone.runOutsideAngular(() => {
    this.observeable$ = this.mergedObservable$.pipe(
      switchMap(ev => interval(1000).pipe(take(this.inactivityTime))),

      tap(value => this.isItTimeToShowPopUp(value)),
      skipWhile(x => {
        this.timeLapsedSinceInactivity = x;
        return x != this.inactivityTime - 1;
      })
    );

    this.subscribeObservable();
    // });
  }

  isItTimeToShowPopUp(val: number) {
    let timeLeftForInactive = this.inactivityTime - val;
    if (timeLeftForInactive === 0 || this.timeLapsedSinceInactivity == this.inactivityTime) {
      this.inactivityModalStream.next(true);
    }
    if (timeLeftForInactive <= this.inactivityTime) {
      this.timeLapsedSinceInactivity = timeLeftForInactive;
      this.minute = this.padZero(Math.floor(timeLeftForInactive / 15));
      this.seconds = this.padZero(timeLeftForInactive % 15);
      this.cd.tick();
    }
  }

  subscribeObservable() {
    this.subscription = this.observeable$.subscribe(x => {
      this.unsubscribeObservable();
    });
  }
  padZero(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  unsubscribeObservable() {
    this.inactivityModalStream.next(true);
    this.subscription?.unsubscribe();
  }

  startTimer() {
    this.createObserable();
  }
  stopTimer() {
    if (this.subscription && !this.subscription.closed) {
      this.minute = this.padZero(0);
      this.seconds = this.padZero(0);
      this.unsubscribeObservable();
    }
  }

  resetTimeLapsed(): void {
    this.timeLapsedSinceInactivity = 0;
  }
}
