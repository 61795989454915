export interface BaseAPIResponse {
  result?: any;
  status: string;
  message: string;
  error?: any;
}

export interface BaseAPIPayload {
  scenario_id: string;
  scenario_version?: string;
  theme_id: string;
  user_id: string;
}
