import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import {
  ConfirmDialogParams,
  ErrorDialogParams,
  InfoDialogParams,
  InputDialogParams,
  SuccessDialogParams,
} from './dialog.model';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogRef: OverlayRef;
  private subscription: Subscription;
  showLoader = false

  constructor(private overlay: Overlay) {}

  open<T>(component: ComponentType<T>, config?: OverlayConfig) {
    this.dialogRef = this.overlay.create({
      hasBackdrop: true,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      ...config,
    });

    const portal = new ComponentPortal(component);

    return this.dialogRef.attach(portal);
  }

  confirm({ confirmationButtonLabel, cancelButtonLabel, title, message, afterOK, afterCancel, afterClose }: ConfirmDialogParams) {
    const { instance } = this.open(ConfirmDialogComponent);
    if (confirmationButtonLabel) {
      instance.confirmationButtonLabel = confirmationButtonLabel;
    }

    if(cancelButtonLabel){
      instance.cancelButtonLabel = cancelButtonLabel;
    }

    instance.title = title;
    instance.message = message;
    this.subscription = instance.afterOK.subscribe(() => afterOK());
    this.subscription.add(instance.afterCancel.subscribe(() => afterCancel?.()));
    this.subscription.add(instance.afterClose.subscribe(() => afterClose?.()));
    return instance;
  }

  input({ title, inputConfig, buttonConfig, afterOK, afterCancel }: InputDialogParams) {
    const { instance } = this.open(InputDialogComponent);
    instance.title = title;
    instance.inputConfig = inputConfig;
    instance.buttonConfig = buttonConfig;
    this.subscription = instance.afterOK.subscribe((arg: string) => afterOK(arg));
    this.subscription.add(instance.afterCancel.subscribe(() => afterCancel?.()));
  }

  error({ title, message, afterClose }: ErrorDialogParams) {
    const { instance } = this.open(ErrorDialogComponent);
    instance.title = title;
    instance.message = message;
    this.subscription = instance.afterClose.subscribe(() => afterClose?.());
  }

  info({ title, message, afterCancel }: InfoDialogParams) {
    const { instance } = this.open(InfoDialogComponent);
    instance.title = title;
    instance.message = message;
    this.subscription = instance.afterClose.subscribe(() => afterCancel?.());
  }

  success({ title, message, afterClose, afterCancel }: SuccessDialogParams) {
    const { instance } = this.open(SuccessDialogComponent);
    instance.title = title;
    instance.message = message;
    this.subscription = instance.afterClose.subscribe(() => afterCancel?.());
    this.subscription = instance.afterClose.subscribe(() => afterClose?.());
  }

  close() {
    this.dialogRef?.dispose();
    this.subscription?.unsubscribe();
  }
}
