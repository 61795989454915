export class GridConfiguration {
    gridStructure: GridStructure = new GridStructure();
    sortingDetails: SortingDetails = new SortingDetails();
    checkboxOptions: CheckboxOptions = new CheckboxOptions();
    columnsMetaData: ColumnsMetaData = new ColumnsMetaData();
    rowOptions: RowOptions = new RowOptions();
    statusIconsOptions: StatusIconsOptions = new StatusIconsOptions();
}

export class GridStructure {
    gridData: Array<any> = [];
    gridColumns: Array<any> = [];
    gridWidths: Array<any> = [];
    keyMappings: Array<any> = [];
    tableBodyMaxHeight: string;
    isInputFieldEnabled: boolean;
}

export class SortingDetails {
    isSortingEnabled: boolean;
    sortingOptions = {
        sortOrder: '',
        sortColumn: '',
    };
    unSortableColumns: Array<string> = [];
}

export class CheckboxOptions {
    isCheckBoxEnabled: boolean;
    selectAllByDefault: boolean;
    isSelectAllCheckboxVisible: boolean;
    // Added array with two new fields to disable particular checkboxes
    disableCheckboxColumns: any = {
       disableCheckBoxFor: '',
       disableCheckBoxValue: '',
    };

}

export class ColumnsMetaData {
    integerColumnName: Array<string> = [];
    isActionButtonsAvailable: boolean;
    isActionButtonsSecondaryAvailable: boolean;
    actionButtons: Array<string> = [];
    actionButtonsSecondary: Array<string> = [];
    filterOn: Array<string> = [];
    disabledActionButtons: Array<any> = [];
    disabledActionButtonScenarios: Array<any> = [];
}

export class RowOptions {
    itemsSelected: Array<any> = [];
    rowItemKeyClicked: string;
    searchText = '';
    inputFieldKey: string;
    highlightRowsonError: boolean;
    highlightUnSelectedRows: boolean;
    highlightForData: Array<string> = [];
}

export class StatusIconsOptions {
    showStatusIcon: boolean;
    statusIcons: Array<any> = [];
    displayInfoIcon = {
        column_name: '',
        tooltip_value: '',
    };
}
