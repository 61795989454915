export enum localNames {
  SCENARIOINFO = '_ngdc_scenario_info',
  SITERANKCATEGORY = '_ngdc_site_rank_category',
  COUNTRYRANKCATEGORY = '_ngdc_country_rank_category',
  PIRANKCATEGORY = '_ngdc_pi_rank_category',
  SITERANKLISTID = '_ngdc_site_rank_list_id',
  COUNTRYRANKLISTID = '_ngdc_country_rank_list_id',
  PIRANKLISTID = '_ngdc_pi_rank_list_id',
  OPTISITERANKLISTID = '_ngdc_opti_site_rank_list_id',
  OPTICOUNTRYRANKLISTID = '_ngdc_opti_country_rank_list_id',
  OPTIPIRANKLISTID = '_ngdc_opti_pi_rank_list_id',
  SITERESULTREGION = '_ngdc_site_result_region',
  SITERESULTCOUNTRY = '_ngdc_site_result_country',
  SITE_RESULT_TAGS = '_ngdc_site_result_tags',
  COUNTRYRESULTREGION = '_ngdc_country_result_region',
  COUNTRYRESULTCOUNTRY = '_ngdc_country_result_country',
  COUNTRY_RESULT_MARKET_LAUNCH_PLAN = '_ngdc_country_result_market_launch_plan',
  COUNTRY_RESULT_POST_TRIAL_ACCESS = '_ngdc_country_result_post_trial_access',
  COUNTRY_OUTREACH_RANK_LIST_ID = 'COUNTRY_OUTREACH_RANK_LIST_ID',
  COUNTRY_OUTREACH_RESULT_REGION = 'COUNTRY_OUTREACH_RESULT_REGION',
  COUNTRY_OUTREACH_RESULT_COUNTRY = 'COUNTRY_OUTREACH_RESULT_COUNTRY',
  COUNTRY_OUTREACH_RESULT_MARKET_LAUNCH_PLAN = 'COUNTRY_OUTREACH_RESULT_MARKET_LAUNCH_PLAN',
  COUNTRY_OUTREACH_RESULT_POST_TRIAL_ACCESS = 'COUNTRY_OUTREACH_RESULT_POST_TRIAL_ACCESS',
  SITE_OUTREACH_RANK_LIST_ID = 'SITE_OUTREACH_RANK_LIST_ID',
  SITE_OUTREACH_RESULT_REGION = 'SITE_OUTREACH_RESULT_REGION',
  SITE_OUTREACH_RESULT_COUNTRY = 'SITE_OUTREACH_RESULT_COUNTRY',
  SITE_OUTREACH_RESULT_TAGS = 'SITE_OUTREACH_RESULT_TAGS',
  PIRESULTREGION = '_ngdc_pi_result_region',
  PIRESULTCOUNTRY = '_ngdc_pi_result_country',
  FILTERCONSTRAINTSSAVE = '_ngdc_filter_constraints_save',
  REGIONCONFIGSAVE = '_ngdc_region_config_save',
  COUNTRYCONFIGSAVE = '_ngdc_country_config_save',
  SITECONFIGSAVE = '_ngdc_site_config_save',
  SAVECONSTRAINTRESPONSE = '_ngdc_save_constraint_response',
  OPTIMIZERESULT = '_ngdc_optimize_result',
  SELECTEDREGIONS = '_ngdc_selected_regions',
  SELECTEDCOUNTRYREGIONS = '_ngdc_selected_country_regions',
  SELECTEDCOUNTRIES = '__ngdc_selected_countries',
  SELECTEDSITES = '_ngdc_selected_sites',
  SITERESULTOPTIMIZE = '_ngdc_site_optimize_result',
  OPTISITERANKCATEGORY = '_ngdc_opti_site_rank_category',
  OPTICOUNTRYRANKCATEGORY = '_ngdc_opti_country_rank_category',
  OPTIPIRANKCATEGORY = '_ngdc_opti_pi_rank_category',
  SITEWEIGHTING = '_ngdc_site_weighting_result',
  COUNTRYWEIGHTING = '_ngdc_country_weighting_result',
  PIWEIGHTING = '_ngdc_pi_weighting_result',
  TEMPSITEWEIGHTING = '_ngdc_temp_site_weighting_result',
  TEMPCOUNTRYWEIGHTING = '_ngdc_temp_country_weighting_result',
  TEMPPIWEIGHTING = '_ngdc_temp_pi_weighting_result',
  OPTISITERESULTREGION = '_ngdc_opti_site_region',
  OPTISITERESULTCOUNTRY = '_ngdc_opti_site_country',
  OPTI_SITE_RESULT_TAGS = '_ngdc_opti_site_result_tags',
  OPTICOUNTRYRESULTREGION = '_ngdc_opti_country_result_region',
  OPTICOUNTRYRESULTCOUNTRY = '_ngdc_opti_country_result_country',
  OPTI_COUNTRY_RESULT_MARKET_LAUNCH_PLAN = '_ngdc_opti_country_result_market_launch_plan',
  OPTI_COUNTRY_RESULT_POST_TRIAL_ACCESS = '_ngdc_opti_country_result_post_trial_access',
  OPTIPIRESULTREGION = '_ngdc_opti_pi_result_region',
  OPTIPIRESULTCOUNTRY = '_ngdc_opti_pi_result_country',
  OPTIADDSITE = '_ngdc_opti_add_site',
  OPTIADDCOUNTRY = '_ngdc_opti_add_country',
  OPTIADDCREGION = '_ngdc_opti_add_region',
  OPTIADDSITERESULTREGION = '_ngdc_opti_add_site_region',
  OPTIADDSITERESULTCOUNTRY = '_ngdc_opti_add_site_country',
  COUNTRYOPTIRESULTREGION = '_ngdc_country_opti_result_region',
  COUNTRYOPTIRESULTCOUNTRY = '_ngdc_country_opti_result_country',
  RESULTVIEW = '_ngdc_result_view',
  PROFILELISTVIEW = '_ngdc_profile_list_view',
  ISOPTIMIZERUNNING = '_ngdc_optimize_running',
  SCENARIOMODE = '_ngdc_scenario_mode',
  SCENARIOMODEFORVIEW = '_ngdc_scenario_mode_for_view',
  SCENARIOHEADER = '_ngdc_scenario_header',
  PATIENTGEOLOCATION = '_ngdc_geo_radio_button_value',
  RANKSAVED = '_ngdc_rank_saved',
  LANDSCAPE_FILTER = '_ngdc_landscape_filter',
  LANDSCAPE_FILTER_WITH_ALL = '_ngdc_landscape_filter_with_all',
  COUNTRYCLINICALCHATRTDATA = '_ngdc_clinical_chart_data',
  COUNTRYNAME_ROUTE = '_ngdc_countryname_filter',
  PATIENTCOUNTRY = '_ngdc_patient_country_type',
  COUNTRYLIST = '_ngdc_landscape_country_list',
  SHOWFILTERFLYOUT = '_ngdc_show_flyoutfilter',
  LANDSCAPE_ID = '_ngdc_landscape_id',
  QUALTRICS_VIEW_TYPE = '_ngdc_qualtrics_view_type',
  FINALLISTID = '_ngdc_final_list_id',
  OPTIMIZESITEID = '_ngdc_optimize_site_id',
  TABNAME = '_ngdc_tabname',
  OPTIMIZEBACK = '_ngdc_optimize_back',
  DISPLAYSCENARIOSTATUS = '_ngdc_display_scenario_status',
  FILTERCRITERIACHEVRON = '_ngdc_filter_criteria_chevron',
  TRIALUNIVERSECHEVRON = '_ngdc_trial_universe_chevron',
  BENCHMARKCHEVRON = '_ngdc_benchmark_chevron',
  SHOW_ALL_DATA = '_ngdc_show_all_data',
  LOCK_STATUS = '_ngdc_site_lock_status',
  SURVEY_STATUS = '_ngdc_survey_status',
  USER_DETAILS = '_ngdc_user_details',
  LANDSCAPEGENERATE_SITE = '_ngdc_landscape_generate_details',
  QUALTRICS_USER_ID = '_ngdc_qualtrics_user_id',
  PE_GENERATION_STATUS = '_ngdc_pe_generation_status',
  COUNTRY_IS_SAVED_FLAG = '_ngdc_country_issaved_flag',
  PI_IS_SAVED_FLAG = '_ngdc_pi_issaved_flag',
  DATA_SOURCE_CHANGE = '_ngdc_datasource_change',
  NOTIFICATION_STATUS = '_ngdc_notification_status',
  UNSAVEDCHANGES = '_ngdc_unsaved_changes',
  COUNTRY_DETAILS = '_ngdc_country_details',
  SITE_DETAILS = '_ngdc_site_details',
  USER_ACTIVE_STATE = 'active',
  user_details = 'user-details',

}
