import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';
import { appSetting } from './shared/dependency/constants';
import { AuthService } from './shared/services/auth.service';
import { HttpHandlerService } from './shared/services/http-handler.service';
import { NotificationService } from './shared/services/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'enrollment-forecasting';
  loadingRouteConfig: boolean;

  // @HostListener('window:beforeunload', ['$event'])
  // beforeunloadHandler(e) {
  //   const loggedOut = localStorage.getItem('isLoggedOut');

  //   if (loggedOut === 'yes') {
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     return;
  //   }
  //   e.preventDefault();
  //   e.returnValue = '';
  //   this.notification.insertUserActivityDetails(false).then(data => {
  //     localStorage.setItem('pageLoadCancelled', data);
  //   });
  // }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(e) {
    // const loggedOut = localStorage.getItem('isLoggedOut');
    // if (loggedOut === 'yes') {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   return;
    // }
    const parts = this.router.url.split('/');
    if (parts[1] !== 'maintenance') {
      e.preventDefault();
      e.returnValue = '';
    }
    if (this.auth.getUserDetails()) {
      this.notification.insertUserActivityDetails(false, 'true').then(data => {
        localStorage.setItem('pageLoadCancelled', data);
      });
    }
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.httpHandler
      .postHttpResults(appSetting.INSERT_USER_ACTIVITY, this.notification.createInsertPayload(false, 'true'))
      .subscribe(resp => {
        localStorage.setItem('response', JSON.stringify(resp));
      });
  }

  constructor(
    private router: Router,
    private auth: AuthService,
    private notification: NotificationService,
    private httpHandler: HttpHandlerService
  ) {
    localStorage.setItem('isLoggedOut', 'no');
    let entries = window.performance.getEntries()[0] as PerformanceNavigationTiming;
    if (entries.type == 'back_forward') {
      sessionStorage.clear();
    }
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof NavigationEnd) {
        this.loadingRouteConfig = false;
      }
    });
  }

}
