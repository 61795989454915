import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './shared/interceptors/http.interceptor';
import { OverlayModule } from '@angular/cdk/overlay';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { NoAccessComponent } from './shared/components/no-access/no-access.component';
import { AuthService } from './shared/services/auth.service';
import { SessionIdInterceptor } from './shared/interceptors/session-id.interceptor';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { SessionHandlerModalComponent } from './session-handler-modal/session-handler-modal.component';
@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    NoAccessComponent,
    MaintenanceComponent,
    SessionHandlerModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    OverlayModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: SessionIdInterceptor, multi: true },
    AuthService

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
