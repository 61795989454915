<div id="overlay"></div>
<ctfo-dialog (ctfoDialogCloseEmitter)="afterClose.emit()">
  <ctfo-dialog-title>{{ title }}</ctfo-dialog-title>
  <ctfo-dialog-content>{{ message }}</ctfo-dialog-content>
  <ctfo-dialog-actions>
    <!--  <button class="button-secondary" ctfoDialogClose (click)="afterClose.emit()">
      <img class="" src="assets/images/icon_cancel.svg" />
      <span>Close</span>
    </button> -->
    <div class="cursor-pointer" ctfoDialogClose (click)="afterClose.emit()" style="color: #27a6a4;">
      <img src="assets/images/shared/cancel.svg" width="16" height="16" class="mr-3">Close
    </div>
  </ctfo-dialog-actions>
</ctfo-dialog>
