import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DialogComponent } from './dialog/dialog.component';
import { DialogActionsComponent } from './dialog-actions/dialog-actions.component';
import { DialogCloseDirective } from './dialog-close.directive';
import { DialogContentComponent } from './dialog-content/dialog-content.component';
import { DialogTitleComponent } from './dialog-title/dialog-title.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { InputDialogComponent } from './input-dialog/input-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    DialogActionsComponent,
    DialogCloseDirective,
    DialogComponent,
    DialogContentComponent,
    DialogTitleComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    InputDialogComponent,
    SuccessDialogComponent,
  ],
  imports: [CommonModule, FormsModule],
  exports: [
    DialogActionsComponent,
    DialogCloseDirective,
    DialogComponent,
    DialogContentComponent,
    DialogTitleComponent,
  ],
})
export class DialogModule {}
