import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HtmltagHandlerService {
  private data :boolean;  

  constructor() {}
  
 setOption(option, value) { 
      this.data=value;
  }  
  
  getOption() {  
    return this.data;  
  } 

  transform(value: any): any {
    return value.replace(/<[^>]*>/g,'');
  }
}
