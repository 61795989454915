<div class="modal-header border-bottom-0 py-0 d-flex align-items-center">
  <h5 class="modal-title font-18 d-flex align-items-center">Customize Activation Curve
    <p class="font-18 align-items-center" style="margin-top:14px;" *ngIf="countryLevelData.name">
      - {{countryLevelData.name}}
    </p>
  </h5>
</div>
<div class="modal-body pt-0 pb-2">
  <div class="row">
    <div class="col-12">
      <app-alerts [messageType]="'error'" [message]="errorMessage" *ngIf="errorMessage.length>0"
        (alertClosed)="errorMessage=''" [showCloseButton]="true"></app-alerts>
    </div>
    <div class="col-md-5 pr-lg-4 pr-md-3" id="col-table">
      <div class="table-responsive">
        <table class="table col-12">
          <thead class="table-header">
            <tr>
              <th *ngIf="!geoLevelData.toggleSelection">Site Activation Duration<sup>*</sup></th>
              <th *ngIf="geoLevelData.toggleSelection">Date of Activation<sup>*</sup></th>
              <th>% Sites Activated <sup>*</sup></th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <ng-container *ngFor="let row of customInputs; let i=index; let f=first; let l=last">
              <tr *ngIf="f || l">
                <td
                  *ngIf="(geoLevelData.toggleSelection || (!geoLevelData.toggleSelection && legendPopupOpen)) && !(geoLevelData.toggleSelection && legendPopupOpen)">
                  {{row.perc_trial_duration.input}}</td>
                <td
                  *ngIf="!((geoLevelData.toggleSelection || (!geoLevelData.toggleSelection && legendPopupOpen)) && !(geoLevelData.toggleSelection && legendPopupOpen))">
                  {{row.perc_trial_duration}}</td>
                <td>{{row.perc_site_activated}}</td>
                <td></td>
              </tr>
              <tr *ngIf="!f && !l">
                <td>
                  <span
                    *ngIf="(geoLevelData.toggleSelection || (!geoLevelData.toggleSelection && legendPopupOpen)) && !(geoLevelData.toggleSelection && legendPopupOpen); else dateInput">
                    <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left"
                      [startDate]="customInputs[i - 1].perc_trial_duration.model"
                      (dateSelect)="onGridInputChange('grid', date, 'perc_trial_duration', i)" #date="ngbDatepicker"
                      [(ngModel)]="row.perc_trial_duration.model" onkeydown="event.preventDefault();"
                      (click)="date.toggle(); $event.stopPropagation();" autocomplete="off"
                      [minDate]="customInputs[i -1].perc_trial_duration.model"
                      [disabled]="isGridInputDisabled(i, 'perc_trial_duration') || !accessMode || popupOpen">
                  </span>
                  <ng-template #dateInput>
                    <input type="text" class="" [(ngModel)]="row.perc_trial_duration"
                      [ngClass]="{'error-required': isError && focussedItem.row === i && focussedItem.column === 'perc_trial_duration'}"
                      onkeydown="if(event.key==='.'){event.preventDefault();}"
                      oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                      (ngModelChange)="onGridInputChange('grid', $event, 'perc_trial_duration', i)"
                      (focus)="onGridInputFocus(i, 'perc_trial_duration')"
                      (focusout)="onGridInputFocusOut(i, 'perc_trial_duration')"
                      [disabled]="isGridInputDisabled(i, 'perc_trial_duration') || !accessMode || popupOpen">
                  </ng-template>

                </td>
                <td>
                  <input type="text" class="" [(ngModel)]="row.perc_site_activated"
                    [ngClass]="{'error-required': isError && focussedItem.row === i && focussedItem.column === 'perc_site_activated'}"
                    onkeydown="if(event.key==='.'){event.preventDefault();}"
                    oninput="event.target.value = event.target.value.replace(/[^0-9]*/g,'');"
                    (ngModelChange)="onGridInputChange('grid', $event, 'perc_site_activated', i)"
                    (focus)="onGridInputFocus(i, 'perc_site_activated')"
                    (focusout)="onGridInputFocusOut(i, 'perc_site_activated')"
                    [disabled]="isGridInputDisabled(i, 'perc_site_activated') || !accessMode || popupOpen">
                </td>
                <td>
                  <button class="btn btn-secondary-white p-0" (click)="deleteCoordinate(i)"
                    [disabled]="customInputs.length <= requiredCoordinates || !accessMode || popupOpen">
                    <img src="assets/images/shared/delete.svg" alt="delete">
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-7 d-flex flex-column pl-lg-4 pl-md-3" id="col-graph">
      <div class="d-flex justify-content-between mb-4 mx-0">
        <div class="item start-date">
          <label class="item-label font-weight-bold mb-2">
            <span>
              Site Activation Start <sup>*</sup>
              <span>
                <img placement="top" tooltipClass="tooltip-class" ngbTooltip="{{toolTip.startDate}}"
                  class="cursor-pointer ml-1 mb-1" src="assets/images/shared/info-icon.svg" height="12" width="12"
                  alt="tooltip">
              </span>
            </span>
          </label>
          <div class="item-input">
            <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left"
              [startDate]="geoLevelData.startDate.model" #startDate="ngbDatepicker" autocomplete="off"
              [(ngModel)]="geoLevelData.startDate.model" onkeydown="event.preventDefault();"
              (dateSelect)="onGridInputChange('country', startDate, 'startDate', 0)"
              (click)="startDate.toggle(); $event.stopPropagation();" [disabled]="popupOpen">
          </div>
        </div>
        <div class="item end-date">
          <label class="item-label font-weight-bold mb-2">
            <span>
              Site Activation End <sup>*</sup>
              <span>
                <img placement="top" tooltipClass="tooltip-class" ngbTooltip="{{toolTip.endDate}}"
                  class="cursor-pointer ml-1 mb-1" src="assets/images/shared/info-icon.svg" height="12" width="12"
                  alt="tooltip">
              </span>
            </span>
          </label>
          <div class="item-input">
            <input class="calendar" autoClose=true ngbDatepicker placement="bottom-left"
              [startDate]="geoLevelData.endDate.model" [minDate]="geoLevelData.startDate.model"
              (dateSelect)="onGridInputChange('country', endDate, 'endDate', 0)" #endDate="ngbDatepicker"
              onkeydown="event.preventDefault();" [(ngModel)]="geoLevelData.endDate.model"
              (click)="endDate.toggle(); $event.stopPropagation();" autocomplete="off" [disabled]="popupOpen">
          </div>
        </div>
        <div class="item toggle-switch">
          <label class="item-label font-weight-bold mb-2">
            <span>
              %/Date
            </span>
          </label>
          <div>
            <label class="toggle">
              <input class="toggle-inp" type="checkbox" [(ngModel)]="geoLevelData.toggleSelection"
                (click)="legendToggle($event, false)"
                [disabled]="geoLevelData.startDate.input == '' || geoLevelData.endDate.input == '' || popupOpen">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="button-container mb-1">
        <button class="button-secondary" (click)="updateChart()"
          [disabled]="!checkForUnfilledInputs(this.geoLevelData.toggleSelection) || isError || chartPlotted || !accessMode || geoLevelData.startDate.input == '' || geoLevelData.endDate.input == '' || popupOpen">
          Calculate Activation Curve</button>
      </div>
      <div class="right-panel border-black border p-3">
        <header class="d-flex align-items-center pb-3">
          <h4 class="font-18 font-weight-bold mb-0">Simulated Activation Curve</h4>
        </header>
        <div class="graph-wrapper">
          <div id="chart-container" class="w-100 d-block">
            <div class="loader loading-circle">
              <i class="fa fa-spinner fa-spin"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container col-12 d-flex align-items-center">
      <span class="link-color cursor-pointer d-flex align-items-center" (click)="addCoordinate()"
        [ngClass]="{'disabled' : popupOpen}">
        <img src="assets/images/shared/add.svg" width="16" height="16" class="mr-2" alt="+">
        Add Coordinate
      </span>
    </div>
  </div>
</div>
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
  <button type="button" class="button-primary mr-3" (click)="modalDismissed('Update')"
    [disabled]="!enableSaveButton()">Update</button>
  <div class="cursor-pointer" (click)="modalDismissed('Cancel')" [ngClass]="{'disabled': popupOpen}">
    <img src="assets/images/shared/cancel.svg" class="mr-1" width="16" height="16">
    Cancel
  </div>
</div>
