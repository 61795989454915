import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { openCloseAccordionAnimation, openCloseSidebarAnimation } from '@app/shared/dependency/animations';

import { appSetting } from '@app/shared/dependency/constants/endpoints';
import { SOMETHING_WENT_WRONG } from '@app/shared/dependency/constants/messages';
import { DialogService } from '@app/shared/dependency/dialog';
import { GridColumnModel, GridConfigModel, GridDataModel } from '@app/shared/dependency/grid';
/* import { GridColumnModel, GridConfigModel, GridDataModel, } from '@app/shared/dependency/grid/grid'; */
import { Utils } from '@app/shared/dependency/utils';
import { AuthService } from '@app/shared/services/auth.service';
import { HttpHandlerService } from '@app/shared/services/http-handler.service';
import { NotificationService } from '@app/shared/services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap/accordion/accordion.module';
import { SessionStorageService } from 'angular-web-storage';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { CHEVRONS } from '../modules/modeling-predictions-shared/modeling-predictions-shared.model';
import * as messages from '@constants/messages';
import { HtmltagHandlerService } from '@app/shared/services/htmltag-handler..service';
import { ScenarioService } from '../modules/modeling-predictions-shared/scenarios.service';
import { Router } from '@angular/router';
@Component({
  selector: 'ctfo-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss'],
  animations: [openCloseAccordionAnimation, openCloseSidebarAnimation],
})
export class NotificationPanelComponent implements OnInit {
  isShow = false;
  pendingRequest = '';
  @ViewChild('a', { static: true }) a: NgbAccordion;
  countrySelected = [];
  noDataFlag = false;
  noDataFlagUser = false;
  notificationLoading = false;
  userLoading = false;
  sidebarOpen = 'closed';
  accordionData: any = [];
  notificationview = true;
  activeuserview = false;
  archiveview = false;
  pokeAllUsers;
  accordionTableObject = {
    accordionType: 'table',
    table: {
      isHeaderVisible: true,
      tableStyle: {
        border: '1px solid rgba(0, 0, 0, 0.125);',
        textTransform: 'none',
      },
      tableColumns: [
        {
          columnname: 'Demographics',
          columntype: 'checkboxLabel',
          columndatakey: 'country',
          isdisplayed: true,
          width: '60%',
          style: {
            color: '#27a6a4',
          },
        },
      ],
      tableScrollableColumns: [],
      tableEditableColumns: [],
      tableData: [],
      sortOn: 'poked_by',
      sortType: 'asc',
    },
  };

  accordionColumns: any = ['User Name'];
  accordionSortOn = 'id';
  accordionSortType = 'asc';
  @Input() sortOn; // stores the column name upon which the whole accordion is sorted
  @Input() sortType = 'asc';
  viewTypeData = 'notification';
  pokedby: any;
  detailsActions: any;
  inputResponse: any;
  notificationDetails: any;

  archiveDetails = [
    new GridColumnModel('Demographics', 'country', '26%', 'plaintext', { 'text-align': 'left' }),
    new GridColumnModel('Requested By', 'poked_by', '20%', 'plaintext', { 'text-align': 'left' }),
    new GridColumnModel('Requested To', 'poked_to', '20%', 'plaintext', {
      'text-align': 'left',
    }),
    new GridColumnModel('Last Updated', 'updated_timestamp', '18%', 'plaintext', {
      'text-align': 'left',
    }),
    new GridColumnModel('Status', 'poked_status', '13%', 'plaintext', {
      'text-align': 'left',
    }),
  ];

  archiveDetailsData = new GridDataModel();
  archiveDetailsProp = new GridConfigModel();
  archiveData: any = [];

  acquireDetails = [
    new GridColumnModel('', 'checked', '10%', 'checkbox', { 'text-align': 'left' }),
    new GridColumnModel('Demographics', 'country', '90%', 'plaintext', { 'text-align': 'left' }),
  ];

  acquireDetailsData = new GridDataModel();
  acquireDetailsProp = new GridConfigModel();

  acquireData: any = [];
  acquireCountryData: any;
  country_update: any = [];
  unsavedChnages = true;
  showbutton = false;
  showRequestAll = false;
  activeUserError = '';
  showAquire = false;
  showAquireAll = false;
  action: any;
  panel: any;
  showLoader: boolean;

  @Input() set notificationConfig(res: any) {
    this.sidebarOpen = res?.state;
    this.inputResponse = res;
    // this.accordionData = Utils.assignObject(this.accordionTableObject);
    this.setViewType(this.viewTypeData);
    if (res?.state == 'open') {
      this.isShow = true;
      if ($('body>.custom-overlay').length == 0) {
        $('.custom-overlay').appendTo('body');
      }
    } else {
      this.isShow = false;
    }
  }

  @Output() toogleAccordion = new EventEmitter();
  @Output() emitCancelFilterResult: EventEmitter<any> = new EventEmitter();

  grant = 'assets/images/check.svg';
  reject = 'assets/images/x.svg';
  request = 'assets/images/request.svg';
  refresh = 'assets/icons/refresh.svg';

  countrySet = new Set();

  refreshstatus = false;
  @ViewChild('inputSearch', { static: true }) inputSearch: ElementRef;
  searchText = '';
  searchStream = new Subject<string>();
  mode: string;
  allTabs = [...CHEVRONS.pre.list];
  tabInfo = {
    currentTab: this.allTabs[0],
    mode: '',
  };

  hasChangesSLP: boolean;
  chevronInfo = CHEVRONS.pre.info;
  whichButtonClicked = '';
  confirmationModal = {
    modalFor: '',
    modalMessage: '',
    modalTitle: '',
    okButtonText: '',
    cancelButtonText: '',
  };

  hideAcquireAllBtn = false;

  constructor(
    private dialog: DialogService,
    public auth: AuthService,
    public notification: NotificationService,
    private localStorage: SessionStorageService,
    private http: HttpHandlerService,
    private ngbModalServices: NgbModal,
    private htmltagService: HtmltagHandlerService,
    private scenarioServices: ScenarioService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.setViewType(this.viewTypeData);
    this.setModeAndTabInfo();
    this.initializeColumns();
    const inputRequest = this.localStorage.get('inputRequest');
    if (inputRequest) {
      this.hideAcquireAllBtn = (inputRequest.input_request.modelling_level.toLowerCase() == 'country') ? true : false;
    }
  }

  ngAfterViewInit(): void {
    this.searchStream
      ?.pipe(
        filter(data => data.length == 0 || data.length > 2),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((res: any) => {
        this.getUserList(this.viewTypeData);
      }),
      (error: any): any => { };
  }

  // ======================================== Popup Handlers ============================================
  private setModeAndTabInfo(): void {
    this.tabInfo = this.localStorage.get('tabInfo');
    if (this.tabInfo && this.tabInfo.mode) {
      this.mode = this.tabInfo.mode;
    } else {
      this.mode = this.localStorage.get('scenario-details') ? 'edit' : 'new';
    }
    if (this.tabInfo === null) {
      this.tabInfo = {
        currentTab: this.allTabs[0],
        mode: 'new',
      };
     // this.localStorage.set('tabInfo', this.tabInfo);
    }
  }

  confirmChanges(action, panel, templateRef: TemplateRef<any>): void {
    const valuesChanged = this.scenarioServices.valuesChanged;
    this.action = action;
    this.panel = panel;
    this.hasChangesSLP = this.htmltagService.getOption();
    switch (this.getCurrentModule()) {
      //for Pre
      case this.chevronInfo[0].key: {
        this.handlePopup(templateRef);
        break;
      }
      case this.chevronInfo[1].key: {
        this.handlePopup(templateRef);
        break;
      }
      case this.chevronInfo[2].key: {
        this.handlePopup(templateRef);
        break;
      }
      case this.chevronInfo[3].key: {
        this.performAction(this.action, this.panel);
        break;
      }
      // for Post
      case 'input_model_params': {
        this.handlePopup(templateRef, valuesChanged);
        break;
      }
      case 'country_selection': {
        this.handlePopup(templateRef, valuesChanged);
        break;
      }
      case 'site-activation': {
        this.handlePopup(templateRef, valuesChanged);
        break;
      }
      case 'view-enrollment': {
        this.performAction(this.action, this.panel);
        break;
      }
    }
  }

  private handlePopup(templateRef: TemplateRef<any>, valuesChanged?: boolean) {
    if (this.hasChangesSLP || valuesChanged) {
      this.configureWarningPopup(messages.EXIT_SCENARIO_POPUP_VALUES, 'modeling-inputs', undefined, templateRef);
    } else {
      this.performAction(this.action, this.panel);
    }
  }


  confirmationModalClosed(event): void {
    if (this.confirmationModal.okButtonText === event) {
      this.performAction(this.action, this.panel);
    }
  }

  getCurrentModule(): string {
    return this.localStorage.get('tabInfo').currentTab;
  }

  // setCurrentModule(module: string): void {
  //   this.tabInfo.currentTab = module;
  //   this.localStorage.set('tabInfo', this.tabInfo);
  // }

  private configureWarningPopup(
    popupVariables: any,
    screenName: string,
    buttonClicked: string,
    templateRef: TemplateRef<any>
  ): void {
    if (buttonClicked) {
      this.whichButtonClicked = buttonClicked;
    }
    this.confirmationModal.modalTitle = screenName
      ? popupVariables[screenName][this.mode]?.title
      : popupVariables.imp.title;
    this.confirmationModal.modalMessage = screenName
      ? popupVariables[screenName][this.mode]?.message
      : popupVariables.message;
    this.confirmationModal.okButtonText = screenName
      ? popupVariables[screenName][this.mode]?.ok_button_text
      : popupVariables.ok_button_text;
    this.confirmationModal.cancelButtonText = screenName
      ? popupVariables[screenName][this.mode]?.cancel_button_text
      : popupVariables.cancel_button_text;
    this.ngbModalServices.open(templateRef, {
      centered: true,
    });
  }

  closeFilterClicked(): any {
    this.viewTypeData = 'notification';
    this.isShow = false;
    this.emitCancelFilterResult.emit();
    setTimeout(() => {
      this.reloadComponent();
    }, 1000);

  }

  closePanel(): void {
    this.viewTypeData = 'notification';
    this.isShow = false;
    this.emitCancelFilterResult.emit();
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  onTableScrollEnd() { }

  getUserList(view): any {
    if (this.sidebarOpen) {
      this.archiveDetailsProp.setLoading(true);
      this.notificationLoading = true;
      this.userLoading = true;
      this.notification
        .fetchNotification()
        .then(res => {
          this.notificationDetails = res.result;
          if (view === 'notification') {
            if (Utils.isArrayExists(res.result.poked_country_details)) {
              this.noDataFlag = false;
              this.notificationLoading = false;
              let pokedCountry = [];
              if (this.searchText.length > 0) {
                pokedCountry = res.result.poked_country_details?.filter(el => {
                  let valueString = el.country?.filter(a => a.toLowerCase().includes(this.searchText.toLowerCase()));
                  if (valueString?.length > 0) {
                    return el;
                  }
                });
              } else {
                pokedCountry = res.result.poked_country_details;
              }
              if (Utils.isArrayExists(pokedCountry)) {
                this.accordionData = pokedCountry?.map(item => {
                  item.country = item.country?.map(str => ({ country: str }));
                  let themeObject = Utils.assignObject(this.accordionTableObject);
                  themeObject = {
                    ...themeObject,
                    ...item,
                  };
                  themeObject['table']['tableData'] = Utils.assignObject(item.country);

                  delete themeObject['scenarios'];
                  return themeObject;
                });
              } else {
                this.noDataFlag = true;
                this.notificationLoading = false;
                this.pendingRequest = 'Search result for ' + this.searchText + ' is not available.';
              }
            } else {
              this.noDataFlag = true;
              this.notificationLoading = false;
              this.pendingRequest = 'No Pending Request';
            }
          }
          if (view === 'activeuser') {
            if (Utils.isArrayExists(res.result.active_users_with_edit_access)) {
              this.noDataFlagUser = false;
              this.userLoading = false;
              const activeUser = res.result.active_users_with_edit_access?.filter(item => {
                return item.is_locked;
              });

              let users = [];
              if (this.searchText.length > 0) {
                users = activeUser?.filter(el => {
                  let valueString = el.country.filter(a => a.toLowerCase().includes(this.searchText.toLowerCase()));
                  if (valueString.length > 0) {
                    return el;
                  }
                });
              } else {
                users = activeUser;
              }

              this.pokeAllUsers = Utils.assignObject(activeUser);
              this.pokeAllUsers.map(el => {
                const { country, user_id } = el;
                el.poked_to = user_id;
                el.poked_by = this.auth.getUserDetails().user_id;
                el.poked_status = 'poked';
                el.country = country;
                delete el.is_locked;
                delete el.user_group;
                delete el.user_name;
                delete el.user_id;
                return el;
              });
              if (this.pokeAllUsers.length > 0) {
                this.showRequestAll = true;
              }

              if (Utils.isArrayExists(users)) {
                this.accordionData = users?.map(item => {
                  item.country = item.country?.map(str => ({ country: str }));
                  let themeObject = Utils.assignObject(this.accordionTableObject);
                  themeObject = {
                    ...themeObject,
                    ...item,
                  };
                  themeObject['table']['tableData'] = Utils.assignObject(item.country);

                  delete themeObject['scenarios'];
                  return themeObject;
                });
              } else {
                if (this.searchText.length > 0) {
                  this.activeUserError = 'Search result for ' + this.searchText + ' is not available.';
                } else {
                  this.activeUserError = 'No Active User Available';
                }
                this.noDataFlagUser = true;
                this.userLoading = false;
              }
            } else {
              this.activeUserError = 'No Active User Available';
              this.noDataFlagUser = true;
              this.userLoading = false;
            }

            if (Utils.isArrayExists(res.result.available_countries)) {
              if (this.searchText.length === 0) {
                this.acquireDetailsProp.setLoading(true);
              }
              const availableCountry = res.result.available_countries?.map(item => ({ checked: false, country: item }));
              this.acquireData = Utils.assignObject(availableCountry);
              this.acquireDetailsData.setData(this.acquireData);
              this.acquireDetailsProp.setLoading(false);
              this.showAquireAll = true;
            } else {
              this.showAquire = false;
              this.showAquireAll = false;
              this.acquireDetailsData.setData([]);
              this.acquireDetailsProp.setLoading(false);
              this.acquireDetailsProp.setNoData('No Demographics Available');
            }
          }
          if (view === 'archive') {
            if (Utils.isArrayExists(this.notificationDetails.archived_details)) {
              this.archiveData = this.notificationDetails.archived_details;
              /*  this.archiveData.map(e => {
                 e.poked_status = this.titlecase.transform(e.poked_status);
               }); */
              this.archiveDetailsData.setData(this.archiveData);
              this.archiveDetailsProp.setLoading(false);
            } else {
              this.archiveDetailsData.setData([]);
              this.archiveDetailsProp.setLoading(false);
              this.archiveDetailsProp.setNoData('No Archive Available');
            }
          }
        })
        .catch(() => {
          if (view === 'notification') {
            this.noDataFlag = true;
            this.notificationLoading = false;
            this.pendingRequest = SOMETHING_WENT_WRONG;
          }
          if (view === 'activeuser') {
            this.activeUserError = SOMETHING_WENT_WRONG;
            this.noDataFlagUser = true;
            this.userLoading = false;
            this.acquireDetailsData.setData([]);
            this.acquireDetailsProp.setLoading(false);
            this.acquireDetailsProp.setNoData(SOMETHING_WENT_WRONG);
          }
          if (view === 'archive') {
            this.archiveDetailsData.setData([]);
            this.archiveDetailsProp.setLoading(false);
            this.archiveDetailsProp.setNoData(SOMETHING_WENT_WRONG);
          }
        });
      //this.notificationLoading = false;
    }
  }

  tableRowClicked($event): void {
    //this.displayError.emit('');
    switch ($event.column?.columndatakey) {
      case 'checked': {
        const index = this.country_update.findIndex(s => s.country === $event.row.country);
        if (index === -1) {
          this.country_update.push($event.row);
        } else {
          this.country_update.splice(index, 1);
        }

        if (this.country_update.length > 0) {
          this.showAquire = true;
        } else {
          this.showAquire = false;
        }

        break;
      }

      default: {
        break;
      }
    }
  }

  allowAlphabetOnly(event): any {
    return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123);
  }

  initializeColumns(): void {
    this.archiveDetailsData.setGridIdentifier('archiveDetailsGrid');
    this.archiveDetailsData.setColumns(this.archiveDetails);
    this.archiveDetailsProp.setGridStyle({
      'max-height': '70vh',
      'min-height': '70vh',
    });

    this.archiveDetailsProp.setHeaderStyle({
      'line-height': '18px',
      'min-height': '25px',
      'fontSize': '13px',
      cursor: 'pointer',
    });

    this.archiveDetailsProp.setDataStyle({
      'min-height': '25px',
    });

    this.acquireDetailsData.setGridIdentifier('acquireDetailsGrid');
    this.acquireDetailsData.setColumns(this.acquireDetails);
    this.acquireDetailsProp.setGridStyle({
      'max-height': '125px',
      'min-height': '125px',
    });

    this.acquireDetailsProp.setHeaderStyle({
      'line-height': '18px',
      'min-height': '35px',
      cursor: 'pointer',
    });
    this.acquireDetailsProp.setDataStyle({
      'min-height': '35px',
    });
  }

  refreshNotification(): any {
    this.searchText = '';
    this.refreshstatus = true;
    if (this.viewTypeData === 'notification') {
      this.getUserList('notification');
    } else if (this.viewTypeData === 'activeuser') {
      this.getUserList('activeuser');
    } else {
      this.getUserList('archive');
    }
    setTimeout(() => {
      this.refreshstatus = false;
    }, 3000);
  }

  panelTableBtnClick(e, panel): any {
    if (this.pokedby !== panel.poked_by) {
      this.countrySelected = [];
    }
    this.pokedby = panel.poked_by;
    if (this.countrySelected.includes(e.data.country)) {
      this.countrySet.delete(e.data.country);
    } else {
      this.countrySet.add(e.data.country);
    }

    this.countrySelected = [...this.countrySet];

    this.detailsActions = {
      poked_by: panel.poked_by,
      country: this.countrySelected,
    };
    if (this.countrySelected.length > 0 && panel.poked_by == this.detailsActions.poked_by) {
      this.showbutton = true;
    } else {
      this.showbutton = false;
    }
  }

  requestall(): any { }
  performAction(action, panel?): any {
    this.htmltagService.setOption('hasChanges', false);
    const inputRequest = this.localStorage.get('inputRequest');
    const userDetails = this.localStorage.get('user-details');
    const scenarioDetails = this.localStorage.get('scenario-details');
    this.showbutton = false;
    const payload = {
      input_request: {
        study_id: inputRequest?.input_request.study_id ?? '',
        scenario_id: inputRequest?.input_request.scenario_id ?? '',
        theme_id: inputRequest?.input_request.theme_id ?? '',
        user_id: userDetails?.user_id ?? '',
        user_group: scenarioDetails?.user_group ? scenarioDetails?.user_group : (inputRequest?.input_request.user_group ? inputRequest?.input_request.user_group :userDetails.app_user_group ),
        poked_details: [],
      },
    };

    if (action === 'acceptall') {
      payload.input_request.poked_details = [
        {
          poked_by: panel.poked_by,
          poked_to: userDetails?.user_id ?? '',
          poked_status: 'accepted',
          country: panel.country.map(a => a.country),
        },
      ];
    }
    if (action === 'rejectall') {
      payload.input_request.poked_details = [
        {
          poked_by: panel.poked_by,
          poked_to: userDetails?.user_id ?? '',
          poked_status: 'rejected',
          country: panel.country.map(a => a.country),
        },
      ];
    }
    if (action === 'requestall') {
      payload.input_request.poked_details = [
        {
          poked_by: userDetails?.user_id ?? '',
          poked_to: panel?.user_id,
          poked_status: 'poked',
          country: panel.country.map(a => a.country),
        },
      ];
    }
    if (action === 'acquire') {
      const countryAcquire = this.country_update.map(a => a.country);
      payload.input_request.poked_details = [
        {
          poked_by: userDetails?.user_id ?? '',
          poked_to: null,
          poked_status: 'poked',
          country: countryAcquire,
        },
      ];
    }
    if (action === 'acquireall') {
      const countryAcquire = this.acquireData.map(a => a.country);
      payload.input_request.poked_details = [
        {
          poked_by: userDetails?.user_id ?? '',
          poked_to: null,
          poked_status: 'poked',
          country: countryAcquire,
        },
      ];
    }

    if (action === 'rejected' || action === 'accepted') {
      payload.input_request.poked_details = [
        {
          poked_by: panel.poked_by,
          poked_to: userDetails?.user_id ?? '',
          poked_status: action,
          country: this.detailsActions.country,
        },
      ];
    }

    if (action === 'poked') {
      payload.input_request.poked_details = [
        {
          poked_by: userDetails?.user_id ?? '',
          poked_to: panel.user_id,
          poked_status: action,
          country: this.detailsActions.country,
        },
      ];
    }

    if (action === 'requestalluser') {
      payload.input_request.poked_details = this.pokeAllUsers;
    }
    this.http.postHttpResults(appSetting.UPDATE_POKE_RELEASE, payload).subscribe((res: any) => {
      if (Utils.isSuccess(res)) {
        this.searchText = '';
        this.showbutton = false;
        if (action === 'accepted' || action === 'rejected' || action === 'rejectall' || action === 'acceptall') {
          this.countrySet.clear();
          if (action === 'accepted' || action === 'acceptall') {
            this.dialog.success({
              title: 'Request Successful',
              message: 'Your request has been processed successfully.',
              afterClose: () => {
                this.closeFilterClicked();
              },
            });
          }
          this.getUserList('notification');
        } else if (action === 'poked' || action === 'requestall' || action === 'requestalluser') {
          this.countrySet.clear();
          this.getUserList('activeuser');
        } else if (action === 'acquire' || action === 'acquireall') {
          this.countrySet.clear();
          this.showAquire = false;
          this.showAquireAll = false;
          this.acquireData = []
          this.country_update = Utils.assignObject([]);
          setTimeout(() => {
            this.notification.refreshScreen.next(true);
          }, 0);
          this.getUserList('activeuser');
        }
      } else {
        this.countrySet.clear();
        this.showbutton = false;
        this.dialog.error({
          title: 'Error Updating View',
          message: res.error.message,
        });
      }
    }),
      (): any => {
        this.countrySet.clear();
        this.showbutton = false;
        this.dialog.error({
          title: 'Error Updating View',
          message: SOMETHING_WENT_WRONG,
        });
      };
  }

  private getLatestScenarioDetail() {
    this.showLoader = true;
    const scenarioDetails = this.localStorage.get('scenario-details');
    const userDetails = this.localStorage.get('user-details');
    const studyDetails = this.localStorage.get('study-details');
    this.scenarioServices.getLatestScenarioDetail(scenarioDetails, userDetails, studyDetails).subscribe(
      response => {
        if (response.status === 'SUCCEEDED') {
          this.showLoader = false;
        } else {
          // this.handleError(SOMETHING_WENT_WRONG);
        }
      },
      () => {
        this.showLoader = false;
        // this.handleError(SOMETHING_WENT_WRONG);
      }
    );
  }
  // responseAction(action, panel?): any {
  //   this.unsavedChnages = this.notification.unsavedChnages;

  //   if (this.unsavedChnages) {
  //     this.dialog.confirm({
  //       title: 'Unsaved Changes',
  //       message: 'If you leave without saving, you will lose all the information. Are you sure you want to leave?',
  //       afterOK: () => {
  //         this.performAction(action, panel);
  //         setTimeout(() => {
  //           this.notification.refreshScreen.next(true);
  //         }, 0);
  //         this.notification.unsavedChnages = false;
  //       },
  //     });
  //   } else {
  //     this.performAction(action, panel);
  //   }
  // }
  panelTableScrollEnd(e): any { }

  panelTableSort(e, i): any { }

  accordionTableSort(event): void { }

  toggleAccordion(ele, id): void {
    ele.toggle('panel-' + id);
    this.countrySelected = Utils.assignObject([]);
    this.countrySet.clear();
    this.showbutton = false;
  }

  accordionTableBtnClicked($event): void { }
  accordionScrollEndClicked(): void { }
  accordionScrollEnd(): void { }

  setViewType(viewType: string): void {
    this.searchText = '';
    this.viewTypeData = viewType;
    if (this.viewTypeData === 'notification') {
      this.getUserList('notification');
      this.notificationview = true;
      this.activeuserview = false;
      this.archiveview = false;
    } else if (this.viewTypeData === 'activeuser') {
      this.notificationview = false;
      this.activeuserview = true;
      this.archiveview = false;
      this.getUserList('activeuser');
    } else {
      this.archiveview = true;
      this.notificationview = false;
      this.activeuserview = false;
      this.getUserList('archive');
    }
  }

  ngOnDestroy(): void {
    this.searchStream?.unsubscribe();
  }
}
