import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ADD_STUDY_POPUP } from '@constants/messages';
import { SessionStorageService } from 'angular-web-storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-copy-inputs-popup',
  templateUrl: './copy-inputs-popup.component.html',
  styleUrls: ['./copy-inputs-popup.component.scss'],
})
export class CopyInputsPopupComponent implements OnInit {

  @Input() openModel;
  @Input() modal: NgbModalRef;
  @Input() modalRef: any;

  tooltip = ADD_STUDY_POPUP;
  cohort_Type;
  studyType: any;
  nameOfSelectedCohort: any;

  @Input() cohortList: any;
  @Output() cohortIndexFrom: EventEmitter<any> = new EventEmitter<any>();

  constructor(private localStorage: SessionStorageService, private router: Router) { }

  ngOnInit(): void {
    this.setSelectedCohort(this.cohortList[0]);
  }

  setSelectedCohort(name): void {
    this.nameOfSelectedCohort = name;
  }

  returnSelectedCohort(): void {
    this.cohortIndexFrom.emit(this.nameOfSelectedCohort);
    this.modal.close();
  }

  onCancel(): void {
    this.modal.close();
  }

}


