import {
  InMemoryDbService,
} from 'angular-in-memory-web-api';

export class InMemoryService implements InMemoryDbService {
  createDb(): object {
    const loginData = {
      // status: 'SUCCEEDED',
      // valid_user: 'Yes',
      // result: [
      //   {
      //     name: 'admin',
      //     user_id: 'admin',
      //     role: 'admin',
      //   },
      // ],
      idm_response: {
        auth_status: 'success',
        get_users_application_roles_api: 'UserDetailWithApplicationRoles',
        idm_application_name: 'CTEMP',
        unique_username: 'zs\\ss22885',
        user_email: 'santhoshvenkateswaran.s@zs.com',
        username: 'santhoshvenkateswaran.s@zs.com',
      },
    };

    const csvFileValidationResponse = {
      result: {
        site_level: {
          file_name: 'error_csv_files/errors_Cb65b9e59epb1657220210701123002873_pb16572_sitelevel.xlsx',
          status: 'failed',
          summary_report: {
            STUDY_1: [{
              column: 'subject_id',
              message: 'The column subject_id should have the unique values for subject_id: SUBJECT__1',
            }, {
              column: 'site_country',
              message: 'The column site_country should have the unique values for site_id: SITE_26',
            }, {
              column: 'site_region',
              message: 'The column site_region should have the unique values for site_id: SITE_26',
            }, {
              column: 'trial_start_date',
              message: 'The column trial_start_date should have the unique values for study_data_id: STUDY_1',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_27',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_26',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_29',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_14',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_10',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_17',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_22',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_1',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_6',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_21',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_19',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_28',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_12',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_2',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_31',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_11',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_4',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_5',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_3',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_20',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_15',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_7',
            }, {
              column: 'enrollment_date',
              message: 'The date in the column Enrollment Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_18',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_27',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_26',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_29',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_14',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_10',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_17',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_22',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_1',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_6',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_21',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_19',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_28',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_12',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_2',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_31',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_11',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_4',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_5',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_3',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_20',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_15',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_7',
            }, {
              column: 'screen_fail_date',
              message: 'The date in the column Screen Fail Date should be greater than or equal to the date in the column Screening Date for site_id: SITE_18',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_27',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_26',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_29',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_14',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_10',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_17',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_22',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_1',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_6',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_21',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_19',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_28',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_12',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_2',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_31',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_11',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_4',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_5',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_3',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_20',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_15',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_7',
            }, {
              column: 'withdrawal_date',
              message: 'The date in the column Withdrawal Date should be greater than or equal to the date in the column Enrollment Date for site_id: SITE_18',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_27',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_26',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_29',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_14',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_10',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_17',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_22',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_1',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_6',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_21',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_19',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_28',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_12',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_2',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_31',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_11',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_4',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_5',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_3',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_20',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_15',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_7',
            }, {
              column: 'subject_first_visit_date',
              message: 'The date in the column Subject First Visit Date should be greater than or equal to the date in the column Trial Start Date for site_id: SITE_18',
            }],
          },
        },
        study_level: {
          file_name: 'error_csv_files/errors_Cb65b9e59epb1657220210701123002873_pb16572_studylevel.xlsx',
          status: 'failed',
          summary_report: {
            STUDY_2: [{
              column: 'site_patient_dropout_rate',
              message: 'The value of site_patient_dropout_rate is not in b/w 0 and 1 for study_data_id: STUDY_2',
            }],
          },
        },
      },
      status: 'SUCCEEDED',
    };

    // fail
    /*  const csvFileValidationStatus = {
       "result": {
         "site_level": {
           "data_type_validation": true,
           "file_name": "error_csv_files/errors_Ca8188b396ctemp_admin20210617134432881_ctemp_admin_sitelevel.xlsx",
           "null_validation": true,
           "status": "failed",
           "summary_report": {
             "STUDY_1": [
               {
                 "column": "trial_start_date",
                 "message": "The column trial_start_date should have the unique values for study_data_id: STUDY_1"
               }
             ]
           },
           "validation_rules": false
         },
         "study_level": {
           "data_type_validation": true,
           "file_name": "error_csv_files/errors_Ca8188b396ctemp_admin20210617134432881_ctemp_admin_studylevel.xlsx",
           "null_validation": true,
           "status": "failed",
           "summary_report": {
             "STUDY_2": [
               {
                 "column": "site_patient_dropout_rate",
                 "message": "The value of site_patient_dropout_rate is not in b/w 0 and 1 for study_data_id: STUDY_2"
               }
             ]
           },
           "validation_rules": false
         }
       },
       "status": "SUCCEEDED"
     } */

    // success
    const csvFileValidationStatus = {
      result: {
        site_level: {
          data_type_validation: true,
          null_validation: true,
          status: 'success',
          summary_report: {},
          validation_rules: true,
        },
        study_level: {
          data_type_validation: true,
          null_validation: true,
          status: 'success',
          summary_report: {},
          validation_rules: true,
        },
      },
      status: 'SUCCEEDED',
    };

    const uploadSiteLevelStudyFile = {
      status: 'SUCCEEDED',
      result: {
        theme_id: 'FILE_20200323072353828',
        file_name: 'uploaded_file_1_RDS.csv',
      },
    };

    const saveUploadedFile = {
      result: [{
          indication: 'DIABETIC MACULAR EDEMA',
          phase: '3',
          study_id: 'FILE3300302317',
          study_name: 'Short Term Outcomes of Acute Myocardial',
          study_type: 'Ongoing',
          theme_id: 'FILE_20200323072353828',
          therapeutic_area: 'Ophthalmology',
        },
        {
          indication: 'DIABETIC MACULAR EDEMA',
          phase: '32',
          study_id: 'FILE8141346040',
          study_name: 'STUDY_9762_ABC',
          study_type: 'Ongoing',
          theme_id: 'FILE_20200323072353828',
          therapeutic_area: 'Ophthalmology',
        },
        {
          indication: 'DIABETIC MACULAR EDEMA',
          phase: '8',
          study_id: 'FILE546642044',
          study_name: 'STUDY_618_ABC',
          study_type: 'Ongoing',
          theme_id: 'FILE_20200323072353828',
          therapeutic_area: 'Ophthalmology',
        },
      ],
      status: 'SUCCEEDED',
    };

    const generateValdationID = {
      result: {
        validation_id: 'C4bbccb4c5hk1774920210601070609436',
      },
      status: 'SUCCEEDED',
    };

    // const gridDataCTFO = {
    //   result: {
    //     error: {
    //       message: 'study_id_details_list is empty or study_id_details_list keys is missing'
    //     },
    //   },
    //   status: 'FAILED'
    // };
    const gridDataCTFO = {
      result: [{
          scenarios: [{
              countries: '32',
              indication: 'asthma',
              investigators: '1385',
              no_of_sites: '1024',
              optimization_criteria: 'speed',
              phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
              scenario_id: '7ad78dd7',
              scenario_name: 'TestFFScenario_test2',
              therapeutic_area: 'autoimmune/inflammation',
            },
            {
              countries: '27',
              indication: 'abdomen,acute,asthma,congestive heart failure',
              investigators: '972',
              no_of_sites: '296',
              optimization_criteria: 'speed',
              phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
              scenario_id: 'dd928b86',
              scenario_name: 'Scenario_DEVTEST1',
              therapeutic_area: 'autoimmune/inflammation,cardiovascular,Other',
            },
          ],
          study_name: 'FF_test2',
          theme_id: 'autoimmune/inflammation-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-4da',
        },
        {
          scenarios: [{
            countries: '32',
            indication: 'asthma',
            investigators: '1642',
            no_of_sites: '1139',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,Other,4',
            scenario_id: '3d7230b8',
            scenario_name: 'Scenario_BE_01',
            therapeutic_area: 'autoimmune/inflammation',
          }],
          study_name: 'Study_BE_01',
          theme_id: 'autoimmune/inflammation-asthma-1-1/2-1A-2-2/3-3-3/4-Other-4-374',
        },
        {
          scenarios: [{
            countries: '32',
            indication: 'asthma',
            investigators: '1642',
            no_of_sites: '1138',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,Other,4',
            scenario_id: 'ebb18838',
            scenario_name: 'scenario_s',
            therapeutic_area: 'autoimmune/inflammation',
          }],
          study_name: 'testing_s',
          theme_id: 'autoimmune/inflammation-asthma-1-1/2-1A-2-2/3-3-3/4-Other-4-e32',
        },
        {
          scenarios: [{
            countries: '30',
            indication: 'acute coronary syndromes,asthma,atopic dermatitis,cardiomyopathy',
            investigators: '4620',
            no_of_sites: '493',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: 'eec37f5a',
            scenario_name: 'Scenario_Test0806T1',
            therapeutic_area: 'autoimmune/inflammation,cardiovascular,oncology',
          }],
          study_name: 'Study_test0806T1',
          theme_id: 'autoimmune/inflammation-cardiovascular-oncology-acute coronary syndromes-asthma-atopic',
        },
        {
          scenarios: [{
            countries: '27',
            indication: 'abdomen, acute,asthma,congestive heart failure',
            investigators: '972',
            no_of_sites: '296',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: 'dd928b86',
            scenario_name: 'Scenario_DEVTEST1',
            therapeutic_area: 'autoimmune/inflammation,cardiovascular,Other',
          }],
          study_name: 'Study_DEVTEST1',
          theme_id: 'autoimmune/inflammation-cardiovascular-Other-abdomen',
        },
        {
          scenarios: [{
            countries: '32',
            indication: 'acute coronary syndromes,arrhythmia,cardiomyopathy,congestive heart failure',
            investigators: '1874',
            no_of_sites: '626',
            optimization_criteria: 'speed',
            phase: '1,1/2,2,1A,2/3,3,3/4,4,Other',
            scenario_id: 'dfd0c8b0',
            scenario_name: 'test',
            therapeutic_area: 'cardiovascular',
          }],
          study_name: 'testing_study',
          theme_id: 'cardiovascular-acute coronary',
        },
        {
          scenarios: [{
            countries: '33',
            indication: 'asthma',
            investigators: '1510',
            no_of_sites: '1102',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: 'e7ed6034',
            scenario_name: 'scenario1',
            therapeutic_area: 'immunology',
          }],
          study_name: 'sn1',
          theme_id: 'immunology-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-00c',
        },
        {
          scenarios: [{
            countries: '33',
            indication: 'asthma',
            investigators: '1432',
            no_of_sites: '1101',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: '09b0af01',
            scenario_name: 'Scenario_Testing-8May',
            therapeutic_area: 'immunology',
          }],
          study_name: 'study_ananya',
          theme_id: 'immunology-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-166',
        },
        {
          scenarios: [{
            countries: '33',
            indication: 'asthma',
            investigators: '1516',
            no_of_sites: '1107',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: '2dbb6a33',
            scenario_name: 'test',
            therapeutic_area: 'immunology',
          }],
          study_name: 'test_study',
          theme_id: 'immunology-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-4be',
        },
        {
          scenarios: [{
            countries: '33',
            indication: 'asthma',
            investigators: '1424',
            no_of_sites: '1101',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: '2254a2ff',
            scenario_name: 'test_SC_BE1',
            therapeutic_area: 'immunology',
          }],
          study_name: 'test_BE1',
          theme_id: 'immunology-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-880',
        },
      ],
      status: 'SUCCEEDED',
    };

    const gridSearchCTFO = {
      result: [{
          scenarios: [{
            countries: '33',
            indication: 'asthma',
            investigators: '1516',
            no_of_sites: '1107',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: '2dbb6a33',
            scenario_name: 'test',
            therapeutic_area: 'immunology',
          }],
          study_name: 'test_study',
          theme_id: 'immunology-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-4be',
        },
        {
          scenarios: [{
            countries: '33',
            indication: 'asthma',
            investigators: '1424',
            no_of_sites: '1101',
            optimization_criteria: 'speed',
            phase: '1,1/2,1A,2,2/3,3,3/4,4,Other',
            scenario_id: '2254a2ff',
            scenario_name: 'test_SC_BE1',
            therapeutic_area: 'immunology',
          }],
          study_name: 'test_BE1',
          theme_id: 'immunology-asthma-1-1/2-1A-2-2/3-3-3/4-4-Other-880',
        },
      ],
      status: 'SUCCEEDED',
    };

    const connectToCTFOFilters = {
      result: {
        input_request: {
          indication: [],
          phase: [],
          therapeutic_area: [],
        },
        response: {
          indication: [
            'acute chest syndrome',
            'acute coronary syndrome',
            'acute kidney injury',
            'addison disease',
            'adrenal insufficiency',
            'acute chest syndrome 1',
            'acute coronary syndrome 1',
            'acute kidney injury 1',
            'addison disease 1',
            'adrenal insufficiency 1',
          ],
          phase: ['1', '1/2', '1A', '2', '2/3', '3', '3/4', '4', 'Other'],
          therapeutic_area: [
            'autoimmune/inflammation',
            'cardiovascular',
            'cardiovascular and metabolism',
            'genitourinary',
            'cardiovascular 2',
            'cardiovascular and metabolism 2',
            'genitourinary 2',
            'cardiovascular 3',
            'cardiovascular and metabolism 3',
            'genitourinary 3',
            'cardiovascular 4',
          ],
        },
      },
    };

    const viewStudies = {
      result: [{
          created_by: 'aj1234',
          historical_data_available: 'N',
          historical_data_id: null,
          indication: 'INDICATION_2',
          is_baseline_tracked: null,
          phase: '2',
          study_feasibility: 'post',
          study_feasibility_label: 'Post-Feasibility',
          study_id: 'FILE222440212',
          study_name: 'Test_ongoing_study',
          study_type: 'Ongoing',
          theme_id: 'FILE_2020051114160922',
          therapeutic_area: 'THERAPY_AREA_2',
          updated_timestamp: '2021-09-29 12:10:43.008908',
          study_start_date: '2021-09-29',
          num_of_cohorts: '3',
          is_cohort: '0',
        },
        {
          created_by: 'aj1234',
          historical_data_available: 'N',
          historical_data_id: null,
          indication: 'INDICATION_2',
          is_baseline_tracked: null,
          phase: '2',
          study_feasibility: 'post',
          study_feasibility_label: 'Post-Feasibility',
          study_id: 'FILE222440213',
          study_name: 'Test_new_study',
          study_type: 'New',
          theme_id: 'FILE_2020051114160922',
          therapeutic_area: 'THERAPY_AREA_2',
          updated_timestamp: '2020-05-11 14:19:47.040583',
          study_start_date: '2020-05-11',
          num_of_cohorts: '2',
          is_cohort: '1',
        },
        {
          created_by: 'aj1234',
          historical_data_available: 'Y',
          historical_data_id: '2224402126222',
          indication: 'INDICATION_2',
          is_baseline_tracked: null,
          phase: '2',
          study_feasibility: 'pre',
          study_feasibility_label: 'Pre-Feasibility',
          study_id: 'FILE2224402126',
          study_name: 'Historical_data_available_new',
          study_type: 'New',
          theme_id: 'FILE_20200511141609228',
          therapeutic_area: 'THERAPY_AREA_2',
          updated_timestamp: '2020-05-11 14:19:47.040583',
          study_start_date: '2020-05-11',
          num_of_cohorts: '4',
          is_cohort: '0',
        },
        {
          created_by: 'aj1234',
          historical_data_available: 'N',
          historical_data_id: '2224402126222',
          indication: 'INDICATION_2',
          is_baseline_tracked: null,
          phase: '2',
          study_feasibility: 'pre',
          study_feasibility_label: 'Pre-Feasibility',
          study_id: 'FILE2224402127',
          study_name: 'Historical_data_unavailable_ongoing',
          study_type: 'Ongoing',
          theme_id: 'FILE_20200511141609228',
          therapeutic_area: 'THERAPY_AREA_2',
          updated_timestamp: '2020-05-11 14:19:47.040583',
          study_start_date: '2020-05-11',
          num_of_cohorts: '5',
          is_cohort: '1',
        },
      ],
      status: 'SUCCEEDED',
    };

    const viewScenarios = {
      result: [
        {
          complete_site_activation_by: '22',
          confidence_interval: '95',
          created_by: 'aj1234',
          created_timestamp: '2021-07-05 06:55:39.459403',
          custom_modeling_level: 'Global',
          distribution_type: 'Gamma',
          duration: '26',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'input_model_params',
          last_updated_timestamp: '2021-07-05 06:55:39.475675',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Country',
          old_scenario_version: '20210705065510462',
          param_estimation_tech: 'BMCMC',
          prediction_start_date: '2021-02-12',
          scenario_id: '2f664374-eb72-447a-8f2c-f7bbb8866e71',
          scenario_name: 'scne_5',
          scenario_status: 'Completed',
          site_activation_method: 'Historical',
          study_id: 'FILE1956808821',
          study_start_date: '2020-10-19',
          target_no_of_patients: '154',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '15',
          total_no_of_sites: '98',
          track_id: 'TM_20210702131715420',
          forecast_type: 'no_forecast',
          cohort_type: '',
          scenario_cohort_details: [{
              cohort_max_psm: '8',
              cohort_min_psm: '5',
              cohort_name: 'Experienced Cohort',
              delay: 20,
              dependencies: ['Experienced cohort1', 'Efficacy'],
              description: 'test',
              distribution_type: 'Gamma',
              enroll_rate_red_patient_drop_out_perc: 4,
              enroll_rate_red_screen_failure_perc: 3,
              exp_empty_sites_perc: 2,
              exp_site_activation_failure_perc: 1,
              indication: 'Breast Cancer',
              total_countries: 10,
              total_no_of_sites: 15,
              total_patients_to_be_enrolled: 200,
            },
            {
              cohort_max_psm: '9',
              cohort_min_psm: '6',
              cohort_name: 'Experienced Cohort2',
              delay: 20,
              dependencies: [''],
              description: 'test',
              distribution_type: 'Gamma',
              enroll_rate_red_patient_drop_out_perc: 4,
              enroll_rate_red_screen_failure_perc: 3,
              exp_empty_sites_perc: 2,
              exp_site_activation_failure_perc: 1,
              indication: 'Breast Cancer',
              total_countries: 10,
              total_no_of_sites: 15,
              total_patients_to_be_enrolled: 200,
            },
            {
              cohort_max_psm: '10',
              cohort_min_psm: '7',
              cohort_name: 'Experienced Cohort3',
              delay: 20,
              dependencies: ['cohort1'],
              description: 'test',
              distribution_type: 'Gamma',
              enroll_rate_red_patient_drop_out_perc: 4,
              enroll_rate_red_screen_failure_perc: 3,
              exp_empty_sites_perc: 2,
              exp_site_activation_failure_perc: 1,
              indication: 'Breast Cancer',
              total_countries: 10,
              total_no_of_sites: 15,
              total_patients_to_be_enrolled: 200,
            },
          ],
        },
        {
          complete_site_activation_by: '22',
          confidence_interval: '80',
          created_by: 'aj1234',
          created_timestamp: '2021-06-22 07:31:46.767091',
          custom_modeling_level: 'Global',
          distribution_type: 'Gamma',
          duration: '24.48903428',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'enrollment_prediction',
          last_updated_timestamp: '2021-07-02 13:17:39.481939',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Country',
          old_scenario_version: '20210608121321736',
          param_estimation_tech: 'MOM',
          prediction_start_date: '2021-02-12',
          scenario_id: '8c6a783b-601b-4bf3-a83e-2fc67f5f3735',
          scenario_name: 'New_5',
          scenario_status: 'Completed',
          site_activation_method: 'Historical',
          study_id: 'FILE1956808821',
          study_start_date: '2020-09-19',
          target_no_of_patients: '1354',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '3',
          total_no_of_sites: '98',
          track_id: 'TM_20210702131717633',
          forecast_type: 'actual_forecast',
        },
        {
          complete_site_activation_by: '22',
          confidence_interval: '80',
          created_by: 'aj1234',
          created_timestamp: '2021-06-22 07:31:46.767091',
          custom_modeling_level: 'Global',
          distribution_type: 'Gamma',
          duration: '24.4',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'enrollment_prediction',
          last_updated_timestamp: '2021-07-02 13:17:39.481939',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Country',
          old_scenario_version: '20210608121321736',
          param_estimation_tech: 'MOM',
          prediction_start_date: '2023-02-12',
          scenario_id: '8c6a783b-601b-4bf3-a83e-2fc67f5f3735',
          scenario_name: 'New_5_edit_param',
          scenario_status: 'Completed',
          site_activation_method: 'Historical',
          study_id: 'FILE1956808821',
          study_start_date: '2020-09-19',
          target_no_of_patients: '1354',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '3',
          total_no_of_sites: '98',
          track_id: null,
          forecast_type: 'edit_parameter',
        },
        {
          complete_site_activation_by: '22',
          confidence_interval: '80',
          created_by: 'aj1234',
          created_timestamp: '2021-06-22 07:31:46.767091',
          custom_modeling_level: 'Global',
          distribution_type: 'Gamma',
          duration: '24.4',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'enrollment_prediction',
          last_updated_timestamp: '2021-07-02 13:17:39.481939',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Country',
          old_scenario_version: '20210608121321736',
          param_estimation_tech: 'MOM',
          prediction_start_date: '2020-12-12',
          scenario_id: '8c6a783b-601b-4bf3-a83e-2fc67f5f3735',
          scenario_name: 'New_5_refresh',
          scenario_status: 'Completed',
          site_activation_method: 'Historical',
          study_id: 'FILE1956808821',
          study_start_date: '2020-09-19',
          target_no_of_patients: '1354',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '3',
          total_no_of_sites: '98',
          track_id: null,
          forecast_type: 'refresh_forecast',
        },
        {
          complete_site_activation_by: '20',
          confidence_interval: '95',
          created_by: 'aj1234',
          created_timestamp: '2021-07-01 11:18:15.290791',
          custom_modeling_level: 'Global',
          distribution_type: 'Gamma',
          duration: '24.4',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'enrollment_model_running',
          last_updated_timestamp: '2021-07-01 11:18:15.368618',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Global',
          old_scenario_version: '20210622081759783',
          param_estimation_tech: 'MOM',
          prediction_start_date: '2021-02-12',
          scenario_id: 'da177a37-8db7-4c3d-a7a9-9fea92dc0684',
          scenario_name: 'Scenario_111',
          scenario_status: 'In Progress',
          site_activation_method: 'Historical',
          study_id: 'FILE1956808821',
          study_start_date: '2020-09-19',
          target_no_of_patients: '255',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '7',
          total_no_of_sites: '130',
          track_id: null,
          forecast_type: 'no_forecast',
        },
        {
          complete_site_activation_by: '25',
          confidence_interval: '95',
          created_by: 'aj1234',
          created_timestamp: '2021-06-10 09:04:01.565696',
          custom_modeling_level: 'Global',
          distribution_type: 'Gamma',
          duration: '29.612903225806452',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'enrollment_prediction',
          last_updated_timestamp: '2021-06-11 09:29:55.343877',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Region',
          old_scenario_version: '20210610085930213',
          param_estimation_tech: 'BMCMC',
          prediction_start_date: '2021-02-12',
          scenario_id: 'bd2f2d0b-c5e6-41ff-8e63-e2b6d7b5510c',
          scenario_name: 'Regional_Data1',
          scenario_status: 'Completed',
          site_activation_method: 'Historical',
          study_id: 'FILE1956808821',
          study_start_date: '2020-09-19',
          target_no_of_patients: '322',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '7',
          total_no_of_sites: '130',
          track_id: null,
          forecast_type: 'actual_forecast',
        },
        {
          complete_site_activation_by: '22',
          confidence_interval: '95',
          created_by: 'aj1234',
          created_timestamp: '2021-06-08 11:09:20.744127',
          custom_modeling_level: null,
          distribution_type: 'Gamma',
          duration: '24.4',
          enroll_rate_red_patient_drop_out_perc: null,
          enroll_rate_red_screen_failure_perc: null,
          exp_empty_sites_perc: null,
          exp_enrollment_completion_date: '2022-10-01',
          exp_site_activation_failure_perc: null,
          is_baseline_tracked: '0',
          last_module_activated: 'simulation_activation',
          last_updated_timestamp: '2021-06-08 11:09:20.854987',
          max_trial_enrollment_duration: '36',
          modelling_level: 'Region',
          old_scenario_version: '20210608110805000',
          param_estimation_tech: 'MOM',
          prediction_start_date: '2021-02-12',
          scenario_id: '29472102-fbd2-43c6-8d82-66f1f5f2df21',
          scenario_name: 'New_2',
          scenario_status: 'In Progress',
          site_activation_method: null,
          study_id: 'FILE1956808821',
          study_start_date: '2020-09-19',
          target_no_of_patients: '500',
          theme_id: 'FILE_20210603101116854',
          threshold_empty_sites: null,
          total_countries: '7',
          total_no_of_sites: '130',
          track_id: null,
          forecast_type: '',
        },
        {
          "cohort_type": "single-entry",
          "complete_site_activation_by": null,
          "confidence_interval": 95,
          "created_timestamp": "2021-12-28 09:34:36.840245",
          "duration": null,
          "exp_enrollment_completion_date": null,
          "forecast_type": "no_forecast",
          "is_baseline_tracked": 0,
          "iterations": 100,
          "last_module_activated": "simulation_input_params",
          "last_updated_timestamp": "2021-12-28 10:41:18.043430",
          "max_trial_enrollment_duration": null,
          "modelling_level": "Country",
          "old_scenario_version": "20211228082040579",
          "prediction_start_date": null,
          "scenario_cohort_details": [{
              "cohort_max_psm": 5,
              "cohort_min_psm": 3,
              "cohort_name": "Cohort_1",
              "delay": null,
              "dependencies": [
                ""
              ],
              "description": 'description cohort_1',
              "distribution_type": "gamma",
              "enroll_rate_red_patient_drop_out_perc": 11,
              "enroll_rate_red_screen_failure_perc": 9,
              "exp_empty_sites_perc": 14,
              "exp_site_activation_failure_perc": 16,
              "indication": "Actinic Cheilitis",
              "total_countries": 5,
              "total_no_of_sites": 100,
              "total_patients_to_be_enrolled": 300
            },
            {
              "cohort_max_psm": 6,
              "cohort_min_psm": 4,
              "cohort_name": "Cohort_2",
              "delay": null,
              "dependencies": [
                ""
              ],
              "description": 'description cohort_2',
              "distribution_type": "gamma",
              "enroll_rate_red_patient_drop_out_perc": 12,
              "enroll_rate_red_screen_failure_perc": 10,
              "exp_empty_sites_perc": 13,
              "exp_site_activation_failure_perc": 8,
              "indication": "Actinic Cheilitis",
              "total_countries": 5,
              "total_no_of_sites": 110,
              "total_patients_to_be_enrolled": 300
            },
            {
              "cohort_max_psm": 7,
              "cohort_min_psm": 5,
              "cohort_name": "Cohort_3",
              "delay": null,
              "dependencies": [
                ""
              ],
              "description": 'description cohort_3',
              "distribution_type": "gamma",
              "enroll_rate_red_patient_drop_out_perc": 7,
              "enroll_rate_red_screen_failure_perc": 8,
              "exp_empty_sites_perc": 10,
              "exp_site_activation_failure_perc": 11,
              "indication": "Actinic Cheilitis",
              "total_countries": 2,
              "total_no_of_sites": 140,
              "total_patients_to_be_enrolled": 290
            }
          ],
          "scenario_created_by": "uk26146",
          "scenario_id": "ce652016-0dcf-4543-9a00-10547b41cd38",
          "scenario_name": "Cohort Scenario",
          "scenario_status": "In Progress",
          "site_activation_method": null,
          "study_id": "FILE1214320355",
          "study_start_date": "2018-06-01",
          "target_completion_prob": 100,
          "target_no_of_patients": 700,
          "theme_id": "FILE228081214320378",
          "track_id": null
        }
      ],
      status: 'SUCCEEDED',
    };


    const historicalMetrics = {
      result: [{
        created_by: 'aj1234',
        historical_trial_median_screening_failure_rate: '21.22',
        historical_trial_patient_drop_out_rate: '0.0',
        study_id: 'CTFO8073436255',
        theme_id: 'CTFO_20201124073636293',
        scenario_cohort_details: [{
          historical_trial_median_screening_failure_rate: '21.22',
          historical_trial_patient_drop_out_rate: '0.0',
          cohort_name: 'Cohort_1',
        }],
      }],
      status: 'SUCCEEDED',
    };

    const studyScenarioCount = {
      result: {
        post: {
          scenario_baseline: '1',
          scenario_completed: '1',
          scenario_inprogress: '21',
          study_count: '29',
        },
        pre: {
          scenario_baseline: '0',
          scenario_completed: '0',
          scenario_inprogress: '4',
          study_count: '3',
        },
      },
      status: 'SUCCEEDED',
    };

    const compareScenariosOutput = {
      result: {
        New_5: {
          enrollment_completion_probability_curve: {
            global: {
              '0.0': 0,
              0.01: 17.9859602649007,
              0.02: 18.2616195924205,
              0.03: 18.4423616770805,
              0.04: 18.581595316502,
              0.05: 18.6968551138984,
              0.06: 18.7965078480542,
              0.07: 18.8849747710708,
              0.08: 18.9651381319301,
              0.09: 19.0380741151465,
              0.1: 19.1050236790788,
              0.11: 19.1680343155539,
              0.12: 19.2273621125574,
              0.13: 19.2838206261164,
              0.14: 19.3377677139353,
              0.15: 19.3895095625828,
              0.16: 19.4393349168646,
              0.17: 19.4875142966069,
              0.18: 19.5342256214149,
              0.19: 19.5795530833413,
              0.2: 19.6237976144671,
              0.21: 19.6667952923018,
              0.22: 19.7090750773994,
              0.23: 19.7502910360885,
              0.24: 19.7908560311284,
              0.25: 19.8307812347606,
              0.26: 19.8698415802855,
              0.27: 19.9084403489854,
              0.28: 19.9466391509434,
              0.29: 19.9843334318652,
              0.3: 20.0213307240705,
              0.31: 20.0578658297372,
              0.32: 20.0939036381514,
              0.33: 20.1298906080615,
              0.34: 20.165448439352,
              0.35: 20.2007920792079,
              0.36: 20.2359071059944,
              0.37: 20.2709099950273,
              0.38: 20.3057521682783,
              0.39: 20.3405254220358,
              0.4: 20.3750876138981,
              0.41: 20.409633718013,
              0.42: 20.4441315498341,
              0.43: 20.4785282258064,
              0.44: 20.5130329359467,
              0.45: 20.5475346765212,
              0.46: 20.5820395738204,
              0.47: 20.6166361602603,
              0.48: 20.651278915724,
              0.49: 20.6861403329588,
              0.5: 20.7210564029072,
              0.51: 20.7561551087403,
              0.52: 20.7913025598849,
              0.53: 20.826722983414,
              0.54: 20.8624535315985,
              0.55: 20.8983647277996,
              0.56: 20.9346405228758,
              0.57: 20.9710928564001,
              0.58: 21.0077455334091,
              0.59: 21.0446169772257,
              0.6: 21.0818634571488,
              0.61: 21.1195923460898,
              0.62: 21.1577027308735,
              0.63: 21.1963017133305,
              0.64: 21.2354419773775,
              0.65: 21.2751706036745,
              0.66: 21.3154404799495,
              0.67: 21.3564419119975,
              0.68: 21.3980746852851,
              0.69: 21.4405557323152,
              0.7: 21.4838366652488,
              0.71: 21.5280946796034,
              0.72: 21.5730625334046,
              0.73: 21.6192517954765,
              0.74: 21.666594989786,
              0.75: 21.7151482479784,
              0.76: 21.7650048664432,
              0.77: 21.8162490508732,
              0.78: 21.8692198890219,
              0.79: 21.9239166029909,
              0.8: 21.9803964516482,
              0.81: 22.0384990781911,
              0.82: 22.0988137991076,
              0.83: 22.1616249863492,
              0.84: 22.2273873478785,
              0.85: 22.2962677529451,
              0.86: 22.3691134202963,
              0.87: 22.446035976016,
              0.88: 22.5281124497992,
              0.89: 22.6159883394999,
              0.9: 22.7106627592426,
              0.91: 22.8137188208617,
              0.92: 22.9270880876312,
              0.93: 23.0531610838002,
              0.94: 23.1955745073182,
              0.95: 23.3608235430964,
              0.96: 23.5590171643546,
              0.97: 23.8084117717145,
              0.98: 24.1504296260438,
              0.99: 24.7147308075773,
              '1.0': 192,
            },
          },
          post_feasibility_simulated_activation_curve: {
            actual: {
              '0.0': '0.0',
              '10.0': '17.35',
            },
            simulated: {
              '20.0': '32.65',
              '30.0': '46.94',
              '40.0': '61.22',
              '50.0': '69.39',
              '60.0': '77.55',
              '70.0': '85.71',
              '80.0': '92.86',
              '90.0': '97.96',
              '100.0': '100.0',
            },
          },
          predicted_patient_enrollment_chart: {
            global: {
              actual_patients_enrolled: {
                0: '223',
                1: '223',
                2: '223',
                3: '223',
                4: '223',
                5: '13',
                6: '82',
                7: '153',
                8: '227',
                9: '305',
                10: '384',
                11: '466',
                12: '551',
                13: '637',
                14: '724',
                15: '813',
                16: '905',
                17: '998',
                18: '1092',
                19: '1186',
                20: '1283',
                21: '1381',
                22: '1479',
                23: '1579',
                24: '1680',
                25: '1780',
                26: '1882',
                27: '1984',
                28: '2088',
                29: '2193',
                30: '2299',
                31: '2404',
                32: '2510',
                33: '2616',
                34: '2721',
                35: '2827',
                36: '2933',
                37: '3038',
                38: '3144',
                39: '3250',
                40: '3355',
                41: '3461',
                42: '3567',
                43: '3672',
                44: '3778',
                45: '3884',
                46: '3990',
                47: '4095',
                48: '4201',
                49: '4307',
                50: '4412',
                51: '4518',
                52: '4624',
                53: '4729',
                54: '4835',
                55: '4941',
                56: '5046',
                57: '5152',
                58: '5258',
                59: '5363',
                60: '5469',
                61: '5575',
                62: '5680',
                63: '5786',
                64: '5892',
                65: '5998',
                66: '6103',
                67: '6209',
                68: '6315',
                69: '6420',
                70: '6526',
                71: '6632',
                72: '6737',
                73: '6843',
                74: '6949',
                75: '7054',
                76: '7160',
                77: '7266',
                78: '7371',
                79: '7477',
                80: '7583',
                81: '7689',
                82: '7794',
                83: '7900',
                84: '8006',
                85: '8111',
                86: '8217',
                87: '8323',
                88: '8428',
                89: '8534',
                90: '8640',
                91: '8745',
                92: '8851',
                93: '8957',
                94: '9062',
                95: '9168',
                96: '9274',
                97: '9380',
                98: '9485',
                99: '9591',
                100: '9697',
                101: '9802',
                102: '9908',
                103: '10014',
                104: '10119',
                105: '10225',
                106: '10331',
                107: '10436',
                108: '10542',
                109: '10648',
                110: '10753',
                111: '10859',
                112: '10965',
                113: '11071',
                114: '11176',
                115: '11282',
                116: '11388',
                117: '11493',
                118: '11599',
                119: '11705',
                120: '11810',
                121: '11916',
                122: '12022',
                123: '12127',
                124: '12233',
                125: '12339',
                126: '12444',
                127: '12550',
                128: '12656',
                129: '12762',
                130: '12867',
                131: '12973',
                132: '13079',
                133: '13184',
                134: '13290',
                135: '13396',
                136: '13501',
                137: '13607',
                138: '13713',
                139: '13818',
                140: '13924',
                141: '14030',
                142: '14135',
                143: '14241',
                144: '14347',
                145: '14453',
                146: '14558',
                147: '14664',
                148: '14770',
                149: '14875',
                150: '14981',
                151: '15087',
                152: '15192',
                153: '15298',
                154: '15404',
                155: '15509',
                156: '15615',
                157: '15721',
                158: '15826',
                159: '15932',
                160: '16038',
                161: '16144',
                162: '16249',
                163: '16355',
                164: '16461',
                165: '16566',
                166: '16672',
                167: '16778',
                168: '16883',
                169: '16989',
                170: '17095',
                171: '17200',
                172: '17306',
                173: '17412',
                174: '17517',
                175: '17623',
                176: '17729',
                177: '17835',
                178: '17940',
                179: '18046',
                180: '18152',
                181: '18257',
                182: '18363',
                183: '18469',
                184: '18574',
                185: '18680',
                186: '18786',
                187: '18891',
                188: '18997',
                189: '19103',
                190: '19208',
                191: '19314',
                192: '19420',
              },
              enrollments: {
                0: '0',
                1: '0',
                2: '0',
                3: '0',
                4: '0',
                5: '13',
                6: '82',
                7: '153',
                8: '227',
                9: '305',
                10: '384',
                11: '466',
                12: '551',
                13: '637',
                14: '724',
                15: '813',
                16: '905',
                17: '998',
                18: '1092',
                19: '1186',
                20: '1283',
                21: '1381',
                22: '1479',
                23: '1579',
                24: '1680',
                25: '1780',
                26: '1882',
                27: '1984',
                28: '2088',
                29: '2193',
                30: '2299',
                31: '2404',
                32: '2510',
                33: '2616',
                34: '2721',
                35: '2827',
                36: '2933',
                37: '3038',
                38: '3144',
                39: '3250',
                40: '3355',
                41: '3461',
                42: '3567',
                43: '3672',
                44: '3778',
                45: '3884',
                46: '3990',
                47: '4095',
                48: '4201',
                49: '4307',
                50: '4412',
                51: '4518',
                52: '4624',
                53: '4729',
                54: '4835',
                55: '4941',
                56: '5046',
                57: '5152',
                58: '5258',
                59: '5363',
                60: '5469',
                61: '5575',
                62: '5680',
                63: '5786',
                64: '5892',
                65: '5998',
                66: '6103',
                67: '6209',
                68: '6315',
                69: '6420',
                70: '6526',
                71: '6632',
                72: '6737',
                73: '6843',
                74: '6949',
                75: '7054',
                76: '7160',
                77: '7266',
                78: '7371',
                79: '7477',
                80: '7583',
                81: '7689',
                82: '7794',
                83: '7900',
                84: '8006',
                85: '8111',
                86: '8217',
                87: '8323',
                88: '8428',
                89: '8534',
                90: '8640',
                91: '8745',
                92: '8851',
                93: '8957',
                94: '9062',
                95: '9168',
                96: '9274',
                97: '9380',
                98: '9485',
                99: '9591',
                100: '9697',
                101: '9802',
                102: '9908',
                103: '10014',
                104: '10119',
                105: '10225',
                106: '10331',
                107: '10436',
                108: '10542',
                109: '10648',
                110: '10753',
                111: '10859',
                112: '10965',
                113: '11071',
                114: '11176',
                115: '11282',
                116: '11388',
                117: '11493',
                118: '11599',
                119: '11705',
                120: '11810',
                121: '11916',
                122: '12022',
                123: '12127',
                124: '12233',
                125: '12339',
                126: '12444',
                127: '12550',
                128: '12656',
                129: '12762',
                130: '12867',
                131: '12973',
                132: '13079',
                133: '13184',
                134: '13290',
                135: '13396',
                136: '13501',
                137: '13607',
                138: '13713',
                139: '13818',
                140: '13924',
                141: '14030',
                142: '14135',
                143: '14241',
                144: '14347',
                145: '14453',
                146: '14558',
                147: '14664',
                148: '14770',
                149: '14875',
                150: '14981',
                151: '15087',
                152: '15192',
                153: '15298',
                154: '15404',
                155: '15509',
                156: '15615',
                157: '15721',
                158: '15826',
                159: '15932',
                160: '16038',
                161: '16144',
                162: '16249',
                163: '16355',
                164: '16461',
                165: '16566',
                166: '16672',
                167: '16778',
                168: '16883',
                169: '16989',
                170: '17095',
                171: '17200',
                172: '17306',
                173: '17412',
                174: '17517',
                175: '17623',
                176: '17729',
                177: '17835',
                178: '17940',
                179: '18046',
                180: '18152',
                181: '18257',
                182: '18363',
                183: '18469',
                184: '18574',
                185: '18680',
                186: '18786',
                187: '18891',
                188: '18997',
                189: '19103',
                190: '19208',
                191: '19314',
                192: '19420',
              },
              lower_confidence: {
                0: '0',
                1: '0',
                2: '0',
                3: '0',
                4: '0',
                5: '13',
                6: '82',
                7: '153',
                8: '227',
                9: '304',
                10: '383',
                11: '465',
                12: '550',
                13: '636',
                14: '723',
                15: '812',
                16: '904',
                17: '997',
                18: '1090',
                19: '1185',
                20: '1281',
                21: '1379',
                22: '1478',
                23: '1577',
                24: '1678',
                25: '1778',
                26: '1879',
                27: '1982',
                28: '2085',
                29: '2190',
                30: '2296',
                31: '2401',
                32: '2507',
                33: '2613',
                34: '2718',
                35: '2824',
                36: '2929',
                37: '3035',
                38: '3141',
                39: '3246',
                40: '3352',
                41: '3457',
                42: '3563',
                43: '3668',
                44: '3774',
                45: '3880',
                46: '3985',
                47: '4091',
                48: '4196',
                49: '4302',
                50: '4407',
                51: '4513',
                52: '4619',
                53: '4724',
                54: '4830',
                55: '4935',
                56: '5041',
                57: '5146',
                58: '5252',
                59: '5358',
                60: '5463',
                61: '5569',
                62: '5674',
                63: '5780',
                64: '5885',
                65: '5991',
                66: '6097',
                67: '6202',
                68: '6308',
                69: '6413',
                70: '6519',
                71: '6625',
                72: '6730',
                73: '6836',
                74: '6941',
                75: '7047',
                76: '7152',
                77: '7258',
                78: '7364',
                79: '7469',
                80: '7575',
                81: '7680',
                82: '7786',
                83: '7891',
                84: '7997',
                85: '8103',
                86: '8208',
                87: '8314',
                88: '8419',
                89: '8525',
                90: '8630',
                91: '8736',
                92: '8842',
                93: '8947',
                94: '9053',
                95: '9158',
                96: '9264',
                97: '9369',
                98: '9475',
                99: '9581',
                100: '9686',
                101: '9792',
                102: '9897',
                103: '10003',
                104: '10108',
                105: '10214',
                106: '10320',
                107: '10425',
                108: '10531',
                109: '10636',
                110: '10742',
                111: '10847',
                112: '10953',
                113: '11059',
                114: '11164',
                115: '11270',
                116: '11375',
                117: '11481',
                118: '11587',
                119: '11692',
                120: '11798',
                121: '11903',
                122: '12009',
                123: '12114',
                124: '12220',
                125: '12326',
                126: '12431',
                127: '12537',
                128: '12642',
                129: '12748',
                130: '12853',
                131: '12959',
                132: '13065',
                133: '13170',
                134: '13276',
                135: '13381',
                136: '13487',
                137: '13592',
                138: '13698',
                139: '13804',
                140: '13909',
                141: '14015',
                142: '14120',
                143: '14226',
                144: '14331',
                145: '14437',
                146: '14543',
                147: '14648',
                148: '14754',
                149: '14859',
                150: '14965',
                151: '15070',
                152: '15176',
                153: '15282',
                154: '15387',
                155: '15493',
                156: '15598',
                157: '15704',
                158: '15810',
                159: '15915',
                160: '16021',
                161: '16126',
                162: '16232',
                163: '16337',
                164: '16443',
                165: '16549',
                166: '16654',
                167: '16760',
                168: '16865',
                169: '16971',
                170: '17076',
                171: '17182',
                172: '17288',
                173: '17393',
                174: '17499',
                175: '17604',
                176: '17710',
                177: '17815',
                178: '17921',
                179: '18027',
                180: '18132',
                181: '18238',
                182: '18343',
                183: '18449',
                184: '18554',
                185: '18660',
                186: '18766',
                187: '18871',
                188: '18977',
                189: '19082',
                190: '19188',
                191: '19293',
                192: '19399',
              },
              upper_confidence: {
                0: '0',
                1: '0',
                2: '0',
                3: '0',
                4: '0',
                5: '13',
                6: '82',
                7: '153',
                8: '228',
                9: '305',
                10: '384',
                11: '467',
                12: '551',
                13: '638',
                14: '725',
                15: '815',
                16: '906',
                17: '999',
                18: '1093',
                19: '1188',
                20: '1285',
                21: '1382',
                22: '1481',
                23: '1581',
                24: '1682',
                25: '1782',
                26: '1884',
                27: '1986',
                28: '2090',
                29: '2195',
                30: '2301',
                31: '2407',
                32: '2513',
                33: '2619',
                34: '2724',
                35: '2830',
                36: '2936',
                37: '3042',
                38: '3148',
                39: '3253',
                40: '3359',
                41: '3465',
                42: '3571',
                43: '3676',
                44: '3782',
                45: '3888',
                46: '3994',
                47: '4100',
                48: '4205',
                49: '4311',
                50: '4417',
                51: '4523',
                52: '4629',
                53: '4734',
                54: '4840',
                55: '4946',
                56: '5052',
                57: '5158',
                58: '5263',
                59: '5369',
                60: '5475',
                61: '5581',
                62: '5687',
                63: '5792',
                64: '5898',
                65: '6004',
                66: '6110',
                67: '6216',
                68: '6321',
                69: '6427',
                70: '6533',
                71: '6639',
                72: '6745',
                73: '6850',
                74: '6956',
                75: '7062',
                76: '7168',
                77: '7274',
                78: '7379',
                79: '7485',
                80: '7591',
                81: '7697',
                82: '7803',
                83: '7908',
                84: '8014',
                85: '8120',
                86: '8226',
                87: '8332',
                88: '8437',
                89: '8543',
                90: '8649',
                91: '8755',
                92: '8861',
                93: '8966',
                94: '9072',
                95: '9178',
                96: '9284',
                97: '9390',
                98: '9495',
                99: '9601',
                100: '9707',
                101: '9813',
                102: '9919',
                103: '10024',
                104: '10130',
                105: '10236',
                106: '10342',
                107: '10448',
                108: '10553',
                109: '10659',
                110: '10765',
                111: '10871',
                112: '10977',
                113: '11082',
                114: '11188',
                115: '11294',
                116: '11400',
                117: '11506',
                118: '11611',
                119: '11717',
                120: '11823',
                121: '11929',
                122: '12035',
                123: '12140',
                124: '12246',
                125: '12352',
                126: '12458',
                127: '12564',
                128: '12669',
                129: '12775',
                130: '12881',
                131: '12987',
                132: '13093',
                133: '13198',
                134: '13304',
                135: '13410',
                136: '13516',
                137: '13622',
                138: '13727',
                139: '13833',
                140: '13939',
                141: '14045',
                142: '14151',
                143: '14256',
                144: '14362',
                145: '14468',
                146: '14574',
                147: '14680',
                148: '14785',
                149: '14891',
                150: '14997',
                151: '15103',
                152: '15209',
                153: '15314',
                154: '15420',
                155: '15526',
                156: '15632',
                157: '15738',
                158: '15843',
                159: '15949',
                160: '16055',
                161: '16161',
                162: '16267',
                163: '16372',
                164: '16478',
                165: '16584',
                166: '16690',
                167: '16796',
                168: '16901',
                169: '17007',
                170: '17113',
                171: '17219',
                172: '17325',
                173: '17430',
                174: '17536',
                175: '17642',
                176: '17748',
                177: '17854',
                178: '17959',
                179: '18065',
                180: '18171',
                181: '18277',
                182: '18383',
                183: '18488',
                184: '18594',
                185: '18700',
                186: '18806',
                187: '18912',
                188: '19017',
                189: '19123',
                190: '19229',
                191: '19335',
                192: '19441',
              },
            },
          },
          time_to_complete_enrollment: {
            5: '18.7',
            10: '19.11',
            20: '19.62',
            30: '20.02',
            40: '20.38',
            50: '20.72',
            60: '21.08',
            70: '21.48',
            80: '21.98',
            90: '22.71',
            95: '23.36',
            time_to_complete_enrollment: '20.72',
          },
        },
        Regional_Data1: {
          enrollment_completion_probability_curve: {
            global: {
              '0.0': 0,
              0.01: 11.2620556142203,
              0.02: 11.3914603516326,
              0.03: 11.4762034514078,
              0.04: 11.5413533834586,
              0.05: 11.595251293422,
              0.06: 11.6418007627197,
              0.07: 11.6831025449102,
              0.08: 11.7204928054171,
              0.09: 11.7548667548668,
              0.1: 11.7867856464781,
              0.11: 11.8167778836988,
              0.12: 11.8450569432482,
              0.13: 11.8718786016135,
              0.14: 11.8975322922691,
              0.15: 11.9221394719025,
              0.16: 11.945759751601,
              0.17: 11.9686587502433,
              0.18: 11.9908238969153,
              0.19: 12.0119822624776,
              0.2: 12.0320810069083,
              0.21: 12.0518926098093,
              0.22: 12.0710616438356,
              0.23: 12.0900162105464,
              0.24: 12.1085086042065,
              0.25: 12.126605328733,
              0.26: 12.1445864156019,
              0.27: 12.1621725731895,
              0.28: 12.1795366795367,
              0.29: 12.1966911764706,
              0.3: 12.2136138853874,
              0.31: 12.2304402760229,
              0.32: 12.2471023668063,
              0.33: 12.2635431918009,
              0.34: 12.2799295774648,
              0.35: 12.2961764705882,
              0.36: 12.312278978389,
              0.37: 12.328344983755,
              0.38: 12.3442833185361,
              0.39: 12.3601937907851,
              0.4: 12.376089540412,
              0.41: 12.3918999404407,
              0.42: 12.407738983388,
              0.43: 12.4235024419416,
              0.44: 12.439229002297,
              0.45: 12.455064051241,
              0.46: 12.4707652191355,
              0.47: 12.486684755301,
              0.48: 12.5025176233635,
              0.49: 12.5184138835637,
              0.5: 12.5343781597573,
              0.51: 12.5502988552325,
              0.52: 12.5664974619289,
              0.53: 12.5826635466477,
              0.54: 12.5989397492099,
              0.55: 12.6153217568948,
              0.56: 12.6318591318591,
              0.57: 12.6484767668479,
              0.58: 12.665227669853,
              0.59: 12.6822208487845,
              0.6: 12.6993599008879,
              0.61: 12.716606311433,
              0.62: 12.7341352136043,
              0.63: 12.7517664172901,
              0.64: 12.7697594501718,
              0.65: 12.788078087483,
              0.66: 12.8065494873404,
              0.67: 12.825311942959,
              0.68: 12.8443510246978,
              0.69: 12.8638853771597,
              0.7: 12.8837135614702,
              0.71: 12.9039601863617,
              0.72: 12.9245383145829,
              0.73: 12.9456209428541,
              0.74: 12.9673460676555,
              0.75: 12.9895142306869,
              0.76: 13.0118799499792,
              0.77: 13.0345908663392,
              0.78: 13.0580651923278,
              0.79: 13.0823170731707,
              0.8: 13.1074554465886,
              0.81: 13.1334109183242,
              0.82: 13.1603142584138,
              0.83: 13.1885385598637,
              0.84: 13.2180049182081,
              0.85: 13.2489537504024,
              0.86: 13.2815429371835,
              0.87: 13.3160229362761,
              0.88: 13.3527110724764,
              0.89: 13.3918977942782,
              0.9: 13.4343245912433,
              0.91: 13.4803045349222,
              0.92: 13.5309724689165,
              0.93: 13.5872164487652,
              0.94: 13.6507829221584,
              0.95: 13.724145785877,
              0.96: 13.8123197600646,
              0.97: 13.9240328253224,
              0.98: 14.0727024877883,
              0.99: 14.307656010381,
              '1.0': 24,
            },
          },
          post_feasibility_simulated_activation_curve: {
            actual: {},
            simulated: {},
          },
          predicted_patient_enrollment_chart: {
            global: {
              actual_patients_enrolled: {
                0: '223',
                1: '223',
                2: '223',
                3: '223',
                4: '223',
                5: '13',
                6: '86',
                7: '161',
                8: '240',
                9: '325',
                10: '413',
                11: '505',
                12: '601',
                13: '700',
                14: '801',
                15: '908',
                16: '1018',
                17: '1130',
                18: '1245',
                19: '1361',
                20: '1480',
                21: '1600',
                22: '1721',
                23: '1842',
                24: '1965',
              },
              enrollments: {
                0: '0',
                1: '0',
                2: '0',
                3: '0',
                4: '0',
                5: '13',
                6: '86',
                7: '161',
                8: '240',
                9: '325',
                10: '413',
                11: '505',
                12: '601',
                13: '700',
                14: '801',
                15: '908',
                16: '1018',
                17: '1130',
                18: '1245',
                19: '1361',
                20: '1480',
                21: '1600',
                22: '1721',
                23: '1842',
                24: '1965',
              },
              lower_confidence: {
                0: '0',
                1: '0',
                2: '0',
                3: '0',
                4: '0',
                5: '13',
                6: '86',
                7: '160',
                8: '239',
                9: '324',
                10: '413',
                11: '505',
                12: '600',
                13: '699',
                14: '801',
                15: '907',
                16: '1017',
                17: '1129',
                18: '1244',
                19: '1360',
                20: '1478',
                21: '1598',
                22: '1719',
                23: '1840',
                24: '1963',
              },
              upper_confidence: {
                0: '0',
                1: '0',
                2: '0',
                3: '0',
                4: '0',
                5: '14',
                6: '86',
                7: '161',
                8: '240',
                9: '325',
                10: '414',
                11: '506',
                12: '601',
                13: '700',
                14: '802',
                15: '909',
                16: '1019',
                17: '1132',
                18: '1246',
                19: '1363',
                20: '1481',
                21: '1601',
                22: '1722',
                23: '1844',
                24: '1968',
              },
            },
          },
          time_to_complete_enrollment: {
            5: '11.6',
            10: '11.79',
            20: '12.03',
            30: '12.21',
            40: '12.38',
            50: '12.53',
            60: '12.7',
            70: '12.88',
            80: '13.11',
            90: '13.43',
            95: '13.72',
            time_to_complete_enrollment: '12.53',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const compareScenariosOutputPre = {
      result: {
        Compare_Scenario_01: {
          enrollment_completion_probability: '0.5',
          mean_range: {
            max_range: '40.0',
            min_range: '10.0',
          },
          pre_feasibility_distribution_target_time_chart: {
            country: {
              Afghanistan: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
              Albania: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
            },
            global: {
              global: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
            },
          },
          pre_feasibility_predicted_patient_time_chart: {
            country: {
              Afghanistan: {
                actual_patients_enrolled: {
                  0: '0',
                  1: '0',
                  2: '0',
                  3: '0',
                  4: '1',
                  5: '17',
                  6: '21',
                  7: '24',
                  8: '31',
                  9: '33',
                  10: '34',
                  11: '34',
                  12: '34',
                  13: '34',
                },
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '7.46',
                  '4.0': '30.34',
                  '5.0': '53.39',
                  '6.0': '85.37',
                  '7.0': '127.58',
                  '8.0': '176.51',
                  '9.0': '232.78',
                  '10.0': '293.83',
                  '11.0': '353.17',
                  '12.0': '431.85',
                  '13.0': '509.94',
                  '14.0': '594.91',
                  '15.0': '695.5',
                  '16.0': '808.63',
                  '17.0': '941.66',
                  '18.0': '1076.39',
                  '19.0': '1215.61',
                  '20.0': '1360.86',
                  '21.0': '1533.16',
                  '22.0': '1711.87',
                  '23.0': '1910.85',
                  '24.0': '2092.4',
                  '25.0': '2286.87',
                  '26.0': '2471.66',
                  '27.0': '2690.39',
                  '28.0': '2922.87',
                  '29.0': '3138.94',
                  '30.0': '3337.5',
                  '31.0': '3547.49',
                  '32.0': '3744.72',
                  '33.0': '3957.79',
                  '34.0': '4165.86',
                  '35.0': '4404.52',
                  '36.0': '4630.1',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '2.14',
                  '4.0': '19.53',
                  '5.0': '39.06',
                  '6.0': '67.25',
                  '7.0': '105.42',
                  '8.0': '150.42',
                  '9.0': '202.83',
                  '10.0': '260.15',
                  '11.0': '316.31',
                  '12.0': '391.12',
                  '13.0': '465.57',
                  '14.0': '547.08',
                  '15.0': '643.73',
                  '16.0': '752.69',
                  '17.0': '881.48',
                  '18.0': '1012.04',
                  '19.0': '1147.22',
                  '20.0': '1288.41',
                  '21.0': '1456.3',
                  '22.0': '1630.77',
                  '23.0': '1825.01',
                  '24.0': '2002.66',
                  '25.0': '2193.12',
                  '26.0': '2374.13',
                  '27.0': '2588.56',
                  '28.0': '2816.88',
                  '29.0': '3028.87',
                  '30.0': '3224.24',
                  '31.0': '3430.7',
                  '32.0': '3624.39',
                  '33.0': '3834.38',
                  '34.0': '4039.28',
                  '35.0': '4274.43',
                  '36.0': '4496.45',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '12.78',
                  '4.0': '41.15',
                  '5.0': '67.72',
                  '6.0': '103.49',
                  '7.0': '149.73',
                  '8.0': '202.59',
                  '9.0': '262.72',
                  '10.0': '327.5',
                  '11.0': '390.04',
                  '12.0': '472.58',
                  '13.0': '554.3',
                  '14.0': '642.73',
                  '15.0': '747.28',
                  '16.0': '864.57',
                  '17.0': '1001.85',
                  '18.0': '1140.74',
                  '19.0': '1284.0',
                  '20.0': '1433.31',
                  '21.0': '1610.02',
                  '22.0': '1792.96',
                  '23.0': '1996.7',
                  '24.0': '2182.13',
                  '25.0': '2380.61',
                  '26.0': '2569.18',
                  '27.0': '2792.21',
                  '28.0': '3028.87',
                  '29.0': '3249.01',
                  '30.0': '3450.75',
                  '31.0': '3664.28',
                  '32.0': '3865.05',
                  '33.0': '4081.19',
                  '34.0': '4292.44',
                  '35.0': '4534.61',
                  '36.0': '4763.74',
                },
              },
              Albania: {
                actual_patients_enrolled: {
                  0: '0',
                  1: '0',
                  2: '0',
                  3: '0',
                  4: '1',
                  5: '17',
                  6: '21',
                  7: '24',
                  8: '31',
                  9: '33',
                  10: '34',
                  11: '34',
                  12: '34',
                  13: '34',
                },
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '26.18',
                  '3.0': '63.18',
                  '4.0': '116.05',
                  '5.0': '192.85',
                  '6.0': '302.71',
                  '7.0': '437.44',
                  '8.0': '604.82',
                  '9.0': '783.62',
                  '10.0': '983.31',
                  '11.0': '1208.46',
                  '12.0': '1446.79',
                  '13.0': '1693.24',
                  '14.0': '1973.74',
                  '15.0': '2304.56',
                  '16.0': '2671.13',
                  '17.0': '3057.21',
                  '18.0': '3444.95',
                  '19.0': '3868.62',
                  '20.0': '4353.47',
                  '21.0': '4834.78',
                  '22.0': '5367.34',
                  '23.0': '5928.08',
                  '24.0': '6548.4',
                  '25.0': '7184.32',
                  '26.0': '7790.64',
                  '27.0': '8386.06',
                  '28.0': '8999.5',
                  '29.0': '9622.03',
                  '30.0': '10221.1',
                  '31.0': '10812.4',
                  '32.0': '11409.91',
                  '33.0': '12014.33',
                  '34.0': '12634.41',
                  '35.0': '13262.42',
                  '36.0': '13867.14',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '16.17',
                  '3.0': '47.61',
                  '4.0': '94.92',
                  '5.0': '165.58',
                  '6.0': '268.61',
                  '7.0': '396.45',
                  '8.0': '556.56',
                  '9.0': '728.72',
                  '10.0': '921.81',
                  '11.0': '1140.28',
                  '12.0': '1372.23',
                  '13.0': '1612.55',
                  '14.0': '1886.65',
                  '15.0': '2210.43',
                  '16.0': '2569.81',
                  '17.0': '2948.76',
                  '18.0': '3329.89',
                  '19.0': '3746.7',
                  '20.0': '4224.05',
                  '21.0': '4698.44',
                  '22.0': '5223.62',
                  '23.0': '5777.16',
                  '24.0': '6389.53',
                  '25.0': '7018.16',
                  '26.0': '7617.62',
                  '27.0': '8206.47',
                  '28.0': '8813.53',
                  '29.0': '9429.65',
                  '30.0': '10022.91',
                  '31.0': '10608.57',
                  '32.0': '11200.51',
                  '33.0': '11799.44',
                  '34.0': '12414.01',
                  '35.0': '13036.62',
                  '36.0': '13636.27',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '36.19',
                  '3.0': '78.75',
                  '4.0': '137.17',
                  '5.0': '220.13',
                  '6.0': '336.82',
                  '7.0': '478.43',
                  '8.0': '653.08',
                  '9.0': '838.52',
                  '10.0': '1044.82',
                  '11.0': '1276.65',
                  '12.0': '1521.35',
                  '13.0': '1773.94',
                  '14.0': '2060.83',
                  '15.0': '2398.68',
                  '16.0': '2772.44',
                  '17.0': '3165.65',
                  '18.0': '3560.0',
                  '19.0': '3990.55',
                  '20.0': '4482.88',
                  '21.0': '4971.12',
                  '22.0': '5511.05',
                  '23.0': '6079.01',
                  '24.0': '6707.28',
                  '25.0': '7350.48',
                  '26.0': '7963.65',
                  '27.0': '8565.65',
                  '28.0': '9185.47',
                  '29.0': '9814.41',
                  '30.0': '10419.29',
                  '31.0': '11016.23',
                  '32.0': '11619.31',
                  '33.0': '12229.21',
                  '34.0': '12854.81',
                  '35.0': '13488.22',
                  '36.0': '14098.01',
                },
              },
            },
            global: {
              global: {
                actual_patients_enrolled: {
                  0: '0',
                  1: '0',
                  2: '0',
                  3: '0',
                  4: '1',
                  5: '17',
                  6: '21',
                  7: '24',
                  8: '31',
                  9: '33',
                  10: '34',
                  11: '34',
                  12: '34',
                  13: '34',
                },
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '26.18',
                  '3.0': '70.64',
                  '4.0': '146.39',
                  '5.0': '246.24',
                  '6.0': '388.08',
                  '7.0': '565.02',
                  '8.0': '781.33',
                  '9.0': '1016.39',
                  '10.0': '1277.14',
                  '11.0': '1561.64',
                  '12.0': '1878.64',
                  '13.0': '2203.18',
                  '14.0': '2568.65',
                  '15.0': '3000.06',
                  '16.0': '3479.75',
                  '17.0': '3998.87',
                  '18.0': '4521.34',
                  '19.0': '5084.23',
                  '20.0': '5714.33',
                  '21.0': '6367.95',
                  '22.0': '7079.2',
                  '23.0': '7838.94',
                  '24.0': '8640.8',
                  '25.0': '9471.19',
                  '26.0': '10262.29',
                  '27.0': '11076.45',
                  '28.0': '11922.37',
                  '29.0': '12760.97',
                  '30.0': '13558.6',
                  '31.0': '14359.89',
                  '32.0': '15154.63',
                  '33.0': '15972.11',
                  '34.0': '16800.27',
                  '35.0': '17666.94',
                  '36.0': '18497.24',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '24.89',
                  '3.0': '67.94',
                  '4.0': '142.26',
                  '5.0': '240.87',
                  '6.0': '381.33',
                  '7.0': '556.85',
                  '8.0': '771.72',
                  '9.0': '1005.43',
                  '10.0': '1264.84',
                  '11.0': '1548.06',
                  '12.0': '1863.74',
                  '13.0': '2187.02',
                  '14.0': '2551.21',
                  '15.0': '2981.2',
                  '16.0': '3459.42',
                  '17.0': '3977.07',
                  '18.0': '4498.15',
                  '19.0': '5059.63',
                  '20.0': '5688.24',
                  '21.0': '6340.39',
                  '22.0': '7050.14',
                  '23.0': '7808.33',
                  '24.0': '8608.66',
                  '25.0': '9437.59',
                  '26.0': '10227.32',
                  '27.0': '11040.07',
                  '28.0': '11884.63',
                  '29.0': '12721.87',
                  '30.0': '13518.34',
                  '31.0': '14318.45',
                  '32.0': '15112.01',
                  '33.0': '15928.38',
                  '34.0': '16755.42',
                  '35.0': '17620.93',
                  '36.0': '18450.12',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '27.48',
                  '3.0': '73.34',
                  '4.0': '150.51',
                  '5.0': '251.62',
                  '6.0': '394.83',
                  '7.0': '573.18',
                  '8.0': '790.94',
                  '9.0': '1027.36',
                  '10.0': '1289.45',
                  '11.0': '1575.21',
                  '12.0': '1893.55',
                  '13.0': '2219.35',
                  '14.0': '2586.09',
                  '15.0': '3018.92',
                  '16.0': '3500.08',
                  '17.0': '4020.67',
                  '18.0': '4544.53',
                  '19.0': '5108.83',
                  '20.0': '5740.42',
                  '21.0': '6395.5',
                  '22.0': '7108.26',
                  '23.0': '7869.54',
                  '24.0': '8672.94',
                  '25.0': '9504.79',
                  '26.0': '10297.27',
                  '27.0': '11112.82',
                  '28.0': '11960.11',
                  '29.0': '12800.06',
                  '30.0': '13598.86',
                  '31.0': '14401.34',
                  '32.0': '15197.25',
                  '33.0': '16015.84',
                  '34.0': '16845.12',
                  '35.0': '17712.94',
                  '36.0': '18544.36',
                },
              },
            },
          },
          pre_feasibility_probability_complete_time_chart: {
            country: {
              Afghanistan: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '5.81',
                  0.02: '5.85',
                  0.03: '5.92',
                  0.04: '5.96',
                  0.05: '5.98',
                  0.06: '6.01',
                  0.07: '6.02',
                  0.08: '6.03',
                  0.09: '6.05',
                  0.1: '6.06',
                  0.11: '6.07',
                  0.12: '6.08',
                  0.13: '6.09',
                  0.14: '6.1',
                  0.15: '6.11',
                  0.16: '6.12',
                  0.17: '6.13',
                  0.18: '6.14',
                  0.19: '6.15',
                  0.2: '6.15',
                  0.21: '6.16',
                  0.22: '6.17',
                  0.23: '6.18',
                  0.24: '6.18',
                  0.25: '6.19',
                  0.26: '6.2',
                  0.27: '6.21',
                  0.28: '6.21',
                  0.29: '6.22',
                  0.3: '6.22',
                  0.31: '6.23',
                  0.32: '6.24',
                  0.33: '6.24',
                  0.34: '6.25',
                  0.35: '6.26',
                  0.36: '6.26',
                  0.37: '6.27',
                  0.38: '6.27',
                  0.39: '6.28',
                  0.4: '6.28',
                  0.41: '6.29',
                  0.42: '6.3',
                  0.43: '6.31',
                  0.44: '6.31',
                  0.45: '6.32',
                  0.46: '6.32',
                  0.47: '6.33',
                  0.48: '6.33',
                  0.49: '6.34',
                  0.5: '6.34',
                  0.51: '6.35',
                  0.52: '6.36',
                  0.53: '6.36',
                  0.54: '6.37',
                  0.55: '6.38',
                  0.56: '6.39',
                  0.57: '6.39',
                  0.58: '6.4',
                  0.59: '6.4',
                  0.6: '6.41',
                  0.61: '6.41',
                  0.62: '6.42',
                  0.63: '6.43',
                  0.64: '6.44',
                  0.65: '6.44',
                  0.66: '6.45',
                  0.67: '6.45',
                  0.68: '6.46',
                  0.69: '6.47',
                  0.7: '6.48',
                  0.71: '6.48',
                  0.72: '6.49',
                  0.73: '6.5',
                  0.74: '6.51',
                  0.75: '6.51',
                  0.76: '6.52',
                  0.77: '6.53',
                  0.78: '6.54',
                  0.79: '6.54',
                  0.8: '6.56',
                  0.81: '6.56',
                  0.82: '6.58',
                  0.83: '6.58',
                  0.84: '6.6',
                  0.85: '6.6',
                  0.86: '6.62',
                  0.87: '6.63',
                  0.88: '6.65',
                  0.89: '6.65',
                  0.9: '6.68',
                  0.91: '6.69',
                  0.92: '6.71',
                  0.93: '6.72',
                  0.94: '6.76',
                  0.95: '6.77',
                  0.96: '6.8',
                  0.97: '6.84',
                  0.98: '6.92',
                  0.99: '6.97',
                  '1.0': '36.0',
                },
                target_no_of_patients: 100.0,
              },
              Albania: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '4.7',
                  0.02: '4.72',
                  0.03: '4.77',
                  0.04: '4.79',
                  0.05: '4.81',
                  0.06: '4.82',
                  0.07: '4.84',
                  0.08: '4.84',
                  0.09: '4.86',
                  0.1: '4.87',
                  0.11: '4.88',
                  0.12: '4.88',
                  0.13: '4.89',
                  0.14: '4.9',
                  0.15: '4.91',
                  0.16: '4.92',
                  0.17: '4.92',
                  0.18: '4.93',
                  0.19: '4.94',
                  0.2: '4.94',
                  0.21: '4.95',
                  0.22: '4.95',
                  0.23: '4.96',
                  0.24: '4.96',
                  0.25: '4.97',
                  0.26: '4.97',
                  0.27: '4.98',
                  0.28: '4.99',
                  0.29: '4.99',
                  0.3: '5.0',
                  0.31: '5.0',
                  0.32: '5.0',
                  0.33: '5.01',
                  0.34: '5.01',
                  0.35: '5.02',
                  0.36: '5.02',
                  0.37: '5.02',
                  0.38: '5.02',
                  0.39: '5.03',
                  0.4: '5.03',
                  0.41: '5.04',
                  0.42: '5.04',
                  0.43: '5.04',
                  0.44: '5.04',
                  0.45: '5.05',
                  0.46: '5.05',
                  0.47: '5.06',
                  0.48: '5.06',
                  0.49: '5.06',
                  0.5: '5.06',
                  0.51: '5.07',
                  0.52: '5.07',
                  0.53: '5.07',
                  0.54: '5.08',
                  0.55: '5.08',
                  0.56: '5.09',
                  0.57: '5.09',
                  0.58: '5.09',
                  0.59: '5.09',
                  0.6: '5.1',
                  0.61: '5.1',
                  0.62: '5.11',
                  0.63: '5.11',
                  0.64: '5.11',
                  0.65: '5.12',
                  0.66: '5.12',
                  0.67: '5.12',
                  0.68: '5.13',
                  0.69: '5.13',
                  0.7: '5.14',
                  0.71: '5.14',
                  0.72: '5.14',
                  0.73: '5.15',
                  0.74: '5.15',
                  0.75: '5.15',
                  0.76: '5.16',
                  0.77: '5.16',
                  0.78: '5.17',
                  0.79: '5.17',
                  0.8: '5.18',
                  0.81: '5.18',
                  0.82: '5.19',
                  0.83: '5.19',
                  0.84: '5.2',
                  0.85: '5.2',
                  0.86: '5.21',
                  0.87: '5.22',
                  0.88: '5.23',
                  0.89: '5.23',
                  0.9: '5.24',
                  0.91: '5.25',
                  0.92: '5.26',
                  0.93: '5.26',
                  0.94: '5.28',
                  0.95: '5.29',
                  0.96: '5.3',
                  0.97: '5.32',
                  0.98: '5.37',
                  0.99: '5.39',
                  '1.0': '36.0',
                },
                target_no_of_patients: 200.0,
              },
            },
            global: {
              global: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '5.05',
                  0.02: '5.07',
                  0.03: '5.09',
                  0.04: '5.12',
                  0.05: '5.12',
                  0.06: '5.14',
                  0.07: '5.16',
                  0.08: '5.16',
                  0.09: '5.17',
                  0.1: '5.18',
                  0.11: '5.19',
                  0.12: '5.2',
                  0.13: '5.2',
                  0.14: '5.21',
                  0.15: '5.22',
                  0.16: '5.22',
                  0.17: '5.23',
                  0.18: '5.24',
                  0.19: '5.24',
                  0.2: '5.25',
                  0.21: '5.25',
                  0.22: '5.26',
                  0.23: '5.26',
                  0.24: '5.27',
                  0.25: '5.27',
                  0.26: '5.28',
                  0.27: '5.28',
                  0.28: '5.29',
                  0.29: '5.29',
                  0.3: '5.3',
                  0.31: '5.3',
                  0.32: '5.3',
                  0.33: '5.31',
                  0.34: '5.31',
                  0.35: '5.32',
                  0.36: '5.32',
                  0.37: '5.33',
                  0.38: '5.33',
                  0.39: '5.33',
                  0.4: '5.34',
                  0.41: '5.34',
                  0.42: '5.35',
                  0.43: '5.35',
                  0.44: '5.35',
                  0.45: '5.36',
                  0.46: '5.36',
                  0.47: '5.37',
                  0.48: '5.37',
                  0.49: '5.38',
                  0.5: '5.38',
                  0.51: '5.38',
                  0.52: '5.39',
                  0.53: '5.39',
                  0.54: '5.4',
                  0.55: '5.4',
                  0.56: '5.41',
                  0.57: '5.41',
                  0.58: '5.41',
                  0.59: '5.42',
                  0.6: '5.42',
                  0.61: '5.43',
                  0.62: '5.43',
                  0.63: '5.43',
                  0.64: '5.44',
                  0.65: '5.44',
                  0.66: '5.45',
                  0.67: '5.45',
                  0.68: '5.46',
                  0.69: '5.46',
                  0.7: '5.47',
                  0.71: '5.47',
                  0.72: '5.48',
                  0.73: '5.48',
                  0.74: '5.49',
                  0.75: '5.49',
                  0.76: '5.5',
                  0.77: '5.5',
                  0.78: '5.51',
                  0.79: '5.52',
                  0.8: '5.52',
                  0.81: '5.53',
                  0.82: '5.54',
                  0.83: '5.55',
                  0.84: '5.55',
                  0.85: '5.56',
                  0.86: '5.57',
                  0.87: '5.57',
                  0.88: '5.58',
                  0.89: '5.59',
                  0.9: '5.6',
                  0.91: '5.61',
                  0.92: '5.62',
                  0.93: '5.64',
                  0.94: '5.66',
                  0.95: '5.68',
                  0.96: '5.7',
                  0.97: '5.73',
                  0.98: '5.77',
                  0.99: '5.8',
                  '1.0': '36.0',
                },
                target_no_of_patients: 300.0,
              },
            },
          },
          pre_feasibility_simulated_activation_curve: {
            0: '0',
            10: '10',
            20: '20',
            70: '95',
            80: '99',
            100: '100',
          },
          pre_feasibility_site_randomization_chart: {
            country: {
              Afghanistan: {
                iteration_0: {
                  '9.0': 0.03,
                  '38.0': 0.0,
                },
                iteration_1: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
                iteration_2: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
              },
              Albania: {
                iteration_0: {
                  '9.0': 0.03,
                  '38.0': 0.0,
                },
                iteration_1: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
                iteration_2: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
              },
            },
            global: {
              global: {
                iteration_0: {
                  '9.0': 0.03,
                  '38.0': 0.0,
                },
                iteration_1: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
                iteration_2: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
              },
            },
          },
          time_to_complete_enrollment: '5.37899159109729',
        },
        Compare_Scenario_02: {
          enrollment_completion_probability: '0.5',
          mean_range: {
            max_range: '1.5',
            min_range: '1.0',
          },
          pre_feasibility_distribution_target_time_chart: {
            global: {
              global: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
            },
            region: {
              Antarctica: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
              Caribbean: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
            },
          },
          pre_feasibility_predicted_patient_time_chart: {
            global: {
              global: {
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.26',
                  '7.0': '1.39',
                  '8.0': '2.51',
                  '9.0': '4.72',
                  '10.0': '7.11',
                  '11.0': '10.3',
                  '12.0': '14.28',
                  '13.0': '19.01',
                  '14.0': '23.99',
                  '15.0': '28.74',
                  '16.0': '33.61',
                  '17.0': '38.7',
                  '18.0': '43.67',
                  '19.0': '48.8',
                  '20.0': '53.65',
                  '21.0': '58.61',
                  '22.0': '63.21',
                  '23.0': '68.24',
                  '24.0': '73.24',
                  '25.0': '78.2',
                  '26.0': '82.94',
                  '27.0': '87.61',
                  '28.0': '92.51',
                  '29.0': '97.51',
                  '30.0': '102.21',
                  '31.0': '107.01',
                  '32.0': '111.81',
                  '33.0': '116.62',
                  '34.0': '121.61',
                  '35.0': '126.57',
                  '36.0': '131.5',
                  '37.0': '136.45',
                  '38.0': '141.51',
                  '39.0': '146.49',
                  '40.0': '151.48',
                  '41.0': '156.41',
                  '42.0': '161.28',
                  '43.0': '166.15',
                  '44.0': '171.02',
                  '45.0': '175.87',
                  '46.0': '180.71',
                  '47.0': '185.54',
                  '48.0': '190.42',
                  '49.0': '195.29',
                  '50.0': '200.15',
                  '51.0': '205.01',
                  '52.0': '209.86',
                  '53.0': '214.71',
                  '54.0': '219.56',
                  '55.0': '224.42',
                  '56.0': '229.27',
                  '57.0': '234.1',
                  '58.0': '238.93',
                  '59.0': '243.83',
                  '60.0': '248.71',
                  '61.0': '253.55',
                  '62.0': '258.46',
                  '63.0': '263.34',
                  '64.0': '268.18',
                  '65.0': '273.03',
                  '66.0': '277.85',
                  '67.0': '282.66',
                  '68.0': '287.48',
                  '69.0': '292.3',
                  '70.0': '297.12',
                  '71.0': '301.99',
                  '72.0': '306.92',
                  '73.0': '311.86',
                  '74.0': '316.84',
                  '75.0': '321.75',
                  '76.0': '326.61',
                  '77.0': '331.48',
                  '78.0': '336.34',
                  '79.0': '341.2',
                  '80.0': '346.07',
                  '81.0': '350.93',
                  '82.0': '355.8',
                  '83.0': '360.66',
                  '84.0': '365.53',
                  '85.0': '370.39',
                  '86.0': '375.25',
                  '87.0': '380.12',
                  '88.0': '384.98',
                  '89.0': '389.85',
                  '90.0': '394.71',
                  '91.0': '399.56',
                  '92.0': '404.39',
                  '93.0': '409.21',
                  '94.0': '414.07',
                  '95.0': '418.93',
                  '96.0': '423.79',
                  '97.0': '428.64',
                  '98.0': '433.5',
                  '99.0': '438.36',
                  '100.0': '443.22',
                  '101.0': '448.09',
                  '102.0': '452.99',
                  '103.0': '457.88',
                  '104.0': '462.78',
                  '105.0': '467.67',
                  '106.0': '472.57',
                  '107.0': '477.46',
                  '108.0': '482.36',
                  '109.0': '487.25',
                  '110.0': '492.15',
                  '111.0': '497.04',
                  '112.0': '501.94',
                  '113.0': '506.83',
                  '114.0': '511.73',
                  '115.0': '516.62',
                  '116.0': '521.52',
                  '117.0': '526.41',
                  '118.0': '531.29',
                  '119.0': '536.16',
                  '120.0': '541.02',
                  '121.0': '545.89',
                  '122.0': '550.75',
                  '123.0': '555.62',
                  '124.0': '560.48',
                  '125.0': '565.35',
                  '126.0': '570.22',
                  '127.0': '575.08',
                  '128.0': '579.95',
                  '129.0': '584.81',
                  '130.0': '589.68',
                  '131.0': '594.54',
                  '132.0': '599.43',
                  '133.0': '604.28',
                  '134.0': '609.13',
                  '135.0': '613.98',
                  '136.0': '618.84',
                  '137.0': '623.69',
                  '138.0': '628.54',
                  '139.0': '633.39',
                  '140.0': '638.24',
                  '141.0': '643.1',
                  '142.0': '647.99',
                  '143.0': '652.87',
                  '144.0': '657.75',
                  '145.0': '662.63',
                  '146.0': '667.52',
                  '147.0': '672.4',
                  '148.0': '677.28',
                  '149.0': '682.16',
                  '150.0': '687.04',
                  '151.0': '691.93',
                  '152.0': '696.81',
                  '153.0': '701.69',
                  '154.0': '706.57',
                  '155.0': '711.46',
                  '156.0': '716.34',
                  '157.0': '721.22',
                  '158.0': '726.1',
                  '159.0': '730.98',
                  '160.0': '735.83',
                  '161.0': '740.69',
                  '162.0': '745.54',
                  '163.0': '750.4',
                  '164.0': '755.26',
                  '165.0': '760.14',
                  '166.0': '765.02',
                  '167.0': '769.9',
                  '168.0': '774.77',
                  '169.0': '779.65',
                  '170.0': '784.5',
                  '171.0': '789.35',
                  '172.0': '794.19',
                  '173.0': '799.04',
                  '174.0': '803.89',
                  '175.0': '808.73',
                  '176.0': '813.58',
                  '177.0': '818.43',
                  '178.0': '823.27',
                  '179.0': '828.12',
                  '180.0': '832.97',
                  '181.0': '837.81',
                  '182.0': '842.66',
                  '183.0': '847.51',
                  '184.0': '852.35',
                  '185.0': '857.2',
                  '186.0': '862.05',
                  '187.0': '866.89',
                  '188.0': '871.74',
                  '189.0': '876.59',
                  '190.0': '881.43',
                  '191.0': '886.28',
                  '192.0': '891.13',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '-0.04',
                  '7.0': '0.61',
                  '8.0': '1.44',
                  '9.0': '2.73',
                  '10.0': '4.62',
                  '11.0': '7.26',
                  '12.0': '10.67',
                  '13.0': '14.82',
                  '14.0': '19.29',
                  '15.0': '23.62',
                  '16.0': '28.07',
                  '17.0': '32.75',
                  '18.0': '37.35',
                  '19.0': '42.13',
                  '20.0': '46.66',
                  '21.0': '51.3',
                  '22.0': '55.63',
                  '23.0': '60.36',
                  '24.0': '65.07',
                  '25.0': '69.77',
                  '26.0': '74.24',
                  '27.0': '78.67',
                  '28.0': '83.33',
                  '29.0': '88.09',
                  '30.0': '92.56',
                  '31.0': '97.13',
                  '32.0': '101.72',
                  '33.0': '106.32',
                  '34.0': '111.09',
                  '35.0': '115.84',
                  '36.0': '120.55',
                  '37.0': '125.31',
                  '38.0': '130.16',
                  '39.0': '134.94',
                  '40.0': '139.74',
                  '41.0': '144.48',
                  '42.0': '149.16',
                  '43.0': '153.85',
                  '44.0': '158.54',
                  '45.0': '163.22',
                  '46.0': '167.89',
                  '47.0': '172.54',
                  '48.0': '177.26',
                  '49.0': '181.96',
                  '50.0': '186.65',
                  '51.0': '191.35',
                  '52.0': '196.04',
                  '53.0': '200.73',
                  '54.0': '205.42',
                  '55.0': '210.11',
                  '56.0': '214.81',
                  '57.0': '219.49',
                  '58.0': '224.17',
                  '59.0': '228.92',
                  '60.0': '233.65',
                  '61.0': '238.35',
                  '62.0': '243.11',
                  '63.0': '247.85',
                  '64.0': '252.56',
                  '65.0': '257.26',
                  '66.0': '261.95',
                  '67.0': '266.62',
                  '68.0': '271.3',
                  '69.0': '275.98',
                  '70.0': '280.67',
                  '71.0': '285.4',
                  '72.0': '290.19',
                  '73.0': '295.0',
                  '74.0': '299.84',
                  '75.0': '304.62',
                  '76.0': '309.36',
                  '77.0': '314.1',
                  '78.0': '318.84',
                  '79.0': '323.57',
                  '80.0': '328.31',
                  '81.0': '333.06',
                  '82.0': '337.8',
                  '83.0': '342.54',
                  '84.0': '347.28',
                  '85.0': '352.03',
                  '86.0': '356.77',
                  '87.0': '361.51',
                  '88.0': '366.26',
                  '89.0': '371.0',
                  '90.0': '375.75',
                  '91.0': '380.48',
                  '92.0': '385.19',
                  '93.0': '389.9',
                  '94.0': '394.64',
                  '95.0': '399.39',
                  '96.0': '404.13',
                  '97.0': '408.88',
                  '98.0': '413.63',
                  '99.0': '418.37',
                  '100.0': '423.12',
                  '101.0': '427.88',
                  '102.0': '432.67',
                  '103.0': '437.45',
                  '104.0': '442.24',
                  '105.0': '447.03',
                  '106.0': '451.82',
                  '107.0': '456.61',
                  '108.0': '461.39',
                  '109.0': '466.18',
                  '110.0': '470.97',
                  '111.0': '475.77',
                  '112.0': '480.56',
                  '113.0': '485.35',
                  '114.0': '490.14',
                  '115.0': '494.93',
                  '116.0': '499.73',
                  '117.0': '504.52',
                  '118.0': '509.3',
                  '119.0': '514.06',
                  '120.0': '518.83',
                  '121.0': '523.6',
                  '122.0': '528.36',
                  '123.0': '533.13',
                  '124.0': '537.9',
                  '125.0': '542.67',
                  '126.0': '547.43',
                  '127.0': '552.2',
                  '128.0': '556.97',
                  '129.0': '561.74',
                  '130.0': '566.51',
                  '131.0': '571.28',
                  '132.0': '576.06',
                  '133.0': '580.83',
                  '134.0': '585.58',
                  '135.0': '590.34',
                  '136.0': '595.1',
                  '137.0': '599.86',
                  '138.0': '604.62',
                  '139.0': '609.38',
                  '140.0': '614.13',
                  '141.0': '618.91',
                  '142.0': '623.7',
                  '143.0': '628.49',
                  '144.0': '633.28',
                  '145.0': '638.07',
                  '146.0': '642.86',
                  '147.0': '647.66',
                  '148.0': '652.45',
                  '149.0': '657.24',
                  '150.0': '662.04',
                  '151.0': '666.83',
                  '152.0': '671.62',
                  '153.0': '676.42',
                  '154.0': '681.21',
                  '155.0': '686.0',
                  '156.0': '690.8',
                  '157.0': '695.59',
                  '158.0': '700.39',
                  '159.0': '705.18',
                  '160.0': '709.94',
                  '161.0': '714.71',
                  '162.0': '719.48',
                  '163.0': '724.25',
                  '164.0': '729.03',
                  '165.0': '733.83',
                  '166.0': '738.62',
                  '167.0': '743.41',
                  '168.0': '748.21',
                  '169.0': '753.0',
                  '170.0': '757.77',
                  '171.0': '762.53',
                  '172.0': '767.3',
                  '173.0': '772.06',
                  '174.0': '776.83',
                  '175.0': '781.59',
                  '176.0': '786.36',
                  '177.0': '791.13',
                  '178.0': '795.89',
                  '179.0': '800.66',
                  '180.0': '805.43',
                  '181.0': '810.19',
                  '182.0': '814.96',
                  '183.0': '819.73',
                  '184.0': '824.5',
                  '185.0': '829.26',
                  '186.0': '834.03',
                  '187.0': '838.8',
                  '188.0': '843.57',
                  '189.0': '848.34',
                  '190.0': '853.11',
                  '191.0': '857.88',
                  '192.0': '862.65',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.57',
                  '7.0': '2.17',
                  '8.0': '3.58',
                  '9.0': '6.71',
                  '10.0': '9.61',
                  '11.0': '13.35',
                  '12.0': '17.9',
                  '13.0': '23.2',
                  '14.0': '28.68',
                  '15.0': '33.86',
                  '16.0': '39.14',
                  '17.0': '44.65',
                  '18.0': '50.0',
                  '19.0': '55.48',
                  '20.0': '60.64',
                  '21.0': '65.91',
                  '22.0': '70.8',
                  '23.0': '76.13',
                  '24.0': '81.4',
                  '25.0': '86.64',
                  '26.0': '91.63',
                  '27.0': '96.55',
                  '28.0': '101.69',
                  '29.0': '106.93',
                  '30.0': '111.85',
                  '31.0': '116.88',
                  '32.0': '121.91',
                  '33.0': '126.93',
                  '34.0': '132.13',
                  '35.0': '137.3',
                  '36.0': '142.44',
                  '37.0': '147.6',
                  '38.0': '152.86',
                  '39.0': '158.04',
                  '40.0': '163.22',
                  '41.0': '168.34',
                  '42.0': '173.39',
                  '43.0': '178.45',
                  '44.0': '183.5',
                  '45.0': '188.52',
                  '46.0': '193.54',
                  '47.0': '198.53',
                  '48.0': '203.58',
                  '49.0': '208.62',
                  '50.0': '213.64',
                  '51.0': '218.67',
                  '52.0': '223.68',
                  '53.0': '228.7',
                  '54.0': '233.71',
                  '55.0': '238.72',
                  '56.0': '243.72',
                  '57.0': '248.71',
                  '58.0': '253.7',
                  '59.0': '258.74',
                  '60.0': '263.78',
                  '61.0': '268.76',
                  '62.0': '273.8',
                  '63.0': '278.82',
                  '64.0': '283.81',
                  '65.0': '288.79',
                  '66.0': '293.75',
                  '67.0': '298.7',
                  '68.0': '303.66',
                  '69.0': '308.61',
                  '70.0': '313.58',
                  '71.0': '318.58',
                  '72.0': '323.64',
                  '73.0': '328.72',
                  '74.0': '333.83',
                  '75.0': '338.87',
                  '76.0': '343.86',
                  '77.0': '348.85',
                  '78.0': '353.85',
                  '79.0': '358.83',
                  '80.0': '363.82',
                  '81.0': '368.81',
                  '82.0': '373.8',
                  '83.0': '378.78',
                  '84.0': '383.77',
                  '85.0': '388.75',
                  '86.0': '393.74',
                  '87.0': '398.72',
                  '88.0': '403.71',
                  '89.0': '408.69',
                  '90.0': '413.68',
                  '91.0': '418.64',
                  '92.0': '423.58',
                  '93.0': '428.53',
                  '94.0': '433.49',
                  '95.0': '438.47',
                  '96.0': '443.44',
                  '97.0': '448.41',
                  '98.0': '453.38',
                  '99.0': '458.35',
                  '100.0': '463.32',
                  '101.0': '468.3',
                  '102.0': '473.3',
                  '103.0': '478.31',
                  '104.0': '483.31',
                  '105.0': '488.31',
                  '106.0': '493.31',
                  '107.0': '498.32',
                  '108.0': '503.32',
                  '109.0': '508.32',
                  '110.0': '513.32',
                  '111.0': '518.32',
                  '112.0': '523.31',
                  '113.0': '528.31',
                  '114.0': '533.31',
                  '115.0': '538.31',
                  '116.0': '543.31',
                  '117.0': '548.3',
                  '118.0': '553.28',
                  '119.0': '558.25',
                  '120.0': '563.21',
                  '121.0': '568.18',
                  '122.0': '573.14',
                  '123.0': '578.11',
                  '124.0': '583.07',
                  '125.0': '588.03',
                  '126.0': '593.0',
                  '127.0': '597.96',
                  '128.0': '602.92',
                  '129.0': '607.89',
                  '130.0': '612.85',
                  '131.0': '617.81',
                  '132.0': '622.79',
                  '133.0': '627.74',
                  '134.0': '632.68',
                  '135.0': '637.63',
                  '136.0': '642.57',
                  '137.0': '647.51',
                  '138.0': '652.46',
                  '139.0': '657.4',
                  '140.0': '662.34',
                  '141.0': '667.3',
                  '142.0': '672.27',
                  '143.0': '677.25',
                  '144.0': '682.22',
                  '145.0': '687.19',
                  '146.0': '692.17',
                  '147.0': '697.14',
                  '148.0': '702.11',
                  '149.0': '707.08',
                  '150.0': '712.05',
                  '151.0': '717.02',
                  '152.0': '722.0',
                  '153.0': '726.97',
                  '154.0': '731.94',
                  '155.0': '736.91',
                  '156.0': '741.88',
                  '157.0': '746.85',
                  '158.0': '751.82',
                  '159.0': '756.78',
                  '160.0': '761.72',
                  '161.0': '766.66',
                  '162.0': '771.6',
                  '163.0': '776.54',
                  '164.0': '781.49',
                  '165.0': '786.45',
                  '166.0': '791.42',
                  '167.0': '796.38',
                  '168.0': '801.34',
                  '169.0': '806.3',
                  '170.0': '811.23',
                  '171.0': '816.16',
                  '172.0': '821.09',
                  '173.0': '826.02',
                  '174.0': '830.95',
                  '175.0': '835.87',
                  '176.0': '840.8',
                  '177.0': '845.73',
                  '178.0': '850.65',
                  '179.0': '855.58',
                  '180.0': '860.51',
                  '181.0': '865.43',
                  '182.0': '870.36',
                  '183.0': '875.29',
                  '184.0': '880.21',
                  '185.0': '885.14',
                  '186.0': '890.06',
                  '187.0': '894.99',
                  '188.0': '899.91',
                  '189.0': '904.83',
                  '190.0': '909.76',
                  '191.0': '914.68',
                  '192.0': '919.61',
                },
              },
            },
            region: {
              Antarctica: {
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.26',
                  '7.0': '1.39',
                  '8.0': '2.51',
                  '9.0': '3.69',
                  '10.0': '4.89',
                  '11.0': '6.44',
                  '12.0': '7.85',
                  '13.0': '10.07',
                  '14.0': '12.5',
                  '15.0': '14.9',
                  '16.0': '17.32',
                  '17.0': '19.82',
                  '18.0': '22.14',
                  '19.0': '24.56',
                  '20.0': '26.98',
                  '21.0': '29.28',
                  '22.0': '31.57',
                  '23.0': '33.9',
                  '24.0': '36.26',
                  '25.0': '38.55',
                  '26.0': '40.73',
                  '27.0': '43.06',
                  '28.0': '45.54',
                  '29.0': '48.11',
                  '30.0': '50.35',
                  '31.0': '52.68',
                  '32.0': '54.95',
                  '33.0': '57.28',
                  '34.0': '59.81',
                  '35.0': '62.36',
                  '36.0': '64.74',
                  '37.0': '67.13',
                  '38.0': '69.63',
                  '39.0': '72.13',
                  '40.0': '74.63',
                  '41.0': '77.0',
                  '42.0': '79.33',
                  '43.0': '81.66',
                  '44.0': '84.0',
                  '45.0': '86.35',
                  '46.0': '88.71',
                  '47.0': '91.02',
                  '48.0': '93.37',
                  '49.0': '95.7',
                  '50.0': '98.07',
                  '51.0': '100.48',
                  '52.0': '102.9',
                  '53.0': '105.31',
                  '54.0': '107.72',
                  '55.0': '110.14',
                  '56.0': '112.55',
                  '57.0': '114.97',
                  '58.0': '117.38',
                  '59.0': '119.86',
                  '60.0': '122.33',
                  '61.0': '124.74',
                  '62.0': '127.15',
                  '63.0': '129.56',
                  '64.0': '131.97',
                  '65.0': '134.38',
                  '66.0': '136.79',
                  '67.0': '139.19',
                  '68.0': '141.6',
                  '69.0': '144.01',
                  '70.0': '146.42',
                  '71.0': '148.83',
                  '72.0': '151.29',
                  '73.0': '153.77',
                  '74.0': '156.24',
                  '75.0': '158.67',
                  '76.0': '161.07',
                  '77.0': '163.47',
                  '78.0': '165.87',
                  '79.0': '168.28',
                  '80.0': '170.68',
                  '81.0': '173.08',
                  '82.0': '175.48',
                  '83.0': '177.88',
                  '84.0': '180.28',
                  '85.0': '182.68',
                  '86.0': '185.08',
                  '87.0': '187.48',
                  '88.0': '189.88',
                  '89.0': '192.28',
                  '90.0': '194.68',
                  '91.0': '197.06',
                  '92.0': '199.43',
                  '93.0': '201.79',
                  '94.0': '204.16',
                  '95.0': '206.52',
                  '96.0': '208.89',
                  '97.0': '211.25',
                  '98.0': '213.62',
                  '99.0': '215.98',
                  '100.0': '218.35',
                  '101.0': '220.72',
                  '102.0': '223.13',
                  '103.0': '225.53',
                  '104.0': '227.93',
                  '105.0': '230.33',
                  '106.0': '232.73',
                  '107.0': '235.14',
                  '108.0': '237.54',
                  '109.0': '239.94',
                  '110.0': '242.34',
                  '111.0': '244.74',
                  '112.0': '247.15',
                  '113.0': '249.55',
                  '114.0': '251.95',
                  '115.0': '254.35',
                  '116.0': '256.75',
                  '117.0': '259.16',
                  '118.0': '261.56',
                  '119.0': '263.96',
                  '120.0': '266.36',
                  '121.0': '268.76',
                  '122.0': '271.17',
                  '123.0': '273.57',
                  '124.0': '275.97',
                  '125.0': '278.37',
                  '126.0': '280.77',
                  '127.0': '283.17',
                  '128.0': '285.58',
                  '129.0': '287.98',
                  '130.0': '290.38',
                  '131.0': '292.78',
                  '132.0': '295.17',
                  '133.0': '297.55',
                  '134.0': '299.93',
                  '135.0': '302.32',
                  '136.0': '304.7',
                  '137.0': '307.08',
                  '138.0': '309.46',
                  '139.0': '311.85',
                  '140.0': '314.23',
                  '141.0': '316.63',
                  '142.0': '319.04',
                  '143.0': '321.45',
                  '144.0': '323.87',
                  '145.0': '326.28',
                  '146.0': '328.69',
                  '147.0': '331.11',
                  '148.0': '333.52',
                  '149.0': '335.93',
                  '150.0': '338.35',
                  '151.0': '340.76',
                  '152.0': '343.18',
                  '153.0': '345.59',
                  '154.0': '348.0',
                  '155.0': '350.42',
                  '156.0': '352.83',
                  '157.0': '355.24',
                  '158.0': '357.66',
                  '159.0': '360.07',
                  '160.0': '362.49',
                  '161.0': '364.9',
                  '162.0': '367.31',
                  '163.0': '369.73',
                  '164.0': '372.14',
                  '165.0': '374.55',
                  '166.0': '376.97',
                  '167.0': '379.38',
                  '168.0': '381.8',
                  '169.0': '384.21',
                  '170.0': '386.59',
                  '171.0': '388.97',
                  '172.0': '391.36',
                  '173.0': '393.74',
                  '174.0': '396.12',
                  '175.0': '398.5',
                  '176.0': '400.89',
                  '177.0': '403.27',
                  '178.0': '405.65',
                  '179.0': '408.03',
                  '180.0': '410.41',
                  '181.0': '412.8',
                  '182.0': '415.18',
                  '183.0': '417.56',
                  '184.0': '419.94',
                  '185.0': '422.33',
                  '186.0': '424.71',
                  '187.0': '427.09',
                  '188.0': '429.47',
                  '189.0': '431.85',
                  '190.0': '434.24',
                  '191.0': '436.62',
                  '192.0': '439.0',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.0',
                  '7.0': '0.0',
                  '8.0': '0.0',
                  '9.0': '0.0',
                  '10.0': '0.57',
                  '11.0': '1.47',
                  '12.0': '2.32',
                  '13.0': '3.79',
                  '14.0': '5.55',
                  '15.0': '7.32',
                  '16.0': '9.15',
                  '17.0': '11.04',
                  '18.0': '12.88',
                  '19.0': '14.83',
                  '20.0': '16.8',
                  '21.0': '18.67',
                  '22.0': '20.55',
                  '23.0': '22.48',
                  '24.0': '24.45',
                  '25.0': '26.38',
                  '26.0': '28.22',
                  '27.0': '30.19',
                  '28.0': '32.3',
                  '29.0': '34.51',
                  '30.0': '36.44',
                  '31.0': '38.44',
                  '32.0': '40.41',
                  '33.0': '42.45',
                  '34.0': '44.66',
                  '35.0': '46.88',
                  '36.0': '48.96',
                  '37.0': '51.08',
                  '38.0': '53.27',
                  '39.0': '55.48',
                  '40.0': '57.7',
                  '41.0': '59.8',
                  '42.0': '61.88',
                  '43.0': '63.95',
                  '44.0': '66.03',
                  '45.0': '68.14',
                  '46.0': '70.25',
                  '47.0': '72.32',
                  '48.0': '74.43',
                  '49.0': '76.52',
                  '50.0': '78.66',
                  '51.0': '80.83',
                  '52.0': '83.0',
                  '53.0': '85.18',
                  '54.0': '87.35',
                  '55.0': '89.54',
                  '56.0': '91.72',
                  '57.0': '93.9',
                  '58.0': '96.09',
                  '59.0': '98.35',
                  '60.0': '100.61',
                  '61.0': '102.81',
                  '62.0': '105.02',
                  '63.0': '107.23',
                  '64.0': '109.44',
                  '65.0': '111.65',
                  '66.0': '113.86',
                  '67.0': '116.06',
                  '68.0': '118.26',
                  '69.0': '120.47',
                  '70.0': '122.67',
                  '71.0': '124.88',
                  '72.0': '127.14',
                  '73.0': '129.42',
                  '74.0': '131.7',
                  '75.0': '133.94',
                  '76.0': '136.16',
                  '77.0': '138.38',
                  '78.0': '140.6',
                  '79.0': '142.82',
                  '80.0': '145.05',
                  '81.0': '147.27',
                  '82.0': '149.5',
                  '83.0': '151.73',
                  '84.0': '153.95',
                  '85.0': '156.18',
                  '86.0': '158.41',
                  '87.0': '160.64',
                  '88.0': '162.86',
                  '89.0': '165.09',
                  '90.0': '167.32',
                  '91.0': '169.53',
                  '92.0': '171.73',
                  '93.0': '173.93',
                  '94.0': '176.13',
                  '95.0': '178.33',
                  '96.0': '180.54',
                  '97.0': '182.74',
                  '98.0': '184.95',
                  '99.0': '187.15',
                  '100.0': '189.36',
                  '101.0': '191.58',
                  '102.0': '193.83',
                  '103.0': '196.07',
                  '104.0': '198.32',
                  '105.0': '200.57',
                  '106.0': '202.82',
                  '107.0': '205.07',
                  '108.0': '207.32',
                  '109.0': '209.57',
                  '110.0': '211.82',
                  '111.0': '214.07',
                  '112.0': '216.33',
                  '113.0': '218.58',
                  '114.0': '220.84',
                  '115.0': '223.09',
                  '116.0': '225.35',
                  '117.0': '227.6',
                  '118.0': '229.86',
                  '119.0': '232.11',
                  '120.0': '234.37',
                  '121.0': '236.63',
                  '122.0': '238.88',
                  '123.0': '241.14',
                  '124.0': '243.4',
                  '125.0': '245.66',
                  '126.0': '247.92',
                  '127.0': '250.18',
                  '128.0': '252.44',
                  '129.0': '254.7',
                  '130.0': '256.96',
                  '131.0': '259.23',
                  '132.0': '261.48',
                  '133.0': '263.73',
                  '134.0': '265.97',
                  '135.0': '268.22',
                  '136.0': '270.47',
                  '137.0': '272.72',
                  '138.0': '274.97',
                  '139.0': '277.22',
                  '140.0': '279.47',
                  '141.0': '281.73',
                  '142.0': '284.01',
                  '143.0': '286.3',
                  '144.0': '288.58',
                  '145.0': '290.86',
                  '146.0': '293.15',
                  '147.0': '295.43',
                  '148.0': '297.72',
                  '149.0': '300.01',
                  '150.0': '302.29',
                  '151.0': '304.58',
                  '152.0': '306.87',
                  '153.0': '309.15',
                  '154.0': '311.44',
                  '155.0': '313.72',
                  '156.0': '316.01',
                  '157.0': '318.3',
                  '158.0': '320.58',
                  '159.0': '322.87',
                  '160.0': '325.16',
                  '161.0': '327.45',
                  '162.0': '329.74',
                  '163.0': '332.03',
                  '164.0': '334.31',
                  '165.0': '336.6',
                  '166.0': '338.9',
                  '167.0': '341.19',
                  '168.0': '343.48',
                  '169.0': '345.77',
                  '170.0': '348.03',
                  '171.0': '350.3',
                  '172.0': '352.56',
                  '173.0': '354.83',
                  '174.0': '357.09',
                  '175.0': '359.36',
                  '176.0': '361.62',
                  '177.0': '363.89',
                  '178.0': '366.15',
                  '179.0': '368.42',
                  '180.0': '370.69',
                  '181.0': '372.95',
                  '182.0': '375.22',
                  '183.0': '377.49',
                  '184.0': '379.76',
                  '185.0': '382.03',
                  '186.0': '384.3',
                  '187.0': '386.57',
                  '188.0': '388.83',
                  '189.0': '391.1',
                  '190.0': '393.37',
                  '191.0': '395.65',
                  '192.0': '397.92',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '1.15',
                  '7.0': '3.66',
                  '8.0': '5.62',
                  '9.0': '7.44',
                  '10.0': '9.2',
                  '11.0': '11.41',
                  '12.0': '13.38',
                  '13.0': '16.35',
                  '14.0': '19.45',
                  '15.0': '22.47',
                  '16.0': '25.49',
                  '17.0': '28.59',
                  '18.0': '31.41',
                  '19.0': '34.3',
                  '20.0': '37.16',
                  '21.0': '39.89',
                  '22.0': '42.59',
                  '23.0': '45.32',
                  '24.0': '48.06',
                  '25.0': '50.72',
                  '26.0': '53.24',
                  '27.0': '55.94',
                  '28.0': '58.77',
                  '29.0': '61.7',
                  '30.0': '64.26',
                  '31.0': '66.91',
                  '32.0': '69.5',
                  '33.0': '72.12',
                  '34.0': '74.97',
                  '35.0': '77.85',
                  '36.0': '80.52',
                  '37.0': '83.19',
                  '38.0': '85.98',
                  '39.0': '88.78',
                  '40.0': '91.56',
                  '41.0': '94.2',
                  '42.0': '96.79',
                  '43.0': '99.38',
                  '44.0': '101.96',
                  '45.0': '104.57',
                  '46.0': '107.17',
                  '47.0': '109.72',
                  '48.0': '112.3',
                  '49.0': '114.87',
                  '50.0': '117.48',
                  '51.0': '120.14',
                  '52.0': '122.79',
                  '53.0': '125.44',
                  '54.0': '128.09',
                  '55.0': '130.74',
                  '56.0': '133.39',
                  '57.0': '136.03',
                  '58.0': '138.67',
                  '59.0': '141.37',
                  '60.0': '144.06',
                  '61.0': '146.67',
                  '62.0': '149.28',
                  '63.0': '151.89',
                  '64.0': '154.5',
                  '65.0': '157.1',
                  '66.0': '159.71',
                  '67.0': '162.33',
                  '68.0': '164.94',
                  '69.0': '167.56',
                  '70.0': '170.17',
                  '71.0': '172.78',
                  '72.0': '175.44',
                  '73.0': '178.12',
                  '74.0': '180.79',
                  '75.0': '183.4',
                  '76.0': '185.99',
                  '77.0': '188.57',
                  '78.0': '191.15',
                  '79.0': '193.73',
                  '80.0': '196.3',
                  '81.0': '198.88',
                  '82.0': '201.45',
                  '83.0': '204.03',
                  '84.0': '206.6',
                  '85.0': '209.17',
                  '86.0': '211.74',
                  '87.0': '214.32',
                  '88.0': '216.89',
                  '89.0': '219.47',
                  '90.0': '222.04',
                  '91.0': '224.59',
                  '92.0': '227.12',
                  '93.0': '229.65',
                  '94.0': '232.18',
                  '95.0': '234.71',
                  '96.0': '237.23',
                  '97.0': '239.76',
                  '98.0': '242.28',
                  '99.0': '244.81',
                  '100.0': '247.33',
                  '101.0': '249.87',
                  '102.0': '252.43',
                  '103.0': '254.98',
                  '104.0': '257.54',
                  '105.0': '260.1',
                  '106.0': '262.65',
                  '107.0': '265.21',
                  '108.0': '267.76',
                  '109.0': '270.31',
                  '110.0': '272.86',
                  '111.0': '275.41',
                  '112.0': '277.97',
                  '113.0': '280.52',
                  '114.0': '283.06',
                  '115.0': '285.61',
                  '116.0': '288.16',
                  '117.0': '290.71',
                  '118.0': '293.26',
                  '119.0': '295.81',
                  '120.0': '298.35',
                  '121.0': '300.9',
                  '122.0': '303.45',
                  '123.0': '305.99',
                  '124.0': '308.54',
                  '125.0': '311.08',
                  '126.0': '313.63',
                  '127.0': '316.17',
                  '128.0': '318.71',
                  '129.0': '321.25',
                  '130.0': '323.8',
                  '131.0': '326.34',
                  '132.0': '328.86',
                  '133.0': '331.38',
                  '134.0': '333.9',
                  '135.0': '336.41',
                  '136.0': '338.93',
                  '137.0': '341.45',
                  '138.0': '343.96',
                  '139.0': '346.48',
                  '140.0': '348.99',
                  '141.0': '351.52',
                  '142.0': '354.06',
                  '143.0': '356.61',
                  '144.0': '359.15',
                  '145.0': '361.7',
                  '146.0': '364.24',
                  '147.0': '366.78',
                  '148.0': '369.32',
                  '149.0': '371.86',
                  '150.0': '374.41',
                  '151.0': '376.95',
                  '152.0': '379.49',
                  '153.0': '382.03',
                  '154.0': '384.57',
                  '155.0': '387.11',
                  '156.0': '389.65',
                  '157.0': '392.19',
                  '158.0': '394.73',
                  '159.0': '397.27',
                  '160.0': '399.81',
                  '161.0': '402.35',
                  '162.0': '404.89',
                  '163.0': '407.43',
                  '164.0': '409.97',
                  '165.0': '412.5',
                  '166.0': '415.04',
                  '167.0': '417.58',
                  '168.0': '420.11',
                  '169.0': '422.65',
                  '170.0': '425.15',
                  '171.0': '427.65',
                  '172.0': '430.15',
                  '173.0': '432.65',
                  '174.0': '435.15',
                  '175.0': '437.65',
                  '176.0': '440.15',
                  '177.0': '442.65',
                  '178.0': '445.15',
                  '179.0': '447.64',
                  '180.0': '450.14',
                  '181.0': '452.64',
                  '182.0': '455.14',
                  '183.0': '457.63',
                  '184.0': '460.13',
                  '185.0': '462.62',
                  '186.0': '465.12',
                  '187.0': '467.61',
                  '188.0': '470.11',
                  '189.0': '472.6',
                  '190.0': '475.1',
                  '191.0': '477.59',
                  '192.0': '480.08',
                },
              },
              Caribbean: {
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.0',
                  '7.0': '0.0',
                  '8.0': '0.0',
                  '9.0': '1.04',
                  '10.0': '2.23',
                  '11.0': '3.87',
                  '12.0': '6.43',
                  '13.0': '8.94',
                  '14.0': '11.49',
                  '15.0': '13.84',
                  '16.0': '16.28',
                  '17.0': '18.88',
                  '18.0': '21.53',
                  '19.0': '24.24',
                  '20.0': '26.67',
                  '21.0': '29.33',
                  '22.0': '31.64',
                  '23.0': '34.34',
                  '24.0': '36.98',
                  '25.0': '39.66',
                  '26.0': '42.21',
                  '27.0': '44.55',
                  '28.0': '46.98',
                  '29.0': '49.41',
                  '30.0': '51.86',
                  '31.0': '54.33',
                  '32.0': '56.86',
                  '33.0': '59.34',
                  '34.0': '61.8',
                  '35.0': '64.21',
                  '36.0': '66.76',
                  '37.0': '69.32',
                  '38.0': '71.88',
                  '39.0': '74.36',
                  '40.0': '76.85',
                  '41.0': '79.4',
                  '42.0': '81.94',
                  '43.0': '84.48',
                  '44.0': '87.02',
                  '45.0': '89.51',
                  '46.0': '92.0',
                  '47.0': '94.52',
                  '48.0': '97.05',
                  '49.0': '99.59',
                  '50.0': '102.08',
                  '51.0': '104.53',
                  '52.0': '106.97',
                  '53.0': '109.4',
                  '54.0': '111.84',
                  '55.0': '114.28',
                  '56.0': '116.72',
                  '57.0': '119.14',
                  '58.0': '121.55',
                  '59.0': '123.97',
                  '60.0': '126.38',
                  '61.0': '128.81',
                  '62.0': '131.31',
                  '63.0': '133.78',
                  '64.0': '136.21',
                  '65.0': '138.65',
                  '66.0': '141.06',
                  '67.0': '143.47',
                  '68.0': '145.88',
                  '69.0': '148.28',
                  '70.0': '150.7',
                  '71.0': '153.16',
                  '72.0': '155.62',
                  '73.0': '158.09',
                  '74.0': '160.59',
                  '75.0': '163.07',
                  '76.0': '165.54',
                  '77.0': '168.0',
                  '78.0': '170.47',
                  '79.0': '172.93',
                  '80.0': '175.39',
                  '81.0': '177.86',
                  '82.0': '180.32',
                  '83.0': '182.79',
                  '84.0': '185.25',
                  '85.0': '187.71',
                  '86.0': '190.18',
                  '87.0': '192.64',
                  '88.0': '195.1',
                  '89.0': '197.57',
                  '90.0': '200.03',
                  '91.0': '202.5',
                  '92.0': '204.96',
                  '93.0': '207.42',
                  '94.0': '209.91',
                  '95.0': '212.41',
                  '96.0': '214.9',
                  '97.0': '217.39',
                  '98.0': '219.89',
                  '99.0': '222.38',
                  '100.0': '224.87',
                  '101.0': '227.37',
                  '102.0': '229.86',
                  '103.0': '232.35',
                  '104.0': '234.84',
                  '105.0': '237.34',
                  '106.0': '239.83',
                  '107.0': '242.32',
                  '108.0': '244.82',
                  '109.0': '247.31',
                  '110.0': '249.8',
                  '111.0': '252.3',
                  '112.0': '254.79',
                  '113.0': '257.28',
                  '114.0': '259.78',
                  '115.0': '262.27',
                  '116.0': '264.76',
                  '117.0': '267.26',
                  '118.0': '269.73',
                  '119.0': '272.2',
                  '120.0': '274.66',
                  '121.0': '277.12',
                  '122.0': '279.59',
                  '123.0': '282.05',
                  '124.0': '284.52',
                  '125.0': '286.98',
                  '126.0': '289.44',
                  '127.0': '291.91',
                  '128.0': '294.37',
                  '129.0': '296.83',
                  '130.0': '299.3',
                  '131.0': '301.76',
                  '132.0': '304.25',
                  '133.0': '306.73',
                  '134.0': '309.2',
                  '135.0': '311.67',
                  '136.0': '314.14',
                  '137.0': '316.6',
                  '138.0': '319.07',
                  '139.0': '321.54',
                  '140.0': '324.01',
                  '141.0': '326.48',
                  '142.0': '328.95',
                  '143.0': '331.42',
                  '144.0': '333.88',
                  '145.0': '336.35',
                  '146.0': '338.82',
                  '147.0': '341.29',
                  '148.0': '343.76',
                  '149.0': '346.23',
                  '150.0': '348.7',
                  '151.0': '351.16',
                  '152.0': '353.63',
                  '153.0': '356.1',
                  '154.0': '358.57',
                  '155.0': '361.04',
                  '156.0': '363.51',
                  '157.0': '365.98',
                  '158.0': '368.44',
                  '159.0': '370.91',
                  '160.0': '373.35',
                  '161.0': '375.79',
                  '162.0': '378.23',
                  '163.0': '380.67',
                  '164.0': '383.12',
                  '165.0': '385.59',
                  '166.0': '388.05',
                  '167.0': '390.51',
                  '168.0': '392.98',
                  '169.0': '395.44',
                  '170.0': '397.91',
                  '171.0': '400.37',
                  '172.0': '402.84',
                  '173.0': '405.3',
                  '174.0': '407.77',
                  '175.0': '410.23',
                  '176.0': '412.69',
                  '177.0': '415.16',
                  '178.0': '417.62',
                  '179.0': '420.09',
                  '180.0': '422.55',
                  '181.0': '425.02',
                  '182.0': '427.48',
                  '183.0': '429.95',
                  '184.0': '432.41',
                  '185.0': '434.87',
                  '186.0': '437.34',
                  '187.0': '439.8',
                  '188.0': '442.27',
                  '189.0': '444.73',
                  '190.0': '447.2',
                  '191.0': '449.66',
                  '192.0': '452.13',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.0',
                  '7.0': '0.0',
                  '8.0': '0.0',
                  '9.0': '0.0',
                  '10.0': '0.0',
                  '11.0': '0.0',
                  '12.0': '1.46',
                  '13.0': '3.04',
                  '14.0': '4.79',
                  '15.0': '6.54',
                  '16.0': '8.37',
                  '17.0': '10.36',
                  '18.0': '12.43',
                  '19.0': '14.59',
                  '20.0': '16.55',
                  '21.0': '18.72',
                  '22.0': '20.61',
                  '23.0': '22.86',
                  '24.0': '25.05',
                  '25.0': '27.31',
                  '26.0': '29.46',
                  '27.0': '31.45',
                  '28.0': '33.54',
                  '29.0': '35.63',
                  '30.0': '37.74',
                  '31.0': '39.87',
                  '32.0': '42.07',
                  '33.0': '44.24',
                  '34.0': '46.39',
                  '35.0': '48.5',
                  '36.0': '50.74',
                  '37.0': '52.99',
                  '38.0': '55.25',
                  '39.0': '57.46',
                  '40.0': '59.67',
                  '41.0': '61.94',
                  '42.0': '64.2',
                  '43.0': '66.47',
                  '44.0': '68.74',
                  '45.0': '70.97',
                  '46.0': '73.2',
                  '47.0': '75.45',
                  '48.0': '77.74',
                  '49.0': '80.03',
                  '50.0': '82.28',
                  '51.0': '84.49',
                  '52.0': '86.69',
                  '53.0': '88.9',
                  '54.0': '91.11',
                  '55.0': '93.32',
                  '56.0': '95.54',
                  '57.0': '97.74',
                  '58.0': '99.94',
                  '59.0': '102.14',
                  '60.0': '104.34',
                  '61.0': '106.56',
                  '62.0': '108.85',
                  '63.0': '111.11',
                  '64.0': '113.34',
                  '65.0': '115.57',
                  '66.0': '117.78',
                  '67.0': '119.99',
                  '68.0': '122.2',
                  '69.0': '124.41',
                  '70.0': '126.63',
                  '71.0': '128.9',
                  '72.0': '131.17',
                  '73.0': '133.45',
                  '74.0': '135.75',
                  '75.0': '138.04',
                  '76.0': '140.32',
                  '77.0': '142.6',
                  '78.0': '144.87',
                  '79.0': '147.15',
                  '80.0': '149.43',
                  '81.0': '151.71',
                  '82.0': '153.99',
                  '83.0': '156.27',
                  '84.0': '158.56',
                  '85.0': '160.84',
                  '86.0': '163.13',
                  '87.0': '165.42',
                  '88.0': '167.71',
                  '89.0': '170.0',
                  '90.0': '172.29',
                  '91.0': '174.58',
                  '92.0': '176.87',
                  '93.0': '179.17',
                  '94.0': '181.49',
                  '95.0': '183.81',
                  '96.0': '186.14',
                  '97.0': '188.47',
                  '98.0': '190.8',
                  '99.0': '193.13',
                  '100.0': '195.46',
                  '101.0': '197.79',
                  '102.0': '200.12',
                  '103.0': '202.45',
                  '104.0': '204.79',
                  '105.0': '207.12',
                  '106.0': '209.46',
                  '107.0': '211.79',
                  '108.0': '214.13',
                  '109.0': '216.47',
                  '110.0': '218.81',
                  '111.0': '221.15',
                  '112.0': '223.49',
                  '113.0': '225.83',
                  '114.0': '228.17',
                  '115.0': '230.51',
                  '116.0': '232.85',
                  '117.0': '235.2',
                  '118.0': '237.53',
                  '119.0': '239.85',
                  '120.0': '242.17',
                  '121.0': '244.49',
                  '122.0': '246.81',
                  '123.0': '249.13',
                  '124.0': '251.45',
                  '125.0': '253.78',
                  '126.0': '256.1',
                  '127.0': '258.42',
                  '128.0': '260.74',
                  '129.0': '263.06',
                  '130.0': '265.38',
                  '131.0': '267.7',
                  '132.0': '270.06',
                  '133.0': '272.4',
                  '134.0': '274.73',
                  '135.0': '277.06',
                  '136.0': '279.39',
                  '137.0': '281.73',
                  '138.0': '284.06',
                  '139.0': '286.4',
                  '140.0': '288.73',
                  '141.0': '291.06',
                  '142.0': '293.39',
                  '143.0': '295.73',
                  '144.0': '298.06',
                  '145.0': '300.4',
                  '146.0': '302.73',
                  '147.0': '305.07',
                  '148.0': '307.41',
                  '149.0': '309.74',
                  '150.0': '312.08',
                  '151.0': '314.42',
                  '152.0': '316.76',
                  '153.0': '319.09',
                  '154.0': '321.43',
                  '155.0': '323.77',
                  '156.0': '326.11',
                  '157.0': '328.45',
                  '158.0': '330.79',
                  '159.0': '333.13',
                  '160.0': '335.45',
                  '161.0': '337.76',
                  '162.0': '340.08',
                  '163.0': '342.4',
                  '164.0': '344.73',
                  '165.0': '347.07',
                  '166.0': '349.41',
                  '167.0': '351.76',
                  '168.0': '354.1',
                  '169.0': '356.44',
                  '170.0': '358.79',
                  '171.0': '361.13',
                  '172.0': '363.48',
                  '173.0': '365.82',
                  '174.0': '368.17',
                  '175.0': '370.51',
                  '176.0': '372.86',
                  '177.0': '375.21',
                  '178.0': '377.55',
                  '179.0': '379.9',
                  '180.0': '382.25',
                  '181.0': '384.6',
                  '182.0': '386.95',
                  '183.0': '389.3',
                  '184.0': '391.64',
                  '185.0': '393.99',
                  '186.0': '396.34',
                  '187.0': '398.69',
                  '188.0': '401.04',
                  '189.0': '403.4',
                  '190.0': '405.75',
                  '191.0': '408.1',
                  '192.0': '410.45',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.0',
                  '7.0': '0.0',
                  '8.0': '0.0',
                  '9.0': '3.06',
                  '10.0': '5.16',
                  '11.0': '7.73',
                  '12.0': '11.41',
                  '13.0': '14.83',
                  '14.0': '18.19',
                  '15.0': '21.15',
                  '16.0': '24.19',
                  '17.0': '27.4',
                  '18.0': '30.63',
                  '19.0': '33.89',
                  '20.0': '36.8',
                  '21.0': '39.95',
                  '22.0': '42.67',
                  '23.0': '45.83',
                  '24.0': '48.91',
                  '25.0': '52.0',
                  '26.0': '54.95',
                  '27.0': '57.64',
                  '28.0': '60.42',
                  '29.0': '63.18',
                  '30.0': '65.97',
                  '31.0': '68.79',
                  '32.0': '71.65',
                  '33.0': '74.44',
                  '34.0': '77.21',
                  '35.0': '79.91',
                  '36.0': '82.78',
                  '37.0': '85.64',
                  '38.0': '88.5',
                  '39.0': '91.26',
                  '40.0': '94.04',
                  '41.0': '96.87',
                  '42.0': '99.69',
                  '43.0': '102.5',
                  '44.0': '105.31',
                  '45.0': '108.06',
                  '46.0': '110.81',
                  '47.0': '113.58',
                  '48.0': '116.37',
                  '49.0': '119.16',
                  '50.0': '121.88',
                  '51.0': '124.57',
                  '52.0': '127.24',
                  '53.0': '129.9',
                  '54.0': '132.57',
                  '55.0': '135.23',
                  '56.0': '137.89',
                  '57.0': '140.53',
                  '58.0': '143.16',
                  '59.0': '145.79',
                  '60.0': '148.42',
                  '61.0': '151.06',
                  '62.0': '153.77',
                  '63.0': '156.45',
                  '64.0': '159.09',
                  '65.0': '161.73',
                  '66.0': '164.34',
                  '67.0': '166.95',
                  '68.0': '169.55',
                  '69.0': '172.16',
                  '70.0': '174.77',
                  '71.0': '177.42',
                  '72.0': '180.08',
                  '73.0': '182.73',
                  '74.0': '185.43',
                  '75.0': '188.1',
                  '76.0': '190.76',
                  '77.0': '193.41',
                  '78.0': '196.06',
                  '79.0': '198.71',
                  '80.0': '201.36',
                  '81.0': '204.01',
                  '82.0': '206.65',
                  '83.0': '209.3',
                  '84.0': '211.94',
                  '85.0': '214.58',
                  '86.0': '217.22',
                  '87.0': '219.86',
                  '88.0': '222.5',
                  '89.0': '225.14',
                  '90.0': '227.78',
                  '91.0': '230.41',
                  '92.0': '233.05',
                  '93.0': '235.68',
                  '94.0': '238.34',
                  '95.0': '241.0',
                  '96.0': '243.66',
                  '97.0': '246.32',
                  '98.0': '248.98',
                  '99.0': '251.63',
                  '100.0': '254.29',
                  '101.0': '256.94',
                  '102.0': '259.6',
                  '103.0': '262.25',
                  '104.0': '264.9',
                  '105.0': '267.56',
                  '106.0': '270.21',
                  '107.0': '272.86',
                  '108.0': '275.51',
                  '109.0': '278.15',
                  '110.0': '280.8',
                  '111.0': '283.45',
                  '112.0': '286.09',
                  '113.0': '288.74',
                  '114.0': '291.38',
                  '115.0': '294.03',
                  '116.0': '296.67',
                  '117.0': '299.31',
                  '118.0': '301.94',
                  '119.0': '304.55',
                  '120.0': '307.15',
                  '121.0': '309.76',
                  '122.0': '312.37',
                  '123.0': '314.97',
                  '124.0': '317.58',
                  '125.0': '320.18',
                  '126.0': '322.79',
                  '127.0': '325.4',
                  '128.0': '328.0',
                  '129.0': '330.61',
                  '130.0': '333.21',
                  '131.0': '335.82',
                  '132.0': '338.45',
                  '133.0': '341.07',
                  '134.0': '343.67',
                  '135.0': '346.28',
                  '136.0': '348.88',
                  '137.0': '351.48',
                  '138.0': '354.08',
                  '139.0': '356.69',
                  '140.0': '359.29',
                  '141.0': '361.9',
                  '142.0': '364.5',
                  '143.0': '367.1',
                  '144.0': '369.71',
                  '145.0': '372.31',
                  '146.0': '374.91',
                  '147.0': '377.51',
                  '148.0': '380.11',
                  '149.0': '382.71',
                  '150.0': '385.31',
                  '151.0': '387.91',
                  '152.0': '390.51',
                  '153.0': '393.11',
                  '154.0': '395.71',
                  '155.0': '398.3',
                  '156.0': '400.9',
                  '157.0': '403.5',
                  '158.0': '406.09',
                  '159.0': '408.68',
                  '160.0': '411.25',
                  '161.0': '413.81',
                  '162.0': '416.38',
                  '163.0': '418.94',
                  '164.0': '421.51',
                  '165.0': '424.1',
                  '166.0': '426.69',
                  '167.0': '429.27',
                  '168.0': '431.86',
                  '169.0': '434.44',
                  '170.0': '437.03',
                  '171.0': '439.61',
                  '172.0': '442.2',
                  '173.0': '444.78',
                  '174.0': '447.36',
                  '175.0': '449.95',
                  '176.0': '452.53',
                  '177.0': '455.11',
                  '178.0': '457.69',
                  '179.0': '460.27',
                  '180.0': '462.85',
                  '181.0': '465.44',
                  '182.0': '468.02',
                  '183.0': '470.6',
                  '184.0': '473.18',
                  '185.0': '475.75',
                  '186.0': '478.33',
                  '187.0': '480.91',
                  '188.0': '483.49',
                  '189.0': '486.07',
                  '190.0': '488.65',
                  '191.0': '491.22',
                  '192.0': '493.8',
                },
              },
            },
          },
          pre_feasibility_probability_complete_time_chart: {
            global: {
              global: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '45.64',
                  0.02: '46.04',
                  0.03: '46.67',
                  0.04: '47.34',
                  0.05: '47.52',
                  0.06: '47.85',
                  0.07: '48.28',
                  0.08: '48.41',
                  0.09: '48.66',
                  0.1: '48.99',
                  0.11: '49.09',
                  0.12: '49.38',
                  0.13: '49.48',
                  0.14: '49.74',
                  0.15: '49.91',
                  0.16: '49.99',
                  0.17: '50.23',
                  0.18: '50.38',
                  0.19: '50.45',
                  0.2: '50.67',
                  0.21: '50.8',
                  0.22: '50.87',
                  0.23: '51.07',
                  0.24: '51.2',
                  0.25: '51.27',
                  0.26: '51.39',
                  0.27: '51.52',
                  0.28: '51.7',
                  0.29: '51.82',
                  0.3: '51.94',
                  0.31: '52.0',
                  0.32: '52.11',
                  0.33: '52.23',
                  0.34: '52.4',
                  0.35: '52.51',
                  0.36: '52.62',
                  0.37: '52.68',
                  0.38: '52.79',
                  0.39: '52.9',
                  0.4: '53.01',
                  0.41: '53.17',
                  0.42: '53.28',
                  0.43: '53.39',
                  0.44: '53.44',
                  0.45: '53.55',
                  0.46: '53.66',
                  0.47: '53.82',
                  0.48: '53.93',
                  0.49: '54.04',
                  0.5: '54.09',
                  0.51: '54.2',
                  0.52: '54.31',
                  0.53: '54.42',
                  0.54: '54.53',
                  0.55: '54.64',
                  0.56: '54.8',
                  0.57: '54.92',
                  0.58: '55.03',
                  0.59: '55.14',
                  0.6: '55.26',
                  0.61: '55.37',
                  0.62: '55.49',
                  0.63: '55.55',
                  0.64: '55.67',
                  0.65: '55.79',
                  0.66: '55.91',
                  0.67: '56.03',
                  0.68: '56.16',
                  0.69: '56.35',
                  0.7: '56.48',
                  0.71: '56.62',
                  0.72: '56.75',
                  0.73: '56.89',
                  0.74: '57.04',
                  0.75: '57.11',
                  0.76: '57.26',
                  0.77: '57.41',
                  0.78: '57.56',
                  0.79: '57.72',
                  0.8: '57.89',
                  0.81: '58.15',
                  0.82: '58.32',
                  0.83: '58.51',
                  0.84: '58.7',
                  0.85: '58.91',
                  0.86: '59.12',
                  0.87: '59.23',
                  0.88: '59.46',
                  0.89: '59.71',
                  0.9: '59.98',
                  0.91: '60.27',
                  0.92: '60.59',
                  0.93: '60.94',
                  0.94: '61.54',
                  0.95: '62.03',
                  0.96: '62.61',
                  0.97: '63.38',
                  0.98: '64.48',
                  0.99: '65.31',
                  '1.0': '192.0',
                },
                target_no_of_patients: 220.0,
              },
            },
            region: {
              Antarctica: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '13.77',
                  0.02: '13.94',
                  0.03: '14.28',
                  0.04: '14.44',
                  0.05: '14.57',
                  0.06: '14.69',
                  0.07: '14.79',
                  0.08: '14.84',
                  0.09: '14.97',
                  0.1: '15.05',
                  0.11: '15.13',
                  0.12: '15.17',
                  0.13: '15.27',
                  0.14: '15.3',
                  0.15: '15.37',
                  0.16: '15.46',
                  0.17: '15.52',
                  0.18: '15.55',
                  0.19: '15.63',
                  0.2: '15.66',
                  0.21: '15.74',
                  0.22: '15.8',
                  0.23: '15.85',
                  0.24: '15.87',
                  0.25: '15.95',
                  0.26: '15.97',
                  0.27: '16.05',
                  0.28: '16.07',
                  0.29: '16.12',
                  0.3: '16.16',
                  0.31: '16.23',
                  0.32: '16.25',
                  0.33: '16.32',
                  0.34: '16.34',
                  0.35: '16.41',
                  0.36: '16.43',
                  0.37: '16.5',
                  0.38: '16.52',
                  0.39: '16.59',
                  0.4: '16.61',
                  0.41: '16.67',
                  0.42: '16.7',
                  0.43: '16.76',
                  0.44: '16.78',
                  0.45: '16.85',
                  0.46: '16.87',
                  0.47: '16.94',
                  0.48: '16.96',
                  0.49: '17.03',
                  0.5: '17.05',
                  0.51: '17.13',
                  0.52: '17.2',
                  0.53: '17.22',
                  0.54: '17.27',
                  0.55: '17.32',
                  0.56: '17.4',
                  0.57: '17.45',
                  0.58: '17.5',
                  0.59: '17.53',
                  0.6: '17.61',
                  0.61: '17.64',
                  0.62: '17.72',
                  0.63: '17.74',
                  0.64: '17.83',
                  0.65: '17.86',
                  0.66: '17.94',
                  0.67: '17.97',
                  0.68: '18.06',
                  0.69: '18.09',
                  0.7: '18.18',
                  0.71: '18.21',
                  0.72: '18.3',
                  0.73: '18.33',
                  0.74: '18.43',
                  0.75: '18.46',
                  0.76: '18.57',
                  0.77: '18.6',
                  0.78: '18.71',
                  0.79: '18.75',
                  0.8: '18.87',
                  0.81: '18.91',
                  0.82: '19.04',
                  0.83: '19.08',
                  0.84: '19.22',
                  0.85: '19.27',
                  0.86: '19.42',
                  0.87: '19.48',
                  0.88: '19.65',
                  0.89: '19.72',
                  0.9: '19.92',
                  0.91: '19.99',
                  0.92: '20.25',
                  0.93: '20.35',
                  0.94: '20.68',
                  0.95: '20.81',
                  0.96: '21.1',
                  0.97: '21.48',
                  0.98: '22.33',
                  0.99: '22.81',
                  '1.0': '192.0',
                },
                target_no_of_patients: 20.0,
              },
              Caribbean: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '77.69',
                  0.02: '78.45',
                  0.03: '79.88',
                  0.04: '80.54',
                  0.05: '81.08',
                  0.06: '81.54',
                  0.07: '81.94',
                  0.08: '82.13',
                  0.09: '82.64',
                  0.1: '82.95',
                  0.11: '83.24',
                  0.12: '83.38',
                  0.13: '83.77',
                  0.14: '83.89',
                  0.15: '84.13',
                  0.16: '84.47',
                  0.17: '84.69',
                  0.18: '84.79',
                  0.19: '85.1',
                  0.2: '85.2',
                  0.21: '85.49',
                  0.22: '85.67',
                  0.23: '85.85',
                  0.24: '85.94',
                  0.25: '86.2',
                  0.26: '86.29',
                  0.27: '86.54',
                  0.28: '86.63',
                  0.29: '86.79',
                  0.3: '86.95',
                  0.31: '87.19',
                  0.32: '87.27',
                  0.33: '87.5',
                  0.34: '87.58',
                  0.35: '87.8',
                  0.36: '87.88',
                  0.37: '88.1',
                  0.38: '88.18',
                  0.39: '88.4',
                  0.4: '88.47',
                  0.41: '88.69',
                  0.42: '88.76',
                  0.43: '88.98',
                  0.44: '89.05',
                  0.45: '89.27',
                  0.46: '89.34',
                  0.47: '89.56',
                  0.48: '89.63',
                  0.49: '89.84',
                  0.5: '89.91',
                  0.51: '90.13',
                  0.52: '90.35',
                  0.53: '90.42',
                  0.54: '90.57',
                  0.55: '90.71',
                  0.56: '90.93',
                  0.57: '91.08',
                  0.58: '91.23',
                  0.59: '91.3',
                  0.6: '91.53',
                  0.61: '91.61',
                  0.62: '91.84',
                  0.63: '91.92',
                  0.64: '92.15',
                  0.65: '92.23',
                  0.66: '92.47',
                  0.67: '92.55',
                  0.68: '92.8',
                  0.69: '92.89',
                  0.7: '93.14',
                  0.71: '93.23',
                  0.72: '93.49',
                  0.73: '93.58',
                  0.74: '93.85',
                  0.75: '93.95',
                  0.76: '94.23',
                  0.77: '94.33',
                  0.78: '94.63',
                  0.79: '94.73',
                  0.8: '95.06',
                  0.81: '95.17',
                  0.82: '95.51',
                  0.83: '95.63',
                  0.84: '96.0',
                  0.85: '96.13',
                  0.86: '96.54',
                  0.87: '96.68',
                  0.88: '97.13',
                  0.89: '97.3',
                  0.9: '97.82',
                  0.91: '98.01',
                  0.92: '98.62',
                  0.93: '98.85',
                  0.94: '99.62',
                  0.95: '99.92',
                  0.96: '100.6',
                  0.97: '101.44',
                  0.98: '103.33',
                  0.99: '104.36',
                  '1.0': '192.0',
                },
                target_no_of_patients: 200.0,
              },
            },
          },
          pre_feasibility_simulated_activation_curve: {
            0: '0',
            10: '10',
            20: '20',
            30: '30',
            40: '50',
            50: '60',
            60: '70',
            70: '75',
            80: '80',
            100: '100',
          },
          pre_feasibility_site_randomization_chart: {
            country: {
              Afghanistan: {
                iteration_0: {
                  '9.0': 0.03,
                  '38.0': 0.0,
                },
                iteration_1: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
                iteration_2: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
              },
              Albania: {
                iteration_0: {
                  '9.0': 0.03,
                  '38.0': 0.0,
                },
                iteration_1: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
                iteration_2: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
              },
            },
            global: {
              global: {
                iteration_0: {
                  '9.0': 0.03,
                  '38.0': 0.0,
                },
                iteration_1: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
                iteration_2: {
                  '1.0': 0.0,
                  '2.0': 10.0,
                  '12.0': 20.0,
                  '20.0': 30.0,
                  '35.0': 20.0,
                  '52.0': 30.0,
                  '90.0': 60.0,
                },
              },
            },
          },
          time_to_complete_enrollment: '54.089826134479',
        },
      },
      status: 'SUCCEEDED',
    };

    const chartsDataForDownload = {
      result: {
        'ctemp-historical-site-activation': {
          result: {
            Argentina: {
              fifth_quintile: '30.43',
              first_quintile: '8.7',
              fourth_quintile: '39.13',
              second_quintile: '6.52',
              third_quintile: '15.22',
            },
            Belgium: {
              fifth_quintile: '50.0',
              first_quintile: '0.0',
              fourth_quintile: '16.67',
              second_quintile: '0.0',
              third_quintile: '33.33',
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-historical-site-activation-curve': {
          result: {
            historical: {
              '0.0': '0.0',
              '10.0': '2.44',
              '20.0': '9.77',
              '30.0': '12.22',
              '40.0': '23.12',
              '50.0': '25.56',
              '60.0': '39.47',
              '70.0': '52.63',
              '80.0': '71.24',
              '90.0': '80.83',
              '100.0': '100.0',
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-historical-summary-graph-map-view': {
          result: {
            site_count_country: {
              actual: {
                max: {
                  median_randamization_rate: null,
                  no_of_sites: null,
                },
                median_randamization_rate: [],
                no_of_sites: [],
                range: {
                  median_randamization_rate: null,
                  no_of_sites: null,
                },
              },
              historical: {
                max: {
                  median_randamization_rate: '5.515',
                  no_of_sites: '73',
                },
                median_randamization_rate: [{
                    code: 'AR',
                    country: 'Argentina',
                    median_randamization_rate: 1.342,
                  },
                  {
                    code: 'BE',
                    country: 'Belgium',
                    median_randamization_rate: 0.486,
                  },
                  {
                    code: 'BR',
                    country: 'Brazil',
                    median_randamization_rate: 1.977,
                  },
                  {
                    code: 'CA',
                    country: 'Canada',
                    median_randamization_rate: 0.791,
                  },
                  {
                    code: 'CL',
                    country: 'Chile',
                    median_randamization_rate: 5.515,
                  },
                  {
                    code: 'CN',
                    country: 'China',
                    median_randamization_rate: 0.983,
                  },
                  {
                    code: 'CZ',
                    country: 'Czech Republic',
                    median_randamization_rate: 1.139,
                  },
                  {
                    code: 'FI',
                    country: 'Finland',
                    median_randamization_rate: 0.144,
                  },
                  {
                    code: 'FR',
                    country: 'France',
                    median_randamization_rate: 0.971,
                  },
                  {
                    code: 'DE',
                    country: 'Germany',
                    median_randamization_rate: 0.779,
                  },
                  {
                    code: 'HU',
                    country: 'Hungary',
                    median_randamization_rate: 0.859,
                  },
                  {
                    code: 'IN',
                    country: 'India',
                    median_randamization_rate: 1.679,
                  },
                  {
                    code: 'IL',
                    country: 'Israel',
                    median_randamization_rate: 0.983,
                  },
                  {
                    code: 'IT',
                    country: 'Italy',
                    median_randamization_rate: 0.53,
                  },
                  {
                    code: 'JP',
                    country: 'Japan',
                    median_randamization_rate: 0.562,
                  },
                  {
                    code: 'LT',
                    country: 'Lithuania',
                    median_randamization_rate: 1.148,
                  },
                  {
                    code: 'PL',
                    country: 'Poland',
                    median_randamization_rate: 2.035,
                  },
                  {
                    code: 'RO',
                    country: 'Romania',
                    median_randamization_rate: 0.852,
                  },
                  {
                    code: 'RU',
                    country: 'Russia',
                    median_randamization_rate: 1.171,
                  },
                  {
                    code: 'ZA',
                    country: 'South Africa',
                    median_randamization_rate: 1.422,
                  },
                  {
                    code: 'ES',
                    country: 'Spain',
                    median_randamization_rate: 0.828,
                  },
                  {
                    code: 'UA',
                    country: 'Ukraine',
                    median_randamization_rate: 1.048,
                  },
                  {
                    code: 'GB',
                    country: 'United Kingdom',
                    median_randamization_rate: 0.745,
                  },
                  {
                    code: 'US',
                    country: 'United States',
                    median_randamization_rate: 1.164,
                  },
                ],
                no_of_sites: [{
                    code: 'AR',
                    country: 'Argentina',
                    no_of_sites: 15,
                  },
                  {
                    code: 'BE',
                    country: 'Belgium',
                    no_of_sites: 6,
                  },
                  {
                    code: 'BR',
                    country: 'Brazil',
                    no_of_sites: 3,
                  },
                  {
                    code: 'CA',
                    country: 'Canada',
                    no_of_sites: 8,
                  },
                  {
                    code: 'CL',
                    country: 'Chile',
                    no_of_sites: 1,
                  },
                  {
                    code: 'CN',
                    country: 'China',
                    no_of_sites: 7,
                  },
                  {
                    code: 'CZ',
                    country: 'Czech Republic',
                    no_of_sites: 9,
                  },
                  {
                    code: 'FI',
                    country: 'Finland',
                    no_of_sites: 1,
                  },
                  {
                    code: 'FR',
                    country: 'France',
                    no_of_sites: 3,
                  },
                  {
                    code: 'DE',
                    country: 'Germany',
                    no_of_sites: 9,
                  },
                  {
                    code: 'HU',
                    country: 'Hungary',
                    no_of_sites: 22,
                  },
                  {
                    code: 'IN',
                    country: 'India',
                    no_of_sites: 11,
                  },
                  {
                    code: 'IL',
                    country: 'Israel',
                    no_of_sites: 10,
                  },
                  {
                    code: 'IT',
                    country: 'Italy',
                    no_of_sites: 4,
                  },
                  {
                    code: 'JP',
                    country: 'Japan',
                    no_of_sites: 4,
                  },
                  {
                    code: 'LT',
                    country: 'Lithuania',
                    no_of_sites: 1,
                  },
                  {
                    code: 'PL',
                    country: 'Poland',
                    no_of_sites: 11,
                  },
                  {
                    code: 'RO',
                    country: 'Romania',
                    no_of_sites: 9,
                  },
                  {
                    code: 'RU',
                    country: 'Russia',
                    no_of_sites: 60,
                  },
                  {
                    code: 'ZA',
                    country: 'South Africa',
                    no_of_sites: 9,
                  },
                  {
                    code: 'ES',
                    country: 'Spain',
                    no_of_sites: 9,
                  },
                  {
                    code: 'UA',
                    country: 'Ukraine',
                    no_of_sites: 7,
                  },
                  {
                    code: 'GB',
                    country: 'United Kingdom',
                    no_of_sites: 1,
                  },
                  {
                    code: 'US',
                    country: 'United States',
                    no_of_sites: 73,
                  },
                ],
                range: {
                  median_randamization_rate: [{
                      from: 0.144,
                      to: 0.745,
                    },
                    {
                      from: 0.746,
                      to: 0.859,
                    },
                    {
                      from: 0.86,
                      to: 1.139,
                    },
                    {
                      from: 1.14,
                      to: 1.422,
                    },
                    {
                      from: 1.423,
                    },
                  ],
                  no_of_sites: [{
                      from: 0,
                      to: 4,
                    },
                    {
                      from: 5,
                      to: 9,
                    },
                    {
                      from: 10,
                      to: 14,
                    },
                    {
                      from: 15,
                      to: 19,
                    },
                    {
                      from: 20,
                    },
                  ],
                },
              },
            },
            site_count_tier: {
              actual: {
                max: {
                  median_randamization_rate: null,
                  no_of_sites: null,
                },
                median_randamization_rate: [],
                no_of_sites: [],
              },
              historical: {
                max: {
                  median_randamization_rate: '1.208',
                  no_of_sites: '75',
                },
                median_randamization_rate: [{
                    median_randamization_rate: 1.208,
                    tier: '1',
                  },
                  {
                    median_randamization_rate: 1.112,
                    tier: '2',
                  },
                  {
                    median_randamization_rate: 1.186,
                    tier: '3',
                  },
                  {
                    median_randamization_rate: 1.101,
                    tier: '4',
                  },
                ],
                no_of_sites: [{
                    no_of_sites: 75,
                    tier: '1',
                  },
                  {
                    no_of_sites: 73,
                    tier: '2',
                  },
                  {
                    no_of_sites: 72,
                    tier: '3',
                  },
                  {
                    no_of_sites: 73,
                    tier: '4',
                  },
                ],
              },
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-predicted-patient-enrollment': {
          result: {
            global: {
              global: {
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.76',
                  '5.0': '2.59',
                  '6.0': '4.42',
                  '7.0': '6.25',
                  '8.0': '11.12',
                  '9.0': '16.65',
                  '10.0': '23.97',
                  '11.0': '31.68',
                  '12.0': '40.84',
                  '13.0': '52.93',
                  '14.0': '69.41',
                  '15.0': '91.61',
                  '16.0': '117.25',
                  '17.0': '145.87',
                  '18.0': '175.23',
                  '19.0': '213.68',
                  '20.0': '252.13',
                  '21.0': '290.59',
                  '22.0': '329.04',
                  '23.0': '367.49',
                  '24.0': '405.94',
                  '25.0': '444.4',
                  '26.0': '482.85',
                  '27.0': '521.3',
                  '28.0': '559.75',
                  '29.0': '598.21',
                  '30.0': '636.66',
                  '31.0': '675.11',
                  '32.0': '713.57',
                  '33.0': '752.02',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '0.0',
                  '5.0': '0.0',
                  '6.0': '0.0',
                  '7.0': '0.0',
                  '8.0': '0.0',
                  '9.0': '0.0',
                  '10.0': '1.19',
                  '11.0': '3.96',
                  '12.0': '7.8',
                  '13.0': '14.15',
                  '14.0': '24.1',
                  '15.0': '38.98',
                  '16.0': '56.44',
                  '17.0': '76.24',
                  '18.0': '96.29',
                  '19.0': '124.69',
                  '20.0': '152.59',
                  '21.0': '180.14',
                  '22.0': '207.42',
                  '23.0': '234.5',
                  '24.0': '261.44',
                  '25.0': '288.26',
                  '26.0': '314.98',
                  '27.0': '341.63',
                  '28.0': '368.23',
                  '29.0': '394.77',
                  '30.0': '421.27',
                  '31.0': '447.73',
                  '32.0': '474.17',
                  '33.0': '500.58',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '0.0',
                  '4.0': '2.86',
                  '5.0': '7.62',
                  '6.0': '12.2',
                  '7.0': '16.75',
                  '8.0': '25.15',
                  '9.0': '34.78',
                  '10.0': '46.76',
                  '11.0': '59.41',
                  '12.0': '73.88',
                  '13.0': '91.71',
                  '14.0': '114.72',
                  '15.0': '144.24',
                  '16.0': '178.06',
                  '17.0': '215.5',
                  '18.0': '254.17',
                  '19.0': '302.67',
                  '20.0': '351.67',
                  '21.0': '401.04',
                  '22.0': '450.66',
                  '23.0': '500.48',
                  '24.0': '550.45',
                  '25.0': '600.54',
                  '26.0': '650.72',
                  '27.0': '700.97',
                  '28.0': '751.28',
                  '29.0': '801.65',
                  '30.0': '852.05',
                  '31.0': '902.49',
                  '32.0': '952.96',
                  '33.0': '1003.46',
                },
              },
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-probablity-complete-target-enroll': {
          result: {
            global: {
              global: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '13.13',
                  0.02: '13.36',
                  0.03: '13.51',
                  0.04: '13.64',
                  0.05: '13.74',
                  0.06: '13.83',
                  0.07: '13.91',
                  0.08: '13.98',
                  0.09: '14.04',
                  0.1: '14.09',
                  0.11: '14.13',
                  0.12: '14.18',
                  0.13: '14.22',
                  0.14: '14.26',
                  0.15: '14.3',
                  0.16: '14.34',
                  0.17: '14.37',
                  0.18: '14.41',
                  0.19: '14.44',
                  0.2: '14.48',
                  0.21: '14.51',
                  0.22: '14.54',
                  0.23: '14.58',
                  0.24: '14.61',
                  0.25: '14.64',
                  0.26: '14.67',
                  0.27: '14.7',
                  0.28: '14.73',
                  0.29: '14.76',
                  0.3: '14.79',
                  0.31: '14.82',
                  0.32: '14.85',
                  0.33: '14.88',
                  0.34: '14.91',
                  0.35: '14.94',
                  0.36: '14.97',
                  0.37: '14.99',
                  0.38: '15.02',
                  0.39: '15.05',
                  0.4: '15.07',
                  0.41: '15.1',
                  0.42: '15.12',
                  0.43: '15.15',
                  0.44: '15.17',
                  0.45: '15.2',
                  0.46: '15.22',
                  0.47: '15.25',
                  0.48: '15.27',
                  0.49: '15.3',
                  0.5: '15.33',
                  0.51: '15.35',
                  0.52: '15.38',
                  0.53: '15.41',
                  0.54: '15.43',
                  0.55: '15.46',
                  0.56: '15.49',
                  0.57: '15.52',
                  0.58: '15.55',
                  0.59: '15.58',
                  0.6: '15.6',
                  0.61: '15.63',
                  0.62: '15.66',
                  0.63: '15.69',
                  0.64: '15.73',
                  0.65: '15.76',
                  0.66: '15.79',
                  0.67: '15.82',
                  0.68: '15.86',
                  0.69: '15.89',
                  0.7: '15.93',
                  0.71: '15.96',
                  0.72: '16.0',
                  0.73: '16.03',
                  0.74: '16.07',
                  0.75: '16.11',
                  0.76: '16.14',
                  0.77: '16.18',
                  0.78: '16.22',
                  0.79: '16.26',
                  0.8: '16.31',
                  0.81: '16.35',
                  0.82: '16.4',
                  0.83: '16.45',
                  0.84: '16.5',
                  0.85: '16.56',
                  0.86: '16.61',
                  0.87: '16.68',
                  0.88: '16.74',
                  0.89: '16.82',
                  0.9: '16.9',
                  0.91: '16.98',
                  0.92: '17.08',
                  0.93: '17.18',
                  0.94: '17.31',
                  0.95: '17.45',
                  0.96: '17.63',
                  0.97: '17.86',
                  0.98: '18.13',
                  0.99: '18.52',
                  '1.0': '33.0',
                },
                target_no_of_patients: '100',
              },
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-simulated-activation-curve': {
          result: {
            actual: {},
            simulated: {
              '0.0': '0.0',
              '20.0': '4.76',
              '40.0': '14.29',
              '50.0': '19.05',
              '60.0': '23.81',
              '80.0': '66.67',
              '90.0': '76.19',
              '100.0': '100.0',
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-simulated-site-activation': {
          result: {
            Argentina: {
              fifth_quintile: '26.67',
              first_quintile: '0.0',
              fourth_quintile: '46.67',
              second_quintile: '13.33',
              third_quintile: '13.33',
            },
            Belgium: {
              fifth_quintile: '50.0',
              first_quintile: '16.67',
              fourth_quintile: '33.33',
              second_quintile: '0.0',
              third_quintile: '0.0',
            },
          },
          status: 'SUCCEEDED',
        },
      },
      status: 'SUCCEEDED',
    };

    const chartsDataForDownloadPre = {
      result: {
        'ctemp-pre-feasibility-distribution-target-time-chart': {
          result: {
            global: {
              // global: {},
            },
            country: {
              ABC: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
              Americas: {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
            },
            region: {
              'australia and new zealand': {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
              'north america': {
                0: {
                  '[11.0,12.0)': 1.0,
                },
                1: {
                  '[12.0,13.0)': 0.0,
                },
                2: {
                  '[13.0,14.0)': 1.0,
                },
                3: {
                  '[14.0,14.0)': 2.0,
                },
                4: {
                  '[14.0,15.0)': 0.0,
                },
                5: {
                  '[15.0,16.0)': 0.0,
                },
                6: {
                  '[16.0,17.0)': 7.0,
                },
                7: {
                  '[17.0,18.0)': 2.0,
                },
                8: {
                  '[18.0,18.0)': 6.0,
                },
                9: {
                  '[18.0,19.0)': 4.0,
                },
              },
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-pre-feasibility-historical-activation-curve': {
          result: {
            0: '0',
            20: '40',
            30: '60',
            60: '90',
            90: '95',
            100: '100',
          },
          status: 'SUCCEEDED',
        },
        'ctemp-pre-feasibility-predicted-patient-time-chart': {
          result: {
            global: {
              global: {
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '3.67',
                  '3.0': '20.08',
                  '4.0': '39.25',
                  '5.0': '62.34',
                  '6.0': '91.68',
                  '7.0': '125.1',
                  '8.0': '168.09',
                  '9.0': '213.2',
                  '10.0': '265.46',
                  '11.0': '318.86',
                  '12.0': '376.59',
                  '13.0': '436.99',
                  '14.0': '501.4',
                  '15.0': '568.85',
                  '16.0': '638.12',
                  '17.0': '711.35',
                  '18.0': '785.52',
                  '19.0': '865.12',
                  '20.0': '944.71',
                  '21.0': '1024.31',
                  '22.0': '1103.91',
                  '23.0': '1183.51',
                  '24.0': '1263.11',
                  '25.0': '1342.71',
                  '26.0': '1422.31',
                  '27.0': '1501.91',
                  '28.0': '1581.5',
                  '29.0': '1661.1',
                  '30.0': '1740.7',
                  '31.0': '1820.3',
                  '32.0': '1899.9',
                  '33.0': '1979.5',
                  '34.0': '2059.1',
                  '35.0': '2138.69',
                  '36.0': '2218.29',
                  '37.0': '2297.89',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '0.0',
                  '3.0': '9.08',
                  '4.0': '22.32',
                  '5.0': '39.25',
                  '6.0': '62.02',
                  '7.0': '88.5',
                  '8.0': '123.93',
                  '9.0': '161.23',
                  '10.0': '205.24',
                  '11.0': '250.24',
                  '12.0': '299.29',
                  '13.0': '350.83',
                  '14.0': '406.13',
                  '15.0': '464.29',
                  '16.0': '524.1',
                  '17.0': '587.69',
                  '18.0': '652.07',
                  '19.0': '721.69',
                  '20.0': '791.19',
                  '21.0': '860.6',
                  '22.0': '929.94',
                  '23.0': '999.21',
                  '24.0': '1068.43',
                  '25.0': '1137.6',
                  '26.0': '1206.73',
                  '27.0': '1275.83',
                  '28.0': '1344.9',
                  '29.0': '1413.95',
                  '30.0': '1482.97',
                  '31.0': '1551.97',
                  '32.0': '1620.96',
                  '33.0': '1689.93',
                  '34.0': '1758.89',
                  '35.0': '1827.83',
                  '36.0': '1896.77',
                  '37.0': '1965.69',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '0.0',
                  '2.0': '7.75',
                  '3.0': '31.07',
                  '4.0': '56.18',
                  '5.0': '85.44',
                  '6.0': '121.35',
                  '7.0': '161.71',
                  '8.0': '212.25',
                  '9.0': '265.17',
                  '10.0': '325.67',
                  '11.0': '387.48',
                  '12.0': '453.89',
                  '13.0': '523.16',
                  '14.0': '596.67',
                  '15.0': '673.41',
                  '16.0': '752.14',
                  '17.0': '835.02',
                  '18.0': '918.96',
                  '19.0': '1008.54',
                  '20.0': '1098.24',
                  '21.0': '1188.02',
                  '22.0': '1277.89',
                  '23.0': '1367.81',
                  '24.0': '1457.79',
                  '25.0': '1547.82',
                  '26.0': '1637.88',
                  '27.0': '1727.98',
                  '28.0': '1818.11',
                  '29.0': '1908.26',
                  '30.0': '1998.43',
                  '31.0': '2088.63',
                  '32.0': '2178.84',
                  '33.0': '2269.06',
                  '34.0': '2359.3',
                  '35.0': '2449.55',
                  '36.0': '2539.82',
                  '37.0': '2630.09',
                },
              },
            },
            region: {
              Antarctica: {
                enrollments: {
                  '0.0': '0.0',
                  '1.0': '24.76711923096843',
                  '2.0': '158.63673977003813',
                  '3.0': '385.53607505381484',
                  '4.0': '670.9550118811932',
                  '5.0': '958.7546720170495',
                  '6.0': '1246.554332152906',
                  '7.0': '1534.353992288762',
                  '8.0': '1822.153652424619',
                  '9.0': '2109.055415811755',
                  '10.0': '2395.89233336094',
                  '11.0': '2682.729250910124',
                  '12.0': '2969.566168459309',
                  '13.0': '3256.4030860084936',
                  '14.0': '3543.2400035576784',
                  '15.0': '3830.0769211068628',
                },
                lower_confidence: {
                  '0.0': '0.0',
                  '1.0': '14.985311137067676',
                  '2.0': '133.94067864630475',
                  '3.0': '347.038168446016',
                  '4.0': '620.1275767101777',
                  '5.0': '898.0638558061678',
                  '6.0': '1177.316682778998',
                  '7.0': '1457.5096940579351',
                  '8.0': '1738.3906519390084',
                  '9.0': '2018.9413999022115',
                  '10.0': '2299.848940714146',
                  '11.0': '2581.101839849588',
                  '12.0': '2862.645973516408',
                  '13.0': '3144.4400318594185',
                  '14.0': '3426.4516355489186',
                  '15.0': '3708.6548460549593',
                },
                upper_confidence: {
                  '0.0': '0.0',
                  '1.0': '34.54892732486918',
                  '2.0': '183.3328008937716',
                  '3.0': '424.0339816616138',
                  '4.0': '721.7824470522086',
                  '5.0': '1019.4454882279317',
                  '6.0': '1315.791981526814',
                  '7.0': '1611.1982905195891',
                  '8.0': '1905.9166529102288',
                  '9.0': '2199.1694317212987',
                  '10.0': '2491.935726007734',
                  '11.0': '2784.3566619706603',
                  '12.0': '3076.486363402209',
                  '13.0': '3368.366140157569',
                  '14.0': '3660.028371566438',
                  '15.0': '3951.4989961587676',
                },
              },
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-pre-feasibility-probability-complete-time-chart': {
          result: {
            global: {
              global: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '0.27',
                  0.02: '0.28',
                  0.03: '0.29',
                  0.04: '0.3',
                  0.05: '0.3',
                  0.06: '0.31',
                  0.07: '0.31',
                  0.08: '0.31',
                  0.09: '0.32',
                  '1.0': '15.0',
                },
                target_no_of_patients: '20',
              },
            },
            region: {
              Antarctica: {
                Probability: {
                  '0.0': '0.0',
                  0.01: '0.27',
                  0.02: '0.28',
                  0.03: '0.29',
                  0.04: '0.3',
                  0.05: '0.3',
                  0.06: '0.31',
                  0.07: '0.31',
                  0.08: '0.31',
                  0.09: '0.32',
                  0.1: '0.32',
                  0.11: '0.32',
                  0.12: '0.33',
                  0.13: '0.33',
                  0.14: '0.33',
                  0.15: '0.33',
                  0.16: '0.34',
                  0.17: '0.34',
                  0.18: '0.34',
                  0.19: '0.34',
                  0.2: '0.34',
                  0.21: '0.35',
                  0.22: '0.35',
                  0.23: '0.35',
                  0.24: '0.35',
                  0.25: '0.36',
                  0.26: '0.36',
                  0.27: '0.36',
                  0.28: '0.36',
                  0.29: '0.36',
                  0.3: '0.36',
                  0.31: '0.37',
                  0.32: '0.37',
                  0.33: '0.37',
                  0.34: '0.37',
                  0.35: '0.37',
                  0.36: '0.38',
                  0.37: '0.38',
                  0.38: '0.38',
                  0.39: '0.38',
                  0.4: '0.38',
                  0.41: '0.39',
                  0.42: '0.39',
                  0.43: '0.39',
                  0.44: '0.39',
                  0.45: '0.39',
                  0.46: '0.39',
                  0.47: '0.4',
                  0.48: '0.4',
                  0.49: '0.4',
                  0.5: '0.4',
                  0.51: '0.41',
                  0.52: '0.41',
                  0.53: '0.41',
                  0.54: '0.41',
                  0.55: '0.41',
                  0.56: '0.42',
                  0.57: '0.42',
                  0.58: '0.42',
                  0.59: '0.42',
                  0.6: '0.43',
                  0.61: '0.43',
                  0.62: '0.43',
                  0.63: '0.43',
                  0.64: '0.44',
                  0.65: '0.44',
                  0.66: '0.44',
                  0.67: '0.44',
                  0.68: '0.45',
                  0.69: '0.45',
                  0.7: '0.45',
                  0.71: '0.45',
                  0.72: '0.46',
                  0.73: '0.46',
                  0.74: '0.47',
                  0.75: '0.47',
                  0.76: '0.47',
                  0.77: '0.47',
                  0.78: '0.48',
                  0.79: '0.48',
                  0.8: '0.49',
                  0.81: '0.49',
                  0.82: '0.5',
                  0.83: '0.5',
                  0.84: '0.51',
                  0.85: '0.51',
                  0.86: '0.52',
                  0.87: '0.52',
                  0.88: '0.53',
                  0.89: '0.54',
                  0.9: '0.55',
                  0.91: '0.55',
                  0.92: '0.57',
                  0.93: '0.57',
                  0.94: '0.6',
                  0.95: '0.6',
                  0.96: '0.62',
                  0.97: '0.65',
                  0.98: '0.72',
                  0.99: '0.76',
                  '1.0': '15.0',
                },
                target_no_of_patients: '20',
              },
            },
          },
          status: 'SUCCEEDED',
        },
        'ctemp-pre-feasibility-simulated-activation-curve': {
          result: {
            0: '0',
            10: '10',
            20: '20',
            30: '30',
            40: '50',
            50: '60',
            60: '70',
            70: '75',
            80: '80',
            100: '100',
          },
          status: 'SUCCEEDED',
        },
        'ctemp-pre-feasibility-site-randomization-chart': {
          result: {
            global: {
              // global: {},
            },
            country: {
              ABC: {
                iteration_1: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.12,
                  0.18: 0.1,
                  0.21: 0.1,
                  0.24: 0.11,
                  0.27: 0.13,
                  0.3: 0.15,
                  0.33: 0.17,
                  0.36: 0.18,
                  0.39: 0.18,
                  0.42: 0.19,
                  0.45: 0.22,
                  0.48: 0.25,
                  0.51: 0.25,
                  0.54: 0.22,
                  0.57: 0.18,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.2,
                  1.14: 0.17,
                  1.17: 0.13,
                  1.2: 0.1,
                  1.23: 0.09,
                  1.26: 0.13,
                  1.29: 0.17,
                  1.32: 0.19,
                  1.35: 0.17,
                  1.38: 0.13,
                  1.41: 0.08,
                  1.44: 0.05,
                  1.47: 0.03,
                  1.5: 0.04,
                  1.53: 0.06,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.05,
                  1.83: 0.06,
                  1.86: 0.08,
                  1.89: 0.09,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.1,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.15,
                  2.28: 0.11,
                  2.31: 0.06,
                  2.34: 0.02,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.07,
                  2.49: 0.08,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.18,
                  2.73: 0.17,
                  2.76: 0.16,
                  2.79: 0.16,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.04,
                  3.15: 0.07,
                  3.18: 0.08,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.0,
                  3.36: 0.0,
                  3.39: 0.0,
                  3.42: 0.0,
                  3.45: 0.0,
                  3.48: 0.0,
                  3.51: 0.0,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.0,
                  3.87: 0.0,
                  3.9: 0.0,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.08,
                  4.11: 0.1,
                  4.14: 0.1,
                  4.17: 0.1,
                  4.2: 0.09,
                  4.23: 0.08,
                  4.26: 0.07,
                  4.29: 0.09,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.05,
                  4.47: 0.02,
                  4.5: 0.01,
                  4.53: 0.0,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.04,
                  4.65: 0.06,
                  4.68: 0.08,
                  4.71: 0.07,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.17,
                  4.95: 0.13,
                  4.98: 0.07,
                  5.01: 0.03,
                  5.04: 0.01,
                  5.07: 0.01,
                  5.1: 0.03,
                  5.13: 0.06,
                  5.16: 0.08,
                  5.19: 0.07,
                  5.22: 0.05,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.12,
                  5.4: 0.16,
                  5.43: 0.15,
                  5.46: 0.11,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.12,
                  5.7: 0.15,
                  5.73: 0.14,
                  5.76: 0.09,
                  5.79: 0.05,
                  5.82: 0.03,
                  5.85: 0.05,
                  5.88: 0.07,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.04,
                  6.21: 0.07,
                  6.24: 0.1,
                  6.27: 0.12,
                  6.3: 0.12,
                  6.33: 0.1,
                  6.36: 0.09,
                  6.39: 0.08,
                  6.42: 0.09,
                  6.45: 0.08,
                  6.48: 0.05,
                  6.51: 0.03,
                  6.54: 0.02,
                  6.57: 0.04,
                  6.6: 0.08,
                  6.63: 0.14,
                  6.66: 0.16,
                  6.69: 0.13,
                  6.72: 0.07,
                  6.75: 0.03,
                  6.78: 0.01,
                  6.81: 0.0,
                  6.84: 0.0,
                  6.87: 0.0,
                  6.9: 0.01,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.08,
                  7.02: 0.07,
                  7.05: 0.05,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.04,
                  7.23: 0.07,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.07,
                  7.89: 0.04,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.08,
                  8.22: 0.07,
                  8.25: 0.04,
                  8.28: 0.02,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.03,
                  8.46: 0.07,
                  8.49: 0.11,
                  8.52: 0.13,
                  8.55: 0.13,
                  8.58: 0.1,
                  8.61: 0.06,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.07,
                  8.91: 0.08,
                  8.94: 0.06,
                  8.97: 0.03,
                  '9.0': 0.02,
                  9.03: 0.03,
                  9.06: 0.05,
                  9.09: 0.07,
                  9.12: 0.08,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.02,
                  9.24: 0.03,
                  9.27: 0.05,
                  9.3: 0.08,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.06,
                  9.42: 0.07,
                  9.45: 0.08,
                  9.48: 0.07,
                  9.51: 0.05,
                  9.54: 0.02,
                  9.57: 0.01,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.07,
                  10.02: 0.12,
                  10.05: 0.14,
                  10.08: 0.14,
                  10.11: 0.12,
                  10.14: 0.1,
                  10.17: 0.09,
                  10.2: 0.08,
                  10.23: 0.06,
                  10.26: 0.03,
                  10.29: 0.01,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.0,
                  10.47: 0.0,
                  10.5: 0.0,
                  10.53: 0.0,
                  10.56: 0.0,
                  10.59: 0.0,
                  10.62: 0.0,
                  10.65: 0.0,
                  10.68: 0.0,
                  10.71: 0.0,
                  10.74: 0.0,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.0,
                  11.1: 0.01,
                  11.13: 0.02,
                  11.16: 0.04,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.05,
                  11.67: 0.1,
                  11.7: 0.15,
                  11.73: 0.2,
                  11.76: 0.24,
                  11.79: 0.24,
                  11.82: 0.19,
                  11.85: 0.14,
                  11.88: 0.11,
                  11.91: 0.09,
                  11.94: 0.06,
                  11.97: 0.03,
                  '12.0': 0.01,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.07,
                  12.21: 0.08,
                  12.24: 0.06,
                  12.27: 0.04,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.06,
                  12.87: 0.08,
                  12.9: 0.07,
                  12.93: 0.05,
                  12.96: 0.05,
                  12.99: 0.06,
                  13.02: 0.08,
                  13.05: 0.08,
                  13.08: 0.05,
                  13.11: 0.03,
                  13.14: 0.03,
                  13.17: 0.05,
                  13.2: 0.07,
                  13.23: 0.08,
                  13.26: 0.06,
                  13.29: 0.03,
                  13.32: 0.01,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.0,
                  13.62: 0.0,
                  13.65: 0.0,
                  13.68: 0.0,
                  13.71: 0.0,
                  13.74: 0.0,
                  13.77: 0.01,
                  13.8: 0.02,
                  13.83: 0.04,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.01,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.0,
                  14.34: 0.0,
                  14.37: 0.0,
                  14.4: 0.0,
                  14.43: 0.0,
                  14.46: 0.0,
                  14.49: 0.01,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.07,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.03,
                  14.7: 0.01,
                  14.73: 0.0,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.01,
                  14.88: 0.02,
                  14.91: 0.04,
                  14.94: 0.07,
                  14.97: 0.08,
                  '15.0': 0.06,
                  15.03: 0.04,
                  15.06: 0.01,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.0,
                  15.24: 0.0,
                  15.27: 0.0,
                  15.3: 0.0,
                  15.33: 0.0,
                  15.36: 0.0,
                  15.39: 0.0,
                  15.42: 0.0,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.03,
                  15.54: 0.06,
                  15.57: 0.08,
                  15.6: 0.07,
                  15.63: 0.05,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.07,
                  16.32: 0.05,
                  16.35: 0.02,
                  16.38: 0.01,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.0,
                  16.59: 0.0,
                  16.62: 0.0,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.0,
                  17.16: 0.0,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.08,
                  17.43: 0.12,
                  17.46: 0.15,
                  17.49: 0.16,
                  17.52: 0.15,
                  17.55: 0.13,
                  17.58: 0.09,
                  17.61: 0.05,
                  17.64: 0.02,
                  17.67: 0.01,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.01,
                  18.42: 0.02,
                  18.45: 0.05,
                  18.48: 0.08,
                  18.51: 0.09,
                  18.54: 0.08,
                  18.57: 0.08,
                  18.6: 0.09,
                  18.63: 0.08,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.01,
                  19.5: 0.02,
                  19.53: 0.04,
                  19.56: 0.07,
                  19.59: 0.08,
                  19.62: 0.06,
                  19.65: 0.03,
                  19.68: 0.01,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.08,
                  22.89: 0.07,
                  22.92: 0.04,
                  22.95: 0.02,
                  22.98: 0.01,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.01,
                  23.97: 0.03,
                  '24.0': 0.05,
                  24.03: 0.08,
                  24.06: 0.08,
                  24.09: 0.05,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.01,
                  25.08: 0.03,
                  25.11: 0.06,
                  25.14: 0.08,
                  25.17: 0.07,
                  25.2: 0.05,
                  25.23: 0.02,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.01,
                  26.94: 0.03,
                  26.97: 0.05,
                  '27.0': 0.08,
                  27.03: 0.08,
                  27.06: 0.05,
                  27.09: 0.03,
                  27.12: 0.01,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.01,
                  28.89: 0.04,
                  28.92: 0.06,
                  28.95: 0.08,
                  28.98: 0.07,
                  29.01: 0.04,
                  29.04: 0.02,
                  29.07: 0.01,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.02,
                  30.3: 0.04,
                  30.33: 0.07,
                  30.36: 0.08,
                  30.39: 0.07,
                  30.42: 0.04,
                  30.45: 0.02,
                  30.48: 0.01,
                  30.51: 0.01,
                  30.54: 0.02,
                  30.57: 0.05,
                  30.6: 0.07,
                  30.63: 0.08,
                  30.66: 0.06,
                  30.69: 0.03,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.02,
                  33.96: 0.05,
                  33.99: 0.09,
                  34.02: 0.13,
                  34.05: 0.14,
                  34.08: 0.12,
                  34.11: 0.07,
                  34.14: 0.03,
                  34.17: 0.01,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.0,
                  40.74: 0.0,
                  40.77: 0.0,
                  40.8: 0.0,
                  40.83: 0.0,
                  40.86: 0.0,
                  40.89: 0.0,
                  40.92: 0.0,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.01,
                  41.58: 0.03,
                  41.61: 0.05,
                  41.64: 0.08,
                  41.67: 0.08,
                  41.7: 0.05,
                  41.73: 0.03,
                  41.76: 0.01,
                  41.79: 0.0,
                  41.82: 0.0,
                  41.85: 0.0,
                  41.88: 0.0,
                  41.91: 0.0,
                  41.94: 0.0,
                  41.97: 0.0,
                  '42.0': 0.0,
                  42.03: 0.0,
                  42.06: 0.0,
                  42.09: 0.0,
                  42.12: 0.0,
                  42.15: 0.0,
                  42.18: 0.0,
                  42.21: 0.0,
                  42.24: 0.0,
                  42.27: 0.0,
                  42.3: 0.0,
                  42.33: 0.0,
                  42.36: 0.0,
                  42.39: 0.0,
                  42.42: 0.0,
                  42.45: 0.0,
                  42.48: 0.0,
                  42.51: 0.0,
                  42.54: 0.0,
                  42.57: 0.0,
                  42.6: 0.0,
                  42.63: 0.0,
                  42.66: 0.0,
                  42.69: 0.0,
                  42.72: 0.0,
                  42.75: 0.0,
                  42.78: 0.0,
                  42.81: 0.0,
                  42.84: 0.0,
                  42.87: 0.0,
                  42.9: 0.0,
                  42.93: 0.0,
                  42.96: 0.0,
                  42.99: 0.0,
                  43.02: 0.0,
                  43.05: 0.0,
                  43.08: 0.0,
                  43.11: 0.0,
                  43.14: 0.0,
                  43.17: 0.0,
                  43.2: 0.0,
                  43.23: 0.0,
                  43.26: 0.0,
                  43.29: 0.0,
                  43.32: 0.0,
                  43.35: 0.0,
                  43.38: 0.0,
                  43.41: 0.0,
                  43.44: 0.0,
                  43.47: 0.0,
                  43.5: 0.0,
                  43.53: 0.0,
                  43.56: 0.0,
                  43.59: 0.0,
                  43.62: 0.0,
                  43.65: 0.0,
                  43.68: 0.0,
                  43.71: 0.0,
                  43.74: 0.0,
                  43.77: 0.0,
                  43.8: 0.0,
                  43.83: 0.0,
                  43.86: 0.0,
                  43.89: 0.0,
                  43.92: 0.0,
                  43.95: 0.0,
                  43.98: 0.0,
                  44.01: 0.0,
                  44.04: 0.0,
                  44.07: 0.0,
                  44.1: 0.0,
                  44.13: 0.0,
                  44.16: 0.0,
                  44.19: 0.0,
                  44.22: 0.0,
                  44.25: 0.0,
                  44.28: 0.0,
                  44.31: 0.0,
                  44.34: 0.0,
                  44.37: 0.0,
                  44.4: 0.0,
                  44.43: 0.0,
                  44.46: 0.0,
                  44.49: 0.0,
                  44.52: 0.0,
                  44.55: 0.0,
                  44.58: 0.0,
                  44.61: 0.0,
                  44.64: 0.0,
                  44.67: 0.0,
                  44.7: 0.0,
                  44.73: 0.0,
                  44.76: 0.0,
                  44.79: 0.0,
                  44.82: 0.0,
                  44.85: 0.0,
                  44.88: 0.0,
                  44.91: 0.0,
                  44.94: 0.0,
                  44.97: 0.0,
                  '45.0': 0.0,
                  45.03: 0.0,
                  45.06: 0.0,
                  45.09: 0.0,
                  45.12: 0.0,
                  45.15: 0.0,
                  45.18: 0.0,
                  45.21: 0.0,
                  45.24: 0.0,
                  45.27: 0.0,
                  45.3: 0.0,
                  45.33: 0.0,
                  45.36: 0.0,
                  45.39: 0.0,
                  45.42: 0.0,
                  45.45: 0.0,
                  45.48: 0.0,
                  45.51: 0.0,
                  45.54: 0.0,
                  45.57: 0.0,
                  45.6: 0.0,
                  45.63: 0.0,
                  45.66: 0.0,
                  45.69: 0.0,
                  45.72: 0.0,
                  45.75: 0.0,
                  45.78: 0.0,
                  45.81: 0.0,
                  45.84: 0.0,
                  45.87: 0.0,
                  45.9: 0.0,
                  45.93: 0.0,
                  45.96: 0.0,
                  45.99: 0.0,
                  46.02: 0.0,
                  46.05: 0.0,
                  46.08: 0.01,
                  46.11: 0.03,
                  46.14: 0.06,
                  46.17: 0.08,
                  46.2: 0.08,
                  46.23: 0.05,
                  46.26: 0.02,
                  46.29: 0.01,
                  46.32: 0.0,
                  46.35: 0.0,
                  46.38: 0.0,
                  46.41: 0.0,
                  46.44: 0.0,
                  46.47: 0.0,
                  46.5: 0.0,
                  46.53: 0.0,
                  46.56: 0.0,
                  46.59: 0.0,
                  46.62: 0.0,
                  46.65: 0.0,
                  46.68: 0.0,
                  46.71: 0.0,
                  46.74: 0.0,
                  46.77: 0.0,
                  46.8: 0.0,
                  46.83: 0.0,
                  46.86: 0.0,
                  46.89: 0.0,
                  46.92: 0.0,
                  46.95: 0.0,
                  46.98: 0.0,
                },
                iteration_2: {
                  '0.0': 0.0,
                  0.03: 0.0,
                  0.06: 0.01,
                  0.09: 0.03,
                  0.12: 0.08,
                  0.15: 0.15,
                  0.18: 0.2,
                  0.21: 0.22,
                  0.24: 0.2,
                  0.27: 0.16,
                  0.3: 0.13,
                  0.33: 0.1,
                  0.36: 0.08,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.18,
                  0.66: 0.2,
                  0.69: 0.21,
                  0.72: 0.24,
                  0.75: 0.25,
                  0.78: 0.23,
                  0.81: 0.16,
                  0.84: 0.08,
                  0.87: 0.03,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.0,
                  1.02: 0.0,
                  1.05: 0.02,
                  1.08: 0.04,
                  1.11: 0.07,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.01,
                  1.26: 0.0,
                  1.29: 0.0,
                  1.32: 0.01,
                  1.35: 0.03,
                  1.38: 0.09,
                  1.41: 0.17,
                  1.44: 0.23,
                  1.47: 0.22,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.02,
                  1.59: 0.01,
                  1.62: 0.0,
                  1.65: 0.0,
                  1.68: 0.0,
                  1.71: 0.01,
                  1.74: 0.03,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.05,
                  1.95: 0.02,
                  1.98: 0.01,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.0,
                  2.1: 0.0,
                  2.13: 0.01,
                  2.16: 0.02,
                  2.19: 0.06,
                  2.22: 0.12,
                  2.25: 0.16,
                  2.28: 0.15,
                  2.31: 0.1,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.01,
                  2.43: 0.02,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.1,
                  2.55: 0.11,
                  2.58: 0.13,
                  2.61: 0.16,
                  2.64: 0.18,
                  2.67: 0.18,
                  2.7: 0.15,
                  2.73: 0.11,
                  2.76: 0.08,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.21,
                  2.88: 0.23,
                  2.91: 0.19,
                  2.94: 0.12,
                  2.97: 0.09,
                  '3.0': 0.09,
                  3.03: 0.08,
                  3.06: 0.06,
                  3.09: 0.03,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.08,
                  3.24: 0.1,
                  3.27: 0.11,
                  3.3: 0.14,
                  3.33: 0.19,
                  3.36: 0.26,
                  3.39: 0.3,
                  3.42: 0.29,
                  3.45: 0.22,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.07,
                  3.57: 0.08,
                  3.6: 0.08,
                  3.63: 0.05,
                  3.66: 0.02,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.07,
                  3.9: 0.13,
                  3.93: 0.18,
                  3.96: 0.19,
                  3.99: 0.17,
                  4.02: 0.12,
                  4.05: 0.07,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.08,
                  4.35: 0.07,
                  4.38: 0.07,
                  4.41: 0.08,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.0,
                  4.62: 0.0,
                  4.65: 0.0,
                  4.68: 0.0,
                  4.71: 0.0,
                  4.74: 0.0,
                  4.77: 0.0,
                  4.8: 0.0,
                  4.83: 0.0,
                  4.86: 0.0,
                  4.89: 0.0,
                  4.92: 0.0,
                  4.95: 0.0,
                  4.98: 0.01,
                  5.01: 0.02,
                  5.04: 0.04,
                  5.07: 0.07,
                  5.1: 0.08,
                  5.13: 0.06,
                  5.16: 0.03,
                  5.19: 0.01,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.01,
                  5.37: 0.02,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.09,
                  5.58: 0.07,
                  5.61: 0.04,
                  5.64: 0.02,
                  5.67: 0.01,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.0,
                  5.88: 0.0,
                  5.91: 0.0,
                  5.94: 0.0,
                  5.97: 0.0,
                  '6.0': 0.0,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.09,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.02,
                  6.96: 0.01,
                  6.99: 0.0,
                  7.02: 0.0,
                  7.05: 0.01,
                  7.08: 0.05,
                  7.11: 0.12,
                  7.14: 0.2,
                  7.17: 0.23,
                  7.2: 0.2,
                  7.23: 0.12,
                  7.26: 0.05,
                  7.29: 0.02,
                  7.32: 0.0,
                  7.35: 0.0,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.01,
                  7.59: 0.04,
                  7.62: 0.09,
                  7.65: 0.14,
                  7.68: 0.16,
                  7.71: 0.13,
                  7.74: 0.09,
                  7.77: 0.08,
                  7.8: 0.08,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.01,
                  7.95: 0.02,
                  7.98: 0.04,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.03,
                  8.13: 0.01,
                  8.16: 0.0,
                  8.19: 0.0,
                  8.22: 0.0,
                  8.25: 0.0,
                  8.28: 0.0,
                  8.31: 0.01,
                  8.34: 0.02,
                  8.37: 0.05,
                  8.4: 0.07,
                  8.43: 0.08,
                  8.46: 0.06,
                  8.49: 0.03,
                  8.52: 0.01,
                  8.55: 0.0,
                  8.58: 0.01,
                  8.61: 0.03,
                  8.64: 0.07,
                  8.67: 0.12,
                  8.7: 0.16,
                  8.73: 0.15,
                  8.76: 0.13,
                  8.79: 0.11,
                  8.82: 0.09,
                  8.85: 0.07,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.08,
                  9.18: 0.13,
                  9.21: 0.17,
                  9.24: 0.19,
                  9.27: 0.17,
                  9.3: 0.12,
                  9.33: 0.06,
                  9.36: 0.03,
                  9.39: 0.02,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.08,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.02,
                  9.87: 0.04,
                  9.9: 0.07,
                  9.93: 0.08,
                  9.96: 0.07,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.11,
                  10.71: 0.11,
                  10.74: 0.1,
                  10.77: 0.08,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.08,
                  10.92: 0.08,
                  10.95: 0.06,
                  10.98: 0.03,
                  11.01: 0.01,
                  11.04: 0.01,
                  11.07: 0.02,
                  11.1: 0.05,
                  11.13: 0.09,
                  11.16: 0.14,
                  11.19: 0.15,
                  11.22: 0.12,
                  11.25: 0.07,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.01,
                  11.49: 0.03,
                  11.52: 0.06,
                  11.55: 0.08,
                  11.58: 0.08,
                  11.61: 0.05,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.02,
                  12.06: 0.04,
                  12.09: 0.07,
                  12.12: 0.08,
                  12.15: 0.07,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.03,
                  12.36: 0.06,
                  12.39: 0.08,
                  12.42: 0.07,
                  12.45: 0.04,
                  12.48: 0.02,
                  12.51: 0.01,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.01,
                  12.69: 0.03,
                  12.72: 0.06,
                  12.75: 0.08,
                  12.78: 0.07,
                  12.81: 0.04,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.01,
                  13.5: 0.02,
                  13.53: 0.05,
                  13.56: 0.08,
                  13.59: 0.1,
                  13.62: 0.11,
                  13.65: 0.11,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.05,
                  13.89: 0.04,
                  13.92: 0.06,
                  13.95: 0.08,
                  13.98: 0.08,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.08,
                  14.31: 0.06,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.11,
                  14.49: 0.15,
                  14.52: 0.15,
                  14.55: 0.11,
                  14.58: 0.06,
                  14.61: 0.04,
                  14.64: 0.05,
                  14.67: 0.07,
                  14.7: 0.08,
                  14.73: 0.06,
                  14.76: 0.03,
                  14.79: 0.01,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.01,
                  15.18: 0.02,
                  15.21: 0.06,
                  15.24: 0.13,
                  15.27: 0.2,
                  15.3: 0.24,
                  15.33: 0.23,
                  15.36: 0.19,
                  15.39: 0.13,
                  15.42: 0.07,
                  15.45: 0.03,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.05,
                  15.69: 0.07,
                  15.72: 0.08,
                  15.75: 0.06,
                  15.78: 0.03,
                  15.81: 0.01,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.01,
                  16.02: 0.02,
                  16.05: 0.05,
                  16.08: 0.07,
                  16.11: 0.08,
                  16.14: 0.06,
                  16.17: 0.03,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.01,
                  16.47: 0.03,
                  16.5: 0.05,
                  16.53: 0.08,
                  16.56: 0.08,
                  16.59: 0.05,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.04,
                  16.8: 0.07,
                  16.83: 0.08,
                  16.86: 0.06,
                  16.89: 0.03,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.03,
                  17.19: 0.05,
                  17.22: 0.08,
                  17.25: 0.08,
                  17.28: 0.07,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.08,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.08,
                  18.69: 0.06,
                  18.72: 0.03,
                  18.75: 0.01,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.01,
                  20.94: 0.02,
                  20.97: 0.05,
                  '21.0': 0.07,
                  21.03: 0.08,
                  21.06: 0.06,
                  21.09: 0.03,
                  21.12: 0.01,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.0,
                  22.8: 0.0,
                  22.83: 0.0,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.07,
                  24.15: 0.08,
                  24.18: 0.07,
                  24.21: 0.04,
                  24.24: 0.02,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.01,
                  25.56: 0.02,
                  25.59: 0.04,
                  25.62: 0.07,
                  25.65: 0.08,
                  25.68: 0.06,
                  25.71: 0.03,
                  25.74: 0.01,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.07,
                  26.52: 0.08,
                  26.55: 0.06,
                  26.58: 0.03,
                  26.61: 0.01,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.01,
                  29.55: 0.04,
                  29.58: 0.07,
                  29.61: 0.08,
                  29.64: 0.07,
                  29.67: 0.04,
                  29.7: 0.02,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.01,
                  32.64: 0.02,
                  32.67: 0.05,
                  32.7: 0.07,
                  32.73: 0.08,
                  32.76: 0.06,
                  32.79: 0.03,
                  32.82: 0.01,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.01,
                  33.09: 0.03,
                  33.12: 0.06,
                  33.15: 0.08,
                  33.18: 0.07,
                  33.21: 0.05,
                  33.24: 0.02,
                  33.27: 0.01,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.01,
                  35.67: 0.02,
                  35.7: 0.05,
                  35.73: 0.07,
                  35.76: 0.08,
                  35.79: 0.06,
                  35.82: 0.03,
                  35.85: 0.01,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
                iteration_3: {
                  '0.0': 0.09,
                  0.03: 0.16,
                  0.06: 0.21,
                  0.09: 0.22,
                  0.12: 0.18,
                  0.15: 0.14,
                  0.18: 0.14,
                  0.21: 0.15,
                  0.24: 0.14,
                  0.27: 0.11,
                  0.3: 0.06,
                  0.33: 0.02,
                  0.36: 0.01,
                  0.39: 0.0,
                  0.42: 0.01,
                  0.45: 0.04,
                  0.48: 0.1,
                  0.51: 0.17,
                  0.54: 0.22,
                  0.57: 0.21,
                  0.6: 0.15,
                  0.63: 0.08,
                  0.66: 0.03,
                  0.69: 0.02,
                  0.72: 0.04,
                  0.75: 0.07,
                  0.78: 0.08,
                  0.81: 0.07,
                  0.84: 0.04,
                  0.87: 0.02,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.02,
                  1.02: 0.04,
                  1.05: 0.07,
                  1.08: 0.08,
                  1.11: 0.07,
                  1.14: 0.04,
                  1.17: 0.02,
                  1.2: 0.01,
                  1.23: 0.03,
                  1.26: 0.08,
                  1.29: 0.14,
                  1.32: 0.19,
                  1.35: 0.22,
                  1.38: 0.24,
                  1.41: 0.3,
                  1.44: 0.39,
                  1.47: 0.47,
                  1.5: 0.5,
                  1.53: 0.47,
                  1.56: 0.4,
                  1.59: 0.33,
                  1.62: 0.27,
                  1.65: 0.21,
                  1.68: 0.16,
                  1.71: 0.12,
                  1.74: 0.09,
                  1.77: 0.07,
                  1.8: 0.07,
                  1.83: 0.08,
                  1.86: 0.08,
                  1.89: 0.05,
                  1.92: 0.02,
                  1.95: 0.01,
                  1.98: 0.0,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.02,
                  2.1: 0.04,
                  2.13: 0.07,
                  2.16: 0.09,
                  2.19: 0.09,
                  2.22: 0.08,
                  2.25: 0.09,
                  2.28: 0.11,
                  2.31: 0.12,
                  2.34: 0.15,
                  2.37: 0.17,
                  2.4: 0.15,
                  2.43: 0.1,
                  2.46: 0.06,
                  2.49: 0.06,
                  2.52: 0.09,
                  2.55: 0.13,
                  2.58: 0.16,
                  2.61: 0.16,
                  2.64: 0.14,
                  2.67: 0.12,
                  2.7: 0.08,
                  2.73: 0.04,
                  2.76: 0.02,
                  2.79: 0.0,
                  2.82: 0.0,
                  2.85: 0.0,
                  2.88: 0.0,
                  2.91: 0.0,
                  2.94: 0.02,
                  2.97: 0.05,
                  '3.0': 0.1,
                  3.03: 0.17,
                  3.06: 0.21,
                  3.09: 0.23,
                  3.12: 0.21,
                  3.15: 0.17,
                  3.18: 0.1,
                  3.21: 0.05,
                  3.24: 0.02,
                  3.27: 0.01,
                  3.3: 0.01,
                  3.33: 0.02,
                  3.36: 0.04,
                  3.39: 0.07,
                  3.42: 0.08,
                  3.45: 0.07,
                  3.48: 0.04,
                  3.51: 0.02,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.01,
                  3.87: 0.03,
                  3.9: 0.06,
                  3.93: 0.08,
                  3.96: 0.07,
                  3.99: 0.05,
                  4.02: 0.02,
                  4.05: 0.01,
                  4.08: 0.0,
                  4.11: 0.0,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.0,
                  4.23: 0.0,
                  4.26: 0.0,
                  4.29: 0.0,
                  4.32: 0.0,
                  4.35: 0.0,
                  4.38: 0.0,
                  4.41: 0.0,
                  4.44: 0.0,
                  4.47: 0.0,
                  4.5: 0.0,
                  4.53: 0.0,
                  4.56: 0.01,
                  4.59: 0.04,
                  4.62: 0.09,
                  4.65: 0.14,
                  4.68: 0.16,
                  4.71: 0.13,
                  4.74: 0.09,
                  4.77: 0.08,
                  4.8: 0.08,
                  4.83: 0.07,
                  4.86: 0.05,
                  4.89: 0.02,
                  4.92: 0.01,
                  4.95: 0.0,
                  4.98: 0.0,
                  5.01: 0.0,
                  5.04: 0.0,
                  5.07: 0.0,
                  5.1: 0.0,
                  5.13: 0.0,
                  5.16: 0.0,
                  5.19: 0.0,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.0,
                  5.37: 0.0,
                  5.4: 0.0,
                  5.43: 0.02,
                  5.46: 0.05,
                  5.49: 0.1,
                  5.52: 0.15,
                  5.55: 0.15,
                  5.58: 0.11,
                  5.61: 0.05,
                  5.64: 0.02,
                  5.67: 0.0,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.01,
                  5.88: 0.02,
                  5.91: 0.05,
                  5.94: 0.08,
                  5.97: 0.08,
                  '6.0': 0.06,
                  6.03: 0.03,
                  6.06: 0.01,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.0,
                  6.33: 0.0,
                  6.36: 0.01,
                  6.39: 0.02,
                  6.42: 0.05,
                  6.45: 0.07,
                  6.48: 0.08,
                  6.51: 0.06,
                  6.54: 0.03,
                  6.57: 0.02,
                  6.6: 0.02,
                  6.63: 0.05,
                  6.66: 0.09,
                  6.69: 0.15,
                  6.72: 0.19,
                  6.75: 0.2,
                  6.78: 0.18,
                  6.81: 0.14,
                  6.84: 0.11,
                  6.87: 0.09,
                  6.9: 0.06,
                  6.93: 0.04,
                  6.96: 0.03,
                  6.99: 0.04,
                  7.02: 0.06,
                  7.05: 0.08,
                  7.08: 0.07,
                  7.11: 0.04,
                  7.14: 0.02,
                  7.17: 0.02,
                  7.2: 0.03,
                  7.23: 0.06,
                  7.26: 0.08,
                  7.29: 0.07,
                  7.32: 0.05,
                  7.35: 0.02,
                  7.38: 0.01,
                  7.41: 0.0,
                  7.44: 0.01,
                  7.47: 0.04,
                  7.5: 0.08,
                  7.53: 0.13,
                  7.56: 0.15,
                  7.59: 0.13,
                  7.62: 0.08,
                  7.65: 0.04,
                  7.68: 0.03,
                  7.71: 0.04,
                  7.74: 0.07,
                  7.77: 0.08,
                  7.8: 0.07,
                  7.83: 0.04,
                  7.86: 0.02,
                  7.89: 0.0,
                  7.92: 0.0,
                  7.95: 0.0,
                  7.98: 0.0,
                  8.01: 0.0,
                  8.04: 0.0,
                  8.07: 0.01,
                  8.1: 0.03,
                  8.13: 0.06,
                  8.16: 0.08,
                  8.19: 0.07,
                  8.22: 0.05,
                  8.25: 0.02,
                  8.28: 0.01,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.01,
                  8.4: 0.02,
                  8.43: 0.05,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.06,
                  8.55: 0.04,
                  8.58: 0.04,
                  8.61: 0.06,
                  8.64: 0.08,
                  8.67: 0.07,
                  8.7: 0.05,
                  8.73: 0.02,
                  8.76: 0.01,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.05,
                  8.88: 0.08,
                  8.91: 0.08,
                  8.94: 0.07,
                  8.97: 0.06,
                  '9.0': 0.08,
                  9.03: 0.11,
                  9.06: 0.13,
                  9.09: 0.13,
                  9.12: 0.1,
                  9.15: 0.08,
                  9.18: 0.07,
                  9.21: 0.08,
                  9.24: 0.08,
                  9.27: 0.07,
                  9.3: 0.05,
                  9.33: 0.05,
                  9.36: 0.07,
                  9.39: 0.08,
                  9.42: 0.07,
                  9.45: 0.04,
                  9.48: 0.02,
                  9.51: 0.01,
                  9.54: 0.0,
                  9.57: 0.0,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.01,
                  9.93: 0.03,
                  9.96: 0.06,
                  9.99: 0.08,
                  10.02: 0.09,
                  10.05: 0.11,
                  10.08: 0.13,
                  10.11: 0.16,
                  10.14: 0.15,
                  10.17: 0.1,
                  10.2: 0.05,
                  10.23: 0.02,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.01,
                  10.44: 0.03,
                  10.47: 0.06,
                  10.5: 0.08,
                  10.53: 0.08,
                  10.56: 0.08,
                  10.59: 0.08,
                  10.62: 0.08,
                  10.65: 0.08,
                  10.68: 0.05,
                  10.71: 0.02,
                  10.74: 0.01,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.01,
                  11.07: 0.03,
                  11.1: 0.05,
                  11.13: 0.08,
                  11.16: 0.09,
                  11.19: 0.1,
                  11.22: 0.1,
                  11.25: 0.09,
                  11.28: 0.07,
                  11.31: 0.04,
                  11.34: 0.02,
                  11.37: 0.02,
                  11.4: 0.04,
                  11.43: 0.07,
                  11.46: 0.08,
                  11.49: 0.06,
                  11.52: 0.03,
                  11.55: 0.01,
                  11.58: 0.0,
                  11.61: 0.0,
                  11.64: 0.0,
                  11.67: 0.0,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.0,
                  12.12: 0.0,
                  12.15: 0.0,
                  12.18: 0.0,
                  12.21: 0.0,
                  12.24: 0.0,
                  12.27: 0.0,
                  12.3: 0.0,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.01,
                  12.51: 0.02,
                  12.54: 0.04,
                  12.57: 0.07,
                  12.6: 0.08,
                  12.63: 0.06,
                  12.66: 0.04,
                  12.69: 0.01,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.0,
                  12.81: 0.0,
                  12.84: 0.0,
                  12.87: 0.0,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.01,
                  13.56: 0.04,
                  13.59: 0.06,
                  13.62: 0.08,
                  13.65: 0.07,
                  13.68: 0.04,
                  13.71: 0.02,
                  13.74: 0.01,
                  13.77: 0.0,
                  13.8: 0.0,
                  13.83: 0.0,
                  13.86: 0.0,
                  13.89: 0.0,
                  13.92: 0.0,
                  13.95: 0.0,
                  13.98: 0.0,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.01,
                  14.34: 0.04,
                  14.37: 0.09,
                  14.4: 0.14,
                  14.43: 0.19,
                  14.46: 0.2,
                  14.49: 0.18,
                  14.52: 0.14,
                  14.55: 0.13,
                  14.58: 0.16,
                  14.61: 0.16,
                  14.64: 0.12,
                  14.67: 0.07,
                  14.7: 0.03,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.01,
                  14.82: 0.02,
                  14.85: 0.04,
                  14.88: 0.07,
                  14.91: 0.08,
                  14.94: 0.06,
                  14.97: 0.03,
                  '15.0': 0.01,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.01,
                  15.15: 0.02,
                  15.18: 0.05,
                  15.21: 0.07,
                  15.24: 0.08,
                  15.27: 0.06,
                  15.3: 0.03,
                  15.33: 0.01,
                  15.36: 0.01,
                  15.39: 0.03,
                  15.42: 0.06,
                  15.45: 0.08,
                  15.48: 0.08,
                  15.51: 0.08,
                  15.54: 0.09,
                  15.57: 0.1,
                  15.6: 0.12,
                  15.63: 0.12,
                  15.66: 0.1,
                  15.69: 0.07,
                  15.72: 0.04,
                  15.75: 0.01,
                  15.78: 0.01,
                  15.81: 0.02,
                  15.84: 0.04,
                  15.87: 0.07,
                  15.9: 0.08,
                  15.93: 0.06,
                  15.96: 0.03,
                  15.99: 0.01,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.09,
                  16.32: 0.08,
                  16.35: 0.08,
                  16.38: 0.09,
                  16.41: 0.07,
                  16.44: 0.06,
                  16.47: 0.06,
                  16.5: 0.07,
                  16.53: 0.08,
                  16.56: 0.07,
                  16.59: 0.04,
                  16.62: 0.02,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.02,
                  16.98: 0.04,
                  17.01: 0.08,
                  17.04: 0.13,
                  17.07: 0.14,
                  17.1: 0.12,
                  17.13: 0.08,
                  17.16: 0.04,
                  17.19: 0.01,
                  17.22: 0.01,
                  17.25: 0.02,
                  17.28: 0.05,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.06,
                  17.4: 0.03,
                  17.43: 0.01,
                  17.46: 0.0,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.01,
                  17.64: 0.03,
                  17.67: 0.06,
                  17.7: 0.08,
                  17.73: 0.07,
                  17.76: 0.04,
                  17.79: 0.02,
                  17.82: 0.01,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.0,
                  18.57: 0.0,
                  18.6: 0.0,
                  18.63: 0.0,
                  18.66: 0.0,
                  18.69: 0.0,
                  18.72: 0.0,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.02,
                  20.82: 0.04,
                  20.85: 0.07,
                  20.88: 0.08,
                  20.91: 0.07,
                  20.94: 0.04,
                  20.97: 0.02,
                  '21.0': 0.01,
                  21.03: 0.02,
                  21.06: 0.05,
                  21.09: 0.07,
                  21.12: 0.08,
                  21.15: 0.06,
                  21.18: 0.03,
                  21.21: 0.01,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.01,
                  22.65: 0.03,
                  22.68: 0.06,
                  22.71: 0.08,
                  22.74: 0.07,
                  22.77: 0.05,
                  22.8: 0.02,
                  22.83: 0.01,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.0,
                  24.09: 0.0,
                  24.12: 0.0,
                  24.15: 0.0,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.01,
                  24.54: 0.03,
                  24.57: 0.06,
                  24.6: 0.08,
                  24.63: 0.08,
                  24.66: 0.05,
                  24.69: 0.02,
                  24.72: 0.01,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.01,
                  24.93: 0.02,
                  24.96: 0.05,
                  24.99: 0.08,
                  25.02: 0.08,
                  25.05: 0.06,
                  25.08: 0.03,
                  25.11: 0.01,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.01,
                  25.74: 0.02,
                  25.77: 0.05,
                  25.8: 0.08,
                  25.83: 0.08,
                  25.86: 0.06,
                  25.89: 0.03,
                  25.92: 0.01,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.01,
                  27.21: 0.03,
                  27.24: 0.05,
                  27.27: 0.08,
                  27.3: 0.08,
                  27.33: 0.05,
                  27.36: 0.03,
                  27.39: 0.01,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.01,
                  33.96: 0.02,
                  33.99: 0.04,
                  34.02: 0.07,
                  34.05: 0.08,
                  34.08: 0.06,
                  34.11: 0.04,
                  34.14: 0.01,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.01,
                  40.74: 0.02,
                  40.77: 0.04,
                  40.8: 0.07,
                  40.83: 0.08,
                  40.86: 0.06,
                  40.89: 0.04,
                  40.92: 0.01,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.0,
                  41.58: 0.0,
                  41.61: 0.0,
                  41.64: 0.0,
                  41.67: 0.0,
                  41.7: 0.0,
                  41.73: 0.0,
                  41.76: 0.0,
                  41.79: 0.01,
                  41.82: 0.02,
                  41.85: 0.05,
                  41.88: 0.07,
                  41.91: 0.08,
                  41.94: 0.06,
                  41.97: 0.03,
                },
                iteration_median: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.14,
                  0.18: 0.18,
                  0.21: 0.19,
                  0.24: 0.18,
                  0.27: 0.15,
                  0.3: 0.13,
                  0.33: 0.11,
                  0.36: 0.1,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.15,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.06,
                  1.26: 0.08,
                  1.29: 0.08,
                  1.32: 0.07,
                  1.35: 0.08,
                  1.38: 0.12,
                  1.41: 0.16,
                  1.44: 0.17,
                  1.47: 0.16,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.08,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.16,
                  2.28: 0.11,
                  2.31: 0.07,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.15,
                  2.73: 0.12,
                  2.76: 0.09,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.01,
                  3.36: 0.03,
                  3.39: 0.06,
                  3.42: 0.11,
                  3.45: 0.14,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.06,
                  3.57: 0.02,
                  3.6: 0.01,
                  3.63: 0.0,
                  3.66: 0.01,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.02,
                  3.9: 0.01,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.03,
                  4.65: 0.02,
                  4.68: 0.03,
                  4.71: 0.06,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.16,
                  4.95: 0.1,
                  4.98: 0.05,
                  5.01: 0.02,
                  5.04: 0.02,
                  5.07: 0.04,
                  5.1: 0.07,
                  5.13: 0.06,
                  5.16: 0.07,
                  5.19: 0.04,
                  5.22: 0.03,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.05,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.07,
                  5.7: 0.04,
                  5.73: 0.02,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.01,
                  5.85: 0.02,
                  5.88: 0.05,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.01,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.11,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.06,
                  7.02: 0.03,
                  7.05: 0.01,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.05,
                  7.23: 0.08,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.04,
                  8.22: 0.02,
                  8.25: 0.01,
                  8.28: 0.0,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.04,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.07,
                  8.55: 0.04,
                  8.58: 0.02,
                  8.61: 0.03,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.04,
                  9.24: 0.08,
                  9.27: 0.12,
                  9.3: 0.12,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.05,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.07,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.08,
                  10.71: 0.05,
                  10.74: 0.04,
                  10.77: 0.05,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.03,
                  10.92: 0.01,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.01,
                  11.1: 0.03,
                  11.13: 0.06,
                  11.16: 0.08,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.01,
                  13.62: 0.03,
                  13.65: 0.06,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.05,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.07,
                  14.31: 0.04,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.05,
                  14.49: 0.03,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.06,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.05,
                  14.7: 0.02,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.01,
                  15.24: 0.02,
                  15.27: 0.04,
                  15.3: 0.07,
                  15.33: 0.08,
                  15.36: 0.06,
                  15.39: 0.04,
                  15.42: 0.01,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.01,
                  16.59: 0.03,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.01,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.01,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.05,
                  22.89: 0.02,
                  22.92: 0.01,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.04,
                  26.52: 0.02,
                  26.55: 0.01,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
              },
              Americas: {
                iteration_0: {
                  '0.0': 0.16,
                  0.03: 0.21,
                  0.06: 0.21,
                  0.09: 0.17,
                  0.12: 0.14,
                  0.15: 0.14,
                  0.18: 0.18,
                  0.21: 0.19,
                  0.24: 0.18,
                  0.27: 0.15,
                  0.3: 0.13,
                  0.33: 0.11,
                  0.36: 0.1,
                  0.39: 0.07,
                  0.42: 0.04,
                  0.45: 0.01,
                  0.48: 0.0,
                  0.51: 0.0,
                  0.54: 0.0,
                  0.57: 0.0,
                  0.6: 0.0,
                  0.63: 0.0,
                  0.66: 0.0,
                  0.69: 0.01,
                  0.72: 0.02,
                  0.75: 0.05,
                  0.78: 0.07,
                  0.81: 0.08,
                  0.84: 0.07,
                  0.87: 0.06,
                  0.9: 0.07,
                  0.93: 0.13,
                  0.96: 0.22,
                  0.99: 0.32,
                  1.02: 0.38,
                  1.05: 0.35,
                  1.08: 0.25,
                  1.11: 0.15,
                  1.14: 0.07,
                  1.17: 0.04,
                  1.2: 0.04,
                  1.23: 0.06,
                  1.26: 0.08,
                  1.29: 0.08,
                  1.32: 0.07,
                  1.35: 0.08,
                  1.38: 0.12,
                  1.41: 0.16,
                  1.44: 0.17,
                  1.47: 0.16,
                  1.5: 0.17,
                  1.53: 0.18,
                  1.56: 0.17,
                  1.59: 0.15,
                  1.62: 0.13,
                  1.65: 0.13,
                  1.68: 0.13,
                  1.71: 0.12,
                  1.74: 0.12,
                  1.77: 0.14,
                  1.8: 0.18,
                  1.83: 0.22,
                  1.86: 0.21,
                  1.89: 0.17,
                  1.92: 0.14,
                  1.95: 0.16,
                  1.98: 0.2,
                  2.01: 0.22,
                  2.04: 0.19,
                  2.07: 0.13,
                  2.1: 0.08,
                  2.13: 0.08,
                  2.16: 0.14,
                  2.19: 0.2,
                  2.22: 0.22,
                  2.25: 0.18,
                  2.28: 0.11,
                  2.31: 0.07,
                  2.34: 0.05,
                  2.37: 0.07,
                  2.4: 0.08,
                  2.43: 0.07,
                  2.46: 0.04,
                  2.49: 0.02,
                  2.52: 0.01,
                  2.55: 0.0,
                  2.58: 0.01,
                  2.61: 0.04,
                  2.64: 0.07,
                  2.67: 0.11,
                  2.7: 0.12,
                  2.73: 0.12,
                  2.76: 0.09,
                  2.79: 0.06,
                  2.82: 0.03,
                  2.85: 0.01,
                  2.88: 0.0,
                  2.91: 0.0,
                  2.94: 0.0,
                  2.97: 0.0,
                  '3.0': 0.0,
                  3.03: 0.0,
                  3.06: 0.0,
                  3.09: 0.0,
                  3.12: 0.0,
                  3.15: 0.0,
                  3.18: 0.0,
                  3.21: 0.0,
                  3.24: 0.0,
                  3.27: 0.0,
                  3.3: 0.0,
                  3.33: 0.01,
                  3.36: 0.03,
                  3.39: 0.06,
                  3.42: 0.11,
                  3.45: 0.14,
                  3.48: 0.14,
                  3.51: 0.1,
                  3.54: 0.06,
                  3.57: 0.02,
                  3.6: 0.01,
                  3.63: 0.0,
                  3.66: 0.01,
                  3.69: 0.04,
                  3.72: 0.1,
                  3.75: 0.15,
                  3.78: 0.16,
                  3.81: 0.12,
                  3.84: 0.06,
                  3.87: 0.02,
                  3.9: 0.01,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.0,
                  4.02: 0.0,
                  4.05: 0.0,
                  4.08: 0.0,
                  4.11: 0.0,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.0,
                  4.23: 0.0,
                  4.26: 0.02,
                  4.29: 0.05,
                  4.32: 0.12,
                  4.35: 0.19,
                  4.38: 0.23,
                  4.41: 0.23,
                  4.44: 0.21,
                  4.47: 0.22,
                  4.5: 0.24,
                  4.53: 0.22,
                  4.56: 0.15,
                  4.59: 0.07,
                  4.62: 0.03,
                  4.65: 0.02,
                  4.68: 0.03,
                  4.71: 0.06,
                  4.74: 0.09,
                  4.77: 0.12,
                  4.8: 0.14,
                  4.83: 0.17,
                  4.86: 0.19,
                  4.89: 0.19,
                  4.92: 0.16,
                  4.95: 0.1,
                  4.98: 0.05,
                  5.01: 0.02,
                  5.04: 0.02,
                  5.07: 0.04,
                  5.1: 0.07,
                  5.13: 0.08,
                  5.16: 0.07,
                  5.19: 0.04,
                  5.22: 0.03,
                  5.25: 0.03,
                  5.28: 0.06,
                  5.31: 0.08,
                  5.34: 0.07,
                  5.37: 0.05,
                  5.4: 0.02,
                  5.43: 0.01,
                  5.46: 0.0,
                  5.49: 0.0,
                  5.52: 0.0,
                  5.55: 0.02,
                  5.58: 0.04,
                  5.61: 0.07,
                  5.64: 0.08,
                  5.67: 0.07,
                  5.7: 0.04,
                  5.73: 0.02,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.01,
                  5.85: 0.02,
                  5.88: 0.05,
                  5.91: 0.08,
                  5.94: 0.08,
                  5.97: 0.06,
                  '6.0': 0.05,
                  6.03: 0.05,
                  6.06: 0.07,
                  6.09: 0.08,
                  6.12: 0.06,
                  6.15: 0.03,
                  6.18: 0.01,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.0,
                  6.33: 0.0,
                  6.36: 0.0,
                  6.39: 0.0,
                  6.42: 0.0,
                  6.45: 0.0,
                  6.48: 0.01,
                  6.51: 0.02,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.09,
                  6.66: 0.1,
                  6.69: 0.11,
                  6.72: 0.13,
                  6.75: 0.14,
                  6.78: 0.14,
                  6.81: 0.14,
                  6.84: 0.12,
                  6.87: 0.09,
                  6.9: 0.08,
                  6.93: 0.08,
                  6.96: 0.08,
                  6.99: 0.06,
                  7.02: 0.03,
                  7.05: 0.01,
                  7.08: 0.0,
                  7.11: 0.0,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.05,
                  7.23: 0.08,
                  7.26: 0.09,
                  7.29: 0.08,
                  7.32: 0.08,
                  7.35: 0.09,
                  7.38: 0.08,
                  7.41: 0.05,
                  7.44: 0.03,
                  7.47: 0.01,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.0,
                  7.77: 0.0,
                  7.8: 0.0,
                  7.83: 0.0,
                  7.86: 0.0,
                  7.89: 0.0,
                  7.92: 0.02,
                  7.95: 0.04,
                  7.98: 0.07,
                  8.01: 0.08,
                  8.04: 0.08,
                  8.07: 0.07,
                  8.1: 0.08,
                  8.13: 0.08,
                  8.16: 0.07,
                  8.19: 0.04,
                  8.22: 0.02,
                  8.25: 0.01,
                  8.28: 0.0,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.04,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.07,
                  8.55: 0.04,
                  8.58: 0.02,
                  8.61: 0.0,
                  8.64: 0.0,
                  8.67: 0.0,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.0,
                  8.82: 0.0,
                  8.85: 0.0,
                  8.88: 0.0,
                  8.91: 0.0,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.0,
                  9.12: 0.0,
                  9.15: 0.0,
                  9.18: 0.01,
                  9.21: 0.04,
                  9.24: 0.08,
                  9.27: 0.12,
                  9.3: 0.14,
                  9.33: 0.12,
                  9.36: 0.09,
                  9.39: 0.05,
                  9.42: 0.04,
                  9.45: 0.05,
                  9.48: 0.07,
                  9.51: 0.08,
                  9.54: 0.06,
                  9.57: 0.03,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.0,
                  9.96: 0.0,
                  9.99: 0.0,
                  10.02: 0.0,
                  10.05: 0.0,
                  10.08: 0.0,
                  10.11: 0.0,
                  10.14: 0.0,
                  10.17: 0.0,
                  10.2: 0.0,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.01,
                  10.44: 0.03,
                  10.47: 0.07,
                  10.5: 0.11,
                  10.53: 0.13,
                  10.56: 0.14,
                  10.59: 0.13,
                  10.62: 0.12,
                  10.65: 0.1,
                  10.68: 0.08,
                  10.71: 0.05,
                  10.74: 0.04,
                  10.77: 0.05,
                  10.8: 0.07,
                  10.83: 0.08,
                  10.86: 0.06,
                  10.89: 0.03,
                  10.92: 0.01,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.01,
                  11.1: 0.03,
                  11.13: 0.06,
                  11.16: 0.08,
                  11.19: 0.07,
                  11.22: 0.05,
                  11.25: 0.02,
                  11.28: 0.01,
                  11.31: 0.0,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.0,
                  11.61: 0.0,
                  11.64: 0.0,
                  11.67: 0.0,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.0,
                  12.12: 0.0,
                  12.15: 0.0,
                  12.18: 0.0,
                  12.21: 0.0,
                  12.24: 0.0,
                  12.27: 0.0,
                  12.3: 0.0,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.0,
                  12.81: 0.0,
                  12.84: 0.0,
                  12.87: 0.0,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.01,
                  13.62: 0.03,
                  13.65: 0.06,
                  13.68: 0.1,
                  13.71: 0.13,
                  13.74: 0.15,
                  13.77: 0.17,
                  13.8: 0.18,
                  13.83: 0.18,
                  13.86: 0.15,
                  13.89: 0.1,
                  13.92: 0.05,
                  13.95: 0.03,
                  13.98: 0.05,
                  14.01: 0.09,
                  14.04: 0.14,
                  14.07: 0.15,
                  14.1: 0.12,
                  14.13: 0.07,
                  14.16: 0.04,
                  14.19: 0.05,
                  14.22: 0.07,
                  14.25: 0.08,
                  14.28: 0.07,
                  14.31: 0.04,
                  14.34: 0.04,
                  14.37: 0.06,
                  14.4: 0.08,
                  14.43: 0.08,
                  14.46: 0.05,
                  14.49: 0.03,
                  14.52: 0.02,
                  14.55: 0.03,
                  14.58: 0.06,
                  14.61: 0.08,
                  14.64: 0.07,
                  14.67: 0.05,
                  14.7: 0.02,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.01,
                  15.24: 0.02,
                  15.27: 0.04,
                  15.3: 0.07,
                  15.33: 0.08,
                  15.36: 0.06,
                  15.39: 0.04,
                  15.42: 0.01,
                  15.45: 0.0,
                  15.48: 0.0,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.0,
                  15.63: 0.0,
                  15.66: 0.0,
                  15.69: 0.0,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.0,
                  16.2: 0.0,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.01,
                  16.59: 0.03,
                  16.62: 0.06,
                  16.65: 0.08,
                  16.68: 0.07,
                  16.71: 0.04,
                  16.74: 0.02,
                  16.77: 0.01,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.02,
                  16.95: 0.05,
                  16.98: 0.09,
                  17.01: 0.13,
                  17.04: 0.14,
                  17.07: 0.12,
                  17.1: 0.07,
                  17.13: 0.03,
                  17.16: 0.01,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.0,
                  17.37: 0.0,
                  17.4: 0.0,
                  17.43: 0.0,
                  17.46: 0.0,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.01,
                  17.97: 0.02,
                  '18.0': 0.06,
                  18.03: 0.11,
                  18.06: 0.15,
                  18.09: 0.15,
                  18.12: 0.1,
                  18.15: 0.05,
                  18.18: 0.02,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.0,
                  18.57: 0.0,
                  18.6: 0.0,
                  18.63: 0.0,
                  18.66: 0.0,
                  18.69: 0.0,
                  18.72: 0.0,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.01,
                  19.14: 0.04,
                  19.17: 0.06,
                  19.2: 0.08,
                  19.23: 0.07,
                  19.26: 0.04,
                  19.29: 0.02,
                  19.32: 0.01,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.01,
                  20.13: 0.02,
                  20.16: 0.05,
                  20.19: 0.07,
                  20.22: 0.08,
                  20.25: 0.06,
                  20.28: 0.03,
                  20.31: 0.01,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.01,
                  21.96: 0.04,
                  21.99: 0.06,
                  22.02: 0.08,
                  22.05: 0.07,
                  22.08: 0.04,
                  22.11: 0.02,
                  22.14: 0.01,
                  22.17: 0.03,
                  22.2: 0.05,
                  22.23: 0.08,
                  22.26: 0.08,
                  22.29: 0.05,
                  22.32: 0.03,
                  22.35: 0.01,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.01,
                  22.74: 0.03,
                  22.77: 0.06,
                  22.8: 0.08,
                  22.83: 0.07,
                  22.86: 0.05,
                  22.89: 0.02,
                  22.92: 0.01,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.0,
                  24.09: 0.0,
                  24.12: 0.0,
                  24.15: 0.0,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.01,
                  24.54: 0.02,
                  24.57: 0.04,
                  24.6: 0.07,
                  24.63: 0.08,
                  24.66: 0.07,
                  24.69: 0.05,
                  24.72: 0.05,
                  24.75: 0.07,
                  24.78: 0.08,
                  24.81: 0.07,
                  24.84: 0.04,
                  24.87: 0.01,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.01,
                  25.29: 0.03,
                  25.32: 0.06,
                  25.35: 0.08,
                  25.38: 0.07,
                  25.41: 0.05,
                  25.44: 0.02,
                  25.47: 0.01,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.01,
                  26.37: 0.03,
                  26.4: 0.06,
                  26.43: 0.08,
                  26.46: 0.07,
                  26.49: 0.04,
                  26.52: 0.02,
                  26.55: 0.01,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.02,
                  27.63: 0.04,
                  27.66: 0.07,
                  27.69: 0.08,
                  27.72: 0.06,
                  27.75: 0.04,
                  27.78: 0.01,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.01,
                  30.75: 0.04,
                  30.78: 0.06,
                  30.81: 0.08,
                  30.84: 0.07,
                  30.87: 0.04,
                  30.9: 0.02,
                  30.93: 0.01,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.01,
                  36.3: 0.03,
                  36.33: 0.06,
                  36.36: 0.08,
                  36.39: 0.07,
                  36.42: 0.05,
                  36.45: 0.02,
                  36.48: 0.01,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.0,
                  40.74: 0.0,
                  40.77: 0.0,
                  40.8: 0.0,
                  40.83: 0.0,
                  40.86: 0.0,
                  40.89: 0.0,
                  40.92: 0.0,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.0,
                  41.58: 0.0,
                  41.61: 0.0,
                  41.64: 0.0,
                  41.67: 0.0,
                  41.7: 0.0,
                  41.73: 0.0,
                  41.76: 0.0,
                  41.79: 0.0,
                  41.82: 0.0,
                  41.85: 0.0,
                  41.88: 0.0,
                  41.91: 0.0,
                  41.94: 0.0,
                  41.97: 0.0,
                  '42.0': 0.0,
                  42.03: 0.0,
                  42.06: 0.0,
                  42.09: 0.0,
                  42.12: 0.0,
                  42.15: 0.0,
                  42.18: 0.0,
                  42.21: 0.0,
                  42.24: 0.0,
                  42.27: 0.0,
                  42.3: 0.0,
                  42.33: 0.0,
                  42.36: 0.0,
                  42.39: 0.0,
                  42.42: 0.0,
                  42.45: 0.0,
                  42.48: 0.0,
                  42.51: 0.0,
                  42.54: 0.0,
                  42.57: 0.0,
                  42.6: 0.0,
                  42.63: 0.0,
                  42.66: 0.0,
                  42.69: 0.0,
                  42.72: 0.0,
                  42.75: 0.0,
                  42.78: 0.0,
                  42.81: 0.0,
                  42.84: 0.0,
                  42.87: 0.0,
                  42.9: 0.0,
                  42.93: 0.0,
                  42.96: 0.0,
                  42.99: 0.0,
                  43.02: 0.0,
                  43.05: 0.0,
                  43.08: 0.0,
                  43.11: 0.0,
                  43.14: 0.0,
                  43.17: 0.0,
                  43.2: 0.0,
                  43.23: 0.0,
                  43.26: 0.0,
                  43.29: 0.0,
                  43.32: 0.0,
                  43.35: 0.0,
                  43.38: 0.0,
                  43.41: 0.0,
                  43.44: 0.0,
                  43.47: 0.0,
                  43.5: 0.0,
                  43.53: 0.0,
                  43.56: 0.0,
                  43.59: 0.0,
                  43.62: 0.0,
                  43.65: 0.0,
                  43.68: 0.0,
                  43.71: 0.0,
                  43.74: 0.0,
                  43.77: 0.0,
                  43.8: 0.0,
                  43.83: 0.0,
                  43.86: 0.0,
                  43.89: 0.0,
                  43.92: 0.0,
                  43.95: 0.0,
                  43.98: 0.0,
                  44.01: 0.0,
                  44.04: 0.0,
                  44.07: 0.0,
                  44.1: 0.0,
                  44.13: 0.0,
                  44.16: 0.0,
                  44.19: 0.0,
                  44.22: 0.0,
                  44.25: 0.0,
                  44.28: 0.0,
                  44.31: 0.0,
                  44.34: 0.0,
                  44.37: 0.0,
                  44.4: 0.0,
                  44.43: 0.0,
                  44.46: 0.0,
                  44.49: 0.0,
                  44.52: 0.0,
                  44.55: 0.0,
                  44.58: 0.0,
                  44.61: 0.0,
                  44.64: 0.0,
                  44.67: 0.0,
                  44.7: 0.0,
                  44.73: 0.0,
                  44.76: 0.0,
                  44.79: 0.0,
                  44.82: 0.0,
                  44.85: 0.0,
                  44.88: 0.0,
                  44.91: 0.0,
                  44.94: 0.0,
                  44.97: 0.0,
                  '45.0': 0.0,
                  45.03: 0.0,
                  45.06: 0.0,
                  45.09: 0.0,
                  45.12: 0.0,
                  45.15: 0.0,
                  45.18: 0.0,
                  45.21: 0.0,
                  45.24: 0.0,
                  45.27: 0.0,
                  45.3: 0.0,
                  45.33: 0.0,
                  45.36: 0.0,
                  45.39: 0.0,
                  45.42: 0.0,
                  45.45: 0.0,
                  45.48: 0.0,
                  45.51: 0.0,
                  45.54: 0.0,
                  45.57: 0.0,
                  45.6: 0.0,
                  45.63: 0.0,
                  45.66: 0.0,
                  45.69: 0.0,
                  45.72: 0.0,
                  45.75: 0.0,
                  45.78: 0.0,
                  45.81: 0.0,
                  45.84: 0.0,
                  45.87: 0.0,
                  45.9: 0.0,
                  45.93: 0.0,
                  45.96: 0.0,
                  45.99: 0.0,
                  46.02: 0.0,
                  46.05: 0.0,
                  46.08: 0.0,
                  46.11: 0.0,
                  46.14: 0.0,
                  46.17: 0.0,
                  46.2: 0.0,
                  46.23: 0.0,
                  46.26: 0.0,
                  46.29: 0.0,
                  46.32: 0.0,
                  46.35: 0.0,
                  46.38: 0.0,
                  46.41: 0.0,
                  46.44: 0.0,
                  46.47: 0.0,
                  46.5: 0.0,
                  46.53: 0.0,
                  46.56: 0.0,
                  46.59: 0.0,
                  46.62: 0.0,
                  46.65: 0.0,
                  46.68: 0.0,
                  46.71: 0.0,
                  46.74: 0.0,
                  46.77: 0.0,
                  46.8: 0.0,
                  46.83: 0.0,
                  46.86: 0.0,
                  46.89: 0.0,
                  46.92: 0.0,
                  46.95: 0.0,
                  46.98: 0.0,
                  47.01: 0.0,
                  47.04: 0.0,
                  47.07: 0.0,
                  47.1: 0.0,
                  47.13: 0.0,
                  47.16: 0.0,
                  47.19: 0.0,
                  47.22: 0.0,
                  47.25: 0.0,
                  47.28: 0.0,
                  47.31: 0.0,
                  47.34: 0.0,
                  47.37: 0.0,
                  47.4: 0.0,
                  47.43: 0.0,
                  47.46: 0.0,
                  47.49: 0.0,
                  47.52: 0.0,
                  47.55: 0.0,
                  47.58: 0.0,
                  47.61: 0.0,
                  47.64: 0.0,
                  47.67: 0.0,
                  47.7: 0.0,
                  47.73: 0.0,
                  47.76: 0.0,
                  47.79: 0.0,
                  47.82: 0.0,
                  47.85: 0.0,
                  47.88: 0.0,
                  47.91: 0.0,
                  47.94: 0.0,
                  47.97: 0.0,
                  '48.0': 0.0,
                  48.03: 0.0,
                  48.06: 0.0,
                  48.09: 0.0,
                  48.12: 0.0,
                  48.15: 0.0,
                  48.18: 0.0,
                  48.21: 0.0,
                  48.24: 0.0,
                  48.27: 0.0,
                  48.3: 0.0,
                  48.33: 0.0,
                  48.36: 0.0,
                  48.39: 0.0,
                  48.42: 0.0,
                  48.45: 0.0,
                  48.48: 0.0,
                  48.51: 0.0,
                  48.54: 0.0,
                  48.57: 0.0,
                  48.6: 0.0,
                  48.63: 0.0,
                  48.66: 0.0,
                  48.69: 0.0,
                  48.72: 0.0,
                  48.75: 0.0,
                  48.78: 0.0,
                  48.81: 0.0,
                  48.84: 0.0,
                  48.87: 0.0,
                  48.9: 0.0,
                  48.93: 0.0,
                  48.96: 0.0,
                  48.99: 0.0,
                  49.02: 0.0,
                  49.05: 0.0,
                  49.08: 0.0,
                  49.11: 0.0,
                  49.14: 0.0,
                  49.17: 0.0,
                  49.2: 0.0,
                  49.23: 0.0,
                  49.26: 0.0,
                  49.29: 0.0,
                  49.32: 0.0,
                  49.35: 0.0,
                  49.38: 0.0,
                  49.41: 0.0,
                  49.44: 0.0,
                  49.47: 0.0,
                  49.5: 0.0,
                  49.53: 0.0,
                  49.56: 0.0,
                  49.59: 0.0,
                  49.62: 0.0,
                  49.65: 0.0,
                  49.68: 0.0,
                  49.71: 0.0,
                  49.74: 0.0,
                  49.77: 0.0,
                  49.8: 0.0,
                  49.83: 0.0,
                  49.86: 0.0,
                  49.89: 0.0,
                  49.92: 0.0,
                  49.95: 0.0,
                  49.98: 0.0,
                  50.01: 0.0,
                  50.04: 0.0,
                  50.07: 0.0,
                  50.1: 0.0,
                  50.13: 0.0,
                  50.16: 0.0,
                  50.19: 0.0,
                  50.22: 0.01,
                  50.25: 0.03,
                  50.28: 0.06,
                  50.31: 0.08,
                  50.34: 0.07,
                  50.37: 0.05,
                  50.4: 0.02,
                  50.43: 0.01,
                  50.46: 0.0,
                  50.49: 0.0,
                  50.52: 0.0,
                  50.55: 0.0,
                  50.58: 0.0,
                  50.61: 0.0,
                  50.64: 0.0,
                  50.67: 0.0,
                  50.7: 0.0,
                  50.73: 0.0,
                  50.76: 0.0,
                  50.79: 0.0,
                  50.82: 0.0,
                  50.85: 0.0,
                  50.88: 0.0,
                  50.91: 0.0,
                  50.94: 0.0,
                  50.97: 0.0,
                  '51.0': 0.0,
                  51.03: 0.0,
                  51.06: 0.0,
                  51.09: 0.0,
                  51.12: 0.0,
                  51.15: 0.0,
                  51.18: 0.0,
                  51.21: 0.0,
                  51.24: 0.0,
                  51.27: 0.0,
                  51.3: 0.0,
                  51.33: 0.0,
                  51.36: 0.0,
                  51.39: 0.0,
                  51.42: 0.0,
                  51.45: 0.0,
                  51.48: 0.0,
                  51.51: 0.0,
                  51.54: 0.0,
                  51.57: 0.0,
                  51.6: 0.0,
                  51.63: 0.0,
                  51.66: 0.0,
                  51.69: 0.0,
                  51.72: 0.0,
                  51.75: 0.0,
                  51.78: 0.0,
                  51.81: 0.0,
                  51.84: 0.0,
                  51.87: 0.0,
                  51.9: 0.0,
                  51.93: 0.0,
                  51.96: 0.0,
                  51.99: 0.0,
                  52.02: 0.0,
                  52.05: 0.0,
                  52.08: 0.0,
                  52.11: 0.0,
                  52.14: 0.0,
                  52.17: 0.0,
                  52.2: 0.0,
                  52.23: 0.0,
                  52.26: 0.0,
                  52.29: 0.0,
                  52.32: 0.0,
                  52.35: 0.0,
                  52.38: 0.0,
                  52.41: 0.0,
                  52.44: 0.0,
                  52.47: 0.0,
                  52.5: 0.0,
                  52.53: 0.0,
                  52.56: 0.0,
                  52.59: 0.0,
                  52.62: 0.0,
                  52.65: 0.0,
                  52.68: 0.0,
                  52.71: 0.0,
                  52.74: 0.0,
                  52.77: 0.0,
                  52.8: 0.0,
                  52.83: 0.0,
                  52.86: 0.0,
                  52.89: 0.0,
                  52.92: 0.0,
                  52.95: 0.0,
                  52.98: 0.0,
                  53.01: 0.0,
                  53.04: 0.0,
                  53.07: 0.0,
                  53.1: 0.0,
                  53.13: 0.0,
                  53.16: 0.0,
                  53.19: 0.0,
                  53.22: 0.0,
                  53.25: 0.0,
                  53.28: 0.0,
                  53.31: 0.0,
                  53.34: 0.0,
                  53.37: 0.0,
                  53.4: 0.0,
                  53.43: 0.0,
                  53.46: 0.0,
                  53.49: 0.0,
                  53.52: 0.0,
                  53.55: 0.0,
                  53.58: 0.0,
                  53.61: 0.0,
                  53.64: 0.0,
                  53.67: 0.0,
                  53.7: 0.0,
                  53.73: 0.0,
                  53.76: 0.0,
                  53.79: 0.0,
                  53.82: 0.0,
                  53.85: 0.0,
                  53.88: 0.0,
                  53.91: 0.0,
                  53.94: 0.0,
                  53.97: 0.0,
                  '54.0': 0.0,
                  54.03: 0.0,
                  54.06: 0.0,
                  54.09: 0.0,
                  54.12: 0.0,
                  54.15: 0.0,
                  54.18: 0.0,
                  54.21: 0.0,
                  54.24: 0.0,
                  54.27: 0.0,
                  54.3: 0.0,
                  54.33: 0.0,
                  54.36: 0.0,
                  54.39: 0.0,
                  54.42: 0.0,
                  54.45: 0.0,
                  54.48: 0.0,
                  54.51: 0.0,
                  54.54: 0.0,
                  54.57: 0.0,
                  54.6: 0.0,
                  54.63: 0.0,
                  54.66: 0.0,
                  54.69: 0.0,
                  54.72: 0.0,
                  54.75: 0.0,
                  54.78: 0.0,
                  54.81: 0.0,
                  54.84: 0.0,
                  54.87: 0.0,
                  54.9: 0.0,
                  54.93: 0.0,
                  54.96: 0.0,
                  54.99: 0.0,
                  55.02: 0.0,
                  55.05: 0.0,
                  55.08: 0.0,
                  55.11: 0.0,
                  55.14: 0.0,
                  55.17: 0.0,
                  55.2: 0.0,
                  55.23: 0.0,
                  55.26: 0.0,
                  55.29: 0.0,
                  55.32: 0.0,
                  55.35: 0.0,
                  55.38: 0.0,
                  55.41: 0.0,
                  55.44: 0.0,
                  55.47: 0.0,
                  55.5: 0.0,
                  55.53: 0.0,
                  55.56: 0.0,
                  55.59: 0.0,
                  55.62: 0.0,
                  55.65: 0.0,
                  55.68: 0.0,
                  55.71: 0.0,
                  55.74: 0.0,
                  55.77: 0.0,
                  55.8: 0.0,
                  55.83: 0.0,
                  55.86: 0.0,
                  55.89: 0.0,
                  55.92: 0.0,
                  55.95: 0.0,
                  55.98: 0.0,
                  56.01: 0.0,
                  56.04: 0.0,
                  56.07: 0.0,
                  56.1: 0.0,
                  56.13: 0.0,
                  56.16: 0.0,
                  56.19: 0.0,
                  56.22: 0.0,
                  56.25: 0.0,
                  56.28: 0.0,
                  56.31: 0.0,
                  56.34: 0.0,
                  56.37: 0.0,
                  56.4: 0.0,
                  56.43: 0.0,
                  56.46: 0.0,
                  56.49: 0.0,
                  56.52: 0.0,
                  56.55: 0.0,
                  56.58: 0.0,
                  56.61: 0.0,
                  56.64: 0.0,
                  56.67: 0.0,
                  56.7: 0.0,
                  56.73: 0.0,
                  56.76: 0.0,
                  56.79: 0.0,
                  56.82: 0.0,
                  56.85: 0.0,
                  56.88: 0.0,
                  56.91: 0.0,
                  56.94: 0.0,
                  56.97: 0.0,
                  '57.0': 0.0,
                  57.03: 0.0,
                  57.06: 0.0,
                  57.09: 0.0,
                  57.12: 0.0,
                  57.15: 0.0,
                  57.18: 0.0,
                  57.21: 0.0,
                  57.24: 0.0,
                  57.27: 0.0,
                  57.3: 0.0,
                  57.33: 0.0,
                  57.36: 0.0,
                  57.39: 0.0,
                  57.42: 0.0,
                  57.45: 0.0,
                  57.48: 0.0,
                  57.51: 0.0,
                  57.54: 0.0,
                  57.57: 0.0,
                  57.6: 0.0,
                  57.63: 0.0,
                  57.66: 0.0,
                  57.69: 0.0,
                  57.72: 0.0,
                  57.75: 0.0,
                  57.78: 0.0,
                  57.81: 0.0,
                  57.84: 0.0,
                  57.87: 0.0,
                  57.9: 0.0,
                  57.93: 0.0,
                  57.96: 0.0,
                  57.99: 0.0,
                  58.02: 0.0,
                  58.05: 0.0,
                  58.08: 0.0,
                  58.11: 0.0,
                  58.14: 0.0,
                  58.17: 0.0,
                  58.2: 0.0,
                  58.23: 0.0,
                  58.26: 0.0,
                  58.29: 0.0,
                  58.32: 0.0,
                  58.35: 0.0,
                  58.38: 0.0,
                  58.41: 0.0,
                  58.44: 0.0,
                  58.47: 0.0,
                  58.5: 0.0,
                  58.53: 0.0,
                  58.56: 0.0,
                  58.59: 0.0,
                  58.62: 0.0,
                  58.65: 0.0,
                  58.68: 0.0,
                  58.71: 0.0,
                  58.74: 0.0,
                  58.77: 0.0,
                  58.8: 0.0,
                  58.83: 0.0,
                  58.86: 0.0,
                  58.89: 0.0,
                  58.92: 0.0,
                  58.95: 0.0,
                  58.98: 0.0,
                  59.01: 0.0,
                  59.04: 0.0,
                  59.07: 0.0,
                  59.1: 0.0,
                  59.13: 0.0,
                  59.16: 0.0,
                  59.19: 0.01,
                  59.22: 0.04,
                  59.25: 0.07,
                  59.28: 0.08,
                  59.31: 0.07,
                  59.34: 0.04,
                  59.37: 0.02,
                  59.4: 0.0,
                  59.43: 0.0,
                  59.46: 0.0,
                  59.49: 0.0,
                  59.52: 0.0,
                  59.55: 0.0,
                  59.58: 0.0,
                  59.61: 0.0,
                  59.64: 0.0,
                  59.67: 0.0,
                  59.7: 0.0,
                  59.73: 0.0,
                  59.76: 0.0,
                  59.79: 0.0,
                  59.82: 0.0,
                  59.85: 0.0,
                  59.88: 0.0,
                  59.91: 0.0,
                  59.94: 0.0,
                  59.97: 0.0,
                  '60.0': 0.0,
                  60.03: 0.0,
                  60.06: 0.0,
                  60.09: 0.0,
                  60.12: 0.0,
                  60.15: 0.0,
                  60.18: 0.0,
                  60.21: 0.0,
                  60.24: 0.0,
                  60.27: 0.0,
                  60.3: 0.0,
                  60.33: 0.0,
                  60.36: 0.0,
                  60.39: 0.0,
                  60.42: 0.0,
                  60.45: 0.0,
                  60.48: 0.0,
                  60.51: 0.0,
                  60.54: 0.0,
                  60.57: 0.0,
                  60.6: 0.0,
                  60.63: 0.0,
                  60.66: 0.0,
                  60.69: 0.0,
                  60.72: 0.0,
                  60.75: 0.0,
                  60.78: 0.0,
                  60.81: 0.0,
                  60.84: 0.0,
                  60.87: 0.0,
                  60.9: 0.0,
                  60.93: 0.0,
                  60.96: 0.0,
                  60.99: 0.0,
                  61.02: 0.0,
                  61.05: 0.0,
                  61.08: 0.0,
                  61.11: 0.0,
                  61.14: 0.0,
                  61.17: 0.0,
                  61.2: 0.0,
                  61.23: 0.0,
                  61.26: 0.0,
                  61.29: 0.0,
                  61.32: 0.0,
                  61.35: 0.0,
                  61.38: 0.0,
                  61.41: 0.0,
                  61.44: 0.0,
                  61.47: 0.0,
                  61.5: 0.0,
                  61.53: 0.0,
                  61.56: 0.0,
                  61.59: 0.0,
                  61.62: 0.0,
                  61.65: 0.0,
                  61.68: 0.0,
                  61.71: 0.0,
                  61.74: 0.0,
                  61.77: 0.0,
                  61.8: 0.0,
                  61.83: 0.0,
                  61.86: 0.0,
                  61.89: 0.0,
                  61.92: 0.0,
                  61.95: 0.0,
                  61.98: 0.0,
                  62.01: 0.0,
                  62.04: 0.0,
                  62.07: 0.0,
                  62.1: 0.0,
                  62.13: 0.0,
                  62.16: 0.0,
                  62.19: 0.0,
                  62.22: 0.0,
                  62.25: 0.0,
                  62.28: 0.0,
                  62.31: 0.0,
                  62.34: 0.0,
                  62.37: 0.0,
                  62.4: 0.0,
                  62.43: 0.0,
                  62.46: 0.0,
                  62.49: 0.0,
                  62.52: 0.0,
                  62.55: 0.0,
                  62.58: 0.0,
                  62.61: 0.0,
                  62.64: 0.0,
                  62.67: 0.0,
                  62.7: 0.0,
                  62.73: 0.0,
                  62.76: 0.0,
                  62.79: 0.0,
                  62.82: 0.0,
                  62.85: 0.0,
                  62.88: 0.0,
                  62.91: 0.0,
                  62.94: 0.0,
                  62.97: 0.0,
                  '63.0': 0.0,
                  63.03: 0.0,
                  63.06: 0.0,
                  63.09: 0.0,
                  63.12: 0.0,
                  63.15: 0.0,
                  63.18: 0.0,
                  63.21: 0.0,
                  63.24: 0.0,
                  63.27: 0.0,
                  63.3: 0.0,
                  63.33: 0.0,
                  63.36: 0.0,
                  63.39: 0.0,
                  63.42: 0.0,
                  63.45: 0.0,
                  63.48: 0.0,
                  63.51: 0.0,
                  63.54: 0.0,
                  63.57: 0.0,
                  63.6: 0.0,
                  63.63: 0.0,
                  63.66: 0.0,
                  63.69: 0.0,
                  63.72: 0.0,
                  63.75: 0.0,
                  63.78: 0.0,
                  63.81: 0.0,
                  63.84: 0.0,
                  63.87: 0.0,
                  63.9: 0.0,
                  63.93: 0.0,
                  63.96: 0.0,
                  63.99: 0.0,
                  64.02: 0.0,
                  64.05: 0.0,
                  64.08: 0.0,
                  64.11: 0.0,
                  64.14: 0.0,
                  64.17: 0.0,
                  64.2: 0.0,
                  64.23: 0.0,
                  64.26: 0.0,
                  64.29: 0.0,
                  64.32: 0.0,
                  64.35: 0.0,
                  64.38: 0.0,
                  64.41: 0.0,
                  64.44: 0.0,
                  64.47: 0.0,
                  64.5: 0.0,
                  64.53: 0.0,
                  64.56: 0.0,
                  64.59: 0.0,
                  64.62: 0.0,
                  64.65: 0.0,
                  64.68: 0.0,
                  64.71: 0.0,
                  64.74: 0.0,
                  64.77: 0.0,
                  64.8: 0.0,
                  64.83: 0.0,
                  64.86: 0.0,
                  64.89: 0.0,
                  64.92: 0.0,
                  64.95: 0.0,
                  64.98: 0.0,
                  65.01: 0.0,
                  65.04: 0.0,
                  65.07: 0.0,
                  65.1: 0.0,
                  65.13: 0.0,
                  65.16: 0.0,
                  65.19: 0.0,
                  65.22: 0.0,
                  65.25: 0.0,
                  65.28: 0.0,
                  65.31: 0.0,
                  65.34: 0.0,
                  65.37: 0.0,
                  65.4: 0.0,
                  65.43: 0.0,
                  65.46: 0.0,
                  65.49: 0.0,
                  65.52: 0.0,
                  65.55: 0.0,
                  65.58: 0.0,
                  65.61: 0.0,
                  65.64: 0.0,
                  65.67: 0.0,
                  65.7: 0.0,
                  65.73: 0.0,
                  65.76: 0.0,
                  65.79: 0.0,
                  65.82: 0.0,
                  65.85: 0.0,
                  65.88: 0.0,
                  65.91: 0.0,
                  65.94: 0.0,
                  65.97: 0.0,
                  '66.0': 0.0,
                  66.03: 0.0,
                  66.06: 0.0,
                  66.09: 0.0,
                  66.12: 0.0,
                  66.15: 0.0,
                  66.18: 0.0,
                  66.21: 0.0,
                  66.24: 0.0,
                  66.27: 0.0,
                  66.3: 0.0,
                  66.33: 0.0,
                  66.36: 0.0,
                  66.39: 0.0,
                  66.42: 0.0,
                  66.45: 0.0,
                  66.48: 0.0,
                  66.51: 0.0,
                  66.54: 0.0,
                  66.57: 0.0,
                  66.6: 0.0,
                  66.63: 0.0,
                  66.66: 0.0,
                  66.69: 0.0,
                  66.72: 0.0,
                  66.75: 0.0,
                  66.78: 0.0,
                  66.81: 0.0,
                  66.84: 0.0,
                  66.87: 0.0,
                  66.9: 0.0,
                  66.93: 0.0,
                  66.96: 0.0,
                  66.99: 0.0,
                  67.02: 0.0,
                  67.05: 0.0,
                  67.08: 0.0,
                  67.11: 0.0,
                  67.14: 0.0,
                  67.17: 0.0,
                  67.2: 0.0,
                  67.23: 0.0,
                  67.26: 0.0,
                  67.29: 0.0,
                  67.32: 0.0,
                  67.35: 0.0,
                  67.38: 0.0,
                  67.41: 0.0,
                  67.44: 0.0,
                  67.47: 0.0,
                  67.5: 0.0,
                  67.53: 0.0,
                  67.56: 0.0,
                  67.59: 0.0,
                  67.62: 0.0,
                  67.65: 0.0,
                  67.68: 0.0,
                  67.71: 0.0,
                  67.74: 0.0,
                  67.77: 0.0,
                  67.8: 0.0,
                  67.83: 0.0,
                  67.86: 0.0,
                  67.89: 0.0,
                  67.92: 0.0,
                  67.95: 0.0,
                  67.98: 0.0,
                  68.01: 0.0,
                  68.04: 0.0,
                  68.07: 0.0,
                  68.1: 0.0,
                  68.13: 0.0,
                  68.16: 0.0,
                  68.19: 0.0,
                  68.22: 0.0,
                  68.25: 0.0,
                  68.28: 0.0,
                  68.31: 0.0,
                  68.34: 0.0,
                  68.37: 0.0,
                  68.4: 0.0,
                  68.43: 0.0,
                  68.46: 0.0,
                  68.49: 0.0,
                  68.52: 0.0,
                  68.55: 0.0,
                  68.58: 0.0,
                  68.61: 0.0,
                  68.64: 0.0,
                  68.67: 0.0,
                  68.7: 0.0,
                  68.73: 0.0,
                  68.76: 0.0,
                  68.79: 0.0,
                  68.82: 0.0,
                  68.85: 0.0,
                  68.88: 0.0,
                  68.91: 0.0,
                  68.94: 0.0,
                  68.97: 0.0,
                  '69.0': 0.0,
                  69.03: 0.0,
                  69.06: 0.0,
                  69.09: 0.0,
                  69.12: 0.0,
                  69.15: 0.0,
                  69.18: 0.0,
                  69.21: 0.0,
                  69.24: 0.0,
                  69.27: 0.0,
                  69.3: 0.0,
                  69.33: 0.0,
                  69.36: 0.0,
                  69.39: 0.0,
                  69.42: 0.0,
                  69.45: 0.0,
                  69.48: 0.0,
                  69.51: 0.0,
                  69.54: 0.0,
                  69.57: 0.0,
                  69.6: 0.0,
                  69.63: 0.0,
                  69.66: 0.0,
                  69.69: 0.0,
                  69.72: 0.0,
                  69.75: 0.0,
                  69.78: 0.0,
                  69.81: 0.0,
                  69.84: 0.0,
                  69.87: 0.0,
                  69.9: 0.0,
                  69.93: 0.0,
                  69.96: 0.0,
                  69.99: 0.0,
                  70.02: 0.0,
                  70.05: 0.0,
                  70.08: 0.0,
                  70.11: 0.0,
                  70.14: 0.0,
                  70.17: 0.0,
                  70.2: 0.0,
                  70.23: 0.0,
                  70.26: 0.0,
                  70.29: 0.0,
                  70.32: 0.0,
                  70.35: 0.0,
                  70.38: 0.0,
                  70.41: 0.0,
                  70.44: 0.0,
                  70.47: 0.0,
                  70.5: 0.0,
                  70.53: 0.0,
                  70.56: 0.0,
                  70.59: 0.0,
                  70.62: 0.0,
                  70.65: 0.0,
                  70.68: 0.0,
                  70.71: 0.0,
                  70.74: 0.0,
                  70.77: 0.0,
                  70.8: 0.0,
                  70.83: 0.0,
                  70.86: 0.0,
                  70.89: 0.0,
                  70.92: 0.0,
                  70.95: 0.0,
                  70.98: 0.0,
                  71.01: 0.0,
                  71.04: 0.0,
                  71.07: 0.0,
                  71.1: 0.0,
                  71.13: 0.0,
                  71.16: 0.0,
                  71.19: 0.0,
                  71.22: 0.0,
                  71.25: 0.0,
                  71.28: 0.0,
                  71.31: 0.0,
                  71.34: 0.0,
                  71.37: 0.0,
                  71.4: 0.0,
                  71.43: 0.0,
                  71.46: 0.0,
                  71.49: 0.0,
                  71.52: 0.0,
                  71.55: 0.0,
                  71.58: 0.0,
                  71.61: 0.0,
                  71.64: 0.0,
                  71.67: 0.0,
                  71.7: 0.0,
                  71.73: 0.0,
                  71.76: 0.0,
                  71.79: 0.0,
                  71.82: 0.0,
                  71.85: 0.0,
                  71.88: 0.0,
                  71.91: 0.0,
                  71.94: 0.0,
                  71.97: 0.0,
                  '72.0': 0.0,
                  72.03: 0.0,
                  72.06: 0.0,
                  72.09: 0.0,
                  72.12: 0.0,
                  72.15: 0.0,
                  72.18: 0.0,
                  72.21: 0.0,
                  72.24: 0.0,
                  72.27: 0.0,
                  72.3: 0.0,
                  72.33: 0.0,
                  72.36: 0.0,
                  72.39: 0.0,
                  72.42: 0.0,
                  72.45: 0.0,
                  72.48: 0.0,
                  72.51: 0.0,
                  72.54: 0.0,
                  72.57: 0.0,
                  72.6: 0.0,
                  72.63: 0.0,
                  72.66: 0.0,
                  72.69: 0.0,
                  72.72: 0.0,
                  72.75: 0.0,
                  72.78: 0.0,
                  72.81: 0.0,
                  72.84: 0.0,
                  72.87: 0.0,
                  72.9: 0.0,
                  72.93: 0.0,
                  72.96: 0.0,
                  72.99: 0.0,
                  73.02: 0.0,
                  73.05: 0.0,
                  73.08: 0.01,
                  73.11: 0.02,
                  73.14: 0.05,
                  73.17: 0.07,
                  73.2: 0.08,
                  73.23: 0.06,
                  73.26: 0.03,
                  73.29: 0.01,
                  73.32: 0.0,
                  73.35: 0.0,
                  73.38: 0.0,
                  73.41: 0.0,
                  73.44: 0.0,
                  73.47: 0.0,
                  73.5: 0.0,
                  73.53: 0.0,
                  73.56: 0.0,
                  73.59: 0.0,
                  73.62: 0.0,
                  73.65: 0.0,
                  73.68: 0.0,
                  73.71: 0.0,
                  73.74: 0.0,
                  73.77: 0.0,
                  73.8: 0.0,
                  73.83: 0.0,
                  73.86: 0.0,
                  73.89: 0.0,
                  73.92: 0.0,
                  73.95: 0.0,
                  73.98: 0.0,
                },
                iteration_1: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.12,
                  0.18: 0.1,
                  0.21: 0.1,
                  0.24: 0.11,
                  0.27: 0.13,
                  0.3: 0.15,
                  0.33: 0.17,
                  0.36: 0.18,
                  0.39: 0.18,
                  0.42: 0.19,
                  0.45: 0.22,
                  0.48: 0.25,
                  0.51: 0.25,
                  0.54: 0.22,
                  0.57: 0.18,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.2,
                  1.14: 0.17,
                  1.17: 0.13,
                  1.2: 0.1,
                  1.23: 0.09,
                  1.26: 0.13,
                  1.29: 0.17,
                  1.32: 0.19,
                  1.35: 0.17,
                  1.38: 0.13,
                  1.41: 0.08,
                  1.44: 0.05,
                  1.47: 0.03,
                  1.5: 0.04,
                  1.53: 0.06,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.05,
                  1.83: 0.06,
                  1.86: 0.08,
                  1.89: 0.09,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.1,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.15,
                  2.28: 0.11,
                  2.31: 0.06,
                  2.34: 0.02,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.07,
                  2.49: 0.08,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.18,
                  2.73: 0.17,
                  2.76: 0.16,
                  2.79: 0.16,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.04,
                  3.15: 0.07,
                  3.18: 0.08,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.0,
                  3.36: 0.0,
                  3.39: 0.0,
                  3.42: 0.0,
                  3.45: 0.0,
                  3.48: 0.0,
                  3.51: 0.0,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.0,
                  3.87: 0.0,
                  3.9: 0.0,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.08,
                  4.11: 0.1,
                  4.14: 0.1,
                  4.17: 0.1,
                  4.2: 0.09,
                  4.23: 0.08,
                  4.26: 0.07,
                  4.29: 0.09,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.05,
                  4.47: 0.02,
                  4.5: 0.01,
                  4.53: 0.0,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.04,
                  4.65: 0.06,
                  4.68: 0.08,
                  4.71: 0.07,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.17,
                  4.95: 0.13,
                  4.98: 0.07,
                  5.01: 0.03,
                  5.04: 0.01,
                  5.07: 0.01,
                  5.1: 0.03,
                  5.13: 0.06,
                  5.16: 0.08,
                  5.19: 0.07,
                  5.22: 0.05,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.12,
                  5.4: 0.16,
                  5.43: 0.15,
                  5.46: 0.11,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.12,
                  5.7: 0.15,
                  5.73: 0.14,
                  5.76: 0.09,
                  5.79: 0.05,
                  5.82: 0.03,
                  5.85: 0.05,
                  5.88: 0.07,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.04,
                  6.21: 0.07,
                  6.24: 0.1,
                  6.27: 0.12,
                  6.3: 0.12,
                  6.33: 0.1,
                  6.36: 0.09,
                  6.39: 0.08,
                  6.42: 0.09,
                  6.45: 0.08,
                  6.48: 0.05,
                  6.51: 0.03,
                  6.54: 0.02,
                  6.57: 0.04,
                  6.6: 0.08,
                  6.63: 0.14,
                  6.66: 0.16,
                  6.69: 0.13,
                  6.72: 0.07,
                  6.75: 0.03,
                  6.78: 0.01,
                  6.81: 0.0,
                  6.84: 0.0,
                  6.87: 0.0,
                  6.9: 0.01,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.08,
                  7.02: 0.07,
                  7.05: 0.05,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.04,
                  7.23: 0.07,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.07,
                  7.89: 0.04,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.08,
                  8.22: 0.07,
                  8.25: 0.04,
                  8.28: 0.02,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.03,
                  8.46: 0.07,
                  8.49: 0.11,
                  8.52: 0.13,
                  8.55: 0.13,
                  8.58: 0.1,
                  8.61: 0.06,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.07,
                  8.91: 0.08,
                  8.94: 0.06,
                  8.97: 0.03,
                  '9.0': 0.02,
                  9.03: 0.03,
                  9.06: 0.05,
                  9.09: 0.07,
                  9.12: 0.08,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.02,
                  9.24: 0.03,
                  9.27: 0.05,
                  9.3: 0.08,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.06,
                  9.42: 0.07,
                  9.45: 0.08,
                  9.48: 0.07,
                  9.51: 0.05,
                  9.54: 0.02,
                  9.57: 0.01,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.07,
                  10.02: 0.12,
                  10.05: 0.14,
                  10.08: 0.14,
                  10.11: 0.12,
                  10.14: 0.1,
                  10.17: 0.09,
                  10.2: 0.08,
                  10.23: 0.06,
                  10.26: 0.03,
                  10.29: 0.01,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.0,
                  10.47: 0.0,
                  10.5: 0.0,
                  10.53: 0.0,
                  10.56: 0.0,
                  10.59: 0.0,
                  10.62: 0.0,
                  10.65: 0.0,
                  10.68: 0.0,
                  10.71: 0.0,
                  10.74: 0.0,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.0,
                  11.1: 0.01,
                  11.13: 0.02,
                  11.16: 0.04,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.05,
                  11.67: 0.1,
                  11.7: 0.15,
                  11.73: 0.2,
                  11.76: 0.24,
                  11.79: 0.24,
                  11.82: 0.19,
                  11.85: 0.14,
                  11.88: 0.11,
                  11.91: 0.09,
                  11.94: 0.06,
                  11.97: 0.03,
                  '12.0': 0.01,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.07,
                  12.21: 0.08,
                  12.24: 0.06,
                  12.27: 0.04,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.06,
                  12.87: 0.08,
                  12.9: 0.07,
                  12.93: 0.05,
                  12.96: 0.05,
                  12.99: 0.06,
                  13.02: 0.08,
                  13.05: 0.08,
                  13.08: 0.05,
                  13.11: 0.03,
                  13.14: 0.03,
                  13.17: 0.05,
                  13.2: 0.07,
                  13.23: 0.08,
                  13.26: 0.06,
                  13.29: 0.03,
                  13.32: 0.01,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.0,
                  13.62: 0.0,
                  13.65: 0.0,
                  13.68: 0.0,
                  13.71: 0.0,
                  13.74: 0.0,
                  13.77: 0.01,
                  13.8: 0.02,
                  13.83: 0.04,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.01,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.0,
                  14.34: 0.0,
                  14.37: 0.0,
                  14.4: 0.0,
                  14.43: 0.0,
                  14.46: 0.0,
                  14.49: 0.01,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.07,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.03,
                  14.7: 0.01,
                  14.73: 0.0,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.01,
                  14.88: 0.02,
                  14.91: 0.04,
                  14.94: 0.07,
                  14.97: 0.08,
                  '15.0': 0.06,
                  15.03: 0.04,
                  15.06: 0.01,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.0,
                  15.24: 0.0,
                  15.27: 0.0,
                  15.3: 0.0,
                  15.33: 0.0,
                  15.36: 0.0,
                  15.39: 0.0,
                  15.42: 0.0,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.03,
                  15.54: 0.06,
                  15.57: 0.08,
                  15.6: 0.07,
                  15.63: 0.05,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.07,
                  16.32: 0.05,
                  16.35: 0.02,
                  16.38: 0.01,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.0,
                  16.59: 0.0,
                  16.62: 0.0,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.0,
                  17.16: 0.0,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.08,
                  17.43: 0.12,
                  17.46: 0.15,
                  17.49: 0.16,
                  17.52: 0.15,
                  17.55: 0.13,
                  17.58: 0.09,
                  17.61: 0.05,
                  17.64: 0.02,
                  17.67: 0.01,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.01,
                  18.42: 0.02,
                  18.45: 0.05,
                  18.48: 0.08,
                  18.51: 0.09,
                  18.54: 0.08,
                  18.57: 0.08,
                  18.6: 0.09,
                  18.63: 0.08,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.01,
                  19.5: 0.02,
                  19.53: 0.04,
                  19.56: 0.07,
                  19.59: 0.08,
                  19.62: 0.06,
                  19.65: 0.03,
                  19.68: 0.01,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.08,
                  22.89: 0.07,
                  22.92: 0.04,
                  22.95: 0.02,
                  22.98: 0.01,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.01,
                  23.97: 0.03,
                  '24.0': 0.05,
                  24.03: 0.08,
                  24.06: 0.08,
                  24.09: 0.05,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.01,
                  25.08: 0.03,
                  25.11: 0.06,
                  25.14: 0.08,
                  25.17: 0.07,
                  25.2: 0.05,
                  25.23: 0.02,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.01,
                  26.94: 0.03,
                  26.97: 0.05,
                  '27.0': 0.08,
                  27.03: 0.08,
                  27.06: 0.05,
                  27.09: 0.03,
                  27.12: 0.01,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.01,
                  28.89: 0.04,
                  28.92: 0.06,
                  28.95: 0.08,
                  28.98: 0.07,
                  29.01: 0.04,
                  29.04: 0.02,
                  29.07: 0.01,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.02,
                  30.3: 0.04,
                  30.33: 0.07,
                  30.36: 0.08,
                  30.39: 0.07,
                  30.42: 0.04,
                  30.45: 0.02,
                  30.48: 0.01,
                  30.51: 0.01,
                  30.54: 0.02,
                  30.57: 0.05,
                  30.6: 0.07,
                  30.63: 0.08,
                  30.66: 0.06,
                  30.69: 0.03,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.02,
                  33.96: 0.05,
                  33.99: 0.09,
                  34.02: 0.13,
                  34.05: 0.14,
                  34.08: 0.12,
                  34.11: 0.07,
                  34.14: 0.03,
                  34.17: 0.01,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.0,
                  40.74: 0.0,
                  40.77: 0.0,
                  40.8: 0.0,
                  40.83: 0.0,
                  40.86: 0.0,
                  40.89: 0.0,
                  40.92: 0.0,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.01,
                  41.58: 0.03,
                  41.61: 0.05,
                  41.64: 0.08,
                  41.67: 0.08,
                  41.7: 0.05,
                  41.73: 0.03,
                  41.76: 0.01,
                  41.79: 0.0,
                  41.82: 0.0,
                  41.85: 0.0,
                  41.88: 0.0,
                  41.91: 0.0,
                  41.94: 0.0,
                  41.97: 0.0,
                  '42.0': 0.0,
                  42.03: 0.0,
                  42.06: 0.0,
                  42.09: 0.0,
                  42.12: 0.0,
                  42.15: 0.0,
                  42.18: 0.0,
                  42.21: 0.0,
                  42.24: 0.0,
                  42.27: 0.0,
                  42.3: 0.0,
                  42.33: 0.0,
                  42.36: 0.0,
                  42.39: 0.0,
                  42.42: 0.0,
                  42.45: 0.0,
                  42.48: 0.0,
                  42.51: 0.0,
                  42.54: 0.0,
                  42.57: 0.0,
                  42.6: 0.0,
                  42.63: 0.0,
                  42.66: 0.0,
                  42.69: 0.0,
                  42.72: 0.0,
                  42.75: 0.0,
                  42.78: 0.0,
                  42.81: 0.0,
                  42.84: 0.0,
                  42.87: 0.0,
                  42.9: 0.0,
                  42.93: 0.0,
                  42.96: 0.0,
                  42.99: 0.0,
                  43.02: 0.0,
                  43.05: 0.0,
                  43.08: 0.0,
                  43.11: 0.0,
                  43.14: 0.0,
                  43.17: 0.0,
                  43.2: 0.0,
                  43.23: 0.0,
                  43.26: 0.0,
                  43.29: 0.0,
                  43.32: 0.0,
                  43.35: 0.0,
                  43.38: 0.0,
                  43.41: 0.0,
                  43.44: 0.0,
                  43.47: 0.0,
                  43.5: 0.0,
                  43.53: 0.0,
                  43.56: 0.0,
                  43.59: 0.0,
                  43.62: 0.0,
                  43.65: 0.0,
                  43.68: 0.0,
                  43.71: 0.0,
                  43.74: 0.0,
                  43.77: 0.0,
                  43.8: 0.0,
                  43.83: 0.0,
                  43.86: 0.0,
                  43.89: 0.0,
                  43.92: 0.0,
                  43.95: 0.0,
                  43.98: 0.0,
                  44.01: 0.0,
                  44.04: 0.0,
                  44.07: 0.0,
                  44.1: 0.0,
                  44.13: 0.0,
                  44.16: 0.0,
                  44.19: 0.0,
                  44.22: 0.0,
                  44.25: 0.0,
                  44.28: 0.0,
                  44.31: 0.0,
                  44.34: 0.0,
                  44.37: 0.0,
                  44.4: 0.0,
                  44.43: 0.0,
                  44.46: 0.0,
                  44.49: 0.0,
                  44.52: 0.0,
                  44.55: 0.0,
                  44.58: 0.0,
                  44.61: 0.0,
                  44.64: 0.0,
                  44.67: 0.0,
                  44.7: 0.0,
                  44.73: 0.0,
                  44.76: 0.0,
                  44.79: 0.0,
                  44.82: 0.0,
                  44.85: 0.0,
                  44.88: 0.0,
                  44.91: 0.0,
                  44.94: 0.0,
                  44.97: 0.0,
                  '45.0': 0.0,
                  45.03: 0.0,
                  45.06: 0.0,
                  45.09: 0.0,
                  45.12: 0.0,
                  45.15: 0.0,
                  45.18: 0.0,
                  45.21: 0.0,
                  45.24: 0.0,
                  45.27: 0.0,
                  45.3: 0.0,
                  45.33: 0.0,
                  45.36: 0.0,
                  45.39: 0.0,
                  45.42: 0.0,
                  45.45: 0.0,
                  45.48: 0.0,
                  45.51: 0.0,
                  45.54: 0.0,
                  45.57: 0.0,
                  45.6: 0.0,
                  45.63: 0.0,
                  45.66: 0.0,
                  45.69: 0.0,
                  45.72: 0.0,
                  45.75: 0.0,
                  45.78: 0.0,
                  45.81: 0.0,
                  45.84: 0.0,
                  45.87: 0.0,
                  45.9: 0.0,
                  45.93: 0.0,
                  45.96: 0.0,
                  45.99: 0.0,
                  46.02: 0.0,
                  46.05: 0.0,
                  46.08: 0.01,
                  46.11: 0.03,
                  46.14: 0.06,
                  46.17: 0.08,
                  46.2: 0.08,
                  46.23: 0.05,
                  46.26: 0.02,
                  46.29: 0.01,
                  46.32: 0.0,
                  46.35: 0.0,
                  46.38: 0.0,
                  46.41: 0.0,
                  46.44: 0.0,
                  46.47: 0.0,
                  46.5: 0.0,
                  46.53: 0.0,
                  46.56: 0.0,
                  46.59: 0.0,
                  46.62: 0.0,
                  46.65: 0.0,
                  46.68: 0.0,
                  46.71: 0.0,
                  46.74: 0.0,
                  46.77: 0.0,
                  46.8: 0.0,
                  46.83: 0.0,
                  46.86: 0.0,
                  46.89: 0.0,
                  46.92: 0.0,
                  46.95: 0.0,
                  46.98: 0.0,
                },
                iteration_2: {
                  '0.0': 0.0,
                  0.03: 0.0,
                  0.06: 0.01,
                  0.09: 0.03,
                  0.12: 0.08,
                  0.15: 0.15,
                  0.18: 0.2,
                  0.21: 0.22,
                  0.24: 0.2,
                  0.27: 0.16,
                  0.3: 0.13,
                  0.33: 0.1,
                  0.36: 0.08,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.18,
                  0.66: 0.2,
                  0.69: 0.21,
                  0.72: 0.24,
                  0.75: 0.25,
                  0.78: 0.23,
                  0.81: 0.16,
                  0.84: 0.08,
                  0.87: 0.03,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.0,
                  1.02: 0.0,
                  1.05: 0.02,
                  1.08: 0.04,
                  1.11: 0.07,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.01,
                  1.26: 0.0,
                  1.29: 0.0,
                  1.32: 0.01,
                  1.35: 0.03,
                  1.38: 0.09,
                  1.41: 0.17,
                  1.44: 0.23,
                  1.47: 0.22,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.02,
                  1.59: 0.01,
                  1.62: 0.0,
                  1.65: 0.0,
                  1.68: 0.0,
                  1.71: 0.01,
                  1.74: 0.03,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.05,
                  1.95: 0.02,
                  1.98: 0.01,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.0,
                  2.1: 0.0,
                  2.13: 0.01,
                  2.16: 0.02,
                  2.19: 0.06,
                  2.22: 0.12,
                  2.25: 0.16,
                  2.28: 0.15,
                  2.31: 0.1,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.01,
                  2.43: 0.02,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.1,
                  2.55: 0.11,
                  2.58: 0.13,
                  2.61: 0.16,
                  2.64: 0.18,
                  2.67: 0.18,
                  2.7: 0.15,
                  2.73: 0.11,
                  2.76: 0.08,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.21,
                  2.88: 0.23,
                  2.91: 0.19,
                  2.94: 0.12,
                  2.97: 0.09,
                  '3.0': 0.09,
                  3.03: 0.08,
                  3.06: 0.06,
                  3.09: 0.03,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.08,
                  3.24: 0.1,
                  3.27: 0.11,
                  3.3: 0.14,
                  3.33: 0.19,
                  3.36: 0.26,
                  3.39: 0.3,
                  3.42: 0.29,
                  3.45: 0.22,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.07,
                  3.57: 0.08,
                  3.6: 0.08,
                  3.63: 0.05,
                  3.66: 0.02,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.07,
                  3.9: 0.13,
                  3.93: 0.18,
                  3.96: 0.19,
                  3.99: 0.17,
                  4.02: 0.12,
                  4.05: 0.07,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.08,
                  4.35: 0.07,
                  4.38: 0.07,
                  4.41: 0.08,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.0,
                  4.62: 0.0,
                  4.65: 0.0,
                  4.68: 0.0,
                  4.71: 0.0,
                  4.74: 0.0,
                  4.77: 0.0,
                  4.8: 0.0,
                  4.83: 0.0,
                  4.86: 0.0,
                  4.89: 0.0,
                  4.92: 0.0,
                  4.95: 0.0,
                  4.98: 0.01,
                  5.01: 0.02,
                  5.04: 0.04,
                  5.07: 0.07,
                  5.1: 0.08,
                  5.13: 0.06,
                  5.16: 0.03,
                  5.19: 0.01,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.01,
                  5.37: 0.02,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.09,
                  5.58: 0.07,
                  5.61: 0.04,
                  5.64: 0.02,
                  5.67: 0.01,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.0,
                  5.88: 0.0,
                  5.91: 0.0,
                  5.94: 0.0,
                  5.97: 0.0,
                  '6.0': 0.0,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.09,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.02,
                  6.96: 0.01,
                  6.99: 0.0,
                  7.02: 0.0,
                  7.05: 0.01,
                  7.08: 0.05,
                  7.11: 0.12,
                  7.14: 0.2,
                  7.17: 0.23,
                  7.2: 0.2,
                  7.23: 0.12,
                  7.26: 0.05,
                  7.29: 0.02,
                  7.32: 0.0,
                  7.35: 0.0,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.01,
                  7.59: 0.04,
                  7.62: 0.09,
                  7.65: 0.14,
                  7.68: 0.16,
                  7.71: 0.13,
                  7.74: 0.09,
                  7.77: 0.08,
                  7.8: 0.08,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.01,
                  7.95: 0.02,
                  7.98: 0.04,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.03,
                  8.13: 0.01,
                  8.16: 0.0,
                  8.19: 0.0,
                  8.22: 0.0,
                  8.25: 0.0,
                  8.28: 0.0,
                  8.31: 0.01,
                  8.34: 0.02,
                  8.37: 0.05,
                  8.4: 0.07,
                  8.43: 0.08,
                  8.46: 0.06,
                  8.49: 0.03,
                  8.52: 0.01,
                  8.55: 0.0,
                  8.58: 0.01,
                  8.61: 0.03,
                  8.64: 0.07,
                  8.67: 0.12,
                  8.7: 0.16,
                  8.73: 0.15,
                  8.76: 0.13,
                  8.79: 0.11,
                  8.82: 0.09,
                  8.85: 0.07,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.08,
                  9.18: 0.13,
                  9.21: 0.17,
                  9.24: 0.19,
                  9.27: 0.17,
                  9.3: 0.12,
                  9.33: 0.06,
                  9.36: 0.03,
                  9.39: 0.02,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.08,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.02,
                  9.87: 0.04,
                  9.9: 0.07,
                  9.93: 0.08,
                  9.96: 0.07,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.11,
                  10.71: 0.11,
                  10.74: 0.1,
                  10.77: 0.08,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.08,
                  10.92: 0.08,
                  10.95: 0.06,
                  10.98: 0.03,
                  11.01: 0.01,
                  11.04: 0.01,
                  11.07: 0.02,
                  11.1: 0.05,
                  11.13: 0.09,
                  11.16: 0.14,
                  11.19: 0.15,
                  11.22: 0.12,
                  11.25: 0.07,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.01,
                  11.49: 0.03,
                  11.52: 0.06,
                  11.55: 0.08,
                  11.58: 0.08,
                  11.61: 0.05,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.02,
                  12.06: 0.04,
                  12.09: 0.07,
                  12.12: 0.08,
                  12.15: 0.07,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.03,
                  12.36: 0.06,
                  12.39: 0.08,
                  12.42: 0.07,
                  12.45: 0.04,
                  12.48: 0.02,
                  12.51: 0.01,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.01,
                  12.69: 0.03,
                  12.72: 0.06,
                  12.75: 0.08,
                  12.78: 0.07,
                  12.81: 0.04,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.01,
                  13.5: 0.02,
                  13.53: 0.05,
                  13.56: 0.08,
                  13.59: 0.1,
                  13.62: 0.11,
                  13.65: 0.11,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.05,
                  13.89: 0.04,
                  13.92: 0.06,
                  13.95: 0.08,
                  13.98: 0.08,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.08,
                  14.31: 0.06,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.11,
                  14.49: 0.15,
                  14.52: 0.15,
                  14.55: 0.11,
                  14.58: 0.06,
                  14.61: 0.04,
                  14.64: 0.05,
                  14.67: 0.07,
                  14.7: 0.08,
                  14.73: 0.06,
                  14.76: 0.03,
                  14.79: 0.01,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.01,
                  15.18: 0.02,
                  15.21: 0.06,
                  15.24: 0.13,
                  15.27: 0.2,
                  15.3: 0.24,
                  15.33: 0.23,
                  15.36: 0.19,
                  15.39: 0.13,
                  15.42: 0.07,
                  15.45: 0.03,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.05,
                  15.69: 0.07,
                  15.72: 0.08,
                  15.75: 0.06,
                  15.78: 0.03,
                  15.81: 0.01,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.01,
                  16.02: 0.02,
                  16.05: 0.05,
                  16.08: 0.07,
                  16.11: 0.08,
                  16.14: 0.06,
                  16.17: 0.03,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.01,
                  16.47: 0.03,
                  16.5: 0.05,
                  16.53: 0.08,
                  16.56: 0.08,
                  16.59: 0.05,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.04,
                  16.8: 0.07,
                  16.83: 0.08,
                  16.86: 0.06,
                  16.89: 0.03,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.03,
                  17.19: 0.05,
                  17.22: 0.08,
                  17.25: 0.08,
                  17.28: 0.07,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.08,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.08,
                  18.69: 0.06,
                  18.72: 0.03,
                  18.75: 0.01,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.01,
                  20.94: 0.02,
                  20.97: 0.05,
                  '21.0': 0.07,
                  21.03: 0.08,
                  21.06: 0.06,
                  21.09: 0.03,
                  21.12: 0.01,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.0,
                  22.8: 0.0,
                  22.83: 0.0,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.07,
                  24.15: 0.08,
                  24.18: 0.07,
                  24.21: 0.04,
                  24.24: 0.02,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.01,
                  25.56: 0.02,
                  25.59: 0.04,
                  25.62: 0.07,
                  25.65: 0.08,
                  25.68: 0.06,
                  25.71: 0.03,
                  25.74: 0.01,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.07,
                  26.52: 0.08,
                  26.55: 0.06,
                  26.58: 0.03,
                  26.61: 0.01,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.01,
                  29.55: 0.04,
                  29.58: 0.07,
                  29.61: 0.08,
                  29.64: 0.07,
                  29.67: 0.04,
                  29.7: 0.02,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.01,
                  32.64: 0.02,
                  32.67: 0.05,
                  32.7: 0.07,
                  32.73: 0.08,
                  32.76: 0.06,
                  32.79: 0.03,
                  32.82: 0.01,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.01,
                  33.09: 0.03,
                  33.12: 0.06,
                  33.15: 0.08,
                  33.18: 0.07,
                  33.21: 0.05,
                  33.24: 0.02,
                  33.27: 0.01,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.01,
                  35.67: 0.02,
                  35.7: 0.05,
                  35.73: 0.07,
                  35.76: 0.08,
                  35.79: 0.06,
                  35.82: 0.03,
                  35.85: 0.01,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
                iteration_3: {
                  '0.0': 0.09,
                  0.03: 0.16,
                  0.06: 0.21,
                  0.09: 0.22,
                  0.12: 0.18,
                  0.15: 0.14,
                  0.18: 0.14,
                  0.21: 0.15,
                  0.24: 0.14,
                  0.27: 0.11,
                  0.3: 0.06,
                  0.33: 0.02,
                  0.36: 0.01,
                  0.39: 0.0,
                  0.42: 0.01,
                  0.45: 0.04,
                  0.48: 0.1,
                  0.51: 0.17,
                  0.54: 0.22,
                  0.57: 0.21,
                  0.6: 0.15,
                  0.63: 0.08,
                  0.66: 0.03,
                  0.69: 0.02,
                  0.72: 0.04,
                  0.75: 0.07,
                  0.78: 0.08,
                  0.81: 0.07,
                  0.84: 0.04,
                  0.87: 0.02,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.02,
                  1.02: 0.04,
                  1.05: 0.07,
                  1.08: 0.08,
                  1.11: 0.07,
                  1.14: 0.04,
                  1.17: 0.02,
                  1.2: 0.01,
                  1.23: 0.03,
                  1.26: 0.08,
                  1.29: 0.14,
                  1.32: 0.19,
                  1.35: 0.22,
                  1.38: 0.24,
                  1.41: 0.3,
                  1.44: 0.39,
                  1.47: 0.47,
                  1.5: 0.5,
                  1.53: 0.47,
                  1.56: 0.4,
                  1.59: 0.33,
                  1.62: 0.27,
                  1.65: 0.21,
                  1.68: 0.16,
                  1.71: 0.12,
                  1.74: 0.09,
                  1.77: 0.07,
                  1.8: 0.07,
                  1.83: 0.08,
                  1.86: 0.08,
                  1.89: 0.05,
                  1.92: 0.02,
                  1.95: 0.01,
                  1.98: 0.0,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.02,
                  2.1: 0.04,
                  2.13: 0.07,
                  2.16: 0.09,
                  2.19: 0.09,
                  2.22: 0.08,
                  2.25: 0.09,
                  2.28: 0.11,
                  2.31: 0.12,
                  2.34: 0.15,
                  2.37: 0.17,
                  2.4: 0.15,
                  2.43: 0.1,
                  2.46: 0.06,
                  2.49: 0.06,
                  2.52: 0.09,
                  2.55: 0.13,
                  2.58: 0.16,
                  2.61: 0.16,
                  2.64: 0.14,
                  2.67: 0.12,
                  2.7: 0.08,
                  2.73: 0.04,
                  2.76: 0.02,
                  2.79: 0.0,
                  2.82: 0.0,
                  2.85: 0.0,
                  2.88: 0.0,
                  2.91: 0.0,
                  2.94: 0.02,
                  2.97: 0.05,
                  '3.0': 0.1,
                  3.03: 0.17,
                  3.06: 0.21,
                  3.09: 0.23,
                  3.12: 0.21,
                  3.15: 0.17,
                  3.18: 0.1,
                  3.21: 0.05,
                  3.24: 0.02,
                  3.27: 0.01,
                  3.3: 0.01,
                  3.33: 0.02,
                  3.36: 0.04,
                  3.39: 0.07,
                  3.42: 0.08,
                  3.45: 0.07,
                  3.48: 0.04,
                  3.51: 0.02,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.01,
                  3.87: 0.03,
                  3.9: 0.06,
                  3.93: 0.08,
                  3.96: 0.07,
                  3.99: 0.05,
                  4.02: 0.02,
                  4.05: 0.01,
                  4.08: 0.0,
                  4.11: 0.0,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.0,
                  4.23: 0.0,
                  4.26: 0.0,
                  4.29: 0.0,
                  4.32: 0.0,
                  4.35: 0.0,
                  4.38: 0.0,
                  4.41: 0.0,
                  4.44: 0.0,
                  4.47: 0.0,
                  4.5: 0.0,
                  4.53: 0.0,
                  4.56: 0.01,
                  4.59: 0.04,
                  4.62: 0.09,
                  4.65: 0.14,
                  4.68: 0.16,
                  4.71: 0.13,
                  4.74: 0.09,
                  4.77: 0.08,
                  4.8: 0.08,
                  4.83: 0.07,
                  4.86: 0.05,
                  4.89: 0.02,
                  4.92: 0.01,
                  4.95: 0.0,
                  4.98: 0.0,
                  5.01: 0.0,
                  5.04: 0.0,
                  5.07: 0.0,
                  5.1: 0.0,
                  5.13: 0.0,
                  5.16: 0.0,
                  5.19: 0.0,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.0,
                  5.37: 0.0,
                  5.4: 0.0,
                  5.43: 0.02,
                  5.46: 0.05,
                  5.49: 0.1,
                  5.52: 0.15,
                  5.55: 0.15,
                  5.58: 0.11,
                  5.61: 0.05,
                  5.64: 0.02,
                  5.67: 0.0,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.01,
                  5.88: 0.02,
                  5.91: 0.05,
                  5.94: 0.08,
                  5.97: 0.08,
                  '6.0': 0.06,
                  6.03: 0.03,
                  6.06: 0.01,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.0,
                  6.33: 0.0,
                  6.36: 0.01,
                  6.39: 0.02,
                  6.42: 0.05,
                  6.45: 0.07,
                  6.48: 0.08,
                  6.51: 0.06,
                  6.54: 0.03,
                  6.57: 0.02,
                  6.6: 0.02,
                  6.63: 0.05,
                  6.66: 0.09,
                  6.69: 0.15,
                  6.72: 0.19,
                  6.75: 0.2,
                  6.78: 0.18,
                  6.81: 0.14,
                  6.84: 0.11,
                  6.87: 0.09,
                  6.9: 0.06,
                  6.93: 0.04,
                  6.96: 0.03,
                  6.99: 0.04,
                  7.02: 0.06,
                  7.05: 0.08,
                  7.08: 0.07,
                  7.11: 0.04,
                  7.14: 0.02,
                  7.17: 0.02,
                  7.2: 0.03,
                  7.23: 0.06,
                  7.26: 0.08,
                  7.29: 0.07,
                  7.32: 0.05,
                  7.35: 0.02,
                  7.38: 0.01,
                  7.41: 0.0,
                  7.44: 0.01,
                  7.47: 0.04,
                  7.5: 0.08,
                  7.53: 0.13,
                  7.56: 0.15,
                  7.59: 0.13,
                  7.62: 0.08,
                  7.65: 0.04,
                  7.68: 0.03,
                  7.71: 0.04,
                  7.74: 0.07,
                  7.77: 0.08,
                  7.8: 0.07,
                  7.83: 0.04,
                  7.86: 0.02,
                  7.89: 0.0,
                  7.92: 0.0,
                  7.95: 0.0,
                  7.98: 0.0,
                  8.01: 0.0,
                  8.04: 0.0,
                  8.07: 0.01,
                  8.1: 0.03,
                  8.13: 0.06,
                  8.16: 0.08,
                  8.19: 0.07,
                  8.22: 0.05,
                  8.25: 0.02,
                  8.28: 0.01,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.01,
                  8.4: 0.02,
                  8.43: 0.05,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.06,
                  8.55: 0.04,
                  8.58: 0.04,
                  8.61: 0.06,
                  8.64: 0.08,
                  8.67: 0.07,
                  8.7: 0.05,
                  8.73: 0.02,
                  8.76: 0.01,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.05,
                  8.88: 0.08,
                  8.91: 0.08,
                  8.94: 0.07,
                  8.97: 0.06,
                  '9.0': 0.08,
                  9.03: 0.11,
                  9.06: 0.13,
                  9.09: 0.13,
                  9.12: 0.1,
                  9.15: 0.08,
                  9.18: 0.07,
                  9.21: 0.08,
                  9.24: 0.08,
                  9.27: 0.07,
                  9.3: 0.05,
                  9.33: 0.05,
                  9.36: 0.07,
                  9.39: 0.08,
                  9.42: 0.07,
                  9.45: 0.04,
                  9.48: 0.02,
                  9.51: 0.01,
                  9.54: 0.0,
                  9.57: 0.0,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.01,
                  9.93: 0.03,
                  9.96: 0.06,
                  9.99: 0.08,
                  10.02: 0.09,
                  10.05: 0.11,
                  10.08: 0.13,
                  10.11: 0.16,
                  10.14: 0.15,
                  10.17: 0.1,
                  10.2: 0.05,
                  10.23: 0.02,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.01,
                  10.44: 0.03,
                  10.47: 0.06,
                  10.5: 0.08,
                  10.53: 0.08,
                  10.56: 0.08,
                  10.59: 0.08,
                  10.62: 0.08,
                  10.65: 0.08,
                  10.68: 0.05,
                  10.71: 0.02,
                  10.74: 0.01,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.01,
                  11.07: 0.03,
                  11.1: 0.05,
                  11.13: 0.08,
                  11.16: 0.09,
                  11.19: 0.1,
                  11.22: 0.1,
                  11.25: 0.09,
                  11.28: 0.07,
                  11.31: 0.04,
                  11.34: 0.02,
                  11.37: 0.02,
                  11.4: 0.04,
                  11.43: 0.07,
                  11.46: 0.08,
                  11.49: 0.06,
                  11.52: 0.03,
                  11.55: 0.01,
                  11.58: 0.0,
                  11.61: 0.0,
                  11.64: 0.0,
                  11.67: 0.0,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.0,
                  12.12: 0.0,
                  12.15: 0.0,
                  12.18: 0.0,
                  12.21: 0.0,
                  12.24: 0.0,
                  12.27: 0.0,
                  12.3: 0.0,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.01,
                  12.51: 0.02,
                  12.54: 0.04,
                  12.57: 0.07,
                  12.6: 0.08,
                  12.63: 0.06,
                  12.66: 0.04,
                  12.69: 0.01,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.0,
                  12.81: 0.0,
                  12.84: 0.0,
                  12.87: 0.0,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.01,
                  13.56: 0.04,
                  13.59: 0.06,
                  13.62: 0.08,
                  13.65: 0.07,
                  13.68: 0.04,
                  13.71: 0.02,
                  13.74: 0.01,
                  13.77: 0.0,
                  13.8: 0.0,
                  13.83: 0.0,
                  13.86: 0.0,
                  13.89: 0.0,
                  13.92: 0.0,
                  13.95: 0.0,
                  13.98: 0.0,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.01,
                  14.34: 0.04,
                  14.37: 0.09,
                  14.4: 0.14,
                  14.43: 0.19,
                  14.46: 0.2,
                  14.49: 0.18,
                  14.52: 0.14,
                  14.55: 0.13,
                  14.58: 0.16,
                  14.61: 0.16,
                  14.64: 0.12,
                  14.67: 0.07,
                  14.7: 0.03,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.01,
                  14.82: 0.02,
                  14.85: 0.04,
                  14.88: 0.07,
                  14.91: 0.08,
                  14.94: 0.06,
                  14.97: 0.03,
                  '15.0': 0.01,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.01,
                  15.15: 0.02,
                  15.18: 0.05,
                  15.21: 0.07,
                  15.24: 0.08,
                  15.27: 0.06,
                  15.3: 0.03,
                  15.33: 0.01,
                  15.36: 0.01,
                  15.39: 0.03,
                  15.42: 0.06,
                  15.45: 0.08,
                  15.48: 0.08,
                  15.51: 0.08,
                  15.54: 0.09,
                  15.57: 0.1,
                  15.6: 0.12,
                  15.63: 0.12,
                  15.66: 0.1,
                  15.69: 0.07,
                  15.72: 0.04,
                  15.75: 0.01,
                  15.78: 0.01,
                  15.81: 0.02,
                  15.84: 0.04,
                  15.87: 0.07,
                  15.9: 0.08,
                  15.93: 0.06,
                  15.96: 0.03,
                  15.99: 0.01,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.09,
                  16.32: 0.08,
                  16.35: 0.08,
                  16.38: 0.09,
                  16.41: 0.07,
                  16.44: 0.06,
                  16.47: 0.06,
                  16.5: 0.07,
                  16.53: 0.08,
                  16.56: 0.07,
                  16.59: 0.04,
                  16.62: 0.02,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.02,
                  16.98: 0.04,
                  17.01: 0.08,
                  17.04: 0.13,
                  17.07: 0.14,
                  17.1: 0.12,
                  17.13: 0.08,
                  17.16: 0.04,
                  17.19: 0.01,
                  17.22: 0.01,
                  17.25: 0.02,
                  17.28: 0.05,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.06,
                  17.4: 0.03,
                  17.43: 0.01,
                  17.46: 0.0,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.01,
                  17.64: 0.03,
                  17.67: 0.06,
                  17.7: 0.08,
                  17.73: 0.07,
                  17.76: 0.04,
                  17.79: 0.02,
                  17.82: 0.01,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.0,
                  18.57: 0.0,
                  18.6: 0.0,
                  18.63: 0.0,
                  18.66: 0.0,
                  18.69: 0.0,
                  18.72: 0.0,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.02,
                  20.82: 0.04,
                  20.85: 0.07,
                  20.88: 0.08,
                  20.91: 0.07,
                  20.94: 0.04,
                  20.97: 0.02,
                  '21.0': 0.01,
                  21.03: 0.02,
                  21.06: 0.05,
                  21.09: 0.07,
                  21.12: 0.08,
                  21.15: 0.06,
                  21.18: 0.03,
                  21.21: 0.01,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.01,
                  22.65: 0.03,
                  22.68: 0.06,
                  22.71: 0.08,
                  22.74: 0.07,
                  22.77: 0.05,
                  22.8: 0.02,
                  22.83: 0.01,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.0,
                  24.09: 0.0,
                  24.12: 0.0,
                  24.15: 0.0,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.01,
                  24.54: 0.03,
                  24.57: 0.06,
                  24.6: 0.08,
                  24.63: 0.08,
                  24.66: 0.05,
                  24.69: 0.02,
                  24.72: 0.01,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.01,
                  24.93: 0.02,
                  24.96: 0.05,
                  24.99: 0.08,
                  25.02: 0.08,
                  25.05: 0.06,
                  25.08: 0.03,
                  25.11: 0.01,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.01,
                  25.74: 0.02,
                  25.77: 0.05,
                  25.8: 0.08,
                  25.83: 0.08,
                  25.86: 0.06,
                  25.89: 0.03,
                  25.92: 0.01,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.01,
                  27.21: 0.03,
                  27.24: 0.05,
                  27.27: 0.08,
                  27.3: 0.08,
                  27.33: 0.05,
                  27.36: 0.03,
                  27.39: 0.01,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.01,
                  33.96: 0.02,
                  33.99: 0.04,
                  34.02: 0.07,
                  34.05: 0.08,
                  34.08: 0.06,
                  34.11: 0.04,
                  34.14: 0.01,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.01,
                  40.74: 0.02,
                  40.77: 0.04,
                  40.8: 0.07,
                  40.83: 0.08,
                  40.86: 0.06,
                  40.89: 0.04,
                  40.92: 0.01,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.0,
                  41.58: 0.0,
                  41.61: 0.0,
                  41.64: 0.0,
                  41.67: 0.0,
                  41.7: 0.0,
                  41.73: 0.0,
                  41.76: 0.0,
                  41.79: 0.01,
                  41.82: 0.02,
                  41.85: 0.05,
                  41.88: 0.07,
                  41.91: 0.08,
                  41.94: 0.06,
                  41.97: 0.03,
                },
                iteration_median: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.14,
                  0.18: 0.18,
                  0.21: 0.19,
                  0.24: 0.18,
                  0.27: 0.15,
                  0.3: 0.13,
                  0.33: 0.11,
                  0.36: 0.1,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.15,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.06,
                  1.26: 0.08,
                  1.29: 0.08,
                  1.32: 0.07,
                  1.35: 0.08,
                  1.38: 0.12,
                  1.41: 0.16,
                  1.44: 0.17,
                  1.47: 0.16,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.08,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.16,
                  2.28: 0.11,
                  2.31: 0.07,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.15,
                  2.73: 0.12,
                  2.76: 0.09,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.01,
                  3.36: 0.03,
                  3.39: 0.06,
                  3.42: 0.11,
                  3.45: 0.14,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.06,
                  3.57: 0.02,
                  3.6: 0.01,
                  3.63: 0.0,
                  3.66: 0.01,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.02,
                  3.9: 0.01,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.03,
                  4.65: 0.02,
                  4.68: 0.03,
                  4.71: 0.06,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.16,
                  4.95: 0.1,
                  4.98: 0.05,
                  5.01: 0.02,
                  5.04: 0.02,
                  5.07: 0.04,
                  5.1: 0.07,
                  5.13: 0.06,
                  5.16: 0.07,
                  5.19: 0.04,
                  5.22: 0.03,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.05,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.07,
                  5.7: 0.04,
                  5.73: 0.02,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.01,
                  5.85: 0.02,
                  5.88: 0.05,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.01,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.11,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.06,
                  7.02: 0.03,
                  7.05: 0.01,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.05,
                  7.23: 0.08,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.04,
                  8.22: 0.02,
                  8.25: 0.01,
                  8.28: 0.0,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.04,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.07,
                  8.55: 0.04,
                  8.58: 0.02,
                  8.61: 0.03,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.04,
                  9.24: 0.08,
                  9.27: 0.12,
                  9.3: 0.12,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.05,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.07,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.08,
                  10.71: 0.05,
                  10.74: 0.04,
                  10.77: 0.05,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.03,
                  10.92: 0.01,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.01,
                  11.1: 0.03,
                  11.13: 0.06,
                  11.16: 0.08,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.01,
                  13.62: 0.03,
                  13.65: 0.06,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.05,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.07,
                  14.31: 0.04,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.05,
                  14.49: 0.03,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.06,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.05,
                  14.7: 0.02,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.01,
                  15.24: 0.02,
                  15.27: 0.04,
                  15.3: 0.07,
                  15.33: 0.08,
                  15.36: 0.06,
                  15.39: 0.04,
                  15.42: 0.01,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.01,
                  16.59: 0.03,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.01,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.01,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.05,
                  22.89: 0.02,
                  22.92: 0.01,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.04,
                  26.52: 0.02,
                  26.55: 0.01,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
              },
            },
            region: {
              'australia and new zealand': {
                iteration_1: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.12,
                  0.18: 0.1,
                  0.21: 0.1,
                  0.24: 0.11,
                  0.27: 0.13,
                  0.3: 0.15,
                  0.33: 0.17,
                  0.36: 0.18,
                  0.39: 0.18,
                  0.42: 0.19,
                  0.45: 0.22,
                  0.48: 0.25,
                  0.51: 0.25,
                  0.54: 0.22,
                  0.57: 0.18,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.2,
                  1.14: 0.17,
                  1.17: 0.13,
                  1.2: 0.1,
                  1.23: 0.09,
                  1.26: 0.13,
                  1.29: 0.17,
                  1.32: 0.19,
                  1.35: 0.17,
                  1.38: 0.13,
                  1.41: 0.08,
                  1.44: 0.05,
                  1.47: 0.03,
                  1.5: 0.04,
                  1.53: 0.06,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.05,
                  1.83: 0.06,
                  1.86: 0.08,
                  1.89: 0.09,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.1,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.15,
                  2.28: 0.11,
                  2.31: 0.06,
                  2.34: 0.02,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.07,
                  2.49: 0.08,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.18,
                  2.73: 0.17,
                  2.76: 0.16,
                  2.79: 0.16,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.04,
                  3.15: 0.07,
                  3.18: 0.08,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.0,
                  3.36: 0.0,
                  3.39: 0.0,
                  3.42: 0.0,
                  3.45: 0.0,
                  3.48: 0.0,
                  3.51: 0.0,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.0,
                  3.87: 0.0,
                  3.9: 0.0,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.08,
                  4.11: 0.1,
                  4.14: 0.1,
                  4.17: 0.1,
                  4.2: 0.09,
                  4.23: 0.08,
                  4.26: 0.07,
                  4.29: 0.09,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.05,
                  4.47: 0.02,
                  4.5: 0.01,
                  4.53: 0.0,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.04,
                  4.65: 0.06,
                  4.68: 0.08,
                  4.71: 0.07,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.17,
                  4.95: 0.13,
                  4.98: 0.07,
                  5.01: 0.03,
                  5.04: 0.01,
                  5.07: 0.01,
                  5.1: 0.03,
                  5.13: 0.06,
                  5.16: 0.08,
                  5.19: 0.07,
                  5.22: 0.05,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.12,
                  5.4: 0.16,
                  5.43: 0.15,
                  5.46: 0.11,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.12,
                  5.7: 0.15,
                  5.73: 0.14,
                  5.76: 0.09,
                  5.79: 0.05,
                  5.82: 0.03,
                  5.85: 0.05,
                  5.88: 0.07,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.04,
                  6.21: 0.07,
                  6.24: 0.1,
                  6.27: 0.12,
                  6.3: 0.12,
                  6.33: 0.1,
                  6.36: 0.09,
                  6.39: 0.08,
                  6.42: 0.09,
                  6.45: 0.08,
                  6.48: 0.05,
                  6.51: 0.03,
                  6.54: 0.02,
                  6.57: 0.04,
                  6.6: 0.08,
                  6.63: 0.14,
                  6.66: 0.16,
                  6.69: 0.13,
                  6.72: 0.07,
                  6.75: 0.03,
                  6.78: 0.01,
                  6.81: 0.0,
                  6.84: 0.0,
                  6.87: 0.0,
                  6.9: 0.01,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.08,
                  7.02: 0.07,
                  7.05: 0.05,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.04,
                  7.23: 0.07,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.07,
                  7.89: 0.04,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.08,
                  8.22: 0.07,
                  8.25: 0.04,
                  8.28: 0.02,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.03,
                  8.46: 0.07,
                  8.49: 0.11,
                  8.52: 0.13,
                  8.55: 0.13,
                  8.58: 0.1,
                  8.61: 0.06,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.07,
                  8.91: 0.08,
                  8.94: 0.06,
                  8.97: 0.03,
                  '9.0': 0.02,
                  9.03: 0.03,
                  9.06: 0.05,
                  9.09: 0.07,
                  9.12: 0.08,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.02,
                  9.24: 0.03,
                  9.27: 0.05,
                  9.3: 0.08,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.06,
                  9.42: 0.07,
                  9.45: 0.08,
                  9.48: 0.07,
                  9.51: 0.05,
                  9.54: 0.02,
                  9.57: 0.01,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.07,
                  10.02: 0.12,
                  10.05: 0.14,
                  10.08: 0.14,
                  10.11: 0.12,
                  10.14: 0.1,
                  10.17: 0.09,
                  10.2: 0.08,
                  10.23: 0.06,
                  10.26: 0.03,
                  10.29: 0.01,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.0,
                  10.47: 0.0,
                  10.5: 0.0,
                  10.53: 0.0,
                  10.56: 0.0,
                  10.59: 0.0,
                  10.62: 0.0,
                  10.65: 0.0,
                  10.68: 0.0,
                  10.71: 0.0,
                  10.74: 0.0,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.0,
                  11.1: 0.01,
                  11.13: 0.02,
                  11.16: 0.04,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.05,
                  11.67: 0.1,
                  11.7: 0.15,
                  11.73: 0.2,
                  11.76: 0.24,
                  11.79: 0.24,
                  11.82: 0.19,
                  11.85: 0.14,
                  11.88: 0.11,
                  11.91: 0.09,
                  11.94: 0.06,
                  11.97: 0.03,
                  '12.0': 0.01,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.07,
                  12.21: 0.08,
                  12.24: 0.06,
                  12.27: 0.04,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.06,
                  12.87: 0.08,
                  12.9: 0.07,
                  12.93: 0.05,
                  12.96: 0.05,
                  12.99: 0.06,
                  13.02: 0.08,
                  13.05: 0.08,
                  13.08: 0.05,
                  13.11: 0.03,
                  13.14: 0.03,
                  13.17: 0.05,
                  13.2: 0.07,
                  13.23: 0.08,
                  13.26: 0.06,
                  13.29: 0.03,
                  13.32: 0.01,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.0,
                  13.62: 0.0,
                  13.65: 0.0,
                  13.68: 0.0,
                  13.71: 0.0,
                  13.74: 0.0,
                  13.77: 0.01,
                  13.8: 0.02,
                  13.83: 0.04,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.01,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.0,
                  14.34: 0.0,
                  14.37: 0.0,
                  14.4: 0.0,
                  14.43: 0.0,
                  14.46: 0.0,
                  14.49: 0.01,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.07,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.03,
                  14.7: 0.01,
                  14.73: 0.0,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.01,
                  14.88: 0.02,
                  14.91: 0.04,
                  14.94: 0.07,
                  14.97: 0.08,
                  '15.0': 0.06,
                  15.03: 0.04,
                  15.06: 0.01,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.0,
                  15.24: 0.0,
                  15.27: 0.0,
                  15.3: 0.0,
                  15.33: 0.0,
                  15.36: 0.0,
                  15.39: 0.0,
                  15.42: 0.0,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.03,
                  15.54: 0.06,
                  15.57: 0.08,
                  15.6: 0.07,
                  15.63: 0.05,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.07,
                  16.32: 0.05,
                  16.35: 0.02,
                  16.38: 0.01,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.0,
                  16.59: 0.0,
                  16.62: 0.0,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.0,
                  17.16: 0.0,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.08,
                  17.43: 0.12,
                  17.46: 0.15,
                  17.49: 0.16,
                  17.52: 0.15,
                  17.55: 0.13,
                  17.58: 0.09,
                  17.61: 0.05,
                  17.64: 0.02,
                  17.67: 0.01,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.01,
                  18.42: 0.02,
                  18.45: 0.05,
                  18.48: 0.08,
                  18.51: 0.09,
                  18.54: 0.08,
                  18.57: 0.08,
                  18.6: 0.09,
                  18.63: 0.08,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.01,
                  19.5: 0.02,
                  19.53: 0.04,
                  19.56: 0.07,
                  19.59: 0.08,
                  19.62: 0.06,
                  19.65: 0.03,
                  19.68: 0.01,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.08,
                  22.89: 0.07,
                  22.92: 0.04,
                  22.95: 0.02,
                  22.98: 0.01,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.01,
                  23.97: 0.03,
                  '24.0': 0.05,
                  24.03: 0.08,
                  24.06: 0.08,
                  24.09: 0.05,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.01,
                  25.08: 0.03,
                  25.11: 0.06,
                  25.14: 0.08,
                  25.17: 0.07,
                  25.2: 0.05,
                  25.23: 0.02,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.01,
                  26.94: 0.03,
                  26.97: 0.05,
                  '27.0': 0.08,
                  27.03: 0.08,
                  27.06: 0.05,
                  27.09: 0.03,
                  27.12: 0.01,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.01,
                  28.89: 0.04,
                  28.92: 0.06,
                  28.95: 0.08,
                  28.98: 0.07,
                  29.01: 0.04,
                  29.04: 0.02,
                  29.07: 0.01,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.02,
                  30.3: 0.04,
                  30.33: 0.07,
                  30.36: 0.08,
                  30.39: 0.07,
                  30.42: 0.04,
                  30.45: 0.02,
                  30.48: 0.01,
                  30.51: 0.01,
                  30.54: 0.02,
                  30.57: 0.05,
                  30.6: 0.07,
                  30.63: 0.08,
                  30.66: 0.06,
                  30.69: 0.03,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.02,
                  33.96: 0.05,
                  33.99: 0.09,
                  34.02: 0.13,
                  34.05: 0.14,
                  34.08: 0.12,
                  34.11: 0.07,
                  34.14: 0.03,
                  34.17: 0.01,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.0,
                  40.74: 0.0,
                  40.77: 0.0,
                  40.8: 0.0,
                  40.83: 0.0,
                  40.86: 0.0,
                  40.89: 0.0,
                  40.92: 0.0,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.01,
                  41.58: 0.03,
                  41.61: 0.05,
                  41.64: 0.08,
                  41.67: 0.08,
                  41.7: 0.05,
                  41.73: 0.03,
                  41.76: 0.01,
                  41.79: 0.0,
                  41.82: 0.0,
                  41.85: 0.0,
                  41.88: 0.0,
                  41.91: 0.0,
                  41.94: 0.0,
                  41.97: 0.0,
                  '42.0': 0.0,
                  42.03: 0.0,
                  42.06: 0.0,
                  42.09: 0.0,
                  42.12: 0.0,
                  42.15: 0.0,
                  42.18: 0.0,
                  42.21: 0.0,
                  42.24: 0.0,
                  42.27: 0.0,
                  42.3: 0.0,
                  42.33: 0.0,
                  42.36: 0.0,
                  42.39: 0.0,
                  42.42: 0.0,
                  42.45: 0.0,
                  42.48: 0.0,
                  42.51: 0.0,
                  42.54: 0.0,
                  42.57: 0.0,
                  42.6: 0.0,
                  42.63: 0.0,
                  42.66: 0.0,
                  42.69: 0.0,
                  42.72: 0.0,
                  42.75: 0.0,
                  42.78: 0.0,
                  42.81: 0.0,
                  42.84: 0.0,
                  42.87: 0.0,
                  42.9: 0.0,
                  42.93: 0.0,
                  42.96: 0.0,
                  42.99: 0.0,
                  43.02: 0.0,
                  43.05: 0.0,
                  43.08: 0.0,
                  43.11: 0.0,
                  43.14: 0.0,
                  43.17: 0.0,
                  43.2: 0.0,
                  43.23: 0.0,
                  43.26: 0.0,
                  43.29: 0.0,
                  43.32: 0.0,
                  43.35: 0.0,
                  43.38: 0.0,
                  43.41: 0.0,
                  43.44: 0.0,
                  43.47: 0.0,
                  43.5: 0.0,
                  43.53: 0.0,
                  43.56: 0.0,
                  43.59: 0.0,
                  43.62: 0.0,
                  43.65: 0.0,
                  43.68: 0.0,
                  43.71: 0.0,
                  43.74: 0.0,
                  43.77: 0.0,
                  43.8: 0.0,
                  43.83: 0.0,
                  43.86: 0.0,
                  43.89: 0.0,
                  43.92: 0.0,
                  43.95: 0.0,
                  43.98: 0.0,
                  44.01: 0.0,
                  44.04: 0.0,
                  44.07: 0.0,
                  44.1: 0.0,
                  44.13: 0.0,
                  44.16: 0.0,
                  44.19: 0.0,
                  44.22: 0.0,
                  44.25: 0.0,
                  44.28: 0.0,
                  44.31: 0.0,
                  44.34: 0.0,
                  44.37: 0.0,
                  44.4: 0.0,
                  44.43: 0.0,
                  44.46: 0.0,
                  44.49: 0.0,
                  44.52: 0.0,
                  44.55: 0.0,
                  44.58: 0.0,
                  44.61: 0.0,
                  44.64: 0.0,
                  44.67: 0.0,
                  44.7: 0.0,
                  44.73: 0.0,
                  44.76: 0.0,
                  44.79: 0.0,
                  44.82: 0.0,
                  44.85: 0.0,
                  44.88: 0.0,
                  44.91: 0.0,
                  44.94: 0.0,
                  44.97: 0.0,
                  '45.0': 0.0,
                  45.03: 0.0,
                  45.06: 0.0,
                  45.09: 0.0,
                  45.12: 0.0,
                  45.15: 0.0,
                  45.18: 0.0,
                  45.21: 0.0,
                  45.24: 0.0,
                  45.27: 0.0,
                  45.3: 0.0,
                  45.33: 0.0,
                  45.36: 0.0,
                  45.39: 0.0,
                  45.42: 0.0,
                  45.45: 0.0,
                  45.48: 0.0,
                  45.51: 0.0,
                  45.54: 0.0,
                  45.57: 0.0,
                  45.6: 0.0,
                  45.63: 0.0,
                  45.66: 0.0,
                  45.69: 0.0,
                  45.72: 0.0,
                  45.75: 0.0,
                  45.78: 0.0,
                  45.81: 0.0,
                  45.84: 0.0,
                  45.87: 0.0,
                  45.9: 0.0,
                  45.93: 0.0,
                  45.96: 0.0,
                  45.99: 0.0,
                  46.02: 0.0,
                  46.05: 0.0,
                  46.08: 0.01,
                  46.11: 0.03,
                  46.14: 0.06,
                  46.17: 0.08,
                  46.2: 0.08,
                  46.23: 0.05,
                  46.26: 0.02,
                  46.29: 0.01,
                  46.32: 0.0,
                  46.35: 0.0,
                  46.38: 0.0,
                  46.41: 0.0,
                  46.44: 0.0,
                  46.47: 0.0,
                  46.5: 0.0,
                  46.53: 0.0,
                  46.56: 0.0,
                  46.59: 0.0,
                  46.62: 0.0,
                  46.65: 0.0,
                  46.68: 0.0,
                  46.71: 0.0,
                  46.74: 0.0,
                  46.77: 0.0,
                  46.8: 0.0,
                  46.83: 0.0,
                  46.86: 0.0,
                  46.89: 0.0,
                  46.92: 0.0,
                  46.95: 0.0,
                  46.98: 0.0,
                },
                iteration_2: {
                  '0.0': 0.0,
                  0.03: 0.0,
                  0.06: 0.01,
                  0.09: 0.03,
                  0.12: 0.08,
                  0.15: 0.15,
                  0.18: 0.2,
                  0.21: 0.22,
                  0.24: 0.2,
                  0.27: 0.16,
                  0.3: 0.13,
                  0.33: 0.1,
                  0.36: 0.08,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.18,
                  0.66: 0.2,
                  0.69: 0.21,
                  0.72: 0.24,
                  0.75: 0.25,
                  0.78: 0.23,
                  0.81: 0.16,
                  0.84: 0.08,
                  0.87: 0.03,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.0,
                  1.02: 0.0,
                  1.05: 0.02,
                  1.08: 0.04,
                  1.11: 0.07,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.01,
                  1.26: 0.0,
                  1.29: 0.0,
                  1.32: 0.01,
                  1.35: 0.03,
                  1.38: 0.09,
                  1.41: 0.17,
                  1.44: 0.23,
                  1.47: 0.22,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.02,
                  1.59: 0.01,
                  1.62: 0.0,
                  1.65: 0.0,
                  1.68: 0.0,
                  1.71: 0.01,
                  1.74: 0.03,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.05,
                  1.95: 0.02,
                  1.98: 0.01,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.0,
                  2.1: 0.0,
                  2.13: 0.01,
                  2.16: 0.02,
                  2.19: 0.06,
                  2.22: 0.12,
                  2.25: 0.16,
                  2.28: 0.15,
                  2.31: 0.1,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.01,
                  2.43: 0.02,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.1,
                  2.55: 0.11,
                  2.58: 0.13,
                  2.61: 0.16,
                  2.64: 0.18,
                  2.67: 0.18,
                  2.7: 0.15,
                  2.73: 0.11,
                  2.76: 0.08,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.21,
                  2.88: 0.23,
                  2.91: 0.19,
                  2.94: 0.12,
                  2.97: 0.09,
                  '3.0': 0.09,
                  3.03: 0.08,
                  3.06: 0.06,
                  3.09: 0.03,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.08,
                  3.24: 0.1,
                  3.27: 0.11,
                  3.3: 0.14,
                  3.33: 0.19,
                  3.36: 0.26,
                  3.39: 0.3,
                  3.42: 0.29,
                  3.45: 0.22,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.07,
                  3.57: 0.08,
                  3.6: 0.08,
                  3.63: 0.05,
                  3.66: 0.02,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.07,
                  3.9: 0.13,
                  3.93: 0.18,
                  3.96: 0.19,
                  3.99: 0.17,
                  4.02: 0.12,
                  4.05: 0.07,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.08,
                  4.35: 0.07,
                  4.38: 0.07,
                  4.41: 0.08,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.0,
                  4.62: 0.0,
                  4.65: 0.0,
                  4.68: 0.0,
                  4.71: 0.0,
                  4.74: 0.0,
                  4.77: 0.0,
                  4.8: 0.0,
                  4.83: 0.0,
                  4.86: 0.0,
                  4.89: 0.0,
                  4.92: 0.0,
                  4.95: 0.0,
                  4.98: 0.01,
                  5.01: 0.02,
                  5.04: 0.04,
                  5.07: 0.07,
                  5.1: 0.08,
                  5.13: 0.06,
                  5.16: 0.03,
                  5.19: 0.01,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.01,
                  5.37: 0.02,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.09,
                  5.58: 0.07,
                  5.61: 0.04,
                  5.64: 0.02,
                  5.67: 0.01,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.0,
                  5.88: 0.0,
                  5.91: 0.0,
                  5.94: 0.0,
                  5.97: 0.0,
                  '6.0': 0.0,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.09,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.02,
                  6.96: 0.01,
                  6.99: 0.0,
                  7.02: 0.0,
                  7.05: 0.01,
                  7.08: 0.05,
                  7.11: 0.12,
                  7.14: 0.2,
                  7.17: 0.23,
                  7.2: 0.2,
                  7.23: 0.12,
                  7.26: 0.05,
                  7.29: 0.02,
                  7.32: 0.0,
                  7.35: 0.0,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.01,
                  7.59: 0.04,
                  7.62: 0.09,
                  7.65: 0.14,
                  7.68: 0.16,
                  7.71: 0.13,
                  7.74: 0.09,
                  7.77: 0.08,
                  7.8: 0.08,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.01,
                  7.95: 0.02,
                  7.98: 0.04,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.03,
                  8.13: 0.01,
                  8.16: 0.0,
                  8.19: 0.0,
                  8.22: 0.0,
                  8.25: 0.0,
                  8.28: 0.0,
                  8.31: 0.01,
                  8.34: 0.02,
                  8.37: 0.05,
                  8.4: 0.07,
                  8.43: 0.08,
                  8.46: 0.06,
                  8.49: 0.03,
                  8.52: 0.01,
                  8.55: 0.0,
                  8.58: 0.01,
                  8.61: 0.03,
                  8.64: 0.07,
                  8.67: 0.12,
                  8.7: 0.16,
                  8.73: 0.15,
                  8.76: 0.13,
                  8.79: 0.11,
                  8.82: 0.09,
                  8.85: 0.07,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.08,
                  9.18: 0.13,
                  9.21: 0.17,
                  9.24: 0.19,
                  9.27: 0.17,
                  9.3: 0.12,
                  9.33: 0.06,
                  9.36: 0.03,
                  9.39: 0.02,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.08,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.02,
                  9.87: 0.04,
                  9.9: 0.07,
                  9.93: 0.08,
                  9.96: 0.07,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.11,
                  10.71: 0.11,
                  10.74: 0.1,
                  10.77: 0.08,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.08,
                  10.92: 0.08,
                  10.95: 0.06,
                  10.98: 0.03,
                  11.01: 0.01,
                  11.04: 0.01,
                  11.07: 0.02,
                  11.1: 0.05,
                  11.13: 0.09,
                  11.16: 0.14,
                  11.19: 0.15,
                  11.22: 0.12,
                  11.25: 0.07,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.01,
                  11.49: 0.03,
                  11.52: 0.06,
                  11.55: 0.08,
                  11.58: 0.08,
                  11.61: 0.05,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.02,
                  12.06: 0.04,
                  12.09: 0.07,
                  12.12: 0.08,
                  12.15: 0.07,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.03,
                  12.36: 0.06,
                  12.39: 0.08,
                  12.42: 0.07,
                  12.45: 0.04,
                  12.48: 0.02,
                  12.51: 0.01,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.01,
                  12.69: 0.03,
                  12.72: 0.06,
                  12.75: 0.08,
                  12.78: 0.07,
                  12.81: 0.04,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.01,
                  13.5: 0.02,
                  13.53: 0.05,
                  13.56: 0.08,
                  13.59: 0.1,
                  13.62: 0.11,
                  13.65: 0.11,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.05,
                  13.89: 0.04,
                  13.92: 0.06,
                  13.95: 0.08,
                  13.98: 0.08,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.08,
                  14.31: 0.06,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.11,
                  14.49: 0.15,
                  14.52: 0.15,
                  14.55: 0.11,
                  14.58: 0.06,
                  14.61: 0.04,
                  14.64: 0.05,
                  14.67: 0.07,
                  14.7: 0.08,
                  14.73: 0.06,
                  14.76: 0.03,
                  14.79: 0.01,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.01,
                  15.18: 0.02,
                  15.21: 0.06,
                  15.24: 0.13,
                  15.27: 0.2,
                  15.3: 0.24,
                  15.33: 0.23,
                  15.36: 0.19,
                  15.39: 0.13,
                  15.42: 0.07,
                  15.45: 0.03,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.05,
                  15.69: 0.07,
                  15.72: 0.08,
                  15.75: 0.06,
                  15.78: 0.03,
                  15.81: 0.01,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.01,
                  16.02: 0.02,
                  16.05: 0.05,
                  16.08: 0.07,
                  16.11: 0.08,
                  16.14: 0.06,
                  16.17: 0.03,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.01,
                  16.47: 0.03,
                  16.5: 0.05,
                  16.53: 0.08,
                  16.56: 0.08,
                  16.59: 0.05,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.04,
                  16.8: 0.07,
                  16.83: 0.08,
                  16.86: 0.06,
                  16.89: 0.03,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.03,
                  17.19: 0.05,
                  17.22: 0.08,
                  17.25: 0.08,
                  17.28: 0.07,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.08,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.08,
                  18.69: 0.06,
                  18.72: 0.03,
                  18.75: 0.01,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.01,
                  20.94: 0.02,
                  20.97: 0.05,
                  '21.0': 0.07,
                  21.03: 0.08,
                  21.06: 0.06,
                  21.09: 0.03,
                  21.12: 0.01,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.0,
                  22.8: 0.0,
                  22.83: 0.0,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.07,
                  24.15: 0.08,
                  24.18: 0.07,
                  24.21: 0.04,
                  24.24: 0.02,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.01,
                  25.56: 0.02,
                  25.59: 0.04,
                  25.62: 0.07,
                  25.65: 0.08,
                  25.68: 0.06,
                  25.71: 0.03,
                  25.74: 0.01,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.07,
                  26.52: 0.08,
                  26.55: 0.06,
                  26.58: 0.03,
                  26.61: 0.01,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.01,
                  29.55: 0.04,
                  29.58: 0.07,
                  29.61: 0.08,
                  29.64: 0.07,
                  29.67: 0.04,
                  29.7: 0.02,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.01,
                  32.64: 0.02,
                  32.67: 0.05,
                  32.7: 0.07,
                  32.73: 0.08,
                  32.76: 0.06,
                  32.79: 0.03,
                  32.82: 0.01,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.01,
                  33.09: 0.03,
                  33.12: 0.06,
                  33.15: 0.08,
                  33.18: 0.07,
                  33.21: 0.05,
                  33.24: 0.02,
                  33.27: 0.01,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.01,
                  35.67: 0.02,
                  35.7: 0.05,
                  35.73: 0.07,
                  35.76: 0.08,
                  35.79: 0.06,
                  35.82: 0.03,
                  35.85: 0.01,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
                iteration_3: {
                  '0.0': 0.09,
                  0.03: 0.16,
                  0.06: 0.21,
                  0.09: 0.22,
                  0.12: 0.18,
                  0.15: 0.14,
                  0.18: 0.14,
                  0.21: 0.15,
                  0.24: 0.14,
                  0.27: 0.11,
                  0.3: 0.06,
                  0.33: 0.02,
                  0.36: 0.01,
                  0.39: 0.0,
                  0.42: 0.01,
                  0.45: 0.04,
                  0.48: 0.1,
                  0.51: 0.17,
                  0.54: 0.22,
                  0.57: 0.21,
                  0.6: 0.15,
                  0.63: 0.08,
                  0.66: 0.03,
                  0.69: 0.02,
                  0.72: 0.04,
                  0.75: 0.07,
                  0.78: 0.08,
                  0.81: 0.07,
                  0.84: 0.04,
                  0.87: 0.02,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.02,
                  1.02: 0.04,
                  1.05: 0.07,
                  1.08: 0.08,
                  1.11: 0.07,
                  1.14: 0.04,
                  1.17: 0.02,
                  1.2: 0.01,
                  1.23: 0.03,
                  1.26: 0.08,
                  1.29: 0.14,
                  1.32: 0.19,
                  1.35: 0.22,
                  1.38: 0.24,
                  1.41: 0.3,
                  1.44: 0.39,
                  1.47: 0.47,
                  1.5: 0.5,
                  1.53: 0.47,
                  1.56: 0.4,
                  1.59: 0.33,
                  1.62: 0.27,
                  1.65: 0.21,
                  1.68: 0.16,
                  1.71: 0.12,
                  1.74: 0.09,
                  1.77: 0.07,
                  1.8: 0.07,
                  1.83: 0.08,
                  1.86: 0.08,
                  1.89: 0.05,
                  1.92: 0.02,
                  1.95: 0.01,
                  1.98: 0.0,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.02,
                  2.1: 0.04,
                  2.13: 0.07,
                  2.16: 0.09,
                  2.19: 0.09,
                  2.22: 0.08,
                  2.25: 0.09,
                  2.28: 0.11,
                  2.31: 0.12,
                  2.34: 0.15,
                  2.37: 0.17,
                  2.4: 0.15,
                  2.43: 0.1,
                  2.46: 0.06,
                  2.49: 0.06,
                  2.52: 0.09,
                  2.55: 0.13,
                  2.58: 0.16,
                  2.61: 0.16,
                  2.64: 0.14,
                  2.67: 0.12,
                  2.7: 0.08,
                  2.73: 0.04,
                  2.76: 0.02,
                  2.79: 0.0,
                  2.82: 0.0,
                  2.85: 0.0,
                  2.88: 0.0,
                  2.91: 0.0,
                  2.94: 0.02,
                  2.97: 0.05,
                  '3.0': 0.1,
                  3.03: 0.17,
                  3.06: 0.21,
                  3.09: 0.23,
                  3.12: 0.21,
                  3.15: 0.17,
                  3.18: 0.1,
                  3.21: 0.05,
                  3.24: 0.02,
                  3.27: 0.01,
                  3.3: 0.01,
                  3.33: 0.02,
                  3.36: 0.04,
                  3.39: 0.07,
                  3.42: 0.08,
                  3.45: 0.07,
                  3.48: 0.04,
                  3.51: 0.02,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.01,
                  3.87: 0.03,
                  3.9: 0.06,
                  3.93: 0.08,
                  3.96: 0.07,
                  3.99: 0.05,
                  4.02: 0.02,
                  4.05: 0.01,
                  4.08: 0.0,
                  4.11: 0.0,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.0,
                  4.23: 0.0,
                  4.26: 0.0,
                  4.29: 0.0,
                  4.32: 0.0,
                  4.35: 0.0,
                  4.38: 0.0,
                  4.41: 0.0,
                  4.44: 0.0,
                  4.47: 0.0,
                  4.5: 0.0,
                  4.53: 0.0,
                  4.56: 0.01,
                  4.59: 0.04,
                  4.62: 0.09,
                  4.65: 0.14,
                  4.68: 0.16,
                  4.71: 0.13,
                  4.74: 0.09,
                  4.77: 0.08,
                  4.8: 0.08,
                  4.83: 0.07,
                  4.86: 0.05,
                  4.89: 0.02,
                  4.92: 0.01,
                  4.95: 0.0,
                  4.98: 0.0,
                  5.01: 0.0,
                  5.04: 0.0,
                  5.07: 0.0,
                  5.1: 0.0,
                  5.13: 0.0,
                  5.16: 0.0,
                  5.19: 0.0,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.0,
                  5.37: 0.0,
                  5.4: 0.0,
                  5.43: 0.02,
                  5.46: 0.05,
                  5.49: 0.1,
                  5.52: 0.15,
                  5.55: 0.15,
                  5.58: 0.11,
                  5.61: 0.05,
                  5.64: 0.02,
                  5.67: 0.0,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.01,
                  5.88: 0.02,
                  5.91: 0.05,
                  5.94: 0.08,
                  5.97: 0.08,
                  '6.0': 0.06,
                  6.03: 0.03,
                  6.06: 0.01,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.0,
                  6.33: 0.0,
                  6.36: 0.01,
                  6.39: 0.02,
                  6.42: 0.05,
                  6.45: 0.07,
                  6.48: 0.08,
                  6.51: 0.06,
                  6.54: 0.03,
                  6.57: 0.02,
                  6.6: 0.02,
                  6.63: 0.05,
                  6.66: 0.09,
                  6.69: 0.15,
                  6.72: 0.19,
                  6.75: 0.2,
                  6.78: 0.18,
                  6.81: 0.14,
                  6.84: 0.11,
                  6.87: 0.09,
                  6.9: 0.06,
                  6.93: 0.04,
                  6.96: 0.03,
                  6.99: 0.04,
                  7.02: 0.06,
                  7.05: 0.08,
                  7.08: 0.07,
                  7.11: 0.04,
                  7.14: 0.02,
                  7.17: 0.02,
                  7.2: 0.03,
                  7.23: 0.06,
                  7.26: 0.08,
                  7.29: 0.07,
                  7.32: 0.05,
                  7.35: 0.02,
                  7.38: 0.01,
                  7.41: 0.0,
                  7.44: 0.01,
                  7.47: 0.04,
                  7.5: 0.08,
                  7.53: 0.13,
                  7.56: 0.15,
                  7.59: 0.13,
                  7.62: 0.08,
                  7.65: 0.04,
                  7.68: 0.03,
                  7.71: 0.04,
                  7.74: 0.07,
                  7.77: 0.08,
                  7.8: 0.07,
                  7.83: 0.04,
                  7.86: 0.02,
                  7.89: 0.0,
                  7.92: 0.0,
                  7.95: 0.0,
                  7.98: 0.0,
                  8.01: 0.0,
                  8.04: 0.0,
                  8.07: 0.01,
                  8.1: 0.03,
                  8.13: 0.06,
                  8.16: 0.08,
                  8.19: 0.07,
                  8.22: 0.05,
                  8.25: 0.02,
                  8.28: 0.01,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.01,
                  8.4: 0.02,
                  8.43: 0.05,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.06,
                  8.55: 0.04,
                  8.58: 0.04,
                  8.61: 0.06,
                  8.64: 0.08,
                  8.67: 0.07,
                  8.7: 0.05,
                  8.73: 0.02,
                  8.76: 0.01,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.05,
                  8.88: 0.08,
                  8.91: 0.08,
                  8.94: 0.07,
                  8.97: 0.06,
                  '9.0': 0.08,
                  9.03: 0.11,
                  9.06: 0.13,
                  9.09: 0.13,
                  9.12: 0.1,
                  9.15: 0.08,
                  9.18: 0.07,
                  9.21: 0.08,
                  9.24: 0.08,
                  9.27: 0.07,
                  9.3: 0.05,
                  9.33: 0.05,
                  9.36: 0.07,
                  9.39: 0.08,
                  9.42: 0.07,
                  9.45: 0.04,
                  9.48: 0.02,
                  9.51: 0.01,
                  9.54: 0.0,
                  9.57: 0.0,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.01,
                  9.93: 0.03,
                  9.96: 0.06,
                  9.99: 0.08,
                  10.02: 0.09,
                  10.05: 0.11,
                  10.08: 0.13,
                  10.11: 0.16,
                  10.14: 0.15,
                  10.17: 0.1,
                  10.2: 0.05,
                  10.23: 0.02,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.01,
                  10.44: 0.03,
                  10.47: 0.06,
                  10.5: 0.08,
                  10.53: 0.08,
                  10.56: 0.08,
                  10.59: 0.08,
                  10.62: 0.08,
                  10.65: 0.08,
                  10.68: 0.05,
                  10.71: 0.02,
                  10.74: 0.01,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.01,
                  11.07: 0.03,
                  11.1: 0.05,
                  11.13: 0.08,
                  11.16: 0.09,
                  11.19: 0.1,
                  11.22: 0.1,
                  11.25: 0.09,
                  11.28: 0.07,
                  11.31: 0.04,
                  11.34: 0.02,
                  11.37: 0.02,
                  11.4: 0.04,
                  11.43: 0.07,
                  11.46: 0.08,
                  11.49: 0.06,
                  11.52: 0.03,
                  11.55: 0.01,
                  11.58: 0.0,
                  11.61: 0.0,
                  11.64: 0.0,
                  11.67: 0.0,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.0,
                  12.12: 0.0,
                  12.15: 0.0,
                  12.18: 0.0,
                  12.21: 0.0,
                  12.24: 0.0,
                  12.27: 0.0,
                  12.3: 0.0,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.01,
                  12.51: 0.02,
                  12.54: 0.04,
                  12.57: 0.07,
                  12.6: 0.08,
                  12.63: 0.06,
                  12.66: 0.04,
                  12.69: 0.01,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.0,
                  12.81: 0.0,
                  12.84: 0.0,
                  12.87: 0.0,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.01,
                  13.56: 0.04,
                  13.59: 0.06,
                  13.62: 0.08,
                  13.65: 0.07,
                  13.68: 0.04,
                  13.71: 0.02,
                  13.74: 0.01,
                  13.77: 0.0,
                  13.8: 0.0,
                  13.83: 0.0,
                  13.86: 0.0,
                  13.89: 0.0,
                  13.92: 0.0,
                  13.95: 0.0,
                  13.98: 0.0,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.01,
                  14.34: 0.04,
                  14.37: 0.09,
                  14.4: 0.14,
                  14.43: 0.19,
                  14.46: 0.2,
                  14.49: 0.18,
                  14.52: 0.14,
                  14.55: 0.13,
                  14.58: 0.16,
                  14.61: 0.16,
                  14.64: 0.12,
                  14.67: 0.07,
                  14.7: 0.03,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.01,
                  14.82: 0.02,
                  14.85: 0.04,
                  14.88: 0.07,
                  14.91: 0.08,
                  14.94: 0.06,
                  14.97: 0.03,
                  '15.0': 0.01,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.01,
                  15.15: 0.02,
                  15.18: 0.05,
                  15.21: 0.07,
                  15.24: 0.08,
                  15.27: 0.06,
                  15.3: 0.03,
                  15.33: 0.01,
                  15.36: 0.01,
                  15.39: 0.03,
                  15.42: 0.06,
                  15.45: 0.08,
                  15.48: 0.08,
                  15.51: 0.08,
                  15.54: 0.09,
                  15.57: 0.1,
                  15.6: 0.12,
                  15.63: 0.12,
                  15.66: 0.1,
                  15.69: 0.07,
                  15.72: 0.04,
                  15.75: 0.01,
                  15.78: 0.01,
                  15.81: 0.02,
                  15.84: 0.04,
                  15.87: 0.07,
                  15.9: 0.08,
                  15.93: 0.06,
                  15.96: 0.03,
                  15.99: 0.01,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.09,
                  16.32: 0.08,
                  16.35: 0.08,
                  16.38: 0.09,
                  16.41: 0.07,
                  16.44: 0.06,
                  16.47: 0.06,
                  16.5: 0.07,
                  16.53: 0.08,
                  16.56: 0.07,
                  16.59: 0.04,
                  16.62: 0.02,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.02,
                  16.98: 0.04,
                  17.01: 0.08,
                  17.04: 0.13,
                  17.07: 0.14,
                  17.1: 0.12,
                  17.13: 0.08,
                  17.16: 0.04,
                  17.19: 0.01,
                  17.22: 0.01,
                  17.25: 0.02,
                  17.28: 0.05,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.06,
                  17.4: 0.03,
                  17.43: 0.01,
                  17.46: 0.0,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.01,
                  17.64: 0.03,
                  17.67: 0.06,
                  17.7: 0.08,
                  17.73: 0.07,
                  17.76: 0.04,
                  17.79: 0.02,
                  17.82: 0.01,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.0,
                  18.57: 0.0,
                  18.6: 0.0,
                  18.63: 0.0,
                  18.66: 0.0,
                  18.69: 0.0,
                  18.72: 0.0,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.02,
                  20.82: 0.04,
                  20.85: 0.07,
                  20.88: 0.08,
                  20.91: 0.07,
                  20.94: 0.04,
                  20.97: 0.02,
                  '21.0': 0.01,
                  21.03: 0.02,
                  21.06: 0.05,
                  21.09: 0.07,
                  21.12: 0.08,
                  21.15: 0.06,
                  21.18: 0.03,
                  21.21: 0.01,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.01,
                  22.65: 0.03,
                  22.68: 0.06,
                  22.71: 0.08,
                  22.74: 0.07,
                  22.77: 0.05,
                  22.8: 0.02,
                  22.83: 0.01,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.0,
                  24.09: 0.0,
                  24.12: 0.0,
                  24.15: 0.0,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.01,
                  24.54: 0.03,
                  24.57: 0.06,
                  24.6: 0.08,
                  24.63: 0.08,
                  24.66: 0.05,
                  24.69: 0.02,
                  24.72: 0.01,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.01,
                  24.93: 0.02,
                  24.96: 0.05,
                  24.99: 0.08,
                  25.02: 0.08,
                  25.05: 0.06,
                  25.08: 0.03,
                  25.11: 0.01,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.01,
                  25.74: 0.02,
                  25.77: 0.05,
                  25.8: 0.08,
                  25.83: 0.08,
                  25.86: 0.06,
                  25.89: 0.03,
                  25.92: 0.01,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.01,
                  27.21: 0.03,
                  27.24: 0.05,
                  27.27: 0.08,
                  27.3: 0.08,
                  27.33: 0.05,
                  27.36: 0.03,
                  27.39: 0.01,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.01,
                  33.96: 0.02,
                  33.99: 0.04,
                  34.02: 0.07,
                  34.05: 0.08,
                  34.08: 0.06,
                  34.11: 0.04,
                  34.14: 0.01,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.01,
                  40.74: 0.02,
                  40.77: 0.04,
                  40.8: 0.07,
                  40.83: 0.08,
                  40.86: 0.06,
                  40.89: 0.04,
                  40.92: 0.01,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.0,
                  41.58: 0.0,
                  41.61: 0.0,
                  41.64: 0.0,
                  41.67: 0.0,
                  41.7: 0.0,
                  41.73: 0.0,
                  41.76: 0.0,
                  41.79: 0.01,
                  41.82: 0.02,
                  41.85: 0.05,
                  41.88: 0.07,
                  41.91: 0.08,
                  41.94: 0.06,
                  41.97: 0.03,
                },
                iteration_median: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.14,
                  0.18: 0.18,
                  0.21: 0.19,
                  0.24: 0.18,
                  0.27: 0.15,
                  0.3: 0.13,
                  0.33: 0.11,
                  0.36: 0.1,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.15,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.06,
                  1.26: 0.08,
                  1.29: 0.08,
                  1.32: 0.07,
                  1.35: 0.08,
                  1.38: 0.12,
                  1.41: 0.16,
                  1.44: 0.17,
                  1.47: 0.16,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.08,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.16,
                  2.28: 0.11,
                  2.31: 0.07,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.15,
                  2.73: 0.12,
                  2.76: 0.09,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.01,
                  3.36: 0.03,
                  3.39: 0.06,
                  3.42: 0.11,
                  3.45: 0.14,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.06,
                  3.57: 0.02,
                  3.6: 0.01,
                  3.63: 0.0,
                  3.66: 0.01,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.02,
                  3.9: 0.01,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.03,
                  4.65: 0.02,
                  4.68: 0.03,
                  4.71: 0.06,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.16,
                  4.95: 0.1,
                  4.98: 0.05,
                  5.01: 0.02,
                  5.04: 0.02,
                  5.07: 0.04,
                  5.1: 0.07,
                  5.13: 0.06,
                  5.16: 0.07,
                  5.19: 0.04,
                  5.22: 0.03,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.05,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.07,
                  5.7: 0.04,
                  5.73: 0.02,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.01,
                  5.85: 0.02,
                  5.88: 0.05,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.01,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.11,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.06,
                  7.02: 0.03,
                  7.05: 0.01,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.05,
                  7.23: 0.08,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.04,
                  8.22: 0.02,
                  8.25: 0.01,
                  8.28: 0.0,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.04,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.07,
                  8.55: 0.04,
                  8.58: 0.02,
                  8.61: 0.03,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.04,
                  9.24: 0.08,
                  9.27: 0.12,
                  9.3: 0.12,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.05,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.07,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.08,
                  10.71: 0.05,
                  10.74: 0.04,
                  10.77: 0.05,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.03,
                  10.92: 0.01,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.01,
                  11.1: 0.03,
                  11.13: 0.06,
                  11.16: 0.08,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.01,
                  13.62: 0.03,
                  13.65: 0.06,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.05,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.07,
                  14.31: 0.04,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.05,
                  14.49: 0.03,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.06,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.05,
                  14.7: 0.02,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.01,
                  15.24: 0.02,
                  15.27: 0.04,
                  15.3: 0.07,
                  15.33: 0.08,
                  15.36: 0.06,
                  15.39: 0.04,
                  15.42: 0.01,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.01,
                  16.59: 0.03,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.01,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.01,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.05,
                  22.89: 0.02,
                  22.92: 0.01,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.04,
                  26.52: 0.02,
                  26.55: 0.01,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
              },
              'north america': {
                iteration_0: {
                  '0.0': 0.16,
                  0.03: 0.21,
                  0.06: 0.21,
                  0.09: 0.17,
                  0.12: 0.14,
                  0.15: 0.14,
                  0.18: 0.18,
                  0.21: 0.19,
                  0.24: 0.18,
                  0.27: 0.15,
                  0.3: 0.13,
                  0.33: 0.11,
                  0.36: 0.1,
                  0.39: 0.07,
                  0.42: 0.04,
                  0.45: 0.01,
                  0.48: 0.0,
                  0.51: 0.0,
                  0.54: 0.0,
                  0.57: 0.0,
                  0.6: 0.0,
                  0.63: 0.0,
                  0.66: 0.0,
                  0.69: 0.01,
                  0.72: 0.02,
                  0.75: 0.05,
                  0.78: 0.07,
                  0.81: 0.08,
                  0.84: 0.07,
                  0.87: 0.06,
                  0.9: 0.07,
                  0.93: 0.13,
                  0.96: 0.22,
                  0.99: 0.32,
                  1.02: 0.38,
                  1.05: 0.35,
                  1.08: 0.25,
                  1.11: 0.15,
                  1.14: 0.07,
                  1.17: 0.04,
                  1.2: 0.04,
                  1.23: 0.06,
                  1.26: 0.08,
                  1.29: 0.08,
                  1.32: 0.07,
                  1.35: 0.08,
                  1.38: 0.12,
                  1.41: 0.16,
                  1.44: 0.17,
                  1.47: 0.16,
                  1.5: 0.17,
                  1.53: 0.18,
                  1.56: 0.17,
                  1.59: 0.15,
                  1.62: 0.13,
                  1.65: 0.13,
                  1.68: 0.13,
                  1.71: 0.12,
                  1.74: 0.12,
                  1.77: 0.14,
                  1.8: 0.18,
                  1.83: 0.22,
                  1.86: 0.21,
                  1.89: 0.17,
                  1.92: 0.14,
                  1.95: 0.16,
                  1.98: 0.2,
                  2.01: 0.22,
                  2.04: 0.19,
                  2.07: 0.13,
                  2.1: 0.08,
                  2.13: 0.08,
                  2.16: 0.14,
                  2.19: 0.2,
                  2.22: 0.22,
                  2.25: 0.18,
                  2.28: 0.11,
                  2.31: 0.07,
                  2.34: 0.05,
                  2.37: 0.07,
                  2.4: 0.08,
                  2.43: 0.07,
                  2.46: 0.04,
                  2.49: 0.02,
                  2.52: 0.01,
                  2.55: 0.0,
                  2.58: 0.01,
                  2.61: 0.04,
                  2.64: 0.07,
                  2.67: 0.11,
                  2.7: 0.12,
                  2.73: 0.12,
                  2.76: 0.09,
                  2.79: 0.06,
                  2.82: 0.03,
                  2.85: 0.01,
                  2.88: 0.0,
                  2.91: 0.0,
                  2.94: 0.0,
                  2.97: 0.0,
                  '3.0': 0.0,
                  3.03: 0.0,
                  3.06: 0.0,
                  3.09: 0.0,
                  3.12: 0.0,
                  3.15: 0.0,
                  3.18: 0.0,
                  3.21: 0.0,
                  3.24: 0.0,
                  3.27: 0.0,
                  3.3: 0.0,
                  3.33: 0.01,
                  3.36: 0.03,
                  3.39: 0.06,
                  3.42: 0.11,
                  3.45: 0.14,
                  3.48: 0.14,
                  3.51: 0.1,
                  3.54: 0.06,
                  3.57: 0.02,
                  3.6: 0.01,
                  3.63: 0.0,
                  3.66: 0.01,
                  3.69: 0.04,
                  3.72: 0.1,
                  3.75: 0.15,
                  3.78: 0.16,
                  3.81: 0.12,
                  3.84: 0.06,
                  3.87: 0.02,
                  3.9: 0.01,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.0,
                  4.02: 0.0,
                  4.05: 0.0,
                  4.08: 0.0,
                  4.11: 0.0,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.0,
                  4.23: 0.0,
                  4.26: 0.02,
                  4.29: 0.05,
                  4.32: 0.12,
                  4.35: 0.19,
                  4.38: 0.23,
                  4.41: 0.23,
                  4.44: 0.21,
                  4.47: 0.22,
                  4.5: 0.24,
                  4.53: 0.22,
                  4.56: 0.15,
                  4.59: 0.07,
                  4.62: 0.03,
                  4.65: 0.02,
                  4.68: 0.03,
                  4.71: 0.06,
                  4.74: 0.09,
                  4.77: 0.12,
                  4.8: 0.14,
                  4.83: 0.17,
                  4.86: 0.19,
                  4.89: 0.19,
                  4.92: 0.16,
                  4.95: 0.1,
                  4.98: 0.05,
                  5.01: 0.02,
                  5.04: 0.02,
                  5.07: 0.04,
                  5.1: 0.07,
                  5.13: 0.08,
                  5.16: 0.07,
                  5.19: 0.04,
                  5.22: 0.03,
                  5.25: 0.03,
                  5.28: 0.06,
                  5.31: 0.08,
                  5.34: 0.07,
                  5.37: 0.05,
                  5.4: 0.02,
                  5.43: 0.01,
                  5.46: 0.0,
                  5.49: 0.0,
                  5.52: 0.0,
                  5.55: 0.02,
                  5.58: 0.04,
                  5.61: 0.07,
                  5.64: 0.08,
                  5.67: 0.07,
                  5.7: 0.04,
                  5.73: 0.02,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.01,
                  5.85: 0.02,
                  5.88: 0.05,
                  5.91: 0.08,
                  5.94: 0.08,
                  5.97: 0.06,
                  '6.0': 0.05,
                  6.03: 0.05,
                  6.06: 0.07,
                  6.09: 0.08,
                  6.12: 0.06,
                  6.15: 0.03,
                  6.18: 0.01,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.0,
                  6.33: 0.0,
                  6.36: 0.0,
                  6.39: 0.0,
                  6.42: 0.0,
                  6.45: 0.0,
                  6.48: 0.01,
                  6.51: 0.02,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.09,
                  6.66: 0.1,
                  6.69: 0.11,
                  6.72: 0.13,
                  6.75: 0.14,
                  6.78: 0.14,
                  6.81: 0.14,
                  6.84: 0.12,
                  6.87: 0.09,
                  6.9: 0.08,
                  6.93: 0.08,
                  6.96: 0.08,
                  6.99: 0.06,
                  7.02: 0.03,
                  7.05: 0.01,
                  7.08: 0.0,
                  7.11: 0.0,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.05,
                  7.23: 0.08,
                  7.26: 0.09,
                  7.29: 0.08,
                  7.32: 0.08,
                  7.35: 0.09,
                  7.38: 0.08,
                  7.41: 0.05,
                  7.44: 0.03,
                  7.47: 0.01,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.0,
                  7.77: 0.0,
                  7.8: 0.0,
                  7.83: 0.0,
                  7.86: 0.0,
                  7.89: 0.0,
                  7.92: 0.02,
                  7.95: 0.04,
                  7.98: 0.07,
                  8.01: 0.08,
                  8.04: 0.08,
                  8.07: 0.07,
                  8.1: 0.08,
                  8.13: 0.08,
                  8.16: 0.07,
                  8.19: 0.04,
                  8.22: 0.02,
                  8.25: 0.01,
                  8.28: 0.0,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.04,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.07,
                  8.55: 0.04,
                  8.58: 0.02,
                  8.61: 0.0,
                  8.64: 0.0,
                  8.67: 0.0,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.0,
                  8.82: 0.0,
                  8.85: 0.0,
                  8.88: 0.0,
                  8.91: 0.0,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.0,
                  9.12: 0.0,
                  9.15: 0.0,
                  9.18: 0.01,
                  9.21: 0.04,
                  9.24: 0.08,
                  9.27: 0.12,
                  9.3: 0.14,
                  9.33: 0.12,
                  9.36: 0.09,
                  9.39: 0.05,
                  9.42: 0.04,
                  9.45: 0.05,
                  9.48: 0.07,
                  9.51: 0.08,
                  9.54: 0.06,
                  9.57: 0.03,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.0,
                  9.96: 0.0,
                  9.99: 0.0,
                  10.02: 0.0,
                  10.05: 0.0,
                  10.08: 0.0,
                  10.11: 0.0,
                  10.14: 0.0,
                  10.17: 0.0,
                  10.2: 0.0,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.01,
                  10.44: 0.03,
                  10.47: 0.07,
                  10.5: 0.11,
                  10.53: 0.13,
                  10.56: 0.14,
                  10.59: 0.13,
                  10.62: 0.12,
                  10.65: 0.1,
                  10.68: 0.08,
                  10.71: 0.05,
                  10.74: 0.04,
                  10.77: 0.05,
                  10.8: 0.07,
                  10.83: 0.08,
                  10.86: 0.06,
                  10.89: 0.03,
                  10.92: 0.01,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.01,
                  11.1: 0.03,
                  11.13: 0.06,
                  11.16: 0.08,
                  11.19: 0.07,
                  11.22: 0.05,
                  11.25: 0.02,
                  11.28: 0.01,
                  11.31: 0.0,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.0,
                  11.61: 0.0,
                  11.64: 0.0,
                  11.67: 0.0,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.0,
                  12.12: 0.0,
                  12.15: 0.0,
                  12.18: 0.0,
                  12.21: 0.0,
                  12.24: 0.0,
                  12.27: 0.0,
                  12.3: 0.0,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.0,
                  12.81: 0.0,
                  12.84: 0.0,
                  12.87: 0.0,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.01,
                  13.62: 0.03,
                  13.65: 0.06,
                  13.68: 0.1,
                  13.71: 0.13,
                  13.74: 0.15,
                  13.77: 0.17,
                  13.8: 0.18,
                  13.83: 0.18,
                  13.86: 0.15,
                  13.89: 0.1,
                  13.92: 0.05,
                  13.95: 0.03,
                  13.98: 0.05,
                  14.01: 0.09,
                  14.04: 0.14,
                  14.07: 0.15,
                  14.1: 0.12,
                  14.13: 0.07,
                  14.16: 0.04,
                  14.19: 0.05,
                  14.22: 0.07,
                  14.25: 0.08,
                  14.28: 0.07,
                  14.31: 0.04,
                  14.34: 0.04,
                  14.37: 0.06,
                  14.4: 0.08,
                  14.43: 0.08,
                  14.46: 0.05,
                  14.49: 0.03,
                  14.52: 0.02,
                  14.55: 0.03,
                  14.58: 0.06,
                  14.61: 0.08,
                  14.64: 0.07,
                  14.67: 0.05,
                  14.7: 0.02,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.01,
                  15.24: 0.02,
                  15.27: 0.04,
                  15.3: 0.07,
                  15.33: 0.08,
                  15.36: 0.06,
                  15.39: 0.04,
                  15.42: 0.01,
                  15.45: 0.0,
                  15.48: 0.0,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.0,
                  15.63: 0.0,
                  15.66: 0.0,
                  15.69: 0.0,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.0,
                  16.2: 0.0,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.01,
                  16.59: 0.03,
                  16.62: 0.06,
                  16.65: 0.08,
                  16.68: 0.07,
                  16.71: 0.04,
                  16.74: 0.02,
                  16.77: 0.01,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.02,
                  16.95: 0.05,
                  16.98: 0.09,
                  17.01: 0.13,
                  17.04: 0.14,
                  17.07: 0.12,
                  17.1: 0.07,
                  17.13: 0.03,
                  17.16: 0.01,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.0,
                  17.37: 0.0,
                  17.4: 0.0,
                  17.43: 0.0,
                  17.46: 0.0,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.01,
                  17.97: 0.02,
                  '18.0': 0.06,
                  18.03: 0.11,
                  18.06: 0.15,
                  18.09: 0.15,
                  18.12: 0.1,
                  18.15: 0.05,
                  18.18: 0.02,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.0,
                  18.57: 0.0,
                  18.6: 0.0,
                  18.63: 0.0,
                  18.66: 0.0,
                  18.69: 0.0,
                  18.72: 0.0,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.01,
                  19.14: 0.04,
                  19.17: 0.06,
                  19.2: 0.08,
                  19.23: 0.07,
                  19.26: 0.04,
                  19.29: 0.02,
                  19.32: 0.01,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.01,
                  20.13: 0.02,
                  20.16: 0.05,
                  20.19: 0.07,
                  20.22: 0.08,
                  20.25: 0.06,
                  20.28: 0.03,
                  20.31: 0.01,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.01,
                  21.96: 0.04,
                  21.99: 0.06,
                  22.02: 0.08,
                  22.05: 0.07,
                  22.08: 0.04,
                  22.11: 0.02,
                  22.14: 0.01,
                  22.17: 0.03,
                  22.2: 0.05,
                  22.23: 0.08,
                  22.26: 0.08,
                  22.29: 0.05,
                  22.32: 0.03,
                  22.35: 0.01,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.01,
                  22.74: 0.03,
                  22.77: 0.06,
                  22.8: 0.08,
                  22.83: 0.07,
                  22.86: 0.05,
                  22.89: 0.02,
                  22.92: 0.01,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.0,
                  24.09: 0.0,
                  24.12: 0.0,
                  24.15: 0.0,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.01,
                  24.54: 0.02,
                  24.57: 0.04,
                  24.6: 0.07,
                  24.63: 0.08,
                  24.66: 0.07,
                  24.69: 0.05,
                  24.72: 0.05,
                  24.75: 0.07,
                  24.78: 0.08,
                  24.81: 0.07,
                  24.84: 0.04,
                  24.87: 0.01,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.01,
                  25.29: 0.03,
                  25.32: 0.06,
                  25.35: 0.08,
                  25.38: 0.07,
                  25.41: 0.05,
                  25.44: 0.02,
                  25.47: 0.01,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.01,
                  26.37: 0.03,
                  26.4: 0.06,
                  26.43: 0.08,
                  26.46: 0.07,
                  26.49: 0.04,
                  26.52: 0.02,
                  26.55: 0.01,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.02,
                  27.63: 0.04,
                  27.66: 0.07,
                  27.69: 0.08,
                  27.72: 0.06,
                  27.75: 0.04,
                  27.78: 0.01,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.01,
                  30.75: 0.04,
                  30.78: 0.06,
                  30.81: 0.08,
                  30.84: 0.07,
                  30.87: 0.04,
                  30.9: 0.02,
                  30.93: 0.01,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.01,
                  36.3: 0.03,
                  36.33: 0.06,
                  36.36: 0.08,
                  36.39: 0.07,
                  36.42: 0.05,
                  36.45: 0.02,
                  36.48: 0.01,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.0,
                  40.74: 0.0,
                  40.77: 0.0,
                  40.8: 0.0,
                  40.83: 0.0,
                  40.86: 0.0,
                  40.89: 0.0,
                  40.92: 0.0,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.0,
                  41.58: 0.0,
                  41.61: 0.0,
                  41.64: 0.0,
                  41.67: 0.0,
                  41.7: 0.0,
                  41.73: 0.0,
                  41.76: 0.0,
                  41.79: 0.0,
                  41.82: 0.0,
                  41.85: 0.0,
                  41.88: 0.0,
                  41.91: 0.0,
                  41.94: 0.0,
                  41.97: 0.0,
                  '42.0': 0.0,
                  42.03: 0.0,
                  42.06: 0.0,
                  42.09: 0.0,
                  42.12: 0.0,
                  42.15: 0.0,
                  42.18: 0.0,
                  42.21: 0.0,
                  42.24: 0.0,
                  42.27: 0.0,
                  42.3: 0.0,
                  42.33: 0.0,
                  42.36: 0.0,
                  42.39: 0.0,
                  42.42: 0.0,
                  42.45: 0.0,
                  42.48: 0.0,
                  42.51: 0.0,
                  42.54: 0.0,
                  42.57: 0.0,
                  42.6: 0.0,
                  42.63: 0.0,
                  42.66: 0.0,
                  42.69: 0.0,
                  42.72: 0.0,
                  42.75: 0.0,
                  42.78: 0.0,
                  42.81: 0.0,
                  42.84: 0.0,
                  42.87: 0.0,
                  42.9: 0.0,
                  42.93: 0.0,
                  42.96: 0.0,
                  42.99: 0.0,
                  43.02: 0.0,
                  43.05: 0.0,
                  43.08: 0.0,
                  43.11: 0.0,
                  43.14: 0.0,
                  43.17: 0.0,
                  43.2: 0.0,
                  43.23: 0.0,
                  43.26: 0.0,
                  43.29: 0.0,
                  43.32: 0.0,
                  43.35: 0.0,
                  43.38: 0.0,
                  43.41: 0.0,
                  43.44: 0.0,
                  43.47: 0.0,
                  43.5: 0.0,
                  43.53: 0.0,
                  43.56: 0.0,
                  43.59: 0.0,
                  43.62: 0.0,
                  43.65: 0.0,
                  43.68: 0.0,
                  43.71: 0.0,
                  43.74: 0.0,
                  43.77: 0.0,
                  43.8: 0.0,
                  43.83: 0.0,
                  43.86: 0.0,
                  43.89: 0.0,
                  43.92: 0.0,
                  43.95: 0.0,
                  43.98: 0.0,
                  44.01: 0.0,
                  44.04: 0.0,
                  44.07: 0.0,
                  44.1: 0.0,
                  44.13: 0.0,
                  44.16: 0.0,
                  44.19: 0.0,
                  44.22: 0.0,
                  44.25: 0.0,
                  44.28: 0.0,
                  44.31: 0.0,
                  44.34: 0.0,
                  44.37: 0.0,
                  44.4: 0.0,
                  44.43: 0.0,
                  44.46: 0.0,
                  44.49: 0.0,
                  44.52: 0.0,
                  44.55: 0.0,
                  44.58: 0.0,
                  44.61: 0.0,
                  44.64: 0.0,
                  44.67: 0.0,
                  44.7: 0.0,
                  44.73: 0.0,
                  44.76: 0.0,
                  44.79: 0.0,
                  44.82: 0.0,
                  44.85: 0.0,
                  44.88: 0.0,
                  44.91: 0.0,
                  44.94: 0.0,
                  44.97: 0.0,
                  '45.0': 0.0,
                  45.03: 0.0,
                  45.06: 0.0,
                  45.09: 0.0,
                  45.12: 0.0,
                  45.15: 0.0,
                  45.18: 0.0,
                  45.21: 0.0,
                  45.24: 0.0,
                  45.27: 0.0,
                  45.3: 0.0,
                  45.33: 0.0,
                  45.36: 0.0,
                  45.39: 0.0,
                  45.42: 0.0,
                  45.45: 0.0,
                  45.48: 0.0,
                  45.51: 0.0,
                  45.54: 0.0,
                  45.57: 0.0,
                  45.6: 0.0,
                  45.63: 0.0,
                  45.66: 0.0,
                  45.69: 0.0,
                  45.72: 0.0,
                  45.75: 0.0,
                  45.78: 0.0,
                  45.81: 0.0,
                  45.84: 0.0,
                  45.87: 0.0,
                  45.9: 0.0,
                  45.93: 0.0,
                  45.96: 0.0,
                  45.99: 0.0,
                  46.02: 0.0,
                  46.05: 0.0,
                  46.08: 0.0,
                  46.11: 0.0,
                  46.14: 0.0,
                  46.17: 0.0,
                  46.2: 0.0,
                  46.23: 0.0,
                  46.26: 0.0,
                  46.29: 0.0,
                  46.32: 0.0,
                  46.35: 0.0,
                  46.38: 0.0,
                  46.41: 0.0,
                  46.44: 0.0,
                  46.47: 0.0,
                  46.5: 0.0,
                  46.53: 0.0,
                  46.56: 0.0,
                  46.59: 0.0,
                  46.62: 0.0,
                  46.65: 0.0,
                  46.68: 0.0,
                  46.71: 0.0,
                  46.74: 0.0,
                  46.77: 0.0,
                  46.8: 0.0,
                  46.83: 0.0,
                  46.86: 0.0,
                  46.89: 0.0,
                  46.92: 0.0,
                  46.95: 0.0,
                  46.98: 0.0,
                  47.01: 0.0,
                  47.04: 0.0,
                  47.07: 0.0,
                  47.1: 0.0,
                  47.13: 0.0,
                  47.16: 0.0,
                  47.19: 0.0,
                  47.22: 0.0,
                  47.25: 0.0,
                  47.28: 0.0,
                  47.31: 0.0,
                  47.34: 0.0,
                  47.37: 0.0,
                  47.4: 0.0,
                  47.43: 0.0,
                  47.46: 0.0,
                  47.49: 0.0,
                  47.52: 0.0,
                  47.55: 0.0,
                  47.58: 0.0,
                  47.61: 0.0,
                  47.64: 0.0,
                  47.67: 0.0,
                  47.7: 0.0,
                  47.73: 0.0,
                  47.76: 0.0,
                  47.79: 0.0,
                  47.82: 0.0,
                  47.85: 0.0,
                  47.88: 0.0,
                  47.91: 0.0,
                  47.94: 0.0,
                  47.97: 0.0,
                  '48.0': 0.0,
                  48.03: 0.0,
                  48.06: 0.0,
                  48.09: 0.0,
                  48.12: 0.0,
                  48.15: 0.0,
                  48.18: 0.0,
                  48.21: 0.0,
                  48.24: 0.0,
                  48.27: 0.0,
                  48.3: 0.0,
                  48.33: 0.0,
                  48.36: 0.0,
                  48.39: 0.0,
                  48.42: 0.0,
                  48.45: 0.0,
                  48.48: 0.0,
                  48.51: 0.0,
                  48.54: 0.0,
                  48.57: 0.0,
                  48.6: 0.0,
                  48.63: 0.0,
                  48.66: 0.0,
                  48.69: 0.0,
                  48.72: 0.0,
                  48.75: 0.0,
                  48.78: 0.0,
                  48.81: 0.0,
                  48.84: 0.0,
                  48.87: 0.0,
                  48.9: 0.0,
                  48.93: 0.0,
                  48.96: 0.0,
                  48.99: 0.0,
                  49.02: 0.0,
                  49.05: 0.0,
                  49.08: 0.0,
                  49.11: 0.0,
                  49.14: 0.0,
                  49.17: 0.0,
                  49.2: 0.0,
                  49.23: 0.0,
                  49.26: 0.0,
                  49.29: 0.0,
                  49.32: 0.0,
                  49.35: 0.0,
                  49.38: 0.0,
                  49.41: 0.0,
                  49.44: 0.0,
                  49.47: 0.0,
                  49.5: 0.0,
                  49.53: 0.0,
                  49.56: 0.0,
                  49.59: 0.0,
                  49.62: 0.0,
                  49.65: 0.0,
                  49.68: 0.0,
                  49.71: 0.0,
                  49.74: 0.0,
                  49.77: 0.0,
                  49.8: 0.0,
                  49.83: 0.0,
                  49.86: 0.0,
                  49.89: 0.0,
                  49.92: 0.0,
                  49.95: 0.0,
                  49.98: 0.0,
                  50.01: 0.0,
                  50.04: 0.0,
                  50.07: 0.0,
                  50.1: 0.0,
                  50.13: 0.0,
                  50.16: 0.0,
                  50.19: 0.0,
                  50.22: 0.01,
                  50.25: 0.03,
                  50.28: 0.06,
                  50.31: 0.08,
                  50.34: 0.07,
                  50.37: 0.05,
                  50.4: 0.02,
                  50.43: 0.01,
                  50.46: 0.0,
                  50.49: 0.0,
                  50.52: 0.0,
                  50.55: 0.0,
                  50.58: 0.0,
                  50.61: 0.0,
                  50.64: 0.0,
                  50.67: 0.0,
                  50.7: 0.0,
                  50.73: 0.0,
                  50.76: 0.0,
                  50.79: 0.0,
                  50.82: 0.0,
                  50.85: 0.0,
                  50.88: 0.0,
                  50.91: 0.0,
                  50.94: 0.0,
                  50.97: 0.0,
                  '51.0': 0.0,
                  51.03: 0.0,
                  51.06: 0.0,
                  51.09: 0.0,
                  51.12: 0.0,
                  51.15: 0.0,
                  51.18: 0.0,
                  51.21: 0.0,
                  51.24: 0.0,
                  51.27: 0.0,
                  51.3: 0.0,
                  51.33: 0.0,
                  51.36: 0.0,
                  51.39: 0.0,
                  51.42: 0.0,
                  51.45: 0.0,
                  51.48: 0.0,
                  51.51: 0.0,
                  51.54: 0.0,
                  51.57: 0.0,
                  51.6: 0.0,
                  51.63: 0.0,
                  51.66: 0.0,
                  51.69: 0.0,
                  51.72: 0.0,
                  51.75: 0.0,
                  51.78: 0.0,
                  51.81: 0.0,
                  51.84: 0.0,
                  51.87: 0.0,
                  51.9: 0.0,
                  51.93: 0.0,
                  51.96: 0.0,
                  51.99: 0.0,
                  52.02: 0.0,
                  52.05: 0.0,
                  52.08: 0.0,
                  52.11: 0.0,
                  52.14: 0.0,
                  52.17: 0.0,
                  52.2: 0.0,
                  52.23: 0.0,
                  52.26: 0.0,
                  52.29: 0.0,
                  52.32: 0.0,
                  52.35: 0.0,
                  52.38: 0.0,
                  52.41: 0.0,
                  52.44: 0.0,
                  52.47: 0.0,
                  52.5: 0.0,
                  52.53: 0.0,
                  52.56: 0.0,
                  52.59: 0.0,
                  52.62: 0.0,
                  52.65: 0.0,
                  52.68: 0.0,
                  52.71: 0.0,
                  52.74: 0.0,
                  52.77: 0.0,
                  52.8: 0.0,
                  52.83: 0.0,
                  52.86: 0.0,
                  52.89: 0.0,
                  52.92: 0.0,
                  52.95: 0.0,
                  52.98: 0.0,
                  53.01: 0.0,
                  53.04: 0.0,
                  53.07: 0.0,
                  53.1: 0.0,
                  53.13: 0.0,
                  53.16: 0.0,
                  53.19: 0.0,
                  53.22: 0.0,
                  53.25: 0.0,
                  53.28: 0.0,
                  53.31: 0.0,
                  53.34: 0.0,
                  53.37: 0.0,
                  53.4: 0.0,
                  53.43: 0.0,
                  53.46: 0.0,
                  53.49: 0.0,
                  53.52: 0.0,
                  53.55: 0.0,
                  53.58: 0.0,
                  53.61: 0.0,
                  53.64: 0.0,
                  53.67: 0.0,
                  53.7: 0.0,
                  53.73: 0.0,
                  53.76: 0.0,
                  53.79: 0.0,
                  53.82: 0.0,
                  53.85: 0.0,
                  53.88: 0.0,
                  53.91: 0.0,
                  53.94: 0.0,
                  53.97: 0.0,
                  '54.0': 0.0,
                  54.03: 0.0,
                  54.06: 0.0,
                  54.09: 0.0,
                  54.12: 0.0,
                  54.15: 0.0,
                  54.18: 0.0,
                  54.21: 0.0,
                  54.24: 0.0,
                  54.27: 0.0,
                  54.3: 0.0,
                  54.33: 0.0,
                  54.36: 0.0,
                  54.39: 0.0,
                  54.42: 0.0,
                  54.45: 0.0,
                  54.48: 0.0,
                  54.51: 0.0,
                  54.54: 0.0,
                  54.57: 0.0,
                  54.6: 0.0,
                  54.63: 0.0,
                  54.66: 0.0,
                  54.69: 0.0,
                  54.72: 0.0,
                  54.75: 0.0,
                  54.78: 0.0,
                  54.81: 0.0,
                  54.84: 0.0,
                  54.87: 0.0,
                  54.9: 0.0,
                  54.93: 0.0,
                  54.96: 0.0,
                  54.99: 0.0,
                  55.02: 0.0,
                  55.05: 0.0,
                  55.08: 0.0,
                  55.11: 0.0,
                  55.14: 0.0,
                  55.17: 0.0,
                  55.2: 0.0,
                  55.23: 0.0,
                  55.26: 0.0,
                  55.29: 0.0,
                  55.32: 0.0,
                  55.35: 0.0,
                  55.38: 0.0,
                  55.41: 0.0,
                  55.44: 0.0,
                  55.47: 0.0,
                  55.5: 0.0,
                  55.53: 0.0,
                  55.56: 0.0,
                  55.59: 0.0,
                  55.62: 0.0,
                  55.65: 0.0,
                  55.68: 0.0,
                  55.71: 0.0,
                  55.74: 0.0,
                  55.77: 0.0,
                  55.8: 0.0,
                  55.83: 0.0,
                  55.86: 0.0,
                  55.89: 0.0,
                  55.92: 0.0,
                  55.95: 0.0,
                  55.98: 0.0,
                  56.01: 0.0,
                  56.04: 0.0,
                  56.07: 0.0,
                  56.1: 0.0,
                  56.13: 0.0,
                  56.16: 0.0,
                  56.19: 0.0,
                  56.22: 0.0,
                  56.25: 0.0,
                  56.28: 0.0,
                  56.31: 0.0,
                  56.34: 0.0,
                  56.37: 0.0,
                  56.4: 0.0,
                  56.43: 0.0,
                  56.46: 0.0,
                  56.49: 0.0,
                  56.52: 0.0,
                  56.55: 0.0,
                  56.58: 0.0,
                  56.61: 0.0,
                  56.64: 0.0,
                  56.67: 0.0,
                  56.7: 0.0,
                  56.73: 0.0,
                  56.76: 0.0,
                  56.79: 0.0,
                  56.82: 0.0,
                  56.85: 0.0,
                  56.88: 0.0,
                  56.91: 0.0,
                  56.94: 0.0,
                  56.97: 0.0,
                  '57.0': 0.0,
                  57.03: 0.0,
                  57.06: 0.0,
                  57.09: 0.0,
                  57.12: 0.0,
                  57.15: 0.0,
                  57.18: 0.0,
                  57.21: 0.0,
                  57.24: 0.0,
                  57.27: 0.0,
                  57.3: 0.0,
                  57.33: 0.0,
                  57.36: 0.0,
                  57.39: 0.0,
                  57.42: 0.0,
                  57.45: 0.0,
                  57.48: 0.0,
                  57.51: 0.0,
                  57.54: 0.0,
                  57.57: 0.0,
                  57.6: 0.0,
                  57.63: 0.0,
                  57.66: 0.0,
                  57.69: 0.0,
                  57.72: 0.0,
                  57.75: 0.0,
                  57.78: 0.0,
                  57.81: 0.0,
                  57.84: 0.0,
                  57.87: 0.0,
                  57.9: 0.0,
                  57.93: 0.0,
                  57.96: 0.0,
                  57.99: 0.0,
                  58.02: 0.0,
                  58.05: 0.0,
                  58.08: 0.0,
                  58.11: 0.0,
                  58.14: 0.0,
                  58.17: 0.0,
                  58.2: 0.0,
                  58.23: 0.0,
                  58.26: 0.0,
                  58.29: 0.0,
                  58.32: 0.0,
                  58.35: 0.0,
                  58.38: 0.0,
                  58.41: 0.0,
                  58.44: 0.0,
                  58.47: 0.0,
                  58.5: 0.0,
                  58.53: 0.0,
                  58.56: 0.0,
                  58.59: 0.0,
                  58.62: 0.0,
                  58.65: 0.0,
                  58.68: 0.0,
                  58.71: 0.0,
                  58.74: 0.0,
                  58.77: 0.0,
                  58.8: 0.0,
                  58.83: 0.0,
                  58.86: 0.0,
                  58.89: 0.0,
                  58.92: 0.0,
                  58.95: 0.0,
                  58.98: 0.0,
                  59.01: 0.0,
                  59.04: 0.0,
                  59.07: 0.0,
                  59.1: 0.0,
                  59.13: 0.0,
                  59.16: 0.0,
                  59.19: 0.01,
                  59.22: 0.04,
                  59.25: 0.07,
                  59.28: 0.08,
                  59.31: 0.07,
                  59.34: 0.04,
                  59.37: 0.02,
                  59.4: 0.0,
                  59.43: 0.0,
                  59.46: 0.0,
                  59.49: 0.0,
                  59.52: 0.0,
                  59.55: 0.0,
                  59.58: 0.0,
                  59.61: 0.0,
                  59.64: 0.0,
                  59.67: 0.0,
                  59.7: 0.0,
                  59.73: 0.0,
                  59.76: 0.0,
                  59.79: 0.0,
                  59.82: 0.0,
                  59.85: 0.0,
                  59.88: 0.0,
                  59.91: 0.0,
                  59.94: 0.0,
                  59.97: 0.0,
                  '60.0': 0.0,
                  60.03: 0.0,
                  60.06: 0.0,
                  60.09: 0.0,
                  60.12: 0.0,
                  60.15: 0.0,
                  60.18: 0.0,
                  60.21: 0.0,
                  60.24: 0.0,
                  60.27: 0.0,
                  60.3: 0.0,
                  60.33: 0.0,
                  60.36: 0.0,
                  60.39: 0.0,
                  60.42: 0.0,
                  60.45: 0.0,
                  60.48: 0.0,
                  60.51: 0.0,
                  60.54: 0.0,
                  60.57: 0.0,
                  60.6: 0.0,
                  60.63: 0.0,
                  60.66: 0.0,
                  60.69: 0.0,
                  60.72: 0.0,
                  60.75: 0.0,
                  60.78: 0.0,
                  60.81: 0.0,
                  60.84: 0.0,
                  60.87: 0.0,
                  60.9: 0.0,
                  60.93: 0.0,
                  60.96: 0.0,
                  60.99: 0.0,
                  61.02: 0.0,
                  61.05: 0.0,
                  61.08: 0.0,
                  61.11: 0.0,
                  61.14: 0.0,
                  61.17: 0.0,
                  61.2: 0.0,
                  61.23: 0.0,
                  61.26: 0.0,
                  61.29: 0.0,
                  61.32: 0.0,
                  61.35: 0.0,
                  61.38: 0.0,
                  61.41: 0.0,
                  61.44: 0.0,
                  61.47: 0.0,
                  61.5: 0.0,
                  61.53: 0.0,
                  61.56: 0.0,
                  61.59: 0.0,
                  61.62: 0.0,
                  61.65: 0.0,
                  61.68: 0.0,
                  61.71: 0.0,
                  61.74: 0.0,
                  61.77: 0.0,
                  61.8: 0.0,
                  61.83: 0.0,
                  61.86: 0.0,
                  61.89: 0.0,
                  61.92: 0.0,
                  61.95: 0.0,
                  61.98: 0.0,
                  62.01: 0.0,
                  62.04: 0.0,
                  62.07: 0.0,
                  62.1: 0.0,
                  62.13: 0.0,
                  62.16: 0.0,
                  62.19: 0.0,
                  62.22: 0.0,
                  62.25: 0.0,
                  62.28: 0.0,
                  62.31: 0.0,
                  62.34: 0.0,
                  62.37: 0.0,
                  62.4: 0.0,
                  62.43: 0.0,
                  62.46: 0.0,
                  62.49: 0.0,
                  62.52: 0.0,
                  62.55: 0.0,
                  62.58: 0.0,
                  62.61: 0.0,
                  62.64: 0.0,
                  62.67: 0.0,
                  62.7: 0.0,
                  62.73: 0.0,
                  62.76: 0.0,
                  62.79: 0.0,
                  62.82: 0.0,
                  62.85: 0.0,
                  62.88: 0.0,
                  62.91: 0.0,
                  62.94: 0.0,
                  62.97: 0.0,
                  '63.0': 0.0,
                  63.03: 0.0,
                  63.06: 0.0,
                  63.09: 0.0,
                  63.12: 0.0,
                  63.15: 0.0,
                  63.18: 0.0,
                  63.21: 0.0,
                  63.24: 0.0,
                  63.27: 0.0,
                  63.3: 0.0,
                  63.33: 0.0,
                  63.36: 0.0,
                  63.39: 0.0,
                  63.42: 0.0,
                  63.45: 0.0,
                  63.48: 0.0,
                  63.51: 0.0,
                  63.54: 0.0,
                  63.57: 0.0,
                  63.6: 0.0,
                  63.63: 0.0,
                  63.66: 0.0,
                  63.69: 0.0,
                  63.72: 0.0,
                  63.75: 0.0,
                  63.78: 0.0,
                  63.81: 0.0,
                  63.84: 0.0,
                  63.87: 0.0,
                  63.9: 0.0,
                  63.93: 0.0,
                  63.96: 0.0,
                  63.99: 0.0,
                  64.02: 0.0,
                  64.05: 0.0,
                  64.08: 0.0,
                  64.11: 0.0,
                  64.14: 0.0,
                  64.17: 0.0,
                  64.2: 0.0,
                  64.23: 0.0,
                  64.26: 0.0,
                  64.29: 0.0,
                  64.32: 0.0,
                  64.35: 0.0,
                  64.38: 0.0,
                  64.41: 0.0,
                  64.44: 0.0,
                  64.47: 0.0,
                  64.5: 0.0,
                  64.53: 0.0,
                  64.56: 0.0,
                  64.59: 0.0,
                  64.62: 0.0,
                  64.65: 0.0,
                  64.68: 0.0,
                  64.71: 0.0,
                  64.74: 0.0,
                  64.77: 0.0,
                  64.8: 0.0,
                  64.83: 0.0,
                  64.86: 0.0,
                  64.89: 0.0,
                  64.92: 0.0,
                  64.95: 0.0,
                  64.98: 0.0,
                  65.01: 0.0,
                  65.04: 0.0,
                  65.07: 0.0,
                  65.1: 0.0,
                  65.13: 0.0,
                  65.16: 0.0,
                  65.19: 0.0,
                  65.22: 0.0,
                  65.25: 0.0,
                  65.28: 0.0,
                  65.31: 0.0,
                  65.34: 0.0,
                  65.37: 0.0,
                  65.4: 0.0,
                  65.43: 0.0,
                  65.46: 0.0,
                  65.49: 0.0,
                  65.52: 0.0,
                  65.55: 0.0,
                  65.58: 0.0,
                  65.61: 0.0,
                  65.64: 0.0,
                  65.67: 0.0,
                  65.7: 0.0,
                  65.73: 0.0,
                  65.76: 0.0,
                  65.79: 0.0,
                  65.82: 0.0,
                  65.85: 0.0,
                  65.88: 0.0,
                  65.91: 0.0,
                  65.94: 0.0,
                  65.97: 0.0,
                  '66.0': 0.0,
                  66.03: 0.0,
                  66.06: 0.0,
                  66.09: 0.0,
                  66.12: 0.0,
                  66.15: 0.0,
                  66.18: 0.0,
                  66.21: 0.0,
                  66.24: 0.0,
                  66.27: 0.0,
                  66.3: 0.0,
                  66.33: 0.0,
                  66.36: 0.0,
                  66.39: 0.0,
                  66.42: 0.0,
                  66.45: 0.0,
                  66.48: 0.0,
                  66.51: 0.0,
                  66.54: 0.0,
                  66.57: 0.0,
                  66.6: 0.0,
                  66.63: 0.0,
                  66.66: 0.0,
                  66.69: 0.0,
                  66.72: 0.0,
                  66.75: 0.0,
                  66.78: 0.0,
                  66.81: 0.0,
                  66.84: 0.0,
                  66.87: 0.0,
                  66.9: 0.0,
                  66.93: 0.0,
                  66.96: 0.0,
                  66.99: 0.0,
                  67.02: 0.0,
                  67.05: 0.0,
                  67.08: 0.0,
                  67.11: 0.0,
                  67.14: 0.0,
                  67.17: 0.0,
                  67.2: 0.0,
                  67.23: 0.0,
                  67.26: 0.0,
                  67.29: 0.0,
                  67.32: 0.0,
                  67.35: 0.0,
                  67.38: 0.0,
                  67.41: 0.0,
                  67.44: 0.0,
                  67.47: 0.0,
                  67.5: 0.0,
                  67.53: 0.0,
                  67.56: 0.0,
                  67.59: 0.0,
                  67.62: 0.0,
                  67.65: 0.0,
                  67.68: 0.0,
                  67.71: 0.0,
                  67.74: 0.0,
                  67.77: 0.0,
                  67.8: 0.0,
                  67.83: 0.0,
                  67.86: 0.0,
                  67.89: 0.0,
                  67.92: 0.0,
                  67.95: 0.0,
                  67.98: 0.0,
                  68.01: 0.0,
                  68.04: 0.0,
                  68.07: 0.0,
                  68.1: 0.0,
                  68.13: 0.0,
                  68.16: 0.0,
                  68.19: 0.0,
                  68.22: 0.0,
                  68.25: 0.0,
                  68.28: 0.0,
                  68.31: 0.0,
                  68.34: 0.0,
                  68.37: 0.0,
                  68.4: 0.0,
                  68.43: 0.0,
                  68.46: 0.0,
                  68.49: 0.0,
                  68.52: 0.0,
                  68.55: 0.0,
                  68.58: 0.0,
                  68.61: 0.0,
                  68.64: 0.0,
                  68.67: 0.0,
                  68.7: 0.0,
                  68.73: 0.0,
                  68.76: 0.0,
                  68.79: 0.0,
                  68.82: 0.0,
                  68.85: 0.0,
                  68.88: 0.0,
                  68.91: 0.0,
                  68.94: 0.0,
                  68.97: 0.0,
                  '69.0': 0.0,
                  69.03: 0.0,
                  69.06: 0.0,
                  69.09: 0.0,
                  69.12: 0.0,
                  69.15: 0.0,
                  69.18: 0.0,
                  69.21: 0.0,
                  69.24: 0.0,
                  69.27: 0.0,
                  69.3: 0.0,
                  69.33: 0.0,
                  69.36: 0.0,
                  69.39: 0.0,
                  69.42: 0.0,
                  69.45: 0.0,
                  69.48: 0.0,
                  69.51: 0.0,
                  69.54: 0.0,
                  69.57: 0.0,
                  69.6: 0.0,
                  69.63: 0.0,
                  69.66: 0.0,
                  69.69: 0.0,
                  69.72: 0.0,
                  69.75: 0.0,
                  69.78: 0.0,
                  69.81: 0.0,
                  69.84: 0.0,
                  69.87: 0.0,
                  69.9: 0.0,
                  69.93: 0.0,
                  69.96: 0.0,
                  69.99: 0.0,
                  70.02: 0.0,
                  70.05: 0.0,
                  70.08: 0.0,
                  70.11: 0.0,
                  70.14: 0.0,
                  70.17: 0.0,
                  70.2: 0.0,
                  70.23: 0.0,
                  70.26: 0.0,
                  70.29: 0.0,
                  70.32: 0.0,
                  70.35: 0.0,
                  70.38: 0.0,
                  70.41: 0.0,
                  70.44: 0.0,
                  70.47: 0.0,
                  70.5: 0.0,
                  70.53: 0.0,
                  70.56: 0.0,
                  70.59: 0.0,
                  70.62: 0.0,
                  70.65: 0.0,
                  70.68: 0.0,
                  70.71: 0.0,
                  70.74: 0.0,
                  70.77: 0.0,
                  70.8: 0.0,
                  70.83: 0.0,
                  70.86: 0.0,
                  70.89: 0.0,
                  70.92: 0.0,
                  70.95: 0.0,
                  70.98: 0.0,
                  71.01: 0.0,
                  71.04: 0.0,
                  71.07: 0.0,
                  71.1: 0.0,
                  71.13: 0.0,
                  71.16: 0.0,
                  71.19: 0.0,
                  71.22: 0.0,
                  71.25: 0.0,
                  71.28: 0.0,
                  71.31: 0.0,
                  71.34: 0.0,
                  71.37: 0.0,
                  71.4: 0.0,
                  71.43: 0.0,
                  71.46: 0.0,
                  71.49: 0.0,
                  71.52: 0.0,
                  71.55: 0.0,
                  71.58: 0.0,
                  71.61: 0.0,
                  71.64: 0.0,
                  71.67: 0.0,
                  71.7: 0.0,
                  71.73: 0.0,
                  71.76: 0.0,
                  71.79: 0.0,
                  71.82: 0.0,
                  71.85: 0.0,
                  71.88: 0.0,
                  71.91: 0.0,
                  71.94: 0.0,
                  71.97: 0.0,
                  '72.0': 0.0,
                  72.03: 0.0,
                  72.06: 0.0,
                  72.09: 0.0,
                  72.12: 0.0,
                  72.15: 0.0,
                  72.18: 0.0,
                  72.21: 0.0,
                  72.24: 0.0,
                  72.27: 0.0,
                  72.3: 0.0,
                  72.33: 0.0,
                  72.36: 0.0,
                  72.39: 0.0,
                  72.42: 0.0,
                  72.45: 0.0,
                  72.48: 0.0,
                  72.51: 0.0,
                  72.54: 0.0,
                  72.57: 0.0,
                  72.6: 0.0,
                  72.63: 0.0,
                  72.66: 0.0,
                  72.69: 0.0,
                  72.72: 0.0,
                  72.75: 0.0,
                  72.78: 0.0,
                  72.81: 0.0,
                  72.84: 0.0,
                  72.87: 0.0,
                  72.9: 0.0,
                  72.93: 0.0,
                  72.96: 0.0,
                  72.99: 0.0,
                  73.02: 0.0,
                  73.05: 0.0,
                  73.08: 0.01,
                  73.11: 0.02,
                  73.14: 0.05,
                  73.17: 0.07,
                  73.2: 0.08,
                  73.23: 0.06,
                  73.26: 0.03,
                  73.29: 0.01,
                  73.32: 0.0,
                  73.35: 0.0,
                  73.38: 0.0,
                  73.41: 0.0,
                  73.44: 0.0,
                  73.47: 0.0,
                  73.5: 0.0,
                  73.53: 0.0,
                  73.56: 0.0,
                  73.59: 0.0,
                  73.62: 0.0,
                  73.65: 0.0,
                  73.68: 0.0,
                  73.71: 0.0,
                  73.74: 0.0,
                  73.77: 0.0,
                  73.8: 0.0,
                  73.83: 0.0,
                  73.86: 0.0,
                  73.89: 0.0,
                  73.92: 0.0,
                  73.95: 0.0,
                  73.98: 0.0,
                },
                iteration_1: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.12,
                  0.18: 0.1,
                  0.21: 0.1,
                  0.24: 0.11,
                  0.27: 0.13,
                  0.3: 0.15,
                  0.33: 0.17,
                  0.36: 0.18,
                  0.39: 0.18,
                  0.42: 0.19,
                  0.45: 0.22,
                  0.48: 0.25,
                  0.51: 0.25,
                  0.54: 0.22,
                  0.57: 0.18,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.2,
                  1.14: 0.17,
                  1.17: 0.13,
                  1.2: 0.1,
                  1.23: 0.09,
                  1.26: 0.13,
                  1.29: 0.17,
                  1.32: 0.19,
                  1.35: 0.17,
                  1.38: 0.13,
                  1.41: 0.08,
                  1.44: 0.05,
                  1.47: 0.03,
                  1.5: 0.04,
                  1.53: 0.06,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.05,
                  1.83: 0.06,
                  1.86: 0.08,
                  1.89: 0.09,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.1,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.15,
                  2.28: 0.11,
                  2.31: 0.06,
                  2.34: 0.02,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.07,
                  2.49: 0.08,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.18,
                  2.73: 0.17,
                  2.76: 0.16,
                  2.79: 0.16,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.04,
                  3.15: 0.07,
                  3.18: 0.08,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.0,
                  3.36: 0.0,
                  3.39: 0.0,
                  3.42: 0.0,
                  3.45: 0.0,
                  3.48: 0.0,
                  3.51: 0.0,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.0,
                  3.87: 0.0,
                  3.9: 0.0,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.08,
                  4.11: 0.1,
                  4.14: 0.1,
                  4.17: 0.1,
                  4.2: 0.09,
                  4.23: 0.08,
                  4.26: 0.07,
                  4.29: 0.09,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.05,
                  4.47: 0.02,
                  4.5: 0.01,
                  4.53: 0.0,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.04,
                  4.65: 0.06,
                  4.68: 0.08,
                  4.71: 0.07,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.17,
                  4.95: 0.13,
                  4.98: 0.07,
                  5.01: 0.03,
                  5.04: 0.01,
                  5.07: 0.01,
                  5.1: 0.03,
                  5.13: 0.06,
                  5.16: 0.08,
                  5.19: 0.07,
                  5.22: 0.05,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.12,
                  5.4: 0.16,
                  5.43: 0.15,
                  5.46: 0.11,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.12,
                  5.7: 0.15,
                  5.73: 0.14,
                  5.76: 0.09,
                  5.79: 0.05,
                  5.82: 0.03,
                  5.85: 0.05,
                  5.88: 0.07,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.04,
                  6.21: 0.07,
                  6.24: 0.1,
                  6.27: 0.12,
                  6.3: 0.12,
                  6.33: 0.1,
                  6.36: 0.09,
                  6.39: 0.08,
                  6.42: 0.09,
                  6.45: 0.08,
                  6.48: 0.05,
                  6.51: 0.03,
                  6.54: 0.02,
                  6.57: 0.04,
                  6.6: 0.08,
                  6.63: 0.14,
                  6.66: 0.16,
                  6.69: 0.13,
                  6.72: 0.07,
                  6.75: 0.03,
                  6.78: 0.01,
                  6.81: 0.0,
                  6.84: 0.0,
                  6.87: 0.0,
                  6.9: 0.01,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.08,
                  7.02: 0.07,
                  7.05: 0.05,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.04,
                  7.23: 0.07,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.07,
                  7.89: 0.04,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.08,
                  8.22: 0.07,
                  8.25: 0.04,
                  8.28: 0.02,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.03,
                  8.46: 0.07,
                  8.49: 0.11,
                  8.52: 0.13,
                  8.55: 0.13,
                  8.58: 0.1,
                  8.61: 0.06,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.07,
                  8.91: 0.08,
                  8.94: 0.06,
                  8.97: 0.03,
                  '9.0': 0.02,
                  9.03: 0.03,
                  9.06: 0.05,
                  9.09: 0.07,
                  9.12: 0.08,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.02,
                  9.24: 0.03,
                  9.27: 0.05,
                  9.3: 0.08,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.06,
                  9.42: 0.07,
                  9.45: 0.08,
                  9.48: 0.07,
                  9.51: 0.05,
                  9.54: 0.02,
                  9.57: 0.01,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.07,
                  10.02: 0.12,
                  10.05: 0.14,
                  10.08: 0.14,
                  10.11: 0.12,
                  10.14: 0.1,
                  10.17: 0.09,
                  10.2: 0.08,
                  10.23: 0.06,
                  10.26: 0.03,
                  10.29: 0.01,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.0,
                  10.47: 0.0,
                  10.5: 0.0,
                  10.53: 0.0,
                  10.56: 0.0,
                  10.59: 0.0,
                  10.62: 0.0,
                  10.65: 0.0,
                  10.68: 0.0,
                  10.71: 0.0,
                  10.74: 0.0,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.0,
                  11.1: 0.01,
                  11.13: 0.02,
                  11.16: 0.04,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.05,
                  11.67: 0.1,
                  11.7: 0.15,
                  11.73: 0.2,
                  11.76: 0.24,
                  11.79: 0.24,
                  11.82: 0.19,
                  11.85: 0.14,
                  11.88: 0.11,
                  11.91: 0.09,
                  11.94: 0.06,
                  11.97: 0.03,
                  '12.0': 0.01,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.07,
                  12.21: 0.08,
                  12.24: 0.06,
                  12.27: 0.04,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.06,
                  12.87: 0.08,
                  12.9: 0.07,
                  12.93: 0.05,
                  12.96: 0.05,
                  12.99: 0.06,
                  13.02: 0.08,
                  13.05: 0.08,
                  13.08: 0.05,
                  13.11: 0.03,
                  13.14: 0.03,
                  13.17: 0.05,
                  13.2: 0.07,
                  13.23: 0.08,
                  13.26: 0.06,
                  13.29: 0.03,
                  13.32: 0.01,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.0,
                  13.62: 0.0,
                  13.65: 0.0,
                  13.68: 0.0,
                  13.71: 0.0,
                  13.74: 0.0,
                  13.77: 0.01,
                  13.8: 0.02,
                  13.83: 0.04,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.01,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.0,
                  14.34: 0.0,
                  14.37: 0.0,
                  14.4: 0.0,
                  14.43: 0.0,
                  14.46: 0.0,
                  14.49: 0.01,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.07,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.03,
                  14.7: 0.01,
                  14.73: 0.0,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.01,
                  14.88: 0.02,
                  14.91: 0.04,
                  14.94: 0.07,
                  14.97: 0.08,
                  '15.0': 0.06,
                  15.03: 0.04,
                  15.06: 0.01,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.0,
                  15.24: 0.0,
                  15.27: 0.0,
                  15.3: 0.0,
                  15.33: 0.0,
                  15.36: 0.0,
                  15.39: 0.0,
                  15.42: 0.0,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.03,
                  15.54: 0.06,
                  15.57: 0.08,
                  15.6: 0.07,
                  15.63: 0.05,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.07,
                  16.32: 0.05,
                  16.35: 0.02,
                  16.38: 0.01,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.0,
                  16.59: 0.0,
                  16.62: 0.0,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.0,
                  17.16: 0.0,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.08,
                  17.43: 0.12,
                  17.46: 0.15,
                  17.49: 0.16,
                  17.52: 0.15,
                  17.55: 0.13,
                  17.58: 0.09,
                  17.61: 0.05,
                  17.64: 0.02,
                  17.67: 0.01,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.01,
                  18.42: 0.02,
                  18.45: 0.05,
                  18.48: 0.08,
                  18.51: 0.09,
                  18.54: 0.08,
                  18.57: 0.08,
                  18.6: 0.09,
                  18.63: 0.08,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.01,
                  19.5: 0.02,
                  19.53: 0.04,
                  19.56: 0.07,
                  19.59: 0.08,
                  19.62: 0.06,
                  19.65: 0.03,
                  19.68: 0.01,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.08,
                  22.89: 0.07,
                  22.92: 0.04,
                  22.95: 0.02,
                  22.98: 0.01,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.01,
                  23.97: 0.03,
                  '24.0': 0.05,
                  24.03: 0.08,
                  24.06: 0.08,
                  24.09: 0.05,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.01,
                  25.08: 0.03,
                  25.11: 0.06,
                  25.14: 0.08,
                  25.17: 0.07,
                  25.2: 0.05,
                  25.23: 0.02,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.01,
                  26.94: 0.03,
                  26.97: 0.05,
                  '27.0': 0.08,
                  27.03: 0.08,
                  27.06: 0.05,
                  27.09: 0.03,
                  27.12: 0.01,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.01,
                  28.89: 0.04,
                  28.92: 0.06,
                  28.95: 0.08,
                  28.98: 0.07,
                  29.01: 0.04,
                  29.04: 0.02,
                  29.07: 0.01,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.02,
                  30.3: 0.04,
                  30.33: 0.07,
                  30.36: 0.08,
                  30.39: 0.07,
                  30.42: 0.04,
                  30.45: 0.02,
                  30.48: 0.01,
                  30.51: 0.01,
                  30.54: 0.02,
                  30.57: 0.05,
                  30.6: 0.07,
                  30.63: 0.08,
                  30.66: 0.06,
                  30.69: 0.03,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.02,
                  33.96: 0.05,
                  33.99: 0.09,
                  34.02: 0.13,
                  34.05: 0.14,
                  34.08: 0.12,
                  34.11: 0.07,
                  34.14: 0.03,
                  34.17: 0.01,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.0,
                  40.74: 0.0,
                  40.77: 0.0,
                  40.8: 0.0,
                  40.83: 0.0,
                  40.86: 0.0,
                  40.89: 0.0,
                  40.92: 0.0,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.01,
                  41.58: 0.03,
                  41.61: 0.05,
                  41.64: 0.08,
                  41.67: 0.08,
                  41.7: 0.05,
                  41.73: 0.03,
                  41.76: 0.01,
                  41.79: 0.0,
                  41.82: 0.0,
                  41.85: 0.0,
                  41.88: 0.0,
                  41.91: 0.0,
                  41.94: 0.0,
                  41.97: 0.0,
                  '42.0': 0.0,
                  42.03: 0.0,
                  42.06: 0.0,
                  42.09: 0.0,
                  42.12: 0.0,
                  42.15: 0.0,
                  42.18: 0.0,
                  42.21: 0.0,
                  42.24: 0.0,
                  42.27: 0.0,
                  42.3: 0.0,
                  42.33: 0.0,
                  42.36: 0.0,
                  42.39: 0.0,
                  42.42: 0.0,
                  42.45: 0.0,
                  42.48: 0.0,
                  42.51: 0.0,
                  42.54: 0.0,
                  42.57: 0.0,
                  42.6: 0.0,
                  42.63: 0.0,
                  42.66: 0.0,
                  42.69: 0.0,
                  42.72: 0.0,
                  42.75: 0.0,
                  42.78: 0.0,
                  42.81: 0.0,
                  42.84: 0.0,
                  42.87: 0.0,
                  42.9: 0.0,
                  42.93: 0.0,
                  42.96: 0.0,
                  42.99: 0.0,
                  43.02: 0.0,
                  43.05: 0.0,
                  43.08: 0.0,
                  43.11: 0.0,
                  43.14: 0.0,
                  43.17: 0.0,
                  43.2: 0.0,
                  43.23: 0.0,
                  43.26: 0.0,
                  43.29: 0.0,
                  43.32: 0.0,
                  43.35: 0.0,
                  43.38: 0.0,
                  43.41: 0.0,
                  43.44: 0.0,
                  43.47: 0.0,
                  43.5: 0.0,
                  43.53: 0.0,
                  43.56: 0.0,
                  43.59: 0.0,
                  43.62: 0.0,
                  43.65: 0.0,
                  43.68: 0.0,
                  43.71: 0.0,
                  43.74: 0.0,
                  43.77: 0.0,
                  43.8: 0.0,
                  43.83: 0.0,
                  43.86: 0.0,
                  43.89: 0.0,
                  43.92: 0.0,
                  43.95: 0.0,
                  43.98: 0.0,
                  44.01: 0.0,
                  44.04: 0.0,
                  44.07: 0.0,
                  44.1: 0.0,
                  44.13: 0.0,
                  44.16: 0.0,
                  44.19: 0.0,
                  44.22: 0.0,
                  44.25: 0.0,
                  44.28: 0.0,
                  44.31: 0.0,
                  44.34: 0.0,
                  44.37: 0.0,
                  44.4: 0.0,
                  44.43: 0.0,
                  44.46: 0.0,
                  44.49: 0.0,
                  44.52: 0.0,
                  44.55: 0.0,
                  44.58: 0.0,
                  44.61: 0.0,
                  44.64: 0.0,
                  44.67: 0.0,
                  44.7: 0.0,
                  44.73: 0.0,
                  44.76: 0.0,
                  44.79: 0.0,
                  44.82: 0.0,
                  44.85: 0.0,
                  44.88: 0.0,
                  44.91: 0.0,
                  44.94: 0.0,
                  44.97: 0.0,
                  '45.0': 0.0,
                  45.03: 0.0,
                  45.06: 0.0,
                  45.09: 0.0,
                  45.12: 0.0,
                  45.15: 0.0,
                  45.18: 0.0,
                  45.21: 0.0,
                  45.24: 0.0,
                  45.27: 0.0,
                  45.3: 0.0,
                  45.33: 0.0,
                  45.36: 0.0,
                  45.39: 0.0,
                  45.42: 0.0,
                  45.45: 0.0,
                  45.48: 0.0,
                  45.51: 0.0,
                  45.54: 0.0,
                  45.57: 0.0,
                  45.6: 0.0,
                  45.63: 0.0,
                  45.66: 0.0,
                  45.69: 0.0,
                  45.72: 0.0,
                  45.75: 0.0,
                  45.78: 0.0,
                  45.81: 0.0,
                  45.84: 0.0,
                  45.87: 0.0,
                  45.9: 0.0,
                  45.93: 0.0,
                  45.96: 0.0,
                  45.99: 0.0,
                  46.02: 0.0,
                  46.05: 0.0,
                  46.08: 0.01,
                  46.11: 0.03,
                  46.14: 0.06,
                  46.17: 0.08,
                  46.2: 0.08,
                  46.23: 0.05,
                  46.26: 0.02,
                  46.29: 0.01,
                  46.32: 0.0,
                  46.35: 0.0,
                  46.38: 0.0,
                  46.41: 0.0,
                  46.44: 0.0,
                  46.47: 0.0,
                  46.5: 0.0,
                  46.53: 0.0,
                  46.56: 0.0,
                  46.59: 0.0,
                  46.62: 0.0,
                  46.65: 0.0,
                  46.68: 0.0,
                  46.71: 0.0,
                  46.74: 0.0,
                  46.77: 0.0,
                  46.8: 0.0,
                  46.83: 0.0,
                  46.86: 0.0,
                  46.89: 0.0,
                  46.92: 0.0,
                  46.95: 0.0,
                  46.98: 0.0,
                },
                iteration_2: {
                  '0.0': 0.0,
                  0.03: 0.0,
                  0.06: 0.01,
                  0.09: 0.03,
                  0.12: 0.08,
                  0.15: 0.15,
                  0.18: 0.2,
                  0.21: 0.22,
                  0.24: 0.2,
                  0.27: 0.16,
                  0.3: 0.13,
                  0.33: 0.1,
                  0.36: 0.08,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.18,
                  0.66: 0.2,
                  0.69: 0.21,
                  0.72: 0.24,
                  0.75: 0.25,
                  0.78: 0.23,
                  0.81: 0.16,
                  0.84: 0.08,
                  0.87: 0.03,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.0,
                  1.02: 0.0,
                  1.05: 0.02,
                  1.08: 0.04,
                  1.11: 0.07,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.01,
                  1.26: 0.0,
                  1.29: 0.0,
                  1.32: 0.01,
                  1.35: 0.03,
                  1.38: 0.09,
                  1.41: 0.17,
                  1.44: 0.23,
                  1.47: 0.22,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.02,
                  1.59: 0.01,
                  1.62: 0.0,
                  1.65: 0.0,
                  1.68: 0.0,
                  1.71: 0.01,
                  1.74: 0.03,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.05,
                  1.95: 0.02,
                  1.98: 0.01,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.0,
                  2.1: 0.0,
                  2.13: 0.01,
                  2.16: 0.02,
                  2.19: 0.06,
                  2.22: 0.12,
                  2.25: 0.16,
                  2.28: 0.15,
                  2.31: 0.1,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.01,
                  2.43: 0.02,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.1,
                  2.55: 0.11,
                  2.58: 0.13,
                  2.61: 0.16,
                  2.64: 0.18,
                  2.67: 0.18,
                  2.7: 0.15,
                  2.73: 0.11,
                  2.76: 0.08,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.21,
                  2.88: 0.23,
                  2.91: 0.19,
                  2.94: 0.12,
                  2.97: 0.09,
                  '3.0': 0.09,
                  3.03: 0.08,
                  3.06: 0.06,
                  3.09: 0.03,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.08,
                  3.24: 0.1,
                  3.27: 0.11,
                  3.3: 0.14,
                  3.33: 0.19,
                  3.36: 0.26,
                  3.39: 0.3,
                  3.42: 0.29,
                  3.45: 0.22,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.07,
                  3.57: 0.08,
                  3.6: 0.08,
                  3.63: 0.05,
                  3.66: 0.02,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.07,
                  3.9: 0.13,
                  3.93: 0.18,
                  3.96: 0.19,
                  3.99: 0.17,
                  4.02: 0.12,
                  4.05: 0.07,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.08,
                  4.35: 0.07,
                  4.38: 0.07,
                  4.41: 0.08,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.0,
                  4.62: 0.0,
                  4.65: 0.0,
                  4.68: 0.0,
                  4.71: 0.0,
                  4.74: 0.0,
                  4.77: 0.0,
                  4.8: 0.0,
                  4.83: 0.0,
                  4.86: 0.0,
                  4.89: 0.0,
                  4.92: 0.0,
                  4.95: 0.0,
                  4.98: 0.01,
                  5.01: 0.02,
                  5.04: 0.04,
                  5.07: 0.07,
                  5.1: 0.08,
                  5.13: 0.06,
                  5.16: 0.03,
                  5.19: 0.01,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.01,
                  5.37: 0.02,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.09,
                  5.58: 0.07,
                  5.61: 0.04,
                  5.64: 0.02,
                  5.67: 0.01,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.0,
                  5.88: 0.0,
                  5.91: 0.0,
                  5.94: 0.0,
                  5.97: 0.0,
                  '6.0': 0.0,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.09,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.02,
                  6.96: 0.01,
                  6.99: 0.0,
                  7.02: 0.0,
                  7.05: 0.01,
                  7.08: 0.05,
                  7.11: 0.12,
                  7.14: 0.2,
                  7.17: 0.23,
                  7.2: 0.2,
                  7.23: 0.12,
                  7.26: 0.05,
                  7.29: 0.02,
                  7.32: 0.0,
                  7.35: 0.0,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.01,
                  7.59: 0.04,
                  7.62: 0.09,
                  7.65: 0.14,
                  7.68: 0.16,
                  7.71: 0.13,
                  7.74: 0.09,
                  7.77: 0.08,
                  7.8: 0.08,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.01,
                  7.95: 0.02,
                  7.98: 0.04,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.03,
                  8.13: 0.01,
                  8.16: 0.0,
                  8.19: 0.0,
                  8.22: 0.0,
                  8.25: 0.0,
                  8.28: 0.0,
                  8.31: 0.01,
                  8.34: 0.02,
                  8.37: 0.05,
                  8.4: 0.07,
                  8.43: 0.08,
                  8.46: 0.06,
                  8.49: 0.03,
                  8.52: 0.01,
                  8.55: 0.0,
                  8.58: 0.01,
                  8.61: 0.03,
                  8.64: 0.07,
                  8.67: 0.12,
                  8.7: 0.16,
                  8.73: 0.15,
                  8.76: 0.13,
                  8.79: 0.11,
                  8.82: 0.09,
                  8.85: 0.07,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.08,
                  9.18: 0.13,
                  9.21: 0.17,
                  9.24: 0.19,
                  9.27: 0.17,
                  9.3: 0.12,
                  9.33: 0.06,
                  9.36: 0.03,
                  9.39: 0.02,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.08,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.02,
                  9.87: 0.04,
                  9.9: 0.07,
                  9.93: 0.08,
                  9.96: 0.07,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.11,
                  10.71: 0.11,
                  10.74: 0.1,
                  10.77: 0.08,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.08,
                  10.92: 0.08,
                  10.95: 0.06,
                  10.98: 0.03,
                  11.01: 0.01,
                  11.04: 0.01,
                  11.07: 0.02,
                  11.1: 0.05,
                  11.13: 0.09,
                  11.16: 0.14,
                  11.19: 0.15,
                  11.22: 0.12,
                  11.25: 0.07,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.01,
                  11.49: 0.03,
                  11.52: 0.06,
                  11.55: 0.08,
                  11.58: 0.08,
                  11.61: 0.05,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.02,
                  12.06: 0.04,
                  12.09: 0.07,
                  12.12: 0.08,
                  12.15: 0.07,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.03,
                  12.36: 0.06,
                  12.39: 0.08,
                  12.42: 0.07,
                  12.45: 0.04,
                  12.48: 0.02,
                  12.51: 0.01,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.01,
                  12.69: 0.03,
                  12.72: 0.06,
                  12.75: 0.08,
                  12.78: 0.07,
                  12.81: 0.04,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.01,
                  13.5: 0.02,
                  13.53: 0.05,
                  13.56: 0.08,
                  13.59: 0.1,
                  13.62: 0.11,
                  13.65: 0.11,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.05,
                  13.89: 0.04,
                  13.92: 0.06,
                  13.95: 0.08,
                  13.98: 0.08,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.08,
                  14.31: 0.06,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.11,
                  14.49: 0.15,
                  14.52: 0.15,
                  14.55: 0.11,
                  14.58: 0.06,
                  14.61: 0.04,
                  14.64: 0.05,
                  14.67: 0.07,
                  14.7: 0.08,
                  14.73: 0.06,
                  14.76: 0.03,
                  14.79: 0.01,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.01,
                  15.18: 0.02,
                  15.21: 0.06,
                  15.24: 0.13,
                  15.27: 0.2,
                  15.3: 0.24,
                  15.33: 0.23,
                  15.36: 0.19,
                  15.39: 0.13,
                  15.42: 0.07,
                  15.45: 0.03,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.05,
                  15.69: 0.07,
                  15.72: 0.08,
                  15.75: 0.06,
                  15.78: 0.03,
                  15.81: 0.01,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.01,
                  16.02: 0.02,
                  16.05: 0.05,
                  16.08: 0.07,
                  16.11: 0.08,
                  16.14: 0.06,
                  16.17: 0.03,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.01,
                  16.47: 0.03,
                  16.5: 0.05,
                  16.53: 0.08,
                  16.56: 0.08,
                  16.59: 0.05,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.04,
                  16.8: 0.07,
                  16.83: 0.08,
                  16.86: 0.06,
                  16.89: 0.03,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.03,
                  17.19: 0.05,
                  17.22: 0.08,
                  17.25: 0.08,
                  17.28: 0.07,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.08,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.08,
                  18.69: 0.06,
                  18.72: 0.03,
                  18.75: 0.01,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.01,
                  20.94: 0.02,
                  20.97: 0.05,
                  '21.0': 0.07,
                  21.03: 0.08,
                  21.06: 0.06,
                  21.09: 0.03,
                  21.12: 0.01,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.0,
                  22.8: 0.0,
                  22.83: 0.0,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.07,
                  24.15: 0.08,
                  24.18: 0.07,
                  24.21: 0.04,
                  24.24: 0.02,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.01,
                  25.56: 0.02,
                  25.59: 0.04,
                  25.62: 0.07,
                  25.65: 0.08,
                  25.68: 0.06,
                  25.71: 0.03,
                  25.74: 0.01,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.07,
                  26.52: 0.08,
                  26.55: 0.06,
                  26.58: 0.03,
                  26.61: 0.01,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.01,
                  29.55: 0.04,
                  29.58: 0.07,
                  29.61: 0.08,
                  29.64: 0.07,
                  29.67: 0.04,
                  29.7: 0.02,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.01,
                  32.64: 0.02,
                  32.67: 0.05,
                  32.7: 0.07,
                  32.73: 0.08,
                  32.76: 0.06,
                  32.79: 0.03,
                  32.82: 0.01,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.01,
                  33.09: 0.03,
                  33.12: 0.06,
                  33.15: 0.08,
                  33.18: 0.07,
                  33.21: 0.05,
                  33.24: 0.02,
                  33.27: 0.01,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.01,
                  35.67: 0.02,
                  35.7: 0.05,
                  35.73: 0.07,
                  35.76: 0.08,
                  35.79: 0.06,
                  35.82: 0.03,
                  35.85: 0.01,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
                iteration_3: {
                  '0.0': 0.09,
                  0.03: 0.16,
                  0.06: 0.21,
                  0.09: 0.22,
                  0.12: 0.18,
                  0.15: 0.14,
                  0.18: 0.14,
                  0.21: 0.15,
                  0.24: 0.14,
                  0.27: 0.11,
                  0.3: 0.06,
                  0.33: 0.02,
                  0.36: 0.01,
                  0.39: 0.0,
                  0.42: 0.01,
                  0.45: 0.04,
                  0.48: 0.1,
                  0.51: 0.17,
                  0.54: 0.22,
                  0.57: 0.21,
                  0.6: 0.15,
                  0.63: 0.08,
                  0.66: 0.03,
                  0.69: 0.02,
                  0.72: 0.04,
                  0.75: 0.07,
                  0.78: 0.08,
                  0.81: 0.07,
                  0.84: 0.04,
                  0.87: 0.02,
                  0.9: 0.01,
                  0.93: 0.0,
                  0.96: 0.0,
                  0.99: 0.02,
                  1.02: 0.04,
                  1.05: 0.07,
                  1.08: 0.08,
                  1.11: 0.07,
                  1.14: 0.04,
                  1.17: 0.02,
                  1.2: 0.01,
                  1.23: 0.03,
                  1.26: 0.08,
                  1.29: 0.14,
                  1.32: 0.19,
                  1.35: 0.22,
                  1.38: 0.24,
                  1.41: 0.3,
                  1.44: 0.39,
                  1.47: 0.47,
                  1.5: 0.5,
                  1.53: 0.47,
                  1.56: 0.4,
                  1.59: 0.33,
                  1.62: 0.27,
                  1.65: 0.21,
                  1.68: 0.16,
                  1.71: 0.12,
                  1.74: 0.09,
                  1.77: 0.07,
                  1.8: 0.07,
                  1.83: 0.08,
                  1.86: 0.08,
                  1.89: 0.05,
                  1.92: 0.02,
                  1.95: 0.01,
                  1.98: 0.0,
                  2.01: 0.0,
                  2.04: 0.0,
                  2.07: 0.02,
                  2.1: 0.04,
                  2.13: 0.07,
                  2.16: 0.09,
                  2.19: 0.09,
                  2.22: 0.08,
                  2.25: 0.09,
                  2.28: 0.11,
                  2.31: 0.12,
                  2.34: 0.15,
                  2.37: 0.17,
                  2.4: 0.15,
                  2.43: 0.1,
                  2.46: 0.06,
                  2.49: 0.06,
                  2.52: 0.09,
                  2.55: 0.13,
                  2.58: 0.16,
                  2.61: 0.16,
                  2.64: 0.14,
                  2.67: 0.12,
                  2.7: 0.08,
                  2.73: 0.04,
                  2.76: 0.02,
                  2.79: 0.0,
                  2.82: 0.0,
                  2.85: 0.0,
                  2.88: 0.0,
                  2.91: 0.0,
                  2.94: 0.02,
                  2.97: 0.05,
                  '3.0': 0.1,
                  3.03: 0.17,
                  3.06: 0.21,
                  3.09: 0.23,
                  3.12: 0.21,
                  3.15: 0.17,
                  3.18: 0.1,
                  3.21: 0.05,
                  3.24: 0.02,
                  3.27: 0.01,
                  3.3: 0.01,
                  3.33: 0.02,
                  3.36: 0.04,
                  3.39: 0.07,
                  3.42: 0.08,
                  3.45: 0.07,
                  3.48: 0.04,
                  3.51: 0.02,
                  3.54: 0.0,
                  3.57: 0.0,
                  3.6: 0.0,
                  3.63: 0.0,
                  3.66: 0.0,
                  3.69: 0.0,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.0,
                  3.84: 0.01,
                  3.87: 0.03,
                  3.9: 0.06,
                  3.93: 0.08,
                  3.96: 0.07,
                  3.99: 0.05,
                  4.02: 0.02,
                  4.05: 0.01,
                  4.08: 0.0,
                  4.11: 0.0,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.0,
                  4.23: 0.0,
                  4.26: 0.0,
                  4.29: 0.0,
                  4.32: 0.0,
                  4.35: 0.0,
                  4.38: 0.0,
                  4.41: 0.0,
                  4.44: 0.0,
                  4.47: 0.0,
                  4.5: 0.0,
                  4.53: 0.0,
                  4.56: 0.01,
                  4.59: 0.04,
                  4.62: 0.09,
                  4.65: 0.14,
                  4.68: 0.16,
                  4.71: 0.13,
                  4.74: 0.09,
                  4.77: 0.08,
                  4.8: 0.08,
                  4.83: 0.07,
                  4.86: 0.05,
                  4.89: 0.02,
                  4.92: 0.01,
                  4.95: 0.0,
                  4.98: 0.0,
                  5.01: 0.0,
                  5.04: 0.0,
                  5.07: 0.0,
                  5.1: 0.0,
                  5.13: 0.0,
                  5.16: 0.0,
                  5.19: 0.0,
                  5.22: 0.0,
                  5.25: 0.0,
                  5.28: 0.0,
                  5.31: 0.0,
                  5.34: 0.0,
                  5.37: 0.0,
                  5.4: 0.0,
                  5.43: 0.02,
                  5.46: 0.05,
                  5.49: 0.1,
                  5.52: 0.15,
                  5.55: 0.15,
                  5.58: 0.11,
                  5.61: 0.05,
                  5.64: 0.02,
                  5.67: 0.0,
                  5.7: 0.0,
                  5.73: 0.0,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.0,
                  5.85: 0.01,
                  5.88: 0.02,
                  5.91: 0.05,
                  5.94: 0.08,
                  5.97: 0.08,
                  '6.0': 0.06,
                  6.03: 0.03,
                  6.06: 0.01,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.0,
                  6.18: 0.0,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.0,
                  6.33: 0.0,
                  6.36: 0.01,
                  6.39: 0.02,
                  6.42: 0.05,
                  6.45: 0.07,
                  6.48: 0.08,
                  6.51: 0.06,
                  6.54: 0.03,
                  6.57: 0.02,
                  6.6: 0.02,
                  6.63: 0.05,
                  6.66: 0.09,
                  6.69: 0.15,
                  6.72: 0.19,
                  6.75: 0.2,
                  6.78: 0.18,
                  6.81: 0.14,
                  6.84: 0.11,
                  6.87: 0.09,
                  6.9: 0.06,
                  6.93: 0.04,
                  6.96: 0.03,
                  6.99: 0.04,
                  7.02: 0.06,
                  7.05: 0.08,
                  7.08: 0.07,
                  7.11: 0.04,
                  7.14: 0.02,
                  7.17: 0.02,
                  7.2: 0.03,
                  7.23: 0.06,
                  7.26: 0.08,
                  7.29: 0.07,
                  7.32: 0.05,
                  7.35: 0.02,
                  7.38: 0.01,
                  7.41: 0.0,
                  7.44: 0.01,
                  7.47: 0.04,
                  7.5: 0.08,
                  7.53: 0.13,
                  7.56: 0.15,
                  7.59: 0.13,
                  7.62: 0.08,
                  7.65: 0.04,
                  7.68: 0.03,
                  7.71: 0.04,
                  7.74: 0.07,
                  7.77: 0.08,
                  7.8: 0.07,
                  7.83: 0.04,
                  7.86: 0.02,
                  7.89: 0.0,
                  7.92: 0.0,
                  7.95: 0.0,
                  7.98: 0.0,
                  8.01: 0.0,
                  8.04: 0.0,
                  8.07: 0.01,
                  8.1: 0.03,
                  8.13: 0.06,
                  8.16: 0.08,
                  8.19: 0.07,
                  8.22: 0.05,
                  8.25: 0.02,
                  8.28: 0.01,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.01,
                  8.4: 0.02,
                  8.43: 0.05,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.06,
                  8.55: 0.04,
                  8.58: 0.04,
                  8.61: 0.06,
                  8.64: 0.08,
                  8.67: 0.07,
                  8.7: 0.05,
                  8.73: 0.02,
                  8.76: 0.01,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.05,
                  8.88: 0.08,
                  8.91: 0.08,
                  8.94: 0.07,
                  8.97: 0.06,
                  '9.0': 0.08,
                  9.03: 0.11,
                  9.06: 0.13,
                  9.09: 0.13,
                  9.12: 0.1,
                  9.15: 0.08,
                  9.18: 0.07,
                  9.21: 0.08,
                  9.24: 0.08,
                  9.27: 0.07,
                  9.3: 0.05,
                  9.33: 0.05,
                  9.36: 0.07,
                  9.39: 0.08,
                  9.42: 0.07,
                  9.45: 0.04,
                  9.48: 0.02,
                  9.51: 0.01,
                  9.54: 0.0,
                  9.57: 0.0,
                  9.6: 0.0,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.01,
                  9.93: 0.03,
                  9.96: 0.06,
                  9.99: 0.08,
                  10.02: 0.09,
                  10.05: 0.11,
                  10.08: 0.13,
                  10.11: 0.16,
                  10.14: 0.15,
                  10.17: 0.1,
                  10.2: 0.05,
                  10.23: 0.02,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.01,
                  10.44: 0.03,
                  10.47: 0.06,
                  10.5: 0.08,
                  10.53: 0.08,
                  10.56: 0.08,
                  10.59: 0.08,
                  10.62: 0.08,
                  10.65: 0.08,
                  10.68: 0.05,
                  10.71: 0.02,
                  10.74: 0.01,
                  10.77: 0.0,
                  10.8: 0.0,
                  10.83: 0.0,
                  10.86: 0.0,
                  10.89: 0.0,
                  10.92: 0.0,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.01,
                  11.07: 0.03,
                  11.1: 0.05,
                  11.13: 0.08,
                  11.16: 0.09,
                  11.19: 0.1,
                  11.22: 0.1,
                  11.25: 0.09,
                  11.28: 0.07,
                  11.31: 0.04,
                  11.34: 0.02,
                  11.37: 0.02,
                  11.4: 0.04,
                  11.43: 0.07,
                  11.46: 0.08,
                  11.49: 0.06,
                  11.52: 0.03,
                  11.55: 0.01,
                  11.58: 0.0,
                  11.61: 0.0,
                  11.64: 0.0,
                  11.67: 0.0,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.0,
                  12.12: 0.0,
                  12.15: 0.0,
                  12.18: 0.0,
                  12.21: 0.0,
                  12.24: 0.0,
                  12.27: 0.0,
                  12.3: 0.0,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.01,
                  12.51: 0.02,
                  12.54: 0.04,
                  12.57: 0.07,
                  12.6: 0.08,
                  12.63: 0.06,
                  12.66: 0.04,
                  12.69: 0.01,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.0,
                  12.81: 0.0,
                  12.84: 0.0,
                  12.87: 0.0,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.01,
                  13.56: 0.04,
                  13.59: 0.06,
                  13.62: 0.08,
                  13.65: 0.07,
                  13.68: 0.04,
                  13.71: 0.02,
                  13.74: 0.01,
                  13.77: 0.0,
                  13.8: 0.0,
                  13.83: 0.0,
                  13.86: 0.0,
                  13.89: 0.0,
                  13.92: 0.0,
                  13.95: 0.0,
                  13.98: 0.0,
                  14.01: 0.0,
                  14.04: 0.0,
                  14.07: 0.0,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.0,
                  14.19: 0.0,
                  14.22: 0.0,
                  14.25: 0.0,
                  14.28: 0.0,
                  14.31: 0.01,
                  14.34: 0.04,
                  14.37: 0.09,
                  14.4: 0.14,
                  14.43: 0.19,
                  14.46: 0.2,
                  14.49: 0.18,
                  14.52: 0.14,
                  14.55: 0.13,
                  14.58: 0.16,
                  14.61: 0.16,
                  14.64: 0.12,
                  14.67: 0.07,
                  14.7: 0.03,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.01,
                  14.82: 0.02,
                  14.85: 0.04,
                  14.88: 0.07,
                  14.91: 0.08,
                  14.94: 0.06,
                  14.97: 0.03,
                  '15.0': 0.01,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.01,
                  15.15: 0.02,
                  15.18: 0.05,
                  15.21: 0.07,
                  15.24: 0.08,
                  15.27: 0.06,
                  15.3: 0.03,
                  15.33: 0.01,
                  15.36: 0.01,
                  15.39: 0.03,
                  15.42: 0.06,
                  15.45: 0.08,
                  15.48: 0.08,
                  15.51: 0.08,
                  15.54: 0.09,
                  15.57: 0.1,
                  15.6: 0.12,
                  15.63: 0.12,
                  15.66: 0.1,
                  15.69: 0.07,
                  15.72: 0.04,
                  15.75: 0.01,
                  15.78: 0.01,
                  15.81: 0.02,
                  15.84: 0.04,
                  15.87: 0.07,
                  15.9: 0.08,
                  15.93: 0.06,
                  15.96: 0.03,
                  15.99: 0.01,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.03,
                  16.23: 0.06,
                  16.26: 0.08,
                  16.29: 0.09,
                  16.32: 0.08,
                  16.35: 0.08,
                  16.38: 0.09,
                  16.41: 0.07,
                  16.44: 0.06,
                  16.47: 0.06,
                  16.5: 0.07,
                  16.53: 0.08,
                  16.56: 0.07,
                  16.59: 0.04,
                  16.62: 0.02,
                  16.65: 0.0,
                  16.68: 0.0,
                  16.71: 0.0,
                  16.74: 0.0,
                  16.77: 0.0,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.0,
                  16.95: 0.02,
                  16.98: 0.04,
                  17.01: 0.08,
                  17.04: 0.13,
                  17.07: 0.14,
                  17.1: 0.12,
                  17.13: 0.08,
                  17.16: 0.04,
                  17.19: 0.01,
                  17.22: 0.01,
                  17.25: 0.02,
                  17.28: 0.05,
                  17.31: 0.07,
                  17.34: 0.08,
                  17.37: 0.06,
                  17.4: 0.03,
                  17.43: 0.01,
                  17.46: 0.0,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.01,
                  17.64: 0.03,
                  17.67: 0.06,
                  17.7: 0.08,
                  17.73: 0.07,
                  17.76: 0.04,
                  17.79: 0.02,
                  17.82: 0.01,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.0,
                  18.57: 0.0,
                  18.6: 0.0,
                  18.63: 0.0,
                  18.66: 0.0,
                  18.69: 0.0,
                  18.72: 0.0,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.02,
                  20.82: 0.04,
                  20.85: 0.07,
                  20.88: 0.08,
                  20.91: 0.07,
                  20.94: 0.04,
                  20.97: 0.02,
                  '21.0': 0.01,
                  21.03: 0.02,
                  21.06: 0.05,
                  21.09: 0.07,
                  21.12: 0.08,
                  21.15: 0.06,
                  21.18: 0.03,
                  21.21: 0.01,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.01,
                  22.65: 0.03,
                  22.68: 0.06,
                  22.71: 0.08,
                  22.74: 0.07,
                  22.77: 0.05,
                  22.8: 0.02,
                  22.83: 0.01,
                  22.86: 0.0,
                  22.89: 0.0,
                  22.92: 0.0,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.0,
                  24.09: 0.0,
                  24.12: 0.0,
                  24.15: 0.0,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.01,
                  24.54: 0.03,
                  24.57: 0.06,
                  24.6: 0.08,
                  24.63: 0.08,
                  24.66: 0.05,
                  24.69: 0.02,
                  24.72: 0.01,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.01,
                  24.93: 0.02,
                  24.96: 0.05,
                  24.99: 0.08,
                  25.02: 0.08,
                  25.05: 0.06,
                  25.08: 0.03,
                  25.11: 0.01,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.0,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.01,
                  25.74: 0.02,
                  25.77: 0.05,
                  25.8: 0.08,
                  25.83: 0.08,
                  25.86: 0.06,
                  25.89: 0.03,
                  25.92: 0.01,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.0,
                  26.43: 0.0,
                  26.46: 0.0,
                  26.49: 0.0,
                  26.52: 0.0,
                  26.55: 0.0,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.01,
                  27.21: 0.03,
                  27.24: 0.05,
                  27.27: 0.08,
                  27.3: 0.08,
                  27.33: 0.05,
                  27.36: 0.03,
                  27.39: 0.01,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.0,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.01,
                  33.96: 0.02,
                  33.99: 0.04,
                  34.02: 0.07,
                  34.05: 0.08,
                  34.08: 0.06,
                  34.11: 0.04,
                  34.14: 0.01,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                  '36.0': 0.0,
                  36.03: 0.0,
                  36.06: 0.0,
                  36.09: 0.0,
                  36.12: 0.0,
                  36.15: 0.0,
                  36.18: 0.0,
                  36.21: 0.0,
                  36.24: 0.0,
                  36.27: 0.0,
                  36.3: 0.0,
                  36.33: 0.0,
                  36.36: 0.0,
                  36.39: 0.0,
                  36.42: 0.0,
                  36.45: 0.0,
                  36.48: 0.0,
                  36.51: 0.0,
                  36.54: 0.0,
                  36.57: 0.0,
                  36.6: 0.0,
                  36.63: 0.0,
                  36.66: 0.0,
                  36.69: 0.0,
                  36.72: 0.0,
                  36.75: 0.0,
                  36.78: 0.0,
                  36.81: 0.0,
                  36.84: 0.0,
                  36.87: 0.0,
                  36.9: 0.0,
                  36.93: 0.0,
                  36.96: 0.0,
                  36.99: 0.0,
                  37.02: 0.0,
                  37.05: 0.0,
                  37.08: 0.0,
                  37.11: 0.0,
                  37.14: 0.0,
                  37.17: 0.0,
                  37.2: 0.0,
                  37.23: 0.0,
                  37.26: 0.0,
                  37.29: 0.0,
                  37.32: 0.0,
                  37.35: 0.0,
                  37.38: 0.0,
                  37.41: 0.0,
                  37.44: 0.0,
                  37.47: 0.0,
                  37.5: 0.0,
                  37.53: 0.0,
                  37.56: 0.0,
                  37.59: 0.0,
                  37.62: 0.0,
                  37.65: 0.0,
                  37.68: 0.0,
                  37.71: 0.0,
                  37.74: 0.0,
                  37.77: 0.0,
                  37.8: 0.0,
                  37.83: 0.0,
                  37.86: 0.0,
                  37.89: 0.0,
                  37.92: 0.0,
                  37.95: 0.0,
                  37.98: 0.0,
                  38.01: 0.0,
                  38.04: 0.0,
                  38.07: 0.0,
                  38.1: 0.0,
                  38.13: 0.0,
                  38.16: 0.0,
                  38.19: 0.0,
                  38.22: 0.0,
                  38.25: 0.0,
                  38.28: 0.0,
                  38.31: 0.0,
                  38.34: 0.0,
                  38.37: 0.0,
                  38.4: 0.0,
                  38.43: 0.0,
                  38.46: 0.0,
                  38.49: 0.0,
                  38.52: 0.0,
                  38.55: 0.0,
                  38.58: 0.0,
                  38.61: 0.0,
                  38.64: 0.0,
                  38.67: 0.0,
                  38.7: 0.0,
                  38.73: 0.0,
                  38.76: 0.0,
                  38.79: 0.0,
                  38.82: 0.0,
                  38.85: 0.0,
                  38.88: 0.0,
                  38.91: 0.0,
                  38.94: 0.0,
                  38.97: 0.0,
                  '39.0': 0.0,
                  39.03: 0.0,
                  39.06: 0.0,
                  39.09: 0.0,
                  39.12: 0.0,
                  39.15: 0.0,
                  39.18: 0.0,
                  39.21: 0.0,
                  39.24: 0.0,
                  39.27: 0.0,
                  39.3: 0.0,
                  39.33: 0.0,
                  39.36: 0.0,
                  39.39: 0.0,
                  39.42: 0.0,
                  39.45: 0.0,
                  39.48: 0.0,
                  39.51: 0.0,
                  39.54: 0.0,
                  39.57: 0.0,
                  39.6: 0.0,
                  39.63: 0.0,
                  39.66: 0.0,
                  39.69: 0.0,
                  39.72: 0.0,
                  39.75: 0.0,
                  39.78: 0.0,
                  39.81: 0.0,
                  39.84: 0.0,
                  39.87: 0.0,
                  39.9: 0.0,
                  39.93: 0.0,
                  39.96: 0.0,
                  39.99: 0.0,
                  40.02: 0.0,
                  40.05: 0.0,
                  40.08: 0.0,
                  40.11: 0.0,
                  40.14: 0.0,
                  40.17: 0.0,
                  40.2: 0.0,
                  40.23: 0.0,
                  40.26: 0.0,
                  40.29: 0.0,
                  40.32: 0.0,
                  40.35: 0.0,
                  40.38: 0.0,
                  40.41: 0.0,
                  40.44: 0.0,
                  40.47: 0.0,
                  40.5: 0.0,
                  40.53: 0.0,
                  40.56: 0.0,
                  40.59: 0.0,
                  40.62: 0.0,
                  40.65: 0.0,
                  40.68: 0.0,
                  40.71: 0.01,
                  40.74: 0.02,
                  40.77: 0.04,
                  40.8: 0.07,
                  40.83: 0.08,
                  40.86: 0.06,
                  40.89: 0.04,
                  40.92: 0.01,
                  40.95: 0.0,
                  40.98: 0.0,
                  41.01: 0.0,
                  41.04: 0.0,
                  41.07: 0.0,
                  41.1: 0.0,
                  41.13: 0.0,
                  41.16: 0.0,
                  41.19: 0.0,
                  41.22: 0.0,
                  41.25: 0.0,
                  41.28: 0.0,
                  41.31: 0.0,
                  41.34: 0.0,
                  41.37: 0.0,
                  41.4: 0.0,
                  41.43: 0.0,
                  41.46: 0.0,
                  41.49: 0.0,
                  41.52: 0.0,
                  41.55: 0.0,
                  41.58: 0.0,
                  41.61: 0.0,
                  41.64: 0.0,
                  41.67: 0.0,
                  41.7: 0.0,
                  41.73: 0.0,
                  41.76: 0.0,
                  41.79: 0.01,
                  41.82: 0.02,
                  41.85: 0.05,
                  41.88: 0.07,
                  41.91: 0.08,
                  41.94: 0.06,
                  41.97: 0.03,
                },
                iteration_median: {
                  '0.0': 0.03,
                  0.03: 0.06,
                  0.06: 0.09,
                  0.09: 0.12,
                  0.12: 0.13,
                  0.15: 0.14,
                  0.18: 0.18,
                  0.21: 0.19,
                  0.24: 0.18,
                  0.27: 0.15,
                  0.3: 0.13,
                  0.33: 0.11,
                  0.36: 0.1,
                  0.39: 0.08,
                  0.42: 0.09,
                  0.45: 0.08,
                  0.48: 0.06,
                  0.51: 0.06,
                  0.54: 0.09,
                  0.57: 0.12,
                  0.6: 0.15,
                  0.63: 0.13,
                  0.66: 0.13,
                  0.69: 0.12,
                  0.72: 0.1,
                  0.75: 0.09,
                  0.78: 0.09,
                  0.81: 0.09,
                  0.84: 0.07,
                  0.87: 0.04,
                  0.9: 0.02,
                  0.93: 0.02,
                  0.96: 0.04,
                  0.99: 0.09,
                  1.02: 0.15,
                  1.05: 0.2,
                  1.08: 0.22,
                  1.11: 0.15,
                  1.14: 0.08,
                  1.17: 0.06,
                  1.2: 0.04,
                  1.23: 0.06,
                  1.26: 0.08,
                  1.29: 0.08,
                  1.32: 0.07,
                  1.35: 0.08,
                  1.38: 0.12,
                  1.41: 0.16,
                  1.44: 0.17,
                  1.47: 0.16,
                  1.5: 0.15,
                  1.53: 0.07,
                  1.56: 0.08,
                  1.59: 0.07,
                  1.62: 0.05,
                  1.65: 0.05,
                  1.68: 0.07,
                  1.71: 0.08,
                  1.74: 0.08,
                  1.77: 0.06,
                  1.8: 0.11,
                  1.83: 0.14,
                  1.86: 0.14,
                  1.89: 0.1,
                  1.92: 0.09,
                  1.95: 0.11,
                  1.98: 0.14,
                  2.01: 0.15,
                  2.04: 0.13,
                  2.07: 0.09,
                  2.1: 0.08,
                  2.13: 0.08,
                  2.16: 0.14,
                  2.19: 0.17,
                  2.22: 0.17,
                  2.25: 0.16,
                  2.28: 0.11,
                  2.31: 0.07,
                  2.34: 0.04,
                  2.37: 0.01,
                  2.4: 0.02,
                  2.43: 0.04,
                  2.46: 0.04,
                  2.49: 0.07,
                  2.52: 0.06,
                  2.55: 0.04,
                  2.58: 0.03,
                  2.61: 0.06,
                  2.64: 0.11,
                  2.67: 0.16,
                  2.7: 0.15,
                  2.73: 0.12,
                  2.76: 0.09,
                  2.79: 0.09,
                  2.82: 0.14,
                  2.85: 0.1,
                  2.88: 0.07,
                  2.91: 0.06,
                  2.94: 0.07,
                  2.97: 0.08,
                  '3.0': 0.06,
                  3.03: 0.04,
                  3.06: 0.02,
                  3.09: 0.02,
                  3.12: 0.02,
                  3.15: 0.03,
                  3.18: 0.06,
                  3.21: 0.06,
                  3.24: 0.03,
                  3.27: 0.01,
                  3.3: 0.0,
                  3.33: 0.01,
                  3.36: 0.03,
                  3.39: 0.06,
                  3.42: 0.11,
                  3.45: 0.14,
                  3.48: 0.14,
                  3.51: 0.08,
                  3.54: 0.06,
                  3.57: 0.02,
                  3.6: 0.01,
                  3.63: 0.0,
                  3.66: 0.01,
                  3.69: 0.01,
                  3.72: 0.0,
                  3.75: 0.0,
                  3.78: 0.0,
                  3.81: 0.01,
                  3.84: 0.03,
                  3.87: 0.02,
                  3.9: 0.01,
                  3.93: 0.0,
                  3.96: 0.0,
                  3.99: 0.01,
                  4.02: 0.02,
                  4.05: 0.05,
                  4.08: 0.03,
                  4.11: 0.01,
                  4.14: 0.0,
                  4.17: 0.0,
                  4.2: 0.01,
                  4.23: 0.03,
                  4.26: 0.06,
                  4.29: 0.08,
                  4.32: 0.11,
                  4.35: 0.13,
                  4.38: 0.12,
                  4.41: 0.09,
                  4.44: 0.08,
                  4.47: 0.06,
                  4.5: 0.04,
                  4.53: 0.01,
                  4.56: 0.0,
                  4.59: 0.01,
                  4.62: 0.03,
                  4.65: 0.02,
                  4.68: 0.03,
                  4.71: 0.06,
                  4.74: 0.05,
                  4.77: 0.05,
                  4.8: 0.08,
                  4.83: 0.12,
                  4.86: 0.16,
                  4.89: 0.18,
                  4.92: 0.16,
                  4.95: 0.1,
                  4.98: 0.05,
                  5.01: 0.02,
                  5.04: 0.02,
                  5.07: 0.04,
                  5.1: 0.07,
                  5.13: 0.06,
                  5.16: 0.07,
                  5.19: 0.04,
                  5.22: 0.03,
                  5.25: 0.02,
                  5.28: 0.01,
                  5.31: 0.03,
                  5.34: 0.07,
                  5.37: 0.05,
                  5.4: 0.05,
                  5.43: 0.08,
                  5.46: 0.09,
                  5.49: 0.09,
                  5.52: 0.09,
                  5.55: 0.08,
                  5.58: 0.07,
                  5.61: 0.06,
                  5.64: 0.08,
                  5.67: 0.07,
                  5.7: 0.04,
                  5.73: 0.02,
                  5.76: 0.0,
                  5.79: 0.0,
                  5.82: 0.01,
                  5.85: 0.02,
                  5.88: 0.05,
                  5.91: 0.08,
                  5.94: 0.06,
                  5.97: 0.03,
                  '6.0': 0.01,
                  6.03: 0.0,
                  6.06: 0.0,
                  6.09: 0.0,
                  6.12: 0.0,
                  6.15: 0.01,
                  6.18: 0.01,
                  6.21: 0.0,
                  6.24: 0.0,
                  6.27: 0.0,
                  6.3: 0.01,
                  6.33: 0.03,
                  6.36: 0.05,
                  6.39: 0.08,
                  6.42: 0.08,
                  6.45: 0.05,
                  6.48: 0.03,
                  6.51: 0.03,
                  6.54: 0.05,
                  6.57: 0.08,
                  6.6: 0.09,
                  6.63: 0.1,
                  6.66: 0.1,
                  6.69: 0.11,
                  6.72: 0.07,
                  6.75: 0.05,
                  6.78: 0.05,
                  6.81: 0.06,
                  6.84: 0.08,
                  6.87: 0.07,
                  6.9: 0.05,
                  6.93: 0.03,
                  6.96: 0.06,
                  6.99: 0.06,
                  7.02: 0.03,
                  7.05: 0.01,
                  7.08: 0.02,
                  7.11: 0.01,
                  7.14: 0.01,
                  7.17: 0.02,
                  7.2: 0.05,
                  7.23: 0.08,
                  7.26: 0.08,
                  7.29: 0.06,
                  7.32: 0.04,
                  7.35: 0.01,
                  7.38: 0.0,
                  7.41: 0.0,
                  7.44: 0.0,
                  7.47: 0.0,
                  7.5: 0.0,
                  7.53: 0.0,
                  7.56: 0.0,
                  7.59: 0.0,
                  7.62: 0.0,
                  7.65: 0.0,
                  7.68: 0.0,
                  7.71: 0.0,
                  7.74: 0.02,
                  7.77: 0.04,
                  7.8: 0.07,
                  7.83: 0.08,
                  7.86: 0.05,
                  7.89: 0.03,
                  7.92: 0.02,
                  7.95: 0.03,
                  7.98: 0.05,
                  8.01: 0.07,
                  8.04: 0.08,
                  8.07: 0.06,
                  8.1: 0.05,
                  8.13: 0.05,
                  8.16: 0.07,
                  8.19: 0.04,
                  8.22: 0.02,
                  8.25: 0.01,
                  8.28: 0.0,
                  8.31: 0.0,
                  8.34: 0.0,
                  8.37: 0.0,
                  8.4: 0.01,
                  8.43: 0.04,
                  8.46: 0.07,
                  8.49: 0.08,
                  8.52: 0.07,
                  8.55: 0.04,
                  8.58: 0.02,
                  8.61: 0.03,
                  8.64: 0.02,
                  8.67: 0.01,
                  8.7: 0.0,
                  8.73: 0.0,
                  8.76: 0.0,
                  8.79: 0.01,
                  8.82: 0.02,
                  8.85: 0.04,
                  8.88: 0.04,
                  8.91: 0.01,
                  8.94: 0.0,
                  8.97: 0.0,
                  '9.0': 0.0,
                  9.03: 0.0,
                  9.06: 0.0,
                  9.09: 0.01,
                  9.12: 0.04,
                  9.15: 0.06,
                  9.18: 0.03,
                  9.21: 0.04,
                  9.24: 0.08,
                  9.27: 0.12,
                  9.3: 0.12,
                  9.33: 0.08,
                  9.36: 0.07,
                  9.39: 0.05,
                  9.42: 0.04,
                  9.45: 0.06,
                  9.48: 0.07,
                  9.51: 0.07,
                  9.54: 0.04,
                  9.57: 0.02,
                  9.6: 0.01,
                  9.63: 0.0,
                  9.66: 0.0,
                  9.69: 0.0,
                  9.72: 0.0,
                  9.75: 0.0,
                  9.78: 0.0,
                  9.81: 0.0,
                  9.84: 0.0,
                  9.87: 0.0,
                  9.9: 0.0,
                  9.93: 0.01,
                  9.96: 0.03,
                  9.99: 0.05,
                  10.02: 0.05,
                  10.05: 0.07,
                  10.08: 0.08,
                  10.11: 0.07,
                  10.14: 0.04,
                  10.17: 0.02,
                  10.2: 0.01,
                  10.23: 0.0,
                  10.26: 0.0,
                  10.29: 0.0,
                  10.32: 0.0,
                  10.35: 0.0,
                  10.38: 0.0,
                  10.41: 0.0,
                  10.44: 0.01,
                  10.47: 0.02,
                  10.5: 0.05,
                  10.53: 0.07,
                  10.56: 0.09,
                  10.59: 0.09,
                  10.62: 0.09,
                  10.65: 0.1,
                  10.68: 0.08,
                  10.71: 0.05,
                  10.74: 0.04,
                  10.77: 0.05,
                  10.8: 0.06,
                  10.83: 0.05,
                  10.86: 0.06,
                  10.89: 0.03,
                  10.92: 0.01,
                  10.95: 0.0,
                  10.98: 0.0,
                  11.01: 0.0,
                  11.04: 0.0,
                  11.07: 0.01,
                  11.1: 0.03,
                  11.13: 0.06,
                  11.16: 0.08,
                  11.19: 0.07,
                  11.22: 0.08,
                  11.25: 0.06,
                  11.28: 0.03,
                  11.31: 0.01,
                  11.34: 0.0,
                  11.37: 0.0,
                  11.4: 0.0,
                  11.43: 0.0,
                  11.46: 0.0,
                  11.49: 0.0,
                  11.52: 0.0,
                  11.55: 0.0,
                  11.58: 0.01,
                  11.61: 0.02,
                  11.64: 0.02,
                  11.67: 0.01,
                  11.7: 0.0,
                  11.73: 0.0,
                  11.76: 0.0,
                  11.79: 0.0,
                  11.82: 0.0,
                  11.85: 0.0,
                  11.88: 0.0,
                  11.91: 0.0,
                  11.94: 0.0,
                  11.97: 0.0,
                  '12.0': 0.0,
                  12.03: 0.0,
                  12.06: 0.0,
                  12.09: 0.01,
                  12.12: 0.02,
                  12.15: 0.04,
                  12.18: 0.04,
                  12.21: 0.02,
                  12.24: 0.01,
                  12.27: 0.0,
                  12.3: 0.01,
                  12.33: 0.0,
                  12.36: 0.0,
                  12.39: 0.0,
                  12.42: 0.0,
                  12.45: 0.0,
                  12.48: 0.0,
                  12.51: 0.0,
                  12.54: 0.0,
                  12.57: 0.0,
                  12.6: 0.0,
                  12.63: 0.0,
                  12.66: 0.0,
                  12.69: 0.0,
                  12.72: 0.0,
                  12.75: 0.0,
                  12.78: 0.01,
                  12.81: 0.03,
                  12.84: 0.02,
                  12.87: 0.01,
                  12.9: 0.0,
                  12.93: 0.0,
                  12.96: 0.0,
                  12.99: 0.0,
                  13.02: 0.0,
                  13.05: 0.0,
                  13.08: 0.0,
                  13.11: 0.0,
                  13.14: 0.0,
                  13.17: 0.0,
                  13.2: 0.0,
                  13.23: 0.0,
                  13.26: 0.0,
                  13.29: 0.0,
                  13.32: 0.0,
                  13.35: 0.0,
                  13.38: 0.0,
                  13.41: 0.0,
                  13.44: 0.0,
                  13.47: 0.0,
                  13.5: 0.0,
                  13.53: 0.0,
                  13.56: 0.0,
                  13.59: 0.01,
                  13.62: 0.03,
                  13.65: 0.06,
                  13.68: 0.09,
                  13.71: 0.08,
                  13.74: 0.07,
                  13.77: 0.07,
                  13.8: 0.08,
                  13.83: 0.07,
                  13.86: 0.07,
                  13.89: 0.08,
                  13.92: 0.06,
                  13.95: 0.03,
                  13.98: 0.05,
                  14.01: 0.05,
                  14.04: 0.03,
                  14.07: 0.01,
                  14.1: 0.0,
                  14.13: 0.0,
                  14.16: 0.01,
                  14.19: 0.02,
                  14.22: 0.05,
                  14.25: 0.07,
                  14.28: 0.07,
                  14.31: 0.04,
                  14.34: 0.03,
                  14.37: 0.02,
                  14.4: 0.02,
                  14.43: 0.05,
                  14.46: 0.05,
                  14.49: 0.03,
                  14.52: 0.02,
                  14.55: 0.05,
                  14.58: 0.06,
                  14.61: 0.08,
                  14.64: 0.06,
                  14.67: 0.05,
                  14.7: 0.02,
                  14.73: 0.01,
                  14.76: 0.0,
                  14.79: 0.0,
                  14.82: 0.0,
                  14.85: 0.0,
                  14.88: 0.0,
                  14.91: 0.0,
                  14.94: 0.0,
                  14.97: 0.0,
                  '15.0': 0.0,
                  15.03: 0.0,
                  15.06: 0.0,
                  15.09: 0.0,
                  15.12: 0.0,
                  15.15: 0.0,
                  15.18: 0.0,
                  15.21: 0.01,
                  15.24: 0.02,
                  15.27: 0.04,
                  15.3: 0.07,
                  15.33: 0.08,
                  15.36: 0.06,
                  15.39: 0.04,
                  15.42: 0.01,
                  15.45: 0.0,
                  15.48: 0.01,
                  15.51: 0.0,
                  15.54: 0.0,
                  15.57: 0.0,
                  15.6: 0.01,
                  15.63: 0.02,
                  15.66: 0.02,
                  15.69: 0.01,
                  15.72: 0.0,
                  15.75: 0.0,
                  15.78: 0.0,
                  15.81: 0.0,
                  15.84: 0.0,
                  15.87: 0.0,
                  15.9: 0.0,
                  15.93: 0.0,
                  15.96: 0.0,
                  15.99: 0.0,
                  16.02: 0.0,
                  16.05: 0.0,
                  16.08: 0.0,
                  16.11: 0.0,
                  16.14: 0.0,
                  16.17: 0.01,
                  16.2: 0.01,
                  16.23: 0.0,
                  16.26: 0.0,
                  16.29: 0.0,
                  16.32: 0.0,
                  16.35: 0.0,
                  16.38: 0.0,
                  16.41: 0.0,
                  16.44: 0.0,
                  16.47: 0.0,
                  16.5: 0.0,
                  16.53: 0.0,
                  16.56: 0.01,
                  16.59: 0.03,
                  16.62: 0.02,
                  16.65: 0.01,
                  16.68: 0.0,
                  16.71: 0.01,
                  16.74: 0.02,
                  16.77: 0.01,
                  16.8: 0.0,
                  16.83: 0.0,
                  16.86: 0.0,
                  16.89: 0.0,
                  16.92: 0.01,
                  16.95: 0.0,
                  16.98: 0.0,
                  17.01: 0.0,
                  17.04: 0.0,
                  17.07: 0.0,
                  17.1: 0.0,
                  17.13: 0.01,
                  17.16: 0.01,
                  17.19: 0.0,
                  17.22: 0.0,
                  17.25: 0.0,
                  17.28: 0.0,
                  17.31: 0.0,
                  17.34: 0.01,
                  17.37: 0.04,
                  17.4: 0.06,
                  17.43: 0.03,
                  17.46: 0.01,
                  17.49: 0.0,
                  17.52: 0.0,
                  17.55: 0.0,
                  17.58: 0.0,
                  17.61: 0.0,
                  17.64: 0.0,
                  17.67: 0.0,
                  17.7: 0.0,
                  17.73: 0.0,
                  17.76: 0.0,
                  17.79: 0.0,
                  17.82: 0.0,
                  17.85: 0.0,
                  17.88: 0.0,
                  17.91: 0.0,
                  17.94: 0.0,
                  17.97: 0.0,
                  '18.0': 0.0,
                  18.03: 0.0,
                  18.06: 0.0,
                  18.09: 0.0,
                  18.12: 0.0,
                  18.15: 0.0,
                  18.18: 0.0,
                  18.21: 0.0,
                  18.24: 0.0,
                  18.27: 0.0,
                  18.3: 0.0,
                  18.33: 0.0,
                  18.36: 0.0,
                  18.39: 0.0,
                  18.42: 0.0,
                  18.45: 0.0,
                  18.48: 0.0,
                  18.51: 0.0,
                  18.54: 0.01,
                  18.57: 0.02,
                  18.6: 0.05,
                  18.63: 0.07,
                  18.66: 0.06,
                  18.69: 0.03,
                  18.72: 0.01,
                  18.75: 0.0,
                  18.78: 0.0,
                  18.81: 0.0,
                  18.84: 0.0,
                  18.87: 0.0,
                  18.9: 0.0,
                  18.93: 0.0,
                  18.96: 0.0,
                  18.99: 0.0,
                  19.02: 0.0,
                  19.05: 0.0,
                  19.08: 0.0,
                  19.11: 0.0,
                  19.14: 0.0,
                  19.17: 0.0,
                  19.2: 0.0,
                  19.23: 0.0,
                  19.26: 0.0,
                  19.29: 0.0,
                  19.32: 0.0,
                  19.35: 0.0,
                  19.38: 0.0,
                  19.41: 0.0,
                  19.44: 0.0,
                  19.47: 0.0,
                  19.5: 0.0,
                  19.53: 0.0,
                  19.56: 0.0,
                  19.59: 0.0,
                  19.62: 0.0,
                  19.65: 0.0,
                  19.68: 0.0,
                  19.71: 0.0,
                  19.74: 0.0,
                  19.77: 0.0,
                  19.8: 0.0,
                  19.83: 0.0,
                  19.86: 0.0,
                  19.89: 0.0,
                  19.92: 0.0,
                  19.95: 0.0,
                  19.98: 0.0,
                  20.01: 0.0,
                  20.04: 0.0,
                  20.07: 0.0,
                  20.1: 0.0,
                  20.13: 0.0,
                  20.16: 0.0,
                  20.19: 0.0,
                  20.22: 0.0,
                  20.25: 0.0,
                  20.28: 0.0,
                  20.31: 0.0,
                  20.34: 0.0,
                  20.37: 0.0,
                  20.4: 0.0,
                  20.43: 0.0,
                  20.46: 0.0,
                  20.49: 0.0,
                  20.52: 0.0,
                  20.55: 0.0,
                  20.58: 0.0,
                  20.61: 0.0,
                  20.64: 0.0,
                  20.67: 0.0,
                  20.7: 0.0,
                  20.73: 0.0,
                  20.76: 0.0,
                  20.79: 0.0,
                  20.82: 0.0,
                  20.85: 0.0,
                  20.88: 0.0,
                  20.91: 0.0,
                  20.94: 0.0,
                  20.97: 0.0,
                  '21.0': 0.0,
                  21.03: 0.0,
                  21.06: 0.0,
                  21.09: 0.0,
                  21.12: 0.0,
                  21.15: 0.0,
                  21.18: 0.0,
                  21.21: 0.0,
                  21.24: 0.0,
                  21.27: 0.0,
                  21.3: 0.0,
                  21.33: 0.0,
                  21.36: 0.0,
                  21.39: 0.0,
                  21.42: 0.0,
                  21.45: 0.0,
                  21.48: 0.0,
                  21.51: 0.0,
                  21.54: 0.0,
                  21.57: 0.0,
                  21.6: 0.0,
                  21.63: 0.0,
                  21.66: 0.0,
                  21.69: 0.0,
                  21.72: 0.0,
                  21.75: 0.0,
                  21.78: 0.0,
                  21.81: 0.0,
                  21.84: 0.0,
                  21.87: 0.0,
                  21.9: 0.0,
                  21.93: 0.0,
                  21.96: 0.0,
                  21.99: 0.0,
                  22.02: 0.0,
                  22.05: 0.0,
                  22.08: 0.0,
                  22.11: 0.0,
                  22.14: 0.0,
                  22.17: 0.0,
                  22.2: 0.0,
                  22.23: 0.0,
                  22.26: 0.0,
                  22.29: 0.0,
                  22.32: 0.0,
                  22.35: 0.0,
                  22.38: 0.0,
                  22.41: 0.0,
                  22.44: 0.0,
                  22.47: 0.0,
                  22.5: 0.0,
                  22.53: 0.0,
                  22.56: 0.0,
                  22.59: 0.0,
                  22.62: 0.0,
                  22.65: 0.0,
                  22.68: 0.0,
                  22.71: 0.0,
                  22.74: 0.0,
                  22.77: 0.01,
                  22.8: 0.03,
                  22.83: 0.06,
                  22.86: 0.05,
                  22.89: 0.02,
                  22.92: 0.01,
                  22.95: 0.0,
                  22.98: 0.0,
                  23.01: 0.0,
                  23.04: 0.0,
                  23.07: 0.0,
                  23.1: 0.0,
                  23.13: 0.0,
                  23.16: 0.0,
                  23.19: 0.0,
                  23.22: 0.0,
                  23.25: 0.0,
                  23.28: 0.0,
                  23.31: 0.0,
                  23.34: 0.0,
                  23.37: 0.0,
                  23.4: 0.0,
                  23.43: 0.0,
                  23.46: 0.0,
                  23.49: 0.0,
                  23.52: 0.0,
                  23.55: 0.0,
                  23.58: 0.0,
                  23.61: 0.0,
                  23.64: 0.0,
                  23.67: 0.0,
                  23.7: 0.0,
                  23.73: 0.0,
                  23.76: 0.0,
                  23.79: 0.0,
                  23.82: 0.0,
                  23.85: 0.0,
                  23.88: 0.0,
                  23.91: 0.0,
                  23.94: 0.0,
                  23.97: 0.0,
                  '24.0': 0.0,
                  24.03: 0.0,
                  24.06: 0.02,
                  24.09: 0.04,
                  24.12: 0.03,
                  24.15: 0.01,
                  24.18: 0.0,
                  24.21: 0.0,
                  24.24: 0.0,
                  24.27: 0.0,
                  24.3: 0.0,
                  24.33: 0.0,
                  24.36: 0.0,
                  24.39: 0.0,
                  24.42: 0.0,
                  24.45: 0.0,
                  24.48: 0.0,
                  24.51: 0.0,
                  24.54: 0.0,
                  24.57: 0.0,
                  24.6: 0.0,
                  24.63: 0.0,
                  24.66: 0.0,
                  24.69: 0.0,
                  24.72: 0.0,
                  24.75: 0.0,
                  24.78: 0.0,
                  24.81: 0.0,
                  24.84: 0.0,
                  24.87: 0.0,
                  24.9: 0.0,
                  24.93: 0.0,
                  24.96: 0.0,
                  24.99: 0.0,
                  25.02: 0.0,
                  25.05: 0.0,
                  25.08: 0.0,
                  25.11: 0.0,
                  25.14: 0.0,
                  25.17: 0.0,
                  25.2: 0.0,
                  25.23: 0.0,
                  25.26: 0.01,
                  25.29: 0.0,
                  25.32: 0.0,
                  25.35: 0.0,
                  25.38: 0.0,
                  25.41: 0.0,
                  25.44: 0.0,
                  25.47: 0.0,
                  25.5: 0.0,
                  25.53: 0.0,
                  25.56: 0.0,
                  25.59: 0.0,
                  25.62: 0.0,
                  25.65: 0.0,
                  25.68: 0.0,
                  25.71: 0.0,
                  25.74: 0.0,
                  25.77: 0.0,
                  25.8: 0.0,
                  25.83: 0.0,
                  25.86: 0.0,
                  25.89: 0.0,
                  25.92: 0.0,
                  25.95: 0.0,
                  25.98: 0.0,
                  26.01: 0.0,
                  26.04: 0.0,
                  26.07: 0.0,
                  26.1: 0.0,
                  26.13: 0.0,
                  26.16: 0.0,
                  26.19: 0.0,
                  26.22: 0.0,
                  26.25: 0.0,
                  26.28: 0.0,
                  26.31: 0.0,
                  26.34: 0.0,
                  26.37: 0.0,
                  26.4: 0.01,
                  26.43: 0.02,
                  26.46: 0.05,
                  26.49: 0.04,
                  26.52: 0.02,
                  26.55: 0.01,
                  26.58: 0.0,
                  26.61: 0.0,
                  26.64: 0.0,
                  26.67: 0.0,
                  26.7: 0.0,
                  26.73: 0.0,
                  26.76: 0.0,
                  26.79: 0.0,
                  26.82: 0.0,
                  26.85: 0.0,
                  26.88: 0.0,
                  26.91: 0.0,
                  26.94: 0.0,
                  26.97: 0.0,
                  '27.0': 0.0,
                  27.03: 0.0,
                  27.06: 0.0,
                  27.09: 0.0,
                  27.12: 0.0,
                  27.15: 0.0,
                  27.18: 0.0,
                  27.21: 0.0,
                  27.24: 0.0,
                  27.27: 0.0,
                  27.3: 0.0,
                  27.33: 0.0,
                  27.36: 0.0,
                  27.39: 0.0,
                  27.42: 0.0,
                  27.45: 0.0,
                  27.48: 0.0,
                  27.51: 0.0,
                  27.54: 0.0,
                  27.57: 0.0,
                  27.6: 0.0,
                  27.63: 0.0,
                  27.66: 0.0,
                  27.69: 0.0,
                  27.72: 0.0,
                  27.75: 0.0,
                  27.78: 0.0,
                  27.81: 0.0,
                  27.84: 0.0,
                  27.87: 0.0,
                  27.9: 0.0,
                  27.93: 0.0,
                  27.96: 0.0,
                  27.99: 0.0,
                  28.02: 0.0,
                  28.05: 0.0,
                  28.08: 0.0,
                  28.11: 0.0,
                  28.14: 0.0,
                  28.17: 0.0,
                  28.2: 0.0,
                  28.23: 0.0,
                  28.26: 0.0,
                  28.29: 0.0,
                  28.32: 0.0,
                  28.35: 0.0,
                  28.38: 0.0,
                  28.41: 0.0,
                  28.44: 0.0,
                  28.47: 0.0,
                  28.5: 0.0,
                  28.53: 0.0,
                  28.56: 0.0,
                  28.59: 0.0,
                  28.62: 0.0,
                  28.65: 0.0,
                  28.68: 0.0,
                  28.71: 0.0,
                  28.74: 0.0,
                  28.77: 0.0,
                  28.8: 0.0,
                  28.83: 0.0,
                  28.86: 0.0,
                  28.89: 0.0,
                  28.92: 0.0,
                  28.95: 0.0,
                  28.98: 0.0,
                  29.01: 0.0,
                  29.04: 0.0,
                  29.07: 0.0,
                  29.1: 0.0,
                  29.13: 0.0,
                  29.16: 0.0,
                  29.19: 0.0,
                  29.22: 0.0,
                  29.25: 0.0,
                  29.28: 0.0,
                  29.31: 0.0,
                  29.34: 0.0,
                  29.37: 0.0,
                  29.4: 0.0,
                  29.43: 0.0,
                  29.46: 0.0,
                  29.49: 0.0,
                  29.52: 0.0,
                  29.55: 0.0,
                  29.58: 0.0,
                  29.61: 0.0,
                  29.64: 0.0,
                  29.67: 0.0,
                  29.7: 0.0,
                  29.73: 0.0,
                  29.76: 0.0,
                  29.79: 0.0,
                  29.82: 0.0,
                  29.85: 0.0,
                  29.88: 0.0,
                  29.91: 0.0,
                  29.94: 0.0,
                  29.97: 0.0,
                  '30.0': 0.0,
                  30.03: 0.0,
                  30.06: 0.0,
                  30.09: 0.0,
                  30.12: 0.0,
                  30.15: 0.0,
                  30.18: 0.0,
                  30.21: 0.0,
                  30.24: 0.0,
                  30.27: 0.0,
                  30.3: 0.0,
                  30.33: 0.0,
                  30.36: 0.0,
                  30.39: 0.0,
                  30.42: 0.0,
                  30.45: 0.0,
                  30.48: 0.0,
                  30.51: 0.0,
                  30.54: 0.0,
                  30.57: 0.0,
                  30.6: 0.0,
                  30.63: 0.0,
                  30.66: 0.0,
                  30.69: 0.0,
                  30.72: 0.01,
                  30.75: 0.0,
                  30.78: 0.0,
                  30.81: 0.0,
                  30.84: 0.0,
                  30.87: 0.0,
                  30.9: 0.0,
                  30.93: 0.0,
                  30.96: 0.0,
                  30.99: 0.0,
                  31.02: 0.0,
                  31.05: 0.0,
                  31.08: 0.0,
                  31.11: 0.0,
                  31.14: 0.0,
                  31.17: 0.0,
                  31.2: 0.0,
                  31.23: 0.0,
                  31.26: 0.0,
                  31.29: 0.0,
                  31.32: 0.0,
                  31.35: 0.0,
                  31.38: 0.0,
                  31.41: 0.0,
                  31.44: 0.0,
                  31.47: 0.0,
                  31.5: 0.0,
                  31.53: 0.0,
                  31.56: 0.0,
                  31.59: 0.0,
                  31.62: 0.0,
                  31.65: 0.0,
                  31.68: 0.0,
                  31.71: 0.0,
                  31.74: 0.0,
                  31.77: 0.0,
                  31.8: 0.0,
                  31.83: 0.0,
                  31.86: 0.0,
                  31.89: 0.0,
                  31.92: 0.0,
                  31.95: 0.0,
                  31.98: 0.0,
                  32.01: 0.0,
                  32.04: 0.0,
                  32.07: 0.0,
                  32.1: 0.0,
                  32.13: 0.0,
                  32.16: 0.0,
                  32.19: 0.0,
                  32.22: 0.0,
                  32.25: 0.0,
                  32.28: 0.0,
                  32.31: 0.0,
                  32.34: 0.0,
                  32.37: 0.0,
                  32.4: 0.0,
                  32.43: 0.0,
                  32.46: 0.0,
                  32.49: 0.0,
                  32.52: 0.0,
                  32.55: 0.0,
                  32.58: 0.0,
                  32.61: 0.0,
                  32.64: 0.0,
                  32.67: 0.0,
                  32.7: 0.0,
                  32.73: 0.0,
                  32.76: 0.0,
                  32.79: 0.0,
                  32.82: 0.0,
                  32.85: 0.0,
                  32.88: 0.0,
                  32.91: 0.0,
                  32.94: 0.0,
                  32.97: 0.0,
                  '33.0': 0.0,
                  33.03: 0.0,
                  33.06: 0.0,
                  33.09: 0.0,
                  33.12: 0.0,
                  33.15: 0.0,
                  33.18: 0.0,
                  33.21: 0.0,
                  33.24: 0.0,
                  33.27: 0.0,
                  33.3: 0.0,
                  33.33: 0.0,
                  33.36: 0.0,
                  33.39: 0.0,
                  33.42: 0.0,
                  33.45: 0.0,
                  33.48: 0.0,
                  33.51: 0.0,
                  33.54: 0.0,
                  33.57: 0.0,
                  33.6: 0.0,
                  33.63: 0.0,
                  33.66: 0.0,
                  33.69: 0.0,
                  33.72: 0.0,
                  33.75: 0.0,
                  33.78: 0.0,
                  33.81: 0.0,
                  33.84: 0.0,
                  33.87: 0.0,
                  33.9: 0.0,
                  33.93: 0.0,
                  33.96: 0.0,
                  33.99: 0.0,
                  34.02: 0.0,
                  34.05: 0.0,
                  34.08: 0.0,
                  34.11: 0.0,
                  34.14: 0.0,
                  34.17: 0.0,
                  34.2: 0.0,
                  34.23: 0.0,
                  34.26: 0.0,
                  34.29: 0.0,
                  34.32: 0.0,
                  34.35: 0.0,
                  34.38: 0.0,
                  34.41: 0.0,
                  34.44: 0.0,
                  34.47: 0.0,
                  34.5: 0.0,
                  34.53: 0.0,
                  34.56: 0.0,
                  34.59: 0.0,
                  34.62: 0.0,
                  34.65: 0.0,
                  34.68: 0.0,
                  34.71: 0.0,
                  34.74: 0.0,
                  34.77: 0.0,
                  34.8: 0.0,
                  34.83: 0.0,
                  34.86: 0.0,
                  34.89: 0.0,
                  34.92: 0.0,
                  34.95: 0.0,
                  34.98: 0.0,
                  35.01: 0.0,
                  35.04: 0.0,
                  35.07: 0.0,
                  35.1: 0.0,
                  35.13: 0.0,
                  35.16: 0.0,
                  35.19: 0.0,
                  35.22: 0.0,
                  35.25: 0.0,
                  35.28: 0.0,
                  35.31: 0.0,
                  35.34: 0.0,
                  35.37: 0.0,
                  35.4: 0.0,
                  35.43: 0.0,
                  35.46: 0.0,
                  35.49: 0.0,
                  35.52: 0.0,
                  35.55: 0.0,
                  35.58: 0.0,
                  35.61: 0.0,
                  35.64: 0.0,
                  35.67: 0.0,
                  35.7: 0.0,
                  35.73: 0.0,
                  35.76: 0.0,
                  35.79: 0.0,
                  35.82: 0.0,
                  35.85: 0.0,
                  35.88: 0.0,
                  35.91: 0.0,
                  35.94: 0.0,
                  35.97: 0.0,
                },
              },
            },
          },
          status: 'SUCCEEDED',
        },
      },
      status: 'SUCCEEDED',
    };

    const downloadAvailable = {
      result: [{
        download_id: 'Dec916ebb9ctemp_admin20201013111136866',
        download_type: 'scenario',
        presigned_url: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-zip-file.zip',
        // tslint:disable-next-line: max-line-length
        // presigned_url: 'https://aws-a0036-use1-00-d-s3b-rwcb-chb-data01.s3.amazonaws.com/clinical-data-lake/qa/applications/enrollment-monitoring-prediction/processed/temp_files/asthma_study_Demo_scenario_asthma_1.zip?AWSAccessKeyId=ASIA6QQBTVNR7RUSZJX3&Signature=fzwDVKB6%2FM3PvfoyKGctX3U070Y%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEJn%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJHMEUCIGqAymgYBU06Lk156HnmTYcxr5nmVDLXb%2BBkILMmir7YAiEAhOc389x5qRbPuiqyczzrcncaE9c5Fm%2BAFXIpi9HnFPoqvQMI0v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARABGgw5OTc1MDk1NDA3MDciDNe0O4oGCEmu6Z5XWyqRAwEz3fI2P2J2M2kyytPWXRQMSmIZRQyuvprmsWqpnAnusRXzMp2ZJwMWk2BW%2FDTj0KeW8aNBJT2%2BpwZgj6Mv%2FxPDmyWHzeRIt2c6v48kMHVBN0HXrztYnzP%2FDoZeJgqxExGjs7%2BhbxApT3myE0uvqJD2BjCuBIhzwmqqA%2FWH42uetNWkuKgf2%2B2SqioBPoVWtxCTqRH5g7DTb8zHlxGBqeAx1XbsC7qX5vPs2NTf6VRGrldhv%2FJzk339XyxIrzscYkeTZYF1%2BmDr7dQI9krv6FGENw6rOewcrX8OFlNzGkTyGyfZNIxJz5fW7teMmR%2BL1UHa16qQ4qPsK7v3RF4724%2F7zErdQTczY9lImr5NrYRv4tM0ECQ0WMyf2a08fItpHCExtyPsHzh%2B5I7oeuy%2F4OH47WhVJRCcokVIyN5rvvcFNQDp%2FCMOhYUZdTKAVzgTJDrN4tNxYE9aGR%2F75Tk32H51G%2F6qgHoUng%2FKL0xcqI0pihtDHijHXQnWnjqmTskJ7FOOk0L36rETBCk1VRoHBCJAMLWcoPwFOusBtb60qKyStX3QEjsF6XWQyxXEOFzXWDgmUd0OTJ2Z%2FEvEeZGiS%2F0y9jtLK0shaiyYqRfJ%2BkyPnKI6zvFw%2F8Rg3y3clhG52pjC7FaoxLp1jeMdqFzgWV8tqBRPqFVzA3nvcx91o6eFjHX4kBnxy0nEoZWLvvaYxoZZKRyPk%2FvmMTNpHcM1bOL12cg%2FWB89ZY00J5x%2BQBnZ5EKB5S3rO1Kfxzg1KHzD%2BaKumP11%2F%2FaWqSVBgI51rGAKSXsHVoIzIJh4k7g%2F1myJ%2FZA1zk8c9Stg0lK35OhGnwN0vZBQT2qfsYCYCvQSDW5PwDk6uA%3D%3D&Expires=1602928414',
      }],
      status: 'SUCCEEDED',
    };

    const scenarioNameValidation = {
      status: 'SUCCEEDED',
      is_valid_flag: 'Valid',
    };

    const geoDropdown = {
      result: {
        geo_list: [
          'British Indian Ocean Territory',
          'Cocos (Keeling) Islands',
          'France',
          'Hong Kong',
          'India',
          'South Georgia and South Sandwich Is.',
        ],
      },
      status: 'SUCCEEDED',
    };

    const scenarioID = {
      result: {
        scenario_id: '3c498f34-f18c-45ec-afc8-cf712212d2f2',
      },
      status: 'SUCCEEDED',
    };

    const versionID = {
      result: {
        new_scenario_version: '20200410141215767',
      },
      status: 'SUCCEEDED',
    };

    const countryData = {
      result: {
        all_countries_info: [{
            actual_enrolled_patient: '21',
            actual_median_randomization_enrollment_rate: '0.452',
            country: 'Argentina',
            historical_median_randomisation_enrollment_rate: '1.295',
            historical_no_sites: '5',
            median_adjusted_randomisation_enrollment_rate: '1.208',
            region: 'South America',
            std_randomisation_enrollment_rate: '0.542',
          },
          {
            actual_enrolled_patient: '5',
            actual_median_randomization_enrollment_rate: '0.442',
            country: 'Australia',
            historical_median_randomisation_enrollment_rate: '1.551',
            historical_no_sites: '5',
            median_adjusted_randomisation_enrollment_rate: '1.386',
            region: 'AUS',
            std_randomisation_enrollment_rate: '0.676',
          },
          // {
          //   actual_enrolled_patient: '5555555555555555555555555555555555555555555555',
          //   actual_median_randomization_enrollment_rate: '0.44200',
          //   country: 'Australia Australia Australia Australia',
          //   historical_median_randomisation_enrollment_rate: '1.55100',
          //   historical_no_sites: '5555555555555555555555555555555555555555555555',
          //   median_adjusted_randomisation_enrollment_rate: '1.38600',
          //   region: 'ANZANZANZANZ ANZANZANZANZ ANZANZANZANZ',
          //   std_randomisation_enrollment_rate: '0.67600'
          // },
          {
            actual_enrolled_patient: '15',
            actual_median_randomization_enrollment_rate: '0.418',
            country: 'Austria',
            historical_median_randomisation_enrollment_rate: '1.041',
            historical_no_sites: '4',
            median_adjusted_randomisation_enrollment_rate: '1.065',
            region: 'Europe',
            std_randomisation_enrollment_rate: '0.598',
          },
          {
            actual_enrolled_patient: '11',
            actual_median_randomization_enrollment_rate: '0.503',
            country: 'China',
            historical_median_randomisation_enrollment_rate: '0.981',
            historical_no_sites: '5',
            median_adjusted_randomisation_enrollment_rate: '0.744',
            region: 'Asia',
            std_randomisation_enrollment_rate: '0.470',
          },
          {
            actual_enrolled_patient: '14',
            actual_median_randomization_enrollment_rate: '0.235',
            country: 'India',
            historical_median_randomisation_enrollment_rate: '1.485',
            historical_no_sites: '4',
            median_adjusted_randomisation_enrollment_rate: '1.365',
            region: 'Asia',
            std_randomisation_enrollment_rate: '0.676',
          },
          {
            actual_enrolled_patient: '23',
            actual_median_randomization_enrollment_rate: '0.589',
            country: 'Indonesia',
            historical_median_randomisation_enrollment_rate: '1.058',
            historical_no_sites: '5',
            median_adjusted_randomisation_enrollment_rate: '0.759',
            region: 'Asia',
            std_randomisation_enrollment_rate: '0.504',
          },
          {
            actual_enrolled_patient: '14',
            actual_median_randomization_enrollment_rate: '0.083',
            country: 'Kenya',
            historical_median_randomisation_enrollment_rate: '0.689',
            historical_no_sites: '4',
            median_adjusted_randomisation_enrollment_rate: '0.619',
            region: 'Africa',
            std_randomisation_enrollment_rate: '0.469',
          },
          {
            actual_enrolled_patient: '32',
            actual_median_randomization_enrollment_rate: '0.460',
            country: 'South Africa',
            historical_median_randomisation_enrollment_rate: '1.243',
            historical_no_sites: '4',
            median_adjusted_randomisation_enrollment_rate: '0.864',
            region: 'Africa',
            std_randomisation_enrollment_rate: '0.503',
          },
          {
            actual_enrolled_patient: '23',
            actual_median_randomization_enrollment_rate: '0.319',
            country: 'Spain',
            historical_median_randomisation_enrollment_rate: '1.375',
            historical_no_sites: '4',
            median_adjusted_randomisation_enrollment_rate: '0.804',
            region: 'Europe',
            std_randomisation_enrollment_rate: '0.619',
          },
          {
            actual_enrolled_patient: '8',
            actual_median_randomization_enrollment_rate: '0.400',
            country: 'United States',
            historical_median_randomisation_enrollment_rate: '1.257',
            historical_no_sites: '5',
            median_adjusted_randomisation_enrollment_rate: '0.904',
            region: 'North America',
            std_randomisation_enrollment_rate: '0.542',
          },
        ],
        selected_country_info: [{
            country: 'Argentina',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'South America',
            target_no_patients_country: '25',
          },
          {
            country: 'Australia',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'AUS',
            target_no_patients_country: '20',
          },
          {
            country: 'Austria',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Europe',
            target_no_patients_country: '20',
          },
          {
            country: 'China',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Asia',
            target_no_patients_country: '13',
          },
          {
            country: 'India',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Asia',
            target_no_patients_country: '16',
          },
          {
            country: 'Indonesia',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Asia',
            target_no_patients_country: '26',
          },
          {
            country: 'Kenya',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Africa',
            target_no_patients_country: '15',
          },
          {
            country: 'South Africa',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Africa',
            target_no_patients_country: '35',
          },
          {
            country: 'Spain',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'Europe',
            target_no_patients_country: '25',
          },
          {
            country: 'United States',
            created_by: 'aj1234',
            created_timestamp: '2020-09-17 08:36:02.617447',
            region: 'North America',
            target_no_patients_country: '10',
          },
        ],
      },
      status: 'SUCCEEDED',
    };

    // const countrySelectionsValidation = {
    //   result: {
    //     validation_failed_for: [],
    //     validation_flag: 0,
    //   },
    //   status: 'SUCCEEDED',
    // };

    const countrySelectionsValidationFail = {
      result: {
        validation_failed_for: ['Asia'],
        validation_flag: 0,
      },
      status: 'SUCCEEDED',
    };

    const historicalSiteActivation = {
      result: {
        FRANCE: {
          fifth_quintile: '25.0',
          first_quintile: '25.0',
          fourth_quintile: '0.0',
          second_quintile: '50.0',
          third_quintile: '0.0',
        },
        ITALY: {
          fifth_quintile: '22.22',
          first_quintile: '44.44',
          fourth_quintile: '0.0',
          second_quintile: '22.22',
          third_quintile: '11.11',
        },
      },
      status: 'SUCCEEDED',
    };

    const historicalActivationCurve = {
      result: {
        historical: {
          10: '0.0',
          100: '100.0',
          20: '0.0',
          30: '0.0',
          40: '35.29',
          50: '58.82',
          60: '76.47',
          70: '76.47',
          80: '88.23',
          90: '100.0',
        },
      },
      status: 'SUCCEEDED',
    };

    const simulatedSiteActivation = {
      result: {
        FRANCE: {
          fifth_quintile: '20.0',
          first_quintile: '40.0',
          fourth_quintile: '20.0',
          second_quintile: '0.0',
          third_quintile: '20.0',
        },
        GERMANY: {
          fifth_quintile: '25.0',
          first_quintile: '12.5',
          fourth_quintile: '0.0',
          second_quintile: '50.0',
          third_quintile: '12.5',
        },
        ITALY: {
          fifth_quintile: '22.22',
          first_quintile: '11.11',
          fourth_quintile: '22.22',
          second_quintile: '11.11',
          third_quintile: '33.33',
        },
      },
      status: 'SUCCEEDED',
    };

    const simulatedActivationCurve = {
      result: {
        simulated: {
          30: '69.05',
          40: '85.71',
          50: '100.0',
        },
        actual: {
          10: '42.86',
          20: '52.38',
        },
      },
      status: 'SUCCEEDED',
    };

    const runEnrollmentModel = {
      result: 'Results saved',
      status: 'SUCCEEDED',
    };

    const lowerModellingLevel = {
      result: {
        france: {
          ctfo_site_12705: {
            actual_patients_enrolled: {
              0: '8.800506048865342',
              1: '8.800506048865342',
              2: '8.800506048865342',
              3: '8.800506048865342',
              4: '8.800506048865342',
              5: '8.800506048865342',
              6: '8.800506048865342',
              7: '8.800506048865342',
              8: '8.800506048865342',
              9: '8.800506048865342',
              10: '8.800506048865342',
              11: '8.800506048865342',
              12: '0.02095358583063177',
              13: '0.7501383727366173',
              14: '1.4835138768087293',
              15: '2.216889380880841',
              16: '2.950264884952953',
              17: '3.6836403890250646',
              18: '4.417015893097177',
              19: '5.1503913971692885',
              20: '5.879576184075274',
              21: '6.612951688147386',
              22: '7.346327192219499',
              23: '8.07970269629161',
              24: '8.813078200363723',
              25: '9.546453704435834',
              26: '10.279829208507946',
              27: '11.00901399541393',
              28: '11.742389499486043',
              29: '12.475765003558156',
              30: '13.209140507630266',
              31: '13.94251601170238',
              32: '14.675891515774492',
              33: '15.409267019846604',
              34: '16.138451806752588',
              35: '16.8718273108247',
              36: '17.60520281489681',
              37: '18.338578318968924',
              38: '19.071953823041035',
              39: '19.805329327113146',
              40: '20.53870483118526',
              41: '21.267889618091246',
              42: '22.001265122163357',
              43: '22.734640626235468',
              44: '23.468016130307582',
              45: '24.201391634379693',
              46: '24.934767138451804',
              47: '25.66395192535779',
              48: '26.397327429429904',
              49: '27.13070293350201',
              50: '27.864078437574122',
            },
            enrollments: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.0',
              13: '0.0',
              14: '0.0',
              15: '0.0',
              16: '0.0',
              17: '0.0',
              18: '0.0',
              19: '0.0',
              20: '0.0',
              21: '0.0',
              22: '0.0',
              23: '0.0',
              24: '0.0',
              25: '0.0',
              26: '0.0',
              27: '0.0',
              28: '0.0',
              29: '0.0',
              30: '14.363517869345328',
              31: '15.177804076345328',
              32: '15.992090283345329',
              33: '16.80637649034533',
              34: '17.62066269734533',
              35: '18.434948904345333',
              36: '19.24923511134533',
              37: '20.063521318345334',
              38: '20.877807525345332',
              39: '21.69209373234533',
              40: '22.50637993934533',
              41: '23.320666146345328',
              42: '24.134952353345327',
              43: '24.949238560345325',
              44: '25.763524767345324',
              45: '26.577810974345322',
              46: '27.39209718134532',
              47: '28.20638338834532',
              48: '29.020669595345318',
              49: '29.834955802345316',
              50: '30.649242009345315',
              51: '31.463528216345313',
              52: '32.27781442334531',
              53: '33.092100630345314',
              54: '33.90638683734531',
              55: '34.72067304434531',
              56: '35.534959251345306',
              57: '36.34924545834531',
              58: '37.1635316653453',
              59: '37.977817872345305',
              60: '38.7921040793453',
              61: '39.6063902863453',
              62: '40.4206764933453',
              63: '41.2349627003453',
              64: '42.0492489073453',
              65: '42.863535114345304',
              66: '43.677821321345306',
              67: '44.49210752834531',
              68: '45.30639373534531',
              69: '46.12067994234531',
              70: '46.93496614934532',
              71: '47.74925235634532',
              72: '48.563538563345325',
              73: '49.37782477034533',
              74: '50.19211097734533',
              75: '51.00639718434533',
              76: '51.820683391345334',
              77: '52.63496959834534',
              78: '53.449255805345345',
              79: '54.26354201234535',
              80: '55.07782821934535',
              81: '55.89211442634535',
              82: '56.70640063334535',
              83: '57.520686840345356',
              84: '58.334973047345365',
              85: '59.14925925434537',
              86: '59.96354546134537',
              87: '60.77783166834537',
              88: '61.59211787534537',
              89: '62.406404082345375',
              90: '63.22069028934538',
              91: '64.03497649634538',
              92: '64.84926270334539',
              93: '65.66354891034538',
              94: '66.47783511734539',
              95: '67.2921213243454',
              96: '68.1064075313454',
              97: '68.9206937383454',
              98: '69.7349799453454',
              99: '70.54926615234541',
              100: '71.3635523593454',
              101: '72.17783856634541',
              102: '72.99212477334542',
              103: '73.80641098034542',
              104: '74.62069718734543',
              105: '75.43498339434542',
              106: '76.24926960134543',
              107: '77.06355580834543',
              108: '77.87784201534544',
              109: '78.69212822234545',
              110: '79.50641442934544',
              111: '80.32070063634545',
              112: '81.13498684334544',
              113: '81.94927305034544',
              114: '82.76355925734543',
              115: '83.57784546434543',
              116: '84.39213167134542',
              117: '85.20641787834542',
              118: '86.02070408534541',
              119: '86.83499029234541',
              120: '87.6492764993454',
              121: '88.4635627063454',
              122: '89.2778489133454',
              123: '90.09213512034539',
            },
            lower_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.0',
              13: '0.0',
              14: '0.0',
              15: '0.0',
              16: '0.0',
              17: '0.0',
              18: '0.0',
              19: '0.0',
              20: '0.0',
              21: '0.0',
              22: '0.0',
              23: '0.0',
              24: '0.0',
              25: '0.0',
              26: '0.0',
              27: '0.0',
              28: '0.0',
              29: '0.0',
              30: '10.797842878329568',
              31: '11.536286539720836',
              32: '12.275130249208964',
              33: '13.014350129805997',
              34: '13.753924273231515',
              35: '14.493832532066271',
              36: '15.234056338611946',
              37: '15.974578546429058',
              38: '16.715383291219407',
              39: '17.456455868279747',
              40: '18.197782624208045',
              41: '18.93935086091503',
              42: '19.681148750298036',
              43: '20.423165258185527',
              44: '21.16539007636842',
              45: '21.907813561707748',
              46: '22.6504266814526',
              47: '23.393220964023968',
              48: '24.136188454622182',
              49: '24.87932167510229',
              50: '25.622613587634948',
              51: '26.36605756173317',
              52: '27.109647344278276',
              53: '27.853377032224532',
              54: '28.597241047701026',
              55: '29.34123411526334',
              56: '30.085351241076832',
              57: '30.829587693838803',
              58: '31.573938987268804',
              59: '32.318400864015665',
              60: '33.062969280846495',
              61: '33.807640394997684',
              62: '34.55241055158083',
              63: '35.29727627194773',
              64: '36.04223424292877',
              65: '36.787281306867484',
              66: '37.532414452382355',
              67: '38.27763080579335',
              68: '39.02292762315706',
              69: '39.76830228285977',
              70: '40.51375227872251',
              71: '41.25927521357653',
              72: '42.00486879327161',
              73: '42.750530821082855',
              74: '43.496259192484935',
              75: '44.24205189026549',
              76: '44.98790697995173',
              77: '45.73382260552678',
              78: '46.47979698541426',
              79: '47.22582840871124',
              80: '47.971915231651735',
              81: '48.718055874284055',
              82: '49.46424881734689',
              83: '50.21049259933024',
              84: '50.95678581370821',
              85: '51.7031271063321',
              86: '52.44951517297268',
              87: '53.19594875700188',
              88: '53.94242664720437',
              89: '54.68894767571066',
              90: '55.43551071604381',
              91: '56.182114681272374',
              92: '56.92875852226283',
              93: '57.675441226025185',
              94: '58.42216181414598',
              95: '59.168919341303315',
              96: '59.91571289385874',
              97: '60.66254158852148',
              98: '61.40940457108057',
              99: '62.15630101520088',
              100: '62.90323012127926',
              101: '63.65019111535725',
              102: '64.3971832480872',
              103: '65.14420579374855',
              104: '65.8912580493115',
              105: '66.63833933354553',
              106: '67.38544898617003',
              107: '68.13258636704481',
              108: '68.87975085539836',
              109: '69.62694184909172',
              110: '70.37415876391609',
              111: '71.1214010329222',
              112: '71.86866810578009',
              113: '72.61595944816743',
              114: '73.36327454118485',
              115: '74.11061288079713',
              116: '74.85797397729874',
              117: '75.60535735480245',
              118: '76.35276255074996',
              119: '77.10018911544347',
              120: '77.84763661159693',
              121: '78.59510461390629',
              122: '79.34259270863748',
              123: '80.09010049323166',
            },
            upper_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.0',
              13: '0.0',
              14: '0.0',
              15: '0.0',
              16: '0.0',
              17: '0.0',
              18: '0.0',
              19: '0.0',
              20: '0.0',
              21: '0.0',
              22: '0.0',
              23: '0.0',
              24: '0.0',
              25: '0.0',
              26: '0.0',
              27: '0.0',
              28: '0.0',
              29: '0.0',
              30: '17.929192860361088',
              31: '18.81932161296982',
              32: '19.709050317481694',
              33: '20.59840285088466',
              34: '21.487401121459143',
              35: '22.37606527662439',
              36: '23.26441388407872',
              37: '24.15246409026161',
              38: '25.040231759471258',
              39: '25.927731596410915',
              40: '26.814977254482613',
              41: '27.70198143177563',
              42: '28.588755956392617',
              43: '29.475311862505123',
              44: '30.361659458322226',
              45: '31.247808386982896',
              46: '32.13376768123804',
              47: '33.01954581266667',
              48: '33.90515073606845',
              49: '34.79058992958834',
              50: '35.675870431055685',
              51: '36.560998870957455',
              52: '37.445981502412344',
              53: '38.33082422846609',
              54: '39.21553262698959',
              55: '40.100111973427275',
              56: '40.98456726161378',
              57: '41.86890322285181',
              58: '42.7531243434218',
              59: '43.63723488067494',
              60: '44.5212388778441',
              61: '45.40514017769292',
              62: '46.288942435109774',
              63: '47.17264912874286',
              64: '48.05626357176183',
              65: '48.93978892182312',
              66: '49.82322819030826',
              67: '50.706584250897265',
              68: '51.58985984753357',
              69: '52.47305760183086',
              70: '53.35618001996813',
              71: '54.23922949911411',
              72: '55.12220833341904',
              73: '56.0051187196078',
              74: '56.88796276220573',
              75: '57.77074247842518',
              76: '58.65345980273894',
              77: '59.536116591163896',
              78: '60.41871462527642',
              79: '61.30125561597946',
              80: '62.18374120703896',
              81: '63.066172978406655',
              82: '63.948552449343815',
              83: '64.83088108136049',
              84: '65.71316028098252',
              85: '66.59539140235864',
              86: '67.47757574971806',
              87: '68.35971457968886',
              88: '69.24180910348637',
              89: '70.1238604889801',
              90: '71.00586986264696',
              91: '71.88783831141839',
              92: '72.76976688442794',
              93: '73.6516565946656',
              94: '74.5335084205448',
              95: '75.41532330738748',
              96: '76.29710216883205',
              97: '77.17884588816932',
              98: '78.06055531961024',
              99: '78.94223128948994',
              100: '79.82387459741156',
              101: '80.70548601733357',
              102: '81.58706629860362',
              103: '82.4686161669423',
              104: '83.35013632537934',
              105: '84.23162745514531',
              106: '85.11309021652085',
              107: '85.99452524964607',
              108: '86.87593317529253',
              109: '87.75731459559915',
              110: '88.6386700947748',
              111: '89.5200002397687',
              112: '90.40130558091082',
              113: '91.28258665252346',
              114: '92.16384397350603',
              115: '93.04507804789374',
              116: '93.92628936539211',
              117: '94.80747840188839',
              118: '95.68864561994087',
              119: '96.56979146924735',
              120: '97.45091638709387',
              121: '98.33202079878451',
              122: '99.2131051180533',
              123: '100.0941697474591',
            },
          },
          ctfo_site_174355: {
            actual_patients_enrolled: {
              0: '12.199493951134656',
              1: '12.199493951134656',
              2: '12.199493951134656',
              3: '12.199493951134656',
              4: '12.199493951134656',
              5: '12.199493951134656',
              6: '12.199493951134656',
              7: '12.199493951134656',
              8: '12.199493951134656',
              9: '12.199493951134656',
              10: '12.199493951134656',
              11: '12.199493951134656',
              12: '0.02904641416936823',
              13: '1.0398616272633827',
              14: '2.0564861231912706',
              15: '3.073110619119159',
              16: '4.089735115047047',
              17: '5.106359610974934',
              18: '6.122984106902822',
              19: '7.13960860283071',
              20: '8.150423815924725',
              21: '9.167048311852612',
              22: '10.183672807780502',
              23: '11.200297303708389',
              24: '12.216921799636278',
              25: '13.233546295564166',
              26: '14.250170791492053',
              27: '15.260986004586067',
              28: '16.277610500513955',
              29: '17.294234996441844',
              30: '18.31085949236973',
              31: '19.327483988297622',
              32: '20.344108484225508',
              33: '21.360732980153397',
              34: '22.37154819324741',
              35: '23.388172689175295',
              36: '24.404797185103185',
              37: '25.421421681031074',
              38: '26.43804617695896',
              39: '27.45467067288685',
              40: '28.471295168814738',
              41: '29.48211038190875',
              42: '30.49873487783664',
              43: '31.51535937376453',
              44: '32.53198386969242',
              45: '33.548608365620304',
              46: '34.56523286154819',
              47: '35.57604807464221',
              48: '36.592672570570095',
              49: '37.60929706649798',
              50: '38.625921562425866',
            },
            enrollments: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '1.3965648514082967',
              11: '1.6158088330020757',
              12: '1.868750181499979',
              13: '2.1216915299978822',
              14: '2.3746328784957855',
              15: '2.627574226993689',
              16: '2.880515575491592',
              17: '3.1334569239894954',
              18: '3.3863982724873987',
              19: '3.639339620985302',
              20: '3.8922809694832052',
              21: '4.1452223179811085',
              22: '4.398163666479012',
              23: '4.651105014976915',
              24: '4.904046363474818',
              25: '5.156987711972722',
              26: '5.409929060470625',
              27: '5.662870408968528',
              28: '5.9158117574664315',
              29: '6.168753105964335',
              30: '6.539484870964335',
              31: '6.9102166359643356',
              32: '7.280948400964336',
              33: '7.651680165964336',
              34: '8.022411930964337',
              35: '8.393143695964337',
              36: '8.763875460964337',
              37: '9.134607225964338',
              38: '9.505338990964338',
              39: '9.876070755964337',
              40: '10.246802520964335',
              41: '10.617534285964336',
              42: '10.988266050964334',
              43: '11.358997815964335',
              44: '11.729729580964333',
              45: '12.100461345964334',
              46: '12.471193110964332',
              47: '12.841924875964333',
              48: '13.212656640964331',
              49: '13.58338840596433',
              50: '13.95412017096433',
              51: '14.324851935964329',
              52: '14.69558370096433',
              53: '15.066315465964328',
              54: '15.437047230964328',
              55: '15.807778995964327',
              56: '16.178510760964325',
              57: '16.549242525964324',
              58: '16.919974290964326',
              59: '17.290706055964325',
              60: '17.661437820964323',
              61: '18.032169585964322',
              62: '18.402901350964324',
              63: '18.773633115964323',
              64: '19.144364880964325',
              65: '19.515096645964324',
              66: '19.885828410964326',
              67: '20.256560175964328',
              68: '20.62729194096433',
              69: '20.998023705964332',
              70: '21.36875547096433',
              71: '21.739487235964333',
              72: '22.110219000964335',
              73: '22.480950765964337',
              74: '22.85168253096434',
              75: '23.222414295964338',
              76: '23.59314606096434',
              77: '23.963877825964342',
              78: '24.334609590964345',
              79: '24.705341355964347',
              80: '25.076073120964345',
              81: '25.446804885964347',
              82: '25.81753665096435',
              83: '26.18826841596435',
              84: '26.559000180964354',
              85: '26.929731945964352',
              86: '27.300463710964355',
              87: '27.671195475964357',
              88: '28.04192724096436',
              89: '28.41265900596436',
              90: '28.78339077096436',
              91: '29.154122535964362',
              92: '29.524854300964364',
              93: '29.895586065964366',
              94: '30.26631783096437',
              95: '30.637049595964367',
              96: '31.00778136096437',
              97: '31.37851312596437',
              98: '31.749244890964373',
              99: '32.11997665596437',
              100: '32.490708420964374',
              101: '32.861440185964376',
              102: '33.23217195096438',
              103: '33.60290371596438',
              104: '33.97363548096438',
              105: '34.344367245964385',
              106: '34.71509901096439',
              107: '35.08583077596438',
              108: '35.456562540964384',
              109: '35.82729430596439',
              110: '36.19802607096439',
              111: '36.56875783596439',
              112: '36.93948960096439',
              113: '37.31022136596439',
              114: '37.68095313096439',
              115: '38.051684895964385',
              116: '38.42241666096438',
              117: '38.79314842596438',
              118: '39.16388019096438',
              119: '39.53461195596437',
              120: '39.905343720964375',
              121: '40.27607548596437',
              122: '40.646807250964365',
              123: '41.01753901596436',
            },
            lower_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.7042932977065118',
              11: '0.8856237312080736',
              12: '1.0870742985478545',
              13: '1.3054145833506823',
              14: '1.5244663406503955',
              15: '1.7441476871376538',
              16: '1.964390048406114',
              17: '2.1851353897834374',
              18: '2.4063341399400096',
              19: '2.627943607359358',
              20: '2.8499267544216056',
              21: '3.0722512355466542',
              22: '3.2948886333933833',
              23: '3.517813845715779',
              24: '3.7410045882895506',
              25: '3.9644409883041796',
              26: '4.1881052490130575',
              27: '4.411981371058902',
              28: '4.6360549192796245',
              29: '4.860312826312657',
              30: '4.916088856796516',
              31: '5.252290697853425',
              32: '5.58867467454736',
              33: '5.925229916059424',
              34: '6.261946447892445',
              35: '6.598815097241784',
              36: '6.935827410525014',
              37: '7.27297558123661',
              38: '7.610252386609909',
              39: '7.947651131823676',
              40: '8.285165600697669',
              41: '8.622790011990586',
              42: '8.960518980552418',
              43: '9.298347482697569',
              44: '9.636270825259784',
              45: '9.974284617868825',
              46: '10.312384748054582',
              47: '10.650567358839725',
              48: '10.988828828528474',
              49: '11.327165752438477',
              50: '11.665574926356191',
              51: '12.004053331524668',
              52: '12.342598120996845',
              53: '12.681206607208395',
              54: '13.019876250641994',
              55: '13.35860464947037',
              56: '13.697389530078771',
              57: '14.036228738379133',
              58: '14.375120231838185',
              59: '14.714062072150577',
              60: '15.053052418495655',
              61: '15.39208952132329',
              62: '15.731171716620006',
              63: '16.070297420611723',
              64: '16.409465124864166',
              65: '16.748673391745772',
              66: '17.08792085022167',
              67: '17.427206191950322',
              68: '17.76652816765724',
              69: '18.10588558376273',
              70: '18.445277299242733',
              71: '18.784702222703842',
              72: '19.124159309655365',
              73: '19.46364755996284',
              74: '19.80316601546883',
              75: '20.142713757768114',
              76: '20.48228990612551',
              77: '20.821893615525585',
              78: '21.161524074844497',
              79: '21.501180505134922',
              80: '21.840862158015938',
              81: '22.180568314160265',
              82: '22.520298281872012',
              83: '22.860051395748542',
              84: '23.19982701542064',
              85: '23.539624524365596',
              86: '23.879443328788255',
              87: '24.219282856565524',
              88: '24.559142556250016',
              89: '24.899021896129035',
              90: '25.238920363335268',
              91: '25.578837463005833',
              92: '25.918772717486657',
              93: '26.258725665579256',
              94: '26.59869586182729',
              95: '26.938682875840506',
              96: '27.278686291653607',
              97: '27.618705707118128',
              98: '27.958740733325193',
              99: '28.29879099405735',
              100: '28.63885612526779',
              101: '28.978935774585352',
              102: '29.319029600843777',
              103: '29.65913727363385',
              104: '29.99925847287711',
              105: '30.339392888419958',
              106: '30.67954021964697',
              107: '31.019700175112337',
              108: '31.359872472188506',
              109: '31.700056836731044',
              110: '32.0402530027588',
              111: '32.38046071214868',
              112: '32.72067971434405',
              113: '33.060909766076314',
              114: '33.40115063109871',
              115: '33.7414020799319',
              116: '34.081663889620614',
              117: '34.42193584350084',
              118: '34.762217730977035',
              119: '35.102509347308825',
              120: '35.44281049340671',
              121: '35.78312097563642',
              122: '36.123440605631316',
              123: '36.46376920011264',
            },
            upper_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '2.088836405110081',
              11: '2.345993934796078',
              12: '2.6504260644521036',
              13: '2.937968476645082',
              14: '3.2247994163411757',
              15: '3.511000766849724',
              16: '3.79664110257707',
              17: '4.081778458195553',
              18: '4.366462405034787',
              19: '4.650735634611246',
              20: '4.934635184544804',
              21: '5.218193400415562',
              22: '5.50143869956464',
              23: '5.784396184238051',
              24: '6.067088138660086',
              25: '6.349534435641265',
              26: '6.631752871928192',
              27: '6.913759446878155',
              28: '7.195568595653238',
              29: '7.477193385616013',
              30: '8.162880885132154',
              31: '8.568142574075246',
              32: '8.973222127381312',
              33: '9.378130415869249',
              34: '9.782877414036227',
              35: '10.18747229468689',
              36: '10.591923511403662',
              37: '10.996238870692068',
              38: '11.400425595318767',
              39: '11.804490380104998',
              40: '12.208439441231004',
              41: '12.612278559938087',
              42: '13.016013121376252',
              43: '13.4196481492311',
              44: '13.823188336668883',
              45: '14.22663807405984',
              46: '14.630001473874083',
              47: '15.033282393088939',
              48: '15.436484453400189',
              49: '15.839611059490183',
              50: '16.242665415572468',
              51: '16.64565054040399',
              52: '17.04856928093181',
              53: '17.45142432472026',
              54: '17.85421821128666',
              55: '18.256953342458285',
              56: '18.65963199184988',
              57: '19.062256313549522',
              58: '19.464828350090464',
              59: '19.867350039778074',
              60: '20.26982322343299',
              61: '20.672249650605355',
              62: '21.07463098530864',
              63: '21.47696881131692',
              64: '21.87926463706448',
              65: '22.28151990018288',
              66: '22.68373597170698',
              67: '23.085914159978334',
              68: '23.48805571427142',
              69: '23.890161828165933',
              70: '24.292233642685932',
              71: '24.694272249224824',
              72: '25.096278692273305',
              73: '25.49825397196583',
              74: '25.900199046459846',
              75: '26.302114834160566',
              76: '26.704002215803175',
              77: '27.1058620364031',
              78: '27.507695107084192',
              79: '27.909502206793768',
              80: '28.311284083912756',
              81: '28.713041457768433',
              82: '29.114775020056687',
              83: '29.516485436180158',
              84: '29.918173346508063',
              85: '30.31983936756311',
              86: '30.721484093140454',
              87: '31.12310809536319',
              88: '31.5247119256787',
              89: '31.926296115799683',
              90: '32.32786117859346',
              91: '32.72940760892289',
              92: '33.13093588444207',
              93: '33.53244646634948',
              94: '33.93393980010144',
              95: '34.33541631608823',
              96: '34.73687643027513',
              97: '35.13832054481061',
              98: '35.539749048603554',
              99: '35.9411623178714',
              100: '36.34256071666096',
              101: '36.7439445973434',
              102: '37.14531430108497',
              103: '37.54667015829491',
              104: '37.948012489051656',
              105: '38.349341603508805',
              106: '38.7506578022818',
              107: '39.15196137681644',
              108: '39.55325260974027',
              109: '39.95453177519773',
              110: '40.35579913916997',
              111: '40.7570549597801',
              112: '41.158299487584735',
              113: '41.55953296585247',
              114: '41.960755630830064',
              115: '42.36196771199687',
              116: '42.76316943230815',
              117: '43.16436100842792',
              118: '43.56554265095172',
              119: '43.96671456461992',
              120: '44.367876948522024',
              121: '44.76902999629232',
              122: '45.17017389629742',
              123: '45.57130883181609',
            },
          },
        },
        'australia and new zealand': {
          ausnz_20056: {
            actual_patients_enrolled: {
              0: '8.800506048865342',
              1: '8.800506048865342',
              2: '8.800506048865342',
              3: '8.800506048865342',
              4: '8.800506048865342',
              5: '8.800506048865342',
              6: '8.800506048865342',
              7: '8.800506048865342',
              8: '8.800506048865342',
              9: '8.800506048865342',
              10: '8.800506048865342',
              11: '8.800506048865342',
              12: '0.02095358583063177',
              13: '0.7501383727366173',
              14: '1.4835138768087293',
              15: '2.216889380880841',
              16: '2.950264884952953',
              17: '3.6836403890250646',
              18: '4.417015893097177',
              19: '5.1503913971692885',
              20: '5.879576184075274',
              21: '6.612951688147386',
              22: '7.346327192219499',
              23: '8.07970269629161',
              24: '8.813078200363723',
              25: '9.546453704435834',
              26: '10.279829208507946',
              27: '11.00901399541393',
              28: '11.742389499486043',
              29: '12.475765003558156',
              30: '13.209140507630266',
              31: '13.94251601170238',
              32: '14.675891515774492',
              33: '15.409267019846604',
              34: '16.138451806752588',
              35: '16.8718273108247',
              36: '17.60520281489681',
              37: '18.338578318968924',
              38: '19.071953823041035',
              39: '19.805329327113146',
              40: '20.53870483118526',
              41: '21.267889618091246',
              42: '22.001265122163357',
              43: '22.734640626235468',
              44: '23.468016130307582',
              45: '24.201391634379693',
              46: '24.934767138451804',
              47: '25.66395192535779',
              48: '26.397327429429904',
              49: '27.13070293350201',
              50: '27.864078437574122',
            },
            enrollments: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '2.6097238670442486',
              10: '3.0790738496408876',
              11: '3.561253055480222',
              12: '4.048248062431404',
              13: '4.535243069382586',
              14: '5.05279810127005',
              15: '5.665466447493616',
              16: '6.311451184992529',
              17: '6.957435922491442',
              18: '7.603420659990355',
              19: '8.275107509410374',
              20: '8.954815688641906',
              21: '9.64926505164037',
              22: '10.355860543228157',
              23: '11.062456034815947',
              24: '11.788485676621457',
              25: '12.514515318426966',
              26: '13.240544960232477',
              27: '13.988265830731915',
              28: '14.745113186857997',
              29: '15.501960542984078',
              30: '16.304306501484078',
            },
            lower_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '2.2834139657318753',
              10: '2.7242959429673155',
              11: '3.1846497630838355',
              12: '3.6529239682501244',
              13: '4.123212078650331',
              14: '4.6129903142185364',
              15: '5.167961925033206',
              16: '5.7855064267105405',
              17: '6.414161684434294',
              18: '7.043129016802396',
              19: '7.689597550736689',
              20: '8.350149260011673',
              21: '9.023327671600121',
              22: '9.70955495798129',
              23: '10.400123485217094',
              24: '11.10263567120536',
              25: '11.812912401614051',
              26: '12.523372049016617',
              27: '13.248865768424114',
              28: '13.987550102100002',
              29: '14.729285895774067',
              30: '15.499408589288095',
            },
            upper_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '2.9360337683566224',
              10: '3.4338517563144593',
              11: '3.937856347876609',
              12: '4.443572156612684',
              13: '4.947274060114841',
              14: '5.492605888321564',
              15: '6.162970969954026',
              16: '6.837395943274517',
              17: '7.500710160548589',
              18: '8.163712303178313',
              19: '8.860617468084058',
              20: '9.55948211727214',
              21: '10.275202431680617',
              22: '11.002166128475025',
              23: '11.724788584414798',
              24: '12.474335682037552',
              25: '13.216118235239882',
              26: '13.957717871448336',
              27: '14.727665893039719',
              28: '15.502676271615991',
              29: '16.274635190194086',
              30: '17.10920441368006',
            },
          },
          ausnz_27152: {
            actual_patients_enrolled: {
              0: '8.800506048865342',
              1: '8.800506048865342',
              2: '8.800506048865342',
              3: '8.800506048865342',
              4: '8.800506048865342',
              5: '8.800506048865342',
              6: '8.800506048865342',
              7: '8.800506048865342',
              8: '8.800506048865342',
              9: '8.800506048865342',
              10: '8.800506048865342',
              11: '8.800506048865342',
              12: '0.02095358583063177',
              13: '0.7501383727366173',
              14: '1.4835138768087293',
              15: '2.216889380880841',
              16: '2.950264884952953',
              17: '3.6836403890250646',
              18: '4.417015893097177',
              19: '5.1503913971692885',
              20: '5.879576184075274',
              21: '6.612951688147386',
              22: '7.346327192219499',
              23: '8.07970269629161',
              24: '8.813078200363723',
              25: '9.546453704435834',
              26: '10.279829208507946',
              27: '11.00901399541393',
              28: '11.742389499486043',
              29: '12.475765003558156',
              30: '13.209140507630266',
              31: '13.94251601170238',
              32: '14.675891515774492',
              33: '15.409267019846604',
              34: '16.138451806752588',
              35: '16.8718273108247',
              36: '17.60520281489681',
              37: '18.338578318968924',
              38: '19.071953823041035',
              39: '19.805329327113146',
              40: '20.53870483118526',
              41: '21.267889618091246',
              42: '22.001265122163357',
              43: '22.734640626235468',
              44: '23.468016130307582',
              45: '24.201391634379693',
              46: '24.934767138451804',
              47: '25.66395192535779',
              48: '26.397327429429904',
              49: '27.13070293350201',
              50: '27.864078437574122',
            },
            enrollments: {
              1: '0.03183585280830216',
              2: '0.07125752603435068',
              3: '0.1201383705226045',
              4: '0.19079814028032344',
              5: '0.2869949394196093',
              6: '0.3965308486000207',
              7: '0.5260783363189597',
              8: '0.6648761241352324',
              9: '0.8090947333088983',
              10: '0.9718776265053937',
              11: '1.1466665200150359',
              12: '1.326233097168711',
              13: '1.5075931090276466',
              14: '1.6889531208865824',
              15: '1.8816938787608648',
              16: '2.109855434733119',
              17: '2.350424224928618',
              18: '2.5909930151241163',
              19: '2.8315618053196148',
              20: '3.0817022240868184',
              21: '3.334829836662944',
              22: '3.5934471590402484',
              23: '3.8565877763046834',
              24: '4.119728393569118',
              25: '4.39010641093755',
              26: '4.660484428305981',
              27: '4.930862445674413',
              28: '5.209318413405815',
              29: '5.49117314194144',
              30: '5.773027870477065',
              31: '6.071826565477065',
            },
            lower_confidence: {
              1: '0.015428055550007102',
              2: '0.04512464389556964',
              3: '0.08425439290311915',
              4: '0.14094610705290272',
              5: '0.2218486566525007',
              6: '0.3199114401418093',
              7: '0.4360136980577296',
              8: '0.5648698012378821',
              9: '0.7005603481251885',
              10: '0.8503577613589476',
              11: '1.0145449901364816',
              12: '1.1859836560035584',
              13: '1.3603719232136677',
              14: '1.5355101814337317',
              15: '1.7179066851822855',
              16: '1.9245816130195035',
              17: '2.1545590800345793',
              18: '2.3886742626721524',
              19: '2.622905663875403',
              20: '2.8636546229145354',
              21: '3.109648245267137',
              22: '3.360344131192511',
            },
            upper_confidence: {
              1: '0.04824365006659721',
              2: '0.09739040817313173',
              3: '0.15602234814208985',
              4: '0.24065017350774412',
              5: '0.35214122218671795',
              6: '0.4731502570582321',
              7: '0.6161429745801899',
              8: '0.7648824470325829',
              9: '0.9176291184926079',
              10: '1.0933974916518396',
              11: '1.2787880498935904',
              12: '1.4664825383338636',
              13: '1.6548142948416256',
              14: '1.842396060339433',
              15: '2.045481072339444',
              16: '2.2951292564467343',
              17: '2.546289369822656',
              18: '2.7933117675760797',
            },
          },
        },
        region: {
          Australia: {
            actual_patients_enrolled: {
              0: '26.0',
              1: '26.0',
              2: '26.0',
              3: '26.0',
              4: '26.0',
              5: '26.0',
              6: '26.0',
              7: '26.0',
              8: '26.0',
              9: '26.0',
              10: '26.0',
              11: '26.0',
              12: '0.04',
              13: '1.71',
              14: '3.38',
              15: '5.04',
              16: '6.71',
              17: '8.37',
              18: '10.04',
              19: '11.71',
              20: '13.37',
              21: '15.04',
              22: '16.7',
              23: '18.37',
              24: '20.04',
              25: '21.7',
              26: '23.37',
              27: '25.03',
              28: '26.7',
              29: '28.37',
              30: '30.03',
              31: '31.7',
              32: '33.37',
              33: '35.03',
              34: '36.7',
              35: '38.36',
              36: '40.03',
              37: '41.7',
              38: '43.36',
              39: '45.03',
              40: '46.69',
              41: '48.36',
              42: '50.03',
              43: '51.69',
              44: '53.36',
              45: '55.02',
              46: '56.69',
              47: '58.36',
              48: '60.02',
              49: '61.69',
              50: '63.35',
            },
            enrollments: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.04',
              13: '1.71',
              14: '3.38',
              15: '5.04',
              16: '6.71',
              17: '8.37',
              18: '10.04',
              19: '11.71',
              20: '13.37',
              21: '15.04',
              22: '16.7',
              23: '18.37',
              24: '20.04',
              25: '21.7',
              26: '23.37',
              27: '25.03',
              28: '26.7',
              29: '28.37',
              30: '30.03',
              31: '31.7',
              32: '33.37',
              33: '35.03',
              34: '36.7',
              35: '38.36',
              36: '40.03',
              37: '41.7',
              38: '43.36',
              39: '45.03',
              40: '46.69',
              41: '48.36',
              42: '50.03',
              43: '51.69',
              44: '53.36',
              45: '55.02',
              46: '56.69',
              47: '58.36',
              48: '60.02',
              49: '61.69',
              50: '63.35',
            },
            lower_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.0',
              13: '0.0',
              14: '0.0',
              15: '0.35',
              16: '1.19',
              17: '2.09',
              18: '3.04',
              19: '4.01',
              20: '5.01',
              21: '6.02',
              22: '7.05',
              23: '8.09',
              24: '9.14',
              25: '10.2',
              26: '11.26',
              27: '12.33',
              28: '13.41',
              29: '14.49',
              30: '15.57',
              31: '16.66',
              32: '17.75',
              33: '18.84',
              34: '19.93',
              35: '21.03',
              36: '22.13',
              37: '23.23',
              38: '24.33',
              39: '25.44',
              40: '26.54',
              41: '27.65',
              42: '28.76',
              43: '29.86',
              44: '30.97',
              45: '32.08',
              46: '33.2',
              47: '34.31',
              48: '35.42',
              49: '36.53',
              50: '37.65',
            },
            upper_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.45',
              13: '4.33',
              14: '7.14',
              15: '9.73',
              16: '12.23',
              17: '14.66',
              18: '17.04',
              19: '19.4',
              20: '21.74',
              21: '24.05',
              22: '26.36',
              23: '28.65',
              24: '30.93',
              25: '33.21',
              26: '35.47',
              27: '37.74',
              28: '39.99',
              29: '42.25',
              30: '44.5',
              31: '46.74',
              32: '48.98',
              33: '51.22',
              34: '53.46',
              35: '55.7',
              36: '57.93',
              37: '60.16',
              38: '62.39',
              39: '64.62',
              40: '66.85',
              41: '69.07',
              42: '71.3',
              43: '73.52',
              44: '75.74',
              45: '77.96',
              46: '80.18',
              47: '82.4',
              48: '84.62',
              49: '86.84',
              50: '89.06',
              51: '91.28',
            },
          },
        },
        global: {
          Australia: {
            actual_patients_enrolled: {
              0: '26.0',
              1: '26.0',
              2: '26.0',
              3: '26.0',
              4: '26.0',
              5: '26.0',
              6: '26.0',
              7: '26.0',
              8: '26.0',
              9: '26.0',
              10: '26.0',
              11: '26.0',
              12: '0.04',
              13: '1.71',
              14: '3.38',
              15: '5.04',
              16: '6.71',
              17: '8.37',
              18: '10.04',
              19: '11.71',
              20: '13.37',
              21: '15.04',
              22: '16.7',
              23: '18.37',
              24: '20.04',
              25: '21.7',
              26: '23.37',
              27: '25.03',
              28: '26.7',
              29: '28.37',
              30: '30.03',
              31: '31.7',
              32: '33.37',
              33: '35.03',
              34: '36.7',
              35: '38.36',
              36: '40.03',
              37: '41.7',
              38: '43.36',
              39: '45.03',
              40: '46.69',
              41: '48.36',
              42: '50.03',
              43: '51.69',
              44: '53.36',
              45: '55.02',
              46: '56.69',
              47: '58.36',
              48: '60.02',
              49: '61.69',
              50: '63.35',
            },
            enrollments: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.04',
              13: '1.71',
              14: '3.38',
              15: '5.04',
              16: '6.71',
              17: '8.37',
              18: '10.04',
              19: '11.71',
              20: '13.37',
              21: '15.04',
              22: '16.7',
              23: '18.37',
              24: '20.04',
              25: '21.7',
              26: '23.37',
              27: '25.03',
              28: '26.7',
              29: '28.37',
              30: '30.03',
              31: '31.7',
              32: '33.37',
              33: '35.03',
              34: '36.7',
              35: '38.36',
              36: '40.03',
              37: '41.7',
              38: '43.36',
              39: '45.03',
              40: '46.69',
              41: '48.36',
              42: '50.03',
              43: '51.69',
              44: '53.36',
              45: '55.02',
              46: '56.69',
              47: '58.36',
              48: '60.02',
              49: '61.69',
              50: '63.35',
            },
            lower_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.0',
              13: '0.0',
              14: '0.0',
              15: '0.35',
              16: '1.19',
              17: '2.09',
              18: '3.04',
              19: '4.01',
              20: '5.01',
              21: '6.02',
              22: '7.05',
              23: '8.09',
              24: '9.14',
              25: '10.2',
              26: '11.26',
              27: '12.33',
              28: '13.41',
              29: '14.49',
              30: '15.57',
              31: '16.66',
              32: '17.75',
              33: '18.84',
              34: '19.93',
              35: '21.03',
              36: '22.13',
              37: '23.23',
              38: '24.33',
              39: '25.44',
              40: '26.54',
              41: '27.65',
              42: '28.76',
              43: '29.86',
              44: '30.97',
              45: '32.08',
              46: '33.2',
              47: '34.31',
              48: '35.42',
              49: '36.53',
              50: '37.65',
            },
            upper_confidence: {
              0: '0.0',
              1: '0.0',
              2: '0.0',
              3: '0.0',
              4: '0.0',
              5: '0.0',
              6: '0.0',
              7: '0.0',
              8: '0.0',
              9: '0.0',
              10: '0.0',
              11: '0.0',
              12: '0.45',
              13: '4.33',
              14: '7.14',
              15: '9.73',
              16: '12.23',
              17: '14.66',
              18: '17.04',
              19: '19.4',
              20: '21.74',
              21: '24.05',
              22: '26.36',
              23: '28.65',
              24: '30.93',
              25: '33.21',
              26: '35.47',
              27: '37.74',
              28: '39.99',
              29: '42.25',
              30: '44.5',
              31: '46.74',
              32: '48.98',
              33: '51.22',
              34: '53.46',
              35: '55.7',
              36: '57.93',
              37: '60.16',
              38: '62.39',
              39: '64.62',
              40: '66.85',
              41: '69.07',
              42: '71.3',
              43: '73.52',
              44: '75.74',
              45: '77.96',
              46: '80.18',
              47: '82.4',
              48: '84.62',
              49: '86.84',
              50: '89.06',
              51: '91.28',
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const siteActivationDefaultDates = {
      result: [{
        site_activation_date: '2020-04-01 00:00:00',
        trial_enrollment_completion_date: '2021-07-01 00:00:00',
        trial_start_date: '2019-01-01 00:00:00',
      }],
      status: 'SUCCEEDED',
    };

    const enrollmentPredictionData = {
      result: {
        country: {
          france: {
            actual_patients_enrolled: {
              '0.0': '57.0',
              '1.0': '57.0',
              '2.0': '57.0',
              '3.0': '57.0',
              '4.0': '57.0',
              '5.0': '57.0',
              '6.0': '57.0',
              '7.0': '13.29494132057833',
              '8.0': '45.88286841258108',
              '9.0': '78.47079550458379',
              '10.0': '111.11634807447628',
              '11.0': '145.45608969432737',
              '12.0': '179.84193169649018',
              '13.0': '215.9334878441896',
              '14.0': '252.05961927862282',
              '15.0': '289.9606154320602',
              '16.0': '331.1001634429012',
              '17.0': '488.0669220121344',
              '18.0': '645.0336805813672',
              '19.0': '802.0004391506004',
              '20.0': '958.9671977198334',
              '21.0': '1115.9339562890664',
              '22.0': '1272.9007148582996',
              '23.0': '1429.8674734275323',
              '24.0': '1586.8342319967655',
              '25.0': '1743.8009905659987',
              '26.0': '1900.767749135232',
              '27.0': '2057.734507704465',
              '28.0': '2214.701266273698',
              '29.0': '2371.668024842931',
              '30.0': '2528.634783412165',
            },
            enrollments: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '13.29',
              '8.0': '45.88',
              '9.0': '78.47',
              '10.0': '111.12',
              '11.0': '145.46',
              '12.0': '179.84',
              '13.0': '215.93',
              '14.0': '252.06',
              '15.0': '289.96',
              '16.0': '331.1',
              '17.0': '488.07',
              '18.0': '645.03',
              '19.0': '802.0',
              '20.0': '958.97',
              '21.0': '1115.93',
              '22.0': '1272.9',
              '23.0': '1429.87',
              '24.0': '1586.83',
              '25.0': '1743.8',
              '26.0': '1900.77',
              '27.0': '2057.73',
              '28.0': '2214.7',
              '29.0': '2371.67',
              '30.0': '2528.63',
            },
            lower_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '5.37',
              '8.0': '29.47',
              '9.0': '54.94',
              '10.0': '80.84',
              '11.0': '108.47',
              '12.0': '136.21',
              '13.0': '165.59',
              '14.0': '195.0',
              '15.0': '226.1',
              '16.0': '260.28',
              '17.0': '405.84',
              '18.0': '550.0',
              '19.0': '693.24',
              '20.0': '835.87',
              '21.0': '978.09',
              '22.0': '1120.01',
              '23.0': '1261.71',
              '24.0': '1403.25',
              '25.0': '1544.66',
              '26.0': '1685.97',
              '27.0': '1827.21',
              '28.0': '1968.39',
              '29.0': '2109.51',
              '30.0': '2250.59',
            },
            upper_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '21.22',
              '8.0': '62.29',
              '9.0': '102.0',
              '10.0': '141.39',
              '11.0': '182.44',
              '12.0': '223.47',
              '13.0': '266.27',
              '14.0': '309.12',
              '15.0': '353.82',
              '16.0': '401.92',
              '17.0': '570.29',
              '18.0': '740.07',
              '19.0': '910.76',
              '20.0': '1082.06',
              '21.0': '1253.78',
              '22.0': '1425.8',
              '23.0': '1598.03',
              '24.0': '1770.42',
              '25.0': '1942.94',
              '26.0': '2115.56',
              '27.0': '2288.26',
              '28.0': '2461.02',
              '29.0': '2633.83',
              '30.0': '2806.68',
            },
          },
          usa: {
            actual_patients_enrolled: {
              '0.0': '53.0',
              '1.0': '53.0',
              '2.0': '53.0',
              '3.0': '53.0',
              '4.0': '53.0',
              '5.0': '53.0',
              '6.0': '53.0',
              '7.0': '10.244572029300427',
              '8.0': '34.55815875843413',
              '9.0': '58.87174548756784',
              '10.0': '83.18533221670154',
              '11.0': '107.49891894583523',
              '12.0': '131.81250567496895',
              '13.0': '156.12609240410262',
              '14.0': '180.43967913323638',
              '15.0': '204.7532658623701',
              '16.0': '231.0420480174916',
              '17.0': '405.47048712172216',
              '18.0': '579.8989262259528',
              '19.0': '754.3273653301833',
              '20.0': '928.7558044344133',
              '21.0': '1103.1842435386436',
              '22.0': '1277.6126826428742',
              '23.0': '1452.0411217471044',
              '24.0': '1626.4695608513352',
              '25.0': '1800.897999955566',
              '26.0': '1975.3264390597965',
              '27.0': '2149.754878164027',
              '28.0': '2324.1833172682573',
              '29.0': '2498.611756372488',
              '30.0': '2673.0401954767176',
            },
            enrollments: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '10.24',
              '8.0': '34.56',
              '9.0': '58.87',
              '10.0': '83.19',
              '11.0': '107.5',
              '12.0': '131.81',
              '13.0': '156.13',
              '14.0': '180.44',
              '15.0': '204.75',
              '16.0': '231.04',
              '17.0': '405.47',
              '18.0': '579.9',
              '19.0': '754.33',
              '20.0': '928.76',
              '21.0': '1103.18',
              '22.0': '1277.61',
              '23.0': '1452.04',
              '24.0': '1626.47',
              '25.0': '1800.9',
              '26.0': '1975.33',
              '27.0': '2149.75',
              '28.0': '2324.18',
              '29.0': '2498.61',
              '30.0': '2673.04',
            },
            lower_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '3.43',
              '8.0': '21.14',
              '9.0': '40.22',
              '10.0': '59.73',
              '11.0': '79.45',
              '12.0': '99.3',
              '13.0': '119.22',
              '14.0': '139.2',
              '15.0': '159.21',
              '16.0': '181.14',
              '17.0': '344.57',
              '18.0': '507.48',
              '19.0': '670.09',
              '20.0': '832.51',
              '21.0': '994.81',
              '22.0': '1157.01',
              '23.0': '1319.15',
              '24.0': '1481.25',
              '25.0': '1643.3',
              '26.0': '1805.33',
              '27.0': '1967.33',
              '28.0': '2129.32',
              '29.0': '2291.29',
              '30.0': '2453.25',
            },
            upper_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '17.05',
              '8.0': '47.98',
              '9.0': '77.53',
              '10.0': '106.64',
              '11.0': '135.54',
              '12.0': '164.32',
              '13.0': '193.03',
              '14.0': '221.68',
              '15.0': '250.29',
              '16.0': '280.94',
              '17.0': '466.37',
              '18.0': '652.32',
              '19.0': '838.56',
              '20.0': '1025.0',
              '21.0': '1211.56',
              '22.0': '1398.21',
              '23.0': '1584.93',
              '24.0': '1771.69',
              '25.0': '1958.49',
              '26.0': '2145.32',
              '27.0': '2332.18',
              '28.0': '2519.05',
              '29.0': '2705.93',
              '30.0': '2892.83',
            },
          },
        },
        global: {
          global: {
            actual_patients_enrolled: {
              '0.0': '110.0',
              '1.0': '110.0',
              '2.0': '110.0',
              '3.0': '110.0',
              '4.0': '110.0',
              '5.0': '110.0',
              '6.0': '110.0',
              '7.0': '23.881536004118647',
              '8.0': '81.58742871416217',
              '9.0': '139.29332142420566',
              '10.0': '197.05854495819776',
              '11.0': '256.56809471627696',
              '12.0': '316.12510913351514',
              '13.0': '377.43831593963006',
              '14.0': '438.78712124011406',
              '15.0': '501.96331591821337',
              '16.0': '570.3962215981528',
              '17.0': '902.2579856096248',
              '18.0': '1234.1197496210973',
              '19.0': '1565.9815136325697',
              '20.0': '1897.843277644041',
              '21.0': '2229.7050416555135',
              '22.0': '2561.5668056669856',
              '23.0': '2893.428569678457',
              '24.0': '3225.29033368993',
              '25.0': '3557.1520977014025',
              '26.0': '3889.013861712874',
              '27.0': '4220.875625724346',
              '28.0': '4552.737389735819',
              '29.0': '4884.599153747291',
              '30.0': '5216.460917758763',
            },
            enrollments: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '23.88',
              '8.0': '81.59',
              '9.0': '139.29',
              '10.0': '197.06',
              '11.0': '256.57',
              '12.0': '316.13',
              '13.0': '377.44',
              '14.0': '438.79',
              '15.0': '501.96',
              '16.0': '570.4',
              '17.0': '902.26',
              '18.0': '1234.12',
              '19.0': '1565.98',
              '20.0': '1897.84',
              '21.0': '2229.71',
              '22.0': '2561.57',
              '23.0': '2893.43',
              '24.0': '3225.29',
              '25.0': '3557.15',
              '26.0': '3889.01',
              '27.0': '4220.88',
              '28.0': '4552.74',
              '29.0': '4884.6',
              '30.0': '5216.46',
            },
            lower_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '13.37',
              '8.0': '60.31',
              '9.0': '109.22',
              '10.0': '158.76',
              '11.0': '210.21',
              '12.0': '261.85',
              '13.0': '315.25',
              '14.0': '368.72',
              '15.0': '423.98',
              '16.0': '484.36',
              '17.0': '800.6',
              '18.0': '1115.3',
              '19.0': '1429.03',
              '20.0': '1742.13',
              '21.0': '2054.81',
              '22.0': '2367.19',
              '23.0': '2679.36',
              '24.0': '2991.36',
              '25.0': '3303.23',
              '26.0': '3615.01',
              '27.0': '3926.71',
              '28.0': '4238.35',
              '29.0': '4549.93',
              '30.0': '4861.48',
            },
            upper_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '34.4',
              '8.0': '102.86',
              '9.0': '169.37',
              '10.0': '235.36',
              '11.0': '302.92',
              '12.0': '370.4',
              '13.0': '439.63',
              '14.0': '508.85',
              '15.0': '579.94',
              '16.0': '656.43',
              '17.0': '1003.92',
              '18.0': '1352.94',
              '19.0': '1702.93',
              '20.0': '2053.55',
              '21.0': '2404.6',
              '22.0': '2755.94',
              '23.0': '3107.5',
              '24.0': '3459.22',
              '25.0': '3811.07',
              '26.0': '4163.02',
              '27.0': '4515.04',
              '28.0': '4867.13',
              '29.0': '5219.26',
              '30.0': '5571.44',
            },
          },
        },
        region: {
          'australia and new zealand': {
            actual_patients_enrolled: {
              '0.0': '57.0',
              '1.0': '57.0',
              '2.0': '57.0',
              '3.0': '57.0',
              '4.0': '57.0',
              '5.0': '57.0',
              '6.0': '57.0',
              '7.0': '13.29494132057833',
              '8.0': '45.88286841258108',
              '9.0': '78.47079550458379',
              '10.0': '111.11634807447628',
              '11.0': '145.45608969432737',
              '12.0': '179.84193169649018',
              '13.0': '215.9334878441896',
              '14.0': '252.05961927862282',
              '15.0': '289.9606154320602',
              '16.0': '331.1001634429012',
              '17.0': '488.0669220121344',
              '18.0': '645.0336805813672',
              '19.0': '802.0004391506004',
              '20.0': '958.9671977198334',
              '21.0': '1115.9339562890664',
              '22.0': '1272.9007148582996',
              '23.0': '1429.8674734275323',
              '24.0': '1586.8342319967655',
              '25.0': '1743.8009905659987',
              '26.0': '1900.767749135232',
              '27.0': '2057.734507704465',
              '28.0': '2214.701266273698',
              '29.0': '2371.668024842931',
              '30.0': '2528.634783412165',
            },
            enrollments: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '13.29',
              '8.0': '45.88',
              '9.0': '78.47',
              '10.0': '111.12',
              '11.0': '145.46',
              '12.0': '179.84',
              '13.0': '215.93',
              '14.0': '252.06',
              '15.0': '289.96',
              '16.0': '331.1',
              '17.0': '488.07',
              '18.0': '645.03',
              '19.0': '802.0',
              '20.0': '958.97',
              '21.0': '1115.93',
              '22.0': '1272.9',
              '23.0': '1429.87',
              '24.0': '1586.83',
              '25.0': '1743.8',
              '26.0': '1900.77',
              '27.0': '2057.73',
              '28.0': '2214.7',
              '29.0': '2371.67',
              '30.0': '2528.63',
            },
            lower_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '5.37',
              '8.0': '29.47',
              '9.0': '54.94',
              '10.0': '80.84',
              '11.0': '108.47',
              '12.0': '136.21',
              '13.0': '165.59',
              '14.0': '195.0',
              '15.0': '226.1',
              '16.0': '260.28',
              '17.0': '405.84',
              '18.0': '550.0',
              '19.0': '693.24',
              '20.0': '835.87',
              '21.0': '978.09',
              '22.0': '1120.01',
              '23.0': '1261.71',
              '24.0': '1403.25',
              '25.0': '1544.66',
              '26.0': '1685.97',
              '27.0': '1827.21',
              '28.0': '1968.39',
              '29.0': '2109.51',
              '30.0': '2250.59',
            },
            upper_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '21.22',
              '8.0': '62.29',
              '9.0': '102.0',
              '10.0': '141.39',
              '11.0': '182.44',
              '12.0': '223.47',
              '13.0': '266.27',
              '14.0': '309.12',
              '15.0': '353.82',
              '16.0': '401.92',
              '17.0': '570.29',
              '18.0': '740.07',
              '19.0': '910.76',
              '20.0': '1082.06',
              '21.0': '1253.78',
              '22.0': '1425.8',
              '23.0': '1598.03',
              '24.0': '1770.42',
              '25.0': '1942.94',
              '26.0': '2115.56',
              '27.0': '2288.26',
              '28.0': '2461.02',
              '29.0': '2633.83',
              '30.0': '2806.68',
            },
          },
          'north america': {
            actual_patients_enrolled: {
              '0.0': '53.0',
              '1.0': '53.0',
              '2.0': '53.0',
              '3.0': '53.0',
              '4.0': '53.0',
              '5.0': '53.0',
              '6.0': '53.0',
              '7.0': '10.244572029300427',
              '8.0': '34.55815875843413',
              '9.0': '58.87174548756784',
              '10.0': '83.18533221670154',
              '11.0': '107.49891894583523',
              '12.0': '131.81250567496895',
              '13.0': '156.12609240410262',
              '14.0': '180.43967913323638',
              '15.0': '204.7532658623701',
              '16.0': '231.0420480174916',
              '17.0': '405.47048712172216',
              '18.0': '579.8989262259528',
              '19.0': '754.3273653301833',
              '20.0': '928.7558044344133',
              '21.0': '1103.1842435386436',
              '22.0': '1277.6126826428742',
              '23.0': '1452.0411217471044',
              '24.0': '1626.4695608513352',
              '25.0': '1800.897999955566',
              '26.0': '1975.3264390597965',
              '27.0': '2149.754878164027',
              '28.0': '2324.1833172682573',
              '29.0': '2498.611756372488',
              '30.0': '2673.0401954767176',
            },
            enrollments: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '10.24',
              '8.0': '34.56',
              '9.0': '58.87',
              '10.0': '83.19',
              '11.0': '107.5',
              '12.0': '131.81',
              '13.0': '156.13',
              '14.0': '180.44',
              '15.0': '204.75',
              '16.0': '231.04',
              '17.0': '405.47',
              '18.0': '579.9',
              '19.0': '754.33',
              '20.0': '928.76',
              '21.0': '1103.18',
              '22.0': '1277.61',
              '23.0': '1452.04',
              '24.0': '1626.47',
              '25.0': '1800.9',
              '26.0': '1975.33',
              '27.0': '2149.75',
              '28.0': '2324.18',
              '29.0': '2498.61',
              '30.0': '2673.04',
            },
            lower_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '3.43',
              '8.0': '21.14',
              '9.0': '40.22',
              '10.0': '59.73',
              '11.0': '79.45',
              '12.0': '99.3',
              '13.0': '119.22',
              '14.0': '139.2',
              '15.0': '159.21',
              '16.0': '181.14',
              '17.0': '344.57',
              '18.0': '507.48',
              '19.0': '670.09',
              '20.0': '832.51',
              '21.0': '994.81',
              '22.0': '1157.01',
              '23.0': '1319.15',
              '24.0': '1481.25',
              '25.0': '1643.3',
              '26.0': '1805.33',
              '27.0': '1967.33',
              '28.0': '2129.32',
              '29.0': '2291.29',
              '30.0': '2453.25',
            },
            upper_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '17.05',
              '8.0': '47.98',
              '9.0': '77.53',
              '10.0': '106.64',
              '11.0': '135.54',
              '12.0': '164.32',
              '13.0': '193.03',
              '14.0': '221.68',
              '15.0': '250.29',
              '16.0': '280.94',
              '17.0': '466.37',
              '18.0': '652.32',
              '19.0': '838.56',
              '20.0': '1025.0',
              '21.0': '1211.56',
              '22.0': '1398.21',
              '23.0': '1584.93',
              '24.0': '1771.69',
              '25.0': '1958.49',
              '26.0': '2145.32',
              '27.0': '2332.18',
              '28.0': '2519.05',
              '29.0': '2705.93',
              '30.0': '2892.83',
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const enrollmentProbabilityData = {
      result: {
        country: {
          france: {
            Probability: {
              '0.0': '0.0',
              0.01: '7.89',
              0.02: '7.92',
              0.03: '7.98',
              0.04: '8.0',
              0.05: '8.03',
              0.06: '8.04',
              0.07: '8.06',
              0.08: '8.07',
              0.09: '8.09',
              0.1: '8.1',
              0.12: '8.12',
              0.13: '8.14',
              0.14: '8.15',
              0.15: '8.16',
              0.16: '8.17',
              0.17: '8.18',
              0.18: '8.19',
              0.2: '8.2',
              0.21: '8.22',
              0.23: '8.23',
              0.24: '8.24',
              0.26: '8.25',
              0.28: '8.27',
              0.3: '8.28',
              0.32: '8.3',
              0.34: '8.31',
              0.36: '8.33',
              0.38: '8.34',
              0.39: '8.35',
              0.4: '8.36',
              0.42: '8.37',
              0.43: '8.38',
              0.44: '8.39',
              0.46: '8.4',
              0.47: '8.41',
              0.48: '8.42',
              0.5: '8.43',
              0.51: '8.44',
              0.52: '8.45',
              0.54: '8.46',
              0.55: '8.47',
              0.56: '8.48',
              0.57: '8.49',
              0.59: '8.5',
              0.6: '8.51',
              0.61: '8.52',
              0.63: '8.53',
              0.65: '8.55',
              0.66: '8.56',
              0.67: '8.57',
              0.68: '8.58',
              0.69: '8.59',
              0.71: '8.6',
              0.73: '8.62',
              0.75: '8.64',
              0.76: '8.66',
              0.77: '8.67',
              0.78: '8.68',
              0.79: '8.69',
              0.8: '8.71',
              0.81: '8.72',
              0.83: '8.74',
              0.84: '8.76',
              0.85: '8.77',
              0.86: '8.8',
              0.87: '8.81',
              0.88: '8.83',
              0.89: '8.84',
              0.9: '8.88',
              0.91: '8.89',
              0.92: '8.93',
              0.93: '8.94',
              0.94: '8.99',
              0.95: '9.01',
              0.96: '9.06',
              0.97: '9.12',
              0.98: '9.25',
              0.99: '9.33',
              '1.0': '30.0',
            },
            target_no_of_patients: '10',
          },
          usa: {
            Probability: {
              '0.0': '0.0',
              0.01: '8.34',
              0.02: '8.38',
              0.03: '8.45',
              0.04: '8.49',
              0.05: '8.52',
              0.06: '8.54',
              0.07: '8.56',
              0.08: '8.57',
              0.09: '8.6',
              0.1: '8.62',
              0.12: '8.64',
              0.14: '8.67',
              0.15: '8.69',
              0.16: '8.71',
              0.18: '8.72',
              0.19: '8.74',
              0.2: '8.75',
              0.21: '8.77',
              0.22: '8.78',
              0.24: '8.79',
              0.26: '8.81',
              0.28: '8.83',
              0.29: '8.84',
              0.3: '8.85',
              0.32: '8.87',
              0.34: '8.89',
              0.36: '8.91',
              0.38: '8.93',
              0.39: '8.94',
              0.4: '8.95',
              0.41: '8.96',
              0.42: '8.97',
              0.43: '8.98',
              0.44: '8.99',
              0.46: '9.0',
              0.48: '9.02',
              0.5: '9.04',
              0.51: '9.06',
              0.52: '9.07',
              0.54: '9.08',
              0.55: '9.09',
              0.56: '9.11',
              0.57: '9.12',
              0.59: '9.13',
              0.61: '9.15',
              0.62: '9.17',
              0.63: '9.18',
              0.64: '9.19',
              0.65: '9.2',
              0.66: '9.21',
              0.67: '9.22',
              0.69: '9.24',
              0.7: '9.26',
              0.71: '9.27',
              0.73: '9.29',
              0.74: '9.31',
              0.75: '9.32',
              0.76: '9.34',
              0.77: '9.35',
              0.78: '9.37',
              0.79: '9.38',
              0.8: '9.4',
              0.81: '9.41',
              0.82: '9.43',
              0.83: '9.44',
              0.84: '9.47',
              0.85: '9.48',
              0.86: '9.51',
              0.87: '9.52',
              0.88: '9.56',
              0.89: '9.57',
              0.9: '9.61',
              0.91: '9.63',
              0.92: '9.68',
              0.93: '9.7',
              0.94: '9.76',
              0.95: '9.79',
              0.96: '9.84',
              0.97: '9.91',
              0.98: '10.08',
              0.99: '10.18',
              '1.0': '120.0',
            },
            target_no_of_patients: '10',
          },
        },
        global: {
          global: {
            Probability: {
              '0.0': '0.0',
              0.01: '8.21',
              0.02: '8.24',
              0.03: '8.29',
              0.04: '8.31',
              0.05: '8.33',
              0.06: '8.35',
              0.07: '8.36',
              0.08: '8.37',
              0.09: '8.39',
              0.1: '8.4',
              0.12: '8.41',
              0.14: '8.43',
              0.15: '8.44',
              0.16: '8.45',
              0.17: '8.46',
              0.18: '8.47',
              0.2: '8.48',
              0.21: '8.49',
              0.22: '8.5',
              0.24: '8.51',
              0.26: '8.52',
              0.28: '8.53',
              0.29: '8.54',
              0.3: '8.55',
              0.32: '8.56',
              0.34: '8.57',
              0.36: '8.58',
              0.38: '8.59',
              0.39: '8.6',
              0.41: '8.61',
              0.42: '8.62',
              0.44: '8.63',
              0.46: '8.64',
              0.48: '8.65',
              0.5: '8.66',
              0.51: '8.67',
              0.53: '8.68',
              0.55: '8.69',
              0.56: '8.7',
              0.57: '8.71',
              0.59: '8.72',
              0.61: '8.73',
              0.63: '8.74',
              0.64: '8.75',
              0.65: '8.76',
              0.67: '8.77',
              0.69: '8.78',
              0.7: '8.79',
              0.71: '8.8',
              0.73: '8.81',
              0.74: '8.82',
              0.75: '8.83',
              0.77: '8.84',
              0.79: '8.86',
              0.81: '8.88',
              0.83: '8.9',
              0.85: '8.92',
              0.86: '8.94',
              0.87: '8.95',
              0.89: '8.97',
              0.9: '9.0',
              0.91: '9.01',
              0.92: '9.03',
              0.93: '9.04',
              0.94: '9.08',
              0.95: '9.09',
              0.96: '9.12',
              0.97: '9.16',
              0.98: '9.25',
              0.99: '9.3',
              '1.0': '30.0',
            },
            target_no_of_patients: '20',
          },
        },
        region: {
          'australia and new zealand': {
            Probability: {
              '0.0': '0.0',
              0.01: '7.89',
              0.02: '7.92',
              0.03: '7.98',
              0.04: '8.0',
              0.05: '8.03',
              0.06: '8.04',
              0.07: '8.06',
              0.08: '8.07',
              0.09: '8.09',
              0.1: '8.1',
              0.12: '8.12',
              0.13: '8.14',
              0.14: '8.15',
              0.15: '8.16',
              0.16: '8.17',
              0.17: '8.18',
              0.18: '8.19',
              0.2: '8.2',
              0.21: '8.22',
              0.23: '8.23',
              0.24: '8.24',
              0.26: '8.25',
              0.28: '8.27',
              0.3: '8.28',
              0.32: '8.3',
              0.34: '8.31',
              0.36: '8.33',
              0.38: '8.34',
              0.39: '8.35',
              0.4: '8.36',
              0.42: '8.37',
              0.43: '8.38',
              0.44: '8.39',
              0.46: '8.4',
              0.47: '8.41',
              0.48: '8.42',
              0.5: '8.43',
              0.51: '8.44',
              0.52: '8.45',
              0.54: '8.46',
              0.55: '8.47',
              0.56: '8.48',
              0.57: '8.49',
              0.59: '8.5',
              0.6: '8.51',
              0.61: '8.52',
              0.63: '8.53',
              0.65: '8.55',
              0.66: '8.56',
              0.67: '8.57',
              0.68: '8.58',
              0.69: '8.59',
              0.71: '8.6',
              0.73: '8.62',
              0.75: '8.64',
              0.76: '8.66',
              0.77: '8.67',
              0.78: '8.68',
              0.79: '8.69',
              0.8: '8.71',
              0.81: '8.72',
              0.83: '8.74',
              0.84: '8.76',
              0.85: '8.77',
              0.86: '8.8',
              0.87: '8.81',
              0.88: '8.83',
              0.89: '8.84',
              0.9: '8.88',
              0.91: '8.89',
              0.92: '8.93',
              0.93: '8.94',
              0.94: '8.99',
              0.95: '9.01',
              0.96: '9.06',
              0.97: '9.12',
              0.98: '9.25',
              0.99: '9.33',
              '1.0': '30.0',
            },
            target_no_of_patients: '10',
          },
          'north america': {
            Probability: {
              '0.0': '0.0',
              0.01: '8.34',
              0.02: '8.38',
              0.03: '8.45',
              0.04: '8.49',
              0.05: '8.52',
              0.06: '8.54',
              0.07: '8.56',
              0.08: '8.57',
              0.09: '8.6',
              0.1: '8.62',
              0.12: '8.64',
              0.14: '8.67',
              0.15: '8.69',
              0.16: '8.71',
              0.18: '8.72',
              0.19: '8.74',
              0.2: '8.75',
              0.21: '8.77',
              0.22: '8.78',
              0.24: '8.79',
              0.26: '8.81',
              0.28: '8.83',
              0.29: '8.84',
              0.3: '8.85',
              0.32: '8.87',
              0.34: '8.89',
              0.36: '8.91',
              0.38: '8.93',
              0.39: '8.94',
              0.4: '8.95',
              0.41: '8.96',
              0.42: '8.97',
              0.43: '8.98',
              0.44: '8.99',
              0.46: '9.0',
              0.48: '9.02',
              0.5: '9.04',
              0.51: '9.06',
              0.52: '9.07',
              0.54: '9.08',
              0.55: '9.09',
              0.56: '9.11',
              0.57: '9.12',
              0.59: '9.13',
              0.61: '9.15',
              0.62: '9.17',
              0.63: '9.18',
              0.64: '9.19',
              0.65: '9.2',
              0.66: '9.21',
              0.67: '9.22',
              0.69: '9.24',
              0.7: '9.26',
              0.71: '9.27',
              0.73: '9.29',
              0.74: '9.31',
              0.75: '9.32',
              0.76: '9.34',
              0.77: '9.35',
              0.78: '9.37',
              0.79: '9.38',
              0.8: '9.4',
              0.81: '9.41',
              0.82: '9.43',
              0.83: '9.44',
              0.84: '9.47',
              0.85: '9.48',
              0.86: '9.51',
              0.87: '9.52',
              0.88: '9.56',
              0.89: '9.57',
              0.9: '9.61',
              0.91: '9.63',
              0.92: '9.68',
              0.93: '9.7',
              0.94: '9.76',
              0.95: '9.79',
              0.96: '9.84',
              0.97: '9.91',
              0.98: '10.08',
              0.99: '10.18',
              '1.0': '30.0',
            },
            target_no_of_patients: '10',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const enrollmentCompletionTimeTableData = {
      result: {
        country: {
          france: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '16',
            time_to_complete_enrollment: '23.84',
          },
          usa: {
            completion_probability_10_perc: '25.67',
            completion_probability_20_perc: '26.7',
            completion_probability_30_perc: '27.61',
            completion_probability_40_perc: '28.5',
            completion_probability_50_perc: '29.44',
            completion_probability_5_perc: '24.92',
            completion_probability_60_perc: '30.63',
            completion_probability_70_perc: '31.99',
            completion_probability_80_perc: '33.91',
            completion_probability_90_perc: '37.47',
            completion_probability_95_perc: '41.09',
            no_of_sites: '5',
            target_no_of_patients: '38',
            time_to_complete_enrollment: '29.49',
          },
          india: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '16',
            time_to_complete_enrollment: '23.84',
          },
          australia: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '17',
            time_to_complete_enrollment: '23.84',
          },
          singapore: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '18',
            time_to_complete_enrollment: '23.84',
          },
          japan: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '54',
            time_to_complete_enrollment: '23.84',
          },
          thailand: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '9',
            time_to_complete_enrollment: '23.84',
          },
          russia: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '47',
            time_to_complete_enrollment: '23.84',
          },
          ukrain: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '10',
            time_to_complete_enrollment: '23.84',
          },
          malaysia: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '13',
            time_to_complete_enrollment: '23.84',
          },
          china: {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '28',
            time_to_complete_enrollment: '23.84',
          },
        },
        global: {
          global: {
            completion_probability_10_perc: '24.58',
            completion_probability_20_perc: '25.29',
            completion_probability_30_perc: '25.89',
            completion_probability_40_perc: '26.45',
            completion_probability_50_perc: '27.03',
            completion_probability_5_perc: '24.05',
            completion_probability_60_perc: '27.73',
            completion_probability_70_perc: '28.49',
            completion_probability_80_perc: '29.51',
            completion_probability_90_perc: '31.21',
            completion_probability_95_perc: '32.73',
            no_of_sites: '10',
            target_no_of_patients: '54',
            time_to_complete_enrollment: '27.06',
          },
        },
        region: {
          'australia and new zealand': {
            completion_probability_10_perc: '25.67',
            completion_probability_20_perc: '26.7',
            completion_probability_30_perc: '27.61',
            completion_probability_40_perc: '28.5',
            completion_probability_50_perc: '29.44',
            completion_probability_5_perc: '24.92',
            completion_probability_60_perc: '30.63',
            completion_probability_70_perc: '31.99',
            completion_probability_80_perc: '33.91',
            completion_probability_90_perc: '37.47',
            completion_probability_95_perc: '41.09',
            no_of_sites: '5',
            target_no_of_patients: '38',
            time_to_complete_enrollment: '29.49',
          },
          'north america': {
            completion_probability_10_perc: '21.26',
            completion_probability_20_perc: '22.03',
            completion_probability_30_perc: '22.63',
            completion_probability_40_perc: '23.2',
            completion_probability_50_perc: '23.81',
            completion_probability_5_perc: '20.69',
            completion_probability_60_perc: '24.58',
            completion_probability_70_perc: '25.45',
            completion_probability_80_perc: '26.67',
            completion_probability_90_perc: '28.91',
            completion_probability_95_perc: '31.15',
            no_of_sites: '5',
            target_no_of_patients: '16',
            time_to_complete_enrollment: '23.84',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const preDistributionTargetTimeChart = {
      result: {
        global: {
          // global: {},
        },
        country: {
          ABC: {
            0: {
              '[11.0,12.0)': 1.0,
            },
            1: {
              '[12.0,13.0)': 0.0,
            },
            2: {
              '[13.0,14.0)': 1.0,
            },
            3: {
              '[14.0,14.0)': 2.0,
            },
            4: {
              '[14.0,15.0)': 10.0,
            },
            5: {
              '[15.0,16.0)': 6.0,
            },
            6: {
              '[16.0,17.0)': 7.0,
            },
            7: {
              '[17.0,18.0)': 2.0,
            },
            8: {
              '[18.0,18.0)': 6.0,
            },
            9: {
              '[18.0,19.0)': 4.0,
            },
          },
          Americas: {
            0: {
              '[11.0,12.0)': 1.0,
            },
            1: {
              '[12.0,13.0)': 0.0,
            },
            2: {
              '[13.0,14.0)': 1.0,
            },
            3: {
              '[14.0,14.0)': 2.0,
            },
            4: {
              '[14.0,15.0)': 0.0,
            },
            5: {
              '[15.0,16.0)': 0.0,
            },
            6: {
              '[16.0,17.0)': 7.0,
            },
            7: {
              '[17.0,18.0)': 2.0,
            },
            8: {
              '[18.0,18.0)': 6.0,
            },
            9: {
              '[18.0,19.0)': 4.0,
            },
          },
        },
        region: {
          'australia and new zealand': {
            0: {
              '[11.0,12.0)': 1.0,
            },
            1: {
              '[12.0,13.0)': 0.0,
            },
            2: {
              '[13.0,14.0)': 1.0,
            },
            3: {
              '[14.0,14.0)': 2.0,
            },
            4: {
              '[14.0,15.0)': 10.0,
            },
            5: {
              '[15.0,16.0)': 6.0,
            },
            6: {
              '[16.0,17.0)': 7.0,
            },
            7: {
              '[17.0,18.0)': 2.0,
            },
            8: {
              '[18.0,18.0)': 6.0,
            },
            9: {
              '[18.0,19.0)': 4.0,
            },
          },
          'north america': {
            0: {
              '[11.0,12.0)': 1.0,
            },
            1: {
              '[12.0,13.0)': 0.0,
            },
            2: {
              '[13.0,14.0)': 1.0,
            },
            3: {
              '[14.0,14.0)': 2.0,
            },
            4: {
              '[14.0,15.0)': 0.0,
            },
            5: {
              '[15.0,16.0)': 0.0,
            },
            6: {
              '[16.0,17.0)': 7.0,
            },
            7: {
              '[17.0,18.0)': 2.0,
            },
            8: {
              '[18.0,18.0)': 6.0,
            },
            9: {
              '[18.0,19.0)': 4.0,
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const prePredictedPatientTimeChart = {
      result: {
        global: {
          global: {
            enrollments: {
              0.0: '0.0',
              1.0: '13.606784192125831',
              10.0: '2625.2522443234016',
              11.0: '3272.375048642889',
              12.0: '4046.69426910406',
              13.0: '4896.785292996225',
              14.0: '5824.174969507057',
              15.0: '6903.969074156885',
              16.0: '8057.710745394683',
              17.0: '9305.099309645504',
              18.0: '10708.785815065394',
              19.0: '12129.286433219073',
              2.0: '82.44799624243127',
              20.0: '13697.80138578889',
              21.0: '15323.650985634364',
              22.0: '16906.04385595054',
              23.0: '18527.711422637694',
              24.0: '20191.532374372',
              25.0: '21876.939198635773',
              26.0: '23545.74530815244',
              27.0: '25241.90660844144',
              28.0: '26943.359383106617',
              29.0: '28644.8121577718',
              3.0: '183.61981576248863',
              30.0: '30346.264932436978',
              31.0: '32047.71770710216',
              32.0: '33749.170481767345',
              33.0: '35450.62325643252',
              34.0: '37152.076031097706',
              35.0: '38853.528805762886',
              36.0: '40554.98158042807',
              4.0: '332.1308788936939',
              5.0: '549.8552829057936',
              6.0: '813.9998034816977',
              7.0: '1154.892392007855',
              8.0: '1567.0847464020808',
              9.0: '2051.2427432604745',
            },
            lower_confidence: {
              0.0: '0.0',
              1.0: '5.9760960219171855',
              10.0: '2524.786192369755',
              11.0: '3160.033097755365',
              12.0: '3921.86003525796',
              13.0: '4759.244719878194',
              14.0: '5674.578263883189',
              15.0: '6741.098478691886',
              16.0: '7881.527149296368',
              17.0: '9115.990200723756',
              18.0: '10505.79719202093',
              19.0: '11913.325322570538',
              2.0: '64.64212834193746',
              20.0: '13468.385670606755',
              21.0: '15080.77344573802',
              22.0: '16651.105866496913',
              23.0: '18260.790551364877',
              24.0: '19912.932530013408',
              25.0: '21586.985283346683',
              26.0: '23244.820391246543',
              27.0: '24930.287992000853',
              28.0: '26621.402058900654',
              29.0: '28312.837949274537',
              3.0: '157.01440281175275',
              30.0: '30004.567359128505',
              31.0: '31696.56590502446',
              32.0: '33388.812403461976',
              33.0: '35081.28831211035',
              34.0: '36773.97729056162',
              35.0: '38466.86485076774',
              36.0: '40159.93807574787',
              4.0: '296.40697288220065',
              5.0: '503.8602155289797',
              6.0: '757.9382896006149',
              7.0: '1087.8158133226007',
              8.0: '1489.4896180328321',
              9.0: '1962.375415918796',
            },
            upper_confidence: {
              0.0: '0.0',
              1.0: '21.237472362334472',
              10.0: '2725.718296277048',
              11.0: '3384.7169995304125',
              12.0: '4171.528502950158',
              13.0: '5034.3258661142545',
              14.0: '5973.771675130925',
              15.0: '7066.839669621883',
              16.0: '8233.894341492998',
              17.0: '9494.208418567252',
              18.0: '10911.774438109858',
              19.0: '12345.247543867605',
              2.0: '100.25386414292507',
              20.0: '13927.21710097102',
              21.0: '15566.528525530706',
              22.0: '17160.981845404156',
              23.0: '18794.63229391052',
              24.0: '20470.132218730585',
              25.0: '22166.893113924863',
              26.0: '23846.67022505834',
              27.0: '25553.525224882018',
              28.0: '27265.316707312577',
              29.0: '28976.786366269054',
              3.0: '210.22522871322448',
              30.0: '30687.96250574545',
              31.0: '32398.86950917987',
              32.0: '34109.528560072715',
              33.0: '35819.958200754685',
              34.0: '37530.17477163379',
              35.0: '39240.192760758044',
              36.0: '40950.02508510826',
              4.0: '367.85478490518716',
              5.0: '595.8503502826075',
              6.0: '870.0613173627803',
              7.0: '1221.9689706931094',
              8.0: '1644.6798747713297',
              9.0: '2140.1100706021534',
            },
          },
        },
        region: {
          'australia and new zealand': {
            enrollments: {
              0.0: '0.0',
              1.0: '13.606784192125831',
              10.0: '2625.2522443234016',
              11.0: '3272.375048642889',
              12.0: '4046.69426910406',
              13.0: '4896.785292996225',
              14.0: '5824.174969507057',
              15.0: '6903.969074156885',
              16.0: '8057.710745394683',
              17.0: '9305.099309645504',
              18.0: '10708.785815065394',
              19.0: '12129.286433219073',
              2.0: '82.44799624243127',
              20.0: '13697.80138578889',
              21.0: '15323.650985634364',
              22.0: '16906.04385595054',
              23.0: '18527.711422637694',
              24.0: '20191.532374372',
              25.0: '21876.939198635773',
              26.0: '23545.74530815244',
              27.0: '25241.90660844144',
              28.0: '26943.359383106617',
              29.0: '28644.8121577718',
              3.0: '183.61981576248863',
              30.0: '30346.264932436978',
              31.0: '32047.71770710216',
              32.0: '33749.170481767345',
              33.0: '35450.62325643252',
              34.0: '37152.076031097706',
              35.0: '38853.528805762886',
              36.0: '40554.98158042807',
              4.0: '332.1308788936939',
              5.0: '549.8552829057936',
              6.0: '813.9998034816977',
              7.0: '1154.892392007855',
              8.0: '1567.0847464020808',
              9.0: '2051.2427432604745',
            },
            lower_confidence: {
              0.0: '0.0',
              1.0: '5.9760960219171855',
              10.0: '2524.786192369755',
              11.0: '3160.033097755365',
              12.0: '3921.86003525796',
              13.0: '4759.244719878194',
              14.0: '5674.578263883189',
              15.0: '6741.098478691886',
              16.0: '7881.527149296368',
              17.0: '9115.990200723756',
              18.0: '10505.79719202093',
              19.0: '11913.325322570538',
              2.0: '64.64212834193746',
              20.0: '13468.385670606755',
              21.0: '15080.77344573802',
              22.0: '16651.105866496913',
              23.0: '18260.790551364877',
              24.0: '19912.932530013408',
              25.0: '21586.985283346683',
              26.0: '23244.820391246543',
              27.0: '24930.287992000853',
              28.0: '26621.402058900654',
              29.0: '28312.837949274537',
              3.0: '157.01440281175275',
              30.0: '30004.567359128505',
              31.0: '31696.56590502446',
              32.0: '33388.812403461976',
              33.0: '35081.28831211035',
              34.0: '36773.97729056162',
              35.0: '38466.86485076774',
              36.0: '40159.93807574787',
              4.0: '296.40697288220065',
              5.0: '503.8602155289797',
              6.0: '757.9382896006149',
              7.0: '1087.8158133226007',
              8.0: '1489.4896180328321',
              9.0: '1962.375415918796',
            },
            upper_confidence: {
              0.0: '0.0',
              1.0: '21.237472362334472',
              10.0: '2725.718296277048',
              11.0: '3384.7169995304125',
              12.0: '4171.528502950158',
              13.0: '5034.3258661142545',
              14.0: '5973.771675130925',
              15.0: '7066.839669621883',
              16.0: '8233.894341492998',
              17.0: '9494.208418567252',
              18.0: '10911.774438109858',
              19.0: '12345.247543867605',
              2.0: '100.25386414292507',
              20.0: '13927.21710097102',
              21.0: '15566.528525530706',
              22.0: '17160.981845404156',
              23.0: '18794.63229391052',
              24.0: '20470.132218730585',
              25.0: '22166.893113924863',
              26.0: '23846.67022505834',
              27.0: '25553.525224882018',
              28.0: '27265.316707312577',
              29.0: '28976.786366269054',
              3.0: '210.22522871322448',
              30.0: '30687.96250574545',
              31.0: '32398.86950917987',
              32.0: '34109.528560072715',
              33.0: '35819.958200754685',
              34.0: '37530.17477163379',
              35.0: '39240.192760758044',
              36.0: '40950.02508510826',
              4.0: '367.85478490518716',
              5.0: '595.8503502826075',
              6.0: '870.0613173627803',
              7.0: '1221.9689706931094',
              8.0: '1644.6798747713297',
              9.0: '2140.1100706021534',
            },
          },
          'north america': {
            enrollments: {
              0.0: '0.0',
              1.0: '0.5413449556206733',
              10.0: '2039.6061878132464',
              11.0: '2601.675029350029',
              12.0: '3109.1536053257037',
              13.0: '3709.082195105873',
              14.0: '4312.087267798597',
              15.0: '4915.681945082666',
              16.0: '5582.253287420227',
              17.0: '6261.501515111462',
              18.0: '6969.065958587538',
              19.0: '7775.059117168379',
              2.0: '48.943048863773775',
              20.0: '8652.810285094858',
              21.0: '9532.924488893355',
              22.0: '10451.061443203691',
              23.0: '11411.214314897854',
              24.0: '12443.195874979221',
              25.0: '13478.22720115022',
              26.0: '14513.258527321219',
              27.0: '15548.289853492219',
              28.0: '16583.321179663224',
              29.0: '17618.35250583422',
              3.0: '114.38872502284872',
              30.0: '18653.383832005224',
              31.0: '19688.41515817622',
              32.0: '20723.446484347223',
              33.0: '21758.477810518227',
              34.0: '22793.509136689223',
              35.0: '23828.540462860226',
              36.0: '24863.571789031226',
              4.0: '205.92615452421524',
              5.0: '346.27454843349',
              6.0: '555.6025659286238',
              7.0: '834.3175441133403',
              8.0: '1167.5737627026422',
              9.0: '1561.4819536072634',
            },
            lower_confidence: {
              0.0: '0.0',
              1.0: '0.0',
              10.0: '1950.6314007893163',
              11.0: '2501.664461322668',
              12.0: '2999.5773965281687',
              13.0: '3589.7052975296833',
              14.0: '4182.868762763232',
              15.0: '4778.036512573328',
              16.0: '5435.015545422036',
              17.0: '6105.41587638085',
              18.0: '6804.5744730486595',
              19.0: '7601.502915482773',
              2.0: '35.1944242786551',
              20.0: '8469.89749211503',
              21.0: '9341.063655115804',
              22.0: '10250.166975375152',
              23.0: '11201.625805066682',
              24.0: '12224.44620255983',
              25.0: '13250.645018359211',
              26.0: '14277.10542166869',
              27.0: '15303.794211395603',
              28.0: '16330.758417762076',
              29.0: '17357.972440556485',
              3.0: '93.3552039180305',
              30.0: '18385.414415673924',
              31.0: '19413.06549359709',
              32.0: '20440.90928771864',
              33.0: '21468.9314460554',
              34.0: '22497.119314211224',
              35.0: '23525.461666895484',
              36.0: '24553.94849168696',
              4.0: '177.47292357868994',
              5.0: '309.76625378337',
              6.0: '509.36940221081534',
              7.0: '777.6152927163048',
              8.0: '1100.3732655598512',
              9.0: '1483.9418831481878',
            },
            upper_confidence: {
              0.0: '0.0',
              1.0: '1.877090018305796',
              10.0: '2128.5809748371757',
              11.0: '2701.6855973773913',
              12.0: '3218.729814123239',
              13.0: '3828.4590926820642',
              14.0: '4441.3057728339645',
              15.0: '5053.327377592003',
              16.0: '5729.491029418418',
              17.0: '6417.587153842076',
              18.0: '7133.557444126416',
              19.0: '7948.615318853986',
              2.0: '62.69167344889244',
              20.0: '8835.723078074687',
              21.0: '9724.785322670905',
              22.0: '10651.95591103223',
              23.0: '11620.802824729026',
              24.0: '12661.94554739861',
              25.0: '13705.809383941229',
              26.0: '14749.411632973755',
              27.0: '15792.785495588838',
              28.0: '16835.883941564374',
              29.0: '17878.732571111956',
              3.0: '135.42224612766694',
              30.0: '18921.353248336523',
              31.0: '19963.76482275535',
              32.0: '21005.9836809758',
              33.0: '22048.02417498105',
              34.0: '23089.898959167218',
              35.0: '24131.619258824965',
              36.0: '25173.195086375497',
              4.0: '234.3793854697405',
              5.0: '382.78284308361',
              6.0: '601.8357296464321',
              7.0: '891.0197955103763',
              8.0: '1234.7742598454327',
              9.0: '1639.022024066339',
            },
          },
        },
        country: {
          ABC: {
            enrollments: {
              0.0: '0.0',
              1.0: '13.606784192125831',
              10.0: '2625.2522443234016',
              11.0: '3272.375048642889',
              12.0: '4046.69426910406',
              13.0: '4896.785292996225',
              14.0: '5824.174969507057',
              15.0: '6903.969074156885',
              16.0: '8057.710745394683',
              17.0: '9305.099309645504',
              18.0: '10708.785815065394',
              19.0: '12129.286433219073',
              2.0: '82.44799624243127',
              20.0: '13697.80138578889',
              21.0: '15323.650985634364',
              22.0: '16906.04385595054',
              23.0: '18527.711422637694',
              24.0: '20191.532374372',
              25.0: '21876.939198635773',
              26.0: '23545.74530815244',
              27.0: '25241.90660844144',
              28.0: '26943.359383106617',
              29.0: '28644.8121577718',
              3.0: '183.61981576248863',
              30.0: '30346.264932436978',
              31.0: '32047.71770710216',
              32.0: '33749.170481767345',
              33.0: '35450.62325643252',
              34.0: '37152.076031097706',
              35.0: '38853.528805762886',
              36.0: '40554.98158042807',
              4.0: '332.1308788936939',
              5.0: '549.8552829057936',
              6.0: '813.9998034816977',
              7.0: '1154.892392007855',
              8.0: '1567.0847464020808',
              9.0: '2051.2427432604745',
            },
            lower_confidence: {
              0.0: '0.0',
              1.0: '5.9760960219171855',
              10.0: '2524.786192369755',
              11.0: '3160.033097755365',
              12.0: '3921.86003525796',
              13.0: '4759.244719878194',
              14.0: '5674.578263883189',
              15.0: '6741.098478691886',
              16.0: '7881.527149296368',
              17.0: '9115.990200723756',
              18.0: '10505.79719202093',
              19.0: '11913.325322570538',
              2.0: '64.64212834193746',
              20.0: '13468.385670606755',
              21.0: '15080.77344573802',
              22.0: '16651.105866496913',
              23.0: '18260.790551364877',
              24.0: '19912.932530013408',
              25.0: '21586.985283346683',
              26.0: '23244.820391246543',
              27.0: '24930.287992000853',
              28.0: '26621.402058900654',
              29.0: '28312.837949274537',
              3.0: '157.01440281175275',
              30.0: '30004.567359128505',
              31.0: '31696.56590502446',
              32.0: '33388.812403461976',
              33.0: '35081.28831211035',
              34.0: '36773.97729056162',
              35.0: '38466.86485076774',
              36.0: '40159.93807574787',
              4.0: '296.40697288220065',
              5.0: '503.8602155289797',
              6.0: '757.9382896006149',
              7.0: '1087.8158133226007',
              8.0: '1489.4896180328321',
              9.0: '1962.375415918796',
            },
            upper_confidence: {
              0.0: '0.0',
              1.0: '21.237472362334472',
              10.0: '2725.718296277048',
              11.0: '3384.7169995304125',
              12.0: '4171.528502950158',
              13.0: '5034.3258661142545',
              14.0: '5973.771675130925',
              15.0: '7066.839669621883',
              16.0: '8233.894341492998',
              17.0: '9494.208418567252',
              18.0: '10911.774438109858',
              19.0: '12345.247543867605',
              2.0: '100.25386414292507',
              20.0: '13927.21710097102',
              21.0: '15566.528525530706',
              22.0: '17160.981845404156',
              23.0: '18794.63229391052',
              24.0: '20470.132218730585',
              25.0: '22166.893113924863',
              26.0: '23846.67022505834',
              27.0: '25553.525224882018',
              28.0: '27265.316707312577',
              29.0: '28976.786366269054',
              3.0: '210.22522871322448',
              30.0: '30687.96250574545',
              31.0: '32398.86950917987',
              32.0: '34109.528560072715',
              33.0: '35819.958200754685',
              34.0: '37530.17477163379',
              35.0: '39240.192760758044',
              36.0: '40950.02508510826',
              4.0: '367.85478490518716',
              5.0: '595.8503502826075',
              6.0: '870.0613173627803',
              7.0: '1221.9689706931094',
              8.0: '1644.6798747713297',
              9.0: '2140.1100706021534',
            },
          },
          Americas: {
            enrollments: {
              0.0: '0.0',
              1.0: '0.5413449556206733',
              10.0: '2039.6061878132464',
              11.0: '2601.675029350029',
              12.0: '3109.1536053257037',
              13.0: '3709.082195105873',
              14.0: '4312.087267798597',
              15.0: '4915.681945082666',
              16.0: '5582.253287420227',
              17.0: '6261.501515111462',
              18.0: '6969.065958587538',
              19.0: '7775.059117168379',
              2.0: '48.943048863773775',
              20.0: '8652.810285094858',
              21.0: '9532.924488893355',
              22.0: '10451.061443203691',
              23.0: '11411.214314897854',
              24.0: '12443.195874979221',
              25.0: '13478.22720115022',
              26.0: '14513.258527321219',
              27.0: '15548.289853492219',
              28.0: '16583.321179663224',
              29.0: '17618.35250583422',
              3.0: '114.38872502284872',
              30.0: '18653.383832005224',
              31.0: '19688.41515817622',
              32.0: '20723.446484347223',
              33.0: '21758.477810518227',
              34.0: '22793.509136689223',
              35.0: '23828.540462860226',
              36.0: '24863.571789031226',
              4.0: '205.92615452421524',
              5.0: '346.27454843349',
              6.0: '555.6025659286238',
              7.0: '834.3175441133403',
              8.0: '1167.5737627026422',
              9.0: '1561.4819536072634',
            },
            lower_confidence: {
              0.0: '0.0',
              1.0: '0.0',
              10.0: '1950.6314007893163',
              11.0: '2501.664461322668',
              12.0: '2999.5773965281687',
              13.0: '3589.7052975296833',
              14.0: '4182.868762763232',
              15.0: '4778.036512573328',
              16.0: '5435.015545422036',
              17.0: '6105.41587638085',
              18.0: '6804.5744730486595',
              19.0: '7601.502915482773',
              2.0: '35.1944242786551',
              20.0: '8469.89749211503',
              21.0: '9341.063655115804',
              22.0: '10250.166975375152',
              23.0: '11201.625805066682',
              24.0: '12224.44620255983',
              25.0: '13250.645018359211',
              26.0: '14277.10542166869',
              27.0: '15303.794211395603',
              28.0: '16330.758417762076',
              29.0: '17357.972440556485',
              3.0: '93.3552039180305',
              30.0: '18385.414415673924',
              31.0: '19413.06549359709',
              32.0: '20440.90928771864',
              33.0: '21468.9314460554',
              34.0: '22497.119314211224',
              35.0: '23525.461666895484',
              36.0: '24553.94849168696',
              4.0: '177.47292357868994',
              5.0: '309.76625378337',
              6.0: '509.36940221081534',
              7.0: '777.6152927163048',
              8.0: '1100.3732655598512',
              9.0: '1483.9418831481878',
            },
            upper_confidence: {
              0.0: '0.0',
              1.0: '1.877090018305796',
              10.0: '2128.5809748371757',
              11.0: '2701.6855973773913',
              12.0: '3218.729814123239',
              13.0: '3828.4590926820642',
              14.0: '4441.3057728339645',
              15.0: '5053.327377592003',
              16.0: '5729.491029418418',
              17.0: '6417.587153842076',
              18.0: '7133.557444126416',
              19.0: '7948.615318853986',
              2.0: '62.69167344889244',
              20.0: '8835.723078074687',
              21.0: '9724.785322670905',
              22.0: '10651.95591103223',
              23.0: '11620.802824729026',
              24.0: '12661.94554739861',
              25.0: '13705.809383941229',
              26.0: '14749.411632973755',
              27.0: '15792.785495588838',
              28.0: '16835.883941564374',
              29.0: '17878.732571111956',
              3.0: '135.42224612766694',
              30.0: '18921.353248336523',
              31.0: '19963.76482275535',
              32.0: '21005.9836809758',
              33.0: '22048.02417498105',
              34.0: '23089.898959167218',
              35.0: '24131.619258824965',
              36.0: '25173.195086375497',
              4.0: '234.3793854697405',
              5.0: '382.78284308361',
              6.0: '601.8357296464321',
              7.0: '891.0197955103763',
              8.0: '1234.7742598454327',
              9.0: '1639.022024066339',
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const preProbabilityCompleteTimeChart = {
      result: {
        global: {
          global: {
            Probability: {
              1.0: '36.0',
            },
            target_no_of_patients: '50',
          },
        },
        region: {
          'australia and new zealand': {
            Probability: {
              '0.0': '0.0',
              0.01: '0.88',
              0.02: '0.91',
              0.03: '0.96',
              0.04: '0.98',
              0.05: '1.0',
              0.06: '1.0',
              0.07: '1.01',
              0.08: '1.01',
              0.09: '1.02',
              0.1: '1.02',
              0.11: '1.02',
              0.12: '1.02',
              0.13: '1.03',
              0.14: '1.03',
              0.15: '1.03',
              0.16: '1.03',
              0.17: '1.04',
              0.18: '1.04',
              0.19: '1.04',
              0.2: '1.04',
              0.3: '1.06',
              0.31: '1.06',
              0.32: '1.06',
              0.33: '1.07',
              0.34: '1.07',
              0.35: '1.07',
              0.36: '1.07',
              0.37: '1.07',
              0.38: '1.07',
              0.39: '1.08',
              0.4: '1.08',
              0.41: '1.08',
              0.42: '1.08',
              0.43: '1.08',
              0.44: '1.08',
              0.45: '1.08',
              0.46: '1.09',
              0.47: '1.09',
              0.48: '1.09',
              0.49: '1.09',
              0.5: '1.09',
              0.51: '1.09',
              0.52: '1.1',
              0.53: '1.1',
              0.54: '1.1',
              0.55: '1.1',
              0.56: '1.1',
              0.57: '1.11',
              0.58: '1.11',
              0.59: '1.11',
              0.6: '1.11',
              0.61: '1.11',
              0.62: '1.11',
              0.63: '1.11',
              0.64: '1.12',
              0.65: '1.12',
              0.66: '1.12',
              0.67: '1.12',
              0.68: '1.12',
              0.69: '1.13',
              0.7: '1.13',
              0.71: '1.13',
              0.72: '1.13',
              0.73: '1.13',
              0.74: '1.14',
              0.75: '1.14',
              0.76: '1.14',
              0.77: '1.14',
              0.78: '1.15',
              0.79: '1.15',
              0.8: '1.15',
              0.81: '1.15',
              0.82: '1.16',
              0.83: '1.16',
              0.84: '1.16',
              0.85: '1.16',
              0.86: '1.17',
              0.87: '1.17',
              0.88: '1.18',
              0.89: '1.18',
              0.9: '1.19',
              0.91: '1.19',
              0.92: '1.2',
              0.93: '1.2',
              0.94: '1.21',
              0.95: '1.21',
              0.96: '1.22',
              0.97: '1.23',
              0.98: '1.26',
              0.99: '1.27',
              1.0: '36.0',
            },
            target_no_of_patients: '30',
          },
          'north america': {
            Probability: {
              0.0: '0.0',
              0.01: '1.12',
              0.02: '1.13',
              0.03: '1.14',
              0.04: '1.14',
              0.05: '1.14',
              0.06: '1.14',
              0.07: '1.15',
              0.08: '1.15',
              0.09: '1.15',
              0.1: '1.15',
              0.11: '1.15',
              0.12: '1.15',
              0.13: '1.16',
              0.14: '1.16',
              0.15: '1.16',
              0.16: '1.16',
              0.17: '1.16',
              0.18: '1.16',
              0.19: '1.16',
              0.2: '1.16',
              0.21: '1.17',
              0.22: '1.17',
              0.23: '1.17',
              0.24: '1.17',
              0.25: '1.17',
              0.26: '1.17',
              0.27: '1.17',
              0.28: '1.17',
              0.29: '1.17',
              0.3: '1.18',
              0.31: '1.18',
              0.32: '1.18',
              0.33: '1.18',
              0.34: '1.18',
              0.35: '1.18',
              0.36: '1.18',
              0.37: '1.18',
              0.38: '1.18',
              0.39: '1.18',
              0.4: '1.19',
              0.41: '1.19',
              0.42: '1.19',
              0.43: '1.19',
              0.44: '1.19',
              0.45: '1.19',
              0.46: '1.19',
              0.47: '1.19',
              0.48: '1.19',
              0.49: '1.19',
              0.5: '1.19',
              0.51: '1.2',
              0.52: '1.2',
              0.53: '1.2',
              0.54: '1.2',
              0.55: '1.2',
              0.56: '1.2',
              0.57: '1.2',
              0.58: '1.2',
              0.59: '1.2',
              0.6: '1.21',
              0.61: '1.21',
              0.62: '1.21',
              0.63: '1.21',
              0.64: '1.21',
              0.65: '1.21',
              0.66: '1.21',
              0.67: '1.21',
              0.68: '1.22',
              0.69: '1.22',
              0.7: '1.22',
              0.71: '1.22',
              0.72: '1.22',
              0.73: '1.22',
              0.74: '1.22',
              0.75: '1.22',
              0.76: '1.23',
              0.77: '1.23',
              0.78: '1.23',
              0.79: '0.46',
              0.8: '0.47',
              0.81: '0.47',
              0.82: '0.47',
              0.83: '0.47',
              0.84: '0.48',
              0.85: '0.48',
              0.86: '0.49',
              0.87: '0.49',
              0.88: '0.49',
              0.89: '0.5',
              0.9: '0.5',
              0.91: '0.51',
              0.92: '0.51',
              0.93: '0.52',
              0.94: '0.53',
              0.95: '0.53',
              0.96: '0.55',
              0.97: '0.56',
              0.98: '0.59',
              0.99: '0.61',
              1.0: '36.0',
            },
            target_no_of_patients: '20',
          },
        },
        country: {
          ABC: {
            Probability: {
              '0.0': '0.0',
              0.01: '0.88',
              0.02: '0.91',
              0.03: '0.96',
              0.04: '0.98',
              0.05: '1.0',
              0.06: '1.0',
              0.07: '1.01',
              0.08: '1.01',
              0.09: '1.02',
              0.1: '1.02',
              0.11: '1.02',
              0.12: '1.02',
              0.13: '1.03',
              0.14: '1.03',
              0.15: '1.03',
              0.16: '1.03',
              0.17: '1.04',
              0.18: '1.04',
              0.19: '1.04',
              0.2: '1.04',
              0.3: '1.06',
              0.31: '1.06',
              0.32: '1.06',
              0.33: '1.07',
              0.34: '1.07',
              0.35: '1.07',
              0.36: '1.07',
              0.37: '1.07',
              0.38: '1.07',
              0.39: '1.08',
              0.4: '1.08',
              0.41: '1.08',
              0.42: '1.08',
              0.43: '1.08',
              0.44: '1.08',
              0.45: '1.08',
              0.46: '1.09',
              0.47: '1.09',
              0.48: '1.09',
              0.49: '1.09',
              0.5: '1.09',
              0.51: '1.09',
              0.52: '1.1',
              0.53: '1.1',
              0.54: '1.1',
              0.55: '1.1',
              0.56: '1.1',
              0.57: '1.11',
              0.58: '1.11',
              0.59: '1.11',
              0.6: '1.11',
              0.61: '1.11',
              0.62: '1.11',
              0.63: '1.11',
              0.64: '1.12',
              0.65: '1.12',
              0.66: '1.12',
              0.67: '1.12',
              0.68: '1.12',
              0.69: '1.13',
              0.7: '1.13',
              0.71: '1.13',
              0.72: '1.13',
              0.73: '1.13',
              0.74: '1.14',
              0.75: '1.14',
              0.76: '1.14',
              0.77: '1.14',
              0.78: '1.15',
              0.79: '1.15',
              0.8: '1.15',
              0.81: '1.15',
              0.82: '1.16',
              0.83: '1.16',
              0.84: '1.16',
              0.85: '1.16',
              0.86: '1.17',
              0.87: '1.17',
              0.88: '1.18',
              0.89: '1.18',
              0.9: '1.19',
              0.91: '1.19',
              0.92: '1.2',
              0.93: '1.2',
              0.94: '1.21',
              0.95: '1.21',
              0.96: '1.22',
              0.97: '1.23',
              0.98: '1.26',
              0.99: '1.27',
              1.0: '36.0',
            },
            target_no_of_patients: '30',
          },
          Americas: {
            Probability: {
              0.0: '0.0',
              0.01: '1.12',
              0.02: '1.13',
              0.03: '1.14',
              0.04: '1.14',
              0.05: '1.14',
              0.06: '1.14',
              0.07: '1.15',
              0.08: '1.15',
              0.09: '1.15',
              0.1: '1.15',
              0.11: '1.15',
              0.12: '1.15',
              0.13: '1.16',
              0.14: '1.16',
              0.15: '1.16',
              0.16: '1.16',
              0.17: '1.16',
              0.18: '1.16',
              0.19: '1.16',
              0.2: '1.16',
              0.21: '1.17',
              0.22: '1.17',
              0.23: '1.17',
              0.24: '1.17',
              0.25: '1.17',
              0.26: '1.17',
              0.27: '1.17',
              0.28: '1.17',
              0.29: '1.17',
              0.3: '1.18',
              0.31: '1.18',
              0.32: '1.18',
              0.33: '1.18',
              0.34: '1.18',
              0.35: '1.18',
              0.36: '1.18',
              0.37: '1.18',
              0.38: '1.18',
              0.39: '1.18',
              0.4: '1.19',
              0.41: '1.19',
              0.42: '1.19',
              0.43: '1.19',
              0.44: '1.19',
              0.45: '1.19',
              0.46: '1.19',
              0.47: '1.19',
              0.48: '1.19',
              0.49: '1.19',
              0.5: '1.19',
              0.51: '1.2',
              0.52: '1.2',
              0.53: '1.2',
              0.54: '1.2',
              0.55: '1.2',
              0.56: '1.2',
              0.57: '1.2',
              0.58: '1.2',
              0.59: '1.2',
              0.6: '1.21',
              0.61: '1.21',
              0.62: '1.21',
              0.63: '1.21',
              0.64: '1.21',
              0.65: '1.21',
              0.66: '1.21',
              0.67: '1.21',
              0.68: '1.22',
              0.69: '1.22',
              0.7: '1.22',
              0.71: '1.22',
              0.72: '1.22',
              0.73: '1.22',
              0.74: '1.22',
              0.75: '1.22',
              0.76: '1.23',
              0.77: '1.23',
              0.78: '1.23',
              0.79: '0.46',
              0.8: '0.47',
              0.81: '0.47',
              0.82: '0.47',
              0.83: '0.47',
              0.84: '0.48',
              0.85: '0.48',
              0.86: '0.49',
              0.87: '0.49',
              0.88: '0.49',
              0.89: '0.5',
              0.9: '0.5',
              0.91: '0.51',
              0.92: '0.51',
              0.93: '0.52',
              0.94: '0.53',
              0.95: '0.53',
              0.96: '0.55',
              0.97: '0.56',
              0.98: '0.59',
              0.99: '0.61',
              1.0: '36.0',
            },
            target_no_of_patients: '20',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const preEnrollmentCompletionGrid = {
      result: {
        global: {
          global: {
            completion_probability_10_perc: 36.35,
            completion_probability_20_perc: 37.3,
            completion_probability_30_perc: 38.01,
            completion_probability_40_perc: 38.62,
            completion_probability_50_perc: 39.21,
            completion_probability_5_perc: 35.59,
            completion_probability_60_perc: 39.81,
            completion_probability_70_perc: 40.47,
            completion_probability_80_perc: 41.27,
            completion_probability_90_perc: 42.41,
            completion_probability_95_perc: 43.39,
            no_of_sites: 62.0,
            target_no_of_patients: 650.0,
            time_to_complete_enrollment: 39.21,
          },
        },
        country: {
          ABC: {
            completion_probability_10_perc: '1.02',
            completion_probability_20_perc: '1.04',
            completion_probability_30_perc: '1.06',
            completion_probability_40_perc: '1.08',
            completion_probability_50_perc: '1.09',
            completion_probability_5_perc: '1.0',
            completion_probability_60_perc: '1.11',
            completion_probability_70_perc: '1.13',
            completion_probability_80_perc: '1.15',
            completion_probability_90_perc: '1.19',
            completion_probability_95_perc: '1.21',
            no_of_sites: '20',
            target_no_of_patients: '30',
            time_to_complete_enrollment: '1.09',
          },
          Americas: {
            completion_probability_10_perc: '1.15',
            completion_probability_20_perc: '1.16',
            completion_probability_30_perc: '1.18',
            completion_probability_40_perc: '1.19',
            completion_probability_50_perc: '1.19',
            completion_probability_5_perc: '1.14',
            completion_probability_60_perc: '1.21',
            completion_probability_70_perc: '1.22',
            completion_probability_80_perc: '0.47',
            completion_probability_90_perc: '0.5',
            completion_probability_95_perc: '0.53',
            no_of_sites: '10',
            target_no_of_patients: '20',
            time_to_complete_enrollment: '1.19',
          },
        },
        region: {
          'australia and new zealand': {
            completion_probability_10_perc: '1.02',
            completion_probability_20_perc: '1.04',
            completion_probability_30_perc: '1.06',
            completion_probability_40_perc: '1.08',
            completion_probability_50_perc: '1.09',
            completion_probability_5_perc: '1.0',
            completion_probability_60_perc: '1.11',
            completion_probability_70_perc: '1.13',
            completion_probability_80_perc: '1.15',
            completion_probability_90_perc: '1.19',
            completion_probability_95_perc: '1.21',
            no_of_sites: '20',
            target_no_of_patients: '30',
            time_to_complete_enrollment: '1.09',
          },
          'north america': {
            completion_probability_10_perc: '1.15',
            completion_probability_20_perc: '1.16',
            completion_probability_30_perc: '1.18',
            completion_probability_40_perc: '1.19',
            completion_probability_50_perc: '1.19',
            completion_probability_5_perc: '1.14',
            completion_probability_60_perc: '1.21',
            completion_probability_70_perc: '1.22',
            completion_probability_80_perc: '0.47',
            completion_probability_90_perc: '0.5',
            completion_probability_95_perc: '0.53',
            no_of_sites: '10',
            target_no_of_patients: '20',
            time_to_complete_enrollment: '1.19',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const preSiteRandomizationChart = {
      result: {
        global: {
          // global: {},
        },
        country: {
          ABC: {
            iteration_1: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.12,
              0.18: 0.1,
              0.21: 0.1,
              0.24: 0.11,
              0.27: 0.13,
              0.3: 0.15,
              0.33: 0.17,
              0.36: 0.18,
              0.39: 0.18,
              0.42: 0.19,
              0.45: 0.22,
              0.48: 0.25,
              0.51: 0.25,
              0.54: 0.22,
              0.57: 0.18,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.2,
              1.14: 0.17,
              1.17: 0.13,
              1.2: 0.1,
              1.23: 0.09,
              1.26: 0.13,
              1.29: 0.17,
              1.32: 0.19,
              1.35: 0.17,
              1.38: 0.13,
              1.41: 0.08,
              1.44: 0.05,
              1.47: 0.03,
              1.5: 0.04,
              1.53: 0.06,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.05,
              1.83: 0.06,
              1.86: 0.08,
              1.89: 0.09,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.1,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.15,
              2.28: 0.11,
              2.31: 0.06,
              2.34: 0.02,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.07,
              2.49: 0.08,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.18,
              2.73: 0.17,
              2.76: 0.16,
              2.79: 0.16,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.04,
              3.15: 0.07,
              3.18: 0.08,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.0,
              3.36: 0.0,
              3.39: 0.0,
              3.42: 0.0,
              3.45: 0.0,
              3.48: 0.0,
              3.51: 0.0,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.0,
              3.87: 0.0,
              3.9: 0.0,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.08,
              4.11: 0.1,
              4.14: 0.1,
              4.17: 0.1,
              4.2: 0.09,
              4.23: 0.08,
              4.26: 0.07,
              4.29: 0.09,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.05,
              4.47: 0.02,
              4.5: 0.01,
              4.53: 0.0,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.04,
              4.65: 0.06,
              4.68: 0.08,
              4.71: 0.07,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.17,
              4.95: 0.13,
              4.98: 0.07,
              5.01: 0.03,
              5.04: 0.01,
              5.07: 0.01,
              5.1: 0.03,
              5.13: 0.06,
              5.16: 0.08,
              5.19: 0.07,
              5.22: 0.05,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.12,
              5.4: 0.16,
              5.43: 0.15,
              5.46: 0.11,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.12,
              5.7: 0.15,
              5.73: 0.14,
              5.76: 0.09,
              5.79: 0.05,
              5.82: 0.03,
              5.85: 0.05,
              5.88: 0.07,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.04,
              6.21: 0.07,
              6.24: 0.1,
              6.27: 0.12,
              6.3: 0.12,
              6.33: 0.1,
              6.36: 0.09,
              6.39: 0.08,
              6.42: 0.09,
              6.45: 0.08,
              6.48: 0.05,
              6.51: 0.03,
              6.54: 0.02,
              6.57: 0.04,
              6.6: 0.08,
              6.63: 0.14,
              6.66: 0.16,
              6.69: 0.13,
              6.72: 0.07,
              6.75: 0.03,
              6.78: 0.01,
              6.81: 0.0,
              6.84: 0.0,
              6.87: 0.0,
              6.9: 0.01,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.08,
              7.02: 0.07,
              7.05: 0.05,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.04,
              7.23: 0.07,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.07,
              7.89: 0.04,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.08,
              8.22: 0.07,
              8.25: 0.04,
              8.28: 0.02,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.03,
              8.46: 0.07,
              8.49: 0.11,
              8.52: 0.13,
              8.55: 0.13,
              8.58: 0.1,
              8.61: 0.06,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.07,
              8.91: 0.08,
              8.94: 0.06,
              8.97: 0.03,
              '9.0': 0.02,
              9.03: 0.03,
              9.06: 0.05,
              9.09: 0.07,
              9.12: 0.08,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.02,
              9.24: 0.03,
              9.27: 0.05,
              9.3: 0.08,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.06,
              9.42: 0.07,
              9.45: 0.08,
              9.48: 0.07,
              9.51: 0.05,
              9.54: 0.02,
              9.57: 0.01,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.07,
              10.02: 0.12,
              10.05: 0.14,
              10.08: 0.14,
              10.11: 0.12,
              10.14: 0.1,
              10.17: 0.09,
              10.2: 0.08,
              10.23: 0.06,
              10.26: 0.03,
              10.29: 0.01,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.0,
              10.47: 0.0,
              10.5: 0.0,
              10.53: 0.0,
              10.56: 0.0,
              10.59: 0.0,
              10.62: 0.0,
              10.65: 0.0,
              10.68: 0.0,
              10.71: 0.0,
              10.74: 0.0,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.0,
              11.1: 0.01,
              11.13: 0.02,
              11.16: 0.04,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.05,
              11.67: 0.1,
              11.7: 0.15,
              11.73: 0.2,
              11.76: 0.24,
              11.79: 0.24,
              11.82: 0.19,
              11.85: 0.14,
              11.88: 0.11,
              11.91: 0.09,
              11.94: 0.06,
              11.97: 0.03,
              '12.0': 0.01,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.07,
              12.21: 0.08,
              12.24: 0.06,
              12.27: 0.04,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.06,
              12.87: 0.08,
              12.9: 0.07,
              12.93: 0.05,
              12.96: 0.05,
              12.99: 0.06,
              13.02: 0.08,
              13.05: 0.08,
              13.08: 0.05,
              13.11: 0.03,
              13.14: 0.03,
              13.17: 0.05,
              13.2: 0.07,
              13.23: 0.08,
              13.26: 0.06,
              13.29: 0.03,
              13.32: 0.01,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.0,
              13.62: 0.0,
              13.65: 0.0,
              13.68: 0.0,
              13.71: 0.0,
              13.74: 0.0,
              13.77: 0.01,
              13.8: 0.02,
              13.83: 0.04,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.01,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.0,
              14.34: 0.0,
              14.37: 0.0,
              14.4: 0.0,
              14.43: 0.0,
              14.46: 0.0,
              14.49: 0.01,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.07,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.03,
              14.7: 0.01,
              14.73: 0.0,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.01,
              14.88: 0.02,
              14.91: 0.04,
              14.94: 0.07,
              14.97: 0.08,
              '15.0': 0.06,
              15.03: 0.04,
              15.06: 0.01,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.0,
              15.24: 0.0,
              15.27: 0.0,
              15.3: 0.0,
              15.33: 0.0,
              15.36: 0.0,
              15.39: 0.0,
              15.42: 0.0,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.03,
              15.54: 0.06,
              15.57: 0.08,
              15.6: 0.07,
              15.63: 0.05,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.07,
              16.32: 0.05,
              16.35: 0.02,
              16.38: 0.01,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.0,
              16.59: 0.0,
              16.62: 0.0,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.0,
              17.16: 0.0,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.08,
              17.43: 0.12,
              17.46: 0.15,
              17.49: 0.16,
              17.52: 0.15,
              17.55: 0.13,
              17.58: 0.09,
              17.61: 0.05,
              17.64: 0.02,
              17.67: 0.01,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.01,
              18.42: 0.02,
              18.45: 0.05,
              18.48: 0.08,
              18.51: 0.09,
              18.54: 0.08,
              18.57: 0.08,
              18.6: 0.09,
              18.63: 0.08,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.01,
              19.5: 0.02,
              19.53: 0.04,
              19.56: 0.07,
              19.59: 0.08,
              19.62: 0.06,
              19.65: 0.03,
              19.68: 0.01,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.08,
              22.89: 0.07,
              22.92: 0.04,
              22.95: 0.02,
              22.98: 0.01,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.01,
              23.97: 0.03,
              '24.0': 0.05,
              24.03: 0.08,
              24.06: 0.08,
              24.09: 0.05,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.01,
              25.08: 0.03,
              25.11: 0.06,
              25.14: 0.08,
              25.17: 0.07,
              25.2: 0.05,
              25.23: 0.02,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.01,
              26.94: 0.03,
              26.97: 0.05,
              '27.0': 0.08,
              27.03: 0.08,
              27.06: 0.05,
              27.09: 0.03,
              27.12: 0.01,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.01,
              28.89: 0.04,
              28.92: 0.06,
              28.95: 0.08,
              28.98: 0.07,
              29.01: 0.04,
              29.04: 0.02,
              29.07: 0.01,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.02,
              30.3: 0.04,
              30.33: 0.07,
              30.36: 0.08,
              30.39: 0.07,
              30.42: 0.04,
              30.45: 0.02,
              30.48: 0.01,
              30.51: 0.01,
              30.54: 0.02,
              30.57: 0.05,
              30.6: 0.07,
              30.63: 0.08,
              30.66: 0.06,
              30.69: 0.03,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.02,
              33.96: 0.05,
              33.99: 0.09,
              34.02: 0.13,
              34.05: 0.14,
              34.08: 0.12,
              34.11: 0.07,
              34.14: 0.03,
              34.17: 0.01,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.0,
              40.74: 0.0,
              40.77: 0.0,
              40.8: 0.0,
              40.83: 0.0,
              40.86: 0.0,
              40.89: 0.0,
              40.92: 0.0,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.01,
              41.58: 0.03,
              41.61: 0.05,
              41.64: 0.08,
              41.67: 0.08,
              41.7: 0.05,
              41.73: 0.03,
              41.76: 0.01,
              41.79: 0.0,
              41.82: 0.0,
              41.85: 0.0,
              41.88: 0.0,
              41.91: 0.0,
              41.94: 0.0,
              41.97: 0.0,
              '42.0': 0.0,
              42.03: 0.0,
              42.06: 0.0,
              42.09: 0.0,
              42.12: 0.0,
              42.15: 0.0,
              42.18: 0.0,
              42.21: 0.0,
              42.24: 0.0,
              42.27: 0.0,
              42.3: 0.0,
              42.33: 0.0,
              42.36: 0.0,
              42.39: 0.0,
              42.42: 0.0,
              42.45: 0.0,
              42.48: 0.0,
              42.51: 0.0,
              42.54: 0.0,
              42.57: 0.0,
              42.6: 0.0,
              42.63: 0.0,
              42.66: 0.0,
              42.69: 0.0,
              42.72: 0.0,
              42.75: 0.0,
              42.78: 0.0,
              42.81: 0.0,
              42.84: 0.0,
              42.87: 0.0,
              42.9: 0.0,
              42.93: 0.0,
              42.96: 0.0,
              42.99: 0.0,
              43.02: 0.0,
              43.05: 0.0,
              43.08: 0.0,
              43.11: 0.0,
              43.14: 0.0,
              43.17: 0.0,
              43.2: 0.0,
              43.23: 0.0,
              43.26: 0.0,
              43.29: 0.0,
              43.32: 0.0,
              43.35: 0.0,
              43.38: 0.0,
              43.41: 0.0,
              43.44: 0.0,
              43.47: 0.0,
              43.5: 0.0,
              43.53: 0.0,
              43.56: 0.0,
              43.59: 0.0,
              43.62: 0.0,
              43.65: 0.0,
              43.68: 0.0,
              43.71: 0.0,
              43.74: 0.0,
              43.77: 0.0,
              43.8: 0.0,
              43.83: 0.0,
              43.86: 0.0,
              43.89: 0.0,
              43.92: 0.0,
              43.95: 0.0,
              43.98: 0.0,
              44.01: 0.0,
              44.04: 0.0,
              44.07: 0.0,
              44.1: 0.0,
              44.13: 0.0,
              44.16: 0.0,
              44.19: 0.0,
              44.22: 0.0,
              44.25: 0.0,
              44.28: 0.0,
              44.31: 0.0,
              44.34: 0.0,
              44.37: 0.0,
              44.4: 0.0,
              44.43: 0.0,
              44.46: 0.0,
              44.49: 0.0,
              44.52: 0.0,
              44.55: 0.0,
              44.58: 0.0,
              44.61: 0.0,
              44.64: 0.0,
              44.67: 0.0,
              44.7: 0.0,
              44.73: 0.0,
              44.76: 0.0,
              44.79: 0.0,
              44.82: 0.0,
              44.85: 0.0,
              44.88: 0.0,
              44.91: 0.0,
              44.94: 0.0,
              44.97: 0.0,
              '45.0': 0.0,
              45.03: 0.0,
              45.06: 0.0,
              45.09: 0.0,
              45.12: 0.0,
              45.15: 0.0,
              45.18: 0.0,
              45.21: 0.0,
              45.24: 0.0,
              45.27: 0.0,
              45.3: 0.0,
              45.33: 0.0,
              45.36: 0.0,
              45.39: 0.0,
              45.42: 0.0,
              45.45: 0.0,
              45.48: 0.0,
              45.51: 0.0,
              45.54: 0.0,
              45.57: 0.0,
              45.6: 0.0,
              45.63: 0.0,
              45.66: 0.0,
              45.69: 0.0,
              45.72: 0.0,
              45.75: 0.0,
              45.78: 0.0,
              45.81: 0.0,
              45.84: 0.0,
              45.87: 0.0,
              45.9: 0.0,
              45.93: 0.0,
              45.96: 0.0,
              45.99: 0.0,
              46.02: 0.0,
              46.05: 0.0,
              46.08: 0.01,
              46.11: 0.03,
              46.14: 0.06,
              46.17: 0.08,
              46.2: 0.08,
              46.23: 0.05,
              46.26: 0.02,
              46.29: 0.01,
              46.32: 0.0,
              46.35: 0.0,
              46.38: 0.0,
              46.41: 0.0,
              46.44: 0.0,
              46.47: 0.0,
              46.5: 0.0,
              46.53: 0.0,
              46.56: 0.0,
              46.59: 0.0,
              46.62: 0.0,
              46.65: 0.0,
              46.68: 0.0,
              46.71: 0.0,
              46.74: 0.0,
              46.77: 0.0,
              46.8: 0.0,
              46.83: 0.0,
              46.86: 0.0,
              46.89: 0.0,
              46.92: 0.0,
              46.95: 0.0,
              46.98: 0.0,
            },
            iteration_2: {
              '0.0': 0.0,
              0.03: 0.0,
              0.06: 0.01,
              0.09: 0.03,
              0.12: 0.08,
              0.15: 0.15,
              0.18: 0.2,
              0.21: 0.22,
              0.24: 0.2,
              0.27: 0.16,
              0.3: 0.13,
              0.33: 0.1,
              0.36: 0.08,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.18,
              0.66: 0.2,
              0.69: 0.21,
              0.72: 0.24,
              0.75: 0.25,
              0.78: 0.23,
              0.81: 0.16,
              0.84: 0.08,
              0.87: 0.03,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.0,
              1.02: 0.0,
              1.05: 0.02,
              1.08: 0.04,
              1.11: 0.07,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.01,
              1.26: 0.0,
              1.29: 0.0,
              1.32: 0.01,
              1.35: 0.03,
              1.38: 0.09,
              1.41: 0.17,
              1.44: 0.23,
              1.47: 0.22,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.02,
              1.59: 0.01,
              1.62: 0.0,
              1.65: 0.0,
              1.68: 0.0,
              1.71: 0.01,
              1.74: 0.03,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.05,
              1.95: 0.02,
              1.98: 0.01,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.0,
              2.1: 0.0,
              2.13: 0.01,
              2.16: 0.02,
              2.19: 0.06,
              2.22: 0.12,
              2.25: 0.16,
              2.28: 0.15,
              2.31: 0.1,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.01,
              2.43: 0.02,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.1,
              2.55: 0.11,
              2.58: 0.13,
              2.61: 0.16,
              2.64: 0.18,
              2.67: 0.18,
              2.7: 0.15,
              2.73: 0.11,
              2.76: 0.08,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.21,
              2.88: 0.23,
              2.91: 0.19,
              2.94: 0.12,
              2.97: 0.09,
              '3.0': 0.09,
              3.03: 0.08,
              3.06: 0.06,
              3.09: 0.03,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.08,
              3.24: 0.1,
              3.27: 0.11,
              3.3: 0.14,
              3.33: 0.19,
              3.36: 0.26,
              3.39: 0.3,
              3.42: 0.29,
              3.45: 0.22,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.07,
              3.57: 0.08,
              3.6: 0.08,
              3.63: 0.05,
              3.66: 0.02,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.07,
              3.9: 0.13,
              3.93: 0.18,
              3.96: 0.19,
              3.99: 0.17,
              4.02: 0.12,
              4.05: 0.07,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.08,
              4.35: 0.07,
              4.38: 0.07,
              4.41: 0.08,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.0,
              4.62: 0.0,
              4.65: 0.0,
              4.68: 0.0,
              4.71: 0.0,
              4.74: 0.0,
              4.77: 0.0,
              4.8: 0.0,
              4.83: 0.0,
              4.86: 0.0,
              4.89: 0.0,
              4.92: 0.0,
              4.95: 0.0,
              4.98: 0.01,
              5.01: 0.02,
              5.04: 0.04,
              5.07: 0.07,
              5.1: 0.08,
              5.13: 0.06,
              5.16: 0.03,
              5.19: 0.01,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.01,
              5.37: 0.02,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.09,
              5.58: 0.07,
              5.61: 0.04,
              5.64: 0.02,
              5.67: 0.01,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.0,
              5.88: 0.0,
              5.91: 0.0,
              5.94: 0.0,
              5.97: 0.0,
              '6.0': 0.0,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.09,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.02,
              6.96: 0.01,
              6.99: 0.0,
              7.02: 0.0,
              7.05: 0.01,
              7.08: 0.05,
              7.11: 0.12,
              7.14: 0.2,
              7.17: 0.23,
              7.2: 0.2,
              7.23: 0.12,
              7.26: 0.05,
              7.29: 0.02,
              7.32: 0.0,
              7.35: 0.0,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.01,
              7.59: 0.04,
              7.62: 0.09,
              7.65: 0.14,
              7.68: 0.16,
              7.71: 0.13,
              7.74: 0.09,
              7.77: 0.08,
              7.8: 0.08,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.01,
              7.95: 0.02,
              7.98: 0.04,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.03,
              8.13: 0.01,
              8.16: 0.0,
              8.19: 0.0,
              8.22: 0.0,
              8.25: 0.0,
              8.28: 0.0,
              8.31: 0.01,
              8.34: 0.02,
              8.37: 0.05,
              8.4: 0.07,
              8.43: 0.08,
              8.46: 0.06,
              8.49: 0.03,
              8.52: 0.01,
              8.55: 0.0,
              8.58: 0.01,
              8.61: 0.03,
              8.64: 0.07,
              8.67: 0.12,
              8.7: 0.16,
              8.73: 0.15,
              8.76: 0.13,
              8.79: 0.11,
              8.82: 0.09,
              8.85: 0.07,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.08,
              9.18: 0.13,
              9.21: 0.17,
              9.24: 0.19,
              9.27: 0.17,
              9.3: 0.12,
              9.33: 0.06,
              9.36: 0.03,
              9.39: 0.02,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.08,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.02,
              9.87: 0.04,
              9.9: 0.07,
              9.93: 0.08,
              9.96: 0.07,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.11,
              10.71: 0.11,
              10.74: 0.1,
              10.77: 0.08,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.08,
              10.92: 0.08,
              10.95: 0.06,
              10.98: 0.03,
              11.01: 0.01,
              11.04: 0.01,
              11.07: 0.02,
              11.1: 0.05,
              11.13: 0.09,
              11.16: 0.14,
              11.19: 0.15,
              11.22: 0.12,
              11.25: 0.07,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.01,
              11.49: 0.03,
              11.52: 0.06,
              11.55: 0.08,
              11.58: 0.08,
              11.61: 0.05,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.02,
              12.06: 0.04,
              12.09: 0.07,
              12.12: 0.08,
              12.15: 0.07,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.03,
              12.36: 0.06,
              12.39: 0.08,
              12.42: 0.07,
              12.45: 0.04,
              12.48: 0.02,
              12.51: 0.01,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.01,
              12.69: 0.03,
              12.72: 0.06,
              12.75: 0.08,
              12.78: 0.07,
              12.81: 0.04,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.01,
              13.5: 0.02,
              13.53: 0.05,
              13.56: 0.08,
              13.59: 0.1,
              13.62: 0.11,
              13.65: 0.11,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.05,
              13.89: 0.04,
              13.92: 0.06,
              13.95: 0.08,
              13.98: 0.08,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.08,
              14.31: 0.06,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.11,
              14.49: 0.15,
              14.52: 0.15,
              14.55: 0.11,
              14.58: 0.06,
              14.61: 0.04,
              14.64: 0.05,
              14.67: 0.07,
              14.7: 0.08,
              14.73: 0.06,
              14.76: 0.03,
              14.79: 0.01,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.01,
              15.18: 0.02,
              15.21: 0.06,
              15.24: 0.13,
              15.27: 0.2,
              15.3: 0.24,
              15.33: 0.23,
              15.36: 0.19,
              15.39: 0.13,
              15.42: 0.07,
              15.45: 0.03,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.05,
              15.69: 0.07,
              15.72: 0.08,
              15.75: 0.06,
              15.78: 0.03,
              15.81: 0.01,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.01,
              16.02: 0.02,
              16.05: 0.05,
              16.08: 0.07,
              16.11: 0.08,
              16.14: 0.06,
              16.17: 0.03,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.01,
              16.47: 0.03,
              16.5: 0.05,
              16.53: 0.08,
              16.56: 0.08,
              16.59: 0.05,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.04,
              16.8: 0.07,
              16.83: 0.08,
              16.86: 0.06,
              16.89: 0.03,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.03,
              17.19: 0.05,
              17.22: 0.08,
              17.25: 0.08,
              17.28: 0.07,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.08,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.08,
              18.69: 0.06,
              18.72: 0.03,
              18.75: 0.01,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.01,
              20.94: 0.02,
              20.97: 0.05,
              '21.0': 0.07,
              21.03: 0.08,
              21.06: 0.06,
              21.09: 0.03,
              21.12: 0.01,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.0,
              22.8: 0.0,
              22.83: 0.0,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.07,
              24.15: 0.08,
              24.18: 0.07,
              24.21: 0.04,
              24.24: 0.02,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.01,
              25.56: 0.02,
              25.59: 0.04,
              25.62: 0.07,
              25.65: 0.08,
              25.68: 0.06,
              25.71: 0.03,
              25.74: 0.01,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.07,
              26.52: 0.08,
              26.55: 0.06,
              26.58: 0.03,
              26.61: 0.01,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.01,
              29.55: 0.04,
              29.58: 0.07,
              29.61: 0.08,
              29.64: 0.07,
              29.67: 0.04,
              29.7: 0.02,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.01,
              32.64: 0.02,
              32.67: 0.05,
              32.7: 0.07,
              32.73: 0.08,
              32.76: 0.06,
              32.79: 0.03,
              32.82: 0.01,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.01,
              33.09: 0.03,
              33.12: 0.06,
              33.15: 0.08,
              33.18: 0.07,
              33.21: 0.05,
              33.24: 0.02,
              33.27: 0.01,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.01,
              35.67: 0.02,
              35.7: 0.05,
              35.73: 0.07,
              35.76: 0.08,
              35.79: 0.06,
              35.82: 0.03,
              35.85: 0.01,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
            iteration_3: {
              '0.0': 0.09,
              0.03: 0.16,
              0.06: 0.21,
              0.09: 0.22,
              0.12: 0.18,
              0.15: 0.14,
              0.18: 0.14,
              0.21: 0.15,
              0.24: 0.14,
              0.27: 0.11,
              0.3: 0.06,
              0.33: 0.02,
              0.36: 0.01,
              0.39: 0.0,
              0.42: 0.01,
              0.45: 0.04,
              0.48: 0.1,
              0.51: 0.17,
              0.54: 0.22,
              0.57: 0.21,
              0.6: 0.15,
              0.63: 0.08,
              0.66: 0.03,
              0.69: 0.02,
              0.72: 0.04,
              0.75: 0.07,
              0.78: 0.08,
              0.81: 0.07,
              0.84: 0.04,
              0.87: 0.02,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.02,
              1.02: 0.04,
              1.05: 0.07,
              1.08: 0.08,
              1.11: 0.07,
              1.14: 0.04,
              1.17: 0.02,
              1.2: 0.01,
              1.23: 0.03,
              1.26: 0.08,
              1.29: 0.14,
              1.32: 0.19,
              1.35: 0.22,
              1.38: 0.24,
              1.41: 0.3,
              1.44: 0.39,
              1.47: 0.47,
              1.5: 0.5,
              1.53: 0.47,
              1.56: 0.4,
              1.59: 0.33,
              1.62: 0.27,
              1.65: 0.21,
              1.68: 0.16,
              1.71: 0.12,
              1.74: 0.09,
              1.77: 0.07,
              1.8: 0.07,
              1.83: 0.08,
              1.86: 0.08,
              1.89: 0.05,
              1.92: 0.02,
              1.95: 0.01,
              1.98: 0.0,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.02,
              2.1: 0.04,
              2.13: 0.07,
              2.16: 0.09,
              2.19: 0.09,
              2.22: 0.08,
              2.25: 0.09,
              2.28: 0.11,
              2.31: 0.12,
              2.34: 0.15,
              2.37: 0.17,
              2.4: 0.15,
              2.43: 0.1,
              2.46: 0.06,
              2.49: 0.06,
              2.52: 0.09,
              2.55: 0.13,
              2.58: 0.16,
              2.61: 0.16,
              2.64: 0.14,
              2.67: 0.12,
              2.7: 0.08,
              2.73: 0.04,
              2.76: 0.02,
              2.79: 0.0,
              2.82: 0.0,
              2.85: 0.0,
              2.88: 0.0,
              2.91: 0.0,
              2.94: 0.02,
              2.97: 0.05,
              '3.0': 0.1,
              3.03: 0.17,
              3.06: 0.21,
              3.09: 0.23,
              3.12: 0.21,
              3.15: 0.17,
              3.18: 0.1,
              3.21: 0.05,
              3.24: 0.02,
              3.27: 0.01,
              3.3: 0.01,
              3.33: 0.02,
              3.36: 0.04,
              3.39: 0.07,
              3.42: 0.08,
              3.45: 0.07,
              3.48: 0.04,
              3.51: 0.02,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.01,
              3.87: 0.03,
              3.9: 0.06,
              3.93: 0.08,
              3.96: 0.07,
              3.99: 0.05,
              4.02: 0.02,
              4.05: 0.01,
              4.08: 0.0,
              4.11: 0.0,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.0,
              4.23: 0.0,
              4.26: 0.0,
              4.29: 0.0,
              4.32: 0.0,
              4.35: 0.0,
              4.38: 0.0,
              4.41: 0.0,
              4.44: 0.0,
              4.47: 0.0,
              4.5: 0.0,
              4.53: 0.0,
              4.56: 0.01,
              4.59: 0.04,
              4.62: 0.09,
              4.65: 0.14,
              4.68: 0.16,
              4.71: 0.13,
              4.74: 0.09,
              4.77: 0.08,
              4.8: 0.08,
              4.83: 0.07,
              4.86: 0.05,
              4.89: 0.02,
              4.92: 0.01,
              4.95: 0.0,
              4.98: 0.0,
              5.01: 0.0,
              5.04: 0.0,
              5.07: 0.0,
              5.1: 0.0,
              5.13: 0.0,
              5.16: 0.0,
              5.19: 0.0,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.0,
              5.37: 0.0,
              5.4: 0.0,
              5.43: 0.02,
              5.46: 0.05,
              5.49: 0.1,
              5.52: 0.15,
              5.55: 0.15,
              5.58: 0.11,
              5.61: 0.05,
              5.64: 0.02,
              5.67: 0.0,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.01,
              5.88: 0.02,
              5.91: 0.05,
              5.94: 0.08,
              5.97: 0.08,
              '6.0': 0.06,
              6.03: 0.03,
              6.06: 0.01,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.0,
              6.33: 0.0,
              6.36: 0.01,
              6.39: 0.02,
              6.42: 0.05,
              6.45: 0.07,
              6.48: 0.08,
              6.51: 0.06,
              6.54: 0.03,
              6.57: 0.02,
              6.6: 0.02,
              6.63: 0.05,
              6.66: 0.09,
              6.69: 0.15,
              6.72: 0.19,
              6.75: 0.2,
              6.78: 0.18,
              6.81: 0.14,
              6.84: 0.11,
              6.87: 0.09,
              6.9: 0.06,
              6.93: 0.04,
              6.96: 0.03,
              6.99: 0.04,
              7.02: 0.06,
              7.05: 0.08,
              7.08: 0.07,
              7.11: 0.04,
              7.14: 0.02,
              7.17: 0.02,
              7.2: 0.03,
              7.23: 0.06,
              7.26: 0.08,
              7.29: 0.07,
              7.32: 0.05,
              7.35: 0.02,
              7.38: 0.01,
              7.41: 0.0,
              7.44: 0.01,
              7.47: 0.04,
              7.5: 0.08,
              7.53: 0.13,
              7.56: 0.15,
              7.59: 0.13,
              7.62: 0.08,
              7.65: 0.04,
              7.68: 0.03,
              7.71: 0.04,
              7.74: 0.07,
              7.77: 0.08,
              7.8: 0.07,
              7.83: 0.04,
              7.86: 0.02,
              7.89: 0.0,
              7.92: 0.0,
              7.95: 0.0,
              7.98: 0.0,
              8.01: 0.0,
              8.04: 0.0,
              8.07: 0.01,
              8.1: 0.03,
              8.13: 0.06,
              8.16: 0.08,
              8.19: 0.07,
              8.22: 0.05,
              8.25: 0.02,
              8.28: 0.01,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.01,
              8.4: 0.02,
              8.43: 0.05,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.06,
              8.55: 0.04,
              8.58: 0.04,
              8.61: 0.06,
              8.64: 0.08,
              8.67: 0.07,
              8.7: 0.05,
              8.73: 0.02,
              8.76: 0.01,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.05,
              8.88: 0.08,
              8.91: 0.08,
              8.94: 0.07,
              8.97: 0.06,
              '9.0': 0.08,
              9.03: 0.11,
              9.06: 0.13,
              9.09: 0.13,
              9.12: 0.1,
              9.15: 0.08,
              9.18: 0.07,
              9.21: 0.08,
              9.24: 0.08,
              9.27: 0.07,
              9.3: 0.05,
              9.33: 0.05,
              9.36: 0.07,
              9.39: 0.08,
              9.42: 0.07,
              9.45: 0.04,
              9.48: 0.02,
              9.51: 0.01,
              9.54: 0.0,
              9.57: 0.0,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.01,
              9.93: 0.03,
              9.96: 0.06,
              9.99: 0.08,
              10.02: 0.09,
              10.05: 0.11,
              10.08: 0.13,
              10.11: 0.16,
              10.14: 0.15,
              10.17: 0.1,
              10.2: 0.05,
              10.23: 0.02,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.01,
              10.44: 0.03,
              10.47: 0.06,
              10.5: 0.08,
              10.53: 0.08,
              10.56: 0.08,
              10.59: 0.08,
              10.62: 0.08,
              10.65: 0.08,
              10.68: 0.05,
              10.71: 0.02,
              10.74: 0.01,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.01,
              11.07: 0.03,
              11.1: 0.05,
              11.13: 0.08,
              11.16: 0.09,
              11.19: 0.1,
              11.22: 0.1,
              11.25: 0.09,
              11.28: 0.07,
              11.31: 0.04,
              11.34: 0.02,
              11.37: 0.02,
              11.4: 0.04,
              11.43: 0.07,
              11.46: 0.08,
              11.49: 0.06,
              11.52: 0.03,
              11.55: 0.01,
              11.58: 0.0,
              11.61: 0.0,
              11.64: 0.0,
              11.67: 0.0,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.0,
              12.12: 0.0,
              12.15: 0.0,
              12.18: 0.0,
              12.21: 0.0,
              12.24: 0.0,
              12.27: 0.0,
              12.3: 0.0,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.01,
              12.51: 0.02,
              12.54: 0.04,
              12.57: 0.07,
              12.6: 0.08,
              12.63: 0.06,
              12.66: 0.04,
              12.69: 0.01,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.0,
              12.81: 0.0,
              12.84: 0.0,
              12.87: 0.0,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.01,
              13.56: 0.04,
              13.59: 0.06,
              13.62: 0.08,
              13.65: 0.07,
              13.68: 0.04,
              13.71: 0.02,
              13.74: 0.01,
              13.77: 0.0,
              13.8: 0.0,
              13.83: 0.0,
              13.86: 0.0,
              13.89: 0.0,
              13.92: 0.0,
              13.95: 0.0,
              13.98: 0.0,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.01,
              14.34: 0.04,
              14.37: 0.09,
              14.4: 0.14,
              14.43: 0.19,
              14.46: 0.2,
              14.49: 0.18,
              14.52: 0.14,
              14.55: 0.13,
              14.58: 0.16,
              14.61: 0.16,
              14.64: 0.12,
              14.67: 0.07,
              14.7: 0.03,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.01,
              14.82: 0.02,
              14.85: 0.04,
              14.88: 0.07,
              14.91: 0.08,
              14.94: 0.06,
              14.97: 0.03,
              '15.0': 0.01,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.01,
              15.15: 0.02,
              15.18: 0.05,
              15.21: 0.07,
              15.24: 0.08,
              15.27: 0.06,
              15.3: 0.03,
              15.33: 0.01,
              15.36: 0.01,
              15.39: 0.03,
              15.42: 0.06,
              15.45: 0.08,
              15.48: 0.08,
              15.51: 0.08,
              15.54: 0.09,
              15.57: 0.1,
              15.6: 0.12,
              15.63: 0.12,
              15.66: 0.1,
              15.69: 0.07,
              15.72: 0.04,
              15.75: 0.01,
              15.78: 0.01,
              15.81: 0.02,
              15.84: 0.04,
              15.87: 0.07,
              15.9: 0.08,
              15.93: 0.06,
              15.96: 0.03,
              15.99: 0.01,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.09,
              16.32: 0.08,
              16.35: 0.08,
              16.38: 0.09,
              16.41: 0.07,
              16.44: 0.06,
              16.47: 0.06,
              16.5: 0.07,
              16.53: 0.08,
              16.56: 0.07,
              16.59: 0.04,
              16.62: 0.02,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.02,
              16.98: 0.04,
              17.01: 0.08,
              17.04: 0.13,
              17.07: 0.14,
              17.1: 0.12,
              17.13: 0.08,
              17.16: 0.04,
              17.19: 0.01,
              17.22: 0.01,
              17.25: 0.02,
              17.28: 0.05,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.06,
              17.4: 0.03,
              17.43: 0.01,
              17.46: 0.0,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.01,
              17.64: 0.03,
              17.67: 0.06,
              17.7: 0.08,
              17.73: 0.07,
              17.76: 0.04,
              17.79: 0.02,
              17.82: 0.01,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.0,
              18.57: 0.0,
              18.6: 0.0,
              18.63: 0.0,
              18.66: 0.0,
              18.69: 0.0,
              18.72: 0.0,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.02,
              20.82: 0.04,
              20.85: 0.07,
              20.88: 0.08,
              20.91: 0.07,
              20.94: 0.04,
              20.97: 0.02,
              '21.0': 0.01,
              21.03: 0.02,
              21.06: 0.05,
              21.09: 0.07,
              21.12: 0.08,
              21.15: 0.06,
              21.18: 0.03,
              21.21: 0.01,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.01,
              22.65: 0.03,
              22.68: 0.06,
              22.71: 0.08,
              22.74: 0.07,
              22.77: 0.05,
              22.8: 0.02,
              22.83: 0.01,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.0,
              24.09: 0.0,
              24.12: 0.0,
              24.15: 0.0,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.01,
              24.54: 0.03,
              24.57: 0.06,
              24.6: 0.08,
              24.63: 0.08,
              24.66: 0.05,
              24.69: 0.02,
              24.72: 0.01,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.01,
              24.93: 0.02,
              24.96: 0.05,
              24.99: 0.08,
              25.02: 0.08,
              25.05: 0.06,
              25.08: 0.03,
              25.11: 0.01,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.01,
              25.74: 0.02,
              25.77: 0.05,
              25.8: 0.08,
              25.83: 0.08,
              25.86: 0.06,
              25.89: 0.03,
              25.92: 0.01,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.01,
              27.21: 0.03,
              27.24: 0.05,
              27.27: 0.08,
              27.3: 0.08,
              27.33: 0.05,
              27.36: 0.03,
              27.39: 0.01,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.01,
              33.96: 0.02,
              33.99: 0.04,
              34.02: 0.07,
              34.05: 0.08,
              34.08: 0.06,
              34.11: 0.04,
              34.14: 0.01,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.01,
              40.74: 0.02,
              40.77: 0.04,
              40.8: 0.07,
              40.83: 0.08,
              40.86: 0.06,
              40.89: 0.04,
              40.92: 0.01,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.0,
              41.58: 0.0,
              41.61: 0.0,
              41.64: 0.0,
              41.67: 0.0,
              41.7: 0.0,
              41.73: 0.0,
              41.76: 0.0,
              41.79: 0.01,
              41.82: 0.02,
              41.85: 0.05,
              41.88: 0.07,
              41.91: 0.08,
              41.94: 0.06,
              41.97: 0.03,
            },
            iteration_median: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.14,
              0.18: 0.18,
              0.21: 0.19,
              0.24: 0.18,
              0.27: 0.15,
              0.3: 0.13,
              0.33: 0.11,
              0.36: 0.1,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.15,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.06,
              1.26: 0.08,
              1.29: 0.08,
              1.32: 0.07,
              1.35: 0.08,
              1.38: 0.12,
              1.41: 0.16,
              1.44: 0.17,
              1.47: 0.16,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.08,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.16,
              2.28: 0.11,
              2.31: 0.07,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.15,
              2.73: 0.12,
              2.76: 0.09,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.01,
              3.36: 0.03,
              3.39: 0.06,
              3.42: 0.11,
              3.45: 0.14,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.06,
              3.57: 0.02,
              3.6: 0.01,
              3.63: 0.0,
              3.66: 0.01,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.02,
              3.9: 0.01,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.03,
              4.65: 0.02,
              4.68: 0.03,
              4.71: 0.06,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.16,
              4.95: 0.1,
              4.98: 0.05,
              5.01: 0.02,
              5.04: 0.02,
              5.07: 0.04,
              5.1: 0.07,
              5.13: 0.06,
              5.16: 0.07,
              5.19: 0.04,
              5.22: 0.03,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.05,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.07,
              5.7: 0.04,
              5.73: 0.02,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.01,
              5.85: 0.02,
              5.88: 0.05,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.01,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.11,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.06,
              7.02: 0.03,
              7.05: 0.01,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.05,
              7.23: 0.08,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.04,
              8.22: 0.02,
              8.25: 0.01,
              8.28: 0.0,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.04,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.07,
              8.55: 0.04,
              8.58: 0.02,
              8.61: 0.03,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.04,
              9.24: 0.08,
              9.27: 0.12,
              9.3: 0.12,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.05,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.07,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.08,
              10.71: 0.05,
              10.74: 0.04,
              10.77: 0.05,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.03,
              10.92: 0.01,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.01,
              11.1: 0.03,
              11.13: 0.06,
              11.16: 0.08,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.01,
              13.62: 0.03,
              13.65: 0.06,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.05,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.07,
              14.31: 0.04,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.05,
              14.49: 0.03,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.06,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.05,
              14.7: 0.02,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.01,
              15.24: 0.02,
              15.27: 0.04,
              15.3: 0.07,
              15.33: 0.08,
              15.36: 0.06,
              15.39: 0.04,
              15.42: 0.01,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.01,
              16.59: 0.03,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.01,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.01,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.05,
              22.89: 0.02,
              22.92: 0.01,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.04,
              26.52: 0.02,
              26.55: 0.01,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
          },
          Americas: {
            iteration_0: {
              '0.0': 0.16,
              0.03: 0.21,
              0.06: 0.21,
              0.09: 0.17,
              0.12: 0.14,
              0.15: 0.14,
              0.18: 0.18,
              0.21: 0.19,
              0.24: 0.18,
              0.27: 0.15,
              0.3: 0.13,
              0.33: 0.11,
              0.36: 0.1,
              0.39: 0.07,
              0.42: 0.04,
              0.45: 0.01,
              0.48: 0.0,
              0.51: 0.0,
              0.54: 0.0,
              0.57: 0.0,
              0.6: 0.0,
              0.63: 0.0,
              0.66: 0.0,
              0.69: 0.01,
              0.72: 0.02,
              0.75: 0.05,
              0.78: 0.07,
              0.81: 0.08,
              0.84: 0.07,
              0.87: 0.06,
              0.9: 0.07,
              0.93: 0.13,
              0.96: 0.22,
              0.99: 0.32,
              1.02: 0.38,
              1.05: 0.35,
              1.08: 0.25,
              1.11: 0.15,
              1.14: 0.07,
              1.17: 0.04,
              1.2: 0.04,
              1.23: 0.06,
              1.26: 0.08,
              1.29: 0.08,
              1.32: 0.07,
              1.35: 0.08,
              1.38: 0.12,
              1.41: 0.16,
              1.44: 0.17,
              1.47: 0.16,
              1.5: 0.17,
              1.53: 0.18,
              1.56: 0.17,
              1.59: 0.15,
              1.62: 0.13,
              1.65: 0.13,
              1.68: 0.13,
              1.71: 0.12,
              1.74: 0.12,
              1.77: 0.14,
              1.8: 0.18,
              1.83: 0.22,
              1.86: 0.21,
              1.89: 0.17,
              1.92: 0.14,
              1.95: 0.16,
              1.98: 0.2,
              2.01: 0.22,
              2.04: 0.19,
              2.07: 0.13,
              2.1: 0.08,
              2.13: 0.08,
              2.16: 0.14,
              2.19: 0.2,
              2.22: 0.22,
              2.25: 0.18,
              2.28: 0.11,
              2.31: 0.07,
              2.34: 0.05,
              2.37: 0.07,
              2.4: 0.08,
              2.43: 0.07,
              2.46: 0.04,
              2.49: 0.02,
              2.52: 0.01,
              2.55: 0.0,
              2.58: 0.01,
              2.61: 0.04,
              2.64: 0.07,
              2.67: 0.11,
              2.7: 0.12,
              2.73: 0.12,
              2.76: 0.09,
              2.79: 0.06,
              2.82: 0.03,
              2.85: 0.01,
              2.88: 0.0,
              2.91: 0.0,
              2.94: 0.0,
              2.97: 0.0,
              '3.0': 0.0,
              3.03: 0.0,
              3.06: 0.0,
              3.09: 0.0,
              3.12: 0.0,
              3.15: 0.0,
              3.18: 0.0,
              3.21: 0.0,
              3.24: 0.0,
              3.27: 0.0,
              3.3: 0.0,
              3.33: 0.01,
              3.36: 0.03,
              3.39: 0.06,
              3.42: 0.11,
              3.45: 0.14,
              3.48: 0.14,
              3.51: 0.1,
              3.54: 0.06,
              3.57: 0.02,
              3.6: 0.01,
              3.63: 0.0,
              3.66: 0.01,
              3.69: 0.04,
              3.72: 0.1,
              3.75: 0.15,
              3.78: 0.16,
              3.81: 0.12,
              3.84: 0.06,
              3.87: 0.02,
              3.9: 0.01,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.0,
              4.02: 0.0,
              4.05: 0.0,
              4.08: 0.0,
              4.11: 0.0,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.0,
              4.23: 0.0,
              4.26: 0.02,
              4.29: 0.05,
              4.32: 0.12,
              4.35: 0.19,
              4.38: 0.23,
              4.41: 0.23,
              4.44: 0.21,
              4.47: 0.22,
              4.5: 0.24,
              4.53: 0.22,
              4.56: 0.15,
              4.59: 0.07,
              4.62: 0.03,
              4.65: 0.02,
              4.68: 0.03,
              4.71: 0.06,
              4.74: 0.09,
              4.77: 0.12,
              4.8: 0.14,
              4.83: 0.17,
              4.86: 0.19,
              4.89: 0.19,
              4.92: 0.16,
              4.95: 0.1,
              4.98: 0.05,
              5.01: 0.02,
              5.04: 0.02,
              5.07: 0.04,
              5.1: 0.07,
              5.13: 0.08,
              5.16: 0.07,
              5.19: 0.04,
              5.22: 0.03,
              5.25: 0.03,
              5.28: 0.06,
              5.31: 0.08,
              5.34: 0.07,
              5.37: 0.05,
              5.4: 0.02,
              5.43: 0.01,
              5.46: 0.0,
              5.49: 0.0,
              5.52: 0.0,
              5.55: 0.02,
              5.58: 0.04,
              5.61: 0.07,
              5.64: 0.08,
              5.67: 0.07,
              5.7: 0.04,
              5.73: 0.02,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.01,
              5.85: 0.02,
              5.88: 0.05,
              5.91: 0.08,
              5.94: 0.08,
              5.97: 0.06,
              '6.0': 0.05,
              6.03: 0.05,
              6.06: 0.07,
              6.09: 0.08,
              6.12: 0.06,
              6.15: 0.03,
              6.18: 0.01,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.0,
              6.33: 0.0,
              6.36: 0.0,
              6.39: 0.0,
              6.42: 0.0,
              6.45: 0.0,
              6.48: 0.01,
              6.51: 0.02,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.09,
              6.66: 0.1,
              6.69: 0.11,
              6.72: 0.13,
              6.75: 0.14,
              6.78: 0.14,
              6.81: 0.14,
              6.84: 0.12,
              6.87: 0.09,
              6.9: 0.08,
              6.93: 0.08,
              6.96: 0.08,
              6.99: 0.06,
              7.02: 0.03,
              7.05: 0.01,
              7.08: 0.0,
              7.11: 0.0,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.05,
              7.23: 0.08,
              7.26: 0.09,
              7.29: 0.08,
              7.32: 0.08,
              7.35: 0.09,
              7.38: 0.08,
              7.41: 0.05,
              7.44: 0.03,
              7.47: 0.01,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.0,
              7.77: 0.0,
              7.8: 0.0,
              7.83: 0.0,
              7.86: 0.0,
              7.89: 0.0,
              7.92: 0.02,
              7.95: 0.04,
              7.98: 0.07,
              8.01: 0.08,
              8.04: 0.08,
              8.07: 0.07,
              8.1: 0.08,
              8.13: 0.08,
              8.16: 0.07,
              8.19: 0.04,
              8.22: 0.02,
              8.25: 0.01,
              8.28: 0.0,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.04,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.07,
              8.55: 0.04,
              8.58: 0.02,
              8.61: 0.0,
              8.64: 0.0,
              8.67: 0.0,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.0,
              8.82: 0.0,
              8.85: 0.0,
              8.88: 0.0,
              8.91: 0.0,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.0,
              9.12: 0.0,
              9.15: 0.0,
              9.18: 0.01,
              9.21: 0.04,
              9.24: 0.08,
              9.27: 0.12,
              9.3: 0.14,
              9.33: 0.12,
              9.36: 0.09,
              9.39: 0.05,
              9.42: 0.04,
              9.45: 0.05,
              9.48: 0.07,
              9.51: 0.08,
              9.54: 0.06,
              9.57: 0.03,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.0,
              9.96: 0.0,
              9.99: 0.0,
              10.02: 0.0,
              10.05: 0.0,
              10.08: 0.0,
              10.11: 0.0,
              10.14: 0.0,
              10.17: 0.0,
              10.2: 0.0,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.01,
              10.44: 0.03,
              10.47: 0.07,
              10.5: 0.11,
              10.53: 0.13,
              10.56: 0.14,
              10.59: 0.13,
              10.62: 0.12,
              10.65: 0.1,
              10.68: 0.08,
              10.71: 0.05,
              10.74: 0.04,
              10.77: 0.05,
              10.8: 0.07,
              10.83: 0.08,
              10.86: 0.06,
              10.89: 0.03,
              10.92: 0.01,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.01,
              11.1: 0.03,
              11.13: 0.06,
              11.16: 0.08,
              11.19: 0.07,
              11.22: 0.05,
              11.25: 0.02,
              11.28: 0.01,
              11.31: 0.0,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.0,
              11.61: 0.0,
              11.64: 0.0,
              11.67: 0.0,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.0,
              12.12: 0.0,
              12.15: 0.0,
              12.18: 0.0,
              12.21: 0.0,
              12.24: 0.0,
              12.27: 0.0,
              12.3: 0.0,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.0,
              12.81: 0.0,
              12.84: 0.0,
              12.87: 0.0,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.01,
              13.62: 0.03,
              13.65: 0.06,
              13.68: 0.1,
              13.71: 0.13,
              13.74: 0.15,
              13.77: 0.17,
              13.8: 0.18,
              13.83: 0.18,
              13.86: 0.15,
              13.89: 0.1,
              13.92: 0.05,
              13.95: 0.03,
              13.98: 0.05,
              14.01: 0.09,
              14.04: 0.14,
              14.07: 0.15,
              14.1: 0.12,
              14.13: 0.07,
              14.16: 0.04,
              14.19: 0.05,
              14.22: 0.07,
              14.25: 0.08,
              14.28: 0.07,
              14.31: 0.04,
              14.34: 0.04,
              14.37: 0.06,
              14.4: 0.08,
              14.43: 0.08,
              14.46: 0.05,
              14.49: 0.03,
              14.52: 0.02,
              14.55: 0.03,
              14.58: 0.06,
              14.61: 0.08,
              14.64: 0.07,
              14.67: 0.05,
              14.7: 0.02,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.01,
              15.24: 0.02,
              15.27: 0.04,
              15.3: 0.07,
              15.33: 0.08,
              15.36: 0.06,
              15.39: 0.04,
              15.42: 0.01,
              15.45: 0.0,
              15.48: 0.0,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.0,
              15.63: 0.0,
              15.66: 0.0,
              15.69: 0.0,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.0,
              16.2: 0.0,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.01,
              16.59: 0.03,
              16.62: 0.06,
              16.65: 0.08,
              16.68: 0.07,
              16.71: 0.04,
              16.74: 0.02,
              16.77: 0.01,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.02,
              16.95: 0.05,
              16.98: 0.09,
              17.01: 0.13,
              17.04: 0.14,
              17.07: 0.12,
              17.1: 0.07,
              17.13: 0.03,
              17.16: 0.01,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.0,
              17.37: 0.0,
              17.4: 0.0,
              17.43: 0.0,
              17.46: 0.0,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.01,
              17.97: 0.02,
              '18.0': 0.06,
              18.03: 0.11,
              18.06: 0.15,
              18.09: 0.15,
              18.12: 0.1,
              18.15: 0.05,
              18.18: 0.02,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.0,
              18.57: 0.0,
              18.6: 0.0,
              18.63: 0.0,
              18.66: 0.0,
              18.69: 0.0,
              18.72: 0.0,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.01,
              19.14: 0.04,
              19.17: 0.06,
              19.2: 0.08,
              19.23: 0.07,
              19.26: 0.04,
              19.29: 0.02,
              19.32: 0.01,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.01,
              20.13: 0.02,
              20.16: 0.05,
              20.19: 0.07,
              20.22: 0.08,
              20.25: 0.06,
              20.28: 0.03,
              20.31: 0.01,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.01,
              21.96: 0.04,
              21.99: 0.06,
              22.02: 0.08,
              22.05: 0.07,
              22.08: 0.04,
              22.11: 0.02,
              22.14: 0.01,
              22.17: 0.03,
              22.2: 0.05,
              22.23: 0.08,
              22.26: 0.08,
              22.29: 0.05,
              22.32: 0.03,
              22.35: 0.01,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.01,
              22.74: 0.03,
              22.77: 0.06,
              22.8: 0.08,
              22.83: 0.07,
              22.86: 0.05,
              22.89: 0.02,
              22.92: 0.01,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.0,
              24.09: 0.0,
              24.12: 0.0,
              24.15: 0.0,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.01,
              24.54: 0.02,
              24.57: 0.04,
              24.6: 0.07,
              24.63: 0.08,
              24.66: 0.07,
              24.69: 0.05,
              24.72: 0.05,
              24.75: 0.07,
              24.78: 0.08,
              24.81: 0.07,
              24.84: 0.04,
              24.87: 0.01,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.01,
              25.29: 0.03,
              25.32: 0.06,
              25.35: 0.08,
              25.38: 0.07,
              25.41: 0.05,
              25.44: 0.02,
              25.47: 0.01,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.01,
              26.37: 0.03,
              26.4: 0.06,
              26.43: 0.08,
              26.46: 0.07,
              26.49: 0.04,
              26.52: 0.02,
              26.55: 0.01,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.02,
              27.63: 0.04,
              27.66: 0.07,
              27.69: 0.08,
              27.72: 0.06,
              27.75: 0.04,
              27.78: 0.01,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.01,
              30.75: 0.04,
              30.78: 0.06,
              30.81: 0.08,
              30.84: 0.07,
              30.87: 0.04,
              30.9: 0.02,
              30.93: 0.01,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.01,
              36.3: 0.03,
              36.33: 0.06,
              36.36: 0.08,
              36.39: 0.07,
              36.42: 0.05,
              36.45: 0.02,
              36.48: 0.01,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.0,
              40.74: 0.0,
              40.77: 0.0,
              40.8: 0.0,
              40.83: 0.0,
              40.86: 0.0,
              40.89: 0.0,
              40.92: 0.0,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.0,
              41.58: 0.0,
              41.61: 0.0,
              41.64: 0.0,
              41.67: 0.0,
              41.7: 0.0,
              41.73: 0.0,
              41.76: 0.0,
              41.79: 0.0,
              41.82: 0.0,
              41.85: 0.0,
              41.88: 0.0,
              41.91: 0.0,
              41.94: 0.0,
              41.97: 0.0,
              '42.0': 0.0,
              42.03: 0.0,
              42.06: 0.0,
              42.09: 0.0,
              42.12: 0.0,
              42.15: 0.0,
              42.18: 0.0,
              42.21: 0.0,
              42.24: 0.0,
              42.27: 0.0,
              42.3: 0.0,
              42.33: 0.0,
              42.36: 0.0,
              42.39: 0.0,
              42.42: 0.0,
              42.45: 0.0,
              42.48: 0.0,
              42.51: 0.0,
              42.54: 0.0,
              42.57: 0.0,
              42.6: 0.0,
              42.63: 0.0,
              42.66: 0.0,
              42.69: 0.0,
              42.72: 0.0,
              42.75: 0.0,
              42.78: 0.0,
              42.81: 0.0,
              42.84: 0.0,
              42.87: 0.0,
              42.9: 0.0,
              42.93: 0.0,
              42.96: 0.0,
              42.99: 0.0,
              43.02: 0.0,
              43.05: 0.0,
              43.08: 0.0,
              43.11: 0.0,
              43.14: 0.0,
              43.17: 0.0,
              43.2: 0.0,
              43.23: 0.0,
              43.26: 0.0,
              43.29: 0.0,
              43.32: 0.0,
              43.35: 0.0,
              43.38: 0.0,
              43.41: 0.0,
              43.44: 0.0,
              43.47: 0.0,
              43.5: 0.0,
              43.53: 0.0,
              43.56: 0.0,
              43.59: 0.0,
              43.62: 0.0,
              43.65: 0.0,
              43.68: 0.0,
              43.71: 0.0,
              43.74: 0.0,
              43.77: 0.0,
              43.8: 0.0,
              43.83: 0.0,
              43.86: 0.0,
              43.89: 0.0,
              43.92: 0.0,
              43.95: 0.0,
              43.98: 0.0,
              44.01: 0.0,
              44.04: 0.0,
              44.07: 0.0,
              44.1: 0.0,
              44.13: 0.0,
              44.16: 0.0,
              44.19: 0.0,
              44.22: 0.0,
              44.25: 0.0,
              44.28: 0.0,
              44.31: 0.0,
              44.34: 0.0,
              44.37: 0.0,
              44.4: 0.0,
              44.43: 0.0,
              44.46: 0.0,
              44.49: 0.0,
              44.52: 0.0,
              44.55: 0.0,
              44.58: 0.0,
              44.61: 0.0,
              44.64: 0.0,
              44.67: 0.0,
              44.7: 0.0,
              44.73: 0.0,
              44.76: 0.0,
              44.79: 0.0,
              44.82: 0.0,
              44.85: 0.0,
              44.88: 0.0,
              44.91: 0.0,
              44.94: 0.0,
              44.97: 0.0,
              '45.0': 0.0,
              45.03: 0.0,
              45.06: 0.0,
              45.09: 0.0,
              45.12: 0.0,
              45.15: 0.0,
              45.18: 0.0,
              45.21: 0.0,
              45.24: 0.0,
              45.27: 0.0,
              45.3: 0.0,
              45.33: 0.0,
              45.36: 0.0,
              45.39: 0.0,
              45.42: 0.0,
              45.45: 0.0,
              45.48: 0.0,
              45.51: 0.0,
              45.54: 0.0,
              45.57: 0.0,
              45.6: 0.0,
              45.63: 0.0,
              45.66: 0.0,
              45.69: 0.0,
              45.72: 0.0,
              45.75: 0.0,
              45.78: 0.0,
              45.81: 0.0,
              45.84: 0.0,
              45.87: 0.0,
              45.9: 0.0,
              45.93: 0.0,
              45.96: 0.0,
              45.99: 0.0,
              46.02: 0.0,
              46.05: 0.0,
              46.08: 0.0,
              46.11: 0.0,
              46.14: 0.0,
              46.17: 0.0,
              46.2: 0.0,
              46.23: 0.0,
              46.26: 0.0,
              46.29: 0.0,
              46.32: 0.0,
              46.35: 0.0,
              46.38: 0.0,
              46.41: 0.0,
              46.44: 0.0,
              46.47: 0.0,
              46.5: 0.0,
              46.53: 0.0,
              46.56: 0.0,
              46.59: 0.0,
              46.62: 0.0,
              46.65: 0.0,
              46.68: 0.0,
              46.71: 0.0,
              46.74: 0.0,
              46.77: 0.0,
              46.8: 0.0,
              46.83: 0.0,
              46.86: 0.0,
              46.89: 0.0,
              46.92: 0.0,
              46.95: 0.0,
              46.98: 0.0,
              47.01: 0.0,
              47.04: 0.0,
              47.07: 0.0,
              47.1: 0.0,
              47.13: 0.0,
              47.16: 0.0,
              47.19: 0.0,
              47.22: 0.0,
              47.25: 0.0,
              47.28: 0.0,
              47.31: 0.0,
              47.34: 0.0,
              47.37: 0.0,
              47.4: 0.0,
              47.43: 0.0,
              47.46: 0.0,
              47.49: 0.0,
              47.52: 0.0,
              47.55: 0.0,
              47.58: 0.0,
              47.61: 0.0,
              47.64: 0.0,
              47.67: 0.0,
              47.7: 0.0,
              47.73: 0.0,
              47.76: 0.0,
              47.79: 0.0,
              47.82: 0.0,
              47.85: 0.0,
              47.88: 0.0,
              47.91: 0.0,
              47.94: 0.0,
              47.97: 0.0,
              '48.0': 0.0,
              48.03: 0.0,
              48.06: 0.0,
              48.09: 0.0,
              48.12: 0.0,
              48.15: 0.0,
              48.18: 0.0,
              48.21: 0.0,
              48.24: 0.0,
              48.27: 0.0,
              48.3: 0.0,
              48.33: 0.0,
              48.36: 0.0,
              48.39: 0.0,
              48.42: 0.0,
              48.45: 0.0,
              48.48: 0.0,
              48.51: 0.0,
              48.54: 0.0,
              48.57: 0.0,
              48.6: 0.0,
              48.63: 0.0,
              48.66: 0.0,
              48.69: 0.0,
              48.72: 0.0,
              48.75: 0.0,
              48.78: 0.0,
              48.81: 0.0,
              48.84: 0.0,
              48.87: 0.0,
              48.9: 0.0,
              48.93: 0.0,
              48.96: 0.0,
              48.99: 0.0,
              49.02: 0.0,
              49.05: 0.0,
              49.08: 0.0,
              49.11: 0.0,
              49.14: 0.0,
              49.17: 0.0,
              49.2: 0.0,
              49.23: 0.0,
              49.26: 0.0,
              49.29: 0.0,
              49.32: 0.0,
              49.35: 0.0,
              49.38: 0.0,
              49.41: 0.0,
              49.44: 0.0,
              49.47: 0.0,
              49.5: 0.0,
              49.53: 0.0,
              49.56: 0.0,
              49.59: 0.0,
              49.62: 0.0,
              49.65: 0.0,
              49.68: 0.0,
              49.71: 0.0,
              49.74: 0.0,
              49.77: 0.0,
              49.8: 0.0,
              49.83: 0.0,
              49.86: 0.0,
              49.89: 0.0,
              49.92: 0.0,
              49.95: 0.0,
              49.98: 0.0,
              50.01: 0.0,
              50.04: 0.0,
              50.07: 0.0,
              50.1: 0.0,
              50.13: 0.0,
              50.16: 0.0,
              50.19: 0.0,
              50.22: 0.01,
              50.25: 0.03,
              50.28: 0.06,
              50.31: 0.08,
              50.34: 0.07,
              50.37: 0.05,
              50.4: 0.02,
              50.43: 0.01,
              50.46: 0.0,
              50.49: 0.0,
              50.52: 0.0,
              50.55: 0.0,
              50.58: 0.0,
              50.61: 0.0,
              50.64: 0.0,
              50.67: 0.0,
              50.7: 0.0,
              50.73: 0.0,
              50.76: 0.0,
              50.79: 0.0,
              50.82: 0.0,
              50.85: 0.0,
              50.88: 0.0,
              50.91: 0.0,
              50.94: 0.0,
              50.97: 0.0,
              '51.0': 0.0,
              51.03: 0.0,
              51.06: 0.0,
              51.09: 0.0,
              51.12: 0.0,
              51.15: 0.0,
              51.18: 0.0,
              51.21: 0.0,
              51.24: 0.0,
              51.27: 0.0,
              51.3: 0.0,
              51.33: 0.0,
              51.36: 0.0,
              51.39: 0.0,
              51.42: 0.0,
              51.45: 0.0,
              51.48: 0.0,
              51.51: 0.0,
              51.54: 0.0,
              51.57: 0.0,
              51.6: 0.0,
              51.63: 0.0,
              51.66: 0.0,
              51.69: 0.0,
              51.72: 0.0,
              51.75: 0.0,
              51.78: 0.0,
              51.81: 0.0,
              51.84: 0.0,
              51.87: 0.0,
              51.9: 0.0,
              51.93: 0.0,
              51.96: 0.0,
              51.99: 0.0,
              52.02: 0.0,
              52.05: 0.0,
              52.08: 0.0,
              52.11: 0.0,
              52.14: 0.0,
              52.17: 0.0,
              52.2: 0.0,
              52.23: 0.0,
              52.26: 0.0,
              52.29: 0.0,
              52.32: 0.0,
              52.35: 0.0,
              52.38: 0.0,
              52.41: 0.0,
              52.44: 0.0,
              52.47: 0.0,
              52.5: 0.0,
              52.53: 0.0,
              52.56: 0.0,
              52.59: 0.0,
              52.62: 0.0,
              52.65: 0.0,
              52.68: 0.0,
              52.71: 0.0,
              52.74: 0.0,
              52.77: 0.0,
              52.8: 0.0,
              52.83: 0.0,
              52.86: 0.0,
              52.89: 0.0,
              52.92: 0.0,
              52.95: 0.0,
              52.98: 0.0,
              53.01: 0.0,
              53.04: 0.0,
              53.07: 0.0,
              53.1: 0.0,
              53.13: 0.0,
              53.16: 0.0,
              53.19: 0.0,
              53.22: 0.0,
              53.25: 0.0,
              53.28: 0.0,
              53.31: 0.0,
              53.34: 0.0,
              53.37: 0.0,
              53.4: 0.0,
              53.43: 0.0,
              53.46: 0.0,
              53.49: 0.0,
              53.52: 0.0,
              53.55: 0.0,
              53.58: 0.0,
              53.61: 0.0,
              53.64: 0.0,
              53.67: 0.0,
              53.7: 0.0,
              53.73: 0.0,
              53.76: 0.0,
              53.79: 0.0,
              53.82: 0.0,
              53.85: 0.0,
              53.88: 0.0,
              53.91: 0.0,
              53.94: 0.0,
              53.97: 0.0,
              '54.0': 0.0,
              54.03: 0.0,
              54.06: 0.0,
              54.09: 0.0,
              54.12: 0.0,
              54.15: 0.0,
              54.18: 0.0,
              54.21: 0.0,
              54.24: 0.0,
              54.27: 0.0,
              54.3: 0.0,
              54.33: 0.0,
              54.36: 0.0,
              54.39: 0.0,
              54.42: 0.0,
              54.45: 0.0,
              54.48: 0.0,
              54.51: 0.0,
              54.54: 0.0,
              54.57: 0.0,
              54.6: 0.0,
              54.63: 0.0,
              54.66: 0.0,
              54.69: 0.0,
              54.72: 0.0,
              54.75: 0.0,
              54.78: 0.0,
              54.81: 0.0,
              54.84: 0.0,
              54.87: 0.0,
              54.9: 0.0,
              54.93: 0.0,
              54.96: 0.0,
              54.99: 0.0,
              55.02: 0.0,
              55.05: 0.0,
              55.08: 0.0,
              55.11: 0.0,
              55.14: 0.0,
              55.17: 0.0,
              55.2: 0.0,
              55.23: 0.0,
              55.26: 0.0,
              55.29: 0.0,
              55.32: 0.0,
              55.35: 0.0,
              55.38: 0.0,
              55.41: 0.0,
              55.44: 0.0,
              55.47: 0.0,
              55.5: 0.0,
              55.53: 0.0,
              55.56: 0.0,
              55.59: 0.0,
              55.62: 0.0,
              55.65: 0.0,
              55.68: 0.0,
              55.71: 0.0,
              55.74: 0.0,
              55.77: 0.0,
              55.8: 0.0,
              55.83: 0.0,
              55.86: 0.0,
              55.89: 0.0,
              55.92: 0.0,
              55.95: 0.0,
              55.98: 0.0,
              56.01: 0.0,
              56.04: 0.0,
              56.07: 0.0,
              56.1: 0.0,
              56.13: 0.0,
              56.16: 0.0,
              56.19: 0.0,
              56.22: 0.0,
              56.25: 0.0,
              56.28: 0.0,
              56.31: 0.0,
              56.34: 0.0,
              56.37: 0.0,
              56.4: 0.0,
              56.43: 0.0,
              56.46: 0.0,
              56.49: 0.0,
              56.52: 0.0,
              56.55: 0.0,
              56.58: 0.0,
              56.61: 0.0,
              56.64: 0.0,
              56.67: 0.0,
              56.7: 0.0,
              56.73: 0.0,
              56.76: 0.0,
              56.79: 0.0,
              56.82: 0.0,
              56.85: 0.0,
              56.88: 0.0,
              56.91: 0.0,
              56.94: 0.0,
              56.97: 0.0,
              '57.0': 0.0,
              57.03: 0.0,
              57.06: 0.0,
              57.09: 0.0,
              57.12: 0.0,
              57.15: 0.0,
              57.18: 0.0,
              57.21: 0.0,
              57.24: 0.0,
              57.27: 0.0,
              57.3: 0.0,
              57.33: 0.0,
              57.36: 0.0,
              57.39: 0.0,
              57.42: 0.0,
              57.45: 0.0,
              57.48: 0.0,
              57.51: 0.0,
              57.54: 0.0,
              57.57: 0.0,
              57.6: 0.0,
              57.63: 0.0,
              57.66: 0.0,
              57.69: 0.0,
              57.72: 0.0,
              57.75: 0.0,
              57.78: 0.0,
              57.81: 0.0,
              57.84: 0.0,
              57.87: 0.0,
              57.9: 0.0,
              57.93: 0.0,
              57.96: 0.0,
              57.99: 0.0,
              58.02: 0.0,
              58.05: 0.0,
              58.08: 0.0,
              58.11: 0.0,
              58.14: 0.0,
              58.17: 0.0,
              58.2: 0.0,
              58.23: 0.0,
              58.26: 0.0,
              58.29: 0.0,
              58.32: 0.0,
              58.35: 0.0,
              58.38: 0.0,
              58.41: 0.0,
              58.44: 0.0,
              58.47: 0.0,
              58.5: 0.0,
              58.53: 0.0,
              58.56: 0.0,
              58.59: 0.0,
              58.62: 0.0,
              58.65: 0.0,
              58.68: 0.0,
              58.71: 0.0,
              58.74: 0.0,
              58.77: 0.0,
              58.8: 0.0,
              58.83: 0.0,
              58.86: 0.0,
              58.89: 0.0,
              58.92: 0.0,
              58.95: 0.0,
              58.98: 0.0,
              59.01: 0.0,
              59.04: 0.0,
              59.07: 0.0,
              59.1: 0.0,
              59.13: 0.0,
              59.16: 0.0,
              59.19: 0.01,
              59.22: 0.04,
              59.25: 0.07,
              59.28: 0.08,
              59.31: 0.07,
              59.34: 0.04,
              59.37: 0.02,
              59.4: 0.0,
              59.43: 0.0,
              59.46: 0.0,
              59.49: 0.0,
              59.52: 0.0,
              59.55: 0.0,
              59.58: 0.0,
              59.61: 0.0,
              59.64: 0.0,
              59.67: 0.0,
              59.7: 0.0,
              59.73: 0.0,
              59.76: 0.0,
              59.79: 0.0,
              59.82: 0.0,
              59.85: 0.0,
              59.88: 0.0,
              59.91: 0.0,
              59.94: 0.0,
              59.97: 0.0,
              '60.0': 0.0,
              60.03: 0.0,
              60.06: 0.0,
              60.09: 0.0,
              60.12: 0.0,
              60.15: 0.0,
              60.18: 0.0,
              60.21: 0.0,
              60.24: 0.0,
              60.27: 0.0,
              60.3: 0.0,
              60.33: 0.0,
              60.36: 0.0,
              60.39: 0.0,
              60.42: 0.0,
              60.45: 0.0,
              60.48: 0.0,
              60.51: 0.0,
              60.54: 0.0,
              60.57: 0.0,
              60.6: 0.0,
              60.63: 0.0,
              60.66: 0.0,
              60.69: 0.0,
              60.72: 0.0,
              60.75: 0.0,
              60.78: 0.0,
              60.81: 0.0,
              60.84: 0.0,
              60.87: 0.0,
              60.9: 0.0,
              60.93: 0.0,
              60.96: 0.0,
              60.99: 0.0,
              61.02: 0.0,
              61.05: 0.0,
              61.08: 0.0,
              61.11: 0.0,
              61.14: 0.0,
              61.17: 0.0,
              61.2: 0.0,
              61.23: 0.0,
              61.26: 0.0,
              61.29: 0.0,
              61.32: 0.0,
              61.35: 0.0,
              61.38: 0.0,
              61.41: 0.0,
              61.44: 0.0,
              61.47: 0.0,
              61.5: 0.0,
              61.53: 0.0,
              61.56: 0.0,
              61.59: 0.0,
              61.62: 0.0,
              61.65: 0.0,
              61.68: 0.0,
              61.71: 0.0,
              61.74: 0.0,
              61.77: 0.0,
              61.8: 0.0,
              61.83: 0.0,
              61.86: 0.0,
              61.89: 0.0,
              61.92: 0.0,
              61.95: 0.0,
              61.98: 0.0,
              62.01: 0.0,
              62.04: 0.0,
              62.07: 0.0,
              62.1: 0.0,
              62.13: 0.0,
              62.16: 0.0,
              62.19: 0.0,
              62.22: 0.0,
              62.25: 0.0,
              62.28: 0.0,
              62.31: 0.0,
              62.34: 0.0,
              62.37: 0.0,
              62.4: 0.0,
              62.43: 0.0,
              62.46: 0.0,
              62.49: 0.0,
              62.52: 0.0,
              62.55: 0.0,
              62.58: 0.0,
              62.61: 0.0,
              62.64: 0.0,
              62.67: 0.0,
              62.7: 0.0,
              62.73: 0.0,
              62.76: 0.0,
              62.79: 0.0,
              62.82: 0.0,
              62.85: 0.0,
              62.88: 0.0,
              62.91: 0.0,
              62.94: 0.0,
              62.97: 0.0,
              '63.0': 0.0,
              63.03: 0.0,
              63.06: 0.0,
              63.09: 0.0,
              63.12: 0.0,
              63.15: 0.0,
              63.18: 0.0,
              63.21: 0.0,
              63.24: 0.0,
              63.27: 0.0,
              63.3: 0.0,
              63.33: 0.0,
              63.36: 0.0,
              63.39: 0.0,
              63.42: 0.0,
              63.45: 0.0,
              63.48: 0.0,
              63.51: 0.0,
              63.54: 0.0,
              63.57: 0.0,
              63.6: 0.0,
              63.63: 0.0,
              63.66: 0.0,
              63.69: 0.0,
              63.72: 0.0,
              63.75: 0.0,
              63.78: 0.0,
              63.81: 0.0,
              63.84: 0.0,
              63.87: 0.0,
              63.9: 0.0,
              63.93: 0.0,
              63.96: 0.0,
              63.99: 0.0,
              64.02: 0.0,
              64.05: 0.0,
              64.08: 0.0,
              64.11: 0.0,
              64.14: 0.0,
              64.17: 0.0,
              64.2: 0.0,
              64.23: 0.0,
              64.26: 0.0,
              64.29: 0.0,
              64.32: 0.0,
              64.35: 0.0,
              64.38: 0.0,
              64.41: 0.0,
              64.44: 0.0,
              64.47: 0.0,
              64.5: 0.0,
              64.53: 0.0,
              64.56: 0.0,
              64.59: 0.0,
              64.62: 0.0,
              64.65: 0.0,
              64.68: 0.0,
              64.71: 0.0,
              64.74: 0.0,
              64.77: 0.0,
              64.8: 0.0,
              64.83: 0.0,
              64.86: 0.0,
              64.89: 0.0,
              64.92: 0.0,
              64.95: 0.0,
              64.98: 0.0,
              65.01: 0.0,
              65.04: 0.0,
              65.07: 0.0,
              65.1: 0.0,
              65.13: 0.0,
              65.16: 0.0,
              65.19: 0.0,
              65.22: 0.0,
              65.25: 0.0,
              65.28: 0.0,
              65.31: 0.0,
              65.34: 0.0,
              65.37: 0.0,
              65.4: 0.0,
              65.43: 0.0,
              65.46: 0.0,
              65.49: 0.0,
              65.52: 0.0,
              65.55: 0.0,
              65.58: 0.0,
              65.61: 0.0,
              65.64: 0.0,
              65.67: 0.0,
              65.7: 0.0,
              65.73: 0.0,
              65.76: 0.0,
              65.79: 0.0,
              65.82: 0.0,
              65.85: 0.0,
              65.88: 0.0,
              65.91: 0.0,
              65.94: 0.0,
              65.97: 0.0,
              '66.0': 0.0,
              66.03: 0.0,
              66.06: 0.0,
              66.09: 0.0,
              66.12: 0.0,
              66.15: 0.0,
              66.18: 0.0,
              66.21: 0.0,
              66.24: 0.0,
              66.27: 0.0,
              66.3: 0.0,
              66.33: 0.0,
              66.36: 0.0,
              66.39: 0.0,
              66.42: 0.0,
              66.45: 0.0,
              66.48: 0.0,
              66.51: 0.0,
              66.54: 0.0,
              66.57: 0.0,
              66.6: 0.0,
              66.63: 0.0,
              66.66: 0.0,
              66.69: 0.0,
              66.72: 0.0,
              66.75: 0.0,
              66.78: 0.0,
              66.81: 0.0,
              66.84: 0.0,
              66.87: 0.0,
              66.9: 0.0,
              66.93: 0.0,
              66.96: 0.0,
              66.99: 0.0,
              67.02: 0.0,
              67.05: 0.0,
              67.08: 0.0,
              67.11: 0.0,
              67.14: 0.0,
              67.17: 0.0,
              67.2: 0.0,
              67.23: 0.0,
              67.26: 0.0,
              67.29: 0.0,
              67.32: 0.0,
              67.35: 0.0,
              67.38: 0.0,
              67.41: 0.0,
              67.44: 0.0,
              67.47: 0.0,
              67.5: 0.0,
              67.53: 0.0,
              67.56: 0.0,
              67.59: 0.0,
              67.62: 0.0,
              67.65: 0.0,
              67.68: 0.0,
              67.71: 0.0,
              67.74: 0.0,
              67.77: 0.0,
              67.8: 0.0,
              67.83: 0.0,
              67.86: 0.0,
              67.89: 0.0,
              67.92: 0.0,
              67.95: 0.0,
              67.98: 0.0,
              68.01: 0.0,
              68.04: 0.0,
              68.07: 0.0,
              68.1: 0.0,
              68.13: 0.0,
              68.16: 0.0,
              68.19: 0.0,
              68.22: 0.0,
              68.25: 0.0,
              68.28: 0.0,
              68.31: 0.0,
              68.34: 0.0,
              68.37: 0.0,
              68.4: 0.0,
              68.43: 0.0,
              68.46: 0.0,
              68.49: 0.0,
              68.52: 0.0,
              68.55: 0.0,
              68.58: 0.0,
              68.61: 0.0,
              68.64: 0.0,
              68.67: 0.0,
              68.7: 0.0,
              68.73: 0.0,
              68.76: 0.0,
              68.79: 0.0,
              68.82: 0.0,
              68.85: 0.0,
              68.88: 0.0,
              68.91: 0.0,
              68.94: 0.0,
              68.97: 0.0,
              '69.0': 0.0,
              69.03: 0.0,
              69.06: 0.0,
              69.09: 0.0,
              69.12: 0.0,
              69.15: 0.0,
              69.18: 0.0,
              69.21: 0.0,
              69.24: 0.0,
              69.27: 0.0,
              69.3: 0.0,
              69.33: 0.0,
              69.36: 0.0,
              69.39: 0.0,
              69.42: 0.0,
              69.45: 0.0,
              69.48: 0.0,
              69.51: 0.0,
              69.54: 0.0,
              69.57: 0.0,
              69.6: 0.0,
              69.63: 0.0,
              69.66: 0.0,
              69.69: 0.0,
              69.72: 0.0,
              69.75: 0.0,
              69.78: 0.0,
              69.81: 0.0,
              69.84: 0.0,
              69.87: 0.0,
              69.9: 0.0,
              69.93: 0.0,
              69.96: 0.0,
              69.99: 0.0,
              70.02: 0.0,
              70.05: 0.0,
              70.08: 0.0,
              70.11: 0.0,
              70.14: 0.0,
              70.17: 0.0,
              70.2: 0.0,
              70.23: 0.0,
              70.26: 0.0,
              70.29: 0.0,
              70.32: 0.0,
              70.35: 0.0,
              70.38: 0.0,
              70.41: 0.0,
              70.44: 0.0,
              70.47: 0.0,
              70.5: 0.0,
              70.53: 0.0,
              70.56: 0.0,
              70.59: 0.0,
              70.62: 0.0,
              70.65: 0.0,
              70.68: 0.0,
              70.71: 0.0,
              70.74: 0.0,
              70.77: 0.0,
              70.8: 0.0,
              70.83: 0.0,
              70.86: 0.0,
              70.89: 0.0,
              70.92: 0.0,
              70.95: 0.0,
              70.98: 0.0,
              71.01: 0.0,
              71.04: 0.0,
              71.07: 0.0,
              71.1: 0.0,
              71.13: 0.0,
              71.16: 0.0,
              71.19: 0.0,
              71.22: 0.0,
              71.25: 0.0,
              71.28: 0.0,
              71.31: 0.0,
              71.34: 0.0,
              71.37: 0.0,
              71.4: 0.0,
              71.43: 0.0,
              71.46: 0.0,
              71.49: 0.0,
              71.52: 0.0,
              71.55: 0.0,
              71.58: 0.0,
              71.61: 0.0,
              71.64: 0.0,
              71.67: 0.0,
              71.7: 0.0,
              71.73: 0.0,
              71.76: 0.0,
              71.79: 0.0,
              71.82: 0.0,
              71.85: 0.0,
              71.88: 0.0,
              71.91: 0.0,
              71.94: 0.0,
              71.97: 0.0,
              '72.0': 0.0,
              72.03: 0.0,
              72.06: 0.0,
              72.09: 0.0,
              72.12: 0.0,
              72.15: 0.0,
              72.18: 0.0,
              72.21: 0.0,
              72.24: 0.0,
              72.27: 0.0,
              72.3: 0.0,
              72.33: 0.0,
              72.36: 0.0,
              72.39: 0.0,
              72.42: 0.0,
              72.45: 0.0,
              72.48: 0.0,
              72.51: 0.0,
              72.54: 0.0,
              72.57: 0.0,
              72.6: 0.0,
              72.63: 0.0,
              72.66: 0.0,
              72.69: 0.0,
              72.72: 0.0,
              72.75: 0.0,
              72.78: 0.0,
              72.81: 0.0,
              72.84: 0.0,
              72.87: 0.0,
              72.9: 0.0,
              72.93: 0.0,
              72.96: 0.0,
              72.99: 0.0,
              73.02: 0.0,
              73.05: 0.0,
              73.08: 0.01,
              73.11: 0.02,
              73.14: 0.05,
              73.17: 0.07,
              73.2: 0.08,
              73.23: 0.06,
              73.26: 0.03,
              73.29: 0.01,
              73.32: 0.0,
              73.35: 0.0,
              73.38: 0.0,
              73.41: 0.0,
              73.44: 0.0,
              73.47: 0.0,
              73.5: 0.0,
              73.53: 0.0,
              73.56: 0.0,
              73.59: 0.0,
              73.62: 0.0,
              73.65: 0.0,
              73.68: 0.0,
              73.71: 0.0,
              73.74: 0.0,
              73.77: 0.0,
              73.8: 0.0,
              73.83: 0.0,
              73.86: 0.0,
              73.89: 0.0,
              73.92: 0.0,
              73.95: 0.0,
              73.98: 0.0,
            },
            iteration_1: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.12,
              0.18: 0.1,
              0.21: 0.1,
              0.24: 0.11,
              0.27: 0.13,
              0.3: 0.15,
              0.33: 0.17,
              0.36: 0.18,
              0.39: 0.18,
              0.42: 0.19,
              0.45: 0.22,
              0.48: 0.25,
              0.51: 0.25,
              0.54: 0.22,
              0.57: 0.18,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.2,
              1.14: 0.17,
              1.17: 0.13,
              1.2: 0.1,
              1.23: 0.09,
              1.26: 0.13,
              1.29: 0.17,
              1.32: 0.19,
              1.35: 0.17,
              1.38: 0.13,
              1.41: 0.08,
              1.44: 0.05,
              1.47: 0.03,
              1.5: 0.04,
              1.53: 0.06,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.05,
              1.83: 0.06,
              1.86: 0.08,
              1.89: 0.09,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.1,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.15,
              2.28: 0.11,
              2.31: 0.06,
              2.34: 0.02,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.07,
              2.49: 0.08,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.18,
              2.73: 0.17,
              2.76: 0.16,
              2.79: 0.16,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.04,
              3.15: 0.07,
              3.18: 0.08,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.0,
              3.36: 0.0,
              3.39: 0.0,
              3.42: 0.0,
              3.45: 0.0,
              3.48: 0.0,
              3.51: 0.0,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.0,
              3.87: 0.0,
              3.9: 0.0,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.08,
              4.11: 0.1,
              4.14: 0.1,
              4.17: 0.1,
              4.2: 0.09,
              4.23: 0.08,
              4.26: 0.07,
              4.29: 0.09,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.05,
              4.47: 0.02,
              4.5: 0.01,
              4.53: 0.0,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.04,
              4.65: 0.06,
              4.68: 0.08,
              4.71: 0.07,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.17,
              4.95: 0.13,
              4.98: 0.07,
              5.01: 0.03,
              5.04: 0.01,
              5.07: 0.01,
              5.1: 0.03,
              5.13: 0.06,
              5.16: 0.08,
              5.19: 0.07,
              5.22: 0.05,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.12,
              5.4: 0.16,
              5.43: 0.15,
              5.46: 0.11,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.12,
              5.7: 0.15,
              5.73: 0.14,
              5.76: 0.09,
              5.79: 0.05,
              5.82: 0.03,
              5.85: 0.05,
              5.88: 0.07,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.04,
              6.21: 0.07,
              6.24: 0.1,
              6.27: 0.12,
              6.3: 0.12,
              6.33: 0.1,
              6.36: 0.09,
              6.39: 0.08,
              6.42: 0.09,
              6.45: 0.08,
              6.48: 0.05,
              6.51: 0.03,
              6.54: 0.02,
              6.57: 0.04,
              6.6: 0.08,
              6.63: 0.14,
              6.66: 0.16,
              6.69: 0.13,
              6.72: 0.07,
              6.75: 0.03,
              6.78: 0.01,
              6.81: 0.0,
              6.84: 0.0,
              6.87: 0.0,
              6.9: 0.01,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.08,
              7.02: 0.07,
              7.05: 0.05,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.04,
              7.23: 0.07,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.07,
              7.89: 0.04,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.08,
              8.22: 0.07,
              8.25: 0.04,
              8.28: 0.02,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.03,
              8.46: 0.07,
              8.49: 0.11,
              8.52: 0.13,
              8.55: 0.13,
              8.58: 0.1,
              8.61: 0.06,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.07,
              8.91: 0.08,
              8.94: 0.06,
              8.97: 0.03,
              '9.0': 0.02,
              9.03: 0.03,
              9.06: 0.05,
              9.09: 0.07,
              9.12: 0.08,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.02,
              9.24: 0.03,
              9.27: 0.05,
              9.3: 0.08,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.06,
              9.42: 0.07,
              9.45: 0.08,
              9.48: 0.07,
              9.51: 0.05,
              9.54: 0.02,
              9.57: 0.01,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.07,
              10.02: 0.12,
              10.05: 0.14,
              10.08: 0.14,
              10.11: 0.12,
              10.14: 0.1,
              10.17: 0.09,
              10.2: 0.08,
              10.23: 0.06,
              10.26: 0.03,
              10.29: 0.01,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.0,
              10.47: 0.0,
              10.5: 0.0,
              10.53: 0.0,
              10.56: 0.0,
              10.59: 0.0,
              10.62: 0.0,
              10.65: 0.0,
              10.68: 0.0,
              10.71: 0.0,
              10.74: 0.0,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.0,
              11.1: 0.01,
              11.13: 0.02,
              11.16: 0.04,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.05,
              11.67: 0.1,
              11.7: 0.15,
              11.73: 0.2,
              11.76: 0.24,
              11.79: 0.24,
              11.82: 0.19,
              11.85: 0.14,
              11.88: 0.11,
              11.91: 0.09,
              11.94: 0.06,
              11.97: 0.03,
              '12.0': 0.01,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.07,
              12.21: 0.08,
              12.24: 0.06,
              12.27: 0.04,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.06,
              12.87: 0.08,
              12.9: 0.07,
              12.93: 0.05,
              12.96: 0.05,
              12.99: 0.06,
              13.02: 0.08,
              13.05: 0.08,
              13.08: 0.05,
              13.11: 0.03,
              13.14: 0.03,
              13.17: 0.05,
              13.2: 0.07,
              13.23: 0.08,
              13.26: 0.06,
              13.29: 0.03,
              13.32: 0.01,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.0,
              13.62: 0.0,
              13.65: 0.0,
              13.68: 0.0,
              13.71: 0.0,
              13.74: 0.0,
              13.77: 0.01,
              13.8: 0.02,
              13.83: 0.04,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.01,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.0,
              14.34: 0.0,
              14.37: 0.0,
              14.4: 0.0,
              14.43: 0.0,
              14.46: 0.0,
              14.49: 0.01,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.07,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.03,
              14.7: 0.01,
              14.73: 0.0,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.01,
              14.88: 0.02,
              14.91: 0.04,
              14.94: 0.07,
              14.97: 0.08,
              '15.0': 0.06,
              15.03: 0.04,
              15.06: 0.01,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.0,
              15.24: 0.0,
              15.27: 0.0,
              15.3: 0.0,
              15.33: 0.0,
              15.36: 0.0,
              15.39: 0.0,
              15.42: 0.0,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.03,
              15.54: 0.06,
              15.57: 0.08,
              15.6: 0.07,
              15.63: 0.05,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.07,
              16.32: 0.05,
              16.35: 0.02,
              16.38: 0.01,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.0,
              16.59: 0.0,
              16.62: 0.0,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.0,
              17.16: 0.0,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.08,
              17.43: 0.12,
              17.46: 0.15,
              17.49: 0.16,
              17.52: 0.15,
              17.55: 0.13,
              17.58: 0.09,
              17.61: 0.05,
              17.64: 0.02,
              17.67: 0.01,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.01,
              18.42: 0.02,
              18.45: 0.05,
              18.48: 0.08,
              18.51: 0.09,
              18.54: 0.08,
              18.57: 0.08,
              18.6: 0.09,
              18.63: 0.08,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.01,
              19.5: 0.02,
              19.53: 0.04,
              19.56: 0.07,
              19.59: 0.08,
              19.62: 0.06,
              19.65: 0.03,
              19.68: 0.01,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.08,
              22.89: 0.07,
              22.92: 0.04,
              22.95: 0.02,
              22.98: 0.01,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.01,
              23.97: 0.03,
              '24.0': 0.05,
              24.03: 0.08,
              24.06: 0.08,
              24.09: 0.05,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.01,
              25.08: 0.03,
              25.11: 0.06,
              25.14: 0.08,
              25.17: 0.07,
              25.2: 0.05,
              25.23: 0.02,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.01,
              26.94: 0.03,
              26.97: 0.05,
              '27.0': 0.08,
              27.03: 0.08,
              27.06: 0.05,
              27.09: 0.03,
              27.12: 0.01,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.01,
              28.89: 0.04,
              28.92: 0.06,
              28.95: 0.08,
              28.98: 0.07,
              29.01: 0.04,
              29.04: 0.02,
              29.07: 0.01,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.02,
              30.3: 0.04,
              30.33: 0.07,
              30.36: 0.08,
              30.39: 0.07,
              30.42: 0.04,
              30.45: 0.02,
              30.48: 0.01,
              30.51: 0.01,
              30.54: 0.02,
              30.57: 0.05,
              30.6: 0.07,
              30.63: 0.08,
              30.66: 0.06,
              30.69: 0.03,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.02,
              33.96: 0.05,
              33.99: 0.09,
              34.02: 0.13,
              34.05: 0.14,
              34.08: 0.12,
              34.11: 0.07,
              34.14: 0.03,
              34.17: 0.01,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.0,
              40.74: 0.0,
              40.77: 0.0,
              40.8: 0.0,
              40.83: 0.0,
              40.86: 0.0,
              40.89: 0.0,
              40.92: 0.0,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.01,
              41.58: 0.03,
              41.61: 0.05,
              41.64: 0.08,
              41.67: 0.08,
              41.7: 0.05,
              41.73: 0.03,
              41.76: 0.01,
              41.79: 0.0,
              41.82: 0.0,
              41.85: 0.0,
              41.88: 0.0,
              41.91: 0.0,
              41.94: 0.0,
              41.97: 0.0,
              '42.0': 0.0,
              42.03: 0.0,
              42.06: 0.0,
              42.09: 0.0,
              42.12: 0.0,
              42.15: 0.0,
              42.18: 0.0,
              42.21: 0.0,
              42.24: 0.0,
              42.27: 0.0,
              42.3: 0.0,
              42.33: 0.0,
              42.36: 0.0,
              42.39: 0.0,
              42.42: 0.0,
              42.45: 0.0,
              42.48: 0.0,
              42.51: 0.0,
              42.54: 0.0,
              42.57: 0.0,
              42.6: 0.0,
              42.63: 0.0,
              42.66: 0.0,
              42.69: 0.0,
              42.72: 0.0,
              42.75: 0.0,
              42.78: 0.0,
              42.81: 0.0,
              42.84: 0.0,
              42.87: 0.0,
              42.9: 0.0,
              42.93: 0.0,
              42.96: 0.0,
              42.99: 0.0,
              43.02: 0.0,
              43.05: 0.0,
              43.08: 0.0,
              43.11: 0.0,
              43.14: 0.0,
              43.17: 0.0,
              43.2: 0.0,
              43.23: 0.0,
              43.26: 0.0,
              43.29: 0.0,
              43.32: 0.0,
              43.35: 0.0,
              43.38: 0.0,
              43.41: 0.0,
              43.44: 0.0,
              43.47: 0.0,
              43.5: 0.0,
              43.53: 0.0,
              43.56: 0.0,
              43.59: 0.0,
              43.62: 0.0,
              43.65: 0.0,
              43.68: 0.0,
              43.71: 0.0,
              43.74: 0.0,
              43.77: 0.0,
              43.8: 0.0,
              43.83: 0.0,
              43.86: 0.0,
              43.89: 0.0,
              43.92: 0.0,
              43.95: 0.0,
              43.98: 0.0,
              44.01: 0.0,
              44.04: 0.0,
              44.07: 0.0,
              44.1: 0.0,
              44.13: 0.0,
              44.16: 0.0,
              44.19: 0.0,
              44.22: 0.0,
              44.25: 0.0,
              44.28: 0.0,
              44.31: 0.0,
              44.34: 0.0,
              44.37: 0.0,
              44.4: 0.0,
              44.43: 0.0,
              44.46: 0.0,
              44.49: 0.0,
              44.52: 0.0,
              44.55: 0.0,
              44.58: 0.0,
              44.61: 0.0,
              44.64: 0.0,
              44.67: 0.0,
              44.7: 0.0,
              44.73: 0.0,
              44.76: 0.0,
              44.79: 0.0,
              44.82: 0.0,
              44.85: 0.0,
              44.88: 0.0,
              44.91: 0.0,
              44.94: 0.0,
              44.97: 0.0,
              '45.0': 0.0,
              45.03: 0.0,
              45.06: 0.0,
              45.09: 0.0,
              45.12: 0.0,
              45.15: 0.0,
              45.18: 0.0,
              45.21: 0.0,
              45.24: 0.0,
              45.27: 0.0,
              45.3: 0.0,
              45.33: 0.0,
              45.36: 0.0,
              45.39: 0.0,
              45.42: 0.0,
              45.45: 0.0,
              45.48: 0.0,
              45.51: 0.0,
              45.54: 0.0,
              45.57: 0.0,
              45.6: 0.0,
              45.63: 0.0,
              45.66: 0.0,
              45.69: 0.0,
              45.72: 0.0,
              45.75: 0.0,
              45.78: 0.0,
              45.81: 0.0,
              45.84: 0.0,
              45.87: 0.0,
              45.9: 0.0,
              45.93: 0.0,
              45.96: 0.0,
              45.99: 0.0,
              46.02: 0.0,
              46.05: 0.0,
              46.08: 0.01,
              46.11: 0.03,
              46.14: 0.06,
              46.17: 0.08,
              46.2: 0.08,
              46.23: 0.05,
              46.26: 0.02,
              46.29: 0.01,
              46.32: 0.0,
              46.35: 0.0,
              46.38: 0.0,
              46.41: 0.0,
              46.44: 0.0,
              46.47: 0.0,
              46.5: 0.0,
              46.53: 0.0,
              46.56: 0.0,
              46.59: 0.0,
              46.62: 0.0,
              46.65: 0.0,
              46.68: 0.0,
              46.71: 0.0,
              46.74: 0.0,
              46.77: 0.0,
              46.8: 0.0,
              46.83: 0.0,
              46.86: 0.0,
              46.89: 0.0,
              46.92: 0.0,
              46.95: 0.0,
              46.98: 0.0,
            },
            iteration_2: {
              '0.0': 0.0,
              0.03: 0.0,
              0.06: 0.01,
              0.09: 0.03,
              0.12: 0.08,
              0.15: 0.15,
              0.18: 0.2,
              0.21: 0.22,
              0.24: 0.2,
              0.27: 0.16,
              0.3: 0.13,
              0.33: 0.1,
              0.36: 0.08,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.18,
              0.66: 0.2,
              0.69: 0.21,
              0.72: 0.24,
              0.75: 0.25,
              0.78: 0.23,
              0.81: 0.16,
              0.84: 0.08,
              0.87: 0.03,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.0,
              1.02: 0.0,
              1.05: 0.02,
              1.08: 0.04,
              1.11: 0.07,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.01,
              1.26: 0.0,
              1.29: 0.0,
              1.32: 0.01,
              1.35: 0.03,
              1.38: 0.09,
              1.41: 0.17,
              1.44: 0.23,
              1.47: 0.22,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.02,
              1.59: 0.01,
              1.62: 0.0,
              1.65: 0.0,
              1.68: 0.0,
              1.71: 0.01,
              1.74: 0.03,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.05,
              1.95: 0.02,
              1.98: 0.01,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.0,
              2.1: 0.0,
              2.13: 0.01,
              2.16: 0.02,
              2.19: 0.06,
              2.22: 0.12,
              2.25: 0.16,
              2.28: 0.15,
              2.31: 0.1,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.01,
              2.43: 0.02,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.1,
              2.55: 0.11,
              2.58: 0.13,
              2.61: 0.16,
              2.64: 0.18,
              2.67: 0.18,
              2.7: 0.15,
              2.73: 0.11,
              2.76: 0.08,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.21,
              2.88: 0.23,
              2.91: 0.19,
              2.94: 0.12,
              2.97: 0.09,
              '3.0': 0.09,
              3.03: 0.08,
              3.06: 0.06,
              3.09: 0.03,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.08,
              3.24: 0.1,
              3.27: 0.11,
              3.3: 0.14,
              3.33: 0.19,
              3.36: 0.26,
              3.39: 0.3,
              3.42: 0.29,
              3.45: 0.22,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.07,
              3.57: 0.08,
              3.6: 0.08,
              3.63: 0.05,
              3.66: 0.02,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.07,
              3.9: 0.13,
              3.93: 0.18,
              3.96: 0.19,
              3.99: 0.17,
              4.02: 0.12,
              4.05: 0.07,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.08,
              4.35: 0.07,
              4.38: 0.07,
              4.41: 0.08,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.0,
              4.62: 0.0,
              4.65: 0.0,
              4.68: 0.0,
              4.71: 0.0,
              4.74: 0.0,
              4.77: 0.0,
              4.8: 0.0,
              4.83: 0.0,
              4.86: 0.0,
              4.89: 0.0,
              4.92: 0.0,
              4.95: 0.0,
              4.98: 0.01,
              5.01: 0.02,
              5.04: 0.04,
              5.07: 0.07,
              5.1: 0.08,
              5.13: 0.06,
              5.16: 0.03,
              5.19: 0.01,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.01,
              5.37: 0.02,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.09,
              5.58: 0.07,
              5.61: 0.04,
              5.64: 0.02,
              5.67: 0.01,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.0,
              5.88: 0.0,
              5.91: 0.0,
              5.94: 0.0,
              5.97: 0.0,
              '6.0': 0.0,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.09,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.02,
              6.96: 0.01,
              6.99: 0.0,
              7.02: 0.0,
              7.05: 0.01,
              7.08: 0.05,
              7.11: 0.12,
              7.14: 0.2,
              7.17: 0.23,
              7.2: 0.2,
              7.23: 0.12,
              7.26: 0.05,
              7.29: 0.02,
              7.32: 0.0,
              7.35: 0.0,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.01,
              7.59: 0.04,
              7.62: 0.09,
              7.65: 0.14,
              7.68: 0.16,
              7.71: 0.13,
              7.74: 0.09,
              7.77: 0.08,
              7.8: 0.08,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.01,
              7.95: 0.02,
              7.98: 0.04,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.03,
              8.13: 0.01,
              8.16: 0.0,
              8.19: 0.0,
              8.22: 0.0,
              8.25: 0.0,
              8.28: 0.0,
              8.31: 0.01,
              8.34: 0.02,
              8.37: 0.05,
              8.4: 0.07,
              8.43: 0.08,
              8.46: 0.06,
              8.49: 0.03,
              8.52: 0.01,
              8.55: 0.0,
              8.58: 0.01,
              8.61: 0.03,
              8.64: 0.07,
              8.67: 0.12,
              8.7: 0.16,
              8.73: 0.15,
              8.76: 0.13,
              8.79: 0.11,
              8.82: 0.09,
              8.85: 0.07,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.08,
              9.18: 0.13,
              9.21: 0.17,
              9.24: 0.19,
              9.27: 0.17,
              9.3: 0.12,
              9.33: 0.06,
              9.36: 0.03,
              9.39: 0.02,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.08,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.02,
              9.87: 0.04,
              9.9: 0.07,
              9.93: 0.08,
              9.96: 0.07,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.11,
              10.71: 0.11,
              10.74: 0.1,
              10.77: 0.08,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.08,
              10.92: 0.08,
              10.95: 0.06,
              10.98: 0.03,
              11.01: 0.01,
              11.04: 0.01,
              11.07: 0.02,
              11.1: 0.05,
              11.13: 0.09,
              11.16: 0.14,
              11.19: 0.15,
              11.22: 0.12,
              11.25: 0.07,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.01,
              11.49: 0.03,
              11.52: 0.06,
              11.55: 0.08,
              11.58: 0.08,
              11.61: 0.05,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.02,
              12.06: 0.04,
              12.09: 0.07,
              12.12: 0.08,
              12.15: 0.07,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.03,
              12.36: 0.06,
              12.39: 0.08,
              12.42: 0.07,
              12.45: 0.04,
              12.48: 0.02,
              12.51: 0.01,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.01,
              12.69: 0.03,
              12.72: 0.06,
              12.75: 0.08,
              12.78: 0.07,
              12.81: 0.04,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.01,
              13.5: 0.02,
              13.53: 0.05,
              13.56: 0.08,
              13.59: 0.1,
              13.62: 0.11,
              13.65: 0.11,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.05,
              13.89: 0.04,
              13.92: 0.06,
              13.95: 0.08,
              13.98: 0.08,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.08,
              14.31: 0.06,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.11,
              14.49: 0.15,
              14.52: 0.15,
              14.55: 0.11,
              14.58: 0.06,
              14.61: 0.04,
              14.64: 0.05,
              14.67: 0.07,
              14.7: 0.08,
              14.73: 0.06,
              14.76: 0.03,
              14.79: 0.01,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.01,
              15.18: 0.02,
              15.21: 0.06,
              15.24: 0.13,
              15.27: 0.2,
              15.3: 0.24,
              15.33: 0.23,
              15.36: 0.19,
              15.39: 0.13,
              15.42: 0.07,
              15.45: 0.03,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.05,
              15.69: 0.07,
              15.72: 0.08,
              15.75: 0.06,
              15.78: 0.03,
              15.81: 0.01,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.01,
              16.02: 0.02,
              16.05: 0.05,
              16.08: 0.07,
              16.11: 0.08,
              16.14: 0.06,
              16.17: 0.03,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.01,
              16.47: 0.03,
              16.5: 0.05,
              16.53: 0.08,
              16.56: 0.08,
              16.59: 0.05,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.04,
              16.8: 0.07,
              16.83: 0.08,
              16.86: 0.06,
              16.89: 0.03,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.03,
              17.19: 0.05,
              17.22: 0.08,
              17.25: 0.08,
              17.28: 0.07,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.08,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.08,
              18.69: 0.06,
              18.72: 0.03,
              18.75: 0.01,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.01,
              20.94: 0.02,
              20.97: 0.05,
              '21.0': 0.07,
              21.03: 0.08,
              21.06: 0.06,
              21.09: 0.03,
              21.12: 0.01,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.0,
              22.8: 0.0,
              22.83: 0.0,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.07,
              24.15: 0.08,
              24.18: 0.07,
              24.21: 0.04,
              24.24: 0.02,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.01,
              25.56: 0.02,
              25.59: 0.04,
              25.62: 0.07,
              25.65: 0.08,
              25.68: 0.06,
              25.71: 0.03,
              25.74: 0.01,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.07,
              26.52: 0.08,
              26.55: 0.06,
              26.58: 0.03,
              26.61: 0.01,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.01,
              29.55: 0.04,
              29.58: 0.07,
              29.61: 0.08,
              29.64: 0.07,
              29.67: 0.04,
              29.7: 0.02,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.01,
              32.64: 0.02,
              32.67: 0.05,
              32.7: 0.07,
              32.73: 0.08,
              32.76: 0.06,
              32.79: 0.03,
              32.82: 0.01,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.01,
              33.09: 0.03,
              33.12: 0.06,
              33.15: 0.08,
              33.18: 0.07,
              33.21: 0.05,
              33.24: 0.02,
              33.27: 0.01,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.01,
              35.67: 0.02,
              35.7: 0.05,
              35.73: 0.07,
              35.76: 0.08,
              35.79: 0.06,
              35.82: 0.03,
              35.85: 0.01,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
            iteration_3: {
              '0.0': 0.09,
              0.03: 0.16,
              0.06: 0.21,
              0.09: 0.22,
              0.12: 0.18,
              0.15: 0.14,
              0.18: 0.14,
              0.21: 0.15,
              0.24: 0.14,
              0.27: 0.11,
              0.3: 0.06,
              0.33: 0.02,
              0.36: 0.01,
              0.39: 0.0,
              0.42: 0.01,
              0.45: 0.04,
              0.48: 0.1,
              0.51: 0.17,
              0.54: 0.22,
              0.57: 0.21,
              0.6: 0.15,
              0.63: 0.08,
              0.66: 0.03,
              0.69: 0.02,
              0.72: 0.04,
              0.75: 0.07,
              0.78: 0.08,
              0.81: 0.07,
              0.84: 0.04,
              0.87: 0.02,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.02,
              1.02: 0.04,
              1.05: 0.07,
              1.08: 0.08,
              1.11: 0.07,
              1.14: 0.04,
              1.17: 0.02,
              1.2: 0.01,
              1.23: 0.03,
              1.26: 0.08,
              1.29: 0.14,
              1.32: 0.19,
              1.35: 0.22,
              1.38: 0.24,
              1.41: 0.3,
              1.44: 0.39,
              1.47: 0.47,
              1.5: 0.5,
              1.53: 0.47,
              1.56: 0.4,
              1.59: 0.33,
              1.62: 0.27,
              1.65: 0.21,
              1.68: 0.16,
              1.71: 0.12,
              1.74: 0.09,
              1.77: 0.07,
              1.8: 0.07,
              1.83: 0.08,
              1.86: 0.08,
              1.89: 0.05,
              1.92: 0.02,
              1.95: 0.01,
              1.98: 0.0,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.02,
              2.1: 0.04,
              2.13: 0.07,
              2.16: 0.09,
              2.19: 0.09,
              2.22: 0.08,
              2.25: 0.09,
              2.28: 0.11,
              2.31: 0.12,
              2.34: 0.15,
              2.37: 0.17,
              2.4: 0.15,
              2.43: 0.1,
              2.46: 0.06,
              2.49: 0.06,
              2.52: 0.09,
              2.55: 0.13,
              2.58: 0.16,
              2.61: 0.16,
              2.64: 0.14,
              2.67: 0.12,
              2.7: 0.08,
              2.73: 0.04,
              2.76: 0.02,
              2.79: 0.0,
              2.82: 0.0,
              2.85: 0.0,
              2.88: 0.0,
              2.91: 0.0,
              2.94: 0.02,
              2.97: 0.05,
              '3.0': 0.1,
              3.03: 0.17,
              3.06: 0.21,
              3.09: 0.23,
              3.12: 0.21,
              3.15: 0.17,
              3.18: 0.1,
              3.21: 0.05,
              3.24: 0.02,
              3.27: 0.01,
              3.3: 0.01,
              3.33: 0.02,
              3.36: 0.04,
              3.39: 0.07,
              3.42: 0.08,
              3.45: 0.07,
              3.48: 0.04,
              3.51: 0.02,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.01,
              3.87: 0.03,
              3.9: 0.06,
              3.93: 0.08,
              3.96: 0.07,
              3.99: 0.05,
              4.02: 0.02,
              4.05: 0.01,
              4.08: 0.0,
              4.11: 0.0,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.0,
              4.23: 0.0,
              4.26: 0.0,
              4.29: 0.0,
              4.32: 0.0,
              4.35: 0.0,
              4.38: 0.0,
              4.41: 0.0,
              4.44: 0.0,
              4.47: 0.0,
              4.5: 0.0,
              4.53: 0.0,
              4.56: 0.01,
              4.59: 0.04,
              4.62: 0.09,
              4.65: 0.14,
              4.68: 0.16,
              4.71: 0.13,
              4.74: 0.09,
              4.77: 0.08,
              4.8: 0.08,
              4.83: 0.07,
              4.86: 0.05,
              4.89: 0.02,
              4.92: 0.01,
              4.95: 0.0,
              4.98: 0.0,
              5.01: 0.0,
              5.04: 0.0,
              5.07: 0.0,
              5.1: 0.0,
              5.13: 0.0,
              5.16: 0.0,
              5.19: 0.0,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.0,
              5.37: 0.0,
              5.4: 0.0,
              5.43: 0.02,
              5.46: 0.05,
              5.49: 0.1,
              5.52: 0.15,
              5.55: 0.15,
              5.58: 0.11,
              5.61: 0.05,
              5.64: 0.02,
              5.67: 0.0,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.01,
              5.88: 0.02,
              5.91: 0.05,
              5.94: 0.08,
              5.97: 0.08,
              '6.0': 0.06,
              6.03: 0.03,
              6.06: 0.01,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.0,
              6.33: 0.0,
              6.36: 0.01,
              6.39: 0.02,
              6.42: 0.05,
              6.45: 0.07,
              6.48: 0.08,
              6.51: 0.06,
              6.54: 0.03,
              6.57: 0.02,
              6.6: 0.02,
              6.63: 0.05,
              6.66: 0.09,
              6.69: 0.15,
              6.72: 0.19,
              6.75: 0.2,
              6.78: 0.18,
              6.81: 0.14,
              6.84: 0.11,
              6.87: 0.09,
              6.9: 0.06,
              6.93: 0.04,
              6.96: 0.03,
              6.99: 0.04,
              7.02: 0.06,
              7.05: 0.08,
              7.08: 0.07,
              7.11: 0.04,
              7.14: 0.02,
              7.17: 0.02,
              7.2: 0.03,
              7.23: 0.06,
              7.26: 0.08,
              7.29: 0.07,
              7.32: 0.05,
              7.35: 0.02,
              7.38: 0.01,
              7.41: 0.0,
              7.44: 0.01,
              7.47: 0.04,
              7.5: 0.08,
              7.53: 0.13,
              7.56: 0.15,
              7.59: 0.13,
              7.62: 0.08,
              7.65: 0.04,
              7.68: 0.03,
              7.71: 0.04,
              7.74: 0.07,
              7.77: 0.08,
              7.8: 0.07,
              7.83: 0.04,
              7.86: 0.02,
              7.89: 0.0,
              7.92: 0.0,
              7.95: 0.0,
              7.98: 0.0,
              8.01: 0.0,
              8.04: 0.0,
              8.07: 0.01,
              8.1: 0.03,
              8.13: 0.06,
              8.16: 0.08,
              8.19: 0.07,
              8.22: 0.05,
              8.25: 0.02,
              8.28: 0.01,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.01,
              8.4: 0.02,
              8.43: 0.05,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.06,
              8.55: 0.04,
              8.58: 0.04,
              8.61: 0.06,
              8.64: 0.08,
              8.67: 0.07,
              8.7: 0.05,
              8.73: 0.02,
              8.76: 0.01,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.05,
              8.88: 0.08,
              8.91: 0.08,
              8.94: 0.07,
              8.97: 0.06,
              '9.0': 0.08,
              9.03: 0.11,
              9.06: 0.13,
              9.09: 0.13,
              9.12: 0.1,
              9.15: 0.08,
              9.18: 0.07,
              9.21: 0.08,
              9.24: 0.08,
              9.27: 0.07,
              9.3: 0.05,
              9.33: 0.05,
              9.36: 0.07,
              9.39: 0.08,
              9.42: 0.07,
              9.45: 0.04,
              9.48: 0.02,
              9.51: 0.01,
              9.54: 0.0,
              9.57: 0.0,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.01,
              9.93: 0.03,
              9.96: 0.06,
              9.99: 0.08,
              10.02: 0.09,
              10.05: 0.11,
              10.08: 0.13,
              10.11: 0.16,
              10.14: 0.15,
              10.17: 0.1,
              10.2: 0.05,
              10.23: 0.02,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.01,
              10.44: 0.03,
              10.47: 0.06,
              10.5: 0.08,
              10.53: 0.08,
              10.56: 0.08,
              10.59: 0.08,
              10.62: 0.08,
              10.65: 0.08,
              10.68: 0.05,
              10.71: 0.02,
              10.74: 0.01,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.01,
              11.07: 0.03,
              11.1: 0.05,
              11.13: 0.08,
              11.16: 0.09,
              11.19: 0.1,
              11.22: 0.1,
              11.25: 0.09,
              11.28: 0.07,
              11.31: 0.04,
              11.34: 0.02,
              11.37: 0.02,
              11.4: 0.04,
              11.43: 0.07,
              11.46: 0.08,
              11.49: 0.06,
              11.52: 0.03,
              11.55: 0.01,
              11.58: 0.0,
              11.61: 0.0,
              11.64: 0.0,
              11.67: 0.0,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.0,
              12.12: 0.0,
              12.15: 0.0,
              12.18: 0.0,
              12.21: 0.0,
              12.24: 0.0,
              12.27: 0.0,
              12.3: 0.0,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.01,
              12.51: 0.02,
              12.54: 0.04,
              12.57: 0.07,
              12.6: 0.08,
              12.63: 0.06,
              12.66: 0.04,
              12.69: 0.01,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.0,
              12.81: 0.0,
              12.84: 0.0,
              12.87: 0.0,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.01,
              13.56: 0.04,
              13.59: 0.06,
              13.62: 0.08,
              13.65: 0.07,
              13.68: 0.04,
              13.71: 0.02,
              13.74: 0.01,
              13.77: 0.0,
              13.8: 0.0,
              13.83: 0.0,
              13.86: 0.0,
              13.89: 0.0,
              13.92: 0.0,
              13.95: 0.0,
              13.98: 0.0,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.01,
              14.34: 0.04,
              14.37: 0.09,
              14.4: 0.14,
              14.43: 0.19,
              14.46: 0.2,
              14.49: 0.18,
              14.52: 0.14,
              14.55: 0.13,
              14.58: 0.16,
              14.61: 0.16,
              14.64: 0.12,
              14.67: 0.07,
              14.7: 0.03,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.01,
              14.82: 0.02,
              14.85: 0.04,
              14.88: 0.07,
              14.91: 0.08,
              14.94: 0.06,
              14.97: 0.03,
              '15.0': 0.01,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.01,
              15.15: 0.02,
              15.18: 0.05,
              15.21: 0.07,
              15.24: 0.08,
              15.27: 0.06,
              15.3: 0.03,
              15.33: 0.01,
              15.36: 0.01,
              15.39: 0.03,
              15.42: 0.06,
              15.45: 0.08,
              15.48: 0.08,
              15.51: 0.08,
              15.54: 0.09,
              15.57: 0.1,
              15.6: 0.12,
              15.63: 0.12,
              15.66: 0.1,
              15.69: 0.07,
              15.72: 0.04,
              15.75: 0.01,
              15.78: 0.01,
              15.81: 0.02,
              15.84: 0.04,
              15.87: 0.07,
              15.9: 0.08,
              15.93: 0.06,
              15.96: 0.03,
              15.99: 0.01,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.09,
              16.32: 0.08,
              16.35: 0.08,
              16.38: 0.09,
              16.41: 0.07,
              16.44: 0.06,
              16.47: 0.06,
              16.5: 0.07,
              16.53: 0.08,
              16.56: 0.07,
              16.59: 0.04,
              16.62: 0.02,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.02,
              16.98: 0.04,
              17.01: 0.08,
              17.04: 0.13,
              17.07: 0.14,
              17.1: 0.12,
              17.13: 0.08,
              17.16: 0.04,
              17.19: 0.01,
              17.22: 0.01,
              17.25: 0.02,
              17.28: 0.05,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.06,
              17.4: 0.03,
              17.43: 0.01,
              17.46: 0.0,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.01,
              17.64: 0.03,
              17.67: 0.06,
              17.7: 0.08,
              17.73: 0.07,
              17.76: 0.04,
              17.79: 0.02,
              17.82: 0.01,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.0,
              18.57: 0.0,
              18.6: 0.0,
              18.63: 0.0,
              18.66: 0.0,
              18.69: 0.0,
              18.72: 0.0,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.02,
              20.82: 0.04,
              20.85: 0.07,
              20.88: 0.08,
              20.91: 0.07,
              20.94: 0.04,
              20.97: 0.02,
              '21.0': 0.01,
              21.03: 0.02,
              21.06: 0.05,
              21.09: 0.07,
              21.12: 0.08,
              21.15: 0.06,
              21.18: 0.03,
              21.21: 0.01,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.01,
              22.65: 0.03,
              22.68: 0.06,
              22.71: 0.08,
              22.74: 0.07,
              22.77: 0.05,
              22.8: 0.02,
              22.83: 0.01,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.0,
              24.09: 0.0,
              24.12: 0.0,
              24.15: 0.0,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.01,
              24.54: 0.03,
              24.57: 0.06,
              24.6: 0.08,
              24.63: 0.08,
              24.66: 0.05,
              24.69: 0.02,
              24.72: 0.01,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.01,
              24.93: 0.02,
              24.96: 0.05,
              24.99: 0.08,
              25.02: 0.08,
              25.05: 0.06,
              25.08: 0.03,
              25.11: 0.01,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.01,
              25.74: 0.02,
              25.77: 0.05,
              25.8: 0.08,
              25.83: 0.08,
              25.86: 0.06,
              25.89: 0.03,
              25.92: 0.01,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.01,
              27.21: 0.03,
              27.24: 0.05,
              27.27: 0.08,
              27.3: 0.08,
              27.33: 0.05,
              27.36: 0.03,
              27.39: 0.01,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.01,
              33.96: 0.02,
              33.99: 0.04,
              34.02: 0.07,
              34.05: 0.08,
              34.08: 0.06,
              34.11: 0.04,
              34.14: 0.01,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.01,
              40.74: 0.02,
              40.77: 0.04,
              40.8: 0.07,
              40.83: 0.08,
              40.86: 0.06,
              40.89: 0.04,
              40.92: 0.01,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.0,
              41.58: 0.0,
              41.61: 0.0,
              41.64: 0.0,
              41.67: 0.0,
              41.7: 0.0,
              41.73: 0.0,
              41.76: 0.0,
              41.79: 0.01,
              41.82: 0.02,
              41.85: 0.05,
              41.88: 0.07,
              41.91: 0.08,
              41.94: 0.06,
              41.97: 0.03,
            },
            iteration_median: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.14,
              0.18: 0.18,
              0.21: 0.19,
              0.24: 0.18,
              0.27: 0.15,
              0.3: 0.13,
              0.33: 0.11,
              0.36: 0.1,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.15,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.06,
              1.26: 0.08,
              1.29: 0.08,
              1.32: 0.07,
              1.35: 0.08,
              1.38: 0.12,
              1.41: 0.16,
              1.44: 0.17,
              1.47: 0.16,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.08,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.16,
              2.28: 0.11,
              2.31: 0.07,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.15,
              2.73: 0.12,
              2.76: 0.09,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.01,
              3.36: 0.03,
              3.39: 0.06,
              3.42: 0.11,
              3.45: 0.14,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.06,
              3.57: 0.02,
              3.6: 0.01,
              3.63: 0.0,
              3.66: 0.01,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.02,
              3.9: 0.01,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.03,
              4.65: 0.02,
              4.68: 0.03,
              4.71: 0.06,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.16,
              4.95: 0.1,
              4.98: 0.05,
              5.01: 0.02,
              5.04: 0.02,
              5.07: 0.04,
              5.1: 0.07,
              5.13: 0.06,
              5.16: 0.07,
              5.19: 0.04,
              5.22: 0.03,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.05,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.07,
              5.7: 0.04,
              5.73: 0.02,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.01,
              5.85: 0.02,
              5.88: 0.05,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.01,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.11,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.06,
              7.02: 0.03,
              7.05: 0.01,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.05,
              7.23: 0.08,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.04,
              8.22: 0.02,
              8.25: 0.01,
              8.28: 0.0,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.04,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.07,
              8.55: 0.04,
              8.58: 0.02,
              8.61: 0.03,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.04,
              9.24: 0.08,
              9.27: 0.12,
              9.3: 0.12,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.05,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.07,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.08,
              10.71: 0.05,
              10.74: 0.04,
              10.77: 0.05,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.03,
              10.92: 0.01,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.01,
              11.1: 0.03,
              11.13: 0.06,
              11.16: 0.08,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.01,
              13.62: 0.03,
              13.65: 0.06,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.05,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.07,
              14.31: 0.04,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.05,
              14.49: 0.03,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.06,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.05,
              14.7: 0.02,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.01,
              15.24: 0.02,
              15.27: 0.04,
              15.3: 0.07,
              15.33: 0.08,
              15.36: 0.06,
              15.39: 0.04,
              15.42: 0.01,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.01,
              16.59: 0.03,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.01,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.01,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.05,
              22.89: 0.02,
              22.92: 0.01,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.04,
              26.52: 0.02,
              26.55: 0.01,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
          },
        },
        region: {
          'australia and new zealand': {
            iteration_1: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.12,
              0.18: 0.1,
              0.21: 0.1,
              0.24: 0.11,
              0.27: 0.13,
              0.3: 0.15,
              0.33: 0.17,
              0.36: 0.18,
              0.39: 0.18,
              0.42: 0.19,
              0.45: 0.22,
              0.48: 0.25,
              0.51: 0.25,
              0.54: 0.22,
              0.57: 0.18,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.2,
              1.14: 0.17,
              1.17: 0.13,
              1.2: 0.1,
              1.23: 0.09,
              1.26: 0.13,
              1.29: 0.17,
              1.32: 0.19,
              1.35: 0.17,
              1.38: 0.13,
              1.41: 0.08,
              1.44: 0.05,
              1.47: 0.03,
              1.5: 0.04,
              1.53: 0.06,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.05,
              1.83: 0.06,
              1.86: 0.08,
              1.89: 0.09,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.1,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.15,
              2.28: 0.11,
              2.31: 0.06,
              2.34: 0.02,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.07,
              2.49: 0.08,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.18,
              2.73: 0.17,
              2.76: 0.16,
              2.79: 0.16,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.04,
              3.15: 0.07,
              3.18: 0.08,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.0,
              3.36: 0.0,
              3.39: 0.0,
              3.42: 0.0,
              3.45: 0.0,
              3.48: 0.0,
              3.51: 0.0,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.0,
              3.87: 0.0,
              3.9: 0.0,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.08,
              4.11: 0.1,
              4.14: 0.1,
              4.17: 0.1,
              4.2: 0.09,
              4.23: 0.08,
              4.26: 0.07,
              4.29: 0.09,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.05,
              4.47: 0.02,
              4.5: 0.01,
              4.53: 0.0,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.04,
              4.65: 0.06,
              4.68: 0.08,
              4.71: 0.07,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.17,
              4.95: 0.13,
              4.98: 0.07,
              5.01: 0.03,
              5.04: 0.01,
              5.07: 0.01,
              5.1: 0.03,
              5.13: 0.06,
              5.16: 0.08,
              5.19: 0.07,
              5.22: 0.05,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.12,
              5.4: 0.16,
              5.43: 0.15,
              5.46: 0.11,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.12,
              5.7: 0.15,
              5.73: 0.14,
              5.76: 0.09,
              5.79: 0.05,
              5.82: 0.03,
              5.85: 0.05,
              5.88: 0.07,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.04,
              6.21: 0.07,
              6.24: 0.1,
              6.27: 0.12,
              6.3: 0.12,
              6.33: 0.1,
              6.36: 0.09,
              6.39: 0.08,
              6.42: 0.09,
              6.45: 0.08,
              6.48: 0.05,
              6.51: 0.03,
              6.54: 0.02,
              6.57: 0.04,
              6.6: 0.08,
              6.63: 0.14,
              6.66: 0.16,
              6.69: 0.13,
              6.72: 0.07,
              6.75: 0.03,
              6.78: 0.01,
              6.81: 0.0,
              6.84: 0.0,
              6.87: 0.0,
              6.9: 0.01,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.08,
              7.02: 0.07,
              7.05: 0.05,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.04,
              7.23: 0.07,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.07,
              7.89: 0.04,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.08,
              8.22: 0.07,
              8.25: 0.04,
              8.28: 0.02,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.03,
              8.46: 0.07,
              8.49: 0.11,
              8.52: 0.13,
              8.55: 0.13,
              8.58: 0.1,
              8.61: 0.06,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.07,
              8.91: 0.08,
              8.94: 0.06,
              8.97: 0.03,
              '9.0': 0.02,
              9.03: 0.03,
              9.06: 0.05,
              9.09: 0.07,
              9.12: 0.08,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.02,
              9.24: 0.03,
              9.27: 0.05,
              9.3: 0.08,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.06,
              9.42: 0.07,
              9.45: 0.08,
              9.48: 0.07,
              9.51: 0.05,
              9.54: 0.02,
              9.57: 0.01,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.07,
              10.02: 0.12,
              10.05: 0.14,
              10.08: 0.14,
              10.11: 0.12,
              10.14: 0.1,
              10.17: 0.09,
              10.2: 0.08,
              10.23: 0.06,
              10.26: 0.03,
              10.29: 0.01,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.0,
              10.47: 0.0,
              10.5: 0.0,
              10.53: 0.0,
              10.56: 0.0,
              10.59: 0.0,
              10.62: 0.0,
              10.65: 0.0,
              10.68: 0.0,
              10.71: 0.0,
              10.74: 0.0,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.0,
              11.1: 0.01,
              11.13: 0.02,
              11.16: 0.04,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.05,
              11.67: 0.1,
              11.7: 0.15,
              11.73: 0.2,
              11.76: 0.24,
              11.79: 0.24,
              11.82: 0.19,
              11.85: 0.14,
              11.88: 0.11,
              11.91: 0.09,
              11.94: 0.06,
              11.97: 0.03,
              '12.0': 0.01,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.07,
              12.21: 0.08,
              12.24: 0.06,
              12.27: 0.04,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.06,
              12.87: 0.08,
              12.9: 0.07,
              12.93: 0.05,
              12.96: 0.05,
              12.99: 0.06,
              13.02: 0.08,
              13.05: 0.08,
              13.08: 0.05,
              13.11: 0.03,
              13.14: 0.03,
              13.17: 0.05,
              13.2: 0.07,
              13.23: 0.08,
              13.26: 0.06,
              13.29: 0.03,
              13.32: 0.01,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.0,
              13.62: 0.0,
              13.65: 0.0,
              13.68: 0.0,
              13.71: 0.0,
              13.74: 0.0,
              13.77: 0.01,
              13.8: 0.02,
              13.83: 0.04,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.01,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.0,
              14.34: 0.0,
              14.37: 0.0,
              14.4: 0.0,
              14.43: 0.0,
              14.46: 0.0,
              14.49: 0.01,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.07,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.03,
              14.7: 0.01,
              14.73: 0.0,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.01,
              14.88: 0.02,
              14.91: 0.04,
              14.94: 0.07,
              14.97: 0.08,
              '15.0': 0.06,
              15.03: 0.04,
              15.06: 0.01,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.0,
              15.24: 0.0,
              15.27: 0.0,
              15.3: 0.0,
              15.33: 0.0,
              15.36: 0.0,
              15.39: 0.0,
              15.42: 0.0,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.03,
              15.54: 0.06,
              15.57: 0.08,
              15.6: 0.07,
              15.63: 0.05,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.07,
              16.32: 0.05,
              16.35: 0.02,
              16.38: 0.01,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.0,
              16.59: 0.0,
              16.62: 0.0,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.0,
              17.16: 0.0,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.08,
              17.43: 0.12,
              17.46: 0.15,
              17.49: 0.16,
              17.52: 0.15,
              17.55: 0.13,
              17.58: 0.09,
              17.61: 0.05,
              17.64: 0.02,
              17.67: 0.01,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.01,
              18.42: 0.02,
              18.45: 0.05,
              18.48: 0.08,
              18.51: 0.09,
              18.54: 0.08,
              18.57: 0.08,
              18.6: 0.09,
              18.63: 0.08,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.01,
              19.5: 0.02,
              19.53: 0.04,
              19.56: 0.07,
              19.59: 0.08,
              19.62: 0.06,
              19.65: 0.03,
              19.68: 0.01,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.08,
              22.89: 0.07,
              22.92: 0.04,
              22.95: 0.02,
              22.98: 0.01,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.01,
              23.97: 0.03,
              '24.0': 0.05,
              24.03: 0.08,
              24.06: 0.08,
              24.09: 0.05,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.01,
              25.08: 0.03,
              25.11: 0.06,
              25.14: 0.08,
              25.17: 0.07,
              25.2: 0.05,
              25.23: 0.02,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.01,
              26.94: 0.03,
              26.97: 0.05,
              '27.0': 0.08,
              27.03: 0.08,
              27.06: 0.05,
              27.09: 0.03,
              27.12: 0.01,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.01,
              28.89: 0.04,
              28.92: 0.06,
              28.95: 0.08,
              28.98: 0.07,
              29.01: 0.04,
              29.04: 0.02,
              29.07: 0.01,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.02,
              30.3: 0.04,
              30.33: 0.07,
              30.36: 0.08,
              30.39: 0.07,
              30.42: 0.04,
              30.45: 0.02,
              30.48: 0.01,
              30.51: 0.01,
              30.54: 0.02,
              30.57: 0.05,
              30.6: 0.07,
              30.63: 0.08,
              30.66: 0.06,
              30.69: 0.03,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.02,
              33.96: 0.05,
              33.99: 0.09,
              34.02: 0.13,
              34.05: 0.14,
              34.08: 0.12,
              34.11: 0.07,
              34.14: 0.03,
              34.17: 0.01,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.0,
              40.74: 0.0,
              40.77: 0.0,
              40.8: 0.0,
              40.83: 0.0,
              40.86: 0.0,
              40.89: 0.0,
              40.92: 0.0,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.01,
              41.58: 0.03,
              41.61: 0.05,
              41.64: 0.08,
              41.67: 0.08,
              41.7: 0.05,
              41.73: 0.03,
              41.76: 0.01,
              41.79: 0.0,
              41.82: 0.0,
              41.85: 0.0,
              41.88: 0.0,
              41.91: 0.0,
              41.94: 0.0,
              41.97: 0.0,
              '42.0': 0.0,
              42.03: 0.0,
              42.06: 0.0,
              42.09: 0.0,
              42.12: 0.0,
              42.15: 0.0,
              42.18: 0.0,
              42.21: 0.0,
              42.24: 0.0,
              42.27: 0.0,
              42.3: 0.0,
              42.33: 0.0,
              42.36: 0.0,
              42.39: 0.0,
              42.42: 0.0,
              42.45: 0.0,
              42.48: 0.0,
              42.51: 0.0,
              42.54: 0.0,
              42.57: 0.0,
              42.6: 0.0,
              42.63: 0.0,
              42.66: 0.0,
              42.69: 0.0,
              42.72: 0.0,
              42.75: 0.0,
              42.78: 0.0,
              42.81: 0.0,
              42.84: 0.0,
              42.87: 0.0,
              42.9: 0.0,
              42.93: 0.0,
              42.96: 0.0,
              42.99: 0.0,
              43.02: 0.0,
              43.05: 0.0,
              43.08: 0.0,
              43.11: 0.0,
              43.14: 0.0,
              43.17: 0.0,
              43.2: 0.0,
              43.23: 0.0,
              43.26: 0.0,
              43.29: 0.0,
              43.32: 0.0,
              43.35: 0.0,
              43.38: 0.0,
              43.41: 0.0,
              43.44: 0.0,
              43.47: 0.0,
              43.5: 0.0,
              43.53: 0.0,
              43.56: 0.0,
              43.59: 0.0,
              43.62: 0.0,
              43.65: 0.0,
              43.68: 0.0,
              43.71: 0.0,
              43.74: 0.0,
              43.77: 0.0,
              43.8: 0.0,
              43.83: 0.0,
              43.86: 0.0,
              43.89: 0.0,
              43.92: 0.0,
              43.95: 0.0,
              43.98: 0.0,
              44.01: 0.0,
              44.04: 0.0,
              44.07: 0.0,
              44.1: 0.0,
              44.13: 0.0,
              44.16: 0.0,
              44.19: 0.0,
              44.22: 0.0,
              44.25: 0.0,
              44.28: 0.0,
              44.31: 0.0,
              44.34: 0.0,
              44.37: 0.0,
              44.4: 0.0,
              44.43: 0.0,
              44.46: 0.0,
              44.49: 0.0,
              44.52: 0.0,
              44.55: 0.0,
              44.58: 0.0,
              44.61: 0.0,
              44.64: 0.0,
              44.67: 0.0,
              44.7: 0.0,
              44.73: 0.0,
              44.76: 0.0,
              44.79: 0.0,
              44.82: 0.0,
              44.85: 0.0,
              44.88: 0.0,
              44.91: 0.0,
              44.94: 0.0,
              44.97: 0.0,
              '45.0': 0.0,
              45.03: 0.0,
              45.06: 0.0,
              45.09: 0.0,
              45.12: 0.0,
              45.15: 0.0,
              45.18: 0.0,
              45.21: 0.0,
              45.24: 0.0,
              45.27: 0.0,
              45.3: 0.0,
              45.33: 0.0,
              45.36: 0.0,
              45.39: 0.0,
              45.42: 0.0,
              45.45: 0.0,
              45.48: 0.0,
              45.51: 0.0,
              45.54: 0.0,
              45.57: 0.0,
              45.6: 0.0,
              45.63: 0.0,
              45.66: 0.0,
              45.69: 0.0,
              45.72: 0.0,
              45.75: 0.0,
              45.78: 0.0,
              45.81: 0.0,
              45.84: 0.0,
              45.87: 0.0,
              45.9: 0.0,
              45.93: 0.0,
              45.96: 0.0,
              45.99: 0.0,
              46.02: 0.0,
              46.05: 0.0,
              46.08: 0.01,
              46.11: 0.03,
              46.14: 0.06,
              46.17: 0.08,
              46.2: 0.08,
              46.23: 0.05,
              46.26: 0.02,
              46.29: 0.01,
              46.32: 0.0,
              46.35: 0.0,
              46.38: 0.0,
              46.41: 0.0,
              46.44: 0.0,
              46.47: 0.0,
              46.5: 0.0,
              46.53: 0.0,
              46.56: 0.0,
              46.59: 0.0,
              46.62: 0.0,
              46.65: 0.0,
              46.68: 0.0,
              46.71: 0.0,
              46.74: 0.0,
              46.77: 0.0,
              46.8: 0.0,
              46.83: 0.0,
              46.86: 0.0,
              46.89: 0.0,
              46.92: 0.0,
              46.95: 0.0,
              46.98: 0.0,
            },
            iteration_2: {
              '0.0': 0.0,
              0.03: 0.0,
              0.06: 0.01,
              0.09: 0.03,
              0.12: 0.08,
              0.15: 0.15,
              0.18: 0.2,
              0.21: 0.22,
              0.24: 0.2,
              0.27: 0.16,
              0.3: 0.13,
              0.33: 0.1,
              0.36: 0.08,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.18,
              0.66: 0.2,
              0.69: 0.21,
              0.72: 0.24,
              0.75: 0.25,
              0.78: 0.23,
              0.81: 0.16,
              0.84: 0.08,
              0.87: 0.03,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.0,
              1.02: 0.0,
              1.05: 0.02,
              1.08: 0.04,
              1.11: 0.07,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.01,
              1.26: 0.0,
              1.29: 0.0,
              1.32: 0.01,
              1.35: 0.03,
              1.38: 0.09,
              1.41: 0.17,
              1.44: 0.23,
              1.47: 0.22,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.02,
              1.59: 0.01,
              1.62: 0.0,
              1.65: 0.0,
              1.68: 0.0,
              1.71: 0.01,
              1.74: 0.03,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.05,
              1.95: 0.02,
              1.98: 0.01,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.0,
              2.1: 0.0,
              2.13: 0.01,
              2.16: 0.02,
              2.19: 0.06,
              2.22: 0.12,
              2.25: 0.16,
              2.28: 0.15,
              2.31: 0.1,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.01,
              2.43: 0.02,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.1,
              2.55: 0.11,
              2.58: 0.13,
              2.61: 0.16,
              2.64: 0.18,
              2.67: 0.18,
              2.7: 0.15,
              2.73: 0.11,
              2.76: 0.08,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.21,
              2.88: 0.23,
              2.91: 0.19,
              2.94: 0.12,
              2.97: 0.09,
              '3.0': 0.09,
              3.03: 0.08,
              3.06: 0.06,
              3.09: 0.03,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.08,
              3.24: 0.1,
              3.27: 0.11,
              3.3: 0.14,
              3.33: 0.19,
              3.36: 0.26,
              3.39: 0.3,
              3.42: 0.29,
              3.45: 0.22,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.07,
              3.57: 0.08,
              3.6: 0.08,
              3.63: 0.05,
              3.66: 0.02,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.07,
              3.9: 0.13,
              3.93: 0.18,
              3.96: 0.19,
              3.99: 0.17,
              4.02: 0.12,
              4.05: 0.07,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.08,
              4.35: 0.07,
              4.38: 0.07,
              4.41: 0.08,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.0,
              4.62: 0.0,
              4.65: 0.0,
              4.68: 0.0,
              4.71: 0.0,
              4.74: 0.0,
              4.77: 0.0,
              4.8: 0.0,
              4.83: 0.0,
              4.86: 0.0,
              4.89: 0.0,
              4.92: 0.0,
              4.95: 0.0,
              4.98: 0.01,
              5.01: 0.02,
              5.04: 0.04,
              5.07: 0.07,
              5.1: 0.08,
              5.13: 0.06,
              5.16: 0.03,
              5.19: 0.01,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.01,
              5.37: 0.02,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.09,
              5.58: 0.07,
              5.61: 0.04,
              5.64: 0.02,
              5.67: 0.01,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.0,
              5.88: 0.0,
              5.91: 0.0,
              5.94: 0.0,
              5.97: 0.0,
              '6.0': 0.0,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.09,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.02,
              6.96: 0.01,
              6.99: 0.0,
              7.02: 0.0,
              7.05: 0.01,
              7.08: 0.05,
              7.11: 0.12,
              7.14: 0.2,
              7.17: 0.23,
              7.2: 0.2,
              7.23: 0.12,
              7.26: 0.05,
              7.29: 0.02,
              7.32: 0.0,
              7.35: 0.0,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.01,
              7.59: 0.04,
              7.62: 0.09,
              7.65: 0.14,
              7.68: 0.16,
              7.71: 0.13,
              7.74: 0.09,
              7.77: 0.08,
              7.8: 0.08,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.01,
              7.95: 0.02,
              7.98: 0.04,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.03,
              8.13: 0.01,
              8.16: 0.0,
              8.19: 0.0,
              8.22: 0.0,
              8.25: 0.0,
              8.28: 0.0,
              8.31: 0.01,
              8.34: 0.02,
              8.37: 0.05,
              8.4: 0.07,
              8.43: 0.08,
              8.46: 0.06,
              8.49: 0.03,
              8.52: 0.01,
              8.55: 0.0,
              8.58: 0.01,
              8.61: 0.03,
              8.64: 0.07,
              8.67: 0.12,
              8.7: 0.16,
              8.73: 0.15,
              8.76: 0.13,
              8.79: 0.11,
              8.82: 0.09,
              8.85: 0.07,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.08,
              9.18: 0.13,
              9.21: 0.17,
              9.24: 0.19,
              9.27: 0.17,
              9.3: 0.12,
              9.33: 0.06,
              9.36: 0.03,
              9.39: 0.02,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.08,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.02,
              9.87: 0.04,
              9.9: 0.07,
              9.93: 0.08,
              9.96: 0.07,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.11,
              10.71: 0.11,
              10.74: 0.1,
              10.77: 0.08,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.08,
              10.92: 0.08,
              10.95: 0.06,
              10.98: 0.03,
              11.01: 0.01,
              11.04: 0.01,
              11.07: 0.02,
              11.1: 0.05,
              11.13: 0.09,
              11.16: 0.14,
              11.19: 0.15,
              11.22: 0.12,
              11.25: 0.07,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.01,
              11.49: 0.03,
              11.52: 0.06,
              11.55: 0.08,
              11.58: 0.08,
              11.61: 0.05,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.02,
              12.06: 0.04,
              12.09: 0.07,
              12.12: 0.08,
              12.15: 0.07,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.03,
              12.36: 0.06,
              12.39: 0.08,
              12.42: 0.07,
              12.45: 0.04,
              12.48: 0.02,
              12.51: 0.01,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.01,
              12.69: 0.03,
              12.72: 0.06,
              12.75: 0.08,
              12.78: 0.07,
              12.81: 0.04,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.01,
              13.5: 0.02,
              13.53: 0.05,
              13.56: 0.08,
              13.59: 0.1,
              13.62: 0.11,
              13.65: 0.11,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.05,
              13.89: 0.04,
              13.92: 0.06,
              13.95: 0.08,
              13.98: 0.08,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.08,
              14.31: 0.06,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.11,
              14.49: 0.15,
              14.52: 0.15,
              14.55: 0.11,
              14.58: 0.06,
              14.61: 0.04,
              14.64: 0.05,
              14.67: 0.07,
              14.7: 0.08,
              14.73: 0.06,
              14.76: 0.03,
              14.79: 0.01,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.01,
              15.18: 0.02,
              15.21: 0.06,
              15.24: 0.13,
              15.27: 0.2,
              15.3: 0.24,
              15.33: 0.23,
              15.36: 0.19,
              15.39: 0.13,
              15.42: 0.07,
              15.45: 0.03,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.05,
              15.69: 0.07,
              15.72: 0.08,
              15.75: 0.06,
              15.78: 0.03,
              15.81: 0.01,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.01,
              16.02: 0.02,
              16.05: 0.05,
              16.08: 0.07,
              16.11: 0.08,
              16.14: 0.06,
              16.17: 0.03,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.01,
              16.47: 0.03,
              16.5: 0.05,
              16.53: 0.08,
              16.56: 0.08,
              16.59: 0.05,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.04,
              16.8: 0.07,
              16.83: 0.08,
              16.86: 0.06,
              16.89: 0.03,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.03,
              17.19: 0.05,
              17.22: 0.08,
              17.25: 0.08,
              17.28: 0.07,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.08,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.08,
              18.69: 0.06,
              18.72: 0.03,
              18.75: 0.01,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.01,
              20.94: 0.02,
              20.97: 0.05,
              '21.0': 0.07,
              21.03: 0.08,
              21.06: 0.06,
              21.09: 0.03,
              21.12: 0.01,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.0,
              22.8: 0.0,
              22.83: 0.0,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.07,
              24.15: 0.08,
              24.18: 0.07,
              24.21: 0.04,
              24.24: 0.02,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.01,
              25.56: 0.02,
              25.59: 0.04,
              25.62: 0.07,
              25.65: 0.08,
              25.68: 0.06,
              25.71: 0.03,
              25.74: 0.01,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.07,
              26.52: 0.08,
              26.55: 0.06,
              26.58: 0.03,
              26.61: 0.01,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.01,
              29.55: 0.04,
              29.58: 0.07,
              29.61: 0.08,
              29.64: 0.07,
              29.67: 0.04,
              29.7: 0.02,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.01,
              32.64: 0.02,
              32.67: 0.05,
              32.7: 0.07,
              32.73: 0.08,
              32.76: 0.06,
              32.79: 0.03,
              32.82: 0.01,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.01,
              33.09: 0.03,
              33.12: 0.06,
              33.15: 0.08,
              33.18: 0.07,
              33.21: 0.05,
              33.24: 0.02,
              33.27: 0.01,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.01,
              35.67: 0.02,
              35.7: 0.05,
              35.73: 0.07,
              35.76: 0.08,
              35.79: 0.06,
              35.82: 0.03,
              35.85: 0.01,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
            iteration_3: {
              '0.0': 0.09,
              0.03: 0.16,
              0.06: 0.21,
              0.09: 0.22,
              0.12: 0.18,
              0.15: 0.14,
              0.18: 0.14,
              0.21: 0.15,
              0.24: 0.14,
              0.27: 0.11,
              0.3: 0.06,
              0.33: 0.02,
              0.36: 0.01,
              0.39: 0.0,
              0.42: 0.01,
              0.45: 0.04,
              0.48: 0.1,
              0.51: 0.17,
              0.54: 0.22,
              0.57: 0.21,
              0.6: 0.15,
              0.63: 0.08,
              0.66: 0.03,
              0.69: 0.02,
              0.72: 0.04,
              0.75: 0.07,
              0.78: 0.08,
              0.81: 0.07,
              0.84: 0.04,
              0.87: 0.02,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.02,
              1.02: 0.04,
              1.05: 0.07,
              1.08: 0.08,
              1.11: 0.07,
              1.14: 0.04,
              1.17: 0.02,
              1.2: 0.01,
              1.23: 0.03,
              1.26: 0.08,
              1.29: 0.14,
              1.32: 0.19,
              1.35: 0.22,
              1.38: 0.24,
              1.41: 0.3,
              1.44: 0.39,
              1.47: 0.47,
              1.5: 0.5,
              1.53: 0.47,
              1.56: 0.4,
              1.59: 0.33,
              1.62: 0.27,
              1.65: 0.21,
              1.68: 0.16,
              1.71: 0.12,
              1.74: 0.09,
              1.77: 0.07,
              1.8: 0.07,
              1.83: 0.08,
              1.86: 0.08,
              1.89: 0.05,
              1.92: 0.02,
              1.95: 0.01,
              1.98: 0.0,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.02,
              2.1: 0.04,
              2.13: 0.07,
              2.16: 0.09,
              2.19: 0.09,
              2.22: 0.08,
              2.25: 0.09,
              2.28: 0.11,
              2.31: 0.12,
              2.34: 0.15,
              2.37: 0.17,
              2.4: 0.15,
              2.43: 0.1,
              2.46: 0.06,
              2.49: 0.06,
              2.52: 0.09,
              2.55: 0.13,
              2.58: 0.16,
              2.61: 0.16,
              2.64: 0.14,
              2.67: 0.12,
              2.7: 0.08,
              2.73: 0.04,
              2.76: 0.02,
              2.79: 0.0,
              2.82: 0.0,
              2.85: 0.0,
              2.88: 0.0,
              2.91: 0.0,
              2.94: 0.02,
              2.97: 0.05,
              '3.0': 0.1,
              3.03: 0.17,
              3.06: 0.21,
              3.09: 0.23,
              3.12: 0.21,
              3.15: 0.17,
              3.18: 0.1,
              3.21: 0.05,
              3.24: 0.02,
              3.27: 0.01,
              3.3: 0.01,
              3.33: 0.02,
              3.36: 0.04,
              3.39: 0.07,
              3.42: 0.08,
              3.45: 0.07,
              3.48: 0.04,
              3.51: 0.02,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.01,
              3.87: 0.03,
              3.9: 0.06,
              3.93: 0.08,
              3.96: 0.07,
              3.99: 0.05,
              4.02: 0.02,
              4.05: 0.01,
              4.08: 0.0,
              4.11: 0.0,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.0,
              4.23: 0.0,
              4.26: 0.0,
              4.29: 0.0,
              4.32: 0.0,
              4.35: 0.0,
              4.38: 0.0,
              4.41: 0.0,
              4.44: 0.0,
              4.47: 0.0,
              4.5: 0.0,
              4.53: 0.0,
              4.56: 0.01,
              4.59: 0.04,
              4.62: 0.09,
              4.65: 0.14,
              4.68: 0.16,
              4.71: 0.13,
              4.74: 0.09,
              4.77: 0.08,
              4.8: 0.08,
              4.83: 0.07,
              4.86: 0.05,
              4.89: 0.02,
              4.92: 0.01,
              4.95: 0.0,
              4.98: 0.0,
              5.01: 0.0,
              5.04: 0.0,
              5.07: 0.0,
              5.1: 0.0,
              5.13: 0.0,
              5.16: 0.0,
              5.19: 0.0,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.0,
              5.37: 0.0,
              5.4: 0.0,
              5.43: 0.02,
              5.46: 0.05,
              5.49: 0.1,
              5.52: 0.15,
              5.55: 0.15,
              5.58: 0.11,
              5.61: 0.05,
              5.64: 0.02,
              5.67: 0.0,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.01,
              5.88: 0.02,
              5.91: 0.05,
              5.94: 0.08,
              5.97: 0.08,
              '6.0': 0.06,
              6.03: 0.03,
              6.06: 0.01,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.0,
              6.33: 0.0,
              6.36: 0.01,
              6.39: 0.02,
              6.42: 0.05,
              6.45: 0.07,
              6.48: 0.08,
              6.51: 0.06,
              6.54: 0.03,
              6.57: 0.02,
              6.6: 0.02,
              6.63: 0.05,
              6.66: 0.09,
              6.69: 0.15,
              6.72: 0.19,
              6.75: 0.2,
              6.78: 0.18,
              6.81: 0.14,
              6.84: 0.11,
              6.87: 0.09,
              6.9: 0.06,
              6.93: 0.04,
              6.96: 0.03,
              6.99: 0.04,
              7.02: 0.06,
              7.05: 0.08,
              7.08: 0.07,
              7.11: 0.04,
              7.14: 0.02,
              7.17: 0.02,
              7.2: 0.03,
              7.23: 0.06,
              7.26: 0.08,
              7.29: 0.07,
              7.32: 0.05,
              7.35: 0.02,
              7.38: 0.01,
              7.41: 0.0,
              7.44: 0.01,
              7.47: 0.04,
              7.5: 0.08,
              7.53: 0.13,
              7.56: 0.15,
              7.59: 0.13,
              7.62: 0.08,
              7.65: 0.04,
              7.68: 0.03,
              7.71: 0.04,
              7.74: 0.07,
              7.77: 0.08,
              7.8: 0.07,
              7.83: 0.04,
              7.86: 0.02,
              7.89: 0.0,
              7.92: 0.0,
              7.95: 0.0,
              7.98: 0.0,
              8.01: 0.0,
              8.04: 0.0,
              8.07: 0.01,
              8.1: 0.03,
              8.13: 0.06,
              8.16: 0.08,
              8.19: 0.07,
              8.22: 0.05,
              8.25: 0.02,
              8.28: 0.01,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.01,
              8.4: 0.02,
              8.43: 0.05,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.06,
              8.55: 0.04,
              8.58: 0.04,
              8.61: 0.06,
              8.64: 0.08,
              8.67: 0.07,
              8.7: 0.05,
              8.73: 0.02,
              8.76: 0.01,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.05,
              8.88: 0.08,
              8.91: 0.08,
              8.94: 0.07,
              8.97: 0.06,
              '9.0': 0.08,
              9.03: 0.11,
              9.06: 0.13,
              9.09: 0.13,
              9.12: 0.1,
              9.15: 0.08,
              9.18: 0.07,
              9.21: 0.08,
              9.24: 0.08,
              9.27: 0.07,
              9.3: 0.05,
              9.33: 0.05,
              9.36: 0.07,
              9.39: 0.08,
              9.42: 0.07,
              9.45: 0.04,
              9.48: 0.02,
              9.51: 0.01,
              9.54: 0.0,
              9.57: 0.0,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.01,
              9.93: 0.03,
              9.96: 0.06,
              9.99: 0.08,
              10.02: 0.09,
              10.05: 0.11,
              10.08: 0.13,
              10.11: 0.16,
              10.14: 0.15,
              10.17: 0.1,
              10.2: 0.05,
              10.23: 0.02,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.01,
              10.44: 0.03,
              10.47: 0.06,
              10.5: 0.08,
              10.53: 0.08,
              10.56: 0.08,
              10.59: 0.08,
              10.62: 0.08,
              10.65: 0.08,
              10.68: 0.05,
              10.71: 0.02,
              10.74: 0.01,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.01,
              11.07: 0.03,
              11.1: 0.05,
              11.13: 0.08,
              11.16: 0.09,
              11.19: 0.1,
              11.22: 0.1,
              11.25: 0.09,
              11.28: 0.07,
              11.31: 0.04,
              11.34: 0.02,
              11.37: 0.02,
              11.4: 0.04,
              11.43: 0.07,
              11.46: 0.08,
              11.49: 0.06,
              11.52: 0.03,
              11.55: 0.01,
              11.58: 0.0,
              11.61: 0.0,
              11.64: 0.0,
              11.67: 0.0,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.0,
              12.12: 0.0,
              12.15: 0.0,
              12.18: 0.0,
              12.21: 0.0,
              12.24: 0.0,
              12.27: 0.0,
              12.3: 0.0,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.01,
              12.51: 0.02,
              12.54: 0.04,
              12.57: 0.07,
              12.6: 0.08,
              12.63: 0.06,
              12.66: 0.04,
              12.69: 0.01,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.0,
              12.81: 0.0,
              12.84: 0.0,
              12.87: 0.0,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.01,
              13.56: 0.04,
              13.59: 0.06,
              13.62: 0.08,
              13.65: 0.07,
              13.68: 0.04,
              13.71: 0.02,
              13.74: 0.01,
              13.77: 0.0,
              13.8: 0.0,
              13.83: 0.0,
              13.86: 0.0,
              13.89: 0.0,
              13.92: 0.0,
              13.95: 0.0,
              13.98: 0.0,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.01,
              14.34: 0.04,
              14.37: 0.09,
              14.4: 0.14,
              14.43: 0.19,
              14.46: 0.2,
              14.49: 0.18,
              14.52: 0.14,
              14.55: 0.13,
              14.58: 0.16,
              14.61: 0.16,
              14.64: 0.12,
              14.67: 0.07,
              14.7: 0.03,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.01,
              14.82: 0.02,
              14.85: 0.04,
              14.88: 0.07,
              14.91: 0.08,
              14.94: 0.06,
              14.97: 0.03,
              '15.0': 0.01,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.01,
              15.15: 0.02,
              15.18: 0.05,
              15.21: 0.07,
              15.24: 0.08,
              15.27: 0.06,
              15.3: 0.03,
              15.33: 0.01,
              15.36: 0.01,
              15.39: 0.03,
              15.42: 0.06,
              15.45: 0.08,
              15.48: 0.08,
              15.51: 0.08,
              15.54: 0.09,
              15.57: 0.1,
              15.6: 0.12,
              15.63: 0.12,
              15.66: 0.1,
              15.69: 0.07,
              15.72: 0.04,
              15.75: 0.01,
              15.78: 0.01,
              15.81: 0.02,
              15.84: 0.04,
              15.87: 0.07,
              15.9: 0.08,
              15.93: 0.06,
              15.96: 0.03,
              15.99: 0.01,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.09,
              16.32: 0.08,
              16.35: 0.08,
              16.38: 0.09,
              16.41: 0.07,
              16.44: 0.06,
              16.47: 0.06,
              16.5: 0.07,
              16.53: 0.08,
              16.56: 0.07,
              16.59: 0.04,
              16.62: 0.02,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.02,
              16.98: 0.04,
              17.01: 0.08,
              17.04: 0.13,
              17.07: 0.14,
              17.1: 0.12,
              17.13: 0.08,
              17.16: 0.04,
              17.19: 0.01,
              17.22: 0.01,
              17.25: 0.02,
              17.28: 0.05,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.06,
              17.4: 0.03,
              17.43: 0.01,
              17.46: 0.0,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.01,
              17.64: 0.03,
              17.67: 0.06,
              17.7: 0.08,
              17.73: 0.07,
              17.76: 0.04,
              17.79: 0.02,
              17.82: 0.01,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.0,
              18.57: 0.0,
              18.6: 0.0,
              18.63: 0.0,
              18.66: 0.0,
              18.69: 0.0,
              18.72: 0.0,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.02,
              20.82: 0.04,
              20.85: 0.07,
              20.88: 0.08,
              20.91: 0.07,
              20.94: 0.04,
              20.97: 0.02,
              '21.0': 0.01,
              21.03: 0.02,
              21.06: 0.05,
              21.09: 0.07,
              21.12: 0.08,
              21.15: 0.06,
              21.18: 0.03,
              21.21: 0.01,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.01,
              22.65: 0.03,
              22.68: 0.06,
              22.71: 0.08,
              22.74: 0.07,
              22.77: 0.05,
              22.8: 0.02,
              22.83: 0.01,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.0,
              24.09: 0.0,
              24.12: 0.0,
              24.15: 0.0,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.01,
              24.54: 0.03,
              24.57: 0.06,
              24.6: 0.08,
              24.63: 0.08,
              24.66: 0.05,
              24.69: 0.02,
              24.72: 0.01,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.01,
              24.93: 0.02,
              24.96: 0.05,
              24.99: 0.08,
              25.02: 0.08,
              25.05: 0.06,
              25.08: 0.03,
              25.11: 0.01,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.01,
              25.74: 0.02,
              25.77: 0.05,
              25.8: 0.08,
              25.83: 0.08,
              25.86: 0.06,
              25.89: 0.03,
              25.92: 0.01,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.01,
              27.21: 0.03,
              27.24: 0.05,
              27.27: 0.08,
              27.3: 0.08,
              27.33: 0.05,
              27.36: 0.03,
              27.39: 0.01,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.01,
              33.96: 0.02,
              33.99: 0.04,
              34.02: 0.07,
              34.05: 0.08,
              34.08: 0.06,
              34.11: 0.04,
              34.14: 0.01,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.01,
              40.74: 0.02,
              40.77: 0.04,
              40.8: 0.07,
              40.83: 0.08,
              40.86: 0.06,
              40.89: 0.04,
              40.92: 0.01,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.0,
              41.58: 0.0,
              41.61: 0.0,
              41.64: 0.0,
              41.67: 0.0,
              41.7: 0.0,
              41.73: 0.0,
              41.76: 0.0,
              41.79: 0.01,
              41.82: 0.02,
              41.85: 0.05,
              41.88: 0.07,
              41.91: 0.08,
              41.94: 0.06,
              41.97: 0.03,
            },
            iteration_median: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.14,
              0.18: 0.18,
              0.21: 0.19,
              0.24: 0.18,
              0.27: 0.15,
              0.3: 0.13,
              0.33: 0.11,
              0.36: 0.1,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.15,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.06,
              1.26: 0.08,
              1.29: 0.08,
              1.32: 0.07,
              1.35: 0.08,
              1.38: 0.12,
              1.41: 0.16,
              1.44: 0.17,
              1.47: 0.16,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.08,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.16,
              2.28: 0.11,
              2.31: 0.07,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.15,
              2.73: 0.12,
              2.76: 0.09,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.01,
              3.36: 0.03,
              3.39: 0.06,
              3.42: 0.11,
              3.45: 0.14,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.06,
              3.57: 0.02,
              3.6: 0.01,
              3.63: 0.0,
              3.66: 0.01,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.02,
              3.9: 0.01,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.03,
              4.65: 0.02,
              4.68: 0.03,
              4.71: 0.06,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.16,
              4.95: 0.1,
              4.98: 0.05,
              5.01: 0.02,
              5.04: 0.02,
              5.07: 0.04,
              5.1: 0.07,
              5.13: 0.06,
              5.16: 0.07,
              5.19: 0.04,
              5.22: 0.03,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.05,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.07,
              5.7: 0.04,
              5.73: 0.02,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.01,
              5.85: 0.02,
              5.88: 0.05,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.01,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.11,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.06,
              7.02: 0.03,
              7.05: 0.01,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.05,
              7.23: 0.08,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.04,
              8.22: 0.02,
              8.25: 0.01,
              8.28: 0.0,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.04,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.07,
              8.55: 0.04,
              8.58: 0.02,
              8.61: 0.03,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.04,
              9.24: 0.08,
              9.27: 0.12,
              9.3: 0.12,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.05,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.07,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.08,
              10.71: 0.05,
              10.74: 0.04,
              10.77: 0.05,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.03,
              10.92: 0.01,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.01,
              11.1: 0.03,
              11.13: 0.06,
              11.16: 0.08,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.01,
              13.62: 0.03,
              13.65: 0.06,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.05,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.07,
              14.31: 0.04,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.05,
              14.49: 0.03,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.06,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.05,
              14.7: 0.02,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.01,
              15.24: 0.02,
              15.27: 0.04,
              15.3: 0.07,
              15.33: 0.08,
              15.36: 0.06,
              15.39: 0.04,
              15.42: 0.01,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.01,
              16.59: 0.03,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.01,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.01,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.05,
              22.89: 0.02,
              22.92: 0.01,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.04,
              26.52: 0.02,
              26.55: 0.01,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
          },
          'north america': {
            iteration_0: {
              '0.0': 0.16,
              0.03: 0.21,
              0.06: 0.21,
              0.09: 0.17,
              0.12: 0.14,
              0.15: 0.14,
              0.18: 0.18,
              0.21: 0.19,
              0.24: 0.18,
              0.27: 0.15,
              0.3: 0.13,
              0.33: 0.11,
              0.36: 0.1,
              0.39: 0.07,
              0.42: 0.04,
              0.45: 0.01,
              0.48: 0.0,
              0.51: 0.0,
              0.54: 0.0,
              0.57: 0.0,
              0.6: 0.0,
              0.63: 0.0,
              0.66: 0.0,
              0.69: 0.01,
              0.72: 0.02,
              0.75: 0.05,
              0.78: 0.07,
              0.81: 0.08,
              0.84: 0.07,
              0.87: 0.06,
              0.9: 0.07,
              0.93: 0.13,
              0.96: 0.22,
              0.99: 0.32,
              1.02: 0.38,
              1.05: 0.35,
              1.08: 0.25,
              1.11: 0.15,
              1.14: 0.07,
              1.17: 0.04,
              1.2: 0.04,
              1.23: 0.06,
              1.26: 0.08,
              1.29: 0.08,
              1.32: 0.07,
              1.35: 0.08,
              1.38: 0.12,
              1.41: 0.16,
              1.44: 0.17,
              1.47: 0.16,
              1.5: 0.17,
              1.53: 0.18,
              1.56: 0.17,
              1.59: 0.15,
              1.62: 0.13,
              1.65: 0.13,
              1.68: 0.13,
              1.71: 0.12,
              1.74: 0.12,
              1.77: 0.14,
              1.8: 0.18,
              1.83: 0.22,
              1.86: 0.21,
              1.89: 0.17,
              1.92: 0.14,
              1.95: 0.16,
              1.98: 0.2,
              2.01: 0.22,
              2.04: 0.19,
              2.07: 0.13,
              2.1: 0.08,
              2.13: 0.08,
              2.16: 0.14,
              2.19: 0.2,
              2.22: 0.22,
              2.25: 0.18,
              2.28: 0.11,
              2.31: 0.07,
              2.34: 0.05,
              2.37: 0.07,
              2.4: 0.08,
              2.43: 0.07,
              2.46: 0.04,
              2.49: 0.02,
              2.52: 0.01,
              2.55: 0.0,
              2.58: 0.01,
              2.61: 0.04,
              2.64: 0.07,
              2.67: 0.11,
              2.7: 0.12,
              2.73: 0.12,
              2.76: 0.09,
              2.79: 0.06,
              2.82: 0.03,
              2.85: 0.01,
              2.88: 0.0,
              2.91: 0.0,
              2.94: 0.0,
              2.97: 0.0,
              '3.0': 0.0,
              3.03: 0.0,
              3.06: 0.0,
              3.09: 0.0,
              3.12: 0.0,
              3.15: 0.0,
              3.18: 0.0,
              3.21: 0.0,
              3.24: 0.0,
              3.27: 0.0,
              3.3: 0.0,
              3.33: 0.01,
              3.36: 0.03,
              3.39: 0.06,
              3.42: 0.11,
              3.45: 0.14,
              3.48: 0.14,
              3.51: 0.1,
              3.54: 0.06,
              3.57: 0.02,
              3.6: 0.01,
              3.63: 0.0,
              3.66: 0.01,
              3.69: 0.04,
              3.72: 0.1,
              3.75: 0.15,
              3.78: 0.16,
              3.81: 0.12,
              3.84: 0.06,
              3.87: 0.02,
              3.9: 0.01,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.0,
              4.02: 0.0,
              4.05: 0.0,
              4.08: 0.0,
              4.11: 0.0,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.0,
              4.23: 0.0,
              4.26: 0.02,
              4.29: 0.05,
              4.32: 0.12,
              4.35: 0.19,
              4.38: 0.23,
              4.41: 0.23,
              4.44: 0.21,
              4.47: 0.22,
              4.5: 0.24,
              4.53: 0.22,
              4.56: 0.15,
              4.59: 0.07,
              4.62: 0.03,
              4.65: 0.02,
              4.68: 0.03,
              4.71: 0.06,
              4.74: 0.09,
              4.77: 0.12,
              4.8: 0.14,
              4.83: 0.17,
              4.86: 0.19,
              4.89: 0.19,
              4.92: 0.16,
              4.95: 0.1,
              4.98: 0.05,
              5.01: 0.02,
              5.04: 0.02,
              5.07: 0.04,
              5.1: 0.07,
              5.13: 0.08,
              5.16: 0.07,
              5.19: 0.04,
              5.22: 0.03,
              5.25: 0.03,
              5.28: 0.06,
              5.31: 0.08,
              5.34: 0.07,
              5.37: 0.05,
              5.4: 0.02,
              5.43: 0.01,
              5.46: 0.0,
              5.49: 0.0,
              5.52: 0.0,
              5.55: 0.02,
              5.58: 0.04,
              5.61: 0.07,
              5.64: 0.08,
              5.67: 0.07,
              5.7: 0.04,
              5.73: 0.02,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.01,
              5.85: 0.02,
              5.88: 0.05,
              5.91: 0.08,
              5.94: 0.08,
              5.97: 0.06,
              '6.0': 0.05,
              6.03: 0.05,
              6.06: 0.07,
              6.09: 0.08,
              6.12: 0.06,
              6.15: 0.03,
              6.18: 0.01,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.0,
              6.33: 0.0,
              6.36: 0.0,
              6.39: 0.0,
              6.42: 0.0,
              6.45: 0.0,
              6.48: 0.01,
              6.51: 0.02,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.09,
              6.66: 0.1,
              6.69: 0.11,
              6.72: 0.13,
              6.75: 0.14,
              6.78: 0.14,
              6.81: 0.14,
              6.84: 0.12,
              6.87: 0.09,
              6.9: 0.08,
              6.93: 0.08,
              6.96: 0.08,
              6.99: 0.06,
              7.02: 0.03,
              7.05: 0.01,
              7.08: 0.0,
              7.11: 0.0,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.05,
              7.23: 0.08,
              7.26: 0.09,
              7.29: 0.08,
              7.32: 0.08,
              7.35: 0.09,
              7.38: 0.08,
              7.41: 0.05,
              7.44: 0.03,
              7.47: 0.01,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.0,
              7.77: 0.0,
              7.8: 0.0,
              7.83: 0.0,
              7.86: 0.0,
              7.89: 0.0,
              7.92: 0.02,
              7.95: 0.04,
              7.98: 0.07,
              8.01: 0.08,
              8.04: 0.08,
              8.07: 0.07,
              8.1: 0.08,
              8.13: 0.08,
              8.16: 0.07,
              8.19: 0.04,
              8.22: 0.02,
              8.25: 0.01,
              8.28: 0.0,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.04,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.07,
              8.55: 0.04,
              8.58: 0.02,
              8.61: 0.0,
              8.64: 0.0,
              8.67: 0.0,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.0,
              8.82: 0.0,
              8.85: 0.0,
              8.88: 0.0,
              8.91: 0.0,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.0,
              9.12: 0.0,
              9.15: 0.0,
              9.18: 0.01,
              9.21: 0.04,
              9.24: 0.08,
              9.27: 0.12,
              9.3: 0.14,
              9.33: 0.12,
              9.36: 0.09,
              9.39: 0.05,
              9.42: 0.04,
              9.45: 0.05,
              9.48: 0.07,
              9.51: 0.08,
              9.54: 0.06,
              9.57: 0.03,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.0,
              9.96: 0.0,
              9.99: 0.0,
              10.02: 0.0,
              10.05: 0.0,
              10.08: 0.0,
              10.11: 0.0,
              10.14: 0.0,
              10.17: 0.0,
              10.2: 0.0,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.01,
              10.44: 0.03,
              10.47: 0.07,
              10.5: 0.11,
              10.53: 0.13,
              10.56: 0.14,
              10.59: 0.13,
              10.62: 0.12,
              10.65: 0.1,
              10.68: 0.08,
              10.71: 0.05,
              10.74: 0.04,
              10.77: 0.05,
              10.8: 0.07,
              10.83: 0.08,
              10.86: 0.06,
              10.89: 0.03,
              10.92: 0.01,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.01,
              11.1: 0.03,
              11.13: 0.06,
              11.16: 0.08,
              11.19: 0.07,
              11.22: 0.05,
              11.25: 0.02,
              11.28: 0.01,
              11.31: 0.0,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.0,
              11.61: 0.0,
              11.64: 0.0,
              11.67: 0.0,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.0,
              12.12: 0.0,
              12.15: 0.0,
              12.18: 0.0,
              12.21: 0.0,
              12.24: 0.0,
              12.27: 0.0,
              12.3: 0.0,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.0,
              12.81: 0.0,
              12.84: 0.0,
              12.87: 0.0,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.01,
              13.62: 0.03,
              13.65: 0.06,
              13.68: 0.1,
              13.71: 0.13,
              13.74: 0.15,
              13.77: 0.17,
              13.8: 0.18,
              13.83: 0.18,
              13.86: 0.15,
              13.89: 0.1,
              13.92: 0.05,
              13.95: 0.03,
              13.98: 0.05,
              14.01: 0.09,
              14.04: 0.14,
              14.07: 0.15,
              14.1: 0.12,
              14.13: 0.07,
              14.16: 0.04,
              14.19: 0.05,
              14.22: 0.07,
              14.25: 0.08,
              14.28: 0.07,
              14.31: 0.04,
              14.34: 0.04,
              14.37: 0.06,
              14.4: 0.08,
              14.43: 0.08,
              14.46: 0.05,
              14.49: 0.03,
              14.52: 0.02,
              14.55: 0.03,
              14.58: 0.06,
              14.61: 0.08,
              14.64: 0.07,
              14.67: 0.05,
              14.7: 0.02,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.01,
              15.24: 0.02,
              15.27: 0.04,
              15.3: 0.07,
              15.33: 0.08,
              15.36: 0.06,
              15.39: 0.04,
              15.42: 0.01,
              15.45: 0.0,
              15.48: 0.0,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.0,
              15.63: 0.0,
              15.66: 0.0,
              15.69: 0.0,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.0,
              16.2: 0.0,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.01,
              16.59: 0.03,
              16.62: 0.06,
              16.65: 0.08,
              16.68: 0.07,
              16.71: 0.04,
              16.74: 0.02,
              16.77: 0.01,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.02,
              16.95: 0.05,
              16.98: 0.09,
              17.01: 0.13,
              17.04: 0.14,
              17.07: 0.12,
              17.1: 0.07,
              17.13: 0.03,
              17.16: 0.01,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.0,
              17.37: 0.0,
              17.4: 0.0,
              17.43: 0.0,
              17.46: 0.0,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.01,
              17.97: 0.02,
              '18.0': 0.06,
              18.03: 0.11,
              18.06: 0.15,
              18.09: 0.15,
              18.12: 0.1,
              18.15: 0.05,
              18.18: 0.02,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.0,
              18.57: 0.0,
              18.6: 0.0,
              18.63: 0.0,
              18.66: 0.0,
              18.69: 0.0,
              18.72: 0.0,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.01,
              19.14: 0.04,
              19.17: 0.06,
              19.2: 0.08,
              19.23: 0.07,
              19.26: 0.04,
              19.29: 0.02,
              19.32: 0.01,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.01,
              20.13: 0.02,
              20.16: 0.05,
              20.19: 0.07,
              20.22: 0.08,
              20.25: 0.06,
              20.28: 0.03,
              20.31: 0.01,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.01,
              21.96: 0.04,
              21.99: 0.06,
              22.02: 0.08,
              22.05: 0.07,
              22.08: 0.04,
              22.11: 0.02,
              22.14: 0.01,
              22.17: 0.03,
              22.2: 0.05,
              22.23: 0.08,
              22.26: 0.08,
              22.29: 0.05,
              22.32: 0.03,
              22.35: 0.01,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.01,
              22.74: 0.03,
              22.77: 0.06,
              22.8: 0.08,
              22.83: 0.07,
              22.86: 0.05,
              22.89: 0.02,
              22.92: 0.01,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.0,
              24.09: 0.0,
              24.12: 0.0,
              24.15: 0.0,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.01,
              24.54: 0.02,
              24.57: 0.04,
              24.6: 0.07,
              24.63: 0.08,
              24.66: 0.07,
              24.69: 0.05,
              24.72: 0.05,
              24.75: 0.07,
              24.78: 0.08,
              24.81: 0.07,
              24.84: 0.04,
              24.87: 0.01,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.01,
              25.29: 0.03,
              25.32: 0.06,
              25.35: 0.08,
              25.38: 0.07,
              25.41: 0.05,
              25.44: 0.02,
              25.47: 0.01,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.01,
              26.37: 0.03,
              26.4: 0.06,
              26.43: 0.08,
              26.46: 0.07,
              26.49: 0.04,
              26.52: 0.02,
              26.55: 0.01,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.02,
              27.63: 0.04,
              27.66: 0.07,
              27.69: 0.08,
              27.72: 0.06,
              27.75: 0.04,
              27.78: 0.01,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.01,
              30.75: 0.04,
              30.78: 0.06,
              30.81: 0.08,
              30.84: 0.07,
              30.87: 0.04,
              30.9: 0.02,
              30.93: 0.01,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.01,
              36.3: 0.03,
              36.33: 0.06,
              36.36: 0.08,
              36.39: 0.07,
              36.42: 0.05,
              36.45: 0.02,
              36.48: 0.01,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.0,
              40.74: 0.0,
              40.77: 0.0,
              40.8: 0.0,
              40.83: 0.0,
              40.86: 0.0,
              40.89: 0.0,
              40.92: 0.0,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.0,
              41.58: 0.0,
              41.61: 0.0,
              41.64: 0.0,
              41.67: 0.0,
              41.7: 0.0,
              41.73: 0.0,
              41.76: 0.0,
              41.79: 0.0,
              41.82: 0.0,
              41.85: 0.0,
              41.88: 0.0,
              41.91: 0.0,
              41.94: 0.0,
              41.97: 0.0,
              '42.0': 0.0,
              42.03: 0.0,
              42.06: 0.0,
              42.09: 0.0,
              42.12: 0.0,
              42.15: 0.0,
              42.18: 0.0,
              42.21: 0.0,
              42.24: 0.0,
              42.27: 0.0,
              42.3: 0.0,
              42.33: 0.0,
              42.36: 0.0,
              42.39: 0.0,
              42.42: 0.0,
              42.45: 0.0,
              42.48: 0.0,
              42.51: 0.0,
              42.54: 0.0,
              42.57: 0.0,
              42.6: 0.0,
              42.63: 0.0,
              42.66: 0.0,
              42.69: 0.0,
              42.72: 0.0,
              42.75: 0.0,
              42.78: 0.0,
              42.81: 0.0,
              42.84: 0.0,
              42.87: 0.0,
              42.9: 0.0,
              42.93: 0.0,
              42.96: 0.0,
              42.99: 0.0,
              43.02: 0.0,
              43.05: 0.0,
              43.08: 0.0,
              43.11: 0.0,
              43.14: 0.0,
              43.17: 0.0,
              43.2: 0.0,
              43.23: 0.0,
              43.26: 0.0,
              43.29: 0.0,
              43.32: 0.0,
              43.35: 0.0,
              43.38: 0.0,
              43.41: 0.0,
              43.44: 0.0,
              43.47: 0.0,
              43.5: 0.0,
              43.53: 0.0,
              43.56: 0.0,
              43.59: 0.0,
              43.62: 0.0,
              43.65: 0.0,
              43.68: 0.0,
              43.71: 0.0,
              43.74: 0.0,
              43.77: 0.0,
              43.8: 0.0,
              43.83: 0.0,
              43.86: 0.0,
              43.89: 0.0,
              43.92: 0.0,
              43.95: 0.0,
              43.98: 0.0,
              44.01: 0.0,
              44.04: 0.0,
              44.07: 0.0,
              44.1: 0.0,
              44.13: 0.0,
              44.16: 0.0,
              44.19: 0.0,
              44.22: 0.0,
              44.25: 0.0,
              44.28: 0.0,
              44.31: 0.0,
              44.34: 0.0,
              44.37: 0.0,
              44.4: 0.0,
              44.43: 0.0,
              44.46: 0.0,
              44.49: 0.0,
              44.52: 0.0,
              44.55: 0.0,
              44.58: 0.0,
              44.61: 0.0,
              44.64: 0.0,
              44.67: 0.0,
              44.7: 0.0,
              44.73: 0.0,
              44.76: 0.0,
              44.79: 0.0,
              44.82: 0.0,
              44.85: 0.0,
              44.88: 0.0,
              44.91: 0.0,
              44.94: 0.0,
              44.97: 0.0,
              '45.0': 0.0,
              45.03: 0.0,
              45.06: 0.0,
              45.09: 0.0,
              45.12: 0.0,
              45.15: 0.0,
              45.18: 0.0,
              45.21: 0.0,
              45.24: 0.0,
              45.27: 0.0,
              45.3: 0.0,
              45.33: 0.0,
              45.36: 0.0,
              45.39: 0.0,
              45.42: 0.0,
              45.45: 0.0,
              45.48: 0.0,
              45.51: 0.0,
              45.54: 0.0,
              45.57: 0.0,
              45.6: 0.0,
              45.63: 0.0,
              45.66: 0.0,
              45.69: 0.0,
              45.72: 0.0,
              45.75: 0.0,
              45.78: 0.0,
              45.81: 0.0,
              45.84: 0.0,
              45.87: 0.0,
              45.9: 0.0,
              45.93: 0.0,
              45.96: 0.0,
              45.99: 0.0,
              46.02: 0.0,
              46.05: 0.0,
              46.08: 0.0,
              46.11: 0.0,
              46.14: 0.0,
              46.17: 0.0,
              46.2: 0.0,
              46.23: 0.0,
              46.26: 0.0,
              46.29: 0.0,
              46.32: 0.0,
              46.35: 0.0,
              46.38: 0.0,
              46.41: 0.0,
              46.44: 0.0,
              46.47: 0.0,
              46.5: 0.0,
              46.53: 0.0,
              46.56: 0.0,
              46.59: 0.0,
              46.62: 0.0,
              46.65: 0.0,
              46.68: 0.0,
              46.71: 0.0,
              46.74: 0.0,
              46.77: 0.0,
              46.8: 0.0,
              46.83: 0.0,
              46.86: 0.0,
              46.89: 0.0,
              46.92: 0.0,
              46.95: 0.0,
              46.98: 0.0,
              47.01: 0.0,
              47.04: 0.0,
              47.07: 0.0,
              47.1: 0.0,
              47.13: 0.0,
              47.16: 0.0,
              47.19: 0.0,
              47.22: 0.0,
              47.25: 0.0,
              47.28: 0.0,
              47.31: 0.0,
              47.34: 0.0,
              47.37: 0.0,
              47.4: 0.0,
              47.43: 0.0,
              47.46: 0.0,
              47.49: 0.0,
              47.52: 0.0,
              47.55: 0.0,
              47.58: 0.0,
              47.61: 0.0,
              47.64: 0.0,
              47.67: 0.0,
              47.7: 0.0,
              47.73: 0.0,
              47.76: 0.0,
              47.79: 0.0,
              47.82: 0.0,
              47.85: 0.0,
              47.88: 0.0,
              47.91: 0.0,
              47.94: 0.0,
              47.97: 0.0,
              '48.0': 0.0,
              48.03: 0.0,
              48.06: 0.0,
              48.09: 0.0,
              48.12: 0.0,
              48.15: 0.0,
              48.18: 0.0,
              48.21: 0.0,
              48.24: 0.0,
              48.27: 0.0,
              48.3: 0.0,
              48.33: 0.0,
              48.36: 0.0,
              48.39: 0.0,
              48.42: 0.0,
              48.45: 0.0,
              48.48: 0.0,
              48.51: 0.0,
              48.54: 0.0,
              48.57: 0.0,
              48.6: 0.0,
              48.63: 0.0,
              48.66: 0.0,
              48.69: 0.0,
              48.72: 0.0,
              48.75: 0.0,
              48.78: 0.0,
              48.81: 0.0,
              48.84: 0.0,
              48.87: 0.0,
              48.9: 0.0,
              48.93: 0.0,
              48.96: 0.0,
              48.99: 0.0,
              49.02: 0.0,
              49.05: 0.0,
              49.08: 0.0,
              49.11: 0.0,
              49.14: 0.0,
              49.17: 0.0,
              49.2: 0.0,
              49.23: 0.0,
              49.26: 0.0,
              49.29: 0.0,
              49.32: 0.0,
              49.35: 0.0,
              49.38: 0.0,
              49.41: 0.0,
              49.44: 0.0,
              49.47: 0.0,
              49.5: 0.0,
              49.53: 0.0,
              49.56: 0.0,
              49.59: 0.0,
              49.62: 0.0,
              49.65: 0.0,
              49.68: 0.0,
              49.71: 0.0,
              49.74: 0.0,
              49.77: 0.0,
              49.8: 0.0,
              49.83: 0.0,
              49.86: 0.0,
              49.89: 0.0,
              49.92: 0.0,
              49.95: 0.0,
              49.98: 0.0,
              50.01: 0.0,
              50.04: 0.0,
              50.07: 0.0,
              50.1: 0.0,
              50.13: 0.0,
              50.16: 0.0,
              50.19: 0.0,
              50.22: 0.01,
              50.25: 0.03,
              50.28: 0.06,
              50.31: 0.08,
              50.34: 0.07,
              50.37: 0.05,
              50.4: 0.02,
              50.43: 0.01,
              50.46: 0.0,
              50.49: 0.0,
              50.52: 0.0,
              50.55: 0.0,
              50.58: 0.0,
              50.61: 0.0,
              50.64: 0.0,
              50.67: 0.0,
              50.7: 0.0,
              50.73: 0.0,
              50.76: 0.0,
              50.79: 0.0,
              50.82: 0.0,
              50.85: 0.0,
              50.88: 0.0,
              50.91: 0.0,
              50.94: 0.0,
              50.97: 0.0,
              '51.0': 0.0,
              51.03: 0.0,
              51.06: 0.0,
              51.09: 0.0,
              51.12: 0.0,
              51.15: 0.0,
              51.18: 0.0,
              51.21: 0.0,
              51.24: 0.0,
              51.27: 0.0,
              51.3: 0.0,
              51.33: 0.0,
              51.36: 0.0,
              51.39: 0.0,
              51.42: 0.0,
              51.45: 0.0,
              51.48: 0.0,
              51.51: 0.0,
              51.54: 0.0,
              51.57: 0.0,
              51.6: 0.0,
              51.63: 0.0,
              51.66: 0.0,
              51.69: 0.0,
              51.72: 0.0,
              51.75: 0.0,
              51.78: 0.0,
              51.81: 0.0,
              51.84: 0.0,
              51.87: 0.0,
              51.9: 0.0,
              51.93: 0.0,
              51.96: 0.0,
              51.99: 0.0,
              52.02: 0.0,
              52.05: 0.0,
              52.08: 0.0,
              52.11: 0.0,
              52.14: 0.0,
              52.17: 0.0,
              52.2: 0.0,
              52.23: 0.0,
              52.26: 0.0,
              52.29: 0.0,
              52.32: 0.0,
              52.35: 0.0,
              52.38: 0.0,
              52.41: 0.0,
              52.44: 0.0,
              52.47: 0.0,
              52.5: 0.0,
              52.53: 0.0,
              52.56: 0.0,
              52.59: 0.0,
              52.62: 0.0,
              52.65: 0.0,
              52.68: 0.0,
              52.71: 0.0,
              52.74: 0.0,
              52.77: 0.0,
              52.8: 0.0,
              52.83: 0.0,
              52.86: 0.0,
              52.89: 0.0,
              52.92: 0.0,
              52.95: 0.0,
              52.98: 0.0,
              53.01: 0.0,
              53.04: 0.0,
              53.07: 0.0,
              53.1: 0.0,
              53.13: 0.0,
              53.16: 0.0,
              53.19: 0.0,
              53.22: 0.0,
              53.25: 0.0,
              53.28: 0.0,
              53.31: 0.0,
              53.34: 0.0,
              53.37: 0.0,
              53.4: 0.0,
              53.43: 0.0,
              53.46: 0.0,
              53.49: 0.0,
              53.52: 0.0,
              53.55: 0.0,
              53.58: 0.0,
              53.61: 0.0,
              53.64: 0.0,
              53.67: 0.0,
              53.7: 0.0,
              53.73: 0.0,
              53.76: 0.0,
              53.79: 0.0,
              53.82: 0.0,
              53.85: 0.0,
              53.88: 0.0,
              53.91: 0.0,
              53.94: 0.0,
              53.97: 0.0,
              '54.0': 0.0,
              54.03: 0.0,
              54.06: 0.0,
              54.09: 0.0,
              54.12: 0.0,
              54.15: 0.0,
              54.18: 0.0,
              54.21: 0.0,
              54.24: 0.0,
              54.27: 0.0,
              54.3: 0.0,
              54.33: 0.0,
              54.36: 0.0,
              54.39: 0.0,
              54.42: 0.0,
              54.45: 0.0,
              54.48: 0.0,
              54.51: 0.0,
              54.54: 0.0,
              54.57: 0.0,
              54.6: 0.0,
              54.63: 0.0,
              54.66: 0.0,
              54.69: 0.0,
              54.72: 0.0,
              54.75: 0.0,
              54.78: 0.0,
              54.81: 0.0,
              54.84: 0.0,
              54.87: 0.0,
              54.9: 0.0,
              54.93: 0.0,
              54.96: 0.0,
              54.99: 0.0,
              55.02: 0.0,
              55.05: 0.0,
              55.08: 0.0,
              55.11: 0.0,
              55.14: 0.0,
              55.17: 0.0,
              55.2: 0.0,
              55.23: 0.0,
              55.26: 0.0,
              55.29: 0.0,
              55.32: 0.0,
              55.35: 0.0,
              55.38: 0.0,
              55.41: 0.0,
              55.44: 0.0,
              55.47: 0.0,
              55.5: 0.0,
              55.53: 0.0,
              55.56: 0.0,
              55.59: 0.0,
              55.62: 0.0,
              55.65: 0.0,
              55.68: 0.0,
              55.71: 0.0,
              55.74: 0.0,
              55.77: 0.0,
              55.8: 0.0,
              55.83: 0.0,
              55.86: 0.0,
              55.89: 0.0,
              55.92: 0.0,
              55.95: 0.0,
              55.98: 0.0,
              56.01: 0.0,
              56.04: 0.0,
              56.07: 0.0,
              56.1: 0.0,
              56.13: 0.0,
              56.16: 0.0,
              56.19: 0.0,
              56.22: 0.0,
              56.25: 0.0,
              56.28: 0.0,
              56.31: 0.0,
              56.34: 0.0,
              56.37: 0.0,
              56.4: 0.0,
              56.43: 0.0,
              56.46: 0.0,
              56.49: 0.0,
              56.52: 0.0,
              56.55: 0.0,
              56.58: 0.0,
              56.61: 0.0,
              56.64: 0.0,
              56.67: 0.0,
              56.7: 0.0,
              56.73: 0.0,
              56.76: 0.0,
              56.79: 0.0,
              56.82: 0.0,
              56.85: 0.0,
              56.88: 0.0,
              56.91: 0.0,
              56.94: 0.0,
              56.97: 0.0,
              '57.0': 0.0,
              57.03: 0.0,
              57.06: 0.0,
              57.09: 0.0,
              57.12: 0.0,
              57.15: 0.0,
              57.18: 0.0,
              57.21: 0.0,
              57.24: 0.0,
              57.27: 0.0,
              57.3: 0.0,
              57.33: 0.0,
              57.36: 0.0,
              57.39: 0.0,
              57.42: 0.0,
              57.45: 0.0,
              57.48: 0.0,
              57.51: 0.0,
              57.54: 0.0,
              57.57: 0.0,
              57.6: 0.0,
              57.63: 0.0,
              57.66: 0.0,
              57.69: 0.0,
              57.72: 0.0,
              57.75: 0.0,
              57.78: 0.0,
              57.81: 0.0,
              57.84: 0.0,
              57.87: 0.0,
              57.9: 0.0,
              57.93: 0.0,
              57.96: 0.0,
              57.99: 0.0,
              58.02: 0.0,
              58.05: 0.0,
              58.08: 0.0,
              58.11: 0.0,
              58.14: 0.0,
              58.17: 0.0,
              58.2: 0.0,
              58.23: 0.0,
              58.26: 0.0,
              58.29: 0.0,
              58.32: 0.0,
              58.35: 0.0,
              58.38: 0.0,
              58.41: 0.0,
              58.44: 0.0,
              58.47: 0.0,
              58.5: 0.0,
              58.53: 0.0,
              58.56: 0.0,
              58.59: 0.0,
              58.62: 0.0,
              58.65: 0.0,
              58.68: 0.0,
              58.71: 0.0,
              58.74: 0.0,
              58.77: 0.0,
              58.8: 0.0,
              58.83: 0.0,
              58.86: 0.0,
              58.89: 0.0,
              58.92: 0.0,
              58.95: 0.0,
              58.98: 0.0,
              59.01: 0.0,
              59.04: 0.0,
              59.07: 0.0,
              59.1: 0.0,
              59.13: 0.0,
              59.16: 0.0,
              59.19: 0.01,
              59.22: 0.04,
              59.25: 0.07,
              59.28: 0.08,
              59.31: 0.07,
              59.34: 0.04,
              59.37: 0.02,
              59.4: 0.0,
              59.43: 0.0,
              59.46: 0.0,
              59.49: 0.0,
              59.52: 0.0,
              59.55: 0.0,
              59.58: 0.0,
              59.61: 0.0,
              59.64: 0.0,
              59.67: 0.0,
              59.7: 0.0,
              59.73: 0.0,
              59.76: 0.0,
              59.79: 0.0,
              59.82: 0.0,
              59.85: 0.0,
              59.88: 0.0,
              59.91: 0.0,
              59.94: 0.0,
              59.97: 0.0,
              '60.0': 0.0,
              60.03: 0.0,
              60.06: 0.0,
              60.09: 0.0,
              60.12: 0.0,
              60.15: 0.0,
              60.18: 0.0,
              60.21: 0.0,
              60.24: 0.0,
              60.27: 0.0,
              60.3: 0.0,
              60.33: 0.0,
              60.36: 0.0,
              60.39: 0.0,
              60.42: 0.0,
              60.45: 0.0,
              60.48: 0.0,
              60.51: 0.0,
              60.54: 0.0,
              60.57: 0.0,
              60.6: 0.0,
              60.63: 0.0,
              60.66: 0.0,
              60.69: 0.0,
              60.72: 0.0,
              60.75: 0.0,
              60.78: 0.0,
              60.81: 0.0,
              60.84: 0.0,
              60.87: 0.0,
              60.9: 0.0,
              60.93: 0.0,
              60.96: 0.0,
              60.99: 0.0,
              61.02: 0.0,
              61.05: 0.0,
              61.08: 0.0,
              61.11: 0.0,
              61.14: 0.0,
              61.17: 0.0,
              61.2: 0.0,
              61.23: 0.0,
              61.26: 0.0,
              61.29: 0.0,
              61.32: 0.0,
              61.35: 0.0,
              61.38: 0.0,
              61.41: 0.0,
              61.44: 0.0,
              61.47: 0.0,
              61.5: 0.0,
              61.53: 0.0,
              61.56: 0.0,
              61.59: 0.0,
              61.62: 0.0,
              61.65: 0.0,
              61.68: 0.0,
              61.71: 0.0,
              61.74: 0.0,
              61.77: 0.0,
              61.8: 0.0,
              61.83: 0.0,
              61.86: 0.0,
              61.89: 0.0,
              61.92: 0.0,
              61.95: 0.0,
              61.98: 0.0,
              62.01: 0.0,
              62.04: 0.0,
              62.07: 0.0,
              62.1: 0.0,
              62.13: 0.0,
              62.16: 0.0,
              62.19: 0.0,
              62.22: 0.0,
              62.25: 0.0,
              62.28: 0.0,
              62.31: 0.0,
              62.34: 0.0,
              62.37: 0.0,
              62.4: 0.0,
              62.43: 0.0,
              62.46: 0.0,
              62.49: 0.0,
              62.52: 0.0,
              62.55: 0.0,
              62.58: 0.0,
              62.61: 0.0,
              62.64: 0.0,
              62.67: 0.0,
              62.7: 0.0,
              62.73: 0.0,
              62.76: 0.0,
              62.79: 0.0,
              62.82: 0.0,
              62.85: 0.0,
              62.88: 0.0,
              62.91: 0.0,
              62.94: 0.0,
              62.97: 0.0,
              '63.0': 0.0,
              63.03: 0.0,
              63.06: 0.0,
              63.09: 0.0,
              63.12: 0.0,
              63.15: 0.0,
              63.18: 0.0,
              63.21: 0.0,
              63.24: 0.0,
              63.27: 0.0,
              63.3: 0.0,
              63.33: 0.0,
              63.36: 0.0,
              63.39: 0.0,
              63.42: 0.0,
              63.45: 0.0,
              63.48: 0.0,
              63.51: 0.0,
              63.54: 0.0,
              63.57: 0.0,
              63.6: 0.0,
              63.63: 0.0,
              63.66: 0.0,
              63.69: 0.0,
              63.72: 0.0,
              63.75: 0.0,
              63.78: 0.0,
              63.81: 0.0,
              63.84: 0.0,
              63.87: 0.0,
              63.9: 0.0,
              63.93: 0.0,
              63.96: 0.0,
              63.99: 0.0,
              64.02: 0.0,
              64.05: 0.0,
              64.08: 0.0,
              64.11: 0.0,
              64.14: 0.0,
              64.17: 0.0,
              64.2: 0.0,
              64.23: 0.0,
              64.26: 0.0,
              64.29: 0.0,
              64.32: 0.0,
              64.35: 0.0,
              64.38: 0.0,
              64.41: 0.0,
              64.44: 0.0,
              64.47: 0.0,
              64.5: 0.0,
              64.53: 0.0,
              64.56: 0.0,
              64.59: 0.0,
              64.62: 0.0,
              64.65: 0.0,
              64.68: 0.0,
              64.71: 0.0,
              64.74: 0.0,
              64.77: 0.0,
              64.8: 0.0,
              64.83: 0.0,
              64.86: 0.0,
              64.89: 0.0,
              64.92: 0.0,
              64.95: 0.0,
              64.98: 0.0,
              65.01: 0.0,
              65.04: 0.0,
              65.07: 0.0,
              65.1: 0.0,
              65.13: 0.0,
              65.16: 0.0,
              65.19: 0.0,
              65.22: 0.0,
              65.25: 0.0,
              65.28: 0.0,
              65.31: 0.0,
              65.34: 0.0,
              65.37: 0.0,
              65.4: 0.0,
              65.43: 0.0,
              65.46: 0.0,
              65.49: 0.0,
              65.52: 0.0,
              65.55: 0.0,
              65.58: 0.0,
              65.61: 0.0,
              65.64: 0.0,
              65.67: 0.0,
              65.7: 0.0,
              65.73: 0.0,
              65.76: 0.0,
              65.79: 0.0,
              65.82: 0.0,
              65.85: 0.0,
              65.88: 0.0,
              65.91: 0.0,
              65.94: 0.0,
              65.97: 0.0,
              '66.0': 0.0,
              66.03: 0.0,
              66.06: 0.0,
              66.09: 0.0,
              66.12: 0.0,
              66.15: 0.0,
              66.18: 0.0,
              66.21: 0.0,
              66.24: 0.0,
              66.27: 0.0,
              66.3: 0.0,
              66.33: 0.0,
              66.36: 0.0,
              66.39: 0.0,
              66.42: 0.0,
              66.45: 0.0,
              66.48: 0.0,
              66.51: 0.0,
              66.54: 0.0,
              66.57: 0.0,
              66.6: 0.0,
              66.63: 0.0,
              66.66: 0.0,
              66.69: 0.0,
              66.72: 0.0,
              66.75: 0.0,
              66.78: 0.0,
              66.81: 0.0,
              66.84: 0.0,
              66.87: 0.0,
              66.9: 0.0,
              66.93: 0.0,
              66.96: 0.0,
              66.99: 0.0,
              67.02: 0.0,
              67.05: 0.0,
              67.08: 0.0,
              67.11: 0.0,
              67.14: 0.0,
              67.17: 0.0,
              67.2: 0.0,
              67.23: 0.0,
              67.26: 0.0,
              67.29: 0.0,
              67.32: 0.0,
              67.35: 0.0,
              67.38: 0.0,
              67.41: 0.0,
              67.44: 0.0,
              67.47: 0.0,
              67.5: 0.0,
              67.53: 0.0,
              67.56: 0.0,
              67.59: 0.0,
              67.62: 0.0,
              67.65: 0.0,
              67.68: 0.0,
              67.71: 0.0,
              67.74: 0.0,
              67.77: 0.0,
              67.8: 0.0,
              67.83: 0.0,
              67.86: 0.0,
              67.89: 0.0,
              67.92: 0.0,
              67.95: 0.0,
              67.98: 0.0,
              68.01: 0.0,
              68.04: 0.0,
              68.07: 0.0,
              68.1: 0.0,
              68.13: 0.0,
              68.16: 0.0,
              68.19: 0.0,
              68.22: 0.0,
              68.25: 0.0,
              68.28: 0.0,
              68.31: 0.0,
              68.34: 0.0,
              68.37: 0.0,
              68.4: 0.0,
              68.43: 0.0,
              68.46: 0.0,
              68.49: 0.0,
              68.52: 0.0,
              68.55: 0.0,
              68.58: 0.0,
              68.61: 0.0,
              68.64: 0.0,
              68.67: 0.0,
              68.7: 0.0,
              68.73: 0.0,
              68.76: 0.0,
              68.79: 0.0,
              68.82: 0.0,
              68.85: 0.0,
              68.88: 0.0,
              68.91: 0.0,
              68.94: 0.0,
              68.97: 0.0,
              '69.0': 0.0,
              69.03: 0.0,
              69.06: 0.0,
              69.09: 0.0,
              69.12: 0.0,
              69.15: 0.0,
              69.18: 0.0,
              69.21: 0.0,
              69.24: 0.0,
              69.27: 0.0,
              69.3: 0.0,
              69.33: 0.0,
              69.36: 0.0,
              69.39: 0.0,
              69.42: 0.0,
              69.45: 0.0,
              69.48: 0.0,
              69.51: 0.0,
              69.54: 0.0,
              69.57: 0.0,
              69.6: 0.0,
              69.63: 0.0,
              69.66: 0.0,
              69.69: 0.0,
              69.72: 0.0,
              69.75: 0.0,
              69.78: 0.0,
              69.81: 0.0,
              69.84: 0.0,
              69.87: 0.0,
              69.9: 0.0,
              69.93: 0.0,
              69.96: 0.0,
              69.99: 0.0,
              70.02: 0.0,
              70.05: 0.0,
              70.08: 0.0,
              70.11: 0.0,
              70.14: 0.0,
              70.17: 0.0,
              70.2: 0.0,
              70.23: 0.0,
              70.26: 0.0,
              70.29: 0.0,
              70.32: 0.0,
              70.35: 0.0,
              70.38: 0.0,
              70.41: 0.0,
              70.44: 0.0,
              70.47: 0.0,
              70.5: 0.0,
              70.53: 0.0,
              70.56: 0.0,
              70.59: 0.0,
              70.62: 0.0,
              70.65: 0.0,
              70.68: 0.0,
              70.71: 0.0,
              70.74: 0.0,
              70.77: 0.0,
              70.8: 0.0,
              70.83: 0.0,
              70.86: 0.0,
              70.89: 0.0,
              70.92: 0.0,
              70.95: 0.0,
              70.98: 0.0,
              71.01: 0.0,
              71.04: 0.0,
              71.07: 0.0,
              71.1: 0.0,
              71.13: 0.0,
              71.16: 0.0,
              71.19: 0.0,
              71.22: 0.0,
              71.25: 0.0,
              71.28: 0.0,
              71.31: 0.0,
              71.34: 0.0,
              71.37: 0.0,
              71.4: 0.0,
              71.43: 0.0,
              71.46: 0.0,
              71.49: 0.0,
              71.52: 0.0,
              71.55: 0.0,
              71.58: 0.0,
              71.61: 0.0,
              71.64: 0.0,
              71.67: 0.0,
              71.7: 0.0,
              71.73: 0.0,
              71.76: 0.0,
              71.79: 0.0,
              71.82: 0.0,
              71.85: 0.0,
              71.88: 0.0,
              71.91: 0.0,
              71.94: 0.0,
              71.97: 0.0,
              '72.0': 0.0,
              72.03: 0.0,
              72.06: 0.0,
              72.09: 0.0,
              72.12: 0.0,
              72.15: 0.0,
              72.18: 0.0,
              72.21: 0.0,
              72.24: 0.0,
              72.27: 0.0,
              72.3: 0.0,
              72.33: 0.0,
              72.36: 0.0,
              72.39: 0.0,
              72.42: 0.0,
              72.45: 0.0,
              72.48: 0.0,
              72.51: 0.0,
              72.54: 0.0,
              72.57: 0.0,
              72.6: 0.0,
              72.63: 0.0,
              72.66: 0.0,
              72.69: 0.0,
              72.72: 0.0,
              72.75: 0.0,
              72.78: 0.0,
              72.81: 0.0,
              72.84: 0.0,
              72.87: 0.0,
              72.9: 0.0,
              72.93: 0.0,
              72.96: 0.0,
              72.99: 0.0,
              73.02: 0.0,
              73.05: 0.0,
              73.08: 0.01,
              73.11: 0.02,
              73.14: 0.05,
              73.17: 0.07,
              73.2: 0.08,
              73.23: 0.06,
              73.26: 0.03,
              73.29: 0.01,
              73.32: 0.0,
              73.35: 0.0,
              73.38: 0.0,
              73.41: 0.0,
              73.44: 0.0,
              73.47: 0.0,
              73.5: 0.0,
              73.53: 0.0,
              73.56: 0.0,
              73.59: 0.0,
              73.62: 0.0,
              73.65: 0.0,
              73.68: 0.0,
              73.71: 0.0,
              73.74: 0.0,
              73.77: 0.0,
              73.8: 0.0,
              73.83: 0.0,
              73.86: 0.0,
              73.89: 0.0,
              73.92: 0.0,
              73.95: 0.0,
              73.98: 0.0,
            },
            iteration_1: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.12,
              0.18: 0.1,
              0.21: 0.1,
              0.24: 0.11,
              0.27: 0.13,
              0.3: 0.15,
              0.33: 0.17,
              0.36: 0.18,
              0.39: 0.18,
              0.42: 0.19,
              0.45: 0.22,
              0.48: 0.25,
              0.51: 0.25,
              0.54: 0.22,
              0.57: 0.18,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.2,
              1.14: 0.17,
              1.17: 0.13,
              1.2: 0.1,
              1.23: 0.09,
              1.26: 0.13,
              1.29: 0.17,
              1.32: 0.19,
              1.35: 0.17,
              1.38: 0.13,
              1.41: 0.08,
              1.44: 0.05,
              1.47: 0.03,
              1.5: 0.04,
              1.53: 0.06,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.05,
              1.83: 0.06,
              1.86: 0.08,
              1.89: 0.09,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.1,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.15,
              2.28: 0.11,
              2.31: 0.06,
              2.34: 0.02,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.07,
              2.49: 0.08,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.18,
              2.73: 0.17,
              2.76: 0.16,
              2.79: 0.16,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.04,
              3.15: 0.07,
              3.18: 0.08,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.0,
              3.36: 0.0,
              3.39: 0.0,
              3.42: 0.0,
              3.45: 0.0,
              3.48: 0.0,
              3.51: 0.0,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.0,
              3.87: 0.0,
              3.9: 0.0,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.08,
              4.11: 0.1,
              4.14: 0.1,
              4.17: 0.1,
              4.2: 0.09,
              4.23: 0.08,
              4.26: 0.07,
              4.29: 0.09,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.05,
              4.47: 0.02,
              4.5: 0.01,
              4.53: 0.0,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.04,
              4.65: 0.06,
              4.68: 0.08,
              4.71: 0.07,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.17,
              4.95: 0.13,
              4.98: 0.07,
              5.01: 0.03,
              5.04: 0.01,
              5.07: 0.01,
              5.1: 0.03,
              5.13: 0.06,
              5.16: 0.08,
              5.19: 0.07,
              5.22: 0.05,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.12,
              5.4: 0.16,
              5.43: 0.15,
              5.46: 0.11,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.12,
              5.7: 0.15,
              5.73: 0.14,
              5.76: 0.09,
              5.79: 0.05,
              5.82: 0.03,
              5.85: 0.05,
              5.88: 0.07,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.04,
              6.21: 0.07,
              6.24: 0.1,
              6.27: 0.12,
              6.3: 0.12,
              6.33: 0.1,
              6.36: 0.09,
              6.39: 0.08,
              6.42: 0.09,
              6.45: 0.08,
              6.48: 0.05,
              6.51: 0.03,
              6.54: 0.02,
              6.57: 0.04,
              6.6: 0.08,
              6.63: 0.14,
              6.66: 0.16,
              6.69: 0.13,
              6.72: 0.07,
              6.75: 0.03,
              6.78: 0.01,
              6.81: 0.0,
              6.84: 0.0,
              6.87: 0.0,
              6.9: 0.01,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.08,
              7.02: 0.07,
              7.05: 0.05,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.04,
              7.23: 0.07,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.07,
              7.89: 0.04,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.08,
              8.22: 0.07,
              8.25: 0.04,
              8.28: 0.02,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.03,
              8.46: 0.07,
              8.49: 0.11,
              8.52: 0.13,
              8.55: 0.13,
              8.58: 0.1,
              8.61: 0.06,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.07,
              8.91: 0.08,
              8.94: 0.06,
              8.97: 0.03,
              '9.0': 0.02,
              9.03: 0.03,
              9.06: 0.05,
              9.09: 0.07,
              9.12: 0.08,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.02,
              9.24: 0.03,
              9.27: 0.05,
              9.3: 0.08,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.06,
              9.42: 0.07,
              9.45: 0.08,
              9.48: 0.07,
              9.51: 0.05,
              9.54: 0.02,
              9.57: 0.01,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.07,
              10.02: 0.12,
              10.05: 0.14,
              10.08: 0.14,
              10.11: 0.12,
              10.14: 0.1,
              10.17: 0.09,
              10.2: 0.08,
              10.23: 0.06,
              10.26: 0.03,
              10.29: 0.01,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.0,
              10.47: 0.0,
              10.5: 0.0,
              10.53: 0.0,
              10.56: 0.0,
              10.59: 0.0,
              10.62: 0.0,
              10.65: 0.0,
              10.68: 0.0,
              10.71: 0.0,
              10.74: 0.0,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.0,
              11.1: 0.01,
              11.13: 0.02,
              11.16: 0.04,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.05,
              11.67: 0.1,
              11.7: 0.15,
              11.73: 0.2,
              11.76: 0.24,
              11.79: 0.24,
              11.82: 0.19,
              11.85: 0.14,
              11.88: 0.11,
              11.91: 0.09,
              11.94: 0.06,
              11.97: 0.03,
              '12.0': 0.01,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.07,
              12.21: 0.08,
              12.24: 0.06,
              12.27: 0.04,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.06,
              12.87: 0.08,
              12.9: 0.07,
              12.93: 0.05,
              12.96: 0.05,
              12.99: 0.06,
              13.02: 0.08,
              13.05: 0.08,
              13.08: 0.05,
              13.11: 0.03,
              13.14: 0.03,
              13.17: 0.05,
              13.2: 0.07,
              13.23: 0.08,
              13.26: 0.06,
              13.29: 0.03,
              13.32: 0.01,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.0,
              13.62: 0.0,
              13.65: 0.0,
              13.68: 0.0,
              13.71: 0.0,
              13.74: 0.0,
              13.77: 0.01,
              13.8: 0.02,
              13.83: 0.04,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.01,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.0,
              14.34: 0.0,
              14.37: 0.0,
              14.4: 0.0,
              14.43: 0.0,
              14.46: 0.0,
              14.49: 0.01,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.07,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.03,
              14.7: 0.01,
              14.73: 0.0,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.01,
              14.88: 0.02,
              14.91: 0.04,
              14.94: 0.07,
              14.97: 0.08,
              '15.0': 0.06,
              15.03: 0.04,
              15.06: 0.01,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.0,
              15.24: 0.0,
              15.27: 0.0,
              15.3: 0.0,
              15.33: 0.0,
              15.36: 0.0,
              15.39: 0.0,
              15.42: 0.0,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.03,
              15.54: 0.06,
              15.57: 0.08,
              15.6: 0.07,
              15.63: 0.05,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.07,
              16.32: 0.05,
              16.35: 0.02,
              16.38: 0.01,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.0,
              16.59: 0.0,
              16.62: 0.0,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.0,
              17.16: 0.0,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.08,
              17.43: 0.12,
              17.46: 0.15,
              17.49: 0.16,
              17.52: 0.15,
              17.55: 0.13,
              17.58: 0.09,
              17.61: 0.05,
              17.64: 0.02,
              17.67: 0.01,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.01,
              18.42: 0.02,
              18.45: 0.05,
              18.48: 0.08,
              18.51: 0.09,
              18.54: 0.08,
              18.57: 0.08,
              18.6: 0.09,
              18.63: 0.08,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.01,
              19.5: 0.02,
              19.53: 0.04,
              19.56: 0.07,
              19.59: 0.08,
              19.62: 0.06,
              19.65: 0.03,
              19.68: 0.01,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.08,
              22.89: 0.07,
              22.92: 0.04,
              22.95: 0.02,
              22.98: 0.01,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.01,
              23.97: 0.03,
              '24.0': 0.05,
              24.03: 0.08,
              24.06: 0.08,
              24.09: 0.05,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.01,
              25.08: 0.03,
              25.11: 0.06,
              25.14: 0.08,
              25.17: 0.07,
              25.2: 0.05,
              25.23: 0.02,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.01,
              26.94: 0.03,
              26.97: 0.05,
              '27.0': 0.08,
              27.03: 0.08,
              27.06: 0.05,
              27.09: 0.03,
              27.12: 0.01,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.01,
              28.89: 0.04,
              28.92: 0.06,
              28.95: 0.08,
              28.98: 0.07,
              29.01: 0.04,
              29.04: 0.02,
              29.07: 0.01,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.02,
              30.3: 0.04,
              30.33: 0.07,
              30.36: 0.08,
              30.39: 0.07,
              30.42: 0.04,
              30.45: 0.02,
              30.48: 0.01,
              30.51: 0.01,
              30.54: 0.02,
              30.57: 0.05,
              30.6: 0.07,
              30.63: 0.08,
              30.66: 0.06,
              30.69: 0.03,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.02,
              33.96: 0.05,
              33.99: 0.09,
              34.02: 0.13,
              34.05: 0.14,
              34.08: 0.12,
              34.11: 0.07,
              34.14: 0.03,
              34.17: 0.01,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.0,
              40.74: 0.0,
              40.77: 0.0,
              40.8: 0.0,
              40.83: 0.0,
              40.86: 0.0,
              40.89: 0.0,
              40.92: 0.0,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.01,
              41.58: 0.03,
              41.61: 0.05,
              41.64: 0.08,
              41.67: 0.08,
              41.7: 0.05,
              41.73: 0.03,
              41.76: 0.01,
              41.79: 0.0,
              41.82: 0.0,
              41.85: 0.0,
              41.88: 0.0,
              41.91: 0.0,
              41.94: 0.0,
              41.97: 0.0,
              '42.0': 0.0,
              42.03: 0.0,
              42.06: 0.0,
              42.09: 0.0,
              42.12: 0.0,
              42.15: 0.0,
              42.18: 0.0,
              42.21: 0.0,
              42.24: 0.0,
              42.27: 0.0,
              42.3: 0.0,
              42.33: 0.0,
              42.36: 0.0,
              42.39: 0.0,
              42.42: 0.0,
              42.45: 0.0,
              42.48: 0.0,
              42.51: 0.0,
              42.54: 0.0,
              42.57: 0.0,
              42.6: 0.0,
              42.63: 0.0,
              42.66: 0.0,
              42.69: 0.0,
              42.72: 0.0,
              42.75: 0.0,
              42.78: 0.0,
              42.81: 0.0,
              42.84: 0.0,
              42.87: 0.0,
              42.9: 0.0,
              42.93: 0.0,
              42.96: 0.0,
              42.99: 0.0,
              43.02: 0.0,
              43.05: 0.0,
              43.08: 0.0,
              43.11: 0.0,
              43.14: 0.0,
              43.17: 0.0,
              43.2: 0.0,
              43.23: 0.0,
              43.26: 0.0,
              43.29: 0.0,
              43.32: 0.0,
              43.35: 0.0,
              43.38: 0.0,
              43.41: 0.0,
              43.44: 0.0,
              43.47: 0.0,
              43.5: 0.0,
              43.53: 0.0,
              43.56: 0.0,
              43.59: 0.0,
              43.62: 0.0,
              43.65: 0.0,
              43.68: 0.0,
              43.71: 0.0,
              43.74: 0.0,
              43.77: 0.0,
              43.8: 0.0,
              43.83: 0.0,
              43.86: 0.0,
              43.89: 0.0,
              43.92: 0.0,
              43.95: 0.0,
              43.98: 0.0,
              44.01: 0.0,
              44.04: 0.0,
              44.07: 0.0,
              44.1: 0.0,
              44.13: 0.0,
              44.16: 0.0,
              44.19: 0.0,
              44.22: 0.0,
              44.25: 0.0,
              44.28: 0.0,
              44.31: 0.0,
              44.34: 0.0,
              44.37: 0.0,
              44.4: 0.0,
              44.43: 0.0,
              44.46: 0.0,
              44.49: 0.0,
              44.52: 0.0,
              44.55: 0.0,
              44.58: 0.0,
              44.61: 0.0,
              44.64: 0.0,
              44.67: 0.0,
              44.7: 0.0,
              44.73: 0.0,
              44.76: 0.0,
              44.79: 0.0,
              44.82: 0.0,
              44.85: 0.0,
              44.88: 0.0,
              44.91: 0.0,
              44.94: 0.0,
              44.97: 0.0,
              '45.0': 0.0,
              45.03: 0.0,
              45.06: 0.0,
              45.09: 0.0,
              45.12: 0.0,
              45.15: 0.0,
              45.18: 0.0,
              45.21: 0.0,
              45.24: 0.0,
              45.27: 0.0,
              45.3: 0.0,
              45.33: 0.0,
              45.36: 0.0,
              45.39: 0.0,
              45.42: 0.0,
              45.45: 0.0,
              45.48: 0.0,
              45.51: 0.0,
              45.54: 0.0,
              45.57: 0.0,
              45.6: 0.0,
              45.63: 0.0,
              45.66: 0.0,
              45.69: 0.0,
              45.72: 0.0,
              45.75: 0.0,
              45.78: 0.0,
              45.81: 0.0,
              45.84: 0.0,
              45.87: 0.0,
              45.9: 0.0,
              45.93: 0.0,
              45.96: 0.0,
              45.99: 0.0,
              46.02: 0.0,
              46.05: 0.0,
              46.08: 0.01,
              46.11: 0.03,
              46.14: 0.06,
              46.17: 0.08,
              46.2: 0.08,
              46.23: 0.05,
              46.26: 0.02,
              46.29: 0.01,
              46.32: 0.0,
              46.35: 0.0,
              46.38: 0.0,
              46.41: 0.0,
              46.44: 0.0,
              46.47: 0.0,
              46.5: 0.0,
              46.53: 0.0,
              46.56: 0.0,
              46.59: 0.0,
              46.62: 0.0,
              46.65: 0.0,
              46.68: 0.0,
              46.71: 0.0,
              46.74: 0.0,
              46.77: 0.0,
              46.8: 0.0,
              46.83: 0.0,
              46.86: 0.0,
              46.89: 0.0,
              46.92: 0.0,
              46.95: 0.0,
              46.98: 0.0,
            },
            iteration_2: {
              '0.0': 0.0,
              0.03: 0.0,
              0.06: 0.01,
              0.09: 0.03,
              0.12: 0.08,
              0.15: 0.15,
              0.18: 0.2,
              0.21: 0.22,
              0.24: 0.2,
              0.27: 0.16,
              0.3: 0.13,
              0.33: 0.1,
              0.36: 0.08,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.18,
              0.66: 0.2,
              0.69: 0.21,
              0.72: 0.24,
              0.75: 0.25,
              0.78: 0.23,
              0.81: 0.16,
              0.84: 0.08,
              0.87: 0.03,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.0,
              1.02: 0.0,
              1.05: 0.02,
              1.08: 0.04,
              1.11: 0.07,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.01,
              1.26: 0.0,
              1.29: 0.0,
              1.32: 0.01,
              1.35: 0.03,
              1.38: 0.09,
              1.41: 0.17,
              1.44: 0.23,
              1.47: 0.22,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.02,
              1.59: 0.01,
              1.62: 0.0,
              1.65: 0.0,
              1.68: 0.0,
              1.71: 0.01,
              1.74: 0.03,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.05,
              1.95: 0.02,
              1.98: 0.01,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.0,
              2.1: 0.0,
              2.13: 0.01,
              2.16: 0.02,
              2.19: 0.06,
              2.22: 0.12,
              2.25: 0.16,
              2.28: 0.15,
              2.31: 0.1,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.01,
              2.43: 0.02,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.1,
              2.55: 0.11,
              2.58: 0.13,
              2.61: 0.16,
              2.64: 0.18,
              2.67: 0.18,
              2.7: 0.15,
              2.73: 0.11,
              2.76: 0.08,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.21,
              2.88: 0.23,
              2.91: 0.19,
              2.94: 0.12,
              2.97: 0.09,
              '3.0': 0.09,
              3.03: 0.08,
              3.06: 0.06,
              3.09: 0.03,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.08,
              3.24: 0.1,
              3.27: 0.11,
              3.3: 0.14,
              3.33: 0.19,
              3.36: 0.26,
              3.39: 0.3,
              3.42: 0.29,
              3.45: 0.22,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.07,
              3.57: 0.08,
              3.6: 0.08,
              3.63: 0.05,
              3.66: 0.02,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.07,
              3.9: 0.13,
              3.93: 0.18,
              3.96: 0.19,
              3.99: 0.17,
              4.02: 0.12,
              4.05: 0.07,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.08,
              4.35: 0.07,
              4.38: 0.07,
              4.41: 0.08,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.0,
              4.62: 0.0,
              4.65: 0.0,
              4.68: 0.0,
              4.71: 0.0,
              4.74: 0.0,
              4.77: 0.0,
              4.8: 0.0,
              4.83: 0.0,
              4.86: 0.0,
              4.89: 0.0,
              4.92: 0.0,
              4.95: 0.0,
              4.98: 0.01,
              5.01: 0.02,
              5.04: 0.04,
              5.07: 0.07,
              5.1: 0.08,
              5.13: 0.06,
              5.16: 0.03,
              5.19: 0.01,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.01,
              5.37: 0.02,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.09,
              5.58: 0.07,
              5.61: 0.04,
              5.64: 0.02,
              5.67: 0.01,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.0,
              5.88: 0.0,
              5.91: 0.0,
              5.94: 0.0,
              5.97: 0.0,
              '6.0': 0.0,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.09,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.02,
              6.96: 0.01,
              6.99: 0.0,
              7.02: 0.0,
              7.05: 0.01,
              7.08: 0.05,
              7.11: 0.12,
              7.14: 0.2,
              7.17: 0.23,
              7.2: 0.2,
              7.23: 0.12,
              7.26: 0.05,
              7.29: 0.02,
              7.32: 0.0,
              7.35: 0.0,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.01,
              7.59: 0.04,
              7.62: 0.09,
              7.65: 0.14,
              7.68: 0.16,
              7.71: 0.13,
              7.74: 0.09,
              7.77: 0.08,
              7.8: 0.08,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.01,
              7.95: 0.02,
              7.98: 0.04,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.03,
              8.13: 0.01,
              8.16: 0.0,
              8.19: 0.0,
              8.22: 0.0,
              8.25: 0.0,
              8.28: 0.0,
              8.31: 0.01,
              8.34: 0.02,
              8.37: 0.05,
              8.4: 0.07,
              8.43: 0.08,
              8.46: 0.06,
              8.49: 0.03,
              8.52: 0.01,
              8.55: 0.0,
              8.58: 0.01,
              8.61: 0.03,
              8.64: 0.07,
              8.67: 0.12,
              8.7: 0.16,
              8.73: 0.15,
              8.76: 0.13,
              8.79: 0.11,
              8.82: 0.09,
              8.85: 0.07,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.08,
              9.18: 0.13,
              9.21: 0.17,
              9.24: 0.19,
              9.27: 0.17,
              9.3: 0.12,
              9.33: 0.06,
              9.36: 0.03,
              9.39: 0.02,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.08,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.02,
              9.87: 0.04,
              9.9: 0.07,
              9.93: 0.08,
              9.96: 0.07,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.11,
              10.71: 0.11,
              10.74: 0.1,
              10.77: 0.08,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.08,
              10.92: 0.08,
              10.95: 0.06,
              10.98: 0.03,
              11.01: 0.01,
              11.04: 0.01,
              11.07: 0.02,
              11.1: 0.05,
              11.13: 0.09,
              11.16: 0.14,
              11.19: 0.15,
              11.22: 0.12,
              11.25: 0.07,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.01,
              11.49: 0.03,
              11.52: 0.06,
              11.55: 0.08,
              11.58: 0.08,
              11.61: 0.05,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.02,
              12.06: 0.04,
              12.09: 0.07,
              12.12: 0.08,
              12.15: 0.07,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.03,
              12.36: 0.06,
              12.39: 0.08,
              12.42: 0.07,
              12.45: 0.04,
              12.48: 0.02,
              12.51: 0.01,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.01,
              12.69: 0.03,
              12.72: 0.06,
              12.75: 0.08,
              12.78: 0.07,
              12.81: 0.04,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.01,
              13.5: 0.02,
              13.53: 0.05,
              13.56: 0.08,
              13.59: 0.1,
              13.62: 0.11,
              13.65: 0.11,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.05,
              13.89: 0.04,
              13.92: 0.06,
              13.95: 0.08,
              13.98: 0.08,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.08,
              14.31: 0.06,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.11,
              14.49: 0.15,
              14.52: 0.15,
              14.55: 0.11,
              14.58: 0.06,
              14.61: 0.04,
              14.64: 0.05,
              14.67: 0.07,
              14.7: 0.08,
              14.73: 0.06,
              14.76: 0.03,
              14.79: 0.01,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.01,
              15.18: 0.02,
              15.21: 0.06,
              15.24: 0.13,
              15.27: 0.2,
              15.3: 0.24,
              15.33: 0.23,
              15.36: 0.19,
              15.39: 0.13,
              15.42: 0.07,
              15.45: 0.03,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.05,
              15.69: 0.07,
              15.72: 0.08,
              15.75: 0.06,
              15.78: 0.03,
              15.81: 0.01,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.01,
              16.02: 0.02,
              16.05: 0.05,
              16.08: 0.07,
              16.11: 0.08,
              16.14: 0.06,
              16.17: 0.03,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.01,
              16.47: 0.03,
              16.5: 0.05,
              16.53: 0.08,
              16.56: 0.08,
              16.59: 0.05,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.04,
              16.8: 0.07,
              16.83: 0.08,
              16.86: 0.06,
              16.89: 0.03,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.03,
              17.19: 0.05,
              17.22: 0.08,
              17.25: 0.08,
              17.28: 0.07,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.08,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.08,
              18.69: 0.06,
              18.72: 0.03,
              18.75: 0.01,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.01,
              20.94: 0.02,
              20.97: 0.05,
              '21.0': 0.07,
              21.03: 0.08,
              21.06: 0.06,
              21.09: 0.03,
              21.12: 0.01,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.0,
              22.8: 0.0,
              22.83: 0.0,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.07,
              24.15: 0.08,
              24.18: 0.07,
              24.21: 0.04,
              24.24: 0.02,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.01,
              25.56: 0.02,
              25.59: 0.04,
              25.62: 0.07,
              25.65: 0.08,
              25.68: 0.06,
              25.71: 0.03,
              25.74: 0.01,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.07,
              26.52: 0.08,
              26.55: 0.06,
              26.58: 0.03,
              26.61: 0.01,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.01,
              29.55: 0.04,
              29.58: 0.07,
              29.61: 0.08,
              29.64: 0.07,
              29.67: 0.04,
              29.7: 0.02,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.01,
              32.64: 0.02,
              32.67: 0.05,
              32.7: 0.07,
              32.73: 0.08,
              32.76: 0.06,
              32.79: 0.03,
              32.82: 0.01,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.01,
              33.09: 0.03,
              33.12: 0.06,
              33.15: 0.08,
              33.18: 0.07,
              33.21: 0.05,
              33.24: 0.02,
              33.27: 0.01,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.01,
              35.67: 0.02,
              35.7: 0.05,
              35.73: 0.07,
              35.76: 0.08,
              35.79: 0.06,
              35.82: 0.03,
              35.85: 0.01,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
            iteration_3: {
              '0.0': 0.09,
              0.03: 0.16,
              0.06: 0.21,
              0.09: 0.22,
              0.12: 0.18,
              0.15: 0.14,
              0.18: 0.14,
              0.21: 0.15,
              0.24: 0.14,
              0.27: 0.11,
              0.3: 0.06,
              0.33: 0.02,
              0.36: 0.01,
              0.39: 0.0,
              0.42: 0.01,
              0.45: 0.04,
              0.48: 0.1,
              0.51: 0.17,
              0.54: 0.22,
              0.57: 0.21,
              0.6: 0.15,
              0.63: 0.08,
              0.66: 0.03,
              0.69: 0.02,
              0.72: 0.04,
              0.75: 0.07,
              0.78: 0.08,
              0.81: 0.07,
              0.84: 0.04,
              0.87: 0.02,
              0.9: 0.01,
              0.93: 0.0,
              0.96: 0.0,
              0.99: 0.02,
              1.02: 0.04,
              1.05: 0.07,
              1.08: 0.08,
              1.11: 0.07,
              1.14: 0.04,
              1.17: 0.02,
              1.2: 0.01,
              1.23: 0.03,
              1.26: 0.08,
              1.29: 0.14,
              1.32: 0.19,
              1.35: 0.22,
              1.38: 0.24,
              1.41: 0.3,
              1.44: 0.39,
              1.47: 0.47,
              1.5: 0.5,
              1.53: 0.47,
              1.56: 0.4,
              1.59: 0.33,
              1.62: 0.27,
              1.65: 0.21,
              1.68: 0.16,
              1.71: 0.12,
              1.74: 0.09,
              1.77: 0.07,
              1.8: 0.07,
              1.83: 0.08,
              1.86: 0.08,
              1.89: 0.05,
              1.92: 0.02,
              1.95: 0.01,
              1.98: 0.0,
              2.01: 0.0,
              2.04: 0.0,
              2.07: 0.02,
              2.1: 0.04,
              2.13: 0.07,
              2.16: 0.09,
              2.19: 0.09,
              2.22: 0.08,
              2.25: 0.09,
              2.28: 0.11,
              2.31: 0.12,
              2.34: 0.15,
              2.37: 0.17,
              2.4: 0.15,
              2.43: 0.1,
              2.46: 0.06,
              2.49: 0.06,
              2.52: 0.09,
              2.55: 0.13,
              2.58: 0.16,
              2.61: 0.16,
              2.64: 0.14,
              2.67: 0.12,
              2.7: 0.08,
              2.73: 0.04,
              2.76: 0.02,
              2.79: 0.0,
              2.82: 0.0,
              2.85: 0.0,
              2.88: 0.0,
              2.91: 0.0,
              2.94: 0.02,
              2.97: 0.05,
              '3.0': 0.1,
              3.03: 0.17,
              3.06: 0.21,
              3.09: 0.23,
              3.12: 0.21,
              3.15: 0.17,
              3.18: 0.1,
              3.21: 0.05,
              3.24: 0.02,
              3.27: 0.01,
              3.3: 0.01,
              3.33: 0.02,
              3.36: 0.04,
              3.39: 0.07,
              3.42: 0.08,
              3.45: 0.07,
              3.48: 0.04,
              3.51: 0.02,
              3.54: 0.0,
              3.57: 0.0,
              3.6: 0.0,
              3.63: 0.0,
              3.66: 0.0,
              3.69: 0.0,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.0,
              3.84: 0.01,
              3.87: 0.03,
              3.9: 0.06,
              3.93: 0.08,
              3.96: 0.07,
              3.99: 0.05,
              4.02: 0.02,
              4.05: 0.01,
              4.08: 0.0,
              4.11: 0.0,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.0,
              4.23: 0.0,
              4.26: 0.0,
              4.29: 0.0,
              4.32: 0.0,
              4.35: 0.0,
              4.38: 0.0,
              4.41: 0.0,
              4.44: 0.0,
              4.47: 0.0,
              4.5: 0.0,
              4.53: 0.0,
              4.56: 0.01,
              4.59: 0.04,
              4.62: 0.09,
              4.65: 0.14,
              4.68: 0.16,
              4.71: 0.13,
              4.74: 0.09,
              4.77: 0.08,
              4.8: 0.08,
              4.83: 0.07,
              4.86: 0.05,
              4.89: 0.02,
              4.92: 0.01,
              4.95: 0.0,
              4.98: 0.0,
              5.01: 0.0,
              5.04: 0.0,
              5.07: 0.0,
              5.1: 0.0,
              5.13: 0.0,
              5.16: 0.0,
              5.19: 0.0,
              5.22: 0.0,
              5.25: 0.0,
              5.28: 0.0,
              5.31: 0.0,
              5.34: 0.0,
              5.37: 0.0,
              5.4: 0.0,
              5.43: 0.02,
              5.46: 0.05,
              5.49: 0.1,
              5.52: 0.15,
              5.55: 0.15,
              5.58: 0.11,
              5.61: 0.05,
              5.64: 0.02,
              5.67: 0.0,
              5.7: 0.0,
              5.73: 0.0,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.0,
              5.85: 0.01,
              5.88: 0.02,
              5.91: 0.05,
              5.94: 0.08,
              5.97: 0.08,
              '6.0': 0.06,
              6.03: 0.03,
              6.06: 0.01,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.0,
              6.18: 0.0,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.0,
              6.33: 0.0,
              6.36: 0.01,
              6.39: 0.02,
              6.42: 0.05,
              6.45: 0.07,
              6.48: 0.08,
              6.51: 0.06,
              6.54: 0.03,
              6.57: 0.02,
              6.6: 0.02,
              6.63: 0.05,
              6.66: 0.09,
              6.69: 0.15,
              6.72: 0.19,
              6.75: 0.2,
              6.78: 0.18,
              6.81: 0.14,
              6.84: 0.11,
              6.87: 0.09,
              6.9: 0.06,
              6.93: 0.04,
              6.96: 0.03,
              6.99: 0.04,
              7.02: 0.06,
              7.05: 0.08,
              7.08: 0.07,
              7.11: 0.04,
              7.14: 0.02,
              7.17: 0.02,
              7.2: 0.03,
              7.23: 0.06,
              7.26: 0.08,
              7.29: 0.07,
              7.32: 0.05,
              7.35: 0.02,
              7.38: 0.01,
              7.41: 0.0,
              7.44: 0.01,
              7.47: 0.04,
              7.5: 0.08,
              7.53: 0.13,
              7.56: 0.15,
              7.59: 0.13,
              7.62: 0.08,
              7.65: 0.04,
              7.68: 0.03,
              7.71: 0.04,
              7.74: 0.07,
              7.77: 0.08,
              7.8: 0.07,
              7.83: 0.04,
              7.86: 0.02,
              7.89: 0.0,
              7.92: 0.0,
              7.95: 0.0,
              7.98: 0.0,
              8.01: 0.0,
              8.04: 0.0,
              8.07: 0.01,
              8.1: 0.03,
              8.13: 0.06,
              8.16: 0.08,
              8.19: 0.07,
              8.22: 0.05,
              8.25: 0.02,
              8.28: 0.01,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.01,
              8.4: 0.02,
              8.43: 0.05,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.06,
              8.55: 0.04,
              8.58: 0.04,
              8.61: 0.06,
              8.64: 0.08,
              8.67: 0.07,
              8.7: 0.05,
              8.73: 0.02,
              8.76: 0.01,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.05,
              8.88: 0.08,
              8.91: 0.08,
              8.94: 0.07,
              8.97: 0.06,
              '9.0': 0.08,
              9.03: 0.11,
              9.06: 0.13,
              9.09: 0.13,
              9.12: 0.1,
              9.15: 0.08,
              9.18: 0.07,
              9.21: 0.08,
              9.24: 0.08,
              9.27: 0.07,
              9.3: 0.05,
              9.33: 0.05,
              9.36: 0.07,
              9.39: 0.08,
              9.42: 0.07,
              9.45: 0.04,
              9.48: 0.02,
              9.51: 0.01,
              9.54: 0.0,
              9.57: 0.0,
              9.6: 0.0,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.01,
              9.93: 0.03,
              9.96: 0.06,
              9.99: 0.08,
              10.02: 0.09,
              10.05: 0.11,
              10.08: 0.13,
              10.11: 0.16,
              10.14: 0.15,
              10.17: 0.1,
              10.2: 0.05,
              10.23: 0.02,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.01,
              10.44: 0.03,
              10.47: 0.06,
              10.5: 0.08,
              10.53: 0.08,
              10.56: 0.08,
              10.59: 0.08,
              10.62: 0.08,
              10.65: 0.08,
              10.68: 0.05,
              10.71: 0.02,
              10.74: 0.01,
              10.77: 0.0,
              10.8: 0.0,
              10.83: 0.0,
              10.86: 0.0,
              10.89: 0.0,
              10.92: 0.0,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.01,
              11.07: 0.03,
              11.1: 0.05,
              11.13: 0.08,
              11.16: 0.09,
              11.19: 0.1,
              11.22: 0.1,
              11.25: 0.09,
              11.28: 0.07,
              11.31: 0.04,
              11.34: 0.02,
              11.37: 0.02,
              11.4: 0.04,
              11.43: 0.07,
              11.46: 0.08,
              11.49: 0.06,
              11.52: 0.03,
              11.55: 0.01,
              11.58: 0.0,
              11.61: 0.0,
              11.64: 0.0,
              11.67: 0.0,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.0,
              12.12: 0.0,
              12.15: 0.0,
              12.18: 0.0,
              12.21: 0.0,
              12.24: 0.0,
              12.27: 0.0,
              12.3: 0.0,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.01,
              12.51: 0.02,
              12.54: 0.04,
              12.57: 0.07,
              12.6: 0.08,
              12.63: 0.06,
              12.66: 0.04,
              12.69: 0.01,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.0,
              12.81: 0.0,
              12.84: 0.0,
              12.87: 0.0,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.01,
              13.56: 0.04,
              13.59: 0.06,
              13.62: 0.08,
              13.65: 0.07,
              13.68: 0.04,
              13.71: 0.02,
              13.74: 0.01,
              13.77: 0.0,
              13.8: 0.0,
              13.83: 0.0,
              13.86: 0.0,
              13.89: 0.0,
              13.92: 0.0,
              13.95: 0.0,
              13.98: 0.0,
              14.01: 0.0,
              14.04: 0.0,
              14.07: 0.0,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.0,
              14.19: 0.0,
              14.22: 0.0,
              14.25: 0.0,
              14.28: 0.0,
              14.31: 0.01,
              14.34: 0.04,
              14.37: 0.09,
              14.4: 0.14,
              14.43: 0.19,
              14.46: 0.2,
              14.49: 0.18,
              14.52: 0.14,
              14.55: 0.13,
              14.58: 0.16,
              14.61: 0.16,
              14.64: 0.12,
              14.67: 0.07,
              14.7: 0.03,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.01,
              14.82: 0.02,
              14.85: 0.04,
              14.88: 0.07,
              14.91: 0.08,
              14.94: 0.06,
              14.97: 0.03,
              '15.0': 0.01,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.01,
              15.15: 0.02,
              15.18: 0.05,
              15.21: 0.07,
              15.24: 0.08,
              15.27: 0.06,
              15.3: 0.03,
              15.33: 0.01,
              15.36: 0.01,
              15.39: 0.03,
              15.42: 0.06,
              15.45: 0.08,
              15.48: 0.08,
              15.51: 0.08,
              15.54: 0.09,
              15.57: 0.1,
              15.6: 0.12,
              15.63: 0.12,
              15.66: 0.1,
              15.69: 0.07,
              15.72: 0.04,
              15.75: 0.01,
              15.78: 0.01,
              15.81: 0.02,
              15.84: 0.04,
              15.87: 0.07,
              15.9: 0.08,
              15.93: 0.06,
              15.96: 0.03,
              15.99: 0.01,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.03,
              16.23: 0.06,
              16.26: 0.08,
              16.29: 0.09,
              16.32: 0.08,
              16.35: 0.08,
              16.38: 0.09,
              16.41: 0.07,
              16.44: 0.06,
              16.47: 0.06,
              16.5: 0.07,
              16.53: 0.08,
              16.56: 0.07,
              16.59: 0.04,
              16.62: 0.02,
              16.65: 0.0,
              16.68: 0.0,
              16.71: 0.0,
              16.74: 0.0,
              16.77: 0.0,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.0,
              16.95: 0.02,
              16.98: 0.04,
              17.01: 0.08,
              17.04: 0.13,
              17.07: 0.14,
              17.1: 0.12,
              17.13: 0.08,
              17.16: 0.04,
              17.19: 0.01,
              17.22: 0.01,
              17.25: 0.02,
              17.28: 0.05,
              17.31: 0.07,
              17.34: 0.08,
              17.37: 0.06,
              17.4: 0.03,
              17.43: 0.01,
              17.46: 0.0,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.01,
              17.64: 0.03,
              17.67: 0.06,
              17.7: 0.08,
              17.73: 0.07,
              17.76: 0.04,
              17.79: 0.02,
              17.82: 0.01,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.0,
              18.57: 0.0,
              18.6: 0.0,
              18.63: 0.0,
              18.66: 0.0,
              18.69: 0.0,
              18.72: 0.0,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.02,
              20.82: 0.04,
              20.85: 0.07,
              20.88: 0.08,
              20.91: 0.07,
              20.94: 0.04,
              20.97: 0.02,
              '21.0': 0.01,
              21.03: 0.02,
              21.06: 0.05,
              21.09: 0.07,
              21.12: 0.08,
              21.15: 0.06,
              21.18: 0.03,
              21.21: 0.01,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.01,
              22.65: 0.03,
              22.68: 0.06,
              22.71: 0.08,
              22.74: 0.07,
              22.77: 0.05,
              22.8: 0.02,
              22.83: 0.01,
              22.86: 0.0,
              22.89: 0.0,
              22.92: 0.0,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.0,
              24.09: 0.0,
              24.12: 0.0,
              24.15: 0.0,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.01,
              24.54: 0.03,
              24.57: 0.06,
              24.6: 0.08,
              24.63: 0.08,
              24.66: 0.05,
              24.69: 0.02,
              24.72: 0.01,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.01,
              24.93: 0.02,
              24.96: 0.05,
              24.99: 0.08,
              25.02: 0.08,
              25.05: 0.06,
              25.08: 0.03,
              25.11: 0.01,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.0,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.01,
              25.74: 0.02,
              25.77: 0.05,
              25.8: 0.08,
              25.83: 0.08,
              25.86: 0.06,
              25.89: 0.03,
              25.92: 0.01,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.0,
              26.43: 0.0,
              26.46: 0.0,
              26.49: 0.0,
              26.52: 0.0,
              26.55: 0.0,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.01,
              27.21: 0.03,
              27.24: 0.05,
              27.27: 0.08,
              27.3: 0.08,
              27.33: 0.05,
              27.36: 0.03,
              27.39: 0.01,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.0,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.01,
              33.96: 0.02,
              33.99: 0.04,
              34.02: 0.07,
              34.05: 0.08,
              34.08: 0.06,
              34.11: 0.04,
              34.14: 0.01,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
              '36.0': 0.0,
              36.03: 0.0,
              36.06: 0.0,
              36.09: 0.0,
              36.12: 0.0,
              36.15: 0.0,
              36.18: 0.0,
              36.21: 0.0,
              36.24: 0.0,
              36.27: 0.0,
              36.3: 0.0,
              36.33: 0.0,
              36.36: 0.0,
              36.39: 0.0,
              36.42: 0.0,
              36.45: 0.0,
              36.48: 0.0,
              36.51: 0.0,
              36.54: 0.0,
              36.57: 0.0,
              36.6: 0.0,
              36.63: 0.0,
              36.66: 0.0,
              36.69: 0.0,
              36.72: 0.0,
              36.75: 0.0,
              36.78: 0.0,
              36.81: 0.0,
              36.84: 0.0,
              36.87: 0.0,
              36.9: 0.0,
              36.93: 0.0,
              36.96: 0.0,
              36.99: 0.0,
              37.02: 0.0,
              37.05: 0.0,
              37.08: 0.0,
              37.11: 0.0,
              37.14: 0.0,
              37.17: 0.0,
              37.2: 0.0,
              37.23: 0.0,
              37.26: 0.0,
              37.29: 0.0,
              37.32: 0.0,
              37.35: 0.0,
              37.38: 0.0,
              37.41: 0.0,
              37.44: 0.0,
              37.47: 0.0,
              37.5: 0.0,
              37.53: 0.0,
              37.56: 0.0,
              37.59: 0.0,
              37.62: 0.0,
              37.65: 0.0,
              37.68: 0.0,
              37.71: 0.0,
              37.74: 0.0,
              37.77: 0.0,
              37.8: 0.0,
              37.83: 0.0,
              37.86: 0.0,
              37.89: 0.0,
              37.92: 0.0,
              37.95: 0.0,
              37.98: 0.0,
              38.01: 0.0,
              38.04: 0.0,
              38.07: 0.0,
              38.1: 0.0,
              38.13: 0.0,
              38.16: 0.0,
              38.19: 0.0,
              38.22: 0.0,
              38.25: 0.0,
              38.28: 0.0,
              38.31: 0.0,
              38.34: 0.0,
              38.37: 0.0,
              38.4: 0.0,
              38.43: 0.0,
              38.46: 0.0,
              38.49: 0.0,
              38.52: 0.0,
              38.55: 0.0,
              38.58: 0.0,
              38.61: 0.0,
              38.64: 0.0,
              38.67: 0.0,
              38.7: 0.0,
              38.73: 0.0,
              38.76: 0.0,
              38.79: 0.0,
              38.82: 0.0,
              38.85: 0.0,
              38.88: 0.0,
              38.91: 0.0,
              38.94: 0.0,
              38.97: 0.0,
              '39.0': 0.0,
              39.03: 0.0,
              39.06: 0.0,
              39.09: 0.0,
              39.12: 0.0,
              39.15: 0.0,
              39.18: 0.0,
              39.21: 0.0,
              39.24: 0.0,
              39.27: 0.0,
              39.3: 0.0,
              39.33: 0.0,
              39.36: 0.0,
              39.39: 0.0,
              39.42: 0.0,
              39.45: 0.0,
              39.48: 0.0,
              39.51: 0.0,
              39.54: 0.0,
              39.57: 0.0,
              39.6: 0.0,
              39.63: 0.0,
              39.66: 0.0,
              39.69: 0.0,
              39.72: 0.0,
              39.75: 0.0,
              39.78: 0.0,
              39.81: 0.0,
              39.84: 0.0,
              39.87: 0.0,
              39.9: 0.0,
              39.93: 0.0,
              39.96: 0.0,
              39.99: 0.0,
              40.02: 0.0,
              40.05: 0.0,
              40.08: 0.0,
              40.11: 0.0,
              40.14: 0.0,
              40.17: 0.0,
              40.2: 0.0,
              40.23: 0.0,
              40.26: 0.0,
              40.29: 0.0,
              40.32: 0.0,
              40.35: 0.0,
              40.38: 0.0,
              40.41: 0.0,
              40.44: 0.0,
              40.47: 0.0,
              40.5: 0.0,
              40.53: 0.0,
              40.56: 0.0,
              40.59: 0.0,
              40.62: 0.0,
              40.65: 0.0,
              40.68: 0.0,
              40.71: 0.01,
              40.74: 0.02,
              40.77: 0.04,
              40.8: 0.07,
              40.83: 0.08,
              40.86: 0.06,
              40.89: 0.04,
              40.92: 0.01,
              40.95: 0.0,
              40.98: 0.0,
              41.01: 0.0,
              41.04: 0.0,
              41.07: 0.0,
              41.1: 0.0,
              41.13: 0.0,
              41.16: 0.0,
              41.19: 0.0,
              41.22: 0.0,
              41.25: 0.0,
              41.28: 0.0,
              41.31: 0.0,
              41.34: 0.0,
              41.37: 0.0,
              41.4: 0.0,
              41.43: 0.0,
              41.46: 0.0,
              41.49: 0.0,
              41.52: 0.0,
              41.55: 0.0,
              41.58: 0.0,
              41.61: 0.0,
              41.64: 0.0,
              41.67: 0.0,
              41.7: 0.0,
              41.73: 0.0,
              41.76: 0.0,
              41.79: 0.01,
              41.82: 0.02,
              41.85: 0.05,
              41.88: 0.07,
              41.91: 0.08,
              41.94: 0.06,
              41.97: 0.03,
            },
            iteration_median: {
              '0.0': 0.03,
              0.03: 0.06,
              0.06: 0.09,
              0.09: 0.12,
              0.12: 0.13,
              0.15: 0.14,
              0.18: 0.18,
              0.21: 0.19,
              0.24: 0.18,
              0.27: 0.15,
              0.3: 0.13,
              0.33: 0.11,
              0.36: 0.1,
              0.39: 0.08,
              0.42: 0.09,
              0.45: 0.08,
              0.48: 0.06,
              0.51: 0.06,
              0.54: 0.09,
              0.57: 0.12,
              0.6: 0.15,
              0.63: 0.13,
              0.66: 0.13,
              0.69: 0.12,
              0.72: 0.1,
              0.75: 0.09,
              0.78: 0.09,
              0.81: 0.09,
              0.84: 0.07,
              0.87: 0.04,
              0.9: 0.02,
              0.93: 0.02,
              0.96: 0.04,
              0.99: 0.09,
              1.02: 0.15,
              1.05: 0.2,
              1.08: 0.22,
              1.11: 0.15,
              1.14: 0.08,
              1.17: 0.06,
              1.2: 0.04,
              1.23: 0.06,
              1.26: 0.08,
              1.29: 0.08,
              1.32: 0.07,
              1.35: 0.08,
              1.38: 0.12,
              1.41: 0.16,
              1.44: 0.17,
              1.47: 0.16,
              1.5: 0.15,
              1.53: 0.07,
              1.56: 0.08,
              1.59: 0.07,
              1.62: 0.05,
              1.65: 0.05,
              1.68: 0.07,
              1.71: 0.08,
              1.74: 0.08,
              1.77: 0.06,
              1.8: 0.11,
              1.83: 0.14,
              1.86: 0.14,
              1.89: 0.1,
              1.92: 0.09,
              1.95: 0.11,
              1.98: 0.14,
              2.01: 0.15,
              2.04: 0.13,
              2.07: 0.09,
              2.1: 0.08,
              2.13: 0.08,
              2.16: 0.14,
              2.19: 0.17,
              2.22: 0.17,
              2.25: 0.16,
              2.28: 0.11,
              2.31: 0.07,
              2.34: 0.04,
              2.37: 0.01,
              2.4: 0.02,
              2.43: 0.04,
              2.46: 0.04,
              2.49: 0.07,
              2.52: 0.06,
              2.55: 0.04,
              2.58: 0.03,
              2.61: 0.06,
              2.64: 0.11,
              2.67: 0.16,
              2.7: 0.15,
              2.73: 0.12,
              2.76: 0.09,
              2.79: 0.09,
              2.82: 0.14,
              2.85: 0.1,
              2.88: 0.07,
              2.91: 0.06,
              2.94: 0.07,
              2.97: 0.08,
              '3.0': 0.06,
              3.03: 0.04,
              3.06: 0.02,
              3.09: 0.02,
              3.12: 0.02,
              3.15: 0.03,
              3.18: 0.06,
              3.21: 0.06,
              3.24: 0.03,
              3.27: 0.01,
              3.3: 0.0,
              3.33: 0.01,
              3.36: 0.03,
              3.39: 0.06,
              3.42: 0.11,
              3.45: 0.14,
              3.48: 0.14,
              3.51: 0.08,
              3.54: 0.06,
              3.57: 0.02,
              3.6: 0.01,
              3.63: 0.0,
              3.66: 0.01,
              3.69: 0.01,
              3.72: 0.0,
              3.75: 0.0,
              3.78: 0.0,
              3.81: 0.01,
              3.84: 0.03,
              3.87: 0.02,
              3.9: 0.01,
              3.93: 0.0,
              3.96: 0.0,
              3.99: 0.01,
              4.02: 0.02,
              4.05: 0.05,
              4.08: 0.03,
              4.11: 0.01,
              4.14: 0.0,
              4.17: 0.0,
              4.2: 0.01,
              4.23: 0.03,
              4.26: 0.06,
              4.29: 0.08,
              4.32: 0.11,
              4.35: 0.13,
              4.38: 0.12,
              4.41: 0.09,
              4.44: 0.08,
              4.47: 0.06,
              4.5: 0.04,
              4.53: 0.01,
              4.56: 0.0,
              4.59: 0.01,
              4.62: 0.03,
              4.65: 0.02,
              4.68: 0.03,
              4.71: 0.06,
              4.74: 0.05,
              4.77: 0.05,
              4.8: 0.08,
              4.83: 0.12,
              4.86: 0.16,
              4.89: 0.18,
              4.92: 0.16,
              4.95: 0.1,
              4.98: 0.05,
              5.01: 0.02,
              5.04: 0.02,
              5.07: 0.04,
              5.1: 0.07,
              5.13: 0.06,
              5.16: 0.07,
              5.19: 0.04,
              5.22: 0.03,
              5.25: 0.02,
              5.28: 0.01,
              5.31: 0.03,
              5.34: 0.07,
              5.37: 0.05,
              5.4: 0.05,
              5.43: 0.08,
              5.46: 0.09,
              5.49: 0.09,
              5.52: 0.09,
              5.55: 0.08,
              5.58: 0.07,
              5.61: 0.06,
              5.64: 0.08,
              5.67: 0.07,
              5.7: 0.04,
              5.73: 0.02,
              5.76: 0.0,
              5.79: 0.0,
              5.82: 0.01,
              5.85: 0.02,
              5.88: 0.05,
              5.91: 0.08,
              5.94: 0.06,
              5.97: 0.03,
              '6.0': 0.01,
              6.03: 0.0,
              6.06: 0.0,
              6.09: 0.0,
              6.12: 0.0,
              6.15: 0.01,
              6.18: 0.01,
              6.21: 0.0,
              6.24: 0.0,
              6.27: 0.0,
              6.3: 0.01,
              6.33: 0.03,
              6.36: 0.05,
              6.39: 0.08,
              6.42: 0.08,
              6.45: 0.05,
              6.48: 0.03,
              6.51: 0.03,
              6.54: 0.05,
              6.57: 0.08,
              6.6: 0.09,
              6.63: 0.1,
              6.66: 0.1,
              6.69: 0.11,
              6.72: 0.07,
              6.75: 0.05,
              6.78: 0.05,
              6.81: 0.06,
              6.84: 0.08,
              6.87: 0.07,
              6.9: 0.05,
              6.93: 0.03,
              6.96: 0.06,
              6.99: 0.06,
              7.02: 0.03,
              7.05: 0.01,
              7.08: 0.02,
              7.11: 0.01,
              7.14: 0.01,
              7.17: 0.02,
              7.2: 0.05,
              7.23: 0.08,
              7.26: 0.08,
              7.29: 0.06,
              7.32: 0.04,
              7.35: 0.01,
              7.38: 0.0,
              7.41: 0.0,
              7.44: 0.0,
              7.47: 0.0,
              7.5: 0.0,
              7.53: 0.0,
              7.56: 0.0,
              7.59: 0.0,
              7.62: 0.0,
              7.65: 0.0,
              7.68: 0.0,
              7.71: 0.0,
              7.74: 0.02,
              7.77: 0.04,
              7.8: 0.07,
              7.83: 0.08,
              7.86: 0.05,
              7.89: 0.03,
              7.92: 0.02,
              7.95: 0.03,
              7.98: 0.05,
              8.01: 0.07,
              8.04: 0.08,
              8.07: 0.06,
              8.1: 0.05,
              8.13: 0.05,
              8.16: 0.07,
              8.19: 0.04,
              8.22: 0.02,
              8.25: 0.01,
              8.28: 0.0,
              8.31: 0.0,
              8.34: 0.0,
              8.37: 0.0,
              8.4: 0.01,
              8.43: 0.04,
              8.46: 0.07,
              8.49: 0.08,
              8.52: 0.07,
              8.55: 0.04,
              8.58: 0.02,
              8.61: 0.03,
              8.64: 0.02,
              8.67: 0.01,
              8.7: 0.0,
              8.73: 0.0,
              8.76: 0.0,
              8.79: 0.01,
              8.82: 0.02,
              8.85: 0.04,
              8.88: 0.04,
              8.91: 0.01,
              8.94: 0.0,
              8.97: 0.0,
              '9.0': 0.0,
              9.03: 0.0,
              9.06: 0.0,
              9.09: 0.01,
              9.12: 0.04,
              9.15: 0.06,
              9.18: 0.03,
              9.21: 0.04,
              9.24: 0.08,
              9.27: 0.12,
              9.3: 0.12,
              9.33: 0.08,
              9.36: 0.07,
              9.39: 0.05,
              9.42: 0.04,
              9.45: 0.06,
              9.48: 0.07,
              9.51: 0.07,
              9.54: 0.04,
              9.57: 0.02,
              9.6: 0.01,
              9.63: 0.0,
              9.66: 0.0,
              9.69: 0.0,
              9.72: 0.0,
              9.75: 0.0,
              9.78: 0.0,
              9.81: 0.0,
              9.84: 0.0,
              9.87: 0.0,
              9.9: 0.0,
              9.93: 0.01,
              9.96: 0.03,
              9.99: 0.05,
              10.02: 0.05,
              10.05: 0.07,
              10.08: 0.08,
              10.11: 0.07,
              10.14: 0.04,
              10.17: 0.02,
              10.2: 0.01,
              10.23: 0.0,
              10.26: 0.0,
              10.29: 0.0,
              10.32: 0.0,
              10.35: 0.0,
              10.38: 0.0,
              10.41: 0.0,
              10.44: 0.01,
              10.47: 0.02,
              10.5: 0.05,
              10.53: 0.07,
              10.56: 0.09,
              10.59: 0.09,
              10.62: 0.09,
              10.65: 0.1,
              10.68: 0.08,
              10.71: 0.05,
              10.74: 0.04,
              10.77: 0.05,
              10.8: 0.06,
              10.83: 0.05,
              10.86: 0.06,
              10.89: 0.03,
              10.92: 0.01,
              10.95: 0.0,
              10.98: 0.0,
              11.01: 0.0,
              11.04: 0.0,
              11.07: 0.01,
              11.1: 0.03,
              11.13: 0.06,
              11.16: 0.08,
              11.19: 0.07,
              11.22: 0.08,
              11.25: 0.06,
              11.28: 0.03,
              11.31: 0.01,
              11.34: 0.0,
              11.37: 0.0,
              11.4: 0.0,
              11.43: 0.0,
              11.46: 0.0,
              11.49: 0.0,
              11.52: 0.0,
              11.55: 0.0,
              11.58: 0.01,
              11.61: 0.02,
              11.64: 0.02,
              11.67: 0.01,
              11.7: 0.0,
              11.73: 0.0,
              11.76: 0.0,
              11.79: 0.0,
              11.82: 0.0,
              11.85: 0.0,
              11.88: 0.0,
              11.91: 0.0,
              11.94: 0.0,
              11.97: 0.0,
              '12.0': 0.0,
              12.03: 0.0,
              12.06: 0.0,
              12.09: 0.01,
              12.12: 0.02,
              12.15: 0.04,
              12.18: 0.04,
              12.21: 0.02,
              12.24: 0.01,
              12.27: 0.0,
              12.3: 0.01,
              12.33: 0.0,
              12.36: 0.0,
              12.39: 0.0,
              12.42: 0.0,
              12.45: 0.0,
              12.48: 0.0,
              12.51: 0.0,
              12.54: 0.0,
              12.57: 0.0,
              12.6: 0.0,
              12.63: 0.0,
              12.66: 0.0,
              12.69: 0.0,
              12.72: 0.0,
              12.75: 0.0,
              12.78: 0.01,
              12.81: 0.03,
              12.84: 0.02,
              12.87: 0.01,
              12.9: 0.0,
              12.93: 0.0,
              12.96: 0.0,
              12.99: 0.0,
              13.02: 0.0,
              13.05: 0.0,
              13.08: 0.0,
              13.11: 0.0,
              13.14: 0.0,
              13.17: 0.0,
              13.2: 0.0,
              13.23: 0.0,
              13.26: 0.0,
              13.29: 0.0,
              13.32: 0.0,
              13.35: 0.0,
              13.38: 0.0,
              13.41: 0.0,
              13.44: 0.0,
              13.47: 0.0,
              13.5: 0.0,
              13.53: 0.0,
              13.56: 0.0,
              13.59: 0.01,
              13.62: 0.03,
              13.65: 0.06,
              13.68: 0.09,
              13.71: 0.08,
              13.74: 0.07,
              13.77: 0.07,
              13.8: 0.08,
              13.83: 0.07,
              13.86: 0.07,
              13.89: 0.08,
              13.92: 0.06,
              13.95: 0.03,
              13.98: 0.05,
              14.01: 0.05,
              14.04: 0.03,
              14.07: 0.01,
              14.1: 0.0,
              14.13: 0.0,
              14.16: 0.01,
              14.19: 0.02,
              14.22: 0.05,
              14.25: 0.07,
              14.28: 0.07,
              14.31: 0.04,
              14.34: 0.03,
              14.37: 0.02,
              14.4: 0.02,
              14.43: 0.05,
              14.46: 0.05,
              14.49: 0.03,
              14.52: 0.02,
              14.55: 0.05,
              14.58: 0.06,
              14.61: 0.08,
              14.64: 0.06,
              14.67: 0.05,
              14.7: 0.02,
              14.73: 0.01,
              14.76: 0.0,
              14.79: 0.0,
              14.82: 0.0,
              14.85: 0.0,
              14.88: 0.0,
              14.91: 0.0,
              14.94: 0.0,
              14.97: 0.0,
              '15.0': 0.0,
              15.03: 0.0,
              15.06: 0.0,
              15.09: 0.0,
              15.12: 0.0,
              15.15: 0.0,
              15.18: 0.0,
              15.21: 0.01,
              15.24: 0.02,
              15.27: 0.04,
              15.3: 0.07,
              15.33: 0.08,
              15.36: 0.06,
              15.39: 0.04,
              15.42: 0.01,
              15.45: 0.0,
              15.48: 0.01,
              15.51: 0.0,
              15.54: 0.0,
              15.57: 0.0,
              15.6: 0.01,
              15.63: 0.02,
              15.66: 0.02,
              15.69: 0.01,
              15.72: 0.0,
              15.75: 0.0,
              15.78: 0.0,
              15.81: 0.0,
              15.84: 0.0,
              15.87: 0.0,
              15.9: 0.0,
              15.93: 0.0,
              15.96: 0.0,
              15.99: 0.0,
              16.02: 0.0,
              16.05: 0.0,
              16.08: 0.0,
              16.11: 0.0,
              16.14: 0.0,
              16.17: 0.01,
              16.2: 0.01,
              16.23: 0.0,
              16.26: 0.0,
              16.29: 0.0,
              16.32: 0.0,
              16.35: 0.0,
              16.38: 0.0,
              16.41: 0.0,
              16.44: 0.0,
              16.47: 0.0,
              16.5: 0.0,
              16.53: 0.0,
              16.56: 0.01,
              16.59: 0.03,
              16.62: 0.02,
              16.65: 0.01,
              16.68: 0.0,
              16.71: 0.01,
              16.74: 0.02,
              16.77: 0.01,
              16.8: 0.0,
              16.83: 0.0,
              16.86: 0.0,
              16.89: 0.0,
              16.92: 0.01,
              16.95: 0.0,
              16.98: 0.0,
              17.01: 0.0,
              17.04: 0.0,
              17.07: 0.0,
              17.1: 0.0,
              17.13: 0.01,
              17.16: 0.01,
              17.19: 0.0,
              17.22: 0.0,
              17.25: 0.0,
              17.28: 0.0,
              17.31: 0.0,
              17.34: 0.01,
              17.37: 0.04,
              17.4: 0.06,
              17.43: 0.03,
              17.46: 0.01,
              17.49: 0.0,
              17.52: 0.0,
              17.55: 0.0,
              17.58: 0.0,
              17.61: 0.0,
              17.64: 0.0,
              17.67: 0.0,
              17.7: 0.0,
              17.73: 0.0,
              17.76: 0.0,
              17.79: 0.0,
              17.82: 0.0,
              17.85: 0.0,
              17.88: 0.0,
              17.91: 0.0,
              17.94: 0.0,
              17.97: 0.0,
              '18.0': 0.0,
              18.03: 0.0,
              18.06: 0.0,
              18.09: 0.0,
              18.12: 0.0,
              18.15: 0.0,
              18.18: 0.0,
              18.21: 0.0,
              18.24: 0.0,
              18.27: 0.0,
              18.3: 0.0,
              18.33: 0.0,
              18.36: 0.0,
              18.39: 0.0,
              18.42: 0.0,
              18.45: 0.0,
              18.48: 0.0,
              18.51: 0.0,
              18.54: 0.01,
              18.57: 0.02,
              18.6: 0.05,
              18.63: 0.07,
              18.66: 0.06,
              18.69: 0.03,
              18.72: 0.01,
              18.75: 0.0,
              18.78: 0.0,
              18.81: 0.0,
              18.84: 0.0,
              18.87: 0.0,
              18.9: 0.0,
              18.93: 0.0,
              18.96: 0.0,
              18.99: 0.0,
              19.02: 0.0,
              19.05: 0.0,
              19.08: 0.0,
              19.11: 0.0,
              19.14: 0.0,
              19.17: 0.0,
              19.2: 0.0,
              19.23: 0.0,
              19.26: 0.0,
              19.29: 0.0,
              19.32: 0.0,
              19.35: 0.0,
              19.38: 0.0,
              19.41: 0.0,
              19.44: 0.0,
              19.47: 0.0,
              19.5: 0.0,
              19.53: 0.0,
              19.56: 0.0,
              19.59: 0.0,
              19.62: 0.0,
              19.65: 0.0,
              19.68: 0.0,
              19.71: 0.0,
              19.74: 0.0,
              19.77: 0.0,
              19.8: 0.0,
              19.83: 0.0,
              19.86: 0.0,
              19.89: 0.0,
              19.92: 0.0,
              19.95: 0.0,
              19.98: 0.0,
              20.01: 0.0,
              20.04: 0.0,
              20.07: 0.0,
              20.1: 0.0,
              20.13: 0.0,
              20.16: 0.0,
              20.19: 0.0,
              20.22: 0.0,
              20.25: 0.0,
              20.28: 0.0,
              20.31: 0.0,
              20.34: 0.0,
              20.37: 0.0,
              20.4: 0.0,
              20.43: 0.0,
              20.46: 0.0,
              20.49: 0.0,
              20.52: 0.0,
              20.55: 0.0,
              20.58: 0.0,
              20.61: 0.0,
              20.64: 0.0,
              20.67: 0.0,
              20.7: 0.0,
              20.73: 0.0,
              20.76: 0.0,
              20.79: 0.0,
              20.82: 0.0,
              20.85: 0.0,
              20.88: 0.0,
              20.91: 0.0,
              20.94: 0.0,
              20.97: 0.0,
              '21.0': 0.0,
              21.03: 0.0,
              21.06: 0.0,
              21.09: 0.0,
              21.12: 0.0,
              21.15: 0.0,
              21.18: 0.0,
              21.21: 0.0,
              21.24: 0.0,
              21.27: 0.0,
              21.3: 0.0,
              21.33: 0.0,
              21.36: 0.0,
              21.39: 0.0,
              21.42: 0.0,
              21.45: 0.0,
              21.48: 0.0,
              21.51: 0.0,
              21.54: 0.0,
              21.57: 0.0,
              21.6: 0.0,
              21.63: 0.0,
              21.66: 0.0,
              21.69: 0.0,
              21.72: 0.0,
              21.75: 0.0,
              21.78: 0.0,
              21.81: 0.0,
              21.84: 0.0,
              21.87: 0.0,
              21.9: 0.0,
              21.93: 0.0,
              21.96: 0.0,
              21.99: 0.0,
              22.02: 0.0,
              22.05: 0.0,
              22.08: 0.0,
              22.11: 0.0,
              22.14: 0.0,
              22.17: 0.0,
              22.2: 0.0,
              22.23: 0.0,
              22.26: 0.0,
              22.29: 0.0,
              22.32: 0.0,
              22.35: 0.0,
              22.38: 0.0,
              22.41: 0.0,
              22.44: 0.0,
              22.47: 0.0,
              22.5: 0.0,
              22.53: 0.0,
              22.56: 0.0,
              22.59: 0.0,
              22.62: 0.0,
              22.65: 0.0,
              22.68: 0.0,
              22.71: 0.0,
              22.74: 0.0,
              22.77: 0.01,
              22.8: 0.03,
              22.83: 0.06,
              22.86: 0.05,
              22.89: 0.02,
              22.92: 0.01,
              22.95: 0.0,
              22.98: 0.0,
              23.01: 0.0,
              23.04: 0.0,
              23.07: 0.0,
              23.1: 0.0,
              23.13: 0.0,
              23.16: 0.0,
              23.19: 0.0,
              23.22: 0.0,
              23.25: 0.0,
              23.28: 0.0,
              23.31: 0.0,
              23.34: 0.0,
              23.37: 0.0,
              23.4: 0.0,
              23.43: 0.0,
              23.46: 0.0,
              23.49: 0.0,
              23.52: 0.0,
              23.55: 0.0,
              23.58: 0.0,
              23.61: 0.0,
              23.64: 0.0,
              23.67: 0.0,
              23.7: 0.0,
              23.73: 0.0,
              23.76: 0.0,
              23.79: 0.0,
              23.82: 0.0,
              23.85: 0.0,
              23.88: 0.0,
              23.91: 0.0,
              23.94: 0.0,
              23.97: 0.0,
              '24.0': 0.0,
              24.03: 0.0,
              24.06: 0.02,
              24.09: 0.04,
              24.12: 0.03,
              24.15: 0.01,
              24.18: 0.0,
              24.21: 0.0,
              24.24: 0.0,
              24.27: 0.0,
              24.3: 0.0,
              24.33: 0.0,
              24.36: 0.0,
              24.39: 0.0,
              24.42: 0.0,
              24.45: 0.0,
              24.48: 0.0,
              24.51: 0.0,
              24.54: 0.0,
              24.57: 0.0,
              24.6: 0.0,
              24.63: 0.0,
              24.66: 0.0,
              24.69: 0.0,
              24.72: 0.0,
              24.75: 0.0,
              24.78: 0.0,
              24.81: 0.0,
              24.84: 0.0,
              24.87: 0.0,
              24.9: 0.0,
              24.93: 0.0,
              24.96: 0.0,
              24.99: 0.0,
              25.02: 0.0,
              25.05: 0.0,
              25.08: 0.0,
              25.11: 0.0,
              25.14: 0.0,
              25.17: 0.0,
              25.2: 0.0,
              25.23: 0.0,
              25.26: 0.01,
              25.29: 0.0,
              25.32: 0.0,
              25.35: 0.0,
              25.38: 0.0,
              25.41: 0.0,
              25.44: 0.0,
              25.47: 0.0,
              25.5: 0.0,
              25.53: 0.0,
              25.56: 0.0,
              25.59: 0.0,
              25.62: 0.0,
              25.65: 0.0,
              25.68: 0.0,
              25.71: 0.0,
              25.74: 0.0,
              25.77: 0.0,
              25.8: 0.0,
              25.83: 0.0,
              25.86: 0.0,
              25.89: 0.0,
              25.92: 0.0,
              25.95: 0.0,
              25.98: 0.0,
              26.01: 0.0,
              26.04: 0.0,
              26.07: 0.0,
              26.1: 0.0,
              26.13: 0.0,
              26.16: 0.0,
              26.19: 0.0,
              26.22: 0.0,
              26.25: 0.0,
              26.28: 0.0,
              26.31: 0.0,
              26.34: 0.0,
              26.37: 0.0,
              26.4: 0.01,
              26.43: 0.02,
              26.46: 0.05,
              26.49: 0.04,
              26.52: 0.02,
              26.55: 0.01,
              26.58: 0.0,
              26.61: 0.0,
              26.64: 0.0,
              26.67: 0.0,
              26.7: 0.0,
              26.73: 0.0,
              26.76: 0.0,
              26.79: 0.0,
              26.82: 0.0,
              26.85: 0.0,
              26.88: 0.0,
              26.91: 0.0,
              26.94: 0.0,
              26.97: 0.0,
              '27.0': 0.0,
              27.03: 0.0,
              27.06: 0.0,
              27.09: 0.0,
              27.12: 0.0,
              27.15: 0.0,
              27.18: 0.0,
              27.21: 0.0,
              27.24: 0.0,
              27.27: 0.0,
              27.3: 0.0,
              27.33: 0.0,
              27.36: 0.0,
              27.39: 0.0,
              27.42: 0.0,
              27.45: 0.0,
              27.48: 0.0,
              27.51: 0.0,
              27.54: 0.0,
              27.57: 0.0,
              27.6: 0.0,
              27.63: 0.0,
              27.66: 0.0,
              27.69: 0.0,
              27.72: 0.0,
              27.75: 0.0,
              27.78: 0.0,
              27.81: 0.0,
              27.84: 0.0,
              27.87: 0.0,
              27.9: 0.0,
              27.93: 0.0,
              27.96: 0.0,
              27.99: 0.0,
              28.02: 0.0,
              28.05: 0.0,
              28.08: 0.0,
              28.11: 0.0,
              28.14: 0.0,
              28.17: 0.0,
              28.2: 0.0,
              28.23: 0.0,
              28.26: 0.0,
              28.29: 0.0,
              28.32: 0.0,
              28.35: 0.0,
              28.38: 0.0,
              28.41: 0.0,
              28.44: 0.0,
              28.47: 0.0,
              28.5: 0.0,
              28.53: 0.0,
              28.56: 0.0,
              28.59: 0.0,
              28.62: 0.0,
              28.65: 0.0,
              28.68: 0.0,
              28.71: 0.0,
              28.74: 0.0,
              28.77: 0.0,
              28.8: 0.0,
              28.83: 0.0,
              28.86: 0.0,
              28.89: 0.0,
              28.92: 0.0,
              28.95: 0.0,
              28.98: 0.0,
              29.01: 0.0,
              29.04: 0.0,
              29.07: 0.0,
              29.1: 0.0,
              29.13: 0.0,
              29.16: 0.0,
              29.19: 0.0,
              29.22: 0.0,
              29.25: 0.0,
              29.28: 0.0,
              29.31: 0.0,
              29.34: 0.0,
              29.37: 0.0,
              29.4: 0.0,
              29.43: 0.0,
              29.46: 0.0,
              29.49: 0.0,
              29.52: 0.0,
              29.55: 0.0,
              29.58: 0.0,
              29.61: 0.0,
              29.64: 0.0,
              29.67: 0.0,
              29.7: 0.0,
              29.73: 0.0,
              29.76: 0.0,
              29.79: 0.0,
              29.82: 0.0,
              29.85: 0.0,
              29.88: 0.0,
              29.91: 0.0,
              29.94: 0.0,
              29.97: 0.0,
              '30.0': 0.0,
              30.03: 0.0,
              30.06: 0.0,
              30.09: 0.0,
              30.12: 0.0,
              30.15: 0.0,
              30.18: 0.0,
              30.21: 0.0,
              30.24: 0.0,
              30.27: 0.0,
              30.3: 0.0,
              30.33: 0.0,
              30.36: 0.0,
              30.39: 0.0,
              30.42: 0.0,
              30.45: 0.0,
              30.48: 0.0,
              30.51: 0.0,
              30.54: 0.0,
              30.57: 0.0,
              30.6: 0.0,
              30.63: 0.0,
              30.66: 0.0,
              30.69: 0.0,
              30.72: 0.01,
              30.75: 0.0,
              30.78: 0.0,
              30.81: 0.0,
              30.84: 0.0,
              30.87: 0.0,
              30.9: 0.0,
              30.93: 0.0,
              30.96: 0.0,
              30.99: 0.0,
              31.02: 0.0,
              31.05: 0.0,
              31.08: 0.0,
              31.11: 0.0,
              31.14: 0.0,
              31.17: 0.0,
              31.2: 0.0,
              31.23: 0.0,
              31.26: 0.0,
              31.29: 0.0,
              31.32: 0.0,
              31.35: 0.0,
              31.38: 0.0,
              31.41: 0.0,
              31.44: 0.0,
              31.47: 0.0,
              31.5: 0.0,
              31.53: 0.0,
              31.56: 0.0,
              31.59: 0.0,
              31.62: 0.0,
              31.65: 0.0,
              31.68: 0.0,
              31.71: 0.0,
              31.74: 0.0,
              31.77: 0.0,
              31.8: 0.0,
              31.83: 0.0,
              31.86: 0.0,
              31.89: 0.0,
              31.92: 0.0,
              31.95: 0.0,
              31.98: 0.0,
              32.01: 0.0,
              32.04: 0.0,
              32.07: 0.0,
              32.1: 0.0,
              32.13: 0.0,
              32.16: 0.0,
              32.19: 0.0,
              32.22: 0.0,
              32.25: 0.0,
              32.28: 0.0,
              32.31: 0.0,
              32.34: 0.0,
              32.37: 0.0,
              32.4: 0.0,
              32.43: 0.0,
              32.46: 0.0,
              32.49: 0.0,
              32.52: 0.0,
              32.55: 0.0,
              32.58: 0.0,
              32.61: 0.0,
              32.64: 0.0,
              32.67: 0.0,
              32.7: 0.0,
              32.73: 0.0,
              32.76: 0.0,
              32.79: 0.0,
              32.82: 0.0,
              32.85: 0.0,
              32.88: 0.0,
              32.91: 0.0,
              32.94: 0.0,
              32.97: 0.0,
              '33.0': 0.0,
              33.03: 0.0,
              33.06: 0.0,
              33.09: 0.0,
              33.12: 0.0,
              33.15: 0.0,
              33.18: 0.0,
              33.21: 0.0,
              33.24: 0.0,
              33.27: 0.0,
              33.3: 0.0,
              33.33: 0.0,
              33.36: 0.0,
              33.39: 0.0,
              33.42: 0.0,
              33.45: 0.0,
              33.48: 0.0,
              33.51: 0.0,
              33.54: 0.0,
              33.57: 0.0,
              33.6: 0.0,
              33.63: 0.0,
              33.66: 0.0,
              33.69: 0.0,
              33.72: 0.0,
              33.75: 0.0,
              33.78: 0.0,
              33.81: 0.0,
              33.84: 0.0,
              33.87: 0.0,
              33.9: 0.0,
              33.93: 0.0,
              33.96: 0.0,
              33.99: 0.0,
              34.02: 0.0,
              34.05: 0.0,
              34.08: 0.0,
              34.11: 0.0,
              34.14: 0.0,
              34.17: 0.0,
              34.2: 0.0,
              34.23: 0.0,
              34.26: 0.0,
              34.29: 0.0,
              34.32: 0.0,
              34.35: 0.0,
              34.38: 0.0,
              34.41: 0.0,
              34.44: 0.0,
              34.47: 0.0,
              34.5: 0.0,
              34.53: 0.0,
              34.56: 0.0,
              34.59: 0.0,
              34.62: 0.0,
              34.65: 0.0,
              34.68: 0.0,
              34.71: 0.0,
              34.74: 0.0,
              34.77: 0.0,
              34.8: 0.0,
              34.83: 0.0,
              34.86: 0.0,
              34.89: 0.0,
              34.92: 0.0,
              34.95: 0.0,
              34.98: 0.0,
              35.01: 0.0,
              35.04: 0.0,
              35.07: 0.0,
              35.1: 0.0,
              35.13: 0.0,
              35.16: 0.0,
              35.19: 0.0,
              35.22: 0.0,
              35.25: 0.0,
              35.28: 0.0,
              35.31: 0.0,
              35.34: 0.0,
              35.37: 0.0,
              35.4: 0.0,
              35.43: 0.0,
              35.46: 0.0,
              35.49: 0.0,
              35.52: 0.0,
              35.55: 0.0,
              35.58: 0.0,
              35.61: 0.0,
              35.64: 0.0,
              35.67: 0.0,
              35.7: 0.0,
              35.73: 0.0,
              35.76: 0.0,
              35.79: 0.0,
              35.82: 0.0,
              35.85: 0.0,
              35.88: 0.0,
              35.91: 0.0,
              35.94: 0.0,
              35.97: 0.0,
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const preMeanEnrollmentRate = {
      result: {
        global: '54.74',
        country: {
          ABC: '49.56',
          Americas: '51.28',
        },
      },
      status: 'SUCCEEDED',
    };

    const postMeanEnrollmentRate = {
      result: {
        enrollment_data: {
          Country: [{
              expected_median_randomisation_enrollment_rate: '0.444',
              historical_median_randomisation_enrollment_rate: '0.666',
              level_name: 'france',
            },
            {
              expected_median_randomisation_enrollment_rate: '0.555',
              historical_median_randomisation_enrollment_rate: '0.888',
              level_name: 'usa',
            },
          ],
          Region: [{
              expected_median_randomisation_enrollment_rate: '0.444',
              historical_median_randomisation_enrollment_rate: '0.666',
              level_name: 'france',
            },
            {
              expected_median_randomisation_enrollment_rate: '0.555',
              historical_median_randomisation_enrollment_rate: '0.888',
              level_name: 'usa',
            },
          ],
          Global: [{
            expected_median_randomisation_enrollment_rate: '0.777',
            historical_median_randomisation_enrollment_rate: '0.333',
            level_name: 'Global',
          }],
        },
      },
      status: 'SUCCEEDED',
    };

    const uploadStudyFromCTFOtoCTEMP = {
      result: {
        study_id_details_list: [{
          study_id: 'CTFO2800646571',
          study_name: 'FF_test2_TestFFScenario_test2',
          study_type: 'New',
        }],
        theme_id: 'CTFO_20200812065500592',
      },
      status: 'SUCCEEDED',
    };

    const studyDetailedView = {
      median_adjusted_randomization_flag: true,
      result: [{
          actual_randomization_enrollment_rate: '0.000',
          adjusted_median_randomization_enrollment_rate: 24,
          mean_patient_dropout_rate: '0.000',
          median_randomization_enrollment_rate: '0.000',
          median_screen_fail_rate: '0.000',
          median_startup_duration: '0.0',
          num_investigators: '0',
          number_of_trials: '0',
          site_country: 'Kenya',
          site_id: 'CSITE_1',
          site_name: 'CSITE_1_I1',
          site_rank: '1',
          site_region: 'AFRICA',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: '1.708',
          adjusted_median_randomization_enrollment_rate: null,
          mean_patient_dropout_rate: '0.125',
          median_randomization_enrollment_rate: '1.160',
          median_screen_fail_rate: '0.050',
          median_startup_duration: '5.0',
          num_investigators: '6',
          number_of_trials: '2',
          site_country: 'Australia',
          site_id: 'BSITE_1',
          site_name: 'BSITE_1_B1',
          site_rank: '1',
          site_region: 'AUS',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: '0.695',
          adjusted_median_randomization_enrollment_rate: null,
          mean_patient_dropout_rate: '0.230',
          median_randomization_enrollment_rate: '1.230',
          median_screen_fail_rate: '0.140',
          median_startup_duration: '6.5',
          num_investigators: '3',
          number_of_trials: '2',
          site_country: 'United States',
          site_id: 'BSITE_2',
          site_name: 'BSITE_2_B1',
          site_rank: '2',
          site_region: 'NORTH AMERICA',
          site_tier: '2',
        },
      ],
      status: 'SUCCEEDED',
    };

    const studyDetailedViewSearch = {
      result: [{
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
        {
          actual_randomization_enrollment_rate: null,
          adjusted_median_randomization_enrollment_rate: '1.900',
          mean_patient_dropout_rate: '0.270',
          median_randomization_enrollment_rate: '0.552',
          median_screen_fail_rate: '0.030',
          median_startup_duration: '8.0',
          num_investigators: '5',
          number_of_trials: '1',
          site_country: 'Austria',
          site_id: 'SITE_1',
          site_name: 'SITE_1_A1',
          site_rank: '1',
          site_region: 'Europe',
          site_tier: '1',
        },
      ],
      status: 'SUCCEEDED',
    };

    const historicalSummaryGraphData = {
      result: {
        site_count_country: {
          actual: {
            max: {
              median_randamization_rate: null,
              no_of_sites: null,
            },
            median_randamization_rate: [],
            no_of_sites: [],
            range: {
              median_randamization_rate: null,
              no_of_sites: null,
            },
          },
          historical: {
            max: {
              median_randamization_rate: '5.515',
              no_of_sites: '73',
            },
            median_randamization_rate: [{
                code: 'AR',
                country: 'Argentina',
                median_randamization_rate: 1.342,
              },
              {
                code: 'BE',
                country: 'Belgium',
                median_randamization_rate: 0.486,
              },
              {
                code: 'HK',
                country: 'Hong Kong',
                median_randamization_rate: 1.977,
              },
              {
                code: 'BR',
                country: 'Brazil',
                median_randamization_rate: 1.977,
              },
              {
                code: 'CA',
                country: 'Canada',
                median_randamization_rate: 0.791,
              },
              {
                code: 'CL',
                country: 'Chile',
                median_randamization_rate: 5.515,
              },
              {
                code: 'CN',
                country: 'China',
                median_randamization_rate: 0.983,
              },
              {
                code: 'CZ',
                country: 'Czech Republic',
                median_randamization_rate: 1.139,
              },
              {
                code: 'FI',
                country: 'Finland',
                median_randamization_rate: 0.144,
              },
              {
                code: 'FR',
                country: 'France',
                median_randamization_rate: 0.971,
              },
              {
                code: 'DE',
                country: 'Germany',
                median_randamization_rate: 0.779,
              },
              {
                code: 'HU',
                country: 'Hungary',
                median_randamization_rate: 0.859,
              },
              {
                code: 'IN',
                country: 'India',
                median_randamization_rate: 1.679,
              },
              {
                code: 'IL',
                country: 'Israel',
                median_randamization_rate: 0.983,
              },
              {
                code: 'IT',
                country: 'Italy',
                median_randamization_rate: 0.53,
              },
              {
                code: 'JP',
                country: 'Japan',
                median_randamization_rate: 0.562,
              },
              {
                code: 'LT',
                country: 'Lithuania',
                median_randamization_rate: 1.148,
              },
              {
                code: 'PL',
                country: 'Poland',
                median_randamization_rate: 2.035,
              },
              {
                code: 'RO',
                country: 'Romania',
                median_randamization_rate: 0.852,
              },
              {
                code: 'RU',
                country: 'Russia',
                median_randamization_rate: 1.171,
              },
              {
                code: 'ZA',
                country: 'South Africa',
                median_randamization_rate: 1.422,
              },
              {
                code: 'ES',
                country: 'Spain',
                median_randamization_rate: 0.828,
              },
              {
                code: 'UA',
                country: 'Ukraine',
                median_randamization_rate: 1.048,
              },
              {
                code: 'GB',
                country: 'United Kingdom',
                median_randamization_rate: 0.745,
              },
              {
                code: 'US',
                country: 'United States',
                median_randamization_rate: 1.164,
              },
            ],
            no_of_sites: [{
                code: 'AR',
                country: 'Argentina',
                no_of_sites: 15,
              },
              {
                code: 'BE',
                country: 'Belgium',
                no_of_sites: 60,
              },
              {
                code: 'BR',
                country: 'Brazil',
                no_of_sites: 3,
              },
              {
                code: 'HK',
                country: 'Hong Kong',
                median_randamization_rate: 20,
              },
              {
                code: 'CA',
                country: 'Canada',
                no_of_sites: 8,
              },
              {
                code: 'CL',
                country: 'Chile',
                no_of_sites: 1,
              },
              {
                code: 'CN',
                country: 'China',
                no_of_sites: 7,
              },
              {
                code: 'CZ',
                country: 'Czech Republic',
                no_of_sites: 9,
              },
              {
                code: 'FI',
                country: 'Finland',
                no_of_sites: 1,
              },
              {
                code: 'FR',
                country: 'France',
                no_of_sites: 3,
              },
              {
                code: 'DE',
                country: 'Germany',
                no_of_sites: 9,
              },
              {
                code: 'HU',
                country: 'Hungary',
                no_of_sites: 22,
              },
              {
                code: 'IN',
                country: 'India',
                no_of_sites: 11,
              },
              {
                code: 'IL',
                country: 'Israel',
                no_of_sites: 10,
              },
              {
                code: 'IT',
                country: 'Italy',
                no_of_sites: 4,
              },
              {
                code: 'JP',
                country: 'Japan',
                no_of_sites: 4,
              },
              {
                code: 'LT',
                country: 'Lithuania',
                no_of_sites: 1,
              },
              {
                code: 'PL',
                country: 'Poland',
                no_of_sites: 11,
              },
              {
                code: 'RO',
                country: 'Romania',
                no_of_sites: 9,
              },
              {
                code: 'RU',
                country: 'Russia',
                no_of_sites: 60,
              },
              {
                code: 'ZA',
                country: 'South Africa',
                no_of_sites: 9,
              },
              {
                code: 'ES',
                country: 'Spain',
                no_of_sites: 9,
              },
              {
                code: 'UA',
                country: 'Ukraine',
                no_of_sites: 7,
              },
              {
                code: 'GB',
                country: 'United Kingdom',
                no_of_sites: 1,
              },
              {
                code: 'US',
                country: 'United States',
                no_of_sites: 73,
              },
            ],
            range: {
              median_randamization_rate: [{
                  from: 0.144,
                  to: 0.745,
                },
                {
                  from: 0.746,
                  to: 0.859,
                },
                {
                  from: 0.86,
                  to: 1.139,
                },
                {
                  from: 1.14,
                  to: 1.422,
                },
                {
                  from: 1.423,
                },
              ],
              no_of_sites: [{
                  from: 0,
                  to: 4,
                },
                {
                  from: 5,
                  to: 9,
                },
                {
                  from: 10,
                  to: 14,
                },
                {
                  from: 15,
                  to: 19,
                },
                {
                  from: 20,
                },
              ],
            },
          },
        },
        site_count_tier: {
          actual: {
            max: {
              median_randamization_rate: null,
              no_of_sites: null,
            },
            median_randamization_rate: [],
            no_of_sites: [],
          },
          historical: {
            max: {
              median_randamization_rate: '1.229',
              no_of_sites: '75',
            },
            median_randamization_rate: [{
                median_randamization_rate: 1.199,
                tier: '1',
              },
              {
                median_randamization_rate: 1.117,
                tier: '2',
              },
              {
                median_randamization_rate: 1.209,
                tier: '3',
              },
              {
                median_randamization_rate: 1.229,
                tier: '4',
              },
            ],
            no_of_sites: [{
                no_of_sites: 75,
                tier: '1',
              },
              {
                no_of_sites: 73,
                tier: '2',
              },
              {
                no_of_sites: 72,
                tier: '3',
              },
              {
                no_of_sites: 73,
                tier: '4',
              },
            ],
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const adjustRandomization = {
      result: [{
          adjusted_randomization_enrollment_rate: '1.000',
          adjustment_factor_perc: null,
          country: 'Argentina',
          replace_with: '1.0',
          site_randomization_enrollment_rate: '1.342',
        },
        {
          adjusted_randomization_enrollment_rate: '0.496',
          adjustment_factor_perc: '2.0',
          country: 'Belgium',
          replace_with: null,
          site_randomization_enrollment_rate: '0.486',
        },
        {
          adjusted_randomization_enrollment_rate: '3.001',
          adjustment_factor_perc: null,
          country: 'Brazil',
          replace_with: '3.0',
          site_randomization_enrollment_rate: '1.977',
        },
        {
          adjusted_randomization_enrollment_rate: null,
          adjustment_factor_perc: null,
          country: 'Canada',
          replace_with: null,
          site_randomization_enrollment_rate: '0.791',
        },
        {
          adjusted_randomization_enrollment_rate: '6.18',
          adjustment_factor_perc: null,
          country: 'Chile',
          replace_with: '7',
          site_randomization_enrollment_rate: '5.515',
        },
        {
          adjusted_randomization_enrollment_rate: null,
          adjustment_factor_perc: null,
          country: 'China',
          replace_with: null,
          site_randomization_enrollment_rate: '0.983',
        },
        {
          adjusted_randomization_enrollment_rate: null,
          adjustment_factor_perc: null,
          country: 'Czech Republic',
          replace_with: null,
          site_randomization_enrollment_rate: '1.139',
        },
        {
          adjusted_randomization_enrollment_rate: null,
          adjustment_factor_perc: null,
          country: 'Finland',
          replace_with: null,
          site_randomization_enrollment_rate: '0.144',
        },
      ],
      status: 'SUCCEEDED',
      total_records: '24',
    };

    // Fail
    /* const validationForHistoricalStatus = {
      "result": {
        "site_level": {
          "data_type_validation": true,
          "file_name": "error_csv_files/errors_Ca8188b396ctemp_admin20210617134432881_ctemp_admin_sitelevel.xlsx",
          "null_validation": true,
          "status": "failed",
          "summary_report": {
            "STUDY_1": [
              {
                "column": "trial_start_date",
                "message": "The column trial_start_date should have the unique values for study_data_id: STUDY_1"
              }
            ]
          },
          "validation_rules": false
        },
        "study_level": {
          "data_type_validation": true,
          "file_name": "error_csv_files/errors_Ca8188b396ctemp_admin20210617134432881_ctemp_admin_studylevel.xlsx",
          "null_validation": true,
          "status": "failed",
          "summary_report": {
            "STUDY_2": [
              {
                "column": "site_patient_dropout_rate",
                "message": "The value of site_patient_dropout_rate is not in b/w 0 and 1 for study_data_id: STUDY_2"
              }
            ]
          },
          "validation_rules": false
        }
      },
      "status": "SUCCEEDED"
    } */

    // Success
    const validationForHistoricalStatus = {
      result: {
        site_level: {
          data_type_validation: true,
          file_name: 'error_csv_files/errors_C9b2587ae3uk2614620210630110148560_uk26146_sitelevel.xlsx',
          null_validation: true,
          status: 'SUCCEEDED',
          summary_report: {
            '': [{
              column: '',
              message: 'CTEMP_SUBJECT_INPUT_FORMAT excel sheet is empty',
            }],
          },
          validation_rules: true,
        },
        study_level: {
          data_type_validation: true,
          file_name: 'error_csv_files/errors_C9b2587ae3uk2614620210630110148560_uk26146_studylevel.xlsx',
          null_validation: true,
          status: 'SUCCEEDED',
          summary_report: {
            '': [{
              column: '',
              message: 'CTEMP_INPUT_DATA_FORMAT excel sheet is empty',
            }],
          },
          validation_rules: true,
        },
      },
      status: 'SUCCEEDED',
    };

    const successResponse = {
      status: 'SUCCEEDED',
    };

    const preFeasibilityFilters = {
      result: {
        input_request: {
          indication: [],
          phase: [],
          therapeutic_area: [],
        },
        response: {
          indication: [
            'autoimmune/inflammation',
            'cardiovascular',
            'cardiovascular and metabolism',
            'genitourinary',
            'cardiovascular 2',
            'cardiovascular and metabolism 2',
            'genitourinary 2',
            'cardiovascular 3',
          ],
          phase: ['1', '1/2', '1A', '2', '2/3', '3', '3/4', '4', 'Other'],
          therapeutic_area: [
            'autoimmune/inflammation',
            'cardiovascular',
            'cardiovascular and metabolism',
            'genitourinary',
            'cardiovascular 2',
            'cardiovascular and metabolism 2',
            'genitourinary 2',
            'cardiovascular 3',
            'cardiovascular and metabolism 3',
            'genitourinary 3',
            'cardiovascular 4',
          ],
        },
      },
    };

    const preFeasibilityStudyId = {
      result: {
        study_id: 'FILE4152276218',
        theme_id: 'FILE203104152276245',
      },
      status: 'SUCCEEDED',
    };

    const uploadHistoricalBenchmarks = {
      result: {
        file_name_site: 'CTEMP_INPUT_DATA_FORMAT_v08_valid_site.csv',
        file_name_subject: 'CTEMP_INPUT_DATA_FORMAT_v08_valid_subject.csv',
        track_id: 'TM_20210601072009377',
      },
      status: 'SUCCEEDED',
    };

    const uploadTrackMonitor = {
      result: {
        file_name_site: 'CTEMP_INPUT_DATA_FORMAT_v08_valid_site.csv',
        file_name_subject: 'CTEMP_INPUT_DATA_FORMAT_v08_valid_subject.csv',
        track_id: 'TM_20210601072009377',
      },
      status: 'SUCCEEDED',
    };

    const cohortList = {
      result: {
        cohort_list: ['Cohort_1', 'Cohort_2', 'Cohort_3']
      },
      status: 'SUCCEEDED'
    };

    const viewSimulationGridData = {
      result: {
        countries: ['Argentina', 'Australia', 'Belgium', 'Brazil', 'Bulgaria'],
        actual_psm_info: [
          {
            "cohort_name": "Cohort_1",
            "geo_level_details": [{
                "geo_level_value": "Argentina",
                "psm_param_1": 11,
                "psm_param_2": 21,
                "psm_param_3": 33
              },
              {
                "geo_level_value": "Australia",
                "psm_param_1": 15,
                "psm_param_2": 12,
                "psm_param_3": 13
              },
              {
                "geo_level_value": "Belgium",
                "psm_param_1": 18,
                "psm_param_2": 2,
                "psm_param_3": 32
              },
              {
                "geo_level_value": "Brazil",
                "psm_param_1": 10,
                "psm_param_2": 5,
                "psm_param_3": 7
              },
              {
                "geo_level_value": "Bulgaria",
                "psm_param_1": 3,
                "psm_param_2": 5,
                "psm_param_3": 6
              }
            ]
          },
          {
            "cohort_name": "Cohort_2",
            "geo_level_details": [{
                "geo_level_value": "Argentina",
                "psm_param_1": 9,
                "psm_param_2": 10,
                "psm_param_3": 11
              },
              {
                "geo_level_value": "Australia",
                "psm_param_1": 12,
                "psm_param_2": 13,
                "psm_param_3": 33
              },
              {
                "geo_level_value": "Belgium",
                "psm_param_1": 14,
                "psm_param_2": 15,
                "psm_param_3": 15
              },
              {
                "geo_level_value": "Brazil",
                "psm_param_1": 16,
                "psm_param_2": 17,
                "psm_param_3": 13
              },
              {
                "geo_level_value": "Bulgaria",
                "psm_param_1": 1,
                "psm_param_2": 2,
                "psm_param_3": 3
              }
            ]
          },
          {
            "cohort_name": "Cohort_3",
            "geo_level_details": [{
                "geo_level_value": "Argentina",
                "psm_param_1": 11,
                "psm_param_2": 12,
                "psm_param_3": 13
              },
              {
                "geo_level_value": "Australia",
                "psm_param_1": 21,
                "psm_param_2": 22,
                "psm_param_3": 23
              },
              {
                "geo_level_value": "Belgium",
                "psm_param_1": 3,
                "psm_param_2": 10,
                "psm_param_3": 0.3
              },
              {
                "geo_level_value": "Brazil",
                "psm_param_1": 0.3,
                "psm_param_2": 2,
                "psm_param_3": 1.3
              },
              {
                "geo_level_value": "Bulgaria",
                "psm_param_1": 1.3,
                "psm_param_2": 2,
                "psm_param_3": 1.2
              }
            ]
          }
        ],
        historical_psm_info: [
          {
            "cohort_name": "Cohort_1",
            "geo_level_details": [{
                "geo_level_value": "Argentina",
                "psm_param_1": 1.3,
                "psm_param_2": 12,
                "psm_param_3": 2.3
              },
              {
                "geo_level_value": "Australia",
                "psm_param_1": 1.33,
                "psm_param_2": 1,
                "psm_param_3": 3
              },
              {
                "geo_level_value": "Belgium",
                "psm_param_1": 3,
                "psm_param_2": 10,
                "psm_param_3": 0.3
              },
              {
                "geo_level_value": "Brazil",
                "psm_param_1": 0.3,
                "psm_param_2": 2,
                "psm_param_3": 1.3
              },
              {
                "geo_level_value": "Bulgaria",
                "psm_param_1": 1.3,
                "psm_param_2": 2,
                "psm_param_3": 1.2
              }
            ]
          },
          {
            "cohort_name": "Cohort_2",
            "geo_level_details": [{
                "geo_level_value": "Argentina",
                "psm_param_1": 1.3,
                "psm_param_2": 1.4,
                "psm_param_3": 1.22
              },
              {
                "geo_level_value": "Australia",
                "psm_param_1": 1.3,
                "psm_param_2": 1.4,
                "psm_param_3": 1.22
              },
              {
                "geo_level_value": "Belgium",
                "psm_param_1": 1.3,
                "psm_param_2": 1.4,
                "psm_param_3": 1.22
              },
              {
                "geo_level_value": "Brazil",
                "psm_param_1": 1.3,
                "psm_param_2": 1.4,
                "psm_param_3": 1.22
              },
              {
                "geo_level_value": "Bulgaria",
                "psm_param_1": 0.33,
                "psm_param_2": 3,
                "psm_param_3": 0.33
              }
            ]
          },
          {
            "cohort_name": "Cohort_3",
            "geo_level_details": [{
                "geo_level_value": "Argentina",
                "psm_param_1": 2.3,
                "psm_param_2": 2.23,
                "psm_param_3": 1.43
              },
              {
                "geo_level_value": "Australia",
                "psm_param_1": 2.13,
                "psm_param_2": 2.23,
                "psm_param_3": 1.43
              },
              {
                "geo_level_value": "Belgium",
                "psm_param_1": 2.3,
                "psm_param_2": 2.23,
                "psm_param_3": 1.43
              },
              {
                "geo_level_value": "Brazil",
                "psm_param_1": 2.3,
                "psm_param_2": 2.23,
                "psm_param_3": 1.43
              },
              {
                "geo_level_value": "Bulgaria",
                "psm_param_1": 2.3,
                "psm_param_2": 2.23,
                "psm_param_3": 1.43
              }
            ]
          }
        ],
        user_psm_info: [
          // {
          //   "cohort_name": "Cohort_1",
          //   "geo_level_details": [{
          //       "geo_level_value": "Argentina",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 12,
          //       "target_no_sites": 13
          //     },
          //     {
          //       "geo_level_value": "Australia",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 42,
          //       "target_no_sites": 13
          //     },
          //     {
          //       "geo_level_value": "Belgium",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 61,
          //       "target_no_sites": 11
          //     },
          //     {
          //       "geo_level_value": "Brazil",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 21,
          //       "target_no_sites": 16
          //     },
          //     {
          //       "geo_level_value": "Bulgaria",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 6,
          //       "target_no_sites": 17
          //     }
          //   ]
          // },
          // {
          //   "cohort_name": "Cohort_2",
          //   "geo_level_details": [{
          //       "geo_level_value": "Argentina",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 2,
          //       "target_no_sites": 10
          //     },
          //     {
          //       "geo_level_value": "Australia",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 60,
          //       "target_no_sites": 18
          //     },
          //     {
          //       "geo_level_value": "Belgium",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 60,
          //       "target_no_sites": 14
          //     },
          //     {
          //       "geo_level_value": "Brazil",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 15,
          //       "target_no_sites": 17
          //     },
          //     {
          //       "geo_level_value": "Bulgaria",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 72,
          //       "target_no_sites": 18
          //     }
          //   ]
          // },
          // {
          //   "cohort_name": "Cohort_3",
          //   "geo_level_details": [{
          //       "geo_level_value": "Argentina",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 32,
          //       "target_no_sites": 19
          //     },
          //     {
          //       "geo_level_value": "Australia",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 52,
          //       "target_no_sites": 10
          //     },
          //     {
          //       "geo_level_value": "Belgium",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 12,
          //       "target_no_sites": 8
          //     },
          //     {
          //       "geo_level_value": "Brazil",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 22,
          //       "target_no_sites": 14
          //     },
          //     {
          //       "geo_level_value": "Bulgaria",
          //       "psm_param_1": 1,
          //       "psm_param_2": 2,
          //       "psm_param_3": 3,
          //       "selected_flag": "Y",
          //       "target_no_patients": 34,
          //       "target_no_sites": 19
          //     }
          //   ]
          // }
        ],
        psm_param_mapping: {
          "gamma": {
            "psm_param_1": "Min PSM Mean",
            "psm_param_2": "Variance for PSM",
            "psm_param_3": "Max PSM Mean"
          },
          "half-norm": {
            "psm_param_1": "Min PSM Mean",
            "psm_param_2": "Variance  PSM Mean",
            "psm_param_3": "Max PSM Mean"
          },
          "triangular": {
            "psm_param_1": "Min PSM Mean",
            "psm_param_2": "Median for PSM",
            "psm_param_3": "Max PSM Mean"
          }
        },
      },
      status: "SUCCEEDED",

      // result: {
      //    actual_psm_info: [
      //       //   {
      //       //     "geo_level_value":"Americas",
      //       //     "psm_param_1":0.03,
      //       //     "psm_param_2":0.01,
      //       //     "psm_param_3":0.33,
      //       // },
      //       // {
      //       //    "geo_level_value":"Europe",
      //       //    "psm_param_1":0.0,
      //       //    "psm_param_2":0.08,
      //       //    "psm_param_3":0.93,
      //       // },
      //       // {
      //       //    "geo_level_value":"North America",
      //       //    "psm_param_1":0.0,
      //       //    "psm_param_2":0.08,
      //       //    "psm_param_3":0.96,
      //       // },
      //    ],
      //    historical_psm_info: [
      //       {
      //          geo_level_value: 'Americas',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.01,
      //          psm_param_3: 0.33,
      //       },
      //       {
      //          geo_level_value: 'Asia',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.17,
      //          psm_param_3: 0.99,
      //       },
      //       {
      //          geo_level_value: 'Australia_Oceania',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.0,
      //          psm_param_3: 0.1,
      //       },
      //       {
      //          geo_level_value: 'Eastern Europe',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.0,
      //          psm_param_3: 0.13,
      //       },
      //       {
      //          geo_level_value: 'Europe',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.01,
      //          psm_param_3: 0.33,
      //       },
      //       {
      //          geo_level_value: 'North America',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.01,
      //          psm_param_3: 0.33,
      //       },
      //       {
      //          geo_level_value: 'South America',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.0,
      //          psm_param_3: 0.06,
      //       },
      //       {
      //          geo_level_value: 'Western Asia_Middle East',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.0,
      //          psm_param_3: 0.06,
      //       },
      //       {
      //          geo_level_value: 'Western Europe',
      //          psm_param_1: 0.03,
      //          psm_param_2: 0.01,
      //          psm_param_3: 0.33,
      //       },
      //    ],
      //    psm_param_mapping: {
      //       psm_param_1: 'Min PSM Mean',
      //       psm_param_2: 'Variance for PSM',
      //       psm_param_3: 'Max PSM Mean',
      //    },
      //    user_psm_info: [
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'Americas',
      //          psm_param_1: 10,
      //          psm_param_2: 10,
      //          psm_param_3: 11,
      //          selected_flag: 'Y',
      //          target_no_patients: 20,
      //          target_no_sites: 20,
      //       },
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'Asia',
      //          psm_param_1: null,
      //          psm_param_2: null,
      //          psm_param_3: null,
      //          selected_flag: 'N',
      //          target_no_patients: null,
      //          target_no_sites: null,
      //       },
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'Australia_Oceania',
      //          psm_param_1: null,
      //          psm_param_2: null,
      //          psm_param_3: null,
      //          selected_flag: 'N',
      //          target_no_patients: null,
      //          target_no_sites: null,
      //       },
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'Eastern Europe',
      //          psm_param_1: null,
      //          psm_param_2: null,
      //          psm_param_3: null,
      //          selected_flag: 'N',
      //          target_no_patients: null,
      //          target_no_sites: null,
      //       },
      //       {
      //          actual_enrolled_patient: '34',
      //          actual_site: '12',
      //          geo_level_value: 'Europe',
      //          psm_param_1: 1,
      //          psm_param_2: 3,
      //          psm_param_3: 2,
      //          selected_flag: 'Y',
      //          target_no_patients: 40,
      //          target_no_sites: 15,
      //       },
      //       {
      //          actual_enrolled_patient: '22',
      //          actual_site: '8',
      //          geo_level_value: 'North America',
      //          psm_param_1: 1,
      //          psm_param_2: 3,
      //          psm_param_3: 2,
      //          selected_flag: 'Y',
      //          target_no_patients: 23,
      //          target_no_sites: 15,
      //       },
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'South America',
      //          psm_param_1: null,
      //          psm_param_2: null,
      //          psm_param_3: null,
      //          selected_flag: 'N',
      //          target_no_patients: null,
      //          target_no_sites: null,
      //       },
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'Western Asia_Middle East',
      //          psm_param_1: null,
      //          psm_param_2: null,
      //          psm_param_3: null,
      //          selected_flag: 'N',
      //          target_no_patients: null,
      //          target_no_sites: null,
      //       },
      //       {
      //          actual_enrolled_patient: null,
      //          actual_site: null,
      //          geo_level_value: 'Western Europe',
      //          psm_param_1: '50.0',
      //          psm_param_2: '50.0',
      //          psm_param_3: '50.0',
      //          selected_flag: 'Y',
      //          target_no_patients: 50,
      //          target_no_sites: 50,
      //       },
      //    ],
      // },
    };

    const historicalCurvePref = {
      result: {
        20: '40',
        30: '80',
        60: '90',
        90: '40',
        100: '98',
      },
      status: 'SUCCEEDED',
    };

    const simulatedCurvePref = {
      result: {
        0: '4',
        20: '14',
        40: '19',
        60: '58',
        90: '66',
        100: '100',
      },
      status: 'SUCCEEDED',
    };

    const runEnrollmentModelPref = {
      result: 'Results saved',
      status: 'SUCCEEDED',
    };

    const runEnrollmentModelStatusPref = {
      result: [{
        description: 'View Enrollment DS Model Run Completed.',
        last_updated_time: '2021-02-03 04:58:50.407178',
        model_run_id: '20210121134601647-bcdd019b-d679-4e8d-b62b-7506ffb47290-enrollment_model_run-aj1234',
        model_run_status: 'Completed',
        start_time: '2021-02-03 04:58:50.407171',
      }],
      status: 'SUCCEEDED',
    };

    const runEnrollmentModelStatus = {
      result: [{
        description: 'View Enrollment DS Model Run Completed.',
        last_updated_time: '2021-02-03 04:58:50.407178',
        model_run_id: '20210121134601647-bcdd019b-d679-4e8d-b62b-7506ffb47290-enrollment_model_run-aj1234',
        model_run_status: 'completed',
        start_time: '2021-02-03 04:58:50.407171',
      }],
      status: 'SUCCEEDED',
    };

    // Country
    const country = {
      result: {
        geography_info: [
          {
            cohort_name: 'Cohort_1',
            geo_level_details: [
              {
                actual_enrolled_patient: null,
                actual_median_randomization_enrollment_rate: null,
                created_by: null,
                created_timestamp: null,
                expected_median_randomisation_enrollment_rate: '2',
                expected_std_randomisation_enrollment_rate: '2',
                historical_median_randomisation_enrollment_rate: '1.16',
                historical_no_sites: '5',
                is_selected: '0',
                level_value: 'Australia',
                median_adjusted_randomisation_enrollment_rate: '1.16',
                modelling_level: 'country',
                region: 'Australia and New Zealand',
                std_randomisation_enrollment_rate: '0.45',
                target_no_patients: '10',
              },
              {
                actual_enrolled_patient: '8',
                actual_median_randomization_enrollment_rate: '0.00',
                created_by: 'aj1234',
                created_timestamp: '2021-05-03 06:18:28.669000',
                expected_median_randomisation_enrollment_rate: '1.0',
                expected_std_randomisation_enrollment_rate: '1.0',
                historical_median_randomisation_enrollment_rate: '0.42',
                historical_no_sites: '1',
                is_selected: '1',
                level_value: 'Belgium',
                median_adjusted_randomisation_enrollment_rate: '0.32',
                modelling_level: 'country',
                region: 'Western Europe',
                std_randomisation_enrollment_rate: '0.36',
                target_no_patients: '11',
              },
              {
                actual_enrolled_patient: '28',
                actual_median_randomization_enrollment_rate: '0.32',
                created_by: 'aj1234',
                created_timestamp: '2021-05-03 06:18:28.669000',
                expected_median_randomisation_enrollment_rate: '3.0',
                expected_std_randomisation_enrollment_rate: '3.0',
                historical_median_randomisation_enrollment_rate: '0.58',
                historical_no_sites: '24',
                is_selected: '1',
                level_value: 'Canada',
                median_adjusted_randomisation_enrollment_rate: '0.51',
                modelling_level: 'country',
                region: 'Northern America',
                std_randomisation_enrollment_rate: '0.41',
                target_no_patients: '33',
              },
            ]
          },
          {
            cohort_name: 'Cohort_2',
            geo_level_details: [
              {
                actual_enrolled_patient: null,
                actual_median_randomization_enrollment_rate: null,
                created_by: null,
                created_timestamp: null,
                expected_median_randomisation_enrollment_rate: '2',
                expected_std_randomisation_enrollment_rate: '2',
                historical_median_randomisation_enrollment_rate: '1.16',
                historical_no_sites: '5',
                is_selected: '0',
                level_value: 'Australia',
                median_adjusted_randomisation_enrollment_rate: '1.16',
                modelling_level: 'country',
                region: 'Australia and New Zealand',
                std_randomisation_enrollment_rate: '0.45',
                target_no_patients: '8',
              },
              {
                actual_enrolled_patient: '8',
                actual_median_randomization_enrollment_rate: '0.00',
                created_by: 'aj1234',
                created_timestamp: '2021-05-03 06:18:28.669000',
                expected_median_randomisation_enrollment_rate: '1.0',
                expected_std_randomisation_enrollment_rate: '1.0',
                historical_median_randomisation_enrollment_rate: '0.42',
                historical_no_sites: '1',
                is_selected: '1',
                level_value: 'Belgium',
                median_adjusted_randomisation_enrollment_rate: '0.32',
                modelling_level: 'country',
                region: 'Western Europe',
                std_randomisation_enrollment_rate: '0.36',
                target_no_patients: '11',
              },
              {
                actual_enrolled_patient: '28',
                actual_median_randomization_enrollment_rate: '0.32',
                created_by: 'aj1234',
                created_timestamp: '2021-05-03 06:18:28.669000',
                expected_median_randomisation_enrollment_rate: '3.0',
                expected_std_randomisation_enrollment_rate: '3.0',
                historical_median_randomisation_enrollment_rate: '0.58',
                historical_no_sites: '24',
                is_selected: '1',
                level_value: 'Canada',
                median_adjusted_randomisation_enrollment_rate: '0.51',
                modelling_level: 'country',
                region: 'Northern America',
                std_randomisation_enrollment_rate: '0.41',
                target_no_patients: '33',
              },
            ]
          },
          {
            cohort_name: 'Cohort_3',
            geo_level_details: [
              {
                actual_enrolled_patient: null,
                actual_median_randomization_enrollment_rate: null,
                created_by: null,
                created_timestamp: null,
                expected_median_randomisation_enrollment_rate: '1.2',
                expected_std_randomisation_enrollment_rate: '1',
                historical_median_randomisation_enrollment_rate: '1.16',
                historical_no_sites: '5',
                is_selected: '0',
                level_value: 'Australia',
                median_adjusted_randomisation_enrollment_rate: '1.16',
                modelling_level: 'country',
                region: 'Australia and New Zealand',
                std_randomisation_enrollment_rate: '0.45',
                target_no_patients: '10',
              },
              {
                actual_enrolled_patient: '8',
                actual_median_randomization_enrollment_rate: '0.00',
                created_by: 'aj1234',
                created_timestamp: '2021-05-03 06:18:28.669000',
                expected_median_randomisation_enrollment_rate: '1.0',
                expected_std_randomisation_enrollment_rate: '1.0',
                historical_median_randomisation_enrollment_rate: '0.42',
                historical_no_sites: '1',
                is_selected: '1',
                level_value: 'Belgium',
                median_adjusted_randomisation_enrollment_rate: '0.32',
                modelling_level: 'country',
                region: 'Western Europe',
                std_randomisation_enrollment_rate: '0.36',
                target_no_patients: '11',
              },
              {
                actual_enrolled_patient: '28',
                actual_median_randomization_enrollment_rate: '0.32',
                created_by: 'aj1234',
                created_timestamp: '2021-05-03 06:18:28.669000',
                expected_median_randomisation_enrollment_rate: '3.0',
                expected_std_randomisation_enrollment_rate: '3.0',
                historical_median_randomisation_enrollment_rate: '0.58',
                historical_no_sites: '24',
                is_selected: '1',
                level_value: 'Canada',
                median_adjusted_randomisation_enrollment_rate: '0.51',
                modelling_level: 'country',
                region: 'Northern America',
                std_randomisation_enrollment_rate: '0.41',
                target_no_patients: '33',
              },
            ]
          }
        ],
      },
      status: 'SUCCEEDED',
    };

    // Region
    const reg = {
      result: {
        geography_info: [
          { cohort_name: 'Cohort_1',
            geo_level_details : [
              {
                actual_enrolled_patient: null,
                actual_median_randomization_enrollment_rate: null,
                countries: [{
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Australia',
                    historical_median_randomisation_enrollment_rate: '1.16',
                    historical_no_sites: '5',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '1.16',
                    region: 'Australia and New Zealand',
                    std_randomisation_enrollment_rate: '0.45',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'New Zealand',
                    historical_median_randomisation_enrollment_rate: '0.81',
                    historical_no_sites: '3',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.81',
                    region: 'Australia and New Zealand',
                    std_randomisation_enrollment_rate: '0.27',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '20',
                historical_median_randomisation_enrollment_rate: '0.90',
                historical_no_sites: '8',
                level_value: 'Australia and New Zealand',
                median_adjusted_randomisation_enrollment_rate: '0.90',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.41',
                target_no_patients: '22',
              },
              {
                actual_enrolled_patient: '215',
                actual_median_randomization_enrollment_rate: '0.34',
                countries: [{
                    actual_enrolled_patient: '28',
                    actual_median_randomization_enrollment_rate: '0.32',
                    country: 'Canada',
                    historical_median_randomisation_enrollment_rate: '0.58',
                    historical_no_sites: '24',
                    is_selected: '0',
                    median_adjusted_randomisation_enrollment_rate: '0.51',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: '0.41',
                  },
                  {
                    actual_enrolled_patient: '187',
                    actual_median_randomization_enrollment_rate: '0.36',
                    country: 'United States',
                    historical_median_randomisation_enrollment_rate: '0.44',
                    historical_no_sites: '63',
                    is_selected: '0',
                    median_adjusted_randomisation_enrollment_rate: '0.43',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: '0.55',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '10',
                historical_median_randomisation_enrollment_rate: '0.48',
                historical_no_sites: '87',
                level_value: 'Northern America',
                median_adjusted_randomisation_enrollment_rate: '0.45',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.51',
                target_no_patients: '300',
              },
              {
                actual_enrolled_patient: '8',
                actual_median_randomization_enrollment_rate: '0.00',
                countries: [{
                    actual_enrolled_patient: '8',
                    actual_median_randomization_enrollment_rate: '0.00',
                    country: 'Belgium',
                    historical_median_randomisation_enrollment_rate: '0.42',
                    historical_no_sites: '11',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.32',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.36',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'France',
                    historical_median_randomisation_enrollment_rate: '0.52',
                    historical_no_sites: '18',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.52',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.53',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Germany',
                    historical_median_randomisation_enrollment_rate: '0.40',
                    historical_no_sites: '6',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.40',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.45',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '20',
                historical_median_randomisation_enrollment_rate: '0.48',
                historical_no_sites: '35',
                level_value: 'Western Europe',
                median_adjusted_randomisation_enrollment_rate: '0.44',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.48',
                target_no_patients: '20',
              },
            ]
          },
          { cohort_name: 'Cohort_2',
            geo_level_details : [
              {
                actual_enrolled_patient: null,
                cohort_name: 'Experienced Cohort',
                actual_median_randomization_enrollment_rate: null,
                countries: [{
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Australia',
                    historical_median_randomisation_enrollment_rate: '1.16',
                    historical_no_sites: '5',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '1.16',
                    region: 'Australia and New Zealand',
                    std_randomisation_enrollment_rate: '0.45',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'New Zealand',
                    historical_median_randomisation_enrollment_rate: '0.81',
                    historical_no_sites: '3',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.81',
                    region: 'Australia and New Zealand',
                    std_randomisation_enrollment_rate: '0.27',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '20',
                historical_median_randomisation_enrollment_rate: '0.90',
                historical_no_sites: '8',
                level_value: 'Australia and New Zealand',
                median_adjusted_randomisation_enrollment_rate: '0.90',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.41',
                target_no_patients: '22',
              },
              {
                actual_enrolled_patient: '215',
                actual_median_randomization_enrollment_rate: '0.34',
                countries: [{
                    actual_enrolled_patient: '28',
                    actual_median_randomization_enrollment_rate: '0.32',
                    country: 'Canada',
                    historical_median_randomisation_enrollment_rate: '0.58',
                    historical_no_sites: '24',
                    is_selected: '0',
                    median_adjusted_randomisation_enrollment_rate: '0.51',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: '0.41',
                  },
                  {
                    actual_enrolled_patient: '187',
                    actual_median_randomization_enrollment_rate: '0.36',
                    country: 'United States',
                    historical_median_randomisation_enrollment_rate: '0.44',
                    historical_no_sites: '63',
                    is_selected: '0',
                    median_adjusted_randomisation_enrollment_rate: '0.43',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: '0.55',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '10',
                historical_median_randomisation_enrollment_rate: '0.48',
                historical_no_sites: '87',
                level_value: 'Northern America',
                median_adjusted_randomisation_enrollment_rate: '0.45',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.51',
                target_no_patients: '300',
              },
              {
                actual_enrolled_patient: '8',
                actual_median_randomization_enrollment_rate: '0.00',
                countries: [{
                    actual_enrolled_patient: '8',
                    actual_median_randomization_enrollment_rate: '0.00',
                    country: 'Belgium',
                    historical_median_randomisation_enrollment_rate: '0.42',
                    historical_no_sites: '11',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.32',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.36',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'France',
                    historical_median_randomisation_enrollment_rate: '0.52',
                    historical_no_sites: '18',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.52',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.53',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Germany',
                    historical_median_randomisation_enrollment_rate: '0.40',
                    historical_no_sites: '6',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.40',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.45',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '20',
                historical_median_randomisation_enrollment_rate: '0.48',
                historical_no_sites: '35',
                level_value: 'Western Europe',
                median_adjusted_randomisation_enrollment_rate: '0.44',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.48',
                target_no_patients: '20',
              },
            ]
          },
          {cohort_name: 'Cohort_3',
            geo_level_details : [
              {
                actual_enrolled_patient: null,
                cohort_name: 'Experienced Cohort',
                actual_median_randomization_enrollment_rate: null,
                countries: [{
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Australia',
                    historical_median_randomisation_enrollment_rate: '1.16',
                    historical_no_sites: '5',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '1.16',
                    region: 'Australia and New Zealand',
                    std_randomisation_enrollment_rate: '0.45',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'New Zealand',
                    historical_median_randomisation_enrollment_rate: '0.81',
                    historical_no_sites: '3',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.81',
                    region: 'Australia and New Zealand',
                    std_randomisation_enrollment_rate: '0.27',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '20',
                historical_median_randomisation_enrollment_rate: '0.90',
                historical_no_sites: '8',
                level_value: 'Australia and New Zealand',
                median_adjusted_randomisation_enrollment_rate: '0.90',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.41',
                target_no_patients: '22',
              },
              {
                actual_enrolled_patient: '215',
                actual_median_randomization_enrollment_rate: '0.34',
                countries: [{
                    actual_enrolled_patient: '28',
                    actual_median_randomization_enrollment_rate: '0.32',
                    country: 'Canada',
                    historical_median_randomisation_enrollment_rate: '0.58',
                    historical_no_sites: '24',
                    is_selected: '0',
                    median_adjusted_randomisation_enrollment_rate: '0.51',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: '0.41',
                  },
                  {
                    actual_enrolled_patient: '187',
                    actual_median_randomization_enrollment_rate: '0.36',
                    country: 'United States',
                    historical_median_randomisation_enrollment_rate: '0.44',
                    historical_no_sites: '63',
                    is_selected: '0',
                    median_adjusted_randomisation_enrollment_rate: '0.43',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: '0.55',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '10',
                historical_median_randomisation_enrollment_rate: '0.48',
                historical_no_sites: '87',
                level_value: 'Northern America',
                median_adjusted_randomisation_enrollment_rate: '0.45',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.51',
                target_no_patients: '300',
              },
              {
                actual_enrolled_patient: '8',
                actual_median_randomization_enrollment_rate: '0.00',
                countries: [{
                    actual_enrolled_patient: '8',
                    actual_median_randomization_enrollment_rate: '0.00',
                    country: 'Belgium',
                    historical_median_randomisation_enrollment_rate: '0.42',
                    historical_no_sites: '11',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.32',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.36',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'France',
                    historical_median_randomisation_enrollment_rate: '0.52',
                    historical_no_sites: '18',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.52',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.53',
                  },
                  {
                    actual_enrolled_patient: null,
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Germany',
                    historical_median_randomisation_enrollment_rate: '0.40',
                    historical_no_sites: '6',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '0.40',
                    region: 'Western Europe',
                    std_randomisation_enrollment_rate: '0.45',
                  },
                ],
                created_by: 'aj1234',
                created_timestamp: '2021-05-13 16:25:18.061000',
                expected_median_randomisation_enrollment_rate: '10',
                expected_std_randomisation_enrollment_rate: '20',
                historical_median_randomisation_enrollment_rate: '0.48',
                historical_no_sites: '35',
                level_value: 'Western Europe',
                median_adjusted_randomisation_enrollment_rate: '0.44',
                modelling_level: 'region',
                region: ' ',
                std_randomisation_enrollment_rate: '0.48',
                target_no_patients: '20',
              },
            ]
          }
        ],
      },
      status: 'SUCCEEDED',
    };

    // Global
    const countryDataGeoSelction = {
      result: {
        geography_info: [
          {
            cohort_name: 'cohort_1',
            geo_level_details : [
              {
                actual_enrolled_patient: '12',
                actual_median_randomization_enrollment_rate: null,
                countries: [
                  {
                    actual_enrolled_patient: '12',
                    actual_median_randomization_enrollment_rate: null,
                    country: 'Canada',
                    historical_median_randomisation_enrollment_rate: '1.95',
                    historical_no_sites: '1',
                    is_selected: '1',
                    median_adjusted_randomisation_enrollment_rate: '1.95',
                    region: 'Northern America',
                    std_randomisation_enrollment_rate: null,
                  },
                  {
                    "actual_enrolled_patient": "12",
                    "actual_median_randomization_enrollment_rate": null,
                    "country": "Finland",
                    "historical_median_randomisation_enrollment_rate": "2.21",
                    "historical_no_sites": "6",
                    "is_selected": "1",
                    "median_adjusted_randomisation_enrollment_rate": "2.21",
                    "region": "Northern Europe",
                    "std_randomisation_enrollment_rate": "0.52",
                  },
                  {
                    "actual_enrolled_patient": "12",
                    "actual_median_randomization_enrollment_rate": null,
                    "country": "Norway",
                    "historical_median_randomisation_enrollment_rate": "1.72",
                    "historical_no_sites": "7",
                    "is_selected": "1",
                    "median_adjusted_randomisation_enrollment_rate": "1.72",
                    "region": "Northern Europe",
                    "std_randomisation_enrollment_rate": "0.41",
                  },
                  {
                    "actual_enrolled_patient": "12",
                    "actual_median_randomization_enrollment_rate": null,
                    "country": "Russia",
                    "historical_median_randomisation_enrollment_rate": "4.66",
                    "historical_no_sites": "1",
                    "is_selected": "1",
                    "median_adjusted_randomisation_enrollment_rate": "4.66",
                    "region": "Eastern Europe",
                    "std_randomisation_enrollment_rate": null,
                  },
                  {
                    "actual_enrolled_patient": "12",
                    "actual_median_randomization_enrollment_rate": null,
                    "country": "South Africa",
                    "historical_median_randomisation_enrollment_rate": "1.72",
                    "historical_no_sites": "2",
                    "is_selected": "1",
                    "median_adjusted_randomisation_enrollment_rate": "1.72",
                    "region": "Southern Africa",
                    "std_randomisation_enrollment_rate": "0.23",
                  }
                ],
                created_by: 'ctemp_admin',
                created_timestamp: '2021-04-29 08:28:15.634000',
                expected_median_randomisation_enrollment_rate: null,
                expected_std_randomisation_enrollment_rate: null,
                historical_median_randomisation_enrollment_rate: '1.83',
                historical_no_sites: '27',
                level_value: 'Global',
                median_adjusted_randomisation_enrollment_rate: '1.83',
                modelling_level: 'global',
                region: ' ',
                std_randomisation_enrollment_rate: '0.68',
                target_no_patients: '50',
              },
            ]
          }
        ],
      },
      status: 'SUCCEEDED',
    };


    // graphDistribution

    const graphDistribution = {
      result: {
        geo_level_distribution: [{
            actual: {
              '-0.041744978477915': 0.0037079623029640002,
              '-0.030316556442961003': 0.004266275879592001,
              '-0.018888134408007': 0.0048982314366020005,
              '-0.007459712373053': 0.005611870630944001,
              0.0039687096619: 0.006415866044628,
              0.015397131696854001: 0.007319536452261001,
              0.026825553731807002: 0.008332857434196001,
              0.038253975766761004: 0.009466466598019,
              0.049682397801715: 0.010731662658870001,
              0.061110819836669: 0.012140397628609,
              0.072539241871623: 0.013705261376615001,
              0.083967663906577: 0.01543945785241,
              0.09539608594153101: 0.017356772303633,
              0.106824507976485: 0.019471528883009,
              0.11825293001143901: 0.021798538115796,
              0.129681352046393: 0.024353033794985,
              0.141109774081347: 0.027150598985543003,
              0.152538196116301: 0.030207080950708003,
              0.16396661815125502: 0.033538494962136005,
              0.17539504018620902: 0.037160917120311006,
              0.186823462221162: 0.041090366490255006,
              0.198251884256116: 0.045342677048213,
              0.20968030629107: 0.049933360134765,
              0.22110872832602402: 0.05487745831578,
              0.23253715036097802: 0.060189391761207,
              0.24396557239593203: 0.065882798458884,
              0.255393994430886: 0.07197036978240201,
              0.26682241646584004: 0.078463683123887,
              0.278250838500794: 0.085373033480098,
              0.289679260535748: 0.09270726603875201,
              0.30110768257070203: 0.100473611947144,
              0.312536104605656: 0.10867752955264101,
              0.32396452664061004: 0.11732255348060001,
              0.335392948675563: 0.12641015395622102,
              0.34682137071051705: 0.13593960877994402,
              0.35824979274547103: 0.145907890328931,
              0.369678214780425: 0.15630956987854702,
              0.38110663681537904: 0.16713674141686002,
              0.392535058850333: 0.17837896696230102,
              0.40396348088528705: 0.19002324519095,
              0.41539190292024103: 0.20205400493743603,
              0.426820324955195: 0.21445312485530202,
              0.43824874699014904: 0.227199980212643,
              0.449677169025103: 0.24027151746157202,
              0.46110559106005705: 0.25364235686096304,
              0.47253401309501103: 0.26728492305693,
              0.48396243512996406: 0.281169603140878,
              0.49539085716491804: 0.295264931317531,
              0.506819279199872: 0.309537798931719,
              0.518247701234826: 0.323953688229747,
              0.5296761232697801: 0.338476927875249,
              0.5411045453047341: 0.35307096790680603,
              0.552532967339688: 0.36769867152074404,
              0.563961389374642: 0.38232262079238705,
              0.575389811409596: 0.396905433216761,
              0.5868182334445501: 0.411410085758514,
              0.5982466554795041: 0.42580024295303404,
              0.609675077514458: 0.44004058549770103,
              0.621103499549412: 0.45409713571437604,
              0.632531921584366: 0.46793757625108706,
              0.64396034361932: 0.48153155842110706,
              0.6553887656542731: 0.49485099664916204,
              0.666817187689227: 0.507870345604689,
              0.678245609724181: 0.520566856747693,
              0.6896740317591351: 0.532920811190398,
              0.7011024537940891: 0.5449157259838281,
              0.7125308758290431: 0.556538531169112,
              0.723959297863997: 0.567779715185025,
              0.735387719898951: 0.5786334364929351,
              0.7468161419339051: 0.589097599564903,
              0.7582445639688591: 0.5991738936777891,
              0.7696729860038131: 0.608867793263963,
              0.781101408038767: 0.6181885188860721,
              0.792529830073721: 0.6271489582285871,
              0.8039582521086741: 0.6357655468317981,
              0.8153866741436281: 0.644058108634832,
              0.8268150961785821: 0.6520496567429781,
              0.838243518213536: 0.659766155191656,
              0.84967194024849: 0.6672362428449391,
              0.8611003622834441: 0.674490920940623,
              0.8725287843183981: 0.681563206176313,
              0.8839572063533521: 0.688487751620754,
              0.895385628388306: 0.695300438130387,
              0.90681405042326: 0.7020379393503481,
              0.9182424724582141: 0.7087372637786541,
              0.9296708944931681: 0.715435277767961,
              0.9410993165281221: 0.722168213725735,
              0.952527738563075: 0.728971168144898,
              0.963956160598029: 0.7358775944459091,
              0.9753845826329831: 0.7429187959300101,
              0.9868130046679371: 0.7501234244236681,
              0.9982414267028911: 0.75751699042727,
              1.009669848737845: 0.765121390757982,
              1.021098270772799: 0.772954459788553,
              1.032526692807753: 0.7810295504224031,
              1.043955114842707: 0.7893551509028891,
              1.055383536877661: 0.79793454342457,
              1.066811958912615: 0.8067655102912541,
              1.078240380947569: 0.815840093045869,
              1.089668802982523: 0.8251444095787811,
              1.101097225017477: 0.834658533704098,
              1.112525647052431: 0.8443564410801061,
              1.123954069087384: 0.854206024644488,
              1.135382491122338: 0.8641691819443561,
              1.146810913157292: 0.8742019758742071,
              1.158239335192246: 0.88425486940283,
              1.1696677572272: 0.894273033886216,
              1.181096179262154: 0.9041967295423651,
              1.192524601297108: 0.9139617556221911,
              1.2039530233320621: 0.9234999667659751,
              1.215381445367016: 0.932739851005529,
              1.22680986740197: 0.9416071638773271,
              1.238238289436924: 0.9500256121702471,
              1.249666711471878: 0.9579175799617331,
              1.261095133506832: 0.9652048888159571,
              1.272523555541786: 0.9718095833432121,
              1.283951977576739: 0.9776547327663591,
              1.295380399611693: 0.9826652387202771,
              1.306808821646647: 0.9867686392346241,
              1.318237243681601: 0.9898958987266421,
              1.329665665716555: 0.991982173864379,
              1.341094087751509: 0.9929675453537201,
              1.352522509786463: 0.9927977060541421,
              1.363950931821417: 0.9914245963342061,
              1.375379353856371: 0.9888069782315051,
              1.386807775891325: 0.9849109407732641,
              1.398236197926279: 0.9797103297304771,
              1.409664619961233: 0.9731870961053141,
              1.421093041996187: 0.9653315587714071,
              1.4325214640311401: 0.9561425778803541,
              1.443949886066094: 0.9456276368950831,
              1.455378308101048: 0.9338028323899451,
              1.466806730136002: 0.9206927720465221,
              1.478235152170956: 0.90633038255106,
              1.4896635742059101: 0.89075663034201,
              1.5010919962408642: 0.8740201593436171,
              1.512520418275818: 0.85617685093352,
              1.523948840310772: 0.8372893124109221,
              1.535377262345726: 0.8174263011403511,
              1.5468056843806801: 0.7966620923303831,
              1.5582341064156342: 0.775075799055231,
              1.569662528450587: 0.752750653630876,
              1.581090950485542: 0.7297732598103751,
              1.5925193725204951: 0.706232825462019,
              1.603947794555449: 0.6822203854388711,
              1.615376216590403: 0.657828024241718,
              1.626804638625357: 0.633148107824972,
              1.638233060660311: 0.608272533504821,
              1.6496614826952651: 0.5832920064112831,
              1.661089904730219: 0.5582953502935231,
              1.672518326765173: 0.5333688597550741,
              1.683946748800127: 0.508595700178467,
              1.695375170835081: 0.48405536071404204,
              1.7068035928700351: 0.459823164773041,
              1.718232014904989: 0.43596984149844903,
              1.729660436939942: 0.41256116070630905,
              1.741088858974896: 0.389657632812819,
              1.7525172810098502: 0.36731427430517705,
              1.763945703044804: 0.345580438392366,
              1.7753741250797581: 0.32449970960017505,
              1.786802547114712: 0.30410986026532,
              1.798230969149666: 0.284442866147395,
              1.8096593911846202: 0.265524977723445,
              1.821087813219574: 0.247376843165162,
              1.8325162352545281: 0.23001367852777602,
              1.843944657289482: 0.213445480305494,
              1.855373079324436: 0.19767727523157602,
              1.8668015013593902: 0.18270940202069802,
              1.878229923394344: 0.168537819664193,
              1.8896583454292981: 0.155154436890654,
              1.901086767464251: 0.142547457489223,
              1.9125151894992052: 0.130701736353625,
              1.923943611534159: 0.11959914133355601,
              1.935372033569113: 0.109218916267504,
              1.946800455604067: 0.099538040908326,
              1.958228877639021: 0.090531583830147,
              1.9696572996739752: 0.082173044812946,
              1.981085721708929: 0.07443468362996801,
              1.992514143743883: 0.067287832603812,
              2.003942565778837: 0.060703190741031005,
              2.015370987813791: 0.05465109769446801,
              2.026799409848745: 0.04910178623005,
              2.038227831883699: 0.044025612284172004,
              2.049656253918653: 0.039393262083534004,
              2.061084675953606: 0.035175936157052,
              2.07251309798856: 0.031345510395531004,
              2.083941520023514: 0.027874674606758004,
              2.095369942058468: 0.024737049269690004,
              2.106798364093422: 0.021907281410781,
              2.118226786128376: 0.019361120708105,
              2.12965520816333: 0.017075477075427,
              2.141083630198284: 0.015028461090101001,
              2.152512052233238: 0.013199408707424,
              2.163940474268192: 0.011568891752025,
              2.175368896303146: 0.010118715696775001,
              2.186797318338099: 0.008831906234207001,
              2.198225740373054: 0.007692686117715001,
              2.209654162408008: 0.006686443702805,
              2.221082584442961: 0.005799694555585,
              2.232511006477915: 0.00502003741946,
            },
            gamma: {
              0.0038075490348770005: 6.0135840816284164e-05,
              0.017153625491116: 0.00010123063870800001,
              0.030499701947356003: 0.00016536842222300002,
              0.043845778403596006: 0.00026223531470000004,
              0.057191854859836: 0.00040382683230300004,
              0.07053793131607601: 0.000604184902876,
              0.083884007772315: 0.0008787507299200001,
              0.097230084228555: 0.001243328952395,
              0.110576160684795: 0.0017127548353830001,
              0.12392223714103501: 0.002299458973983,
              0.137268313597274: 0.003012198930709,
              0.23069084879095303: 0.011399295587478,
              0.24403692524719203: 0.012986231973247001,
              0.25738300170343203: 0.014638281038266001,
              0.270729078159672: 0.016342223356968,
              0.28407515461591204: 0.018083541695775,
              0.297421231072152: 0.019847903347787,
              0.31076730752839105: 0.021624292406864,
              0.324113383984631: 0.023409721449542002,
              0.33745946044087105: 0.025215003209693002,
              0.350805536897111: 0.027070694980368,
              0.36415161335335: 0.029032125454668004,
              0.37749768980959003: 0.031182409121705,
              0.39084376626583: 0.033632539738149,
              0.40418984272207004: 0.036518007133949,
              0.417535919178309: 0.039991877204005,
              0.43088199563454904: 0.044214887949393,
              0.444228072090789: 0.049343791573832,
              0.45757414854702905: 0.055519801163936004,
              0.470920225003269: 0.062859398363696,
              0.48426630145950805: 0.071449708838902,
              0.497612377915748: 0.08134998413097501,
              0.5109584543719881: 0.09259941780005,
              0.524304530828228: 0.105229770370135,
              0.537650607284467: 0.119279510218252,
              0.5509966837407071: 0.134804963202762,
              0.5643427601969471: 0.151883835715587,
              0.577688836653187: 0.17060773283250802,
              0.591034913109426: 0.191062845219054,
              0.604380989565666: 0.21330130437090103,
              0.6177270660219061: 0.23730895507343402,
              0.631073142478146: 0.262977511330556,
              0.644419218934385: 0.290089469313532,
              0.657765295390625: 0.31832239761887204,
              0.6711113718468651: 0.34727552705500003,
              0.6844574483031051: 0.376516682940496,
              0.697803524759345: 0.40564268072970905,
              0.711149601215584: 0.43434257037039903,
              0.7244956776718241: 0.46245156675007704,
              0.7378417541280641: 0.489984637691085,
              0.751187830584304: 0.517142366679411,
              0.764533907040543: 0.5442870386496821,
              0.7778799834967831: 0.571892627001886,
              0.7912260599530231: 0.600477089316921,
              0.8045721364092631: 0.6305280083112901,
              0.817918212865502: 0.6624326887725811,
              0.831264289321742: 0.696421674852386,
              0.8446103657779821: 0.7325312705011681,
              0.8579564422342221: 0.770587270272025,
              0.871302518690461: 0.8102097254960401,
              0.884648595146701: 0.8508375058173341,
              0.8979946716029411: 0.8917712154331501,
              0.9113407480591811: 0.9322327175500691,
              0.9246868245154211: 0.9714381612367771,
              0.93803290097166: 1.008678640313408,
              0.9513789774279001: 1.043399019479119,
              0.9647250538841401: 1.075262437349604,
              0.9780711303403801: 1.104187228330247,
              0.991417206796619: 1.130345720580659,
              1.004763283252859: 1.154120682562261,
              1.018109359709099: 1.176023878719228,
              1.031455436165339: 1.196589990834955,
              1.044801512621578: 1.216265508616799,
              1.058147589077818: 1.235314133464483,
              1.071493665534058: 1.2537570955293091,
              1.084839741990298: 1.2713593675287171,
              1.098185818446537: 1.287663051265482,
              1.111531894902777: 1.302059680820236,
              1.124877971359017: 1.3138860174369351,
              1.138224047815257: 1.322524454451088,
              1.151570124271497: 1.327489716355713,
              1.164916200727736: 1.328487536238458,
              1.178262277183976: 1.325437281802569,
              1.191608353640216: 1.3184577191523261,
              1.204954430096456: 1.30782195377571,
              1.218300506552695: 1.293892953966347,
              1.231646583008935: 1.277054084857355,
              1.244992659465175: 1.2576492395882881,
              1.258338735921415: 1.235944376648373,
              1.271684812377655: 1.21211704446253,
              1.285030888833894: 1.186273851205379,
              1.298376965290134: 1.158489292958627,
              1.311723041746374: 1.128854451858925,
              1.325069118202614: 1.097522052180924,
              1.338415194658853: 1.06473574006384,
              1.351761271115093: 1.030835852657493,
              1.3651073475713331: 0.9962401644186181,
              1.378453424027573: 0.9614044626951721,
              1.391799500483812: 0.926772645064811,
              1.405145576940052: 0.892728169616057,
              1.4184916533962921: 0.85955774325485,
              1.431837729852532: 0.827434561314174,
              1.445183806308771: 0.7964233150848811,
              1.458529882765011: 0.766503945804473,
              1.4718759592212511: 0.7376070210092751,
              1.485222035677491: 0.709651508757119,
              1.49856811213373: 0.682575932043565,
              1.51191418858997: 0.6563561499243681,
              1.5252602650462102: 0.6310066566485071,
              1.53860634150245: 0.606566404723337,
              1.551952417958689: 0.583073804763249,
              1.565298494414929: 0.560537956621741,
              1.5786445708711692: 0.5389138494723681,
              1.591990647327409: 0.518088150446538,
              1.605336723783649: 0.49787960205240206,
              1.618682800239888: 0.47805461782487,
              1.6320288766961282: 0.45835523032166003,
              1.645374953152368: 0.438533904487766,
              1.658721029608608: 0.418388449613587,
              1.672067106064847: 0.39779054012632203,
              1.6854131825210872: 0.37670298534287006,
              1.698759258977327: 0.355183361335675,
              1.712105335433567: 0.33337429655195405,
              1.7254514118898072: 0.31148301246356,
              1.7387974883460462: 0.289754282696558,
              1.752143564802286: 0.26844164032043605,
              1.765489641258526: 0.24778146393398903,
              1.778835717714766: 0.22797363589473502,
              1.7921817941710052: 0.209170959601812,
              1.805527870627245: 0.19147766091169302,
              1.818873947083485: 0.174955377405997,
              1.832220023539725: 0.15963345119729402,
              1.8455660999959642: 0.145519515991962,
              1.858912176452204: 0.132606632521404,
              1.872258252908444: 0.12087463098340201,
              1.885604329364684: 0.110285541550018,
              1.8989504058209241: 0.10077537352027201,
              1.912296482277163: 0.092246237959154,
              1.925642558733403: 0.084563243068905,
              1.938988635189643: 0.077559483710723,
              1.9523347116458831: 0.071050088252864,
              1.965680788102122: 0.064853422263124,
              1.979026864558362: 0.058815119608843,
              1.992372941014602: 0.052829418329180006,
              2.005719017470842: 0.046852703856537004,
              2.019065093927081: 0.040906050475952006,
              2.032411170383321: 0.035066287451267004,
              2.045757246839561: 0.029447855982330004,
              2.059103323295801: 0.024179689521310003,
              2.07244939975204: 0.019382072872584002,
              2.08579547620828: 0.015147864269661,
              2.09914155266452: 0.011530927552074001,
              2.11248762912076: 0.008542668542574001,
              2.125833705577: 0.006155769937963001,
              2.139179782033239: 0.004312989457569001,
              2.152525858489479: 0.0029384016605190004,
              2.165871934945719: 0.00194866203626,
              2.179218011401959: 0.001262527640318,
              2.192564087858198: 0.000807699169225,
              2.205910164314438: 0.000524808414902,
              2.219256240770678: 0.00036890824957200004,
              2.232602317226918: 0.00030908090774,
              2.245948393683158: 0.00032680117892700005,
              2.259294470139397: 0.00041356050710300004,
              2.272640546595637: 0.000568074942616,
              2.285986623051877: 0.0007932481542960001,
              2.299332699508117: 0.0010929921952730001,
              2.312678775964356: 0.001469037079406,
              2.326024852420596: 0.0019179623076280002,
              2.339370928876836: 0.002428807427569,
              2.352717005333076: 0.0029816984371770004,
              2.3660630817893162: 0.0035479018431240003,
              2.379409158245555: 0.004091555044782,
              2.392755234701795: 0.004573030973972001,
              2.406101311158035: 0.0049535358484100005,
              2.419447387614275: 0.005200207319729,
              2.4327934640705142: 0.005290780549517001,
              2.446139540526754: 0.005216898960013001,
              2.459485616982994: 0.004985384223937001,
              2.4728316934392343: 0.004617196498785001,
              2.4861777698954732: 0.0041443038229950005,
              2.499523846351713: 0.0036051083269970003,
              2.512869922807953: 0.00303933488224,
              2.526215999264193: 0.002483317748694,
              2.539562075720432: 0.001966434717304,
              2.552908152176672: 0.0015091078424890002,
              2.566254228632912: 0.0011224177381610001,
              2.5796003050891523: 0.00080906286736,
              2.5929463815453913: 0.000565201778277,
              2.606292458001631: 0.00038266458629700005,
              2.619638534457871: 0.00025108832955800004,
              2.632984610914111: 0.000159671823459,
              2.64633068737035: 9.840644546782398e-05,
              2.65967676382659: 5.877766203289085e-05,
            },
            geo_level_type: 'Belgium',
            halfnorm: {
              0.44266030109435706: 0.002170169314611,
              0.456071951842256: 0.0036187070406000004,
              0.46948360259015404: 0.005891145653163001,
              0.48289525333805206: 0.009364659807435,
              0.496306904085951: 0.014537744526065,
              0.5097185548338491: 0.022043994442733,
              0.523130205581748: 0.03265555296072,
              0.536541856329646: 0.047270883663946,
              0.5499535070775441: 0.066882036073013,
              0.563365157825443: 0.09251835279893901,
              0.576776808573341: 0.125166675096634,
              0.5901884593212391: 0.16567229646616602,
              0.603600110069138: 0.21462955632656902,
              0.617011760817036: 0.272275103503203,
              0.6304234115649351: 0.33839939998602003,
              0.643835062312833: 0.412292030655156,
              0.657246713060731: 0.49273333868024505,
              0.6706583638086301: 0.5780390110036361,
              0.684070014556528: 0.666156436731138,
              0.697481665304427: 0.7548034850873231,
              0.7108933160523251: 0.8416335506653071,
              0.724304966800223: 0.924406788062575,
              0.737716617548122: 1.001147213862345,
              0.7511282682960201: 1.070268673937855,
              0.764539919043918: 1.130658562779189,
              0.777951569791817: 1.181715089358316,
              0.7913632205397151: 1.223340196983446,
              0.804774871287614: 1.255894767182218,
              0.818186522035512: 1.280125000100318,
              0.8315981727834101: 1.297069154394535,
              0.845009823531309: 1.307952959425585,
              0.8584214742792071: 1.314080921870202,
              0.8718331250271061: 1.316730114395027,
              0.885244775775004: 1.317053027600537,
              0.8986564265229021: 1.315996343932032,
              0.9120680772708011: 1.314242397548901,
              0.925479728018699: 1.312178971531453,
              0.9388913787665971: 1.309900625001523,
              0.9523030295144961: 1.307241101385983,
              0.965714680262394: 1.303832184301445,
              0.9791263310102931: 1.299180562425489,
              0.9925379817581911: 1.292751756071677,
              1.00594963250609: 1.284049571996851,
              1.019361283253988: 1.272681038117848,
              1.032772934001886: 1.258399962342565,
              1.046184584749784: 1.241126397608542,
              1.0595962354976831: 1.220943438093845,
              1.073007886245581: 1.198076053928245,
              1.08641953699348: 1.172858516558427,
              1.099831187741378: 1.145697204975542,
              1.113242838489276: 1.117034454135455,
              1.126654489237175: 1.087317152775801,
              1.140066139985073: 1.056971683550104,
              1.153477790732972: 1.026385116416692,
              1.16688944148087: 0.9958916781879121,
              1.180301092228768: 0.965763480180098,
              1.193712742976667: 0.93620505738623,
              1.207124393724565: 0.9073520416849941,
              1.220536044472464: 0.879274819292824,
              1.233947695220362: 0.851987997861216,
              1.24735934596826: 0.825465846982796,
              1.2607709967161589: 0.799662736178526,
              1.274182647464057: 0.774536312901537,
              1.287594298211956: 0.7500701342586771,
              1.301005948959854: 0.726292015261595,
              1.314417599707752: 0.7032846465168281,
              1.327829250455651: 0.681186034033425,
              1.341240901203549: 0.660178827201481,
              1.354652551951447: 0.640469337682937,
              1.3680642026993461: 0.6222587076357771,
              1.381475853447244: 0.605710004330099,
              1.394887504195142: 0.5909158229291961,
              1.408299154943041: 0.5778711746324721,
              1.421710805690939: 0.566455997653175,
              1.435122456438838: 0.5564305866993641,
              1.448534107186736: 0.547445684170978,
              1.461945757934634: 0.539067070046594,
              1.475357408682533: 0.530812453421817,
              1.488769059430431: 0.522196589603503,
              1.50218071017833: 0.512779126470933,
              1.515592360926228: 0.502208992349072,
              1.5290040116741261: 0.49025934910945906,
              1.5424156624220249: 0.47684827752789805,
              1.5558273131699232: 0.462042299310702,
              1.569238963917822: 0.44604228240966304,
              1.58265061466572: 0.42915383203398505,
              1.596062265413618: 0.41174651792328903,
              1.609473916161517: 0.39420785564974703,
              1.622885566909415: 0.376898587636156,
              1.636297217657313: 0.36011539897536804,
              1.6497088684052121: 0.344065829087475,
              1.66312051915311: 0.32885803841287203,
              1.6765321699010092: 0.314505617313452,
              1.689943820648907: 0.30094520420402704,
              1.703355471396805: 0.28806272901204605,
              1.716767122144704: 0.275722956577032,
              1.730178772892602: 0.263796870456938,
              1.7435904236405002: 0.252182321073945,
              1.757002074388399: 0.240815073074112,
              1.770413725136297: 0.22966956336763303,
              1.783825375884196: 0.21875086049984102,
              1.797237026632094: 0.20808103410664402,
              1.8106486773799921: 0.19768404386122002,
              1.824060328127891: 0.18757317789072603,
              1.8374719788757892: 0.177744081515036,
              1.850883629623688: 0.168174800489405,
              1.8642952803715862: 0.158832435613421,
              1.877706931119484: 0.14968440857259602,
              1.891118581867383: 0.140711325487621,
              1.904530232615281: 0.13191817941682402,
              1.917941883363179: 0.12334114215555302,
              1.9313535341110781: 0.11504827191024601,
              1.944765184858976: 0.107133812555498,
              1.9581768356068752: 0.099707064670632,
              1.971588486354773: 0.09287780363551501,
              1.985000137102671: 0.08674074390478001,
              1.99841178785057: 0.08136155930764001,
              2.011823438598468: 0.076766532321535,
              2.025235089346367: 0.072937161369944,
              2.038646740094265: 0.06981017998502101,
              2.052058390842163: 0.067282607501745,
              2.065470041590062: 0.065220796074148,
              2.07888169233796: 0.06347204725746601,
              2.092293343085859: 0.061877264697257,
              2.105704993833757: 0.060283250531766,
              2.119116644581655: 0.058553561602139,
              2.132528295329554: 0.05657721769227501,
              2.145939946077452: 0.05427490549501401,
              2.159351596825351: 0.051602586942563006,
              2.172763247573249: 0.048552579700344003,
              2.186174898321147: 0.045152252312451006,
              2.199586549069046: 0.041460513378714,
              2.212998199816944: 0.037562322885034,
              2.226409850564843: 0.033561545847451,
              2.23982150131274: 0.029572604732888003,
              2.253233152060639: 0.025711538701157003,
              2.266644802808537: 0.022087197787213003,
              2.280056453556436: 0.018793342404179,
              2.293468104304335: 0.01590235430458,
              2.306879755052232: 0.013461093221282002,
              2.320291405800131: 0.011489180190699002,
              2.3337030565480292: 0.009979699339084,
              2.347114707295928: 0.008902035799694001,
              2.360526358043826: 0.008206352595394,
              2.373938008791724: 0.007829082286724,
              2.387349659539623: 0.007698779539427001,
              2.400761310287521: 0.007741740589575,
              2.41417296103542: 0.007886923703908,
              2.427584611783318: 0.008069872002356001,
              2.440996262531216: 0.008235514620830001,
              2.4544079132791152: 0.008339874757150001,
              2.467819564027013: 0.008350821486562,
              2.481231214774912: 0.00824805541326,
              2.49464286552281: 0.008022518706583,
              2.508054516270708: 0.007675382865931,
              2.521466167018607: 0.007216715803996,
              2.534877817766505: 0.006663887628350001,
              2.548289468514403: 0.006039759114343001,
              2.561701119262302: 0.00537071311951,
              2.5751127700102: 0.0046846281443640005,
              2.588524420758099: 0.004008935802902,
              2.601936071505997: 0.003368928144835,
              2.615347722253895: 0.0027864698876330003,
              2.628759373001794: 0.002279219438588,
              2.6421710237496923: 0.0018603793858070002,
              2.655582674497591: 0.0015389012073600001,
              2.668994325245489: 0.0013199847799180001,
              2.682405975993387: 0.00120566348851,
              2.695817626741286: 0.001195264984907,
              2.709229277489184: 0.001285588851643,
              2.722640928237083: 0.001470736085616,
              2.73605257898498: 0.001741641408332,
              2.749464229732879: 0.002085471466599,
              2.7628758804807783: 0.0024851324436960002,
              2.776287531228676: 0.0029191567400810004,
              2.789699181976575: 0.0033621979377950004,
              2.803110832724472: 0.0037862585244850004,
              2.816522483472371: 0.004162623996091,
              2.82993413422027: 0.004464311568885,
              2.843345784968168: 0.0046687003733860005,
              2.856757435716066: 0.004759928893608,
              2.870169086463964: 0.004730648570907,
              2.883580737211863: 0.004582814299384,
              2.896992387959762: 0.004327355374103,
              2.91040403870766: 0.003982768524421,
              2.923815689455558: 0.003572863259954,
              2.937227340203456: 0.00312402716848,
              2.9506389909513553: 0.0026624372621640003,
              2.964050641699254: 0.002211615726959,
              2.977462292447152: 0.001790628201923,
              2.99087394319505: 0.001413079218453,
              3.004285593942948: 0.001086908217848,
              3.017697244690847: 0.000814862768936,
              3.031108895438746: 0.000595444517698,
              3.044520546186644: 0.000424095183742,
              3.057932196934542: 0.000294408665567,
              3.07134384768244: 0.00019920628839700002,
              3.084755498430339: 0.00013137741251000002,
              3.098167149178238: 8.445076134848489e-05,
              3.111578799926136: 5.2911690674297304e-05,
            },
            triangular: {
              0.17630994285368: 0.000260242269059,
              0.18403184821736202: 0.00036824635481400004,
              0.19175375358104402: 0.0005157156800260001,
              0.19947565894472602: 0.000714869534609,
              0.20719756430840802: 0.0009808956356430001,
              0.21491946967209002: 0.0013324013959650001,
              0.22264137503577203: 0.001791851789243,
              0.23036328039945403: 0.0023859650877820003,
              0.23808518576313603: 0.0031460323284570003,
              0.245807091126818: 0.004108122125644,
              0.25352899649050004: 0.0053131300833100005,
              0.26125090185418204: 0.006806632230146,
              0.26897280721786404: 0.008638505186315001,
              0.27669471258154604: 0.010862282566173,
              0.28441661794522805: 0.013534227571711,
              0.29213852330891: 0.016712115663647,
              0.29986042867259105: 0.020453738085104003,
              0.307582334036273: 0.024815155976723003,
              0.315304239399955: 0.029848754666115,
              0.323026144763637: 0.035601167005640005,
              0.330748050127319: 0.042111151813533004,
              0.338469955491001: 0.049407527001104005,
              0.346191860854683: 0.057507265462360006,
              0.353913766218365: 0.06641386418488701,
              0.361635671582047: 0.07611609265829501,
              0.369357576945729: 0.086587215358148,
              0.377079482309411: 0.09778476525292501,
              0.384801387673093: 0.10965092181625401,
              0.392523293036775: 0.122113519261204,
              0.400245198400457: 0.135087680302787,
              0.407967103764139: 0.14847803952476002,
              0.41568900912782103: 0.162181490212729,
              0.42341091449150303: 0.17609036100914602,
              0.43113281985518503: 0.19009590536744,
              0.43885472521886704: 0.20409196859745402,
              0.44657663058254904: 0.21797868497927203,
              0.45429853594623104: 0.231666051288363,
              0.46202044130991304: 0.24507722313450603,
              0.46974234667359505: 0.258151386585526,
              0.47746425203727705: 0.27084606934987704,
              0.48518615740095905: 0.283138773055823,
              0.49290806276464105: 0.29502783066383104,
              0.500629968128323: 0.306532420591882,
              0.5083518734920051: 0.317691701482725,
              0.5160737788556871: 0.32856306827181403,
              0.5237956842193691: 0.339219570535617,
              0.5315175895830511: 0.34974657668947,
              0.5392394949467331: 0.360237810486204,
              0.5469614003104151: 0.370790926792032,
              0.5546833056740971: 0.381502828573394,
              0.5624052110377791: 0.39246495290591105,
              0.5701271164014611: 0.403758767196689,
              0.5778490217651431: 0.415451714858364,
              0.5855709271288251: 0.42759383064322404,
              0.5932928324925071: 0.440215209531972,
              0.6010147378561891: 0.453324461100284,
              0.6087366432198701: 0.46690821718568704,
              0.6164585485835521: 0.48093168967945604,
              0.6241804539472341: 0.495340203838017,
              0.6319023593109161: 0.510061567644009,
              0.6396242646745981: 0.5250090861359661,
              0.6473461700382801: 0.5400849967680831,
              0.6550680754019621: 0.5551840912921611,
              0.6627899807656441: 0.5701973023768271,
              0.6705118861293261: 0.5850150674127721,
              0.678233791493008: 0.5995303332356541,
              0.68595569685669: 0.613641127158404,
              0.693677602220372: 0.6272526836450371,
              0.701399507584054: 0.6402791736661251,
              0.709121412947736: 0.6526451274328221,
              0.716843318311418: 0.664286664752197,
              0.7245652236751: 0.6751526472657421,
              0.732287129038782: 0.685205843127706,
              0.740009034402464: 0.694424150447694,
              0.747730939766146: 0.702801867383759,
              0.755452845129828: 0.7103509328615291,
              0.76317475049351: 0.717102002569813,
              0.770896655857192: 0.7231051801807801,
              0.778618561220874: 0.7284302022422701,
              0.786340466584556: 0.733165882677651,
              0.794062371948238: 0.737418661267459,
              0.80178427731192: 0.7413101674649031,
              0.809506182675602: 0.744973799678574,
              0.817228088039284: 0.7485504203797151,
              0.824949993402966: 0.7521833663624671,
              0.832671898766648: 0.756013057902528,
              0.84039380413033: 0.7601715484486701,
              0.848115709494012: 0.764777379038719,
              0.855837614857694: 0.7699310846802121,
              0.863559520221376: 0.775711644738279,
              0.8712814255850581: 0.782174082632472,
              0.8790033309487401: 0.789348313153004,
              0.8867252363124221: 0.797239222743454,
              0.8944471416761041: 0.805827864297997,
              0.9021690470397861: 0.815073567060827,
              0.9098909524034681: 0.8249167141641751,
              0.9176128577671491: 0.835281930062683,
              0.9253347631308311: 0.8460814464812261,
              0.9330566684945131: 0.8572184715278061,
              0.9407785738581951: 0.868590460660663,
              0.9485004792218771: 0.8800922657551871,
              0.9562223845855591: 0.8916192046704251,
              0.9639442899492411: 0.9030701355757841,
              0.9716661953129231: 0.914350629163262,
              0.9793881006766051: 0.9253763047216681,
              0.9871100060402871: 0.9360763361465291,
              0.9948319114039691: 0.9463970504293631,
              1.002553816767651: 0.9563054476600861,
              1.010275722131333: 0.9657923841865591,
              1.017997627495015: 0.9748750953941091,
              1.025719532858697: 0.9835987051963541,
              1.033441438222379: 0.992036384769386,
              1.041163343586061: 1.000287886288694,
              1.048885248949743: 1.008476284840792,
              1.056607154313425: 1.016742903503242,
              1.0643290596771071: 1.025240558136191,
              1.072050965040789: 1.034125422053418,
              1.079772870404471: 1.04354795812151,
              1.087494775768153: 1.053643480323194,
              1.0952166811318351: 1.06452297547966,
              1.102938586495517: 1.076264830916806,
              1.110660491859199: 1.088908073579119,
              1.118382397222881: 1.102447634522809,
              1.1261043025865631: 1.116832019026155,
              1.133826207950245: 1.131963599626334,
              1.141548113313927: 1.147701572116232,
              1.149270018677609: 1.16386743800789,
              1.156991924041291: 1.180252714879716,
              1.164713829404973: 1.196628439418791,
              1.172435734768655: 1.212755924523219,
              1.180157640132336: 1.228398165652581,
              1.187879545496019: 1.243331263529266,
              1.1956014508597: 1.2573552384487021,
              1.203323356223382: 1.27030365210929,
              1.211045261587064: 1.282051521159438,
              1.218767166950746: 1.292521097221633,
              1.226489072314428: 1.301685195540335,
              1.23421097767811: 1.309567873241041,
              1.241932883041792: 1.316242383197289,
              1.249654788405474: 1.3218264554226131,
              1.257376693769156: 1.32647507949169,
              1.265098599132838: 1.3303710736396321,
              1.27282050449652: 1.333713824201507,
              1.280542409860202: 1.33670665908268,
              1.288264315223884: 1.339543378427528,
              1.295986220587566: 1.342394503646213,
              1.303708125951248: 1.345393823275653,
              1.31143003131493: 1.348625813114148,
              1.319151936678612: 1.352114491925137,
              1.326873842042294: 1.3558142460414762,
              1.334595747405976: 1.3596031187361262,
              1.342317652769658: 1.363279013697267,
              1.35003955813334: 1.366559204354874,
              1.357761463497022: 1.369083467712888,
              1.365483368860704: 1.370421066558053,
              1.373205274224386: 1.370081680865228,
              1.380927179588068: 1.367530232800412,
              1.38864908495175: 1.36220535838681,
              1.396370990315432: 1.353541056517799,
              1.404092895679114: 1.340990803115478,
              1.411814801042796: 1.324053172310027,
              1.419536706406478: 1.30229778097021,
              1.42725861177016: 1.275390194886441,
              1.4349805171338421: 1.243114331961406,
              1.442702422497524: 1.205390893956808,
              1.4504243278612061: 1.162290470221878,
              1.458146233224888: 1.11404018999662,
              1.46586813858857: 1.061023146789844,
              1.473590043952251: 1.003770257715107,
              1.4813119493159341: 0.942944718894503,
              1.4890338546796151: 0.8793197322831541,
              1.496755760043297: 0.8137506621531191,
              1.504477665406979: 0.747143184592151,
              1.512199570770661: 0.6804192808754941,
              1.5199214761343431: 0.6144830670772571,
              1.527643381498025: 0.550188434376175,
              1.535365286861707: 0.48831030028857203,
              1.543087192225389: 0.42952095964782705,
              1.5508090975890712: 0.37437260813459705,
              1.558531002952753: 0.323286632473177,
              1.566252908316435: 0.276549766187628,
              1.573974813680117: 0.23431674304149502,
              1.5816967190437992: 0.19661868089994602,
              1.589418624407481: 0.163376125830333,
              1.597140529771163: 0.13441549671278802,
              1.604862435134845: 0.10948759858463701,
              1.6125843404985272: 0.088286910653308,
              1.620306245862209: 0.07047048502142801,
              1.628028151225891: 0.055675490772365006,
              1.635750056589573: 0.043534678153033,
              1.6434719619532552: 0.033689292342841,
              1.651193867316937: 0.025799211860433,
              1.658915772680619: 0.019550304135226,
              1.666637678044301: 0.014659167299099001,
              1.6743595834079832: 0.010875556219700001,
              1.682081488771665: 0.007982871293599,
              1.689803394135347: 0.005797124278180001,
              1.697525299499029: 0.004164793476115001,
              1.7052472048627112: 0.002959949752046,
              1.712969110226393: 0.0020809845481180003,
            },
          },
          {
            actual: {
              '-0.041744978477915': 0.0037079623029640002,
              '-0.030316556442961003': 0.004266275879592001,
              '-0.018888134408007': 0.0048982314366020005,
              '-0.007459712373053': 0.005611870630944001,
              0.0039687096619: 0.006415866044628,
              0.015397131696854001: 0.007319536452261001,
              0.026825553731807002: 0.008332857434196001,
              0.038253975766761004: 0.009466466598019,
              0.049682397801715: 0.010731662658870001,
              0.061110819836669: 0.012140397628609,
              0.072539241871623: 0.013705261376615001,
              0.083967663906577: 0.01543945785241,
              0.09539608594153101: 0.017356772303633,
              0.106824507976485: 0.019471528883009,
              0.11825293001143901: 0.021798538115796,
              0.129681352046393: 0.024353033794985,
              0.141109774081347: 0.027150598985543003,
              0.152538196116301: 0.030207080950708003,
              0.16396661815125502: 0.033538494962136005,
              0.17539504018620902: 0.037160917120311006,
              0.186823462221162: 0.041090366490255006,
              0.198251884256116: 0.045342677048213,
              0.20968030629107: 0.049933360134765,
              0.22110872832602402: 0.05487745831578,
              0.23253715036097802: 0.060189391761207,
              0.24396557239593203: 0.065882798458884,
              0.255393994430886: 0.07197036978240201,
              0.26682241646584004: 0.078463683123887,
              0.278250838500794: 0.085373033480098,
              0.289679260535748: 0.09270726603875201,
              0.30110768257070203: 0.100473611947144,
              0.312536104605656: 0.10867752955264101,
              0.32396452664061004: 0.11732255348060001,
              0.335392948675563: 0.12641015395622102,
              0.34682137071051705: 0.13593960877994402,
              0.35824979274547103: 0.145907890328931,
              0.369678214780425: 0.15630956987854702,
              0.38110663681537904: 0.16713674141686002,
              0.392535058850333: 0.17837896696230102,
              0.40396348088528705: 0.19002324519095,
              0.41539190292024103: 0.20205400493743603,
              0.426820324955195: 0.21445312485530202,
              0.43824874699014904: 0.227199980212643,
              0.449677169025103: 0.24027151746157202,
              0.46110559106005705: 0.25364235686096304,
              0.47253401309501103: 0.26728492305693,
              0.48396243512996406: 0.281169603140878,
              0.49539085716491804: 0.295264931317531,
              0.506819279199872: 0.309537798931719,
              0.518247701234826: 0.323953688229747,
              0.5296761232697801: 0.338476927875249,
              0.5411045453047341: 0.35307096790680603,
              0.552532967339688: 0.36769867152074404,
              0.563961389374642: 0.38232262079238705,
              0.575389811409596: 0.396905433216761,
              0.5868182334445501: 0.411410085758514,
              0.5982466554795041: 0.42580024295303404,
              0.609675077514458: 0.44004058549770103,
              0.621103499549412: 0.45409713571437604,
              0.632531921584366: 0.46793757625108706,
              0.64396034361932: 0.48153155842110706,
              0.6553887656542731: 0.49485099664916204,
              0.666817187689227: 0.507870345604689,
              0.678245609724181: 0.520566856747693,
              0.6896740317591351: 0.532920811190398,
              0.7011024537940891: 0.5449157259838281,
              0.7125308758290431: 0.556538531169112,
              0.723959297863997: 0.567779715185025,
              0.735387719898951: 0.5786334364929351,
              0.7468161419339051: 0.589097599564903,
              0.7582445639688591: 0.5991738936777891,
              0.7696729860038131: 0.608867793263963,
              0.781101408038767: 0.6181885188860721,
              0.792529830073721: 0.6271489582285871,
              0.8039582521086741: 0.6357655468317981,
              0.8153866741436281: 0.644058108634832,
              0.8268150961785821: 0.6520496567429781,
              0.838243518213536: 0.659766155191656,
              0.84967194024849: 0.6672362428449391,
              0.8611003622834441: 0.674490920940623,
              0.8725287843183981: 0.681563206176313,
              0.8839572063533521: 0.688487751620754,
              0.895385628388306: 0.695300438130387,
              0.90681405042326: 0.7020379393503481,
              0.9182424724582141: 0.7087372637786541,
              0.9296708944931681: 0.715435277767961,
              0.9410993165281221: 0.722168213725735,
              0.952527738563075: 0.728971168144898,
              0.963956160598029: 0.7358775944459091,
              0.9753845826329831: 0.7429187959300101,
              0.9868130046679371: 0.7501234244236681,
              0.9982414267028911: 0.75751699042727,
              1.009669848737845: 0.765121390757982,
              1.021098270772799: 0.772954459788553,
              1.032526692807753: 0.7810295504224031,
              1.043955114842707: 0.7893551509028891,
              1.055383536877661: 0.79793454342457,
              1.066811958912615: 0.8067655102912541,
              1.078240380947569: 0.815840093045869,
              1.089668802982523: 0.8251444095787811,
              1.101097225017477: 0.834658533704098,
              1.112525647052431: 0.8443564410801061,
              1.123954069087384: 0.854206024644488,
              1.135382491122338: 0.8641691819443561,
              1.146810913157292: 0.8742019758742071,
              1.158239335192246: 0.88425486940283,
              1.1696677572272: 0.894273033886216,
              1.181096179262154: 0.9041967295423651,
              1.192524601297108: 0.9139617556221911,
              1.2039530233320621: 0.9234999667659751,
              1.215381445367016: 0.932739851005529,
              1.22680986740197: 0.9416071638773271,
              1.238238289436924: 0.9500256121702471,
              1.249666711471878: 0.9579175799617331,
              1.261095133506832: 0.9652048888159571,
              1.272523555541786: 0.9718095833432121,
              1.283951977576739: 0.9776547327663591,
              1.295380399611693: 0.9826652387202771,
              1.306808821646647: 0.9867686392346241,
              1.318237243681601: 0.9898958987266421,
              1.329665665716555: 0.991982173864379,
              1.341094087751509: 0.9929675453537201,
              1.352522509786463: 0.9927977060541421,
              1.363950931821417: 0.9914245963342061,
              1.375379353856371: 0.9888069782315051,
              1.386807775891325: 0.9849109407732641,
              1.398236197926279: 0.9797103297304771,
              1.409664619961233: 0.9731870961053141,
              1.421093041996187: 0.9653315587714071,
              1.4325214640311401: 0.9561425778803541,
              1.443949886066094: 0.9456276368950831,
              1.455378308101048: 0.9338028323899451,
              1.466806730136002: 0.9206927720465221,
              1.478235152170956: 0.90633038255106,
              1.4896635742059101: 0.89075663034201,
              1.5010919962408642: 0.8740201593436171,
              1.512520418275818: 0.85617685093352,
              1.523948840310772: 0.8372893124109221,
              1.535377262345726: 0.8174263011403511,
              1.5468056843806801: 0.7966620923303831,
              1.5582341064156342: 0.775075799055231,
              1.569662528450587: 0.752750653630876,
              1.581090950485542: 0.7297732598103751,
              1.5925193725204951: 0.706232825462019,
              1.603947794555449: 0.6822203854388711,
              1.615376216590403: 0.657828024241718,
              1.626804638625357: 0.633148107824972,
              1.638233060660311: 0.608272533504821,
              1.6496614826952651: 0.5832920064112831,
              1.661089904730219: 0.5582953502935231,
              1.672518326765173: 0.5333688597550741,
              1.683946748800127: 0.508595700178467,
              1.695375170835081: 0.48405536071404204,
              1.7068035928700351: 0.459823164773041,
              1.718232014904989: 0.43596984149844903,
              1.729660436939942: 0.41256116070630905,
              1.741088858974896: 0.389657632812819,
              1.7525172810098502: 0.36731427430517705,
              1.763945703044804: 0.345580438392366,
              1.7753741250797581: 0.32449970960017505,
              1.786802547114712: 0.30410986026532,
              1.798230969149666: 0.284442866147395,
              1.8096593911846202: 0.265524977723445,
              1.821087813219574: 0.247376843165162,
              1.8325162352545281: 0.23001367852777602,
              1.843944657289482: 0.213445480305494,
              1.855373079324436: 0.19767727523157602,
              1.8668015013593902: 0.18270940202069802,
              1.878229923394344: 0.168537819664193,
              1.8896583454292981: 0.155154436890654,
              1.901086767464251: 0.142547457489223,
              1.9125151894992052: 0.130701736353625,
              1.923943611534159: 0.11959914133355601,
              1.935372033569113: 0.109218916267504,
              1.946800455604067: 0.099538040908326,
              1.958228877639021: 0.090531583830147,
              1.9696572996739752: 0.082173044812946,
              1.981085721708929: 0.07443468362996801,
              1.992514143743883: 0.067287832603812,
              2.003942565778837: 0.060703190741031005,
              2.015370987813791: 0.05465109769446801,
              2.026799409848745: 0.04910178623005,
              2.038227831883699: 0.044025612284172004,
              2.049656253918653: 0.039393262083534004,
              2.061084675953606: 0.035175936157052,
              2.07251309798856: 0.031345510395531004,
              2.083941520023514: 0.027874674606758004,
              2.095369942058468: 0.024737049269690004,
              2.106798364093422: 0.021907281410781,
              2.118226786128376: 0.019361120708105,
              2.12965520816333: 0.017075477075427,
              2.141083630198284: 0.015028461090101001,
              2.152512052233238: 0.013199408707424,
              2.163940474268192: 0.011568891752025,
              2.175368896303146: 0.010118715696775001,
              2.186797318338099: 0.008831906234207001,
              2.198225740373054: 0.007692686117715001,
              2.209654162408008: 0.006686443702805,
              2.221082584442961: 0.005799694555585,
              2.232511006477915: 0.00502003741946,
            },
            gamma: {
              0.0038075490348770005: 6.0135840816284164e-05,
              0.017153625491116: 0.00010123063870800001,
              0.030499701947356003: 0.00016536842222300002,
              0.043845778403596006: 0.00026223531470000004,
              0.057191854859836: 0.00040382683230300004,
              0.07053793131607601: 0.000604184902876,
              0.083884007772315: 0.0008787507299200001,
              0.097230084228555: 0.001243328952395,
              0.110576160684795: 0.0017127548353830001,
              0.12392223714103501: 0.002299458973983,
              0.137268313597274: 0.003012198930709,
              0.150614390053514: 0.003855237979612,
              0.16396046650975402: 0.004828174420384,
              0.17730654296599402: 0.005926465576462,
              0.19065261942223302: 0.007142488720285001,
              0.20399869587847302: 0.008466805242366001,
              0.21734477233471303: 0.009889219062808001,
              0.23069084879095303: 0.011399295587478,
              0.24403692524719203: 0.012986231973247001,
              0.25738300170343203: 0.014638281038266001,
              0.270729078159672: 0.016342223356968,
              0.28407515461591204: 0.018083541695775,
              0.297421231072152: 0.019847903347787,
              0.31076730752839105: 0.021624292406864,
              0.324113383984631: 0.023409721449542002,
              0.33745946044087105: 0.025215003209693002,
              0.350805536897111: 0.027070694980368,
              0.36415161335335: 0.029032125454668004,
              0.37749768980959003: 0.031182409121705,
              0.39084376626583: 0.033632539738149,
              0.40418984272207004: 0.036518007133949,
              0.417535919178309: 0.039991877204005,
              0.43088199563454904: 0.044214887949393,
              0.444228072090789: 0.049343791573832,
              0.45757414854702905: 0.055519801163936004,
              0.470920225003269: 0.062859398363696,
              0.48426630145950805: 0.071449708838902,
              0.497612377915748: 0.08134998413097501,
              0.5109584543719881: 0.09259941780005,
              0.524304530828228: 0.105229770370135,
              0.537650607284467: 0.119279510218252,
              0.5509966837407071: 0.134804963202762,
              0.5643427601969471: 0.151883835715587,
              0.577688836653187: 0.17060773283250802,
              0.591034913109426: 0.191062845219054,
              0.604380989565666: 0.21330130437090103,
              0.6177270660219061: 0.23730895507343402,
              0.631073142478146: 0.262977511330556,
              0.644419218934385: 0.290089469313532,
              0.657765295390625: 0.31832239761887204,
              0.6711113718468651: 0.34727552705500003,
              0.6844574483031051: 0.376516682940496,
              0.697803524759345: 0.40564268072970905,
              0.711149601215584: 0.43434257037039903,
              0.7244956776718241: 0.46245156675007704,
              0.7378417541280641: 0.489984637691085,
              0.751187830584304: 0.517142366679411,
              0.764533907040543: 0.5442870386496821,
              0.7778799834967831: 0.571892627001886,
              0.7912260599530231: 0.600477089316921,
              0.8045721364092631: 0.6305280083112901,
              0.817918212865502: 0.6624326887725811,
              0.831264289321742: 0.696421674852386,
              0.8446103657779821: 0.7325312705011681,
              0.8579564422342221: 0.770587270272025,
              0.871302518690461: 0.8102097254960401,
              0.884648595146701: 0.8508375058173341,
              0.8979946716029411: 0.8917712154331501,
              0.9113407480591811: 0.9322327175500691,
              0.9246868245154211: 0.9714381612367771,
              0.93803290097166: 1.008678640313408,
              0.9513789774279001: 1.043399019479119,
              0.9647250538841401: 1.075262437349604,
              0.9780711303403801: 1.104187228330247,
              0.991417206796619: 1.130345720580659,
              1.004763283252859: 1.154120682562261,
              1.018109359709099: 1.176023878719228,
              1.031455436165339: 1.196589990834955,
              1.044801512621578: 1.216265508616799,
              1.058147589077818: 1.235314133464483,
              1.071493665534058: 1.2537570955293091,
              1.084839741990298: 1.2713593675287171,
              1.098185818446537: 1.287663051265482,
              1.111531894902777: 1.302059680820236,
              1.124877971359017: 1.3138860174369351,
              1.138224047815257: 1.322524454451088,
              1.151570124271497: 1.327489716355713,
              1.164916200727736: 1.328487536238458,
              1.178262277183976: 1.325437281802569,
              1.191608353640216: 1.3184577191523261,
              1.204954430096456: 1.30782195377571,
              1.218300506552695: 1.293892953966347,
              1.231646583008935: 1.277054084857355,
              1.244992659465175: 1.2576492395882881,
              1.258338735921415: 1.235944376648373,
              1.271684812377655: 1.21211704446253,
              1.285030888833894: 1.186273851205379,
              1.298376965290134: 1.158489292958627,
              1.311723041746374: 1.128854451858925,
              1.325069118202614: 1.097522052180924,
              1.338415194658853: 1.06473574006384,
              1.351761271115093: 1.030835852657493,
              1.3651073475713331: 0.9962401644186181,
              1.378453424027573: 0.9614044626951721,
              1.391799500483812: 0.926772645064811,
              1.405145576940052: 0.892728169616057,
              1.4184916533962921: 0.85955774325485,
              1.431837729852532: 0.827434561314174,
              1.445183806308771: 0.7964233150848811,
              1.458529882765011: 0.766503945804473,
              1.4718759592212511: 0.7376070210092751,
              1.485222035677491: 0.709651508757119,
              1.49856811213373: 0.682575932043565,
              1.51191418858997: 0.6563561499243681,
              1.5252602650462102: 0.6310066566485071,
              1.53860634150245: 0.606566404723337,
              1.551952417958689: 0.583073804763249,
              1.565298494414929: 0.560537956621741,
              1.5786445708711692: 0.5389138494723681,
              1.591990647327409: 0.518088150446538,
              1.605336723783649: 0.49787960205240206,
              1.618682800239888: 0.47805461782487,
              1.6320288766961282: 0.45835523032166003,
              1.645374953152368: 0.438533904487766,
              1.658721029608608: 0.418388449613587,
              1.672067106064847: 0.39779054012632203,
              1.6854131825210872: 0.37670298534287006,
              1.698759258977327: 0.355183361335675,
              1.712105335433567: 0.33337429655195405,
              1.7254514118898072: 0.31148301246356,
              1.7387974883460462: 0.289754282696558,
              1.752143564802286: 0.26844164032043605,
              1.765489641258526: 0.24778146393398903,
              1.778835717714766: 0.22797363589473502,
              1.7921817941710052: 0.209170959601812,
              1.805527870627245: 0.19147766091169302,
              1.818873947083485: 0.174955377405997,
              1.832220023539725: 0.15963345119729402,
              1.8455660999959642: 0.145519515991962,
              1.858912176452204: 0.132606632521404,
              1.872258252908444: 0.12087463098340201,
              1.885604329364684: 0.110285541550018,
              1.8989504058209241: 0.10077537352027201,
              1.912296482277163: 0.092246237959154,
              1.925642558733403: 0.084563243068905,
              1.938988635189643: 0.077559483710723,
              1.9523347116458831: 0.071050088252864,
              1.965680788102122: 0.064853422263124,
              1.979026864558362: 0.058815119608843,
              1.992372941014602: 0.052829418329180006,
              2.005719017470842: 0.046852703856537004,
              2.019065093927081: 0.040906050475952006,
              2.032411170383321: 0.035066287451267004,
              2.045757246839561: 0.029447855982330004,
              2.059103323295801: 0.024179689521310003,
              2.07244939975204: 0.019382072872584002,
              2.08579547620828: 0.015147864269661,
              2.09914155266452: 0.011530927552074001,
              2.11248762912076: 0.008542668542574001,
              2.125833705577: 0.006155769937963001,
              2.139179782033239: 0.004312989457569001,
              2.152525858489479: 0.0029384016605190004,
              2.165871934945719: 0.00194866203626,
              2.179218011401959: 0.001262527640318,
              2.192564087858198: 0.000807699169225,
              2.205910164314438: 0.000524808414902,
              2.219256240770678: 0.00036890824957200004,
              2.232602317226918: 0.00030908090774,
              2.245948393683158: 0.00032680117892700005,
              2.259294470139397: 0.00041356050710300004,
              2.272640546595637: 0.000568074942616,
              2.285986623051877: 0.0007932481542960001,
              2.299332699508117: 0.0010929921952730001,
              2.312678775964356: 0.001469037079406,
              2.326024852420596: 0.0019179623076280002,
              2.339370928876836: 0.002428807427569,
              2.352717005333076: 0.0029816984371770004,
              2.3660630817893162: 0.0035479018431240003,
              2.379409158245555: 0.004091555044782,
              2.392755234701795: 0.004573030973972001,
              2.406101311158035: 0.0049535358484100005,
              2.419447387614275: 0.005200207319729,
              2.4327934640705142: 0.005290780549517001,
              2.446139540526754: 0.005216898960013001,
              2.459485616982994: 0.004985384223937001,
              2.4728316934392343: 0.004617196498785001,
              2.4861777698954732: 0.0041443038229950005,
              2.499523846351713: 0.0036051083269970003,
              2.512869922807953: 0.00303933488224,
              2.526215999264193: 0.002483317748694,
              2.539562075720432: 0.001966434717304,
              2.552908152176672: 0.0015091078424890002,
              2.566254228632912: 0.0011224177381610001,
              2.5796003050891523: 0.00080906286736,
              2.5929463815453913: 0.000565201778277,
              2.606292458001631: 0.00038266458629700005,
              2.619638534457871: 0.00025108832955800004,
              2.632984610914111: 0.000159671823459,
              2.64633068737035: 9.840644546782398e-05,
              2.65967676382659: 5.877766203289085e-05,
            },
            geo_level_type: 'Canada',
            halfnorm: {
              0.44266030109435706: 0.002170169314611,
              0.456071951842256: 0.0036187070406000004,
              0.46948360259015404: 0.005891145653163001,
              0.48289525333805206: 0.009364659807435,
              0.496306904085951: 0.014537744526065,
              0.5097185548338491: 0.022043994442733,
              0.523130205581748: 0.03265555296072,
              0.536541856329646: 0.047270883663946,
              0.5499535070775441: 0.066882036073013,
              0.563365157825443: 0.09251835279893901,
              0.576776808573341: 0.125166675096634,
              0.5901884593212391: 0.16567229646616602,
              0.603600110069138: 0.21462955632656902,
              0.617011760817036: 0.272275103503203,
              0.6304234115649351: 0.33839939998602003,
              0.643835062312833: 0.412292030655156,
              0.657246713060731: 0.49273333868024505,
              0.6706583638086301: 0.5780390110036361,
              0.684070014556528: 0.666156436731138,
              0.697481665304427: 0.7548034850873231,
              0.7108933160523251: 0.8416335506653071,
              0.724304966800223: 0.924406788062575,
              0.737716617548122: 1.001147213862345,
              0.7511282682960201: 1.070268673937855,
              0.764539919043918: 1.130658562779189,
              0.777951569791817: 1.181715089358316,
              0.7913632205397151: 1.223340196983446,
              0.804774871287614: 1.255894767182218,
              0.818186522035512: 1.280125000100318,
              0.8315981727834101: 1.297069154394535,
              0.845009823531309: 1.307952959425585,
              0.8584214742792071: 1.314080921870202,
              0.8718331250271061: 1.316730114395027,
              0.885244775775004: 1.317053027600537,
              0.8986564265229021: 1.315996343932032,
              0.9120680772708011: 1.314242397548901,
              0.925479728018699: 1.312178971531453,
              0.9388913787665971: 1.309900625001523,
              0.9523030295144961: 1.307241101385983,
              0.965714680262394: 1.303832184301445,
              0.9791263310102931: 1.299180562425489,
              0.9925379817581911: 1.292751756071677,
              1.00594963250609: 1.284049571996851,
              1.019361283253988: 1.272681038117848,
              1.032772934001886: 1.258399962342565,
              1.046184584749784: 1.241126397608542,
              1.0595962354976831: 1.220943438093845,
              1.073007886245581: 1.198076053928245,
              1.08641953699348: 1.172858516558427,
              1.099831187741378: 1.145697204975542,
              1.113242838489276: 1.117034454135455,
              1.126654489237175: 1.087317152775801,
              1.140066139985073: 1.056971683550104,
              1.153477790732972: 1.026385116416692,
              1.16688944148087: 0.9958916781879121,
              1.180301092228768: 0.965763480180098,
              1.193712742976667: 0.93620505738623,
              1.207124393724565: 0.9073520416849941,
              1.220536044472464: 0.879274819292824,
              1.233947695220362: 0.851987997861216,
              1.24735934596826: 0.825465846982796,
              1.2607709967161589: 0.799662736178526,
              1.274182647464057: 0.774536312901537,
              1.287594298211956: 0.7500701342586771,
              1.301005948959854: 0.726292015261595,
              1.314417599707752: 0.7032846465168281,
              1.327829250455651: 0.681186034033425,
              1.341240901203549: 0.660178827201481,
              1.354652551951447: 0.640469337682937,
              1.3680642026993461: 0.6222587076357771,
              1.381475853447244: 0.605710004330099,
              1.394887504195142: 0.5909158229291961,
              1.408299154943041: 0.5778711746324721,
              1.421710805690939: 0.566455997653175,
              1.435122456438838: 0.5564305866993641,
              1.448534107186736: 0.547445684170978,
              1.461945757934634: 0.539067070046594,
              1.475357408682533: 0.530812453421817,
              1.488769059430431: 0.522196589603503,
              1.50218071017833: 0.512779126470933,
              1.515592360926228: 0.502208992349072,
              1.5290040116741261: 0.49025934910945906,
              1.5424156624220249: 0.47684827752789805,
              1.5558273131699232: 0.462042299310702,
              1.569238963917822: 0.44604228240966304,
              1.58265061466572: 0.42915383203398505,
              1.596062265413618: 0.41174651792328903,
              1.609473916161517: 0.39420785564974703,
              1.622885566909415: 0.376898587636156,
              1.636297217657313: 0.36011539897536804,
              1.6497088684052121: 0.344065829087475,
              1.66312051915311: 0.32885803841287203,
              1.6765321699010092: 0.314505617313452,
              1.689943820648907: 0.30094520420402704,
              1.703355471396805: 0.28806272901204605,
              1.716767122144704: 0.275722956577032,
              1.730178772892602: 0.263796870456938,
              1.7435904236405002: 0.252182321073945,
              1.757002074388399: 0.240815073074112,
              1.770413725136297: 0.22966956336763303,
              1.783825375884196: 0.21875086049984102,
              1.797237026632094: 0.20808103410664402,
              1.8106486773799921: 0.19768404386122002,
              1.824060328127891: 0.18757317789072603,
              1.8374719788757892: 0.177744081515036,
              1.850883629623688: 0.168174800489405,
              1.8642952803715862: 0.158832435613421,
              1.877706931119484: 0.14968440857259602,
              1.891118581867383: 0.140711325487621,
              1.904530232615281: 0.13191817941682402,
              1.917941883363179: 0.12334114215555302,
              1.9313535341110781: 0.11504827191024601,
              1.944765184858976: 0.107133812555498,
              1.9581768356068752: 0.099707064670632,
              1.971588486354773: 0.09287780363551501,
              1.985000137102671: 0.08674074390478001,
              1.99841178785057: 0.08136155930764001,
              2.011823438598468: 0.076766532321535,
              2.025235089346367: 0.072937161369944,
              2.038646740094265: 0.06981017998502101,
              2.052058390842163: 0.067282607501745,
              2.065470041590062: 0.065220796074148,
              2.07888169233796: 0.06347204725746601,
              2.092293343085859: 0.061877264697257,
              2.105704993833757: 0.060283250531766,
              2.119116644581655: 0.058553561602139,
              2.132528295329554: 0.05657721769227501,
              2.145939946077452: 0.05427490549501401,
              2.159351596825351: 0.051602586942563006,
              2.172763247573249: 0.048552579700344003,
              2.186174898321147: 0.045152252312451006,
              2.199586549069046: 0.041460513378714,
              2.212998199816944: 0.037562322885034,
              2.226409850564843: 0.033561545847451,
              2.23982150131274: 0.029572604732888003,
              2.253233152060639: 0.025711538701157003,
              2.266644802808537: 0.022087197787213003,
              2.280056453556436: 0.018793342404179,
              2.293468104304335: 0.01590235430458,
              2.306879755052232: 0.013461093221282002,
              2.320291405800131: 0.011489180190699002,
              2.3337030565480292: 0.009979699339084,
              2.347114707295928: 0.008902035799694001,
              2.360526358043826: 0.008206352595394,
              2.373938008791724: 0.007829082286724,
              2.387349659539623: 0.007698779539427001,
              2.400761310287521: 0.007741740589575,
              2.41417296103542: 0.007886923703908,
              2.427584611783318: 0.008069872002356001,
              2.440996262531216: 0.008235514620830001,
              2.4544079132791152: 0.008339874757150001,
              2.467819564027013: 0.008350821486562,
              2.481231214774912: 0.00824805541326,
              2.49464286552281: 0.008022518706583,
              2.508054516270708: 0.007675382865931,
              2.521466167018607: 0.007216715803996,
              2.534877817766505: 0.006663887628350001,
              2.548289468514403: 0.006039759114343001,
              2.561701119262302: 0.00537071311951,
              2.5751127700102: 0.0046846281443640005,
              2.588524420758099: 0.004008935802902,
              2.601936071505997: 0.003368928144835,
              2.615347722253895: 0.0027864698876330003,
              2.628759373001794: 0.002279219438588,
              2.6421710237496923: 0.0018603793858070002,
              2.655582674497591: 0.0015389012073600001,
              2.668994325245489: 0.0013199847799180001,
              2.682405975993387: 0.00120566348851,
              2.695817626741286: 0.001195264984907,
              2.709229277489184: 0.001285588851643,
              2.722640928237083: 0.001470736085616,
              2.73605257898498: 0.001741641408332,
              2.749464229732879: 0.002085471466599,
              2.7628758804807783: 0.0024851324436960002,
              2.776287531228676: 0.0029191567400810004,
              2.789699181976575: 0.0033621979377950004,
              2.803110832724472: 0.0037862585244850004,
              2.816522483472371: 0.004162623996091,
              2.82993413422027: 0.004464311568885,
              2.843345784968168: 0.0046687003733860005,
              2.856757435716066: 0.004759928893608,
              2.870169086463964: 0.004730648570907,
              2.883580737211863: 0.004582814299384,
              2.896992387959762: 0.004327355374103,
              2.91040403870766: 0.003982768524421,
              2.923815689455558: 0.003572863259954,
              2.937227340203456: 0.00312402716848,
              2.9506389909513553: 0.0026624372621640003,
              2.964050641699254: 0.002211615726959,
              2.977462292447152: 0.001790628201923,
              2.99087394319505: 0.001413079218453,
              3.004285593942948: 0.001086908217848,
              3.017697244690847: 0.000814862768936,
              3.031108895438746: 0.000595444517698,
              3.044520546186644: 0.000424095183742,
              3.057932196934542: 0.000294408665567,
              3.07134384768244: 0.00019920628839700002,
              3.084755498430339: 0.00013137741251000002,
              3.098167149178238: 8.445076134848489e-05,
              3.111578799926136: 5.2911690674297304e-05,
            },
            triangular: {
              0.17630994285368: 0.000260242269059,
              0.18403184821736202: 0.00036824635481400004,
              0.19175375358104402: 0.0005157156800260001,
              0.19947565894472602: 0.000714869534609,
              0.20719756430840802: 0.0009808956356430001,
              0.21491946967209002: 0.0013324013959650001,
              0.22264137503577203: 0.001791851789243,
              0.23036328039945403: 0.0023859650877820003,
              0.23808518576313603: 0.0031460323284570003,
              0.245807091126818: 0.004108122125644,
              0.25352899649050004: 0.0053131300833100005,
              0.26125090185418204: 0.006806632230146,
              0.26897280721786404: 0.008638505186315001,
              0.27669471258154604: 0.010862282566173,
              0.28441661794522805: 0.013534227571711,
              0.29213852330891: 0.016712115663647,
              0.29986042867259105: 0.020453738085104003,
              0.307582334036273: 0.024815155976723003,
              0.315304239399955: 0.029848754666115,
              0.323026144763637: 0.035601167005640005,
              0.330748050127319: 0.042111151813533004,
              0.338469955491001: 0.049407527001104005,
              0.346191860854683: 0.057507265462360006,
              0.353913766218365: 0.06641386418488701,
              0.361635671582047: 0.07611609265829501,
              0.369357576945729: 0.086587215358148,
              0.377079482309411: 0.09778476525292501,
              0.384801387673093: 0.10965092181625401,
              0.392523293036775: 0.122113519261204,
              0.400245198400457: 0.135087680302787,
              0.407967103764139: 0.14847803952476002,
              0.41568900912782103: 0.162181490212729,
              0.42341091449150303: 0.17609036100914602,
              0.43113281985518503: 0.19009590536744,
              0.43885472521886704: 0.20409196859745402,
              0.44657663058254904: 0.21797868497927203,
              0.45429853594623104: 0.231666051288363,
              0.46202044130991304: 0.24507722313450603,
              0.46974234667359505: 0.258151386585526,
              0.47746425203727705: 0.27084606934987704,
              0.48518615740095905: 0.283138773055823,
              0.49290806276464105: 0.29502783066383104,
              0.500629968128323: 0.306532420591882,
              0.5083518734920051: 0.317691701482725,
              0.5160737788556871: 0.32856306827181403,
              0.5237956842193691: 0.339219570535617,
              0.5315175895830511: 0.34974657668947,
              0.5392394949467331: 0.360237810486204,
              0.5469614003104151: 0.370790926792032,
              0.5546833056740971: 0.381502828573394,
              0.5624052110377791: 0.39246495290591105,
              0.5701271164014611: 0.403758767196689,
              0.5778490217651431: 0.415451714858364,
              0.5855709271288251: 0.42759383064322404,
              0.5932928324925071: 0.440215209531972,
              0.6010147378561891: 0.453324461100284,
              0.6087366432198701: 0.46690821718568704,
              0.6164585485835521: 0.48093168967945604,
              0.6241804539472341: 0.495340203838017,
              0.6319023593109161: 0.510061567644009,
              0.6396242646745981: 0.5250090861359661,
              0.6473461700382801: 0.5400849967680831,
              0.6550680754019621: 0.5551840912921611,
              0.6627899807656441: 0.5701973023768271,
              0.6705118861293261: 0.5850150674127721,
              0.678233791493008: 0.5995303332356541,
              0.68595569685669: 0.613641127158404,
              0.693677602220372: 0.6272526836450371,
              0.701399507584054: 0.6402791736661251,
              0.709121412947736: 0.6526451274328221,
              0.716843318311418: 0.664286664752197,
              0.7245652236751: 0.6751526472657421,
              0.732287129038782: 0.685205843127706,
              0.740009034402464: 0.694424150447694,
              0.747730939766146: 0.702801867383759,
              0.755452845129828: 0.7103509328615291,
              0.76317475049351: 0.717102002569813,
              0.770896655857192: 0.7231051801807801,
              0.778618561220874: 0.7284302022422701,
              0.786340466584556: 0.733165882677651,
              0.794062371948238: 0.737418661267459,
              0.80178427731192: 0.7413101674649031,
              0.809506182675602: 0.744973799678574,
              0.817228088039284: 0.7485504203797151,
              0.824949993402966: 0.7521833663624671,
              0.832671898766648: 0.756013057902528,
              0.84039380413033: 0.7601715484486701,
              0.848115709494012: 0.764777379038719,
              0.855837614857694: 0.7699310846802121,
              0.863559520221376: 0.775711644738279,
              0.8712814255850581: 0.782174082632472,
              0.8790033309487401: 0.789348313153004,
              0.8867252363124221: 0.797239222743454,
              0.8944471416761041: 0.805827864297997,
              0.9021690470397861: 0.815073567060827,
              0.9098909524034681: 0.8249167141641751,
              0.9176128577671491: 0.835281930062683,
              0.9253347631308311: 0.8460814464812261,
              0.9330566684945131: 0.8572184715278061,
              0.9407785738581951: 0.868590460660663,
              0.9485004792218771: 0.8800922657551871,
              0.9562223845855591: 0.8916192046704251,
              0.9639442899492411: 0.9030701355757841,
              0.9716661953129231: 0.914350629163262,
              0.9793881006766051: 0.9253763047216681,
              0.9871100060402871: 0.9360763361465291,
              0.9948319114039691: 0.9463970504293631,
              1.002553816767651: 0.9563054476600861,
              1.010275722131333: 0.9657923841865591,
              1.017997627495015: 0.9748750953941091,
              1.025719532858697: 0.9835987051963541,
              1.033441438222379: 0.992036384769386,
              1.041163343586061: 1.000287886288694,
              1.048885248949743: 1.008476284840792,
              1.056607154313425: 1.016742903503242,
              1.0643290596771071: 1.025240558136191,
              1.072050965040789: 1.034125422053418,
              1.079772870404471: 1.04354795812151,
              1.087494775768153: 1.053643480323194,
              1.0952166811318351: 1.06452297547966,
              1.102938586495517: 1.076264830916806,
              1.110660491859199: 1.088908073579119,
              1.118382397222881: 1.102447634522809,
              1.1261043025865631: 1.116832019026155,
              1.133826207950245: 1.131963599626334,
              1.141548113313927: 1.147701572116232,
              1.149270018677609: 1.16386743800789,
              1.156991924041291: 1.180252714879716,
              1.164713829404973: 1.196628439418791,
              1.172435734768655: 1.212755924523219,
              1.180157640132336: 1.228398165652581,
              1.187879545496019: 1.243331263529266,
              1.1956014508597: 1.2573552384487021,
              1.203323356223382: 1.27030365210929,
              1.211045261587064: 1.282051521159438,
              1.218767166950746: 1.292521097221633,
              1.226489072314428: 1.301685195540335,
              1.23421097767811: 1.309567873241041,
              1.241932883041792: 1.316242383197289,
              1.249654788405474: 1.3218264554226131,
              1.257376693769156: 1.32647507949169,
              1.265098599132838: 1.3303710736396321,
              1.27282050449652: 1.333713824201507,
              1.280542409860202: 1.33670665908268,
              1.288264315223884: 1.339543378427528,
              1.295986220587566: 1.342394503646213,
              1.303708125951248: 1.345393823275653,
              1.31143003131493: 1.348625813114148,
              1.319151936678612: 1.352114491925137,
              1.326873842042294: 1.3558142460414762,
              1.334595747405976: 1.3596031187361262,
              1.342317652769658: 1.363279013697267,
              1.35003955813334: 1.366559204354874,
              1.357761463497022: 1.369083467712888,
              1.365483368860704: 1.370421066558053,
              1.373205274224386: 1.370081680865228,
              1.380927179588068: 1.367530232800412,
              1.38864908495175: 1.36220535838681,
              1.396370990315432: 1.353541056517799,
              1.404092895679114: 1.340990803115478,
              1.411814801042796: 1.324053172310027,
              1.419536706406478: 1.30229778097021,
              1.42725861177016: 1.275390194886441,
              1.4349805171338421: 1.243114331961406,
              1.442702422497524: 1.205390893956808,
              1.4504243278612061: 1.162290470221878,
              1.458146233224888: 1.11404018999662,
              1.46586813858857: 1.061023146789844,
              1.473590043952251: 1.003770257715107,
              1.4813119493159341: 0.942944718894503,
              1.4890338546796151: 0.8793197322831541,
              1.496755760043297: 0.8137506621531191,
              1.504477665406979: 0.747143184592151,
              1.512199570770661: 0.6804192808754941,
              1.5199214761343431: 0.6144830670772571,
              1.527643381498025: 0.550188434376175,
              1.535365286861707: 0.48831030028857203,
              1.543087192225389: 0.42952095964782705,
              1.5508090975890712: 0.37437260813459705,
              1.558531002952753: 0.323286632473177,
              1.566252908316435: 0.276549766187628,
              1.573974813680117: 0.23431674304149502,
              1.5816967190437992: 0.19661868089994602,
              1.589418624407481: 0.163376125830333,
              1.597140529771163: 0.13441549671278802,
              1.604862435134845: 0.10948759858463701,
              1.6125843404985272: 0.088286910653308,
              1.620306245862209: 0.07047048502142801,
              1.628028151225891: 0.055675490772365006,
              1.635750056589573: 0.043534678153033,
              1.6434719619532552: 0.033689292342841,
              1.651193867316937: 0.025799211860433,
              1.658915772680619: 0.019550304135226,
              1.666637678044301: 0.014659167299099001,
              1.6743595834079832: 0.010875556219700001,
              1.682081488771665: 0.007982871293599,
              1.689803394135347: 0.005797124278180001,
              1.697525299499029: 0.004164793476115001,
              1.7052472048627112: 0.002959949752046,
              1.712969110226393: 0.0020809845481180003,
            },
          },
          {
            actual: {
              '-0.041744978477915': 0.0037079623029640002,
              '-0.030316556442961003': 0.004266275879592001,
              '-0.018888134408007': 0.0048982314366020005,
              '-0.007459712373053': 0.005611870630944001,
              0.0039687096619: 0.006415866044628,
              0.015397131696854001: 0.007319536452261001,
              0.026825553731807002: 0.008332857434196001,
              0.038253975766761004: 0.009466466598019,
              0.049682397801715: 0.010731662658870001,
              0.061110819836669: 0.012140397628609,
              0.072539241871623: 0.013705261376615001,
              0.083967663906577: 0.01543945785241,
              0.09539608594153101: 0.017356772303633,
              0.106824507976485: 0.019471528883009,
              0.11825293001143901: 0.021798538115796,
              0.129681352046393: 0.024353033794985,
              0.141109774081347: 0.027150598985543003,
              0.152538196116301: 0.030207080950708003,
              0.16396661815125502: 0.033538494962136005,
              0.17539504018620902: 0.037160917120311006,
              0.186823462221162: 0.041090366490255006,
              0.198251884256116: 0.045342677048213,
              0.20968030629107: 0.049933360134765,
              0.22110872832602402: 0.05487745831578,
              0.23253715036097802: 0.060189391761207,
              0.24396557239593203: 0.065882798458884,
              0.255393994430886: 0.07197036978240201,
              0.26682241646584004: 0.078463683123887,
              0.278250838500794: 0.085373033480098,
              0.289679260535748: 0.09270726603875201,
              0.30110768257070203: 0.100473611947144,
              0.312536104605656: 0.10867752955264101,
              0.32396452664061004: 0.11732255348060001,
              0.335392948675563: 0.12641015395622102,
              0.34682137071051705: 0.13593960877994402,
              0.35824979274547103: 0.145907890328931,
              0.369678214780425: 0.15630956987854702,
              0.38110663681537904: 0.16713674141686002,
              0.392535058850333: 0.17837896696230102,
              0.40396348088528705: 0.19002324519095,
              0.41539190292024103: 0.20205400493743603,
              0.426820324955195: 0.21445312485530202,
              0.43824874699014904: 0.227199980212643,
              0.449677169025103: 0.24027151746157202,
              0.46110559106005705: 0.25364235686096304,
              0.47253401309501103: 0.26728492305693,
              0.48396243512996406: 0.281169603140878,
              0.49539085716491804: 0.295264931317531,
              0.506819279199872: 0.309537798931719,
              0.518247701234826: 0.323953688229747,
              0.5296761232697801: 0.338476927875249,
              0.5411045453047341: 0.35307096790680603,
              0.552532967339688: 0.36769867152074404,
              0.563961389374642: 0.38232262079238705,
              0.575389811409596: 0.396905433216761,
              0.5868182334445501: 0.411410085758514,
              0.5982466554795041: 0.42580024295303404,
              0.609675077514458: 0.44004058549770103,
              0.621103499549412: 0.45409713571437604,
              0.632531921584366: 0.46793757625108706,
              0.64396034361932: 0.48153155842110706,
              0.6553887656542731: 0.49485099664916204,
              0.666817187689227: 0.507870345604689,
              0.678245609724181: 0.520566856747693,
              0.6896740317591351: 0.532920811190398,
              0.7011024537940891: 0.5449157259838281,
              0.7125308758290431: 0.556538531169112,
              0.723959297863997: 0.567779715185025,
              0.735387719898951: 0.5786334364929351,
              0.7468161419339051: 0.589097599564903,
              0.7582445639688591: 0.5991738936777891,
              0.7696729860038131: 0.608867793263963,
              0.781101408038767: 0.6181885188860721,
              0.792529830073721: 0.6271489582285871,
              0.8039582521086741: 0.6357655468317981,
              0.8153866741436281: 0.644058108634832,
              0.8268150961785821: 0.6520496567429781,
              0.838243518213536: 0.659766155191656,
              0.84967194024849: 0.6672362428449391,
              0.8611003622834441: 0.674490920940623,
              0.8725287843183981: 0.681563206176313,
              0.8839572063533521: 0.688487751620754,
              0.895385628388306: 0.695300438130387,
              0.90681405042326: 0.7020379393503481,
              0.9182424724582141: 0.7087372637786541,
              0.9296708944931681: 0.715435277767961,
              0.9410993165281221: 0.722168213725735,
              0.952527738563075: 0.728971168144898,
              0.963956160598029: 0.7358775944459091,
              0.9753845826329831: 0.7429187959300101,
              0.9868130046679371: 0.7501234244236681,
              0.9982414267028911: 0.75751699042727,
              1.009669848737845: 0.765121390757982,
              1.021098270772799: 0.772954459788553,
              1.032526692807753: 0.7810295504224031,
              1.043955114842707: 0.7893551509028891,
              1.055383536877661: 0.79793454342457,
              1.066811958912615: 0.8067655102912541,
              1.078240380947569: 0.815840093045869,
              1.089668802982523: 0.8251444095787811,
              1.101097225017477: 0.834658533704098,
              1.112525647052431: 0.8443564410801061,
              1.123954069087384: 0.854206024644488,
              1.135382491122338: 0.8641691819443561,
              1.146810913157292: 0.8742019758742071,
              1.158239335192246: 0.88425486940283,
              1.1696677572272: 0.894273033886216,
              1.181096179262154: 0.9041967295423651,
              1.192524601297108: 0.9139617556221911,
              1.2039530233320621: 0.9234999667659751,
              1.215381445367016: 0.932739851005529,
              1.22680986740197: 0.9416071638773271,
              1.238238289436924: 0.9500256121702471,
              1.249666711471878: 0.9579175799617331,
              1.261095133506832: 0.9652048888159571,
              1.272523555541786: 0.9718095833432121,
              1.283951977576739: 0.9776547327663591,
              1.295380399611693: 0.9826652387202771,
              1.306808821646647: 0.9867686392346241,
              1.318237243681601: 0.9898958987266421,
              1.329665665716555: 0.991982173864379,
              1.341094087751509: 0.9929675453537201,
              1.352522509786463: 0.9927977060541421,
              1.363950931821417: 0.9914245963342061,
              1.375379353856371: 0.9888069782315051,
              1.386807775891325: 0.9849109407732641,
              1.398236197926279: 0.9797103297304771,
              1.409664619961233: 0.9731870961053141,
              1.421093041996187: 0.9653315587714071,
              1.4325214640311401: 0.9561425778803541,
              1.443949886066094: 0.9456276368950831,
              1.455378308101048: 0.9338028323899451,
              1.466806730136002: 0.9206927720465221,
              1.478235152170956: 0.90633038255106,
              1.4896635742059101: 0.89075663034201,
              1.5010919962408642: 0.8740201593436171,
              1.512520418275818: 0.85617685093352,
              1.523948840310772: 0.8372893124109221,
              1.535377262345726: 0.8174263011403511,
              1.5468056843806801: 0.7966620923303831,
              1.5582341064156342: 0.775075799055231,
              1.569662528450587: 0.752750653630876,
              1.581090950485542: 0.7297732598103751,
              1.5925193725204951: 0.706232825462019,
              1.603947794555449: 0.6822203854388711,
              1.615376216590403: 0.657828024241718,
              1.626804638625357: 0.633148107824972,
              1.638233060660311: 0.608272533504821,
              1.6496614826952651: 0.5832920064112831,
              1.661089904730219: 0.5582953502935231,
              1.672518326765173: 0.5333688597550741,
              1.683946748800127: 0.508595700178467,
              1.695375170835081: 0.48405536071404204,
              1.7068035928700351: 0.459823164773041,
              1.718232014904989: 0.43596984149844903,
              1.729660436939942: 0.41256116070630905,
              1.741088858974896: 0.389657632812819,
              1.7525172810098502: 0.36731427430517705,
              1.763945703044804: 0.345580438392366,
              1.7753741250797581: 0.32449970960017505,
              1.786802547114712: 0.30410986026532,
              1.798230969149666: 0.284442866147395,
              1.8096593911846202: 0.265524977723445,
              1.821087813219574: 0.247376843165162,
              1.8325162352545281: 0.23001367852777602,
              1.843944657289482: 0.213445480305494,
              1.855373079324436: 0.19767727523157602,
              1.8668015013593902: 0.18270940202069802,
              1.878229923394344: 0.168537819664193,
              1.8896583454292981: 0.155154436890654,
              1.901086767464251: 0.142547457489223,
              1.9125151894992052: 0.130701736353625,
              1.923943611534159: 0.11959914133355601,
              1.935372033569113: 0.109218916267504,
              1.946800455604067: 0.099538040908326,
              1.958228877639021: 0.090531583830147,
              1.9696572996739752: 0.082173044812946,
              1.981085721708929: 0.07443468362996801,
              1.992514143743883: 0.067287832603812,
              2.003942565778837: 0.060703190741031005,
              2.015370987813791: 0.05465109769446801,
              2.026799409848745: 0.04910178623005,
              2.038227831883699: 0.044025612284172004,
              2.049656253918653: 0.039393262083534004,
              2.061084675953606: 0.035175936157052,
              2.07251309798856: 0.031345510395531004,
              2.083941520023514: 0.027874674606758004,
              2.095369942058468: 0.024737049269690004,
              2.106798364093422: 0.021907281410781,
              2.118226786128376: 0.019361120708105,
              2.12965520816333: 0.017075477075427,
              2.141083630198284: 0.015028461090101001,
              2.152512052233238: 0.013199408707424,
              2.163940474268192: 0.011568891752025,
              2.175368896303146: 0.010118715696775001,
              2.186797318338099: 0.008831906234207001,
              2.198225740373054: 0.007692686117715001,
              2.209654162408008: 0.006686443702805,
              2.221082584442961: 0.005799694555585,
              2.232511006477915: 0.00502003741946,
            },
            gamma: {
              0.0038075490348770005: 6.0135840816284164e-05,
              0.017153625491116: 0.00010123063870800001,
              0.030499701947356003: 0.00016536842222300002,
              0.043845778403596006: 0.00026223531470000004,
              0.057191854859836: 0.00040382683230300004,
              0.07053793131607601: 0.000604184902876,
              0.083884007772315: 0.0008787507299200001,
              0.097230084228555: 0.001243328952395,
              0.110576160684795: 0.0017127548353830001,
              0.12392223714103501: 0.002299458973983,
              0.137268313597274: 0.003012198930709,
              0.150614390053514: 0.003855237979612,
              0.16396046650975402: 0.004828174420384,
              0.17730654296599402: 0.005926465576462,
              0.19065261942223302: 0.007142488720285001,
              0.20399869587847302: 0.008466805242366001,
              0.21734477233471303: 0.009889219062808001,
              0.23069084879095303: 0.011399295587478,
              0.24403692524719203: 0.012986231973247001,
              0.25738300170343203: 0.014638281038266001,
              0.270729078159672: 0.016342223356968,
              0.28407515461591204: 0.018083541695775,
              0.297421231072152: 0.019847903347787,
              0.31076730752839105: 0.021624292406864,
              0.324113383984631: 0.023409721449542002,
              0.33745946044087105: 0.025215003209693002,
              0.350805536897111: 0.027070694980368,
              0.36415161335335: 0.029032125454668004,
              0.37749768980959003: 0.031182409121705,
              0.39084376626583: 0.033632539738149,
              0.40418984272207004: 0.036518007133949,
              0.417535919178309: 0.039991877204005,
              0.43088199563454904: 0.044214887949393,
              0.444228072090789: 0.049343791573832,
              0.45757414854702905: 0.055519801163936004,
              0.470920225003269: 0.062859398363696,
              0.48426630145950805: 0.071449708838902,
              0.497612377915748: 0.08134998413097501,
              0.5109584543719881: 0.09259941780005,
              0.524304530828228: 0.105229770370135,
              0.537650607284467: 0.119279510218252,
              0.5509966837407071: 0.134804963202762,
              0.5643427601969471: 0.151883835715587,
              0.577688836653187: 0.17060773283250802,
              0.591034913109426: 0.191062845219054,
              0.604380989565666: 0.21330130437090103,
              0.6177270660219061: 0.23730895507343402,
              0.631073142478146: 0.262977511330556,
              0.644419218934385: 0.290089469313532,
              0.657765295390625: 0.31832239761887204,
              0.6711113718468651: 0.34727552705500003,
              0.6844574483031051: 0.376516682940496,
              0.697803524759345: 0.40564268072970905,
              0.711149601215584: 0.43434257037039903,
              0.7244956776718241: 0.46245156675007704,
              0.7378417541280641: 0.489984637691085,
              0.751187830584304: 0.517142366679411,
              0.764533907040543: 0.5442870386496821,
              0.7778799834967831: 0.571892627001886,
              0.7912260599530231: 0.600477089316921,
              0.8045721364092631: 0.6305280083112901,
              0.817918212865502: 0.6624326887725811,
              0.831264289321742: 0.696421674852386,
              0.8446103657779821: 0.7325312705011681,
              0.8579564422342221: 0.770587270272025,
              0.871302518690461: 0.8102097254960401,
              0.884648595146701: 0.8508375058173341,
              0.8979946716029411: 0.8917712154331501,
              0.9113407480591811: 0.9322327175500691,
              0.9246868245154211: 0.9714381612367771,
              0.93803290097166: 1.008678640313408,
              0.9513789774279001: 1.043399019479119,
              0.9647250538841401: 1.075262437349604,
              0.9780711303403801: 1.104187228330247,
              0.991417206796619: 1.130345720580659,
              1.004763283252859: 1.154120682562261,
              1.018109359709099: 1.176023878719228,
              1.031455436165339: 1.196589990834955,
              1.044801512621578: 1.216265508616799,
              1.058147589077818: 1.235314133464483,
              1.071493665534058: 1.2537570955293091,
              1.084839741990298: 1.2713593675287171,
              1.098185818446537: 1.287663051265482,
              1.111531894902777: 1.302059680820236,
              1.124877971359017: 1.3138860174369351,
              1.138224047815257: 1.322524454451088,
              1.151570124271497: 1.327489716355713,
              1.164916200727736: 1.328487536238458,
              1.178262277183976: 1.325437281802569,
              1.191608353640216: 1.3184577191523261,
              1.204954430096456: 1.30782195377571,
              1.218300506552695: 1.293892953966347,
              1.231646583008935: 1.277054084857355,
              1.244992659465175: 1.2576492395882881,
              1.258338735921415: 1.235944376648373,
              1.271684812377655: 1.21211704446253,
              1.285030888833894: 1.186273851205379,
              1.298376965290134: 1.158489292958627,
              1.311723041746374: 1.128854451858925,
              1.325069118202614: 1.097522052180924,
              1.338415194658853: 1.06473574006384,
              1.351761271115093: 1.030835852657493,
              1.3651073475713331: 0.9962401644186181,
              1.378453424027573: 0.9614044626951721,
              1.391799500483812: 0.926772645064811,
              1.405145576940052: 0.892728169616057,
              1.4184916533962921: 0.85955774325485,
              1.431837729852532: 0.827434561314174,
              1.445183806308771: 0.7964233150848811,
              1.458529882765011: 0.766503945804473,
              1.4718759592212511: 0.7376070210092751,
              1.485222035677491: 0.709651508757119,
              1.49856811213373: 0.682575932043565,
              1.51191418858997: 0.6563561499243681,
              1.5252602650462102: 0.6310066566485071,
              1.53860634150245: 0.606566404723337,
              1.551952417958689: 0.583073804763249,
              1.565298494414929: 0.560537956621741,
              1.5786445708711692: 0.5389138494723681,
              1.591990647327409: 0.518088150446538,
              1.605336723783649: 0.49787960205240206,
              1.618682800239888: 0.47805461782487,
              1.6320288766961282: 0.45835523032166003,
              1.645374953152368: 0.438533904487766,
              1.658721029608608: 0.418388449613587,
              1.672067106064847: 0.39779054012632203,
              1.6854131825210872: 0.37670298534287006,
              1.698759258977327: 0.355183361335675,
              1.712105335433567: 0.33337429655195405,
              1.7254514118898072: 0.31148301246356,
              1.7387974883460462: 0.289754282696558,
              1.752143564802286: 0.26844164032043605,
              1.765489641258526: 0.24778146393398903,
              1.778835717714766: 0.22797363589473502,
              1.7921817941710052: 0.209170959601812,
              1.805527870627245: 0.19147766091169302,
              1.818873947083485: 0.174955377405997,
              1.832220023539725: 0.15963345119729402,
              1.8455660999959642: 0.145519515991962,
              1.858912176452204: 0.132606632521404,
              1.872258252908444: 0.12087463098340201,
              1.885604329364684: 0.110285541550018,
              1.8989504058209241: 0.10077537352027201,
              1.912296482277163: 0.092246237959154,
              1.925642558733403: 0.084563243068905,
              1.938988635189643: 0.077559483710723,
              1.9523347116458831: 0.071050088252864,
              1.965680788102122: 0.064853422263124,
              1.979026864558362: 0.058815119608843,
              1.992372941014602: 0.052829418329180006,
              2.005719017470842: 0.046852703856537004,
              2.019065093927081: 0.040906050475952006,
              2.032411170383321: 0.035066287451267004,
              2.045757246839561: 0.029447855982330004,
              2.059103323295801: 0.024179689521310003,
              2.07244939975204: 0.019382072872584002,
              2.08579547620828: 0.015147864269661,
              2.09914155266452: 0.011530927552074001,
              2.11248762912076: 0.008542668542574001,
              2.125833705577: 0.006155769937963001,
              2.139179782033239: 0.004312989457569001,
              2.152525858489479: 0.0029384016605190004,
              2.165871934945719: 0.00194866203626,
              2.179218011401959: 0.001262527640318,
              2.192564087858198: 0.000807699169225,
              2.205910164314438: 0.000524808414902,
              2.219256240770678: 0.00036890824957200004,
              2.232602317226918: 0.00030908090774,
              2.245948393683158: 0.00032680117892700005,
              2.259294470139397: 0.00041356050710300004,
              2.272640546595637: 0.000568074942616,
              2.285986623051877: 0.0007932481542960001,
              2.299332699508117: 0.0010929921952730001,
              2.312678775964356: 0.001469037079406,
              2.326024852420596: 0.0019179623076280002,
              2.339370928876836: 0.002428807427569,
              2.352717005333076: 0.0029816984371770004,
              2.3660630817893162: 0.0035479018431240003,
              2.379409158245555: 0.004091555044782,
              2.392755234701795: 0.004573030973972001,
              2.406101311158035: 0.0049535358484100005,
              2.419447387614275: 0.005200207319729,
              2.4327934640705142: 0.005290780549517001,
              2.446139540526754: 0.005216898960013001,
              2.459485616982994: 0.004985384223937001,
              2.4728316934392343: 0.004617196498785001,
              2.4861777698954732: 0.0041443038229950005,
              2.499523846351713: 0.0036051083269970003,
              2.512869922807953: 0.00303933488224,
              2.526215999264193: 0.002483317748694,
              2.539562075720432: 0.001966434717304,
              2.552908152176672: 0.0015091078424890002,
              2.566254228632912: 0.0011224177381610001,
              2.5796003050891523: 0.00080906286736,
              2.5929463815453913: 0.000565201778277,
              2.606292458001631: 0.00038266458629700005,
              2.619638534457871: 0.00025108832955800004,
              2.632984610914111: 0.000159671823459,
              2.64633068737035: 9.840644546782398e-05,
              2.65967676382659: 5.877766203289085e-05,
            },
            geo_level_type: 'Denmark',
            halfnorm: {
              0.44266030109435706: 0.002170169314611,
              0.456071951842256: 0.0036187070406000004,
              0.46948360259015404: 0.005891145653163001,
              0.48289525333805206: 0.009364659807435,
              0.496306904085951: 0.014537744526065,
              0.5097185548338491: 0.022043994442733,
              0.523130205581748: 0.03265555296072,
              0.536541856329646: 0.047270883663946,
              0.5499535070775441: 0.066882036073013,
              0.563365157825443: 0.09251835279893901,
              0.576776808573341: 0.125166675096634,
              0.5901884593212391: 0.16567229646616602,
              0.603600110069138: 0.21462955632656902,
              0.617011760817036: 0.272275103503203,
              0.6304234115649351: 0.33839939998602003,
              0.643835062312833: 0.412292030655156,
              0.657246713060731: 0.49273333868024505,
              0.6706583638086301: 0.5780390110036361,
              0.684070014556528: 0.666156436731138,
              0.697481665304427: 0.7548034850873231,
              0.7108933160523251: 0.8416335506653071,
              0.724304966800223: 0.924406788062575,
              0.737716617548122: 1.001147213862345,
              0.7511282682960201: 1.070268673937855,
              0.764539919043918: 1.130658562779189,
              0.777951569791817: 1.181715089358316,
              0.7913632205397151: 1.223340196983446,
              0.804774871287614: 1.255894767182218,
              0.818186522035512: 1.280125000100318,
              0.8315981727834101: 1.297069154394535,
              0.845009823531309: 1.307952959425585,
              0.8584214742792071: 1.314080921870202,
              0.8718331250271061: 1.316730114395027,
              0.885244775775004: 1.317053027600537,
              0.8986564265229021: 1.315996343932032,
              0.9120680772708011: 1.314242397548901,
              0.925479728018699: 1.312178971531453,
              0.9388913787665971: 1.309900625001523,
              0.9523030295144961: 1.307241101385983,
              0.965714680262394: 1.303832184301445,
              0.9791263310102931: 1.299180562425489,
              0.9925379817581911: 1.292751756071677,
              1.00594963250609: 1.284049571996851,
              1.019361283253988: 1.272681038117848,
              1.032772934001886: 1.258399962342565,
              1.046184584749784: 1.241126397608542,
              1.0595962354976831: 1.220943438093845,
              1.073007886245581: 1.198076053928245,
              1.08641953699348: 1.172858516558427,
              1.099831187741378: 1.145697204975542,
              1.113242838489276: 1.117034454135455,
              1.126654489237175: 1.087317152775801,
              1.140066139985073: 1.056971683550104,
              1.153477790732972: 1.026385116416692,
              1.16688944148087: 0.9958916781879121,
              1.180301092228768: 0.965763480180098,
              1.193712742976667: 0.93620505738623,
              1.207124393724565: 0.9073520416849941,
              1.220536044472464: 0.879274819292824,
              1.233947695220362: 0.851987997861216,
              1.24735934596826: 0.825465846982796,
              1.2607709967161589: 0.799662736178526,
              1.274182647464057: 0.774536312901537,
              1.287594298211956: 0.7500701342586771,
              1.301005948959854: 0.726292015261595,
              1.314417599707752: 0.7032846465168281,
              1.327829250455651: 0.681186034033425,
              1.341240901203549: 0.660178827201481,
              1.354652551951447: 0.640469337682937,
              1.3680642026993461: 0.6222587076357771,
              1.381475853447244: 0.605710004330099,
              1.394887504195142: 0.5909158229291961,
              1.408299154943041: 0.5778711746324721,
              1.421710805690939: 0.566455997653175,
              1.435122456438838: 0.5564305866993641,
              1.448534107186736: 0.547445684170978,
              1.461945757934634: 0.539067070046594,
              1.475357408682533: 0.530812453421817,
              1.488769059430431: 0.522196589603503,
              1.50218071017833: 0.512779126470933,
              1.515592360926228: 0.502208992349072,
              1.5290040116741261: 0.49025934910945906,
              1.5424156624220249: 0.47684827752789805,
              1.5558273131699232: 0.462042299310702,
              1.569238963917822: 0.44604228240966304,
              1.58265061466572: 0.42915383203398505,
              1.596062265413618: 0.41174651792328903,
              1.609473916161517: 0.39420785564974703,
              1.622885566909415: 0.376898587636156,
              1.636297217657313: 0.36011539897536804,
              1.6497088684052121: 0.344065829087475,
              1.66312051915311: 0.32885803841287203,
              1.6765321699010092: 0.314505617313452,
              1.689943820648907: 0.30094520420402704,
              1.703355471396805: 0.28806272901204605,
              1.716767122144704: 0.275722956577032,
              1.730178772892602: 0.263796870456938,
              1.7435904236405002: 0.252182321073945,
              1.757002074388399: 0.240815073074112,
              1.770413725136297: 0.22966956336763303,
              1.783825375884196: 0.21875086049984102,
              1.797237026632094: 0.20808103410664402,
              1.8106486773799921: 0.19768404386122002,
              1.824060328127891: 0.18757317789072603,
              1.8374719788757892: 0.177744081515036,
              1.850883629623688: 0.168174800489405,
              1.8642952803715862: 0.158832435613421,
              1.877706931119484: 0.14968440857259602,
              1.891118581867383: 0.140711325487621,
              1.904530232615281: 0.13191817941682402,
              1.917941883363179: 0.12334114215555302,
              1.9313535341110781: 0.11504827191024601,
              1.944765184858976: 0.107133812555498,
              1.9581768356068752: 0.099707064670632,
              1.971588486354773: 0.09287780363551501,
              1.985000137102671: 0.08674074390478001,
              1.99841178785057: 0.08136155930764001,
              2.011823438598468: 0.076766532321535,
              2.025235089346367: 0.072937161369944,
              2.038646740094265: 0.06981017998502101,
              2.052058390842163: 0.067282607501745,
              2.065470041590062: 0.065220796074148,
              2.07888169233796: 0.06347204725746601,
              2.092293343085859: 0.061877264697257,
              2.105704993833757: 0.060283250531766,
              2.119116644581655: 0.058553561602139,
              2.132528295329554: 0.05657721769227501,
              2.145939946077452: 0.05427490549501401,
              2.159351596825351: 0.051602586942563006,
              2.172763247573249: 0.048552579700344003,
              2.186174898321147: 0.045152252312451006,
              2.199586549069046: 0.041460513378714,
              2.212998199816944: 0.037562322885034,
              2.226409850564843: 0.033561545847451,
              2.23982150131274: 0.029572604732888003,
              2.253233152060639: 0.025711538701157003,
              2.266644802808537: 0.022087197787213003,
              2.280056453556436: 0.018793342404179,
              2.293468104304335: 0.01590235430458,
              2.306879755052232: 0.013461093221282002,
              2.320291405800131: 0.011489180190699002,
              2.3337030565480292: 0.009979699339084,
              2.347114707295928: 0.008902035799694001,
              2.360526358043826: 0.008206352595394,
              2.373938008791724: 0.007829082286724,
              2.387349659539623: 0.007698779539427001,
              2.400761310287521: 0.007741740589575,
              2.41417296103542: 0.007886923703908,
              2.427584611783318: 0.008069872002356001,
              2.440996262531216: 0.008235514620830001,
              2.4544079132791152: 0.008339874757150001,
              2.467819564027013: 0.008350821486562,
              2.481231214774912: 0.00824805541326,
              2.49464286552281: 0.008022518706583,
              2.508054516270708: 0.007675382865931,
              2.521466167018607: 0.007216715803996,
              2.534877817766505: 0.006663887628350001,
              2.548289468514403: 0.006039759114343001,
              2.561701119262302: 0.00537071311951,
              2.5751127700102: 0.0046846281443640005,
              2.588524420758099: 0.004008935802902,
              2.601936071505997: 0.003368928144835,
              2.615347722253895: 0.0027864698876330003,
              2.628759373001794: 0.002279219438588,
              2.6421710237496923: 0.0018603793858070002,
              2.655582674497591: 0.0015389012073600001,
              2.668994325245489: 0.0013199847799180001,
              2.682405975993387: 0.00120566348851,
              2.695817626741286: 0.001195264984907,
              2.709229277489184: 0.001285588851643,
              2.722640928237083: 0.001470736085616,
              2.73605257898498: 0.001741641408332,
              2.749464229732879: 0.002085471466599,
              2.7628758804807783: 0.0024851324436960002,
              2.776287531228676: 0.0029191567400810004,
              2.789699181976575: 0.0033621979377950004,
              2.803110832724472: 0.0037862585244850004,
              2.816522483472371: 0.004162623996091,
              2.82993413422027: 0.004464311568885,
              2.843345784968168: 0.0046687003733860005,
              2.856757435716066: 0.004759928893608,
              2.870169086463964: 0.004730648570907,
              2.883580737211863: 0.004582814299384,
              2.896992387959762: 0.004327355374103,
              2.91040403870766: 0.003982768524421,
              2.923815689455558: 0.003572863259954,
              2.937227340203456: 0.00312402716848,
              2.9506389909513553: 0.0026624372621640003,
              2.964050641699254: 0.002211615726959,
              2.977462292447152: 0.001790628201923,
              2.99087394319505: 0.001413079218453,
              3.004285593942948: 0.001086908217848,
              3.017697244690847: 0.000814862768936,
              3.031108895438746: 0.000595444517698,
              3.044520546186644: 0.000424095183742,
              3.057932196934542: 0.000294408665567,
              3.07134384768244: 0.00019920628839700002,
              3.084755498430339: 0.00013137741251000002,
              3.098167149178238: 8.445076134848489e-05,
              3.111578799926136: 5.2911690674297304e-05,
            },
            triangular: {
              0.17630994285368: 0.000260242269059,
              0.18403184821736202: 0.00036824635481400004,
              0.19175375358104402: 0.0005157156800260001,
              0.19947565894472602: 0.000714869534609,
              0.20719756430840802: 0.0009808956356430001,
              0.21491946967209002: 0.0013324013959650001,
              0.22264137503577203: 0.001791851789243,
              0.23036328039945403: 0.0023859650877820003,
              0.23808518576313603: 0.0031460323284570003,
              0.245807091126818: 0.004108122125644,
              0.25352899649050004: 0.0053131300833100005,
              0.26125090185418204: 0.006806632230146,
              0.26897280721786404: 0.008638505186315001,
              0.27669471258154604: 0.010862282566173,
              0.28441661794522805: 0.013534227571711,
              0.29213852330891: 0.016712115663647,
              0.29986042867259105: 0.020453738085104003,
              0.307582334036273: 0.024815155976723003,
              0.315304239399955: 0.029848754666115,
              0.323026144763637: 0.035601167005640005,
              0.330748050127319: 0.042111151813533004,
              0.338469955491001: 0.049407527001104005,
              0.346191860854683: 0.057507265462360006,
              0.353913766218365: 0.06641386418488701,
              0.361635671582047: 0.07611609265829501,
              0.369357576945729: 0.086587215358148,
              0.377079482309411: 0.09778476525292501,
              0.384801387673093: 0.10965092181625401,
              0.392523293036775: 0.122113519261204,
              0.400245198400457: 0.135087680302787,
              0.407967103764139: 0.14847803952476002,
              0.41568900912782103: 0.162181490212729,
              0.42341091449150303: 0.17609036100914602,
              0.43113281985518503: 0.19009590536744,
              0.43885472521886704: 0.20409196859745402,
              0.44657663058254904: 0.21797868497927203,
              0.45429853594623104: 0.231666051288363,
              0.46202044130991304: 0.24507722313450603,
              0.46974234667359505: 0.258151386585526,
              0.47746425203727705: 0.27084606934987704,
              0.48518615740095905: 0.283138773055823,
              0.49290806276464105: 0.29502783066383104,
              0.500629968128323: 0.306532420591882,
              0.5083518734920051: 0.317691701482725,
              0.5160737788556871: 0.32856306827181403,
              0.5237956842193691: 0.339219570535617,
              0.5315175895830511: 0.34974657668947,
              0.5392394949467331: 0.360237810486204,
              0.5469614003104151: 0.370790926792032,
              0.5546833056740971: 0.381502828573394,
              0.5624052110377791: 0.39246495290591105,
              0.5701271164014611: 0.403758767196689,
              0.5778490217651431: 0.415451714858364,
              0.5855709271288251: 0.42759383064322404,
              0.5932928324925071: 0.440215209531972,
              0.6010147378561891: 0.453324461100284,
              0.6087366432198701: 0.46690821718568704,
              0.6164585485835521: 0.48093168967945604,
              0.6241804539472341: 0.495340203838017,
              0.6319023593109161: 0.510061567644009,
              0.6396242646745981: 0.5250090861359661,
              0.6473461700382801: 0.5400849967680831,
              0.6550680754019621: 0.5551840912921611,
              0.6627899807656441: 0.5701973023768271,
              0.6705118861293261: 0.5850150674127721,
              0.678233791493008: 0.5995303332356541,
              0.68595569685669: 0.613641127158404,
              0.693677602220372: 0.6272526836450371,
              0.701399507584054: 0.6402791736661251,
              0.709121412947736: 0.6526451274328221,
              0.716843318311418: 0.664286664752197,
              0.7245652236751: 0.6751526472657421,
              0.732287129038782: 0.685205843127706,
              0.740009034402464: 0.694424150447694,
              0.747730939766146: 0.702801867383759,
              0.755452845129828: 0.7103509328615291,
              0.76317475049351: 0.717102002569813,
              0.770896655857192: 0.7231051801807801,
              0.778618561220874: 0.7284302022422701,
              0.786340466584556: 0.733165882677651,
              0.794062371948238: 0.737418661267459,
              0.80178427731192: 0.7413101674649031,
              0.809506182675602: 0.744973799678574,
              0.817228088039284: 0.7485504203797151,
              0.824949993402966: 0.7521833663624671,
              0.832671898766648: 0.756013057902528,
              0.84039380413033: 0.7601715484486701,
              0.848115709494012: 0.764777379038719,
              0.855837614857694: 0.7699310846802121,
              0.863559520221376: 0.775711644738279,
              0.8712814255850581: 0.782174082632472,
              0.8790033309487401: 0.789348313153004,
              0.8867252363124221: 0.797239222743454,
              0.8944471416761041: 0.805827864297997,
              0.9021690470397861: 0.815073567060827,
              0.9098909524034681: 0.8249167141641751,
              0.9176128577671491: 0.835281930062683,
              0.9253347631308311: 0.8460814464812261,
              0.9330566684945131: 0.8572184715278061,
              0.9407785738581951: 0.868590460660663,
              0.9485004792218771: 0.8800922657551871,
              0.9562223845855591: 0.8916192046704251,
              0.9639442899492411: 0.9030701355757841,
              0.9716661953129231: 0.914350629163262,
              0.9793881006766051: 0.9253763047216681,
              0.9871100060402871: 0.9360763361465291,
              0.9948319114039691: 0.9463970504293631,
              1.002553816767651: 0.9563054476600861,
              1.010275722131333: 0.9657923841865591,
              1.017997627495015: 0.9748750953941091,
              1.025719532858697: 0.9835987051963541,
              1.033441438222379: 0.992036384769386,
              1.041163343586061: 1.000287886288694,
              1.048885248949743: 1.008476284840792,
              1.056607154313425: 1.016742903503242,
              1.0643290596771071: 1.025240558136191,
              1.072050965040789: 1.034125422053418,
              1.079772870404471: 1.04354795812151,
              1.087494775768153: 1.053643480323194,
              1.0952166811318351: 1.06452297547966,
              1.102938586495517: 1.076264830916806,
              1.110660491859199: 1.088908073579119,
              1.118382397222881: 1.102447634522809,
              1.1261043025865631: 1.116832019026155,
              1.133826207950245: 1.131963599626334,
              1.141548113313927: 1.147701572116232,
              1.149270018677609: 1.16386743800789,
              1.156991924041291: 1.180252714879716,
              1.164713829404973: 1.196628439418791,
              1.172435734768655: 1.212755924523219,
              1.180157640132336: 1.228398165652581,
              1.187879545496019: 1.243331263529266,
              1.1956014508597: 1.2573552384487021,
              1.203323356223382: 1.27030365210929,
              1.211045261587064: 1.282051521159438,
              1.218767166950746: 1.292521097221633,
              1.226489072314428: 1.301685195540335,
              1.23421097767811: 1.309567873241041,
              1.241932883041792: 1.316242383197289,
              1.249654788405474: 1.3218264554226131,
              1.257376693769156: 1.32647507949169,
              1.265098599132838: 1.3303710736396321,
              1.27282050449652: 1.333713824201507,
              1.280542409860202: 1.33670665908268,
              1.288264315223884: 1.339543378427528,
              1.295986220587566: 1.342394503646213,
              1.303708125951248: 1.345393823275653,
              1.31143003131493: 1.348625813114148,
              1.319151936678612: 1.352114491925137,
              1.326873842042294: 1.3558142460414762,
              1.334595747405976: 1.3596031187361262,
              1.342317652769658: 1.363279013697267,
              1.35003955813334: 1.366559204354874,
              1.357761463497022: 1.369083467712888,
              1.365483368860704: 1.370421066558053,
              1.373205274224386: 1.370081680865228,
              1.380927179588068: 1.367530232800412,
              1.38864908495175: 1.36220535838681,
              1.396370990315432: 1.353541056517799,
              1.404092895679114: 1.340990803115478,
              1.411814801042796: 1.324053172310027,
              1.419536706406478: 1.30229778097021,
              1.42725861177016: 1.275390194886441,
              1.4349805171338421: 1.243114331961406,
              1.442702422497524: 1.205390893956808,
              1.4504243278612061: 1.162290470221878,
              1.458146233224888: 1.11404018999662,
              1.46586813858857: 1.061023146789844,
              1.473590043952251: 1.003770257715107,
              1.4813119493159341: 0.942944718894503,
              1.4890338546796151: 0.8793197322831541,
              1.496755760043297: 0.8137506621531191,
              1.504477665406979: 0.747143184592151,
              1.512199570770661: 0.6804192808754941,
              1.5199214761343431: 0.6144830670772571,
              1.527643381498025: 0.550188434376175,
              1.535365286861707: 0.48831030028857203,
              1.543087192225389: 0.42952095964782705,
              1.5508090975890712: 0.37437260813459705,
              1.558531002952753: 0.323286632473177,
              1.566252908316435: 0.276549766187628,
              1.573974813680117: 0.23431674304149502,
              1.5816967190437992: 0.19661868089994602,
              1.589418624407481: 0.163376125830333,
              1.597140529771163: 0.13441549671278802,
              1.604862435134845: 0.10948759858463701,
              1.6125843404985272: 0.088286910653308,
              1.620306245862209: 0.07047048502142801,
              1.628028151225891: 0.055675490772365006,
              1.635750056589573: 0.043534678153033,
              1.6434719619532552: 0.033689292342841,
              1.651193867316937: 0.025799211860433,
              1.658915772680619: 0.019550304135226,
              1.666637678044301: 0.014659167299099001,
              1.6743595834079832: 0.010875556219700001,
              1.682081488771665: 0.007982871293599,
              1.689803394135347: 0.005797124278180001,
              1.697525299499029: 0.004164793476115001,
              1.7052472048627112: 0.002959949752046,
              1.712969110226393: 0.0020809845481180003,
            },
          },
          {
            actual: {
              '-0.041744978477915': 0.0037079623029640002,
              '-0.030316556442961003': 0.004266275879592001,
              '-0.018888134408007': 0.0048982314366020005,
              '-0.007459712373053': 0.005611870630944001,
              0.0039687096619: 0.006415866044628,
              0.015397131696854001: 0.007319536452261001,
              0.026825553731807002: 0.008332857434196001,
              0.038253975766761004: 0.009466466598019,
              0.049682397801715: 0.010731662658870001,
              0.061110819836669: 0.012140397628609,
              0.072539241871623: 0.013705261376615001,
              0.083967663906577: 0.01543945785241,
              0.09539608594153101: 0.017356772303633,
              0.106824507976485: 0.019471528883009,
              0.11825293001143901: 0.021798538115796,
              0.129681352046393: 0.024353033794985,
              0.141109774081347: 0.027150598985543003,
              0.152538196116301: 0.030207080950708003,
              0.16396661815125502: 0.033538494962136005,
              0.17539504018620902: 0.037160917120311006,
              0.186823462221162: 0.041090366490255006,
              0.198251884256116: 0.045342677048213,
              0.20968030629107: 0.049933360134765,
              0.22110872832602402: 0.05487745831578,
              0.23253715036097802: 0.060189391761207,
              0.24396557239593203: 0.065882798458884,
              0.255393994430886: 0.07197036978240201,
              0.26682241646584004: 0.078463683123887,
              0.278250838500794: 0.085373033480098,
              0.289679260535748: 0.09270726603875201,
              0.30110768257070203: 0.100473611947144,
              0.312536104605656: 0.10867752955264101,
              0.32396452664061004: 0.11732255348060001,
              0.335392948675563: 0.12641015395622102,
              0.34682137071051705: 0.13593960877994402,
              0.35824979274547103: 0.145907890328931,
              0.369678214780425: 0.15630956987854702,
              0.38110663681537904: 0.16713674141686002,
              0.392535058850333: 0.17837896696230102,
              0.40396348088528705: 0.19002324519095,
              0.41539190292024103: 0.20205400493743603,
              0.426820324955195: 0.21445312485530202,
              0.43824874699014904: 0.227199980212643,
              0.449677169025103: 0.24027151746157202,
              0.46110559106005705: 0.25364235686096304,
              0.47253401309501103: 0.26728492305693,
              0.48396243512996406: 0.281169603140878,
              0.49539085716491804: 0.295264931317531,
              0.506819279199872: 0.309537798931719,
              0.518247701234826: 0.323953688229747,
              0.5296761232697801: 0.338476927875249,
              0.5411045453047341: 0.35307096790680603,
              0.552532967339688: 0.36769867152074404,
              0.563961389374642: 0.38232262079238705,
              0.575389811409596: 0.396905433216761,
              0.5868182334445501: 0.411410085758514,
              0.5982466554795041: 0.42580024295303404,
              0.609675077514458: 0.44004058549770103,
              0.621103499549412: 0.45409713571437604,
              0.632531921584366: 0.46793757625108706,
              0.64396034361932: 0.48153155842110706,
              0.6553887656542731: 0.49485099664916204,
              0.666817187689227: 0.507870345604689,
              0.678245609724181: 0.520566856747693,
              0.6896740317591351: 0.532920811190398,
              0.7011024537940891: 0.5449157259838281,
              0.7125308758290431: 0.556538531169112,
              0.723959297863997: 0.567779715185025,
              0.735387719898951: 0.5786334364929351,
              0.7468161419339051: 0.589097599564903,
              0.7582445639688591: 0.5991738936777891,
              0.7696729860038131: 0.608867793263963,
              0.781101408038767: 0.6181885188860721,
              0.792529830073721: 0.6271489582285871,
              0.8039582521086741: 0.6357655468317981,
              0.8153866741436281: 0.644058108634832,
              0.8268150961785821: 0.6520496567429781,
              0.838243518213536: 0.659766155191656,
              0.84967194024849: 0.6672362428449391,
              0.8611003622834441: 0.674490920940623,
              0.8725287843183981: 0.681563206176313,
              0.8839572063533521: 0.688487751620754,
              0.895385628388306: 0.695300438130387,
              0.90681405042326: 0.7020379393503481,
              0.9182424724582141: 0.7087372637786541,
              0.9296708944931681: 0.715435277767961,
              0.9410993165281221: 0.722168213725735,
              0.952527738563075: 0.728971168144898,
              0.963956160598029: 0.7358775944459091,
              0.9753845826329831: 0.7429187959300101,
              0.9868130046679371: 0.7501234244236681,
              0.9982414267028911: 0.75751699042727,
              1.009669848737845: 0.765121390757982,
              1.021098270772799: 0.772954459788553,
              1.032526692807753: 0.7810295504224031,
              1.043955114842707: 0.7893551509028891,
              1.055383536877661: 0.79793454342457,
              1.066811958912615: 0.8067655102912541,
              1.078240380947569: 0.815840093045869,
              1.089668802982523: 0.8251444095787811,
              1.101097225017477: 0.834658533704098,
              1.112525647052431: 0.8443564410801061,
              1.123954069087384: 0.854206024644488,
              1.135382491122338: 0.8641691819443561,
              1.146810913157292: 0.8742019758742071,
              1.158239335192246: 0.88425486940283,
              1.1696677572272: 0.894273033886216,
              1.181096179262154: 0.9041967295423651,
              1.192524601297108: 0.9139617556221911,
              1.2039530233320621: 0.9234999667659751,
              1.215381445367016: 0.932739851005529,
              1.22680986740197: 0.9416071638773271,
              1.238238289436924: 0.9500256121702471,
              1.249666711471878: 0.9579175799617331,
              1.261095133506832: 0.9652048888159571,
              1.272523555541786: 0.9718095833432121,
              1.283951977576739: 0.9776547327663591,
              1.295380399611693: 0.9826652387202771,
              1.306808821646647: 0.9867686392346241,
              1.318237243681601: 0.9898958987266421,
              1.329665665716555: 0.991982173864379,
              1.341094087751509: 0.9929675453537201,
              1.352522509786463: 0.9927977060541421,
              1.363950931821417: 0.9914245963342061,
              1.375379353856371: 0.9888069782315051,
              1.386807775891325: 0.9849109407732641,
              1.398236197926279: 0.9797103297304771,
              1.409664619961233: 0.9731870961053141,
              1.421093041996187: 0.9653315587714071,
              1.4325214640311401: 0.9561425778803541,
              1.443949886066094: 0.9456276368950831,
              1.455378308101048: 0.9338028323899451,
              1.466806730136002: 0.9206927720465221,
              1.478235152170956: 0.90633038255106,
              1.4896635742059101: 0.89075663034201,
              1.5010919962408642: 0.8740201593436171,
              1.512520418275818: 0.85617685093352,
              1.523948840310772: 0.8372893124109221,
              1.535377262345726: 0.8174263011403511,
              1.5468056843806801: 0.7966620923303831,
              1.5582341064156342: 0.775075799055231,
              1.569662528450587: 0.752750653630876,
              1.581090950485542: 0.7297732598103751,
              1.5925193725204951: 0.706232825462019,
              1.603947794555449: 0.6822203854388711,
              1.615376216590403: 0.657828024241718,
              1.626804638625357: 0.633148107824972,
              1.638233060660311: 0.608272533504821,
              1.6496614826952651: 0.5832920064112831,
              1.661089904730219: 0.5582953502935231,
              1.672518326765173: 0.5333688597550741,
              1.683946748800127: 0.508595700178467,
              1.695375170835081: 0.48405536071404204,
              1.7068035928700351: 0.459823164773041,
              1.718232014904989: 0.43596984149844903,
              1.729660436939942: 0.41256116070630905,
              1.741088858974896: 0.389657632812819,
              1.7525172810098502: 0.36731427430517705,
              1.763945703044804: 0.345580438392366,
              1.7753741250797581: 0.32449970960017505,
              1.786802547114712: 0.30410986026532,
              1.798230969149666: 0.284442866147395,
              1.8096593911846202: 0.265524977723445,
              1.821087813219574: 0.247376843165162,
              1.8325162352545281: 0.23001367852777602,
              1.843944657289482: 0.213445480305494,
              1.855373079324436: 0.19767727523157602,
              1.8668015013593902: 0.18270940202069802,
              1.878229923394344: 0.168537819664193,
              1.8896583454292981: 0.155154436890654,
              1.901086767464251: 0.142547457489223,
              1.9125151894992052: 0.130701736353625,
              1.923943611534159: 0.11959914133355601,
              1.935372033569113: 0.109218916267504,
              1.946800455604067: 0.099538040908326,
              1.958228877639021: 0.090531583830147,
              1.9696572996739752: 0.082173044812946,
              1.981085721708929: 0.07443468362996801,
              1.992514143743883: 0.067287832603812,
              2.003942565778837: 0.060703190741031005,
              2.015370987813791: 0.05465109769446801,
              2.026799409848745: 0.04910178623005,
              2.038227831883699: 0.044025612284172004,
              2.049656253918653: 0.039393262083534004,
              2.061084675953606: 0.035175936157052,
              2.07251309798856: 0.031345510395531004,
              2.083941520023514: 0.027874674606758004,
              2.095369942058468: 0.024737049269690004,
              2.106798364093422: 0.021907281410781,
              2.118226786128376: 0.019361120708105,
              2.12965520816333: 0.017075477075427,
              2.141083630198284: 0.015028461090101001,
              2.152512052233238: 0.013199408707424,
              2.163940474268192: 0.011568891752025,
              2.175368896303146: 0.010118715696775001,
              2.186797318338099: 0.008831906234207001,
              2.198225740373054: 0.007692686117715001,
              2.209654162408008: 0.006686443702805,
              2.221082584442961: 0.005799694555585,
              2.232511006477915: 0.00502003741946,
            },
            gamma: {
              0.0038075490348770005: 6.0135840816284164e-05,
              0.017153625491116: 0.00010123063870800001,
              0.030499701947356003: 0.00016536842222300002,
              0.043845778403596006: 0.00026223531470000004,
              0.057191854859836: 0.00040382683230300004,
              0.07053793131607601: 0.000604184902876,
              0.083884007772315: 0.0008787507299200001,
              0.097230084228555: 0.001243328952395,
              0.110576160684795: 0.0017127548353830001,
              0.12392223714103501: 0.002299458973983,
              0.137268313597274: 0.003012198930709,
              0.150614390053514: 0.003855237979612,
              0.16396046650975402: 0.004828174420384,
              0.17730654296599402: 0.005926465576462,
              0.19065261942223302: 0.007142488720285001,
              0.20399869587847302: 0.008466805242366001,
              0.21734477233471303: 0.009889219062808001,
              0.23069084879095303: 0.011399295587478,
              0.24403692524719203: 0.012986231973247001,
              0.25738300170343203: 0.014638281038266001,
              0.270729078159672: 0.016342223356968,
              0.28407515461591204: 0.018083541695775,
              0.297421231072152: 0.019847903347787,
              0.31076730752839105: 0.021624292406864,
              0.324113383984631: 0.023409721449542002,
              0.33745946044087105: 0.025215003209693002,
              0.350805536897111: 0.027070694980368,
              0.36415161335335: 0.029032125454668004,
              0.37749768980959003: 0.031182409121705,
              0.39084376626583: 0.033632539738149,
              0.40418984272207004: 0.036518007133949,
              0.417535919178309: 0.039991877204005,
              0.43088199563454904: 0.044214887949393,
              0.444228072090789: 0.049343791573832,
              0.45757414854702905: 0.055519801163936004,
              0.470920225003269: 0.062859398363696,
              0.48426630145950805: 0.071449708838902,
              0.497612377915748: 0.08134998413097501,
              0.5109584543719881: 0.09259941780005,
              0.524304530828228: 0.105229770370135,
              0.537650607284467: 0.119279510218252,
              0.5509966837407071: 0.134804963202762,
              0.5643427601969471: 0.151883835715587,
              0.577688836653187: 0.17060773283250802,
              0.591034913109426: 0.191062845219054,
              0.604380989565666: 0.21330130437090103,
              0.6177270660219061: 0.23730895507343402,
              0.631073142478146: 0.262977511330556,
              0.644419218934385: 0.290089469313532,
              0.657765295390625: 0.31832239761887204,
              0.6711113718468651: 0.34727552705500003,
              0.6844574483031051: 0.376516682940496,
              0.697803524759345: 0.40564268072970905,
              0.711149601215584: 0.43434257037039903,
              0.7244956776718241: 0.46245156675007704,
              0.7378417541280641: 0.489984637691085,
              0.751187830584304: 0.517142366679411,
              0.764533907040543: 0.5442870386496821,
              0.7778799834967831: 0.571892627001886,
              0.7912260599530231: 0.600477089316921,
              0.8045721364092631: 0.6305280083112901,
              0.817918212865502: 0.6624326887725811,
              0.831264289321742: 0.696421674852386,
              0.8446103657779821: 0.7325312705011681,
              0.8579564422342221: 0.770587270272025,
              0.871302518690461: 0.8102097254960401,
              0.884648595146701: 0.8508375058173341,
              0.8979946716029411: 0.8917712154331501,
              0.9113407480591811: 0.9322327175500691,
              0.9246868245154211: 0.9714381612367771,
              0.93803290097166: 1.008678640313408,
              0.9513789774279001: 1.043399019479119,
              0.9647250538841401: 1.075262437349604,
              0.9780711303403801: 1.104187228330247,
              0.991417206796619: 1.130345720580659,
              1.004763283252859: 1.154120682562261,
              1.018109359709099: 1.176023878719228,
              1.031455436165339: 1.196589990834955,
              1.044801512621578: 1.216265508616799,
              1.058147589077818: 1.235314133464483,
              1.071493665534058: 1.2537570955293091,
              1.084839741990298: 1.2713593675287171,
              1.098185818446537: 1.287663051265482,
              1.111531894902777: 1.302059680820236,
              1.124877971359017: 1.3138860174369351,
              1.138224047815257: 1.322524454451088,
              1.151570124271497: 1.327489716355713,
              1.164916200727736: 1.328487536238458,
              1.178262277183976: 1.325437281802569,
              1.191608353640216: 1.3184577191523261,
              1.204954430096456: 1.30782195377571,
              1.218300506552695: 1.293892953966347,
              1.231646583008935: 1.277054084857355,
              1.244992659465175: 1.2576492395882881,
              1.258338735921415: 1.235944376648373,
              1.271684812377655: 1.21211704446253,
              1.285030888833894: 1.186273851205379,
              1.298376965290134: 1.158489292958627,
              1.311723041746374: 1.128854451858925,
              1.325069118202614: 1.097522052180924,
              1.338415194658853: 1.06473574006384,
              1.351761271115093: 1.030835852657493,
              1.3651073475713331: 0.9962401644186181,
              1.378453424027573: 0.9614044626951721,
              1.391799500483812: 0.926772645064811,
              1.405145576940052: 0.892728169616057,
              1.4184916533962921: 0.85955774325485,
              1.431837729852532: 0.827434561314174,
              1.445183806308771: 0.7964233150848811,
              1.458529882765011: 0.766503945804473,
              1.4718759592212511: 0.7376070210092751,
              1.485222035677491: 0.709651508757119,
              1.49856811213373: 0.682575932043565,
              1.51191418858997: 0.6563561499243681,
              1.5252602650462102: 0.6310066566485071,
              1.53860634150245: 0.606566404723337,
              1.551952417958689: 0.583073804763249,
              1.565298494414929: 0.560537956621741,
              1.5786445708711692: 0.5389138494723681,
              1.591990647327409: 0.518088150446538,
              1.605336723783649: 0.49787960205240206,
              1.618682800239888: 0.47805461782487,
              1.6320288766961282: 0.45835523032166003,
              1.645374953152368: 0.438533904487766,
              1.658721029608608: 0.418388449613587,
              1.672067106064847: 0.39779054012632203,
              1.6854131825210872: 0.37670298534287006,
              1.698759258977327: 0.355183361335675,
              1.712105335433567: 0.33337429655195405,
              1.7254514118898072: 0.31148301246356,
              1.7387974883460462: 0.289754282696558,
              1.752143564802286: 0.26844164032043605,
              1.765489641258526: 0.24778146393398903,
              1.778835717714766: 0.22797363589473502,
              1.7921817941710052: 0.209170959601812,
              1.805527870627245: 0.19147766091169302,
              1.818873947083485: 0.174955377405997,
              1.832220023539725: 0.15963345119729402,
              1.8455660999959642: 0.145519515991962,
              1.858912176452204: 0.132606632521404,
              1.872258252908444: 0.12087463098340201,
              1.885604329364684: 0.110285541550018,
              1.8989504058209241: 0.10077537352027201,
              1.912296482277163: 0.092246237959154,
              1.925642558733403: 0.084563243068905,
              1.938988635189643: 0.077559483710723,
              1.9523347116458831: 0.071050088252864,
              1.965680788102122: 0.064853422263124,
              1.979026864558362: 0.058815119608843,
              1.992372941014602: 0.052829418329180006,
              2.005719017470842: 0.046852703856537004,
              2.019065093927081: 0.040906050475952006,
              2.032411170383321: 0.035066287451267004,
              2.045757246839561: 0.029447855982330004,
              2.059103323295801: 0.024179689521310003,
              2.07244939975204: 0.019382072872584002,
              2.08579547620828: 0.015147864269661,
              2.09914155266452: 0.011530927552074001,
              2.11248762912076: 0.008542668542574001,
              2.125833705577: 0.006155769937963001,
              2.139179782033239: 0.004312989457569001,
              2.152525858489479: 0.0029384016605190004,
              2.165871934945719: 0.00194866203626,
              2.179218011401959: 0.001262527640318,
              2.192564087858198: 0.000807699169225,
              2.205910164314438: 0.000524808414902,
              2.219256240770678: 0.00036890824957200004,
              2.232602317226918: 0.00030908090774,
              2.245948393683158: 0.00032680117892700005,
              2.259294470139397: 0.00041356050710300004,
              2.272640546595637: 0.000568074942616,
              2.285986623051877: 0.0007932481542960001,
              2.299332699508117: 0.0010929921952730001,
              2.312678775964356: 0.001469037079406,
              2.326024852420596: 0.0019179623076280002,
              2.339370928876836: 0.002428807427569,
              2.352717005333076: 0.0029816984371770004,
              2.3660630817893162: 0.0035479018431240003,
              2.379409158245555: 0.004091555044782,
              2.392755234701795: 0.004573030973972001,
              2.406101311158035: 0.0049535358484100005,
              2.419447387614275: 0.005200207319729,
              2.4327934640705142: 0.005290780549517001,
              2.446139540526754: 0.005216898960013001,
              2.459485616982994: 0.004985384223937001,
              2.4728316934392343: 0.004617196498785001,
              2.4861777698954732: 0.0041443038229950005,
              2.499523846351713: 0.0036051083269970003,
              2.512869922807953: 0.00303933488224,
              2.526215999264193: 0.002483317748694,
              2.539562075720432: 0.001966434717304,
              2.552908152176672: 0.0015091078424890002,
              2.566254228632912: 0.0011224177381610001,
              2.5796003050891523: 0.00080906286736,
              2.5929463815453913: 0.000565201778277,
              2.606292458001631: 0.00038266458629700005,
              2.619638534457871: 0.00025108832955800004,
              2.632984610914111: 0.000159671823459,
              2.64633068737035: 9.840644546782398e-05,
              2.65967676382659: 5.877766203289085e-05,
            },
            geo_level_type: 'Germany',
            halfnorm: {
              0.44266030109435706: 0.002170169314611,
              0.456071951842256: 0.0036187070406000004,
              0.46948360259015404: 0.005891145653163001,
              0.48289525333805206: 0.009364659807435,
              0.496306904085951: 0.014537744526065,
              0.5097185548338491: 0.022043994442733,
              0.523130205581748: 0.03265555296072,
              0.536541856329646: 0.047270883663946,
              0.5499535070775441: 0.066882036073013,
              0.563365157825443: 0.09251835279893901,
              0.576776808573341: 0.125166675096634,
              0.5901884593212391: 0.16567229646616602,
              0.603600110069138: 0.21462955632656902,
              0.617011760817036: 0.272275103503203,
              0.6304234115649351: 0.33839939998602003,
              0.643835062312833: 0.412292030655156,
              0.657246713060731: 0.49273333868024505,
              0.6706583638086301: 0.5780390110036361,
              0.684070014556528: 0.666156436731138,
              0.697481665304427: 0.7548034850873231,
              0.7108933160523251: 0.8416335506653071,
              0.724304966800223: 0.924406788062575,
              0.737716617548122: 1.001147213862345,
              0.7511282682960201: 1.070268673937855,
              0.764539919043918: 1.130658562779189,
              0.777951569791817: 1.181715089358316,
              0.7913632205397151: 1.223340196983446,
              0.804774871287614: 1.255894767182218,
              0.818186522035512: 1.280125000100318,
              0.8315981727834101: 1.297069154394535,
              0.845009823531309: 1.307952959425585,
              0.8584214742792071: 1.314080921870202,
              0.8718331250271061: 1.316730114395027,
              0.885244775775004: 1.317053027600537,
              0.8986564265229021: 1.315996343932032,
              0.9120680772708011: 1.314242397548901,
              0.925479728018699: 1.312178971531453,
              0.9388913787665971: 1.309900625001523,
              0.9523030295144961: 1.307241101385983,
              0.965714680262394: 1.303832184301445,
              0.9791263310102931: 1.299180562425489,
              0.9925379817581911: 1.292751756071677,
              1.00594963250609: 1.284049571996851,
              1.019361283253988: 1.272681038117848,
              1.032772934001886: 1.258399962342565,
              1.046184584749784: 1.241126397608542,
              1.0595962354976831: 1.220943438093845,
              1.073007886245581: 1.198076053928245,
              1.08641953699348: 1.172858516558427,
              1.099831187741378: 1.145697204975542,
              1.113242838489276: 1.117034454135455,
              1.126654489237175: 1.087317152775801,
              1.140066139985073: 1.056971683550104,
              1.153477790732972: 1.026385116416692,
              1.16688944148087: 0.9958916781879121,
              1.180301092228768: 0.965763480180098,
              1.193712742976667: 0.93620505738623,
              1.207124393724565: 0.9073520416849941,
              1.220536044472464: 0.879274819292824,
              1.233947695220362: 0.851987997861216,
              1.24735934596826: 0.825465846982796,
              1.2607709967161589: 0.799662736178526,
              1.274182647464057: 0.774536312901537,
              1.287594298211956: 0.7500701342586771,
              1.301005948959854: 0.726292015261595,
              1.314417599707752: 0.7032846465168281,
              1.327829250455651: 0.681186034033425,
              1.341240901203549: 0.660178827201481,
              1.354652551951447: 0.640469337682937,
              1.3680642026993461: 0.6222587076357771,
              1.381475853447244: 0.605710004330099,
              1.394887504195142: 0.5909158229291961,
              1.408299154943041: 0.5778711746324721,
              1.421710805690939: 0.566455997653175,
              1.435122456438838: 0.5564305866993641,
              1.448534107186736: 0.547445684170978,
              1.461945757934634: 0.539067070046594,
              1.475357408682533: 0.530812453421817,
              1.488769059430431: 0.522196589603503,
              1.50218071017833: 0.512779126470933,
              1.515592360926228: 0.502208992349072,
              1.5290040116741261: 0.49025934910945906,
              1.5424156624220249: 0.47684827752789805,
              1.5558273131699232: 0.462042299310702,
              1.569238963917822: 0.44604228240966304,
              1.58265061466572: 0.42915383203398505,
              1.596062265413618: 0.41174651792328903,
              1.609473916161517: 0.39420785564974703,
              1.622885566909415: 0.376898587636156,
              1.636297217657313: 0.36011539897536804,
              1.6497088684052121: 0.344065829087475,
              1.66312051915311: 0.32885803841287203,
              1.6765321699010092: 0.314505617313452,
              1.689943820648907: 0.30094520420402704,
              1.703355471396805: 0.28806272901204605,
              1.716767122144704: 0.275722956577032,
              1.730178772892602: 0.263796870456938,
              1.7435904236405002: 0.252182321073945,
              1.757002074388399: 0.240815073074112,
              1.770413725136297: 0.22966956336763303,
              1.783825375884196: 0.21875086049984102,
              1.797237026632094: 0.20808103410664402,
              1.8106486773799921: 0.19768404386122002,
              1.824060328127891: 0.18757317789072603,
              1.8374719788757892: 0.177744081515036,
              1.850883629623688: 0.168174800489405,
              1.8642952803715862: 0.158832435613421,
              1.877706931119484: 0.14968440857259602,
              1.891118581867383: 0.140711325487621,
              1.904530232615281: 0.13191817941682402,
              1.917941883363179: 0.12334114215555302,
              1.9313535341110781: 0.11504827191024601,
              1.944765184858976: 0.107133812555498,
              1.9581768356068752: 0.099707064670632,
              1.971588486354773: 0.09287780363551501,
              1.985000137102671: 0.08674074390478001,
              1.99841178785057: 0.08136155930764001,
              2.011823438598468: 0.076766532321535,
              2.025235089346367: 0.072937161369944,
              2.038646740094265: 0.06981017998502101,
              2.052058390842163: 0.067282607501745,
              2.065470041590062: 0.065220796074148,
              2.07888169233796: 0.06347204725746601,
              2.092293343085859: 0.061877264697257,
              2.105704993833757: 0.060283250531766,
              2.119116644581655: 0.058553561602139,
              2.132528295329554: 0.05657721769227501,
              2.145939946077452: 0.05427490549501401,
              2.159351596825351: 0.051602586942563006,
              2.172763247573249: 0.048552579700344003,
              2.186174898321147: 0.045152252312451006,
              2.199586549069046: 0.041460513378714,
              2.212998199816944: 0.037562322885034,
              2.226409850564843: 0.033561545847451,
              2.23982150131274: 0.029572604732888003,
              2.253233152060639: 0.025711538701157003,
              2.266644802808537: 0.022087197787213003,
              2.280056453556436: 0.018793342404179,
              2.293468104304335: 0.01590235430458,
              2.306879755052232: 0.013461093221282002,
              2.320291405800131: 0.011489180190699002,
              2.3337030565480292: 0.009979699339084,
              2.347114707295928: 0.008902035799694001,
              2.360526358043826: 0.008206352595394,
              2.373938008791724: 0.007829082286724,
              2.387349659539623: 0.007698779539427001,
              2.400761310287521: 0.007741740589575,
              2.41417296103542: 0.007886923703908,
              2.427584611783318: 0.008069872002356001,
              2.440996262531216: 0.008235514620830001,
              2.4544079132791152: 0.008339874757150001,
              2.467819564027013: 0.008350821486562,
              2.481231214774912: 0.00824805541326,
              2.49464286552281: 0.008022518706583,
              2.508054516270708: 0.007675382865931,
              2.521466167018607: 0.007216715803996,
              2.534877817766505: 0.006663887628350001,
              2.548289468514403: 0.006039759114343001,
              2.561701119262302: 0.00537071311951,
              2.5751127700102: 0.0046846281443640005,
              2.588524420758099: 0.004008935802902,
              2.601936071505997: 0.003368928144835,
              2.615347722253895: 0.0027864698876330003,
              2.628759373001794: 0.002279219438588,
              2.6421710237496923: 0.0018603793858070002,
              2.655582674497591: 0.0015389012073600001,
              2.668994325245489: 0.0013199847799180001,
              2.682405975993387: 0.00120566348851,
              2.695817626741286: 0.001195264984907,
              2.709229277489184: 0.001285588851643,
              2.722640928237083: 0.001470736085616,
              2.73605257898498: 0.001741641408332,
              2.749464229732879: 0.002085471466599,
              2.7628758804807783: 0.0024851324436960002,
              2.776287531228676: 0.0029191567400810004,
              2.789699181976575: 0.0033621979377950004,
              2.803110832724472: 0.0037862585244850004,
              2.816522483472371: 0.004162623996091,
              2.82993413422027: 0.004464311568885,
              2.843345784968168: 0.0046687003733860005,
              2.856757435716066: 0.004759928893608,
              2.870169086463964: 0.004730648570907,
              2.883580737211863: 0.004582814299384,
              2.896992387959762: 0.004327355374103,
              2.91040403870766: 0.003982768524421,
              2.923815689455558: 0.003572863259954,
              2.937227340203456: 0.00312402716848,
              2.9506389909513553: 0.0026624372621640003,
              2.964050641699254: 0.002211615726959,
              2.977462292447152: 0.001790628201923,
              2.99087394319505: 0.001413079218453,
              3.004285593942948: 0.001086908217848,
              3.017697244690847: 0.000814862768936,
              3.031108895438746: 0.000595444517698,
              3.044520546186644: 0.000424095183742,
              3.057932196934542: 0.000294408665567,
              3.07134384768244: 0.00019920628839700002,
              3.084755498430339: 0.00013137741251000002,
              3.098167149178238: 8.445076134848489e-05,
              3.111578799926136: 5.2911690674297304e-05,
            },
            triangular: {
              0.17630994285368: 0.000260242269059,
              0.18403184821736202: 0.00036824635481400004,
              0.19175375358104402: 0.0005157156800260001,
              0.19947565894472602: 0.000714869534609,
              0.20719756430840802: 0.0009808956356430001,
              0.21491946967209002: 0.0013324013959650001,
              0.22264137503577203: 0.001791851789243,
              0.23036328039945403: 0.0023859650877820003,
              0.23808518576313603: 0.0031460323284570003,
              0.245807091126818: 0.004108122125644,
              0.25352899649050004: 0.0053131300833100005,
              0.26125090185418204: 0.006806632230146,
              0.26897280721786404: 0.008638505186315001,
              0.27669471258154604: 0.010862282566173,
              0.28441661794522805: 0.013534227571711,
              0.29213852330891: 0.016712115663647,
              0.29986042867259105: 0.020453738085104003,
              0.307582334036273: 0.024815155976723003,
              0.315304239399955: 0.029848754666115,
              0.323026144763637: 0.035601167005640005,
              0.330748050127319: 0.042111151813533004,
              0.338469955491001: 0.049407527001104005,
              0.346191860854683: 0.057507265462360006,
              0.353913766218365: 0.06641386418488701,
              0.361635671582047: 0.07611609265829501,
              0.369357576945729: 0.086587215358148,
              0.377079482309411: 0.09778476525292501,
              0.384801387673093: 0.10965092181625401,
              0.392523293036775: 0.122113519261204,
              0.400245198400457: 0.135087680302787,
              0.407967103764139: 0.14847803952476002,
              0.41568900912782103: 0.162181490212729,
              0.42341091449150303: 0.17609036100914602,
              0.43113281985518503: 0.19009590536744,
              0.43885472521886704: 0.20409196859745402,
              0.44657663058254904: 0.21797868497927203,
              0.45429853594623104: 0.231666051288363,
              0.46202044130991304: 0.24507722313450603,
              0.46974234667359505: 0.258151386585526,
              0.47746425203727705: 0.27084606934987704,
              0.48518615740095905: 0.283138773055823,
              0.49290806276464105: 0.29502783066383104,
              0.500629968128323: 0.306532420591882,
              0.5083518734920051: 0.317691701482725,
              0.5160737788556871: 0.32856306827181403,
              0.5237956842193691: 0.339219570535617,
              0.5315175895830511: 0.34974657668947,
              0.5392394949467331: 0.360237810486204,
              0.5469614003104151: 0.370790926792032,
              0.5546833056740971: 0.381502828573394,
              0.5624052110377791: 0.39246495290591105,
              0.5701271164014611: 0.403758767196689,
              0.5778490217651431: 0.415451714858364,
              0.5855709271288251: 0.42759383064322404,
              0.5932928324925071: 0.440215209531972,
              0.6010147378561891: 0.453324461100284,
              0.6087366432198701: 0.46690821718568704,
              0.6164585485835521: 0.48093168967945604,
              0.6241804539472341: 0.495340203838017,
              0.6319023593109161: 0.510061567644009,
              0.6396242646745981: 0.5250090861359661,
              0.6473461700382801: 0.5400849967680831,
              0.6550680754019621: 0.5551840912921611,
              0.6627899807656441: 0.5701973023768271,
              0.6705118861293261: 0.5850150674127721,
              0.678233791493008: 0.5995303332356541,
              0.68595569685669: 0.613641127158404,
              0.693677602220372: 0.6272526836450371,
              0.701399507584054: 0.6402791736661251,
              0.709121412947736: 0.6526451274328221,
              0.716843318311418: 0.664286664752197,
              0.7245652236751: 0.6751526472657421,
              0.732287129038782: 0.685205843127706,
              0.740009034402464: 0.694424150447694,
              0.747730939766146: 0.702801867383759,
              0.755452845129828: 0.7103509328615291,
              0.76317475049351: 0.717102002569813,
              0.770896655857192: 0.7231051801807801,
              0.778618561220874: 0.7284302022422701,
              0.786340466584556: 0.733165882677651,
              0.794062371948238: 0.737418661267459,
              0.80178427731192: 0.7413101674649031,
              0.809506182675602: 0.744973799678574,
              0.817228088039284: 0.7485504203797151,
              0.824949993402966: 0.7521833663624671,
              0.832671898766648: 0.756013057902528,
              0.84039380413033: 0.7601715484486701,
              0.848115709494012: 0.764777379038719,
              0.855837614857694: 0.7699310846802121,
              0.863559520221376: 0.775711644738279,
              0.8712814255850581: 0.782174082632472,
              0.8790033309487401: 0.789348313153004,
              0.8867252363124221: 0.797239222743454,
              0.8944471416761041: 0.805827864297997,
              0.9021690470397861: 0.815073567060827,
              0.9098909524034681: 0.8249167141641751,
              0.9176128577671491: 0.835281930062683,
              0.9253347631308311: 0.8460814464812261,
              0.9330566684945131: 0.8572184715278061,
              0.9407785738581951: 0.868590460660663,
              0.9485004792218771: 0.8800922657551871,
              0.9562223845855591: 0.8916192046704251,
              0.9639442899492411: 0.9030701355757841,
              0.9716661953129231: 0.914350629163262,
              0.9793881006766051: 0.9253763047216681,
              0.9871100060402871: 0.9360763361465291,
              0.9948319114039691: 0.9463970504293631,
              1.002553816767651: 0.9563054476600861,
              1.010275722131333: 0.9657923841865591,
              1.017997627495015: 0.9748750953941091,
              1.025719532858697: 0.9835987051963541,
              1.033441438222379: 0.992036384769386,
              1.041163343586061: 1.000287886288694,
              1.048885248949743: 1.008476284840792,
              1.056607154313425: 1.016742903503242,
              1.0643290596771071: 1.025240558136191,
              1.072050965040789: 1.034125422053418,
              1.079772870404471: 1.04354795812151,
              1.087494775768153: 1.053643480323194,
              1.0952166811318351: 1.06452297547966,
              1.102938586495517: 1.076264830916806,
              1.110660491859199: 1.088908073579119,
              1.118382397222881: 1.102447634522809,
              1.1261043025865631: 1.116832019026155,
              1.133826207950245: 1.131963599626334,
              1.141548113313927: 1.147701572116232,
              1.149270018677609: 1.16386743800789,
              1.156991924041291: 1.180252714879716,
              1.164713829404973: 1.196628439418791,
              1.172435734768655: 1.212755924523219,
              1.180157640132336: 1.228398165652581,
              1.187879545496019: 1.243331263529266,
              1.1956014508597: 1.2573552384487021,
              1.203323356223382: 1.27030365210929,
              1.211045261587064: 1.282051521159438,
              1.218767166950746: 1.292521097221633,
              1.226489072314428: 1.301685195540335,
              1.23421097767811: 1.309567873241041,
              1.241932883041792: 1.316242383197289,
              1.249654788405474: 1.3218264554226131,
              1.257376693769156: 1.32647507949169,
              1.265098599132838: 1.3303710736396321,
              1.27282050449652: 1.333713824201507,
              1.280542409860202: 1.33670665908268,
              1.288264315223884: 1.339543378427528,
              1.295986220587566: 1.342394503646213,
              1.303708125951248: 1.345393823275653,
              1.31143003131493: 1.348625813114148,
              1.319151936678612: 1.352114491925137,
              1.326873842042294: 1.3558142460414762,
              1.334595747405976: 1.3596031187361262,
              1.342317652769658: 1.363279013697267,
              1.35003955813334: 1.366559204354874,
              1.357761463497022: 1.369083467712888,
              1.365483368860704: 1.370421066558053,
              1.373205274224386: 1.370081680865228,
              1.380927179588068: 1.367530232800412,
              1.38864908495175: 1.36220535838681,
              1.396370990315432: 1.353541056517799,
              1.404092895679114: 1.340990803115478,
              1.411814801042796: 1.324053172310027,
              1.419536706406478: 1.30229778097021,
              1.42725861177016: 1.275390194886441,
              1.4349805171338421: 1.243114331961406,
              1.442702422497524: 1.205390893956808,
              1.4504243278612061: 1.162290470221878,
              1.458146233224888: 1.11404018999662,
              1.46586813858857: 1.061023146789844,
              1.473590043952251: 1.003770257715107,
              1.4813119493159341: 0.942944718894503,
              1.4890338546796151: 0.8793197322831541,
              1.496755760043297: 0.8137506621531191,
              1.504477665406979: 0.747143184592151,
              1.512199570770661: 0.6804192808754941,
              1.5199214761343431: 0.6144830670772571,
              1.527643381498025: 0.550188434376175,
              1.535365286861707: 0.48831030028857203,
              1.543087192225389: 0.42952095964782705,
              1.5508090975890712: 0.37437260813459705,
              1.558531002952753: 0.323286632473177,
              1.566252908316435: 0.276549766187628,
              1.573974813680117: 0.23431674304149502,
              1.5816967190437992: 0.19661868089994602,
              1.589418624407481: 0.163376125830333,
              1.597140529771163: 0.13441549671278802,
              1.604862435134845: 0.10948759858463701,
              1.6125843404985272: 0.088286910653308,
              1.620306245862209: 0.07047048502142801,
              1.628028151225891: 0.055675490772365006,
              1.635750056589573: 0.043534678153033,
              1.6434719619532552: 0.033689292342841,
              1.651193867316937: 0.025799211860433,
              1.658915772680619: 0.019550304135226,
              1.666637678044301: 0.014659167299099001,
              1.6743595834079832: 0.010875556219700001,
              1.682081488771665: 0.007982871293599,
              1.689803394135347: 0.005797124278180001,
              1.697525299499029: 0.004164793476115001,
              1.7052472048627112: 0.002959949752046,
              1.712969110226393: 0.0020809845481180003,
            },
          },
          {
            actual: {
              '-0.7458329332615281': 0.0019462994344370001,
              '-0.731080542982517': 0.002301798517122,
              '-0.716328152703507': 0.002714003871944,
              '-0.701575762424497': 0.00319036018782,
              '-0.686823372145486': 0.003739002070199,
              '-0.6720709818664761': 0.004368769346689,
              '-0.6573185915874661': 0.005089213758405001,
              '-0.6425662013084551': 0.005910595483718001,
              '-0.627813811029445': 0.006843867918026001,
              '-0.613061420750435': 0.007900649147954,
              '-0.598309030471424': 0.009093178616778,
              '-0.583556640192414': 0.010434257585111,
              '-0.5688042499134041': 0.011937172151474001,
              '-0.5540518596343931': 0.013615597814699001,
              '-0.5392994693553831': 0.01548348483628,
              '-0.524547079076373': 0.017554923996164002,
              '-0.509794688797362': 0.019843992728675,
              '-0.495042298518352': 0.022364582072653003,
              '-0.48028990823934203': 0.025130205365658003,
              '-0.46553751796033105': 0.028153790148079002,
              '-0.450785127681321': 0.031447455308395,
              '-0.436032737402311': 0.035022276083023,
              '-0.42128034712330004': 0.038888040107757,
              '-0.40652795684429005': 0.04305299828602,
              '-0.39177556656528': 0.047523614773489,
              '-0.377023176286269': 0.052304320859659005,
              '-0.36227078600725904': 0.057397277934803,
              '-0.34751839572824905': 0.06280215504596401,
              '-0.332766005449238': 0.06851592674948201,
              '-0.318013615170228': 0.074532697043413,
              '-0.30326122489121804': 0.080843555096599,
              '-0.288508834612207': 0.087436468271378,
              '-0.273756444333197': 0.09429621755683501,
              '-0.259004054054187': 0.10140437998682601,
              '-0.24425166377517601': 0.10873936191456,
              '-0.22949927349616603': 0.11627648616130201,
              '-0.21474688321715601': 0.123988135064582,
              '-0.199994492938145': 0.13184395034023902,
              '-0.18524210265913502': 0.13981108946696702,
              '-0.170489712380125': 0.14785453703085202,
              '-0.15573732210111402': 0.155937468163339,
              '-0.140984931822104': 0.16402165990522602,
              '-0.12623254154309402': 0.172067945069104,
              '-0.111480151264083': 0.18003670199156402,
              '-0.096727760985073': 0.187888372501957,
              '-0.081975370706062': 0.195583999521931,
              '-0.067222980427052': 0.203085774981233,
              '-0.05247059014804201': 0.21035758821757003,
              '-0.037718199869032': 0.21736556474263502,
              '-0.022965809590021': 0.22407858521724802,
              '-0.008213419311011001': 0.23046877469225302,
              0.006538970967998: 0.23651195263718203,
              0.021291361247009002: 0.242188034985956,
              0.036043751526019005: 0.247481380360509,
              0.050796141805030004: 0.25238107376408303,
              0.06554853208404: 0.25688114233441,
              0.08030092236305: 0.260980699175796,
              0.09505331264206: 0.264684012807037,
              0.109805702921071: 0.26800050132499104,
              0.12455809320008102: 0.27094465194675604,
              0.139310483479092: 0.273535868112392,
              0.15406287375810201: 0.27579824776314804,
              0.168815264037112: 0.277760297719274,
              0.18356765431612201: 0.27945459023379104,
              0.19832004459513303: 0.28091736876781104,
              0.213072434874143: 0.282188110799634,
              0.22782482515315303: 0.283309056032286,
              0.242577215432164: 0.28432470869832904,
              0.257329605711174: 0.28528132278018503,
              0.272081995990184: 0.28622637887916,
              0.286834386269195: 0.28720806119339903,
              0.30158677654820504: 0.28827474262540703,
              0.316339166827215: 0.28947448545868804,
              0.331091557106226: 0.290854564347718,
              0.34584394738523605: 0.292461017584057,
              0.36059633766424704: 0.29433823176151,
              0.375348727943257: 0.29652856409064904,
              0.390101118222267: 0.299072005730581,
              0.40485350850127805: 0.302005888632971,
              0.41960589878028803: 0.305364637544846,
              0.434358289059298: 0.309179568003472,
              0.44911067933830806: 0.313478730384784,
              0.46386306961731905: 0.31828679933907905,
              0.47861545989632903: 0.323625007263156,
              0.493367850175339: 0.329511119813926,
              0.50812024045435: 0.335959450860472,
              0.52287263073336: 0.34298091369547,
              0.5376250210123701: 0.350583104779564,
              0.5523774112913811: 0.358770415772793,
              0.5671298015703911: 0.36754416911751303,
              0.581882191849402: 0.37690277198314104,
              0.596634582128412: 0.38684188297431704,
              0.611386972407422: 0.397354585654603,
              0.626139362686433: 0.40843156266517405,
              0.6408917529654431: 0.420061264042887,
              0.6556441432444531: 0.43223006328734304,
              0.6703965335234641: 0.444922394815485,
              0.685148923802474: 0.45812086669759305,
              0.699901314081484: 0.47180634301032204,
              0.714653704360494: 0.485957990786447,
              0.729406094639505: 0.500553287397149,
              0.7441584849185151: 0.515567985273201,
              0.7589108751975251: 0.530976032149804,
              0.7736632654765361: 0.546749446489696,
              0.7884156557555461: 0.562858149373847,
              0.803168046034557: 0.579269755911567,
              0.817920436313567: 0.5959493310652361,
              0.832672826592577: 0.612859116653502,
              0.8474252168715881: 0.629958238127523,
              0.8621776071505981: 0.647202401439646,
              0.8769299974296081: 0.6645435918715731,
              0.8916823877086191: 0.6819297879888291,
              0.9064347779876291: 0.699304704871997,
              0.921187168266639: 0.716607581380962,
              0.935939558545649: 0.73377302638341,
              0.9506919488246601: 0.7507309385820851,
              0.9654443391036701: 0.7674065137802291,
              0.9801967293826801: 0.783720352121076,
              0.9949491196616911: 0.7995886760325981,
              1.009701509940701: 0.8149236673292191,
              1.024453900219712: 0.8296339292124321,
              1.039206290498722: 0.843625075834713,
              1.053958680777732: 0.8568004487246821,
              1.0687110710567431: 0.8690619558090431,
              1.083463461335753: 0.8803110251126761,
              1.098215851614763: 0.890449661584463,
              1.112968241893773: 0.8993815919990891,
              1.127720632172784: 0.9070134796401721,
              1.142473022451794: 0.9132561875893451,
              1.157225412730805: 0.9180260670325371,
              1.1719778030098151: 0.9212462451394531,
              1.186730193288825: 0.92284788584993,
              1.201482583567835: 0.922771396367383,
              1.216234973846846: 0.9209675523493501,
              1.230987364125856: 0.917398515710048,
              1.245739754404867: 0.912038720597879,
              1.260492144683877: 0.9048756054466871,
              1.2752445349628871: 0.895910171965526,
              1.289996925241897: 0.8851573554495351,
              1.304749315520908: 0.8726461947678461,
              1.319501705799918: 0.8584197947025791,
              1.334254096078928: 0.842535077853879,
              1.349006486357939: 0.825062327961213,
              1.363758876636949: 0.806084531089812,
              1.3785112669159592: 0.7856965255643341,
              1.3932636571949701: 0.7640039756769831,
              1.40801604747398: 0.7411221879422161,
              1.4227684377529901: 0.717174791916337,
              1.437520828032001: 0.6922923102661801,
              1.452273218311011: 0.666610644794218,
              1.467025608590022: 0.640269506466089,
              1.481777998869032: 0.613410818120531,
              1.4965303891480422: 0.586177118472308,
              1.511282779427052: 0.558709995268249,
              1.526035169706063: 0.5311485740663761,
              1.5407875599850729: 0.503628087137438,
              1.555539950264083: 0.47627854451052504,
              1.570292340543094: 0.449223526285484,
              1.585044730822104: 0.422579112108647,
              1.5997971211011142: 0.39645296025422505,
              1.6145495113801251: 0.37094354517262,
              1.629301901659135: 0.346139558758605,
              1.644054291938145: 0.32211947705154503,
              1.658806682217156: 0.298951290694615,
              1.673559072496166: 0.27669239432820303,
              1.688311462775177: 0.255389627241188,
              1.703063853054187: 0.23507945510640202,
              1.7178162433331972: 0.21578828052359,
              1.732568633612207: 0.19753286841099002,
              1.747321023891218: 0.18032087103782302,
              1.7620734141702281: 0.164151436674075,
              1.776825804449238: 0.14901588543837202,
              1.791578194728249: 0.134898435926184,
              1.806330585007259: 0.121776966566512,
              1.8210829752862692: 0.10962379634578501,
              1.8358353655652802: 0.098406470507455,
              1.85058775584429: 0.08808853803560401,
              1.8653401461233: 0.078630309109877,
              1.880092536402311: 0.069989582226089,
              1.894844926681321: 0.062122332262325004,
              1.909597316960332: 0.05498335238728701,
              1.924349707239342: 0.048526844313246005,
              1.9391020975183522: 0.042706952951847,
              1.953854487797362: 0.037478243004618,
              1.968606878076373: 0.032796116384301,
              1.9833592683553831: 0.028617170597195003,
              1.998111658634393: 0.024899499305434003,
              2.012864048913404: 0.02160293722223,
              2.027616439192414: 0.018689252268508,
              2.042368829471424: 0.016122288536701,
              2.057121219750435: 0.013868064071628002,
              2.071873610029445: 0.011894827797688001,
              2.086626000308456: 0.010173080107206,
              2.101378390587466: 0.008675561689864001,
              2.116130780866476: 0.007377215142491,
              2.130883171145487: 0.006255123767512001,
              2.145635561424497: 0.00528843176278,
              2.160387951703507: 0.0044582497409170006,
              2.175140341982517: 0.0037475492074040004,
              2.189892732261528: 0.0031410492873720003,
            },
            gamma: {
              '-0.7635019647947361': 4.0581994405308256e-05,
              '-0.743635816525764': 6.84066403465038e-05,
              '-0.723769668256793': 0.00011189882199100001,
              '-0.7039035199878211': 0.00017768789347000002,
              '-0.684037371718849': 0.000274012213858,
              '-0.664171223449877': 0.000410558170433,
              '-0.6443050751809061': 0.0005980400571130001,
              '-0.624438926911934': 0.000847514678304,
              '-0.604572778642962': 0.001169488199009,
              '-0.5847066303739901': 0.001572940996103,
              '-0.564840482105018': 0.0020644464753110004,
              '-0.544974333836047': 0.0026475681427990004,
              '-0.5251081855670751': 0.003322670635075,
              '-0.505242037298103': 0.004087178534193,
              '-0.48537588902913104': 0.004936188341576,
              '-0.46550974076016005': 0.005863228769486001,
              '-0.445643592491188': 0.006860920527053001,
              '-0.42577744422221603': 0.007921339900794001,
              '-0.40591129595324404': 0.009036037290107001,
              '-0.386045147684273': 0.010195859809327001,
              '-0.366178999415301': 0.011390905782508001,
              '-0.34631285114632904': 0.012611025654701001,
              '-0.326446702877357': 0.013847232010709,
              '-0.306580554608386': 0.015094190221549002,
              '-0.28671440633941403': 0.016353676832928002,
              '-0.26684825807044205': 0.017638590918992,
              '-0.24698210980147': 0.018976862736694,
              '-0.22711596153249902': 0.020414481887274002,
              '-0.207249813263527': 0.022016890792821,
              '-0.18738366499455503': 0.023868159377266003,
              '-0.16751751672558302': 0.026067659118341004,
              '-0.147651368456611': 0.028724365840665,
              '-0.12778522018764002': 0.031949402120125,
              '-0.10791907191866801': 0.035847912474116006,
              '-0.08805292364969601': 0.040511736304469005,
              '-0.06818677538072501': 0.046014462031620004,
              '-0.048320627111753': 0.052410177249491006,
              '-0.028454478842781003': 0.059736512579777,
              '-0.008588330573809': 0.06802148339887601,
              0.011277817695162002: 0.077292396296049,
              0.031143965964133002: 0.08758406554444001,
              0.051010114233105: 0.09894316877701101,
              0.070876262502077: 0.111426045192358,
              0.09074241077104901: 0.125088657148639,
              0.11060855904002001: 0.13996955239147002,
              0.13047470730899202: 0.15606896618104502,
              0.150340855577964: 0.17332903455539903,
              0.17020700384693602: 0.191620843004551,
              0.190073152115907: 0.21074334015283003,
              0.209939300384879: 0.23043700946939302,
              0.22980544865385102: 0.250412024630276,
              0.249671596922823: 0.27038714614423603,
              0.269537745191794: 0.29013271600573404,
              0.289403893460766: 0.30950954954139603,
              0.30927004172973804: 0.32849577720716705,
              0.32913618999871: 0.34719576911320704,
              0.349002338267681: 0.36582871646104903,
              0.36886848653665305: 0.384698410102694,
              0.38873463480562503: 0.40414926632476,
              0.408600783074597: 0.42451586815296805,
              0.42846693134356806: 0.44607378662559005,
              0.44833307961254004: 0.46899832904267,
              0.468199227881512: 0.49333571953612604,
              0.48806537615048406: 0.518988877153314,
              0.507931524419456: 0.5457181360851271,
              0.527797672688427: 0.573156262922162,
              0.5476638209573991: 0.60083675747675,
              0.567529969226371: 0.6282340857210611,
              0.587396117495343: 0.654813555943873,
              0.6072622657643141: 0.6800867361374401,
              0.627128414033286: 0.7036659475886731,
              0.646994562302258: 0.7253093477650651,
              0.6668607105712301: 0.74494755671441,
              0.686726858840201: 0.7626845352593241,
              0.706593007109173: 0.77876961821181,
              0.7264591553781451: 0.79354346191832,
              0.746325303647117: 0.807366676819381,
              0.7661914519160881: 0.8205443423216541,
              0.7860576001850601: 0.833261076936408,
              0.805923748454032: 0.8455393483390221,
              0.8257898967230041: 0.8572287602878711,
              0.8456560449919751: 0.8680274692198171,
              0.865522193260947: 0.8775303441769611,
              0.8853883415299191: 0.8852935043936601,
              0.9052544897988911: 0.8909024040645591,
              0.925120638067862: 0.8940309271658811,
              0.9449867863368341: 0.8944816256547591,
              0.9648529346058061: 0.8922015033563191,
              0.984719082874778: 0.887272685173012,
              1.00458523114375: 0.879882015398727,
              1.024451379412721: 0.8702773250640131,
              1.044317527681693: 0.8587202008367391,
              1.064183675950665: 0.8454452163207521,
              1.084049824219637: 0.830633701632222,
              1.103915972488608: 0.8144065624107021,
              1.12378212075758: 0.7968361384886841,
              1.143648269026552: 0.7779726237062411,
              1.163514417295524: 0.7578772217163171,
              1.183380565564495: 0.7366528306649951,
              1.203246713833467: 0.714463988613096,
              1.223112862102439: 0.6915408110414131,
              1.242979010371411: 0.6681658914174,
              1.262845158640382: 0.6446474699933741,
              1.282711306909354: 0.6212854700072921,
              1.302577455178326: 0.59833845464776,
              1.322443603447298: 0.575998914742972,
              1.342309751716269: 0.5543818709235431,
              1.3621758999852411: 0.533528313899425,
              1.382042048254213: 0.5134214503395791,
              1.401908196523185: 0.494010932618739,
              1.4217743447921571: 0.47523881249375205,
              1.441640493061128: 0.45706107674913504,
              1.4615066413301: 0.439460130324248,
              1.481372789599072: 0.422446038153625,
              1.501238937868043: 0.40604711399672705,
              1.521105086137015: 0.390292926872545,
              1.540971234405987: 0.37519446020192504,
              1.560837382674959: 0.360726679927447,
              1.580703530943931: 0.34681807622787003,
              1.600569679212902: 0.333350037307416,
              1.6204358274818742: 0.32016661634486004,
              1.640301975750846: 0.30709291484358303,
              1.6601681240198172: 0.29395847068485204,
              1.680034272288789: 0.280621104195223,
              1.699900420557761: 0.266986791205614,
              1.7197665688267332: 0.253022176559213,
              1.739632717095705: 0.238757978337774,
              1.7594988653646761: 0.22428333085714303,
              1.779365013633648: 0.209732680804111,
              1.79923116190262: 0.19526792803519002,
              1.8190973101715922: 0.18105899402709202,
              1.838963458440563: 0.167265933688159,
              1.8588296067095351: 0.154025165640879,
              1.878695754978507: 0.141441480268685,
              1.898561903247479: 0.12958630440617502,
              1.9184280515164511: 0.11850141494429701,
              1.938294199785422: 0.10820613679091201,
              1.958160348054394: 0.098705327031309,
              1.978026496323366: 0.089995401794769,
              1.997892644592337: 0.08206641877665301,
              2.017758792861309: 0.074899650923476,
              2.037624941130281: 0.068461781185011,
              2.057491089399253: 0.062698266701978,
              2.077357237668224: 0.057529052065101005,
              2.097223385937196: 0.052849380082714004,
              2.117089534206168: 0.048537037995319006,
              2.13695568247514: 0.044465417567714,
              2.156821830744111: 0.040519891160430005,
              2.176687979013083: 0.036613823928207,
              2.196554127282055: 0.032700431654821005,
              2.216420275551027: 0.028777674130209003,
              2.236286423819999: 0.024885116861502,
              2.256152572088971: 0.021093651981858003,
              2.276018720357942: 0.017490570752875002,
              2.295884868626914: 0.014163313364434,
              2.315751016895886: 0.011185146409635001,
              2.335617165164858: 0.008605173783183,
              2.355483313433829: 0.006443808723459001,
              2.3753494617028013: 0.00469352302095,
              2.395215609971773: 0.003323678052273,
              2.4150817582407442: 0.002287715526982,
              2.434947906509716: 0.0015309579881250001,
              2.454814054778688: 0.0009976207049820001,
              2.47468020304766: 0.0006361816623270001,
              2.494546351316631: 0.00040281154981500005,
              2.514412499585603: 0.000262999112335,
              2.534278647854575: 0.00019175804460700002,
              2.554144796123547: 0.00017287353723200003,
              2.574010944392519: 0.000197586395456,
              2.593877092661491: 0.000262985028226,
              2.613743240930462: 0.000370242465713,
              2.6336093891994343: 0.000522743114606,
              2.653475537468406: 0.000724116665316,
              2.673341685737377: 0.0009762358338980001,
              2.693207834006349: 0.0012773206161630002,
              2.713073982275321: 0.0016203881449440001,
              2.732940130544293: 0.001992349751282,
              2.752806278813264: 0.002374045266239,
              2.7726724270822363: 0.002741396827703,
              2.792538575351208: 0.003067667702629,
              2.812404723620179: 0.003326566051942,
              2.832270871889151: 0.0034957044195330004,
              2.852137020158123: 0.003559784639378,
              2.872003168427095: 0.003512878502278,
              2.891869316696067: 0.003359331699824,
              2.911735464965039: 0.003113099421563,
              2.931601613234011: 0.0027956540849100003,
              2.951467761502982: 0.002432899762161,
              2.971333909771954: 0.002051706984269,
              2.991200058040926: 0.001676705528613,
              3.011066206309897: 0.0013278487050130002,
              3.030932354578869: 0.001019038825319,
              3.050798502847841: 0.0007578499145440001,
              3.070664651116812: 0.000546167708646,
              3.090530799385784: 0.00038143379742500003,
              3.110396947654756: 0.00025814437266200003,
              3.130263095923728: 0.000169299818032,
              3.150129244192699: 0.00010759712297100001,
              3.169995392461671: 6.626667272564233e-05,
              3.189861540730643: 3.954941005683986e-05,
            },
            geo_level_type: 'New Zealand',
            halfnorm: {
              '-0.38321422219808704': 0.001236087409005,
              '-0.35966770542319304': 0.00206114710942,
              '-0.33612118864829804': 0.0033554851768760005,
              '-0.31257467187340304': 0.005333933163486001,
              '-0.28902815509850804': 0.008280424408829,
              '-0.26548163832361305': 0.012555842436524,
              '-0.24193512154871802': 0.018599985529733,
              '-0.21838860477382302': 0.026924601558109003,
              '-0.19484208799892902': 0.038094743171354,
              '-0.17129557122403402': 0.05269670445835,
              '-0.14774905444913902': 0.07129257153913901,
              '-0.12420253767424401': 0.094363807608925,
              '-0.10065602089934901': 0.12224893716328401,
              '-0.07710950412445401': 0.15508275089869,
              '-0.053562987349560005': 0.192745899926471,
              '-0.030016470574665003': 0.23483374522656802,
              '-0.006469953799770001': 0.28065159332913203,
              0.017076562975124002: 0.32924009136276905,
              0.040623079750019005: 0.379430110972003,
              0.06416959652491401: 0.42992179361666505,
              0.08771611329980801: 0.47937855722556805,
              0.11126263007470301: 0.5265246282074411,
              0.13480914684959802: 0.570234523769101,
              0.15835566362449302: 0.609604800510242,
              0.18190218039938802: 0.644001739369068,
              0.20544869717428302: 0.67308257155441,
              0.22899521394917802: 0.6967914458286351,
              0.25254173072407204: 0.7153339134865011,
              0.27608824749896704: 0.729134996022167,
              0.29963476427386204: 0.738786065935609,
              0.32318128104875704: 0.744985275495143,
              0.34672779782365204: 0.7484756470388051,
              0.37027431459854704: 0.749984577011189,
              0.39382083137344104: 0.750168502267548,
              0.41736734814833604: 0.749566635229274,
              0.44091386492323104: 0.7485676205322811,
              0.46446038169812603: 0.7473923320870861,
              0.48800689847302103: 0.7460946289816021,
              0.5115534152479161: 0.7445798146152821,
              0.53509993202281: 0.742638159898219,
              0.5586464487977051: 0.7399886840284751,
              0.5821929655726: 0.7363269574823591,
              0.6057394823474951: 0.7313703579703701,
              0.62928599912239: 0.7248950560240791,
              0.6528325158972851: 0.716760825282716,
              0.676379032672179: 0.7069221294111071,
              0.6999255494470741: 0.695426297281971,
              0.723472066221969: 0.682401467627589,
              0.7470185829968641: 0.668038035143738,
              0.770565099771759: 0.652567465620143,
              0.7941116165466541: 0.636241704684193,
              0.817658133321548: 0.6193152917112701,
              0.8412046500964431: 0.6020310861989681,
              0.864751166871338: 0.584609555877004,
              0.8882976836462331: 0.5672410700182781,
              0.9118442004211281: 0.5500806180836151,
              0.9353907171960231: 0.5332446993377581,
              0.9589372339709171: 0.516810567134341,
              0.9824837507458121: 0.500818312131412,
              1.006030267520707: 0.48527625457106904,
              1.029576784295602: 0.47016973889977104,
              1.053123301070497: 0.45547277485941806,
              1.076669817845392: 0.44116123924009704,
              1.1002163346202871: 0.42722576648076405,
              1.123762851395181: 0.41368219948614005,
              1.1473093681700761: 0.40057763726223106,
              1.170855884944971: 0.38799068542243503,
              1.194402401719866: 0.37602537760589605,
              1.217948918494761: 0.36479922503440904,
              1.2414954352696561: 0.35442679447805103,
              1.26504195204455: 0.34500096597067004,
              1.2885884688194449: 0.336574479966527,
              1.31213498559434: 0.32914449493913,
              1.335681502369235: 0.322642625964819,
              1.3592280191441302: 0.31693234144154603,
              1.382774535919025: 0.31181471084392404,
              1.4063210526939192: 0.30704241065779003,
              1.429867569468814: 0.302340737102942,
              1.453414086243709: 0.297433303976957,
              1.476960603018604: 0.292069295037855,
              1.500507119793499: 0.286048746497444,
              1.5240536365683939: 0.279242455646708,
              1.547600153343289: 0.27160376284443705,
              1.571146670118183: 0.26317055759678204,
              1.594693186893078: 0.254057250491164,
              1.6182397036679732: 0.24443790847649702,
              1.6417862204428681: 0.23452303148880901,
              1.665332737217763: 0.224533341071036,
              1.6888792539926571: 0.214674309286447,
              1.712425770767552: 0.20511492235434,
              1.735972287542447: 0.195973390804335,
              1.759518804317342: 0.187311320778234,
              1.783065321092237: 0.179136453089208,
              1.806611837867132: 0.17141269817634802,
              1.830158354642027: 0.164075083883115,
              1.8537048714169222: 0.15704658281906803,
              1.8772513881918171: 0.150253709658202,
              1.9007979049667112: 0.143638281932391,
              1.9243444217416061: 0.137163712398563,
              1.947890938516501: 0.1308154408041,
              1.971437455291396: 0.12459635409659,
              1.99498397206629: 0.11851902272336401,
              2.018530488841186: 0.11259709366124401,
              2.042077005616081: 0.10683813557621501,
              2.065623522390976: 0.10123966812481001,
              2.08917003916587: 0.095789186584327,
              2.112716555940765: 0.09046795219225201,
              2.13626307271566: 0.085257408956631,
              2.159809589490555: 0.080146510490499,
              2.183356106265449: 0.075138100745474,
              2.206902623040344: 0.070252782492265,
              2.230449139815239: 0.065529320398446,
              2.253995656590134: 0.061021393993064006,
              2.277542173365029: 0.056791258819495004,
              2.301088690139924: 0.05290144085852701,
              2.324635206914819: 0.0494058876727,
              2.348181723689714: 0.046342005833402006,
              2.371728240464608: 0.043724765342846,
              2.395274757239503: 0.041543628052865,
              2.418821274014398: 0.039762558579588006,
              2.442367790789293: 0.038322901083333,
              2.465914307564188: 0.037148532277988006,
              2.489460824339082: 0.036152478016572,
              2.513007341113977: 0.035244120023718004,
              2.536553857888872: 0.034336199693965,
              2.560100374663767: 0.03335100158384,
              2.583646891438662: 0.032225313460615,
              2.607193408213557: 0.030913959964151003,
              2.630739924988451: 0.029391857843688002,
              2.654286441763346: 0.027654631386702003,
              2.677832958538241: 0.025717869198438003,
              2.701379475313136: 0.023615124503542,
              2.724925992088031: 0.021394788903596,
              2.748472508862926: 0.019116021947904002,
              2.772019025637821: 0.016843996510176002,
              2.795565542412715: 0.014644806301824001,
              2.81911205918761: 0.012580450244670001,
              2.842658575962505: 0.010704332497248,
              2.8662050927374: 0.009057680337236002,
              2.8897516095122953: 0.0076671841824700005,
              2.913298126287189: 0.006544019804305,
              2.9368446430620843: 0.005684248051819001,
              2.960391159836979: 0.0050704312757660006,
              2.983937676611874: 0.004674183276266001,
              3.007484193386769: 0.004459297241707,
              3.031030710161664: 0.004385079260553,
              3.054577226936559: 0.004409549062429,
              3.078123743711454: 0.004492242619295,
              3.101670260486348: 0.004596446510986001,
              3.125216777261243: 0.004690793414572,
              3.148763294036138: 0.004750234975025001,
              3.172309810811033: 0.004756470025121,
              3.195856327585927: 0.0046979364128240005,
              3.219402844360822: 0.004569474969049,
              3.242949361135717: 0.004371752957704001,
              3.266495877910612: 0.004110504871499001,
              3.290042394685507: 0.00379562439528,
              3.313588911460402: 0.003440132594446,
              3.337135428235297: 0.003059056645814,
              3.360681945010192: 0.002668275616161,
              3.384228461785086: 0.002283414034155,
              3.407774978559981: 0.0019188777731010001,
              3.431321495334876: 0.0015871205626600002,
              3.4548680121097712: 0.001298200297751,
              3.478414528884666: 0.0010596369229280002,
              3.5019610456595602: 0.000876529030852,
              3.525507562434455: 0.000751838372955,
              3.54905407920935: 0.000686723126905,
              3.572600595984245: 0.000680800335863,
              3.59614711275914: 0.000732247102553,
              3.619693629534035: 0.000837703466342,
              3.643240146308929: 0.000992006016003,
              3.6667866630838253: 0.001187845116207,
              3.690333179858719: 0.001415484452148,
              3.7138796966336143: 0.0016626964850310001,
              3.7374262134085092: 0.0019150443744220002,
              3.7609727301834033: 0.0021565812666520003,
              3.784519246958299: 0.0023709519231280003,
              3.808065763733193: 0.0025427874604160004,
              3.831612280508089: 0.002659203458967,
              3.855158797282983: 0.0027111654991770003,
              3.878705314057878: 0.002694487980986,
              3.902251830832773: 0.002610284375112,
              3.9257983476076683: 0.002464779801375,
              3.949344864382562: 0.0022685096470910003,
              3.9728913811574573: 0.002035035358758,
              3.996437897932352: 0.0017793868074480002,
              4.019984414707247: 0.0015164739243470002,
              4.043530931482142: 0.0012596945018280002,
              4.067077448257036: 0.0010199079674120001,
              4.090623965031932: 0.000804863204956,
              4.114170481806826: 0.0006190823701080001,
              4.137716998581721: 0.00046413042612200003,
              4.161263515356616: 0.00033915393888000003,
              4.18481003213151: 0.00024155659805600002,
              4.208356548906406: 0.00016768960935900003,
              4.2319030656813: 0.00011346413536700001,
              4.255449582456195: 7.48300901404852e-05,
              4.27899609923109: 4.8101556906607634e-05,
              4.302542616005985: 3.0137498577331574e-05,
            },
            triangular: {
              '-0.359161551351788': 0.00019674819757900002,
              '-0.348947652581497': 0.00027840137897800003,
              '-0.338733753811206': 0.00038989104604300004,
              '-0.328519855040915': 0.0005404552186950001,
              '-0.318305956270623': 0.000741576105308,
              '-0.30809205750033203': 0.001007321270503,
              '-0.29787815873004103': 0.00135467466963,
              '-0.28766425995975003': 0.001803835834216,
              '-0.27745036118945904': 0.002378461394398,
              '-0.26723646241916704': 0.0031058199214720004,
              '-0.25702256364887605': 0.004016829284385,
              '-0.24680866487858502': 0.005145945843859001,
              '-0.23659476610829402': 0.006530877291091,
              '-0.22638086733800203': 0.008212096075745001,
              '-0.216166968567711': 0.010232138268618001,
              '-0.20595306979742': 0.012634683237409001,
              '-0.195739171027129': 0.015463422281618,
              '-0.18552527225683701': 0.018760738709749,
              '-0.17531137348654602': 0.022566236844437003,
              '-0.16509747471625502': 0.026915172025574002,
              '-0.15488357594596402': 0.031836846670693,
              '-0.144669677175673': 0.03735304767909,
              '-0.134455778405381': 0.043476606887482006,
              '-0.12424187963509001': 0.050210168086236004,
              '-0.11402798086479901': 0.05754524079191001,
              '-0.10381408209450801': 0.065461612430031,
              '-0.093600183324216': 0.07392717710197301,
              '-0.08338628455392501': 0.08289822136953301,
              '-0.07317238578363401': 0.09232018649958501,
              '-0.062958487013343': 0.10212890362019,
              '-0.052744588243052004': 0.11225227462897001,
              '-0.04253068947276': 0.122612348852161,
              '-0.032316790702469005': 0.13312772465733402,
              '-0.022102891932178': 0.14371618754832902,
              '-0.011888993161887': 0.15429748252264702,
              '-0.0016750943915950002': 0.164796109161143,
              0.008538804378695001: 0.17514402328226902,
              0.018752703148986003: 0.18528312903816502,
              0.028966601919277003: 0.19516744991784102,
              0.039180500689568: 0.20476487604599203,
              0.04939439945986: 0.21405839821768202,
              0.059608298230151006: 0.22304675611872501,
              0.06982219700044201: 0.231744449004695,
              0.080036095770733: 0.24018108156820503,
              0.090249994541025: 0.24840004549248001,
              0.10046389331131601: 0.256456567672659,
              0.11067779208160701: 0.26441518828474,
              0.120891690851898: 0.27234676430175303,
              0.13110558962218902: 0.280325124694609,
              0.141319488392481: 0.288423529983423,
              0.151533387162772: 0.29671110836894804,
              0.161747285933063: 0.30524945078938104,
              0.171961184703354: 0.31408954577138504,
              0.18217508347364603: 0.32326922055710605,
              0.19238898224393702: 0.33281122753504605,
              0.20260288101422802: 0.34272207571122404,
              0.21281677978451902: 0.35299165849580305,
              0.22303067855481: 0.36359367540457205,
              0.233244577325102: 0.37448679127207,
              0.243458476095393: 0.385616427534459,
              0.253672374865684: 0.39691704112116405,
              0.263886273635975: 0.40831472165341504,
              0.274100172406267: 0.41972992965717504,
              0.28431407117655805: 0.431080208116755,
              0.29452796994684904: 0.442282725576754,
              0.30474186871714004: 0.45325654777078206,
              0.31495576748743104: 0.46392458137170506,
              0.32516966625772303: 0.474215181798245,
              0.33538356502801403: 0.484063460641224,
              0.345597463798305: 0.49341236127805504,
              0.355811362568596: 0.5022135890452081,
              0.366025261338888: 0.510428482352852,
              0.376239160109179: 0.5180288932042351,
              0.38645305887947: 0.524998112141996,
              0.396666957649761: 0.53133182846315,
              0.406880856420052: 0.537039068226787,
              0.41709475519034406: 0.542143007727601,
              0.42730865396063505: 0.546681526312501,
              0.43752255273092605: 0.550707346162796,
              0.44773645150121705: 0.5542876123253141,
              0.45795035027150904: 0.5575027953364,
              0.46816424904180004: 0.560444849419239,
              0.47837814781209104: 0.5632146263553081,
              0.48859204658238203: 0.565918620902066,
              0.49880594535267403: 0.568665198453018,
              0.5090198441229651: 0.5715605194572231,
              0.5192337428932561: 0.5747044188804751,
              0.5294476416635471: 0.578186516044093,
              0.5396615404338381: 0.58208281736163,
              0.5498754392041301: 0.586453032763344,
              0.5600893379744211: 0.5913387610204891,
              0.5703032367447121: 0.5967626182940631,
              0.5805171355150031: 0.602728298830085,
              0.590731034285294: 0.609221478249453,
              0.600944933055586: 0.616211408672787,
              0.611158831825877: 0.6236530185929801,
              0.621372730596168: 0.63148932263735,
              0.631586629366459: 0.6396539662893971,
              0.641800528136751: 0.64807377300344,
              0.652014426907042: 0.656671217119115,
              0.662228325677333: 0.665366804616983,
              0.672442224447624: 0.6740813937702751,
              0.682656123217916: 0.682738519395451,
              0.692870021988207: 0.691266791106102,
              0.703083920758498: 0.699602415449699,
              0.713297819528789: 0.7076918465190111,
              0.72351171829908: 0.715494506479716,
              0.733725617069372: 0.7229854467604201,
              0.7439395158396631: 0.7301577545829501,
              0.7541534146099541: 0.7370244602325451,
              0.7643673133802451: 0.743619678262173,
              0.7745812121505361: 0.749998727498645,
              0.7847951109208281: 0.756237022519319,
              0.7950090096911191: 0.7624276104741681,
              0.8052229084614101: 0.76867733434795,
              0.8154368072317011: 0.7751017258917311,
              0.8256507060019931: 0.781818855156569,
              0.8358646047722841: 0.7889424749848091,
              0.8460785035425751: 0.796574885369089,
              0.8562924023128661: 0.804799994496588,
              0.8665063010831581: 0.813677064704431,
              0.8767201998534491: 0.823235601118794,
              0.8869340986237401: 0.8334717715199611,
              0.8971479973940311: 0.8443466449006071,
              0.9073618961643221: 0.8557864130073141,
              0.917575794934613: 0.8676846251314341,
              0.927789693704905: 0.8799063329576811,
              0.938003592475196: 0.892293919736002,
              0.948217491245487: 0.9046742847656881,
              0.958431390015779: 0.916866975973164,
              0.96864528878607: 0.928692813333934,
              0.978859187556361: 0.9399825246560941,
              0.989073086326652: 0.9505849214084021,
              0.999286985096943: 0.9603741730101211,
              1.009500883867235: 0.9692557896259061,
              1.019714782637526: 0.9771709919759921,
              1.029928681407817: 0.984099227858485,
              1.040142580178108: 0.9900586849263691,
              1.0503564789484: 0.9951047437713041,
              1.060570377718691: 0.9993264105645311,
              1.0707842764889821: 1.002840860427413,
              1.080998175259273: 1.005786307487783,
              1.091212074029564: 1.008313491678043,
              1.30570394820568: 0.939818520187763,
              1.315917846975971: 0.9112988701681741,
              1.3261317457462631: 0.8787141147578521,
              1.336345644516554: 0.8422359680628511,
              1.346559543286845: 0.8021540561982301,
              1.356773442057136: 0.7588697627883111,
              1.3669873408274271: 0.7128844769507591,
              1.377201239597719: 0.6647827543443621,
              1.38741513836801: 0.6152112669314,
              1.3976290371383011: 0.564854723381606,
              1.407842935908592: 0.514410159402363,
              1.418056834678884: 0.46456110426286806,
              1.428270733449175: 0.41595311623830106,
              1.438484632219466: 0.369172047984961,
              1.448698530989757: 0.324726167422533,
              1.458912429760048: 0.28303294518433303,
              1.46912632853034: 0.24441095779825703,
              1.479340227300631: 0.20907698136393002,
              1.489554126070922: 0.17714799760454503,
              1.499768024841213: 0.148647532229075,
              1.509981923611504: 0.12351551652502502,
              1.5201958223817962: 0.10162071980265701,
              1.530409721152087: 0.082774745842059,
              1.540623619922378: 0.066746615004611,
              1.550837518692669: 0.05327705203531401,
              1.561051417462961: 0.04209174973138,
              1.571265316233252: 0.032913060164047,
              1.581479215003543: 0.025469757738104,
              1.5916931137738342: 0.019504704023825002,
              1.601907012544126: 0.014780408711561001,
              1.612120911314417: 0.011082614498126002,
              1.6223348100847081: 0.008222131214987001,
              1.632548708854999: 0.0060352053653520005,
              1.642762607625291: 0.004382738273050001,
              1.6529765063955821: 0.0031486645604300004,
              1.663190405165873: 0.0022377793612990003,
              1.673404303936164: 0.0015732646372600002,
            },
          },
          {
            actual: {
              '-0.7458329332615281': 0.0019462994344370001,
              '-0.731080542982517': 0.002301798517122,
              '-0.716328152703507': 0.002714003871944,
              '-0.701575762424497': 0.00319036018782,
              '-0.686823372145486': 0.003739002070199,
              '-0.6720709818664761': 0.004368769346689,
              '-0.6573185915874661': 0.005089213758405001,
              '-0.6425662013084551': 0.005910595483718001,
              '-0.627813811029445': 0.006843867918026001,
              '-0.613061420750435': 0.007900649147954,
              '-0.598309030471424': 0.009093178616778,
              '-0.583556640192414': 0.010434257585111,
              '-0.5688042499134041': 0.011937172151474001,
              '-0.5540518596343931': 0.013615597814699001,
              '-0.5392994693553831': 0.01548348483628,
              '-0.524547079076373': 0.017554923996164002,
              '-0.509794688797362': 0.019843992728675,
              '-0.495042298518352': 0.022364582072653003,
              '-0.48028990823934203': 0.025130205365658003,
              '-0.46553751796033105': 0.028153790148079002,
              '-0.450785127681321': 0.031447455308395,
              '-0.436032737402311': 0.035022276083023,
              '-0.42128034712330004': 0.038888040107757,
              '-0.40652795684429005': 0.04305299828602,
              '-0.39177556656528': 0.047523614773489,
              '-0.377023176286269': 0.052304320859659005,
              '-0.36227078600725904': 0.057397277934803,
              '-0.34751839572824905': 0.06280215504596401,
              '-0.332766005449238': 0.06851592674948201,
              '-0.318013615170228': 0.074532697043413,
              '-0.30326122489121804': 0.080843555096599,
              '-0.288508834612207': 0.087436468271378,
              '-0.273756444333197': 0.09429621755683501,
              '-0.259004054054187': 0.10140437998682601,
              '-0.24425166377517601': 0.10873936191456,
              '-0.22949927349616603': 0.11627648616130201,
              '-0.21474688321715601': 0.123988135064582,
              '-0.199994492938145': 0.13184395034023902,
              '-0.18524210265913502': 0.13981108946696702,
              '-0.170489712380125': 0.14785453703085202,
              '-0.15573732210111402': 0.155937468163339,
              '-0.140984931822104': 0.16402165990522602,
              '-0.12623254154309402': 0.172067945069104,
              '-0.111480151264083': 0.18003670199156402,
              '-0.096727760985073': 0.187888372501957,
              '-0.081975370706062': 0.195583999521931,
              '-0.067222980427052': 0.203085774981233,
              '-0.05247059014804201': 0.21035758821757003,
              '-0.037718199869032': 0.21736556474263502,
              '-0.022965809590021': 0.22407858521724802,
              '-0.008213419311011001': 0.23046877469225302,
              0.006538970967998: 0.23651195263718203,
              0.021291361247009002: 0.242188034985956,
              0.036043751526019005: 0.247481380360509,
              0.050796141805030004: 0.25238107376408303,
              0.06554853208404: 0.25688114233441,
              0.08030092236305: 0.260980699175796,
              0.09505331264206: 0.264684012807037,
              0.109805702921071: 0.26800050132499104,
              0.12455809320008102: 0.27094465194675604,
              0.139310483479092: 0.273535868112392,
              0.15406287375810201: 0.27579824776314804,
              0.168815264037112: 0.277760297719274,
              0.18356765431612201: 0.27945459023379104,
              0.19832004459513303: 0.28091736876781104,
              0.213072434874143: 0.282188110799634,
              0.22782482515315303: 0.283309056032286,
              0.242577215432164: 0.28432470869832904,
              0.257329605711174: 0.28528132278018503,
              0.272081995990184: 0.28622637887916,
              0.286834386269195: 0.28720806119339903,
              0.30158677654820504: 0.28827474262540703,
              0.316339166827215: 0.28947448545868804,
              0.331091557106226: 0.290854564347718,
              0.34584394738523605: 0.292461017584057,
              0.36059633766424704: 0.29433823176151,
              0.375348727943257: 0.29652856409064904,
              0.390101118222267: 0.299072005730581,
              0.40485350850127805: 0.302005888632971,
              0.41960589878028803: 0.305364637544846,
              0.434358289059298: 0.309179568003472,
              0.44911067933830806: 0.313478730384784,
              0.46386306961731905: 0.31828679933907905,
              0.47861545989632903: 0.323625007263156,
              0.493367850175339: 0.329511119813926,
              0.50812024045435: 0.335959450860472,
              0.52287263073336: 0.34298091369547,
              0.5376250210123701: 0.350583104779564,
              0.5523774112913811: 0.358770415772793,
              0.5671298015703911: 0.36754416911751303,
              0.581882191849402: 0.37690277198314104,
              0.596634582128412: 0.38684188297431704,
              0.611386972407422: 0.397354585654603,
              0.626139362686433: 0.40843156266517405,
              0.6408917529654431: 0.420061264042887,
              0.6556441432444531: 0.43223006328734304,
              0.6703965335234641: 0.444922394815485,
              0.685148923802474: 0.45812086669759305,
              0.699901314081484: 0.47180634301032204,
              0.714653704360494: 0.485957990786447,
              0.729406094639505: 0.500553287397149,
              0.7441584849185151: 0.515567985273201,
              0.7589108751975251: 0.530976032149804,
              0.7736632654765361: 0.546749446489696,
              0.7884156557555461: 0.562858149373847,
              0.803168046034557: 0.579269755911567,
              0.817920436313567: 0.5959493310652361,
              0.832672826592577: 0.612859116653502,
              0.8474252168715881: 0.629958238127523,
              0.9654443391036701: 0.7674065137802291,
              0.9801967293826801: 0.783720352121076,
              0.9949491196616911: 0.7995886760325981,
              1.009701509940701: 0.8149236673292191,
              1.024453900219712: 0.8296339292124321,
              1.039206290498722: 0.843625075834713,
              1.053958680777732: 0.8568004487246821,
              1.0687110710567431: 0.8690619558090431,
              1.083463461335753: 0.8803110251126761,
              1.098215851614763: 0.890449661584463,
              1.112968241893773: 0.8993815919990891,
              1.127720632172784: 0.9070134796401721,
              1.142473022451794: 0.9132561875893451,
              1.157225412730805: 0.9180260670325371,
              1.1719778030098151: 0.9212462451394531,
              1.186730193288825: 0.92284788584993,
              1.201482583567835: 0.922771396367383,
              1.216234973846846: 0.9209675523493501,
              1.230987364125856: 0.917398515710048,
              1.245739754404867: 0.912038720597879,
              1.260492144683877: 0.9048756054466871,
              1.2752445349628871: 0.895910171965526,
              1.289996925241897: 0.8851573554495351,
              1.304749315520908: 0.8726461947678461,
              1.319501705799918: 0.8584197947025791,
              1.334254096078928: 0.842535077853879,
              1.349006486357939: 0.825062327961213,
              1.363758876636949: 0.806084531089812,
              1.3785112669159592: 0.7856965255643341,
              1.3932636571949701: 0.7640039756769831,
              1.40801604747398: 0.7411221879422161,
              1.4227684377529901: 0.717174791916337,
              1.437520828032001: 0.6922923102661801,
              1.452273218311011: 0.666610644794218,
              1.467025608590022: 0.640269506466089,
              1.481777998869032: 0.613410818120531,
              1.4965303891480422: 0.586177118472308,
              1.511282779427052: 0.558709995268249,
              1.526035169706063: 0.5311485740663761,
              1.5407875599850729: 0.503628087137438,
              1.555539950264083: 0.47627854451052504,
              1.570292340543094: 0.449223526285484,
              1.585044730822104: 0.422579112108647,
              1.5997971211011142: 0.39645296025422505,
              1.6145495113801251: 0.37094354517262,
              1.629301901659135: 0.346139558758605,
              1.644054291938145: 0.32211947705154503,
              1.658806682217156: 0.298951290694615,
              1.673559072496166: 0.27669239432820303,
              1.688311462775177: 0.255389627241188,
              1.703063853054187: 0.23507945510640202,
              1.7178162433331972: 0.21578828052359,
              1.732568633612207: 0.19753286841099002,
              1.747321023891218: 0.18032087103782302,
              1.7620734141702281: 0.164151436674075,
              1.776825804449238: 0.14901588543837202,
              1.791578194728249: 0.134898435926184,
              1.806330585007259: 0.121776966566512,
              1.8210829752862692: 0.10962379634578501,
              1.8358353655652802: 0.098406470507455,
              1.85058775584429: 0.08808853803560401,
              1.8653401461233: 0.078630309109877,
              1.880092536402311: 0.069989582226089,
              1.894844926681321: 0.062122332262325004,
              1.909597316960332: 0.05498335238728701,
              1.924349707239342: 0.048526844313246005,
              1.9391020975183522: 0.042706952951847,
              1.953854487797362: 0.037478243004618,
              1.968606878076373: 0.032796116384301,
              1.9833592683553831: 0.028617170597195003,
              1.998111658634393: 0.024899499305434003,
              2.012864048913404: 0.02160293722223,
              2.027616439192414: 0.018689252268508,
              2.042368829471424: 0.016122288536701,
              2.057121219750435: 0.013868064071628002,
              2.071873610029445: 0.011894827797688001,
              2.086626000308456: 0.010173080107206,
              2.101378390587466: 0.008675561689864001,
              2.116130780866476: 0.007377215142491,
              2.130883171145487: 0.006255123767512001,
              2.145635561424497: 0.00528843176278,
              2.160387951703507: 0.0044582497409170006,
              2.175140341982517: 0.0037475492074040004,
              2.189892732261528: 0.0031410492873720003,
            },
            gamma: {
              '-0.7635019647947361': 4.0581994405308256e-05,
              '-0.743635816525764': 6.84066403465038e-05,
              '-0.723769668256793': 0.00011189882199100001,
              '-0.7039035199878211': 0.00017768789347000002,
              '-0.684037371718849': 0.000274012213858,
              '-0.664171223449877': 0.000410558170433,
              '-0.6443050751809061': 0.0005980400571130001,
              '-0.624438926911934': 0.000847514678304,
              '-0.46550974076016005': 0.005863228769486001,
              '-0.445643592491188': 0.006860920527053001,
              '-0.42577744422221603': 0.007921339900794001,
              '-0.40591129595324404': 0.009036037290107001,
              '-0.386045147684273': 0.010195859809327001,
              '-0.366178999415301': 0.011390905782508001,
              '-0.34631285114632904': 0.012611025654701001,
              '-0.326446702877357': 0.013847232010709,
              '-0.306580554608386': 0.015094190221549002,
              '-0.28671440633941403': 0.016353676832928002,
              '-0.26684825807044205': 0.017638590918992,
              '-0.24698210980147': 0.018976862736694,
              '-0.22711596153249902': 0.020414481887274002,
              '-0.207249813263527': 0.022016890792821,
              '-0.18738366499455503': 0.023868159377266003,
              '-0.16751751672558302': 0.026067659118341004,
              '-0.147651368456611': 0.028724365840665,
              '-0.12778522018764002': 0.031949402120125,
              '-0.10791907191866801': 0.035847912474116006,
              '-0.08805292364969601': 0.040511736304469005,
              '-0.06818677538072501': 0.046014462031620004,
              '-0.048320627111753': 0.052410177249491006,
              '-0.028454478842781003': 0.059736512579777,
              '-0.008588330573809': 0.06802148339887601,
              0.011277817695162002: 0.077292396296049,
              0.031143965964133002: 0.08758406554444001,
              0.051010114233105: 0.09894316877701101,
              0.070876262502077: 0.111426045192358,
              0.09074241077104901: 0.125088657148639,
              0.11060855904002001: 0.13996955239147002,
              0.13047470730899202: 0.15606896618104502,
              0.150340855577964: 0.17332903455539903,
              0.17020700384693602: 0.191620843004551,
              0.190073152115907: 0.21074334015283003,
              0.209939300384879: 0.23043700946939302,
              0.22980544865385102: 0.250412024630276,
              0.249671596922823: 0.27038714614423603,
              0.269537745191794: 0.29013271600573404,
              0.289403893460766: 0.30950954954139603,
              0.30927004172973804: 0.32849577720716705,
              0.32913618999871: 0.34719576911320704,
              0.349002338267681: 0.36582871646104903,
              0.36886848653665305: 0.384698410102694,
              0.38873463480562503: 0.40414926632476,
              0.408600783074597: 0.42451586815296805,
              0.42846693134356806: 0.44607378662559005,
              0.44833307961254004: 0.46899832904267,
              0.468199227881512: 0.49333571953612604,
              0.48806537615048406: 0.518988877153314,
              0.507931524419456: 0.5457181360851271,
              0.527797672688427: 0.573156262922162,
              0.5476638209573991: 0.60083675747675,
              0.567529969226371: 0.6282340857210611,
              0.587396117495343: 0.654813555943873,
              0.6072622657643141: 0.6800867361374401,
              0.627128414033286: 0.7036659475886731,
              0.646994562302258: 0.7253093477650651,
              0.6668607105712301: 0.74494755671441,
              0.686726858840201: 0.7626845352593241,
              0.706593007109173: 0.77876961821181,
              0.7264591553781451: 0.79354346191832,
              0.746325303647117: 0.807366676819381,
              0.7661914519160881: 0.8205443423216541,
              0.7860576001850601: 0.833261076936408,
              0.805923748454032: 0.8455393483390221,
              0.8257898967230041: 0.8572287602878711,
              0.8456560449919751: 0.8680274692198171,
              0.865522193260947: 0.8775303441769611,
              0.8853883415299191: 0.8852935043936601,
              0.9052544897988911: 0.8909024040645591,
              0.925120638067862: 0.8940309271658811,
              0.9449867863368341: 0.8944816256547591,
              0.9648529346058061: 0.8922015033563191,
              0.984719082874778: 0.887272685173012,
              1.00458523114375: 0.879882015398727,
              1.024451379412721: 0.8702773250640131,
              1.044317527681693: 0.8587202008367391,
              1.064183675950665: 0.8454452163207521,
              1.084049824219637: 0.830633701632222,
              1.103915972488608: 0.8144065624107021,
              1.12378212075758: 0.7968361384886841,
              1.143648269026552: 0.7779726237062411,
              1.163514417295524: 0.7578772217163171,
              1.183380565564495: 0.7366528306649951,
              1.203246713833467: 0.714463988613096,
              1.223112862102439: 0.6915408110414131,
              1.242979010371411: 0.6681658914174,
              1.262845158640382: 0.6446474699933741,
              1.282711306909354: 0.6212854700072921,
              1.302577455178326: 0.59833845464776,
              1.322443603447298: 0.575998914742972,
              1.342309751716269: 0.5543818709235431,
              1.3621758999852411: 0.533528313899425,
              1.382042048254213: 0.5134214503395791,
              1.401908196523185: 0.494010932618739,
              1.4217743447921571: 0.47523881249375205,
              1.441640493061128: 0.45706107674913504,
              1.4615066413301: 0.439460130324248,
              1.481372789599072: 0.422446038153625,
              1.501238937868043: 0.40604711399672705,
              1.521105086137015: 0.390292926872545,
              1.540971234405987: 0.37519446020192504,
              1.560837382674959: 0.360726679927447,
              1.580703530943931: 0.34681807622787003,
              1.600569679212902: 0.333350037307416,
              1.6204358274818742: 0.32016661634486004,
              1.640301975750846: 0.30709291484358303,
              1.6601681240198172: 0.29395847068485204,
              1.680034272288789: 0.280621104195223,
              1.699900420557761: 0.266986791205614,
              1.7197665688267332: 0.253022176559213,
              1.739632717095705: 0.238757978337774,
              1.7594988653646761: 0.22428333085714303,
              1.779365013633648: 0.209732680804111,
              1.79923116190262: 0.19526792803519002,
              1.8190973101715922: 0.18105899402709202,
              1.838963458440563: 0.167265933688159,
              1.8588296067095351: 0.154025165640879,
              1.878695754978507: 0.141441480268685,
              1.898561903247479: 0.12958630440617502,
              1.9184280515164511: 0.11850141494429701,
              1.938294199785422: 0.10820613679091201,
              1.958160348054394: 0.098705327031309,
              1.978026496323366: 0.089995401794769,
              1.997892644592337: 0.08206641877665301,
              2.017758792861309: 0.074899650923476,
              2.037624941130281: 0.068461781185011,
              2.057491089399253: 0.062698266701978,
              2.077357237668224: 0.057529052065101005,
              2.097223385937196: 0.052849380082714004,
              2.117089534206168: 0.048537037995319006,
              2.13695568247514: 0.044465417567714,
              2.156821830744111: 0.040519891160430005,
              2.176687979013083: 0.036613823928207,
              2.196554127282055: 0.032700431654821005,
              2.216420275551027: 0.028777674130209003,
              2.236286423819999: 0.024885116861502,
              2.256152572088971: 0.021093651981858003,
              2.276018720357942: 0.017490570752875002,
              2.295884868626914: 0.014163313364434,
              2.315751016895886: 0.011185146409635001,
              2.335617165164858: 0.008605173783183,
              2.355483313433829: 0.006443808723459001,
              2.47468020304766: 0.0006361816623270001,
              2.494546351316631: 0.00040281154981500005,
              2.514412499585603: 0.000262999112335,
              2.534278647854575: 0.00019175804460700002,
              2.554144796123547: 0.00017287353723200003,
              2.574010944392519: 0.000197586395456,
              2.593877092661491: 0.000262985028226,
              2.613743240930462: 0.000370242465713,
              2.6336093891994343: 0.000522743114606,
              2.653475537468406: 0.000724116665316,
              2.673341685737377: 0.0009762358338980001,
              2.693207834006349: 0.0012773206161630002,
              2.713073982275321: 0.0016203881449440001,
              2.732940130544293: 0.001992349751282,
              2.752806278813264: 0.002374045266239,
              2.7726724270822363: 0.002741396827703,
              2.792538575351208: 0.003067667702629,
              2.812404723620179: 0.003326566051942,
              2.832270871889151: 0.0034957044195330004,
              2.852137020158123: 0.003559784639378,
              2.872003168427095: 0.003512878502278,
              2.891869316696067: 0.003359331699824,
              2.911735464965039: 0.003113099421563,
              2.931601613234011: 0.0027956540849100003,
              2.951467761502982: 0.002432899762161,
              2.971333909771954: 0.002051706984269,
              2.991200058040926: 0.001676705528613,
              3.011066206309897: 0.0013278487050130002,
              3.030932354578869: 0.001019038825319,
              3.050798502847841: 0.0007578499145440001,
              3.070664651116812: 0.000546167708646,
              3.090530799385784: 0.00038143379742500003,
              3.110396947654756: 0.00025814437266200003,
              3.130263095923728: 0.000169299818032,
              3.150129244192699: 0.00010759712297100001,
              3.169995392461671: 6.626667272564233e-05,
              3.189861540730643: 3.954941005683986e-05,
            },
            geo_level_type: 'United States',
            halfnorm: {
              '-0.38321422219808704': 0.001236087409005,
              '-0.35966770542319304': 0.00206114710942,
              '-0.33612118864829804': 0.0033554851768760005,
              '-0.31257467187340304': 0.005333933163486001,
              '-0.28902815509850804': 0.008280424408829,
              '-0.26548163832361305': 0.012555842436524,
              '-0.24193512154871802': 0.018599985529733,
              '-0.21838860477382302': 0.026924601558109003,
              '-0.19484208799892902': 0.038094743171354,
              '-0.17129557122403402': 0.05269670445835,
              '-0.14774905444913902': 0.07129257153913901,
              '-0.12420253767424401': 0.094363807608925,
              '-0.10065602089934901': 0.12224893716328401,
              '-0.07710950412445401': 0.15508275089869,
              '-0.053562987349560005': 0.192745899926471,
              '-0.030016470574665003': 0.23483374522656802,
              '-0.006469953799770001': 0.28065159332913203,
              0.017076562975124002: 0.32924009136276905,
              0.040623079750019005: 0.379430110972003,
              0.06416959652491401: 0.42992179361666505,
              0.08771611329980801: 0.47937855722556805,
              0.11126263007470301: 0.5265246282074411,
              0.13480914684959802: 0.570234523769101,
              0.15835566362449302: 0.609604800510242,
              0.18190218039938802: 0.644001739369068,
              0.20544869717428302: 0.67308257155441,
              0.22899521394917802: 0.6967914458286351,
              0.25254173072407204: 0.7153339134865011,
              0.27608824749896704: 0.729134996022167,
              0.29963476427386204: 0.738786065935609,
              0.32318128104875704: 0.744985275495143,
              0.34672779782365204: 0.7484756470388051,
              0.37027431459854704: 0.749984577011189,
              0.39382083137344104: 0.750168502267548,
              0.41736734814833604: 0.749566635229274,
              0.44091386492323104: 0.7485676205322811,
              0.46446038169812603: 0.7473923320870861,
              0.48800689847302103: 0.7460946289816021,
              0.5115534152479161: 0.7445798146152821,
              0.53509993202281: 0.742638159898219,
              0.5586464487977051: 0.7399886840284751,
              0.5821929655726: 0.7363269574823591,
              0.6057394823474951: 0.7313703579703701,
              0.62928599912239: 0.7248950560240791,
              0.6528325158972851: 0.716760825282716,
              0.676379032672179: 0.7069221294111071,
              0.6999255494470741: 0.695426297281971,
              0.723472066221969: 0.682401467627589,
              0.7470185829968641: 0.668038035143738,
              0.770565099771759: 0.652567465620143,
              0.7941116165466541: 0.636241704684193,
              0.817658133321548: 0.6193152917112701,
              0.8412046500964431: 0.6020310861989681,
              0.864751166871338: 0.584609555877004,
              0.8882976836462331: 0.5672410700182781,
              0.9118442004211281: 0.5500806180836151,
              0.9353907171960231: 0.5332446993377581,
              0.9589372339709171: 0.516810567134341,
              0.9824837507458121: 0.500818312131412,
              1.006030267520707: 0.48527625457106904,
              1.029576784295602: 0.47016973889977104,
              1.053123301070497: 0.45547277485941806,
              1.076669817845392: 0.44116123924009704,
              1.1002163346202871: 0.42722576648076405,
              1.123762851395181: 0.41368219948614005,
              1.1473093681700761: 0.40057763726223106,
              1.170855884944971: 0.38799068542243503,
              1.194402401719866: 0.37602537760589605,
              1.217948918494761: 0.36479922503440904,
              1.2414954352696561: 0.35442679447805103,
              1.26504195204455: 0.34500096597067004,
              1.2885884688194449: 0.336574479966527,
              1.31213498559434: 0.32914449493913,
              1.335681502369235: 0.322642625964819,
              1.3592280191441302: 0.31693234144154603,
              1.382774535919025: 0.31181471084392404,
              1.4063210526939192: 0.30704241065779003,
              1.429867569468814: 0.302340737102942,
              1.453414086243709: 0.297433303976957,
              1.476960603018604: 0.292069295037855,
              1.500507119793499: 0.286048746497444,
              1.5240536365683939: 0.279242455646708,
              1.547600153343289: 0.27160376284443705,
              1.571146670118183: 0.26317055759678204,
              1.594693186893078: 0.254057250491164,
              1.6182397036679732: 0.24443790847649702,
              1.6417862204428681: 0.23452303148880901,
              1.665332737217763: 0.224533341071036,
              1.6888792539926571: 0.214674309286447,
              1.712425770767552: 0.20511492235434,
              1.735972287542447: 0.195973390804335,
              1.759518804317342: 0.187311320778234,
              1.783065321092237: 0.179136453089208,
              1.806611837867132: 0.17141269817634802,
              1.830158354642027: 0.164075083883115,
              1.8537048714169222: 0.15704658281906803,
              1.8772513881918171: 0.150253709658202,
              1.9007979049667112: 0.143638281932391,
              1.9243444217416061: 0.137163712398563,
              1.947890938516501: 0.1308154408041,
              1.971437455291396: 0.12459635409659,
              1.99498397206629: 0.11851902272336401,
              2.018530488841186: 0.11259709366124401,
              2.042077005616081: 0.10683813557621501,
              2.065623522390976: 0.10123966812481001,
              2.08917003916587: 0.095789186584327,
              2.112716555940765: 0.09046795219225201,
              2.13626307271566: 0.085257408956631,
              2.159809589490555: 0.080146510490499,
              2.183356106265449: 0.075138100745474,
              2.206902623040344: 0.070252782492265,
              2.230449139815239: 0.065529320398446,
              2.253995656590134: 0.061021393993064006,
              2.277542173365029: 0.056791258819495004,
              2.301088690139924: 0.05290144085852701,
              2.324635206914819: 0.0494058876727,
              2.348181723689714: 0.046342005833402006,
              2.371728240464608: 0.043724765342846,
              2.395274757239503: 0.041543628052865,
              2.418821274014398: 0.039762558579588006,
              2.442367790789293: 0.038322901083333,
              2.465914307564188: 0.037148532277988006,
              2.489460824339082: 0.036152478016572,
              2.513007341113977: 0.035244120023718004,
              2.536553857888872: 0.034336199693965,
              2.560100374663767: 0.03335100158384,
              2.583646891438662: 0.032225313460615,
              2.607193408213557: 0.030913959964151003,
              2.630739924988451: 0.029391857843688002,
              2.654286441763346: 0.027654631386702003,
              2.677832958538241: 0.025717869198438003,
              2.701379475313136: 0.023615124503542,
              2.724925992088031: 0.021394788903596,
              2.748472508862926: 0.019116021947904002,
              2.772019025637821: 0.016843996510176002,
              2.795565542412715: 0.014644806301824001,
              2.81911205918761: 0.012580450244670001,
              2.842658575962505: 0.010704332497248,
              2.8662050927374: 0.009057680337236002,
              2.8897516095122953: 0.0076671841824700005,
              2.913298126287189: 0.006544019804305,
              2.9368446430620843: 0.005684248051819001,
              2.960391159836979: 0.0050704312757660006,
              2.983937676611874: 0.004674183276266001,
              3.195856327585927: 0.0046979364128240005,
              3.219402844360822: 0.004569474969049,
              3.242949361135717: 0.004371752957704001,
              3.266495877910612: 0.004110504871499001,
              3.290042394685507: 0.00379562439528,
              3.313588911460402: 0.003440132594446,
              3.337135428235297: 0.003059056645814,
              3.360681945010192: 0.002668275616161,
              3.384228461785086: 0.002283414034155,
              3.407774978559981: 0.0019188777731010001,
              3.431321495334876: 0.0015871205626600002,
              3.4548680121097712: 0.001298200297751,
              3.478414528884666: 0.0010596369229280002,
              3.5019610456595602: 0.000876529030852,
              3.525507562434455: 0.000751838372955,
              3.54905407920935: 0.000686723126905,
              3.572600595984245: 0.000680800335863,
              3.59614711275914: 0.000732247102553,
              3.619693629534035: 0.000837703466342,
              3.643240146308929: 0.000992006016003,
              3.6667866630838253: 0.001187845116207,
              3.690333179858719: 0.001415484452148,
              3.7138796966336143: 0.0016626964850310001,
              3.7374262134085092: 0.0019150443744220002,
              3.7609727301834033: 0.0021565812666520003,
              3.784519246958299: 0.0023709519231280003,
              3.808065763733193: 0.0025427874604160004,
              3.831612280508089: 0.002659203458967,
              3.855158797282983: 0.0027111654991770003,
              3.878705314057878: 0.002694487980986,
              3.902251830832773: 0.002610284375112,
              3.9257983476076683: 0.002464779801375,
              3.949344864382562: 0.0022685096470910003,
              3.9728913811574573: 0.002035035358758,
              3.996437897932352: 0.0017793868074480002,
              4.019984414707247: 0.0015164739243470002,
              4.043530931482142: 0.0012596945018280002,
              4.067077448257036: 0.0010199079674120001,
              4.090623965031932: 0.000804863204956,
              4.114170481806826: 0.0006190823701080001,
            },
            triangular: {
              '-0.359161551351788': 0.00019674819757900002,
              '-0.348947652581497': 0.00027840137897800003,
              '-0.338733753811206': 0.00038989104604300004,
              '-0.328519855040915': 0.0005404552186950001,
              '-0.16509747471625502': 0.026915172025574002,
              '-0.022102891932178': 0.14371618754832902,
              '-0.011888993161887': 0.15429748252264702,
              '-0.0016750943915950002': 0.164796109161143,
              0.008538804378695001: 0.17514402328226902,
              0.018752703148986003: 0.18528312903816502,
              0.028966601919277003: 0.19516744991784102,
              0.039180500689568: 0.20476487604599203,
              0.04939439945986: 0.21405839821768202,
              0.059608298230151006: 0.22304675611872501,
              0.06982219700044201: 0.231744449004695,
              0.080036095770733: 0.24018108156820503,
              0.090249994541025: 0.24840004549248001,
              0.10046389331131601: 0.256456567672659,
              0.11067779208160701: 0.26441518828474,
              0.120891690851898: 0.27234676430175303,
              0.13110558962218902: 0.280325124694609,
              0.141319488392481: 0.288423529983423,
              0.151533387162772: 0.29671110836894804,
              0.161747285933063: 0.30524945078938104,
              0.171961184703354: 0.31408954577138504,
              0.18217508347364603: 0.32326922055710605,
              0.19238898224393702: 0.33281122753504605,
              0.20260288101422802: 0.34272207571122404,
              0.21281677978451902: 0.35299165849580305,
              0.22303067855481: 0.36359367540457205,
              0.233244577325102: 0.37448679127207,
              0.243458476095393: 0.385616427534459,
              0.253672374865684: 0.39691704112116405,
              0.263886273635975: 0.40831472165341504,
              0.274100172406267: 0.41972992965717504,
              0.28431407117655805: 0.431080208116755,
              0.29452796994684904: 0.442282725576754,
              0.30474186871714004: 0.45325654777078206,
              0.31495576748743104: 0.46392458137170506,
              0.32516966625772303: 0.474215181798245,
              0.33538356502801403: 0.484063460641224,
              0.345597463798305: 0.49341236127805504,
              0.355811362568596: 0.5022135890452081,
              0.366025261338888: 0.510428482352852,
              0.376239160109179: 0.5180288932042351,
              0.38645305887947: 0.524998112141996,
              0.396666957649761: 0.53133182846315,
              0.406880856420052: 0.537039068226787,
              0.41709475519034406: 0.542143007727601,
              0.42730865396063505: 0.546681526312501,
              0.43752255273092605: 0.550707346162796,
              0.44773645150121705: 0.5542876123253141,
              0.45795035027150904: 0.5575027953364,
              0.46816424904180004: 0.560444849419239,
              0.47837814781209104: 0.5632146263553081,
              0.48859204658238203: 0.565918620902066,
              0.49880594535267403: 0.568665198453018,
              0.5090198441229651: 0.5715605194572231,
              0.5192337428932561: 0.5747044188804751,
              0.5294476416635471: 0.578186516044093,
              0.5396615404338381: 0.58208281736163,
              0.5498754392041301: 0.586453032763344,
              0.5600893379744211: 0.5913387610204891,
              0.5703032367447121: 0.5967626182940631,
              0.5805171355150031: 0.602728298830085,
              0.590731034285294: 0.609221478249453,
              0.600944933055586: 0.616211408672787,
              0.611158831825877: 0.6236530185929801,
              0.621372730596168: 0.63148932263735,
              0.631586629366459: 0.6396539662893971,
              0.641800528136751: 0.64807377300344,
              0.652014426907042: 0.656671217119115,
              0.662228325677333: 0.665366804616983,
              0.672442224447624: 0.6740813937702751,
              0.682656123217916: 0.682738519395451,
              0.692870021988207: 0.691266791106102,
              0.703083920758498: 0.699602415449699,
              0.713297819528789: 0.7076918465190111,
              0.72351171829908: 0.715494506479716,
              0.733725617069372: 0.7229854467604201,
              0.7439395158396631: 0.7301577545829501,
              0.7541534146099541: 0.7370244602325451,
              0.7643673133802451: 0.743619678262173,
              0.7745812121505361: 0.749998727498645,
              0.7847951109208281: 0.756237022519319,
              0.7950090096911191: 0.7624276104741681,
              0.8052229084614101: 0.76867733434795,
              0.8154368072317011: 0.7751017258917311,
              0.8256507060019931: 0.781818855156569,
              0.8358646047722841: 0.7889424749848091,
              0.8460785035425751: 0.796574885369089,
              0.8562924023128661: 0.804799994496588,
              0.8665063010831581: 0.813677064704431,
              0.8767201998534491: 0.823235601118794,
              0.8869340986237401: 0.8334717715199611,
              0.8971479973940311: 0.8443466449006071,
              0.9073618961643221: 0.8557864130073141,
              0.917575794934613: 0.8676846251314341,
              0.927789693704905: 0.8799063329576811,
              0.938003592475196: 0.892293919736002,
              0.948217491245487: 0.9046742847656881,
              0.958431390015779: 0.916866975973164,
              0.96864528878607: 0.928692813333934,
              0.978859187556361: 0.9399825246560941,
              0.989073086326652: 0.9505849214084021,
              0.999286985096943: 0.9603741730101211,
              1.009500883867235: 0.9692557896259061,
              1.019714782637526: 0.9771709919759921,
              1.029928681407817: 0.984099227858485,
              1.040142580178108: 0.9900586849263691,
              1.0503564789484: 0.9951047437713041,
              1.060570377718691: 0.9993264105645311,
              1.0707842764889821: 1.002840860427413,
              1.080998175259273: 1.005786307487783,
              1.091212074029564: 1.008313491678043,
              1.101425972799856: 1.010576132834108,
              1.111639871570147: 1.012720747619987,
              1.121853770340438: 1.0148762535256,
              1.132067669110729: 1.017143797276996,
              1.142281567881021: 1.019587244214403,
              1.152495466651312: 1.022224752988338,
              1.162709365421603: 1.025021838782684,
              1.172923264191894: 1.027886299948896,
              1.183137162962185: 1.030665347759643,
              1.193351061732477: 1.033145235450184,
              1.203564960502768: 1.035053627456211,
              1.213778859273059: 1.03606487809901,
              1.22399275804335: 1.035808295939667,
              1.234206656813641: 1.033879351768597,
              1.244420555583933: 1.029853643542965,
              1.254634454354224: 1.023303263459948,
              1.2648483531245152: 1.013815028727797,
              1.275062251894806: 1.00100985167385,
              1.285276150665098: 0.984562354312257,
              1.2954900494353891: 0.9642196979009421,
              1.30570394820568: 0.939818520187763,
              1.315917846975971: 0.9112988701681741,
              1.3261317457462631: 0.8787141147578521,
              1.336345644516554: 0.8422359680628511,
              1.346559543286845: 0.8021540561982301,
              1.356773442057136: 0.7588697627883111,
              1.3669873408274271: 0.7128844769507591,
              1.377201239597719: 0.6647827543443621,
              1.38741513836801: 0.6152112669314,
              1.3976290371383011: 0.564854723381606,
              1.407842935908592: 0.514410159402363,
              1.418056834678884: 0.46456110426286806,
              1.428270733449175: 0.41595311623830106,
              1.438484632219466: 0.369172047984961,
              1.448698530989757: 0.324726167422533,
              1.458912429760048: 0.28303294518433303,
              1.46912632853034: 0.24441095779825703,
              1.479340227300631: 0.20907698136393002,
              1.489554126070922: 0.17714799760454503,
              1.499768024841213: 0.148647532229075,
              1.509981923611504: 0.12351551652502502,
              1.5201958223817962: 0.10162071980265701,
              1.530409721152087: 0.082774745842059,
              1.540623619922378: 0.066746615004611,
              1.550837518692669: 0.05327705203531401,
              1.561051417462961: 0.04209174973138,
              1.571265316233252: 0.032913060164047,
              1.581479215003543: 0.025469757738104,
              1.5916931137738342: 0.019504704023825002,
              1.601907012544126: 0.014780408711561001,
              1.612120911314417: 0.011082614498126002,
              1.6223348100847081: 0.008222131214987001,
              1.632548708854999: 0.0060352053653520005,
              1.642762607625291: 0.004382738273050001,
              1.6529765063955821: 0.0031486645604300004,
              1.663190405165873: 0.0022377793612990003,
              1.673404303936164: 0.0015732646372600002,
            },
          },
        ],
        geo_name_list: [
          'Belgium',
          'Canada',
          'Denmark',
          'Germany',
          'New Zealand',
          'United States',
        ],
        global_level_distribution: {
          actual: {
            '-0.6452434245888781': 0.0013253166634940002,
            '-0.630953319055322': 0.0015967400594980002,
            '-0.616663213521765': 0.0019166746694340001,
            '-0.6023731079882081': 0.0022922605483800002,
            '-0.5880830024546521': 0.002731384773015,
            '-0.573792896921095': 0.0032427033544980004,
            '-0.559502791387539': 0.0038356527611770005,
            '-0.5452126858539821': 0.0045204489772780005,
            '-0.530922580320426': 0.005308072012432,
            '-0.516632474786869': 0.006210233832678,
            '-0.502342369253312': 0.0072393278161030005,
            '-0.488052263719756': 0.008408358054087,
            '-0.47376215818619904': 0.009730847128554002,
            '-0.459472052652643': 0.011220721400144,
            '-0.44518194711908604': 0.01289217334214,
            '-0.43089184158553': 0.014759501046297,
            '-0.41660173605197304': 0.016836925701333,
            '-0.402311630518416': 0.019138388589529,
            '-0.38802152498486003': 0.021677329943763,
            '-0.373731419451303': 0.024466452833448,
            '-0.35944131391774703': 0.027517476075936003,
            '-0.34515120838419': 0.030840880968808004,
            '-0.330861102850634': 0.034445657373761006,
            '-0.31657099731707705': 0.038339055318638,
            '-0.30228089178352': 0.04252634878407,
            '-0.28799078624996405': 0.047010618670146005,
            '-0.273700680716407': 0.051792562064442,
            '-0.25941057518285104': 0.056870334828555004,
            '-0.24512046964929401': 0.062239434165711004,
            '-0.230830364115738': 0.06789262721514701,
            '-0.216540258582181': 0.07381993083817301,
            '-0.202250153048624': 0.08000864662523001,
            '-0.187960047515068': 0.08644345378427101,
            '-0.173669941981511': 0.09310656100155501,
            '-0.159379836447955': 0.09997791664146001,
            '-0.145089730914398': 0.10703547482771401,
            '-0.130799625380842': 0.11425551308909901,
            '-0.11650951984728501': 0.12161299542941902,
            '-0.10221941431372801': 0.129081972969483,
            '-0.08792930878017201': 0.13663601278377202,
            '-0.073639203246615': 0.144248644289303,
            '-0.059349097713059004': 0.15189381160505702,
            '-0.045058992179502': 0.15954631974340303,
            '-0.030768886645946004': 0.16718226236255002,
            '-0.016478781112389002': 0.17477941912713402,
            '-0.0021886755788320003': 0.182317611499716,
            0.012101429954723: 0.189779007006065,
            0.02639153548828: 0.197148363647756,
            0.040681641021836: 0.20441320812266703,
            0.054971746555393004: 0.21156394378464502,
            0.069261852088949: 0.218593886739083,
            0.083551957622506: 0.22549923103034603,
            0.097842063156063: 0.232278946420532,
            0.112132168689619: 0.238934614675014,
            0.126422274223176: 0.245470212448812,
            0.140712379756732: 0.251891850707464,
            0.155002485290289: 0.25820748202841903,
            0.169292590823845: 0.26442658804385605,
            0.18358269635740201: 0.27055985965545803,
            0.19787280189095902: 0.27661888245342103,
            0.21216290742451502: 0.28261583901062004,
            0.22645301295807202: 0.28856323843069304,
            0.24074311849162802: 0.29447368176500804,
            0.255033224025185: 0.30035966976160705,
            0.269323329558741: 0.306233456973628,
            0.283613435092298: 0.31210695365547503,
            0.297903540625855: 0.31799167424254,
            0.31219364615941103: 0.32389872867839803,
            0.326483751692968: 0.329838850552777,
            0.34077385722652404: 0.335822454065049,
            0.355063962760081: 0.341859710335908,
            0.36935406829363704: 0.34796063263651605,
            0.383644173827194: 0.35413515974560805,
            0.39793427936075004: 0.36039322690622805,
            0.412224384894307: 0.36674481472894005,
            0.42651449042786405: 0.37319996783881904,
            0.44080459596142: 0.37976877702027,
            0.45509470149497705: 0.38646132097954905,
            0.469384807028533: 0.39328756649942803,
            0.48367491256209005: 0.400257228565467,
            0.49796501809564603: 0.407379594849811,
            0.512255123629203: 0.414663321593138,
            0.52654522916276: 0.42211621027956703,
            0.5408353346963161: 0.42974497641532605,
            0.5551254402298731: 0.437555023080794,
            0.569415545763429: 0.44555023263334603,
            0.583705651296986: 0.45373278993117705,
            0.5979957568305421: 0.462103049696352,
            0.6122858623640991: 0.47065945914508306,
            0.626575967897656: 0.47939854482822203,
            0.640866073431212: 0.48831496982444705,
            0.6551561789647691: 0.49740166412469305,
            0.6694462844983251: 0.50665002738006,
            0.683736390031882: 0.516050199319935,
            0.698026495565438: 0.525591389261691,
            0.7123166010989951: 0.535262252414967,
            0.726606706632552: 0.5450512973185561,
            0.740896812166108: 0.554947305913415,
            0.7551869176996651: 0.564939745611107,
            0.7694770232332211: 0.575019151397282,
            0.783767128766778: 0.5851774556171421,
            0.798057234300334: 0.5954082436889081,
            0.8123473398338911: 0.6057069156053091,
            0.8266374453674481: 0.616070735691405,
            0.840927550901004: 0.6264987566247591,
            0.855217656434561: 0.636991608083446,
            0.8695077619681171: 0.647551145421817,
            0.8837978675016741: 0.658179959302391,
            0.89808797303523: 0.6688807530266021,
            0.912378078568787: 0.679655600180274,
            0.9266681841023441: 0.690505100904462,
            0.9409582896359001: 0.7014274603816381,
            0.955248395169457: 0.7124175177637131,
            0.9695385007030131: 0.7234657575544821,
            0.9838286062365701: 0.734557338215813,
            0.9981187117701261: 0.7456711743521071,
            1.012408817303683: 0.756779109142351,
            1.02669892283724: 0.7678452126827681,
            1.040989028370796: 0.778825239575846,
            1.055279133904353: 0.7896662755052221,
            1.06956923943791: 0.800306597772501,
            1.083859344971466: 0.810675768990023,
            1.098149450505022: 0.8206949765120891,
            1.112439556038579: 0.8302776229690091,
            1.126729661572135: 0.83933016569087,
            1.141019767105692: 0.847753195133439,
            1.155309872639249: 0.8554427349137781,
            1.169599978172805: 0.862291738988695,
            1.183890083706362: 0.86819175510933,
            1.198180189239918: 0.8730347181790601,
            1.2124702947734751: 0.8767148327142841,
            1.226760400307032: 0.8791305004033481,
            1.241050505840588: 0.8801862468771441,
            1.255340611374145: 0.8797946012969821,
            1.269630716907702: 0.8778778832333881,
            1.283920822441258: 0.874369853507811,
            1.298210927974814: 0.869217189107352,
            1.312501033508371: 0.8623807468299911,
            1.326791139041927: 0.8538365858097691,
            1.341081244575484: 0.8435767253168781,
            1.355371350109041: 0.8316096210165851,
            1.369661455642597: 0.8179603499829331,
            1.3839515611761541: 0.8026705019758441,
            1.398241666709711: 0.7857977815867441,
            1.412531772243267: 0.767415332634613,
            1.426821877776824: 0.747610802467183,
            1.44111198331038: 0.72648516943132,
            1.455402088843937: 0.7041513615913331,
            1.469692194377493: 0.6807326986944421,
            1.48398229991105: 0.6563611923415661,
            1.498272405444606: 0.6311757412844871,
            1.512562510978163: 0.605320259734202,
            1.712623988447955: 0.250361766207342,
            1.7269140939815122: 0.22993744300302102,
            1.7412041995150682: 0.21054032725184502,
            1.7554943050486251: 0.19219322055099503,
            1.7697844105821812: 0.17490868715388902,
            1.784074516115738: 0.15868968631771102,
            1.798364621649295: 0.143530292700902,
            1.812654727182851: 0.129416485373954,
            1.826944832716408: 0.116326986258816,
            1.841234938249964: 0.104234129464653,
            1.8555250437835211: 0.093104743991735,
            1.869815149317077: 0.082901033576723,
            1.884105254850634: 0.07358143899444801,
            1.89839536038419: 0.065101469854978,
            1.912685465917747: 0.057414494782138004,
            1.9269755714513042: 0.050472480774504,
            1.9412656769848602: 0.04422667447933001,
            1.955555782518417: 0.038628220005340004,
            1.9698458880519731: 0.033628709719062004,
            1.98413599358553: 0.029180666174395003,
            1.998426099119087: 0.025237954886463004,
            2.012716204652643: 0.021756129055264,
            2.0270063101862: 0.018692708555873003,
            2.041296415719756: 0.016007396530525003,
            2.055586521253313: 0.013662237740424,
            2.06987662678687: 0.011621723464194001,
            2.084166732320426: 0.009852848172901,
            2.098456837853982: 0.008325123480391,
            2.112746943387539: 0.007010554977488001,
            2.127037048921096: 0.00588358752703,
            2.141327154454652: 0.004921024443368001,
            2.155617259988209: 0.004101925724991001,
            2.169907365521765: 0.0034074901730150003,
            2.184197471055322: 0.0028209258311780003,
            2.198487576588879: 0.0023273127438800004,
          },
          gamma: {
            '-0.689313361344831': 4.2090751217126575e-05,
            '-0.670218529649623': 7.088449991062256e-05,
            '-0.651123697954416': 0.00011584517818500001,
            '-0.6320288662592081': 0.00018378280345700002,
            '-0.6129340345640011': 0.000283140367547,
            '-0.593839202868793': 0.00042381519352700006,
            '-0.574744371173586': 0.0006167114742660001,
            '-0.555649539478378': 0.0008730198227220001,
            '-0.536554707783171': 0.001203286617352,
            '-0.5174598760879631': 0.0016164074649400002,
            '-0.49836504439275603': 0.002118731408007,
            '-0.47927021269754805': 0.0027134703419630004,
            '-0.460175381002341': 0.0034005554189390002,
            '-0.44108054930713303': 0.004176972511332,
            '-0.42198571761192605': 0.0050374700680670004,
            '-0.402890885916718': 0.0059754123995,
            '-0.38379605422151103': 0.006983501046992,
            '-0.36470122252630305': 0.00805414047423,
            '-0.345606390831096': 0.009179380023446001,
            '-0.32651155913588903': 0.010350578195287001,
            '-0.30741672744068105': 0.011558133797313,
            '-0.288321895745473': 0.012791733322827,
            '-0.26922706405026603': 0.014041523659486,
            '-0.250132232355059': 0.015300430446021002,
            '-0.231037400659851': 0.016567551721685,
            '-0.21194256896464403': 0.01785224264465,
            '-0.19284773726943602': 0.019178251713214002,
            '-0.173752905574229': 0.020587130973099003,
            '-0.154658073879021': 0.022140147021542003,
            '-0.13556324218381402': 0.023918063163032,
            '-0.11646841048860601': 0.026018429938296003,
            '-0.097373578793399': 0.028550393402851003,
            '-0.078278747098191': 0.031627483885522,
            '-0.059183915402984004': 0.035359334441302,
            '-0.040089083707776': 0.039843706652789,
            '-0.020994252012569003': 0.045160436129495,
            '-0.001899420317361': 0.051368800909622,
            0.017195411377845003: 0.058509258645925,
            0.036290243073053: 0.066609504095699,
            0.055385074768260004: 0.07569353995606701,
            0.07447990646346801: 0.08579125796648,
            0.09357473815867501: 0.09694529769381201,
            0.112669569853883: 0.109212041297451,
            0.13176440154909: 0.12265467771860401,
            0.150859233244298: 0.13732820580462202,
            0.16995406493950502: 0.15325862111083202,
            0.379997213586788: 0.367547066399029,
            0.39909204528199504: 0.38670747602334904,
            0.418186876977203: 0.40628072961404504,
            0.43728170867241: 0.426627964678056,
            0.45637654036761804: 0.448075916769587,
            0.475471372062825: 0.47086959533239303,
            0.49456620375803206: 0.49513561295738107,
            0.51366103545324: 0.520859516885247,
            0.532755867148448: 0.547878231956728,
            0.551850698843655: 0.57588727383004,
            0.5709455305388631: 0.604461810251087,
            0.5900403622340701: 0.633090500022585,
            0.6091351939292781: 0.66122061844265,
            0.628230025624485: 0.6883117124282401,
            0.647324857319693: 0.713892852058842,
            0.6664196890149: 0.7376160494696491,
            0.6855145207101071: 0.7592966837007371,
            0.7046093524053151: 0.778931936661411,
            0.7237041841005231: 0.79669096868233,
            0.74279901579573: 0.8128756378965051,
            0.761893847490937: 0.8278568752681591,
            0.780988679186145: 0.8419976516174941,
            0.8000835108813521: 0.855577085427439,
            0.8191783425765601: 0.8687305037976291,
            0.8382731742717671: 0.88141703050467,
            0.857368005966975: 0.893420396007172,
            0.876462837662182: 0.9043816995311861,
            0.8955576693573901: 0.9138565298943481,
            0.9146525010525971: 0.921384556971613,
            0.9337473327478051: 0.926558165766135,
            0.9528421644430121: 0.9290779235099511,
            0.97193699613822: 0.9287860829908191,
            0.991031827833427: 0.9256740544396391,
            1.181980144785502: 0.7818614629652451,
            1.20107497648071: 0.7595549417511891,
            1.2201698081759171: 0.736306195528133,
            1.239264639871125: 0.712373374532973,
            1.258359471566332: 0.6880612463778231,
            1.27745430326154: 0.663690292339989,
            1.296549134956747: 0.6395610698716311,
            1.315643966651955: 0.615922163587574,
            1.334738798347162: 0.592948727642679,
            1.3538336300423701: 0.570735672523074,
            1.372928461737577: 0.5493058354293671,
            1.392023293432785: 0.5286299692224931,
            1.411118125127992: 0.508652910203339,
            1.430212956823199: 0.489319325258303,
            1.4493077885184071: 0.470593076961879,
            1.4684026202136151: 0.45246619807166005,
            1.487497451908822: 0.434956194253924,
            1.50659228360403: 0.418093239370024,
            1.525687115299237: 0.401901159817708,
            1.544781946994445: 0.386377438114871,
            1.563876778689652: 0.371477550793587,
            1.582971610384859: 0.35710782137642005,
            1.602066442080067: 0.343128916787647,
            1.621161273775274: 0.32936964953568704,
            1.640256105470482: 0.31564845617638704,
            1.659350937165689: 0.301798328716471,
            1.6784457688608971: 0.28769040568423104,
            1.697540600556105: 0.273251934248051,
            1.7166354322513122: 0.258475675844391,
            1.73573026394652: 0.243419645977053,
            1.754825095641727: 0.22819790777126703,
            1.7739199273369342: 0.21296461232280703,
            1.793014759032142: 0.19789438460257003,
            1.8121095907273492: 0.183162435700566,
            1.831204422422557: 0.16892749115469702,
            1.850299254117764: 0.155319859522412,
            1.869394085812972: 0.142435843688894,
            1.888488917508179: 0.130338372035941,
            1.9075837492033871: 0.119062414645536,
            1.9266785808985951: 0.10862273649921601,
            1.9457734125938022: 0.09902112229642501,
            1.964868244289009: 0.09025058469322601,
            1.983963075984217: 0.082295223257109,
            2.003057907679424: 0.07512604856896901,
            2.022152739374632: 0.068694720315342,
            2.041247571069839: 0.062928207078128,
            2.060342402765047: 0.057727446388556,
            2.079437234460254: 0.052972075297960004,
            2.098532066155462: 0.048531500364849,
            2.117626897850669: 0.044280556471018,
            2.136721729545877: 0.040116428781334,
            2.155816561241084: 0.035972896878381004,
            2.174911392936292: 0.031828495925848,
            2.194006224631499: 0.027706689404415,
            2.213101056326707: 0.023668127826031,
            2.232195888021914: 0.019796927053812002,
            2.251290719717122: 0.016184124612731002,
            2.2703855514123292: 0.012911790045638001,
            2.289480383107537: 0.010040700666331,
            2.308575214802744: 0.007603320468930001,
            2.327670046497952: 0.005602438252478001,
            2.346764878193159: 0.004014617747776001,
            2.3658597098883662: 0.0027968492296840002,
            2.384954541583574: 0.0018945559083460002,
            2.404049373278781: 0.001249327051963,
            2.423144204973989: 0.0008052512171190001,
            2.4422390366691973: 0.0005133092691700001,
            2.461333868364404: 0.00033379567876700003,
            2.480428700059612: 0.00023707425527900002,
            2.4995235317548192: 0.00020311992439000002,
            2.518618363450027: 0.00022028560315400002,
            2.5377131951452343: 0.000283626225676,
            2.556808026840441: 0.000392978828194,
            2.575902858535649: 0.000550893702295,
            2.594997690230856: 0.000760469686193,
            2.614092521926064: 0.0010231733992250001,
            2.6331873536212713: 0.001336799764325,
            2.652282185316479: 0.0016938225792540002,
            2.671377017011687: 0.0020804429219560003,
            2.690471848706894: 0.0024766276436920002,
            2.709566680402102: 0.0028573168814030004,
            2.7286615120973092: 0.0031947758581250003,
            2.747756343792516: 0.003461813810264,
            2.766851175487724: 0.003635358982652,
            2.785946007182931: 0.0036997367127940004,
            2.805040838878139: 0.0036490022161190004,
            2.8241356705733462: 0.0034878450420530002,
            2.843230502268554: 0.003230873599482,
            2.862325333963761: 0.002900430602516,
            2.881420165658969: 0.0025233919506990003,
            2.900514997354176: 0.0021275837804750003,
            2.919609829049384: 0.0017384746042570002,
            2.938704660744591: 0.001376669698474,
            2.9577994924397992: 0.00105650334968,
            2.976894324135006: 0.0007857632729080001,
            2.9959891558302143: 0.000566359638114,
            3.015083987525421: 0.000395614852491,
            3.034178819220629: 0.000267813584696,
            3.053273650915836: 0.000175700233936,
            3.072368482611044: 0.000111709935056,
            3.091463314306251: 6.883209393024843e-05,
            3.110558146001458: 4.110265595252972e-05,
          },
          halfnorm: {
            '-0.39219442089969303': 0.0012112320420720002,
            '-0.36816471238770504': 0.002019701361058,
            '-0.34413500387571705': 0.0032880127516240003,
            '-0.320105295363729': 0.005226677911950001,
            '-0.29607558685174': 0.008113920822156001,
            '-0.272045878339752': 0.012303368324549,
            '-0.24801616982776403': 0.018225975195253,
            '-0.22398646131577601': 0.026383199027532,
            '-0.19995675280378702': 0.037328730336953005,
            '-0.175927044291799': 0.051637073953318006,
            '-0.15189733577981102': 0.069859013513802,
            '-0.127867627267823': 0.09246633090443,
            '-0.10383791875583401': 0.11979074353699501,
            '-0.079808210243846': 0.151964331723419,
            '-0.055778501731858006': 0.188870146454196,
            '-0.03174879321987': 0.23011168523039402,
            '-0.0077190847078810005': 0.275008223546636,
            0.016310623804106: 0.32261969929321904,
            0.040340332316094005: 0.37180049306237906,
            0.06437004082808201: 0.42127688399704505,
            0.08839974934007101: 0.469739166149492,
            0.112429457852059: 0.515937219309031,
            0.136459166364047: 0.5587681920009411,
            0.16048887487603503: 0.597346807353476,
            0.18451858338802402: 0.631052089189929,
            0.208548291900012: 0.659548161147624,
            0.23257800041200002: 0.682780295051096,
            0.25660770892398804: 0.700949908949494,
            0.28063741743597703: 0.714473477963062,
            0.304667125947965: 0.723930483215504,
            0.328696834459953: 0.7300050384608101,
            0.352726542971941: 0.733425225270918,
            0.37675625148393005: 0.734903813531328,
            0.40078595999591804: 0.7350840404005811,
            0.42481566850790603: 0.734494275763781,
            0.448845377019894: 0.7335153493523611,
            0.472875085531883: 0.732363693722567,
            0.49690479404387106: 0.7310920849584881,
            0.520934502555859: 0.729607730628114,
            0.5449642110678471: 0.727705118894661,
            0.568993919579836: 0.725108918945732,
            0.5930236280918241: 0.7215208227563761,
            0.617053336603812: 0.7166638910338271,
            0.6410830451158: 0.7103187950945741,
            0.6651127536277891: 0.7023481282632711,
            0.689142462139777: 0.692707269853842,
            0.7131721706517651: 0.681442597045387,
            0.737201879163753: 0.668679671943985,
            0.7612315876757421: 0.6546050607701811,
            0.7852612961877301: 0.639445574976886,
            0.809291004699718: 0.6234480940440861,
            0.8333207132117061: 0.606862039044422,
            0.857350421723695: 0.589925386032876,
            0.8813801302356831: 0.5728541695524181,
            0.9054098387476711: 0.555834930911925,
            0.929439547259659: 0.5390195430289321,
            0.9534692557716481: 0.522522162589535,
            0.977498964283636: 0.5064184895293511,
            1.001528672795624: 0.49074780835954,
            1.025558381307612: 0.47551827201795605,
            1.049588089819601: 0.46071551964636603,
            1.073617798331589: 0.446314083601333,
            1.097647506843577: 0.432290325744815,
            1.121677215355565: 0.41863506883938706,
            1.1457069238675541: 0.40536383721909003,
            1.169736632379542: 0.392522782818493,
            1.19376634089153: 0.380188930641606,
            1.217796049403519: 0.36846422240894705,
            1.241825757915507: 0.35746380641512804,
            1.265855466427495: 0.34729994571037404,
            1.289885174939483: 0.338063652687693,
            1.3139148834514711: 0.32980660729111705,
            1.33794459196346: 0.322526025131848,
            1.361974300475448: 0.316154896376982,
            1.386004008987436: 0.310559434815324,
            1.410033717499424: 0.305544710036016,
            1.434063426011413: 0.30086837173039005,
            1.4580931345234012: 0.29626123988881603,
            1.482122843035389: 0.29145248590975603,
            1.5061525515473781: 0.286196336988839,
            1.530182260059366: 0.28029685022935,
            1.554211968571354: 0.273627420943027,
            1.578241677083342: 0.266142327725303,
            1.602271385595331: 0.257878698196424,
            1.626301094107319: 0.24894864236448802,
            1.650330802619307: 0.23952272702311803,
            1.6743605111312951: 0.229807219354968,
            1.698390219643284: 0.22001840261252903,
            1.7224199281552721: 0.21035761720665402,
            1.74644963666726: 0.20099045136515903,
            1.7704793451792482: 0.19203273862873402,
            1.794509053691236: 0.18354484635683402,
            1.8185387622032252: 0.17553435970960102,
            1.842568470715213: 0.16796591481855702,
            1.866598179227201: 0.160775845993664,
            1.890627887739189: 0.15388867471880702,
            1.914657596251178: 0.147232393318129,
            1.938687304763166: 0.14074998926228302,
            1.962717013275154: 0.13440561100807102,
            1.9867467217871422: 0.128184991081864,
            2.010776430299131: 0.122090958380225,
            2.034806138811119: 0.11613583058269601,
            2.058835847323108: 0.11033298025135001,
            2.082865555835096: 0.104689823860692,
            2.106895264347084: 0.09920393094224801,
            2.130924972859072: 0.093863048219504,
            2.154954681371061: 0.088648813730824,
            2.178984389883049: 0.08354304460996001,
            2.203014098395037: 0.07853491659178101,
            2.227043806907025: 0.073627216441447,
            2.251073515419014: 0.068840132647131,
            2.275103223931002: 0.064211650392666,
            2.29913293244299: 0.059794369813862006,
            2.323162640954979: 0.05564929461352001,
            2.347192349466967: 0.05183769349386701,
            2.371222057978955: 0.048412429234563,
            2.395251766490942: 0.045410156231987006,
            2.419281475002931: 0.042845543470077005,
            2.4433111835149193: 0.040708264702785005,
            2.467340892026907: 0.038963009149275,
            2.491370600538896: 0.037552300427246006,
            2.515400309050884: 0.036401545945094003,
            2.539430017562872: 0.035425520440522,
            2.56345972607486: 0.034535427799337,
            2.587489434586849: 0.033645764020681,
            2.611519143098837: 0.032680376370844005,
            2.635548851610825: 0.031577323694878,
            2.659578560122813: 0.030292339023221003,
            2.683608268634802: 0.028800843481578003,
            2.70763797714679: 0.027098549344685002,
            2.731667685658778: 0.025200731760581,
            2.755697394170766: 0.023140269262378003,
            2.779727102682755: 0.020964580388586,
            2.8037568111947433: 0.018731635102483,
            2.827786519706731: 0.016505295775239,
            2.85181622821872: 0.014350327099430002,
            2.875845936730708: 0.012327481316474001,
            2.899875645242696: 0.010489088728844001,
            2.923905353754684: 0.008875547612072,
            2.947935062266673: 0.007513011690453,
            2.971964770778661: 0.006412432011834001,
            2.995994479290649: 0.005569948634127001,
            3.020024187802637: 0.004968474546049001,
            3.044053896314626: 0.004580194340211,
            3.068083604826614: 0.004369629255124,
            3.092113313338602: 0.004296903656419,
            3.11614302185059: 0.004320881417117,
            3.140172730362579: 0.0044019121638260005,
            3.164202438874567: 0.0045040207134370005,
            3.188232147386555: 0.0045964704802260005,
            3.212261855898544: 0.004654716783947001,
            3.236291564410532: 0.004660826459045001,
            3.26032127292252: 0.004603469846368001,
            3.284350981434508: 0.004477591517912,
            3.308380689946497: 0.004283845320176,
            3.332410398458485: 0.004027850436128,
            3.356440106970473: 0.003719301607428,
            3.380469815482461: 0.003370958070614,
            3.40449952399445: 0.0029975448345570003,
            3.4285292325064383: 0.0026146216682000002,
            3.452558941018426: 0.0022374989206560003,
            3.476588649530415: 0.001880292790516,
            3.500618358042403: 0.001555206586622,
            3.524648066554391: 0.001272095958755,
            3.548677775066379: 0.001038329639686,
            3.572707483578368: 0.000858903699074,
            3.596737192090356: 0.0007367203331650001,
            3.620766900602344: 0.0006729144308730001,
            3.644796609114332: 0.0006671107358940001,
            3.668826317626321: 0.00071752300595,
            3.692856026138309: 0.0008208588428270001,
            3.716885734650297: 0.000972058661676,
            3.740915443162285: 0.001163959810032,
            3.764945151674274: 0.0013870217518650002,
            3.7889748601862623: 0.0016292628209290002,
            3.81300456869825: 0.001876536474194,
            3.837034277210239: 0.00211321651889,
            3.861063985722227: 0.0023232765891660003,
            3.885093694234215: 0.0024916568406070003,
            3.909123402746203: 0.002605731934833,
            3.933153111258191: 0.0026566491172390003,
            3.9571828197701793: 0.002640306952221,
            3.981212528282167: 0.002557796520717,
            4.245539321914038: 0.000332334198192,
            4.269569030426027: 0.000236699354275,
            4.293598738938015: 0.000164317690236,
            4.317628447450003: 0.00011118258739700001,
            4.3416581559619924: 7.332539934393229e-05,
            4.36568786447398: 4.713432607952563e-05,
            4.389717572985968: 2.9531490798170774e-05,
          },
          triangular: {
            '-0.35207592236649105': 0.00018594312511400002,
            '-0.34126849847239904': 0.000263112054293,
            '-0.33046107457830604': 0.00036847890068500004,
            '-0.31965365068421403': 0.0005107743480520001,
            '-0.308846226790122': 0.000700850021643,
            '-0.29803880289603': 0.0009520009196360001,
            '-0.287231379001937': 0.001280278267779,
            '-0.276423955107845': 0.0017047722740820002,
            '-0.265616531213753': 0.002247840387265,
            '-0.254809107319661': 0.002935253635606,
            '-0.24400168342556902': 0.0037962319318930004,
            '-0.23319425953147602': 0.004863339352804001,
            '-0.135927444484646': 0.030088421833579,
            0.058606185609013005: 0.1844491893953,
            0.069413609503105: 0.19351954139489802,
            0.08022103339719801: 0.202302679320392,
            0.09102845729129001: 0.21079741207188502,
            0.101835881185382: 0.21901744263078002,
            0.11264330507947401: 0.22699074985091602,
            0.12345072897356701: 0.23475834241893903,
            0.13425815286765902: 0.24237241426393502,
            0.14506557676175103: 0.249893961126493,
            0.155873000655843: 0.25738994863661,
            0.166680424549935: 0.26493015120515,
            0.177487848444028: 0.272583806010523,
            0.18829527233812002: 0.28041624485165,
            0.19910269623221202: 0.28848567619838,
            0.20991012012630403: 0.296840288375228,
            0.220717544020397: 0.30551583121729303,
            0.231524967914489: 0.31453380759104904,
            0.24233239180858102: 0.323900369039106,
            0.253139815702673: 0.333605964008149,
            0.263947239596766: 0.343625736391304,
            0.27475466349085803: 0.353920621079273,
            0.28556208738495004: 0.364439036869029,
            0.29636951127904204: 0.375119040202652,
            0.30717693517313405: 0.38589077973219604,
            0.31798435906722705: 0.396679084154673,
            0.328791782961319: 0.40740602485181204,
            0.339599206855411: 0.417993319329212,
            0.350406630749503: 0.42836447809113004,
            0.361214054643596: 0.43844664164330305,
            0.372021478537688: 0.44817210000157504,
            0.38282890243178: 0.45747952831654803,
            0.39363632632587203: 0.46631500341716803,
            0.40444375021996504: 0.47463288289972705,
            0.41525117411405704: 0.48239662840238,
            0.42605859800814905: 0.489579637767922,
            0.43686602190224105: 0.496166119193638,
            0.44767344579633406: 0.502151998712935,
            0.45848086969042606: 0.5075458066892691,
            0.469288293584518: 0.5123694466151071,
            0.48009571747861: 0.516658717568807,
            0.490903141372702: 0.5204634463202,
            0.501710565266795: 0.523847090425679,
            0.5125179891608871: 0.526885701118199,
            0.523325413054979: 0.5296661826532371,
            0.534132836949071: 0.5322838482059651,
            0.544940260843164: 0.53483934402501,
            0.555747684737256: 0.5374350842629331,
            0.5665551086313481: 0.5401713992196,
            0.57736253252544: 0.5431426410962721,
            0.5881699564195331: 0.54643350747535,
            0.598977380313625: 0.5501158306289401,
            0.6097848042077171: 0.554246041320674,
            0.620592228101809: 0.558863453788866,
            0.6313996519959021: 0.563989442153871,
            0.642207075889994: 0.569627497780072,
            0.6530144997840861: 0.57576408295862,
            0.663821923678178: 0.58237013843076,
            0.67462934757227: 0.5894030679390031,
            0.6854367714663631: 0.596809015649277,
            0.696244195360455: 0.604525271120953,
            0.7070516192545471: 0.612482676538322,
            0.717859043148639: 0.6206079638168881,
            0.7286664670427321: 0.628826004611973,
            0.739473890936824: 0.637062003524264,
            0.7502813148309161: 0.6452436947065241,
            0.761088738725008: 0.6533036084095251,
            0.7718961626191011: 0.6611814546073791,
            0.782703586513193: 0.668826628041944,
            0.7935110104072851: 0.676200779343737,
            0.8043184343013771: 0.683280330073975,
            0.815125858195469: 0.690058747092366,
            0.8259332820895621: 0.6965483450833081,
            0.836740705983654: 0.702781365087218,
            0.8475481298777461: 0.7088100879161291,
            0.858355553771839: 0.7147057865085761,
            0.8691629776659311: 0.720556398025163,
            0.879970401560023: 0.7264628978177701,
            0.8907778254541151: 0.7325344728324931,
            0.901585249348207: 0.7388827089163511,
            0.9123926732423: 0.745615111801321,
            0.9232000971363921: 0.7528283633404751,
            0.9340075210304841: 0.760601763627735,
            0.9448149449245761: 0.768991320414643,
            0.955622368818669: 0.778024918456616,
            0.9664297927127611: 0.787698935992852,
            0.977237216606853: 0.7979765800399561,
            0.9880446405009451: 0.808788095766787,
            0.998852064395038: 0.8200328785544461,
            1.00965948828913: 0.831583391216913,
            1.020466912183222: 0.8432906730448361,
            1.031274336077314: 0.854991129730133,
            1.042081759971407: 0.8665142193166031,
            1.052889183865499: 0.8776906020383781,
            1.063696607759591: 0.8883603018410651,
            1.074504031653683: 0.898380433207406,
            1.085311455547775: 0.9076320759556401,
            1.096118879441868: 0.91602592946959,
            1.10692630333596: 0.923506442526391,
            1.117733727230052: 0.9300541916157161,
            1.128541151124144: 0.9356863655559401,
            1.139348575018237: 0.9404553035319241,
            1.1501559989123291: 0.9444451236491391,
            1.160963422806421: 0.9477665659729081,
            1.171770846700513: 0.9505502541489881,
            1.182578270594606: 0.9529386497320741,
            1.193385694488698: 0.9550770305292021,
            1.20419311838279: 0.9571038667613041,
            1.215000542276882: 0.9591409959915861,
            1.225807966170974: 0.9612840101419451,
            1.236615390065067: 0.9635932671780161,
            1.247422813959159: 0.9660859285084811,
            1.258230237853251: 0.968729403163979,
            1.2690376617473431: 0.9714365530518551,
            1.279845085641436: 0.974062980338777,
            1.2906525095355281: 0.9764066768645251,
            1.30145993342962: 0.9782102632653741,
            1.312267357323712: 0.9791659777629411,
            1.323074781217805: 0.9789234866542861,
            1.333882205111897: 0.9771004767779331,
            1.344689629005989: 0.97329585352097,
            1.355497052900081: 0.9671052090407951,
            1.366304476794173: 0.9581380518336161,
            1.377111900688266: 0.9460361130694511,
            1.387919324582358: 0.930491883961553,
            1.39872674847645: 0.9112664112364981,
            1.4095341723705421: 0.88820530421593,
            1.420341596264635: 0.861251904301317,
            1.4311490201587271: 0.830456647589154,
            1.441956444052819: 0.7959818179422631,
            1.452763867946911: 0.7581011357078261,
            1.463571291841004: 0.717193941212182,
            1.474378715735096: 0.673734088145401,
            1.485186139629188: 0.628274029375342,
            1.49599356352328: 0.5814249227528531,
            1.506800987417373: 0.533833873925714,
            1.517608411311465: 0.48615963859965106,
            1.528415835205557: 0.43904820779255604,
            1.539223259099649: 0.393109686829955,
            1.550030682993742: 0.34889775434835,
            1.560838106887834: 0.306892765067857,
            1.5716455307819261: 0.26748926285295704,
            1.5824529546760182: 0.230988328556811,
            1.5932603785701112: 0.197594833308666,
            1.604067802464203: 0.167419334396765,
            1.614875226358295: 0.14048406553862602,
            1.625682650252387: 0.11673225689208501,
            1.636490074146479: 0.09603988473107301,
            1.647297498040572: 0.0782288992319,
            1.658104921934664: 0.06308100576025001,
            1.668912345828756: 0.05035116801199,
            1.679719769722848: 0.039780143263906005,
            1.690527193616941: 0.031105531533730003,
            1.701334617511033: 0.024071002469241,
            1.7121420414051252: 0.018433539240812,
            1.7229494652992172: 0.013968694097947001,
            1.733756889193309: 0.010473976379876002,
            1.744564313087402: 0.007770585916578001,
            1.755371736981494: 0.0057037622715360005,
            1.766179160875586: 0.004142045828523001,
            1.776986584769678: 0.0029757453207160003,
            1.787794008663771: 0.0021148843693500003,
            1.798601432557863: 0.001486863650517,
          },
        },
      },
      status: 'SUCCEEDED',
    };

    // run distribution table data
    const viewDistribution = {
      result: {
        best_fit_distribution: 'Gamma',
        geo_level_distribution: [{
            gamma: {
              chi_square: '782681.812467875',
              p_value: '0.91555',
            },
            geo_level_name: 'SOUTH AMERICA',
            halfnorm: {
              chi_square: '1129883.9092948',
              p_value: '0.58149',
            },
            triang: {
              chi_square: '793939.011657578',
              p_value: '0.92616',
            },
          },
          {
            gamma: {
              chi_square: '724256.99351537',
              p_value: '0.98962',
            },
            geo_level_name: 'NORTH AMERICA',
            halfnorm: {
              chi_square: '1042485.06332909',
              p_value: '0.87732',
            },
            triang: {
              chi_square: '709967.237048592',
              p_value: '0.82611',
            },
          },
          {
            gamma: {
              chi_square: '255323.24985947',
              p_value: '0.64408',
            },
            geo_level_name: 'North America',
            halfnorm: {
              chi_square: '358544.2835899',
              p_value: '0.45732',
            },
            triang: {
              chi_square: '244507.26422935',
              p_value: '0.40586',
            },
          },
          {
            gamma: {
              chi_square: '836778.931044816',
              p_value: '0.7323',
            },
            geo_level_name: 'New Zealand',
            halfnorm: {
              chi_square: '1142379.80957551',
              p_value: '0.28455',
            },
            triang: {
              chi_square: '1009394.84035558',
              p_value: '0.57167',
            },
          },
          {
            gamma: {
              chi_square: '209320.57708613',
              p_value: '0.94661',
            },
            geo_level_name: 'Belgium',
            halfnorm: {
              chi_square: '309973.587534115',
              p_value: '0.12305',
            },
            triang: {
              chi_square: '135943.320153255',
              p_value: '0.34849',
            },
          },
          {
            gamma: {
              chi_square: '297766.594843232',
              p_value: '0.98913',
            },
            geo_level_name: 'Canada',
            halfnorm: {
              chi_square: '430807.308795902',
              p_value: '0.96456',
            },
            triang: {
              chi_square: '241026.795166808',
              p_value: '0.40477',
            },
          },
        ],
        global_level_distribution: {
          gamma: {
            chi_square: '54794.9721378919',
            p_value: '0.67833',
          },
          halfnorm: {
            chi_square: '81.84',
            p_value: '0.01372',
          },
          triang: {
            chi_square: '43087.5501009631',
            p_value: '0.06',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const runDistribution = {
      result: {
        distribution_type: 'Gamma',
      },
      status: 'SUCCEEDED',
    };

    const getHistoricalCustomFlyoutData = {
      result: {
        geo_level_details: [{
            custom: {},
            geo_level_name: 'Norway',
            site_activation_method: 'Historical',
          },
          {
            custom: {},
            geo_level_name: 'Canada',
            site_activation_method: 'Historical',
          },
          {
            custom: {
              '0.0': '0.0',
              '10.0': '4.71',
              '20.0': '12.9',
              '30.0': '22.12',
              '100.0': '100.0',
            },
            geo_level_name: 'Finland',
            site_activation_method: 'Custom',
          },
          {
            custom: {},
            geo_level_name: 'Russia',
            site_activation_method: 'Historical',
          },
          {
            custom: {},
            geo_level_name: 'South Africa',
            site_activation_method: 'Historical',
          },
          {
            custom: {},
            geo_level_name: 'United States',
            site_activation_method: 'Historical',
          },
        ],
        global_level_details: [{
          custom: {},
          geo_level_name: 'Global',
          historical: {
            '0.0': '0.0',
            '10.0': '4.71',
            '20.0': '12.9',
            '30.0': '22.12',
            '40.0': '31.38',
            '50.0': '40.18',
            '60.0': '48.28',
            '70.0': '55.57',
            '80.0': '62.03',
            '90.0': '67.7',
            '100.0': '100.0',
          },
          site_activation_method: 'Historical',
        }],
      },
      status: 'SUCCEEDED',
    };

    const saveHistoricalCustomFlyoutData = {
      status: 'SUCCEEDED',
    };

    const actualEnrolledPatientChart = {
      result: {
        country: {
          Germany: {
            actual_patients_enrolled: {
              '0.0': '57.0',
              '1.0': '57.0',
              '2.0': '57.0',
              '3.0': '57.0',
              '4.0': '57.0',
              '5.0': '57.0',
              '6.0': '57.0',
              '7.0': '13.29494132057833',
              '8.0': '45.88286841258108',
              '9.0': '78.47079550458379',
              '10.0': '111.11634807447628',
              '11.0': '145.45608969432737',
              '12.0': '179.84193169649018',
              '13.0': '215.9334878441896',
              '14.0': '252.05961927862282',
              '15.0': '289.9606154320602',
              '16.0': '331.1001634429012',
              '17.0': '488.0669220121344',
              '18.0': '645.0336805813672',
              '19.0': '802.0004391506004',
              '20.0': '958.9671977198334',
              '21.0': '1115.9339562890664',
              '22.0': '1272.9007148582996',
              '23.0': '1429.8674734275323',
              '24.0': '1586.8342319967655',
              '25.0': '1743.8009905659987',
              '26.0': '1900.767749135232',
              '27.0': '2057.734507704465',
              '28.0': '2214.701266273698',
              '29.0': '2371.668024842931',
              '30.0': '2528.634783412165',
            },
            enrollments: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '13.29',
              '8.0': '45.88',
              '9.0': '78.47',
              '10.0': '111.12',
              '11.0': '145.46',
              '12.0': '179.84',
              '13.0': '215.93',
              '14.0': '252.06',
              '15.0': '289.96',
              '16.0': '331.1',
              '17.0': '488.07',
              '18.0': '645.03',
              '19.0': '802.0',
              '20.0': '958.97',
              '21.0': '1115.93',
              '22.0': '1272.9',
              '23.0': '1429.87',
              '24.0': '1586.83',
              '25.0': '1743.8',
              '26.0': '1900.77',
              '27.0': '2057.73',
              '28.0': '2214.7',
              '29.0': '2371.67',
              '30.0': '2528.63',
            },
            lower_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '5.37',
              '8.0': '29.47',
              '9.0': '54.94',
              '10.0': '80.84',
              '11.0': '108.47',
              '12.0': '136.21',
              '13.0': '165.59',
              '14.0': '195.0',
              '15.0': '226.1',
              '16.0': '260.28',
              '17.0': '405.84',
              '18.0': '550.0',
              '19.0': '693.24',
              '20.0': '835.87',
              '21.0': '978.09',
              '22.0': '1120.01',
              '23.0': '1261.71',
              '24.0': '1403.25',
              '25.0': '1544.66',
              '26.0': '1685.97',
              '27.0': '1827.21',
              '28.0': '1968.39',
              '29.0': '2109.51',
              '30.0': '2250.59',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              '0.0': '0.0',
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '0.0',
              '5.0': '0.0',
              '6.0': '0.0',
              '7.0': '21.22',
              '8.0': '62.29',
              '9.0': '102.0',
              '10.0': '141.39',
              '11.0': '182.44',
              '12.0': '223.47',
              '13.0': '266.27',
              '14.0': '309.12',
              '15.0': '353.82',
              '16.0': '401.92',
              '17.0': '570.29',
              '18.0': '740.07',
              '19.0': '910.76',
              '20.0': '1082.06',
              '21.0': '1253.78',
              '22.0': '1425.8',
              '23.0': '1598.03',
              '24.0': '1770.42',
              '25.0': '1942.94',
              '26.0': '2115.56',
              '27.0': '2288.26',
              '28.0': '2461.02',
              '29.0': '2633.83',
              '30.0': '2806.68',
            },
          },
        },
        region: {
          'Northern America': {
            actual_patients_enrolled: {
              '1.0': '0.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '8.0',
              '5.0': '26.0',
              '6.0': '36.0',
              '7.0': '37.0',
              '8.0': '39.0',
              '9.0': '40.0',
              '10.0': '42.0',
              '11.0': '44.0',
              '12.0': '22.0',
            },
            enrollments: {
              12: '0',
              13: '2',
              14: '4',
              15: '6',
              16: '8',
              17: '10',
              18: '12',
              19: '14',
              20: '16',
              21: '19',
              22: '21',
              23: '23',
              24: '25',
              25: '27',
              26: '29',
              27: '31',
              28: '33',
              29: '35',
              30: '37',
              31: '40',
              32: '42',
              33: '44',
              34: '46',
              35: '48',
              36: '50',
              37: '52',
              38: '54',
              39: '56',
              40: '59',
              41: '62',
              42: '65',
              43: '69',
              44: '73',
              45: '76',
              46: '80',
              47: '84',
              48: '88',
              49: '91',
              50: '95',
              51: '99',
              52: '102',
              53: '106',
              54: '110',
              55: '113',
              56: '117',
              57: '121',
              58: '124',
              59: '128',
              60: '132',
              61: '136',
              62: '139',
              63: '143',
              64: '147',
              65: '150',
              66: '154',
              67: '158',
              68: '161',
              69: '165',
              70: '169',
              71: '172',
              72: '176',
              73: '180',
              74: '183',
              75: '187',
              76: '191',
              77: '195',
              78: '198',
              79: '202',
              80: '206',
              81: '209',
              82: '213',
              83: '217',
              84: '220',
              85: '224',
              86: '228',
              87: '231',
              88: '235',
              89: '239',
              90: '243',
              91: '246',
              92: '250',
              93: '254',
              94: '257',
              95: '261',
              96: '265',
              97: '268',
              98: '272',
              99: '276',
              100: '279',
              101: '283',
              102: '287',
              103: '291',
              104: '294',
              105: '298',
              106: '302',
              107: '305',
              108: '309',
              109: '313',
              110: '316',
              111: '320',
              112: '324',
              113: '327',
              114: '331',
              115: '335',
              116: '338',
              117: '342',
              118: '346',
              119: '350',
              120: '353',
            },
            lower_confidence: {
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '2',
              17: '3',
              18: '4',
              19: '5',
              20: '6',
              21: '7',
              22: '8',
              23: '9',
              24: '10',
              25: '11',
              26: '12',
              27: '13',
              28: '14',
              29: '15',
              30: '16',
              31: '17',
              32: '18',
              33: '19',
              34: '20',
              35: '21',
              36: '22',
              37: '23',
              38: '24',
              39: '26',
              40: '27',
              41: '29',
              42: '31',
              43: '34',
              44: '36',
              45: '39',
              46: '41',
              47: '43',
              48: '46',
              49: '48',
              50: '51',
              51: '53',
              52: '55',
              53: '58',
              54: '60',
              55: '62',
              56: '65',
              57: '67',
              58: '69',
              59: '71',
              60: '74',
              61: '76',
              62: '78',
              63: '80',
              64: '83',
              65: '85',
              66: '87',
              67: '89',
              68: '92',
              69: '94',
              70: '96',
              71: '98',
              72: '101',
              73: '103',
              74: '105',
              75: '107',
              76: '109',
              77: '112',
              78: '114',
              79: '116',
              80: '118',
              81: '120',
              82: '123',
              83: '125',
              84: '127',
              85: '129',
              86: '131',
              87: '134',
              88: '136',
              89: '138',
              90: '140',
              91: '142',
              92: '145',
              93: '147',
              94: '149',
              95: '151',
              96: '153',
              97: '156',
              98: '158',
              99: '160',
              100: '162',
              101: '164',
              102: '166',
              103: '169',
              104: '171',
              105: '173',
              106: '175',
              107: '177',
              108: '180',
              109: '182',
              110: '184',
              111: '186',
              112: '188',
              113: '190',
              114: '193',
              115: '195',
              116: '197',
              117: '199',
              118: '201',
              119: '203',
              120: '206',
            },
            previous_probability_complete_target: {
              12: '8',
              13: '10',
              14: '12',
              15: '14',
              16: '16',
              17: '18',
              18: '20',
              19: '22',
              20: '24',
              21: '27',
              22: '29',
              23: '31',
              24: '33',
              25: '35',
              26: '37',
              27: '39',
              28: '41',
              29: '43',
              30: '45',
              31: '48',
              32: '50',
              33: '52',
              34: '54',
              35: '56',
              36: '58',
              37: '60',
              38: '62',
              39: '64',
              40: '67',
              41: '70',
              42: '73',
              43: '77',
              44: '81',
              45: '84',
              46: '88',
              47: '92',
              48: '96',
              49: '99',
              50: '103',
              51: '107',
              52: '110',
              53: '114',
              54: '118',
              55: '121',
              56: '125',
              57: '129',
              58: '132',
              59: '136',
              60: '140',
              61: '144',
              62: '147',
              63: '151',
              64: '155',
              65: '158',
              66: '162',
              67: '166',
              68: '169',
              69: '173',
              70: '177',
              71: '180',
              72: '184',
              73: '188',
              74: '191',
              75: '195',
              76: '199',
              77: '203',
              78: '206',
              79: '210',
              80: '214',
              81: '217',
              82: '221',
              83: '225',
              84: '228',
              85: '232',
              86: '236',
              87: '239',
              88: '243',
              89: '247',
              90: '251',
              91: '254',
              92: '258',
              93: '262',
              94: '265',
              95: '269',
              96: '273',
              97: '276',
              98: '280',
              99: '284',
              100: '287',
              101: '291',
              102: '295',
              103: '299',
              104: '302',
              105: '306',
              106: '310',
              107: '313',
              108: '317',
              109: '321',
              110: '324',
              111: '328',
              112: '332',
              113: '335',
              114: '339',
              115: '343',
              116: '346',
              117: '350',
              118: '354',
              119: '358',
              120: '361',
            },
            upper_confidence: {
              12: '0',
              13: '4',
              14: '8',
              15: '11',
              16: '14',
              17: '18',
              18: '21',
              19: '24',
              20: '27',
              21: '30',
              22: '34',
              23: '37',
              24: '40',
              25: '43',
              26: '46',
              27: '49',
              28: '53',
              29: '56',
              30: '59',
              31: '62',
              32: '65',
              33: '68',
              34: '72',
              35: '75',
              36: '78',
              37: '81',
              38: '84',
              39: '87',
              40: '91',
              41: '95',
              42: '100',
              43: '104',
              44: '109',
              45: '114',
              46: '119',
              47: '124',
              48: '129',
              49: '134',
              50: '139',
              51: '144',
              52: '149',
              53: '154',
              54: '159',
              55: '164',
              56: '170',
              57: '175',
              58: '180',
              59: '185',
              60: '190',
              61: '195',
              62: '200',
              63: '205',
              64: '210',
              65: '216',
              66: '221',
              67: '226',
              68: '231',
              69: '236',
              70: '241',
              71: '246',
              72: '252',
              73: '257',
              74: '262',
              75: '267',
              76: '272',
              77: '277',
              78: '283',
              79: '288',
              80: '293',
              81: '298',
              82: '303',
              83: '309',
              84: '314',
              85: '319',
              86: '324',
              87: '329',
              88: '334',
              89: '340',
              90: '345',
              91: '350',
              92: '355',
              93: '360',
              94: '366',
              95: '371',
              96: '376',
              97: '381',
              98: '386',
              99: '392',
              100: '397',
              101: '402',
              102: '407',
              103: '412',
              104: '418',
              105: '423',
              106: '428',
              107: '433',
              108: '438',
              109: '444',
              110: '449',
              111: '454',
              112: '459',
              113: '464',
              114: '470',
              115: '475',
              116: '480',
              117: '485',
              118: '491',
              119: '496',
              120: '501',
            },
          },
          'Western Europe': {
            actual_patients_enrolled: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
            },
            enrollments: {
              12: '0',
              13: '3',
              14: '7',
              15: '10',
              16: '13',
              17: '17',
              18: '20',
              19: '24',
              20: '29',
              21: '33',
              22: '39',
              23: '44',
              24: '50',
              25: '56',
              26: '61',
              27: '67',
              28: '72',
              29: '78',
              30: '84',
              31: '89',
              32: '95',
              33: '101',
              34: '106',
              35: '112',
              36: '117',
              37: '123',
              38: '129',
              39: '134',
              40: '140',
              41: '146',
              42: '151',
              43: '157',
              44: '163',
              45: '168',
              46: '174',
              47: '179',
              48: '185',
              49: '191',
              50: '196',
              51: '202',
              52: '208',
              53: '213',
              54: '219',
              55: '224',
              56: '230',
              57: '236',
              58: '241',
              59: '247',
              60: '253',
            },
            lower_confidence: {
              12: '0',
              13: '0',
              14: '1',
              15: '3',
              16: '5',
              17: '7',
              18: '9',
              19: '11',
              20: '14',
              21: '17',
              22: '20',
              23: '24',
              24: '28',
              25: '31',
              26: '35',
              27: '39',
              28: '43',
              29: '46',
              30: '50',
              31: '54',
              32: '57',
              33: '61',
              34: '65',
              35: '68',
              36: '72',
              37: '75',
              38: '79',
              39: '83',
              40: '86',
              41: '90',
              42: '94',
              43: '97',
              44: '101',
              45: '105',
              46: '108',
              47: '112',
              48: '115',
              49: '119',
              50: '123',
              51: '126',
              52: '130',
              53: '134',
              54: '137',
              55: '141',
              56: '144',
              57: '148',
              58: '152',
              59: '155',
              60: '159',
            },
            previous_probability_complete_target: {
              12: '6',
              13: '9',
              14: '13',
              15: '16',
              16: '19',
              17: '23',
              18: '26',
              19: '30',
              20: '35',
              21: '39',
              22: '45',
              23: '50',
              24: '56',
              25: '62',
              26: '67',
              27: '73',
              28: '78',
              29: '84',
              30: '90',
              31: '95',
              32: '101',
              33: '107',
              34: '112',
              35: '118',
              36: '123',
              37: '129',
              38: '135',
              39: '140',
              40: '146',
              41: '152',
              42: '157',
              43: '163',
              44: '169',
              45: '174',
              46: '180',
              47: '185',
              48: '191',
              49: '197',
              50: '202',
              51: '208',
              52: '214',
              53: '219',
              54: '225',
              55: '230',
              56: '236',
              57: '242',
              58: '247',
              59: '253',
              60: '259',
            },
            upper_confidence: {
              12: '0',
              13: '7',
              14: '12',
              15: '17',
              16: '22',
              17: '27',
              18: '32',
              19: '38',
              20: '44',
              21: '50',
              22: '57',
              23: '65',
              24: '72',
              25: '80',
              26: '87',
              27: '95',
              28: '102',
              29: '110',
              30: '118',
              31: '125',
              32: '133',
              33: '140',
              34: '148',
              35: '156',
              36: '163',
              37: '171',
              38: '178',
              39: '186',
              40: '194',
              41: '201',
              42: '209',
              43: '217',
              44: '224',
              45: '232',
              46: '239',
              47: '247',
              48: '255',
              49: '262',
              50: '270',
              51: '278',
              52: '285',
              53: '293',
              54: '301',
              55: '308',
              56: '316',
              57: '323',
              58: '331',
              59: '339',
              60: '346',
            },
          },
        },
        global: {
          global: {
            actual_patients_enrolled: {
              '1.0': '78.0',
              '2.0': '0.0',
              '3.0': '0.0',
              '4.0': '9.0',
              '5.0': '56.0',
              '6.0': '99.0',
              '7.0': '108.0',
              '8.0': '121.0',
              '9.0': '134.0',
              '10.0': '143.0',
              '11.0': '148.0',
              '12.0': '74.0',
            },
            enrollments: {
              12: '0',
              13: '97',
              14: '116',
              15: '57',
              16: '76',
              17: '96',
              18: '116',
              19: '137',
              20: '159',
              21: '183',
              22: '208',
              23: '235',
              24: '261',
              25: '289',
              26: '318',
              27: '348',
              28: '378',
              29: '408',
              30: '439',
              31: '471',
              32: '505',
              33: '538',
              34: '572',
              35: '607',
              36: '642',
              37: '677',
              38: '713',
              39: '749',
              40: '786',
              41: '825',
              42: '864',
              43: '904',
              44: '943',
              45: '983',
              46: '1023',
              47: '1062',
              48: '1102',
              49: '1141',
              50: '1181',
              51: '1220',
              52: '1260',
              53: '1299',
              54: '1339',
              55: '1378',
              56: '1418',
              57: '1458',
              58: '1497',
              59: '1537',
              60: '1576',
              61: '1616',
              62: '1655',
              63: '1695',
              64: '1734',
              65: '1774',
              66: '1813',
              67: '1853',
              68: '1893',
              69: '1932',
              70: '1972',
              71: '2011',
              72: '2051',
              73: '2090',
              74: '2130',
              75: '2169',
              76: '2209',
              77: '2249',
              78: '2288',
              79: '2328',
              80: '2367',
              81: '2407',
              82: '2446',
              83: '2486',
              84: '2525',
              85: '2565',
              86: '2604',
              87: '2644',
              88: '2684',
              89: '2723',
              90: '2763',
              91: '2802',
              92: '2842',
              93: '2881',
              94: '2921',
              95: '2960',
              96: '3000',
              97: '3039',
              98: '3079',
              99: '3119',
              100: '3158',
              101: '3198',
              102: '3237',
              103: '3277',
              104: '3316',
              105: '3356',
              106: '3395',
              107: '3435',
              108: '3475',
              109: '3514',
              110: '3554',
              111: '3593',
              112: '3633',
              113: '3672',
              114: '3712',
              115: '3751',
              116: '3791',
              117: '3830',
              118: '3870',
              119: '3910',
              120: '3949',
            },
            lower_confidence: {
              12: '0',
              13: '2',
              14: '88',
              15: '21',
              16: '32',
              17: '44',
              18: '56',
              19: '70',
              20: '84',
              21: '100',
              22: '117',
              23: '134',
              24: '152',
              25: '172',
              26: '191',
              27: '212',
              28: '233',
              29: '254',
              30: '275',
              31: '298',
              32: '322',
              33: '345',
              34: '369',
              35: '394',
              36: '419',
              37: '444',
              38: '469',
              39: '495',
              40: '521',
              41: '549',
              42: '578',
              43: '607',
              44: '635',
              45: '663',
              46: '692',
              47: '720',
              48: '748',
              49: '777',
              50: '805',
              51: '833',
              52: '861',
              53: '889',
              54: '917',
              55: '945',
              56: '973',
              57: '1001',
              58: '1029',
              59: '1057',
              60: '1085',
              61: '1113',
              62: '1141',
              63: '1169',
              64: '1196',
              65: '1224',
              66: '1252',
              67: '1280',
              68: '1308',
              69: '1335',
              70: '1363',
              71: '1391',
              72: '1419',
              73: '1447',
              74: '1474',
              75: '1502',
              76: '1530',
              77: '1558',
              78: '1585',
              79: '1613',
              80: '1641',
              81: '1668',
              82: '1696',
              83: '1724',
              84: '1752',
              85: '1779',
              86: '1807',
              87: '1835',
              88: '1862',
              89: '1890',
              90: '1918',
              91: '1945',
              92: '1973',
              93: '2001',
              94: '2029',
              95: '2056',
              96: '2084',
              97: '2112',
              98: '2139',
              99: '2167',
              100: '2195',
              101: '2222',
              102: '2250',
              103: '2277',
              104: '2305',
              105: '2333',
              106: '2360',
              107: '2388',
              108: '2416',
              109: '2443',
              110: '2471',
              111: '2499',
              112: '2526',
              113: '2554',
              114: '2582',
              115: '2609',
              116: '2637',
              117: '2665',
              118: '2692',
              119: '2720',
              120: '2747',
            },
            previous_probability_complete_target: {
              12: '78',
              13: '97',
              14: '116',
              15: '135',
              16: '154',
              17: '174',
              18: '194',
              19: '215',
              20: '237',
              21: '261',
              22: '286',
              23: '313',
              24: '339',
              25: '367',
              26: '396',
              27: '426',
              28: '456',
              29: '486',
              30: '517',
              31: '549',
              32: '583',
              33: '616',
              34: '650',
              35: '685',
              36: '720',
              37: '755',
              38: '791',
              39: '827',
              40: '864',
              41: '903',
              42: '942',
              43: '982',
              44: '1021',
              45: '1061',
              46: '1101',
              47: '1140',
              48: '1180',
              49: '1219',
              50: '1259',
              51: '1298',
              52: '1338',
              53: '1377',
              54: '1417',
              55: '1456',
              56: '1496',
              57: '1536',
              58: '1575',
              59: '1615',
              60: '1654',
              61: '1694',
              62: '1733',
              63: '1773',
              64: '1812',
              65: '1852',
              66: '1891',
              67: '1931',
              68: '1971',
              69: '2010',
              70: '2050',
              71: '2089',
              72: '2129',
              73: '2168',
              74: '2208',
              75: '2247',
              76: '2287',
              77: '2327',
              78: '2366',
              79: '2406',
              80: '2445',
              81: '2485',
              82: '2524',
              83: '2564',
              84: '2603',
              85: '2643',
              86: '2682',
              87: '2722',
              88: '2762',
              89: '2801',
              90: '2841',
              91: '2880',
              92: '2920',
              93: '2959',
              94: '2999',
              95: '3038',
              96: '3078',
              97: '3117',
              98: '3157',
              99: '3197',
              100: '3236',
              101: '3276',
              102: '3315',
              103: '3355',
              104: '3394',
              105: '3434',
              106: '3473',
              107: '3513',
              108: '3553',
              109: '3592',
              110: '3632',
              111: '3671',
              112: '3711',
              113: '3750',
              114: '3790',
              115: '3829',
              116: '3869',
              117: '3908',
              118: '3948',
              119: '3988',
              120: '4027',
            },
            upper_confidence: {
              12: '3',
              13: '115',
              14: '66',
              15: '93',
              16: '120',
              17: '147',
              18: '175',
              19: '205',
              20: '235',
              21: '266',
              22: '300',
              23: '335',
              24: '370',
              25: '407',
              26: '444',
              27: '484',
              28: '523',
              29: '562',
              30: '602',
              31: '644',
              32: '687',
              33: '731',
              34: '774',
              35: '820',
              36: '865',
              37: '911',
              38: '957',
              39: '1003',
              40: '1051',
              41: '1100',
              42: '1151',
              43: '1201',
              44: '1252',
              45: '1302',
              46: '1353',
              47: '1404',
              48: '1455',
              49: '1506',
              50: '1557',
              51: '1608',
              52: '1659',
              53: '1710',
              54: '1761',
              55: '1812',
              56: '1863',
              57: '1914',
              58: '1965',
              59: '2016',
              60: '2067',
              61: '2119',
              62: '2170',
              63: '2221',
              64: '2272',
              65: '2324',
              66: '2375',
              67: '2426',
              68: '2477',
              69: '2529',
              70: '2580',
              71: '2631',
              72: '2683',
              73: '2734',
              74: '2785',
              75: '2837',
              76: '2888',
              77: '2939',
              78: '2991',
              79: '3042',
              80: '3094',
              81: '3145',
              82: '3196',
              83: '3248',
              84: '3299',
              85: '3350',
              86: '3402',
              87: '3453',
              88: '3505',
              89: '3556',
              90: '3608',
              91: '3659',
              92: '3710',
              93: '3762',
              94: '3813',
              95: '3865',
              96: '3916',
              97: '3967',
              98: '4019',
              99: '4070',
              100: '4122',
              101: '4173',
              102: '4225',
              103: '4276',
              104: '4327',
              105: '4379',
              106: '4430',
              107: '4482',
              108: '4533',
              109: '4585',
              110: '4636',
              111: '4688',
              112: '4739',
              113: '4791',
              114: '4842',
              115: '4893',
              116: '4945',
              117: '4996',
              118: '5048',
              119: '5099',
              120: '5151',
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const actualLowerModellingChart = {
      result: {
        Germany: {
          ctfo_site_12705: {
            actual_patients_enrolled: {
              5: '400.0',
              6: '410.0',
              7: '430.0',
              8: '440.0',
              9: '450.0',
              10: '450.0',
              11: '496.0',
              12: '447.0',
              13: '452.0',
              14: '456.0',
              15: '465.0',
              16: '469.0',
              17: '474.0',
              18: '574.0',
              19: '674.0',
            },
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '4',
              7: '7',
              8: '12',
              9: '16',
              10: '21',
              11: '26',
              12: '32',
              13: '37',
              14: '43',
              15: '48',
              16: '54',
              17: '59',
              18: '64',
              19: '70',
              20: '76',
              21: '83',
              22: '89',
              23: '95',
              24: '101',
              25: '108',
              26: '114',
              27: '120',
              28: '126',
              29: '133',
              30: '139',
              31: '146',
              32: '153',
              33: '159',
              34: '166',
              35: '172',
              36: '179',
              37: '186',
              38: '192',
              39: '199',
              40: '206',
              41: '212',
              42: '219',
              43: '225',
              44: '232',
              45: '239',
              46: '245',
              47: '252',
              48: '259',
              49: '265',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '4',
              7: '7',
              8: '8',
              9: '9',
              10: '10',
              11: '10',
              12: '12',
              13: '13',
              14: '14',
              15: '15',
              16: '16',
              17: '17',
              18: '18',
              19: '19',
              20: '20',
              21: '21',
              22: '22',
              23: '23',
              24: '24',
              25: '25',
              26: '26',
              27: '27',
              28: '28',
              29: '29',
              30: '30',
              31: '31',
              32: '32',
              33: '33',
              34: '34',
              35: '35',
              36: '36',
              37: '37',
              38: '38',
              39: '39',
              40: '40',
              41: '41',
              42: '42',
              43: '43',
              44: '44',
              45: '45',
              46: '46',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '21',
              1: '22',
              2: '23',
              3: '24',
              4: '25',
              5: '26',
              6: '27',
              7: '30',
              8: '42',
              9: '46',
              10: '51',
              11: '56',
              12: '62',
              13: '67',
              14: '73',
              15: '78',
              16: '84',
              17: '89',
              18: '94',
              19: '90',
              20: '106',
              21: '123',
              22: '129',
              23: '135',
              24: '131',
              25: '138',
              26: '144',
              27: '150',
              28: '156',
              29: '163',
              30: '169',
              31: '176',
              32: '183',
              33: '189',
              34: '196',
              35: '202',
              36: '289',
              37: '296',
              38: '292',
              39: '309',
              40: '316',
              41: '322',
              42: '329',
              43: '335',
              44: '342',
              45: '349',
              46: '355',
              47: '362',
              48: '369',
              49: '375',
            },
          },
          ctfo_site_174355: {
            actual_patients_enrolled: {
              0: '28',
              1: '28',
              2: '28',
              3: '28',
              4: '50',
              5: '100',
              6: '420',
            },
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '11',
              7: '21',
              8: '32',
              9: '45',
              10: '59',
              11: '74',
              12: '89',
              13: '104',
              14: '119',
              15: '134',
              16: '150',
              17: '167',
              18: '184',
              19: '202',
              20: '220',
              21: '239',
              22: '259',
              23: '280',
              24: '301',
              25: '322',
              26: '343',
              27: '364',
              28: '385',
              29: '407',
              30: '428',
              31: '450',
              32: '471',
              33: '493',
              34: '515',
              35: '536',
              36: '558',
              37: '579',
              38: '601',
              39: '622',
              40: '644',
              41: '666',
              42: '687',
              43: '709',
              44: '730',
              45: '752',
              46: '773',
              47: '795',
              48: '817',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '4',
              7: '7',
              8: '20',
              9: '30',
              10: '54',
              11: '26',
              12: '31',
              13: '37',
              14: '42',
              15: '48',
              16: '53',
              17: '59',
              18: '64',
              19: '70',
              20: '76',
              21: '82',
              22: '89',
              23: '95',
              24: '101',
              25: '107',
              26: '113',
              27: '120',
              28: '126',
              29: '132',
              30: '139',
              31: '146',
              32: '152',
              33: '159',
              34: '165',
              35: '172',
              36: '179',
              37: '185',
              38: '192',
              39: '198',
              40: '205',
              41: '212',
              42: '218',
              43: '225',
              44: '231',
              45: '238',
              46: '245',
              47: '251',
              48: '258',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '1',
              2: '3',
              3: '4',
              4: '5',
              5: '6',
              6: '7',
              7: '9',
              8: '10',
              9: '12',
              10: '14',
              11: '16',
              12: '18',
              13: '20',
              14: '22',
              15: '24',
              16: '26',
              17: '28',
              18: '30',
              19: '32',
              20: '34',
              21: '36',
              22: '38',
              23: '39',
              24: '41',
              25: '43',
              26: '45',
              27: '47',
              28: '49',
              29: '51',
              30: '56',
              31: '59',
              32: '62',
              33: '64',
              34: '67',
              35: '70',
              36: '73',
              37: '76',
              38: '78',
              39: '81',
              40: '84',
              41: '87',
              42: '89',
              43: '92',
              44: '95',
              45: '98',
              46: '101',
              47: '103',
              48: '106',
              49: '109',
            },
          },
          ctfo_site_50119: {
            actual_patients_enrolled: {
              0: '28',
              1: '28',
              2: '28',
              3: '28',
              4: '28',
              5: '1',
              6: '11',
            },
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '6',
              28: '6',
              29: '6',
              30: '6',
              31: '7',
              32: '7',
              33: '8',
              34: '8',
              35: '8',
              36: '9',
              37: '9',
              38: '10',
              39: '10',
              40: '10',
              41: '11',
              42: '11',
              43: '12',
              44: '12',
              45: '12',
              46: '13',
              47: '13',
              48: '14',
              49: '14',
              50: '14',
              51: '15',
              52: '15',
              53: '16',
              54: '16',
              55: '16',
              56: '17',
              57: '17',
              58: '18',
              59: '18',
              60: '18',
              61: '19',
              62: '19',
              63: '19',
              64: '20',
              65: '20',
              66: '21',
              67: '21',
              68: '21',
              69: '22',
              70: '22',
              71: '23',
              72: '23',
              73: '23',
              74: '24',
              75: '24',
              76: '25',
              77: '25',
              78: '25',
              79: '26',
              80: '26',
              81: '27',
              82: '27',
              83: '27',
              84: '28',
              85: '28',
              86: '29',
              87: '29',
              88: '29',
              89: '30',
              90: '30',
              91: '31',
              92: '31',
              93: '31',
              94: '32',
              95: '32',
              96: '33',
              97: '33',
              98: '33',
              99: '34',
              100: '34',
              101: '34',
              102: '35',
              103: '35',
              104: '36',
              105: '36',
              106: '36',
              107: '37',
              108: '37',
              109: '38',
              110: '38',
              111: '38',
              112: '39',
              113: '39',
              114: '40',
              115: '40',
              116: '40',
              117: '41',
              118: '41',
              119: '42',
              120: '42',
              121: '42',
              122: '43',
              123: '43',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '5',
              30: '5',
              31: '5',
              32: '5',
              33: '6',
              34: '6',
              35: '7',
              36: '7',
              37: '7',
              38: '8',
              39: '8',
              40: '8',
              41: '9',
              42: '9',
              43: '9',
              44: '10',
              45: '10',
              46: '10',
              47: '11',
              48: '11',
              49: '12',
              50: '12',
              51: '12',
              52: '13',
              53: '13',
              54: '13',
              55: '14',
              56: '14',
              57: '14',
              58: '15',
              59: '15',
              60: '16',
              61: '16',
              62: '16',
              63: '17',
              64: '17',
              65: '17',
              66: '18',
              67: '18',
              68: '18',
              69: '19',
              70: '19',
              71: '20',
              72: '20',
              73: '20',
              74: '21',
              75: '21',
              76: '21',
              77: '22',
              78: '22',
              79: '22',
              80: '23',
              81: '23',
              82: '23',
              83: '24',
              84: '24',
              85: '25',
              86: '25',
              87: '25',
              88: '26',
              89: '26',
              90: '26',
              91: '27',
              92: '27',
              93: '27',
              94: '28',
              95: '28',
              96: '29',
              97: '29',
              98: '29',
              99: '30',
              100: '30',
              101: '30',
              102: '31',
              103: '31',
              104: '31',
              105: '32',
              106: '32',
              107: '33',
              108: '33',
              109: '33',
              110: '34',
              111: '34',
              112: '34',
              113: '35',
              114: '35',
              115: '35',
              116: '36',
              117: '36',
              118: '37',
              119: '37',
              120: '37',
              121: '38',
              122: '38',
              123: '38',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '1',
              7: '1',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '7',
              29: '7',
              30: '8',
              31: '9',
              32: '9',
              33: '9',
              34: '10',
              35: '10',
              36: '11',
              37: '11',
              38: '12',
              39: '12',
              40: '13',
              41: '13',
              42: '13',
              43: '14',
              44: '14',
              45: '15',
              46: '15',
              47: '16',
              48: '16',
              49: '16',
              50: '17',
              51: '17',
              52: '18',
              53: '18',
              54: '19',
              55: '19',
              56: '19',
              57: '20',
              58: '20',
              59: '21',
              60: '21',
              61: '22',
              62: '22',
              63: '22',
              64: '23',
              65: '23',
              66: '24',
              67: '24',
              68: '25',
              69: '25',
              70: '25',
              71: '26',
              72: '26',
              73: '27',
              74: '27',
              75: '28',
              76: '28',
              77: '28',
              78: '29',
              79: '29',
              80: '30',
              81: '30',
              82: '31',
              83: '31',
              84: '31',
              85: '32',
              86: '32',
              87: '33',
              88: '33',
              89: '33',
              90: '34',
              91: '34',
              92: '35',
              93: '35',
              94: '36',
              95: '36',
              96: '36',
              97: '37',
              98: '37',
              99: '38',
              100: '38',
              101: '39',
              102: '39',
              103: '39',
              104: '40',
              105: '40',
              106: '41',
              107: '41',
              108: '42',
              109: '42',
              110: '42',
              111: '43',
              112: '43',
              113: '44',
              114: '44',
              115: '45',
              116: '45',
              117: '45',
              118: '46',
              119: '46',
              120: '47',
              121: '47',
              122: '48',
              123: '48',
            },
          },
          ctfo_site_6836: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '2',
              24: '3',
              25: '3',
              26: '3',
              27: '3',
              28: '3',
              29: '3',
              30: '4',
              31: '4',
              32: '4',
              33: '4',
              34: '5',
              35: '5',
              36: '5',
              37: '5',
              38: '5',
              39: '6',
              40: '6',
              41: '6',
              42: '6',
              43: '7',
              44: '7',
              45: '7',
              46: '7',
              47: '8',
              48: '8',
              49: '8',
              50: '8',
              51: '9',
              52: '9',
              53: '9',
              54: '9',
              55: '9',
              56: '10',
              57: '10',
              58: '10',
              59: '10',
              60: '11',
              61: '11',
              62: '11',
              63: '11',
              64: '12',
              65: '12',
              66: '12',
              67: '12',
              68: '12',
              69: '13',
              70: '13',
              71: '13',
              72: '13',
              73: '14',
              74: '14',
              75: '14',
              76: '14',
              77: '15',
              78: '15',
              79: '15',
              80: '15',
              81: '15',
              82: '16',
              83: '16',
              84: '16',
              85: '16',
              86: '17',
              87: '17',
              88: '17',
              89: '17',
              90: '18',
              91: '18',
              92: '18',
              93: '18',
              94: '19',
              95: '19',
              96: '19',
              97: '19',
              98: '19',
              99: '20',
              100: '20',
              101: '20',
              102: '20',
              103: '21',
              104: '21',
              105: '21',
              106: '21',
              107: '22',
              108: '22',
              109: '22',
              110: '22',
              111: '22',
              112: '23',
              113: '23',
              114: '23',
              115: '23',
              116: '24',
              117: '24',
              118: '24',
              119: '24',
              120: '25',
              121: '25',
              122: '25',
              123: '25',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
              31: '3',
              32: '3',
              33: '3',
              34: '3',
              35: '4',
              36: '4',
              37: '4',
              38: '4',
              39: '4',
              40: '5',
              41: '5',
              42: '5',
              43: '5',
              44: '6',
              45: '6',
              46: '6',
              47: '6',
              48: '6',
              49: '7',
              50: '7',
              51: '7',
              52: '7',
              53: '7',
              54: '8',
              55: '8',
              56: '8',
              57: '8',
              58: '9',
              59: '9',
              60: '9',
              61: '9',
              62: '9',
              63: '10',
              64: '10',
              65: '10',
              66: '10',
              67: '10',
              68: '11',
              69: '11',
              70: '11',
              71: '11',
              72: '12',
              73: '12',
              74: '12',
              75: '12',
              76: '12',
              77: '13',
              78: '13',
              79: '13',
              80: '13',
              81: '13',
              82: '14',
              83: '14',
              84: '14',
              85: '14',
              86: '15',
              87: '15',
              88: '15',
              89: '15',
              90: '15',
              91: '16',
              92: '16',
              93: '16',
              94: '16',
              95: '16',
              96: '17',
              97: '17',
              98: '17',
              99: '17',
              100: '17',
              101: '18',
              102: '18',
              103: '18',
              104: '18',
              105: '19',
              106: '19',
              107: '19',
              108: '19',
              109: '19',
              110: '20',
              111: '20',
              112: '20',
              113: '20',
              114: '20',
              115: '21',
              116: '21',
              117: '21',
              118: '21',
              119: '22',
              120: '22',
              121: '22',
              122: '22',
              123: '22',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
              31: '5',
              32: '5',
              33: '5',
              34: '6',
              35: '6',
              36: '6',
              37: '6',
              38: '7',
              39: '7',
              40: '7',
              41: '7',
              42: '8',
              43: '8',
              44: '8',
              45: '8',
              46: '9',
              47: '9',
              48: '9',
              49: '9',
              50: '10',
              51: '10',
              52: '10',
              53: '10',
              54: '11',
              55: '11',
              56: '11',
              57: '11',
              58: '12',
              59: '12',
              60: '12',
              61: '12',
              62: '13',
              63: '13',
              64: '13',
              65: '14',
              66: '14',
              67: '14',
              68: '14',
              69: '15',
              70: '15',
              71: '15',
              72: '15',
              73: '16',
              74: '16',
              75: '16',
              76: '16',
              77: '17',
              78: '17',
              79: '17',
              80: '17',
              81: '18',
              82: '18',
              83: '18',
              84: '18',
              85: '19',
              86: '19',
              87: '19',
              88: '19',
              89: '20',
              90: '20',
              91: '20',
              92: '20',
              93: '21',
              94: '21',
              95: '21',
              96: '21',
              97: '22',
              98: '22',
              99: '22',
              100: '22',
              101: '23',
              102: '23',
              103: '23',
              104: '23',
              105: '24',
              106: '24',
              107: '24',
              108: '24',
              109: '25',
              110: '25',
              111: '25',
              112: '25',
              113: '26',
              114: '26',
              115: '26',
              116: '26',
              117: '27',
              118: '27',
              119: '27',
              120: '27',
              121: '28',
              122: '28',
              123: '28',
            },
          },
          ctfo_site_7011: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '2',
              24: '3',
              25: '3',
              26: '3',
              27: '3',
              28: '3',
              29: '3',
              30: '4',
              31: '4',
              32: '4',
              33: '4',
              34: '5',
              35: '5',
              36: '5',
              37: '5',
              38: '6',
              39: '6',
              40: '6',
              41: '6',
              42: '6',
              43: '7',
              44: '7',
              45: '7',
              46: '7',
              47: '8',
              48: '8',
              49: '8',
              50: '8',
              51: '9',
              52: '9',
              53: '9',
              54: '9',
              55: '10',
              56: '10',
              57: '10',
              58: '10',
              59: '11',
              60: '11',
              61: '11',
              62: '11',
              63: '11',
              64: '12',
              65: '12',
              66: '12',
              67: '12',
              68: '13',
              69: '13',
              70: '13',
              71: '13',
              72: '14',
              73: '14',
              74: '14',
              75: '14',
              76: '15',
              77: '15',
              78: '15',
              79: '15',
              80: '15',
              81: '16',
              82: '16',
              83: '16',
              84: '16',
              85: '17',
              86: '17',
              87: '17',
              88: '17',
              89: '18',
              90: '18',
              91: '18',
              92: '18',
              93: '19',
              94: '19',
              95: '19',
              96: '19',
              97: '19',
              98: '20',
              99: '20',
              100: '20',
              101: '20',
              102: '21',
              103: '21',
              104: '21',
              105: '21',
              106: '22',
              107: '22',
              108: '22',
              109: '22',
              110: '23',
              111: '23',
              112: '23',
              113: '23',
              114: '23',
              115: '24',
              116: '24',
              117: '24',
              118: '24',
              119: '25',
              120: '25',
              121: '25',
              122: '25',
              123: '26',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
              31: '3',
              32: '3',
              33: '3',
              34: '3',
              35: '4',
              36: '4',
              37: '4',
              38: '4',
              39: '5',
              40: '5',
              41: '5',
              42: '5',
              43: '5',
              44: '6',
              45: '6',
              46: '6',
              47: '6',
              48: '6',
              49: '7',
              50: '7',
              51: '7',
              52: '7',
              53: '8',
              54: '8',
              55: '8',
              56: '8',
              57: '8',
              58: '9',
              59: '9',
              60: '9',
              61: '9',
              62: '10',
              63: '10',
              64: '10',
              65: '10',
              66: '10',
              67: '11',
              68: '11',
              69: '11',
              70: '11',
              71: '11',
              72: '12',
              73: '12',
              74: '12',
              75: '12',
              76: '13',
              77: '13',
              78: '13',
              79: '13',
              80: '13',
              81: '14',
              82: '14',
              83: '14',
              84: '14',
              85: '14',
              86: '15',
              87: '15',
              88: '15',
              89: '15',
              90: '16',
              91: '16',
              92: '16',
              93: '16',
              94: '16',
              95: '17',
              96: '17',
              97: '17',
              98: '17',
              99: '18',
              100: '18',
              101: '18',
              102: '18',
              103: '18',
              104: '19',
              105: '19',
              106: '19',
              107: '19',
              108: '19',
              109: '20',
              110: '20',
              111: '20',
              112: '20',
              113: '21',
              114: '21',
              115: '21',
              116: '21',
              117: '21',
              118: '22',
              119: '22',
              120: '22',
              121: '22',
              122: '22',
              123: '23',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
              31: '5',
              32: '5',
              33: '5',
              34: '6',
              35: '6',
              36: '6',
              37: '6',
              38: '7',
              39: '7',
              40: '7',
              41: '8',
              42: '8',
              43: '8',
              44: '8',
              45: '9',
              46: '9',
              47: '9',
              48: '9',
              49: '10',
              50: '10',
              51: '10',
              52: '10',
              53: '11',
              54: '11',
              55: '11',
              56: '11',
              57: '12',
              58: '12',
              59: '12',
              60: '12',
              61: '13',
              62: '13',
              63: '13',
              64: '13',
              65: '14',
              66: '14',
              67: '14',
              68: '14',
              69: '15',
              70: '15',
              71: '15',
              72: '15',
              73: '16',
              74: '16',
              75: '16',
              76: '16',
              77: '17',
              78: '17',
              79: '17',
              80: '18',
              81: '18',
              82: '18',
              83: '18',
              84: '19',
              85: '19',
              86: '19',
              87: '19',
              88: '20',
              89: '20',
              90: '20',
              91: '20',
              92: '21',
              93: '21',
              94: '21',
              95: '21',
              96: '22',
              97: '22',
              98: '22',
              99: '22',
              100: '23',
              101: '23',
              102: '23',
              103: '23',
              104: '24',
              105: '24',
              106: '24',
              107: '24',
              108: '25',
              109: '25',
              110: '25',
              111: '25',
              112: '26',
              113: '26',
              114: '26',
              115: '26',
              116: '27',
              117: '27',
              118: '27',
              119: '27',
              120: '28',
              121: '28',
              122: '28',
              123: '28',
            },
          },
          ctfo_site_79703: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
              31: '9',
              32: '10',
              33: '10',
              34: '11',
              35: '11',
              36: '12',
              37: '12',
              38: '13',
              39: '13',
              40: '14',
              41: '14',
              42: '15',
              43: '15',
              44: '16',
              45: '16',
              46: '17',
              47: '17',
              48: '18',
              49: '18',
              50: '19',
              51: '19',
              52: '20',
              53: '20',
              54: '21',
              55: '21',
              56: '22',
              57: '22',
              58: '23',
              59: '23',
              60: '24',
              61: '25',
              62: '25',
              63: '26',
              64: '26',
              65: '27',
              66: '27',
              67: '28',
              68: '28',
              69: '29',
              70: '29',
              71: '30',
              72: '30',
              73: '31',
              74: '31',
              75: '32',
              76: '32',
              77: '33',
              78: '33',
              79: '34',
              80: '34',
              81: '35',
              82: '35',
              83: '36',
              84: '36',
              85: '37',
              86: '37',
              87: '38',
              88: '38',
              89: '39',
              90: '39',
              91: '40',
              92: '40',
              93: '41',
              94: '41',
              95: '42',
              96: '43',
              97: '43',
              98: '44',
              99: '44',
              100: '45',
              101: '45',
              102: '46',
              103: '46',
              104: '47',
              105: '47',
              106: '48',
              107: '48',
              108: '49',
              109: '49',
              110: '50',
              111: '50',
              112: '51',
              113: '51',
              114: '52',
              115: '52',
              116: '53',
              117: '53',
              118: '54',
              119: '54',
              120: '55',
              121: '55',
              122: '56',
              123: '56',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '6',
              28: '6',
              29: '6',
              30: '6',
              31: '7',
              32: '7',
              33: '8',
              34: '8',
              35: '9',
              36: '9',
              37: '10',
              38: '10',
              39: '11',
              40: '11',
              41: '11',
              42: '12',
              43: '12',
              44: '13',
              45: '13',
              46: '14',
              47: '14',
              48: '15',
              49: '15',
              50: '16',
              51: '16',
              52: '17',
              53: '17',
              54: '18',
              55: '18',
              56: '18',
              57: '19',
              58: '19',
              59: '20',
              60: '20',
              61: '21',
              62: '21',
              63: '22',
              64: '22',
              65: '23',
              66: '23',
              67: '24',
              68: '24',
              69: '25',
              70: '25',
              71: '26',
              72: '26',
              73: '26',
              74: '27',
              75: '27',
              76: '28',
              77: '28',
              78: '29',
              79: '29',
              80: '30',
              81: '30',
              82: '31',
              83: '31',
              84: '32',
              85: '32',
              86: '33',
              87: '33',
              88: '34',
              89: '34',
              90: '35',
              91: '35',
              92: '35',
              93: '36',
              94: '36',
              95: '37',
              96: '37',
              97: '38',
              98: '38',
              99: '39',
              100: '39',
              101: '40',
              102: '40',
              103: '41',
              104: '41',
              105: '42',
              106: '42',
              107: '43',
              108: '43',
              109: '43',
              110: '44',
              111: '44',
              112: '45',
              113: '45',
              114: '46',
              115: '46',
              116: '47',
              117: '47',
              118: '48',
              119: '48',
              120: '49',
              121: '49',
              122: '50',
              123: '50',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '1',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '9',
              29: '10',
              30: '11',
              31: '11',
              32: '12',
              33: '13',
              34: '13',
              35: '14',
              36: '14',
              37: '15',
              38: '15',
              39: '16',
              40: '16',
              41: '17',
              42: '18',
              43: '18',
              44: '19',
              45: '19',
              46: '20',
              47: '20',
              48: '21',
              49: '21',
              50: '22',
              51: '23',
              52: '23',
              53: '24',
              54: '24',
              55: '25',
              56: '25',
              57: '26',
              58: '26',
              59: '27',
              60: '28',
              61: '28',
              62: '29',
              63: '29',
              64: '30',
              65: '30',
              66: '31',
              67: '32',
              68: '32',
              69: '33',
              70: '33',
              71: '34',
              72: '34',
              73: '35',
              74: '35',
              75: '36',
              76: '37',
              77: '37',
              78: '38',
              79: '38',
              80: '39',
              81: '39',
              82: '40',
              83: '40',
              84: '41',
              85: '42',
              86: '42',
              87: '43',
              88: '43',
              89: '44',
              90: '44',
              91: '45',
              92: '45',
              93: '46',
              94: '47',
              95: '47',
              96: '48',
              97: '48',
              98: '49',
              99: '49',
              100: '50',
              101: '50',
              102: '51',
              103: '52',
              104: '52',
              105: '53',
              106: '53',
              107: '54',
              108: '54',
              109: '55',
              110: '55',
              111: '56',
              112: '57',
              113: '57',
              114: '58',
              115: '58',
              116: '59',
              117: '59',
              118: '60',
              119: '60',
              120: '61',
              121: '62',
              122: '62',
              123: '63',
            },
          },
        },
        'Western Europe': {
          ctfo_site_10056: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '5',
              15: '5',
              16: '6',
              17: '6',
              18: '7',
              19: '8',
              20: '8',
              21: '9',
              22: '10',
              23: '11',
              24: '11',
              25: '12',
              26: '13',
              27: '13',
              28: '14',
              29: '15',
              30: '16',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '5',
              17: '6',
              18: '7',
              19: '7',
              20: '8',
              21: '9',
              22: '9',
              23: '10',
              24: '11',
              25: '11',
              26: '12',
              27: '13',
              28: '13',
              29: '14',
              30: '15',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '5',
              15: '6',
              16: '6',
              17: '7',
              18: '8',
              19: '8',
              20: '9',
              21: '10',
              22: '11',
              23: '11',
              24: '12',
              25: '13',
              26: '13',
              27: '14',
              28: '15',
              29: '16',
              30: '17',
            },
          },
          ctfo_site_107152: {
            enrollments: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '3',
              24: '4',
              25: '4',
              26: '4',
              27: '4',
              28: '5',
              29: '5',
              30: '5',
              31: '6',
            },
            lower_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '5',
              30: '5',
              31: '5',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '3',
              20: '3',
              21: '3',
              22: '3',
              23: '4',
              24: '4',
              25: '4',
              26: '4',
              27: '5',
              28: '5',
              29: '5',
              30: '6',
              31: '6',
            },
          },
          ctfo_site_109658: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '1',
              26: '1',
              27: '1',
              28: '1',
              29: '2',
              30: '2',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '1',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '1',
              26: '1',
              27: '1',
              28: '1',
              29: '1',
              30: '2',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '1',
              26: '1',
              27: '1',
              28: '2',
              29: '2',
              30: '2',
            },
          },
          ctfo_site_116014: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
            },
          },
          ctfo_site_118396: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '5',
              17: '6',
              18: '6',
              19: '7',
              20: '8',
              21: '8',
              22: '9',
              23: '9',
              24: '10',
              25: '11',
              26: '11',
              27: '12',
              28: '13',
              29: '13',
              30: '14',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '8',
              22: '8',
              23: '9',
              24: '10',
              25: '10',
              26: '11',
              27: '11',
              28: '12',
              29: '13',
              30: '13',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '4',
              15: '5',
              16: '6',
              17: '6',
              18: '7',
              19: '7',
              20: '8',
              21: '9',
              22: '9',
              23: '10',
              24: '11',
              25: '11',
              26: '12',
              27: '13',
              28: '13',
              29: '14',
              30: '15',
            },
          },
          ctfo_site_11900: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '12',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '10',
              28: '11',
              29: '12',
              30: '12',
            },
          },
          ctfo_site_12672: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '3',
              20: '3',
              21: '3',
              22: '3',
              23: '4',
              24: '4',
              25: '4',
              26: '5',
              27: '5',
              28: '5',
              29: '5',
              30: '6',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '3',
              24: '4',
              25: '4',
              26: '4',
              27: '5',
              28: '5',
              29: '5',
              30: '5',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '3',
              22: '4',
              23: '4',
              24: '4',
              25: '5',
              26: '5',
              27: '5',
              28: '5',
              29: '6',
              30: '6',
            },
          },
          ctfo_site_12681: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '7',
              25: '7',
              26: '8',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_12846: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_160763: {
            enrollments: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '6',
              29: '7',
              30: '7',
              31: '7',
            },
            lower_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '4',
              25: '5',
              26: '5',
              27: '6',
              28: '6',
              29: '6',
              30: '7',
              31: '7',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '7',
              29: '7',
              30: '7',
              31: '8',
            },
          },
          ctfo_site_161747: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '4',
              22: '5',
              23: '5',
              24: '5',
              25: '6',
              26: '6',
              27: '6',
              28: '7',
              29: '7',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '8',
            },
          },
          ctfo_site_16245: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '22',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '20',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '23',
            },
          },
          ctfo_site_17091: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '3',
              28: '4',
              29: '4',
              30: '4',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
            },
          },
          ctfo_site_172612: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '4',
              15: '5',
              16: '5',
              17: '6',
              18: '7',
              19: '7',
              20: '8',
              21: '9',
              22: '9',
              23: '10',
              24: '11',
              25: '11',
              26: '12',
              27: '13',
              28: '13',
              29: '14',
              30: '15',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '4',
              15: '4',
              16: '5',
              17: '6',
              18: '6',
              19: '7',
              20: '7',
              21: '8',
              22: '9',
              23: '9',
              24: '10',
              25: '11',
              26: '11',
              27: '12',
              28: '13',
              29: '13',
              30: '14',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '5',
              15: '5',
              16: '6',
              17: '7',
              18: '7',
              19: '8',
              20: '9',
              21: '9',
              22: '10',
              23: '11',
              24: '11',
              25: '12',
              26: '13',
              27: '13',
              28: '14',
              29: '15',
              30: '16',
            },
          },
          ctfo_site_172762: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '7',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_180894: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '1',
              22: '1',
              23: '1',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '2',
              30: '2',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '2',
              30: '2',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '1',
              22: '1',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '2',
              30: '2',
            },
          },
          ctfo_site_198964: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '3',
              22: '4',
              23: '4',
              24: '4',
              25: '5',
              26: '5',
              27: '5',
              28: '5',
              29: '6',
              30: '6',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '3',
              20: '3',
              21: '3',
              22: '3',
              23: '4',
              24: '4',
              25: '4',
              26: '5',
              27: '5',
              28: '5',
              29: '5',
              30: '6',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '5',
              28: '6',
              29: '6',
              30: '6',
            },
          },
          ctfo_site_214188: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '3',
              28: '3',
              29: '4',
              30: '4',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '3',
              28: '3',
              29: '3',
              30: '4',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '3',
              28: '4',
              29: '4',
              30: '4',
            },
          },
          ctfo_site_217942: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '7',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_23000: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '3',
              29: '3',
              30: '3',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '2',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '3',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
          },
          ctfo_site_23084: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '5',
              14: '5',
              15: '6',
              16: '6',
              17: '7',
              18: '8',
              19: '9',
              20: '9',
              21: '10',
              22: '11',
              23: '12',
              24: '13',
              25: '13',
              26: '14',
              27: '15',
              28: '16',
              29: '17',
              30: '17',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '5',
              15: '5',
              16: '6',
              17: '7',
              18: '7',
              19: '8',
              20: '9',
              21: '9',
              22: '10',
              23: '11',
              24: '12',
              25: '13',
              26: '13',
              27: '14',
              28: '15',
              29: '16',
              30: '17',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '1',
              6: '1',
              7: '2',
              8: '2',
              9: '3',
              10: '3',
              11: '4',
              12: '4',
              13: '5',
              14: '6',
              15: '6',
              16: '7',
              17: '8',
              18: '9',
              19: '9',
              20: '10',
              21: '11',
              22: '12',
              23: '12',
              24: '13',
              25: '14',
              26: '15',
              27: '16',
              28: '17',
              29: '17',
              30: '18',
            },
          },
          ctfo_site_23344: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '1',
              6: '2',
              7: '3',
              8: '3',
              9: '4',
              10: '5',
              11: '6',
              12: '7',
              13: '7',
              14: '8',
              15: '9',
              16: '10',
              17: '12',
              18: '13',
              19: '14',
              20: '15',
              21: '16',
              22: '17',
              23: '19',
              24: '20',
              25: '21',
              26: '22',
              27: '24',
              28: '25',
              29: '26',
              30: '28',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '1',
              6: '2',
              7: '2',
              8: '3',
              9: '3',
              10: '4',
              11: '5',
              12: '6',
              13: '7',
              14: '7',
              15: '8',
              16: '10',
              17: '11',
              18: '12',
              19: '13',
              20: '14',
              21: '15',
              22: '16',
              23: '18',
              24: '19',
              25: '20',
              26: '21',
              27: '22',
              28: '24',
              29: '25',
              30: '26',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '2',
              6: '2',
              7: '3',
              8: '4',
              9: '5',
              10: '5',
              11: '6',
              12: '7',
              13: '8',
              14: '9',
              15: '10',
              16: '11',
              17: '12',
              18: '14',
              19: '15',
              20: '16',
              21: '17',
              22: '19',
              23: '20',
              24: '21',
              25: '22',
              26: '24',
              27: '25',
              28: '26',
              29: '28',
              30: '29',
            },
          },
          ctfo_site_239258: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '4',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '8',
              30: '9',
            },
          },
          ctfo_site_248489: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '11',
              28: '11',
              29: '12',
              30: '12',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '7',
              20: '7',
              21: '8',
              22: '8',
              23: '9',
              24: '9',
              25: '10',
              26: '11',
              27: '11',
              28: '12',
              29: '12',
              30: '13',
            },
          },
          ctfo_site_2554: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '11',
              28: '11',
              29: '12',
              30: '12',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '7',
              20: '7',
              21: '8',
              22: '8',
              23: '9',
              24: '9',
              25: '10',
              26: '11',
              27: '11',
              28: '12',
              29: '12',
              30: '13',
            },
          },
          ctfo_site_281270: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '6',
              17: '6',
              18: '7',
              19: '8',
              20: '8',
              21: '9',
              22: '10',
              23: '10',
              24: '11',
              25: '12',
              26: '13',
              27: '13',
              28: '14',
              29: '15',
              30: '16',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '5',
              17: '6',
              18: '6',
              19: '7',
              20: '8',
              21: '8',
              22: '9',
              23: '10',
              24: '10',
              25: '11',
              26: '12',
              27: '13',
              28: '13',
              29: '14',
              30: '15',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '2',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '5',
              15: '6',
              16: '6',
              17: '7',
              18: '8',
              19: '8',
              20: '9',
              21: '10',
              22: '10',
              23: '11',
              24: '12',
              25: '13',
              26: '13',
              27: '14',
              28: '15',
              29: '16',
              30: '16',
            },
          },
          ctfo_site_28591: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '3',
              22: '4',
              23: '4',
              24: '4',
              25: '5',
              26: '5',
              27: '5',
              28: '5',
              29: '6',
              30: '6',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '3',
              20: '3',
              21: '3',
              22: '3',
              23: '4',
              24: '4',
              25: '4',
              26: '5',
              27: '5',
              28: '5',
              29: '5',
              30: '6',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '5',
              28: '6',
              29: '6',
              30: '6',
            },
          },
          ctfo_site_28627: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '2',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '3',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '3',
              26: '3',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
          },
          ctfo_site_306882: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '3',
              29: '3',
              30: '3',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
          },
          ctfo_site_310654: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '6',
              22: '6',
              23: '7',
              24: '7',
              25: '8',
              26: '8',
              27: '9',
              28: '9',
              29: '10',
              30: '10',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '11',
            },
          },
          ctfo_site_316865: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '1',
              26: '1',
              27: '2',
              28: '2',
              29: '2',
              30: '2',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '1',
              26: '1',
              27: '1',
              28: '2',
              29: '2',
              30: '2',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '1',
              24: '1',
              25: '1',
              26: '2',
              27: '2',
              28: '2',
              29: '2',
              30: '2',
            },
          },
          ctfo_site_31894: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_33691: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '11',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
          },
          ctfo_site_35530: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '9',
              28: '9',
              29: '10',
              30: '10',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '8',
              28: '9',
              29: '9',
              30: '10',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
          },
          ctfo_site_35541: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '11',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
          },
          ctfo_site_36339: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '4',
              21: '4',
              22: '5',
              23: '5',
              24: '5',
              25: '6',
              26: '6',
              27: '6',
              28: '7',
              29: '7',
              30: '7',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '6',
              29: '7',
              30: '7',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '7',
              30: '8',
            },
          },
          ctfo_site_3895: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '13',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '12',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '13',
            },
          },
          ctfo_site_4241: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '3',
              28: '4',
              29: '4',
              30: '4',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
            },
          },
          ctfo_site_44755: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '6',
              28: '6',
              29: '6',
              30: '7',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '5',
              28: '6',
              29: '6',
              30: '6',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '6',
              29: '7',
              30: '7',
            },
          },
          ctfo_site_45218: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '7',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_46571: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '14',
              25: '14',
              26: '15',
              27: '16',
              28: '17',
              29: '18',
              30: '19',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '13',
              25: '14',
              26: '14',
              27: '15',
              28: '16',
              29: '17',
              30: '18',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '14',
              25: '15',
              26: '16',
              27: '17',
              28: '18',
              29: '19',
              30: '20',
            },
          },
          ctfo_site_5042: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '12',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '10',
              28: '11',
              29: '12',
              30: '12',
            },
          },
          ctfo_site_53658: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '2',
              30: '3',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '3',
              29: '3',
              30: '3',
            },
          },
          ctfo_site_56019: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '8',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '7',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_56037: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '5',
              15: '6',
              16: '6',
              17: '7',
              18: '8',
              19: '8',
              20: '9',
              21: '10',
              22: '11',
              23: '11',
              24: '12',
              25: '13',
              26: '14',
              27: '14',
              28: '15',
              29: '16',
              30: '17',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '2',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '6',
              17: '6',
              18: '7',
              19: '8',
              20: '8',
              21: '9',
              22: '10',
              23: '11',
              24: '11',
              25: '12',
              26: '13',
              27: '14',
              28: '14',
              29: '15',
              30: '16',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '1',
              6: '1',
              7: '2',
              8: '2',
              9: '3',
              10: '3',
              11: '4',
              12: '4',
              13: '5',
              14: '5',
              15: '6',
              16: '7',
              17: '7',
              18: '8',
              19: '9',
              20: '10',
              21: '10',
              22: '11',
              23: '12',
              24: '13',
              25: '14',
              26: '14',
              27: '15',
              28: '16',
              29: '17',
              30: '18',
            },
          },
          ctfo_site_57131: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '11',
              28: '11',
              29: '12',
              30: '12',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '8',
              22: '8',
              23: '9',
              24: '9',
              25: '10',
              26: '11',
              27: '11',
              28: '12',
              29: '12',
              30: '13',
            },
          },
          ctfo_site_57982: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '4',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '7',
              30: '8',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },
          },
          ctfo_site_59612: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '4',
              16: '5',
              17: '6',
              18: '6',
              19: '7',
              20: '7',
              21: '8',
              22: '9',
              23: '9',
              24: '10',
              25: '10',
              26: '11',
              27: '12',
              28: '12',
              29: '13',
              30: '14',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '9',
              24: '9',
              25: '10',
              26: '10',
              27: '11',
              28: '12',
              29: '12',
              30: '13',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '5',
              16: '5',
              17: '6',
              18: '7',
              19: '7',
              20: '8',
              21: '8',
              22: '9',
              23: '10',
              24: '10',
              25: '11',
              26: '12',
              27: '12',
              28: '13',
              29: '14',
              30: '14',
            },
          },
          ctfo_site_62415: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '11',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '1',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '6',
              23: '7',
              24: '7',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '10',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
          },
          ctfo_site_63181: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '3',
              29: '3',
              30: '3',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
            },
          },
          ctfo_site_63679: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '6',
              28: '6',
              29: '6',
              30: '7',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '3',
              22: '4',
              23: '4',
              24: '4',
              25: '5',
              26: '5',
              27: '5',
              28: '6',
              29: '6',
              30: '6',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '6',
              29: '7',
              30: '7',
            },
          },
          ctfo_site_643: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '10',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '9',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '0',
              28: '0',
              29: '0',
              30: '10',
            },
          },
          ctfo_site_67948: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '3',
              15: '4',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '1',
              10: '2',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '11',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '1',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '11',
              28: '11',
              29: '12',
              30: '12',
            },
          },
          ctfo_site_68135: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '2',
              16: '2',
              17: '2',
              18: '2',
              19: '3',
              20: '3',
              21: '3',
              22: '4',
              23: '4',
              24: '4',
              25: '4',
              26: '5',
              27: '5',
              28: '5',
              29: '5',
              30: '6',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '4',
              24: '4',
              25: '4',
              26: '4',
              27: '5',
              28: '5',
              29: '5',
              30: '5',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '3',
              22: '4',
              23: '4',
              24: '4',
              25: '5',
              26: '5',
              27: '5',
              28: '5',
              29: '6',
              30: '6',
            },
          },
          ctfo_site_68564: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '2',
              7: '3',
              8: '3',
              9: '4',
              10: '5',
              11: '6',
              12: '7',
              13: '8',
              14: '9',
              15: '10',
              16: '11',
              17: '12',
              18: '13',
              19: '14',
              20: '16',
              21: '17',
              22: '18',
              23: '19',
              24: '21',
              25: '22',
              26: '23',
              27: '25',
              28: '26',
              29: '27',
              30: '29',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '1',
              6: '2',
              7: '2',
              8: '3',
              9: '4',
              10: '4',
              11: '5',
              12: '6',
              13: '7',
              14: '8',
              15: '9',
              16: '10',
              17: '11',
              18: '12',
              19: '13',
              20: '15',
              21: '16',
              22: '17',
              23: '18',
              24: '20',
              25: '21',
              26: '22',
              27: '23',
              28: '25',
              29: '26',
              30: '27',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '2',
              6: '2',
              7: '3',
              8: '4',
              9: '5',
              10: '6',
              11: '7',
              12: '8',
              13: '8',
              14: '9',
              15: '11',
              16: '12',
              17: '13',
              18: '14',
              19: '15',
              20: '17',
              21: '18',
              22: '19',
              23: '21',
              24: '22',
              25: '23',
              26: '25',
              27: '26',
              28: '27',
              29: '29',
              30: '30',
            },
          },
          ctfo_site_70031: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '10',
              29: '11',
              30: '11',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '10',
              30: '11',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '7',
              22: '8',
              23: '8',
              24: '9',
              25: '9',
              26: '10',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
          },
          ctfo_site_72157: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
            },
          },
          ctfo_site_72571: {
            enrollments: {
              1: '0',
              2: '0',
              3: '1',
              4: '2',
              5: '3',
              6: '4',
              7: '5',
              8: '7',
              9: '9',
              10: '10',
              11: '12',
              12: '14',
              13: '16',
              14: '18',
              15: '21',
              16: '23',
              17: '26',
              18: '28',
              19: '31',
              20: '34',
              21: '37',
              22: '40',
              23: '43',
              24: '45',
              25: '48',
              26: '52',
              27: '55',
              28: '58',
              29: '61',
              30: '64',
              31: '67',
            },
            lower_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '1',
              5: '2',
              6: '3',
              7: '4',
              8: '6',
              9: '7',
              10: '9',
              11: '11',
              12: '13',
              13: '15',
              14: '17',
              15: '19',
              16: '21',
              17: '24',
              18: '26',
              19: '29',
              20: '31',
              21: '34',
              22: '37',
              23: '40',
              24: '43',
              25: '46',
              26: '49',
              27: '52',
              28: '55',
              29: '58',
              30: '61',
              31: '64',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              1: '0',
              2: '1',
              3: '1',
              4: '2',
              5: '3',
              6: '5',
              7: '6',
              8: '8',
              9: '10',
              10: '12',
              11: '14',
              12: '16',
              13: '18',
              14: '20',
              15: '22',
              16: '25',
              17: '28',
              18: '31',
              19: '33',
              20: '36',
              21: '39',
              22: '42',
              23: '45',
              24: '48',
              25: '51',
              26: '54',
              27: '58',
              28: '61',
              29: '64',
              30: '67',
              31: '71',
            },
          },
          ctfo_site_7372: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '2',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '5',
              17: '6',
              18: '7',
              19: '7',
              20: '8',
              21: '9',
              22: '9',
              23: '10',
              24: '11',
              25: '11',
              26: '12',
              27: '13',
              28: '13',
              29: '14',
              30: '15',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '1',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '6',
              18: '6',
              19: '7',
              20: '7',
              21: '8',
              22: '9',
              23: '9',
              24: '10',
              25: '11',
              26: '11',
              27: '12',
              28: '13',
              29: '13',
              30: '14',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '1',
              8: '2',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '5',
              15: '5',
              16: '6',
              17: '7',
              18: '7',
              19: '8',
              20: '9',
              21: '9',
              22: '10',
              23: '11',
              24: '11',
              25: '12',
              26: '13',
              27: '13',
              28: '14',
              29: '15',
              30: '16',
            },
          },
          ctfo_site_76048: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '17',
              16: '20',
              17: '22',
              18: '24',
              19: '26',
              20: '28',
              21: '30',
              22: '32',
              23: '35',
              24: '37',
              25: '39',
              26: '42',
              27: '44',
              28: '46',
              29: '49',
              30: '51',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '16',
              16: '18',
              17: '20',
              18: '22',
              19: '24',
              20: '26',
              21: '28',
              22: '30',
              23: '32',
              24: '35',
              25: '37',
              26: '39',
              27: '42',
              28: '44',
              29: '46',
              30: '49',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '19',
              16: '21',
              17: '23',
              18: '25',
              19: '28',
              20: '30',
              21: '32',
              22: '34',
              23: '37',
              24: '39',
              25: '41',
              26: '44',
              27: '46',
              28: '49',
              29: '51',
              30: '54',
            },
          },
          ctfo_site_76105: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '3',
              28: '4',
              29: '4',
              30: '4',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '2',
              20: '2',
              21: '2',
              22: '2',
              23: '2',
              24: '3',
              25: '3',
              26: '3',
              27: '3',
              28: '3',
              29: '4',
              30: '4',
            },
            previous_probability_complete_target: {
              1: '0.0',
              2: '56.89',
              3: '58.39',
              4: '59.37',
              5: '60.13',
              6: '60.76',
              7: '61.78',
              8: '62.21',
              9: '62.61',
              10: '62.98',
              11: '63.33',
              12: '63.66',
              13: '63.97',
              14: '64.26',
              15: '64.55',
              16: '64.82',
              17: '65.09',
              18: '65.35',
              19: '65.6',
              20: '65.84',
              21: '66.08',
              22: '66.31',
              23: '66.54',
              24: '66.76',
              25: '66.98',
              26: '67.19',
              27: '67.41',
              28: '67.62',
              29: '67.82',
              30: '68.03',
              31: '68.23',
              32: '68.43',
              33: '68.63',
              34: '68.82',
              35: '69.02',
              36: '69.21',
              37: '69.41',
              38: '69.6',
              39: '69.79',
              40: '69.98',
              41: '70.17',
              42: '70.36',
              43: '70.55',
              44: '70.74',
              45: '70.93',
              46: '71.12',
              47: '71.31',
              48: '71.51',
              49: '71.7',
            },
            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '2',
              20: '2',
              21: '2',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '3',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },
          },
          ctfo_site_76141: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '6',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '6',
              24: '6',
              25: '6',
              26: '7',
              27: '7',
              28: '8',
              29: '8',
              30: '9',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '3',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '7',
              25: '7',
              26: '8',
              27: '8',
              28: '9',
              29: '9',
              30: '9',
            },
          },
          ctfo_site_77458: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '4',
              22: '5',
              23: '5',
              24: '5',
              25: '6',
              26: '6',
              27: '6',
              28: '7',
              29: '7',
              30: '8',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '6',
              29: '7',
              30: '7',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '3',
              18: '4',
              19: '4',
              20: '4',
              21: '5',
              22: '5',
              23: '5',
              24: '6',
              25: '6',
              26: '6',
              27: '7',
              28: '7',
              29: '8',
              30: '8',
            },
          },
          ctfo_site_81787: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '8',
              25: '8',
              26: '9',
              27: '9',
              28: '10',
              29: '11',
              30: '11',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '5',
              21: '6',
              22: '6',
              23: '7',
              24: '7',
              25: '8',
              26: '8',
              27: '9',
              28: '9',
              29: '10',
              30: '11',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '4',
              17: '5',
              18: '5',
              19: '6',
              20: '6',
              21: '7',
              22: '7',
              23: '8',
              24: '8',
              25: '9',
              26: '9',
              27: '10',
              28: '11',
              29: '11',
              30: '12',
            },
          },
          ctfo_site_8226: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '2',
              10: '2',
              11: '3',
              12: '3',
              13: '4',
              14: '4',
              15: '5',
              16: '5',
              17: '6',
              18: '6',
              19: '7',
              20: '8',
              21: '8',
              22: '9',
              23: '9',
              24: '10',
              25: '11',
              26: '11',
              27: '12',
              28: '13',
              29: '13',
              30: '14',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '2',
              10: '2',
              11: '2',
              12: '3',
              13: '3',
              14: '4',
              15: '4',
              16: '5',
              17: '5',
              18: '6',
              19: '6',
              20: '7',
              21: '8',
              22: '8',
              23: '9',
              24: '10',
              25: '10',
              26: '11',
              27: '11',
              28: '12',
              29: '13',
              30: '13',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '2',
              10: '3',
              11: '3',
              12: '4',
              13: '4',
              14: '4',
              15: '5',
              16: '6',
              17: '6',
              18: '7',
              19: '7',
              20: '8',
              21: '9',
              22: '9',
              23: '10',
              24: '11',
              25: '11',
              26: '12',
              27: '13',
              28: '13',
              29: '14',
              30: '15',
            },
          },
          ctfo_site_83607: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '2',
              12: '2',
              13: '2',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '4',
              19: '5',
              20: '5',
              21: '6',
              22: '6',
              23: '7',
              24: '7',
              25: '7',
              26: '8',
              27: '8',
              28: '9',
              29: '9',
              30: '10',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '2',
              12: '2',
              13: '2',
              14: '2',
              15: '3',
              16: '3',
              17: '4',
              18: '4',
              19: '4',
              20: '5',
              21: '5',
              22: '6',
              23: '6',
              24: '7',
              25: '7',
              26: '7',
              27: '8',
              28: '8',
              29: '9',
              30: '9',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '2',
              12: '2',
              13: '3',
              14: '3',
              15: '3',
              16: '4',
              17: '4',
              18: '5',
              19: '5',
              20: '6',
              21: '6',
              22: '7',
              23: '7',
              24: '7',
              25: '8',
              26: '8',
              27: '9',
              28: '9',
              29: '10',
              30: '10',
            },
          },
          ctfo_site_84923: {
            enrollments: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '3',
              29: '3',
              30: '3',
              31: '3',
            },
            lower_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
              31: '3',
            },

            upper_confidence: {
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '3',
              28: '3',
              29: '3',
              30: '3',
              31: '3',
            },
          },
          ctfo_site_85345: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '5',
              28: '5',
              29: '6',
              30: '6',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '5',
              28: '5',
              29: '5',
              30: '6',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '0',
              16: '0',
              17: '0',
              18: '0',
              19: '0',
              20: '0',
              21: '0',
              22: '0',
              23: '0',
              24: '0',
              25: '0',
              26: '0',
              27: '5',
              28: '6',
              29: '6',
              30: '6',
            },
          },
          ctfo_site_87094: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '3',
              30: '3',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '0',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '1',
              22: '1',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '2',
              29: '2',
              30: '3',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '0',
              12: '0',
              13: '0',
              14: '1',
              15: '1',
              16: '1',
              17: '1',
              18: '1',
              19: '1',
              20: '1',
              21: '2',
              22: '2',
              23: '2',
              24: '2',
              25: '2',
              26: '2',
              27: '2',
              28: '3',
              29: '3',
              30: '3',
            },
          },
          ctfo_site_97484: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '3',
              22: '3',
              23: '3',
              24: '3',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '5',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '0',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '1',
              17: '2',
              18: '2',
              19: '2',
              20: '2',
              21: '2',
              22: '3',
              23: '3',
              24: '3',
              25: '3',
              26: '4',
              27: '4',
              28: '4',
              29: '4',
              30: '4',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '0',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '1',
              15: '1',
              16: '2',
              17: '2',
              18: '2',
              19: '2',
              20: '3',
              21: '3',
              22: '3',
              23: '3',
              24: '4',
              25: '4',
              26: '4',
              27: '4',
              28: '4',
              29: '5',
              30: '5',
            },
          },
          ctfo_site_976: {
            enrollments: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '2',
              14: '2',
              15: '2',
              16: '2',
              17: '3',
              18: '3',
              19: '3',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '6',
              29: '7',
              30: '7',
            },
            lower_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '0',
              9: '1',
              10: '1',
              11: '1',
              12: '1',
              13: '1',
              14: '2',
              15: '2',
              16: '2',
              17: '2',
              18: '3',
              19: '3',
              20: '3',
              21: '4',
              22: '4',
              23: '4',
              24: '5',
              25: '5',
              26: '5',
              27: '6',
              28: '6',
              29: '6',
              30: '7',
            },

            upper_confidence: {
              0: '0',
              1: '0',
              2: '0',
              3: '0',
              4: '0',
              5: '0',
              6: '0',
              7: '0',
              8: '1',
              9: '1',
              10: '1',
              11: '1',
              12: '2',
              13: '2',
              14: '2',
              15: '2',
              16: '3',
              17: '3',
              18: '3',
              19: '4',
              20: '4',
              21: '4',
              22: '4',
              23: '5',
              24: '5',
              25: '5',
              26: '6',
              27: '6',
              28: '7',
              29: '7',
              30: '7',
            },
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const probCompleteTargetChart = {
      result: {
        country: {
          Germany: {
            Probability: {
              '0.0': '0.0',
              0.01: '7.89',
              0.02: '7.92',
              0.03: '7.98',
              0.04: '8.0',
              0.05: '8.03',
              0.06: '8.04',
              0.07: '8.06',
              0.08: '8.07',
              0.09: '8.09',
              0.1: '8.1',
              0.12: '8.12',
              0.13: '8.14',
              0.14: '8.15',
              0.15: '8.16',
              0.16: '8.17',
              0.17: '8.18',
              0.18: '8.19',
              0.2: '8.2',
              0.21: '8.22',
              0.23: '8.23',
              0.24: '8.24',
              0.26: '8.25',
              0.28: '8.27',
              0.3: '8.28',
              0.32: '8.3',
              0.34: '8.31',
              0.36: '8.33',
              0.38: '8.34',
              0.39: '8.35',
              0.4: '8.36',
              0.42: '8.37',
              0.43: '8.38',
              0.44: '8.39',
              0.46: '8.4',
              0.47: '8.41',
              0.48: '8.42',
              0.5: '8.43',
              0.51: '8.44',
              0.52: '8.45',
              0.54: '8.46',
              0.55: '8.47',
              0.56: '8.48',
              0.57: '8.49',
              0.59: '8.5',
              0.6: '8.51',
              0.61: '8.52',
              0.63: '8.53',
              0.65: '8.55',
              0.66: '8.56',
              0.67: '8.57',
              0.68: '8.58',
              0.69: '8.59',
              0.71: '8.6',
              0.73: '8.62',
              0.75: '8.64',
              0.76: '8.66',
              0.77: '8.67',
              0.78: '8.68',
              0.79: '8.69',
              0.8: '8.71',
              0.81: '8.72',
              0.83: '8.74',
              0.84: '8.76',
              0.85: '8.77',
              0.86: '8.8',
              0.87: '8.81',
              0.88: '8.83',
              0.89: '8.84',
              0.9: '8.88',
              0.91: '8.89',
              0.92: '8.93',
              0.93: '8.94',
              0.94: '8.99',
              0.95: '9.01',
              0.96: '9.06',
              0.97: '9.12',
              0.98: '9.25',
              0.99: '9.33',
              '1.0': '30.0',
            },
            previous_probability_complete_target: {
              '0.0': '0.0',
              0.01: '16.06',
              0.02: '16.57',
              0.03: '16.91',
              0.04: '17.18',
              0.05: '17.41',
              0.06: '17.6',
              0.07: '17.78',
              0.08: '17.94',
              0.09: '18.09',
              0.1: '18.23',
              0.11: '18.37',
              0.12: '18.49',
              0.13: '18.62',
              0.14: '18.73',
              0.15: '18.84',
              0.16: '18.95',
              0.17: '19.06',
              0.18: '19.16',
              0.19: '19.26',
              0.2: '19.36',
              0.21: '19.45',
              0.22: '19.55',
              0.23: '19.64',
              0.24: '19.73',
              0.25: '19.82',
              0.26: '19.91',
              0.27: '20.0',
              0.28: '20.08',
              0.29: '20.16',
              0.3: '20.25',
              0.31: '20.33',
              0.32: '20.41',
              0.33: '20.49',
              0.34: '20.57',
              0.35: '20.65',
              0.36: '20.73',
              0.37: '20.81',
              0.38: '20.89',
              0.39: '20.97',
              0.4: '21.05',
              0.41: '21.13',
              0.42: '21.21',
              0.43: '21.28',
              0.44: '21.36',
              0.45: '21.44',
              0.46: '21.52',
              0.47: '21.6',
              0.48: '21.69',
              0.49: '21.77',
              0.5: '21.85',
              0.51: '21.93',
              0.52: '22.02',
              0.53: '22.1',
              0.54: '22.19',
              0.55: '22.27',
              0.56: '22.36',
              0.57: '22.45',
              0.58: '22.54',
              0.59: '22.63',
              0.6: '22.72',
              0.61: '22.82',
              0.62: '22.92',
              0.63: '23.01',
              0.64: '23.11',
              0.65: '23.21',
              0.66: '23.31',
              0.67: '23.42',
              0.68: '23.52',
              0.69: '23.63',
              0.7: '23.75',
              0.71: '23.86',
              0.72: '23.98',
              0.73: '24.1',
              0.74: '24.22',
              0.75: '24.34',
              0.76: '24.47',
              0.77: '24.61',
              0.78: '24.75',
              0.79: '24.89',
              0.8: '25.04',
              0.81: '25.2',
              0.82: '25.37',
              0.83: '25.54',
              0.84: '25.73',
              0.85: '25.92',
              0.86: '26.13',
              0.87: '26.35',
              0.88: '26.59',
              0.89: '26.85',
              0.9: '27.14',
              0.91: '27.45',
              0.92: '27.8',
              0.93: '28.19',
              0.94: '28.64',
              0.95: '29.17',
              0.96: '29.81',
              0.97: '30.61',
              0.98: '31.72',
              0.99: '33.67',
              '1.0': '50.0',
            },
            target_no_of_patients: '200',
          },
          'United States': {
            Probability: {
              '0.0': '0.0',
              0.01: '26.06',
              0.02: '26.57',
              0.03: '26.91',
              0.04: '27.18',
              0.05: '27.41',
              0.06: '27.6',
              0.07: '27.78',
              0.08: '27.94',
              0.09: '28.09',
              0.1: '28.23',
              0.11: '28.37',
              0.12: '28.49',
              0.13: '28.62',
              0.14: '28.73',
              0.15: '28.84',
              0.16: '28.95',
              0.17: '29.06',
              0.18: '29.16',
              0.19: '29.26',
              0.2: '29.36',
              0.21: '29.45',
              0.22: '29.55',
              0.23: '29.64',
              0.24: '29.73',
              0.25: '29.82',
              0.26: '29.91',
              0.27: '30.0',
              0.28: '30.08',
              0.29: '30.16',
              0.3: '30.25',
              0.31: '30.33',
              0.32: '30.41',
              0.33: '30.49',
              0.34: '30.57',
              0.35: '30.65',
              0.36: '30.73',
              0.37: '30.81',
              0.38: '30.89',
              0.39: '30.97',
              0.4: '31.05',
              0.41: '31.13',
              0.42: '31.21',
              0.43: '31.28',
              0.44: '31.36',
              0.45: '31.44',
              0.46: '31.52',
              0.47: '31.6',
              0.48: '31.69',
              0.49: '31.77',
              0.5: '31.85',
              0.51: '31.93',
              0.52: '32.02',
              0.53: '32.1',
              0.54: '32.19',
              0.55: '32.27',
              0.56: '32.36',
              0.57: '32.45',
              0.58: '32.54',
              0.59: '32.63',
              0.6: '32.72',
              0.61: '32.82',
              0.62: '32.92',
              0.63: '33.01',
              0.64: '33.11',
              0.65: '33.21',
              0.66: '33.31',
              0.67: '33.42',
              0.68: '33.52',
              0.69: '33.63',
              0.7: '33.75',
              0.71: '33.86',
              0.72: '33.98',
              0.73: '34.1',
              0.74: '34.22',
              0.75: '34.34',
              0.76: '34.47',
              0.77: '34.61',
              0.78: '34.75',
              0.79: '34.89',
              0.8: '35.04',
              0.81: '35.2',
              0.82: '35.37',
              0.83: '35.54',
              0.84: '35.73',
              0.85: '35.92',
              0.86: '36.13',
              0.87: '36.35',
              0.88: '36.59',
              0.89: '36.85',
              0.9: '37.14',
              0.91: '37.45',
              0.92: '37.8',
              0.93: '38.19',
              0.94: '38.64',
              0.95: '39.17',
              0.96: '39.81',
              0.97: '40.61',
              0.98: '41.72',
              0.99: '43.67',
              '1.0': '120.0',
            },
            previous_probability_complete_target: {
              '0.0': '0.0',
              0.01: '16.06',
              0.02: '16.57',
              0.03: '16.91',
              0.04: '17.18',
              0.05: '17.41',
              0.06: '17.6',
              0.07: '17.78',
              0.08: '17.94',
              0.09: '18.09',
              0.1: '18.23',
              0.11: '18.37',
              0.12: '18.49',
              0.13: '18.62',
              0.14: '18.73',
              0.15: '18.84',
              0.16: '18.95',
              0.17: '19.06',
              0.18: '19.16',
              0.19: '19.26',
              0.2: '19.36',
              0.21: '19.45',
              0.22: '19.55',
              0.23: '19.64',
              0.24: '19.73',
              0.25: '19.82',
              0.26: '19.91',
              0.27: '20.0',
              0.28: '20.08',
              0.29: '20.16',
              0.3: '20.25',
              0.31: '20.33',
              0.32: '20.41',
              0.33: '20.49',
              0.34: '20.57',
              0.35: '20.65',
              0.36: '20.73',
              0.37: '20.81',
              0.38: '20.89',
              0.39: '20.97',
              0.4: '21.05',
              0.41: '21.13',
              0.42: '21.21',
              0.43: '21.28',
              0.44: '21.36',
              0.45: '21.44',
              0.46: '21.52',
              0.47: '21.6',
              0.48: '21.69',
              0.49: '21.77',
              0.5: '21.85',
              0.51: '21.93',
              0.52: '22.02',
              0.53: '22.1',
              0.54: '22.19',
              0.55: '22.27',
              0.56: '22.36',
              0.57: '22.45',
              0.58: '22.54',
              0.59: '22.63',
              0.6: '22.72',
              0.61: '22.82',
              0.62: '22.92',
              0.63: '23.01',
              0.64: '23.11',
              0.65: '23.21',
              0.66: '23.31',
              0.67: '23.42',
              0.68: '23.52',
              0.69: '23.63',
              0.7: '23.75',
              0.71: '23.86',
              0.72: '23.98',
              0.73: '24.1',
              0.74: '24.22',
              0.75: '24.34',
              0.76: '24.47',
              0.77: '24.61',
              0.78: '24.75',
              0.79: '24.89',
              0.8: '25.04',
              0.81: '25.2',
              0.82: '25.37',
              0.83: '25.54',
              0.84: '25.73',
              0.85: '25.92',
              0.86: '26.13',
              0.87: '26.35',
              0.88: '26.59',
              0.89: '26.85',
              0.9: '27.14',
              0.91: '27.45',
              0.92: '27.8',
              0.93: '28.19',
              0.94: '28.64',
              0.95: '29.17',
              0.96: '29.81',
              0.97: '30.61',
              0.98: '31.72',
              0.99: '33.67',
              '1.0': '50.0',
            },
            target_no_of_patients: '100',
          },
        },
        global: {
          global: {
            Probability: {
              '0.0': '0.0',
              0.01: '26.06',
              0.02: '26.57',
              0.03: '26.91',
              0.04: '27.18',
              0.05: '27.41',
              0.06: '27.6',
              0.07: '27.78',
              0.08: '27.94',
              0.09: '28.09',
              0.1: '28.23',
              0.11: '28.37',
              0.12: '28.49',
              0.13: '28.62',
              0.14: '28.73',
              0.15: '28.84',
              0.16: '28.95',
              0.17: '29.06',
              0.18: '29.16',
              0.19: '29.26',
              0.2: '29.36',
              0.21: '29.45',
              0.22: '29.55',
              0.23: '29.64',
              0.24: '29.73',
              0.25: '29.82',
              0.26: '29.91',
              0.27: '30.0',
              0.28: '30.08',
              0.29: '30.16',
              0.3: '30.25',
              0.31: '30.33',
              0.32: '30.41',
              0.33: '30.49',
              0.34: '30.57',
              0.35: '30.65',
              0.36: '30.73',
              0.37: '30.81',
              0.38: '30.89',
              0.39: '30.97',
              0.4: '31.05',
              0.41: '31.13',
              0.42: '31.21',
              0.43: '31.28',
              0.44: '31.36',
              0.45: '31.44',
              0.46: '31.52',
              0.47: '31.6',
              0.48: '31.69',
              0.49: '31.77',
              0.5: '31.85',
              0.51: '31.93',
              0.52: '32.02',
              0.53: '32.1',
              0.54: '32.19',
              0.55: '32.27',
              0.56: '32.36',
              0.57: '32.45',
              0.58: '32.54',
              0.59: '32.63',
              0.6: '32.72',
              0.61: '32.82',
              0.62: '32.92',
              0.63: '33.01',
              0.64: '33.11',
              0.65: '33.21',
              0.66: '33.31',
              0.67: '33.42',
              0.68: '33.52',
              0.69: '33.63',
              0.7: '33.75',
              0.71: '33.86',
              0.72: '33.98',
              0.73: '34.1',
              0.74: '34.22',
              0.75: '34.34',
              0.76: '34.47',
              0.77: '34.61',
              0.78: '34.75',
              0.79: '34.89',
              0.8: '35.04',
              0.81: '35.2',
              0.82: '35.37',
              0.83: '35.54',
              0.84: '35.73',
              0.85: '35.92',
              0.86: '36.13',
              0.87: '36.35',
              0.88: '36.59',
              0.89: '36.85',
              0.9: '37.14',
              0.91: '37.45',
              0.92: '37.8',
              0.93: '38.19',
              0.94: '38.64',
              0.95: '39.17',
              0.96: '39.81',
              0.97: '40.61',
              0.98: '41.72',
              0.99: '43.67',
              '1.0': '120.0',
            },
            previous_probability_complete_target: {
              '0.0': '0.0',
              0.01: '26.06',
              0.02: '26.57',
              0.03: '26.91',
              0.04: '27.18',
              0.05: '27.41',
              0.06: '27.6',
              0.07: '27.78',
              0.08: '27.94',
              0.09: '28.09',
              0.1: '28.23',
              0.11: '28.37',
              0.12: '28.49',
              0.13: '28.62',
              0.14: '28.73',
              0.15: '28.84',
              0.16: '28.95',
              0.17: '29.06',
              0.18: '29.16',
              0.19: '29.26',
              0.2: '29.36',
              0.21: '29.45',
              0.22: '29.55',
              0.23: '29.64',
              0.24: '29.73',
              0.25: '29.82',
              0.26: '29.91',
              0.27: '30.0',
              0.28: '30.08',
              0.29: '30.16',
              0.3: '30.25',
              0.31: '30.33',
              0.32: '30.41',
              0.33: '30.49',
              0.34: '30.57',
              0.35: '30.65',
              0.36: '30.73',
              0.37: '30.81',
              0.38: '30.89',
              0.39: '30.97',
              0.4: '31.05',
              0.41: '31.13',
              0.42: '31.21',
              0.43: '31.28',
              0.44: '31.36',
              0.45: '31.44',
              0.46: '31.52',
              0.47: '31.6',
              0.48: '31.69',
              0.49: '31.77',
              0.5: '31.85',
              0.51: '31.93',
              0.52: '32.02',
              0.53: '32.1',
              0.54: '32.19',
              0.55: '32.27',
              0.56: '32.36',
              0.57: '32.45',
              0.58: '32.54',
              0.59: '32.63',
              0.6: '32.72',
              0.61: '32.82',
              0.62: '32.92',
              0.63: '33.01',
              0.64: '33.11',
              0.65: '33.21',
              0.66: '33.31',
              0.67: '33.42',
              0.68: '33.52',
              0.69: '33.63',
              0.7: '33.75',
              0.71: '33.86',
              0.72: '33.98',
              0.73: '34.1',
              0.74: '34.22',
              0.75: '34.34',
              0.76: '34.47',
              0.77: '34.61',
              0.78: '34.75',
              0.79: '34.89',
              0.8: '35.04',
              0.81: '35.2',
              0.82: '35.37',
              0.83: '35.54',
              0.84: '35.73',
              0.85: '35.92',
              0.86: '36.13',
              0.87: '36.35',
              0.88: '36.59',
              0.89: '36.85',
              0.9: '37.14',
              0.91: '37.45',
              0.92: '37.8',
              0.93: '38.19',
              0.94: '38.64',
              0.95: '39.17',
              0.96: '39.81',
              0.97: '40.61',
              0.98: '41.72',
              0.99: '43.67',
              '1.0': '120.0',
            },
            target_no_of_patients: '500',
          },
        },
        region: {
          'Northern America': {
            Probability: {
              '0.0': '0.0',
              0.01: '26.06',
              0.02: '26.57',
              0.03: '26.91',
              0.04: '27.18',
              0.05: '27.41',
              0.06: '27.6',
              0.07: '27.78',
              0.08: '27.94',
              0.09: '28.09',
              0.1: '28.23',
              0.11: '28.37',
              0.12: '28.49',
              0.13: '28.62',
              0.14: '28.73',
              0.15: '28.84',
              0.16: '28.95',
              0.17: '29.06',
              0.18: '29.16',
              0.19: '29.26',
              0.2: '29.36',
              0.21: '29.45',
              0.22: '29.55',
              0.23: '29.64',
              0.24: '29.73',
              0.25: '29.82',
              0.26: '29.91',
              0.27: '30.0',
              0.28: '30.08',
              0.29: '30.16',
              0.3: '30.25',
              0.31: '30.33',
              0.32: '30.41',
              0.33: '30.49',
              0.34: '30.57',
              0.35: '30.65',
              0.36: '30.73',
              0.37: '30.81',
              0.38: '30.89',
              0.39: '30.97',
              0.4: '31.05',
              0.41: '31.13',
              0.42: '31.21',
              0.43: '31.28',
              0.44: '31.36',
              0.45: '31.44',
              0.46: '31.52',
              0.47: '31.6',
              0.48: '31.69',
              0.49: '31.77',
              0.5: '31.85',
              0.51: '31.93',
              0.52: '32.02',
              0.53: '32.1',
              0.54: '32.19',
              0.55: '32.27',
              0.56: '32.36',
              0.57: '32.45',
              0.58: '32.54',
              0.59: '32.63',
              0.6: '32.72',
              0.61: '32.82',
              0.62: '32.92',
              0.63: '33.01',
              0.64: '33.11',
              0.65: '33.21',
              0.66: '33.31',
              0.67: '33.42',
              0.68: '33.52',
              0.69: '33.63',
              0.7: '33.75',
              0.71: '33.86',
              0.72: '33.98',
              0.73: '34.1',
              0.74: '34.22',
              0.75: '34.34',
              0.76: '34.47',
              0.77: '34.61',
              0.78: '34.75',
              0.79: '34.89',
              0.8: '35.04',
              0.81: '35.2',
              0.82: '35.37',
              0.83: '35.54',
              0.84: '35.73',
              0.85: '35.92',
              0.86: '36.13',
              0.87: '36.35',
              0.88: '36.59',
              0.89: '36.85',
              0.9: '37.14',
              0.91: '37.45',
              0.92: '37.8',
              0.93: '38.19',
              0.94: '38.64',
              0.95: '39.17',
              0.96: '39.81',
              0.97: '40.61',
              0.98: '41.72',
              0.99: '43.67',
              '1.0': '120.0',
            },
            previous_probability_complete_target: {
              '0.0': '0.0',
              0.01: '16.06',
              0.02: '16.57',
              0.03: '16.91',
              0.04: '17.18',
              0.05: '17.41',
              0.06: '17.6',
              0.07: '17.78',
              0.08: '17.94',
              0.09: '18.09',
              0.1: '18.23',
              0.11: '18.37',
              0.12: '18.49',
              0.13: '18.62',
              0.14: '18.73',
              0.15: '18.84',
              0.16: '18.95',
              0.17: '19.06',
              0.18: '19.16',
              0.19: '19.26',
              0.2: '19.36',
              0.21: '19.45',
              0.22: '19.55',
              0.23: '19.64',
              0.24: '19.73',
              0.25: '19.82',
              0.26: '19.91',
              0.27: '20.0',
              0.28: '20.08',
              0.29: '20.16',
              0.3: '20.25',
              0.31: '20.33',
              0.32: '20.41',
              0.33: '20.49',
              0.34: '20.57',
              0.35: '20.65',
              0.36: '20.73',
              0.37: '20.81',
              0.38: '20.89',
              0.39: '20.97',
              0.4: '21.05',
              0.41: '21.13',
              0.42: '21.21',
              0.43: '21.28',
              0.44: '21.36',
              0.45: '21.44',
              0.46: '21.52',
              0.47: '21.6',
              0.48: '21.69',
              0.49: '21.77',
              0.5: '21.85',
              0.51: '21.93',
              0.52: '22.02',
              0.53: '22.1',
              0.54: '22.19',
              0.55: '22.27',
              0.56: '22.36',
              0.57: '22.45',
              0.58: '22.54',
              0.59: '22.63',
              0.6: '22.72',
              0.61: '22.82',
              0.62: '22.92',
              0.63: '23.01',
              0.64: '23.11',
              0.65: '23.21',
              0.66: '23.31',
              0.67: '23.42',
              0.68: '23.52',
              0.69: '23.63',
              0.7: '23.75',
              0.71: '23.86',
              0.72: '23.98',
              0.73: '24.1',
              0.74: '24.22',
              0.75: '24.34',
              0.76: '24.47',
              0.77: '24.61',
              0.78: '24.75',
              0.79: '24.89',
              0.8: '25.04',
              0.81: '25.2',
              0.82: '25.37',
              0.83: '25.54',
              0.84: '25.73',
              0.85: '25.92',
              0.86: '26.13',
              0.87: '26.35',
              0.88: '26.59',
              0.89: '26.85',
              0.9: '27.14',
              0.91: '27.45',
              0.92: '27.8',
              0.93: '28.19',
              0.94: '28.64',
              0.95: '29.17',
              0.96: '29.81',
              0.97: '30.61',
              0.98: '31.72',
              0.99: '33.67',
              '1.0': '50.0',
            },
            target_no_of_patients: '350',
          },
          'Western Europe': {
            Probability: {
              '0.0': '0.0',
              0.01: '26.06',
              0.02: '26.57',
              0.03: '26.91',
              0.04: '27.18',
              0.05: '27.41',
              0.06: '27.6',
              0.07: '27.78',
              0.08: '27.94',
              0.09: '28.09',
              0.1: '28.23',
              0.11: '28.37',
              0.12: '28.49',
              0.13: '28.62',
              0.14: '28.73',
              0.15: '28.84',
              0.16: '28.95',
              0.17: '29.06',
              0.18: '29.16',
              0.19: '29.26',
              0.2: '29.36',
              0.21: '29.45',
              0.22: '29.55',
              0.23: '29.64',
              0.24: '29.73',
              0.25: '29.82',
              0.26: '29.91',
              0.27: '30.0',
              0.28: '30.08',
              0.29: '30.16',
              0.3: '30.25',
              0.31: '30.33',
              0.32: '30.41',
              0.33: '30.49',
              0.34: '30.57',
              0.35: '30.65',
              0.36: '30.73',
              0.37: '30.81',
              0.38: '30.89',
              0.39: '30.97',
              0.4: '31.05',
              0.41: '31.13',
              0.42: '31.21',
              0.43: '31.28',
              0.44: '31.36',
              0.45: '31.44',
              0.46: '31.52',
              0.47: '31.6',
              0.48: '31.69',
              0.49: '31.77',
              0.5: '31.85',
              0.51: '31.93',
              0.52: '32.02',
              0.53: '32.1',
              0.54: '32.19',
              0.55: '32.27',
              0.56: '32.36',
              0.57: '32.45',
              0.58: '32.54',
              0.59: '32.63',
              0.6: '32.72',
              0.61: '32.82',
              0.62: '32.92',
              0.63: '33.01',
              0.64: '33.11',
              0.65: '33.21',
              0.66: '33.31',
              0.67: '33.42',
              0.68: '33.52',
              0.69: '33.63',
              0.7: '33.75',
              0.71: '33.86',
              0.72: '33.98',
              0.73: '34.1',
              0.74: '34.22',
              0.75: '34.34',
              0.76: '34.47',
              0.77: '34.61',
              0.78: '34.75',
              0.79: '34.89',
              0.8: '35.04',
              0.81: '35.2',
              0.82: '35.37',
              0.83: '35.54',
              0.84: '35.73',
              0.85: '35.92',
              0.86: '36.13',
              0.87: '36.35',
              0.88: '36.59',
              0.89: '36.85',
              0.9: '37.14',
              0.91: '37.45',
              0.92: '37.8',
              0.93: '38.19',
              0.94: '38.64',
              0.95: '39.17',
              0.96: '39.81',
              0.97: '40.61',
              0.98: '41.72',
              0.99: '43.67',
              '1.0': '120.0',
            },
            previous_probability_complete_target: {
              '0.0': '0.0',
              0.01: '16.06',
              0.02: '16.57',
              0.03: '16.91',
              0.04: '17.18',
              0.05: '17.41',
              0.06: '17.6',
              0.07: '17.78',
              0.08: '17.94',
              0.09: '18.09',
              0.1: '18.23',
              0.11: '18.37',
              0.12: '18.49',
              0.13: '18.62',
              0.14: '18.73',
              0.15: '18.84',
              0.16: '18.95',
              0.17: '19.06',
              0.18: '19.16',
              0.19: '19.26',
              0.2: '19.36',
              0.21: '19.45',
              0.22: '19.55',
              0.23: '19.64',
              0.24: '19.73',
              0.25: '19.82',
              0.26: '19.91',
              0.27: '20.0',
              0.28: '20.08',
              0.29: '20.16',
              0.3: '20.25',
              0.31: '20.33',
              0.32: '20.41',
              0.33: '20.49',
              0.34: '20.57',
              0.35: '20.65',
              0.36: '20.73',
              0.37: '20.81',
              0.38: '20.89',
              0.39: '20.97',
              0.4: '21.05',
              0.41: '21.13',
              0.42: '21.21',
              0.43: '21.28',
              0.44: '21.36',
              0.45: '21.44',
              0.46: '21.52',
              0.47: '21.6',
              0.48: '21.69',
              0.49: '21.77',
              0.5: '21.85',
              0.51: '21.93',
              0.52: '22.02',
              0.53: '22.1',
              0.54: '22.19',
              0.55: '22.27',
              0.56: '22.36',
              0.57: '22.45',
              0.58: '22.54',
              0.59: '22.63',
              0.6: '22.72',
              0.61: '22.82',
              0.62: '22.92',
              0.63: '23.01',
              0.64: '23.11',
              0.65: '23.21',
              0.66: '23.31',
              0.67: '23.42',
              0.68: '23.52',
              0.69: '23.63',
              0.7: '23.75',
              0.71: '23.86',
              0.72: '23.98',
              0.73: '24.1',
              0.74: '24.22',
              0.75: '24.34',
              0.76: '24.47',
              0.77: '24.61',
              0.78: '24.75',
              0.79: '24.89',
              0.8: '25.04',
              0.81: '25.2',
              0.82: '25.37',
              0.83: '25.54',
              0.84: '25.73',
              0.85: '25.92',
              0.86: '26.13',
              0.87: '26.35',
              0.88: '26.59',
              0.89: '26.85',
              0.9: '27.14',
              0.91: '27.45',
              0.92: '27.8',
              0.93: '28.19',
              0.94: '28.64',
              0.95: '29.17',
              0.96: '29.81',
              0.97: '30.61',
              0.98: '31.72',
              0.99: '33.67',
              '1.0': '50.0',
            },
            target_no_of_patients: '150',
          },
        },
      },
      status: 'SUCCEEDED',
    };

    const saveBaselineTracked = {
      status: 'SUCCEEDED',
    };

    const postTrackMonitorEditParameter = {
      result: [{
        complete_site_activation_by: '22',
        confidence_interval: '80',
        created_by: 'aj1234',
        created_timestamp: '2021-06-22 07:31:46.767091',
        custom_modeling_level: 'Global',
        distribution_type: 'Gamma',
        duration: '24.4',
        enroll_rate_red_patient_drop_out_perc: null,
        enroll_rate_red_screen_failure_perc: null,
        exp_empty_sites_perc: null,
        exp_enrollment_completion_date: '2022-10-01',
        exp_site_activation_failure_perc: null,
        is_baseline_tracked: '1',
        last_module_activated: 'enrollment_prediction',
        last_updated_timestamp: '2021-07-02 13:17:39.481939',
        max_trial_enrollment_duration: '36',
        modelling_level: 'Country',
        old_scenario_version: '20210608121321736',
        param_estimation_tech: 'MOM',
        prediction_start_date: '2021-04-16',
        scenario_id: '8c6a783b-601b-4bf3-a83e-2fc67f5f3735',
        scenario_name: 'New_5_Edit',
        scenario_status: 'Completed',
        site_activation_method: 'Historical',
        study_id: 'FILE1956808821',
        study_start_date: '2020-09-19',
        target_no_of_patients: '1354',
        theme_id: 'FILE_20210603101116854',
        threshold_empty_sites: null,
        total_countries: '3',
        total_no_of_sites: '98',
        track_id: null,
        forecast_type: 'edit_parameter',
      }],
      status: 'SUCCEEDED',
    };

    const preTrackMonitorEditParameter = {
      result: [{
        complete_site_activation_by: '10',
        confidence_interval: '95',
        created_by: 'hk17749',
        created_timestamp: '2021-08-19 07:11:38.135350',
        distribution_type: 'Gamma',
        duration: '12.03',
        enroll_rate_red_patient_drop_out_perc: null,
        enroll_rate_red_screen_failure_perc: null,
        exp_empty_sites_perc: null,
        exp_enrollment_completion_date: '2022-08-13',
        exp_site_activation_failure_perc: null,
        forecast_type: 'edit_parameter',
        is_baseline_tracked: '0',
        iterations: '100',
        last_module_activated: 'input_model_params',
        last_updated_timestamp: '2021-08-19 07:11:38.135350',
        max_trial_enrollment_duration: '24',
        modelling_level: 'Country',
        old_scenario_version: '20210819071138096',
        param_estimation_tech: 'MOM',
        prediction_start_date: '2021-04-16',
        scenario_id: '12ca2335-4c06-41cc-9073-aaf81ff3188f',
        scenario_name: 'Demo_1',
        scenario_status: 'In Progress',
        site_activation_method: 'custom',
        study_id: 'FILE2000649417',
        study_start_date: '2021-08-12',
        target_completion_prob: '85',
        target_no_of_patients: '300',
        theme_id: 'FILE702122000649448',
        total_countries: '2',
        total_modelling_values: '2',
        total_no_of_sites: '30',
        total_target_no_of_patients: '300',
        total_target_no_of_sites: '30',
        track_id: null,
      }],
      status: 'SUCCEEDED',
    };

    const refreshForecastStatusSuccess = {
      result: [{
        description: 'Refresh Forecast DS Model Run Completed.',
        last_updated_time: '2021-09-06 06:47:53.930786',
        model_run_id: 'hk17749-20210901111952379-89fd6cfc-9b65-46b6-ad25-f82817209db9-reforecast_model_run',
        model_run_status: 'Completed',
        start_time: '2021-09-06 06:47:17.624521',
      }],
      status: 'SUCCEEDED',
    };

    const getUserListDetails = {
      "message": "User details fetched successfully",
      "result": {
        "data": [
          {
            "email": "piyush@gmail.com",
            "first_name": "Gate",
            "last_login_date": "2021-02-01",
            "last_name": "Piyush",
            "user_access_id": "piyush@gmail.com",
            "user_group": ["superuser"]
          },
          {
            "email": "nitin@gmail.com",
            "first_name": "Desai",
            "last_login_date": "2021-05-01",
            "last_name": "Nitin",
            "user_access_id": "nitin@gmail.com",
            "user_group": ["mda"]
          },
          {
            "email": "lokesh@gmail.com",
            "first_name": "GK",
            "last_login_date": "2021-02-23",
            "last_name": "Lokesh",
            "user_access_id": "lokesh@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "brunda@gmail.com",
            "first_name": "DS",
            "last_login_date": "2021-04-09",
            "last_name": "Brunda",
            "user_access_id": "brunda@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "prashant@gmail.com",
            "first_name": "Dubey",
            "last_login_date": "2021-04-09",
            "last_name": "Prashant",
            "user_access_id": "prashant@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "piyush@gmail.com",
            "first_name": "Gate",
            "last_login_date": "2021-02-01",
            "last_name": "Piyush",
            "user_access_id": "piyush@gmail.com",
            "user_group": ["superuser"]
          },
          {
            "email": "nitin@gmail.com",
            "first_name": "Desai",
            "last_login_date": "2021-05-01",
            "last_name": "Nitin",
            "user_access_id": "nitin@gmail.com",
            "user_group": ["mda"]
          },
          {
            "email": "lokesh@gmail.com",
            "first_name": "GK",
            "last_login_date": "2021-02-23",
            "last_name": "Lokesh",
            "user_access_id": "lokesh@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "brunda@gmail.com",
            "first_name": "DS",
            "last_login_date": "2021-04-09",
            "last_name": "Brunda",
            "user_access_id": "brunda@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "prashant@gmail.com",
            "first_name": "Dubey",
            "last_login_date": "2021-04-09",
            "last_name": "Prashant",
            "user_access_id": "prashant@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "piyush@gmail.com",
            "first_name": "Gate",
            "last_login_date": "2021-02-01",
            "last_name": "Piyush",
            "user_access_id": "piyush@gmail.com",
            "user_group": ["superuser"]
          },
          {
            "email": "nitin@gmail.com",
            "first_name": "Desai",
            "last_login_date": "2021-05-01",
            "last_name": "Nitin",
            "user_access_id": "nitin@gmail.com",
            "user_group": ["mda"]
          },
          {
            "email": "lokesh@gmail.com",
            "first_name": "GK",
            "last_login_date": "2021-02-23",
            "last_name": "Lokesh",
            "user_access_id": "lokesh@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "brunda@gmail.com",
            "first_name": "DS",
            "last_login_date": "2021-04-09",
            "last_name": "Brunda",
            "user_access_id": "brunda@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "prashant@gmail.com",
            "first_name": "Dubey",
            "last_login_date": "2021-04-09",
            "last_name": "Prashant",
            "user_access_id": "prashant@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "piyush@gmail.com",
            "first_name": "Gate",
            "last_login_date": "2021-02-01",
            "last_name": "Piyush",
            "user_access_id": "piyush@gmail.com",
            "user_group": ["superuser"]
          },
          {
            "email": "nitin@gmail.com",
            "first_name": "Desai",
            "last_login_date": "2021-05-01",
            "last_name": "Nitin",
            "user_access_id": "nitin@gmail.com",
            "user_group": ["mda"]
          },
          {
            "email": "lokesh@gmail.com",
            "first_name": "GK",
            "last_login_date": "2021-02-23",
            "last_name": "Lokesh",
            "user_access_id": "lokesh@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "brunda@gmail.com",
            "first_name": "DS",
            "last_login_date": "2021-04-09",
            "last_name": "Brunda",
            "user_access_id": "brunda@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "prashant@gmail.com",
            "first_name": "Dubey",
            "last_login_date": "2021-04-09",
            "last_name": "Prashant",
            "user_access_id": "prashant@gmail.com",
            "user_group": ["mda", "gfl"]
          },
          {
            "email": "prashant@gmail.com",
            "first_name": "Dubey",
            "last_login_date": "2021-04-09",
            "last_name": "Prashant",
            "user_access_id": "prashant@gmail.com",
            "user_group": ["mda", "gfl"]
          }
        ],
        "total_count": 200
      },
      "status": "SUCCESS"
    };


    return {
      // LOGIN
      'ctemp-user-login': loginData,

      // SITE LEVEL STUDY DATA
      'ctemp-upload-site-level-study-file': uploadSiteLevelStudyFile,
      'ctemp-save-uploaded-data': saveUploadedFile,

      // CSV File valdiation
      'ctemp-csv-validation': csvFileValidationResponse,
      'ctemp-csv-validation-status': csvFileValidationStatus,

      // Historical Benchmark Upload
      'ctemp-pre-feasibility-upload-benchmark-file': uploadHistoricalBenchmarks,
      'ctemp-pre-feasibility-save-benchmark-file': saveUploadedFile,
      'ctemp-pre-feasibility-genarate-validation-id': generateValdationID,

      // CSV File valdiation
      'ctemp-pre-feasibility-benchmark-validation': csvFileValidationResponse,
      'ctemp-pre-feasibility-benchmark-validation-status': validationForHistoricalStatus,
      'ctemp-genarate-validation-id': generateValdationID,

      // Track & Monitor
      'ctemp-upload-track-study-file': uploadTrackMonitor,
      'ctemp-save-track-study-file': uploadHistoricalBenchmarks,
      'ctemp-track-and-monitor-validation': csvFileValidationResponse,
      'ctemp-track-and-monitor-validation-status': validationForHistoricalStatus,
      'ctemp-track-and-monitor-generate-validation-id': generateValdationID,
      'ctemp-actual-patient-enrolled-chart': actualEnrolledPatientChart,
      'ctemp-actual-lower-modelling-chart': actualLowerModellingChart,
      'ctemp-actual-probability-completion-chart': probCompleteTargetChart,
      'ctemp-save-baseline-tracked-status': saveBaselineTracked,
      'ctemp-post-edit-parameter-model': postTrackMonitorEditParameter,
      'ctemp-pre-edit-parameter-model': preTrackMonitorEditParameter,

      // SLSD popup
      'ctemp-selection-for-modelling-study-level-data': successResponse,
      'ctemp-upload-study-from-ctfo-to-ctemp': uploadStudyFromCTFOtoCTEMP,
      'ctemp-detail-model-run': successResponse,

      // CONNECT TO CTFO
      'ctemp-connect-to-ctfo': gridDataCTFO,
      'ctemp-connect-to-ctfo-search': gridSearchCTFO,
      'ctemp-connect-to-ctfo-filters': connectToCTFOFilters,

      // VIEW SCENARIOS screen
      'ctemp-get-study-scenario-count': studyScenarioCount,
      'ctemp-view-studies': viewStudies,
      'ctemp-view-scenarios': viewScenarios,
      'ctemp-get-study-historical-metrics': historicalMetrics,

      // Study Data - Detailed View
      'ctemp-study-detailed-view': studyDetailedView,
      'ctemp-study-detailed-view-search': studyDetailedViewSearch,

      // Study Data - Historical Summary
      'ctemp-historical-summary-graph-map-view': historicalSummaryGraphData,

      // Compare Scenarios
      // 'ctemp-compare-scenarios': compareScenariosOutputPre,
      'ctemp-compare-scenarios': compareScenariosOutput,

      // Delete Study / Scenario
      'ctemp-delete-study-scenarios': successResponse, // Post
      'ctemp-delete-study-or-scenario': successResponse, // Pre

      // Download Study / Scenario
      'ctemp-get-data-for-images': chartsDataForDownload, // Post
      'ctemp-download-service': successResponse, // Post
      'ctemp-check-download-available': downloadAvailable, // Post
      'ctemp-delete-download-zip': successResponse, // Post
      'ctemp-pre-get-data-for-images': chartsDataForDownloadPre, // Pre
      'ctemp-pre-download-service': successResponse, // Pre
      'ctemp-pre-check-download-available': downloadAvailable, // Pre

      // Generating Scenario Version and Id
      'ctemp-generate-scenario-id': scenarioID,
      'ctemp-generate-version-id': versionID,

      // SLP screen
      'ctemp-pre-feasibility-save-input-model-parameters': successResponse,
      'ctemp-pre-feasibility-validate-scenario-name': scenarioNameValidation,
      'ctemp-pre-feasibility-geo-dropdown': geoDropdown,

      // SIP screen
      'ctemp-pre-feasibility-view-geo-modelling-psm': viewSimulationGridData,
      'ctemp-pre-feasibility-save-geo-modelling-psm': successResponse,
      'ctemp-get-cohort-list': cohortList,

      // SAS screen - Pre Feasibility
      'ctemp-pre-feasibilty-run-historical-model': successResponse,
      'ctemp-pre-feasibility-historical-activation-curve': historicalCurvePref,
      'ctemp-pre-feasibility-simulated-activation-curve': simulatedCurvePref,
      'ctemp-pre-feasibility-run-enrollment-model': runEnrollmentModelPref,
      'ctemp-pre-feasibility-run-enrollment-model-status': runEnrollmentModelStatusPref,

      // IMP screen
      'ctemp-validate-scenario-name': scenarioNameValidation,
      'ctemp-save-and-update-input-model-params': successResponse,

      // CSPC screen
      'ctemp-country-selection-and-patient-count': countryDataGeoSelction,
      'ctemp-validate-country-selection-and-patient-count': countrySelectionsValidationFail,
      'ctemp-save-country-selection-and-patient-count': successResponse,

      // Geo Selection & Patient Count (R4)
      // 'ctemp-country-selection-and-patient-count': regionData,
      // 'ctemp-country-selection-and-patient-count': globalData,

      // SAS screen
      'ctemp-validate-site-activation-date': siteActivationDefaultDates,
      'ctemp-historical-model-run': successResponse,
      'ctemp-historical-site-activation': historicalSiteActivation,
      'ctemp-historical-activation-curve': historicalActivationCurve,
      'ctemp-simulation-model-run': successResponse,
      'ctemp-simulated-site-activation': simulatedSiteActivation,
      'ctemp-simulated-activation-curve': simulatedActivationCurve,
      'ctemp-run-enrollment-model': runEnrollmentModel,
      'ctemp-run-enrollment-model-status': runEnrollmentModelStatus,
      'ctemp-get-historical-custom-details': getHistoricalCustomFlyoutData,
      'ctemp-save-historical-custom-details': saveHistoricalCustomFlyoutData,

      // VEP screen Shared
      // Post
      'ctemp-predicted-patient-enrollment': enrollmentPredictionData,
      'ctemp-probablity-complete-target-enroll': enrollmentProbabilityData,
      'ctemp-enrollment-completion-time-chart': enrollmentCompletionTimeTableData,
      'ctemp-view-enrollment-rate': postMeanEnrollmentRate,
      'ctemp-save-and-update-scenario': successResponse,
      'ctemp-enrollment-lower-modelling-level': lowerModellingLevel,

      // Pre
      'ctemp-pre-feasibility-predicted-patient-time-chart': prePredictedPatientTimeChart,
      'ctemp-pre-feasibility-probability-complete-time-chart': preProbabilityCompleteTimeChart,
      'ctemp-pre-feasibility-distribution-target-time-chart': preDistributionTargetTimeChart,
      'ctemp-pre-feasibility-enrollment-completion-grid-data': preEnrollmentCompletionGrid,
      'ctemp-pre-feasibility-site-randomization-chart': preSiteRandomizationChart,
      'ctemp-pre-feasibility-mean-enrollment-rate': preMeanEnrollmentRate,
      'ctemp-pre-feasibility-save-and-update-scenario': successResponse,

      // Adjust Randomization Popup
      'ctemp-view-adjust-randomization-rate': adjustRandomization,
      'ctemp-save-adjust-randomization-rate': successResponse,

      // Pre-Feasibility (Add Study)
      'ctemp-pre-feasibility-get-filters': preFeasibilityFilters,
      'ctemp-pre-feasibility-generate-study-id': preFeasibilityStudyId,
      'ctemp-pre-feasibility-save-study': successResponse,

      // Edit Distribution
      'ctemp-run-distribution-geo-modelling-psm': runDistribution,
      'ctemp-view-distribution-geo-modelling-psm': viewDistribution,
      'ctemp-distribution-graph-geo-modelling-psm': graphDistribution,

      // Refresh forecast
      'ctemp-run-reforecast-model-status': refreshForecastStatusSuccess,

      //Admin
      'get-user-list-details' : getUserListDetails,

    };
  }
}
