<header class="d-flex justify-content-between align-items-center p-3">
  <ng-content select="ctfo-dialog-title"></ng-content>
  <img
    class="cursor-pointer align-self-baseline"
    src="assets/images/icon_close.svg"
    (ctfoDialogClose)="ctfoDialogCloseEmitter.emit()"
    width="24px"
    height="24px"
    alt="close"
  />
</header>

<main class="p-3">
  <ng-content select="ctfo-dialog-content"></ng-content>
</main>

<footer>
  <ng-content select="ctfo-dialog-actions"></ng-content>
</footer>
