import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PERC_LINE_CHART } from '@app/shared/models/charts.model';
import {
  API_SUCCESS,
  CUSTOMIZE_ACTIVATION_CURVE_ERROR_MESSAGES as ERROR_MESSAGES,
  SOMETHING_WENT_WRONG,
} from '@constants/messages';
import { SessionStorageService } from 'angular-web-storage';
import * as Highcharts from 'highcharts';
import HC_Data from 'highcharts/modules/data';
import * as _ from 'lodash';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as model from './customize-activation-flyout.model';

import { HttpHandlerService } from '@app/shared/services/http-handler.service';
import { appSetting } from '@constants/endpoints';
import { DialogService } from '@app/shared/dependency/dialog';
import { ScenarioService } from '@app/featured-modules/modeling-predictions/modules/modeling-predictions-shared/scenarios.service';

@Component({
  selector: 'app-customize-activation-flyout',
  templateUrl: './customize-activation-flyout.component.html',
  styleUrls: ['./customize-activation-flyout.component.scss'],
})
export class CustomizeActivationFlyoutComponent implements OnInit {
  @Input() modal: NgbModalRef;
  @Input() chartOptions: Highcharts.Options;
  @Input() modelingLevel: string;
  @Input() accessMode: any;
  @Input() accessModeCustomize: any;
  @Input() geoLevelName: any;
  @Input() dateBounds: {
    minStartDate: any;
    maxEndDate: any;
  };
  @Input() flyoutData;
  @Input() elapsedDurationCust;
  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();
  // @ViewChild('messageModal') messageModal;

  highcharts = Highcharts;
  chart;
  requiredCoordinates = 5;

  focussedItem = {
    column: null,
    row: null,
  };

  chartPlotted = false;
  isError = false;
  isErrorDate = false;
  errorMessage = '';
  errorMessageDate = '';
  inputRequest: any;
  disable_baseline_tracked: boolean;

  studyDetails = this.localStorage.get('study-details');

  activeTab = 'global';
  activeIndex;
  originalGlobalData;
  originalCountryData;
  tabSwitchRequest;
  changesMade: boolean = false;
  savingInProgress: boolean = false;

  newPercentageCustomInputs = model.PERCENTAGE_CUSTOM_INPUT;
  newDateCustomInputs = model.DATE_CUSTOM_INPUT;

  inputGridData: any;
  geographyGridData: any;
  globalData: model.CUSTOM_ACTIVATION_DATA;
  countryData: Array<model.CUSTOM_ACTIVATION_DATA> = [];
  completeGlobalData;
  completeCountryData;
  currentActivationData;
  tabDataLoading: boolean = true;
  selectedGeographyData: any;
  countryList = [];

  countryTabEnabled: boolean = false;
  popupOpen: boolean = false;
  chartLoader: boolean = false;
  minSiteActivationDate = '';
  maxSiteActivationDate = '';

  toolTip = {
    startDate: 'Site Activation Start Tooltip Placeholder',
    endDate: 'Site Activation End Tooltip Placeholder',
  };

  constructor(
    private localStorage: SessionStorageService,
    private httpHandler: HttpHandlerService,
    private scenarioService: ScenarioService,
    private dialog: DialogService
  ) {
    HC_Data(Highcharts);
  }

  ngOnInit(): void {
    this.inputRequest = this.localStorage.get('inputRequest');
    this.inputRequest.input_request.study_created_by = this.studyDetails.study_created_by;
    this.inputRequest.input_request.historical_data_id = this.studyDetails.historical_data_id;
    this.inputRequest.input_request.is_cohort = this.studyDetails.is_cohort;
    this.localStorage.set('inputRequest', this.inputRequest);
    this.originalGlobalData = JSON.parse(JSON.stringify(this.flyoutData.global_level_details[0]));
    this.originalCountryData = JSON.parse(JSON.stringify(this.flyoutData.geo_level_details));
    this.prepareFlyoutDisplayData(this.flyoutData);
  }

  private plotChart(level, chartData: Array<[any, any]>, chartType = 'simulated'): void {
    this.chartLoader = true;
    const chartOptions: Highcharts.Options = JSON.parse(JSON.stringify(PERC_LINE_CHART));
    // chartOptions.chart.height = '45%';

    chartOptions.plotOptions.line.color = '' + model.CHART_CONFIG[chartType].lineColor;

    if (this.getToggleValue()) {
      chartData.splice(0, 0, [null, '']);
      chartOptions.series = [];
      chartOptions.xAxis['type'] = 'datetime';
      chartOptions.data['rows'] = chartData;
      chartOptions.data['dateFormat'] = 'YYYY/mm/dd';
      chartOptions.xAxis['tickInterval'] = undefined;

      if (chartData.length > 1) {
        // account for extra added datapoint
        chartOptions.xAxis['min'] = moment(chartData[1][0]).valueOf();
        chartOptions.xAxis['max'] = moment(chartData[chartData.length - 1][0])
          .add(1, 'days')
          .valueOf();

        (chartOptions as any).xAxis.tickPositioner = function () {
          var positions = [],
            tick = moment(chartData[1][0]).valueOf(),
            increment =
              (moment(chartData[chartData.length - 1][0])
                .add(1, 'days')
                .valueOf() -
                moment(chartData[1][0]).valueOf()) /
              4;

          if (
            moment(chartData[chartData.length - 1][0])
              .add(1, 'days')
              .valueOf() !== null &&
            moment(chartData[1][0]).valueOf() !== null
          ) {
            for (
              tick;
              tick - increment <=
              moment(chartData[chartData.length - 1][0])
                .add(1, 'days')
                .valueOf();
              tick += increment
            ) {
              positions.push(tick);
            }
          }
          return positions;
        };

        (chartOptions as any).xAxis.labels.align = 'right';
      } else {
        (chartOptions as any).xAxis.tickPositioner = () => {
          return [];
        };
      }
      (chartOptions as any).xAxis.title.text = 'Date of Activation';
    } else {
      chartOptions.series = [
        {
          type: 'line',
          name: 'Sites Activated',
          data: chartData,
        },
      ];
      chartOptions.xAxis['min'] = 0;
      chartOptions.xAxis['max'] = 100;
      // (chartOptions as any).xAxis.tickInterval = 25;
      (chartOptions as any).xAxis.tickPositioner = () => {
        return [0, 25, 50, 75, 100];
      };
      (chartOptions as any).xAxis.type = 'linear';
      (chartOptions as any).xAxis.title.text = 'Site Activation Duration';
    }

    chartOptions.tooltip.formatter = (c): string => {
      const point = c.chart.hoverPoint;
      const xTooltip = this.getToggleValue()
        ? 'Date of Activation: <strong>' + Highcharts.dateFormat('%d %b %Y', point.x)
        : 'Site Activation Duration: <strong>' + point.x + '%';
      return `Sites Activated: <strong>${point.y}%</strong><br>${xTooltip}</strong><br>`;
    };

    (chartOptions as any).yAxis.labels.formatter = (e: Highcharts.AxisLabelsFormatterContextObject): string => {
      return e.isFirst ? '' + e.value : `${e.value}%`;
    };

    (chartOptions as any).xAxis.labels.formatter = (e): string => {
      const xLabel = this.getToggleValue() ? Highcharts.dateFormat('%d %b %Y', e.value) : e.value + '%';
      return e.isFirst ? '' : `${xLabel}`;
    };

    if (this.chart) {
      this.chart.update(chartOptions, true);
    } else {
      this.chart = new this.highcharts.Chart('chart-container', chartOptions);
    }
    this.chart.reflow();
    this.chartPlotted = true;
  }

  deleteCoordinate(index: number): void {
    if (!this.accessMode) {
      return;
    }
    this.changesMade = true;
    this.chartPlotted = false;
    this.inputGridData.splice(index, 1);
  }

  addCoordinate(type): void {
    if (!this.accessMode) {
      return;
    }
    const dataPoint = type
      ? JSON.parse(JSON.stringify(model.DATE_DATA_POINT))
      : JSON.parse(JSON.stringify(model.PERCENTAGE_DATA_POINT));
    this.inputGridData.splice(this.inputGridData.length - 1, 0, { ...dataPoint });
    this.chartPlotted = false;
    this.changesMade = true;
    setTimeout(() => {
      const ele = document.getElementById('input-grid');
      const height = ele.scrollHeight;
      ele.scrollTop = height;
    }, 50);
  }

  displayError(errorMessage: string): void {
    this.errorMessage = errorMessage;

    const errorElement = document.getElementById('right-container');
    errorElement.scrollTop = 0;
  }

  displayErrorDate(errorMessage: string): void {
    this.errorMessageDate = errorMessage;

    const errorElement = document.getElementById('right-container');
    errorElement.scrollTop = 0;
  }

  modalDismissed(action: string): void {
    if (action === 'Update' && this.activeTab == 'global') {
      this.savingInProgress = true;
      this.prepareSaveData(true);
    } else if (action === 'Update' && this.activeTab == 'country') {
      this.savingInProgress = true;
      this.prepareSaveData(false);
    } else {
      // cancelling flyout
      this.popupOpen = true;
      if (
        this.accessMode &&
        (this.checkForChangesOnTab('country') || this.checkForChangesOnTab('global') || this.changesMade)
      ) {
        this.popupOpen = true;
        this.dialog.confirm({
          title: model.CANCEL_MODAL.modalTitle,
          message: model.CANCEL_MODAL.modalMessage,
          afterOK: () => {
            // this.handlePopupClose('OK');
            this.modalClosed.emit({
              action: 'Cancel',
            });
            this.popupOpen = false;
            this.modal.close();
          },
          afterCancel: () => {
            this.popupOpen = false;
          },
          afterClose: () => {
            this.popupOpen = false;
          },
        });
      } else {
        // this.handlePopupClose('OK');
        this.modalClosed.emit({
          action: 'Cancel',
        });
        this.modal.close();
      }
    }
  }

  openTabSwitchPopup(tab) {
    this.errorMessage = '';
    if (this.checkForChangesOnTab(this.activeTab) || this.changesMade) {
      this.popupOpen = true;
      this.dialog.confirm({
        title: model.CONFIRMATION_MODAL.modalTitle,
        message: model.CONFIRMATION_MODAL.modalMessage,
        afterOK: () => {
          this.popupOpen = false;
          this.setTab(tab);
        },
        afterCancel: () => {
          this.popupOpen = false;
        },
        afterClose: () => {
          this.popupOpen = false;
        },
      });
    } else {
      this.setTab(tab);
    }
  }

  checkForChangesOnTab(tab) {
    return tab === 'global'
      ? !_.isEqual(this.globalData, this.completeGlobalData)
      : !_.isEqual(this.countryData, this.completeCountryData);
  }

  enableCountryTab() {
    return (
      (this.inputRequest.input_request.study_feasibility === 'pre' &&
        this.inputRequest.input_request.modelling_level === 'Country') ||
      (this.inputRequest.input_request.study_feasibility === 'post' &&
        (this.inputRequest.input_request.modelling_level === 'Country' ||
          this.inputRequest.input_request.modelling_level === 'Region'))
    );
  }

  hasMandatoryFields() {
    let unfilledInputs = 0;
    this.inputGridData.forEach(el => {
      const durationInp = this.getToggleValue() // TODO: change to getToggle
        ? el.perc_trial_duration.input
        : el.perc_trial_duration;
      if (
        el.perc_site_activated === null ||
        el.perc_site_activated === '' ||
        durationInp === null ||
        durationInp === ''
      ) {
        unfilledInputs += 1;
      }
    });
    return !Boolean(unfilledInputs);
  }

  ifNoHistoricalData(): any {
    if (
      this.studyDetails.study_feasibility === 'post' ||
      this.inputRequest.input_request.study_feasibility === 'post'
    ) {
      return false;
    } else if (
      this.activeTab === 'country' &&
      (this.inputRequest.input_request.historical_data_id === '' ||
        this.inputRequest.input_request.historical_data_id === null)
    ) {
      let tempArr = [];
      this.countryData.forEach(item => {
        if (item.siteActivationMethod && this.globalData.siteActivationMethod) {
          // global historical
          // do nothing
        } else if (
          !item.siteActivationMethod &&
          item.custom.dateCustom.length <= 0 &&
          item.custom.percentageCustom.length <= 0
        ) {
          // custom, no chart plotted
          // do nothing
        } else {
          tempArr.push(item);
        }
      });

      if (this.countryData.length !== tempArr.length) {
        return true;
      } else {
        return false;
      }
      // } else if (this.activeTab == 'country' && this.currentActivationData.siteActivationMethod) {
      //   return false;
      // } else if (this.activeTab == 'country' && !this.currentActivationData.siteActivationMethod) {
      //   return !this.checkForChangesOnTab(this.activeTab); // TODO: recheck
      // } else if (this.activeTab == 'global' && !this.globalData.siteActivationMethod) {
      //   return !this.checkForChangesOnTab(this.activeTab); // TODO: recheck
    } else {
      if (this.activeTab === 'global' && this.globalData.siteActivationMethod && this.checkforHistoricalId()) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkforHistoricalId(): any {
    const scenario = this.localStorage.get('scenario-details');
    if (scenario && scenario.scenario_id === this.inputRequest?.input_request?.scenario_id) {
      if (
        this.inputRequest?.input_request?.historical_data_id === '' ||
        this.inputRequest?.input_request?.historical_data_id === null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        this.inputRequest.input_request.historical_data_id === '' ||
        this.inputRequest.input_request.historical_data_id === null
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  saveFlyoutData(data) {
    const inp = Object.assign({}, this.inputRequest);
    const endpoint =
      this.inputRequest.input_request.study_feasibility == 'pre'
        ? appSetting.PRE_SAVE_HISTORICAL_CUSTOM_DETAILS
        : appSetting.SAVE_HISTORICAL_FLYOUT_DATA;

    const payload = {
      input_request: {
        user_group: inp.input_request.user_group,
        user_id: inp.input_request.user_id,
        theme_id: inp.input_request.theme_id,
        study_id: inp.input_request.study_id,
        study_type: inp.input_request.study_type || '',
        scenario_id: inp.input_request.scenario_id,
        new_scenario_version: inp.input_request.new_scenario_version,
        historical_data_id: inp.input_request.historical_data_id,
        modelling_level: inp.input_request.modelling_level,
        forecast_type: inp.input_request.forecast_type,
        study_created_by: inp.input_request.study_created_by,
        scenario_created_by: inp.input_request.scenario_created_by,
        is_cohort: inp.input_request.is_cohort,
        cohort_type: inp.input_request.cohort_type,
        geo_level_details: data.country,
        global_level_details: [data.global],
      },
    };

    this.httpHandler.postHttpResults(endpoint, payload).subscribe(
      response => {
        if (response.status === 'SUCCEEDED') {
          this.changesMade = false;
          this.modalClosed.emit({
            action: 'Update',
            custom_modeling_level: this.activeTab === 'global' ? 'Global' : 'Country',
            site_activation_method: 'Custom',
            isFlyoutCustomized: true,
            max_site_activation_start_date:
              this.activeTab === 'global' ? data.global.site_activation_start_date : this.minSiteActivationDate,
            min_site_activation_end_date:
              this.activeTab === 'global' ? data.global.site_activation_end_date : this.maxSiteActivationDate,
          });
          this.savingInProgress = false;
          // this.changesMade = false;
          this.modal.close();
        } else {
          this.savingInProgress = false;
          this.displayError(SOMETHING_WENT_WRONG);
        }
      },
      () => {
        this.savingInProgress = false;
        this.displayError(SOMETHING_WENT_WRONG);
      }
    );
  }

  onGridInputFocus(index: number, field: string): void {
    this.focussedItem = {
      row: index,
      column: field,
    };
  }

  onGridInputFocusOut(index: number, field: string): void {
    this.chartPlotted = false;
    if (this.isError) {
      this.inputGridData[index][field] = null;
      this.displayError('');
      this.isError = false;
    }
  }

  isGridInputDisabled(index: number, field: string): boolean {
    const field1 = 'perc_trial_duration';
    const field2 = 'perc_site_activated';

    if (this.currentActivationData.siteActivationMethod || !this.accessMode) {
      return true;
    } else if (!this.currentActivationData.toggleSelection) {
      // percentage input
      return (
        this.inputGridData[index - 1][field1] === null ||
        this.inputGridData[index - 1][field2] === null ||
        this.inputGridData[index - 1][field1] === '' ||
        this.inputGridData[index - 1][field2] === ''
      );
    } else {
      // date input
      return (
        this.inputGridData[index - 1][field2] === null ||
        this.inputGridData[index - 1][field2] === '' ||
        this.inputGridData[index - 1][field1].input === '' ||
        this.inputGridData[index - 1][field1].input === null
      );
    }
  }

  disableSave() {
    const a = !this.hasMandatoryFields();
    const b = this.ifNoHistoricalData();
    return (
      !this.accessMode ||
      this.inputRequest.input_request.forecast_type == 'refresh_forecast' ||
      this.popupOpen ||
      (!this.currentActivationData.siteActivationMethod &&
        (!this.chartPlotted ||
          a ||
          this.isError ||
          this.isErrorDate ||
          b || // TODO: recheck this function
          this.currentActivationData.siteStartDate.input == '' ||
          this.currentActivationData.siteEndDate.input == ''))
    );
  }

  legendToggle(level, index, event, bypass) {
    if (this.currentActivationData.siteActivationMethod) {
      //historical
      return;
    } else if (
      this.currentActivationData.siteStartDate.input == '' ||
      this.currentActivationData.siteEndDate.input == ''
    ) {
      this.displayError('Please enter site activation start and end date first');
    } else if (!this.chartPlotted && !bypass) {
      this.popupOpen = true;
      this.dialog.confirm({
        title: model.CALCULATE_ACTIVATION_MODAL.modalTitle,
        message: model.CALCULATE_ACTIVATION_MODAL.modalMessage,
        afterOK: () => {
          this.popupOpen = false;
          this.legendToggle(level, index, event, true);
        },
        afterCancel: () => {
          this.popupOpen = false;
          event.target.checked = !event.target.checked; // changing to previous setting
          return;
        },
        afterClose: () => {
          event.target.checked = !event.target.checked;
          this.popupOpen = false;
          return;
        },
      });
    } else {
      this.changesMade = true;
      this.currentActivationData.toggleSelection = event.target.checked;
      // this.setGeography(level, index, false);
      const gridData = event.target.checked
        ? this.selectedGeographyData.custom.dateCustom
        : this.selectedGeographyData.custom.percentageCustom;
      this.setCurrentInputGridData(gridData.length ? gridData : this.createDefaultInputs(event.target.checked));
      this.createChart(level, gridData, index, this.getToggleValue(), false);
    }
  }

  convertToDateModel(date) {
    //
    let d1;
    let d = moment(date).format('YYYY-MM-DD');
    d1 = d.split('-');
    return {
      input: date.length ? moment(date).format('DD MMM YYYY') : '',
      model: {
        year: +d1[0],
        month: +d1[1],
        day: +d1[2],
      },
    };
  }

  convertDateToString(date) {
    return moment(date).format('YYYY-MM-DD').toString();
  }

  prepareSaveData(save) {
    const customData = {
      country: [],
      global: {},
    };

    let gc = {};
    if (this.globalData.toggleSelection) {
      this.globalData.custom.dateCustom.forEach((cd: any) => {
        gc[this.convertDateToString(cd.perc_trial_duration.input)] = cd.perc_site_activated.toString();
      });
    } else {
      this.globalData.custom.percentageCustom.forEach((cd: any) => {
        gc[cd.perc_trial_duration.toString()] = cd.perc_site_activated.toString();
      });
    }

    customData.global = {
      geo_level_name: this.globalData.geographyName,
      historical: this.originalGlobalData.historical,
      custom: gc,
      site_activation_start_date: this.convertDateToString(this.globalData.siteStartDate.input),
      site_activation_end_date: this.convertDateToString(this.globalData.siteEndDate.input),
      legend_toggle: this.globalData.toggleSelection ? 'date' : 'percentage',
      site_activation_method: this.globalData.siteActivationMethod ? 'Historical' : 'Custom',
    };

    // const countrySaveData = [];
    // const countryDt = JSON.parse(JSON.stringify(this.countryData));

    this.countryData.forEach(country => {
      const cc = {};
      if (country.toggleSelection && country.custom.dateCustom.length > 0) {
        country.custom.dateCustom.forEach((cd: any) => {
          cc[this.convertDateToString(cd.perc_trial_duration.input)] = cd.perc_site_activated.toString();
        });
      } else if (country.custom.percentageCustom.length > 0) {
        country.custom.percentageCustom.forEach((cd: any) => {
          cc[cd.perc_trial_duration.toString()] = cd.perc_site_activated.toString();
        });
      }

      customData.country.push({
        geo_level_name: country.geographyName,
        custom: cc,
        site_activation_start_date: this.convertDateToString(country.siteStartDate.input),
        site_activation_end_date: this.convertDateToString(country.siteEndDate.input),
        legend_toggle: country.toggleSelection ? 'date' : 'percentage',
        site_activation_method: country.siteActivationMethod ? 'Historical' : 'Custom',
      });
    });

    if (save) {
      // this.saveFlyoutData(customData);
      this.generateNewVersionId(customData);
    } else {
      this.countrySiteActivationValidationAPI(customData);
    }
  }

  createDefaultInputs(inputType) {
    let defaultInputs;
    if (inputType === true) {
      // DATE
      defaultInputs = model.DATE_CUSTOM_INPUT;
      defaultInputs[0].perc_trial_duration = Object.assign({}, this.currentActivationData.siteStartDate);
      defaultInputs.slice(-1)[0].perc_trial_duration = Object.assign({}, this.currentActivationData.siteEndDate);
    } else {
      defaultInputs = model.PERCENTAGE_CUSTOM_INPUT;
    }
    return JSON.parse(JSON.stringify(defaultInputs));
  }

  setCurrentInputGridData(data) {
    this.inputGridData = data;
  }

  setCurrentGeographyGridData(data) {
    this.geographyGridData = data;
  }

  setCurrentActivationData(startDate, endDate, activationType, toggleSelection) {
    this.currentActivationData = {
      siteStartDate: startDate.input == '' ? this.convertToDateModel(this.dateBounds.minStartDate) : startDate,
      siteEndDate: endDate.input == '' ? this.convertToDateModel(this.dateBounds.maxEndDate) : endDate,
      siteActivationMethod: activationType, // true: historical
      toggleSelection: toggleSelection,
    };
    this.tabDataLoading = false;
  }

  prepareFlyoutDisplayData(result) {
    let localGlobalData = result.global_level_details[0];
    let localCountryData = result.geo_level_details;

    const global_hist = [];
    const global_hist_k = Object.keys(localGlobalData.historical);
    const global_hist_v = Object.values(localGlobalData.historical);

    if (this.studyDetails.historical_data_available != 'N') {
      for (let x = 0; x < global_hist_k.length; x++) {
        const obj = {
          perc_trial_duration: +global_hist_k[x],
          perc_site_activated: +global_hist_v[x],
        };
        global_hist.push(obj);
      }
    }

    const global_cust_date = [];
    const global_cust_perc = [];
    const global_cust_k = Object.keys(localGlobalData.custom);
    const global_cust_v = Object.values(localGlobalData.custom);

    if (localGlobalData.legend_toggle === 'date') {
      for (let x = 0; x < global_cust_k.length; x++) {
        const obj = {
          perc_trial_duration: this.convertToDateModel(global_cust_k[x]),
          perc_site_activated: +global_cust_v[x],
        };
        global_cust_date.push(obj);
      }
    } else {
      for (let x = 0; x < global_cust_k.length; x++) {
        const obj = {
          perc_trial_duration: +global_cust_k[x],
          perc_site_activated: +global_cust_v[x],
        };
        global_cust_perc.push(obj);
      }
    }

    this.globalData = {
      geographyName: localGlobalData.geo_level_name,
      historical: global_hist,
      custom: {
        dateCustom: global_cust_date,
        percentageCustom: global_cust_perc,
      },
      siteActivationMethod:
        this.studyDetails.historical_data_available == 'N'
          ? false
          : localGlobalData.site_activation_method === 'Historical'
          ? true
          : false,
      toggleSelection: localGlobalData.legend_toggle === 'date' ? true : false,
      siteStartDate: this.convertToDateModel(
        localGlobalData.site_activation_start_date != ''
          ? moment
              .max(moment(localGlobalData.site_activation_start_date), moment(this.dateBounds.minStartDate))
              .format('YYYY-MM-DD')
              .toString()
          : this.dateBounds.minStartDate
      ),
      siteEndDate: this.convertToDateModel(
        localGlobalData.site_activation_end_date != ''
          ? moment
              .min(moment(localGlobalData.site_activation_end_date), moment(this.dateBounds.maxEndDate))
              .format('YYYY-MM-DD')
              .toString()
          : this.dateBounds.maxEndDate
      ),
    };

    localCountryData.map(country => {
      const country_cust_date = [];
      const country_cust_perc = [];
      const country_cust_k = Object.keys(country.custom);
      const country_cust_v = Object.values(country.custom);

      if (country.legend_toggle === 'date') {
        for (let x = 0; x < country_cust_k.length; x++) {
          const obj = {
            perc_trial_duration: this.convertToDateModel(country_cust_k[x]),
            perc_site_activated: +country_cust_v[x],
          };
          country_cust_date.push(obj);
        }
      } else {
        for (let x = 0; x < country_cust_k.length; x++) {
          const obj = {
            perc_trial_duration: +country_cust_k[x],
            perc_site_activated: +country_cust_v[x],
          };
          country_cust_perc.push(obj);
        }
      }
      const countryObj = {
        geographyName: country.geo_level_name,
        historical:
          localGlobalData.site_activation_method === 'Historical'
            ? global_hist
            : localGlobalData.legend_toggle === 'date'
            ? global_cust_date
            : global_cust_perc,
        custom: {
          dateCustom: country_cust_date,
          percentageCustom: country_cust_perc,
        },
        siteActivationMethod:
          country.site_activation_method === 'Historical'
            ? this.studyDetails.historical_data_available != 'N' || localGlobalData.site_activation_method === 'Custom'
              ? true
              : false
            : false,
        toggleSelection: country.legend_toggle === 'date' ? true : false,
        siteStartDate: this.convertToDateModel(
          country.site_activation_start_date ||
            localGlobalData.site_activation_start_date ||
            this.dateBounds.minStartDate
        ),
        siteEndDate: this.convertToDateModel(
          country.site_activation_end_date || localGlobalData.site_activation_end_date || this.dateBounds.maxEndDate
        ),
      };

      this.countryData.push(countryObj);
      this.countryList.push({
        geographyName: country.geo_level_name,
        siteActivationMethod:
          country.site_activation_method === 'Historical'
            ? this.studyDetails.historical_data_available != 'N' || localGlobalData.site_activation_method === 'Custom'
              ? true
              : false
            : false,
      });
    });

    this.completeGlobalData = JSON.parse(JSON.stringify(this.globalData));
    this.completeCountryData = JSON.parse(JSON.stringify(this.countryData));

    this.setTab(this.activeTab);
  }

  setGeography(level, index, callForActivationChange) {
    this.activeIndex = index;
    this.selectedGeographyData =
      level === 'global'
        ? JSON.parse(JSON.stringify(this.globalData))
        : JSON.parse(JSON.stringify(this.countryData[index]));
    const activation =
      callForActivationChange !== null ? callForActivationChange : this.selectedGeographyData.siteActivationMethod;
    const gridData = activation
      ? this.selectedGeographyData.historical
      : this.selectedGeographyData.toggleSelection
      ? this.selectedGeographyData.custom.dateCustom
      : this.selectedGeographyData.custom.percentageCustom;
    if (gridData.length && level === 'global') {
      // site activation change retention for pre-saved global custom data
      this.globalData.siteActivationMethod = activation;
    } else if (gridData.length) {
      this.countryData[index].siteActivationMethod = activation;
    } else {
    }
    // this.selectedGeographyData.siteActivationMethod = activation;
    this.setCurrentActivationData(
      this.selectedGeographyData.siteStartDate,
      this.selectedGeographyData.siteEndDate,
      activation,
      this.selectedGeographyData.toggleSelection
    );
    this.setCurrentInputGridData(
      gridData.length ? gridData : this.createDefaultInputs(this.selectedGeographyData.toggleSelection)
    );
    this.createChart(level, gridData, index, this.getToggleValue(), false);
  }

  setTab(tab) {
    this.activeTab = tab;
    this.changesMade = false;
    this.globalData = JSON.parse(JSON.stringify(this.completeGlobalData));
    this.countryData = JSON.parse(JSON.stringify(this.completeCountryData));
    let geographyList = [];
    if (tab === 'global') {
      geographyList.push({
        geographyName: this.globalData.geographyName,
        siteActivationMethod: this.globalData.siteActivationMethod,
      });
    } else {
      this.countryData.forEach(country => {
        geographyList.push({
          geographyName: country.geographyName,
          siteActivationMethod: country.siteActivationMethod,
        });
      });
    }
    this.setCurrentGeographyGridData(geographyList);
    this.setGeography(tab, 0, null);
  }

  changeGridData(level, index) {
    if (level === 'global' || this.activeIndex === index) {
      return;
    } else if (this.changesMade) {
      this.popupOpen = true;
      this.dialog.confirm({
        title: model.CALCULATE_ACTIVATION_MODAL.modalTitle,
        message: model.CALCULATE_ACTIVATION_MODAL.modalMessage,
        afterOK: () => {
          this.geographyGridData
            .filter(row => row.geographyName == this.selectedGeographyData.geographyName)
            .map(r => (r.siteActivationMethod = this.selectedGeographyData.siteActivationMethod));
          this.setGeography(level, index, null);
          this.popupOpen = false;
        },
        afterCancel: () => {
          this.popupOpen = false;
          return;
        },
        afterClose: () => {
          this.popupOpen = false;
          return;
        },
      });
    } else {
      this.geographyGridData
        .filter(row => row.geographyName == this.selectedGeographyData.geographyName)
        .map(r => (r.siteActivationMethod = this.selectedGeographyData.siteActivationMethod));
      this.setGeography(level, index, null);
    }
  }

  validateSiteDates(level, datepicker, field, index) {
    this.displayError('');
    this.displayErrorDate('');
    this.changesMade = true;

    if (
      !moment(datepicker._inputValue).isBetween(this.dateBounds.minStartDate, this.dateBounds.maxEndDate) &&
      !moment(datepicker._inputValue).isSame(this.dateBounds.minStartDate) &&
      !moment(datepicker._inputValue).isSame(this.dateBounds.maxEndDate)
    ) {
      this.displayError('Please add a date within the study duration range.');
      this.setDate(datepicker, field, true, 0);
    } else if (
      field === 'siteEndDate' &&
      this.currentActivationData.siteStartDate.input !== '' &&
      moment(datepicker._inputValue).isBefore(this.currentActivationData.siteStartDate.input)
    ) {
      this.displayError('Please add a date after site activation start date.');
      this.setDate(datepicker, field, true, 0);
    } else if (
      field === 'siteStartDate' &&
      this.currentActivationData.siteEndDate.input !== '' &&
      moment(datepicker._inputValue).isAfter(this.currentActivationData.siteEndDate.input)
    ) {
      this.displayError('Please add a date before site activation end date.');
      this.setDate(datepicker, field, true, 0);
    } else if (this.checkDatesOutOfBound(datepicker, field)) {
      this.displayError('Your input is cleared due to change in Site Activation End date.');
      this.setCurrentInputGridData(JSON.parse(JSON.stringify(this.createDefaultInputs(true))));

      if (level == 'global') {
        this.globalData.custom.dateCustom = [];
      } else {
        this.countryData[index].custom.dateCustom = [];
      }
      this.setDate(datepicker, field, false, index, level);
    } else {
      this.setDate(datepicker, field, false, index, level);
    }
    if (this.currentActivationData.siteStartDate.input.length > 0 && this.currentActivationData.siteEndDate.input) {
      if (
        this.calculateDuration(
          this.currentActivationData.siteStartDate.input,
          this.currentActivationData.siteEndDate.input
        ) < this.elapsedDurationCust
      ) {
        // this.durationError = true;
        this.displayErrorDate(
          'Complete Site Activation By duration should be greater than currently elapsed site activation duration.'
        );
        this.isErrorDate = true;
        // this.errorMessage =
        //   'Complete Site Activation By duration should be greater than currently elapsed site activation duration.';
      }else{
        this.displayErrorDate('');
        this.isErrorDate = false
      }
    }
  }

  calculateDuration(startDate, endDate) {
    const duration = moment(endDate).diff(moment(startDate), 'months', true).toFixed(1);
    return duration;
  }

  setDate(datepicker, field, reset, index, level?) {
    datepicker.close();
    this.isError = false;
    if (reset && (field === 'siteStartDate' || field === 'siteEndDate')) {
      this.currentActivationData[field] = this.convertToDateModel('');
    } else if (field === 'siteStartDate' || field === 'siteEndDate') {
      this.currentActivationData[field].input = datepicker._inputValue;
      this.currentActivationData[field].model = datepicker._model;

      if (field === 'siteStartDate' && this.currentActivationData.toggleSelection) {
        this.inputGridData[0].perc_trial_duration.input = datepicker._inputValue;
        this.inputGridData[0].perc_trial_duration.model = datepicker._model;
        this.chartPlotted = false;
      } else if (field === 'siteEndDate' && this.currentActivationData.toggleSelection) {
        this.inputGridData.slice(-1)[0].perc_trial_duration.input = datepicker._inputValue;
        this.inputGridData.slice(-1)[0].perc_trial_duration.model = datepicker._model;
        this.chartPlotted = false;
      }

      if (level === 'global') {
        this.globalData[field].input = datepicker._inputValue;
        this.globalData[field].model = datepicker._model;
      } else {
        this.countryData[index][field].input = datepicker._inputValue;
        this.countryData[index][field].model = datepicker._model;
      }
    } else if (reset) {
      this.inputGridData[index].perc_trial_duration = this.convertToDateModel('');
    } else {
      this.inputGridData[index].perc_trial_duration.input = datepicker._inputValue;
      this.inputGridData[index].perc_trial_duration.model = datepicker._model;
    }
  }

  siteActivationMethodChanged(level, activationType, bypass) {
    // if (this.changesMade && !bypass) {
    //   this.dialog.confirm({
    //     title: model.CALCULATE_ACTIVATION_MODAL.modalTitle,
    //     message: model.CALCULATE_ACTIVATION_MODAL.modalMessage,
    //     afterOK: () => {
    //       this.siteActivationMethodChanged(level, activationType, true);
    //       this.popupOpen = false;
    //     },
    //     afterCancel: () => {
    //       this.popupOpen = false;
    //     },
    //     afterClose: () => {
    //       this.popupOpen = false;
    //     },
    //   });
    // } else {
    this.changesMade = true;
    // this.selectedGeographyData.siteActivationMethod = activationType;
    this.geographyGridData
      .filter(row => row.geographyName == this.selectedGeographyData.geographyName)
      .map(r => (r.siteActivationMethod = activationType));
    this.setGeography(level, this.activeIndex, activationType);
    // }
  }

  createChart(level, data, index, toggle, retainData) {
    if (data.length && retainData) {
      this.retainGridData(level, index);
    } else {
      const chartData: Array<[any, number]> = data.map(el => {
        const durationInput = toggle
          ? moment(el.perc_trial_duration.input).format('YYYY/MM/DD')
          : Number(el.perc_trial_duration);
        return [durationInput, Number(el.perc_site_activated)];
      });
      this.plotChart('', chartData);
      this.changesMade = false;
    }
  }

  retainGridData(level, index) {
    if (level == 'global') {
      this.globalData.siteActivationMethod = this.currentActivationData.siteActivationMethod;
      this.globalData.siteStartDate = this.currentActivationData.siteStartDate;
      this.globalData.siteEndDate = this.currentActivationData.siteEndDate;
      this.globalData.toggleSelection = this.currentActivationData.toggleSelection;
      if (this.currentActivationData.toggleSelection && !this.currentActivationData.siteActivationMethod) {
        this.globalData.custom.dateCustom = this.inputGridData;
      } else if (!this.currentActivationData.siteActivationMethod) {
        this.globalData.custom.percentageCustom = this.inputGridData;
      }
    } else {
      this.countryData[index].siteActivationMethod = this.currentActivationData.siteActivationMethod;
      this.countryData[index].siteStartDate = this.currentActivationData.siteStartDate;
      this.countryData[index].siteEndDate = this.currentActivationData.siteEndDate;
      this.countryData[index].toggleSelection = this.currentActivationData.toggleSelection;
      if (this.currentActivationData.toggleSelection && !this.currentActivationData.siteActivationMethod) {
        this.countryData[index].custom.dateCustom = this.inputGridData;
      } else if (!this.currentActivationData.siteActivationMethod) {
        this.countryData[index].custom.percentageCustom = this.inputGridData;
      }
    }
    this.setGeography(level, index, false);
  }

  setGridData(inputEvent, index, field) {
    this.changesMade = true;
    let errorMsg;
    this.displayError('');

    if (this.currentActivationData.toggleSelection && field == 'perc_trial_duration') {
      //grid dates
      this.chartPlotted = false;

      const date = moment(inputEvent._inputValue);
      const siteStartDate = moment(this.currentActivationData.siteStartDate.input);
      const siteEndDate = moment(this.currentActivationData.siteEndDate.input);
      const prevDate = moment(this.inputGridData[index - 1].perc_trial_duration.input);
      const nextDate = moment(this.inputGridData[index + 1].perc_trial_duration.input);

      if (
        (date.diff(siteStartDate) < 0 || date.diff(siteEndDate) >= 0) &&
        !date.isSame(siteStartDate) &&
        !date.isSame(siteEndDate)
      ) {
        errorMsg = 'Please add a date within the site duration range.';
      } else if (
        (date.diff(prevDate) < 0 || date.diff(nextDate) >= 0) &&
        !date.isSame(prevDate) &&
        !date.isSame(nextDate)
      ) {
        errorMsg =
          "Please enter 'Date of Activation' & '% Site Activated' value greater than the previous value & less than the next value in the column.";
      } else if (date.isSame(prevDate) || date.isSame(nextDate)) {
        errorMsg = 'Entered "Date of Activation" already exists.';
      } else {
        errorMsg = '';
        this.setDate(inputEvent, field, false, index);
      }

      if (errorMsg.length) {
        this.displayError(errorMsg);
        this.setDate(inputEvent, field, true, index);
      }
    } else {
      const currValue = Number(inputEvent); // converted to number for comparison
      switch (field) {
        case 'perc_trial_duration': {
          const isDuplicate = this.inputGridData.some(
            (el, i) => Number(el.perc_trial_duration) === currValue && i !== index && inputEvent !== null
          );
          if (inputEvent === null || inputEvent === '' || (currValue > 0 && currValue < 100)) {
            const prevValue = this.inputGridData[index - 1].perc_trial_duration;
            const nextValue = this.inputGridData[index + 1].perc_trial_duration;
            if (isDuplicate) {
              this.displayError(ERROR_MESSAGES.duplicateTrialDuration);
              this.isError = true;
            } else if (
              inputEvent !== null &&
              inputEvent !== '' &&
              ((prevValue !== null && prevValue !== '' && Number(prevValue) > currValue) ||
                (nextValue !== null && nextValue !== '' && Number(nextValue) < currValue))
            ) {
              this.displayError(ERROR_MESSAGES.badOrder);
              this.isError = true;
            } else {
              this.displayError('');
              this.isError = false;
            }
          } else {
            this.displayError(ERROR_MESSAGES.trialDurationOutOfRange);
            this.isError = true;
          }
          break;
        }
        case 'perc_site_activated': {
          if (inputEvent === null || inputEvent === '' || (currValue >= 0 && currValue <= 100)) {
            const prevValue = this.inputGridData[index - 1].perc_site_activated;
            const nextValue = this.inputGridData[index + 1].perc_site_activated;
            if (
              inputEvent !== null &&
              inputEvent !== '' &&
              ((prevValue !== null && prevValue !== '' && Number(prevValue) > currValue) ||
                (nextValue !== null && nextValue !== '' && Number(nextValue) < currValue))
            ) {
              this.displayError(ERROR_MESSAGES.badOrder);
              this.isError = true;
            } else {
              this.displayError('');
              this.isError = false;
            }
          } else {
            this.displayError(ERROR_MESSAGES.sitesActivatedOutOfRange);
            this.isError = true;
          }
          break;
        }
      }
    }
  }

  countrySiteActivationValidationAPI(data) {
    // this.savingInProgress = true;

    const inp = this.localStorage.get('inputRequest');
    const payload = {
      input_request: {
        user_id: inp.input_request.user_id,
        theme_id: inp.input_request.theme_id,
        study_id: inp.input_request.study_id,
        scenario_id: inp.input_request.scenario_id,
        scenario_version: inp.input_request.new_scenario_version?.length
          ? inp.input_request.new_scenario_version
          : inp.input_request.old_scenario_version,
        study_feasibility: inp.input_request.study_feasibility,
        modelling_level: inp.input_request.modelling_level,
        custom_modelling_level: 'country',
        study_start_date: this.convertDateToString(this.dateBounds.minStartDate),
        expected_enrollment_completion_date: this.convertDateToString(this.dateBounds.maxEndDate),
        geo_level_details: data.country,
      },
    };

    this.httpHandler.postHttpResults(appSetting.VALIDATE_SITE_ACTIVATION_DATES, payload).subscribe(
      response => {
        if (response.status === API_SUCCESS && response.result.date_validation_flag === 'valid') {
          this.generateNewVersionId(data);
          // this.saveFlyoutData(data);
          this.minSiteActivationDate = response.result.site_activation_start_date;
          this.maxSiteActivationDate = response.result.site_activation_end_date;
        } else if (response.status === API_SUCCESS && response.result.date_validation_flag === 'invalid') {
          this.savingInProgress = false;
          this.displayError('Please enter site activation dates within study duration range for all countries.');
        } else {
          this.savingInProgress = false;
          this.displayError(SOMETHING_WENT_WRONG);
        }
      },
      () => {
        this.savingInProgress = false;
        this.displayError(SOMETHING_WENT_WRONG);
      }
    );
  }

  getToggleValue() {
    if (this.activeTab === 'global' && this.currentActivationData.siteActivationMethod) {
      return false;
    } else if (this.activeTab === 'global' && !this.currentActivationData.siteActivationMethod) {
      return this.currentActivationData.toggleSelection;
    } else if (
      this.activeTab === 'country' &&
      this.globalData.siteActivationMethod &&
      this.currentActivationData.siteActivationMethod
    ) {
      return false;
    } else if (
      this.activeTab === 'country' &&
      !this.globalData.siteActivationMethod &&
      this.currentActivationData.siteActivationMethod
    ) {
      return this.globalData.toggleSelection;
    } else {
      return this.currentActivationData.toggleSelection;
    }
  }

  checkDatesOutOfBound(inputEvent, field) {
    if (this.currentActivationData.siteStartDate.input == '' || !this.currentActivationData.toggleSelection) {
      return false; // Do not check if not already present in grid
    }

    let startDate, endDate, errorMsg;

    if (field === 'siteStartDate') {
      startDate = moment(inputEvent._inputValue);
      endDate = moment(this.currentActivationData.siteEndDate.input);
    } else {
      startDate = moment(this.currentActivationData.siteStartDate.input);
      endDate = moment(inputEvent._inputValue);
    }

    const inputsToCheck = this.inputGridData.slice(1, -1); // Check only for user entered inputs
    const orderError = inputsToCheck.filter(
      d =>
        (moment(d.perc_trial_duration.input).diff(startDate) <= 0 ||
          moment(d.perc_trial_duration.input).diff(endDate) >= 0) &&
        (d.perc_trial_duration.input !== null || d.perc_trial_duration.input !== '')
    );

    errorMsg = orderError.length ? ERROR_MESSAGES.badOrder : '';

    return Boolean(errorMsg.length);
  }

  enableCalculateButton() {
    return (
      this.chartPlotted ||
      !this.hasMandatoryFields() ||
      this.isError ||
      this.popupOpen ||
      this.currentActivationData.siteStartDate.input == '' ||
      this.currentActivationData.siteEndDate.input == ''
    );
  }

  generateNewVersionId(data) {
    // const scenarioDetails = this.localStorage.get('scenario-details');
    // if (scenarioDetails && scenarioDetails.scenario_status.toLowerCase() === 'completed') {
    //   // generate Version ID and modify inputRequest
    //   this.scenarioService.generateScenarioVersion().subscribe((newVersion: string) => {
    //     if (newVersion) {
    //       this.inputRequest.input_request.old_scenario_version = this.inputRequest.input_request.new_scenario_version;
    //       this.inputRequest.input_request.new_scenario_version = newVersion;
    //       // this.inputRequest.input_request.scenario_status = 'In Progress';
    //       this.localStorage.set('inputRequest', this.inputRequest);
    //       this.saveFlyoutData(data);
    //     } else {
    //       // this.handleError(SOMETHING_WENT_WRONG, 'save-api');
    //       this.displayError(SOMETHING_WENT_WRONG);
    //     }
    //   });
    // } else {
    this.saveFlyoutData(data);
    // }
  }
}
