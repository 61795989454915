import { Component, Input, OnInit } from '@angular/core';
import { SortingService } from '@app/shared/components/grid/sorting-service';

@Component({
  selector: 'app-multi-header-table',
  templateUrl: './multi-header-table.component.html',
  styleUrls: ['./multi-header-table.component.scss'],
})
export class MultiHeaderTableComponent implements OnInit {

  @Input() tableData: any;
  @Input() searchText: string;
  @Input() configurations: any;
  // @Output() scrollEnd: EventEmitter<any> = new EventEmitter<any>();

  public tbody: any = [];
  public mainHeader = [];
  public subHeader = [];
  public mainHeaderConfiguration = [];
  public subHeaderConfiguration = [];
  public tableConfiguration = [];

  basedOn = 'geography_level';
  index = 0;
  headerType = 'mainHeader';


  constructor(private sortingService: SortingService) { }

  ngOnInit(): void {
    this.initializeTable();
    setTimeout(() => { this.adjustPadForScroll(); });
  }

  adjustPadForScroll(): void {
    const elements: any = document.getElementsByClassName('multi-header-table');
    if (elements.length && this.tbody.length) {
      for (const ele of elements) {
        const tableContainer = $('.table-body-content', ele);
        const headerElement = $('.table-header-content', ele);
        const scrollHeight = tableContainer[0].scrollHeight;
        const clientHeight = tableContainer[0].clientHeight;
        if (scrollHeight > clientHeight) {
          const scrollWidth = tableContainer[0].offsetWidth - tableContainer[0].clientWidth;
          $(headerElement).attr('style', 'width: calc(100% - ' + scrollWidth + 'px) !important');
        } else {
          $(headerElement).attr('style', 'width: 100%');
        }
      }
    }

  }

  initializeTable(): void {
    this.mainHeader = this.configurations.mainHeader;
    this.mainHeaderConfiguration = this.configurations.mainHeaderConfiguration;
    this.subHeader = this.configurations.subHeader;
    this.subHeaderConfiguration = this.configurations.subHeaderConfiguration;
    this.tableConfiguration = this.configurations.tableConfiguration;
    this.tbody = this.tableData;
    this.sortTableContent(this.basedOn, this.index, this.headerType);
  }

  sortTableContent(basedOn: string, index: number, headerType: string): void {
    this.basedOn = basedOn;
    this.index = index;
    this.headerType = headerType;

    if (this.tbody && this.tbody.length) {
      let itemConfig;
      if (headerType === 'mainHeader') {
        itemConfig = this.mainHeaderConfiguration[index];
      } else {
        itemConfig = this.subHeaderConfiguration[index];
      }
      if (itemConfig.isSortable) {
        if (itemConfig.sortOrder === 'ASC') {
          itemConfig.sortOrder = 'DSC';
          this.sortingService.commonReverseSortByKey(this.tableData, basedOn, itemConfig.isNumber);
        } else {
          itemConfig.sortOrder = 'ASC';
          this.sortingService.commonSortByKey(this.tableData, basedOn, itemConfig.isNumber);
        }
        this.resetSort(basedOn);
      }
    }
  }

  resetSort(exceptFor?: string): void {
    if (exceptFor) {
      this.mainHeaderConfiguration.forEach(el => {
        if (el.id !== exceptFor) {
          el.sortOrder = '';
        }
      });
      this.subHeaderConfiguration.forEach(el => {
        if (el.id !== exceptFor) {
          el.sortOrder = '';
        }
      });
    } else {
      this.mainHeaderConfiguration.forEach(el => {
        el.sortOrder = '';
      });
      this.subHeaderConfiguration.forEach(el => {
        el.sortOrder = '';
      });
    }
  }

  // Scroll Event Emitter
  onScrollEnd(): void {
    // this.scrollEnd.emit();
  }

}
