import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {

  private eventBus = new Subject<BroadcastEvent>();

  constructor() { }

  broadcast(key: string, data?: any): void {
    this.eventBus.next({key, data});
  }

  on<T>(key: string): Observable<T> {
    return this.eventBus.asObservable()
      .pipe(filter(event => event.key === key), map(event => event.data));
  }

}

interface BroadcastEvent {
  key: string;
  data?: any;
}
