import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ctfo-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss'],
})
export class SuccessDialogComponent {
  @Input() title: string;
  @Input() message: string;
  @Output() afterCancel = new EventEmitter<void>();
  @Output() afterClose = new EventEmitter<void>();
}
