import { BaseAPIPayload, BaseAPIResponse } from './api.model';

export interface UpdateMetricsInputData extends BaseAPIPayload {
  page: 'site' | 'country';
}

export interface UpdateMetricsDownloadPayload extends BaseAPIPayload {
  file_type: 'site' | 'country';
}

export interface UpdateMetricsDownloadAPIResponse extends BaseAPIResponse {
  result: { url: string };
}

export interface UpdateMetricsFileValidationAPIResponse extends BaseAPIResponse {
  result: {
    file_id: string;
    version: string;
    validation_status: string;
    data_type_validation: boolean;
    null_validation: boolean;
    summary_report: {
      errors: [];
    };
    upload_status: boolean;
  };
}
