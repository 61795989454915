import { HttpClient } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { appSetting } from '@app/shared/dependency/constants/endpoints';
import { API_SUCCESS } from '@constants/messages';
import { SessionStorageService } from 'angular-web-storage';
import { ToastrService, GlobalConfig } from 'ngx-toastr';

import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { CustomNotificationComponent } from '../custom-notification.component';
import { localNames } from '../dependency/constants/storage';
import { DialogService } from '../dependency/dialog/dialog.service';
import { Utils } from '../dependency/utils';
import { AuthService } from './auth.service';

/* import { CustomNotificationComponent } from './../custom-notification.component'; */
import { HttpHandlerService } from './http-handler.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  notificationStream = new Subject();
  notificationSubscription: Subscription;
  scenarioNameEntered;
  closePanel = new Subject();
  unsavedChnages = false;
  options: GlobalConfig;
  label: any = '';
  refreshSuccess = new Subject<any>();
  listType: any;
  ctfoStudy = false;
  sipsaveButtonDisable: boolean = false;

  activeUser = [];

  lastNotification;

  hasNewNotification: boolean;

  refreshScreen = new BehaviorSubject(false);

  activeUserList: any = [];

  latestLockStatus = new BehaviorSubject(false);
  studyDetails: any;

  constructor(
    private toastr: ToastrService,
    private http: HttpHandlerService,
    private auth: AuthService,
    private dialog: DialogService,
    private localStorage: SessionStorageService,
    private router: Router,
    private sessionStorage: SessionStorageService,
    
  ) {
    if (Utils.getSessionStorage(localNames.USER_ACTIVE_STATE) === 'active') {
      this.insertUserActivityDetails(true);
    }
    this.options = this.toastr.toastrConfig;
    this.options.includeTitleDuplicates = true;
  }

  showSuccess(message: string, title: string, config?: object) {
    config = { ...this.options };
    this.toastr.success(message, title, config);
  }

  showError(message: string, title: string, config?: object) {
    this.toastr.error(message, title, config);
  }

  showInfo(message: string, title: string, config?: object) {
    this.toastr.info(message, title, config);
  }

  showWarning(message: string, title: string, config?: object) {
    this.toastr.warning(message, title, config);
  }

  removeAllToast() {
    this.toastr.clear(); // removes all
  }

  removeToast(toastId: number) {
    this.toastr.remove(toastId); // removes and destroys
  }

  showCustom(message: string, title?: string) {
    const opt = { ...this.options }; // use utils here
    opt.toastComponent = CustomNotificationComponent;
    opt.toastClass = 'pinktoast';
    opt.timeOut = 3000;
    return this.toastr.show(message, title, opt);
  }

  startNotification(): void {
    Utils.setSessionStorage(localNames.NOTIFICATION_STATUS, 'started');
    const data = this.localStorage.get('scenario-details');
    const user_access = data ? data.user_access : true;
    if (!user_access) return;
    if (this.notificationSubscription) {
      this.notificationSubscription.unsubscribe();
    }
    this.notificationSubscription = interval(10000).subscribe(() => {
      this.fetchNotification();
    });
  }

  public insertUserActivityDetails(active,data?): Promise<any> {
    return new Promise((resolve, reject) => {
      const payload = JSON.parse(JSON.stringify(this.createInsertPayload(active,data)));
      // if (payload.input_request.study_id && payload.input_request.theme_id) {
      this.http.postHttpResults(appSetting.INSERT_USER_ACTIVITY, payload).subscribe(
        data => {
          if (payload.active_in_scenario) {
            Utils.setSessionStorage(localNames.USER_ACTIVE_STATE, 'active');
          } else {
            Utils.setSessionStorage(localNames.USER_ACTIVE_STATE, 'inactive');
          }
          setTimeout(() => {
            if (!active) {
              const cancelState = localStorage.getItem('pageLoadCancelled') ?? false;
              if (cancelState) {
                // If cancel state in true do nothing
              } else {
                this.clearLocalStorage();
              }
            }
          }, 2000);
          resolve(true);
        },
        () => {
          reject();
        }
      );
      // }
    });
  }

  public createInsertPayload(active,data?): any {
    const inputRequest = this.localStorage.get('inputRequest');
    const studyDetail = this.localStorage.get('study-details');
    const userDetails = this.localStorage.get('user-details');
    const studyView = this.localStorage.get('study-view');
    let logoutDetails = {
        logout_from:  'tab' ,
        user_id: this.localStorage.get('user-details').user_id ,
        session_id: this.sessionStorage.get('user-details').session_id,
    }
     
    if (studyDetail) {
      return {
        input_request: {
          user_group: inputRequest?.input_request.user_group ?? userDetails.app_user_group,
          active_in_scenario: active,
          study_id: studyDetail?.study_id ?? '',
          scenario_id: inputRequest?.input_request.scenario_id ?? '',
          theme_id: inputRequest?.input_request.theme_id ?? '',
          user_id: userDetails?.user_id ?? '',
          study_feasibility: studyDetail?.study_feasibility ?? '',
          track_id: inputRequest?.input_request.track_id ?? '',
          forecast_type: inputRequest?.input_request.forecast_type ?? '',
          logout_details: data === 'true' ? logoutDetails : {}
        },
      };
    } else {
      return {
        input_request: {
          user_group: userDetails?.app_user_group ?? '',
          active_in_scenario: active,
          study_id: studyView?.study_id ?? '',
          scenario_id: inputRequest?.input_request.scenario_id ?? '',
          theme_id: inputRequest?.input_request.theme_id ?? '',
          user_id: userDetails?.user_id ?? '',
          study_feasibility: studyView?.study_feasibility ?? '',
          track_id: inputRequest?.input_request.track_id ?? '',
          forecast_type: inputRequest?.input_request.forecast_type ?? '',
          logout_details: data === 'true' ? logoutDetails : {}
        },
      };
    }
  }

  clearLocalStorage(): void {
    this.localStorage.remove('study-details');
    this.localStorage.remove('mode');
    this.localStorage.remove('modeling-inputs');
    this.localStorage.remove('inputRequest');
    this.localStorage.remove('ids');
    this.localStorage.remove('tabInfo');
    this.localStorage.remove('scenario-details');
    this.localStorage.remove('selectedScenarios');
    this.localStorage.remove('pageButtonAlreadyClicked');
    this.localStorage.remove('study-view');
    this.localStorage.remove('historicalDetails'); // created when uploading historical data - add pre study
    this.localStorage.remove('valuesChangedInIMP');
    this.localStorage.remove('valuesChangedInGeo');
    this.localStorage.remove('trackMonitorDetails');
    this.localStorage.remove('distributionTypeChanged');
  }

  stopNotification(): void {
    const studyView = this.localStorage.get('study-view');
    this.insertUserActivityDetails(false);
    Utils.removeSessionStorageItem(localNames.USER_ACTIVE_STATE);
    Utils.setSessionStorage(localNames.NOTIFICATION_STATUS, 'stopped');
    this.activeUser = Utils.assignObject([]);
    this.notificationSubscription?.unsubscribe();
    // this.clearLocalStorage();
  }

  createGetNotificationPayload(): any {
    const scenarioDetails = this.localStorage.get('scenario-details');
    const userDetails = this.localStorage.get('user-details');
    const input_request = this.localStorage.get('inputRequest');
    if (scenarioDetails) {
      return {
        input_request: {
          scenario_id: scenarioDetails?.scenario_id ?? '',
          study_id: scenarioDetails?.study_id ?? '',
          theme_id: scenarioDetails?.theme_id ?? '',
          user_id: userDetails?.user_id ?? '',
          user_group: scenarioDetails?.user_group ?? '',
          //  "user_access_id": this.addUserDetails(['user_email'])['user_email'],
          user_access_id: userDetails?.user_email ?? '',
          active_users_with_edit_access: this.activeUserList,
        },
      };
    } else {
      return {
        input_request: {
          scenario_id: input_request?.input_request?.scenario_id ?? '',
          study_id: input_request?.input_request?.study_id ?? '',
          theme_id: input_request?.input_request?.theme_id ?? '',
          user_id: userDetails?.user_id ?? '',
          user_group: input_request?.input_request?.user_group ?? '',
          // "user_access_id": this.addUserDetails(['user_email'])['user_email'],
          user_access_id: userDetails?.user_email ?? '',
          active_users_with_edit_access: this.activeUserList,
        },
      };
    }
  }

  fetchNotification(): Promise<any> {
    const payload = this.createGetNotificationPayload();
    return new Promise((resolve, reject) => {
      this.http.postHttpResults(appSetting.GET_NOTIFICATIONS, payload).subscribe(
        (response: any) => {
          this.latestLockStatus.next(response.result.is_scenario_locked);
          const cancelState = localStorage.getItem('pageLoadCancelled') ?? false;
          const scenarioDetails = this.localStorage.get('scenario-details');
          const user_access = scenarioDetails ? scenarioDetails.user_access : true;
          if (cancelState && user_access) {
            this.insertUserActivityDetails(true).then(data => {
              localStorage.removeItem('pageLoadCancelled');
            });
          }
          if (Utils.isAPICallSuccessful(response)) {
            this.activeUserList = [];
            for (let i = 0; i < response.result.active_users_with_edit_access.length; i++) {
              this.activeUserList.push(response.result.active_users_with_edit_access[i]);
            }

            this.setNewNotification(this.lastNotification, response);
            this.lastNotification = Object.assign({}, response);
            const newNotifications = response.result.newly_added_users;
            newNotifications.forEach(element => {
              const message = `${element.user_name} is now active on the scenario`;
              this.showInfo(message, 'Notification');
            });

            const feedbackNotifications = response.result?.feedback_notification?.filter(
              item => item.poked_status !== 'poked'
            );
            feedbackNotifications?.forEach(e => {
              //const message = `${e.user_name} has ${e.poked_status} your request. Please refresh the page for latest records.`;
              this.showInfo(this.createNotificationMessage(e, e.poked_status), 'Notification');
            });
            const studyDetails = this.localStorage.get('study-details');
            const inputRequest = this.localStorage.get('inputRequest');
            const pokeNotifications = response.result?.feedback_notification?.filter(
              item => item.poked_status === 'poked'
            );
            pokeNotifications?.forEach(e => {
              const message = `User ${e.user_name} (${e.user_group}) has requested edit access for some geographies.Please view Notification Module for more details`;
              const toasterRef = this.showCustom(message, 'Access Request');
              toasterRef.onAction.subscribe(action => {
                let pokeDetails: any = {
                  poked_by: e.poked_by,
                  poked_to: this.addUserDetails(['user_id'])['user_id'],
                  country: e.country,
                };
                if (action === 'approve') {
                  pokeDetails = { poked_status: 'accepted', ...pokeDetails };
                } else if (action === 'reject') {
                  pokeDetails = { poked_status: 'rejected', ...pokeDetails };
                }
                const payload = {
                  input_request: {
                    //...this.addScenarioDetail(['scenario_id', 'theme_id']),
                    ...this.addUserDetails(['user_id', 'user_group']),
                    scenario_id: inputRequest?.input_request.scenario_id ?? '',
                    theme_id: inputRequest?.input_request.theme_id ?? '',
                    user_access_id: this.addUserDetails(['user_email'])['user_email'],
                    study_id: studyDetails?.study_id ?? '',
                    poked_details: [pokeDetails],
                  },
                };
                this.http.postHttpResults(appSetting.UPDATE_POKE_RELEASE, payload).subscribe(
                  data => {
                    if (Utils.isAPICallSuccessful(data)) {
                      this.closePanel.next();
                      this.dialog.success({
                        title: 'Request Successful',
                        message: 'Your request has been processed successfully.',
                        afterClose: () => {
                          this.reloadComponent();
                        },
                      });
                      this.toastr.clear(toasterRef.toastId);
                    } else {
                      this.dialog.error({
                        title: 'Error',
                        message: 'SOMETHING WENT WRONG',
                      });
                    }
                  },
                  () => {
                    this.dialog.error({
                      title: 'Error',
                      message: 'SOMETHING WENT WRONG',
                    });
                  }
                );
              });
            });

            this.activeUser = response.result.active_users_with_edit_access;
            resolve(response);
          } else {
            reject();
          }
        },
        () => {
          reject();
        }
      );
    });
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  createNotificationMessage(notification, type = ''): string {
    let obj = {
      rejected: `Please try again after some time`,
      accepted: `Please refresh the screen to edit new country`,
    };
    const countries = notification?.country.join(' ,');
    return `Your access request has been ${type} by ${notification.user_name}. ${obj[type]}`;
    //for the geography ${countries}
  }

  setNewNotification(prev, cur): void {
    this.hasNewNotification = JSON.stringify(prev) === JSON.stringify(cur);
  }

  checkNewNotification(): boolean {
    return this.hasNewNotification;
  }

  addUserDetails(options = []): any {
    const user = this.localStorage.get('user-details');
    user.user_group = user.app_user_group;
    return this.fetchKeys(options, user);
  }

  addScenarioDetail(options = []): any {
    const data = this.localStorage.get('scenario-details');
    return this.fetchKeys(options, data);
  }

  private fetchKeys(options, data): any {
    if (options?.length <= 0) return data;
    return options?.reduce((acc, cur) => {
      acc = {
        ...acc,
        [cur]: data?.[cur] ?? '',
      };
      return acc;
    }, {});
  }

  isMda(): boolean {
    return this.auth.getUserDetails().user_group === 'mda' || this.addScenarioDetail(['user_group']) === 'mda';
  }

  isGfl(): boolean {
    return this.auth.getUserDetails().user_group === 'gfl' || this.addScenarioDetail(['user_group']) === 'gfl';
  }

  userSpecificMessage(): String {
    if (this.isMda()) {
      return 'NO DATA';
    } else {
      return 'OPTIMIZE NO RESULTS';
    }
  }

  setLabel(label): any {
    this.label = label;
    this.studyDetails = this.localStorage.get('study-view');
    this.studyDetails.label = label;
    this.localStorage.set('study-view', this.studyDetails);
  }
}

export interface config {
  toastComponent: Component;
  closeButton: boolean; //	false	Show close button
  timeOut: number; // 5000	 Time to live in milliseconds
  extendedTimeOut: number; // 1000	Time to close after a user hovers over toast
  disableTimeOut: boolean; // | 'timeOut' | 'extendedTimeOut'	false	Disable both timeOut and extendedTimeOut when set to true. Allows specifying which timeOut to disable, either: timeOut or extendedTimeOut
  easing: string; // 'ease-in'	Toast component easing
  easeTime: string; // | number	300	Time spent easing
  enableHtml: boolean; //	false	Allow html in message
  progressBar: boolean; // false	Show progress bar
  progressAnimation: string; //	'decreasing' | 'increasing'	'decreasing'	Changes the animation of the progress bar.
  toastClass: string; //	'ngx-toastr'	Class on toast
  positionClass: string; //	'toast-top-right'	Class on toast container
  titleClass: string; // 'toast-title'	Class inside toast on title
  messageClass: string; // 'toast-message'	Class inside toast on message
  tapToDismiss: boolean; // true	Close on click
  onActivateTick: boolean; // false Fires changeDetectorRef.detectChanges() when activated. Helps show toast from asynchronous events outside of Angular's change detection
}
