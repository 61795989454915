<div class="modal-header border-bottom-0 pb-0">
    <h5 class="mb-4 font-18">Copy Inputs</h5>

</div>
<div class="modal-body pt-0 pb-2">
    <p class="mb-4">Select a cohort to copy. Any new changes made to the copied cohort will not affect the copy cohort.
    </p>
    <p class="font-weight-bold">Cohorts</p>


    <div *ngFor="let c of cohortList ; let i = index ">
        <label class="custom-radio-button mb-0 d-flex align-items-center mr-4 normal-font-weight">
            <input type="radio" value="cohort" name="cohortName" (click)="setSelectedCohort(c)" [checked]="i===0">
            <span></span>{{c}}
        </label>

    </div>

</div>
<div class="modal-footer d-flex py-0 d-flex justify-content-start">
    <button class="button-primary button-ellipsis d-flex align-items-center mr-4" (click)="returnSelectedCohort()"
        [disabled]="cohortList && !cohortList.length">
        Apply
    </button>
    <div class="cursor-pointer cancel-button" (click)="onCancel()"><img src="assets/images/shared/cancel.svg" alt="">
        Cancel</div>
</div>