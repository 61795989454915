<div class="custom-table">
  <!-- Debug Error -->
  <div class="table-row table-error" *ngIf="internal_error_message && isDebugMode" [ngStyle]="tableStyle">
    <header>{{ internal_error_message }}</header>
  </div>

  <!-- Loader -->
  <div class="table-row table-error" *ngIf="isLoading" [ngStyle]="tableStyle">
    <img src="assets/images/loader.gif" />
  </div>

  <!-- Actual Error-->
  <div class="table-row table-error" *ngIf="!isLoading && errorMessage" [ngStyle]="tableStyle">
    <header>{{ errorMessage }}</header>
  </div>

  <!-- Actual Table-->
  <div class="complete-table" snInfiniteScroll [ngClass]="{ 'table-loading': isLoading }" (scrollEnd)="onScrollEnd()"
    [offset]="100" [disabled]="isLazyLoadDisabled" [ngStyle]="tableStyle">
    <!-- Non Scrollable Table-->
    <div class="no-scroll-table" [style.width]="noScrollWidth">
      <!-- Table Header-->
      <div class="table-row table-header" [ngClass]="{ 'table-header-sticky': isHeaderSticky }" *ngIf="isHeaderVisible">
        <ng-container *ngFor="let column of tableColumns">
          <div class="table-column custome-columns" *ngIf="column['isdisplayed']" [style.width]="column['width']"
            (click)="sortData(column['columndatakey'], column['columntype'])" [ngStyle]="column['style']">
            {{ column['columnname'] }}
            <i *ngIf="sortType === 'desc' && sortOn === column['columndatakey']" class="fa fa-caret-down"></i>
            <i *ngIf="sortType === 'asc' && sortOn === column['columndatakey']" class="fa fa-caret-up"></i>
          </div>
        </ng-container>
      </div>

      <!-- Table Data-->
      <div class="table-data-container">
        <div class="table-row" *ngFor="let row of tableData">
          <ng-container *ngFor="let column of tableColumns">
            <div class="table-column" *ngIf="column['isdisplayed']" [ngStyle]="column['style']"
              [style.width]="column['width']">
              <div class="text" *ngIf="column['columntype'] === 'plaintext'" ngClass="{{ column?.class }}"
                (click)="onButtonClick(column, row)">
                <header *ngIf="!column['bindhtmlkey']" [title]="row[column['columndatakey']] | titlecase">
                  {{ row[column['columndatakey']] }}
                </header>
                <div ngClass="{{ column?.class }}" *ngIf="column['bindhtmlkey']"
                  [title]="row[column['columndatakey']] | titlecase" [innerHTML]="row[column['columndatakey']]"></div>
              </div>
              <div class="text" *ngIf="column['columntype'] === 'inputtext'">
                <input type="text" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" (change)="onButtonClick(column, row)" />
              </div>
              <div class="checkbox" *ngIf="column['columntype'] === 'checkbox'">
                <input type="checkbox" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" (click)="onButtonClick(column, row)" />
              </div>
              <div class="checkbox checkboxLabel" *ngIf="column['columntype'] === 'checkboxLabel'">
                <input type="checkbox" [checked]="false" [title]="row[column['columndatakey']] | titlecase"
                  (click)="onButtonClickForCheckbox(column, row, $event)" />
                <div class="chktext" [ngClass]="{ checkTextClickDisable: checkTextDisable }"
                  [title]="row[column['columndatakey']] | titlecase" (click)="onButtonClick(column, row)">
                  <div>{{ row[column['columndatakey']] }}</div>
                </div>
              </div>
              <div class="checkbox" *ngIf="column['columntype'] === 'radio'">
                <input type="radio" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" />
              </div>
              <div class="buttoncontainer" *ngIf="column['columntype'] === 'actionbutton'"
                (click)="onButtonClick(column, row)">
                <button>{{ column['actionname'] }}</button>
              </div>
              <div class="buttoncontainer" *ngIf="column['columntype'] === 'dropdown'"
                (click)="onButtonClick(column, row)">

              </div>
              <div class="buttoncontainer" *ngIf="column['columntype'] === 'actionicon'">
                <div class="buttons"
                  [ngClass]="{ 'disable-action': row['status_label'] == 'in progress' && icon['action'] == 'share' }"
                  *ngFor="let icon of column['actionicons']" (click)="onButtonClick(column, row, icon)">
                  <!-- <img
                    [ngClass]="{ 'disable-action': row['status_label'] == 'in progress' && icon['action'] == 'share' }"
                    ctempHasRole
                    resource="{{ icon['resource'] }}"
                    specificRole="{{ row['user_group'] }}"
                    [src]="icon['iconsrc']"
                    [title]="icon['title'] | titlecase"
                    height="20"
                  /> -->
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Marker-->
    <div class="marker-scroll-table" [style.width]="'40px'" *ngIf="tableScrollableColumns.length > 0">
      <!-- Table Header-->
      <div class="table-row table-header" [ngClass]="{ 'table-header-sticky': isHeaderSticky }" *ngIf="isHeaderVisible">
        <div class="table-column" (click)="scrollSlicedTable('right')">
          <header>&nbsp;</header>
          <i class="fa fa-caret-left"></i>
        </div>
      </div>

      <!-- Table Data-->
      <div class="table-data-container">
        <div class="table-row" *ngFor="let row of tableData">
          <div class="table-column" [style.minWidth]="'20px'">
            <div class="text">
              <header>&nbsp;</header>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Scrollable Table-->
    <div class="scroll-table" [style.width]="scrollWidth" id="scroll-table" *ngIf="tableScrollableColumns.length > 0"
      [ngClass]="{ 'scroll-table-loading': isLoading, 'table-header-sticky': isHeaderSticky }">
      <!-- Table Header-->
      <div class="table-row table-header" [ngClass]="{ 'table-header-sticky': isHeaderSticky }" *ngIf="isHeaderVisible">
        <ng-container *ngFor="let column of tableScrollableColumns">
          <div class="table-column" *ngIf="column['isdisplayed']" [style.minWidth]="column['width']"
            [style.width]="column['width']" (click)="sortData(column['columndatakey'], column['columntype'])">
            <header>{{ column['columnname'] }}</header>
            <i *ngIf="sortType === 'desc' && sortOn === column['columndatakey']" class="fa fa-caret-down"></i>
            <i *ngIf="sortType === 'asc' && sortOn === column['columndatakey']" class="fa fa-caret-up"></i>
          </div>
        </ng-container>
      </div>

      <!-- Table Data-->
      <div class="table-data-container">
        <div class="table-row" *ngFor="let row of tableData">
          <ng-container *ngFor="let column of tableScrollableColumns">
            <div class="table-column" *ngIf="column['isdisplayed']" [ngStyle]="column['style']"
              [style.minWidth]="column['width']" [style.width]="column['width']">
              <div class="text" *ngIf="column['columntype'] === 'plaintext'" (click)="onButtonClick(column, row)">
                <header [title]="row[column['columndatakey']] | titlecase">{{ row[column['columndatakey']] }}</header>
                <header [title]="row[column['columndatakey']] | titlecase" [innerHTML]="row[column['bindhtmlkey']]">
                </header>
              </div>
              <div class="text" *ngIf="column['columntype'] === 'inputtext'">
                <input type="text" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" (change)="onButtonClick(column, row)" />
              </div>
              <div class="checkbox" *ngIf="column['columntype'] === 'checkbox'">
                <input type="checkbox" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" (ngModelChange)="onButtonClick(column, row)" />
              </div>
              <div class="checkbox" *ngIf="column['columntype'] === 'checkboxLabel'">
                <input type="checkbox" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" (ngModelChange)="onButtonClick(column, row)" />
              </div>
              <div class="checkbox" *ngIf="column['columntype'] === 'radio'">
                <input type="radio" [(ngModel)]="row[column['columndatakey']]"
                  [title]="row[column['columndatakey']] | titlecase" />
              </div>
              <div class="buttoncontainer" *ngIf="column['columntype'] === 'actionbutton'"
                (click)="onButtonClick(column, row)">
                <button>{{ column['actionname'] }}</button>
              </div>
              <div class="buttoncontainer" *ngIf="column['columntype'] === 'dropdown'"
                (click)="onButtonClick(column, row)">

              </div>
              <div class="buttoncontainer" *ngIf="column['columntype'] === 'actionicon'">
                <div class="buttons" *ngFor="let icon of column['actionicons']"
                  (click)="onButtonClick(column, row, icon)">
                  <img [src]="icon['iconsrc']" [title]="icon['title'] | titlecase" height="20" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Marker-->
    <div class="marker-scroll-table" [style.width]="'40px'" *ngIf="tableScrollableColumns.length > 0">
      <!-- Table Header-->
      <div class="table-row table-header" [ngClass]="{ 'table-header-sticky': isHeaderSticky }" *ngIf="isHeaderVisible">
        <div class="table-column" (click)="scrollSlicedTable('left')">
          <header>&nbsp;</header>
          <i class="fa fa-caret-right"></i>
        </div>
      </div>

      <!-- Table Data-->
      <div class="table-data-container">
        <div class="table-row" *ngFor="let row of tableData">
          <div class="table-column" [style.minWidth]="'20px'">
            <div class="text">
              <header>&nbsp;</header>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>