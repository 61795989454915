<main class="container bg-white cont-height w-100 d-grid-center">
  <header class="mx-auto text-center">
    <img
      class="mb-3 zaidyn-logo"
      src="assets/images/header/zs-logo.svg"
      title="Zaidyn Logo"
      alt="Zaidyn Logo"
      draggable="false"
    />
    <h1 class="font-weight-light">SORRY! WE ARE UNDER SCHEDULED MAINTENANCE.</h1>
    <p>Our portal is currently undergoing scheduled maintenance, We'll be right back.</p>
    <p>Thank you for your patience.</p>
    <div class="py-4"></div>
    <p>
      In case of any query reach out to us by
      <a href="mailto:sanofi.clinicalsupport@zs.com?subject=Zaidyn%20Enrollment%20Forecasting%20Query">clicking here.</a>
    </p>
  </header>
</main>
