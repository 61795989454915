import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false,
})
export class SearchPipe implements PipeTransform {

    keysFromJSON;

    transform(items: any[], searchFilter: string, filterOn: Array<string>): any[] {

        this.keysFromJSON = filterOn;
        // if items is valid extract the keys from the json object
        if (!items) {
            return [];
        }
        // is searchFilter is valid convert it to lowercase else return all the items
        if (!searchFilter) {
            return items;
        } else {
            searchFilter = searchFilter.toLowerCase();
        }
        return items.filter((jsonItem) => this.applyFilter(jsonItem, searchFilter));
    }

    // apply the search text filter on the json item passed
    applyFilter(jsonItem, searchFilter): boolean {
        let returnVal = false;
        if (searchFilter) {
            for (const key of this.keysFromJSON) {
                if (jsonItem[key]) {
                    if (jsonItem[key].toString().toLowerCase().includes(searchFilter)) {
                        returnVal = true;
                    }
                }
            }
        }
        return returnVal;
    }
}
