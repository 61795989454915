import { environment } from 'src/environments/environment';

let currentUrl = 'http://localhost:8080/api';
const baseUrls = {
  local: 'https://zs-snfi-clinical-ctemp.dev.zsservices.com/api/CTEMP',
  dev: 'https://zs-snfi-clinical-ctemp.dev.zsservices.com/api/CTEMP',
  qa: 'https://api.sanofi-ctemp.dev.zsservices.com/CTEMP',
  stage: 'https://api.sanofi-ctemp.qa.zsservices.com/CTEMP',
  uat: 'https://api.sanofi-ctemp.uat.zsservices.com/CTEMP',
  production: 'https://api.enrollment-snfi-cde.zsservices.com/CTEMP',
};

const currentUrlSet = (env: string): string => {
  return baseUrls[env];
};

const helpers = {
  setCurrentUrl: (): string => {
    currentUrl = currentUrlSet(environment.env);
    return currentUrl;
  },
  getCurrentUrl: (dev: boolean): string => (dev ? baseUrls.dev : currentUrl),
};

export const consolidatedBaseUrl = Object.assign(baseUrls, helpers);
