export enum GenerateStatus {
  DATA_GENERATE = 'data generated',
  IN_PROGRESS = 'data generation in progress',
  PROGRESS = 'in progress',
  NO_DATA = 'no data available',
}
export enum Status {
  SUCCESS = 'success',
  FAILED = 'failed',
}
export enum LandscapeService {
  DISPLAY_TRIAL_CHART = 'display-trial-chart',
  TRIAL_CHART_POLLING = 'trial-chart-polling',
  CLINICAL_TRIAL_CHART = 'clinicaltrialchart',
  DISPLAY_TRIAL_DETAIL = 'display-trial-detail',
  TRIAL_DETAIL_POLLING = 'trial-detail-polling',
  TRIAL_DETAILS = 'trialdetails',
  DISPLAY_TRIAL_TIMELINE = 'display-trial-timeline',
  TRIAL_TIMELINE_POLLING = 'trial-timeline-polling',
  TRIAL_TIMELINE = 'trialtimeline',
  DRUG_MAP_GENERATE = 'landscapecount/drug',
  DRUG_MAP_POLLING = 'drug-map-polling',
  COUNTRY_LANDSCAPE_DETAIL = 'countrylandscape-detail/global',
  COUNTRY_LANDSCAPE_POLLING = 'country-landscape-polling',
}
export enum TieringSource {
  COMMON = 'common',
}
