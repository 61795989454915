import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@app/shared/dependency/dialog';
import { NotificationService } from '@app/shared/services/notification.service';
import { SessionStorageService } from 'angular-web-storage';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-session-handler-modal',
  templateUrl: './session-handler-modal.component.html',
  styleUrls: ['./session-handler-modal.component.scss']
})
export class SessionHandlerModalComponent implements OnInit {

  constructor(
    private dialog: DialogService,
    private route: Router,
    private sessionStorage: SessionStorageService,
    private notification: NotificationService
  ) {}
  
  ngOnInit(): void {
    this.dialog.confirm({
      title: 'Sorry, only one session can be in use at a time.',
      message: 'You have too many sessions open for Enrollment Forecasting.',
      confirmationButtonLabel: 'Continue on this session',
      cancelButtonLabel: 'Close this session',
      afterOK: () => {
        localStorage.setItem('isLoggedOut', 'no');
        this.sessionStorage.set('new_session_request', true);
        this.route.navigate(['modeling-predictions']);
      },
      afterCancel: () => {
        //this.notification.showInfo('This session has expired.', 'Redirecting to IDM');
        window.location.replace(environment.authLoginUrl);
      },
    });
  }

}
