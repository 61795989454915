import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
})
export class AlertsComponent implements OnInit {

  @Input() messageType: string;
  @Input() message: string;
  @Input() showCloseButton: boolean;
  @Output() alertClosed: EventEmitter<string>;

  constructor() {
    this.alertClosed = new EventEmitter<string>();
    this.showCloseButton = true;
  }

  ngOnInit(): void {
    //
  }

  closeAlert(): void {
    this.alertClosed.emit('closed');
  }
}
