<div class="modal-header border-bottom-0 pb-0">
  <h5 class="modal-title">{{modelTitle}}</h5>
</div>
<div class="modal-body">
  <p *ngIf="status !== 'completed' && status !== 'failed' && status !== 'stopped'" id="subHeading" class="mb-3">Time for
    a coffee break!!!
    <br>{{headerMessage}}
    <ng-template #name [ngIf]="showTimer">
      {{popupDescription}}
    </ng-template>
  </p>
  <p *ngIf="status === 'completed'" id="subHeading" class="mb-3">{{messages.MODEL_RUN_SUCCESS}}</p>
  <p *ngIf="status === 'failed'" id="subHeading" class="mb-3">{{messages.MODEL_RUN_FAILED}}</p>
  <div class="d-flex flex-column pt-2">
    <app-alerts [messageType]="'error'" [message]="errorMessage" *ngIf="errorMessage.length > 0 && status === 'failed'"
      (alertClosed)="errorMessage = ''" [showCloseButton]="false"></app-alerts>
    <div class="row no-gutter">
      <ng-container *ngFor="let item of statusIndicators; let i = index">
        <div class="col-12 d-flex">
          <div class="mr-4 d-flex align-items-center">
            <ng-container *ngIf="item.status.loading.includes(status)">
              <ng-template [ngTemplateOutlet]="loader"></ng-template>
            </ng-container>
            <ng-container *ngIf="item.status.done.includes(status)">
              <ng-template [ngTemplateOutlet]="done"></ng-template>
            </ng-container>
            <ng-container *ngIf="item.status.error.includes(status)">
              <ng-template [ngTemplateOutlet]="error"></ng-template>
            </ng-container>
            <ng-container
              *ngIf="!item.status.loading.includes(status) && !item.status.done.includes(status) && !item.status.error.includes(status)">
              <ng-template [ngTemplateOutlet]="none"></ng-template>
            </ng-container>
          </div>
          <p class="m-0">{{item.title}}</p>
        </div>
      </ng-container>
    </div>
  </div>
  <br>
  <p id="subHeading" class="mb-3" *ngIf="showTimer">You will be navigated to home screen in <b>{{counter$ | async}}</b>
    seconds.
  </p>
  <p id="subHeading" class="mb-3" *ngIf="showCloseCounter">This popup will close automatically in <b>{{counter$ |
      async}}</b>
    seconds.
  </p>
</div>

<div class="modal-footer d-flex py-0 d-flex justify-content-start">
  <ng-container
    *ngIf="parentScreen === 'simulation_activation' || parentScreen ==='site-activation' || parentScreen ==='track-monitor-screen' || parentScreen ==='upload-file'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('redirect')">
      Return to Home screen
    </button>
  </ng-container>
  <ng-container *ngIf="status === 'completed'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('completed')">
      View Results
    </button>
  </ng-container>
  <ng-container
    *ngIf="(status === 'stopped' || status === 'failed') && parentScreen != 'check-track-monitor-validation'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('navigate')">
      Go to Scenario
    </button>
  </ng-container>
  <ng-container
    *ngIf="cancelButtonAvailable && (status == 'preparing' || status == 'cloned' || status == 'queued' || status == 'launched' || status == 'started' || status == 'in-progress')">
    <button type="button" class="button-primary mr-3" (click)="modelRunCancelled()"
      [disabled]="status == 'preparing' || status == 'cloned' || status == 'queued' || status == 'launched'">
      Terminate Model Run
    </button>
  </ng-container>
  <ng-container *ngIf="parentScreen == 'check-track-monitor-validation' && status != 'failed'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('track-monitor-validation')"
      [disabled]="status == 'study-error' || status == 'scenario-error' || status == 'queued'">
      Go to Track & Monitor
    </button>
  </ng-container>
  <ng-container
    *ngIf="parentScreen === 'modelling-predictions' || parentScreen === 't-n-m-file-check' || parentScreen == 'check-track-monitor-validation'">
    <button type="button" class="button-primary mr-3" (click)="modalDismissed('close')">Close</button>
  </ng-container>
</div>

<ng-template #loader>
  <div class="loader">
    <div class="loading-enrollemnt w-100 h-100 loader-class">
      <img src="assets/images/shared/loading-circle.svg" width="24" height="24" alt="loading">
    </div>
  </div>
</ng-template>

<ng-template #done>
  <img src="assets/images/process-status/completed.svg" height="24" width="24" alt="completed">
</ng-template>

<ng-template #error>
  <img src="assets/images/process-status/error.svg" height="24" width="24" alt="completed">
</ng-template>

<ng-template #none>
  <img src="assets/images/process-status/none.svg" height="24" width="24" alt="completed">
</ng-template>
